<template>
	<div class="workingOutStatistic__card justify-space-between d-flex flex-column">
		<div class="workingOutStatistic__card__wrapper">
			<v-card-title class="workingOutStatistic__card-title primary--text h5 justify-center"
				>Statistik für
				{{ title }}
			</v-card-title>
			<v-expansion-panels v-if="filteredWorkoutStepsHistory.length" class="workingOutStatistic__card-panels" hover focusable>
				<v-expansion-panel :key="index" v-for="(workout, index) in filteredWorkoutStepsHistory">
					<v-expansion-panel-header class="h5">
						{{ convertDate(workout.timestamp) }}
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<v-textarea v-if="workout.note" v-model="workout.note" readonly></v-textarea>
						<v-data-table
							v-else
							:headers="datatableHeaders"
							:items="workout.steps"
							hide-default-footer
							mobile-breakpoint="0"
							class="elevation-1"
						></v-data-table>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-card-text v-else>
				Du hast noch keine Statistiken
			</v-card-text>
		</div>

		<v-card-actions class="workingOutStatistic__card-actions justify-center">
			<v-btn color="primary" rounded class="text-white" @click="$emit('closeStatistic')">
				Statistik schliessen
			</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
import api from '../../api'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'WorkingoutStatistic',
	props: {
		plan_id: {
			type: String,
			required: true,
		},
		exercise_id: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			selectedDate: null,
		}
	},
	methods: {
		convertDate(date) {
			return this.$moment(date * 1000).format('DD. MM. YYYY')
		},
	},
	computed: {
		...mapState('workingoutStore', ['workoutStepsHistory']),
		filteredWorkoutStepsHistory() {
			return this.workoutStepsHistory.filter((step) => step.exercise_id === this.exercise_id)
		},
		datatableHeaders() {
			let headers = [{ text: 'Satz', value: 'set', align: 'center' }]
			let workoutHistory = this.workoutStepsHistory.find((step) => step.exercise_id === this.exercise_id)

			workoutHistory.steps.find((step) => step.time)
				? headers.push({ text: 'Zeit', value: 'time', align: 'center' })
				: headers.push({ text: 'Wh', value: 'reps', align: 'center' }, { text: 'Gewicht', value: 'weight', align: 'center' })
			return headers
		},
	},
}
</script>

<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.v-card {
	transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
	opacity: 0.6;
}

.v-item--active {
	background-color: rgba(82, 174, 50, 0.1) !important;
}

.v-expansion-panel-content__wrap {
	padding-bottom: 0;
	padding-left: 2%;
	padding-right: 2%;
}

.workingOutStatistic__card {
	bottom: 0;
	background-color: white;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 6px solid var(--v-primary) !important;

	&-lottie {
		display: unset !important;
		align-self: center;
		height: 80vh !important;
		width: 80vh !important;
	}

	&-panels {
		width: 80%;
		margin-left: 10%;
		height: 60vh;
		overflow-y: auto;
	}

	&-title {
		position: sticky;
	}
	&-actions {
		position: sticky;
		bottom: 0;
	}
}
</style>
