import axios from 'axios'

export default class {
	constructor() {
		return new AbortController()
	}

	// getAbortController() {
	// const controller = new AbortController()
	// return controller
	// }
}
