<template>
	<div class="rollenverwaltung">
		<div class="d-flex mb-5">
			<v-btn class="mx-auto" color="primary" v-if="userService.hasRight('af_view', 'af_edit', 'af_downloadList')" @click="downloadKampagne"
				>Lead Liste herunterladen</v-btn
			>
		</div>
		<label>Benutzer / Weiterempfehlung suchen (mind. 3 Buchstaben):</label>
		<div class="search">
			<input placeholder="Suche..." type="text" v-model="search" />
			<span class="material-icons">search</span>
		</div>
		<div class="invite" v-for="(invite, j) of invites" :key="j">
			<span class="datum">{{ timestamptodate(invite.datum) }}</span>
			<span class="inviter">{{ invite.invitedby }}</span>
			hat
			<span class="invited">{{ invite.telnummer }}</span>
			eingeladen..
			<select class="status" @change="changeStatus(invite._id, invite.status)" v-model="invite.status">
				<option value="1">Wurde angeworben</option>
				<option value="12">Kontaktiert</option>
				<option value="2">Probetraining vereinbart</option>
				<option value="3">Vertrag Abgeschlossen</option>
				<option value="4">Kein Abschluss</option>
				<option value="5">Geschlossen</option>
			</select>
			<textarea v-model="invite.notiz" placeholder="Notizen...."></textarea>
			<div class="save-invite" @click="changeStatus(invite._id, invite.status, invite.notiz, true)">
				Notiz speichern
			</div>
			<div @click="deleteWeiterempfehlung(invite._id)" class="delete">
				löschen
			</div>
		</div>
		<div class="latest-invites">
			<select class="filter" @change="filterInvites()" v-model="filter">
				<option value="1">Wurde angeworben</option>
				<option value="12">Kontaktiert</option>
				<option value="2">Probetraining vereinbart</option>
				<option value="3">Vertrag Abgeschlossen</option>
				<option value="4">Kein Abschluss</option>
				<option value="5">Geschlossen</option>
			</select>
			<div class="invite" v-for="(linvite, i) of latestinvites" :key="i">
				<span class="datum">{{ timestamptodate(linvite.datum) }}</span>
				<span class="inviter">{{ linvite.invitedby }}</span>
				hat
				<span class="invited">
					<span>{{ linvite.name }}</span>
					<br />
					<span>{{ linvite.telnummer }}</span>
				</span>
				eingeladen..
				<select class="status" @change="changeStatus(linvite._id, linvite.status, linvite.notiz, false)" v-model="linvite.status">
					<option value="1">Wurde angeworben</option>
					<option value="12">Kontaktiert</option>
					<option value="2">Probetraining vereinbart</option>
					<option value="3">Vertrag Abgeschlossen</option>
					<option value="4">Kein Abschluss</option>
					<option value="5">Geschlossen</option>
				</select>
				<textarea v-model="linvite.notiz" placeholder="Notizen...."></textarea>
				<div class="save-invite" @click="changeStatus(linvite._id, linvite.status, linvite.notiz, true)">
					Notiz speichern
				</div>
				<div @click="deleteWeiterempfehlung(linvite._id)" class="delete">
					löschen
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'
import zipcelxBasic from 'zipcelx'
import userService from '../../services/userService'
export default {
	data() {
		return {
			userService,
			search: '',
			users: {},
			rollen: {},
			editing: {},
			latestinvites: {},
			invites: {},
			filter: 1,
			awaitingSearch: false,
		}
	},
	watch: {
		search: function(val) {
			if (!this.awaitingSearch) {
				setTimeout(() => {
					this.findInvites()
					this.awaitingSearch = false
				}, 1000) // 1 sec delay
			}
			this.awaitingSearch = true
		},
	},
	methods: {
		downloadKampagne() {
			api.getRecentWeiterempfehlungen().then((data) => {
				const config = {
					filename: 'Lead_Liste',
					sheet: {
						data: data,
					},
				}

				zipcelxBasic(config)
			})
		},
		copyToClipboard(text) {
			navigator.clipboard.writeText(text)
		},
		deleteWeiterempfehlung(id) {
			if (confirm('Wollen Sie die Weiterempfehlung wirklich löschen?')) {
				api.deleteWeiterempfehlung(id).then((data) => {
					if (data.status) {
						alert('wurde gelöscht')
						this.findInvites()
						this.filterInvites()
					}
				})
			}
		},
		async filterInvites() {
			let studio = null

			studio = await api.getUserStudio().catch((e) => {
				window.app.$root.$children[0].checkStudio()
			})
			studio = studio.studio
			let studio_id = studio._id
			api.getInvites({ studio: studio_id, filter: this.filter })
				.then((data) => {
					this.latestinvites = data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		changeStatus(id, status, notiz, al) {
			api.updateInvite({ _id: id, status: status, notiz: notiz })
				.then((data) => {
					if (!data.status) {
						alert('fehler')
					}
					console.log(alert)
					if (al) {
						alert('Gespeichert')
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		timestamptodate(ts) {
			return new Date(ts * 1000).toLocaleString()
		},

		async findInvites() {
			if (this.search.length > 2) {
				let studio = await api.getUserStudio().catch((e) => {
					window.app.$root.$children[0].checkStudio()
				})
				studio = studio.studio
				let studio_id = studio._id
				api.searchInvites({ studio_id: studio_id, search: this.search })
					.then((data) => {
						this.invites = data
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				this.invites = {}
			}
		},
		saveEditing() {
			let senddata = {
				userId: this.editing._id,
				admin: this.editing.admin,
				mitarbeiter: this.editing.mitarbeiter,
				leitung: this.editing.leitung,
			}

			api.editRolle(senddata)
				.then((data) => {
					$('#rolleneditor').hide()
					alert('Benutzer erfolgreich bearbeitet')
				})
				.catch((error) => {
					console.log(error)
				})
		},
		cancelEditing() {
			$('#rolleneditor').hide()
		},
	},
	async created() {
		api.getUserRolle()
			.then((data) => {
				this.rollen = data
			})
			.catch((error) => {
				console.log(error)
			})

		let studio = null

		studio = await api.getUserStudio().catch((e) => {
			window.app.$root.$children[0].checkStudio()
		})
		studio = studio.studio
		let studio_id = studio._id

		api.getInvites({ studio: studio_id, filter: this.filter })
			.then((data) => {
				this.latestinvites = data
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>
<style lang="scss" scoped>
.show-pointer {
	cursor: pointer;
}
.kampagne {
	background-color: var(--v-primary);
	width: 100%;
	padding: 15px;
	margin: 20px auto 20px auto;
	color: #fff;
	/* text-transform: uppercase; */
	border-radius: -25px;
	text-align: center;
}
.save-invite {
	margin-bottom: 10px;
	background-color: var(--v-primary);
	color: #fff;
	padding: 10px;
	border-radius: 2px;
}
.delete {
	margin-top: 10px;
	color: red;
}
select.filter {
	width: 100%;
	padding: 10px 15px;
	margin: 0px 0px 20px 0px;
}
.latest-invites {
	margin-top: 20px;
	padding: 10px;
}
.invites-header {
	font-size: 20px;
}

.invite {
	border: 1px solid var(--v-primary);
	width: 100%;
	max-width: 400px;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	padding: 10px;
	text-align: center;

	.inviter {
		padding: 2px;
		font-weight: 500;
	}
	.invited {
		font-weight: 500;
		padding: 2px;
	}
	.status {
		margin: 20px auto;
	}

	.datum {
		background-color: var(--v-primary);
		color: white;
		padding: 5px 10px;
		margin-bottom: 10px;
	}
}
#rolleneditor {
	padding: 20% 10px 20% 10px;
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	background-color: white;
	h4 {
		text-align: center;
		margin-bottom: 30px;
	}
	.rolle {
		padding-left: 0px !important;
	}
	.rollen-wrapper {
		max-width: 400px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		padding: 5px;
		input {
			width: 20px;
			height: 20px;
			padding: 10px;
		}
	}

	.speichern {
		background-color: var(--v-primary);
		color: white;
		margin: 20px auto 0px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
	.schliessen {
		background-color: #443838;
		color: white;
		margin: 20px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
}
label {
	padding-left: 10px;
}
.search {
	position: relative;
	.material-icons {
		position: absolute;
		top: 10px;
		right: 20px;
		color: #4a4a4a;
	}
	input {
		color: #4a4a4a;
		width: 100%;
		padding: 15px 15px 10px 15px;
		border-bottom: 1px solid #4a4a4a;
	}
}
.save {
	color: white;
	width: 130px;
	background-color: var(--v-primary);
	margin: 20px auto;
	text-align: center;
	padding: 10px;
	border-radius: 40px;
}

.user {
	color: #4a4a4a;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	display: flex;
	border-bottom: 1px solid #4a4a4a;

	.username {
		max-width: 80%;
		display: block;
	}
	.material-icons {
		margin-left: auto;
		margin-right: 10px;
		margin-top: auto;
		margin-bottom: auto;
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
