<template>
    <v-row class="flex-column" no-gutters>
        <v-card-text class="h6 font-weight-bold">Wähle deine Häufigkeit</v-card-text>
        <v-select
            rounded
            filled
            class="font-weight-bold px-1 pt-0 frequenzy-picker"
            @change="freqzenzyChanged"
            v-model="frequenzy"
            :items="frequenzyItems"
        ></v-select>
        <div :class="{ 'rounded pa-1': frequenzyIndex === 0 || frequenzyIndex === 1 }">
            <div v-if="frequenzyIndex === 0">
                <v-select
                    filled
                    rounded
                    type="number"
                    v-model="dayPicker"
                    placeholder="Jeden wie vielten Tag?"
                    :items="dayPickerItems"
                >
                    <template v-slot:item="{ item }">
                        <span v-if="item === 1">Jeden Tag</span>
                        <span v-else>Jeden {{ item }}. Tag</span>
                    </template>
                    <template v-slot:selection="{ item }">
                        <span v-if="item === 1">Jeden Tag</span>
                        <span v-else>Jeden {{ item }}. Tag</span>
                    </template>
                </v-select>
            </div>
            <div class="justify-space-around d-flex" v-if="frequenzyIndex === 1">
                <v-col class="px-0" v-for="(weekday, index) of weekdays" :key="index" cols="1">
                    <v-btn
                        @click="clickedWeekday(weekday)"
                        icon
                        :style="weekday.active ? 'background: #2196F3 !important' : ''"
                        :class="{ 'white--text': weekday.active, 'primary white--text': weekday.executionTimes.length }"
                        class="white"
                    >
                        {{ weekday.label }}
                    </v-btn>
                </v-col>
            </div>
            <div v-if="frequenzyIndex === 0 || frequenzyIndex === 1">
                <div v-if="dayPicker || anyWeekdayActive">
                    <v-card-text class="h6 font-weight-bold">Füge Uhrzeiten hinzu</v-card-text>
                    <div :key="'takingTime' + index" class="d-flex" v-for="(item, index) in displayedExecutionTimes">
                        <v-col cols="2" class="py-2 align-center">
                            <v-btn @click="executionTimes = executionTimes.splice(index, 1)" icon x-small>
                                <v-icon class="red--text">mdi-minus-circle</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="py-2 align-center">
                            <input type="time" v-model="item.time" class="pa-1 lightgray" />
                        </v-col>

                        <v-col cols="6" class="py-2 align-center">
                            <input
                                class="pa-1 lightgray"
                                type="number"
                                style="width: 30px; height: 100%"
                                v-model="item.amount"
                            />
                            {{ item.amount === 1 ? 'Portion' : 'Portionen' }}
                        </v-col>
                    </div>

                    <div class="d-flex">
                        <v-col cols="2" class="py-2 align-center">
                            <v-btn icon x-small @click="addNewTakingTimeClicked()">
                                <v-icon class="primary--text">mdi-plus-circle</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="py-2 align-center" cols="10"><span>Neue Uhrzeit hinzufügen</span></v-col>
                    </div>
                </div>

                <v-card-text class="h6 font-weight-bold" px-2>Ab wann beginnst du mit der Gewohnheit?</v-card-text>
                <v-menu
                    ref="firstDatePicker"
                    v-model="firstDatePicker"
                    :nudge-right="40"
                    :return-value.sync="firstDate"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            filled
                            rounded
                            v-model="firstDate"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-if="firstDatePicker"
                        v-model="firstDate"
                        full-width
                        @change="$refs.firstDatePicker.save(firstDate)"
                    ></v-date-picker>
                </v-menu>
            </div>
            <!-- v-if="anyWeekdayActive || executionTimes.length" -->
            <div v-if="false">
                <v-card-text class="h6 font-weight-bold">
                    Möchtest du eine Benachrichtigung erhalten?
                    <v-col>
                        <v-checkbox
                            v-model="wantNotification"
                            @click="$emit('reTriggerHeight')"
                            color="primary"
                        ></v-checkbox>
                    </v-col>
                </v-card-text>

                <v-col cols="12 py-0" v-if="wantNotification">
                    <v-menu
                        ref="menu"
                        v-model="timePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="notificatonTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="px-4"
                                v-model="notificatonTime"
                                label="Uhrzeit"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="timePicker"
                            v-model="notificatonTime"
                            full-width
                            @click:minute="$refs.menu.save(notificatonTime)"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
            </div>
        </div>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import api from '@/api.js'

export default {
    name: 'habitWorkoutForm',
    props: {
        selectedHabit: {
            type: Object,
            required: true,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            frequenzy: 'regelmässige Abstände',
            frequenzyItems: ['regelmässige Abstände', 'bestimmte Wochentage', 'Nach Bedarf'],
            wantNotification: false,
            timePicker: false,
            firstDatePicker: false,
            notificatonTime: null,
            dayPicker: null,
            executionTimes: [],
            weekdays: [
                { label: 'Mo', active: false, executionTimes: [] },
                { label: 'Di', active: false, executionTimes: [] },
                { label: 'Mi', active: false, executionTimes: [] },
                { label: 'Do', active: false, executionTimes: [] },
                { label: 'Fr', active: false, executionTimes: [] },
                { label: 'Sa', active: false, executionTimes: [] },
                { label: 'So', active: false, executionTimes: [] },
            ],
            selectedDate: null,
            selectedTime: null,
            firstDate: this.$moment().format('YYYY-MM-DD'),
        }
    },
    mounted() {},

    async created() {
        if (this.edit) {
            this.wantNotification = this.selectedHabit.wantNotification
            this.selectedHabit.days.forEach((day) => (this.weekdays[day].active = true))
        }
    },
    methods: {
        freqzenzyChanged() {
            this.$emit('reTriggerHeight')
        },
        addNewTakingTimeClicked() {
            this.frequenzyIndex === 0 && this.executionTimes.push({ time: this.$moment().format('HH:mm'), amount: 1 })
            if (this.frequenzyIndex === 1) {
                this.weekdays.forEach((day) => {
                    if (day.active) day.executionTimes.push({ time: this.$moment().format('HH:mm'), amount: 1 })
                })
            }
            this.$emit('reTriggerHeight')
        },
        clickedWeekday(weekday) {
            this.weekdays.forEach((day, index) => {
                this.weekdays[index].active = day.label === weekday.label
            })
            setTimeout(() => this.$emit('reTriggerHeight'), 50)
        },
    },
    computed: {
        displayedExecutionTimes() {
            if (this.frequenzyIndex === 0) return this.executionTimes
            if (this.frequenzyIndex === 1) {
                let executionTimes = []
                this.weekdays.forEach((day) => {
                    if (day.active) executionTimes = day.executionTimes
                })
                return executionTimes
            }
        },
        dayPickerItems() {
            let newArray = Array.from(Array(100).keys())
            return newArray.map((number) => number + 1)
        },
        frequenzyIndex() {
            return this.frequenzyItems.indexOf(this.frequenzy)
        },
        anyWeekdayActive() {
            return this.weekdays.some((weekday) => weekday.active)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .frequenzy-picker .v-text-field__details {
    display: none;
}
::v-deep .v-menu__content {
    border-radius: 25px;
}
.lightgray {
    background: lightgray;
    border-radius: 5px;
}
</style>
