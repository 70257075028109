<template>
    <v-row no-gutters>
        <v-card-text>
            {{ selectedHabit.description }}
            <span v-if="userWeight">Das wäre für dich {{ Math.round(userWeight * 0.035 * 100) / 100 }} L</span>
        </v-card-text>
        <v-card-text>
            <v-select
                label="Ziel"
                placeholder="in Liter pro Tag"
                :items="waterAmoutOptions"
                @change="reTriggerHeight()"
                v-model="selectedAmount"
            ></v-select>
            <v-row v-show="selectedAmount">
                <v-col cols="12">In 0.25 Liter Gläser angegeben</v-col>
                <v-col cols="2" class="px-0" v-for="index of amountGlassesShown" :key="'selectedAmount' + index">
                    <v-img height="35" width="35" src="/img/lottiefiles/glas.png"></v-img>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="ma-auto" v-show="selectedAmount">
            <v-btn @click="saveNewHabit()" color="primary" outlined>Speichern</v-btn>
        </v-card-actions>
        <div id="saveButton"></div>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
export default {
    name: 'habitDrinkForm',
    props: {
        selectedHabit: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedAmount: null,
            waterAmoutOptions: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
        }
    },
    mounted() {},

    async created() {
        if (this.selectedHabit.amount) this.selectedAmount = this.selectedHabit.amount
    },
    methods: {
        reTriggerHeight() {
            this.$emit('reTriggerHeight', document.querySelector('#saveButton'))
        },
        async saveNewHabit() {
            this.selectedHabit.amount = this.selectedAmount
            try {
                await this.selectedHabit.save()
                this.$emit('habitSaved')
            } catch (e) {
                this.$toast.error('Fehler beim abspeichern')
            }
        },
    },
    computed: {
        ...mapGetters('userStore', ['userWeight']),
        amountGlassesShown() {
            return Math.round(this.selectedAmount / 0.25)
        },
    },
}
</script>

<style lang="scss" scoped></style>
