<template>
    <v-card dark class="mx-auto py-3">
        <v-row v-if="loadingEntries">
            <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-text-field
                    class="mx-5"
                    v-model="search"
                    label="Suche"
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="!searching">
                <v-expansion-panels class="px-5" v-model="panel">
                    <v-expansion-panel v-for="(employee, index) of timeEntries" :key="employee.user + '-panel'">
                        <v-expansion-panel-header>
                            <span
                                :class="{
                                    'orange--text':
                                        submissionState(employee) && submissionState(employee).state === 'submitted',
                                    'primary--text':
                                        submissionState(employee) && submissionState(employee).state === 'accepted',
                                    'red--text':
                                        submissionState(employee) && submissionState(employee).state === 'rejected',
                                }"
                            >
                                {{ employee.fullName + ' ' + employee.email }}
                            </span>

                            <span class="ml-2">
                                <v-chip
                                    v-if="submissionState(employee) && submissionState(employee).state !== 'onlyNotes'"
                                    :color="
                                        submissionState(employee) && submissionState(employee).state === 'rejected'
                                            ? 'red'
                                            : submissionState(employee) &&
                                              submissionState(employee).state === 'accepted'
                                            ? 'primary'
                                            : 'orange'
                                    "
                                >
                                    {{
                                        submissionState(employee) && submissionState(employee).state === 'rejected'
                                            ? 'abgelehnt'
                                            : submissionState(employee) &&
                                              submissionState(employee).state === 'accepted'
                                            ? 'abgeschlossen'
                                            : 'eingereicht'
                                    }}
                                </v-chip>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <office-time-tracker-view
                                v-if="index === panel"
                                :studio="selectedStudioSingle"
                                @fetchEntries="trackerViewUpdated"
                                :timeEntryView="employee"
                                :month="$moment(datePickerRange[0])"
                                office
                            ></office-time-tracker-view>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" v-if="searching">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
            <v-col cols="12" v-if="!timeEntries.length === 0">
                <v-alert class="mx-5" type="info">Es wurden keine Einträge gefunden</v-alert>
            </v-col>
        </v-row>
    </v-card>
</template> 

<script>
import api from '@/api'
import userService from '@/services/userService'
import OfficeTimeTrackerView from './OfficeTimeTrackerView.vue'
export default {
    name: 'OfficeTimeTracker',
    components: { OfficeTimeTrackerView },
    props: {
        datePickerRange: {},
        selectedStudioSingle: {
            required: true,
        },
    },
    data() {
        return {
            search: '',
            panel: null,
            userService,
            loadingEntries: false,
            timeEntries: [],
            filteredItems: [],
            searching: false,
        }
    },
    methods: {
        submissionState(employee) {
            if (
                employee.timeTrackerMonths &&
                employee.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.datePickerRange[0]).format('YYYY-MM')
                )
            ) {
                return employee.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.datePickerRange[0]).format('YYYY-MM')
                )
            }
            return
        },
        trackerViewUpdated(data) {
            this.fetchEntries(data)
        },
        async fetchEntries(data = null) {
            this.loadingEntries = true
            try {
                this.timeEntries = await api.fetchOfficeTimeEntriesForStudio(
                    this.datePickerRange,
                    this.selectedStudioSingle
                )

                this.loadingEntries = false
            } catch (e) {
                this.timeEntries = []
                this.loadingEntries = false
                this.$toast.erorr('Fehler beim Laden der Einträge')
            }
        },
    },
    created() {
        this.fetchEntries()
    },
    watch: {
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.fetchEntries()
            }
        },
        async selectedStudioSingle() {
            this.fetchEntries()
        },
        /*
        search() {
            this.searching = true
            const filteredObj = {}

            for (const key in this.timeEntries) {
                if (this.timeEntries.hasOwnProperty(key)) {
                    let searchEntry =
                        this.timeEntries[key].employee.vorname.toLowerCase() +
                        ' ' +
                        this.timeEntries[key].employee.nachname.toLowerCase() +
                        ' ' +
                        this.timeEntries[key].employee.email.toLowerCase()
                    if (searchEntry.includes(this.search.toLowerCase())) filteredObj[key] = this.timeEntries[key]
                }
            }
            this.searching = false
            this.filteredItems = filteredObj
        },
        */
    },
}
</script>

<style lang="scss" scoped>
</style>