<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Labels</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="labels.length > 0" class="select-label">
            <label>Label bearbeiten:</label>
            <select class="choose-label" @change="editlabel" v-model="editalabel">
              <option value="1" disabled>Label auswählen (anlicken)</option>
              <option v-for="(label, j) of labels" :key="j" :value="label._id">{{ label.code }}</option>
            </select>
          </div>

          <div v-else>Es gibt noch keine Labels, füge jetzt ein neues Label hinzu</div>
          <label v-if="editalabel == 1">Neues Label erstellen:</label>

          <div class="label-editor">
            <label v-if="editalabel != 1">Code:</label>
            <input placeholder="Code" type="text" v-model="alabel.code" />
            <label v-if="editalabel != 1">Text:</label>
            <textarea v-model="alabel.text" placeholder="Text"></textarea>

            <label>Farbe</label>
            <input type="color" id="html5colorpicker" v-model="alabel.color" style="width:100%;" />
          </div>
          <button v-if="editalabel == 1" type="button" class="btn btn-primary" @click.prevent="save">Hinzufügen</button>
          <div v-else class="wrap-edit">
            <button type="button" class="btn btn-primary" @click.prevent="save">Speichern</button>
            <button type="button" class="btn btn-primary" @click.prevent="newLabel">Neues Label hinzufügen</button>
          </div>

          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">Zurück zum Zeitplan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import api from '../api'

export default {
  data() {
    return {
      error: '',
      labels: [],
      editalabel: 1,
      alabel: {
        _id: null,
        code: '',
        text: '',
        color: '#000000',
        studio_id: '',
      },
    }
  },
  methods: {
    newLabel() {
      this.editalabel = 1
      this.alabel = {
        _id: null,
        code: '',
        text: '',
        color: '#000000',
        studio_id: '',
      }
    },
    editlabel() {
      for (let lb of this.labels) {
        if (lb._id == this.editalabel) {
          this.alabel._id = lb._id
          this.alabel.code = lb.code
          this.alabel.text = lb.text
          this.alabel.color = lb.color
          this.alabel.studio_id = lb.studio_id
        }
      }
    },
    async show(labelx) {
      this.labels = labelx
      let studio = null

      studio = await api.getUserStudio().catch((e) => {
        window.app.$root.$children[0].checkStudio()
      })
      studio = studio.studio
      this.alabel.studio_id = studio._id
      $(this.$refs['modal']).modal('show')
    },
    hide() {
      $(this.$refs['modal']).modal('hide')
    },
    async save() {
      let studio = null

      studio = await api.getUserStudio().catch((e) => {
        window.app.$root.$children[0].checkStudio()
      })
      studio = studio.studio
      this.alabel.studio_id = studio._id
      if (this.alabel.code && this.alabel.text) {
        api
          .createLabel(this.alabel)
          .then((data) => {
            if (data.status) {
              this.hide()
              this.editalabel = 1
              this.alabel = {
                _id: null,
                code: '',
                text: '',
                color: '#000000',
                studio_id: '',
              }
              this.$emit('labels-edited')
            } else {
              alert('fehler')
            }
          })
          .catch((error) => {
            alert('fehler')
            console.log(error)
          })
      } else {
        alert('Bitte fülle alle benötigten Felder aus')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  margin-bottom: 10px;
}
.choose-label {
  width: 100%;
  padding: 10px;
}
.label-editor {
  display: flex;
  flex-direction: column;
  input,
  textarea {
    padding: 10px;
    margin-bottom: 10px;
  }
  input[type='color'] {
    padding: 0px 10px;
  }
  label {
    padding: 0px 10px;
  }
}
.modal-content {
  height: 90vh;
}
</style>
