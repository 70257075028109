<template>
  <div style="touch-action: none !important; height: 100vh; width: 100%">
    <v-col cols="12" style="overflow-x: auto">

      <v-card-title class="h6 pa-0">
        <v-btn class="mr-2" small fab color="white" @click="$emit('return')"><v-icon
            color="black">mdi-chevron-left</v-icon></v-btn>

        Deine Splits</v-card-title>

      <div class="button-switcher">
        <v-btn-toggle v-model="selectedSplit" mandatory class="custom-toggle">
          <div class="button-container">
            <v-btn :ripple="false" v-for="(item, index) in form.splits.workouts.map(
              (workout) => workout.title
            )" :key="item + index" class="custom-btn rounded-lg mr-2" @click="initialize">
              <span :class="{ 'custom-toggle--active': index === selectedSplit }" style="z-index: 100">
                {{ item }}
              </span>
            </v-btn>
          </div>
          <span ref="highlight" class="highlight"></span>
        </v-btn-toggle>
      </div>
    </v-col>
    <v-col class="pt-0" cols="12">

      <v-window touchless v-model="selectedSplit">
        <v-window-item :key="'windowItem ' + key" v-for="(item, key) in form.splits.workouts" :value="key">
          <div v-if="selectedSplit === key">
            <v-row no-gutters>
              <v-col cols="4" class="pr-2">
                Suche Übungen für deinen
                {{ form.splits.workouts[selectedSplit].title }} Split
                <v-text-field filled rounded clearable label="Suchen" v-model="exerciseSearch"></v-text-field>
              </v-col>
              <v-col cols="8" class="pl-2">
                Oder nutze unsere Filter Funktion
                <div class="custom-toggle">
                  <v-btn @click="toggleSelectedTag(tag)" class="mr-1" v-for="(tag, key) in item.recommendedTags"
                    :color="tagColors[key]" :key="key" small>
                    {{ tag }}
                  </v-btn>
                  <v-btn @click="toggleAllTags = !toggleAllTags" class="mr-1" color="red" :key="'filter' + key" small>
                    Alle Filter anzeigen
                  </v-btn>
                </div>
                <div class="custom-toggle mt-2">
                  <v-chip @click:close="toggleSelectedTag(tag)" class="mr-1" style="flex: 0 0 auto" outlined
                    color="primary" small v-for="(tag, key) in selectedTags" :key="'tag' + key" close>
                    {{ tag }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" v-if="!editMode">
                <v-card-title class="h6 pa-0 mt-2">Alle Übungen</v-card-title>
                <!-- Exercise List (Draggable Items) -->
                <v-virtual-scroll style="height: 60vh" :items="filteredExercises" item-height="80" bench="1">
                  <template v-slot:default="{ item }">
                    <div
                      class="draggable-item workoutAddExercise__list-item my-3 px-0 elevation-3 d-flex black rounded-lg"
                      :key="'draggable-item-selected' + item._id" :data-id="item._id" :data-source="'exercises'">
                      <!-- Exercise Item Content -->
                      <v-col cols="auto" class="pa-0">
                        <v-img height="76px" width="76px" cover position="top center"
                          :src="getImageForExercise(item)"></v-img>
                      </v-col>
                      <v-col cols="grow" class="ml-5">
                        <v-list-item-title>
                          {{ item.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-bold" v-if="item.deviceShortForm">
                          {{ item.deviceShortForm }}
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col cols="auto 2" class="align-content-center">
                        <!-- Drag Handle Button -->
                        <v-btn icon class="drag-handle" @mousedown.stop @touchstart.stop>
                          <v-icon>mdi-drag-horizontal-variant</v-icon>
                        </v-btn>
                      </v-col>
                    </div>
                  </template>
                </v-virtual-scroll>
              </v-col>

              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" v-if="!editMode">
                <v-card-title class="h6 pa-0 mt-2">Dein Workout</v-card-title>

                <!-- Selected Exercises (Dropzone) -->
                <div class="dropzone" :key="selectedExerciseKey" ref="dropzone">
                  <div v-if="
                    selectedExercisesWithPlaceholders.length === 1 &&
                    !isDragging
                  ">
                    <div class="text-center py-0">
                      <v-card-title class="font-weight-bold py-0" style="place-content: center">
                        Ziehe deine erste Übung ins Workout
                      </v-card-title>
                    </div>
                  </div>
                  <div v-for="(item, index) in selectedExercisesWithPlaceholders"
                    :key="item._id + index || 'placeholder' + index">
                    <div v-if="item.isPlaceholder">
                      <div :class="{
                        'show-placeholder':
                          isDragging &&
                          (parseInt(placeholderIndex) - 1 ===
                            parseInt(index) ||
                            parseInt(placeholderIndex) + 1 ===
                            parseInt(index)),
                      }" style="z-index: 10000" class="placeholder-item" :data-index="index"
                        :data-source="'selectedExercises'">
                        <v-list-item-title>Übung hier einfügen</v-list-item-title>
                      </div>
                    </div>
                    <div v-else-if="parseInt(placeholderIndex) !== index && item.exercises" :key="item._id"
                      :data-id="item._id" :data-index="index" :id="item.originalIndex"
                      :data-source="'selectedExercises'" class="draggable-selected">
                      <div class="multiple-exercises-wrapper"
                        :class="{ 'multiple-exercises-wrapper--superset': item.type === 'superSet', 'multiple-exercises-wrapper--circletraining': item.type === 'circleTraining' }"
                        style="position: relative">
                        <v-chip class="exercise-wrapper-chip" :color="exerciseCombinations[item.type].color"
                          position="absolute" top center>{{ exerciseCombinations[item.type].text }}</v-chip>

                        <v-btn absolute icon color="red" class="mr-1"
                          style="top: 0; bottom: 0; margin: auto; right: 45px; z-index: 1000;"
                          @click="removeExercise(item.originalIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                        <v-btn absolute style="top: 0; bottom: 0; margin: auto; right: 15px; z-index: 1000;" icon
                          color="blue" @click="edit(item, index)"><v-icon>mdi-pencil</v-icon></v-btn>

                        <v-row no-gutters v-for="(exercise, index) in item.exercises" :key="'addEx' + index">
                          <v-col cols="auto" class="pa-0">
                            <v-img height="76px" width="76px" cover position="top center"
                              :src="getImageForExercise(exercise)"></v-img>
                          </v-col>
                          <v-col class="ml-5 py-3" cols="grow">
                            <v-list-item-title>
                              {{ exercise.title }}
                              <span class="" v-if="exercise.sets && exercise.sets.length > 1">
                                {{ exercise.sets ? '(' + exercise.sets.length + ' Sätze)' : '' }}

                              </span>
                              <span v-if="exercise.sets && exercise.sets.length === 1">(1 Satz)</span>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!exercise.sets">
                              Noch keine Sätze hinterlegt
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              <v-chip small
                                v-if="exercise.weightSum && exercise.weightSum.min || exercise.weightSum.max">
                                {{ 'Gewicht | ' + (exercise.weightSum.min === exercise.weightSum.max ?
                                  exercise.weightSum.min :
                                  exercise.weightSum.min + ' - ' + exercise.weightSum.max) + ' KG' }}
                              </v-chip>
                              <v-chip small v-if="exercise.repSum && exercise.repSum.min || exercise.repSum.max">
                                {{ 'WH | ' + (exercise.repSum.min && exercise.repSum.max ? exercise.repSum.min + ' - ' +
                                  exercise.repSum.max
                                  :
                                  exercise.repSum.min ? exercise.repSum.min : exercise.repSum.max ? exercise.repSum.max :
                                    '') }}
                              </v-chip>
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col cols="8">
                            <v-divider style="margin: 10px 0;"></v-divider>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div class="draggable-selected" v-else-if="parseInt(placeholderIndex) === index" :data-id="item._id"
                      :data-index="index" :id="item.originalIndex" :data-source="'selectedExercises'"
                      :style="placeHolderHeight ? 'height: ' + placeHolderHeight + 'px !important' : ''">
                      <v-col cols="12" class="text-center" style="cursor: pointer; align-content-center">
                        <v-list-item-title>
                          Kombinieren zu Supersatz oder Zirkeltraining
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 1.5rem">
                          💪 | 🔄
                        </v-list-item-subtitle>
                      </v-col>
                    </div>
                    <div v-else
                      class="draggable-selected workoutAddExercise__list-item mb-1 px-0 elevation-3 d-flex black rounded"
                      :data-id="item._id" :data-index="index" :id="item.originalIndex"
                      :data-source="'selectedExercises'">
                      <!-- Selected Exercise Item Content -->

                      <v-row @click="test(item)" no-gutters v-if="parseInt(placeholderIndex) !== index">

                        <v-col cols="auto" class="pa-0">
                          <v-img height="76px" width="76px" cover position="top center"
                            :src="getImageForExercise(item)"></v-img>
                        </v-col>
                        <v-col class="ml-5 py-3" cols="grow">
                          <v-list-item-title>
                            {{ item.title }}
                            <span class="" v-if="item.sets && item.sets.length > 1">
                              {{ item.sets ? '(' + item.sets.length + ' Sätze)' : '' }}

                            </span>
                            <span v-if="item.sets && item.sets.length === 1">(1 Satz)</span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="!item.sets">
                            Noch keine Sätze hinterlegt
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            <v-chip small v-if="item.weightSum && item.weightSum.min || item.weightSum.max">
                              {{ 'Gewicht | ' + (item.weightSum.min === item.weightSum.max ? item.weightSum.min :
                                item.weightSum.min + ' - ' + item.weightSum.max) + ' KG' }}
                            </v-chip>
                            <v-chip small v-if="item.repSum && item.repSum.min || item.repSum.max">
                              {{ 'WH | ' + (item.repSum.min && item.repSum.max ? item.repSum.min + ' - ' +
                                item.repSum.max
                                :
                                item.repSum.min ? item.repSum.min : item.repSum.max ? item.repSum.max : '') }}
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-col>
                        <v-col cols="auto" class="align-content-center">
                          <!-- Drag Handle Button -->
                          <v-btn small icon color="red" class="mr-1"
                            @click="removeExercise(item.originalIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                          <v-btn small icon color="blue" @click="edit(item, index)"><v-icon>mdi-pencil</v-icon></v-btn>

                          <v-btn icon class="drag-handle" @mousedown.stop @touchstart.stop>
                            <v-icon>mdi-drag-horizontal-variant</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="12" class="text-center" style="cursor: pointer; align-content-center">
                          <v-list-item-title>
                            Kombinieren zu Supersatz oder Zirkeltraining
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 1.5rem">
                            💪 | 🔄
                          </v-list-item-subtitle>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </v-col>
              <!-- Edit Mode Section -->
              <v-slide-x-reverse-transition class="slow-transition">

                <v-col cols="12" v-if="editMode" transition="expand-transition">
                  <v-card class="elevation-3 rounded-xl"
                    style="height: 70vh; padding: 20px; background: rgba(255, 255, 255, 0.08)">
                    <workout-split-edit-exercise :trainingMethods="trainingMethods" @savedExercise="saveExercise"
                      @cancel-edit="cancelEdit()" :exercise-prop="selectedExercise"></workout-split-edit-exercise>
                  </v-card>
                </v-col>
              </v-slide-x-reverse-transition>
            </v-row>
          </div>
        </v-window-item>
      </v-window>
      <v-card-actions class="justify-content-center"> <v-btn color="primary" x-large rounded @click="$emit('next')">
          weiter
        </v-btn></v-card-actions>
    </v-col>

  </div>
</template>

<script>
import interact from "interactjs";
import api from "@/api";
import { mapActions, mapGetters } from "vuex";
import WorkoutSplitEditExercise from './WorkoutSplitEditExercise.vue'
export default {
  name: "WorkoutAddExercise",
  props: {
    form: {
      type: Object,
    },
    exerciseIds: {
      type: Array,
    },
  },
  components: {
    WorkoutSplitEditExercise
  },
  data() {
    return {
      trainingMethods: {
        dropSet: { color: 'red', text: "🔥 Drop Set", description: "Abnehmendes Gewicht bei mehreren Sätzen ohne Pause zwischen den Sätzen.", emoji: "🔥" },
        reverseDropSet: { color: 'red', text: "🔥 Reverse Drop Set", description: "Zunehmendes Gewicht bei mehreren Sätzen ohne Pausen zwischen den Sätzen.", emoji: "🔥" },
        pyramidSet: { color: 'blue', text: "📈 Pyramidensatz", description: "Steigendes oder fallendes Gewicht bei jeder Wiederholung mit kurzen Pausen.", emoji: "📈" },
        restPause: { color: 'primary', text: "⏱ Rest-Pause", description: "Kurze Pausen zwischen den Wiederholungen.", emoji: "⏱" },
        superSet: { color: 'primary', text: "💪 Supersatz", description: "Kombination von zwei Übungen ohne Pause.", emoji: "💪" },
      },
      exerciseCombinations: {
        superSet: { color: 'orange', text: "💪 Supersatz", description: "Kombination von zwei Übungen ohne Pause.", emoji: "💪" },
        circleTraining: { color: 'blue', text: "Zirkeltraining", description: "Kombination aus zwei oder mehreren Übungen die nacheinander ausgeführt werden.", emoji: "💪" },

      },
      selectedExerciseKey: 0,
      editDialog: false,
      dropzoneWidth: "0",
      dropzoneHeight: "0",
      dialogStyles: {
        top: "0",
        left: "0",
      },
      exerciseSearch: '',
      onDragIndex: null,
      tags: null,
      tagColors: [
        "#00963F", // Startfarbe (Grün)
        "#009655",
        "#009660",
        "#009676",
        "#009681",
        "#009696",
        "#009570", // Türkis beginnt
        "#00957A",
        "#009584",
        "#009584",
        "#00958E",
        "#009598",
        "#00909C", // Türkisfarbe
        "#008BB5", // Übergang zu Blau
        "#0088BB", // Blau beginnt
        "#0084C2",
        "#007FC9",
        "#007FC9",
        "#007BC6",
        "#0073B6",
        "#006BA5",
        "#006BA5",
        "#006395",
        "#005B84",
        "#416ABC", // Endfarbe (Blau-Violett)
      ],
      interactablePlaceholders: [], // To store placeholder interactions
      interactableExercises: [], // To store placeholder interactions
      interactableDraggables: null,
      selectedTags: [],
      exercises: [],
      selectedSplit: 0, // Default set to the first button
      placeholderIndex: 1,
      placeHolderHeight: 0,
      draggedIndex: null,
      dialogOrigin: null,
      isDragging: false, // Flag to track if an item is being dragged
      editMode: false, // Track if edit mode is active
      selectedExercise: {}, // Store the exercise being edited
      selectedExerciseIndex: null,
    };
  },
  computed: {
    ...mapGetters("deviceStore", ["exerciseList"]),
    filteredExercises: function () {
      return this.exercises.filter((exercise) => {
        if (exercise.deleted) return false
        if (exercise.deviceCategory === '(ALT) MedX Kraftgeräte') return false

        // if (!exercise.studios.includes(this.studio_id.toString())) return false
        if (!this.exerciseSearch.length && !this.selectedTags.length) return true
        let exerciseTags = exercise.tags.map((tag) => tag.class)
        if (
          exerciseTags.some((tag) => this.selectedTags.includes(tag)) ||
          !this.selectedTags.length
        ) {
          let foundSth = 0
          let found = false
          if (exercise.title.toLowerCase().includes(this.exerciseSearch.toLowerCase())) found = true
          if (exercise.deviceTitle.toLowerCase().includes(this.exerciseSearch.toLowerCase())) found = true
          if (exercise.deviceShortForm.toLowerCase().includes(this.exerciseSearch.toLowerCase())) found = true
          return found
        }
      })
    },
    selectedExercisesWithPlaceholders() {
      // If not dragging, return selectedExercises as is

      const exercisesWithPlaceholders = [{ isPlaceholder: true }];

      for (const [index, item] of this.form.splits.workouts[this.selectedSplit].exercises.entries()) {
        exercisesWithPlaceholders.push({ ...item, originalIndex: index });
        exercisesWithPlaceholders.push({ isPlaceholder: true });
      }
      return exercisesWithPlaceholders;
    },
  },
  watch: {
    selectedExercisesWithPlaceholders(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.initializeDragAndDrop();
        });
      }
    },
  },
  async created() {
    this.fetchExercises();
    this.tags = await api.fetchExerciseTags();
  },
  mounted() {
    // Initialize highlight position and draggable items after DOM is rendered
    this.initialize()
  },
  methods: {
    ...mapActions("deviceStore", ["setDeviceList"]),
    test(item) {
      console.log(item)
    },
    initialize() {
      this.$nextTick(() => {
        this.updateHighlight(this.selectedSplit);
      });
    },
    saveExercise(exercise) {
      this.$set(this.form.splits.workouts[this.selectedSplit].exercises, this.selectedExerciseIndex - 1, JSON.parse(JSON.stringify(exercise)))
      this.selectedExercise = null
      this.selectedExerciseIndex = null
      this.cancelEdit()
    },
    removeExercise(index) {
      this.form.splits.workouts[this.selectedSplit].exercises.splice(index, 1)
    },
    edit(item, index) {
      console.log("triggered edit", item);
      this.editMode = true;
      this.interactableDraggables.unset();
      this.interactablePlaceholders.forEach(interaction => interaction.unset());

      this.selectedExerciseIndex = index
      this.selectedExercise = { ...item };
    },
    cancelEdit() {
      this.editMode = false;
      this.selectedExercise = {};
      this.selectedExerciseIndex = null
      this.initialize()
    },

    activateDraggableItems() {
      const vm = this;

      // Unset existing interactions to prevent duplicates
      if (this.interactableDraggables) {
        this.interactableDraggables.unset();
      }

      this.interactableDraggables = interact(
        ".draggable-item, .draggable-selected"
      )
        .draggable({
          allowFrom: ".drag-handle", // Only allow dragging from elements with this class
          inertia: true,
          autoScroll: {
            container: document.querySelector(".dropzone"),
          },
          listeners: {
            move: this.dragMoveListener,
            end(event) {
              event.target.style.transform = "translate(0, 0)";
              event.target.removeAttribute("data-x");
              event.target.removeAttribute("data-y");
            },
          },
        })
        .on("dragstart", (event) => {
          event.target.classList.add("dragging");
          vm.isDragging = true;

          if (event.target.classList.contains("draggable-selected")) {
            vm.draggedIndex = parseInt(event.target.getAttribute("data-index"));
          }
        })
        .on("dragend", (event) => {
          event.target.classList.remove("dragging");
          vm.placeholderIndex = -1;
          vm.isDragging = false;
          vm.draggedIndex = null;
          vm.onDragIndex = null;
        });
    },
    fetchExercises() {
      if (!this.exerciseList.length) {
        api.fetchDevices().then((data) => {
          this.setDeviceList(data);
          this.exercises = this.exerciseList;
        });
      } else {
        this.exercises = this.exerciseList;
      }
      this.$emit("triggerKey");
    },
    getImageForExercise(exercise) {
      if (exercise.picture) return this.getImageUrl(exercise.picture)
      if (exercise.manual && exercise.manual[0] && exercise.manual[0].picture)
        return this.getImageUrl(exercise.manual[0].picture)
      return '/img/uebungen/einmalig/imageUploadPlaceholder.jpg'
    },
    getImageUrl(image) {

      return "/img/uebungen/" + image.replace(/\.[^/.]+$/, "") + ".webp";
    },
    toggleSelectedTag(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter(
          (selectedTags) => selectedTags !== tag
        );
      } else {
        this.selectedTags.push(tag);
      }
    },
    updateHighlight(index) {
      // Move highlight
      const btns = this.$el.querySelectorAll(".custom-btn");
      const highlight = this.$refs.highlight;

      if (btns.length > 0) {
        const selectedBtn = btns[index];
        const offsetLeft = selectedBtn.offsetLeft;
        const btnWidth = selectedBtn.offsetWidth;

        // Adjust highlight
        highlight.style.width = `${btnWidth}px`;
        highlight.style.left = `${offsetLeft}px`;
      }

      this.activateDraggableItems();
      this.initializeDragAndDrop();

    },
    initializeDragAndDrop() {
      const vm = this;
      console.log("*beep* *beep* initialisierung *beep*");
      // Clean up existing interactions

      interact.dynamicDrop(true);

      this.interactablePlaceholders.forEach((interaction) =>
        interaction.unset()
      );
      this.interactablePlaceholders = [];
      // Clean up existing interactions
      this.interactableExercises.forEach((interaction) => interaction.unset());
      this.interactableExercises = [];

      // Setup dropzones on placeholders
      this.$nextTick(() => {
        const placeholderElements =
          vm.$el.querySelectorAll(".placeholder-item");
        placeholderElements.forEach((element) => {
          const interaction = interact(element).dropzone({
            accept: ".draggable-item, .draggable-selected, .drag-handle",
            overlap: 0.1,
            autoScroll: true,
            ondropactivate(event) {
              event.target.classList.add("drop-active");
            },
            ondragenter(event) {
              vm.onDragIndex = event.target.getAttribute("data-id");
              event.target.classList.add("drop-target");
            },
            ondragleave(event) {
              vm.placeHolderHeight = 0
              event.target.classList.remove("drop-target");
            },
            ondrop(event) {
              const draggedId = event.relatedTarget.getAttribute("data-id");
              const source = event.relatedTarget.getAttribute("data-source");
              const index =
                vm.placeholderIndex !== null
                  ? vm.placeholderIndex
                  : vm.form.splits.workouts[vm.selectedSplit].exercises.length;

              if (source === "exercises") {
                const droppedExercise = JSON.parse(
                  JSON.stringify(
                    vm.exercises.find((ex) => ex._id === draggedId)
                  )
                );
                if (droppedExercise) {
                  const indexOfPlaceholder = event.target.getAttribute('data-index')
                  const positionInOriginal = Math.floor(indexOfPlaceholder / 2);
                  vm.form.splits.workouts[vm.selectedSplit].exercises.splice(positionInOriginal, 0, droppedExercise);
                }
              } else if (source === "selectedExercises") {
                const fromIndex = vm.draggedIndex;
                if (fromIndex !== null && fromIndex !== index) {
                  const movedExercise = vm.form.splits.workouts[vm.selectedSplit].exercises.splice(
                    fromIndex,
                    1
                  )[0];
                  vm.form.splits.workouts[vm.selectedSplit].exercises.splice(index, 0, movedExercise);
                }
              }
              vm.placeholderIndex = -1;
            },
            ondropdeactivate(event) {
              event.target.classList.remove("drop-active");
              event.target.classList.remove("drop-target");
              vm.placeholderIndex = -1;
            },
          });

          // Store the interaction so it can be unset later
          vm.interactablePlaceholders.push(interaction);
        });
      });
      // Setup dropzones on placeholders
      this.$nextTick(() => {
        const draggableExercises = vm.$el.querySelectorAll(
          ".draggable-selected"
        );
        draggableExercises.forEach((element) => {
          const interaction = interact(element).dropzone({
            accept: ".draggable-item, .draggable-selected, .drag-handle",
            overlap: 0.1,
            ondropactivate(event) {
              event.target.classList.add("drop-active");
            },
            ondragenter(event) {
              vm.placeholderIndex = event.target.getAttribute("data-index");
              vm.placeHolderHeight = event.target.offsetHeight
              event.target.classList.add("drop-target-selectedexercise");
            },
            ondragleave(event) {
              console.log("left");
              event.target.classList.remove("drop-target-selectedexercise");
            },
            ondrop(event) {
              console.log('ouch you dropped on me', event)
              const draggedId = event.relatedTarget.getAttribute("data-id");
              const source = event.relatedTarget.getAttribute("data-source");
              const index =
                vm.placeholderIndex !== null
                  ? vm.placeholderIndex
                  : vm.form.splits.workouts[vm.selectedSplit].exercises.length;

              if (source === "exercises") {
                const droppedExercise = JSON.parse(
                  JSON.stringify(
                    vm.exercises.find((ex) => ex._id === draggedId)
                  )
                );
                const droppedOn = vm.form.splits.workouts[vm.selectedSplit].exercises[event.target.getAttribute("id")]

                if (droppedExercise && droppedOn) {
                  if (

                    vm.form.splits.workouts[vm.selectedSplit].exercises[event.target.getAttribute("id")].exercises
                  ) {
                    vm.form.splits.workouts[vm.selectedSplit].exercises[event.target.getAttribute("id")].exercises.push(droppedExercise)
                    vm.form.splits.workouts[vm.selectedSplit].exercises[event.target.getAttribute("id")].type = 'circleTraining'
                  } else {

                    vm.form.splits.workouts[vm.selectedSplit].exercises[event.target.getAttribute("id")] = { type: 'superSet', exercises: [droppedExercise, droppedOn] }
                  }
                }

                if (droppedExercise && !droppedOn) {
                  vm.form.splits.workouts[vm.selectedSplit].exercises.splice(1, 0, droppedExercise)
                }
              } else if (source === "selectedExercises") {
                const fromIndex = vm.draggedIndex;
                if (fromIndex !== null && fromIndex !== index) {
                  const movedExercise = vm.form.splits.workouts[vm.selectedSplit].exercises.splice(
                    fromIndex,
                    1
                  )[0];
                  vm.form.splits.workouts[vm.selectedSplit].exercises.splice(index, 0, movedExercise);
                }
              }
              vm.placeholderIndex = -1;
            },
            ondropdeactivate(event) {
              event.target.classList.remove("drop-active");
              event.target.classList.remove("drop-target");
              vm.placeholderIndex = -1;
            },
          });

          // Store the interaction so it can be unset later
          vm.interactablePlaceholders.push(interaction);
        });
      });

      // Unset existing interactions to prevent duplicates
    },

    dragMoveListener(event) {
      const target = event.target;
      // Keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // Translate the element
      target.style.transform = `translate(${x}px, ${y}px)`;

      // Update the position attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-exercises-wrapper {
  margin: 15px 0;
  position: relative;
  padding: 20px;

}

.multiple-exercises-wrapper--superset {
  border: 2px dashed orange;
}

.multiple-exercises-wrapper--circletraining {
  border: 2px dashed #2196F3 !important;
}

.exercise-wrapper-chip {
  position: absolute;
  top: -16px;
  right: 0;
  left: 0;
  width: fit-content;
  margin: auto;
}

.show-placeholder {
  border: 2px dashed #aaa;

  opacity: 0.7 !important;
  padding: 2px 0 !important;
  transition: all 0.5s;
  height: 32px !important;
}

.button-switcher {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.custom-toggle {
  background: transparent !important;
  position: relative;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.button-container {
  display: flex;
  flex-wrap: nowrap;
}

.custom-btn {
  border: 3px solid white;

  flex: 0 0 auto;
  color: #d7d7d7;
  font-weight: bold;
  transition: color 0.3s;
  text-align: center;
}

.highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--v-primary);
  border-radius: 8px;
  transition: left 0.3s ease, width 0.3s ease;
}

.custom-toggle--active {
  color: #fff !important;
}

.workoutAddExercise {
  &__list-item {
    height: 76px;
    overflow: hidden;

    .v-list-item__title {
      font-weight: bold;
      margin-bottom: 3px;
    }

    &-image {
      height: 100%;

      &__container {
        height: 100%;
        width: 40%;
      }
    }
  }
}

/* Styles for Interact.js drag-and-drop */
.draggable-item,
.draggable-selected {
  touch-action: none;
  cursor: move;
}

.dragging {
  opacity: 0.5;
  z-index: 100000;
}

.dropzone {
  overflow: auto;
  max-height: 60vh;
  min-height: 80px;
  border: 2px dashed white;
  margin-top: 10px;
  padding: 10px;
  position: relative;
}

.drop-active {
  border-color: blue;
}

.drop-target {
  color: black;
  background-color: #f0f0f0 !important;
}

.drop-target-selectedexercise {
  opacity: 0.5;
}

.can-drop {
  border-color: green;
}

.placeholder-item {
  background-color: var(--v-primary);
  text-align: center;
  padding: 0;
  opacity: 0;
  height: 0px;
  transition: all 0.3s;
}

.invalid-drop {
  border: 2px solid red;
}

.drop-on-exercise {
  background: red !important;
  border: 5px dashed violet !important;
}

.slow-transition {
  transition-duration: 1000 !important;
}
</style>
