<template>
    <v-row justify="center">
        <component
            :is="noDialog ? 'div' : 'v-dialog'"
            :class="{ 'image-overlapping-card--component': noDialog, 'set-height-100': height100 }"
            persistent
            content-class="dialog"
            :max-width="dialogMaxWidth"
            v-model="dialog"
            :fullscreen="dialogFullScreen"
            :hide-overlay="dialogFullScreen"
            :style="!dialogFullScreen ? 'border-radius: 50px !important' : ''"
            scrollable
            :transition="noDialog ? '' : 'dialog-bottom-transition'">
            <div class="image-overlapping-card--wrapper-outer">
                <!--  
                    :style="'background: ' + backgroundColor"
                -->
                <div
                    :class="{ fullScreenPBottom: headerImageActive }"
                    class="image-overlapping-card--wrapper-inner white"
                    :style="'max-width: ' + dialogMaxWidth">
                    <div
                        v-if="headerImageActive"
                        class="position-fixed image-overlapping-card-header-image"
                        :style="'max-width: ' + dialogMaxWidth + ' !important'">
                        <slot v-if="!headerImage" name="image"></slot>
                        <v-img height="35vh" v-if="headerImage" :src="headerImage"></v-img>
                    </div>
                    <v-card
                        :class="{ imageMargin: headerImageActive }"
                        :style="headerImageActive ? 'margin-top: ' + innerCardTopMargin : ''"
                        class="image-overlapping-card mt-40 elevation-0">
                        <v-btn
                            v-if="returnRoute || !changeRoute"
                            class="image-overlapping-card--container-back"
                            fab
                            dark
                            color="white"
                            @click="returnToRoute">
                            <v-icon color="black">mdi-chevron-left</v-icon>
                        </v-btn>

                        <v-row no-gutters class="ma-0 image-overlapping-card--container flex-column">
                            <div class="image-overlapping-card--container-left" v-if="leftIcon">
                                <v-btn
                                    class="image-overlapping-card--container-left-btn"
                                    fab
                                    dark
                                    :color="leftIcon.background ? leftIcon.background : 'white'"
                                    @click="$emit('left-clicked')">
                                    <v-icon :color="leftIcon.color">{{ leftIcon.icon }}</v-icon>
                                </v-btn>
                            </div>
                            <div class="image-overlapping-card--container-right" v-if="rightIcon">
                                <v-btn
                                    class="image-overlapping-card-right-btn"
                                    fab
                                    dark
                                    :color="rightIcon.background ? rightIcon.background : 'white'"
                                    @click="$emit('right-clicked')">
                                    <v-icon :color="rightIcon.color">{{ rightIcon.icon }}</v-icon>
                                </v-btn>
                            </div>

                            <v-row
                                :class="{ fullScreen, contentMargin: headerImageActive }"
                                no-gutters
                                class="ma-0 image-overlapping-card-content">
                                <slot name="content"></slot>
                            </v-row>

                            <!--  Do muen denn de ganz content f  sdfsdfine -->
                        </v-row>
                    </v-card>
                </div>
            </div>
        </component>
    </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VDialog } from 'vuetify/lib'

export default {
    name: 'imageOverlappingCard',
    components: {
        VDialog,
    },
    props: {
        dialogFullScreen: {
            default: true,
        },
        dialogMaxWidth: {
            default: '1040px',
        },
        backgroundColor: {
            default: 'white',
        },
        innerCardTopMargin: {
            default: '35vh !important',
        },
        setFixed: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        headerImage: {
            type: String,
        },
        height100: {
            type: Boolean,
            default: false,
        },
        headerImageActive: {
            type: Boolean,
            default: true,
        },
        fullScreen: {
            type: Boolean,
            default: false,
        },
        returnRoute: {
            type: String,
            required: false,
        },
        changeRoute: {
            type: Boolean,
            default: true,
            required: false,
        },
        returnCallback: {
            type: Boolean,
            default: false,
        },
        leftIcon: {
            type: Object,
            required: false,
            default: null,
        },
        rightIcon: {
            type: Object,
            required: false,
            default: null,
        },
        noDialog: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dialog: true,
        }
    },
    created() {
        this.dialog = this.visible
    },
    computed: {
        ...mapState('navigationStore', ['preSelectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
    },
    methods: {
        ...mapActions('navigationStore', ['setSelectedNavigation']),
        toggleDialog(val) {
            /* 
				let preselectedNav = JSON.parse(JSON.stringify(this.preSelectedNavigation))
			let activenav = JSON.parse(JSON.stringify(this.selectedNavigation))
			if (this.returnRoute === '-1') {
				this.setSelectedNavigation(preselectedNav)
			} else {
				this.setSelectedNavigation(activenav)
				this.$router.push({ name: this.returnRoute })
			} */
            this.returnRoute === '-1'
                ? this.setSelectedNavigation(this.preSelectedNavigation) && this.$router.go(-1)
                : this.$router.push({ name: this.returnRoute })
        },

        returnToRoute() {
            if (this.changeRoute) {
                this.dialog = false
                setTimeout(this.toggleDialog, 100, false)
            } else {
                this.$emit('change-visibility', false)
            }

            if (this.returnCallback) {
                this.$emit('return-clicked')
            }
        },
    },
    watch: {
        visible(val) {
            this.dialog = val
        },
    },
}
</script>
<style lang="scss" scoped>
.fullScreen {
    margin: 0px !important;
    padding: 0px !important;
}
.fullScreenPBottom {
    padding-bottom: 100px;
}
::v-deep .dialog {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.imageMargin {
    margin-top: 35vh !important;
    z-index: 999;
}
.contentMargin {
    margin-top: -50px !important;
}
.image-overlapping-card {
    max-width: 100vw;

    &--component {
        width: 100%;
        height: 100%;
        position: fixed;
    }
    &--wrapper-outer {
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &--wrapper-inner {
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    &-header-image {
        min-height: 35vh;
        background-color: white;
        width: 100%;
        z-index: 999;
    }
    &--container {
        position: relative;
        &-back {
            position: fixed;
            top: 20px;
            left: 11%;
            z-index: 3;
        }
        &-left {
            position: absolute;
            top: -80px;
            left: 11%;
            z-index: 2;
        }
        &-right {
            position: absolute;
            top: -80px;
            right: 11%;
            z-index: 2;
        }
    }
    &-content {
        flex-direction: column;

        width: 100%;

        z-index: 1;
        background-color: white;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        padding-top: 10px;
    }
}

.set-height-100 {
    height: 100% !important;
    max-height: 100% !important;
}
</style>
