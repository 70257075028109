<template>
  <v-form v-model="valid" ref="reportProblemForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="form.user.firstname" :rules="nameRules" :counter="15" label="Vorname" required></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="form.user.lastname" :rules="nameRules" :counter="15" label="Nachname" required></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="form.user.email" :rules="emailRules" label="E-Mail" required></v-text-field>
        </v-col>
        <div v-for="(problem, i) of form.problems" :key="i" class="reportProblemForm__problem">
          <v-col cols="12" md="4">
            <v-select v-model="problem.category" :items="problemItems" :rules="[(v) => !!v || 'Kategorie wird benötigt']" label="Kategorie" required></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select v-model="problem.type" :items="typeItems" :rules="[(v) => !!v || 'Typ wird benötigt']" label="Typ" required></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="problem.title" :rules="titleRules" label="Titel" required></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-textarea v-model="problem.description" :rules="descRules" label="Beschreibung" required></v-textarea>
          </v-col>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="primary" color="white" icon @click="addNewProblem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="primary" color="white" @click="submitProblemForm">
            Absenden
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import api from '../../../api'

export default {
  name: 'reportProblemForm',
  props: {
    value: {
      default: null,
    },
  },
  data: () => ({
    user: null,
    valid: false,
    problemItems: ['News', 'Freundesfunktion', 'Weiterempfehlungen', 'Kursplan', 'Kurse', 'Trainingsplan', 'Übungen', 'Archiv', 'Livestream', 'Kampagne', 'Weiteres'],
    typeItems: ['Problem', 'Anpassungsvorschlag ', 'Erweiterungsvorschlag'],
    defaultProblem: {
      type: null,
      category: null,
      title: '',
      description: '',
    },
    form: {
      user: {
        firstname: '',
        lastname: '',
        email: '',
      },
      problems: [],
    },
    descRules: [(v) => !!v || 'Beschreibung ist erforderlich', (v) => v.length >= 30 || 'Die Beschreibung muss mindestens 30 Zeichen enthalten'],
    titleRules: [(v) => !!v || 'Titel ist erforderlich', (v) => v.length >= 5 || 'Der Titel muss mindestens 5 Zeichen enthalten'],
    nameRules: [(v) => !!v || 'Name ist erforderlich', (v) => v.length <= 15 || 'Der Name muss weniger als 15 Zeichen enthalten'],
    emailRules: [(v) => !!v || 'E-Mail ist erforderlich', (v) => /.+@.+/.test(v) || 'E-Mail muss gültig sein'],
  }),
  created() {
    api.getUser().then((user) => {
      this.user = user.user
      this.form.user.firstname = this.user.vorname
      this.form.user.lastname = this.user.nachname
      this.form.user.email = this.user.email
    })
    this.problemItems.sort()
    this.form.problems.push(JSON.parse(JSON.stringify(this.defaultProblem)))
  },
  methods: {
    submitProblemForm() {
      this.$refs.reportProblemForm.validate() && this.$emit('submitForm', this.form)
    },
    validateProblems() {
      this.$refs.reportProblemForm.validate() && this.submitProblemForm()
    },
    addNewProblem() {
      this.$refs.reportProblemForm.validate() && this.form.problems.push(JSON.parse(JSON.stringify(this.defaultProblem)))
    },
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
.reportProblemForm {
  &__problem {
    width: 100%;
  }
}
</style>
