<template>
	<div class="helsana">
		<img src="https://app.wellcomefit.ch/img/einmalig/helsana.jpg" class="helsana_header" />
		<span class="titel">Als Mitglied von {{ getArt() }} fahren Sie beim Versicherungsschutz einfach besser</span>
		<span class="untertitel">Sehr geehrte Sportbegeisterte</span>
		<span class="text">
			Wenn es um ihre Gesundheit geht, sollten sie alle Möglichkeiten ausschöpfen können. Dazu braucht es einen Versicherungsschutz, der
			zeitgemässe Behandlungsmethoden einschliesst und eine gesunde Lebensweise belohnt. Wie die Rundum-Zusatzversicherung COMPLETA von Helsana,
			die keine Lücken offenlässt. Zum Beispiel deckt sie 75% der Kosten für Therapien der Komplementärmedizin und leistet grosszügige Beiträge
			an Fitnessabonnements, Gesundheitskurse und weitere gesundheitsfördernde Massnahmen. Wir beraten sie gerne. <br /><br />Es gibt noch einen
			guten Grund mehr, jetzt zu Helsana zu wechseln: Als Mitglied von {{ getArt() }}
			profitieren sie und ihre Familien von 10% Prämienreduktion.
			<br /><br />Entscheiden sie sich jetzt für die Zusatzversicherung mit dem umfassenden Leistungsangebot. Ihrer Gesundheit zuliebe.</span
		>

		<div class="campaign-mitgliedervorteil">
			<span class="mitglieder-rabatt">10% Rabatt für Dich und Deine Famile </span>
		</div>

		<a
			class="link"
			title="Jetzt Beratung anfordern"
			href="https://www.helsana.ch/de/meta/kvp/well-come-fit.html?utm_source=email&utm_medium=newsletter&utm_campaign=2021_05_nka_vvg_01&utm_term=de_fitag_kvg_newsletter&utm_content=completa"
			target="blank"
			>Jetzt Beratung anfordern</a
		>
	</div>
</template>
<script>
export default {
	data() {
		return {}
	},
	methods: {
		getArt() {
			if (location.host.includes('local') || location.host.includes('wellcomefit')) {
				return 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				return 'winti FIT'
			}
			if (location.host.includes('fzw')) {
				return 'Fitness Zenter Wetzikon'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.helsana_header {
	max-width: 430px;
	width: 100vw;
	padding-bottom: 20px;
}
.text {
	padding: 0px 10px;
}
.helsana {
	max-width: 400px;
	margin: auto auto auto auto;

	display: flex;
	flex-direction: column;
}
.link {
	width: 90vw;
	margin: 25px auto 30px auto;
	text-align: center;
	background-color: var(--v-primary);
	max-width: 400px;
	border-radius: 5px;
	line-height: 60px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	text-decoration: none;
}
.untertitel {
	font-size: 18px;
	font-weight: 700;
	padding: 0px 10px;
	margin-bottom: 5px;
}
.campaign-mitgliedervorteil {
	text-align: center;
	width: 90vw;
	font-weight: 700;
	max-width: 400px;
	padding: 0 10px;
	border: 3px solid #c93131;
	border-radius: 15px;
	background-color: #fff540;
	color: #c93131;
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	margin: 20px auto 0px auto;

	span {
		font-size: 19px;
		line-height: 30px;
		margin-top: 3px;
	}
}
.titel {
	font-size: 20px;
	padding: 0px 10px;
	font-weight: 700;
	/* text-align: center; */
	/* padding: 10px 10px 0 10px; */
	margin-bottom: 14px;
}
</style>
