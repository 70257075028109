// websocketPlugin.js

const eventBus = {
    listeners: {},
    $on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    },
    $off(event, callback) {
        if (!this.listeners[event]) {
            return;
        }
        if (!callback) {
            // Remove all listeners for the event
            this.listeners[event] = [];
        } else {
            // Remove specific callback for the event
            const index = this.listeners[event].indexOf(callback);
            if (index !== -1) {
                this.listeners[event].splice(index, 1);
            }
        }
    },
    $emit(event, ...args) {
        if (!this.listeners[event]) {
            return;
        }
        this.listeners[event].forEach(callback => {
            callback(...args);
        });
    }
};

const WebSocketPlugin = {
    install(Vue) {
        console.log("installing websocket")

        let server = location.hostname.includes('local') ? 'ws://localhost:4000' : 'wss://app.wellcomefit.ch'

        let socket = null;
        let reconnectAttempts = 0;
        const maxReconnectAttempts = 5;
        const reconnectDelay = 1000; // milliseconds

        const connectWebSocket = () => {
            console.log("connecting to websocket")
            socket = new WebSocket(server + '/api/websocket');

            socket.addEventListener('open', () => {
                let token = localStorage.getItem('token');
                if (token) {
                    socket.send(JSON.stringify({
                        isAuthentication: true,
                        token,
                    }));
                    Vue.prototype.$websocketAuthenticationStarted = true;
                }
                Vue.prototype.$websocket = socket;
                Vue.prototype.$websocketConnected = true;
                reconnectAttempts = 0; // Reset the reconnect attempts counter upon successful connection
            });

            socket.addEventListener('close', () => {
                console.log("connection closed")
                Vue.prototype.$websocketConnected = false;

                if (reconnectAttempts < maxReconnectAttempts) {
                    const reconnectTimeout = Math.pow(2, reconnectAttempts) * reconnectDelay;
                    reconnectAttempts++;
                    console.log("trying to reconnect to websocket in " + reconnectTimeout + "ms")

                    // Attempt to reconnect after the calculated timeout
                    setTimeout(connectWebSocket, reconnectTimeout);
                } else {
                    console.log("Reached maximum reconnect attempts. Connection could not be established.");
                    // Handle the case where the maximum number of reconnect attempts is reached
                }
            });

            socket.addEventListener('message', (event) => {
                let message = JSON.parse(event.data);
                if (message.authenticationStatus) {
                    Vue.prototype.$websocketAuthenticationStarted = false;
                    if (message.authenticationStatus === 'isAuthenticated')
                        Vue.prototype.$websocketAuthenticationStatus = false;
                    if (message.authenticationStatus === 'authenticationFailed')
                        Vue.prototype.$websocketAuthenticationStatus = false;
                }
                eventBus.$emit('websocketMessage', message);
            });

            socket.addEventListener('error', (error) => {
                console.error("WebSocket error:", error);
                // Handle WebSocket error 
            });
        };

        // connectWebSocket();

        // Vue.prototype.$connectWebsocket = connectWebSocket();

        Vue.prototype.$sendMessage = (message) => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                if (message.isAuthentication) {
                    let token = localStorage.getItem('token');
                    if (token) {
                        message.token = token;
                    }
                }
                socket.send(JSON.stringify(message));
            }
        };
    },
};

export { eventBus };
export default WebSocketPlugin;
