<template>
	<div class="aktuelles">
		<v-col cols="12" lg="6" xl="4" class="aktuelles__card-wrapper mb-5" v-show="!n.titel.includes('[hidden]')" v-for="(n, ni) of news" :key="ni">
			<v-card class="aktuelles__card rounded-lg elevation7">
				<v-row>
					<v-col class="pt-0" cols="12" v-show="n.foto">
						<v-img class="aktuelles__card-image" :src="'/img/news/' + n.foto" />
					</v-col>
					<v-col>
						<v-card-title style="word-break: break-word" v-show="n.titel"> {{ format(n.titel) }} </v-card-title>
						<div class="aktuelles__card-content px-5" v-show="n.inhalt" v-html="format(n.inhalt)"></div>
					</v-col>
					<v-col cols="12">
						<v-card-actions class="justify-center">
							<v-btn color="primary" text @click="activeNews = n">mehr lesen...</v-btn>
						</v-card-actions>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<aktuelles-reading-view v-if="activeNews" :news="activeNews" @resetActiveNews="activeNews = null"></aktuelles-reading-view>
	</div>
</template>

<script>
import api from '../api'
import aktuellesReadingView from './aktuellesReadingView.vue'

export default {
	components: { aktuellesReadingView },
	data() {
		return {
			news: {},
			activeNews: null,
			studio_info: {},
		}
	},
	methods: {
		getImageKampagne() {
			let ret = false
			if (location.host.includes('localhost')) {
				ret = 'https://app.wellcomefit.ch/img/kampagne/weiterempfehlung/zehntage_BS_quer.jpg'
			}
			if (location.host.includes('wellcomefit')) {
				ret = '/img/kampagne/weiterempfehlung/zehntage_BS_quer.jpg'
			}
			if (location.host.includes('wintifit')) {
				ret = '/img/kampagne/weiterempfehlung/winti_zehntage_BS_quer.jpg'
			}

			return ret
		},
		format(i) {
			if (i.length > 100) i = i.substring(0, 100) + '...'
			return i.split('{{studio}}').join(this.getArt())
		},
		getArt() {
			let ret = false
			if (location.host.includes('localhost')) {
				ret = 'well come FIT'
			}
			if (location.host.includes('wellcomefit')) {
				ret = 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				ret = 'winti FIT'
			} else if (location.host.includes('fzw')) {
				ret = 'FZW'
			}
			return ret
		},
		loadNews() {
			api.getNews()
				.then((data) => {
					this.news = data

					window.app.$root.$children[0].newNewsCount()
				})
				.catch((error) => {
					console.log(error)
				})
		},
	},
	created() {
		this.loadNews()
	},
}
</script>

<style lang="scss" scoped>
.aktuelles {
	&__card {
		width: 90%;
		max-width: 600px;

		&-wrapper {
			text-align: -webkit-center;
			text-align: -moz-center;
		}

		&-image {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&-content {
			text-align: left;
			text-overflow: ellipsis;
		}
	}
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
