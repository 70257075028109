<template>
	<div class="overflow-auto">
		<v-expansion-panels class="d-flex flex-column" v-model="expansionPanels" :accordion="false">
			<div class="d-flex flex-column align-items-center justify-space-between">
				<v-card-title class="primary--text">Gruppenrecht Erstellen</v-card-title>
				<v-btn v-if="!creator" class="mt-2 createUserRole__create-button" color="primary" @click="openRoleCreator">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</div>
			<v-expansion-panel v-show="creator" :readonly="expansionPanels === 0">
				<v-expansion-panel-header>
					<v-text-field v-model="newRole.title"></v-text-field>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="createUserRole__expansion-panel">
					<div v-for="(right, index) in newRole.rights" :key="index" class="d-flex flex-row justify-space-between">
						<span>({{ right.topic }}) {{ right.right.title }}</span>
						<v-checkbox class="ma-0" v-model="right.active"></v-checkbox>
					</div>
				</v-expansion-panel-content>
				<div class="mt-4 mx-5 d-flex flex-row justify-space-between createUserRole__expansion-panel-actions">
					<v-btn outlined color="grey" @click="cancelRoleCreator">abbrechen</v-btn>
					<v-btn color="primary" @click="createNewUserRole">speichern</v-btn>
				</div>
			</v-expansion-panel>

			<v-card-title v-if="!creator" class="primary--text justify-content-center">Gruppenrechte verwalten</v-card-title>
			<v-expansion-panel
				v-show="!creator"
				v-for="(userRole, index) in userRoles"
				:key="userRole._id"
				:readonly="expansionPanels === index + 1"
				class="d-flex flex-column justify-space-between"
			>
				<v-expansion-panel-header>
					<v-text-field v-model="userRole.title"></v-text-field>
				</v-expansion-panel-header>

				<v-expansion-panel-content
					class="createUserRole__expansion-panel d-flex flex-column"
					:class="{ displayNone: expansionPanels !== index + 1 }"
				>
					<div v-for="(right, index) in userRole.rights" :key="index" class="d-flex flex-row justify-space-between">
						<span>({{ right.topic }}) {{ right.title }}</span>
						<v-checkbox
							:disabled="
								user.email !== 'da@wellcmefit.ch' &&
									user.email !== 'sebs@wellcomefit.ch' &&
									user.email !== 'dominic.ammann.ch@gmail.com'
							"
							class="ma-0"
							v-model="right.active"
						></v-checkbox>
					</div>
					<div class="createUserRole__expansion-panel-actions d-flex flex-row text-center mb-3 px-0 mt-2">
						<v-btn
							class="createUserRole__expansion-panel-actions-button py-5"
							x-small
							outlined
							color="grey"
							@click.prevent="cancelRoleEditor"
						>
							abbrechen
						</v-btn>
						<v-btn
							class="createUserRole__expansion-panel-actions-button py-5 white--text mx-2"
							x-small
							color="red"
							disabled
							@click.prevent="deleteRole(userRole._id)"
						>
							löschen
						</v-btn>
						<v-btn
							class="createUserRole__expansion-panel-actions-button py-5"
							x-small
							color="primary"
							@click.prevent="updateExistingRole(userRole)"
						>
							speichern
						</v-btn>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'

export default {
	name: 'createUserRole',
	data() {
		return {
			user: null,
			userService,
			creator: false,
			expansionPanels: [],
			editingRole: false,
			userRoles: [],
			allRights: [],
			createIsReadOnly: false,
			newRole: {
				title: '',
				rights: [],
			},
		}
	},
	created() {
		this.user = this.userService.getUser()
		api.fetchAllRoles().then((data) => (this.userRoles = data))
		this.setNewRoleRights()
	},
	methods: {
		setNewRoleRights() {
			api.fetchAllRights().then((data) => (this.newRole.rights = data))
		},
		cancelRoleEditor() {
			this.closeRoleEditor()
		},
		closeRoleEditor() {
			this.expansionPanels = []
			this.editingRole = false
		},
		updateExistingRole(data) {
			data.rights = data.rights.filter((right) => right.active)
			api.updateExistingUserRole(data).then((response) => console.log(response))
			this.closeRoleEditor()
		},
		/*
    openRoleEditor () {
      this.editingRole = true
      console.log(this.editingRole);
    },
    */

		deleteRole(data) {
			api.deleteUserRole(data)
				.then((response) => {
					this.userRoles = this.userRoles.filter((userRole) => userRole._id !== data)
				})
				.catch((error) => {
					console.error(error)
				})
			this.closeRoleEditor()
		},
		cancelRoleCreator() {
			this.closeRoleCreator()
		},
		closeRoleCreator() {
			this.expansionPanels = []
			this.newRole = {
				title: '',
				rights: [],
			}
			this.creator = false
			this.createIsReadOnly = false
			this.editingRole = false
		},
		openRoleCreator() {
			this.expansionPanels = '0'
			this.setNewRoleRights()
			this.creator = true
			this.createIsReadOnly = true
		},
		createNewUserRole() {
			let copyNewRole = { ...this.newRole }
			this.newRole.rights = this.newRole.rights.filter((right) => right.active).map((right) => right.right._id)
			api.createUserRole(this.newRole)
				.then((data) => this.userRoles.push(copyNewRole))
				.catch((error) => console.error(error))
			this.closeRoleCreator()
		},
	},
}
</script>

<style lang="scss" scoped>
.displayNone {
	display: none !important;
}
.createUserRole {
	&__create {
		&-button {
			width: 20%;
		}
	}
	&__expansion-panel {
		max-height: 30vh;
		overflow: auto;

		&-actions {
			bottom: 1px;
			place-content: center;
		}
	}
}
</style>
