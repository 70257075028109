<template>
	<div class="rollenverwaltung">
		<swiper ref="swiper" :options="swiperOptions">
			<swiper-slide :key="3">
				<div v-if="!showUserEditor">
					<v-card-title class="primary--text">Benutzer suchen (mind. 3 Buchstaben):</v-card-title>
					<v-card-text class="search">
						<div>
							<input placeholder="Suche..." v-on:input="findUsers" type="text" v-model="search" />
							<span class="material-icons">search</span>
						</div>
						<div class="user" v-for="(user, i) of users" :key="i" @click="editrollen(user)">
							<span class="username">{{ user.vorname }} {{ user.nachname }} ({{ user.email }})</span>
							<span class="material-icons">edit</span>
						</div>
					</v-card-text>
				</div>

				<div v-else class="overflow-auto">
					<v-card-title class="primary--text">Benutzer bearbeiten</v-card-title>
					<v-card-text>
						<v-text-field label="Vorname" type="text" v-model="editing.vorname"></v-text-field>
						<v-text-field label="Nachname" type="text" v-model="editing.nachname"></v-text-field>
						<v-text-field label="Geburtstag" type="text" v-model="editing.geburtstag"></v-text-field>
						<v-text-field label="E-Mail" type="email" v-model="editing.email"></v-text-field>
						<v-text-field label="Telefon" type="tel" v-model="editing.telefon"></v-text-field>

						<v-text-field label="Adresse" type="text" v-model="editing.adresse_1"></v-text-field>
						<v-text-field label="PLZ" type="number" v-model="editing.plz"></v-text-field>
						<v-text-field label="Stadt" type="text" v-model="editing.stadt"></v-text-field>

						<v-text-field
							v-if="editing.tendays_date && isAdmin"
							label="10 Tage Startdatum"
							type="number"
							v-model="editing.tendays_date"
						></v-text-field>
						<v-autocomplete
							v-show="userService.hasRight('g_manageRights')"
							v-model="selected.groups"
							:items="roles"
							no-data-text="Es sind keine Gruppenrechte verfügbar"
							label="Gruppenrecht zuweisen"
							chips
							clearable
							deletable-chips
							multiple
							item-value="_id"
							item-text="title"
							small-chips
						></v-autocomplete>

						<v-autocomplete
							v-show="userService.hasRight('g_manageRights')"
							v-model="selected.specialRights"
							:items="specialRightsAutocomplete"
							label="Rechte einzeln zuweisen"
							no-data-text="Dir sind bereits alle verfügbaren Rechte zugewiesen worden."
							chips
							clearable
							deletable-chips
							multiple
							item-value="_id"
							:item-text="getSpecialRightItemText"
							small-chips
						></v-autocomplete>
						<v-checkbox
							label="slim ME Kampagne"
							@click="editing.slimMeUp_date = new Date().toLocaleDateString('en-CA')"
							v-model="editing.slimMeUp"
						></v-checkbox>
						<div class="wrap-cert d-flex flex-column mx-auto" style="max-width: 500px">
							<v-btn
								block
								color="primary"
								class="mx-auto my-2 white--text"
								v-if="editing.covid_cert"
								@click="downloadCert(editing._id)"
							>
								Zertifikat herunterladen
							</v-btn>
							<v-btn block color="red" class="mx-auto my-2  white--text" @click="fakeCert(editing._id)" v-else>
								Kein Zertifikat hochgeladen
							</v-btn>
						</div>
					</v-card-text>
					<v-card-actions class="rollenverwaltung__card__actions flex-wrap">
						<v-btn @click.prevent="cancelEditing" outlined class="schliessen">
							Abbrechen
						</v-btn>

						<v-btn @click.prevent="deleteUser" :disabled="!userService.hasRight('u_delete')" color="red" class="white--text">
							Benutzer löschen
						</v-btn>

						<v-btn @click.prevent="saveEditing" :disabled="!userService.hasRight('u_edit')" color="primary" class="speichern">
							Speichern
						</v-btn>
					</v-card-actions>
					<v-card-actions class="rollenverwaltung__card__actions mt-3 mb-15">
						<v-btn color="orange" v-show="!editing.tendays_date" @click.prevent="createMembercard(editing._id)">
							Membercard für Benutzer erstellen
						</v-btn>

						<v-btn
							text
							class="mb-15"
							color="orange"
							v-show="editing.tendays_date && !editing.abo"
							@click.prevent="hideMembercard(editing._id)"
						>
							Membercard für Benutzer ausblenden
						</v-btn>
					</v-card-actions>
				</div>
			</swiper-slide>
			<swiper-slide v-if="userService.hasRight('g_manageRights')" :key="1">
				<create-user-role></create-user-role>
			</swiper-slide>
		</swiper>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'
import CreateUserRole from './createUserRole'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import userService from '../../services/userService'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
export default {
	components: {
		CreateUserRole,
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	data() {
		return {
			userService,
			newpw: '',
			search: '',
			users: {},
			setpw: false,
			editing_b: {},
			editing: {},
			selected: {
				groups: [],
				specialRights: [],
			},
			roles: [],
			showUserEditor: false,
			specialRights: [],
			swiperOptions: {
				allowTouchMove: true,
				pagination: {
					el: '.swiper-pagination',
					dynamicBullets: true,
				},
			},
		}
	},
	methods: {
		fakeCert(userid) {
			if (confirm('Möchtest ein Zertifikat für den Kunden ausstellen?')) {
				api.fakeCert(userid).then((data) => {
					this.editing.covid_cert = true
				})
			}
		},
		downloadCert(userid) {
			api.downloadCertForUser(userid).then((data) => {
				saveAs(data, 'Covid_cert_' + Math.round(new Date() / 1000))
			})
		},
		getSpecialRightItemText(right) {
			return right.title + ' (' + right.topic + ')'
		},
		hideMembercard(id) {
			if (confirm('Wurde das Abo bereits von dem Kunden unterschrieben? (OK für bereits unterschrieben)')) {
				api.aboLoesen(id).then((data) => {
					if (data.status) {
						this.editing.abo = true
					}
				})
			}
		},
		createMembercard(id) {
			api.createMembercard(id).then((data) => {
				if (data.status == 1) {
					alert('erfolgreich erstellt')
				} else {
					alert('es gab einen Fehler')
				}
			})
		},
		deleteUser() {
			if (
				confirm(
					'Wollen Sie den Benutzer ' +
						this.editing.vorname +
						' ' +
						this.editing.nachname +
						' wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden'
				)
			) {
				if (confirm('Ganz sicher?')) {
					api.deleteUser({ benutzer_id: this.editing._id })
						.then((data) => {
							if (data.status) {
								this.toggleUserEditor()
								this.findUsers()
								alert('Benutzer gelöscht')
							}
						})
						.catch((error) => {
							alert('fehler')
						})
				}
			}
		},
		editrollen(user) {
			if (!this.userService.hasRight('g_manageRights', 'u_edit', 'u_search')) {
				alert('Sie haben nicht genügend Rechte, um diesen Benutzer zu bearbeiten..')
			} else {
				api.fetchAllRoles().then((data) => {
					this.roles = data
					api.fetchAllRights().then((allRights) => {
						this.specialRights = allRights
						api.fetchRolesForUser(user._id).then((userRoles) => {
							this.roles.forEach((right) => {
								if (userRoles.specialRights.length > 0) {
									userRoles.specialRights.forEach((specialRight) => {
										this.selected.specialRights.indexOf(specialRight._id) === -1 &&
											this.selected.specialRights.push(specialRight._id)
									})
								}
								userRoles.userRoles.forEach((role) => {
									if (right._id === role._id) this.selected.groups.push(right._id)
								})
							})
						})
					})
				})
				this.toggleUserEditor()
				this.setpw = false
				this.newpw = ''
				this.editing = user
				if (!user.stat_trainer) {
					this.editing.stat_trainer = false
				}
			}
		},
		findUsers() {
			if (this.search.length > 2) {
				api.searchUserforRolle({ search: this.search })
					.then((data) => {
						this.users = data
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				this.users = {}
			}
		},
		saveEditing() {
			this.editing.userRoles = this.selected.groups
			this.editing.specialRights = this.selected.specialRights

			api.editRolle(this.editing)
				.then((data) => {
					this.toggleUserEditor()
					alert('Benutzer erfolgreich bearbeitet')
					this.resetRights()
				})
				.catch((error) => {
					console.log(error)
				})
		},
		resetRights() {
			this.roles = []
			this.selected = {
				groups: [],
				specialRights: [],
			}
			this.specialRights = []
		},
		toggleUserEditor() {
			this.showUserEditor = !this.showUserEditor
		},
		cancelEditing() {
			this.resetRights()
			this.toggleUserEditor()
		},
	},
	computed: {
		isAdmin: function() {
			return localStorage.getItem('admin')
		},
		specialRightsAutocomplete: function() {
			let filteredSpecialRights = []
			let ignoreRights = []

			let allActiveGroups = this.roles.filter((group) => this.selected.groups.includes(group._id))
			if (allActiveGroups.length) {
				allActiveGroups.forEach((group) => {
					group.rights.forEach((right) => {
						let existingRight = filteredSpecialRights.find((x) => x._id === right._id)
						if (existingRight) {
							if (!existingRight.active && right.active) {
								filteredSpecialRights = filteredSpecialRights.filter((filteredRight) => filteredRight._id !== right._id)
							}
						} else if (!right.active && !ignoreRights.includes(right._id)) {
							filteredSpecialRights.push(right)
						}

						if (right.active) {
							ignoreRights.push(right._id)
						}
					})
				})
				return filteredSpecialRights
			}
		},
		swiper: function() {
			return this.$refs.swiper.$swiper
		},
	},
}
</script>

<style lang="scss" scoped>
.rollenverwaltung__card__actions {
	@media (max-width: 400px) {
		button {
			width: 85%;
			margin: 5px 0px 5px 0px !important;
		}
	}
}
.swiper-pagination {
	bottom: 5px;
}

.rollenverwaltung {
	&__card__actions {
		place-content: center;
	}
}

.rolleneditor {
	overflow: scroll;
	padding: 10vh 10px 20% 10px;

	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	background-color: #fff;
	.setpwi {
		margin-bottom: 50px !important;
	}
	h2 {
		width: 400px;
		margin: 0px auto 20px auto;
	}
	.edit-user-wrap {
		width: 400px;
		margin: auto;
		.setpw {
			padding: 12px;
			font-weight: bold;
			background-color: #443838;
			color: #fff;
			margin-bottom: 30px;
			margin-top: 15px;
			text-align: center;
		}
		input {
			display: flex;
			width: 100%;
			padding: 5px;
			margin-bottom: 5px;
		}
	}
	h4 {
		text-align: center;
		margin-bottom: 30px;
	}
	h5 {
		text-align: center;
	}
	.rolle {
		padding-left: 0px !important;
	}
	.rollen-wrapper {
		max-width: 400px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		padding: 5px;
		input {
			width: 20px;
			height: 20px;
			padding: 10px;
		}
	}

	.tendays {
		background-color: #ff9800;
		color: #fff;
		margin: 35px 0px;
		padding: 15px 15px;
		/* width: 90%; */
		text-align: center;
	}

	.speichern {
		background-color: var(--v-primary);
		color: #fff;
		margin: 20px 0px 5px 0px;
		padding: 15px 15px;
		/* width: 90%; */
		text-align: center;
	}
	.schliessen {
		background-color: #443838;
		color: #fff;
		margin: 5px 0px;
		padding: 15px 15px;
		/* width: 90%; */
		text-align: center;
	}
	.delete {
		background-color: red;
		color: #fff;
		margin: 20px 0px 0px 0px;
		padding: 15px 15px;
		/* width: 90%; */
		text-align: center;
	}
}
label {
	padding-left: 10px;
}
.search {
	display: block;
	height: 50vh;
	overflow: auto;
	position: relative;
	.material-icons {
		position: absolute;
		top: 10px;
		right: 20px;
		color: #4a4a4a;
	}
	input {
		color: #4a4a4a;
		width: 100%;
		padding: 15px 15px 10px 15px;
		border-bottom: 1px solid #4a4a4a;
	}
}
.save {
	color: white;
	width: 130px;
	background-color: var(--v-primary);
	margin: 20px auto;
	text-align: center;
	padding: 10px;
	border-radius: 40px;
}

.user {
	color: #4a4a4a;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	display: flex;
	border-bottom: 1px solid #4a4a4a;

	.username {
		max-width: 80%;
		display: block;
	}
	.material-icons {
		margin-left: auto;
		margin-right: 10px;
		margin-top: auto;
		margin-bottom: auto;
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
