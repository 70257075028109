export function formatCurrency(value, decimalPlaces = 2) {
    // Runde den Wert zuerst auf die gewünschte Anzahl von Nachkommastellen
    const roundedValue = Number(Math.round(value + 'e' + decimalPlaces) + 'e-' + decimalPlaces);

    // Verwende Intl.NumberFormat für die Formatierung
    return new Intl.NumberFormat('de-CH', {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    }).format(roundedValue);
}


export function roundToNearestFiveCentsWithoutFormatting(amount) {
    let decimalPlaces = 2
    // Konvertiere den Betrag zu einem Ganzzahl Cent-Wert
    let cents = Math.round(amount * 100);

    // Finde den Restwert beim Teilen durch 5
    let remainder = cents % 5;

    // Wenn der Rest 1 oder 2 ist, runde ab, indem du den Rest abziehst
    // Wenn der Rest 3 oder 4 ist, runde auf, indem du den Unterschied zu 5 addierst
    if (remainder === 1 || remainder === 2) {
        cents -= remainder;
    } else if (remainder === 3 || remainder === 4) {
        cents += (5 - remainder);
    }

    // Teile durch 100, um den neuen Betrag in Franken zu erhalten
    let roundedAmount = cents / 100;

    // Nutze die bereits vorhandene formatCurrency-Funktion, um das Ergebnis zu formatieren
    return Number(Math.round(roundedAmount + 'e' + decimalPlaces) + 'e-' + decimalPlaces)
}

export function roundToNearestFiveCents(amount) {
    // Konvertiere den Betrag zu einem Ganzzahl Cent-Wert
    let cents = Math.round(amount * 100);

    // Finde den Restwert beim Teilen durch 5
    let remainder = cents % 5;

    // Wenn der Rest 1 oder 2 ist, runde ab, indem du den Rest abziehst
    // Wenn der Rest 3 oder 4 ist, runde auf, indem du den Unterschied zu 5 addierst
    if (remainder === 1 || remainder === 2) {
        cents -= remainder;
    } else if (remainder === 3 || remainder === 4) {
        cents += (5 - remainder);
    }

    // Teile durch 100, um den neuen Betrag in Franken zu erhalten
    let roundedAmount = cents / 100;

    // Nutze die bereits vorhandene formatCurrency-Funktion, um das Ergebnis zu formatieren
    return formatCurrency(roundedAmount, 2);
}
