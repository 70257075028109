<template>
    <div class="d-flex justify-center">test</div>
</template>
   
<script>
import axios from 'axios'
import api from '../../../api'

export default {
    data() {
        return {}
    },
    created() {},
    methods: {},
    computed: {},
}
</script>
  