<template>
    <v-card color="transparent" elevation="0">
        <v-row class="studioSignatures">
            <v-col v-if="userService.hasRight('g_studioadmin')" cols="12" class="my-5">
                <v-btn color="primary" @click="showStudioPicker">Studio wechseln</v-btn>
            </v-col>

            <v-col v-if="studio && !loading" cols="12" class="my-5">
                <v-card-title class="white--text">
                    Studio Unterschrift für:
                    <br />
                    {{ studio.name }}
                </v-card-title>

                <signature-pad
                    :signature-data="signature ? signature : null"
                    @saveSignature="saveSignatureStudio"
                    maxWidth="390px"
                    ref="signatureStudio"
                ></signature-pad>
                <v-btn color="primary" @click="saveSignature">Speichern</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template> 

<script>
import api from '@/api'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import userService from '@/services/userService'

export default {
    name: 'studioSignatures',
    components: { signaturePad },
    data() {
        return {
            loading: true,
            userService,
            studio: null,
            signature: null,
        }
    },
    created() {
        this.fetchStudio()
        this.fetchSignature()
    },
    methods: {
        saveSignature() {
            this.$refs.signatureStudio.saveSignature()
        },
        async fetchSignature() {
            this.loading = true
            try {
                let signature = await api.fetchStudioSignature()
                console.log(signature, 'SIGNATURE')
                this.signature = signature
            } catch (e) {}
            this.loading = false
        },
        async saveSignatureStudio(signature) {
            console.log('signature', signature)
            try {
                let status = await api.saveStudioSignature(signature)
                this.$toast.success('Unterschrift erfolgreich gespeichert')
            } catch (e) {
                this.$toast.error('Fehler beim Speichern der Unterschrift')
            }
        },
        async fetchStudio() {
            try {
                let studio = await api.getUserStudio()
                this.studio = studio.studio
            } catch (e) {
                this.$toast.error('Fehler beim Laden des Studios')
            }
        },
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
    },

    computed: {},
}
</script>
<style lang="scss" scoped>
.dashboard {
}
</style>
