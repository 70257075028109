<template>
    <div>
        <attendance-trainer-list></attendance-trainer-list>
        <attendance-customer-list></attendance-customer-list>
    </div>
</template>

<script>
import attendanceTrainerList from '@/views/dashboard/attendance/attendanceTrainerList.vue'
import attendanceCustomerList from '@/views/dashboard/attendance/attendanceCustomerList.vue'
export default {
    data() {
        return {}
    },
    components: {
        attendanceTrainerList,
        attendanceCustomerList,
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
</style>