<template>
	<div class="aktuelles">
		<div class="back-navigation">
			<router-link to="/">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Weiterempfehlung</span>
		</div>
		<v-card class="weiterempfehlung__card rounded-xl elevation-7 my-5 ma-auto">
			<v-img :src="getHeader()"></v-img>
			<div class="overflow-auto" style="max-height: 60vh">
				<v-card-title class="h5 primary--text">
					Beschreibung
				</v-card-title>
				<v-card-text v-if="iswellcomefit == 1">
					<span>NIMM DEINE FREUNDE UND FAMILIE MIT AUF DIE SONNENSEITE DES LEBENS</span>
					Schicke diese Promotion an Deine Freunde, Familie und Bekannten und lade sie zu einem gemeinsamen Training in einem well come FIT
					Center eurer Wahl ein.
					<br />
					<br />Deine Kontakte können während der nächsten 10 Tage kostenlos an einem well come FIT Standort trainieren. Sollte sich einer
					Deiner Kontakte für eine Mitgliedschaft bei uns entschliessen, erhältst Du CHF 50.- bar auf die Hand.
				</v-card-text>
				<v-card-text v-if="iswellcomefit == 2">
					<span class="heading-affiliate">TEILE DIESE PROMOTION JETZT MIT DEINEN FREUNDEN</span>
					Schicke diese Promotion an Deine Freunde, Familie und Bekannten und lade sie zu einem gemeinsamen Training in einem winti FIT
					Center eurer Wahl ein.
					<br />
					<br />Deine Kontakte können während der nächsten 10 Tage kostenlos an einem winti FIT Standort trainieren. Sollte sich einer
					Deiner Kontakte für eine Mitgliedschaft bei uns entschliessen, erhältst Du CHF 50.- bar auf die Hand.
				</v-card-text>
				<v-card-text v-if="iswellcomefit == 3">
					<span class="heading-affiliate">TEILE DIESE PROMOTION JETZT MIT DEINEN FREUNDEN</span>
					Schicke diese Promotion an Deine Freunde, Familie und Bekannten. Trainiert gemeinsam im Fitness Zentrum Wetzikon.
					<br />
					<br />Deine Freunde erhalten die Möglichkeit eine Woche kostenlos zu trainieren. Sollte sich einer Deiner Kontakte nach seinem
					einwöchigen Gratistraining für eine Mitgliedschaft entscheiden, erhältst Du CHF 50.- bar auf die Hand.
				</v-card-text>

				<v-card-title class="h5 primary--text">Bedingungen</v-card-title>
				<v-card-text>
					Die CHF 50.- werden nur für ein Abonnement von Neukunden und nach Eingang der Zahlung ausbezahlt. CHF 50.- für ein Jahresabo, CHF
					25 für ein Halbjahresabonnement.
					<br />
					Rabatte können nicht kumuliert werden. Gültigkeit dieser Promotion bis 31.12.{{ year }}.
				</v-card-text>
				<div class="agb" :class="{ 'agb-rounded': !agbchecker }">
					<input @click="toggleAgb()" type="checkbox" />
					<div class="agb-text-wrapper">
						Bedingungen akzeptieren und den Link für die Weiterempfehlung erhalten
					</div>
				</div>
				<div v-show="agbchecker" class="aff-link">
					Dein persönlicher Weiterempfehlungs-Link lautet:
					<br />
					<input id="myreflink" class="affiliate-gen-link" v-model="inputlink" />
					<div v-if="!copied" class="affiliate-gen-link-copy" @click.prevent="copylink">
						Link kopieren!
					</div>
					<div v-else class="affiliate-gen-link-copy" @click.prevent="copylink">
						Link wurde kopiert!
					</div>
					<div class="share-it">
						Teile deinen Link jetzt über:
						<br />
						<div class="link-wrapper">
							<a target="__blank" :href="whatsapplink">
								<img class="share-icon" src="/img/social/whatsapp.svg" />
							</a>
							<a target="__blank" :href="facebooklink">
								<img class="share-icon" src="/img/social/facebook.svg" />
							</a>
							<a target="__blank" :href="linkedinlink">
								<img class="share-icon" src="/img/social/linkedin.svg" />
							</a>
							<a target="__blank" :href="telegramlink">
								<img class="share-icon" src="/img/social/telegram.svg" />
							</a>
							<a target="__blank" :href="maillink">
								<img class="share-icon" src="/img/social/gmail.svg" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</v-card>
	</div>
</template>

<script>
import api from '../api'

export default {
	data() {
		return {
			agbchecker: false,
			hostname: '',
			email: '',
			whatsapplink: '',
			facebooklink: '',
			telegramlink: '',
			linkedinlink: '',
			maillink: '',
			inputlink: '',
			copied: false,
			iswellcomefit: true,
		}
	},
	methods: {
		getHeader() {
			let ret = false
			if (location.host.includes('localhost')) {
				ret = 'https://app.wellcomefit.ch/img/kampagne/weiterempfehlung/zehntage_BS_quer.jpg'
			}
			if (location.host.includes('wellcomefit')) {
				ret = '/img/kampagne/weiterempfehlung/zehntage_BS_quer.jpg'
			}
			if (location.host.includes('wintifit')) {
				ret = '/img/kampagne/weiterempfehlung/winti_zehntage_BS_quer.jpg'
			}

			if (ret) {
				return ret
			} else {
				return '/img/affiliate_1.png'
			}
		},
		copylink() {
			var copyText = document.getElementById('myreflink')

			/* Select the text field */
			copyText.select()
			copyText.setSelectionRange(0, 99999) /*For mobile devices*/

			/* Copy the text inside the text field */
			document.execCommand('copy')

			this.copied = true
		},
		toggleAgb() {
			if (this.agbchecker) {
				this.agbchecker = false
			} else {
				this.agbchecker = true

				setTimeout(function() {
					document.getElementById('myreflink').scrollIntoView()
				}, 500)
			}
		},
	},
	created() {
		api.getUser().then((usdata) => {
			this.year = new Date(new Date()).getFullYear()
			this.email = usdata.user.email
			this.hostname = location.host
			let text = ''
			if (location.host.includes('wellcomefit') || location.host.includes('localhost')) {
				this.iswellcomefit = 1
				text =
					'Hey%20Du%2C%20ich%20war%20gerade%20beim%20Training%20im%20well%20come%20FIT.%20Macht%20voll%20Spass%20H%C3%A4ttest%20Du%20Lust%20das%20Training%20auch%20mal%20zu%20probieren%3F%20Es%20macht%20eben%20nicht%20nur%20fit%20sondern%20sorgt%20auch%20f%C3%BCr%20ziemlich%20viel%20gute%20Laune%20Klicke%20doch%20einfach%20auf%20den%20Link%20und%20sichere%20Dir%20Deinen%20Platz%20f%C3%BCr%20ein%2010t%C3%A4giges%2C%20kostenloses%20Training%20inkl.%20Trainingsplanung%2C%20Groupfitnesslektionen%20(Indoor%20%2F%20Outdoor%20und%20im%20Livestream)%2C%20K%C3%B6rperanalyse%2C%20Zugang%20zu%20einer%20Fitness-App%20und%20einem%20Kinderhort-Angebot.%20W%C3%A4re%20toll%2C%20wenn%20wir%20uns%20da%20mal%20sehen!%20'
			} else if (location.host.includes('wintifit')) {
				// wellcomefit
				this.iswellcomefit = 2
				// wintifit
				text =
					'Hey%20Du%2C%20ich%20war%20gerade%20beim%20Training%20im%20winti%20FIT.%20Macht%20voll%20Spass%20%20H%C3%A4ttest%20Du%20Lust%20das%20Training%20auch%20mal%20zu%20probieren%3F%20Es%20macht%20eben%20nicht%20nur%20fit%20sondern%20sorgt%20auch%20f%C3%BCr%20ziemlich%20viel%20gute%20Laune%20Klicke%20doch%20einfach%20auf%20den%20Link%20und%20sichere%20Dir%20Deinen%20Platz%20f%C3%BCr%20ein%2010t%C3%A4giges%2C%20kostenloses%20Training%20inkl.%20Trainingsplanung%2C%20Groupfitnesslektionen%20(Indoor%20%2F%20Outdoor%20und%20im%20Livestream)%2C%20K%C3%B6rperanalyse%2C%20Zugang%20zu%20einer%20Fitness-App%20und%20einem%20Kinderhort-Angebot.%20W%C3%A4re%20toll%2C%20wenn%20wir%20uns%20da%20mal%20sehen!%20'
			} else if (location.host.includes('fzw')) {
				this.iswellcomefit = 3
				// wintifit
				text =
					'Hey%0AIch%20war%20gerade%20im%20Training%20im%20Fitness%20Zenter%20Wetzikon.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20dann%20%0Aklicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20f%C3%BCr%20eine%20Schnupper%20Woche%20an.%20'
			}
			this.inputlink = 'https://' + this.hostname + '/schnupperwoche?' + this.email
			let sharelink = 'https%3A%2F%2F' + this.hostname + '%2Fschnupperwoche%3F' + this.email.replace('@', '%40')

			this.whatsapplink = 'https://api.whatsapp.com/send?text=' + text + ' ' + sharelink
			this.linkedinlink = 'https://www.linkedin.com/shareArticle?url=' + sharelink
			this.facebooklink = 'https://www.facebook.com/sharer/sharer.php?u=' + sharelink
			this.maillink = 'mailto:?subject=&body=' + text + '%20' + sharelink
			this.telegramlink = 'https://t.me/share/url?url=' + sharelink + '&text=' + text
		})
	},
}
</script>

<style lang="scss" scoped>
.weiterempfehlung__card {
	width: 90%;
	max-width: 700px;
}
.label {
	height: 40px;
	line-height: 40px;
	font-weight: 300;
	padding-left: 15px;
	background-color: #545655;
	color: white;
}
.heading-affiliate {
	width: 100%;
	display: flex;
	color: #8ebf24;
	font-size: 18px;
	/* font-weight: bold; */
	/* text-align: center; */
	margin-bottom: 10px;
}
.affiliate-gen-link {
	width: 100%;
	padding: 5px 10px;
	margin-top: 20px;

	display: flex;
	border: 1px solid var(--v-primary);
	text-align: center;
}
.affiliate-gen-link-copy {
	margin-bottom: 20px;
	background-color: var(--v-primary);
	color: white;
	text-align: center;
	padding: 10px 0px;
}
.aff-link {
	margin: 20px 10px 40px 10px;
}
.link-wrapper {
	display: flex;
	justify-content: space-evenly;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	.share-icon {
		width: 60px;
		height: 60px;
		padding: 7px;
	}
}
.agb-toggler {
	font-weight: 500;
	text-decoration: underline;
}
.affiliate-header {
	font-weight: 500;
	font-size: 23px;
	text-align: center;
	margin-top: 20px;
	color: var(--v-primary);
}
.affiliate-roody-wrapper {
	width: 100%;
	display: flex;

	.affiliate-roody {
		height: 20%;
		max-height: 500px;
		width: 100vw;
		object-fit: contain;
	}
}

.affiliate-text {
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 20px;
	margin-top: 10px;
}
.agb-text-wrapper {
	margin-right: 10px;
	margin-left: 12px;
	max-width: 240px;
}
.agb {
	width: 100%;
	background-color: var(--v-primary);
	color: white;
	padding: 17px;
	justify-content: center;
	display: flex;
	&-rounded {
		border-bottom-right-radius: 24px;
		border-bottom-left-radius: 24px;
	}
	input {
		padding: 5px;

		width: 30px;
		height: 30px;
		margin: auto 10px;
		margin-right: 10px;
	}
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
