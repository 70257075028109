<template>
	<div class="aktuelles">
		<div id="campaign">
			<div v-show="campaign.titel" class="campaign-titel">
				{{ campaign.titel }}
			</div>
			<div v-show="campaign.foto" class="c-header">
				<img :src="'/img/news/' + campaign.foto" />
			</div>
			<div v-show="campaign.untertitel" class="campaign-untertitel">
				{{ campaign.untertitel }}
			</div>
			<div v-html="format(campaign.beschreibung)" v-show="campaign.beschreibung" class="campaign-beschreibung"></div>
			<div
				:class="{
					'campaign-preis-wrapper': 1,
					zus_info: !campaign.rabatt && campaign.zus_info,
				}"
				v-show="campaign.rabatt || campaign.zus_info || campaign.rabatt"
			>
				<div v-show="campaign.rabatt" class="campaign-mitgliedervorteil">
					<span class="mitglieder-rabatt">CHF {{ formatPrice(campaign.rabatt) }} </span>
					<span class="mitglieder-vorteil">Mitgliedervorteil</span>
				</div>
				<div v-show="campaign.zus_info && !campaign.rabatt" class="campaign-mitgliedervorteil zus_info">
					<span class="mitglieder-rabatt">{{ formatPrice(campaign.zus_info) }} </span>
				</div>
				<div class="rabatt-wrapper">
					<div v-show="campaign.rabatt" class="campaign-rabatt">statt CHF {{ formatPrice(campaign.preis) }}</div>
					<div v-show="campaign.preis && campaign.rabatt" class="campaign-preis">
						CHF {{ formatPrice(campaign.preis - campaign.rabatt) }}
					</div>
					<div v-show="campaign.preis && !campaign.rabatt" class="campaign-preis">CHF {{ formatPrice(campaign.preis) }}</div>
					<div v-show="campaign.miete" class="campaign-miete">Miete CHF {{ formatPrice(campaign.miete) }}</div>
					<div v-show="campaign.depot" class="campaign-depot">Depot CHF {{ formatPrice(campaign.depot) }}</div>
				</div>
			</div>
			<div class="soldout" v-show="anzahl < 1 && !user_campaign_reservations[campaign._id]">
				Ausverkauft
			</div>
			<div v-show="!user_campaign_reservations[campaign._id] && anzahl > 0" class="lager">
				<span class="material-icons">info</span>
				<span> Noch {{ anzahl }} Stück verfügbar!</span>
			</div>
			<div v-show="user_campaign_reservations[campaign._id]" class="reservationstext">
				{{ campaign.reservationstext }}
			</div>

			<v-btn
				block
				v-if="!user_campaign_reservations[campaign._id]"
				v-show="anzahl > 0"
				@click.prevent="campaign_signup(campaign._id)"
				:class="{ reservieren: 1, helsana: campaign.titel.includes('Helsana') }"
			>
				Jetzt reservieren
			</v-btn>
			<v-btn
				block
				v-else
				@click.prevent="campaign_signoff(campaign._id)"
				class="reservieren reserviert"
				:class="{
					reservieren: 1,
					reserviert: 1,
					helsana: campaign.titel.includes('Helsana'),
				}"
			>
				Reservierung aufheben
			</v-btn>
		</div>
	</div>
</template>

<script>
import api from '../api'

export default {
	data() {
		return {
			studio: null,
			user: null,
			anzahl: 0,
			campaign: {},
			user_campaign_reservations: [],
			campaign_reservations: [],
		}
	},
	methods: {
		format(i) {
			return i.split('{{studio}}').join(this.getArt())
		},
		getArt() {
			let ret = false
			if (location.host.includes('localhost')) {
				ret = 'well come FIT'
			}
			if (location.host.includes('wellcomefit')) {
				ret = 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				ret = 'winti FIT'
			} else if (location.host.includes('fzw')) {
				ret = 'FZW'
			}
			return ret
		},
		campaign_signoff(id) {
			if (!this.disable_campaign) {
				this.disable_campaign = true
				let userid = this.user._id
				api.signoffCampaign(id, userid).then((data) => {
					if (data.status == 1) {
						this.disable_campaign = false
						this.loadCampaigns()
					} else {
						if (data.status == 3) {
							this.disable_campaign = false
							alert('Leider ist unser Vorrat aufgebraucht. Viel Erfolg bei der nächsten Aktion.')
							this.loadCampaigns()
						} else {
							this.disable_campaign = false
							alert('es gab einen Fehler')
						}
					}
				})
			}
		},
		campaign_signup(id) {
			if (!this.disable_campaign) {
				this.disable_campaign = true

				let userid = this.user._id
				let studio_id = this.studio._id
				api.signupCampaign(id, userid, studio_id).then((data) => {
					console.log('data', data)
					if (data.status == 1) {
						this.disable_campaign = false
						this.loadCampaigns()
					} else {
						if (data.status == 3) {
							this.disable_campaign = false
							alert('Leider ist unser Vorrat aufgebraucht. Viel Erfolg bei der nächsten Aktion.')
							this.loadCampaigns()
						} else {
							this.disable_campaign = false
							alert('es gab einen Fehler')
						}
					}
				})
			}
		},
		formatPrice(price) {
			let pricestring = price + ''
			if (pricestring.split('.')[1] < 10) {
				pricestring += '0'
			}
			return pricestring
		},
		loadCampaigns() {
			api.getKampagne(this.$route.params.id).then((data) => {
				this.campaign = data.campaign[0]
				this.user_campaign_reservations = []

				this.campaigns_reservations = data.campaign_reservations

				let userid = data.user_id

				let counter = 0
				for (let cr of this.campaigns_reservations) {
					if (cr.campaign_id == data.campaign[0]._id) {
						counter++
					}
					if (cr.ben_id == userid) {
						this.user_campaign_reservations[cr.campaign_id] = true
					}
				}
				this.anzahl = data.campaign[0].anzahl - counter
			})
		},
	},
	async created() {
		let user = await api.getUser().catch((e) => {})
		this.user = user
		let studio = null

		studio = await api.getUserStudio().catch((e) => {
			window.app.$root.$children[0].checkStudio()
		})
		this.studio = studio.studio

		this.loadCampaigns()
	},
}
</script>

<style lang="scss" scoped>
.campaign-beschreibung,
.reservationstext {
	white-space: pre-wrap;
}
.campaign-miete,
.campaign-depot {
	text-align: center;
	font-weight: bold;
	font-size: 26px;
}

#campaign {
	padding: 0px 5px;
	max-width: 600px;
	margin: auto;

	.soldout {
		text-align: center;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 25px;
		margin-bottom: 20px;
	}

	.lager {
		text-align: left;
		display: flex;
		flex-direction: row;
		.material-icons {
			color: #8b8b8b;
			padding-right: 5px;
		}
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.reservationstext {
		margin: 25px 0px 20px 0px;
	}

	.reservieren {
		width: 90vw;
		margin: 10px auto 30px auto;
		text-align: center;
		background-color: var(--v-primary);
		max-width: 400px;
		border-radius: 3px;
		line-height: 60px;
		color: white;

		font-weight: bold;

		&.reserviert {
			background-color: #d75e5d;

			&.helsana {
				background-color: none !important;
				border: 3px solid #9a0941;
				background-color: #fff !important;
				color: #9a0941;
			}
		}

		&.helsana {
			background-color: #9a0941;
		}
	}

	> div {
		padding: 0px 10px;
	}

	.campaign {
		&-titel {
			font-size: 34px;
			margin-top: 10px;
			padding: 10px 10px 0px 10px;
			margin-bottom: 10px;
		}
		&-untertitel {
			font-size: 17px;
			line-height: 26px;
			padding: 5px 10px 10px 10px;
		}
		&-beschreibung {
			font-size: 18px;
		}
		&-preis-wrapper {
			position: relative;
			margin: 20px 0px 40px 0px;
			&.zus_info {
				margin: 0px 0px 20px 0px;
			}
			.campaign {
				&-mitgliedervorteil:not(.zus_info) {
					position: absolute;
				}
				&-rabatt {
					text-align: right;
					font-weight: bold;
				}
				&-preis {
					font-size: 30px;
					text-align: right;
					line-height: 28px;
					font-weight: bold;
					color: var(--v-primary);
				}
				&-mitgliedervorteil {
					text-align: center;
					transform: rotate(-10deg);
					font-weight: bold;

					padding: 0px 10px;
					border: 3px solid #c93131;
					border-radius: 15px;
					background-color: #fff540;
					color: #c93131;
					text-transform: uppercase;
					display: flex;
					flex-direction: column;

					&.zus_info {
						transform: rotate(0deg);

						margin: 20px auto auto auto;
						width: 250px;
					}
					@media only screen and (min-width: 400px) {
						left: 5%;
					}
					.mitglieder {
						&-rabatt {
							font-size: 19px;
							line-height: 34px;
							margin-top: 3px;
						}
						&-vorteil {
							line-height: 18px;
							margin-bottom: 9px;
							font-size: 16px;
							@media only screen and (max-width: 350px) {
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}

	.c-header {
		padding: 0px;
		width: 100%;
		img {
			width: 100%;
			max-height: 200px;
			object-fit: contain;
		}
	}
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
