<template>
	<div class="d-flex flex-column justify-justify-space-around">
		<v-card-title class="font-weight-bold h3 black--text">Zeit</v-card-title>
		<v-row class="mb-4 black--text" justify="space-between">
			<v-col class="text-left">
				<span class="text-h2 font-weight-light" v-text="time"></span>
			</v-col>
		</v-row>
		<v-card-actions class="pt-0 justify-space-around">
			<div style="background: red;" class="rounded-lg pa-3" @click="reset">
				<lottie-vue-player
					ref="reset"
					mode="normal"
					style="width:50px;height:50px;background-color: transparent;"
					name="reset"
					:autoplay="false"
					src="/img/lottiefiles/eraseTime.json"
				></lottie-vue-player>
			</div>
			<div class="rounded-lg pa-3" style="background: gainsboro;" @click="runButtonClicked">
				<lottie-player
					id="playStop"
					ref="playStop"
					mode="normal"
					style="width:50px;height:50px;background-color: transparent;"
					name="playStop"
					:autoplay="false"
					src="/img/lottiefiles/playStop.json"
				></lottie-player>
			</div>
			<div v-if="!running && time !== '00:00.000'" class="rounded-lg pa-3 primary" @click="save">
				<lottie-vue-player
					mode="normal"
					:autoplay="false"
					style="width:50px;height:50px;background-color: transparent;"
					src="/img/lottiefiles/whiteCheck.json"
				></lottie-vue-player>
			</div>
		</v-card-actions>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { create } from '@lottiefiles/lottie-interactivity'

export default {
	name: 'WorkingoutExerciseStopwatch',
	props: {
		givenTime: {
			type: String,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			timeBegan: null,
			timeStopped: null,
			stoppedDuration: 0,
			running: false,
			time: '',
			started: null,
		}
	},
	created() {
		this.time = this.givenTime || '00:00.000'
	},
	mounted() {
		this.$refs.playStop.addEventListener('load', function() {
			create({
				mode: 'cursor',
				player: '#playStop',
				actions: [
					{
						type: 'toggle',
					},
				],
			})
		})
	},
	beforeDestroy() {
		this.$refs.playStop.removeEventListener('load', function() {})
	},
	methods: {
		...mapActions('workingoutStore', ['setStoppedDuration']),

		runButtonClicked(emit = true) {
			if (emit) {
				this.running ? this.stop() : this.start()
			}
		},

		start() {
			if (this.running) return

			if (this.timeBegan === null) {
				this.reset()
				this.timeBegan = new Date()
			}

			if (this.timeStopped !== null) {
				this.stoppedDuration += new Date() - this.timeStopped
			}
			this.started = setInterval(this.clockRunning, 10)
			this.running = true
		},
		stop() {
			this.running = false
			this.timeStopped = new Date()
			clearInterval(this.started)
		},
		save() {
			this.setStoppedDuration(this.time)
		},
		reset() {
			this.runButtonClicked(false)
			this.$refs['reset'].stop()
			this.$refs['reset'].togglePlayPause()

			this.running = false
			clearInterval(this.started)
			this.stoppedDuration = 0
			this.timeBegan = null
			this.timeStopped = null
			this.time = '00:00.000'
		},
		clockRunning() {
			let currentTime = new Date()
			let timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration)
			let min = timeElapsed.getUTCMinutes()
			let sec = timeElapsed.getUTCSeconds()
			let ms = timeElapsed.getUTCMilliseconds()

			this.time = this.zeroPrefix(min, 2) + ':' + this.zeroPrefix(sec, 2) + '.' + this.zeroPrefix(ms, 3)
		},
		zeroPrefix(num, digit) {
			let zero = ''
			for (let i = 0; i < digit; i++) {
				zero += '0'
			}
			return (zero + num).slice(-digit)
		},
	},
}
</script>

<style lang="scss" scoped></style>
