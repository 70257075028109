<template>
    <div class="rollenverwaltung">
        <v-row>
            <v-col align="start" cols="6">
                <v-btn
                    text
                    class="mx-auto"
                    v-if="!showUserEditor && userService.hasRight('u_rightList')"
                    @click="$router.push('/dashboard/user/rightlist')"
                    color="white"
                    ><v-icon class="pr-1">mdi-card-account-details</v-icon> Rechte-Liste</v-btn
                >
            </v-col>
            <v-col align="end" cols="6">
                <v-btn icon v-if="!showUserEditor" class="mx-auto" @click="showRegisterDialog = true" color="white"
                    ><v-icon>mdi-account-plus</v-icon></v-btn
                >
            </v-col>
            <v-col cols="12">
                <user-search
                    style="display: flex; flex-direction: row"
                    dark
                    filled
                    label="Suche"
                    @update="editrollen"
                    return-user></user-search>
            </v-col>
        </v-row>

        <register
            @register="newAccountRegistered()"
            :auto-login="false"
            @close="showRegisterDialog = false"
            :active="showRegisterDialog"></register>
    </div>
</template>
<script>
import userSearch from '../../../components/userSearch.vue'
import basicDialog from '../../../components/tools/basicDialog'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import register from '../../launchScreen/register.vue'
import api from '../../../api'
import $ from 'jquery'
import userService from '../../../services/userService'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
export default {
    components: {
        register,
        userSearch,
    },
    data() {
        return {
            userService,
            search: '',
            showRegisterDialog: false,
            userSearchValue: null,
            users: {},
        }
    },
    async created() {
        if (this.$route.params.id) {
            this.editrollen({ _id: this.$route.params.id })
        }
    },
    methods: {
        async editrollen(user) {
            if (user && user._id) {
                this.$router.push('/dashboard/user/search/' + user._id)
                //  this.editRollen({_id: this.$route.params.id})
            }
        },
        findUsers() {
            if (this.search.length > 2) {
                api.searchUserforRolle({ search: this.search })
                    .then((data) => {
                        this.users = data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.users = {}
            }
        },
    },
}
</script>
