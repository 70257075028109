<template>
    <v-card dark class="mx-auto py-3">
        <v-row v-if="loading">
            <v-col cols="12">
                <v-progress-linear class="my-5 px-5" indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" v-if="events.length === 0">
                <v-alert class="mx-5" type="info">Keine Daten vorhanden</v-alert>
            </v-col>
            <v-col cols="12" v-else>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center my-2">
                        <v-btn icon @click="selectedWeek = $moment(selectedWeek.subtract('weeks', 1))">
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <v-text-field disabled filled rounded :value="$moment(selectedWeek).isoWeek()"></v-text-field>
                        <v-btn icon @click="selectedWeek = $moment(selectedWeek.add('weeks', 1))">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-sheet height="800">
                    <v-calendar
                        locale="de-CH"
                        ref="calendar"
                        dark
                        v-model="selectedMonthCalendar"
                        :now="$moment().format('YYYY-MM-DD')"
                        :weekdays="weekdays"
                        :type="'month'"
                        :events="events"
                        :event-color="(event) => event.color"
                        :event-text-color="'white'"
                    >
                        <template #event="{ event }">
                            <div class="d-flex justify-center text-center">
                                {{ event.employeeName }}
                            </div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-col>
        </v-row>
    </v-card>
</template>
  
  <script>
import api from '@/api'
export default {
    name: 'HolidayEntriesView',
    props: {},
    data() {
        return {
            loading: true,
            selectedWeek: this.$moment(),
            datePickerDialog: false,
            events: [],
            weekdays: [1, 2, 3, 4, 5, 6, 0],
        }
    },
    created() {
        this.fetchEntries()
    },
    methods: {
        async fetchEntries() {
            this.loading = true
            try {
                console.log('testitest')
                let data = await api.fetchTechnicTeamEvents({
                    dates: {
                        start: this.selectedWeek.startOf('week').format('YYYY-MM-DD'),
                        end: this.selectedWeek.endOf('week').format('YYYY-MM-DD'),
                    },
                })
                this.events = data
                this.loading = false
            } catch (e) {
                this.$toast.error('Fehler beim Laden der Ferientage')
                this.events = []
                this.loading = false
            }
        },
    },
    computed: {
        selectedMonthCalendar() {
            return this.$moment(this.datePickerRange[0]).startOf('month').format('YYYY-MM-DD')
        },
    },
    watch: {
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.fetchEntries()
            }
        },
    },
}
</script>
  
  <style scoped>
</style>
  