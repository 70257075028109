<template>
	<v-dialog class="workingOutStatistic" persistent max-width="500px" :value="true" transition="dialog-bottom-transition">
		<v-card>
			<v-card-title style="word-break: break-word;" class="workingOutStatistic__card-title h3 text-wrap justify-center">Notiz </v-card-title>
			<v-textarea
				class="workingOutStatistic__textarea ma-auto"
				v-model="note"
				autofocus
				placeholder="Geben Sie hier ihre Notiz ein..."
			></v-textarea>
		</v-card>
	</v-dialog>
</template>

<script>
import api from '../../api'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	name: 'WorkingoutStatistic',
	props: {
		exerciseId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			note: '',
			navigationBarInitiated: false,
		}
	},
	created() {
		this.setNavigationBarText({ text: 'Notiz Speichern', state: false, endPoint: 'saveNote' })
		if (this.savedNote && this.savedNote.exercise === this.exerciseId) this.note = this.savedNote.note
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNewBottomNavigationUsage']),
		...mapActions('workingoutStore', ['setSavedNote']),
	},
	computed: {
		...mapState('navigationStoreNotPersisted', ['workoutExecutions', 'navigationBarText']),
		...mapState('workingoutStore', ['savedNote']),
		...mapGetters('navigationStoreNotPersisted', ['navigationBarEndPoint', 'navigationBarTextState']),
	},
	watch: {
		navigationBarTextState(value) {
			if (this.navigationBarInitiated && this.navigationBarEndPoint === 'saveNote') {
				this.setNavigationBarText(null)
				let note = this.note === '' ? 'Keine Notiz eingegeben' : this.note
				this.setSavedNote({ exercise: this.exerciseId, note: note, saved: true })
			} else {
				this.navigationBarInitiated = true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
	overflow-x: hidden !important;
}
.workingOutStatistic {
	background: white;
	&__textarea {
		width: 80%;
	}
}
</style>
