<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="editModus" class="modal-title">Übung bearbeiten</h5>
          <h5 v-else class="modal-title">Übung hinzufügen</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="Informationen">
            <label class="titel">Informationen</label>
            <div class="informationen-wrapper">
              <label>Titel</label>
              <input v-model="exercise.titel" />
            </div>
            <div class="informationen-wrapper">
              <label>Beschreibung</label>
              <textarea v-model="exercise.beschreibung" />
            </div>
            <div class="informationen-wrapper">
              <label>Video</label>
              <input v-model="exercise.video" />
            </div>
          </div>
          <div class="Tags">
            <label class="titel">Tags</label>
            <div class="tag-wrapper">
              <div v-for="(tag, y) in tags" :key="y" class="check-wrapper"><input class="tag" type="checkbox" :value="y" v-model="tag.checked" />{{ tag.name }}</div>
            </div>
          </div>

          <div class="Einstellungen">
            <label class="titel">Felder</label>

            <div v-for="(setting, i) of exercise_settings" :key="i" class="einstellung-pre">
              <label>Titel:</label>
              <span @click="removeSetting(i)" class="remove">entfernen</span>
              <input placeholder="Text eingeben..." type="text" v-model="setting.name" />
              <label>Art:</label>

              <select v-model="setting.art">
                <option value="1">Zahl</option>
                <option value="2">Text</option>
                <option value="3">Auswahl</option>
              </select>
              <label v-show="setting.art == 3">Auswahlmöglichkeiten<br />(nur kommagetrente Werte):</label>
              <input type="text" class="auswahl" placeholder="Bsp: hart,leicht,mittel" v-show="setting.art == 3" v-model="setting.auswahl" />
            </div>
            <div class="new-einstellung">
              <span>Neues Feld hinzufügen:</span>
              <div class="new-einstellung-art-wrapper">
                <span>Titel: </span>
                <select v-model="addEinstellungArt">
                  <option value="Text eingeben..." selected="selected">
                    Freier Text
                  </option>
                  <!--- Senso Pro -->
                  <option label="Programm" value="Programm">Programm</option>
                  <option label="Thema" value="Thema">Thema</option>
                  <option label="Level" value="Level">Level</option>
                  <!-- Mobility -->
                  <option label="Dehnmethode" value="Dehnmethode">Dehnmethode</option>
                  <!-- Xtension -->
                  <option label="Herzfrequenz" value="Herzfrequenz">Herzfrequenz</option>
                  <!-- Ausdauer -->
                  <option label="Ausdauermethode" value="Ausdauermethode">Ausdauermethode</option>
                  <option label="Watt" value="Watt">Watt</option>
                  <option label="Geschwindigkeit" value="Geschwindigkeit">Geschwindigkeit</option>
                  <option label="Umdrehungen pro Minute" value="Umdrehungen pro Minute">Umdrehungen pro Minute</option>
                  <option label="Kalorien" value="Kalorien">Kalorien</option>
                  <!-- Grundeinstellungen -->
                  <option label="Pause" value="Pause">Pause</option>
                  <option label="Dauer" value="Dauer">Dauer</option>
                  <option label="Notiz" value="Notiz">Notiz</option>
                  <option label="Gewicht" value="Gewicht">Gewicht</option>
                  <option label="Sätze" value="Sätze">Sätze</option>
                  <option label="Max Wiederholungen" value="Max Wiederholungen">Max Wiederholungen</option>
                  <option label="Min Wiederholungen" value="Min Wiederholungen">Min Wiederholungen</option>

                  <!-- Normale Einstellungen -->
                  <option label="Lehne" value="Lehne">Lehne</option>
                  <option label="Sitz" value="Sitz">Sitz</option>
                  <option label="Winkel" value="Winkel">Winkel</option>
                  <option label="Rolle" value="Rolle">Rolle</option>
                  <option label="Polster" value="Polster">Polster</option>
                  <option label="Position" value="Position">Position</option>
                  <option label="Platte" value="Platte">Platte</option>
                  <option label="Widerst." value="Widerst.">Widerst.</option>
                  <option label="Band" value="Band">Band</option>
                  <option label="Höhe Box" value="öhe Box">Höhe Box</option>
                  <option label="Griffe" value="Griffe">Griffe</option>
                  <option label="Höhe (unten, mitte, oben)" value="Höhe (unten, mitte, oben)">
                    Höhe (unten, mitte, oben)
                  </option>
                  <option label="Ball" value="Ball">Ball</option>
                  <option label="Variante" value="Variante">Variante</option>
                  <option label="Bandstärke" value="Bandstärke">
                    Bandstärke
                  </option>
                  <option label="Pad" value="Pad">Pad</option>
                </select>
              </div>

              <button class="addnewsetting" @click="addNewEinstellung">
                Hinzufügen
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="editModus && savemode == 1" type="button" class="btn btn-primary" @click.prevent="saveSettings">
            Speichern
          </button>
          <button v-else type="button" class="btn btn-primary" @click.prevent="saveSettings">
            Übung hinzufügen
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import api from '../api'

export default {
  data() {
    return {
      savemode: 1,
      addEinstellungArt: 'Text eingeben...',
      exercise_settings: [],
      new__exercise_setting: {
        exercise_id: '',
        name: '',
        art: '',
        auswahl: '',
      },
      option_exercises: [],
      exercise: {
        _id: null,
        geraete_old_id: '',
        titel: '',
        beschreibung: '',
        video: '',
        sort_order: '',
        level: '',
        gewichte: '',
        studio_id: '',
      },
      exercise_steps: [],
      new__exercise_step: {
        exercise_id: '',
        old_id: '',
        beschreibung: '',
        bild: '',
        sort_order: '',
        main_photo: '',
      },
      tags: {},
      editModus: false,
      changed_tags: {},
    }
  },
  methods: {
    removeSetting(i) {
      let temp_exercise_setting = []
      for (let x = 0; x < this.exercise_settings.length; x++) {
        if (i !== x) {
          temp_exercise_setting.push(this.exercise_settings[x])
        }
      }
      this.exercise_settings = temp_exercise_setting
    },
    addNewEinstellung() {
      this.new__exercise_setting.exercise_id = this.exercise._id
      this.new__exercise_setting.name = this.addEinstellungArt
      this.new__exercise_setting.art = 1
      this.new__exercise_setting.auswahl = ''

      this.exercise_settings.push({
        exercise_id: this.exercise._id,
        name: this.addEinstellungArt,
        art: 1,
        auswahl: '',
        studio_id: this.exercise.studio_id,
      })
    },
    async show(uebung) {
      if (uebung.uebung) {
        this.editModus = true

        this.exercise = uebung.uebung

        api
          .getExerciseSettings(uebung.uebung._id)
          .then((data) => {
            this.tags = data.tags

            this.exercise_settings = data.settings
            let tags = document.querySelectorAll('.tag')

            for (let tag of tags) {
              for (let t of data.tags) {
                if (t == tag.value) {
                  tag.checked = true
                }
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.editModus = false
        this.exercise._id = null
        this.exercise.titel = ''
        this.exercise.video = ''
        this.exercise.beschreibung = ''

        this.exercise.geraete_old_id = uebung
        let studio = null

        studio = await api.getUserStudio().catch((e) => {
          window.app.$root.$children[0].checkStudio()
        })
        studio = studio.studio
        this.exercise.studio_id = studio._id
      }

      $(this.$refs['modal']).modal('show')
    },
    hide() {
      $(this.$refs['modal']).modal('hide')
    },
    saveSettings() {
      let tags = document.querySelectorAll('.tag')

      let tag_array = []
      for (let tag of tags) {
        if (tag.checked) {
          tag_array.push(tag.value)
        }
      }

      api
        .saveExerciseSettings({
          exercise: this.exercise,
          studio_id: this.exercise.studio_id,
          tags: tag_array,
          exercise_settings: this.exercise_settings,
        })
        .then((data) => {
          if (data.status) {
            this.$emit('saved')
            this.hide()
          } else {
            alert('fehler')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-wrapper {
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
  }

  .check-wrapper {
    input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    margin-bottom: 5px;
    display: flex;
  }
}
.informationen-wrapper {
  display: flex;
  flex-direction: column;

  input,
  textarea {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  textarea {
    height: 80px;
  }
}
.titel {
  font-size: 20px;
  font-weight: bold;
}
.uploaded-image {
  max-height: 200px;
  margin: 20px auto;
  display: block;
}
.modal-content {
  height: 90vh;
}
.modal-body {
  overflow: scroll;
}
.addnewsetting {
  background-color: #9ac13a;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
}
.new-einstellung {
  > span {
    font-weight: bold;
    font-size: 18px;
  }
  .new-einstellung-art-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    > span {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.Einstellungen {
  margin-top: 10px;
}
.einstellung-pre {
  padding: 10px 5px;

  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  .remove {
    color: red;
    position: absolute;
    top: 9px;
    right: 14px;
    font-size: 14px;
  }
  select {
    margin: 5px 0px 15px 0px;
  }
  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}
</style>
