<template>
  <div class="zeitplan">
    <div class="back-navigation">
      <router-link to="/kurse/edit">
        <span class="material-icons">navigate_before</span>
      </router-link>
      <span>Zeitplan</span>
    </div>

    <div class="zeitplan-editor">
      <div class="form-group chose-type-wrap">
        <v-container class="chose-type" fluid>
          <v-switch v-model="zeitplan.wiederholend"></v-switch>
          <label v-if="zeitplan.wiederholend">Wöchentlich</label>
          <label v-else>Einmalig</label>
        </v-container>
      </div>
      <div v-if="zeitplan.wiederholend" class="form-group first">
        <label>Wochentag</label>
        <select v-model="zeitplan.wochentag">
          <option disabled value="null">Wochentag auswählen</option>
          <option value="1">Montag</option>
          <option value="2">Dienstag</option>
          <option value="3">Mittwoch</option>
          <option value="4">Donnerstag</option>
          <option value="5">Freitag</option>
          <option value="6">Samstag</option>
          <option value="7">Sonntag</option>
        </select>
      </div>

      <div v-else class="form-group first">
        <label>Datum</label>
        <input placeholder="Name" v-model="zeitplan.datum" type="date" class="form-control" />
      </div>
      <div class="form-group">
        <label>Startzeit</label>
        <input placeholder="Name" v-model="zeitplan.startzeit" type="time" class="form-control" />
      </div>
      <div class="form-group">
        <label>Dauer</label>
        <input placeholder="Dauer" v-model="zeitplan.dauer" type="number" class="form-control" />
      </div>
      <div class="form-group">
        <label>Raum auswählen</label>
        <select v-model="zeitplan.raum_id">
          <option disabled value="null">Raum auswählen</option>
          <option v-for="(room, room_key) in rooms" :key="room_key" :value="room._id">{{ room.name }}</option>
        </select>
        <div @click="editRooms" class="edit-rooms">Räume bearbeiten</div>
      </div>
      <div class="form-group">
        <user-search instructor :value="zeitplan.instructor_id" rights="gf_c_plan" label="Instruktor" @update="updateInstructor"></user-search>
      </div>

      <div class="form-group grouped">
        <input placeholder="raum" v-model="zeitplan.anmeldung" type="checkbox" class="form-control" />
        <label>Anmeldung</label>
      </div>
      <div class="form-group">
        <label>Anzahl Plätze</label>
        <input placeholder="Anzahl Plätze" v-model="zeitplan.plaetze" type="number" class="form-control" />
      </div>

      <div class="form-group">
        <label>Labels</label>
        <select v-model="labels_selected" v-if="labels.length > 0" class="mul-select" multiple="true">
          <option v-for="(label, j) of labels" :key="j" :value="label._id">{{ label.code }}</option>
        </select>
        <div @click="editLabels" class="edit-labels">Labels bearbeiten</div>
      </div>
      <div v-show="zeitplan.wiederholend" class="form-group">
        <label>Start Datum</label>
        <input placeholder="Name" v-model="zeitplan.start_datum" type="date" class="form-control" />
      </div>
      <div v-show="zeitplan.wiederholend" class="form-group">
        <label>End Datum</label>
        <input placeholder="Name" v-model="zeitplan.end_datum" type="date" class="form-control" />
      </div>
      <div class="form-group grouped">
        <input v-model="zeitplan.push_notification" type="checkbox" class="form-control" />
        <label>Push Notification</label>
      </div>
      <button :disabled="disablesave" class="save" @click.prevent="save">
        Speichern
      </button>
    </div>
    <labeledit ref="labeleditor" v-on:labels-edited="loadLabels"></labeledit>
    <raumedit ref="roomeditor" v-on:rooms-edited="loadRooms"></raumedit>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus'
import Labeledit from '../../components/Label'
import Raumedit from '../../components/Raum'
import api from '../../api'
import userSearch from '../../components/userSearch.vue'

export default {
  components: { Labeledit, Raumedit, userSearch },
  data() {
    return {
      zeitplan: {
        _id: null,
        studio_id: null,
        kurs_id: null,
        raum_id: null,
        instructor_id: null,
        wiederholend: false,
        datum: null,
        wochentag: null,
        startzeit: null,
        dauer: null,
        anmeldung: null,
        plaetze: null,
        start_datum: null,
        end_datum: null,
        push_notification: null,
      },
      disablesave: false,
      labels_selected: [],
      instructors: null,
      labels: [],
      rooms: null,
      editmode: false,
      studio_id: '',
    }
  },
  methods: {
    updateInstructor(id) {
      this.zeitplan.instructor_id = id
    },
    editLabels() {
      this.$refs['labeleditor'].show(this.labels)
    },
    editRooms() {
      this.$refs['roomeditor'].show(this.rooms)
    },
    save() {
      let check = false

      if (this.zeitplan.wiederholend) {
        if (this.zeitplan.wochentag && this.zeitplan.raum_id && this.zeitplan.startzeit && this.zeitplan.dauer && this.zeitplan.instructor_id && this.zeitplan.plaetze) {
          check = true
        }
      } else {
        if (this.zeitplan.datum && this.zeitplan.raum_id && this.zeitplan.startzeit && this.zeitplan.dauer && this.zeitplan.instructor_id && this.zeitplan.plaetze) {
          check = true
        }
      }

      if (check) {
        this.disablesave = true
        this.zeitplan.labels = this.labels_selected
        api
          .saveKurstimeline(this.zeitplan)
          .then((data) => {
            if (data.status) {
              this.disablesave = false
              this.$router.push('/kurse')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        alert('Füllen Sie mehr Daten aus')
      }
    },
    loadRooms() {
      api
        .getRooms(this.studio_id)
        .then((data) => {
          this.rooms = data.room
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loadLabels() {
      api
        .getLabels(this.studio_id)
        .then((data) => {
          this.labels = data
        })
        .catch((error) => {})
    },
  },
  async created() {
    let studio = null

    studio = await api.getUserStudio().catch((e) => {
      window.app.$root.$children[0].checkStudio()
    })
    studio = studio.studio
    let studio_id = studio._id
    this.studio_id = studio_id
    this.zeitplan.kurs_id = this.$route.params.id

    this.zeitplan.studio_id = studio_id
    this.loadLabels()
    this.loadRooms()
  },
}
</script>

<style lang="scss" scoped>
.edit-labels,
.edit-rooms {
  padding: 10px 0px;
  font-size: 12px;
}
.mul-select {
  option {
    color: #4a4a4a !important;
    padding: 5px;
    &:checked {
      background: #52ae32 linear-gradient(0deg, #52ae32 0%, #52ae32 100%);
    }
  }
}

.save {
  margin-bottom: 20px;

  background-color: #52ae32;
}
.save,
.delete {
  width: 100%;
  color: white;
  padding: 10px 30px;
}
.chose-type {
  display: flex;
  padding: 0px;
  label {
    margin: auto 17px;
  }
}

.zeitplan-editor {
  .form-group {
    border-bottom: 1px solid #4a4a4a;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    &.chose-type-wrap {
      margin: 0px;
      border-bottom: none;
    }
    &.first {
      margin-top: 0px;
    }
    label {
      width: 100%;
      font-weight: 300;
    }

    .form-control {
      background-color: transparent;
      border: none;
    }

    input {
      font-weight: 200;

      text-rendering: auto;
      color: #4a4a4a;
      letter-spacing: normal;
      word-spacing: normal;
      text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      display: inline-block;
      text-align: start;

      box-sizing: border-box;
      align-items: center;
      white-space: pre;

      background-color: black;
      cursor: default;
      margin: 0em;
      font: 400 13.3333px Arial;
      border-radius: 0px;
      border-width: 1px;
      border-style: solid;
      border-color: black;
      border-image: initial;
      padding: 0px;
    }

    select {
      width: 100%;
      color: #4a4a4a;
      font-weight: 200;

      option {
        color: black;
      }
    }

    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &.grouped {
      display: flex;
      border-bottom: 0px;
      margin-bottom: 10px;
      label {
        width: 200px;
      }
    }
  }
  padding: 10px 15px;

  color: #4a4a4a;
}
.back-navigation {
  background-color: #52ae32;
  color: white;
  padding: 0px 10px;
  font-weight: 500;
  display: flex;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
  a {
    display: flex;
    padding: 5px;
    color: white;

    span {
      font-size: 34px;
    }
  }
}
.edit-kurse {
  background-color: #52ae32;
  color: white;
  padding: 0px 10px;
  font-weight: 500;
  display: flex;

  a {
    display: flex;
    padding: 5px;
    color: white;
    width: 100%;
    justify-content: space-between;
    span {
      margin-top: auto;
      margin-bottom: auto;
    }
    span.material-icons {
      font-size: 34px;
    }
  }
}
</style>
