<template>
    <v-card style="padding-bottom: 200px !important" flat>
        <v-date-picker v-model="date" @input="generateWeek"></v-date-picker>
        <v-row v-for="(day, index) in week" :key="index" class="my-2">
            <v-col class="d-flex flex-column" cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-chip>{{ day.date }} ({{ day.weekday.substr(0, 2) }})</v-chip>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            v-model="day.times.checkin"
                            label="Check-In"
                            type="time"
                            @change="updateTime(day, 'checkin')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            v-model="day.times.pause"
                            label="Pause Start"
                            type="time"
                            @change="updateTime(day, 'pause')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            v-model="day.times.pauseend"
                            label="Pause Ende"
                            type="time"
                            @change="updateTime(day, 'pauseend')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            v-model="day.times.checkout"
                            label="Check-Out"
                            type="time"
                            @change="updateTime(day, 'checkout')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-chip color="primary">{{ day.workedDuration }} | {{ day.deviation }}</v-chip>
                    </v-col>
                </v-row>
                <v-btn
                    class="ml-auto"
                    outlined
                    :disabled="existingDates.includes(day.date)"
                    color="primary"
                    @click="submitDay(day)"
                >
                    <span v-if="!existingDates.includes(day.date)">Tag speichern</span>
                    <span v-else>Tag bereits vorhanden</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
export default {
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            week: [],
            existingDates: [],
            totalDeviation: 0,
        }
    },
    methods: {
        async fetchExistingTimeEntries() {
            try {
                let status = await api.fetchAllTimeEntriesEmployee(
                    this.$moment(this.date).startOf('week').format('YYYY-MM-DD')
                )
                this.existingDates = status
            } catch (e) {
                console.log(e)
            }
        },
        generateWeek() {
            this.fetchExistingTimeEntries()
            this.week = []
            this.totalDeviation = 0
            const startOfWeek = moment(this.date).startOf('week') // Montag
            const endOfWeek = startOfWeek.clone().add(4, 'days') // Freitag

            for (let i = 0; i <= endOfWeek.diff(startOfWeek, 'days'); i++) {
                const day = moment(startOfWeek).add(i, 'days')
                const times = this.generateRandomTimes(day)
                const workedDuration = this.calculateWorkedDuration(times)
                const deviation = this.calculateDeviation(times)

                if (Math.abs(deviation.asMinutes()) > 25) {
                    this.adjustTimes(times, deviation)
                }

                this.week.push({
                    date: day.format('YYYY-MM-DD'),
                    weekday: day.format('dddd'),
                    times,
                    workedDuration,
                    deviation: this.formatDeviation(deviation),
                })
            }

            this.adjustWeeklyDeviation()
        },
        generateRandomTimes(day) {
            let checkin = moment(day).set({ hour: 7, minute: this.getRandomMinute(0, 25) })
            let pause = moment(checkin).add(5, 'hours').add(this.getRandomMinute(0, 30), 'minutes')
            let pauseend = moment(pause).add(1, 'hour').add(this.getRandomMinute(20, 40), 'minutes')
            let checkout = moment(pauseend).add(3, 'hours').add(this.getRandomMinute(0, 30), 'minutes')

            return {
                checkin: checkin.format('HH:mm'),
                pause: pause.format('HH:mm'),
                pauseend: pauseend.format('HH:mm'),
                checkout: checkout.format('HH:mm'),
            }
        },
        adjustTimes(times, deviation) {
            // Adjust checkout time to ensure deviation is within +/- 25 minutes
            const plannedDuration = moment.duration({ hours: 8, minutes: 25 })
            const adjustment = moment.duration(deviation)
            const checkout = moment(times.checkout, 'HH:mm').subtract(adjustment)

            times.checkout = checkout.format('HH:mm')
        },
        calculateWorkedDuration(times) {
            const checkin = moment(times.checkin, 'HH:mm')
            const pause = moment(times.pause, 'HH:mm')
            const pauseend = moment(times.pauseend, 'HH:mm')
            const checkout = moment(times.checkout, 'HH:mm')

            const workDuration = moment.duration(checkout.diff(checkin))
            const pauseDuration = moment.duration(pauseend.diff(pause))
            const netWorkDuration = workDuration.subtract(pauseDuration)

            const hours = Math.floor(netWorkDuration.asHours())
            const minutes = netWorkDuration.minutes()

            return `${hours}h ${minutes}m`
        },
        calculateDeviation(times) {
            const checkin = moment(times.checkin, 'HH:mm')
            const pause = moment(times.pause, 'HH:mm')
            const pauseend = moment(times.pauseend, 'HH:mm')
            const checkout = moment(times.checkout, 'HH:mm')

            const workDuration = moment.duration(checkout.diff(checkin))
            const pauseDuration = moment.duration(pauseend.diff(pause))
            const netWorkDuration = workDuration.subtract(pauseDuration)

            const plannedDuration = moment.duration({ hours: 8, minutes: 25 })
            return netWorkDuration.subtract(plannedDuration)
        },
        formatDeviation(deviation) {
            const totalMinutes = Math.abs(deviation.asMinutes())
            const hours = Math.floor(totalMinutes / 60)
            const minutes = totalMinutes % 60
            const sign = deviation.asMinutes() < 0 ? '-' : '+'

            // Wenn die Stunden 0 sind, zeige nur die Minuten an
            if (hours === 0) {
                return `${sign}${minutes}m`
            }

            return `${sign}${hours}h ${minutes}m`
        },
        adjustWeeklyDeviation() {
            const plannedWeeklyDuration = moment.duration({ hours: 8, minutes: 25 }).asMinutes() * 5
            let totalDeviation = this.week.reduce((total, day) => total + parseInt(day.deviation), 0)
            const maxAllowedDeviation = 20 // 20 minutes deviation allowed for the entire week

            if (Math.abs(totalDeviation) > maxAllowedDeviation) {
                const adjustmentPerDay = (totalDeviation - maxAllowedDeviation) / 5

                this.week.forEach((day) => {
                    let checkout = moment(day.times.checkout, 'HH:mm')
                    checkout = checkout.subtract(adjustmentPerDay, 'minutes')
                    day.times.checkout = checkout.format('HH:mm')
                    day.deviation = this.formatDeviation(this.calculateDeviation(day.times))
                    day.workedDuration = this.calculateWorkedDuration(day.times)
                })
            }
        },
        getRandomMinute(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min
        },
        updateTime(day, type) {
            // Aktualisiere die Zeit und berechne die Abweichung neu
            const deviation = this.calculateDeviation(day.times)

            if (Math.abs(deviation.asMinutes()) > 25) {
                this.adjustTimes(day.times, deviation)
            }

            day.deviation = this.formatDeviation(this.calculateDeviation(day.times))
            day.workedDuration = this.calculateWorkedDuration(day.times)
            this.adjustWeeklyDeviation()
        },
        async submitDay(day) {
            const payload = {
                date: day.date,
                times: day.times,
            }

            try {
                let status = await api.submitTimePayloadForAdmin({ payload })
                this.$toast.success('Tag gespeichert')
                this.fetchExistingTimeEntries()
            } catch (e) {
                this.$toast.error('Fehler beim Laden')
            }
        },
    },
    created() {
        this.generateWeek()
    },
}
</script>
