<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/bewertungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Bewertungen</span>
		</div>

		<div class="wrap-zeit">
			<v-row class="flex-column">
				<v-col class="mx-auto px-5 justify-center d-flex" cols="12" sm="6">
					<v-date-picker min="2021-10-13" locale="de-DE" v-model="dates" range></v-date-picker>
				</v-col>
				<v-col class="mx-auto mb-10" cols="12" sm="6">
					<v-text-field v-model="dateRangeText" label="Zeitraum" prepend-icon="mdi-calendar" disabled></v-text-field>
				</v-col>
			</v-row>
		</div>

		<div class="wrap-all">
			<div v-show="loading" class="loading">
				Liste wird generiert... Dies kann je nach Auswahl bis zu 2 Minuten dauern
			</div>
			<div class="wrap-actions">
				<div @click="download(3)" v-show="userService.hasRight('kt_viewAll')" class="studiovergleich">
					Gesamt
				</div>
				<div @click="download(0)" v-show="userService.hasRight('kt_viewStudio')" class="studiovergleich">
					Studiovergleich
				</div>
				<div @click="download(1)" v-show="userService.hasRight('kt_trainerVergleich')" class="trainervergleich">
					Trainervergleich
				</div>
				<div @click="download(2)" v-show="userService.hasRight('g_statistics')" class="trainer">
					Meine Statistik
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'
import { saveAs } from 'file-saver'
import zipcelx from 'zipcelx-on-steroids'
import writeXlsxFile from 'write-excel-file'
import userService from '../../services/userService'

export default {
	data() {
		return {
			userService,
			counter: {},
			feedbacks: [],
			api: {},
			dates: [
				new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
			],
			rollen: {},
			loading: false,
		}
	},
	computed: {
		dateRangeText() {
			let date_text = []
			for (let date in this.dates) {
				date_text.push(this.dates[date].split('-')[2] + '.' + this.dates[date].split('-')[1] + '.' + this.dates[date].split('-')[0])
			}
			return date_text.join(' - ')
		},
	},
	methods: {
		showTime(time) {
			return time.split('-')[2] + '.' + time.split('-')[1] + '.' + time.split('-')[0]
		},
		round(number) {
			if (number) {
				return Math.round(number)
			} else {
				return 0
			}
		},
		download(n) {
			this.loading = true
			if (n == 0) {
				this.getStudiovergleich()
			}

			if (n == 1) {
				let studio_ids = [
					'5f326a24ae8863640c859e75',
					'5f3c1241d91ddb464c2648b0',
					'5f3c30f2d91ddb464c2648b5',
					'5f3c30afd91ddb464c2648b1',
					'5f3c30d0d91ddb464c2648b3',
					'5f3c30bfd91ddb464c2648b2',
					'5f3c30e1d91ddb464c2648b4',
					'5f3d482d5dd7955ad48de5e4',
					'5f3d48195dd7955ad48de5e3',
				]

				this.getTrainervergleich(studio_ids)
			}

			if (n == 2) {
				this.getTrainer()
			}
			if (n == 3) {
				this.getTrainerbewertung_gesamt()
			}
		},
		getTrainer() {
			let ref = this
			api.getTrainer().then((data) => {
				let exceldata = []

				exceldata.push([
					{
						value: 'Gesamt',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Gesamt erstellt',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'N Total (Antworten)',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '1',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '2',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '3',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '4',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '5',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
				])
				let colors = {}
				colors['Anamnese'] = '#D9E1F2'
				colors['Trainingsplaene'] = '#C6E0B4'
				let topics = {
					Anamnese: {
						frage_1: 'Warst Du mit Deinem Trainer zufrieden?',
						frage_2: 'Wie hat es Dir im well come FIT gefallen?',
					},
					Trainingsplaene: {
						frage_1: 'Bist Du mit Deinem Trainer zufrieden?',
						frage_2: 'Bist du mit Deinem Trainingsplan zufrieden?',
					},
				}
				// Instructor ids
				let instructor_ids = Object.keys(data)

				for (let topic of Object.keys(topics)) {
					exceldata.push([
						{
							value: topic,
							type: String,
							fontWeight: 'bold',
							backgroundColor: colors[topic].color,
						},
					])

					for (let question of Object.keys(topics[topic])) {
						let key_topic = ''

						for (let instructor of instructor_ids) {
							let pre_excel_data = []
							if (topic == 'Anamnese') {
								key_topic = 'anamnese'
							} else {
								key_topic = 'tp'
							}

							if (instructor == 'gesamt') {
								pre_excel_data.push({
									value: topics[topic][question],
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic].color,
								})
							} else {
								pre_excel_data.push({
									value: data[instructor].instructor.vorname + ' ' + data[instructor].instructor.nachname,
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								})
							}

							let gesamt_instructor =
								data[instructor][key_topic][question].stars['one'] +
								data[instructor][key_topic][question].stars['two'] +
								data[instructor][key_topic][question].stars['three'] +
								data[instructor][key_topic][question].stars['four'] +
								data[instructor][key_topic][question].stars['five']
							pre_excel_data.push(
								{
									value: data[instructor][key_topic].n_c,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								},
								{
									value: gesamt_instructor,

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								},

								{
									value: data[instructor][key_topic][question].stars['one'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['two'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['three'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['four'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['five'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic][question],
								}
							)
							exceldata.push(pre_excel_data)

							exceldata.push([
								{
									value: 'Prozent-Angabe',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: Math.round((100 / data[instructor][key_topic].n_c) * gesamt_instructor) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['one']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['two']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['three']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['four']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['five']) + '%',
									type: String,
									align: 'right',
								},
							])

							// Mittelwert
							let mittelwert = 0
							if (gesamt_instructor > 0) {
								mittelwert = parseFloat(
									(
										(data[instructor][key_topic][question].stars['five'] * 5 +
											data[instructor][key_topic][question].stars['four'] * 4 +
											data[instructor][key_topic][question].stars['three'] * 3 +
											data[instructor][key_topic][question].stars['two'] * 2 +
											data[instructor][key_topic][question].stars['one']) /
										gesamt_instructor
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'MW',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: mittelwert,
									type: Number,
								},
							])

							// STAW
							let staw = 0
							if (gesamt_instructor > 0) {
								staw = parseFloat(
									Math.abs(
										(data[instructor][key_topic][question].stars['five'] * Math.pow(5 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['four'] * Math.pow(4 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['three'] * Math.pow(3 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['two'] * Math.pow(2 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['one'] * Math.pow(1 - mittelwert, 2)) /
											gesamt_instructor
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'STAW',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: staw,
									type: Number,
								},
							])
							exceldata.push([
								{
									value: '',
									type: String,
								},
							])
						}
					}
				}

				writeXlsxFile(exceldata, {
					fileName: 'Trainer.xlsx',
				}).then((d) => {
					ref.loading = false
				})
			})
		},
		async getTrainervergleich(studio_ids) {
			let exceldata_final = []
			let ref = this
			for (let studio_id of studio_ids) {
				let exceldata = []
				let data = []
				try {
					data = await api.getTrainervergleich({
						studio_id: studio_id,
						von: this.dates[0],
						bis: this.dates[1],
					})
				} catch {
					exceldata_final.push(exceldata)
					continue
				}

				exceldata.push([
					{
						value: 'Gesamt',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Stellenprozente',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Erstellte Trainingspläne / \n' + 'Anamnesebogen',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'Anzahl Mitglieder mit Trainingsplan / \n' + 'Anamnesebogen',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'Erstellte Trainingspläne / Anamnesebögen auf 100% Stellenprozente aufgerechnet',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'N Total (Antworten)',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'MW',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'STAW',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '1',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '2',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '3',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '4',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '5',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
				])

				let colors = {}
				colors['Anamnese'] = {
					color: '#2F75B5',
					frage_1: '#D9E1F2',
					frage_2: '#D9E1F2',
				}

				colors['Trainingsplaene'] = {
					color: '#71AF47',
					frage_1: '#C6E0B4',
					frage_2: '#C6E0B4',
				}

				let topics = {
					Anamnese: {
						frage_1: 'Warst Du mit Deinem Trainer zufrieden?',
						frage_2: 'Wie hat es Dir im well come FIT gefallen?',
					},
					Trainingsplaene: {
						frage_1: 'Bist Du mit Deinem Trainer zufrieden?',
						frage_2: 'Bist du mit Deinem Trainingsplan zufrieden?',
					},
				}
				// Instructor ids
				let instructor_ids = Object.keys(data)

				for (let topic of Object.keys(topics)) {
					let pre_excel_data = []
					pre_excel_data.push({
						value: topic,
						type: String,
						fontWeight: 'bold',
						backgroundColor: colors[topic].color,
					})
					for (let i = 0; i <= 11; i++) {
						pre_excel_data.push({
							value: '',
							type: String,
							backgroundColor: colors[topic].color,
						})
					}
					exceldata.push(pre_excel_data)

					for (let question of Object.keys(topics[topic])) {
						let key_topic = ''

						for (let instructor of instructor_ids) {
							let pre_excel_data = []
							if (topic == 'Anamnese') {
								key_topic = 'anamnese'
							} else {
								key_topic = 'tp'
							}

							let percentage_tp_ab = 1

							if (instructor == 'gesamt') {
								let gesamt_percentage = 0
								let instructor_count = 0

								for (let instructor of instructor_ids) {
									if (instructor !== 'gesamt') {
										instructor_count++

										if (data[instructor].instructor.percentage) {
											gesamt_percentage += data[instructor].instructor.percentage
										}
									}
								}

								percentage_tp_ab = parseFloat((data[instructor][key_topic].n_c / gesamt_percentage) * 100 * instructor_count).toFixed(
									2
								)

								pre_excel_data.push(
									{
										value: topics[topic][question],
										type: String,
										fontWeight: 'bold',
										backgroundColor: colors[topic].color,
									},
									{
										value: parseInt(gesamt_percentage),
										type: Number,
										fontWeight: 'bold',
										backgroundColor: colors[topic].color,
									}
								)
							} else {
								percentage_tp_ab = data[instructor].instructor.percentage
									? parseFloat((data[instructor][key_topic].n_c / data[instructor].instructor.percentage) * 100).toFixed(2)
									: 0

								pre_excel_data.push(
									{
										value: data[instructor].instructor.vorname + ' ' + data[instructor].instructor.nachname,
										type: String,
										fontWeight: 'bold',
										backgroundColor: colors[topic][question],
									},
									{
										value: data[instructor].instructor.percentage ? parseInt(data[instructor].instructor.percentage) : 0,
										type: Number,
										fontWeight: 'bold',
										backgroundColor: colors[topic][question],
									}
								)
							}

							let gesamt_instructor =
								data[instructor][key_topic][question].stars['one'] +
								data[instructor][key_topic][question].stars['two'] +
								data[instructor][key_topic][question].stars['three'] +
								data[instructor][key_topic][question].stars['four'] +
								data[instructor][key_topic][question].stars['five']

							// Mittelwert

							let mittelwert = 0

							if (gesamt_instructor > 0) {
								mittelwert = parseFloat(
									(
										(data[instructor][key_topic][question].stars['five'] * 5 +
											data[instructor][key_topic][question].stars['four'] * 4 +
											data[instructor][key_topic][question].stars['three'] * 3 +
											data[instructor][key_topic][question].stars['two'] * 2 +
											data[instructor][key_topic][question].stars['one']) /
										gesamt_instructor
									).toFixed(2)
								)
							}

							let staw = 0
							if (gesamt_instructor > 0) {
								staw = parseFloat(
									Math.abs(
										(data[instructor][key_topic][question].stars['five'] * Math.pow(5 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['four'] * Math.pow(4 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['three'] * Math.pow(3 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['two'] * Math.pow(2 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['one'] * Math.pow(1 - mittelwert, 2)) /
											gesamt_instructor
									).toFixed(2)
								)
							}

							pre_excel_data.push(
								{
									value: data[instructor][key_topic].n_c,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								// Pläne pro Kunde
								{
									value: data[instructor][key_topic].n_c_customer,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},

								// Anzahl erstellte TP auf 100% Stellenprozente aufgerechnet
								{
									value: parseInt(percentage_tp_ab),
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								{
									value: gesamt_instructor,

									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},

								{
									value: mittelwert,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},

								{
									value: staw,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['one'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['two'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['three'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['four'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								},
								{
									value: data[instructor][key_topic][question].stars['five'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: instructor == 'gesamt' ? colors[topic].color : colors[topic][question],
								}
							)
							exceldata.push(pre_excel_data)

							exceldata.push([
								{
									value: 'Prozent-Angabe',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: ref.round((100 / data[instructor][key_topic].n_c) * gesamt_instructor) + '%',
									type: String,
									align: 'right',
								},
								{
									value: '',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['one']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['two']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['three']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['four']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['five']) + '%',
									type: String,
									align: 'right',
								},
							])

							exceldata.push([
								{
									value: '',
									type: String,
								},
							])
						}
					}
				}
				exceldata_final.push(exceldata)
			}

			api.getStudios().then((data) => {
				let sheet_names = []
				for (let stud_id of studio_ids) {
					for (let studio of data) {
						if (studio._id == stud_id) {
							sheet_names.push(
								studio.name
									.replace('A. ', '')
									.replace('B. ', '')
									.replace('C. ', '')
									.replace('D. ', '')
									.replace('E. ', '')
									.replace('F. ', '')
									.replace('G. ', '')
									.replace('H. ', '')
									.replace('I. ', '')
									.replace('J. ', '')
							)
						}
					}
				}
				writeXlsxFile(exceldata_final, {
					sheets: sheet_names,
					columns: [{ width: 40 }, { width: 15 }, { width: 40 }, { width: 50 }, { width: 75 }, { width: 20 }],
					fileName: 'Trainervergleich.xlsx',
				}).then((d) => {
					ref.loading = false
				})
			})
		},
		getTrainerbewertung_gesamt() {
			api.getTrainervergleich_gesamt({ von: this.dates[0], bis: this.dates[1] })
				.then((data) => {
					let exceldata = []

					exceldata.push([
						{
							value: 'Center',
							type: String,
							fontWeight: 'bold',
							backgroundColor: '#92D050',
						},
						{
							value: 'Vorname',
							type: String,
							fontWeight: 'bold',
							backgroundColor: '#92D050',
						},
						{
							value: 'Name',
							type: String,
							fontWeight: 'bold',
							backgroundColor: '#92D050',
						},
						{
							value: 'Stellenprozente',
							type: String,
							fontWeight: 'bold',
							backgroundColor: '#92D050',
						},
						{
							value: 'Erstellte Trainingspläne / \n' + 'Anamnesebogen',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: 'Anzahl Mitglieder mit Trainingsplan / \n' + 'Anamnesebogen',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: 'Erstellte Trainingspläne / Anamnesebögen auf 100% Stellenprozente aufgerechnet',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: 'N Total (Antworten)',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: 'MW',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: 'STAW',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: '1',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: '2',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: '3',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: '4',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
						{
							value: '5',
							type: String,
							fontWeight: 'bold',
							align: 'center',
							backgroundColor: '#92D050',
						},
					])

					for (let instructor in data) {
						let gesamt_instructor =
							data[instructor]['anamnese']['frage_1'].stars['one'] +
							data[instructor]['tp']['frage_1'].stars['one'] +
							data[instructor]['anamnese']['frage_1'].stars['two'] +
							data[instructor]['tp']['frage_1'].stars['two'] +
							+data[instructor]['anamnese']['frage_1'].stars['three'] +
							data[instructor]['tp']['frage_1'].stars['three'] +
							+data[instructor]['anamnese']['frage_1'].stars['four'] +
							data[instructor]['tp']['frage_1'].stars['four'] +
							+data[instructor]['anamnese']['frage_1'].stars['five'] +
							data[instructor]['tp']['frage_1'].stars['five']

						let mittelwert = 0

						if (gesamt_instructor > 0) {
							mittelwert = parseFloat(
								(
									(data[instructor]['anamnese']['frage_1'].stars['five'] * 5 +
										data[instructor]['tp']['frage_1'].stars['five'] * 5 +
										+data[instructor]['anamnese']['frage_1'].stars['four'] * 4 +
										data[instructor]['tp']['frage_1'].stars['four'] * 4 +
										+data[instructor]['anamnese']['frage_1'].stars['three'] * 3 +
										data[instructor]['tp']['frage_1'].stars['three'] * 3 +
										+data[instructor]['anamnese']['frage_1'].stars['two'] * 2 +
										data[instructor]['tp']['frage_1'].stars['two'] * 2 +
										+data[instructor]['anamnese']['frage_1'].stars['one'] * 1 +
										data[instructor]['tp']['frage_1'].stars['one']) /
									gesamt_instructor
								).toFixed(2)
							)
						}

						let staw = 0
						if (gesamt_instructor > 0) {
							staw = parseFloat(
								Math.abs(
									(data[instructor]['anamnese']['frage_1'].stars['five'] * Math.pow(5 - mittelwert, 2) +
										+data[instructor]['tp']['frage_1'].stars['five'] * Math.pow(5 - mittelwert, 2) +
										+data[instructor]['anamnese']['frage_1'].stars['four'] * Math.pow(4 - mittelwert, 2) +
										+data[instructor]['tp']['frage_1'].stars['four'] * Math.pow(4 - mittelwert, 2) +
										+data[instructor]['anamnese']['frage_1'].stars['three'] * Math.pow(3 - mittelwert, 2) +
										+data[instructor]['tp']['frage_1'].stars['three'] * Math.pow(3 - mittelwert, 2) +
										+data[instructor]['anamnese']['frage_1'].stars['two'] * Math.pow(2 - mittelwert, 2) +
										+data[instructor]['tp']['frage_1'].stars['two'] * Math.pow(2 - mittelwert, 2) +
										+data[instructor]['anamnese']['frage_1'].stars['one'] * Math.pow(1 - mittelwert, 2) +
										+data[instructor]['tp']['frage_1'].stars['one'] * Math.pow(1 - mittelwert, 2)) /
										gesamt_instructor
								).toFixed(2)
							)
						}
						exceldata.push([
							{
								value: data[instructor].studio,
								type: String,
							},
							{
								value: data[instructor].instructor.vorname,
								type: String,
							},
							{
								value: data[instructor].instructor.nachname,
								type: String,
							},
							{
								value: data[instructor].instructor.percentage ? data[instructor].instructor.percentage : 0,
								type: Number,
							},
							{
								value: parseInt(data[instructor].anamnese.n_c + data[instructor].tp.n_c),
								type: Number,
								align: 'center',
							},
							{
								value: parseInt(data[instructor].anamnese.n_c_customer + data[instructor].tp.n_c_customer),
								type: Number,

								align: 'center',
							},
							{
								value: data[instructor].instructor.percentage
									? Math.round(
											(parseInt(data[instructor].anamnese.n_c + data[instructor].tp.n_c) /
												parseInt(data[instructor].instructor.percentage)) *
												100
									  )
									: parseInt(data[instructor].anamnese.n_c + data[instructor].tp.n_c),
								type: Number,
								align: 'center',
							},
							{
								value: gesamt_instructor,
								type: Number,
								align: 'center',
							},
							{
								value: mittelwert,
								type: Number,
								align: 'center',
							},
							{
								value: staw,
								type: Number,
								align: 'center',
							},
							{
								value: data[instructor]['anamnese']['frage_1'].stars['one'] + data[instructor]['tp']['frage_1'].stars['one'],
								type: Number,
								align: 'center',
							},
							{
								value: data[instructor]['anamnese']['frage_1'].stars['two'] + data[instructor]['tp']['frage_1'].stars['two'],
								type: Number,
								align: 'center',
							},
							{
								value: data[instructor]['anamnese']['frage_1'].stars['three'] + data[instructor]['tp']['frage_1'].stars['three'],
								type: Number,
								align: 'center',
							},
							{
								value: data[instructor]['anamnese']['frage_1'].stars['four'] + data[instructor]['tp']['frage_1'].stars['four'],
								type: Number,
								align: 'center',
							},
							{
								value: data[instructor]['anamnese']['frage_1'].stars['five'] + data[instructor]['tp']['frage_1'].stars['five'],
								type: Number,
								align: 'center',
							},
						])
					}
					let ref = this
					writeXlsxFile(exceldata, {
						fileName: 'Gesamtauswertung_Trainer.xlsx',
						columns: [
							{ width: 17 },
							{ width: 12 },
							{ width: 20 },
							{ width: 20 },
							{ width: 40 },
							{ width: 50 },
							{ width: 75 },
							{ width: 20 },
						],
					}).then((d) => {
						ref.loading = false
					})
				})
				.catch((e) => {
					console.log(e)
					this.loading = false
					alert('Diese Tabelle ist nicht einsehbar')
				})
		},
		getStudiovergleich() {
			let ref = this
			api.getKreuztabelle().then((data) => {
				let exceldata = []

				exceldata.push([
					{
						value: 'Gesamt',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Gesamt erstellt',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'N Total (Antworten)',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '1',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '2',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '3',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '4',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '5',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
				])

				let topics = {
					Anamnese: {
						frage_1: 'Warst Du mit Deinem Trainer zufrieden?',
						frage_2: 'Wie hat es Dir im well come FIT gefallen?',
					},
					Trainingsplaene: {
						frage_1: 'Bist Du mit Deinem Trainer zufrieden?',
						frage_2: 'Bist du mit Deinem Trainingsplan zufrieden?',
					},
				}
				let colors = {}
				colors['Anamnese'] = '#D9E1F2'
				colors['Trainingsplaene'] = '#C6E0B4'
				// Studio ids
				let studio_ids = Object.keys(data).filter((i) => {
					return i !== '5f5f9e6c17392062f44c5753' && i !== '6001858e404af54a24c6ed60'
				})

				for (let topic of Object.keys(topics)) {
					exceldata.push([
						{
							value: topic,
							type: String,
							fontWeight: 'bold',
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
					])

					for (let question of Object.keys(topics[topic])) {
						let key_topic = ''

						for (let studio of studio_ids) {
							let pre_excel_data = []
							if (topic == 'Anamnese') {
								key_topic = 'anamnese'
							} else {
								key_topic = 'tp'
							}
							if (studio == 'gesamt') {
								pre_excel_data.push({
									value: topics[topic][question],
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							} else {
								pre_excel_data.push({
									value: data[studio].studio
										.replace('A. ', '')
										.replace('B. ', '')
										.replace('C. ', '')
										.replace('D. ', '')
										.replace('E. ', '')
										.replace('F. ', '')
										.replace('G. ', '')
										.replace('H. ', '')
										.replace('I. ', '')
										.replace('J. ', ''),
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							}
							let gesamt_studio =
								data[studio][key_topic][question].stars['one'] +
								data[studio][key_topic][question].stars['two'] +
								data[studio][key_topic][question].stars['three'] +
								data[studio][key_topic][question].stars['four'] +
								data[studio][key_topic][question].stars['five']

							pre_excel_data.push(
								{
									value: data[studio][key_topic].n_c,
									type: Number,
									backgroundColor: colors[topic],
									fontWeight: 'bold',
								},
								{
									value: gesamt_studio,
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},

								{
									value: data[studio][key_topic][question].stars['one'],
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['two'],
									type: Number,
									backgroundColor: colors[topic],
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['three'],
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['four'],
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['five'],
									type: Number,
									backgroundColor: colors[topic],
									fontWeight: 'bold',
								}
							)
							exceldata.push(pre_excel_data)

							exceldata.push([
								{
									value: 'Prozent-Angabe',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: Math.round((100 / data[studio][key_topic].n_c) * gesamt_studio) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['one']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['two']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['three']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['four']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['five']) + '%',
									type: String,
									align: 'right',
								},
							])

							// Mittelwert
							let mittelwert = 0
							if (gesamt_studio > 0) {
								mittelwert = (
									(data[studio][key_topic][question].stars['five'] * 5 +
										data[studio][key_topic][question].stars['four'] * 4 +
										data[studio][key_topic][question].stars['three'] * 3 +
										data[studio][key_topic][question].stars['two'] * 2 +
										data[studio][key_topic][question].stars['one']) /
									gesamt_studio
								).toFixed(2)
							}

							exceldata.push([
								{
									value: 'MW',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: parseFloat(mittelwert),
									type: Number,
								},
							])
							// STAW

							let staw = 0
							if (gesamt_studio > 0) {
								staw = parseFloat(
									Math.abs(
										(data[studio][key_topic][question].stars['five'] * Math.pow(5 - mittelwert, 2) +
											data[studio][key_topic][question].stars['four'] * Math.pow(4 - mittelwert, 2) +
											data[studio][key_topic][question].stars['three'] * Math.pow(3 - mittelwert, 2) +
											data[studio][key_topic][question].stars['two'] * Math.pow(2 - mittelwert, 2) +
											data[studio][key_topic][question].stars['one'] * Math.pow(1 - mittelwert, 2)) /
											gesamt_studio
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'STAW',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: staw,
									type: Number,
								},
							])
							exceldata.push([
								{
									value: '',
									type: String,
								},
							])
						}
					}
				}

				writeXlsxFile(exceldata, {
					fileName: 'Studiovergleich.xlsx',
				}).then((d) => {
					ref.loading = false
				})
			})
		},
		runden(number) {
			if (number && number !== 'infinity') {
				if (number > 100) {
					return 100
				} else {
					return Math.round(number)
				}
			} else {
				return '0'
			}
		},
	},
	updated() {},
	created() {
		api.getUserRolle().then((data) => {
			this.rollen = data
		})
	},
}
</script>
<style lang="scss" scoped>
.wrap-all {
	max-width: 500px;
	margin: 0px auto 60px auto;

	.wrap-actions {
		div {
			width: 100%;
			background-color: var(--v-primary);
			color: white;
			padding: 15px 0px;
			text-align: center;
			margin: 5px 0px;
			letter-spacing: 1px;
			font-weight: bold;
		}
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}

	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
