<template>
  <v-card elevation="0" transparent class="mx-auto">
    <v-row v-if="loaded" style="background-color: #343541">
      <v-col v-if="userService.hasRight('stat_admin')" align="center" cols="12">
        <v-btn @click="reloadStatistics" dark :loading="!finishedLoading" text
          >Aktualisieren</v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-card-title class="white--text">Roody Statistiken</v-card-title>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible1 = isIntersecting)"
        cols="12"
      >
        <v-card v-if="chartVisible1" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
            Anzahl Benutzer pro Studio (roody)
          </v-card-subtitle>
          <v-card-subtitle>Insgesamt: {{ userCount }}</v-card-subtitle>
          <v-col
            v-if="!finishedLoading"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="userCountChartOptionsRoody"
            :series="userCountSeries"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible2 = isIntersecting)"
        cols="12"
        md="6"
      >
        <v-card v-if="chartVisible2" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 white--text font-weight-bold">
            Geschlecht (roody)
          </v-card-subtitle>
          <v-col
            v-if="!finishedLoading"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="barChartOptions"
            :series="userGenderSeries"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible3 = isIntersecting)"
        cols="12"
        md="6"
      >
        <v-card v-if="chartVisible3" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text"
            >Alter (roody)</v-card-subtitle
          >
          <v-col
            v-if="!finishedLoading"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="barChartOptions"
            :series="userAgeSeries"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loaded" style="background-color: #343541">
      <v-col cols="12">
        <v-card-title class="white--text">TAC Statistiken</v-card-title>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible4 = isIntersecting)"
        cols="12"
      >
        <v-card v-if="chartVisible4" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
            Anzahl aktive Kunden pro Studio (TAC)
          </v-card-subtitle>
          <v-card-subtitle>Insgesamt: {{ userCountTAC }}</v-card-subtitle>
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="userCountChartOptionsTAC"
            :series="userCountSeriesTAC"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible5 = isIntersecting)"
        cols="12"
      >
        <v-card v-if="chartVisible5" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
            Anzahl aktive Kunden pro Studio aufgeteilt nach Abo Name (TAC)
          </v-card-subtitle>
          <v-card-subtitle>Insgesamt: {{ userCountTAC }}</v-card-subtitle>
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="userCountSubscriptionChartOptionsTAC"
            :series="userCountSubscriptionSeriesTAC"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible6 = isIntersecting)"
        cols="12"
      >
        <v-card v-if="chartVisible6" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
            Anzahl aktive Kunden pro Studio aufgeteilt nach Abo Name | gruppiert
            (TAC)
          </v-card-subtitle>
          <v-card-subtitle>Insgesamt: {{ userCountTAC }}</v-card-subtitle>
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="userCountSubscriptionChartOptionsTACGrouped"
            :series="userCountSubscriptionSeriesTACGrouped"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible7 = isIntersecting)"
        cols="12"
        md="6"
      >
        <v-card v-if="chartVisible7" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 white--text font-weight-bold">
            Geschlecht (TAC)
          </v-card-subtitle>
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="barChartOptionsTAC"
            :series="userGenderSeriesTAC"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible8 = isIntersecting)"
        cols="12"
        md="6"
      >
        <v-card v-if="chartVisible8" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text"
            >Alter (TAC)</v-card-subtitle
          >
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="barChartOptionsTAC"
            :series="userAgeSeriesTAC"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible9 = isIntersecting)"
        cols="12"
      >
        <v-card v-if="chartVisible9" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
            Anzahl Kunden pro m² (TAC)
          </v-card-subtitle>
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="userCountChartOptionsTACm2"
            :series="activeTACUsersArea"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col
        v-intersect="(isIntersecting) => (chartVisible10 = isIntersecting)"
        cols="12"
      >
        <v-card v-if="chartVisible10" class="rounded-xl pa-1" hover dark>
          <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
            Anzahl Kunden pro 100% (TAC)
          </v-card-subtitle>
          <v-col
            v-if="!finishedLoadingTAC"
            class="d-flex justify-center"
            cols="12"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <apexchart
            v-else
            :options="userCountChartOptionsTACpw"
            :series="customerPerTrainerTAC"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="background-color: #343541" v-else>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="primary" @click="fetchDataNew">Statistiken laden</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import api from "../../../api";
import userService from "@/services/userService";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "dashboard",
  props: {
    studios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userService,
      loaded: false,
      chartVisible1: false,
      chartVisible2: false,
      chartVisible3: false,
      chartVisible4: false,
      chartVisible5: false,
      chartVisible6: false,
      chartVisible7: false,
      chartVisible8: false,
      chartVisible9: false,
      chartVisible10: false,
      chartVisible11: false,
      chartVisible12: false,
      dashboardUserStatistics: {},
      dashboardUserTACStatistics: {},
      dashboardUserInfos: [],
      finishedLoading: true,
      finishedLoadingTAC: true,
      init: true,
    };
  },
  async created() {
    if (!this.loaded) {
      this.fetchDataNew();
    }
  },
  methods: {
    async reloadStatistics() {
      try {
        this.finishedLoadingTAC = false;
        this.finishedLoading = false;
        let status = await api.reloadDashboardUserStatistics();
        this.$toast.success("Daten aktualisiert");
        this.fetchDataNew();
      } catch (e) {
        this.finishedLoadingTAC = true;
        this.finishedLoading = true;
        this.$toast.error("Fehler beim aktualisieren");
      }
    },
    async fetchDataNew() {
      this.loaded = true;
      this.finishedLoadingTAC = false;
      this.finishedLoading = false;
      const statistics = await api.fetchDashboardUserStatisticsNew({
        studios: this.studios.map((e) => e._id),
      });
      this.dashboardUserTACStatistics = statistics.tacStatistics;
      this.dashboardUserStatistics = statistics.statistics;
      this.finishedLoadingTAC = true;
      this.finishedLoading = true;
    },
    async fetchTACData() {
      this.fetchDataNew();
      return;
      this.finishedLoadingTAC = false;
      this.dashboardUserTACStatistics =
        await api.fetchDashboardUserTACStatistics({ studios: this.studios });
      this.finishedLoadingTAC = true;
      this.$forceUpdate();
    },
    async fetchData() {
      this.fetchDataNew();
      return;
      this.finishedLoading = false;
      this.dashboardUserStatistics = await api.fetchDashboardUserStatistics({
        studios: this.studios,
      });
      this.finishedLoading = true;
      this.$forceUpdate();
    },
  },
  watch: {
    studios: {
      handler: function (val, oldVal) {
        this.fetchDataNew();
      },
      deep: true,
    },
  },
  computed: {
    userCountTAC() {
      let amount = this.dashboardUserTACStatistics.activeTACCustomerAmount || 0;
      return amount;
    },
    userCount() {
      let amount = 0;
      for (let graph of this.userAgeSeries) {
        for (let amountStudio of graph.data) {
          amount += amountStudio.y;
        }
      }
      return amount;
    },
    userAgeSeries() {
      return this.dashboardUserStatistics.ageSeriesData
        ? this.dashboardUserStatistics.ageSeriesData
        : [];
    },

    userCountSeries() {
      let data = this.dashboardUserStatistics.userCountSeries
        ? this.dashboardUserStatistics.userCountSeries
        : [];

      let dataSorted = data
        .slice()
        .sort(
          (a, b) =>
            b.usersWithCheckins +
            b.usersWithoutCheckins -
            (a.usersWithCheckins + a.usersWithoutCheckins)
        );

      return [
        {
          name: "Kunden mit Check-ins",
          data: dataSorted.map((item) => item.usersWithCheckins),
        },
        {
          name: "Kunden ohne Check-ins",
          data: dataSorted.map((item) => item.usersWithoutCheckins),
        },
      ];
    },
    userCountSubscriptionSeriesTACGrouped() {
      let data = this.dashboardUserTACStatistics
        .TACUsersBySubscriptionTypeGrouped
        ? this.dashboardUserTACStatistics.TACUsersBySubscriptionTypeGrouped
        : [];

      let dataSorted = data.slice().sort((a, b) => b.length - a.length);

      let allSubscriptionNames = [];
      data.forEach((e) => {
        for (let key of Object.keys(e)) {
          if (key === "location" || key === "length") continue;
          if (!allSubscriptionNames.includes(key))
            allSubscriptionNames.push(key);
        }
      });

      let returnArray = [];

      for (let name of allSubscriptionNames) {
        returnArray.push({
          name,
          data: dataSorted.map((e) => e[name] || 0),
        });
      }

      return returnArray;
    },
    userCountSubscriptionSeriesTAC() {
      let data = this.dashboardUserTACStatistics.TACUsersBySubscriptionType
        ? this.dashboardUserTACStatistics.TACUsersBySubscriptionType
        : [];

      let dataSorted = data.slice().sort((a, b) => b.length - a.length);

      let allSubscriptionNames = [];
      data.forEach((e) => {
        for (let key of Object.keys(e)) {
          if (key === "location" || key === "length") continue;
          if (!allSubscriptionNames.includes(key))
            allSubscriptionNames.push(key);
        }
      });

      let returnArray = [];

      for (let name of allSubscriptionNames) {
        returnArray.push({
          name,
          data: dataSorted.map((e) => e[name] || 0),
        });
      }

      return returnArray;
    },
    userCountSeriesTAC() {
      let data = this.dashboardUserTACStatistics.activeTACUsers
        ? this.dashboardUserTACStatistics.activeTACUsers
        : [];

      let dataSorted = data
        .slice()
        .sort(
          (a, b) =>
            b.emailsFoundAmount +
            b.restAmount -
            (a.emailsFoundAmount + a.restAmount)
        );

      return [
        {
          name: "Kunden mit roody Account",
          data: dataSorted.map((item) => item.emailsFoundAmount),
        },
        {
          name: "Kunden ohne roody Account",
          data: dataSorted.map((item) => item.restAmount),
        },
      ];
    },
    customerPerTrainerTAC() {
      let data = this.dashboardUserTACStatistics.customerPerTrainerTAC
        ? this.dashboardUserTACStatistics.customerPerTrainerTAC
        : [];

      let dataSorted = data.slice().sort((a, b) => b.amount - a.amount);

      return [
        {
          name: "Kunden pro Trainer",
          data: dataSorted.map((item) => item.amount),
        },
      ];
    },
    activeTACUsersArea() {
      let data = this.dashboardUserTACStatistics.activeTACUsersArea
        ? this.dashboardUserTACStatistics.activeTACUsersArea
        : [];

      let dataSorted = data.slice().sort((a, b) => b.amount - a.amount);
      return [
        {
          name: "Kunden pro Quadratmeter",
          data: dataSorted.map((item) => item.amount),
        },
      ];
    },
    userGenderSeries() {
      return this.dashboardUserStatistics.userGenderSeries
        ? this.dashboardUserStatistics.userGenderSeries
        : [];
    },
    userGenderSeriesTAC() {
      return this.dashboardUserTACStatistics.userGenderSeriesTAC
        ? this.dashboardUserTACStatistics.userGenderSeriesTAC
        : [];
    },
    userAgeSeriesTAC() {
      return this.dashboardUserTACStatistics.userAgeSeriesTAC
        ? this.dashboardUserTACStatistics.userAgeSeriesTAC
        : [];
    },
    userCountChartOptionsTACpw() {
      let chartOptions = JSON.parse(JSON.stringify(this.userCountChartOptions));
      let data = this.dashboardUserTACStatistics.customerPerTrainerTAC
        ? this.dashboardUserTACStatistics.customerPerTrainerTAC
        : [];

      let dataSorted = data.slice().sort((a, b) => b.amount - a.amount);
      chartOptions.xaxis.categories = dataSorted.map((item) => item.location);
      return chartOptions;
    },
    userCountChartOptionsTACm2() {
      let chartOptions = JSON.parse(JSON.stringify(this.userCountChartOptions));
      let data = this.dashboardUserTACStatistics.activeTACUsersArea
        ? this.dashboardUserTACStatistics.activeTACUsersArea
        : [];

      let dataSorted = data.slice().sort((a, b) => b.amount - a.amount);
      chartOptions.xaxis.categories = dataSorted.map((item) => item.location);
      return chartOptions;
    },
    userCountSubscriptionChartOptionsTACGrouped() {
      let chartOptions = JSON.parse(JSON.stringify(this.userCountChartOptions));
      let data = this.dashboardUserTACStatistics
        .TACUsersBySubscriptionTypeGrouped
        ? this.dashboardUserTACStatistics.TACUsersBySubscriptionTypeGrouped
        : [];
      let dataSorted = data.slice().sort((a, b) => b.length - a.length);

      chartOptions.xaxis.categories = dataSorted.map((item) => item.location);

      return chartOptions;
    },
    userCountSubscriptionChartOptionsTAC() {
      let chartOptions = JSON.parse(JSON.stringify(this.userCountChartOptions));
      let data = this.dashboardUserTACStatistics.TACUsersBySubscriptionType
        ? this.dashboardUserTACStatistics.TACUsersBySubscriptionType
        : [];
      let dataSorted = data.slice().sort((a, b) => b.length - a.length);

      chartOptions.xaxis.categories = dataSorted.map((item) => item.location);

      return chartOptions;
    },
    userCountChartOptionsTAC() {
      let chartOptions = JSON.parse(JSON.stringify(this.userCountChartOptions));
      let data = this.dashboardUserTACStatistics.activeTACUsers
        ? this.dashboardUserTACStatistics.activeTACUsers
        : [];
      let dataSorted = data
        .slice()
        .sort(
          (a, b) =>
            b.emailsFoundAmount +
            b.restAmount -
            (a.emailsFoundAmount + a.restAmount)
        );

      chartOptions.xaxis.categories = dataSorted.map((item) => item.location);

      return chartOptions;
    },
    userCountChartOptionsRoody() {
      let chartOptions = JSON.parse(JSON.stringify(this.userCountChartOptions));
      let data = this.dashboardUserStatistics.userCountSeries
        ? this.dashboardUserStatistics.userCountSeries
        : [];

      let dataSorted = data
        .slice()
        .sort(
          (a, b) =>
            b.usersWithCheckins +
            b.usersWithoutCheckins -
            (a.usersWithCheckins + a.usersWithoutCheckins)
        );

      chartOptions.xaxis.categories = dataSorted.map((item) => item.location);

      return chartOptions;
    },
    userCountChartOptions() {
      return {
        colors: [
          "#1abc9c", // Türkis
          "#2ecc71", // Smaragdgrün
          "#3498db", // Peter-River-Blau
          "#9b59b6", // Amethyst
          "#34495e", // Nassasphalt
          "#16a085", // Grüne Meeresalge
          "#27ae60", // Nephritis
          "#2980b9", // Belize-Loch
          "#8e44ad", // Wisteria
          "#2c3e50", // Mitternachtsblau
        ],
        xaxis: {
          labels: {
            show: true,
          },
          categories: [],
        },
        yaxis: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: false,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "12px",
                  fontWeight: 900,
                  color: "white",
                },
              },
            },
          },
        },

        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        grid: {
          borderColor: "#535A6C",
        },
        tooltip: {
          theme: "dark",
          y: {
            formatter: function (val, opts) {
              const index = opts.dataPointIndex;
              const total = opts.w.globals.series
                .map((s) => s[index])
                .reduce((a, b) => a + b, 0);
              const percent = ((val / total) * 100).toFixed(2);
              return val + " (" + percent + "%)";
            },
          },
        },
        chart: {
          foreColor: "#ccc",
          type: "bar",
          id: "userCountChart",
          stacked: true,
          toolbar: {
            zoom: false,
          },
        },

        fill: {
          opacity: 1,
        },
      };
    },
    barChartOptionsTAC() {
      let deLength = 0;
      if (this.dashboardUserTACStatistics.activeTACCustomerAmount) {
        deLength = this.dashboardUserTACStatistics.activeTACCustomerAmount;
      }
      return {
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 10,
            columnWidth: "60%",
            dataLabels: {
              total: {
                enabled: true,
                formatter: function (val) {
                  return (
                    Math.round(((val * 100) / deLength) * 100) / 100 + " %"
                  );
                },
                style: {
                  color: "white",
                  fontSize: "12px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: "#535A6C",
        },
        tooltip: {
          theme: "dark",
          y: {
            formatter: function (val, opts) {
              const index = opts.dataPointIndex;
              const total = opts.w.globals.series
                .map((s) => s[index])
                .reduce((a, b) => a + b, 0);
              const percent = ((val / total) * 100).toFixed(2);
              return val + " (" + percent + "%)";
            },
          },
        },
        chart: {
          foreColor: "#ccc",
          type: "bar",
          stacked: true,
          id: "genderChart",
          toolbar: {
            zoom: false,
          },
          fill: {
            opacity: 1,
          },
        },
      };
    },
    barChartOptions() {
      let deLength = 0;

      if (this.dashboardUserStatistics.userLength) {
        deLength = this.dashboardUserStatistics.userLength;
      }
      return {
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 10,
            columnWidth: "60%",
            dataLabels: {
              total: {
                enabled: true,
                formatter: function (val) {
                  return (
                    Math.round(((val * 100) / deLength) * 100) / 100 + " %"
                  );
                },
                style: {
                  color: "white",
                  fontSize: "12px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: "#535A6C",
        },
        tooltip: {
          theme: "dark",
          y: {
            formatter: function (val, opts) {
              const index = opts.dataPointIndex;
              const total = opts.w.globals.series
                .map((s) => s[index])
                .reduce((a, b) => a + b, 0);
              const percent = ((val / total) * 100).toFixed(2);
              return val + " (" + percent + "%)";
            },
          },
        },
        chart: {
          foreColor: "#ccc",
          type: "bar",
          stacked: true,
          id: "genderChart",
          toolbar: {
            zoom: false,
          },
          fill: {
            opacity: 1,
          },
        },
      };
      /*colors: [
				this.$vuetify.theme.themes.light.primary,
				this.$vuetify.theme.themes.light.darkenprimary1,
				this.$vuetify.theme.themes.light.darkenprimary2,
			],*/
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .apexcharts-menu-open {
  background-color: #343541 !important;
  height: 80vh;
}
</style>
