<template xmlns="">
    <image-overlapping-card
        v-if="displayContent"
        no-dialog
        :rightIcon="statisticButton"
        :leftIcon="manualButton"
        style="padding-bottom: 100px"
        @right-clicked="statisticOpen = true"
        @left-clicked="guideOpen = true"
    >
        <template v-slot:image>
            <v-img
                @click="guideOpen = true"
                height="35vh"
                v-if="exercise.picture || (exercise.manual[0] && exercise.manual[0].picture)"
                :src="exercise.picture ? getImageUrl(exercise.picture) : getImageUrl(exercise.manual[0].picture)"
            ></v-img>
        </template>
        <template v-slot:content>
            <v-row no-gutters class="formCard mb-4">
                <v-col class="pa-0" cols="12">
                    <v-row
                        no-gutters
                        class="formTitle formCard-title d-flex flex-column py-0 justify-center align-items-center mx-0 mb-0"
                    >
                        <v-card-subtitle v-if="exercise.deviceShortForm" class="pb-0 font-weight-bold">
                            {{ exercise.deviceShortForm }}
                        </v-card-subtitle>
                        <v-card-title
                            style="letter-spacing: 1px"
                            class="h5 pt-0 pb-5 my-0 font-weight-bold text-uppercase"
                        >
                            {{ exercise.title }}
                        </v-card-title>
                    </v-row>
                </v-col>
            </v-row>

            <v-row
                dense
                class="workout-add-exercise__card-new-step--container mx-5 mt-3 elevation-7"
                v-if="getGeneralSettings.length"
            >
                <v-col class="pa-0" cols="12">
                    <v-row
                        no-gutters
                        class="workout-add-exercise__card-new-step--active workout-add-exercise__card-new-step d-flex flex-row py-3 mt-0 mb-2"
                    >
                        <v-col cols="12" class="d-flex pa-0">
                            <lottie-player
                                mode="normal"
                                style="width: 45px; height: 45px"
                                class="ml-5 mr-3"
                                :autoplay="false"
                                src="/img/lottiefiles/settings/deviceManagement.json"
                            ></lottie-player>
                            <v-card-title
                                style="letter-spacing: 1px"
                                class="h6 mb-0 pa-0 py-3 font-weight-bold text-uppercase"
                            >
                                Geräteeinstellungen
                            </v-card-title>
                        </v-col>
                    </v-row>
                </v-col>
                <v-row no-gutters class="px-5 py-2">
                    <v-col
                        cols="auto"
                        class="pa-1"
                        :key="index + 'deviceSetting'"
                        v-for="(deviceSetting, index) in getGeneralSettings"
                    >
                        <v-card class="mb-2" :color="deviceSetting.value ? 'listitemcolor' : 'grey'">
                            <v-card-title class="pa-1" :class="{ subtitle: !deviceSetting.value }">
                                {{ shorten(deviceSetting.text, 10) }}
                            </v-card-title>
                            <v-card-subtitle
                                class="pa-0 text-center"
                                :class="{ 'white--text h2 pa-0': !deviceSetting.value, 'pa-2': deviceSetting.value }"
                            >
                                {{ deviceSetting.value ? getSettingText(deviceSetting) : '+' }}
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-row>

            <v-row
                :key="'finishedSet' + index"
                v-for="(set, index) in filteredFinishedSteps"
                dense
                no-gutters
                class="workout-add-exercise__card-new-step--container mx-5 mt-3"
                :class="{ 'elevation-7': set.active }"
            >
                <v-col class="pa-0" cols="12">
                    <v-row
                        no-gutters
                        class="workout-add-exercise__card-finished-step d-flex flex-row mx-0 my-0"
                        :class="{ 'workout-add-exercise__card-new-step--active py-3': set.active }"
                    >
                        <v-col cols="9" class="d-flex pa-0">
                            <lottie-player
                                mode="normal"
                                style="width: 45px; height: 45px"
                                class="ml-5 mr-3"
                                :autoplay="false"
                                src="/img/lottiefiles/set.json"
                            ></lottie-player>
                            <v-card-title
                                style="letter-spacing: 1px"
                                class="h6 mb-0 pa-0 font-weight-bold text-uppercase"
                                :class="{ 'py-3': set.active }"
                            >
                                {{
                                    isSensoPro
                                        ? 'gespeicherte Notiz '
                                        : 'Satz ' + (index + 1) + ' / ' + exercise.sets.length
                                }}
                            </v-card-title>
                        </v-col>
                        <v-col cols="3" class="pa-0">
                            <v-btn @click="toggleFinishedStep(index)" icon>
                                <v-icon color="white">
                                    {{ set.active ? 'mdi-close-circle' : 'mdi-pencil-circle' }}
                                </v-icon>
                            </v-btn>
                            <v-btn @click="deleteFinishedStep(index)" icon>
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-expand-transition>
                    <div v-show="set.activeBefore" style="width: 100%">
                        <v-row class="px-5 pt-5">
                            <v-col
                                cols="auto"
                                class="pa-1"
                                :key="setIndex + 'setSetting'"
                                v-for="(setSetting, setIndex) in filteredSetSettings(set)"
                            >
                                <v-card :color="setSetting.color ? 'orange' : 'listitemcolor'">
                                    <v-card-title class="subitle pa-1">
                                        {{ setSetting.text }}
                                    </v-card-title>
                                    <v-card-subtitle
                                        class="text-center"
                                        :class="{ 'white--text h2 pa-0': !setSetting.value, 'pa-2': setSetting.value }"
                                    >
                                        {{
                                            setSetting.text === 'Gewicht'
                                                ? set.weight + ' KG'
                                                : setSetting.text === 'Dauer'
                                                ? set.time + ''
                                                : set.reps
                                                ? set.reps
                                                : set.note || set.value
                                        }}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="justify-space-around mb-3">
                            <v-btn
                                :style="'background: var(--v-primary);'"
                                height="60px"
                                width="60px"
                                icon
                                x-large
                                elevation="3"
                                @click="openSetDialogBottomNavigation(index)"
                            >
                                <lottie-player
                                    mode="normal"
                                    style="width: 60px; height: 60px"
                                    :name="'existingStep' + index"
                                    :autoplay="false"
                                    src="/img/lottiefiles/editSet.json"
                                ></lottie-player>
                            </v-btn>
                        </v-row>
                    </div>
                </v-expand-transition>
            </v-row>

            <v-row
                dense
                no-gutters
                style="margin-bottom: 50px"
                class="workout-add-exercise__card-new-step--container mx-5 mt-3 elevation-7"
                v-if="activeSet && sensoProSetNotDone"
            >
                <v-col class="pa-0" cols="12">
                    <v-row
                        no-gutters
                        class="workout-add-exercise__card-new-step workout-add-exercise__card-new-step--active py-3 d-flex flex-row mx-0 my-0"
                    >
                        <v-col cols="10" class="d-flex pa-0">
                            <lottie-player
                                mode="normal"
                                style="width: 45px; height: 45px"
                                class="ml-5 mr-3"
                                :autoplay="false"
                                src="/img/lottiefiles/set.json"
                            ></lottie-player>
                            <v-card-title
                                style="letter-spacing: 1px"
                                class="h6 mb-0 pa-0 font-weight-bold py-3 text-uppercase"
                            >
                                {{
                                    isSensoPro
                                        ? 'Notiz hinzufügen'
                                        : setsFinished
                                        ? 'Zusätlicher Satz'
                                        : 'Satz ' + (activeSetIndex + 1) + ' / ' + exercise.sets.length
                                }}
                            </v-card-title>
                        </v-col>
                    </v-row>
                </v-col>
                <v-expand-transition>
                    <div class="expand-transition-div">
                        <v-row no-gutters class="px-5 pt-5">
                            <v-col
                                cols="auto"
                                class="pa-1"
                                :key="setIndex + 'activeSetSetting'"
                                v-for="(setSetting, setIndex) in getSetSettings(activeSet.settings)"
                            >
                                <v-card
                                    :color="
                                        setSetting.color
                                            ? setSetting.color
                                            : setSetting.value
                                            ? 'listitemcolor'
                                            : 'grey'
                                    "
                                    :class="{
                                        'white--text': setSetting.color,
                                    }"
                                >
                                    <v-card-title class="subitle pa-1">
                                        {{ shorten(setSetting.text, 10) }}
                                        <v-icon v-if="setSetting.color" color="white">mdi-history</v-icon>
                                    </v-card-title>
                                    <v-card-subtitle
                                        class="text-center"
                                        :class="{
                                            'white--text h2 pa-0': !setSetting.value,
                                            'pa-2': setSetting.value,
                                            'white--text': setSetting.color,
                                        }"
                                    >
                                        {{ setSetting.value ? getSettingText(setSetting) : '+' }}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="justify-space-around mb-3">
                            <v-btn
                                :style="'background: var(--v-primary);'"
                                height="60px"
                                width="60px"
                                icon
                                x-large
                                elevation="3"
                                @click="openSetDialogBottomNavigation()"
                            >
                                <lottie-player
                                    mode="normal"
                                    style="width: 60px; height: 60px"
                                    name="newStep"
                                    :autoplay="false"
                                    src="/img/lottiefiles/addNewStep.json"
                                ></lottie-player>
                            </v-btn>
                        </v-row>
                    </div>
                </v-expand-transition>
            </v-row>
            <v-dialog v-model="secondStepMoreWeightDialog">
                <v-card>
                    <v-card-title>Super gemacht!</v-card-title>
                    <v-card-text>
                        Du hast mehr Gewicht als beim ersten Satz gewählt. Möchtest du nächstes Training mit diesem
                        Gewicht starten?
                    </v-card-text>
                    <v-card-text>
                        <v-checkbox v-model="noForce" label="Bei Übung nicht mehr anzeigen"></v-checkbox>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-space-around">
                        <v-btn color="primary" @click="closeSecondStepMoreWeightDialog(false)">Nein</v-btn>
                        <v-btn color="primary" @click="closeSecondStepMoreWeightDialog(true)">Ja</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <workingout-exercise-note v-if="noteDialog" :exercise-id="propExercise._id"></workingout-exercise-note>
            <workingout-exercise-statistic
                :title="exercise.title"
                :plan_id="$route.params.id"
                :exercise_id="propExercise._id"
                v-if="statisticOpen"
            ></workingout-exercise-statistic>
            <workingout-exercise-guide
                v-if="guideOpen"
                :exercise_id="exercise._id"
                :manual="exercise.manual"
            ></workingout-exercise-guide>
        </template>
    </image-overlapping-card>
</template>

<script>
import api from '../../api'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SwiperSlide } from 'vue-awesome-swiper'
import imageOverlappingCard from '../../components/imageOverlappingCard.vue'
import WorkingoutExerciseStatistic from './WorkingoutExerciseStatistic'
import WorkingoutExerciseNote from './WorkingoutExerciseNote'
import WorkingoutExerciseGuide from './WorkingoutExerciseGuide'
export default {
    name: 'WorkoutStepEditable',
    components: {
        SwiperSlide,
        imageOverlappingCard,
        WorkingoutExerciseStatistic,
        WorkingoutExerciseGuide,
        WorkingoutExerciseNote,
    },
    props: {
        propExercise: {
            type: Object,
            required: true,
        },
        activeSlide: {
            type: Boolean,
        },
        displayContent: {
            type: Boolean,
        },
    },
    data() {
        return {
            noForce: false,
            newStepValue: null,
            statisticOpen: false,
            guideOpen: false,
            activeSet: null,
            noteDialog: false,
            secondStepMoreWeightDialog: false,
            exerciseBackup: null,
            statisticButton: {
                color: 'primary',
                icon: 'mdi-chart-timeline-variant-shimmer',
            },
            manualButton: {
                color: 'primary',
                icon: 'mdi-information-outline',
            },
            exercise: {
                title: '',
                general_exercise_settings: [],
                set_exercise_settings: [],
                sets: [],
                manual: [],
            },
            editModeActive: false,
            exercises: [],
            settingDialogVisible: false,
            activeSetting: '',
            settingInEditMode: null,
            newStepActive: false,
            newStepActiveBefore: false,
            sets: [],
            addStepIcons: [],
            editStepBackup: null,
        }
    },
    created() {
        // this.setNewBottomNavigationUsage(null)
        // this.$emit('updateBottomNavigation', this.exercise.title)
        this.exercise = JSON.parse(JSON.stringify(this.propExercise))
        this.exerciseBackup = JSON.parse(JSON.stringify(this.propExercise))
        if (this.workoutStepsHistory) {
            let history = this.workoutStepsHistory.filter((history) => history.exercise_id === this.exercise._id)
            if (history && history.length) {
                let historyItem = history[history.length - 1]

                if (historyItem && historyItem.steps && historyItem.steps.length) {
                    historyItem.steps.forEach((step, index) => {
                        if (
                            this.exercise.sets &&
                            this.exercise.sets.length &&
                            this.exercise.sets[index] &&
                            this.exercise.sets[index].settings
                        ) {
                            this.exercise.sets[index].settings.push({
                                input: 'number',
                                text: 'WH ',
                                color: 'orange',
                                value: step.reps,
                            })
                            this.exerciseBackup.sets[index].settings.push({
                                input: 'number',
                                text: 'WH ',
                                color: 'orange',
                                value: step.reps,
                            })
                        }
                    })
                }
            }
        }

        if (this.exercise.sets.length) {
            let setIndex =
                this.filteredFinishedSteps.length >= this.exercise.sets.length
                    ? this.exercise.sets.length - 1
                    : this.filteredFinishedSteps.length
            this.activeSet = JSON.parse(JSON.stringify(this.exercise.sets[setIndex]))
            this.activeSet.title = this.exercise.title
        }
    },

    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNewBottomNavigationUsage']),
        ...mapActions('workingoutStore', [
            'setSavedNote',
            'removeStepByIndex',
            'addNewStep',
            'updateStep',
            'setDefaultValue',
            'updateExerciseInWorkoutCreateExercises',
            'removeExerciseByIndex',
            'toggleFinishedStepActive',
            'toggleFinishedStepActiveBefore',
        ]),
        ...mapActions('deviceStore', ['setDeviceList']),
        closeSecondStepMoreWeightDialog(forceValue) {
            if (this.noForce) this.$emit('noForce')
            this.secondStepMoreWeightDialog = false
            if (forceValue) {
                let firstStep = JSON.parse(JSON.stringify(this.filteredFinishedSteps[0]))
                firstStep.forceValue = this.newStepValue.weight
                this.updateStep({ plan_id: this.$route.params.id, exercise_id: this.exercise._id, value: firstStep })
            }
            this.addStepToStore()
        },
        animateNoteIcon(animation) {
            this.addStepIcons.push(animation)
        },
        startNoteAnimation(item, custom = false) {
            this.addStepIcons.forEach((animation) => {
                animation.stop()
                if (animation.name === item) animation.play()
            })
        },
        filteredSetSettings(set) {
            let returnArray = []
            if (set.weight) returnArray.push({ text: 'Gewicht', value: set.weight })
            if (set.reps) returnArray.push({ text: 'Wiederholungen', value: set.reps })
            if (set.time) returnArray.push({ text: 'Dauer', value: set.time })
            if (set.note) returnArray.push({ text: 'Notiz', value: set.note })

            return returnArray
        },
        openSetDialogBottomNavigation(index = -1) {
            if (this.navigationBarUsage && this.navigationBarUsage.usage === 'workoutEnterSet') {
                if (this.noteDialog) this.toggleNoteDialog
                this.setNewBottomNavigationUsage(null)
                this.$emit('updateBottomNavigation', this.exercise.title)
            } else {
                index === -1 ? this.startNoteAnimation('newStep') : this.startNoteAnimation('existingStep' + index)
                let set = this.activeSet
                if (index > -1) {
                    set = this.filteredFinishedSteps[index]
                    set.index = index
                }
                if (set.title === 'Senso Pro' || set.note) {
                    this.toggleNoteDialog()
                } else {
                    this.setNavigationBarText(null)
                    this.setNewBottomNavigationUsage({ usage: 'workoutEnterSet', data: set })
                }
            }
        },
        toggleNoteDialog() {
            if (this.filteredFinishedSteps.length)
                this.setSavedNote({ exercise: this.exercise._id, note: this.filteredFinishedSteps[0].note })
            this.noteDialog = !this.noteDialog
        },
        deleteExercise() {
            this.removeExerciseByIndex(this.exerciseInded)
            this.$emit('updateBottomNavigation')
            alert('Funktion wird implementiert.')
        },
        getSetSettings(settings) {
            return settings.filter((setting) => setting && setting.value)
        },
        getStepIconColor(active) {
            //	if (this.editModeActive) return active ? 'red' : 'blue'
            return 'white'
        },
        getStepIcon(active) {
            //	if (this.editModeActive) return active ? 'mdi-close-circle' : 'mdi-pencil-circle'
            return 'mdi-alert-circle-outline'
        },
        deleteFinishedStep(index) {
            if (confirm('Möchtest du diesen Satz wirklich löschen?')) {
                this.removeStepByIndex({ exercise_id: this.exercise._id, index: index })
                let setIndex =
                    this.filteredFinishedSteps.length >= this.exercise.sets.length
                        ? this.exercise.sets.length - 1
                        : this.filteredFinishedSteps.length
                this.activeSet = JSON.parse(JSON.stringify(this.exercise.sets[setIndex]))
                this.activeSet.title = this.exercise.title
            }
        },
        getSettingText(setting) {
            let text = setting.value
            if (setting.text === 'Gewicht') text = text + ' KG'
            if (setting.text === 'Dauer') text = text + ''
            return text
        },
        toggleFinishedStep(index) {
            if (this.filteredFinishedSteps[index].active) {
                this.finishedStepActiveBeforeToggle(index)
                this.finishedStepActiveToggle(index)
            } else {
                this.finishedStepActiveToggle(index)
                this.finishedStepActiveBeforeToggle(index)
            }
        },
        finishedStepActiveToggle(index) {
            this.toggleFinishedStepActive({ exercise_id: this.exercise._id, index: index })
        },
        finishedStepActiveBeforeToggle(index) {
            this.toggleFinishedStepActiveBefore({ exercise_id: this.exercise._id, index: index })
        },
        shorten(text, length = 25) {
            if (text === 'Min Wiederholungen') return 'Min WH'
            if (text === 'Max Wiederholungen') return 'Max WH'
            if (text === 'Umdrehungen pro Minute') return 'UPM'
            if (text && text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        getImageUrl(image) {
            return '/img/uebungen/' + image.replace(/\.[^/.]+$/, '') + '.webp'
        },
        addStepToStore() {
            this.addNewStep({
                plan_id: this.$route.params.id,
                exercise_id: this.exercise._id,
                date: this.$moment().unix(),
                set: this.filteredFinishedSteps.length + 1,
                settings: JSON.parse(JSON.stringify(this.activeSet.settings)),
                active: false,
                activeBefore: false,
                weight: this.newStepValue.weight,
                reps: { value: this.newStepValue.reps },
                time: this.newStepValue.time,
            })
            if (!this.setsFinished) {
                this.activeSet = JSON.parse(JSON.stringify(this.exercise.sets[this.activeSetIndex + 1]))
            }
        },
    },
    computed: {
        ...mapState('workingoutStore', [
            'defaultValues',
            'savedSet',
            'finishedSteps',
            'savedNote',
            'workoutStepsHistory',
        ]),
        ...mapState('navigationStore', ['selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationBarText', 'navigationBarUsage']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
        ...mapGetters('deviceStore', ['exerciseList']),
        isSensoPro() {
            return this.exercise.title === 'Senso Pro'
        },
        sensoProSetNotDone() {
            if (!this.isSensoPro) return true
            return !this.filteredFinishedSteps.length
        },
        getGeneralSettings() {
            // if (this.editModeActive) return this.exercise.general_exercise_settings
            return this.exercise.general_exercise_settings.filter((setting) => setting.value)
        },
        activeSetIndex() {
            return this.exercise.sets.findIndex((set) => set._id === this.activeSet._id)
        },
        setsFinished() {
            return this.filteredFinishedSteps.length >= this.exercise.sets.length
        },
        filteredFinishedSteps() {
            if (!this.finishedSteps.length) return []
            let stepsForExercise = this.finishedSteps.find(
                (finishedStep) => finishedStep.exercise_id === this.exercise._id
            )
            return stepsForExercise && stepsForExercise.steps ? stepsForExercise.steps : []
        },
    },
    watch: {
        savedNote(value) {
            if (this.activeSlide && value && value.saved) {
                if (this.filteredFinishedSteps.length) {
                    this.updateStep({
                        plan_id: this.$route.params.id,
                        exercise_id: this.exercise._id,
                        value,
                    })
                } else {
                    this.addNewStep({
                        plan_id: this.$route.params.id,
                        exercise_id: this.exercise._id,
                        date: this.$moment().unix(),
                        set: 1,
                        settings: JSON.parse(JSON.stringify(this.activeSet.settings)),
                        active: false,
                        activeBefore: false,
                        reps: {
                            value: null,
                        },
                        note: value.note,
                    })
                }
                this.setSavedNote(null)
                this.$emit('updateBottomNavigation', this.exercise.title)
                this.toggleNoteDialog()
            }
        },
        savedSet(value) {
            if (this.activeSlide) {
                if (!value || value === 'reset') {
                    return this.openSetDialogBottomNavigation()
                }
                if (value.index || value.index === 0) {
                    this.updateStep({ plan_id: this.$route.params.id, exercise_id: this.exercise._id, value })
                } else {
                    this.newStepValue = value
                    if (
                        !this.exercise.noForce &&
                        this.activeSetIndex === 1 &&
                        this.filteredFinishedSteps[0].weight < value.weight
                    ) {
                        this.secondStepMoreWeightDialog = true
                    } else {
                        this.addStepToStore()
                    }
                    this.openSetDialogBottomNavigation()
                }
            }
        },
        navigationBarTextState(value) {
            if (value && this.navigationBarEndPoint === this.exercise._id) {
                this.guideOpen = false
                this.statisticOpen = false
                this.$emit('updateBottomNavigation', this.exercise.title)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.expand-transition-div {
    width: 100%;
}
.formCard {
    margin: -12px 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background: #313131;
}
.formTitle {
    color: white !important;
}
::v-deep .bgpositionleftcenter .v-image__image {
    background-position: left center !important;
}
.workout-add-exercise {
    &__card {
        border-radius: 10px;

        &-title {
            border-radius: 10px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: #313131;
            color: white !important;
        }
        &-finished-step {
            background: var(--v-primary);
            color: white !important;
            align-items: center;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            border-radius: 10px;
        }
        &-new-step {
            background: #313131;
            color: white !important;
            align-items: center;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            border-radius: 10px;
            &--container {
                border-radius: 10px;
            }
            &--active {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }
}
.wrapper-outer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
}
.wrapper-inner {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    height: 84vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.courseListView {
    margin-top: 35vh !important;
    &__container {
        position: relative;
        &-title {
            font-weight: bold;
            color: #071e20;
            word-break: break-word;
        }
    }
    &-course {
        &-signups {
            &-checkbox {
                font-size: 20px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

::v-deep .v-virtual-scroll {
    height: 55vh !important;
}

.workoutAddExercise {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: 100%;

    &__search {
        height: 50px;

        &-input {
            top: 25%;
            left: 10px;
            position: relative;
            width: 90%;
        }
    }

    .h6 {
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: -10px;
    }
    &-actions {
        position: sticky;
        bottom: 0;

        button {
            border-width: 4px;
            font-weight: bold;
        }
    }

    &__list-item {
        height: 100px;
        overflow: hidden;

        .v-list-item__title {
            font-weight: bold;
            margin-bottom: 3px;
        }

        &-image {
            height: 100%;

            &__container {
                height: 100%;
                width: 40%;
            }
        }
    }

    &__swiper {
        height: 100vh;
        background-color: white;
        display: flex;
        &-card {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            &-content {
                overflow-y: auto;
                height: 62vh;

                &-img {
                    margin-top: 25%;
                    width: 50%;
                }
            }

            &-image {
                margin: 0 10%;
                height: 40%;
                background-size: contain;
            }
        }
    }
}
</style>
