<template>
    <v-row>
        <v-col cols="2" class="d-flex flex-column py-0">
            <v-img src="/img/lottiefiles/sunset.png" contain height="35" width="35"></v-img>
            <div style="flex: 1 1 auto; height: 100%; width: 50%; border-right: 2px dashed lightgray"></div>
            <v-img src="/img/lottiefiles/moon.png" contain height="35" width="35"></v-img>
        </v-col>
        <v-col cols="10">
            <habit-home-list-nutrition
                v-if="habits"
                :habitsList="habits"
                @deleted="removeHabit"
                :dateProp="date"
                :selectedExecution="selectedExecution"
            ></habit-home-list-nutrition>
            <v-row no-gutters v-if="loading">
                <v-col cols="12" v-for="template in 5" :key="template">
                    <v-card
                        @click="$emit('selectMeal', meal)"
                        style="width: 98%; margin-left: 1%; margin-right: 1%"
                        class="rounded-lg my-2"
                    >
                        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="mealDistribution">
                <v-col cols="12" v-for="meal of mealDistribution" :key="meal.text">
                    <v-card
                        @click="$emit('selectMeal', meal)"
                        style="width: 98%; margin-left: 1%; margin-right: 1%"
                        class="rounded-lg my-2"
                        :class="{
                            'background-lightgreen':
                                Math.abs(calorieGoalForMeal(meal) - meal.calories) <= 30 && meal.calories !== 0,
                            'background-lightorange':
                                Math.abs(calorieGoalForMeal(meal) - meal.calories) <= 100 &&
                                Math.abs(calorieGoalForMeal(meal) - meal.calories) > 30 &&
                                meal.calories !== 0,
                            'background-lightred':
                                Math.abs(calorieGoalForMeal(meal) - meal.calories) > 100 && meal.calories !== 0,
                        }"
                    >
                        <v-row no-gutters>
                            <v-col cols="3" class="d-flex flex-column pa-2">
                                <v-img
                                    contain
                                    height="52px"
                                    :src="'/img/habitory/' + getItemImage(meal.text) + '.png'"
                                ></v-img>
                            </v-col>
                            <v-col cols="9" class="d-flex flex-column justify-content-center">
                                <span class="font-weight-bold">{{ meal.text }}</span>
                                <span>
                                    {{ Math.round(meal.calories) + ' / ' + calorieGoalForMeal(meal) + ' kcal' }}
                                </span>
                            </v-col>
                        </v-row>
                        <!--  :class="{ habitCompleted: item.completed, habitDelayed: item.delayed }"-->
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import api from '../../api'
import { habitService } from '@/services/habitService.js'
import habitHomeListNutrition from '../habit/habitHomeListNutrition.vue'
export default {
    props: {
        loading: {},
        date: {},
        mealDistribution: {},
        dailyCalories: {
            type: String,
        },
        habits: {},
        selectedExecution: {},
    },
    components: { habitHomeListNutrition },
    methods: {
        removeHabit(id) {
            this.habits = this.habits.filter((hab) => hab._id !== id)
        },
        calorieGoalForMeal(meal) {
            return Math.round(Number((this.dailyCalories / 100) * meal.percentage))
        },
        getItemImage(meal) {
            let imageMapper = {
                Frühstück: 'coffeeIcon',
                'Morgen Snack': 'breakfastSnack',
                Mittagessen: 'lunch',
                'Nachmittags Snack': 'lunchSnack',
                Abendessen: 'dinner',
                'Abend Snack': 'dinnerSnack',
            }

            return imageMapper[meal]
        },
    },
}
</script>
<style lang="scss">
.background-lightgreen {
    background-color: rgba(0, 150, 63, 0.2) !important;
}
.background-lightred {
    background-color: rgba(255, 0, 0, 0.1) !important;
}
.background-lightorange {
    background-color: rgba(150, 150, 0, 0.1) !important;
}
</style>