<template>
    <v-card dark flat>
        <v-row no-gutters v-if="error || noUserFound">
            <v-col class="pt-3 px-4" v-if="error" cols="12">
                <v-alert type="error">Es gab einen Fehler beim Laden der Mitgliedschaft</v-alert>
            </v-col>
            <v-col class="pt-3 px-4" v-if="noUserFound" cols="12">
                <v-alert type="info">Es wurde keine Mitgliedschaft gefunden</v-alert>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col v-if="userMemberDetails" class="my-2 px-3" cols="12">
                <v-chip color="primary">{{ userMemberDetails.MemberCategoryName }}</v-chip>
            </v-col>

            <v-col
                cols="12"
                class="px-3"
                v-if="
                    userMemberDetails &&
                    userMemberDetails.SubContracts &&
                    userMemberDetails.SubContracts.SubContract.length > 0
                "
            >
                <v-chip
                    class="mr-2"
                    v-for="(subContract, i) of userMemberDetails.SubContracts.SubContract"
                    :key="i + '-packageKey'"
                >
                    {{ subContract.MemberCategoryName }}
                </v-chip>
            </v-col>
            <v-col cols="12">
                <v-card-subtitle class="pb-0">Mitgliedschaftskonto: {{ membershipAccountStatement }}</v-card-subtitle>
                <v-card-subtitle class="pt-0">Secondary Spend: {{ secondarySpendStatement }}</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from '@/api'
export default {
    props: {
        tacID: {
            default: null,
            type: String,
        },
    },
    data() {
        return {
            error: false,
            noUserFound: false,
            userMemberDetails: null,
        }
    },
    created() {
        if (this.tacID) {
            this.fetchMemberDetails()
        }
    },
    methods: {
        async fetchMemberDetails() {
            this.error = false
            this.noUserFound = false
            this.userMemberDetails = null
            this.fetchingProfile = true
            try {
                //let profile1 = await api.fetchTACMemberDetailsByTACId(this.tacID)
                let profile = await api.fetchCurrentTACMemberDetailsByTACId(this.tacID)
                if (profile?.Result?.resultStatusFlag === 'SUCCESS') {
                    this.userMemberDetails = profile.MemberDetail
                } else {
                    if (profile.Result.code === '301') {
                        this.noUserFound = true
                        return
                    }
                    this.error = true
                }
            } catch (e) {
                this.noUserFound = true
            }
            this.fetchingProfile = false
        },
    },
    computed: {
        membershipAccountStatement() {
            return this.userMemberDetails?.Accounts?.Account?.find((e) => e.type === 'MEMBERSHIP')?.Balance?.value || 0
        },
        secondarySpendStatement() {
            return (
                this.userMemberDetails?.Accounts?.Account?.find((e) => e.type === 'SECONDARYSPEND')?.Balance?.value || 0
            )
        },
    },
}
</script>