<template>
    <v-row>
        <!-- Iteriere durch jeden Hauptkey im Objekt -->
        <v-col v-for="(locationData, locationKey) in tableData" :key="locationKey" cols="12" class="mb-0 mt-5 pb-0 pt-1">
            <v-simple-table dark>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ locationKey }}</span>
                            </th>
                            <th style="text-align: center" v-for="(value, title) in locationData.Gesamt" :key="title">
                                {{ title }}
                            </th>
                            <th>Gesamt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gesamt</td>
                            <td style="text-align: center" v-for="(value, title) in locationData.Gesamt" :key="title">
                                {{ value }}
                            </td>
                            <td style="text-align: center">
                                {{ getTotal(locationData.Gesamt) }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-expansion-panels dark class="mt-2">
                <v-expansion-panel>
                    <v-expansion-panel-header>Daten pro Studio anzeigen</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>{{ locationKey }}</th>
                                        <th
                                            style="text-align: center"
                                            v-for="(value, title) in locationData.Gesamt"
                                            :key="title">
                                            {{ title }}
                                        </th>
                                        <th style="text-align: center">Gesamt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(studioData, studioKey) in locationData"
                                        :key="studioKey"
                                        v-show="studioKey !== 'Gesamt'">
                                        <td>{{ studioKey }}</td>
                                        <td style="text-align: center" v-for="(value, title) in studioData" :key="title">
                                            {{ value }}
                                        </td>
                                        <td style="text-align: center">{{ getTotal(studioData) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table></v-expansion-panel-content
                    >
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        getTotal(list) {
            let total = 0
            for (const key in list) {
                total += list[key]
            }
            return total
        },
    },

    props: {
        tableData: Object,
    },
}
</script> 

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>