<template>
	<div class="businesSHours">
		<v-tabs @change="checkDays()" centered v-model="tab">
			<v-tabs-slider color="black"></v-tabs-slider>

			<v-tab v-for="item in items" :key="item">
				{{ item }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item class="mb-15 mt-5">
				<v-switch v-model="attended.active" :label="attended.active ? 'Aktiviert' : 'Deaktiviert'"></v-switch>
				<business-hours v-if="days" :switchWidth="120" :hourFormat24="true" :localization="businessHoursGerman" :days="days"></business-hours
				><v-row>
					<v-col cols="12" class="d-flex">
						<v-btn @click="saveBusinessHours('attended')" color="primary" class="mt-5 mx-auto">Speichern</v-btn>
					</v-col>
				</v-row></v-tab-item
			>
			<v-tab-item class="mb-15 mt-5">
				<v-switch v-model="unattended.active" :label="unattended.active ? 'Aktiviert' : 'Deaktiviert'"></v-switch>
				<v-switch v-model="unattended.always" label="24 Stunden"></v-switch>
				<business-hours
					v-if="!unattended.always && days"
					:switchWidth="120"
					:hourFormat24="true"
					:localization="businessHoursGerman"
					:days="days"
				></business-hours>
				<v-row>
					<v-col cols="12" class="d-flex">
						<v-btn @click="saveBusinessHours('unattended')" color="primary" class="mt-5 mx-auto">Speichern</v-btn>
					</v-col>
				</v-row>
			</v-tab-item>
			<v-tab-item class="mb-15 mt-5">
				<v-switch v-model="wellness.active" :label="wellness.active ? 'Aktiviert' : 'Deaktiviert'"></v-switch>
				<v-switch v-model="wellness.likeAttended" label="Wie betreute Öffnungszeiten"></v-switch>
				<business-hours
					v-if="!wellness.likeAttended && days"
					:switchWidth="120"
					:hourFormat24="true"
					:localization="businessHoursGerman"
					:days="days"
				></business-hours
				><v-row>
					<v-col cols="12" class="d-flex">
						<v-btn @click="saveBusinessHours('wellness')" color="primary" class="mt-5 mx-auto">Speichern</v-btn>
					</v-col>
				</v-row></v-tab-item
			>
		</v-tabs-items>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'
import businessHoursGerman from '../../assets/businessHoursGerman.json'

export default {
	components: {},
	data() {
		return {
			attended: {
				active: false,
			},
			unattended: {
				active: false,
				always: false,
			},
			wellness: {
				active: false,
				likeAttended: false,
			},
			days: null,
			tab: null,
			items: ['Betr. Öffnungszeiten', 'Unbetr. Öffnungszeiten', 'Wellness'],
			businessHoursGerman: businessHoursGerman,
		}
	},
	created() {
		this.initDays()
	},
	methods: {
		saveBusinessHours(type) {
			console.log(this[type], this.days)
		},
		checkDays() {
			console.log('changeTab')
			this.initDays()
		},
		initDays() {
			this.days = {
				monday: [
					{
						open: '',
						close: '',
						id: '1',
						isOpen: false,
					},
				],
				tuesday: [
					{
						open: '',
						close: '',
						id: '2',
						isOpen: false,
					},
				],
				wednesday: [
					{
						open: '',
						close: '',
						id: '3',
						isOpen: false,
					},
				],
				thursday: [
					{
						open: '',
						close: '',
						id: '4',
						isOpen: false,
					},
				],
				friday: [
					{
						open: '',
						close: '',
						id: '5',
						isOpen: false,
					},
				],
				saturday: [
					{
						open: '',
						close: '',
						id: '6',
						isOpen: false,
					},
				],
				sunday: [
					{
						open: '',
						close: '',
						id: '0',
						isOpen: false,
					},
				],
			}
		},
	},
}
</script>
<style lang="scss" scoped></style>
