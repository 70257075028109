var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:{ 'habit-home-star--open': _vm.starOpen }},[_c('v-col',{staticClass:"pt-0 text-center",class:{ 'habit-home-star__star--open': _vm.starOpen },attrs:{"cols":"6"}},[_c('canvas',{staticStyle:{"height":"120px"},attrs:{"height":"165","width":"165","id":"canvas"},on:{"click":function($event){return _vm.openStar()}}})]),_c('v-col',{staticClass:"pt-0 d-flex justify-space-around",class:{ 'flex-row': _vm.starOpen, 'flex-column': !_vm.starOpen },attrs:{"cols":_vm.starOpen ? 12 : 6}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[(!_vm.starOpen)?_c('div',{staticClass:"star__list-bullet life mr-5"}):_vm._e(),_c('span',{class:{
					active: _vm.starOpen && _vm.activeChip === 1,
					'habit-home-star__button life font-weight-bold white--text pa-2 rounded-xl elevation-7': _vm.starOpen,
				},on:{"click":function($event){return _vm.changeChip(1)}}},[_vm._v("Leben")])]),_c('div',{staticClass:"d-flex flex-row align-items-center"},[(!_vm.starOpen)?_c('div',{staticClass:"star__list-bullet recovery mr-5"}):_vm._e(),_c('span',{class:{
					active: _vm.starOpen && _vm.activeChip === 2,
					'habit-home-star__button recovery font-weight-bold white--text pa-2 rounded-xl  elevation-7': _vm.starOpen,
				},on:{"click":function($event){return _vm.changeChip(2)}}},[_vm._v("Erholung")])]),_c('div',{staticClass:"d-flex flex-row align-items-center"},[(!_vm.starOpen)?_c('div',{staticClass:"star__list-bullet movement mr-5"}):_vm._e(),_c('span',{class:{
					active: _vm.starOpen && _vm.activeChip === 3,
					'habit-home-star__button movement font-weight-bold white--text pa-2 rounded-xl  elevation-7': _vm.starOpen,
				},on:{"click":function($event){return _vm.changeChip(3)}}},[_vm._v("Bewegung")])]),_c('div',{staticClass:"d-flex flex-row align-items-center"},[(!_vm.starOpen)?_c('div',{staticClass:"star__list-bullet nutrition mr-5"}):_vm._e(),_c('span',{class:{
					active: _vm.starOpen && _vm.activeChip === 4,
					'habit-home-star__button nutrition font-weight-bold white--text pa-2 rounded-xl  elevation-7': _vm.starOpen,
				},on:{"click":function($event){return _vm.changeChip(4)}}},[_vm._v("Ernährung")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }