<template>
    <div class="qrGenerator">
        <v-card-title class="white--text"> QR-Code Generator </v-card-title>
        <v-row>
            <v-col cols="12"><v-text-field dark filled v-model="link"></v-text-field></v-col>
            <v-col cols="12"> <v-img width="300px" :src="'/api/qrcode/image?data=' + link"></v-img></v-col>
            <v-col cols="12">
                <v-btn class="mr-5 white--text" @click="downloadQRCode(link)" color="primary"
                    >QR Code herunterladen</v-btn
                ></v-col
            >
        </v-row>
    </div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'

export default {
    data() {
        return {
            link: 'https://app.wellcomefit.ch',
        }
    },
    created() {},
    methods: {
        async downloadQRCode(link) {
            api.fetchQRCode(link)
        },
    },
}
</script>
<style lang="scss" scoped></style>
