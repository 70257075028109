p<template>
    <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
        <v-card-title>Ernährungsplan</v-card-title>
        <v-card-actions class="d-flex flex-column text-center align-items-center" v-if="!mealPlan">
            <v-btn max-width="150px" @click="startMealPlanCreationForm()">Hinzufügen</v-btn>
        </v-card-actions>

        <v-card-text v-else>
            <span>Ernährungsplan vom {{ $moment(mealPlan.createdAt).format('DD.MM.YYYY') }}</span>
            <p>
                <v-btn @click="deleteMealPlan()" icon color="red">
                    <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
                <v-btn @click="editMealPlan(mealPlan)" icon color="blue">
                    <v-icon color="blue">mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="false" @click="updatePopup(mealPlan.hidePopup)" icon color="blue">
                    <v-icon v-if="mealPlan.hidePopup">mdi-image-off</v-icon>
                    <v-icon v-else>mdi-image</v-icon>
                </v-btn>
            </p>
        </v-card-text>
        <user-dashboard-nutrition-meal-plan-form
            :user="user"
            :allergens="allergens"
            :diet="diet"
            :existingPlan="existingPlanEdit"
            v-if="creationFormActive"
            :maxTimeFilters="maxTimeFilters"
            :difficultyFilters="difficultyFilters"
            @close="closeMealPlanForm"
        ></user-dashboard-nutrition-meal-plan-form>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'

import userDashboardNutritionMealPlanForm from './userDashboardNutritionMealPlanForm.vue'
export default {
    components: {
        userDashboardNutritionMealPlanForm,
    },
    props: {
        allergens: {},
        diet: {},
        user: {
            required: true,
        },
        mealPlan: {
            type: Object,
            required: false,
        },
        difficultyFilters: {
            type: Array,
        },
        maxTimeFilters: {
            type: Array,
        },
    },
    data() {
        return {
            existingPlanEdit: {},
            creationFormActive: false,
        }
    },
    computed: {},
    methods: {
        updatePopup(hidePopup) {
            console.log(hidePopup)
        },
        closeMealPlanForm(mealPlan = null) {
            this.creationFormActive = false
            if (mealPlan) this.$emit('mealPlanCreated', mealPlan)
        },
        async deleteMealPlan() {
            try {
                if (confirm('Möchtest du diesen Plan und alle dazugehörigen Einträge wirklich löschen?')) {
                    let deleted = await api.deleteMealPlan(this.user._id)
                    this.$emit('deleted')
                    this.$toast.success('Plan gelöscht')
                }
            } catch (e) {
                console.log(e)
            }
        },
        editMealPlan(mealPlan) {
            /*alert('Das bearbeiten der Ernährungspläne ist noch in Entwicklung')
            return */
            this.existingPlanEdit = mealPlan
            this.startMealPlanCreationForm()
        },
        startMealPlanCreationForm() {
            this.creationFormActive = true
        },
    },
}
</script>
