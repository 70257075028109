<template>
    <div class="slides d-flex flex-column">
        <v-row>
            <v-col cols="12"><v-card-title class="white--text">Slide Manager</v-card-title></v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn color="primary" class="white--text mx-auto" @click="createNewSlide()">Hinzufügen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col :key="'slidde' + slide._id" v-for="slide of slides" cols="12" md="6" lg="4" xl="3">
                <v-card
                    dark
                    hover
                    @click="editSlide(slide)"
                    :class="{
                        active:
                            slide.hideAfter &&
                            slide.showAfter &&
                            $moment(slide.hideAfter).set({ hours: 23, minutes: 59, seconds: 59 }).unix() >
                                $moment().unix() &&
                            $moment(slide.showAfter).set({ hours: 0, minutes: 0, seconds: 0 }).unix() <
                                $moment().unix(),
                    }"
                    class="slides__list-entry rounded-xl fill-height"
                >
                    <v-card-title>{{ slide.title }}</v-card-title>
                    <v-card-subtitle class="pb-2 pt-1">
                        {{ $moment(slide.showAfter).format('DD.MM.YYYY') }} -
                        {{ $moment(slide.hideAfter).format('DD.MM.YYYY') }}
                    </v-card-subtitle>

                    <v-card-subtitle v-if="slide.clickedBy && slide.clickedBy.length > 0">
                        {{ slide.clickedBy.length }} mal angeklickt
                    </v-card-subtitle>
                    <div class="studios mx-5">
                        <v-chip small class="studio ma-1" v-for="studio of slide.studios" :key="studio._id">
                            {{ studio.name }}
                        </v-chip>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog class="slides__dialog" v-model="showEditDialog" persistent max-width="800px">
            <v-card max-height="70vh" height="800px" class="overflow-y-auto d-flex flex-column">
                <v-card-title v-if="slide && slide.title" class="slides__dialog-title">{{ slide.title }}</v-card-title>
                <v-card-title v-else class="slides__dialog-title">Neue Slide</v-card-title>

                <slim-cropper
                    :key="slide.image + 'slid-crop'"
                    style="width: 300px; margin: auto"
                    class="d-table"
                    :options="slimOptionsExercise"
                >
                    <img v-if="slide.image" :src="'/img/' + slide.image" alt="" />
                    <img v-else src="/img/einmalig/imageUploadPlaceholderBottomBorder.jpg" alt="" />
                    <input type="file" accept="image/*" />
                </slim-cropper>

                <v-card-subtitle class="text-center">Format: 3:1 (900x300)</v-card-subtitle>

                <v-text-field
                    class="mx-5 mt-1"
                    v-model="slide.title"
                    label="Titel"
                    placeholder="Werbung X"
                ></v-text-field>
                <v-text-field
                    class="mx-5 mt-1"
                    v-model="slide.link"
                    label="Link"
                    placeholder="https://app.wellcomefit.ch/..."
                ></v-text-field>

                <v-date-picker locale="de-DE" class="mx-auto" v-model="datePicker" range></v-date-picker>
                <v-select
                    class="mx-5"
                    v-model="slide.studios"
                    :items="studios"
                    item-text="name"
                    item-value="_id"
                    attach
                    chips
                    label="Studios"
                    multiple
                ></v-select>
                <v-card-actions class="slides__dialog-actions">
                    <v-btn color="grey darken-1" text @click="showEditDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :loading="working" color="primary darken-1" text @click="saveSlide">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import api from '../../../api'
export default {
    name: 'slideManager',
    components: { 'slim-cropper': Slim },
    data() {
        return {
            studios: [],
            working: false,
            showEditDialog: false,
            datePicker: null,
            slides: [],
            slide: null,
            slimOptionsExercise: {
                ratio: '3:1',
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
        }
    },
    created() {
        this.fetchSlides()
        this.initSlide()
    },
    computed: {},
    methods: {
        startTemplateEditor() {
            this.$router.push({ name: 'campaignTemplateCreator' })
        },
        initSlide() {
            this.datePicker = null
            this.slide = {
                hideAfter: null,
                showAfter: null,
                link: '',
                linkText: '',
                image: '',
                title: '',
                studios: [],
            }
        },
        editSlide(slide) {
            this.slide = slide
            this.datePicker = [slide.showAfter, slide.hideAfter]

            this.showEditDialog = true
        },
        createNewSlide() {
            this.initSlide()
            this.showEditDialog = true
        },
        async fetchSlides() {
            let slides = await api.fetchSlides()
            this.studios = slides.studios
            this.slides = slides.slides
            this.$emit('finishedloading')
        },
        async saveSlide() {
            this.working = true
            let status = await api.saveSlide(this.slide)
            this.showEditDialog = false
            this.working = false
            this.fetchSlides()
            this.initSlide()
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'slide'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.slide.image = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                })
        },
    },
    watch: {
        datePicker(value) {
            if (value[0] && value[1]) {
                this.slide.showAfter = value[0]
                this.slide.hideAfter = value[1]
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.slides {
    &__list {
        &-entry {
            &.active {
                background-color: rgba(56, 180, 56, 0.822);
            }
        }
    }
    &__dialog {
        position: relative;
        &-title {
            position: sticky;
            top: 0;
            background-color: white;
            border-bottom: 2px solid var(--v-primary);
            z-index: 9;
        }
        &-actions {
            position: sticky;
            bottom: 0;
            background-color: white;
            border-top: 2px solid var(--v-primary);
        }
    }
}
</style>
