<template>
	<div class="aktuelles">
		<div class="weiterempfehlung__card ">
			<v-img max-height="500px" contain :src="getHeader()"></v-img>
			<div class="overflow-auto" style="max-height: 60vh">
				<v-card-title class="h5 primary--text">
					Beschreibung
				</v-card-title>
				<v-card-text v-if="iswellcomefit == 1">
					<span>SPEZIALANGEBOT für unsere MITGLIEDER</span>
					Bring a friend und erhalte CHF 50.- bar auf die Hand, wenn Dein Freund bei uns ein Jahresabonnement abschliesst.
				</v-card-text>
				<v-card-text v-if="iswellcomefit == 2">
					<span class="heading-affiliate">SPEZIALANGEBOT für unsere MITGLIEDER</span>
					Bring a friend und erhalte CHF 50.- bar auf die Hand, wenn Dein Freund bei uns ein Jahresabonnement abschliesst.
				</v-card-text>
				<v-card-text v-if="iswellcomefit == 3">
					<span class="heading-affiliate">TEILE DIESE PROMOTION JETZT MIT DEINEN FREUNDEN</span>
					Schicke diese Promotion an Deine Freunde, Familie und Bekannten. Trainiert gemeinsam im Fitness Zentrum Wetzikon.
					<br />
					<br />
					Deine Freunde erhalten die Möglichkeit eine Woche kostenlos zu trainieren. Sollte sich einer Deiner Kontakte nach seinem
					einwöchigen Gratistraining für eine Mitgliedschaft entscheiden, erhältst Du CHF 50.- bar auf die Hand.
				</v-card-text>

				<v-card-title class="h5 primary--text">Bedingungen</v-card-title>
				<v-card-text v-if="iswellcomefit == 1">
					Kein Firmenrabatt zusätzlich / nur gültig für weiterempfohlene Personen ohne laufenden Vertrag in einem well come FIT Center
				</v-card-text>
				<v-card-text v-if="iswellcomefit == 2">
					Kein Firmenrabatt zusätzlich / nur gültig für weiterempfohlene Personen ohne laufenden Vertrag in einem winti FIT Center.
				</v-card-text>
				<div class="agb rounded" :class="{ 'agb-rounded': !agbchecker }">
					<input @click="toggleAgb()" type="checkbox" />
					<div class="agb-text-wrapper">
						Bedingungen akzeptieren und den Link für die Weiterempfehlung erhalten
					</div>
				</div>
				<div v-show="agbchecker" class="aff-link">
					Dein persönlicher Weiterempfehlungs-Link lautet:
					<br />
					<input id="myreflink" class="affiliate-gen-link" v-model="inputlink" />
					<div v-if="!copied" class="affiliate-gen-link-copy" @click.prevent="copylink">
						Link kopieren!
					</div>
					<div v-else class="affiliate-gen-link-copy" @click.prevent="copylink">
						Link wurde kopiert!
					</div>
					<div class="share-it">
						Teile deinen Link jetzt über:
						<br />
						<div class="link-wrapper">
							<a target="__blank" :href="whatsapplink">
								<img class="share-icon" src="/img/social/whatsapp.svg" />
							</a>
							<a target="__blank" :href="facebooklink">
								<img class="share-icon" src="/img/social/facebook.svg" />
							</a>
							<a target="__blank" :href="linkedinlink">
								<img class="share-icon" src="/img/social/linkedin.svg" />
							</a>
							<a target="__blank" :href="telegramlink">
								<img class="share-icon" src="/img/social/telegram.svg" />
							</a>
							<a target="__blank" :href="maillink">
								<img class="share-icon" src="/img/social/gmail.svg" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '../../api'

export default {
	name: 'internReferal',
	data() {
		return {
			agbchecker: false,
			hostname: '',
			email: '',
			whatsapplink: '',
			facebooklink: '',
			telegramlink: '',
			linkedinlink: '',
			maillink: '',
			inputlink: '',
			copied: false,
			iswellcomefit: true,
		}
	},
	methods: {
		getHeader() {
			let ret = false

			if (location.host.includes('roody') || location.host.includes('wellcomefit') || location.host.includes('localhost')) {
				ret = '/img/kampagne/bringafriend/baf_50_well.jpg'
			}
			if (location.host.includes('wintifit')) {
				ret = '/img/kampagne/bringafriend/baf_50_winti.jpg'
			}

			if (ret) {
				return ret
			} else {
				return '/img/affiliate_1.png'
			}
		},
		copylink() {
			var copyText = document.getElementById('myreflink')

			/* Select the text field */
			copyText.select()
			copyText.setSelectionRange(0, 99999) /*For mobile devices*/

			/* Copy the text inside the text field */
			document.execCommand('copy')

			this.copied = true
		},
		toggleAgb() {
			if (this.agbchecker) {
				this.agbchecker = false
			} else {
				this.agbchecker = true

				setTimeout(function() {
					document.getElementById('myreflink').scrollIntoView()
				}, 500)
			}
		},
	},
	created() {
		api.getUser().then((usdata) => {
			this.year = new Date(new Date()).getFullYear()
			this.email = usdata.user.email
			this.hostname = location.host
			let text = ''
			if (location.host.includes('roody') || location.host.includes('wellcomefit') || location.host.includes('localhost')) {
				this.iswellcomefit = 1

				text =
					'Hey%20Du%2C%20ich%20bin%20gerade%20im%20Training%20beim%20well%20come%20FIT.%20Macht%20voll%20Spass%21%20H%C3%A4ttest%20Du%20Lust%20das%20Training%20hier%20auch%20mal%20zu%20probieren%3F%20Es%20macht%20eben%20nicht%20nur%20fit%20sondern%20sorgt%20auch%20f%C3%BCr%20ziemlich%20viel%20gute%20Laune%20und%20einen%20riesengrossen%20Energiekick.%20Klicke%20doch%20einfach%20auf%20den%20Link%20und%20sichere%20Dir%20Deinen%20Platz%20in%20einem%20Fitness%20Center%2C%20in%20dem%20viel%20Wert%20auf%20pers%C3%B6nliche%20und%20individuelle%20Trainingsbegleitung%20gelegt%20wird.'
			} else if (location.host.includes('wintifit')) {
				// wellcomefit
				this.iswellcomefit = 2
				// wintifit
				text =
					'Hey%20Du%2C%20ich%20bin%20gerade%20im%20Training%20beim%20winti%20FIT.%20Macht%20voll%20Spass%21%20H%C3%A4ttest%20Du%20Lust%20das%20Training%20hier%20auch%20mal%20zu%20probieren%3F%20Es%20macht%20eben%20nicht%20nur%20fit%20sondern%20sorgt%20auch%20f%C3%BCr%20ziemlich%20viel%20gute%20Laune%20und%20einen%20riesengrossen%20Energiekick.%20Klicke%20doch%20einfach%20auf%20den%20Link%20und%20sichere%20Dir%20Deinen%20Platz%20in%20einem%20Fitness%20Center%2C%20in%20dem%20viel%20Wert%20auf%20pers%C3%B6nliche%20und%20individuelle%20Trainingsbegleitung%20gelegt%20wird.'
			}
			this.inputlink = 'https://' + this.hostname + '/roody/referral?' + this.email
			let sharelink = 'https%3A%2F%2F' + this.hostname + '%2Froody%2Freferral%3F' + this.email.replace('@', '%40')

			this.whatsapplink = 'https://api.whatsapp.com/send?text=' + text + ' ' + sharelink
			this.linkedinlink = 'https://www.linkedin.com/shareArticle?url=' + sharelink
			this.facebooklink = 'https://www.facebook.com/sharer/sharer.php?u=' + sharelink
			this.maillink = 'mailto:?subject=&body=' + text + '%20' + sharelink
			this.telegramlink = 'https://t.me/share/url?url=' + sharelink + '&text=' + text
		})
	},
}
</script>

<style lang="scss" scoped>
.label {
	height: 40px;
	line-height: 40px;
	font-weight: 300;
	padding-left: 15px;
	background-color: #545655;
	color: white;
}
.heading-affiliate {
	width: 100%;
	display: flex;
	color: #8ebf24;
	font-size: 18px;
	/* font-weight: bold; */
	/* text-align: center; */
	margin-bottom: 10px;
}
.affiliate-gen-link {
	width: 100%;
	padding: 5px 10px;
	margin-top: 20px;

	display: flex;
	border: 1px solid var(--v-primary);
	text-align: center;
}
.affiliate-gen-link-copy {
	margin-bottom: 20px;
	background-color: var(--v-primary);
	color: white;
	text-align: center;
	padding: 10px 0px;
}
.aff-link {
	margin: 20px 10px 40px 10px;
}
.link-wrapper {
	display: flex;
	justify-content: space-evenly;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	.share-icon {
		width: 60px;
		height: 60px;
		padding: 7px;
	}
}
.agb-toggler {
	font-weight: 500;
	text-decoration: underline;
}
.affiliate-header {
	font-weight: 500;
	font-size: 23px;
	text-align: center;
	margin-top: 20px;
	color: var(--v-primary);
}
.affiliate-roody-wrapper {
	width: 100%;
	display: flex;

	.affiliate-roody {
		height: 20%;
		max-height: 500px;
		width: 100vw;
		object-fit: contain;
	}
}

.affiliate-text {
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 20px;
	margin-top: 10px;
}
.agb-text-wrapper {
	margin-right: 10px;
	margin-left: 12px;
	max-width: 240px;
}
.agb {
	width: 100%;
	background-color: #313131;
	color: white;
	padding: 17px;
	justify-content: center;
	display: flex;

	input {
		padding: 5px;

		width: 30px;
		height: 30px;
		margin: auto 10px;
		margin-right: 10px;
	}
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
