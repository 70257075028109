<template>
    <div>
        <apexchart type="bar" :options="chartOptions" :series="processedSeries"></apexchart>
    </div>
</template>
   
<script>
export default {
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        processedSeries() {
            const statusSet = new Set()
            for (let settingName in this.chartData) {
                for (let statusName in this.chartData[settingName]) {
                    statusSet.add(statusName)
                }
            }

            const settingTotals = {}
            for (let settingName in this.chartData) {
                let total = 0
                for (let statusName in this.chartData[settingName]) {
                    total += this.chartData[settingName][statusName]
                }
                settingTotals[settingName] = total
            }

            const sortedSettings = Object.keys(this.chartData).sort((a, b) => {
                return settingTotals[b] - settingTotals[a]
            })

            const series = []
            for (let statusName of statusSet) {
                const data = []
                for (let settingName of sortedSettings) {
                    data.push(this.chartData[settingName][statusName] || 0)
                }
                series.push({ name: statusName, data })
            }
            return series
        },
        chartOptions() {
            console.log('CHART DATA', this.chartData)
            const settingTotals = {} // Declare settingTotals here as well
            for (let settingName in this.chartData) {
                let total = 0
                for (let statusName in this.chartData[settingName]) {
                    total += this.chartData[settingName][statusName]
                }
                settingTotals[settingName] = total
            }

            let customerAmount = {
                Einstein: 2584,
                Netstal: 2525,
                Frauenfeld: 2493,
                Sirnach: 191,
                Niederurnen: 102,
                Wallisellen: 1118,
                Bülach: 1712,
                Pfungen: 1459,
                Rikon: 597,
                Basel: 928,
                Wetzikon: 717,
                Dietlikon: 1154,
                Steckborn: 265,
                Oerlikon: 482,
                Herisau: 545,
                Winterthur: 1657,
                Ost: 828,
                Arbon: 647,
                Schwanden: 115,
                West: 502,
            }

            return {
                legend: {
                    position: 'top',
                    labels: {
                        colors: '#ffffff',
                        useSeriesColors: false,
                    },
                },
                chart: {
                    stacked: true,
                },
                xaxis: {
                    categories: Object.keys(this.chartData)
                        .sort((a, b) => {
                            return settingTotals[b] - settingTotals[a]
                        })
                        .map((e) => {
                            return e.replace('Weiterempfehlung ', '').replace('St. Gallen ', '')
                            return (
                                e.replace('Weiterempfehlung ', '').replace('St. Gallen ', '') +
                                ' ' +
                                Math.round(
                                    (100 /
                                        customerAmount[e.replace('Weiterempfehlung ', '').replace('St. Gallen ', '')]) *
                                        settingTotals[e]
                                ) +
                                '%'
                            )
                        }),
                    labels: {
                        rotate: -90,

                        rotateAlways: true,
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                colors: [
                    '#1abc9c', // Türkis
                    '#3498db', // Peter-River-Blau
                    '#9b59b6', // Amethyst
                    '#34495e', // Nassasphalt
                    '#e74c3c', // Alizarinrot
                    '#f1c40f', // Sonnengelb
                    '#2980b9', // Belize-Loch
                    '#ecf0f1', // Wolkenweiß
                ],
                tooltip: {
                    y: {
                        formatter: function (val, opts) {
                            const index = opts.dataPointIndex
                            const total = opts.w.globals.series.map((s) => s[index]).reduce((a, b) => a + b, 0)
                            const percent = ((val / total) * 100).toFixed(2)
                            return val + ' (' + percent + '%)'
                        },
                    },
                },
            }
        },
    },
}
</script>
