<template>
    <router-view
        :datePickerRange="datePickerRange"
        class="dashboard"
        :studios="studios"
        :selectedStudioSingle="selectedStudioSingle"></router-view>
</template>
<script>
export default {
    props: {
        studios: Array,
        selectedStudioSingle: [String, Object],
        datePickerRange: Array,
    },
}
</script>
<style scoped>
.dashboard {
    background-color: #343541;
    min-height: 80vh;
}
</style>