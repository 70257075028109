<template xmlns="">
	<swiper ref="workoutSwiper" :options="workoutSwiperOptions">
		<swiper-slide class="workoutEdit__swiper my-5">
			<v-card class="workoutEdit__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div v-if="form.tp.title !== null">
					<v-card-title class="workingOut__swiper-card-title h5 primary--text justify-center"> Trainingsplan bearbeiten </v-card-title>

					<v-form class="workoutEdit__swiper-card-form">
						<v-text-field class="pt-3" ref="title" v-model="form.tp.title" :rules="rules.title" counter="50" required label="Titel">
						</v-text-field>

						<v-text-field
							class="pt-3"
							ref="duration"
							v-model="form.tp.duration"
							:rules="rules.duration"
							counter="3"
							required
							label="Dauer in Minuten"
							type="number"
						>
						</v-text-field>

						<user-search :value="form.tp.user_id" rights="tp_create" label="Kunde" @update="updateCustomer"></user-search>

						<user-search
							:value="form.tp.instructor_id"
							rights="tp_create"
							label="Trainer"
							instructor
							@update="updateInstructor"
						></user-search>

						<v-menu
							ref="menu1"
							v-model="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="form.tp.due"
									label="Neuer Plan fällig"
									ref="calendar"
									prepend-icon="mdi-calendar"
									:rules="[(v) => !!v || 'Required']"
									v-on="on"
								>
								</v-text-field>
							</template>
							<v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
						</v-menu>
					</v-form>
				</div>
				<div v-else class="fill-height">
					<lottie-player
						mode="normal"
						class="workoutEdit__lottie-player"
						:autoplay="false"
						src="/img/lottiefiles/dumbleIcon.json"
					></lottie-player>
				</div>
				<v-card-actions class="justify-center">
					<v-btn color="primary" rounded :disabled="!formIsValid" class="text-white" @click="validateForm"> weiter</v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>

		<swiper-slide class="workoutEdit__swiper my-5" v-for="(exercise, index) in form.tp_exercises" :key="index">
			<v-card v-if="!exerciseInEditMode.title" class="workoutEdit__swiper-card d-flex flex-column elevation-7 rounded-xl">
				<v-card-title class="workoutEdit__swiper-card-title h5 primary--text justify-center">
					<div>{{ exercise.title }}</div>
					<v-btn class="workoutEdit__swiper-card-delete" rounded icon @click="removeExerciseByIndex(index)">
						<v-icon color="red">mdi-delete</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-subtitle class="primary--text text-center">
					{{ exercise.shortForm }}
				</v-card-subtitle>

				<div class="workoutEdit__swiper-card-content">
					<v-img contain class="workoutEdit__swiper-card-image" :src="getImageUrl(exercise.picture)"></v-img>

					<div class="workoutEdit__swiper-card-steps__wrapper">
						<div
							class="workoutEdit__swiper-card-steps d-flex flex-direction-column justify-space-around"
							:key="index"
							v-for="(setting, index) in exercise.tp_exercise_settings.filter((field) => field.value)"
						>
							<v-card-text class="pa-2 body-1">
								{{ setting.title || setting.name }}
							</v-card-text>
							<v-card-text class="pa-2 h5">
								<v-btn depressed>
									{{ setting.value }}
								</v-btn>
							</v-card-text>
						</div>
					</div>
				</div>
				<v-card-actions class="justify-center">
					<v-btn color="primary" class="text-white" rounded @click="setExerciseInEditMode(exercise)"> Überarbeiten</v-btn>
				</v-card-actions>
			</v-card>
			<workout-create-edit-exercise
				v-else
				:exercise="exerciseInEditMode"
				@resetEdit="resetExerciseInEditMode"
				@saveEdit="saveExerciseEdited"
			></workout-create-edit-exercise>
		</swiper-slide>
		<workout-add-exercise @add="addExercise"></workout-add-exercise>

		<workout-create-submit-slide
			v-if="form.tp_exercises"
			@resorted="exerciseArrayResorted"
			edit
			:exercises="form.tp_exercises"
			@updateExercise="updateExercises"
			@changeSorting="setSwiperAllowTouchMove"
			@save="updateTrainingsplan"
		></workout-create-submit-slide>
		<div class="swiper-pagination" slot="pagination"></div>
	</swiper>
</template>

<script>
import api from '../../api'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { workingoutStore } from '../../store/workingoutStore'
import WorkoutAddExercise from './WorkoutAddExercise'
import Dialog from '../../components/Dialog'
import WorkoutCreateEditExercise from './WorkoutCreateEditExercise'
import WorkoutCreateSubmitSlide from './WorkoutCreateSubmitSlide.vue'
import userService from '../../services/userService'
import userSearch from '../../components/userSearch.vue'

export default {
	name: 'WorkoutEdit',
	components: {
		WorkoutAddExercise,
		Swiper,
		userSearch,
		SwiperSlide,
		WorkoutCreateEditExercise,
		WorkoutCreateSubmitSlide,
		Dialog,
	},
	data() {
		return {
			userService,
			menu: false,
			exercises: [],
			exerciseInEditMode: {
				_id: null,
			},
			date: '',
			form: {
				tp: {
					title: null,
					ts: this.$moment()
						.unix()
						.toString(),
					due: '',
					duration: '',
					user_id: null,
					instructor_id: null,
				},
				tp_exercises: [],
			},
			rules: {
				title: [(v) => (v.length <= 50 && v.length > 0) || 'Der Titel darf bis zu 50 Zeichen lang sein.'],
				duration: [(v) => (v.length <= 3 && v.length > 0) || 'Maximal 3 Zeichen'],
			},
		}
	},
	created() {
		api.getTrainingPlan(this.$route.params.id).then((plan) => {
			this.date = this.$moment.unix(plan.tp.due).format('YYYY-MM-DD')
			plan.tp.duration = plan.tp.duration.toString()
			plan._id = this.$route.params.id
			this.form = plan
		})
	},
	methods: {
		updateCustomer(id) {
			this.form.tp.user_id = id
		},
		updateInstructor(id) {
			this.form.tp.instructor_id = id
		},
		updateTrainingsplan() {
			let formData = this.getFormData()
			api.updateTrainingsPlan(formData).then((data) => {
				this.$router.push('/workouts')
			})
		},
		updateExercises(newExercises) {
			this.form.tp_exercises = newExercises
		},
		exerciseArrayResorted(exerciseArray) {
			this.form.tp_exercises = exerciseArray
		},
		formIsValid() {
			if (this.$refs.title.validate() && this.$refs.duration.validate()) {
				return true
			} else {
				return false
			}
		},
		setSwiperAllowTouchMove(value) {
			this.$refs.workoutSwiper.$swiper.allowTouchMove = value
		},

		removeExerciseByIndex(index) {
			this.form.tp_exercises.splice(index, 1)
		},

		addExercise(exercise) {
			exercise.tp_exercise_settings = exercise.fields
			delete exercise.fields, exercise._id
			this.form.tp_exercises.push(exercise)
		},

		resetExerciseInEditMode() {
			this.exerciseInEditMode = {
				_id: null,
			}
			this.setSwiperAllowTouchMove(true)
		},
		setExerciseInEditMode(exercise) {
			this.exerciseInEditMode = JSON.parse(JSON.stringify(exercise))
			this.setSwiperAllowTouchMove(false)
		},
		saveExerciseEdited() {
			let index = this.form.tp_exercises.findIndex((exercise) => exercise._id === this.exerciseInEditMode._id)
			this.form.tp_exercises[index] = this.exerciseInEditMode
			this.resetExerciseInEditMode()
		},
		validateForm() {
			if (this.$refs.title.valid && this.$refs.duration.valid) {
				this.slideTo(1)
			}
		},
		getFormData() {
			let newFormData = { ...this.form }
			let exerciseArray = []
			newFormData.tp_exercises.forEach((exercise, index) => {
				exercise.tp_exercise_settings = exercise.tp_exercise_settings
					.filter((setting) => setting.value)
					.map((setting) => {
						return {
							exercise_setting_id: setting.exercise_setting_id || setting._id,
							value: setting.value,
							title: setting.title || setting.name,
						}
					})

				exercise.sort_order = index
				let serializedExercise = {
					exercise_id: exercise._id,
					sort_order: exercise.sort_order,
					tp_exercise_settings: exercise.tp_exercise_settings,
				}
				exerciseArray.push(serializedExercise)
			})
			newFormData.tp_exercises = exerciseArray
			newFormData.tp.due = this.getDateAsUnixString()
			newFormData.tp.duration = parseInt(newFormData.tp.duration)
			return newFormData
		},
		getImageUrl(imageId) {
			return '/img/uebungen/' + imageId
		},
		slideTo(slide) {
			if (slide == 1) {
				this.showDialog = true
			}
			this.setSwiperAllowTouchMove(true)
			this.$refs.workoutSwiper.$swiper.slideTo(slide, 1000, false)
		},
		getItemText(user) {
			return user.vorname + ' ' + user.nachname + ' (' + user.email + ')'
		},
		formatDate(date) {
			return this.$moment(date).format('DD. MM. YYYY')
		},
		parseDate(date) {
			return this.$moment(date).format('YYYY-MM-DD')
		},
		getDateAsUnixString() {
			return this.$moment(this.form.tp.due, 'DD. MM. YYYY')
				.unix()
				.toString()
		},
	},
	computed: {
		workoutSwiperOptions: function() {
			return {
				allowTouchMove: false,
				pagination: {
					el: '.swiper-pagination',
					dynamicBullets: true,
				},
			}
		},
	},
	watch: {
		date(val) {
			this.form.tp.due = this.formatDate(this.date)
		},
	},
}
</script>
<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.v-card {
	transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
	bottom: 5px;
}

.workoutEdit {
	&__swiper {
		width: 90% !important;
		height: 90% !important;
		margin-left: 5%;
		margin-right: 5%;

		&-card {
			text-align: -webkit-center;
			height: 80vh;
			min-height: 500px;

			&-content {
				overflow-y: auto;
				height: 62vh;
			}
			&-image {
				margin: 0 10%;
				height: 40%;
				background-size: contain;
			}

			&-form {
				width: 80%;
			}

			&-title {
				word-break: break-word;
			}

			&-delete {
				position: absolute;
				right: 20px;
			}

			&-steps {
				width: unset;

				&__wrapper {
					width: 80%;
					height: 60%;
					text-align: center;
					margin-left: 10%;
				}
			}
		}
	}
	&__new-exercise-card {
		bottom: 0;
		opacity: 1 !important;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	&__lottie-player {
		display: unset;
	}
}
</style>
