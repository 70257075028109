<template>
    <v-container>
        <v-row v-if="loading">
            <v-col cols="12">
                <v-alert type="info">
                    <span>Daten werden geladen</span>
                </v-alert>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <v-progress-circular color="white" indeterminate></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="stats.length === 0 || error">
            <v-col cols="12">
                <v-alert type="info">
                    <span>Keine Verträge in dieser Zeitspanne | in diesen Studios gefunden</span>
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-simple-table dark>
                    <thead>
                        <tr>
                            <th class="text-left">Grund</th>
                            <th class="text-left">Anzahl</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(reasonKey, index) of Object.keys(reasonMapper).sort()" :key="index + '-reasonList'">
                            <td>{{ reasonKey }}</td>
                            <td>{{ reasonMapper[reasonKey] }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col cols="12">
                <v-card dark class="px-5">
                    <v-row>
                        <v-col cols="12" v-if="diverseReasons.length > 0">
                            <v-card-subtitle class="px-0">Weitere Gründe Auflistung:</v-card-subtitle>
                        </v-col>
                        <v-col v-for="(divReason, i) of diverseReasons" :key="i + '-divReason'" cols="12">
                            {{ i + 1 }}. {{ divReason }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from '@/api'

export default {
    props: {
        datePickerRange: {
            type: Array,
            default: () => [],
        },
        studios: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            stats: [],
            loading: false,
            error: false,
        }
    },
    async created() {
        this.fetchContractStats()
    },
    methods: {
        async fetchContractStats() {
            if (!(this.studios.length > 0 && this.datePickerRange.length === 2)) {
                return
            }
            this.loading = true
            this.error = false

            try {
                let stats = await api.fetchContractStats({
                    dateRange: this.datePickerRange,
                    studios: this.studios.map((e) => e._id),
                })
                this.stats = stats
            } catch (e) {
                this.error = true
                this.$toast.error('Fehler beim Laden der Contract Statistik')
            }
            this.loading = false
        },
    },
    computed: {
        diverseReasons() {
            if (this.stats === []) return []
            let diverseReasons = []
            this.stats.forEach((s) => {
                s.reasons.forEach((r) => {
                    if (r === 'Weitere' && s.comments[0]) diverseReasons.push(s.comments)
                })
            })

            return diverseReasons.flat()
        },
        reasonMapper() {
            if (this.stats === []) return {}

            let countReasons = {}

            this.stats.forEach((s) => {
                s.reasons.forEach((r) => {
                    if (!countReasons[r]) countReasons[r] = 0
                    countReasons[r]++
                })
            })

            return countReasons
        },
    },
    watch: {
        async datePickerRange(newVal, oldVal) {
            this.fetchContractStats()
        },
        async studios(newVal, oldVal) {
            this.fetchContractStats()
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
 