<template>
    <div>
        <v-card dark>
            <v-switch
                class="pa-5"
                color="primary"
                label="Alle Rechte einzeln anzeigen"
                v-model="showAllRights"
            ></v-switch>
            <v-text-field
                class="px-5 mb-2"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            <v-data-table
                @click:row="pushToUser"
                :headers="headersFiltered"
                :items="userList"
                :items-per-page="600"
                class="elevation-1"
            ></v-data-table>
        </v-card>
    </div>
</template>
<script>
import userService from '../../../services/userService'
import api from '../../../api'
import 'swiper/css/swiper.css'
export default {
    data() {
        return {
            search: '',
            showAllRights: false,
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                    width: '12.5%',
                },
                { text: 'E-Mail', value: 'email', width: '12.5%' },
                { text: 'Rechtegruppen', value: 'userRoles', width: '12.5%' },
                { text: 'Einzel Rechte', value: 'specialRights', width: '12.5%' },
                { text: 'Alle Rechte einzeln', value: 'allRights' },
            ],
            userList: [],
            userListBackup: [],
        }
    },
    async created() {
        this.fetchUserList()
    },
    methods: {
        pushToUser(user) {
            if (confirm('Zum Benutzer ' + user.name + ' wechseln?'))
                this.$router.push('/dashboard/user/search/' + user._id)
        },
        async fetchUserList() {
            try {
                let list = await api.fetchUserRightList()
                this.userListBackup = JSON.parse(JSON.stringify(list.user))
                this.userList = list.user
            } catch (e) {
                this.$toast.error('Fehler beim Laden der Benutzerliste')
            }
        },
    },
    computed: {
        headersFiltered() {
            let headers = this.headers
            if (!this.showAllRights) headers = headers.filter((e) => e.value !== 'allRights')
            return headers
        },
    },
    watch: {
        search() {
            if (this.search.length > 0) {
                this.userList = this.userListBackup.filter((user) => {
                    return (
                        user.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        user.email.toLowerCase().includes(this.search.toLowerCase()) ||
                        user.userRoles.join().toLowerCase().includes(this.search.toLowerCase()) ||
                        user.specialRights.join().toLowerCase().includes(this.search.toLowerCase()) ||
                        user.allRights.join().toLowerCase().includes(this.search.toLowerCase())
                    )
                })
            } else {
                this.userList = this.userListBackup
            }
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
