<template>
    <div class="nextCourses">
        <!---<v-row class="tag my-5 px-5" v-if="!location.hostname.includes('local')">
			<span @click="$router.push('/kurse')" class="tag_title">GROUP FITNESS</span>
			<span @click="$router.push('/kurse')" class="tag_subtitle"><v-icon color="#585858">mdi-transfer-right</v-icon></span>
		</v-row>--->

        <v-row>
            <v-col cols="12" class="d-flex justify-center pt-0">
                <v-chip-group>
                    <v-chip
                        v-for="(item, index) in items"
                        :key="index"
                        v-show="item.show"
                        :class="{ 'selected-chip': selectedIndex === index }"
                        @click="selectChip(index)"
                        class="rounded-lg"
                    >
                        {{ item.label }}
                    </v-chip>
                </v-chip-group>
            </v-col>
        </v-row>
        <v-row v-if="selectedIndex === 0 || selectedIndex === 1" class="nextCourses-container">
            <v-col
                v-for="course of courseList"
                :key="course._id"
                cols="11"
                class="nextCourses-container-col rounded-lg"
                :class="{ 'mx-auto mb-2': showCourse(course), 'my-0 py-0': !showCourse(course) }"
            >
                <v-row v-if="showCourse(course)" @click="pushToCourse(course)" no-gutters class="nextCourses__entry">
                    <v-col cols="3">
                        <div style="position: relative">
                            <v-img
                                cover
                                height="50px"
                                max-width="100px"
                                width="100%"
                                class="rounded-lg mx-auto"
                                :src="
                                    course && course.course && course.course.bild
                                        ? '/img/kurse/' + course.course.bild
                                        : ''
                                "
                            ></v-img>

                            <span
                                style="
                                    position: absolute;
                                    top: -10px;
                                    right: -10px;
                                    background-color: #ffd700;
                                    color: white;
                                    border-radius: 20px;
                                    font-size: 14px;
                                "
                                v-if="courseRecommendations.includes(course.course._id)"
                            >
                                <v-icon class="pa-1" small color="white">mdi-star</v-icon>
                            </span>
                        </div>
                    </v-col>

                    <v-col class="pl-5" cols="7">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <span class="nextCourses__entry-title font-weight-bold">
                                    {{ course && course.course && course.course.name ? course.course.name : '' }}
                                </span>
                            </v-col>
                            <v-col cols="12" style="font-size: 14px" class="pb-0 pt-1 d-flex align-center">
                                <v-icon small>mdi-clock-time-eight</v-icon>
                                <span class="pl-1">{{ courseStartDate(course) }} Uhr | {{ course.duration }} Min</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2">
                        <div v-if="selectedIndex === 0">
                            <div v-if="course.onQueue" class="nextCourses__card-signedUp">
                                <v-icon class="pl-1" color="red">mdi-clipboard-alert</v-icon>
                            </div>
                            <div v-if="course.signedUp && !course.onQueue" class="nextCourses__card-signedUp">
                                <v-icon class="pl-1" color="primary">mdi-check-circle</v-icon>
                            </div>
                            <div v-if="!course.onQueue && !course.signedUp" class="single-chart mx-auto">
                                <svg viewBox="0 0 36 36" class="circular-chart green-color ml-auto">
                                    <path
                                        class="circle-bg"
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path
                                        :style="
                                            getStrokeColor(
                                                calcPercentage(course),
                                                course.course.slimMe,
                                                course.cancelled || course.cancelledVisibility,
                                                course.signedUp
                                            )
                                        "
                                        class="circle"
                                        :stroke-dasharray="calcPercentage(course) + ', 100'"
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="course.lsSignedUp" class="nextCourses__card-signedUp">
                                <v-icon class="pl-1" color="primary">mdi-check-circle</v-icon>
                            </div>
                            <div v-if="!course.lsSignedUp && !course.onQueue" class="nextCourses__card-signedUp">
                                <v-icon class="pl-1" color="red">mdi-video</v-icon>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <news-slider class="mt-5" v-if="selectedIndex === 2"></news-slider>
        <trainingsplan-slider class="mt-5" v-if="selectedIndex === 3"></trainingsplan-slider>
        <div class="transparency-bottom"></div>
        <v-dialog v-model="showDialog" transition="dialog-bottom-transition" max-width="600">
            <v-card>
                <v-toolbar color="primary" dark>Livestreams</v-toolbar>
                <v-card-text class="mt-5">
                    Wechsel in das Studio "LIVESTREAMS", um dich für Livestream Kurse anzumelden.
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn text @click="showDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" class="text--white" text @click="showStudioPicker()">Weiter</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import abortService from '../../services/abortService'
import newsSlider from '../../components/news/newsSlider.vue'
import trainingsplanSlider from '../../components/trainingsplan/trainingsplanSlider.vue'
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'nextCoursesHome',
    components: { newsSlider, trainingsplanSlider },
    data() {
        return {
            items: [
                { show: true, label: 'Nächste Kurse' },
                { show: true, label: 'Livestreams' },
                { show: true, label: 'News' },
                /*{ show: true, label: 'Trainingspläne' },*/
            ],
            selectedIndex: 0,
            userService,
            loading: true,
            courseList: [{}, {}, {}],
            ts: null,
            abortController: null,
            showDialog: false,
        }
    },

    async created() {
        this.abortController = new abortService()
        if (this.alibeyStudio && this.$moment().unix() <= 1653688800 * 1000) {
            this.ts = this.$moment(1653688800 * 1000)
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .unix()
        } else {
            this.ts = this.$moment().set({ hours: 0, minutes: 0, seconds: 0 }).unix()
        }
        console.log(this.ts)

        await this.fetchCourseList()
    },
    beforeDestroy() {
        this.abortController.abort()
    },
    methods: {
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
        showCourse(course) {
            return (
                course &&
                course.course &&
                (!course.cancelled || this.userService.hasRight('gf_cp_viewCancelled') || course.isInstructor) &&
                (!('past' in course) || course.current)
            )
        },
        async selectChip(index) {
            this.selectedIndex = index
            if (index === 0 || index === 1) this.fetchCourseList()
        },
        getStrokeColor(percentage, slimMe = false, cancelled = false, signedUp) {
            if (cancelled) return 'stroke: red;'
            if (slimMe && percentage > 80 && !signedUp) return 'stroke: #88b9c7;'
            if (percentage > 50) {
                return 'stroke: var(--v-primary);'
            }
            if (percentage > 33) {
                return 'stroke: #ff9f00;'
            }
            if (percentage <= 33) {
                return 'stroke: red;'
            }
        },

        calcPercentage(course) {
            if (course.signups) {
                return 100 - Math.round((100 / course.amount) * course.signups.length)
            } else {
                return 100
            }
        },
        courseEndDate(course) {
            let endTime = this.$moment()
            endTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            endTime.add('minutes', course.duration)
            return endTime.format('LT')
        },
        courseStartDate(course) {
            let startTime = this.$moment()

            startTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            return startTime.format('LT')
        },
        pushToCourse(course) {
            if (this.sirnachStudio) return this.$router.push('/kurse')
            if (this.selectedIndex === 1) {
                this.showDialog = true
            } else {
                if (!course.past && !course.current) {
                    let ts = this.ts
                    if (course.courseTimelineId) {
                        ts = 0
                    }
                    this.$router.push({ name: 'courseListView', params: { id: course._id, ts } })
                }
            }
        },
        async fetchCourseList() {
            this.loading = true
            let courseList = await api.fetchCourseList(this.abortController.signal, this.ts)
            let activeCourses = courseList.filter((e) => (e.course && !e.past) || (e.course && e.current)).length
            let courseListLS = await api.fetchLSList(this.abortController.signal, this.ts)
            let activeCoursesLS = courseListLS.filter((e) => (e.course && !e.past) || (e.course && e.current)).length

            if (activeCourses === 0 && activeCoursesLS === 0) {
                this.items[0].show = false
                this.items[1].show = false
                this.selectedIndex = 2
            } else if (activeCourses === 0) {
                this.items[0].show = false
                if (this.selectedIndex === 0) this.selectedIndex = 1
            } else if (activeCoursesLS === 0) {
                this.items[1].show = false
            }

            if (this.selectedIndex === 0) {
                this.courseList = courseList
            } else if (this.selectedIndex === 1) {
                this.courseList = courseListLS
            }

            this.loading = false
        },
    },
    computed: {
        ...mapGetters('userStore', [
            'courseRecommendations', // Zugriff auf den Getter courseRecommendations
        ]),
        location() {
            return location
        },
        sirnachStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && studioCode === 'sirnach') {
                return true
            } else {
                return false
            }
        },
        alibeyStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && (studioCode === 'Alibey' || studioCode === 'alibey' || studioCode === 'ali')) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/circlehome';
.selected-chip {
    background-color: var(--v-primary) !important;
    color: white;
}
.tag {
    display: flex;
    justify-content: space-between;

    .tag_title {
        font-size: 18px;
        letter-spacing: 0.5px;
    }

    .tag_subtitle {
        font-size: 14px;
        margin: auto 0px;
    }
    span {
        font-weight: bold;
    }
}
.nextCourses {
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    &-card {
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
            0px 1px 5px 0px rgb(0 0 0 / 12%);
    }
    .transparency-bottom {
        position: absolute;
        width: 100%;
        height: 20px;
        bottom: 0px;
        right: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 1));
    }
    &-container {
        margin-bottom: 90px;
        overflow-y: auto;

        &-col {
            //background: linear-gradient(180deg, #d1dfdf, #88c7b5 79%);
        }
    }
    &__entry {
        &-time {
            font-size: 14px;
        }
    }
    &__card {
        width: 100%;

        background: #c3e8ff;
        color: #0d1c35;

        &-action {
        }
        &:first-of-type {
            margin-left: 20px;
        }
        &:last-of-type {
            margin-right: 25px;
        }
        &-current {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 60px;
            height: 30px;
            background-color: #f00;
            border-radius: 50px;
            color: #fff;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
            letter-spacing: 1px;
        }
        &-signedUp {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 30px;
            height: 30px;
            background-color: white;
            border-radius: 50%;
            i {
                font-size: 40px;
                line-height: 100%;
                margin: -5px;
            }
        }
        &-transparency {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #0000003d;
        }
        &-text {
            height: 100%;
            &-coursetitle {
                font-weight: bold;
                font-size: 16px;
            }
            &-time {
                &-duration {
                    line-height: 17px !important;
                    margin: auto 5px;
                    font-weight: bold;
                    line-height: 25px;
                }
            }
        }
        &-container {
            overflow-x: auto;
            &-mobile {
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        }
    }
}
</style>
