<template>
    <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
    data() {
        return {
            chartOptions: {
                labels: [this.referralLeadsCount.name],

                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
            },
            series: [this.referralLeadsCount.data],
        }
    },
    props: {
        referralLeadsCount: Object,
    },
}
</script>
