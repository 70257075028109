<template xmlns="">
	<div class="workingOut__container">
		<workingout-header :workout="workout"></workingout-header>

		<swiper ref="workoutSwiper" :options="workoutSwiperOptions">
			<swiper-slide v-if="loading" class="workingOut__swiper">
				<v-card class="workingOut__swiper-card elevation-7 mt-5 rounded-xl">
					<lottie-player
						mode="normal"
						class="workingOut__lottie-player"
						name="loading"
						:autoplay="false"
						src="/img/lottiefiles/dumbleIcon.json"
					></lottie-player>
				</v-card>
			</swiper-slide>
			<swiper-slide class="workingOut__swiper my-5" :key="index" v-for="(exercise, index) in exercices">
				<div class="workingOut__swiper-card d-flex flex-column">
					<v-card-title class="workingOut__swiper-card-title h5 primary--text justify-center">
						{{ exercise.title }}
					</v-card-title>

					<v-card-subtitle class="primary--text text-center">
						{{ exercise.shortForm }}
					</v-card-subtitle>

					<div class="workingOut__swiper-card-content">
						<v-img contain class="workingOut__swiper-card-image" :src="getImageUrl(exercise.picture)"></v-img>

						<div class="workingOut__swiper-card-steps__wrapper">
							<div
								class="workingOut__swiper-card-steps d-flex flex-direction-column justify-space-around"
								:key="index"
								v-for="(setting, index) in exercise.tp_exercise_settings.filter((setting) => setting.value)"
							>
								<v-card-text class="pa-2 body-2">
									{{ setting.title }}
								</v-card-text>
								<v-card-text class="pa-2 body-1" style="font-weight: bold;">
									{{ setting.value }}
								</v-card-text>
							</div>
						</div>
					</div>
					<v-card-actions class="justify-center">
						<v-btn color="primary" rounded class="text-white" @click="toggleGuide"> Anleitung</v-btn>
						<v-btn color="primary" rounded class="text-white" @click="toggleStats">Statistik</v-btn>
						<v-btn color="primary" rounded class="text-white" @click="toggleNewStep"> {{ getTextForNewStepButton(exercise) }}</v-btn>
					</v-card-actions>

					<v-scale-transition class="mb-5" origin="bottom left">
						<workingout-guide v-if="expand.guide" @closeGuide="toggleGuide" :steps="exercise.guide"></workingout-guide>
					</v-scale-transition>
					<v-scale-transition class="mb-5" origin="bottom center">
						<workingout-statistic
							v-if="expand.stats"
							:exercise_id="exercise._id"
							:plan_id="$route.params.id"
							:title="exercise.title"
							@closeStatistic="toggleStats"
						></workingout-statistic>
					</v-scale-transition>
					<v-scale-transition class="mb-5" origin="bottom right">
						<workingout-step
							v-if="expand.newStep"
							:exercise="exercise"
							:step-count="getStepCountForExercise(exercise._id)"
							@closeNewStep="toggleNewStep"
						></workingout-step>
					</v-scale-transition>
				</div>
			</swiper-slide>

			<workingout-submit-slide></workingout-submit-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</div>
</template>

<script>
import api from '../../api'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import WorkingoutHeader from './WorkingoutHeader'
import WorkingoutGuide from './WorkingoutGuide'
import WorkingoutStep from './WorkingoutStep'
import WorkingoutStatistic from './WorkingoutStatistic'
import WorkingoutSubmitSlide from './WorkingoutSubmitSlide'
import 'swiper/css/swiper.css'
import { mapState, mapActions } from 'vuex'
import WorkoutAddExercise from './WorkoutAddExercise'

export default {
	name: 'Workingout',
	components: {
		WorkoutAddExercise,
		WorkingoutHeader,
		WorkingoutGuide,
		WorkingoutStatistic,
		WorkingoutStep,
		WorkingoutSubmitSlide,
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	data() {
		return {
			loading: true,
			workout: {},
			exercices: [],
			expand: {
				guide: false,
				stats: false,
				newStep: false,
			},
		}
	},
	created() {
		this.fetchItems()
	},
	methods: {
		...mapActions('workingoutStore', ['setWorkoutStepsHistory']),
		fetchItems() {
			api.getWorkout(this.$route.params.id).then((data) => {
				this.workout = data.tp
				this.exercices = data.tp_exercises
				this.loading = false
			})
			api.getWorkoutHistory(this.$route.params.id).then((data) => this.setWorkoutStepsHistory(data))
		},
		getImageUrl(imageId) {
			return '/img/uebungen/' + imageId
		},
		toggleGuide() {
			this.expand.guide = !this.expand.guide
			this.toggleSwiperAllowTouchMove()
		},
		toggleStats() {
			this.expand.stats = !this.expand.stats
			this.toggleSwiperAllowTouchMove()
		},
		toggleNewStep() {
			this.expand.newStep = !this.expand.newStep
			this.toggleSwiperAllowTouchMove()
		},
		toggleSwiperAllowTouchMove() {
			this.swiper.allowTouchMove = !this.swiper.allowTouchMove
		},
		settingsForExercice(shortForm, index) {
			let settings = this.exercices[index].settings

			return Object.entries(settings)
		},
		getStepCountForExercise(id) {
			let exercise = this.finishedSteps.find((exercise) => exercise.exercise_id === id)
			if (!exercise || exercise.note) return 1
			return exercise.steps.length + 1
		},
		exerciseHasWeightSetting(exercise) {
			return !!exercise.tp_exercise_settings.find((setting) => setting.title === 'Gewicht')
		},
		getTextForNewStepButton(exercise) {
			return this.exerciseHasWeightSetting(exercise) ? 'Satz ' + this.getStepCountForExercise(exercise._id) + '.' : 'Notiz'
		},
	},
	computed: {
		...mapState('workingoutStore', ['finishedSteps']),
		swiper: function() {
			return this.$refs.workoutSwiper.$swiper
		},
		workoutSwiperOptions: function() {
			return {
				allowTouchMove: true,
				pagination: {
					el: '.swiper-pagination',
					dynamicBullets: true,
				},
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.v-application--wrap {
	background: lightgray !important;
}
.swiper-pagination {
	bottom: 5px;
}

.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOut {
	&__lottie-player {
		display: unset;
	}
	&__swiper {
		height: 90% !important;

		&-card {
			height: 80vh;
			min-height: 500px;

			&-title {
				word-break: break-word;
			}

			&-guide {
				height: 100%;
			}

			&-content {
				overflow-y: auto;
				height: 62vh;
			}

			&-image {
				margin: 0 10%;
				height: 40%;
				background-size: contain;
			}

			&-steps {
				width: unset;

				&-text {
					width: 100%;
					font-weight: bold;
				}

				&__wrapper {
					width: 80%;
					height: 60%;
					text-align: center;
					margin-left: 10%;
				}
			}
		}
	}
}
</style>
