<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="editModus" class="modal-title">Gerät bearbeiten</h5>
          <h5 v-else class="modal-title">Gerät hinzufügen</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="geraet_name">Name</label>
            <input
              id="geraet_name"
              v-model="geraet.titel"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="geraet_label">Interne Bezeichnung</label>
            <input
              id="geraet_label"
              v-model="geraet.label"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="geraet_beschreibung">Beschreibung</label>
            <input
              id="geraet_beschreibung"
              v-model="geraet.beschreibung"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="geraet_bild">Foto</label>

            <label for="bild-upload-edit">Foto hochladen</label>

            <input
              type="file"
              accept="image/*"
              @change="editImage($event)"
              id="bild-upload-edit"
            />

            <img
              v-show="geraet.bild"
              class="uploaded-image"
              :src="'/img/uebungen/' + geraet.bild"
            />
          </div>
          <div class="form-group">
            <label for="geraet_tag">Artikelnummer</label>

            <input
              id="geraet_tag"
              v-model="geraet.tag"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="editModus"
            type="button"
            class="btn btn-primary"
            @click.prevent="save"
          >
            Speichern
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click.prevent="save"
          >
            Gerät hinzufügen
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.prevent="hide"
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import api from "../api";

export default {
  data() {
    return {
      studiocode: "",
      error: "",
      changeStandort: false,
      editModus: false,
      geraet: {
        _id: null,
        old_id: null,
        studio_id: null,
        beschreibung: "",
        bild: "",
        label: "",
        tag: "",
        titel: "",
      },
    };
  },
  methods: {
    editImage(event) {
      this.bild = "test";

      if (event.target.files[0]) {
        this.bild = "test";
        let uploadimg = {};
        uploadimg.art = "uebung";
        const formData = new FormData();
        let file = event.target.files[0];

        formData.append("file", event.target.files[0]);

        uploadimg.formData = formData;
        console.log("editing");
        api
          .uploadImage(uploadimg)
          .then((data) => {
            if (data.status) {
              this.geraet.bild = "uploaded/" + data.name;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    show(id) {
      if (id) {
        this.editModus = true;
        api
          .getGeraetebyId(id)
          .then((data) => {
            this.geraet._id = data[0]._id;
            this.geraet.old_id = data[0].old_id;
            this.geraet.studio_id = data[0].studio_id;
            this.geraet.beschreibung = data[0].beschreibung;
            this.geraet.bild = data[0].bild;
            this.geraet.label = data[0].label;
            this.geraet.tag = data[0].tag;
            this.geraet.titel = data[0].titel;
          })
          .catch((error) => {
            alert("fehler");
          });
      } else {
        this.geraet = {
          _id: null,
          old_id: null,
          studio_id: null,
          beschreibung: "",
          bild: "",
          label: "",
          tag: "",
          titel: "",
        };
        this.editModus = false;
      }

      $(this.$refs["modal"]).modal("show");
    },
    hide() {
      $(this.$refs["modal"]).modal("hide");
    },
    save() {
      if (this.geraet.studio_id == null) {
        this.geraet.studio_id = JSON.parse(localStorage.getItem("studio"))._id;
      }
      api
        .saveGeraet({ geraet: this.geraet })
        .then((data) => {
          console.log(this.geraet);
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          alert("fehler");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
label[for="bild-upload-edit"] {
  border: 1px solid #ced4da;
  color: #495057;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  border-radius: 0.25rem;
}
#bild-upload-edit {
  display: block;
  visibility: hidden;
  height: 0px;
  width: 0px;
}
.uploaded-image {
  max-height: 200px;
  margin: 20px auto;
  display: block;
}
.modal-content {
  height: 90vh;
}
.modal-body {
  overflow: scroll;
}
</style>