<template>
    <v-card :dark="darkMode" elevation="0" transparent>
        <v-card-title style="word-break: break-word" :class="{ 'white--text': darkMode }">
            Einwilligungserklärung zur Bearbeitung von Personendaten {{ age }}
        </v-card-title>
        <v-card-text>
            <v-expansion-panels>
                <v-expansion-panel :key="'expansionpanel' + index" v-for="(consent, index) in consentText1">
                    <v-expansion-panel-header>
                        {{ consent.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        {{ consent.text }}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-card-text class="text-center">
            <span>
                Mit meiner Unterschrift erkläre ich meine Einwilligung zur Datenverarbeitung meiner personen- und
                gesundheitsbezogenen Daten
            </span>
            <signature-pad
                class="mx-auto mt-2"
                :signatureData="customerSignature"
                :darkMode="darkMode"
                autoSaveSignature
                @resetDrawing="resetSignature"
                @saveSignature="setSignature"></signature-pad
            ><br />
            <!--       <v-card-title>Unterschrift eines Elternteils</v-card-title>
            <signature-pad
                class="mx-auto mt-2"
                :signatureData="parentSignature"
                :darkMode="darkMode"
                autoSaveSignature
                @resetDrawing="resetParentSignature"
                @saveSignature="setParentSignature"></signature-pad
            ><br />
           -->
            <small
                >Du kannst unsere Datenschutzbestimmungen jederzeit unter app.wellcomefit.ch/datenschutz einsehen oder in
                deinem roody Profil, indem du das Menü rechts oben öffnest und 'Datenschutz' auswählst</small
            >
        </v-card-text>
        <v-card-actions v-if="withSave" class="justify-content-center pb-3">
            <v-btn :disabled="!customerSignature" color="primary" @click="saveConsent()">Einreichen</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import signaturePad from '../signaturePad/signaturePad.vue'
import userService from '../../services/userService'

import api from '@/api'
export default {
    name: 'consentDeclarationCard',
    props: {
        darkMode: {
            type: Boolean,
            default: true,
        },
        withSave: {
            type: Boolean,
            default: false,
        },
        age: {
            type: Number,
            default: 18,
        },
    },
    data() {
        return {
            userService,
        }
    },
    components: {
        signaturePad,
    },
    computed: {
        isBelow18() {
            console.log(userService.getUserBirthday())
            return true
        },
    },
    methods: {
        saveConsent() {
            api.saveConsent(this.customerSignature)
                .then(() => {
                    console.log('oii')
                    this.$emit('savedConsent')
                })
                .catch((e) => {
                    console.log(e)
                    this.$toast.error('Fehler beim abspeichern')
                })
        },

        resetSignature() {
            this.customerSignature = null
            this.$emit('savedSignature', this.customerSignature)
        },
        setSignature(signature) {
            this.customerSignature = signature
            this.$emit('savedSignature', this.customerSignature)
        },
        resetParentSignature() {
            this.customerSignature = null
            this.$emit('savedSignature', this.customerSignature)
        },
        setParentSignature(signature) {
            this.customerSignature = signature
            this.$emit('savedSignature', this.customerSignature)
        },
    },
    data() {
        return {
            customerSignature: null,
            consentText1: [
                {
                    title: '1. Art der bearbeiteten Daten',
                    text: 'Die bearbeiteten Personen- und Gesundheitsdaten können unter anderem Informationen über meine körperliche Fitness, Gesundheitszustand, Vorerkrankungen, Medikamenteneinnahme und andere relevante andere gesundheitsbezogene Informationen umfassen.',
                },
                {
                    title: '2. Zweck der Datenbearbeitung',
                    text: 'Ich stimme der Bearbeitung meiner Gesundheitsdaten zu, um mir eine angemessene Beratung, Betreuung und Durchführung von Fitness- und Gesundheitsdienstleistungen im Center zu ermöglichen. Diese umfassen u.a. die Erstellung und Überwachung meines individuellen Trainingsplans, die Auswertung meiner körperlichen Leistungsfähigkeit, die Erfassung meiner Gesundheitswerte (z.B.: Gewicht, Blutdruck, Herzfrequenz) und die Dokumentation von eventuellen Verletzungen oder gesundheitlichen Beschwerden.',
                },
                {
                    title: '3. Dauer der Datenbearbeitung',
                    text: 'Ich bin damit einverstanden, dass meine Gesundheitsdaten für den Zeitraum meiner Mitgliedschaft im Fitness-Center gespeichert werden. Nach Beendigung meiner Mitgliedschaft werden die Daten unter Beachtung der geltenden Datenschutzbestimmungen übergeben, gelöscht oder anonymisiert.',
                },
                {
                    title: '4. Weitergabe von Gesundheitsdaten',
                    text: 'Ich bin darüber informiert, dass das Fitness-Center meine Gesundheitsdaten nur berechtigten Personen zugänglich machen wird und Dritte nur nach meiner Information, und nur wenn dies für die ordnungsgemässe Durchführung der Dienstleistungen erforderlich ist. Jegliche Weitergabe erfolgt unter Einhaltung der geltenden Datenschutzgesetze und -bestimmungen.',
                },
                {
                    title: '5. Auskunft, Einsicht und Herausgabe',
                    text: 'Sie haben jederzeit das Recht, Auskunft zu Ihren Personendaten zu erhalten. Sie können Ihr Kundendossier einsehen oder auch eine Kopie verlangen. Die Herausgabe der Kopie kann kostenpflichtig sein. Allfällige Kosten, welche vom Aufwand der Erstellung der Kopie abhängen, werden Ihnen vorgängig bekannt gegeben.',
                },
                {
                    title: '6. Recht auf Datenübertragung',
                    text: 'Ich habe das Recht, Daten, die automatisiert oder digital bearbeitet werden, an mich oder an einen Dritten in einem gängigen, maschinenlesbaren Format übertragen zu lassen. Dies gilt insbesondere für die Weitergabe von medizinischen Daten an eine von mir gewünschte Gesundheitsfachperson. Wenn ich die direkte Übertragung der Daten an einen anderen Verantwortlichen verlange, erfolgt dies nur, soweit es technisch machbar ist.',
                },
                {
                    title: '7. Berichtigung Ihrer Angaben',
                    text: 'Wenn ich feststelle oder der Ansicht bin, dass meine Daten nicht korrekt oder unvollständig sind, habe ich die Möglichkeit, eine Berichtigung zu verlangen. Wenn weder die Korrektheit noch die Unvollständigkeit meiner Daten festgestellt werden kann, habe ich die Möglichkeit, einen Bestreitungsvermerk anzubringen.',
                },
                {
                    title: '8. Freiwilligkeit der Einwilligung',
                    text: 'Ich erkläre, dass meine Einwilligung zur Bearbeitung meiner Gesundheitsdaten freiwillig erfolgt und dass ich über meine Rechte im Zusammenhang mit der Datenbearbeitung informiert bin.',
                },
                {
                    title: '9. Widerruf der Einwilligung',
                    text: 'Ich habe das Recht, eine bereits erteilte Einwilligung zur Bearbeitung meiner Gesundheitsdaten jederzeit ganz oder teilweise mit Wirkung für die Zukunft zu widerrufen. Ein Widerruf berührt nicht die Rechtmässigkeit der bereits erfolgten Datenbearbeitung bis zum Zeitpunkt des Widerrufs.',
                },
            ],
        }
    },
}
</script>