<template>
    <v-card style="margin-bottom: 150px" flat>
        <v-row>
            <v-col cols="12">
                <v-btn @click="fetchMemberCategories">memba cat</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from '@/api'

export default {
    props: {},
    data() {
        return {}
    },
    created() {},
    methods: {
        async fetchMemberCategories() {
            try {
                let memberCategories = await api.fetchTACMembercategories()
                console.log('membercategories', memberCategories)
                let status = await api.saveTACMembercategories({ data: memberCategories.MemberGroups.MemberGroup })
            } catch (e) {
                this.$toast.error('Fehler beim fetchen der Member cats')
            }
        },
    },
}
</script>