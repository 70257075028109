<template></template>

<script>
export default {
	name: 'redirection',
	created() {
		let user = JSON.parse(localStorage.getItem('user'))
		let studioCode = null
		this.routeChanged
		if (user && user.user) studioCode = user.user.studio_id
		if (studioCode && studioCode === 'ot') {
			this.$router.push({ name: 'courseList' })
		} else {
			this.$router.push({ name: this.$route.params.routeName })
		}
	},
}
</script>
<style lang="scss" scoped></style>
