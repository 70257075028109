<template>
    <div class="primary text-white workingOut__header d-flex justify-space-between px-3">
      <router-link class="nav-before" to="/workouts/">
        <span class="text-white material-icons">navigate_before</span>
      </router-link>

      <span> {{ workout.title }} </span>
      <span> {{ workout.duration }} min</span>
    </div>
</template>

<script>
export default {
  props: {
    workout: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>