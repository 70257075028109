<template>
    <v-card class="mx-auto" :dark="dark" flat>
        <v-row no-gutters>
            <v-col cols="12" v-if="paymentEntry.status === 'created'" class="d-flex justify-center">
                <v-btn :loading="loading" @click="initPayment" color="primary">
                    <v-icon class="mr-2">mdi-contactless-payment-circle-outline</v-icon>
                    Jetzt Bezahlen
                </v-btn>
            </v-col>

            <v-col cols="12" v-if="paymentEntry.status === 'init'" class="d-flex justify-center">
                <payment-countdown
                    :whiteText="dark"
                    @timerEnded="paymentExpired = true"
                    :startTime="paymentEntry.transactionInitDate"
                ></payment-countdown>
            </v-col>
            <v-col cols="12" v-if="paymentEntry.status === 'init'" class="d-flex justify-center">
                <v-btn v-if="!paymentExpired" :loading="loading" @click="payNow()" color="primary" class="mt-5">
                    <v-icon v-if="false" class="mr-2">mdi-contactless-payment-circle-outline</v-icon>
                    Jetzt Bezahlen
                </v-btn>
            </v-col>
            <v-col
                cols="12"
                v-if="(paymentEntry.status === 'created' || paymentEntry.status === 'init') && showCancel"
                class="d-flex justify-center mt-3"
            >
                <v-btn @click="deletePayment" text>Zahlung löschen</v-btn>
            </v-col>
            <v-col
                v-if="showIcon && (paymentEntry.status === 'transmitted' || paymentEntry.status === 'settled')"
                cols="12"
                class="d-flex justify-center mt-2"
            >
                <img height="200px" src="https://app.wellcomefit.ch/img/contractIcons/confetti.gif" />
            </v-col>
            <v-col
                v-if="showIcon && (paymentEntry.status === 'transmitted' || paymentEntry.status === 'settled')"
                cols="12"
                class="d-flex justify-center"
            >
                <v-card-title class="mb-0 pb-0">Zahlung erfolgreich</v-card-title>
            </v-col>
            <v-col
                v-if="
                    showIcon &&
                    (paymentEntry.status === 'failed' ||
                        paymentEntry.status === 'canceled' ||
                        paymentEntry.status === 'deprecated')
                "
                cols="12"
                class="d-flex justify-center mt-2"
            >
                <img height="200px" src="https://app.wellcomefit.ch/img/contractIcons/cardDeclined.gif" />
            </v-col>
            <v-col
                v-if="
                    showIcon &&
                    (paymentEntry.status === 'failed' ||
                        paymentEntry.status === 'canceled' ||
                        paymentEntry.status === 'deprecated')
                "
                cols="12"
                class="d-flex justify-center"
            >
                <v-card-title class="mb-0 pb-0">
                    Zahlung {{ paymentEntry.status === 'failed' ? 'fehlgeschlagen' : 'abgebrochen' }}
                </v-card-title>
            </v-col>
            <v-col
                cols="12"
                v-if="paymentEntry.status === 'failed' || paymentEntry.status === 'canceled' || paymentExpired"
                class="d-flex justify-center"
            >
                <v-btn :loading="loading" @click="recreatePayment()" color="primary" class="mt-5">
                    <v-icon v-if="false" class="mr-2">mdi-contactless-payment-circle-outline</v-icon>
                    Erneut versuchen
                </v-btn>
            </v-col>

            <v-col
                cols="12"
                v-if="!hideDate"
                class="d-flex"
                :class="{
                    'justify-center':
                        showIcon &&
                        (paymentEntry.status === 'transmitted' ||
                            paymentEntry.status === 'failed' ||
                            paymentEntry.status === 'canceled'),
                }"
            >
                {{ $moment(paymentEntry.createdAt).format('DD.MM.YY HH:mm') }} Uhr
            </v-col>

            <v-col cols="12" :class="{ 'px-7': paddingTable }">
                <v-simple-table class="mt-5" :dark="dark">
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Status</td>
                                <td>{{ statusMapper[paymentEntry.status] }}</td>
                            </tr>

                            <tr
                                v-if="
                                    paymentEntry.detail && paymentEntry.detail.fail && paymentEntry.detail.fail.message
                                "
                            >
                                <td>Hinweis</td>
                                <td>{{ paymentEntry.detail.fail.message }}</td>
                            </tr>
                            <tr>
                                <td>Betrag</td>
                                <td>CHF {{ parseInt(paymentEntry.amount) }}.-</td>
                            </tr>
                            <tr v-if="paymentEntry.paymentMethod">
                                <td>Zahlungsmethode</td>
                                <td>{{ paymentEntry.paymentMethod }}</td>
                            </tr>
                            <tr v-if="paymentEntry.card && paymentEntry.card.masked">
                                <td>Karte</td>
                                <td>{{ paymentEntry.card.masked }}</td>
                            </tr>
                            <tr>
                                <td>Kunde</td>
                                <td>{{ paymentEntry.user.firstName }} {{ paymentEntry.user.lastName }}</td>
                            </tr>
                            <tr>
                                <td>Visum</td>
                                <td>{{ paymentEntry.createdBy.firstName }} {{ paymentEntry.createdBy.lastName }}</td>
                            </tr>
                            <tr v-for="(note, i) of paymentEntry.notes" :key="i + '-paymentNoteIndex'">
                                <td>Notiz</td>
                                <td>{{ note.note }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card>
</template>  

<script>
import api from '../../../api'
import PaymentCountdown from './PaymentCountdown'
export default {
    components: { PaymentCountdown },
    props: {
        paymentEntry: {
            type: Object,
            required: true,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: true,
        },
        paddingTable: {
            type: Boolean,
            default: true,
        },
        hideDate: {
            type: Boolean,
            default: false,
        },
        showCancel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            paymentExpired: false,
            loading: false,
            statusMapper: {
                init: 'Offen',
                initialized: 'Offen',
                authenticated: 'Authentifiziert',
                authorized: 'Autorisiert',
                settled: 'Abgerechnet',
                canceled: 'Abgebrochen',
                transmitted: 'Übermittelt',
                failed: 'Fehlgeschlagen',
                created: 'Offen',
                deprecated: 'Abgebrochen',
            },
        }
    },
    methods: {
        async deletePayment() {
            try {
                let status = await api.deletePayment(this.paymentEntry._id)
                this.$toast.success('Zahlung wurde abgebrochen')
                this.$emit('recreatePayment')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim löschen')
            }
        },
        async recreatePayment() {
            try {
                let status = await api.recreatePayment(this.paymentEntry._id)
                this.$toast.success('Zahlung wurde neu erstellt')
                this.$emit('recreatePayment')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim erneuten Versuch')
            }
        },
        async payNow(transactionIdParam = null) {
            try {
                let transactionId = transactionIdParam ? transactionIdParam : this.paymentEntry.transactionId
                console.log(transactionId)
                let ref = this
                Datatrans.startPayment({
                    transactionId,
                    opened: function () {
                        console.log('payment-form opened')
                    },
                    loaded: function () {
                        console.log('payment-form loaded')
                    },
                    closed: function () {
                        console.log('payment-page closed')
                        ref.$emit('closePayment')
                    },
                    error: function () {
                        console.log('error')
                        ref.$emit('errorPayment')
                    },
                })
            } catch (e) {
                this.$toast.error('Fehler beim Bezahlen', e)
            }
        },
        async initPayment() {
            this.loading = true
            let url =
                'https://' + window.location.host + window.location.pathname + '?paymentRef=' + this.paymentEntry._id
            if (window.location.pathname === '/') url = 'https://' + window.location.host + '/user/payments'
            try {
                let initData = {
                    refno: this.paymentEntry._id,
                    redirect: {
                        successUrl: url,
                        cancelUrl: url,
                        errorUrl: url,
                        method: 'GET',
                    },
                }
                let paymentInfos = await api.initPayment(initData)

                let transactionId = paymentInfos.transactionId
                this.payNow(transactionId)
            } catch (e) {
                this.$toast.error('Fehler beim Initialisieren der Zahlung')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
</style>