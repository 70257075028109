<template> </template>

<script>
export default {
	name: 'TrainingCalendar',
	data() {
		return {
			isAdmin: false,
		}
	},
	created() {},
}
</script>

<style scoped lang="scss"></style>
