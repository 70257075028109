<template>
    <v-card color="transparent" elevation="0">
        <!--  <v-select dark label="Wie viele Monate zurück?" :items="[12, 24]" v-model="months"></v-select> -->
        <v-card dark v-if="finishedLoading">
            <v-card-title>Total {{ '⌀' + totalAverage }}</v-card-title>
            <v-card-text>
                <apexchart type="bar" :options="chartOptions" :series="centerData.returnDataTotal"></apexchart>
            </v-card-text>
        </v-card>
        <div v-if="finishedLoading">
            <v-card dark v-for="(data, key) of centerData.returnData" :key="key">
                <v-card-title>{{ data.center }} {{ '⌀' + data.centerAverage }}</v-card-title>
                <v-card-text>
                    <apexchart type="bar" :options="chartOptions" :series="data.series"></apexchart>
                </v-card-text>
            </v-card>
        </div>
        <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
            <v-progress-circular indeterminate color="white"></v-progress-circular>
        </v-col>
    </v-card>
</template>
<script>
import api from '../../../api'

import homeDashboardContractTerminationsDialog from './homeDashboardContractTerminationsDialog.vue'
import homeDashboardContractTerminationDetailsDialog from './homeDashboardContractTerminationDetailsDialog.vue'
import userService from '../../../services/userService'
import { mapState } from 'vuex'
export default {
    components: { homeDashboardContractTerminationsDialog, homeDashboardContractTerminationDetailsDialog },
    props: {
        datePickerRange: {
            type: Array,
            default: () => [],
        },
        studios: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            months: 12,
            chartOptions: {
                xaxis: {
                    categories: [],
                },
                colors: ['#2483af', this.$vuetify.theme.themes.light.primary, this.$vuetify.theme.themes.light.primary],
                grid: {
                    borderColor: '#535A6C',
                },
                chart: {
                    foreColor: '#ccc',
                },
                tooltip: {
                    theme: 'dark',
                },
            },
            centers: {},
            finishedLoading: true,
        }
    },
    async created() {
        await this.fetchContractDevelopment()
    },
    methods: {
        async fetchContractDevelopment() {
            if (this.finishedLoading === true) {
                this.finishedLoading = false
                let data = await api.fetchContractDevelopment(this.months)
                this.centers = data.centers
                this.finishedLoading = true
            }
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        totalAverage() {
            try {
                let totalAverage = 0
                this.centerData.returnData.forEach((e) => {
                    totalAverage += e.centerAverage
                })
                totalAverage = Math.round(parseInt(totalAverage))
                return totalAverage
            } catch (e) {
                console.log('fehler')
                return 0
            }
        },
        centerData() {
            let returnData = []
            let returnDataTotal = []
            let firstSeriesTotal = { name: 'Geplant', data: [] }
            let secondSeriesTotal = { name: 'IST', data: [] }
            for (let center in this.centers) {
                let centerAverage = 0
                let centerAverageCounter = 0
                let monthCount = 0
                let lastHabenVal = 0
                let storeCenter = this.storeStudios.find((stud) => stud.code === center)
                let firstSeries = { name: 'Geplant', data: [] }
                let secondSeries = { name: 'IST', data: [] }
                for (let month in this.centers[center]) {
                    if (!firstSeriesTotal.data.find((fst) => fst.x === month)) {
                        firstSeriesTotal.data.push({ x: month, y: 0 })
                        secondSeriesTotal.data.push({ x: month, y: 0 })
                    }
                    let firstSeriesVal = 0
                    if (storeCenter.growth.find((corr) => corr.date === month) && lastHabenVal > 0) {
                        firstSeriesVal = storeCenter.growth.find((corr) => corr.date === month).count
                        firstSeries.data.push({ x: month, y: Number(firstSeriesVal) + Number(lastHabenVal) })
                        firstSeriesTotal.data.find((dat) => dat.x === month).y +=
                            Number(firstSeriesVal) + Number(lastHabenVal)
                    } else {
                        firstSeriesTotal.data.find((dat) => dat.x === month).y += Number(lastHabenVal)
                        firstSeries.data.push({ x: month, y: 0 })
                    }
                    lastHabenVal = this.centers[center][month]
                    secondSeries.data.push({ x: month, y: lastHabenVal })
                    secondSeriesTotal.data.find((dat) => dat.x === month).y += lastHabenVal

                    let tempAverage = lastHabenVal
                    if (parseInt(tempAverage) > 0) centerAverageCounter++
                    centerAverage += tempAverage
                }
                // this.centerAverageArray.push(centerAverage)
                centerAverage = Math.round(parseInt(centerAverage) / centerAverageCounter)
                returnData.push({ centerAverage, center, series: [firstSeries, secondSeries] })
            }
            returnData.sort((a, b) => a.center.localeCompare(b.center))

            returnDataTotal = [firstSeriesTotal, secondSeriesTotal]
            return { returnData, returnDataTotal }
        },
        totalSeries() {
            const totals = {}
            for (const centerName in this.centers) {
                const centerData = this.centers[centerName]
                for (const month in centerData) {
                    if (!totals[month]) {
                        totals[month] = 0
                    }
                    totals[month] += centerData[month]
                }
            }
            return [
                {
                    name: 'Total',
                    data: Object.values(totals),
                },
            ]
        },
    },
    watch: {
        async months() {
            await this.fetchContractDevelopment()
        },
    },
}
</script>
<style lang="scss" scoped>
.fivecolscustom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
}
</style>
 