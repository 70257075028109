<template>
	<div class="leadList">
		<v-row class="mb-15 pb-10">
			<v-col cols="12" class="d-flex justify-center align-center mt-10 mb-10">
				<v-chip-group v-model="selectedStatus" active-class="primary--text text--accent-4" mandatory>
					<v-chip v-for="status in status" :key="status" :value="status">
						{{ status }}
					</v-chip>
				</v-chip-group>
			</v-col>
			<v-col v-if="loading" cols="12" class="d-flex justify-center align-center" style="min-height: 200px;">
				<v-progress-circular indeterminate color="primary" />
			</v-col>
			<v-col class="mx-auto" cols="12" v-if="!loading && leads.length === 0">
				<v-alert type="info" border="left" outlined>
					Keine Leads gefunden
				</v-alert>
			</v-col>
			<v-col v-for="lead of leads" :key="lead._id"
				><v-card class="mx-auto pb-5" max-width="344">
					<v-row>
						<v-col class="px-10"
							><v-card-subtitle class="text-center pb-0 mb-0 d-flex justify-center align-center"
								><v-icon color="primary" class="mr-2">mdi-calendar-clock</v-icon>
								{{ lead.createdAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-subtitle
							>
							<v-card-subtitle class="text-center mt-0 pt-0 d-flex justify-center align-center"
								><v-icon color="primary" class="mr-2">mdi-pencil</v-icon>
								{{ lead.updatedAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-subtitle
							>
							<v-card-text v-if="lead.name" class="user-selectable text-center my-0 py-0">{{ lead.name }}</v-card-text>
							<v-card-text v-if="lead.surname" class="user-selectable text-center my-0 py-0">{{ lead.surname }}</v-card-text>
							<v-card-text v-if="lead.email" class="user-selectable text-center my-0 py-0">{{ lead.email }}</v-card-text>
							<v-card-text v-if="lead.phone" class="user-selectable text-center my-0 py-0">{{ lead.phone }}</v-card-text>
							<v-card-text v-if="lead.message" class="user-selectable text-center my-0 py-0">{{ lead.message }}</v-card-text>
							<v-card-text v-if="lead.referralMail || lead.roodyUser" class="user-selectable text-center mb-0 pb-0"
								>Eingeladen von:</v-card-text
							>
							<v-card-text v-if="lead.referralMail" class="user-selectable text-center my-0 py-0">{{ lead.referralMail }}</v-card-text>
							<v-card-text v-if="lead.roodyUser && lead.roodyUser.vorname" class="user-selectable text-center my-0 py-0">{{
								lead.roodyUser.vorname
							}}</v-card-text
							><v-card-text v-if="lead.roodyUser && lead.roodyUser.nachname" class="user-selectable text-center my-0 py-0">{{
								lead.roodyUser.nachname
							}}</v-card-text>
							<v-textarea class="pt-0 mt-5" v-model="lead.notes" label="Anmerkungen" outlined></v-textarea>
							<v-card-text v-if="!status.includes(lead.status)">ungültiger Status: {{ lead.status }}</v-card-text>
							<v-select v-model="lead.status" :items="status" label="Status" persistent-hint single-line></v-select
						></v-col>
					</v-row>

					<v-card-actions class="d-flex justify-center align-center mb-10"
						><v-btn @click="updateLead(lead)" color="primary" class="white--text">Speichern</v-btn></v-card-actions
					>
				</v-card></v-col
			>
		</v-row>
	</div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'

export default {
	components: {},
	data() {
		return {
			loading: true,
			error: false,
			leads: [],
			fetchingLeads: false,
			status: [],
			selectedStatus: 'Wurde angeworben',
		}
	},
	created() {
		this.loading = true
		this.init()
	},
	methods: {
		async updateLead(lead) {
			let status = await api.updateLead(lead).catch((e) => this.$toast.error('Es gab einen Fehler'))
			this.init(this.selectedStatus)
			if (status) {
				this.$toast.success('Erfolgreich aktualisiert')
			}
		},

		async init(status = 'Wurde angeworben') {
			this.loading = true
			this.leads = []
			let leads = await api.fetchReferralOverview(status).catch((e) => {
				this.loading = false
				this.error = true
			})

			if (leads) this.leads = leads.leads
			if (leads.status) this.status = leads.status
			this.loading = false
		},
	},
	watch: {
		selectedStatus: async function(newStatus, oldStatus) {
			if (newStatus !== oldStatus) {
				this.init(newStatus)
			}
		},
	},
}
</script>
<style lang="scss" scoped></style>
