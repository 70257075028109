<template>
    <v-card class="fill-height" style="background-color: #303030">
        <apexchart class="ma-auto unset-font" :options="weightChartOptions" :series="weightChartItems"></apexchart>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userSearch from '../../../components/userSearch.vue'

export default {
    props: {
        user: {
            required: true,
        },
    },
    mounted() {},
    methods: {
        getMarkerColors() {
            return this.user.weights.map((weight, index) => {
                if (weight.nutritionDashboard) {
                    return 'black'
                } else {
                    return this.$vuetify.theme.themes.light.primary
                }
            })
        },
    },
    computed: {
        weightChartItems() {
            let data = []
            if (this.user.gewicht) data.push(this.user.gewicht)
            if (this.user.weights && this.user.weights.length) {
                this.user.weights.forEach((weight) => {
                    data.push(weight.value)
                })
            }
            if (this.user.targetWeight) data.push(this.user.targetWeight)
            return [
                {
                    name: 'Gewicht',
                    data,
                },
            ]
        },
        weightChartCategories() {
            let categories = []
            if (this.user.gewicht) categories.push('Start')
            if (this.user.weights && this.user.weights.length) {
                this.user.weights.forEach((weight) => {
                    categories.push(this.$moment.unix(weight.date).format('DD.MM.YY'))
                })
            }
            if (this.user.targetWeight) categories.push('Ziel')
            return categories
        },
        weightChartOptions() {
            return {
                chart: {
                    sparkline: {
                        enabled: true,
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            download: false,
                            selection: true,
                            pan: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            reset: true,
                        },
                    },
                    type: 'area',
                },
                tooltip: {
                    theme: 'dark',
                },
                markers: {
                    size: 5, // Größere Marker für bessere Sichtbarkeit
                    strokeColor: this.$vuetify.theme.themes.light.primary, // Umrandung der Marker im Primärfarbton
                    strokeWidth: 2,
                    colors: this.getMarkerColors(),
                },
                forecastDataPoints: {
                    count: 1,
                },
                title: {
                    text: this.user.weights?.length
                        ? 'Gewicht ' + this.user.weights[this.user.weights.length - 1].value + ' KG'
                        : 'Gewicht',
                    offsetX: 10,
                    offsetY: 10,
                    style: {
                        fontSize: '20px',
                        fontFamily: 'roodyFont',
                        color: '#FFFFFF',
                        fontWeight: '400',
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                subtitle: {
                    text: this.user.weights?.length
                        ? this.user.weights[this.user.weights.length - 1].value - this.user.weights[0].value >= 0
                            ? '+ ' +
                              Math.round(
                                  Number(
                                      this.user.weights[this.user.weights.length - 1].value - this.user.weights[0].value
                                  ) * 100
                              ) /
                                  100 +
                              ' KG seit ' +
                              this.$moment(this.user.weights[0].date * 1000).format('DD.MM.YYYY')
                            : Math.round(
                                  (this.user.weights[this.user.weights.length - 1].value - this.user.weights[0].value) *
                                      100
                              ) /
                                  100 +
                              ' KG'
                        : '',
                    offsetX: 10,
                    offsetY: 30,
                    style: {
                        fontSize: '16px',

                        color:
                            this.user.weights?.length &&
                            this.user.weights[this.user.weights.length - 1].value - this.user.weights[0].value >= 0
                                ? 'white'
                                : 'white',
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF',
                        },
                    },
                },
                xaxis: {
                    categories: this.weightChartCategories,
                    labels: {
                        style: {
                            colors: '#FFFFFF',
                        },
                    },
                },
                annotations: {
                    xaxis: [
                        {
                            x: this.user.weights?.length
                                ? this.$moment
                                      .unix(this.user.weights.find((weight) => weight.nutritionDashboard).date)
                                      .format('DD.MM.YY')
                                : '',
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    color: '#fff',
                                    background: '#00E396',
                                },
                                text: 'Wichtige Markierung',
                            },
                        },
                    ],
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 3, // Dicke der Linie
                    colors: [this.$vuetify.theme.themes.light.primary], // Farbe der Linie
                },
                fill: {
                    colors: [
                        this.$vuetify.theme.themes.light.primary,
                        this.$vuetify.theme.themes.light.darkenprimary1,
                        this.$vuetify.theme.themes.light.darkenprimary2,
                    ],
                    opacity: 0.3, // Transparenz der Füllung
                },
                colors: [
                    this.$vuetify.theme.themes.light.primary,
                    this.$vuetify.theme.themes.light.darkenprimary1,
                    this.$vuetify.theme.themes.light.darkenprimary2,
                ],
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.apexcharts-yaxis-title {
    font-weight: normal !important;
}
</style>