<template>
    <v-row class="mt-5 mx-auto" style="max-width: 100%">
        <v-col cols="12">
            <div
                v-if="!loading"
                style="overflow-x: auto; display: flex; flex-wrap: wrap; height: 500px; flex-direction: column"
            >
                <v-card
                    flat
                    dark
                    v-for="(customer, index) in customers"
                    :key="customer._id"
                    class="mx-2 text-center"
                    style="width: 110px; margin-right: 10px; margin-bottom: 10px"
                >
                    <v-menu top min-width="200px" rounded offset-y>
                        <template v-slot:activator="{ on }">
                            <v-badge
                                :color="
                                    customer.attendance[customer.attendance.length - 1].type === 'checkin'
                                        ? 'green'
                                        : 'red'
                                "
                                overlap
                                bordered
                                offset-y="20"
                                offset-x="30"
                            >
                                <v-avatar :key="imgKey" v-on="on" v-if="customer.picture" size="80" color="primary">
                                    <img style="object-fit: cover" v-if="customer.picture" :src="customer.picture" />
                                </v-avatar>
                                <v-avatar v-on="on" size="80" color="primary" v-else>
                                    <span class="white--text text-h5">{{ customer.initials }}</span>
                                </v-avatar>
                            </v-badge>
                        </template>
                        <v-card class="d-flex justify-center">
                            <v-list-item-content class="justify-center">
                                <div class="mx-auto text-center" v-if="!customer.showMore">
                                    <v-avatar v-if="customer.picture" size="120" color="primary">
                                        <img style="object-fit: cover" :src="customer.picture" />
                                    </v-avatar>
                                    <v-avatar size="120" color="primary" v-else>
                                        <span class="white--text text-h5">{{ customer.initials }}</span>
                                    </v-avatar>
                                    <h5 class="mt-2">{{ customer.firstName }} {{ customer.lastName }}</h5>

                                    <v-divider class="my-3"></v-divider>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Typ</th>
                                                <th class="text-left">Uhrzeit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(attendance, index) in customer.attendance"
                                                :key="index + customer._id"
                                            >
                                                <td>{{ attendance.type }}</td>
                                                <td>{{ attendance.time }}</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <v-btn @click.stop="fetchMoreData(index)" depressed rounded text>
                                        Mehr anzeigen
                                    </v-btn>
                                    <v-btn
                                        @click="$router.push('/dashboard/user/search/' + customer._id)"
                                        depressed
                                        rounded
                                        text
                                    >
                                        zum Profil
                                    </v-btn>
                                </div>
                                <div class="mx-auto text-center" v-else>
                                    <v-card-subtitle v-if="customer.workouts.length == 0">
                                        Keine Trainingspläne hinterlegt
                                    </v-card-subtitle>
                                    <v-simple-table v-if="customer.workouts.length > 0">
                                        <thead>
                                            <tr>
                                                <th class="text-left">Plan</th>
                                                <th class="text-left">Datum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="workout of customer.workouts" :key="workout._id">
                                                <td>{{ workout.title }}</td>
                                                <td>
                                                    {{ $moment(parseInt(workout.ts) * 1000).format('DD.MM.YY HH:mm') }}
                                                    Uhr
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>

                                    <v-card-subtitle v-if="customer.courses.length === 0">
                                        Keine Kurse besucht
                                    </v-card-subtitle>

                                    <v-simple-table v-if="customer.courses.length > 0">
                                        <thead>
                                            <tr>
                                                <th class="text-left">Kurs</th>
                                                <th class="text-left">Uhrzeit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="course of customer.courses" :key="course._id">
                                                <td>{{ course.course.name }}</td>
                                                <td>{{ course.time }}</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <v-btn @click.stop="customer.showMore = false" depressed rounded text>
                                        weniger anzeigen
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-menu>

                    <v-card-text class="h6 text-center">
                        <span class="shortenText">{{ customer.firstName }}</span>
                    </v-card-text>
                </v-card>
            </div>
            <div v-else>
                <v-col cols="12" class="px-5">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-col>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import api from '@/api'

export default {
    name: 'attendanceCustomerList',
    data() {
        return {
            loading: false,
            customers: [],
            imgKey: 0,
        }
    },
    props: {
        selectedStudioSingle: {
            required: false,
            default: 0,
        },
    },

    watch: {
        selectedStudioSingle() {
            this.fetchAttendanceList()
        },
    },
    created() {
        if (this.selectedStudioSingle) {
            this.fetchAttendanceList()
        }
    },
    methods: {
        handleMessage(data) {
            if (data.topic === 'attendance' && (data.type === 'checkin' || data.type === 'checkout')) {
                let existingCustomer = this.customers.findIndex((e) => e._id === data._id)
                if (existingCustomer !== -1) {
                    this.customers.splice(existingCustomer, 1)
                    this.customers.unshift(data)
                } else {
                    this.customers.unshift(data)
                }
                this.imgKey++
            }
        },
        async showLess(index) {
            this.customers[index].showMore = false
        },
        async fetchMoreData(index) {
            let user = await api.fetchUserAttendanceStats(this.customers[index]._id)
            this.customers[index].courses = user.courses
            this.customers[index].workouts = user.workouts
            this.customers[index].showMore = true
        },
        async fetchAttendanceList() {
            this.loading = true
            try {
                let data = await api.fetchAttendanceCustomers(this.selectedStudioSingle)
                data = data.filter((e) => e.firstName !== 'Gelöschter')
                data.forEach((e) => {
                    e.showMore = false
                    e.courses = []
                    e.workouts = []
                })
                this.customers = data.sort((a, b) => {
                    const aLatestAttendanceTime = this.$moment(
                        a.attendance[a.attendance.length - 1].time,
                        'HH:mm:ss'
                    ).toDate()
                    const bLatestAttendanceTime = this.$moment(
                        b.attendance[b.attendance.length - 1].time,
                        'HH:mm:ss'
                    ).toDate()
                    return bLatestAttendanceTime - aLatestAttendanceTime
                })
            } catch (error) {
                error
            } finally {
                this.loading = false
            }
        },
    },
}
</script>


<style lang="scss" scoped>
.shortenText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>