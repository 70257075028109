<template>
    <div>
        <v-col cols="12">
            <v-img height="65px" @click="$router.push('/')" contain src="/img/roodyLogoGreen.png"></v-img>
        </v-col>
        <v-row class="justify-space-around" style="height: 80vh">
            <v-card-title class="font-weight-bold" style="word-break: break-word">
                Bitte gedulde dich einen Moment. Deine Zahlung wird geprüft, Du wirst automatisch weitergeleitet.
            </v-card-title>
            <v-col cols="12" class="text-center">
                <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
            </v-col>
        </v-row>
    </div>
</template>
  
  <script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    async created() {
        this.checkPayment()
    },
    methods: {
        async checkPayment() {
            setTimeout(async () => {
                let transactionId = this.$route.query.datatransTrxId
                this.$toast.info('Deine Zahlung wird geprüft bitte nicht neu laden!')
                let payment = await api.checkPaymentStatus(transactionId)
                if (payment.paymentStatus === 'settled' || payment.paymentStatus === 'transmitted') {
                    this.$toast.success('Die Zahlung wurde übermittelt.')
                    this.$router.push('/shop/purchase/infos/' + transactionId)
                } else {
                    this.$toast.error('Deine Zahlung ist fehlgeschlagen. Bitte versuche es erneut')
                    this.$router.push('/shop/checkout')
                }
            }, 300)
        },
    },
}
</script>
  