<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                <span class="headline">Chat</span>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item v-for="(msg, index) in messages" :key="index">
                        <v-list-item-content>
                            <v-list-item-title>{{ msg }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-text-field v-model="newMessage" label="Nachricht" @keyup.enter="sendMessage"></v-text-field>
                <v-btn @click="sendMessage">Senden</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            newMessage: '',
            messages: [],
            socket: null,
        }
    },
    created() {
        // Verbindung zum WebSocket-Endpunkt herstellen
        const socket = new WebSocket('ws://localhost:4000/api/chat/1') // Anpassen mit deiner tatsächlichen URL
        this.socket = socket

        // WebSocket Event: Nachricht empfangen
        socket.onmessage = (event) => {
            this.messages.push(event.data) // Nachricht zur Nachrichtenliste hinzufügen
        }

        // WebSocket Event: Verbindung hergestellt
        socket.onopen = () => {
            console.log('WebSocket-Verbindung hergestellt.')
        }

        // WebSocket Event: Verbindungsfehler
        socket.onerror = (error) => {
            console.error('WebSocket-Verbindungsfehler:', error)
        }
    },
    methods: {
        sendMessage() {
            if (this.newMessage.trim() !== '') {
                this.socket.send(this.newMessage)
                // this.messages.push(this.newMessage) // Nachricht zur Nachrichtenliste hinzufügen
                this.newMessage = '' // Eingabefeld leeren
            }
        },
    },
}
</script>
