<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn v-show="show_ok_btn" color="green darken-1" text @click="$emit('A_Ok')">
            Ok
          </v-btn>

          <v-btn v-show="!show_ok_btn" color="green darken-1" text @click="$emit('A_Disagree')">
            Ablehnen
          </v-btn>

          <v-btn v-show="!show_ok_btn" color="green darken-1" text @click="$emit('A_Agree')">
            Akzeptieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    title: '',
    text: '',
    dialog: false,
    show_ok_btn: false,
  },
}
</script>
