<template>
    <div class="courseEdit">
        <v-row no-gutters>
            <v-col cols="9">
                <v-card-subtitle>
                    Einstellungen der Kursformate, Räume und Labels, sowie die Kundenbewertungen der Kurse findest Du in
                    den Einstellungen unter
                    <strong>"Group Fitness"</strong>
                </v-card-subtitle>
            </v-col>
            <v-col class="d-flex flex-row justify-center" cols="3">
                <v-btn @click="$router.push('/einstellungen/kurse')" x-large class="my-auto" color="primary">
                    <lottie-player
                        mode="normal"
                        style="width: 52px; height: 66px"
                        class="courseList__view-course-lottie"
                        src="/img/lottiefiles/BottomNavigation/settings.json"
                    ></lottie-player>
                </v-btn>
            </v-col>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn @click="downloadInstructorList" class="mx-auto" color="primary">
                    <v-icon class="pr-2">mdi-download-circle</v-icon>
                    Instruktorenliste
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="add-btn">
            <v-btn
                x-large
                class="mx-auto my-5 elevation-6"
                @click="$router.push({ name: 'courseCreateTimeline' })"
                color="primary"
            >
                <v-icon class="text-white">mdi-calendar-edit</v-icon>
                <span class="pr-1">Erstellen</span>
            </v-btn>
        </v-row>
        <v-row>
            <v-col align="center" cols="12">
                <v-btn @click="showParticipantDialog = true" text>Indoor Cycling Teilnehmer anpassen</v-btn>
            </v-col>
        </v-row>
        <v-card-title>Aktive Kursplanungen</v-card-title>
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
            <v-tab v-for="item in items" :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-row class="px-5 pt-5">
                    <v-select
                        @change="fetchCourseList"
                        v-model="selectedDay"
                        :items="days"
                        item-text="text"
                        item-value="value"
                        label="Label"
                        single-line
                    ></v-select>
                </v-row>
                <v-row class="courseEdit__list flex-column px-5 mb-15 pb-15">
                    <v-col class="courseEdit__view pa-0" v-for="(course, y) in courseEditList" :key="course._id">
                        <v-row v-show="showDivider(y)" class="courseEdit__view-date mt-3">
                            <v-col cols="3" class="courseEdit__view-date-hours text-center d-flex pr-0">
                                <span class="ma-auto">ab {{ course.hours }}:00 Uhr</span>
                            </v-col>
                            <v-col cols="9" class="courseEdit__view-date-seperator pr-0"><v-divider></v-divider></v-col>
                        </v-row>
                        <v-card
                            @click="pushToCourse(course)"
                            class="elevation-7 rounded-lg"
                            :class="{
                                'courseEdit__view-course': 1,
                                'py-7': 1,
                                'my-3': 1,
                                'mx-2': 1,
                                'courseEdit__view-course-signedup': course.signedUp,
                            }"
                        >
                            <v-row>
                                <v-col cols="4" class="courseEdit__view-course-icon d-flex">
                                    <span class="ma-auto">
                                        <lottie-player
                                            mode="normal"
                                            v-if="!course.icon"
                                            style="width: 52px; height: 52px"
                                            class="courseList__view-course-lottie m-auto"
                                            src="/img/lottiefiles/gf/dumbell.json"
                                        ></lottie-player>

                                        <lottie-player
                                            mode="normal"
                                            v-else
                                            :key="course.icon"
                                            style="width: 52px; height: 52px"
                                            class="courseList__view-course-lottie m-auto"
                                            :src="'/api/gf/icons/' + course.icon"
                                        ></lottie-player>
                                    </span>
                                </v-col>
                                <v-col v-if="course.course" cols="8" class="courseEdit__view-course-info">
                                    <v-row v-if="course.label" class="courseEdit__view-course-info-subtitle">
                                        <span
                                            class="white--text px-2 rounded"
                                            :style="'background-color:' + course.label.color"
                                        >
                                            {{ course.label.text }}
                                        </span>
                                    </v-row>
                                    <v-row
                                        v-if="course && course.course && course.course.name"
                                        class="courseEdit__view-course-info-title"
                                    >
                                        {{ course.course.name }}
                                    </v-row>

                                    <v-row class="courseEdit__view-course-info-time">
                                        {{ courseStartDate(course) }} - {{ courseEndDate(course) }} Uhr ·
                                        {{ course.duration }} Min
                                    </v-row>
                                    <v-row
                                        v-if="course && course.room && course.room.name"
                                        class="courseEdit__view-course-info-room"
                                    >
                                        {{ course.room.name }}
                                    </v-row>
                                    <v-row class="courseEdit__view-course-info-instructor">
                                        <span v-for="(instructor, iy) of course.instructors" :key="iy">
                                            {{ instructor.vorname }} {{ instructor.nachname }}
                                            <span
                                                class="pr-1"
                                                v-show="
                                                    iy !== course.instructors.length - 1 &&
                                                    course.instructors.length !== 1
                                                "
                                            >
                                                ,
                                            </span>
                                        </span>
                                    </v-row>
                                    <v-row class="courseEdit__view-course-info-instructor">
                                        <span>Plätze: {{ course.amount }}</span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row class="courseEdit__list flex-column px-5 mt-3 mb-15 pb-15">
                    <v-col class="courseEdit__view pa-0" v-for="course of plannedCourseEditList" :key="course._id">
                        <v-card
                            @click="pushToCourse(course)"
                            class="rounded-lg elevation-7"
                            :class="{
                                'courseEdit__view-course': 1,
                                'py-2': 1,
                                'my-5': 1,
                                'mx-2': 1,
                                'courseEdit__view-course-signedup': course.signedUp,
                            }"
                        >
                            <v-row class="py-5">
                                <v-col cols="4" class="courseEdit__view-course-icon d-flex">
                                    <span class="ma-auto">
                                        <lottie-player
                                            mode="normal"
                                            v-if="!course.icon"
                                            style="width: 52px; height: 52px"
                                            class="courseList__view-course-lottie m-auto"
                                            src="/img/lottiefiles/gf/dumbell.json"
                                        ></lottie-player>

                                        <lottie-player
                                            mode="normal"
                                            v-else
                                            :key="course.icon"
                                            style="width: 52px; height: 52px"
                                            class="courseList__view-course-lottie m-auto"
                                            :src="'/api/gf/icons/' + course.icon"
                                        ></lottie-player>
                                    </span>
                                </v-col>
                                <v-col v-if="course.course" cols="8" class="courseEdit__view-course-info">
                                    <v-row class="courseEdit__view-course-info-subtitle">
                                        <div v-if="course.visibleAfter && course.hideAfter" class="text-black">
                                            <span v-if="getDate(course.visibleAfter) !== getDate(course.hideAfter)">
                                                {{ getDate(course.visibleAfter) }} bis {{ getDate(course.hideAfter) }}
                                            </span>
                                            <span v-else>{{ getDate(course.hideAfter) }}</span>
                                        </div>
                                    </v-row>
                                    <v-row
                                        v-if="course && course.course && course.course.name"
                                        class="courseEdit__view-course-info-title"
                                    >
                                        {{ course.course.name }}
                                    </v-row>

                                    <v-row class="courseEdit__view-course-info-time">
                                        {{ courseStartDate(course) }} - {{ courseEndDate(course) }} Uhr ·
                                        {{ course.duration }} Min
                                    </v-row>
                                    <v-row
                                        v-if="course && course.room && course.room.name"
                                        class="courseEdit__view-course-info-room"
                                    >
                                        {{ course.room.name }}
                                    </v-row>
                                    <v-row class="courseEdit__view-course-info-instructor">
                                        <span v-for="(instructor, iy) of course.instructors" :key="iy">
                                            {{ instructor.vorname }} {{ instructor.nachname }}
                                            <span
                                                class="pr-1"
                                                v-show="
                                                    iy !== course.instructors.length - 1 &&
                                                    course.instructors.length !== 1
                                                "
                                            >
                                                ,
                                            </span>
                                        </span>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item></v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="showParticipantDialog" max-width="500px" persistent>
            <v-card flat>
                <v-card-title class="primary white--text">Indoor Cycling Teilnehmer anpassen</v-card-title>
                <v-row no-gutters>
                    <v-col class="px-5 mt-2" cols="12">
                        <v-card-text class="px-0">
                            Passt alle Indoor Cycling Teilnehmerzahlen ab morgen an (Kursplanung und im Kursplan)
                        </v-card-text>
                        <v-text-field v-model="participantAmount" type="number" label="Anzahl Plätze"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn
                        text
                        @click="
                            () => {
                                participantAmount = null
                                showParticipantDialog = false
                            }
                        "
                    >
                        Abbrechen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="updateParticipantAmount" color="primary">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <router-view @resetTab="changeTab" @updateList="fetchCourseList"></router-view>
    </div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapGetters } from 'vuex'
import userSearch from '../../components/userSearch.vue'
import courseSearch from '../../components/courseSearch.vue'
export default {
    name: 'courseEdit',
    components: { userSearch, courseSearch },
    data() {
        return {
            tab: null,
            items: ['Wiederholend', 'Zeitlich begrenzt'],
            dialog: true,
            selectedDay: 1,
            days: [
                { value: 1, text: 'Montag' },
                { value: 2, text: 'Dienstag' },
                { value: 3, text: 'Mittwoch' },
                { value: 4, text: 'Donnerstag' },
                { value: 5, text: 'Freitag' },
                { value: 6, text: 'Samstag' },
                { value: 7, text: 'Sonntag' },
            ],
            plannedCourseEditList: [],
            courseEditList: [],
            showParticipantDialog: false,
            participantAmount: null,
        }
    },
    async created() {
        this.fetchCourseList()
    },
    methods: {
        async updateParticipantAmount() {
            if (!this.participantAmount) {
                this.$toast.error('Bitte trage einge gültige Anzahl Plätze ein')
                return
            }
            try {
                let status = await api.updateGFParticipantAmount({ amount: this.participantAmount })

                if (status?.status?.nModified)
                    this.$toast.success('Es wurden ' + status.status.nModified + ' Kursplanungen angepasst')
                this.fetchCourseList()
                this.participantAmount = null
                this.showParticipantDialog = false
            } catch (e) {
                this.$toast.error('Es gab einen Fehler beim Speichern')
            }
        },
        async downloadInstructorList() {
            let status = await api.getGFInstructorListExcel()

            if (window.flutter_inappwebview) {
                console.log('FLUTTER IOS DOWNLOAD PDF START')
                var reader = new FileReader()
                reader.readAsDataURL(status)
                let ref = this
                reader.onloadend = function () {
                    var dataUrl = reader.result
                    var base64 = dataUrl.split(',')[1]
                    console.log(base64)
                    window.flutter_inappwebview.callHandler(
                        'blobToBase64Handler',
                        base64.toString(),
                        'xlsx',
                        'Instruktorenliste_' + ref.$moment().format('YYYY-MM-DD')
                    )
                }
            } else {
                saveAs(status, 'Instruktorenliste_' + this.$moment().format('YYYY-MM-DD'))
            }
        },
        changeTab(index) {
            this.tab = index
        },
        getDate(ts) {
            return this.$moment(ts * 1000).format('DD.MM.YY')
        },

        pushToCourse(course) {
            this.$router.push({ name: 'courseEditTimeline', params: { id: course._id } })
        },
        courseEndDate(course) {
            let endTime = this.$moment()
            endTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            endTime.add('minutes', course.duration)
            return endTime.format('LT')
        },
        courseStartDate(course) {
            let startTime = this.$moment()

            startTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            return startTime.format('LT')
        },
        async fetchCourseList() {
            let courseList = await api.fetchCourseListEdit(this.selectedDay)
            this.courseEditList = courseList.repeating
            this.plannedCourseEditList = courseList.planned
        },
        showDivider(y) {
            if (y - 1 >= 0) {
                if (this.courseEditList[y].hours !== this.courseEditList[y - 1].hours) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
    },
    watch: {},
    computed: {},
}
</script>
<style lang="scss" scoped>
.add-btn {
    position: fixed;
    bottom: 13vh;
    z-index: 1;
    left: 15px;
    width: calc(100% - 15px);
}
::v-deep .v-slide-group__prev {
    display: none !important;
}
::v-deep .dialog {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.wrapper-outer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
}
.wrapper-inner {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.headerImage {
    max-width: 500px;
    min-height: 35vh;
    background-color: white;

    width: 100%;
}
.speech-bubble {
    position: relative;
    background-color: var(--v-primary) !important;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 26px solid #0000;
    border-top-color: var(--v-primary);
    border-bottom: 0;
    border-left: 4.5px;
    margin-left: -25.5px;
    margin-bottom: -20px;
}
.hide-lower-1 {
    position: fixed;
    width: 100vw;
    height: 30px;
    bottom: 130px;
    right: 0px;
    background-image: linear-gradient(#ffffff4d, #fffffff5);
}
.hide-lower-2 {
    position: fixed;
    width: 100vw;
    height: 130px;
    bottom: 0px;
    right: 0px;
    background-image: linear-gradient(#fffffff5, #ffffff);
}
.signupsCount {
    z-index: 1;
    position: fixed;
    bottom: 105px;
    width: 100vw;
    display: flex;
    justify-content: center;
    right: 0px;
}

.courseEdit {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 150px;
    &__list {
        display: block;
    }
    &__view {
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
        &-date {
            &-hours {
                font-size: 13px;
            }
        }
        &-course {
            background-color: #e9f4f5;
            &-signedup {
                background-color: #c5e6a980 !important;
            }
            &-icon {
                span {
                    height: 65px;
                    width: 65px;
                    background-color: white;
                    position: relative;
                    border-radius: 100%;
                }
            }
            &-info {
                &-subtitle {
                    span {
                        letter-spacing: 1px;
                        padding: 0px 0px;
                        font-size: 13px;
                        font-weight: bold;
                        display: flex;
                    }
                }

                &-title {
                    font-weight: bold;
                    font-size: 18px;
                }
                &-time,
                &-room,
                &-instructor {
                    //font-weight: bold;
                    font-size: 14px;
                }
                &-room {
                }
                &-instructor {
                }
            }
        }
    }
}
</style>
