lv<template>
	<div class="settings">
		<v-list>
			<div v-for="(item, index) in items" :key="'setting' + index">
				<v-list-item @click="startAnimation(item)" v-if="userService.hasRightArray(item.rights)">
					<v-list-item-avatar>
						<lottie-player
							mode="normal"
							class="settings__item-lottie"
							:name="item.text"
							:autoplay="false"
							:src="item.icon"
						></lottie-player>
					</v-list-item-avatar>
					<v-list-item-content><v-list-item-title v-text="item.text"></v-list-item-title></v-list-item-content>
					<v-list-item-icon><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
				</v-list-item>
			</div>

			<v-list-item v-if="user.email === 'sebs@wellcomefit.ch' || user.email === 'dominic.ammann.ch@gmail.com'" @click="$router.push('/habits')">
				<v-list-item-content>
					<v-list-item-title class="red--text" v-text="'Habitory'"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item @click="logout">
				<v-list-item-content>
					<v-list-item-title class="red--text" v-text="'Abmelden'"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<!---
    <div v-if="userService.hasRight('kt_viewStudio', 'kt_viewAll', 'g_statistics', 'kt_trainerVergleich') && wcf()" class="action-link">
      <router-link to="/bewertungen" class="nav-link">
        Feedbacks
        <span class="material-icons">navigate_next</span>
      </router-link>
    </div>
    -->
		<!-- <div v-if="userService.hasRight('gf_s_view') && wcf()" class="action-link">
      <router-link to="/statistik/kurse" class="nav-link">
        Kurststatistiken
        <span class="material-icons">navigate_next</span>
      </router-link>
    </div>
    <div v-if="userService.hasRight('gf_c_signupRange')" class="action-link">
      <router-link to="/kurseinstellungen" class="nav-link">
        Kurseinstellungen
        <span class="material-icons">navigate_next</span>
      </router-link>
    </div>
  
    <div v-if="userService.hasRight('gf_r_viewAll', 'gf_cp_instructor')" class="action-link">
      <router-link to="/kursbewertungen" class="nav-link">
        Kursbewertungen
        <span class="material-icons">navigate_next</span>
      </router-link>
    </div>
     -->

		<!---  <div v-if="userService.hasRight('g_greenScreen') && wcf()" class="action-link">
      <router-link to="/foto" class="nav-link">
        Foto
        <span class="material-icons">navigate_next</span>
      </router-link>
    </div>-->

		<!--
		<div v-if="user.gs_image" class="action-link">
			<router-link to="/ebt" class="nav-link">
				Mein Erstes Bestes Training
				<span class="material-icons">navigate_next</span>
			</router-link>
		</div>
-->
	</div>
</template>

<script>
import api from '../api'
import { saveAs } from 'file-saver'
import zipcelx from 'zipcelx'
import userService from '../services/userService'

export default {
	data() {
		return {
			items: [
				{
					text: 'Intern',
					color: '#b557a1',
					to: '/intern',
					icon: '/img/lottiefiles/settings/campaign.json',
					rights: ['af_downloadList', 'z_downloadList', 'm_downloadList', 'kt_viewAll', 'u_downloadUserList'],
				},
				{
					text: 'Anamnesebögen',
					color: '#c9da53',
					to: '/anamneseboegen',
					icon: '/img/lottiefiles/settings/anamnese.json',
					rights: ['ab_view', 'ab_edit', 'ab_delete'],
				},
				{
					text: 'Benutzerverwaltung',
					color: '#8fc23f',
					to: '/rollenverwaltung',
					icon: '/img/lottiefiles/settings/userManagement.json',
					rights: ['u_search', 'u_edit', 'u_delete', 'g_manageRights'],
				},

				/*				{
					text: 'Covid-Zertifikate',
					color: '#1f8b95',
					to: '/covidcerts',
					icon: '/img/lottiefiles/settings/covid.json',
					rights: ['covid_archive'],
				}, */
				{
					text: 'Geräte- und Übungsverwaltung',
					color: '#30b0ac',
					to: '/devices',
					icon: '/img/lottiefiles/settings/deviceManagementColor.json',
					rights: ['ex_create', 'ex_edit', 'ex_delete'],
				},
				/*{
					text: 'Weiterempfehlungen',
					color: '#1c5c87',
					to: '/weiterempfehlungen',
					icon: '/img/lottiefiles/settings/referal.json',
					rights: ['af_view', 'af_edit', 'af_downloadList'],
				},*/
				{
					text: 'Weiterempfehlungen',
					color: '#1c5c87',
					to: '/einstellungen/leads',
					icon: '/img/lottiefiles/settings/referal.json',
					rights: ['af_view', 'af_edit', 'af_settingsEdit', 'af_downloadList'],
				},
				{
					text: 'Videoarchiv bearbeiten',
					color: '#2276bc',
					to: '/videoarchiv',
					icon: '/img/lottiefiles/settings/archive.json',
					rights: ['a_add', 'a_edit', 'a_delete'],
				},
				/*{
					text: 'News',
					color: '#5e79bd',
					to: '/news',
					icon: '/img/lottiefiles/settings/news.json',
					rights: ['n_add', 'n_delete', 'n_edit'],
				}, */
				/*{
					text: 'QR-Code Erstellung',
					color: '#50b86b',
					to: '/qrcode',
					icon: '/img/lottiefiles/settings/displayManagement.json',
					rights: ['n_add'],
				},*/
				/*{
					text: 'Popups',
					color: '#5e79bd',
					to: '/popups',
					icon: '/img/lottiefiles/settings/popups.json',
					rights: ['n_add', 'n_delete', 'n_edit'],
				}, */
				{
					text: 'Membercard Admin',
					color: '#432255',
					to: '/membercard/admin',
					icon: '/img/lottiefiles/settings/membercard.json',
					rights: ['m_signupUser', 'm_viewSubs', 'm_downloadList', 'm_createSubs'],
				},

				/*	{
					text: 'Anzeigen Manager',
					color: '#432255',
					to: '/manage/slides',
					icon: '/img/lottiefiles/settings/membercard.json',
					rights: ['n_add', 'n_delete', 'n_edit'],
				},
*/
				/*	{
					text: 'Mitarbeiter Manager',
					color: '#432255',
					to: '/manage/employee',
					icon: '/img/lottiefiles/settings/276-female-and-male-lineal-edited.json',
					rights: ['wtv_manage'],
				},

				{
					text: 'Kinderhort',
					color: '#432255',
					to: '/einstellungen/kinderhort',
					icon: '/img/lottiefiles/settings/1546-teddy-bear-toy-flat-edited.json',
					rights: ['g_intern'],
				},
*/
				{
					text: 'Group Fitness',
					color: '#612d82',
					to: '/einstellungen/kurse',
					icon: '/img/lottiefiles/settings/groupFitness.json',
					rights: [
						'gf_s_view',
						'gf_c_signupRange',
						'gf_r_viewAll',
						'gf_c_edit',
						'gf_c_delete',
						'gf_c_plan',
						'gf_c_create',
						'gf_cp_edit',
						'gf_f_courses',
					],
				},
				/*{
					text: 'Kampagnen',
					color: '#b557a1',
					to: '/kampagne',
					icon: '/img/lottiefiles/settings/campaign.json',
					rights: ['c_create', 'c_delete', 'c_edit'],
				},*/
				{
					text: 'slim ME',
					color: '#b557a1',
					to: '/slimme/dashboard',
					icon: '/img/lottiefiles/settings/campaign.json',
					rights: ['u_search'],
				},
			],
			animations: [],
			userService,
			name: '',
			user: {},
			vorname: '',
			mail: '',
			profilbild: null,
			isAdmin: false,
			rollen: {},
			x: null,
		}
	},

	created() {
		api.getUser().then((data) => {
			this.mail = data.user.email
			this.vorname = data.user.vorname
			this.name = data.user.nachname
			this.isAdmin = data.user.isAdmin
			this.profilbild = data.user.bild
			this.user = data.user
		})
	},

	methods: {
		addAnimation(animation) {
			this.animations.push(animation)
		},
		startAnimation(item) {
			/*	this.animations.forEach((animation) => {
				animation.name === item.text ? animation.play() : animation.stop()
			})*/
			setTimeout(this.pushToRoute, 1200, item.to)
		},
		pushToRoute(route) {
			this.$router.push(route)
		},
		wcf() {
			if (
				location.host.includes('dev') ||
				location.host.includes('192') ||
				location.host.includes('local') ||
				location.host.includes('wellcomefit') ||
				location.host.includes('wintifit')
			) {
				return true
			} else {
				return false
			}
		},

		logout() {
			// token
			// user info
			localStorage.removeItem('token')
			localStorage.removeItem('user')

			this.isAuthenticated = false
			this.isAdmin = false
			location.reload()
		},
		checkStudio() {
			window.app.$root.$children[0].checkStudio(true)
		},
	},
}
</script>

<style lang="scss" scoped>
.settings {
	&__item {
		&-lottie {
			height: 45px !important;
			width: 45px !important;
		}
	}
}

.changelog {
	display: block;
	margin: 20px 0px;
	text-align: center;
	color: #fff;
	background-color: var(--v-primary);
	padding: 10px 0px;
	text-decoration: none;
}

.adventsverlosung {
	background-color: var(--v-primary);
	width: 100%;
	margin: 20px auto;
	padding: 10px;
	text-align: center;
	max-width: 300px;
	color: white;
	border-radius: 22px;
}

.roody-infos {
	padding: 20px;
	text-align: center;

	.download-userlist {
		background-color: var(--v-primary);
		width: 100%;
		margin: 20px auto;
		padding: 10px;
		text-align: center;
		max-width: 400px;
		color: white;
		border-radius: 22px;
	}
}

.mcard {
	text-align: center;
	display: flex;
	justify-content: center;
	background-color: var(--v-primary);
	color: #fff;
	padding: 10px 0px;
}

.profile {
	border-bottom: 1px solid grey;

	.profile-wrapper {
		display: flex;
		position: relative;
		overflow: auto;

		.names {
			display: flex;
			flex-direction: column;
			padding-left: 10px;
			color: #4a4a4a;
			font-size: 16px;
		}

		.profile-pic {
			display: flex;
			height: 50px;
			width: 50px;
			object-fit: cover;
			background-color: grey;
			border-radius: 50%;
			border: 3px solid #4a4a4a;
		}
	}
}

.profile-wrapper::after {
	content: '';
	/*
  position: absolute;
  right: 18px;
  top: 30px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #4a4a4a;
  */
}

.action-link.profile {
	border-top: 1px solid grey;
}

.action-link.aw {
	margin-bottom: 20px;
}

.action-link:not(.profile) {
	a {
		border-bottom: 1px solid grey;
		color: #4a4a4a;
		display: flex;
		line-height: 35px;
		position: relative;
		padding: 5px 15px;
		font-weight: 300;

		span {
			margin-left: auto;

			color: #4a4a4a;
			font-size: 31px;
			line-height: 35px;
			padding-right: 10px;
		}
	}
}
</style>
