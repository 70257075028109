<template xmlns="">
    <div class="working-out-exercise-add-set">
        <v-btn class="abort-button" style="z-index: 1000" absolute top right icon small @click="abort">
            <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
        <swiper v-if="shownSetInput === 'weightRep'" ref="addSetSwiper" :options="addSetSwiperOptions">
            <swiper-slide v-if="form.weight || form.weight === 0" id="weight">
                <v-card-title class="font-weight-bold h3 black--text">Gewicht</v-card-title>
                <v-card-text class="py-0">
                    <v-row no-gutters class="mb-4 black--text" justify="space-between">
                        <v-col @click="openInputDialog('Gewicht', form.weight)" class="text-left">
                            <span class="text-h2 font-weight-light" v-text="form.weight"></span>
                            <span class="subheading font-weight-light mr-1">kg</span>
                            <span><v-icon color="black">mdi-pencil</v-icon></span>
                        </v-col>
                    </v-row>

                    <v-slider
                        class="align-items-center"
                        v-model="form.weight"
                        step="0.5"
                        color="primary"
                        hide-details
                        track-color="grey"
                        :min="form.weight > 190 ? 100 : 0"
                        :max="form.weight > 190 ? 500 : 200"
                    >
                        <template v-slot:prepend>
                            <v-icon color="primary" x-large @click="form.weight = form.weight - 0.5">mdi-minus</v-icon>
                        </template>

                        <template v-slot:append>
                            <v-icon color="primary" x-large @click="form.weight = form.weight + 0.5">mdi-plus</v-icon>
                        </template>
                    </v-slider>
                </v-card-text>
                <v-card-actions class="pt-0 justify-center">
                    <div class="rounded-lg pa-3" style="background: gainsboro" @click="swiper.slideTo(1, 500)">
                        <span class="black--text h5">weiter</span>
                    </div>
                </v-card-actions>
            </swiper-slide>
            <swiper-slide id="reps">
                <v-card-title class="font-weight-bold h3 black--text">Wiederholungen</v-card-title>
                <v-card-text class="py-0">
                    <v-row no-gutters class="mb-4 black--text" style="place-content: center" justify="space-between">
                        <v-col class="text-left">
                            <span
                                @click="openInputDialog('Wiederholungen', form.reps)"
                                class="text-h2 font-weight-light"
                                v-text="form.reps"
                            ></span>
                            <span class="subheading font-weight-light mr-1">Wh</span>
                            <span><v-icon color="black">mdi-pencil</v-icon></span>
                        </v-col>
                    </v-row>

                    <v-slider
                        class="align-items-center"
                        v-model="form.reps"
                        color="primary"
                        hide-details
                        track-color="grey"
                        min="0"
                        max="80"
                    >
                        <template v-slot:prepend>
                            <v-icon color="primary" large @click="form.reps = form.reps - 1">mdi-minus</v-icon>
                        </template>

                        <template v-slot:append>
                            <v-icon color="primary" large @click="form.reps = form.reps + 1">mdi-plus</v-icon>
                        </template>
                    </v-slider>
                </v-card-text>
                <v-card-actions class="pt-0 justify-space-around">
                    <div
                        v-if="form.weight"
                        class="rounded-lg pa-3"
                        style="background: gainsboro"
                        @click="swiper.slideTo(0, 500)"
                    >
                        <span class="black--text h5">zurück</span>
                    </div>
                    <div class="rounded-lg pa-3" style="background: gainsboro" @click="saveSetToStore()">
                        <span class="black--text h5">speichern</span>
                    </div>
                </v-card-actions>
                <v-dialog
                    class="rounded"
                    content-class="inputDialogWeight"
                    @click:outside="saveInputDialog()"
                    v-model="inputDialog"
                    max-width="250px"
                >
                    <v-card class="d-flex flex-column">
                        <v-card-subtitle class="font-weight-bold pb-0 pt-1">
                            {{ inputDialogValues.title }}
                        </v-card-subtitle>
                        <v-text-field
                            v-model="inputDialogValues.value"
                            autofocus
                            solo
                            flat
                            hide-details
                            class="align-self-center elevation-0"
                        ></v-text-field>
                    </v-card>
                </v-dialog>
            </swiper-slide>
        </swiper>
        <div v-if="shownSetInput === 'sensoPro'" class="d-flex flex-column justify-space-between">
            <v-card-title class="font-weight-bold h3 black--text">Notiz</v-card-title>
            <v-text-field
                id="noteTextField"
                @click="toggleTextField"
                style="-webkit-user-select: auto !important"
                height="100px"
                @change="writtenText"
                v-model="form.note"
                :readonly="false"
                :disabled="false"
                autofocus
                label="Geben Sie hier ihre Notiz ein..."
            ></v-text-field>
            <v-card-actions class="pt-0 justify-center">
                <div class="rounded-lg pa-3" style="background: gainsboro" @click="saveSetToStore()">
                    <span class="black--text h5">speichern</span>
                </div>
            </v-card-actions>
        </div>
        <workingout-exercise-stopwatch
            v-if="shownSetInput === 'time'"
            :given-time="form.time"
        ></workingout-exercise-stopwatch>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapGetters, mapState, mapActions } from 'vuex'
import WorkingoutExerciseStopwatch from './WorkingoutExerciseStopwatch.vue'

export default {
    components: {
        Swiper,
        SwiperSlide,
        WorkingoutExerciseStopwatch,
    },
    directives: {
        swiper: directive,
    },
    name: 'WorkingoutExerciseAddSet',
    data() {
        return {
            inputDialog: false,
            inputDialogValues: {
                title: '',
                value: '',
            },
            form: {
                weight: null,
                reps: null,
                note: '',
                time: null,
            },
            shownSetInput: '',
        }
    },
    created() {
        let hasWeight = false
        let hasReps = false
        let hasTime = false
        let isSensoPro = false

        this.navigationBarUsageData.settings.forEach((setting) => {
            if (setting.text === 'Gewicht') {
                this.form.weight = this.navigationBarUsageData.weight
                    ? this.navigationBarUsageData.weight
                    : setting.value
                if (!this.form.reps) this.form.reps = 0
                hasWeight = true
            }
            if (setting.text.toString() === 'WH ') {
                console.log(setting)
                this.form.reps = setting.value
            }
            if (setting.text.toString() === 'Min Wiederholungen') {
                this.form.reps = this.navigationBarUsageData.reps ? this.navigationBarUsageData.reps : setting.value
                hasReps = true
            }
            if (setting.text.toString() === 'Max Wiederholungen' && !hasReps) {
                this.form.reps =
                    this.navigationBarUsageData.reps !== 0 ? this.navigationBarUsageData.reps : setting.value
                hasReps = true
            }
            if (setting.text.toString() === 'Wiederholungen' && !hasReps) {
                this.form.reps =
                    this.navigationBarUsageData.reps !== 0 ? this.navigationBarUsageData.reps : setting.value
                hasReps = true
            }
            if (setting.text === 'Dauer') {
                this.form.time = this.navigationBarUsageData.time !== 0 ? this.navigationBarUsageData.time : null
                hasTime = true
            }
            if (this.navigationBarUsageData.title === 'Senso Pro') {
                isSensoPro = true
                this.form.note = this.navigationBarUsageData.form ? this.navigationBarUsageData.form.note : ''
            }
        })
        if (hasWeight && hasReps && !hasTime) {
            this.shownSetInput = 'weightRep'
        } else if (isSensoPro) {
            this.shownSetInput = 'sensoPro'
        } else if (hasTime) {
            this.shownSetInput = 'time'
        } else {
            this.shownSetInput = 'weightRep'
        }
    },
    methods: {
        ...mapActions('workingoutStore', ['saveSet']),
        ...mapActions('navigationStoreNotPersisted', ['setNewBottomNavigationUsage']),
        saveInputDialog() {
            this.inputDialog = false
            this.inputDialogValues.title === 'Gewicht'
                ? (this.form.weight = this.inputDialogValues.value)
                : (this.form.reps = this.inputDialogValues.value)
        },
        openInputDialog(title, value) {
            this.inputDialog = true
            this.inputDialogValues = { title: title, value, value }
        },
        toggleTextField() {
            document.getElementById('noteTextField').focus()
        },
        abort() {
            this.savedSet ? this.saveSet(null) : this.saveSet('reset')
        },
        saveSetToStore(object = null, type = null) {
            let set = JSON.parse(JSON.stringify(this.form))
            if (this.navigationBarUsageData.index !== null) set.index = this.navigationBarUsageData.index
            if (type === 'time') set.time = object
            this.saveSet(set)
        },
    },
    computed: {
        ...mapState('workingoutStore', ['stoppedDuration', 'savedSet']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarUsageData']),
        swiper() {
            return this.$refs.addSetSwiper.$swiper
        },
        addSetSwiperOptions: function () {
            return {
                allowTouchMove: false,
            }
        },
        hasWeight: function () {
            return this.findSettingByTitle('Gewicht') && this.findSettingByTitle('Gewicht').value
        },
        hasReps: function () {
            return this.findSettingByTitle('Min Wiederholungen') && this.findSettingByTitle('Min Wiederholungen').value
        },
        hasStopwatch: function () {
            if (this.findSettingByTitle('Dauer') && this.findSettingByTitle('Dauer').value && !this.isSensoPro)
                return true
            if (this.findSettingByTitle('Dehndauer') && this.findSettingByTitle('Dehndauer').value && !this.isSensoPro)
                return true
        },
    },
    watch: {
        stoppedDuration(value) {
            this.saveSetToStore(value, 'time')
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot {
    input {
        text-align: center;
    }
    box-shadow: null !important;
}
.abort-button {
    height: unset !important;
    position: absolute !important;
}
.working-out-exercise-add-set {
    width: 80vw;
    max-width: 500px;
    height: 100%;
    background: white;
}
::v-deep .inputDialogWeight {
    margin-bottom: auto;
}
</style>
