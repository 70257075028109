<template>
	<div class="tendays-feedback">
		<div class="done" v-show="status === 1">
			Besten Dank für Ihre Rückmeldung! <br />
			Wir bemühen uns, stehts besser zu werden.
		</div>

		<div class="error" v-show="status === 2">
			Die Bewertung kann leider nur einmalig ausgefüllt werden.
			<br /><br />Vielen Dank für die Teilnahme.
		</div>
		<div v-show="status === 0" class="umfrage">
			Wie hat Dir Dein 10 Tage Programm auf die Sonnenseite des Lebens gefallen?
		</div>
		<div v-show="status === 0" class="rating">
			<div v-show="true" class="rating" role="optgroup">
				<!-- in Rails just use 1.upto(5) -->
				<i
					class="fa fa-star-o fa-2x rating-star"
					id="rating-1"
					data-rating="1"
					tabindex="0"
					aria-label="Rate as one out of 5 stars"
					role="radio"
				></i>
				<i
					class="fa fa-star-o fa-2x rating-star"
					id="rating-2"
					data-rating="2"
					tabindex="0"
					aria-label="Rate as two out of 5 stars"
					role="radio"
				></i>
				<i
					class="fa fa-star-o fa-2x rating-star"
					id="rating-3"
					data-rating="3"
					tabindex="0"
					aria-label="Rate as three out of 5 stars"
					role="radio"
				></i>
				<i
					class="fa fa-star-o fa-2x rating-star"
					id="rating-4"
					data-rating="4"
					tabindex="0"
					aria-label="Rate as four out of 5 stars"
					role="radio"
				></i>
				<i
					class="fa fa-star-o fa-2x rating-star"
					id="rating-5"
					data-rating="5"
					tabindex="0"
					aria-label="Rate as five out of 5 stars"
					role="radio"
				></i>
			</div>
			<textarea v-show="true" v-model="rating.text" placeholder="Teile uns hier Deine Rückmeldung für dein 10 Tage Programm mit..."></textarea>
		</div>
		<div v-show="status === 0" @click="submit_feedback()" class="absenden">
			ABSENDEN
		</div>
	</div>
</template>
<script>
import $ from 'jquery'
import api from '../../api'
export default {
	data() {
		return {
			status: 0,
			rating: {
				amount: 0,
				text: '',
			},
		}
	},
	created() {
		this.$nextTick(function() {
			this.createRating()
		})
	},
	methods: {
		submit_feedback() {
			api.createFeedbackTendays({
				user_id: this.$route.params.userid,
				rating: this.rating,
			}).then((data) => {
				if (data.status === 0) {
					this.status = 2
				} else {
					this.status = 1
				}
			})
		},
		createRating() {
			this.rating = {
				amount: 0,
				text: '',
			}
			var ref = this

			$('.rating-star')
				.removeClass('selected')
				.addClass('fa-star-o')

			$('.rating-star')
				.on('mouseover', function(e) {
					var rating = $(e.target).data('rating')

					// fill all the stars
					$('.rating-star')
						.removeClass('fa-star-o')
						.addClass('fa-star')
						.removeClass('selected')
					// empty all the stars to the right of the mouse
					$('.rating-star#rating-' + rating + ' ~ .rating-star')
						.removeClass('fa-star')
						.addClass('fa-star-o')
				})
				.on('mouseleave', function(e) {
					// empty all the stars except those with class .selected
					$('.rating-star')
						.removeClass('fa-star')
						.addClass('fa-star-o')
				})
				.on('click', function(e) {
					var rating = $(e.target).data('rating')
					ref.setRating(rating)
				})
				.on('keyup', function(e) {
					// if spacebar is pressed while selecting a star
					if (e.keyCode === 32) {
						// set rating (same as clicking on the star)
						var rating = $(e.target).data('rating')
						ref.setRating(rating)
					}
				})
		},
		setRating(rating) {
			this.rating.amount = rating

			// fill all the stars assigning the '.selected' class
			$('.rating-star')
				.removeClass('fa-star-o')
				.addClass('selected')
			// empty all the stars to the right of the mouse
			$('.rating-star#rating-' + rating + ' ~ .rating-star')
				.removeClass('selected')
				.addClass('fa-star-o')
		},
		load() {},
	},
}
</script>
<style lang="scss" scoped>
.error,
.done {
	color: white;
	padding: 10px 15px;
}
.done {
	background-color: var(--v-primary);
}
.tendays-feedback {
	max-width: 400px;
	margin: 10px 15px;
	padding-top: 30px;
	.umfrage {
		font-weight: bold;
	}
}
.rating {
	cursor: pointer;
	margin-top: 20px;

	.rating {
		display: flex;
		justify-content: space-evenly;
	}
	i {
		padding: 10px;

		&:focus {
			outline: 0px transparent !important;
		}
	}
	textarea {
		width: 100%;
		height: 100px;

		padding: 10px;
		margin-top: 20px;
	}
}

.rating .rating-star {
	display: inline-block;
	position: relative;
	padding-right: 5px;
	margin-left: -5px;
	color: #e3cf7a;
}
.rating .selected:before {
	content: '\f005';
}
.fa-2x {
	font-size: 3em !important;
}

.absenden {
	text-align: center;
	line-height: 60px;
	background-color: var(--v-primary);
	color: white;
	font-weight: bold;
	letter-spacing: 1px;
	font-size: 24px;
}
</style>
