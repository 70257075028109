export const shopStore = {
	namespaced: true,
	state: {
		cartProducts: [],
		cartVisibilityToggle: false,
		openProduct: null,
	},
	getters: {
		cartTotalPrice(state) {
			let price = 0
			state.cartProducts.forEach(product => {
				price = price + (product.price * product.amount)
			})
			return price
		},
		productsLength(state) {
			return state.cartProducts.length
		},
	},
	mutations: {
		toggleCartVisiblity(state) {
			state.cartVisibilityToggle = !state.cartVisibilityToggle
		},
		addProductToCart(state, data) {
			if (state.cartProducts.length && state.cartProducts.find(product => product._id === data._id && product.price === data.price)) {
				state.cartProducts.find(product => product._id === data._id && product.price === data.price).amount += 1
			} else {
				data.amount = 1
				state.cartProducts.push(data)
			}
		},
		removeProductFromCart(state, data) {
			state.cartProducts.find(product => product._id === data._id && product.price === data.price).amount -= 1
			state.cartProducts = state.cartProducts.filter(product => product.amount)
		},
		openProductFromStore(state, data) {
			state.openProduct = null
			state.openProduct = data
		}
	},
	actions: {
		toggleCartVisiblity({ commit }) {
			commit('toggleCartVisiblity')
		},
		addProductToCart({ commit }, data) {
			commit('addProductToCart', JSON.parse(JSON.stringify(data)))
		},
		removeProductFromCart({ commit }, data) {
			commit('removeProductFromCart', JSON.parse(JSON.stringify(data)))
		},
		openProductFromStore({ commit }, data) {
			commit('openProductFromStore', data)
		}
	},
}
