<template>
    <div class="text-center">
        <v-dialog persistent v-model="dialog" dark width="700">
            <template v-slot:activator="{ on, attrs }">
                <v-btn text class="white--text" @click="fetchEntries()" v-bind="attrs" v-on="on">
                    <v-icon class="white--text pr-2">mdi-pencil</v-icon>
                    Korrektur beantragen
                </v-btn>
            </template>

            <v-card>
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :items-per-page="40"
                    no-data-text="Keine Anwesenheits Einträge gefunden"
                    :headers="tableHeaders"
                    :items="tableItems"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>
                                Einträge vom {{ $moment(selectedDate).format('DD.MM.YYYY') }}
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="formDialog" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        Neuer Eintrag
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-time-picker
                                                    :key="editedItem"
                                                    full-width
                                                    v-model="editedItem.time"
                                                    format="24hr"
                                                ></v-time-picker>
                                            </v-row>
                                            <v-row>
                                                <v-select
                                                    v-model="editedItem.oldType"
                                                    :items="[
                                                        { text: 'Check-In', value: 'checkin' },
                                                        { text: 'Check-Out', value: 'checkout' },
                                                    ]"
                                                    label="Typ"
                                                ></v-select>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="grey darken-1" text @click="close">Abbrechen</v-btn>
                                        <v-btn color="primary" text @click="save" :loading="saving">Speichern</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">
                                        Willst du diesen Eintrag wirklich löschen?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">Löschen</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.type="{ item }">
                        <v-chip :color="getColorForChip(item)">
                            <span>{{ mappedEntryTypes[item.realType] }}</span>
                        </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon v-if="item.correction !== 'open'" small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon v-if="item.correction !== 'open'" small @click="deleteItem(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn color="primary" text @click="closeDialog">Fertig</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
import api from '@/api'
import { mapState } from 'vuex'
export default {
    name: 'DemandTimeEntryEdit',
    props: {
        selectedDate: {
            required: true,
        },
    },
    components: {},
    data() {
        return {
            saving: false,
            editIndex: -1,
            defaultItem: {
                time: '',
                type: null,
            },
            editedItem: {
                time: '',
                type: null,
            },
            formDialog: false,
            dialogDelete: false,
            tableHeaders: [
                { text: 'Zeit', value: 'time', sortable: false },
                { text: 'Typ', value: 'type', sortable: false },
                { text: 'Studio', value: 'studio', sortable: false },
                { text: 'Aktion', value: 'actions', sortable: false },
            ],
            entryTypes: {
                checkin: 'Check-In',
                checkout: 'Check-Out',
                pause: 'Pause Start',
                pauseend: 'Pause Ende',
            },
            mappedEntryTypes: {
                sick: 'Krank',
                holiday: 'Urlaub',
                checkin: 'Check-In',
                checkout: 'Check-Out',
                pause: 'Pause',
                pauseend: 'Pause fertig',
                error: 'Fehler (Checkin ohne Checkout)',
                error1: 'Fehler (2 Check-Ins hintereinander)',
                error2: 'Fehler (2 Check-Outs hintereinander)',
                error3: 'Fehler (Checkout ohne Checkin)',
                deleteCorrectionOpen: 'Löschen (Korrektur offen)',
                checkinCorrectionOpen: 'Check-In (Korrektur offen)',
                checkinCorrectionDenied: 'Check-In (Korrektur abgelehnt)',
                checkinCorrectionApproved: 'Check-In (Korrektur genehmigt)',
                checkoutCorrectionOpen: 'Check-Out (Korrektur offen)',
                checkoutCorrectionDenied: 'Check-Out (Korrektur abgelehnt)',
                checkoutCorrectionApproved: 'Check-Out (Korrektur genehmigt)',
            },
            editTimeEntry: null,
            dialog: false,
            entries: [],
        }
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        formTitle() {
            return this.editIndex === -1 ? 'Neuer Eintrag' : 'Eintrag bearbeiten'
        },
        tableItems() {
            let items = []
            this.entries.forEach((entry) => {
                items.push({
                    correction: entry.correction || null,
                    oldType: entry.oldType,
                    realType: entry.correctedType || entry.type,
                    time: entry.time,
                    type: this.mappedEntryTypes[entry.type],
                    studio: this.storeStudios.find((stud) => stud._id === entry.studio).name,
                })
            })
            return items
        },
    },
    methods: {
        getColorForChip(item) {
            if (
                item.realType === 'error' ||
                item.realType === 'error1' ||
                item.realType === 'error2' ||
                item.realType === 'error3'
            ) {
                return 'red'
            }
            if (item.realType === 'checkinCorrectionOpen' || item.realType === 'checkoutCorrectionOpen') {
                return 'orange'
            }
            if (
                item.realType === 'checkinCorrectionDenied' ||
                item.realType === 'checkoutCorrectionDenied' ||
                item.realType === 'deleteCorrectionOpen'
            ) {
                return 'red'
            }
            if (item.realType === 'checkinCorrectionApproved' || item.realType === 'checkoutCorrectionApproved') {
                return 'success'
            }
            return 'grey' //if (item.)
        },
        async fetchEntries() {
            this.entries = []
            let response = await api.fetchTimeTrackerDemandCorrection(
                this.$moment(this.selectedDate).format('YYYY-MM-DD')
            )
            if (response.attendance && response.attendance[this.$moment(this.selectedDate).format('YYYY-MM-DD')]) {
                this.entries = response.attendance[this.$moment(this.selectedDate).format('YYYY-MM-DD')]
            }
        },
        editItem(item) {
            this.editIndex = this.entries.findIndex((entry) => entry.time === item.time)
            this.editedItem = Object.assign({}, item)
            this.editedItem.beforeCorrectionType = JSON.parse(JSON.stringify(this.editedItem.oldType))
            this.editedItem.beforeCorrectionTime = JSON.parse(JSON.stringify(this.editedItem.time))
            this.formDialog = true
        },

        deleteItem(item) {
            this.editIndex = this.entries.findIndex((entry) => entry.time === item.time)
            this.editedItem = Object.assign({}, item)

            this.deleteItemConfirm()
            this.fetchEntries()
            //this.dialogDelete = true
        },

        async deleteItemConfirm() {
            try {
                await api.deleteAttendanceEntry({
                    date: this.$moment(this.selectedDate).format('YYYY-MM-DD'),
                    time: this.editedItem.time,
                })
            } catch (e) {
                console.log(e)
            }
            this.closeDelete()
        },

        close() {
            this.formDialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editIndex = -1
            })
        },

        async save() {
            this.saving = true
            if (!this.editedItem.time) return alert('Bitte Zeit vollständig eingeben')
            if (!this.editedItem.oldType) return alert('Bitte Typ auswählen')
            if (this.editedItem.time.length < 7) {
                this.editedItem.time = this.editedItem.time + ':00'
            }
            if (this.editIndex > -1) {
                let editedItem = await api.updateExistingAttendanceEntry({
                    date: this.$moment(this.selectedDate).format('YYYY-MM-DD'),
                    correction: this.editedItem,
                })
            } else {
                try {
                    let newItem = await api.createNewAttendanceCorrection({
                        date: this.$moment(this.selectedDate).format('YYYY-MM-DD'),
                        correction: this.editedItem,
                    })
                } catch (e) {
                    this.$toast.error('Es kann keine zweite Korrektur für die selbe Uhrzeit erstellt werden.')
                }
            }
            this.saving = false
            this.fetchEntries()
            this.close()
        },
        closeDialog() {
            this.$emit('fetchEntries')
            this.dialog = false
        },
    },
}
</script>
  
  <style scoped>
</style>
  