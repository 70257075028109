<template>
    <div>
        <v-row>
            <v-col cols="6">
                <v-btn color="primary" :loading="loadingGFStats" @click="downloadGFStatistics">
                    Groupfitness Statistiken
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn color="primary" :loading="loadingGFPivot" @click="downloadGFPivot">
                    Groupfitness Pivot Tabelle
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                <v-card
                    dark
                    hover
                    class="rounded pa-2 d-flex fill-height"
                    style="background: linear-gradient(90deg, #b7f8db 0%, #50a7c2 100%)"
                >
                    <v-col class="h4 d-flex flex-column font-weight-bold align-self-center mb-0">
                        <number from="0" :to="dashboardGroupFitness ? dashboardGroupFitness.length : 0"></number>

                        Kurse
                    </v-col>
                    <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
                        <v-progress-circular indeterminate color="white"></v-progress-circular>
                    </v-col>
                    <apexchart
                        v-else
                        height="80"
                        :series="courseCountSeries"
                        :options="groupFitnessSparkCharts"
                    ></apexchart>
                </v-card>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                <v-card
                    dark
                    hover
                    class="rounded pa-2 d-flex fill-height"
                    style="background: linear-gradient(90deg, #48c6ef 0%, #6f86d6 100%)"
                >
                    <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0">
                        <span>{{ participantsTotal }}</span>
                        <span>Anmeld.</span>
                    </v-col>
                    <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
                        <v-progress-circular indeterminate color="white"></v-progress-circular>
                    </v-col>
                    <apexchart
                        v-else
                        height="80"
                        :series="participantsCountSeries"
                        :options="groupFitnessSparkCharts"
                    ></apexchart>
                </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                <v-card
                    dark
                    hover
                    class="rounded pa-2 d-flex fill-height"
                    style="background: linear-gradient(90deg, #a1c4fd 0%, #c2e9fb 100%)"
                >
                    <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0">
                        <span>{{ starsAverage }}Ø</span>
                        <span>Sterne</span>
                    </v-col>
                    <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
                        <v-progress-circular indeterminate color="white"></v-progress-circular>
                    </v-col>
                    <apexchart
                        v-else
                        height="80"
                        :series="courseRatingSeries"
                        :options="groupFitnessBarCharts"
                    ></apexchart>
                </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                <v-card
                    dark
                    hover
                    class="rounded pa-2 d-flex fill-height"
                    style="background: linear-gradient(90deg, #667db6 0%, #0082c8 33%, #0082c8 66%, #667db6 100%)"
                >
                    <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0">
                        <span>{{ averagePerCourse }} Ø</span>
                        <span>Anmeldungen pro Kurs</span>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="flex-column">
            <v-text-field
                dark
                class="black pa-2"
                v-model="search"
                append-icon="mdi-magnify"
                label="Suchen (mehrere Suchbegriffe mit Komma trennen)"
                single-line
                hide-details
            ></v-text-field>
            <v-data-table
                dark
                :loading="!finishedLoading"
                :headers="groupfitnessCoursesTable"
                no-data-text="Keine Kurse gefunden..."
                loading-text="Groupfitness Kurse werden geladen..."
                :items="tableItems"
                @dblclick:row="dblClicked"
                :items-per-page="8"
                class="elevation-5"
            >
                <template v-slot:item.signupsCount="{ item }">
                    <v-chip
                        :color="getColor(item.signupsCount)"
                        style="min-width: 50%"
                        class="justify-content-center font-weight-bold"
                        dark
                    >
                        {{ item.signupsCount }}
                    </v-chip>
                </template>
            </v-data-table>
            <!-- 

                    <v-card class="rounded-xl pa-1" hover dark>
                        <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">Kurse</v-card-subtitle>
                        <v-col v-if="!finishedLoading" class="d-flex justify-center" cols="12">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                        <apexchart v-else :options="barChartOptions" :series="userAgeSeries"></apexchart>
                    </v-card>
                -->
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card-title class="white--text">Durchschnittliche Teilnehmer pro Kurs</v-card-title>
            </v-col>

            <v-col cols="12">
                <apexchart type="bar" :options="chartOptionsAvgVisitors" :series="chartSeriesAvgVisitors"></apexchart>
            </v-col>
            <v-col cols="12">
                <v-card-title class="white--text">
                    Prozentualer Anteil der Mitglieder, die GF besucht haben
                </v-card-title>
            </v-col>
            <v-col cols="12">
                <apexchart
                    type="bar"
                    :options="chartOptionsActiveGFVisitors"
                    :series="chartSeriesActiveGFVisitors"
                ></apexchart>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'dashboard',
    props: {
        studios: {
            type: Array,
        },
        datePickerRange: {
            type: Array,
        },
    },
    data() {
        return {
            userCount: [],
            courseUniqueVisitors: [],
            percentages: [],
            avgVisitors: [],
            loadingGFStats: false,
            loadingGFPivot: false,
            searchTimeout: null,
            search: '',
            starsAverage: 0,
            backupArray: null,
            dashboardGroupFitness: null,
            finishedLoading: false,
            searchItems: [],
            participantsTotal: 0,
            groupfitnessCoursesTable: [
                {
                    text: 'Datum',
                    value: 'date',
                },
                {
                    text: 'Studio',
                    value: 'studio',
                },
                {
                    text: 'Kurs',
                    value: 'courseTitle',
                },
                {
                    text: 'Instruktor',
                    value: 'instructors',
                },
                {
                    text: 'Dauer',
                    value: 'duration',
                },
                {
                    text: 'Teilnehmer',
                    value: 'signupsCount',
                },
                {
                    text: 'Durchschnittsbewertung',
                    value: 'avgRating',
                },
                {
                    text: 'Abgesagt',
                    value: 'cancelled',
                },
            ],
        }
    },
    async created() {
        let data = await api.fetchGroupfitnessDashboardInfos({
            studios: this.studios.map((studio) => studio._id),
            range: this.datePickerRange,
        })
        this.dashboardGroupFitness = data.courses
        this.userCount = data.userCountMapped
        this.courseUniqueVisitors = data.courseUniqueVisitorsMapped
        this.percentages = data.percentages
        this.avgVisitors = data.averageParticipantsMapped
        this.backupArray = JSON.parse(JSON.stringify(this.dashboardGroupFitness))
        this.setSearchItems()
        // disc? bini
        this.finishedLoading = true
    },
    watch: {
        /*  search() {
            clearTimeout(this.searchTimeout)
            let lowerCaseSearch = this.search.toLowerCase()
            this.searchTimeout = setTimeout(() => {
                this.dashboardGroupFitness = this.backupArray.filter((course) => {
                    return (
                        course.instructors[0].name.toLowerCase().includes(lowerCaseSearch) ||
                        course.course.toLowerCase().includes(lowerCaseSearch)
                    )
                })
            }, 1000)
        },*/
        search() {
            clearTimeout(this.searchTimeout)
            let lowerCaseSearch = this.search.toLowerCase()
            let searchTerms = lowerCaseSearch.split(',')

            this.searchTimeout = setTimeout(() => {
                this.dashboardGroupFitness = this.backupArray.filter((course) => {
                    return searchTerms.every((term) => {
                        return (
                            course.instructors[0].name.toLowerCase().includes(term.trim()) ||
                            course.course.toLowerCase().includes(term.trim())
                        )
                    })
                })
            }, 1000)
            console.log(this.dashboardGroupFitness)
        },
        async studios() {
            this.finishedLoading = false
            let data = await api.fetchGroupfitnessDashboardInfos({
                studios: this.studios.map((studio) => studio._id),
                range: this.datePickerRange,
            })
            this.dashboardGroupFitness = data.courses
            this.userCount = data.userCountMapped
            this.courseUniqueVisitors = data.courseUniqueVisitorsMapped
            this.percentages = data.percentages
            this.avgVisitors = data.averageParticipantsMapped
            this.search = ''
            this.backupArray = JSON.parse(JSON.stringify(this.dashboardGroupFitness))
            this.setSearchItems()

            this.finishedLoading = true
        },
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.finishedLoading = false
                let data = await api.fetchGroupfitnessDashboardInfos({
                    studios: this.studios.map((studio) => studio._id),
                    range: this.datePickerRange,
                })
                this.dashboardGroupFitness = data.courses
                this.userCount = data.userCountMapped
                this.courseUniqueVisitors = data.courseUniqueVisitorsMapped
                this.percentages = data.percentages
                this.avgVisitors = data.averageParticipantsMapped
                this.search = ''
                this.setSearchItems()

                this.backupArray = JSON.parse(JSON.stringify(this.dashboardGroupFitness))
                this.finishedLoading = true
            }
        },
    },
    computed: {
        chartOptionsAvgVisitors() {
            let data = {
                chart: {
                    id: 'leadsPerSetting',
                },
                xaxis: {
                    categories: this.avgVisitors.map((item) => item.name),
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
            }
            return data
        },
        chartSeriesAvgVisitors() {
            let data = [
                {
                    name: 'Durchschnittliche Teilnehmer',
                    data: this.avgVisitors.map((item) => item.data),
                },
            ]
            return data
        },
        chartSeriesActiveGFVisitors() {
            let data = [
                {
                    name: '% aktive GF Besucher',
                    data: this.percentages.map((item) => item.data),
                },
            ]
            return data
        },
        chartOptionsActiveGFVisitors() {
            let data = {
                chart: {
                    id: 'activeGFVisitors',
                },
                xaxis: {
                    categories: this.percentages.map((item) => item.name),
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
            }
            return data
        },
        showMinutes() {
            return this.dashboardGroupFitness.length < 80
        },
        showHours() {
            return this.dashboardGroupFitness.length < 200
        },
        courseCountSeries() {
            let returnData = []
            for (let course of this.dashboardGroupFitness) {
                let date = this.$moment(course.ts * 1000)
                if (this.showHours) {
                    date.set({ hour: course.hours })
                } else {
                    date.set({ hour: 12 })
                }
                if (this.showMinutes) {
                    date.set({ minute: course.minutes })
                }
                if (!returnData.find((data) => data.x === date.unix() * 1000)) {
                    returnData.push({
                        x: date.unix() * 1000,
                        y: 1,
                    })
                } else {
                    returnData.find((data) => data.x === date.unix() * 1000).y++
                }
            }
            return [{ data: returnData }]
        },

        participantsCountSeries() {
            let returnData = []
            let count = 0

            this.dashboardGroupFitness.forEach((course) => {
                count = count + course.signupsCount
                let date = this.$moment(course.ts * 1000)
                if (this.showHours) {
                    date.set({ hour: course.hours })
                } else {
                    date.set({ hour: 12 })
                }
                if (this.showMinutes) {
                    date.set({ minute: course.minutes })
                }
                if (!returnData.find((data) => data.x === date.unix() * 1000)) {
                    returnData.push({
                        x: date.unix() * 1000,
                        y: course.signupsCount,
                    })
                } else {
                    returnData.find((data) => data.x === date.unix() * 1000).y =
                        returnData.find((data) => data.x === date.unix() * 1000).y + course.signupsCount
                }
            })
            this.participantsTotal = count
            return [{ data: returnData }]
        },
        courseRatingSeries() {
            let returnData = []
            let count = 0
            let courseCount = 0

            this.dashboardGroupFitness.forEach((course) => {
                if (course.avgRating !== null) {
                    courseCount++
                    count = count + course.avgRating
                    if (!returnData.find((data) => data.x === Math.round(course.avgRating))) {
                        returnData.push({
                            x: Math.round(course.avgRating),
                            y: 1,
                        })
                    } else {
                        returnData.find((data) => data.x === Math.round(course.avgRating)).y++
                    }
                }
            })
            this.starsAverage = Math.round((count / courseCount) * 10) / 10
            return [{ data: returnData }]
        },
        groupFitnessBarCharts() {
            return {
                chart: {
                    sparkline: {
                        enabled: true,
                    },
                    type: 'bar',
                    height: 80,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                    },
                },
                dataLabels: {
                    style: {
                        fontSize: '12px',
                        colors: ['#304758'],
                    },
                },
                markers: {
                    size: 0,
                },
                xaxis: {
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                },
                yaxis: {
                    show: false,
                },
                grid: {
                    show: false,
                },
                colors: ['#fff'],
                tooltip: {
                    theme: 'dark',
                    y: {
                        title: {
                            formatter: function formatter(val) {
                                return ''
                            },
                        },
                    },
                },
            }
        },
        groupFitnessSparkCharts() {
            return {
                chart: {
                    locales: [
                        {
                            name: 'en',
                            options: {
                                shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                            },
                        },
                    ],
                    id: 'spark1',
                    type: 'bar',
                    group: 'sparks',
                    sparkline: {
                        enabled: true,
                    },
                },
                markers: {
                    size: 0,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: this.dashboardGroupFitness.length < 50 ? ' dd.MM HH:mm' : 'dd.MM',
                        datetimeUTC: false,
                    },
                },
                colors: ['#fff'],
                tooltip: {
                    theme: 'dark',
                    x: {
                        format: 'ddd dd.MM HH:mm',
                    },
                    y: {
                        title: {
                            formatter: function formatter(val) {
                                return ''
                            },
                        },
                    },
                },
            }
        },
        tableItems() {
            if (this.backupArray)
                return this.dashboardGroupFitness.map((course) => {
                    let date = this.$moment(course.ts * 1000).set({ hour: course.hours, minute: course.minutes })
                    return {
                        date: date.format('YYYY.MM.DD HH:mm'),
                        studio: this.studios.find((studio) => studio._id === course.studio).name,
                        courseTitle: course.course,
                        duration: course.duration,
                        signupsCount: course.signupsCount,
                        instructors: course.instructors[0].name,
                        cancelled: course.cancelled ? 'ja' : 'nein',
                        avgRating: course.avgRating ? Math.round(course.avgRating * 100) / 100 : 'nicht bewertet',
                        _id: course._id,
                    }
                })
        },
        averagePerCourse() {
            if (!this.dashboardGroupFitness) return
            return Math.round((this.participantsTotal / this.dashboardGroupFitness.length) * 10) / 10
        },
    },
    methods: {
        async downloadGFPivot() {
            this.loadingGFPivot = true
            try {
                let gfstats = await api.downloadGFPivotExcel(this.datePickerRange)
                this.loadingGFPivot = false
            } catch (e) {
                this.$toast.error('Fehler beim laden')
                this.loadingGFPivot = false
            }
        },
        async downloadGFStatistics() {
            this.loadingGFStats = true
            try {
                let gfstats = await api.downloadGFStatisticsExcel(this.datePickerRange)
                this.loadingGFStats = false
            } catch (e) {
                this.$toast.error('Fehler beim laden')
                this.loadingGFStats = false
            }
        },
        getColor(count) {
            if (count < this.averagePerCourse - 5) return 'red'
            else if (count < this.averagePerCourse - 1) return 'orange'
            else return 'green'
        },
        setSearchItems() {},
        dblClicked(item, item2) {
            this.$router.push('/kurse/' + item2.item._id)
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
