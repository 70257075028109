<template>
    <div class="videoarchiv d-flex flex-row flex-wrap pb-15 mb-20">
        <v-skeleton-loader
            v-show="loading"
            v-for="i in 15"
            :key="i"
            class="mx-auto my-3"
            width="300px"
            height="200px"
            type="card"
        ></v-skeleton-loader>

        <vue-flip
            v-show="!loading"
            width="300px"
            class="my-3 mx-auto d-flex"
            height="200px"
            v-for="video in videoArchive"
            v-model="video.flipped"
            :key="video.course._id"
        >
            <template v-slot:front>
                <v-card
                    @click="video.flipped = true"
                    width="300px"
                    height="200px"
                    class="videoarchiv__card rounded d-flex"
                    :img="'/img/kurse/' + video.course.bild"
                >
                    <div class="videoarchiv__card-text d-flex flex-column justify-space-between white--text mt-auto">
                        <v-card-title
                            class="videoarchiv__card-text-coursetitle ma-0 h4 pb-3"
                            style="word-break: keep-all"
                        >
                            {{ video.course.name }}
                        </v-card-title>
                        <!---	<v-card-text class="pb-2">{{ shorten(video.course.beschreibung, 80) }}</v-card-text>---->
                    </div>
                </v-card>
            </template>
            <template v-slot:back>
                <v-card
                    width="300px"
                    height="200px"
                    class="videoarchiv__card rounded d-flex"
                    :img="'/img/kurse/' + video.course.bild"
                >
                    <div class="videoarchiv__card-description d-flex flex-column justify-space-between white--text">
                        <v-card-text class="pb-2">{{ shorten(video.course.beschreibung, 150) }}</v-card-text>
                        <br />
                        <div class="watchNow" @click="showPopup(video)"><span>Videos ansehen</span></div>
                    </div>
                </v-card>
            </template>
        </vue-flip>
        <v-dialog :value="showVA" persistent max-width="400">
            <v-card v-if="selectedCourse && selectedCourse.videos">
                <v-card-subtitle class="pb-0 pt-5">{{ selectedCourse.videos.length }} Videos</v-card-subtitle>
                <v-card-title class="text-h5 pt-0" style="word-break: keep-all">
                    {{ selectedCourse.course.name }}
                </v-card-title>
                <div class="videoListWrapper">
                    <v-btn
                        text
                        style="text-transform: none !important"
                        width="100%"
                        class="mb-1 videoList d-flex justify-space-between"
                        @click="runVideo(video.link)"
                        v-for="video of selectedCourse.videos"
                        :key="video._id"
                    >
                        <span>{{ video.titel.replaceAll('_', ' ') }}</span>
                        <v-icon color="primary">play_circle_outline</v-icon>
                    </v-btn>
                </div>

                <v-card-actions class="d-flex justify-center">
                    <v-btn color="grey darken-1" text @click="hideVideo">schliessen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="showVideoPopUp" persistent max-width="400">
            <v-card>
                <div class="video-stream">
                    <iframe
                        :src="showVideo"
                        width="100%"
                        height="300px"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen
                    ></iframe>
                </div>
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="grey darken-1" text @click="hideVideoPopup">schliessen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import api from '../api'
import $ from 'jquery'
import VueFlip from 'vue-flip'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    components: {
        VueFlip,
    },
    data() {
        return {
            showVideo: null,
            selectedCourse: null,
            loading: true,
            videoArchive: null,
            videoArchiveFiltered: null,
        }
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'toggleNavigationBarVisibility',
            'setNavigationbarVisibility',
        ]),
        hideVideo() {
            this.setNavigationbarVisibility(true)
            this.showVideo = null
            this.selectedCourse = null
        },
        hideVideoPopup() {
            this.setNavigationbarVisibility(true)
            this.showVideo = null
            this.selectedCourse = null
        },
        showPopup(video) {
            this.setNavigationbarVisibility(false)
            this.selectedCourse = video
        },
        runVideo(link) {
            this.showVideo = link
            this.selectedCourse = null
        },

        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        async loadVideos() {
            this.loading = true

            this.videoArchive = await api.getVideoArchiv()
            this.videoArchiveFiltered = JSON.parse(JSON.stringify(this.videoArchive))
            this.loading = false
        },
    },
    computed: {
        showVA() {
            if (this.selectedCourse) return true
            return false
        },
        showVideoPopUp() {
            if (this.showVideo) return true
            return false
        },
    },
    created() {
        this.loadVideos()
    },
}
</script>
<style lang="scss" scoped>
.video-stream {
    position: relative;
    padding-bottom: 56.25%; /* ratio 16x9 */
    height: 300px;
    overflow: hidden;
    width: 100%;
    height: auto;
    background-image: url('/img/icons/loadingSettings.gif');
    background-position: center center;
}
.video-stream iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videoListWrapper {
    max-height: 300px;
    overflow-y: auto;
}
.watchNow {
    height: 45px;
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    display: flex;
    background-color: var(--v-primary);
    span {
        margin: auto;
    }
}
.videoarchiv {
    &__card {
        &-text {
            width: 100%;

            background-color: #0000008a;
            font-weight: bold;
            letter-spacing: 1px;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }
        &-description {
            width: 100%;
            height: 100%;
            background-color: #0000008a;

            letter-spacing: 1px;
        }
    }
}
</style>
