<template>
    <v-card flat dark>
        <v-row no-gutters>
            <v-col class="d-flex justify-center my-5" cols="12">
                <v-btn color="primary" outlined @click="showCategoryDialog = true">Hinzufügen</v-btn>
            </v-col>

            <v-col v-for="category of categories" :key="category._id" class="fill-height pa-5" cols="12" md="4">
                <v-card
                    @click="$router.push('/dashboard/leads/category-settings/' + category._id)"
                    class="rounded-xl"
                    outlined
                >
                    <v-card-subtitle class="mb-0 pb-0">
                        <v-icon>mdi-calendar</v-icon>
                        {{ $moment(category.updatedAt).format('DD.MM.YYYY HH:mm') }} Uhr
                    </v-card-subtitle>
                    <v-card-title>{{ category.title }}</v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog max-width="500" :value="showCategoryDialog" persistent>
            <v-card flat v-if="category">
                <v-card-title class="primary white--text">Kampagne Titel</v-card-title>
                <v-text-field class="mx-5 mt-5" outlined v-model="category.title" label="Titel"></v-text-field>
                <v-card-actions>
                    <v-btn text @click="resetCategoryDialog">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :loading="savingCategory" color="primary" @click="saveCategoryDialog">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <router-view></router-view>
    </v-card>
</template>

<script>
import api from '@/api'
export default {
    data() {
        return {
            showCategoryDialog: true,
            selectedCategory: null,
            category: null,
            savingCategory: false,
            categories: [],
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.fetchLeadCategories()
            this.resetCategoryDialog()
        },
        async fetchLeadCategories() {
            this.loading = true
            try {
                let categories = await api.fetchLeadCategories()
                this.categories = categories.leadCategories
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },
        async saveCategoryDialog() {
            this.savingCategory = true
            try {
                let status = await api.saveLeadCategory({ category: this.category })
                if (status && status.category) {
                    this.categories.unshift(status.category)
                }
                this.showCategoryDialog = false
            } catch (e) {
                this.$toast.error('Fehler beim Speichern')
            } finally {
                this.savingCategory = false
            }
        },
        resetCategoryDialog() {
            this.showCategoryDialog = false
            this.category = {
                title: null,
                active: null,
                active_from: null,
                active_till: null,
            }
        },
    },
}
</script>
