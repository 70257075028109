<template>
    <v-card dark max-width="700px" class="mx-auto mb-15">
        <v-row class="px-5 pt-5" v-for="(field, index) of formFields" :key="index + '-formFieldIndex'">
            <v-col v-if="field.inputType === 'text'" cols="12">
                <v-text-field
                    filled
                    v-model="field.inputValue"
                    :type="field.inputType"
                    :label="field.identifier"
                ></v-text-field>
            </v-col>
            <v-col v-if="field.inputType === 'title'" cols="12">
                <v-text-field filled v-model="internalTitle" type="text" label="Interner Titel"></v-text-field>
                <v-text-field
                    filled
                    v-model="field.inputValue"
                    :type="field.inputType"
                    :label="field.identifier"
                ></v-text-field>
                <v-switch v-model="flexContract" label="Vertrag mit flexibler Laufzeit"></v-switch>
                <v-switch v-model="useStudioCity" label="Ort vom Studio übernehmen"></v-switch>
            </v-col>
            <v-col v-if="field.inputType === 'title'" cols="12">
                <v-select
                    clearable
                    label="Vertrag in folgenden Studios aktivieren"
                    multiple
                    v-model="enabledStudios"
                    :items="studios.filter((e) => membershipBackupStudios.includes(e._id.toString()))"
                    item-text="name"
                    item-value="_id"
                ></v-select>
            </v-col>
            <v-col v-if="field.inputType === 'title' && enabledStudios.length > 0" cols="12">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>E-Mails bei Ablehnung des Vertrags</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-expansion-panels>
                                <v-expansion-panel v-for="studio of membershipStudios" :key="studio + '-studioEmail'">
                                    <v-expansion-panel-header>
                                        {{ studios.find((e) => e._id.toString() === studio.toString()).name }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-combobox
                                            chips
                                            clearable
                                            multiple
                                            v-if="Object.keys(eMails).includes(studio.toString())"
                                            v-model="eMails[studio]"
                                            type="email"
                                            label="E-Mail"
                                        ></v-combobox>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <v-col v-if="field.inputType === 'company' && enabledStudios.length > 0" cols="12">
                <v-card-subtitle class="px-0 pt-0">Vertragspartner</v-card-subtitle>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Firma</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue[0].name"
                                type="text"
                                label="Firmenname"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue[0].street"
                                type="text"
                                label="Strasse"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue[0].city"
                                type="text"
                                label="Stadt"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue[0].phone"
                                type="text"
                                label="Telefon"
                            ></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col v-if="field.inputType === 'customer' && enabledStudios.length > 0" cols="12">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Kunde</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.firstname.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.lastname.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.street.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.city.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.phone.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.phoneMobile.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                            <v-text-field
                                filled
                                disabled
                                v-model="field.inputValue.phoneBusiness.value"
                                type="text"
                                :label="field.inputValue.identifier"
                            ></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col v-if="field.inputType === 'category' && enabledStudios.length > 0" cols="12">
                <v-card-subtitle class="px-0 pt-0">{{ field.identifier }}</v-card-subtitle>
                <v-row class="my-0 py-0" v-for="(category, index) of field.inputValue" :key="index + '-categoryIndex'">
                    <v-col class="my-0 py-0" cols="1">
                        <v-switch class="my-0 py-0" v-model="category.active"></v-switch>
                    </v-col>
                    <v-col class="my-0 py-0" cols="11">
                        <v-card-text class="my-0 py-0" :class="{ noStudio: category.studios.length === 0 }">
                            {{ category.title }}
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    <span>Wird in folgenden Studios angezeigt:</span>
                                    <br />
                                    <span v-for="studio of category.studios" :key="studio + '-categoryStudioIndex'">
                                        {{ getNameOfStudio(studio) }}
                                        <br />
                                    </span>
                                </span>
                            </v-tooltip>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-subtitle class="px-0 pb-0 mb-2">
                    Badge (Kunden füllen Vertrag über Webseite aus)
                </v-card-subtitle>
                <v-row class="mt-0 pt-0">
                    <v-col class="my-0 py-0" cols="1">
                        <v-switch class="my-0 py-0" v-model="badge.enabled"></v-switch>
                    </v-col>
                    <v-col class="my-0 py-0" cols="11">Gebühr für Badge</v-col>
                </v-row>
                <v-row v-if="badge.enabled" class="my-0 py-0">
                    <v-col class="my-0 py-0" cols="12">
                        <v-text-field
                            v-model="badge.amount"
                            type="number"
                            label="Gebühr für Badge in CHF"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-card-subtitle class="px-0 pb-0 mb-0">Text für Mitarbeiter</v-card-subtitle>
                <vue-editor
                    class="user-selectable mt-5"
                    :editorToolbar="customToolbar"
                    v-model="field.salesText"
                ></vue-editor>
                <v-card-subtitle class="px-0 pb-0 mb-0">Text für Kunden</v-card-subtitle>
                <vue-editor
                    class="user-selectable mt-5"
                    :editorToolbar="customToolbar"
                    v-model="field.publicText"
                ></vue-editor>
            </v-col>
            <v-col v-if="field.inputType === 'membership' && enabledStudios.length > 0" cols="12">
                <v-card-subtitle class="px-0 pt-0">{{ field.identifier }}</v-card-subtitle>
                <v-row
                    class="my-0 py-0"
                    v-for="(membership, index) of field.inputValue"
                    :key="index + '-membershipIndex'"
                >
                    <v-col class="my-0 py-0" cols="3">
                        <v-switch
                            v-model="membership.active"
                            class="my-0 py-0"
                            :disabled="membership.studios.length === 0"
                        ></v-switch>
                    </v-col>
                    <v-col class="my-0 py-0" cols="9">
                        <v-card-text
                            class="my-0 py-0"
                            :class="{
                                flex: membership.flexActive,
                                noStudio: membership.studios.length === 0,
                            }"
                        >
                            {{ membership.title }}

                            {{ getFlexText(membership) }}
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    <span>Wird in folgenden Studios angezeigt:</span>
                                    <br />
                                    <span v-for="studio of membership.studios" :key="studio + '-membershipStudioIndex'">
                                        {{ getNameOfStudio(studio) }}
                                        <br />
                                    </span>
                                </span>
                            </v-tooltip>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-subtitle class="px-0 pb-0 mb-0">Text für Mitarbeiter</v-card-subtitle>
                <vue-editor
                    class="user-selectable mt-5"
                    :editorToolbar="customToolbar"
                    v-model="field.salesText"
                ></vue-editor>
                <v-card-subtitle class="px-0 pb-0 mb-0">Text für Kunden</v-card-subtitle>
                <vue-editor
                    class="user-selectable mt-5"
                    :editorToolbar="customToolbar"
                    v-model="field.publicText"
                ></vue-editor>
            </v-col>

            <v-col v-if="field.inputType === 'packages' && enabledStudios.length > 0" cols="12">
                <v-card-subtitle class="px-0 pt-0">{{ field.identifier }}</v-card-subtitle>
                <v-row class="my-0 py-0" v-for="(packag, index) of field.inputValue" :key="index + '-packageIndex'">
                    <v-col class="my-0 py-0" cols="1">
                        <v-switch
                            v-model="packag.active"
                            class="my-0 py-0"
                            :disabled="packag.studios.length === 0"
                        ></v-switch>
                    </v-col>
                    <v-col class="my-0 py-0" cols="11">
                        <v-card-text class="my-0 py-0" :class="{ noStudio: packag.studios.length === 0 }">
                            {{ packag.title }}
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    <span>Wird in folgenden Studios angezeigt:</span>
                                    <br />
                                    <span v-for="studio of packag.studios" :key="studio + '-packageStudioIndex'">
                                        {{ getNameOfStudio(studio) }}
                                        <br />
                                    </span>
                                </span>
                            </v-tooltip>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-subtitle class="px-0 pb-0 mb-0">Text für Mitarbeiter</v-card-subtitle>
                <vue-editor
                    class="user-selectable mt-5"
                    :editorToolbar="customToolbar"
                    v-model="field.salesText"
                ></vue-editor>
                <v-card-subtitle class="px-0 pb-0 mb-0">Text für Kunden</v-card-subtitle>
                <vue-editor
                    class="user-selectable mt-5"
                    :editorToolbar="customToolbar"
                    v-model="field.publicText"
                ></vue-editor>
            </v-col>
            <v-col v-if="field.inputType === 'paymentOptions' && enabledStudios.length > 0" cols="12">
                <v-card-subtitle class="px-0 pt-0">{{ field.identifier }}</v-card-subtitle>
                <v-row class="my-0 py-0" v-for="(payment, index) of field.inputValue" :key="index + '-paymentIndex'">
                    <v-col class="my-0 py-0" cols="1">
                        <v-switch
                            v-model="payment.active"
                            class="my-0 py-0"
                            :disabled="payment.studios.length === 0"
                        ></v-switch>
                    </v-col>
                    <v-col class="my-0 py-0" cols="11">
                        <v-card-text class="my-0 py-0" :class="{ noStudio: payment.studios.length === 0 }">
                            {{ payment.title }}
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    <span>Wird in folgenden Studios angezeigt:</span>
                                    <br />
                                    <span v-for="studio of payment.studios" :key="studio + '-packageStudioIndex'">
                                        {{ getNameOfStudio(studio) }}
                                        <br />
                                    </span>
                                </span>
                            </v-tooltip>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="field.inputType === 'conditions' && enabledStudios.length > 0" cols="12">
                <v-row no-gutter>
                    <v-col cols="12">
                        <v-card-subtitle class="px-0">Text vor Vertragsbedingungen</v-card-subtitle>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            class="user-selectable"
                            :editorToolbar="customToolbar"
                            v-model="preConditionText"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-card-subtitle class="px-0">Text nach Vertragsbedingungen</v-card-subtitle>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            class="user-selectable"
                            :editorToolbar="customToolbar"
                            v-model="postConditionText"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row no-gutters><v-card-subtitle class="px-0">Vertragsbedingungen</v-card-subtitle></v-row>

                <v-row no-gutters>
                    <v-col cols="12">
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="(condition, index) of field.inputValue"
                                :key="index + '-conditionIndex'"
                            >
                                <v-expansion-panel-header>
                                    {{ 'Bedingung ' + (parseInt(index) + 1) + '.' }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-col cols="12">
                                        <v-checkbox
                                            v-model="condition.highlight"
                                            :label="'Bedingung ' + (parseInt(index) + 1) + ' hervorheben'"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="'Bedingung ' + (parseInt(index) + 1) + '.'"
                                            v-model="condition.inputValue"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-btn
                                            @click="field.inputValue.splice(index, 1)"
                                            color="red"
                                            class="white--text"
                                        >
                                            Löschen
                                        </v-btn>
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col class="mt-2 mb-5 d-flex justify-center" cols="12">
                        <v-btn
                            @click="
                                field.inputValue.push({
                                    inputValue: '',
                                    highlight: false,
                                })
                            "
                            color="primary"
                        >
                            Hinzufügen
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-subtitle class="px-0">Aktionen / Rabatte</v-card-subtitle>
                    </v-col>
                    <v-col class="mb-5" cols="12">
                        <v-switch
                            v-model="promotions.giftSignUpFee"
                            label="Aktivierungs-Tarif frei schenkbar"
                        ></v-switch>
                    </v-col>
                    <v-col class="mb-5" cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-switch
                                    v-model="promotions.giftSignUpFeeIfAdditionalPackages"
                                    label="Aktivierungs-Tarif automatisch schenken bei eingestellter Anzahl Zusatzpakete"
                                ></v-switch>
                            </v-col>
                            <v-col class="mt-0 pt-0 mb-5" v-if="promotions.giftSignUpFeeIfAdditionalPackages" cols="12">
                                <v-switch
                                    class="pt-0 mt-0"
                                    label="Auch für Kunden aktivieren"
                                    v-model="promotions.giftSignUpFeeIfAdditionalPackagesCustomer"
                                ></v-switch>
                            </v-col>
                            <v-col class="mt-0 pt-0 mb-5" v-if="promotions.giftSignUpFeeIfAdditionalPackages" cols="12">
                                <v-text-field
                                    class="pt-0 mt-0"
                                    type="number"
                                    min="2"
                                    label="Anzahl Zusatzpakete"
                                    v-model="promotions.giftSignUpFeeIfAdditionalPackagesCount"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="mb-5" cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-switch
                                    v-model="promotions.giftSignUpFeeIfPackagesPriceHit"
                                    label="Aktivierungs-Tarif automatisch schenken ab ausgewähltem Zusatzpaket Total"
                                ></v-switch>
                            </v-col>

                            <v-col class="mt-0 pt-0 mb-5" v-if="promotions.giftSignUpFeeIfPackagesPriceHit" cols="12">
                                <v-text-field
                                    class="pt-0 mt-0"
                                    type="number"
                                    min="2"
                                    label="Total der Zusatzpakete"
                                    v-model="promotions.giftSignUpFeeIfPackagesPriceHitAmount"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-switch
                                    v-model="promotions.giftPackageAdditionalPackages"
                                    label="Günstigstes Zusatzpaket schenken ab eingestellter Anzahl Zusatzpakete"
                                ></v-switch>
                            </v-col>
                            <v-col class="mt-0 pt-0 mb-5" v-if="promotions.giftPackageAdditionalPackages" cols="12">
                                <v-switch
                                    class="pt-0 mt-0"
                                    label="Auch für Kunden aktivieren"
                                    v-model="promotions.giftPackageAdditionalPackagesCustomer"
                                ></v-switch>
                            </v-col>
                            <v-col class="mt-0 pt-0 mb-5" v-if="promotions.giftPackageAdditionalPackages" cols="12">
                                <v-text-field
                                    class="pt-0 mt-0"
                                    type="number"
                                    min="2"
                                    label="Anzahl Zusatzpakete"
                                    v-model="promotions.giftPackageAdditionalPackagesCount"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-switch v-model="promotions.renewalDiscount" label="Wiederabschlussrabatt"></v-switch>
                            </v-col>
                            <v-col class="mt-0 pt-0 mb-5" v-if="promotions.renewalDiscount" cols="12">
                                <v-text-field
                                    type="number"
                                    class="pt-0 mt-0"
                                    label="Rabatt in CHF"
                                    v-model="promotions.renewalDiscountAmount"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="px-5" v-if="enabledStudios.length > 0">
            <v-col class="d-flex justify-center" cols="12">
                <v-btn @click="createContract" color="primary">
                    <span v-if="!editingContract">Vertrag erstellen</span>
                    <span v-else>Kopie erstellen</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import api from '../../../api'
import { VueEditor } from 'vue2-editor'
export default {
    components: {
        VueEditor,
    },
    data() {
        return {
            useStudioCity: false,
            enabledStudios: [],
            flexContract: false,
            studios: [],
            badge: {
                enabled: false,
                amount: 0,
            },
            editingContract: false,
            promotions: {
                giftSignUpFee: false,
                giftSignUpFeeIfAdditionalPackages: false,
                giftSignUpFeeIfAdditionalPackagesCustomer: false,
                giftSignUpFeeIfPackagesPriceHit: false,
                giftSignUpFeeIfPackagesPriceHitAmount: 0,
                giftSignUpFeeIfAdditionalPackagesCount: 2,
                giftPackageAdditionalPackages: false,
                giftPackageAdditionalPackagesCustomer: false,
                giftPackageAdditionalPackagesCount: 3,
                renewalDiscount: false,
                renewalDiscountAmount: 0,
            },
            internalTitle: '',
            membershipBackup: [],
            formFields: [],
            eMails: {},
            preConditionText: '',
            postConditionText: '',
            contract: null,
            existingContract: null,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
        }
    },
    async created() {
        this.fetchStudios()

        this.fetchFormFields()
    },

    methods: {
        getFlexText(value) {
            let text = ''
            if (value.flexActive && value.flexSelection) {
                if (value.flexSelection.toString() === 'perMonth') text = 'Flex-monatlich'
                else text = 'Flex-täglich'
            }
            return text
        },
        getNameOfStudio(studio) {
            let studioFound = this.studios.find((studioItem) => studioItem._id === studio)
            if (studioFound && studioFound.name) return studioFound.name
            else return 'Studio nicht gefunden'
        },
        async fetchStudios() {
            let studios = await api.fetchStudios()
            this.studios = studios
        },
        getSalesTextIfExists(contract, category) {
            let salesText = ''
            console.log(category, 'CATEGORY', contract)
            if (contract && contract.formFields) {
                let categoryFound = contract.formFields.find((categoryItem) => categoryItem.inputType === category)

                if (categoryFound && categoryFound.salesText) salesText = categoryFound.salesText
            }

            return salesText
        },
        getPublicTextIfExists(contract, category) {
            let publicText = ''
            if (contract && contract.formFields) {
                let categoryFound = contract.formFields.find((categoryItem) => categoryItem.inputType === category)
                if (categoryFound && categoryFound.publicText) publicText = categoryFound.publicText
            }

            return publicText
        },
        getConditionsIfExist(contract) {
            let conditions = null
            if (contract && contract.formFields) {
                let categoryFound = contract.formFields.find((categoryItem) => categoryItem.inputType === 'conditions')
                conditions = categoryFound
            }
            return conditions
        },
        getInternalTitleIfExists(contract, title) {
            let internalTitle = title
            if (contract && contract.internalTitle) internalTitle = contract.internalTitle
            return internalTitle
        },
        async fetchFormFields(force = false) {
            let contract = this.contract !== null && !force ? this.contract : await api.fetchContractSettings()
            console.log('here 1', contract)
            let tempContract = JSON.parse(JSON.stringify(contract))

            if (this.enabledStudios.length > 0) {
                if (contract.memberships) {
                    for (let membershipIndex in contract.memberships) {
                        tempContract.memberships[membershipIndex].studios = contract.memberships[
                            membershipIndex
                        ].studios.filter((ms) => this.enabledStudios.includes(ms.toString()))
                        for (let studioKey of Object.keys(contract.memberships[membershipIndex].studioSettings)) {
                            if (!this.enabledStudios.includes(studioKey.toString())) {
                                delete tempContract.memberships[membershipIndex].studioSettings[studioKey]
                            }
                        }
                    }
                }
            }

            if (this.enabledStudios.length > 0) {
                if (contract.packages) {
                    for (let packageIndex in contract.packages) {
                        tempContract.packages[packageIndex].studios = contract.packages[packageIndex].studios.filter(
                            (ms) => this.enabledStudios.includes(ms.toString())
                        )
                        for (let studioKey of Object.keys(contract.packages[packageIndex].studioSettings)) {
                            if (!this.enabledStudios.includes(studioKey.toString())) {
                                delete tempContract.packages[packageIndex].studioSettings[studioKey]
                            } else {
                                if (tempContract.packages[packageIndex].studioSettings[studioKey].memberships) {
                                    for (let membershipIndex in tempContract.packages[packageIndex].studioSettings[
                                        studioKey
                                    ].memberships) {
                                        tempContract.packages[packageIndex].studioSettings[studioKey].memberships[
                                            membershipIndex
                                        ].studios = tempContract.packages[packageIndex].studioSettings[
                                            studioKey
                                        ].memberships[membershipIndex].studios.filter((ms) =>
                                            this.enabledStudios.includes(ms.toString())
                                        )
                                        for (let studioKeyNew of Object.keys(
                                            tempContract.packages[packageIndex].studioSettings[studioKey].memberships[
                                                membershipIndex
                                            ].studioSettings
                                        )) {
                                            if (!this.enabledStudios.includes(studioKeyNew.toString())) {
                                                delete tempContract.packages[packageIndex].studioSettings[studioKey]
                                                    .memberships[membershipIndex].studioSettings[studioKeyNew]
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.contract = tempContract

            let existingContract = null

            this.formFields = []

            if (this.$route.params.id) {
                this.editingContract = true
                existingContract =
                    this.existingContract !== null
                        ? this.existingContract
                        : await api.fetchContract(this.$route.params.id)

                console.log('here', existingContract, this.formFields)

                if (this.existingContract === null) {
                    if (existingContract && existingContract.formFields) {
                        let memberships = existingContract.formFields.find((e) => e.inputType === 'membership')
                        if (memberships && memberships.inputValue && memberships.inputValue.length > 0) {
                            if (memberships.inputValue[0].flexActive) this.flexContract = true
                        }
                    }

                    if (existingContract && existingContract.enabledStudios) {
                        this.enabledStudios = existingContract.enabledStudios
                    }

                    if (existingContract && existingContract.badge) {
                        this.badge = existingContract.badge
                    }

                    this.existingContract = existingContract
                }
            }

            if (this.existingContract && this.existingContract.useStudioCity)
                this.useStudioCity = this.existingContract.useStudioCity
            this.formFields.push({
                inputValue: 'Mitgliedschaftsvertrag well come FIT AG',
                inputType: 'title',
                identifier: 'Titel',
            })
            if (tempContract.categories) {
                tempContract.categories.forEach((category) =>
                    category.studios.length > 0 ? (category.active = true) : (category.active = false)
                )

                tempContract.categories = tempContract.categories.filter((e) =>
                    e.studios.some((c) => this.enabledStudios.includes(c.toString()))
                )

                this.formFields.push({
                    inputValue: tempContract.categories,
                    inputType: 'category',
                    identifier: 'Mitgliedschaftskategorien',
                    salesText: this.getSalesTextIfExists(existingContract, 'category'),
                    publicText: this.getPublicTextIfExists(existingContract, 'category'),
                })
            }

            if (tempContract.companies) {
                this.formFields.push({
                    inputValue: tempContract.companies,
                    inputType: 'company',
                    identifier: 'Firma - Vertragspartner',
                })
            }

            this.formFields.push({
                inputValue: {
                    firstname: {
                        identifier: 'Vorname',
                        active: true,
                        value: 'Max',
                    },
                    lastname: {
                        identifier: 'Nachname',
                        active: true,
                        value: 'Mustermann',
                    },
                    street: {
                        identifier: 'Strasse',
                        active: true,
                        value: 'Hauptstrasse 3',
                    },
                    city: {
                        identifier: 'PLZ / Ort',
                        active: true,
                        value: '8500 Frauenfeld',
                    },
                    birthday: {
                        identifier: 'Geburtstag',
                        active: true,
                        value: 'Geburtstag',
                    },
                    phone: {
                        identifier: 'Tel. P.',
                        active: true,
                        value: '000 000 00 00',
                    },
                    phoneMobile: {
                        identifier: 'Mobile',
                        active: true,
                        value: '000 000 00 00',
                    },
                    phoneBusiness: {
                        idenfitier: 'Tel. G.',
                        active: true,
                        value: '000 000 00 00',
                    },
                    email: {
                        identifier: 'E-Mail',
                        active: true,
                        value: 'vertrag@wellcomefit.ch',
                    },
                },
                inputType: 'customer',
                identifier: 'Kundeninformationen',
            })

            // Hier hin

            if (tempContract.memberships) {
                this.membershipBackup = JSON.parse(JSON.stringify(tempContract.memberships))
                tempContract.memberships = tempContract.memberships.filter((e) => e.flexActive === this.flexContract)
                tempContract.memberships = tempContract.memberships.filter((e) =>
                    e.studios.some((c) => this.enabledStudios.includes(c.toString()))
                )

                tempContract.memberships.forEach((e) => (e.studios.length > 0 ? (e.active = true) : (e.active = false)))
                this.formFields.push({
                    inputValue: tempContract.memberships,
                    inputType: 'membership',
                    identifier: 'Mitgliedschaften',
                    salesText: this.getSalesTextIfExists(existingContract, 'membership'),
                    publicText: this.getPublicTextIfExists(existingContract, 'membership'),
                })
            }

            if (tempContract.packages) {
                if (this.flexContract)
                    tempContract.packages = tempContract.packages.filter((e) => {
                        for (let studioId in e.studioSettings) {
                            for (let membership of e.studioSettings[studioId].memberships) {
                                if (membership.flexActive) return true
                            }
                        }
                        return false
                    })
                tempContract.packages.forEach((e) => (e.studios.length > 0 ? (e.active = true) : (e.active = false)))

                tempContract.packages = tempContract.packages.filter((e) =>
                    e.studios.some((c) => this.enabledStudios.includes(c.toString()))
                )

                this.formFields.push({
                    inputValue: tempContract.packages,
                    inputType: 'packages',
                    identifier: 'Pakete',
                    salesText: this.getSalesTextIfExists(existingContract, 'packages'),
                    publicText: this.getPublicTextIfExists(existingContract, 'packages'),
                })
            }

            if (tempContract.paymentOptions) {
                tempContract.paymentOptions.forEach((e) =>
                    e.studios.length > 0 ? (e.active = true) : (e.active = false)
                )
                tempContract.paymentOptions = tempContract.paymentOptions.filter((e) =>
                    e.studios.some((c) => this.enabledStudios.includes(c.toString()))
                )
                this.formFields.push({
                    inputValue: tempContract.paymentOptions,
                    inputType: 'paymentOptions',
                    identifier: 'Zahlungsbedingungen',
                })
            }

            let conditions = this.getConditionsIfExist(existingContract)

            if (conditions) {
                this.formFields.push(conditions)
            } else {
                this.formFields.push({
                    inputValue: [
                        {
                            inputValue: '',
                            highlight: false,
                        },
                    ],
                    inputType: 'conditions',
                    identifier: 'Mitgliedschaftsbedingungen',
                })
            }
            this.internalTitle = this.getInternalTitleIfExists(existingContract, this.internalTitle)
            /* */
            console.log(existingContract)
            if (existingContract && Object.keys(existingContract).includes('eMails')) {
                this.eMails = existingContract.eMails
                this.membershipStudios.forEach((e) => {
                    if (!Object.keys(this.eMails).includes(e)) this.eMails[e] = ''
                })
            } else {
                this.membershipStudios.forEach((e) => {
                    this.eMails[e] = ''
                })
            }
            if (existingContract && Object.keys(existingContract).includes('promotions'))
                this.promotions = existingContract.promotions
            if (existingContract && Object.keys(existingContract).includes('preConditionText'))
                this.preConditionText = existingContract.preConditionText
            if (existingContract && Object.keys(existingContract).includes('postConditionText'))
                this.postConditionText = existingContract.postConditionText

            if (this.$route.params.id) {
                if (existingContract && existingContract.formFields) {
                    let memberships = existingContract.formFields.find((e) => e.inputType === 'membership')
                    if (memberships && memberships.inputValue && memberships.inputValue.length > 0) {
                        let names = memberships.inputValue.map((e) => e.title)
                        let membershipsContract = this.formFields.find((e) => e.inputType === 'membership')

                        if (membershipsContract && membershipsContract.inputValue) {
                            membershipsContract.inputValue.forEach((e) => {
                                e.active = names.includes(e.title)
                            })
                        }
                    }

                    let paymentOptions = existingContract.formFields.find((e) => e.inputType === 'paymentOptions')
                    if (paymentOptions && paymentOptions.inputValue && paymentOptions.inputValue.length > 0) {
                        let namesOptions = paymentOptions.inputValue.map((e) => e.title)
                        let paymentOptionsContract = this.formFields.find((e) => e.inputType === 'paymentOptions')

                        if (paymentOptionsContract && paymentOptionsContract.inputValue) {
                            paymentOptionsContract.inputValue.forEach((e) => {
                                e.active = namesOptions.includes(e.title)
                            })
                        }
                    }

                    let categoryOptions = existingContract.formFields.find((e) => e.inputType === 'category')
                    if (categoryOptions && categoryOptions.inputValue && categoryOptions.inputValue.length > 0) {
                        let namesCategory = categoryOptions.inputValue.map((e) => e.title)
                        let categoryOptionsContract = this.formFields.find((e) => e.inputType === 'category')

                        if (categoryOptionsContract && categoryOptionsContract.inputValue) {
                            categoryOptionsContract.inputValue.forEach((e) => {
                                e.active = namesCategory.includes(e.title)
                            })
                        }
                    }

                    let titleOptions = existingContract.formFields.find((e) => e.inputType === 'title')
                    if (titleOptions && titleOptions.inputValue) {
                        let titleOptionsContract = this.formFields.find((e) => e.inputType === 'title')
                        titleOptionsContract.inputValue = titleOptions.inputValue
                    }
                }
            }
        },
        async createContract() {
            let contract = JSON.parse(JSON.stringify(this.formFields))
            console.log(contract)

            contract.forEach((e) => {
                if (e.inputType === 'membership' || e.inputType === 'packages' || e.inputType === 'paymentOptions') {
                    e.inputValue = e.inputValue.filter((e) => e.active)
                }
            })

            console.log(this.useStudioCity, 'HEREHREHRE')

            let status = await api.createContract(
                contract,
                this.internalTitle,
                this.eMails,
                this.promotions,
                this.enabledStudios,
                this.preConditionText,
                this.postConditionText,
                this.useStudioCity,
                this.badge
            )

            if (status) {
                if (this.editingContract) {
                    this.$toast.success('Kopie wurde erstellt')
                } else {
                    this.$toast.success('Vertrag wurde erstellt')
                }
                this.$router.push('/dashboard/contract')
            }
        },
    },
    computed: {
        membershipStudios() {
            let studios = []
            this.formFields.forEach((e) => {
                if (e.inputType === 'membership') {
                    e.inputValue.forEach((e) => {
                        if (e.active)
                            e.studios.forEach((e) => {
                                if (!studios.includes(e)) studios.push(e)
                            })
                    })
                }
            })
            return studios
        },
        membershipBackupStudios() {
            let studios = []

            this.membershipBackup.forEach((e) => {
                e.studios.forEach((e) => {
                    if (!studios.includes(e)) studios.push(e)
                })
            })

            return studios
        },
    },

    watch: {
        enabledStudios() {
            this.fetchFormFields(true)
        },
        flexContract() {
            this.fetchFormFields(true)
        },
    },
}
</script>

<style lang="scss" scoped>
.flex {
    color: orange;
}
.noStudio {
    color: red;
}
</style>
