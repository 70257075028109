<template>
    <div>
        <v-row v-if="filteredHabbitList.length">
            <v-col cols="2" class="d-flex flex-column py-0">
                <v-img src="/img/lottiefiles/sunset.png"></v-img>
                <div style="flex: 1 1 auto; height: 100%; width: 50%; border-right: 2px dashed lightgray"></div>
                <v-img @click="test" src="/img/lottiefiles/moon.png"></v-img>
            </v-col>

            <v-col cols="9">
                <swipe-list class="my-2" ref="list" :items="filteredHabbitList" item-key="id">
                    <template v-slot="{ item, index }">
                        <v-card
                            v-if="item"
                            style="width: 98%; margin-left: 1%; margin-right: 1%"
                            class="rounded-lg d-flex flex-row my-2"
                            :class="{ habitCompleted: item.completed, habitDelayed: item.delayed }"
                            @click="setHabitInExecution(item)"
                        >
                            <v-col cols="3" class="d-flex flex-column pa-2">
                                <v-img contain height="52px" :src="'/img/lottiefiles/' + item.icon"></v-img>
                            </v-col>
                            <v-col v-if="item && item.label" cols="7" class="d-flex flex-column self-center">
                                <span v-if="!item.workout">{{ item.label }}</span>
                                <span v-if="item.amount">
                                    {{ getExecutionsForHabit(item) || 0 }} von {{ item.amount }}
                                    {{ getHabitAmountEnding(item) }}
                                </span>
                                <span v-else-if="item.workout && item.workout.form">{{ item.workout.form.title }}</span>
                                <span v-else-if="item.time">
                                    {{ item.time }}
                                </span>
                            </v-col>

                            <v-col v-if="getExecutionsForHabit(item) >= item.amount" cols="2" class="py-0">
                                <!--
{{ habit.streak }}
🔥
						-->
                                <v-img src="/img/lottiefiles/streakMedal.png" width="45" height="45"></v-img>
                            </v-col>
                        </v-card>
                        <!--	<v-col v-if="index === habitsList.length - 2 || habitsList.length < 2" cols="12">
					<habit-create-button class="py-2"></habit-create-button>
				</v-col> -->
                    </template>
                    <template v-slot:left="{ item, close }" class="mr-1">
                        <div class="red my-3 d-flex flex-column rounded mr-1" @click="deleteHabit(item)">
                            <v-icon x-large color="white">mdi-delete</v-icon>
                            <span class="px-1 white--text">Löschen</span>
                        </div>
                        <div class="blue my-3 d-flex flex-column mr-1 rounded" @click="editHabit(item, close)">
                            <v-icon x-large color="white">mdi-pencil-circle</v-icon>
                            <span class="px-1 white--text">Bearbeiten</span>
                        </div>
                    </template>
                    <template v-slot:right="{ item }" class="ml-1">
                        <div class="primary my-3 d-flex flex-column ml-1 rounded" @click="habitInStatistic = item">
                            <v-icon x-large color="white">mdi-chart-timeline-variant-shimmer</v-icon>
                            <span class="px-1 white--text">Statistik</span>
                        </div>
                    </template>
                </swipe-list>
            </v-col>
            <v-col cols="12">
                <habit-create-button class="py-2"></habit-create-button>
            </v-col>
        </v-row>

        <v-row v-else class="d-flex justify-center pt-5">
            <v-col cols="12" class="d-flex flex-column justify-center align-items-center">
                <v-card-title class="justify-center">Keine Gewohnheiten vorhanden</v-card-title>
                <v-card-text class="text-center">Füge jetzt deine erste Gewohnheit hinzu.</v-card-text>
                <habit-create-button></habit-create-button>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <v-img height="150" src="/img/lottiefiles/empty.gif"></v-img>
            </v-col>
        </v-row>

        <!-- Edit Habit -->
        <habit-edit v-if="habitInEdit" :selectedHabit="habitInEdit" @closeEdit="habitInEdit = null"></habit-edit>

        <!-- All Execution Forms HERE  -->
        <habit-drink-execution
            v-if="habitInExecution && habitInExecution.type === 'drinkHabit'"
            :date="date"
            :habit="habitInExecution"
            @closeExecutionForm="habitInExecution = null"
        ></habit-drink-execution>
        <habit-wake-up-execution
            v-if="habitInExecution && habitInExecution.type === 'wakeUpHabit'"
            :date="date"
            :habit="habitInExecution"
            @closeExecutionForm="habitInExecution = null"
        ></habit-wake-up-execution>

        <!-- Habit Statistic -->
        <habit-statistic
            v-if="habitInStatistic"
            :selectedHabit="habitInStatistic"
            @closeStatistic="habitInStatistic = null"
        ></habit-statistic>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitEdit from './habitEdit.vue'
import habitStatistic from './habitStatistic.vue'
import habitCreateButton from './habitCreateButton.vue'
import habitDrinkExecution from './habitDrinkExecution.vue'
import habitWakeUpExecution from './habitWakeUpExecution.vue'
import { SwipeList, SwipeOut } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'

export default {
    name: 'habitHomeList',
    components: {
        habitEdit,
        habitCreateButton,
        habitDrinkExecution,
        habitWakeUpExecution,
        habitStatistic,
        SwipeList,
        SwipeOut,
    },
    props: {
        date: {
            type: Object,
            default: '',
            required: true,
        },
        activeChip: {
            type: Number,
            default: '',
            required: false,
        },
    },
    data() {
        return {
            habitInExecution: null,
            habitInEdit: null,
            habitInStatistic: null,
            habitsList: [],
        }
    },
    mounted() {},

    async created() {
        this.loadHabitsList()
    },
    computed: {
        filteredHabbitList() {
            return this.habitsList.map((habit) => {
                if (habit.time) {
                    let execution = habit.executions[this.date.format('YYYY-MM-DD')]
                    let time = habit.time || habit
                    console.log('time', time, habit.time, habit, execution)
                    if (execution) {
                        console.log(time)
                        if (this.$moment(execution, 'HH:mm').diff(this.$moment(time, 'HH:mm'), 'minutes', true) <= 15) {
                            habit.completed = true
                            console.log('doo ischer', habit.completed)
                        } else {
                            habit.delayed = true
                        }
                    } else if (this.$moment().diff(this.$moment(habit.time, 'HH:mm'), 'minutes', true) > 15) {
                        habit.delayed = true
                    }
                    /*		habit.completed =
						habit.executions.length &&
						habit.executions[0].createdAt &&
						this.$moment(habit.executions[0].createdAt).isSame(this.date, 'day')
			*/
                }
                if (this.activeChip !== 5 && habit.type !== 'drinkHabit') {
                    return null
                }
                return habit
            })
        },
    },
    methods: {
        test() {
            console.log(this.filteredHabbitList)
        },
        setHabitInExecution(habit) {
            if (habit.type === 'workoutHabit') this.$router.push('/plan/run/' + habit.workout._id)

            console.log(habit.type)
            this.habitInExecution = habit
        },
        editHabit(habit, close) {
            this.habitInEdit = habit
            close()
        },
        async deleteHabit(habit) {
            let deleted = null
            if (confirm('Willst du diese Gewohnheit unwiederruflich löschen?')) deleted = await habit.delete()
            if (deleted) this.habitsList = this.habitsList.filter((h) => h._id !== habit._id)
        },
        async loadHabitsList() {
            this.habitsList = await habitService.getHabits(this.date)
            this.$emit('finishedLoading')
        },
        getHabitAmountEnding(habit) {
            if (habit.type === 'drinkHabit') {
                return 'Liter'
            }
        },
        getExecutionsForHabit(habit) {
            let amount = 0

            if (!habit.executions || !habit.executions[this.date.format('YYYY-MM-DD')]) return
            let execution = habit.executions[this.date.format('YYYY-MM-DD')]
            if (Array.isArray(execution)) {
                execution.forEach((execution) => {
                    amount += execution.amount
                })
                if (amount >= habit.amount) {
                    this.habitsList.find((habitItem) => habitItem._id === habit._id).completed = true
                }
            }
            return amount.toFixed(2)
        },
    },
    watch: {
        date() {
            this.loadHabitsList()
        },
    },
}
</script>

<style lang="scss" scoped></style>
