<template>
	<div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Übungsschritte</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="exercise-steps-wrapper">
						<div v-for="(es, counter) of exercise_steps" :key="counter" class="exercise-step">
							<label class="titel">
								{{ counter + 1 }}. Schritt<span class="remove-step" @click="removeStep(counter)">entfernen</span></label
							>
							<label>Beschreibung</label>
							<textarea v-model="es.beschreibung"> </textarea>
							<label class="bild-upload-edit" :for="'bild-upload-edit-step-' + counter">Foto hochladen</label>
							<input
								class="bild-upload-edit"
								type="file"
								accept="image/*"
								@change="editImage($event, counter)"
								:id="'bild-upload-edit-step-' + counter"
							/>
							<img v-show="es.bild" class="uploaded-image exercise-step-image" :src="'/img/uebungen/' + es.bild" />
						</div>
						<div @click="newStep()" class="new-step">Neuen Schritt hinzufügen</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary" @click.prevent="saveSettings">
						Speichern
					</button>

					<button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">
						Abbrechen
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import api from '../api'

export default {
	data() {
		return {
			exercise_steps: [],
			exercise_step_new: {
				exercise_id: null,
				old_id: null,
				beschreibung: '',
				bild: null,
				sort_order: null,
				main_photo: false,
				studio_id: null,
			},
			studio: null,
		}
	},
	methods: {
		removeStep(counter) {
			let exercise_steps_tmp = []
			let c = 0
			for (let exs of this.exercise_steps) {
				console.log('test')
				if (c != counter) {
					exercise_steps_tmp.push(exs)
				}
				c++
			}
			this.exercise_steps = exercise_steps_tmp
		},
		newStep() {
			this.exercise_steps.push({
				exercise_id: this.uebung.uebung.old_id,
				old_id: null,
				beschreibung: '',
				bild: '',
				sort_order: null,
				main_photo: false,
				studio_id: this.studio._id,
			})
		},
		editImage(event, counter) {
			console.log(event)
			this.bild = 'test'

			if (event.target.files[0]) {
				this.bild = 'test'
				let uploadimg = {}
				uploadimg.art = 'uebung'
				const formData = new FormData()
				let file = event.target.files[0]

				formData.append('file', event.target.files[0])

				uploadimg.formData = formData
				console.log('editing')
				api.uploadImage(uploadimg)
					.then((data) => {
						if (data.status) {
							this.exercise_steps[counter].bild = 'uploaded/' + data.name
						}
					})
					.catch((error) => {
						console.log(error)
					})
			}
		},
		async show(uebung) {
			this.studio = null

			this.studio = await api.getUserStudio().catch((e) => {
				window.app.$root.$children[0].checkStudio()
			})
			this.studio = this.studio.studio
			let studio_id = this.studio._id
			this.uebung = uebung
			let senddata = {
				old_id: uebung.uebung.old_id,
				studio_id,
			}
			api.getExerciseSteps(senddata)
				.then((data) => {
					this.exercise_steps = data
					console.log(data)
				})
				.catch((error) => {
					console.log(error)
				})
			$(this.$refs['modal']).modal('show')
		},
		hide() {
			$(this.$refs['modal']).modal('hide')
		},
		saveSettings() {
			let studio_id = this.studio._id
			api.SaveExerciseSteps({ exercise_steps: this.exercise_steps, exercise_id: this.uebung.uebung.old_id, studio_id })
				.then((data) => {
					if (data.status == 1) {
						this.hide()
					}
				})
				.catch((error) => {
					console.log(error)
					alert('fehler')
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.new-step {
	width: 100%;
	text-align: center;
	height: 50px;
	border-radius: 5px;
	line-height: 50px;
	background-color: var(--v-primary);
	color: white;
}
.remove-step {
	color: red;
	font-weight: 300;
	font-size: 13px;
	line-height: 30px;
	text-decoration: underline;
}
label.bild-upload-edit {
	border: 1px solid #ced4da;
	color: #495057;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 5px 20px;
	border-radius: 0.25rem;
}
input.bild-upload-edit {
	display: block;
	visibility: hidden;
	height: 0px;
	width: 0px;
}
label {
	width: 100%;
}

input,
textarea {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	margin-top: 5px;
	margin-bottom: 15px;
}
textarea {
	height: 80px;
}

.titel {
	font-size: 20px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
}
.uploaded-image {
	max-height: 200px;
	margin: 20px auto;
	display: block;
}
.modal-content {
	height: 90vh;
}
.modal-body {
	overflow: scroll;
}
</style>
