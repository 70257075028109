<template>
	<div>
		<v-tabs v-model="tab" centered color="primary">
			<v-tab key="display">
				Bildschirme verwalten
			</v-tab>
			<v-tab key="gallery">
				Galerien verwalten
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item key="display" class="fill-height">
				<v-card class="fitness-center-display-form__card rounded-xl ma-auto d-flex flex-column justify-space-between">
					<v-card-text>
						<v-select
							:items="studioFilterItems"
							item-value="_id"
							item-text="name"
							v-model="selectedStudioFilter"
							no-data="Es wurden noch keine Display erstellt."
						></v-select>

						<v-row class="justify-center" v-if="filteredDisplays">
							<v-col class="mx-auto" cols="auto" v-for="(display, i) in filteredDisplays" :key="i">
								<v-card>
									<v-card-title primary-title>
										{{ display.title }}
									</v-card-title>
									<v-card-actions class="justify-center">
										<v-btn color="primary" :disabled="!display.status" @click="editDisplay(display)">
											{{ display.status ? 'Bearbeiten' : 'Inaktiv' }}
										</v-btn>
										<!--      <v-btn color="primary" @click="copyLink" outlined
                      >Link kopieren
                      <lottie-player
							mode="normal" width="32px" height="32px" class="fitness-center-diplay-form__lottie" name="deletePersonIcon" loop autoplay  src="/img/lottiefiles/link.json"></lottie-player>
                    </v-btn>
                    <input id="link-value" class="link-value" :value="link(display._id)" />
                  -->
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
					<!-- <v-card-actions class="justify-center"
            ><v-btn outlined color="primary" @click="newDisplayForm"> Neues Display hinzufügen <v-icon color="primary">mdi-plus</v-icon></v-btn>
          </v-card-actions> -->
				</v-card>
			</v-tab-item>

			<v-tab-item key="gallery">
				<v-card class="fitness-center-display-form__card rounded-xl ma-auto mt-5 d-flex flex-column justify-space-between">
					<v-card-text>
						<v-row class="justify-center">
							<v-col class="mx-auto" cols="auto" v-for="(gallery, i) in galleries" :key="i">
								<v-card>
									<v-card-title class="primary--text">
										<span>{{ gallery.title }}</span>
										<v-btn icon color="red" @click="deleteGallery(gallery._id)"><v-icon>mdi-delete</v-icon></v-btn>
									</v-card-title>

									<v-card-actions class="justify-center">
										<v-btn color="primary" @click="editGallery(gallery)">Bearbeiten</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-center"
						><v-btn outlined color="primary" @click="newGalleryForm">
							Neue Galerie hinzufügen <v-icon color="primary">mdi-plus</v-icon></v-btn
						>
					</v-card-actions>
				</v-card>
			</v-tab-item>
		</v-tabs-items>

		<!-- Dialog for Displays -->
		<v-dialog :fullscreen="$vuetify.breakpoint.mobile" class="overflow-hidden" width="700" :value="displayFormActive" persistent>
			<v-form class="fill-height" ref="form" v-if="form && displayFormActive">
				<v-card class="fill-height d-flex flex-column justify-space-between">
					<v-card-title class="mt-5">
						<v-text-field label="Titel" color="primary" class="h5 font-weight-bold uppercase" v-model="form.title"></v-text-field>
					</v-card-title>
					<v-card-text>
						<v-select label="Studio" :items="formStudios" item-value="_id" item-text="name" v-model="form.studio._id"></v-select>
					</v-card-text>
					<v-card-text>
						<v-select
							label="Galerie auswählen"
							:items="galleries"
							item-value="_id"
							item-text="title"
							v-model="form.gallery_id"
						></v-select>
					</v-card-text>
					<v-card-actions class="justify-center">
						<v-btn color="grey" outlined @click="abortForm">Abbrechen</v-btn>
						<v-btn color="primary" @click="saveDisplay"> {{ display.createActive ? 'Erstellen' : 'Speichern' }} </v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!--  Dialog for Galleries -->
		<v-dialog :fullscreen="$vuetify.breakpoint.mobile" class="overflow-hidden" width="700" :value="galleryFormActive" persistent>
			<v-form class="fill-height" ref="form" v-if="form && galleryFormActive">
				<v-card class="fill-height d-flex flex-column justify-space-between">
					<v-card-title class="mt-5">
						<v-text-field label="Titel" color="primary" class="h5 font-weight-bold uppercase" v-model="form.title"></v-text-field>
					</v-card-title>
					<v-card-actions class="justify-center"
						><v-btn @click="sortable = !sortable" color="primary">
							{{ sortable ? 'Sortierung beibehalten' : 'Sortierung ändern' }}
						</v-btn></v-card-actions
					>
					<v-card-text>
						<vue-file-agent
							v-model="form.gallery_images"
							@delete="removeImageFromGallery($event)"
							:sortable="sortable"
							:meta="false"
							uploadUrl="/api/gallery/images"
							:accept="'image/*,video/*'"
							deletable
						></vue-file-agent>
					</v-card-text>
					<v-card-actions class="justify-center">
						<v-btn color="grey" outlined @click="abortForm">Abbrechen</v-btn>
						<v-btn color="primary" @click="saveGallery">{{ gallery.createActive ? 'Erstellen' : 'Speichern' }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapActions } from 'vuex'

export default {
	name: 'fitnessCenterDisplay',
	data() {
		return {
			userService,
			studioFilterItems: [],
			formStudios: null,
			selectedStudioFilter: null,
			form: null,
			galleries: [],
			deletedImages: [],
			sortable: false,
			tab: 1,
			display: {
				createActive: false,
				editingActive: false,
			},
			gallery: {
				createActive: false,
				editingActive: false,
			},
			displays: [],
		}
	},
	async created() {
		await this.initializeGalleries()
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility']),
		async initializeGalleries() {
			this.displays = await api.fetchDisplays()
			this.galleries = await api.fetchGalleries()
			this.formStudios = await api.getStudios()

			if (this.displays) {
				this.displays.forEach((display) => {
					!this.studioFilterItems.find((studio) => studio._id === display.studio._id) && this.studioFilterItems.push(display.studio)
				})
			}
			if (this.studioFilterItems.length) {
				this.selectedStudioFilter = this.studioFilterItems[0]._id
			}
		},
		/* newDisplayForm() {
      this.form = {
        title: '',
        studio: {
          _id: null,
        },
        gallery_id: null,
      }
      this.display.createActive = true
    }, */
		newGalleryForm() {
			this.setNavigationbarVisibility(false)
			this.form = {
				title: '',
				gallery_images: [],
			}
			this.gallery.createActive = true
		},
		// link(id) {
		// return 'app.wellcomefit.ch/centerdisplay/' + id
		// },
		editGallery(gallery) {
			this.setNavigationbarVisibility(false)
			this.form = JSON.parse(JSON.stringify(gallery))
			this.form.gallery_images.forEach((image) => {
				image.url = '/img/' + image.url
			})

			this.gallery.editingActive = true
		},
		saveGallery() {
			this.setNavigationbarVisibility(true)
			if (this.gallery.createActive) {
				if (this.form.gallery_images) {
					this.form.gallery_images = this.form.gallery_images.map((image) => {
						return { _id: image.upload.data._id }
					})
				}
				api.createGallery(this.form).then((data) => {
					this.galleries.push(data)
					this.abortForm()
				})
			} else {
				this.form.gallery_images = this.form.gallery_images.map((image) => {
					return image && image.upload && 'data' in image.upload && image.upload.data && '_id' in image.upload.data
						? { _id: image.upload.data._id }
						: { _id: image._id }
				})

				api.updateGallery(this.form).then((galleryId) => {
					if (this.deletedImages.length) {
						this.deletedImages.forEach((image) => {
							api.deleteGalleryImage(image)
						})
					}
					this.galleries.forEach((gallery) => {
						if (gallery._id === galleryId) {
							gallery.title = this.form.title
							gallery.gallery_images = this.form.gallery_images
							this.abortForm()
						}
					})
				})
			}
		},
		deleteGallery(id) {
			api.deleteGallery(id).then((data) => {
				if (data.deletedCount === 1) {
					this.galleries = this.galleries.filter((gallery) => gallery._id !== id)
				}
			})
		},
		removeImageFromGallery(event) {
			this.deletedImages.push(event)
		},
		/* copyLink() {
      let copyText = document.getElementById('link-value')
      copyText.select()
      copyText.setSelectionRange(0, 99999)

      document.execCommand('copy')
    }, */
		abortForm() {
			this.setNavigationbarVisibility(false)
			this.display.createActive = false
			this.display.editingActive = false
			this.gallery.createActive = false
			this.gallery.editingActive = false
			this.form = null
			this.initializeGalleries()
		},
		saveDisplay() {
			this.display.createActive
				? api.createDisplay(this.form).then((data) => {
						this.galleries.push(data)
						this.abortForm()
				  })
				: api.updateDisplay(this.form).then((displayId) => {
						this.displays.forEach((display) => {
							if (display._id === displayId) {
								display.title = this.form.title
								this.abortForm()
							}
						})
				  })
		},
		editDisplay(display) {
			this.form = JSON.parse(JSON.stringify(display))
			this.display.editingActive = true
		},
	},
	computed: {
		displayFormActive() {
			return this.display.editingActive || this.display.createActive
		},
		galleryFormActive() {
			return this.gallery.editingActive || this.gallery.createActive
		},
		filteredDisplays() {
			return this.displays.filter((display) => display.studio._id === this.selectedStudioFilter)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'md-and-up')} {
	::v-deep .v-dialog--active {
		border-radius: 24px;
	}
}
::v-deep .v-dialog--active {
	overflow-x: hidden;
	background: white;
}

::v-deep .v-tabs-items {
	overflow: unset !important;
}

.fitness-center-display-form {
	&__lottie {
		display: block;
	}

	&__card {
		width: 90%;
		max-width: 1000px;
		height: 80vh;
	}
}
.link-value {
	position: absolute;
	left: -9999px;
}
</style>
