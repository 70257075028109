<template>
    <div id="membercard">
        <div class="anmelden">
            <span>Membercard ID</span>
            <div class="search"><input class="suche" v-model="id" /><span @click="anmelden()">Anmelden</span></div>
        </div>

        <div v-show="showKunde" class="user-infos white--text">
            <span class="datum white--text"
                >Startdatum: {{ getStartDate(kunde.tendays_date) }} <br />
                Aktueller Tag: {{ getDay(kunde.tendays_date) }}</span
            >
            <span class="name white--text">{{ kunde.vorname }} {{ kunde.nachname }}</span>
            <div class="days white--text">
                <span class="lbel white--text">Trainingstage: </span>

                <div v-for="(day, i) in kunde.days" :key="i" class="white--text days_1">
                    <span class="day_counter white--text">Tag {{ i }} ({{ getDate(i, kunde.tendays_date) }}): </span
                    ><span v-if="day" class="trained white--text">Trainiert</span
                    ><span class="trained" v-else>Nicht Trainiert</span>
                </div>
            </div>
        </div>
        <v-data-table
            dark
            :headers="headers"
            :items="tableItems"
            no-data-text="Keine Membercards gefunden"
            :items-per-page="20"
            :search="search"
            class="elevation-1 mt-2">
            <template v-slot:top>
                <v-text-field label="Suchen" v-model="search" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn v-if="!item.abo" text color="primary" @click="loeseAbo(item)">Lösen</v-btn>
                <v-btn v-else disabled text color="primary">
                    <v-icon small color="primary"> mdi-check </v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import api from '../../../api'
export default {
    data() {
        return {
            id: '',
            search: '',
            showKunde: false,
            kunde: {},
            items: [],
            headers: [
                {
                    text: 'Datum',
                    value: 'date',
                },
                {
                    text: 'Tag',
                    value: 'day',
                },
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'Telefon',
                    value: 'telefon',
                },
                {
                    text: 'Email',
                    value: 'email',
                },
                {
                    text: 'Abo Lösen',
                    value: 'actions',
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        tableItems() {
            return this.items.map((item) => {
                console.log(item)
                item.date = this.$moment(item.abo_anfrage_datum * 1000).format('DD.MM.YYYY HH:mm')
                item.day = Math.ceil((item.abo_anfrage_datum - item.tendays_date) / 86400)
                item.name = item.vorname + ' ' + item.nachname
                item.abo_geloest = item.abo ? 'JA' : 'NEIN'
                return item
            })
        },
    },
    methods: {
        loeseAbo(a) {
            if (confirm('Wurde das Abo bereits von dem Kunden unterschrieben? (OK für bereits unterschrieben)')) {
                api.aboLoesen(a._id).then((data) => {
                    this.$toast.success('Abo erfolgreich gelöst')
                    if (data.status) {
                        this.fetchData()
                    }
                })
            }
        },
        getDay(date, bdate) {
            return Math.ceil((bdate - date) / 86400)
        },

        getDate(tag, startdatum) {
            return new Date((startdatum + (tag - 1) * 86400) * 1000).toLocaleDateString()
        },
        getStartDate(date) {
            return new Date(date * 1000).toLocaleDateString()
        },
        getDay(date) {
            return Math.ceil(Math.round(new Date() / 1000 - date) / 86400)
        },
        anmelden() {
            api.td_anmelden(this.id).then((data) => {
                this.kunde = data
            })
            this.kunde._id = this.id
            this.showKunde = true
        },
        async fetchData() {
            this.items = await api.td_aboAnfragen()
        },
    },
    async created() {
        this.fetchData()
        this.$emit('finishedloading')
    },
}
</script>
<style lang="scss" scoped>
#membercard {
    min-height: calc(100vh - 91px);
    position: relative;
    margin-bottom: 100px;

    .user-infos {
        padding: 10px 15px;

        .name,
        .datum {
            width: 100%;
            display: flex;
        }
        .name {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .anmelden {
        display: flex;
        flex-direction: column;
        background-color: var(--v-primary);
        padding: 10px 15px;
        margin-top: 1px;
        > span {
            color: white;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .search {
            display: flex;
            flex-direction: row;
            input {
                width: 60%;
                background-color: white !important;
                color: #313131;
                padding: 0px 10px;
            }
            span {
                color: white;
                text-align: center;
                padding: 5px 0px;
                background-color: #313131;
                width: 40%;
            }
        }
    }
    .action-wrapper {
        position: absolute;
        bottom: 100px;
        display: flex;
        flex-direction: column;
        width: 100%;
        div {
            width: 100%;
            height: 65px;
            line-height: 65px;
            text-align: center;
            font-weight: bold;
            background-color: var(--v-primary);
            color: white;

            &.download-list {
                margin-bottom: 1px;
            }
        }
    }
}
</style>
