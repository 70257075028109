<template>
    <div>
        <swipe-list ref="list" :items="filteredHabbitList" item-key="id">
            <template v-slot="{ item, index }">
                <v-card
                    v-if="item"
                    style="width: 98%; margin-left: 1%; margin-right: 1%"
                    class="rounded-lg d-flex flex-row my-2"
                    :class="{ habitCompleted: item.completed, habitDelayed: item.delayed }"
                    @click="setHabitInExecution(item)"
                >
                    <v-col cols="3" class="d-flex flex-column pa-2">
                        <v-img contain height="52px" :src="'/img/lottiefiles/' + item.icon"></v-img>
                    </v-col>
                    <v-col v-if="item && item.label" cols="7" class="d-flex pa-0 flex-column justify-content-center">
                        <span class="font-weight-bold" v-if="!item.workout">{{ item.label }}</span>
                        <span v-if="item.amount">
                            {{ getExecutionsForHabit(item) || 0 }} von {{ item.amount }}
                            {{ getHabitAmountEnding(item) }}
                        </span>
                        <span v-else-if="item.workout && item.workout.form">{{ item.workout.form.title }}</span>
                        <span v-else-if="item.time">
                            {{ item.time }}
                        </span>
                    </v-col>

                    <v-col v-if="getExecutionsForHabit(item, true)" cols="2" class="pa-0 text-right">
                        <span class="white--text font-weight-bold">{{ getExecutionsForHabit(item, true) }}</span>
                        <v-icon color="orange">mdi-fire</v-icon>

                        <v-img v-if="false" src="/img/lottiefiles/streakMedal.png" width="45" height="45"></v-img>
                    </v-col>
                </v-card>
                <!--	<v-col v-if="index === habitsList.length - 2 || habitsList.length < 2" cols="12">
					<habit-create-button class="py-2"></habit-create-button>
				</v-col> -->
            </template>
            <!-- <template v-slot:left="{ item, close }" class="mr-1">
                <div class="red my-3 d-flex flex-column rounded mr-1" @click="deleteHabit(item)">
                    <v-icon x-large color="white">mdi-delete</v-icon>
                    <span class="px-1 white--text">Löschen</span>
                </div>
                <div class="blue my-3 d-flex flex-column mr-1 rounded" @click="editHabit(item, close)">
                    <v-icon x-large color="white">mdi-pencil-circle</v-icon>
                    <span class="px-1 white--text">Bearbeiten</span>
                </div>
            </template> -->
            <template v-slot:right="{ item }" class="ml-1">
                <div v-if="false" class="primary my-3 d-flex flex-column ml-1 rounded" @click="habitInStatistic = item">
                    <v-icon x-large color="white">mdi-chart-timeline-variant-shimmer</v-icon>
                    <span class="px-1 white--text">Statistik</span>
                </div>
                <div class="red my-3 d-flex flex-column rounded mr-1" @click="deleteHabit(item)">
                    <v-icon x-large color="white">mdi-delete</v-icon>
                    <span class="px-1 white--text">Löschen</span>
                </div>
            </template>
        </swipe-list>
        <div style="position: absolute">
            <habit-edit v-if="habitInEdit" :selectedHabit="habitInEdit" @closeEdit="closeEdit"></habit-edit>

            <habit-drink-execution
                v-if="habitInExecution && habitInExecution.type === 'drinkHabit'"
                :date="date"
                :habit="habitInExecution"
                @closeExecutionForm="habitInExecution = null"
            ></habit-drink-execution>
            <habit-wake-up-execution
                v-if="habitInExecution && habitInExecution.type === 'wakeUpHabit'"
                :date="date"
                :habit="habitInExecution"
                @closeExecutionForm="habitInExecution = null"
            ></habit-wake-up-execution>

            <habit-form-update-execution
                v-if="habitInExecution && habitInExecution.type === 'formUpdateHabit'"
                :date="date"
                :habit="habitInExecution"
                @closeExecutionForm="habitInExecution = null"
            ></habit-form-update-execution>

            <habit-statistic
                v-if="habitInStatistic"
                :selectedHabit="habitInStatistic"
                @closeStatistic="habitInStatistic = null"
            ></habit-statistic>
        </div>
    </div>
    <!-- Edit Habit 
		
	-->
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitEdit from './habitEdit.vue'
import habitStatistic from './habitStatistic.vue'
import habitCreateButton from './habitCreateButton.vue'
import habitDrinkExecution from './habitDrinkExecution.vue'
import habitFormUpdateExecution from './habitFormUpdateExecution.vue'
import habitWakeUpExecution from './habitWakeUpExecution.vue'
import { SwipeList, SwipeOut } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'

export default {
    name: 'habitHomeList',
    components: {
        habitEdit,
        habitCreateButton,
        habitDrinkExecution,
        habitWakeUpExecution,
        habitFormUpdateExecution,
        habitStatistic,
        SwipeList,
        SwipeOut,
    },
    props: {
        selectedExecution: {},
        dateProp: {
            required: true,
        },
        activeChip: {
            type: Number,
            default: 0,
            required: false,
        },
        habitsList: {},
    },
    data() {
        return {
            habitInExecution: null,
            habitInEdit: null,
            habitInStatistic: null,
        }
    },
    watch: {
        selectedExecution(newVal) {
            this.habitInExecution = newVal
        },
    },
    computed: {
        date() {
            return this.$moment(this.dateProp, 'DD-MM-YYYY')
        },
        filteredHabbitList() {
            if (!this.habitsList) return []
            return this.habitsList.map((habit) => {
                if (habit.time) {
                    let execution = habit.executions[this.date.format('YYYY-MM-DD')]
                    console.log(habit.executions, this.date.format('YYYY-MM-DD'))
                    let time = habit.time || habit
                    if (execution) {
                        console.log(time)
                        if (this.$moment(execution, 'HH:mm').diff(this.$moment(time, 'HH:mm'), 'minutes', true) <= 15) {
                            habit.completed = true
                            console.log('doo ischer', habit.completed)
                        } else {
                            habit.delayed = true
                        }
                    } else if (this.$moment().diff(this.$moment(habit.time, 'HH:mm'), 'minutes', true) > 15) {
                        habit.delayed = true
                    }
                    /*		habit.completed =
						habit.executions.length &&
						habit.executions[0].createdAt &&
						this.$moment(habit.executions[0].createdAt).isSame(this.date, 'day')
			*/
                }
                if (habit.type === 'workoutHabit' && habit.executions.length) {
                    habit.completed = habit.executions.find((execution) =>
                        this.$moment(execution).isSame(this.date, 'day')
                    )
                }
                return habit
            })
        },
    },
    methods: {
        closeEdit() {
            console.log('why arent oyu closed?')
            this.habitInEdit = false
        },
        setHabitInExecution(habit) {
            if (habit.type === 'workoutHabit') this.$router.push('/plan/run/' + habit.workout._id)

            this.habitInExecution = habit
        },
        editHabit(habit, close) {
            this.habitInEdit = habit
            console.log(habit)
            close()
        },
        async deleteHabit(habit) {
            let deleted = null
            if (confirm('Willst du diese Gewohnheit unwiederruflich löschen?')) deleted = await habit.delete()
            if (deleted) this.$emit('deleted', habit._id)
        },

        getHabitAmountEnding(habit) {
            if (habit.type === 'drinkHabit') {
                return 'Liter'
            }
        },
        getExecutionsForHabit(habit, streak = false) {
            let amount = 0
            let inStreak = false
            let streakCount = 0
            if (!habit.executions || !habit.executions[this.date.format('YYYY-MM-DD')]) return
            let execution = habit.executions[this.date.format('YYYY-MM-DD')]
            if (Array.isArray(execution)) {
                execution.forEach((execution) => {
                    amount += execution.amount
                })
                if (amount >= habit.amount) {
                    let index = this.habitsList.findIndex((habitItem) => habitItem._id === habit._id)
                    this.$set(this.habitsList[index], 'completed', true)

                    streakCount++
                    inStreak = true
                }
                while (inStreak && streak) {
                    amount = 0
                    execution =
                        habit.executions[this.$moment(this.date).subtract(streakCount, 'days').format('YYYY-MM-DD')]
                    if (!execution) {
                        inStreak = false
                        continue
                    }
                    execution.forEach((execution) => {
                        amount += execution.amount
                    })
                    inStreak = amount >= habit.amount
                    if (inStreak) streakCount++
                }
            }
            console.log(streak, streakCount)
            return streak ? streakCount : amount.toFixed(2)
        },
    },
}
</script>

<style lang="scss" scoped></style>
