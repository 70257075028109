<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Umfrageergebnisse</span>
		</div>

		<div class="survey-wrapper">
			<div class="survey" v-for="s of surveys" :key="s.survey._id">
				<div class="survey-header">{{ timestamptodate(s.survey.ts) }}</div>
				<div class="survey-kunde">
					<strong>{{ s.user.vorname }} {{ s.user.nachname }}</strong
					><br />
					{{ s.user.telefon }}<br />
					<a :href="'mailto:' + s.user.email">{{ s.user.email }}</a>
				</div>

				<div class="survey-text">"{{ s.survey.text }}"</div>
			</div>
		</div>
		<div class="download-survey" @click="downloadSurvey">
			Liste herunterladen
		</div>
	</div>
</template>
<script>
import api from '../../api'
import zipcelx from 'zipcelx'

export default {
	data() {
		return {
			users: {},
			surveys: [],
		}
	},
	methods: {
		downloadSurvey() {
			let exceldata = []
			exceldata.push([
				{
					value: 'Datum',
					type: 'string',
				},
				{
					value: 'Studio',
					type: 'string',
				},
				{
					value: 'Vorname',
					type: 'string',
				},
				{
					value: 'Name',
					type: 'string',
				},
				{
					value: 'E-Mail',
					type: 'string',
				},
				{
					value: 'Telefon',
					type: 'string',
				},
				{
					value: 'Rückmeldung',
					type: 'string',
				},
			])
			for (let survey of this.surveys) {
				exceldata.push([
					{
						value: this.timestamptodate(survey.survey.ts),
						type: 'string',
					},
					{
						value: survey.studio_name,
						type: 'string',
					},
					{
						value: survey.user.vorname,
						type: 'string',
					},
					{
						value: survey.user.nachname,
						type: 'string',
					},
					{
						value: survey.user.email,
						type: 'string',
					},
					{
						value: survey.user.telefon,
						type: 'string',
					},
					{
						value: survey.survey.text,
						type: 'string',
					},
				])
			}
			const config = {
				filename: 'Rückmeldungen_Hygienemassnahmen',
				sheet: {
					data: exceldata,
				},
			}

			zipcelx(config)
		},
		timestamptodate(ts) {
			return new Date(ts * 1000).toLocaleString()
		},
	},
	created() {
		api.getAllSurveys()
			.then((data) => {
				console.log(data)
				this.surveys = data.surveys
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>
<style lang="scss" scoped>
.download-survey {
	position: fixed;
	bottom: 50px;
	width: 300px;

	background-color: var(--v-primary);
	color: white;
	text-align: center;
	right: calc(50% - 150px);
	padding: 10px 0px;
	border-radius: 5px;
}
.survey {
	color: #212529;
	border: 1px solid var(--v-primary);
	width: 90%;
	max-width: 600px;
	margin: 0px auto 20px auto;
	text-align: center;

	&-header {
		color: white;

		background-color: var(--v-primary);
	}
	&-kunde {
		padding: 10px;
		a {
			color: #212529;
		}
	}
	&-text {
		text-align: justify;
		padding: 0px 10px 10px 10px;
		font-size: 14px;
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
