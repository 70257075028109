<template xmlns="">
    <div class="deviceForm">
        <image-overlapping-card v-on:return-clicked="checkChanges" :changeRoute="false" :returnCallback="true">
            <template v-slot:image>
                <slim-cropper v-if="loaded" style="height: 35vh" :options="slimOptions">
                    <img v-if="form.picture" :src="'/img/uebungen/' + form.picture" alt="" />
                    <input type="file" accept="image/*" />
                </slim-cropper>
            </template>
            <template v-slot:content>
                <v-form @@submit.prevent="test" ref="form" lazy-validation>
                    <v-row no-gutters class="ma-0 formCard elevation-7 pb-5">
                        <v-col class="pa-0" cols="12">
                            <v-row no-gutters class="formTitle d-flex flex-row py-3 mx-0 mb-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="settings__item-lottie ml-5 mr-3"
                                    loop
                                    autoplay
                                    src="/img/lottiefiles/gf/gym.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h5 px-0 py-3 my-0 font-weight-bold text-uppercase"
                                >
                                    Gerät
                                </v-card-title>
                            </v-row>
                        </v-col>
                        <v-row no-gutters class="justify-space-between my-0">
                            <v-col cols="7">
                                <v-container class="pr-0 pb-0" fluid>
                                    <v-text-field
                                        class="h5"
                                        placeholder="Geräte Titel"
                                        v-model="form.title"
                                        :rules="rules.title"
                                        required
                                        counter="30"
                                    ></v-text-field>
                                </v-container>
                            </v-col>
                            <v-col cols="4">
                                <v-container class="pl-0 pb-0" fluid>
                                    <v-text-field
                                        class="h6 deviceForm-short-"
                                        color="primary"
                                        placeholder="Kurzform"
                                        :rules="rules.shortForm"
                                        v-model="form.shortForm"
                                        required
                                        counter="8"
                                    ></v-text-field>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="justify-space-between my-0">
                            <v-container class="mb-5" fluid>
                                <v-combobox
                                    v-model="form.category"
                                    :items="categories"
                                    :search-input.sync="search"
                                    hide-selected
                                    hint="Maximal 1 Kategorie"
                                    label="Kategorie"
                                    persistent-hint
                                    small-chips
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    "
                                                    <strong>{{ search }}</strong>
                                                    " wurde nicht gefunden. Klicke auf
                                                    <kbd>Enter</kbd>
                                                    , um eine neue Kategorie zu erstellen
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </v-container>
                        </v-row>
                        <v-row no-gutters class="justify-space-between my-0">
                            <v-container class="mb-5" fluid>
                                <v-select
                                    v-model="form.studios"
                                    :items="studios"
                                    item-text="name"
                                    item-value="_id"
                                    :menu-props="{ maxHeight: '200' }"
                                    label="In folgenden Studios anzeigen"
                                    multiple
                                    hint="Wähle ein Standort an, um das Gerät dort anzuzeigen"
                                    persistent-hint
                                ></v-select>
                            </v-container>
                        </v-row>

                        <v-row
                            no-gutters
                            :style="active ? '' : 'overflow: hidden; max-height: 40vh;'"
                            class="justify-space-around ma-0"
                        >
                            <v-col cols="10" class="d-flex justify-center">
                                <v-btn class="deviceForm-addExercise" @click="addExercise" color="primary">
                                    <strong>+ Übung hinzufügen</strong>
                                </v-btn>
                            </v-col>
                            <v-col cols="10" v-for="(exercise, index) in form.exercises" :key="index">
                                <v-card v-if="exercise" class="d-flex mb-3 flex-row listitemcolor">
                                    <v-speed-dial
                                        :value="true"
                                        top
                                        right
                                        absolute
                                        transition="slide-x-reverse-transition"
                                        direction="left"
                                    >
                                        <template v-slot:activator>
                                            <v-btn v-model="exercise.fab" color="grey" small fab>
                                                <v-icon v-if="exercise.fab" color="white">mdi-close</v-icon>
                                                <v-icon v-else color="white">mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-btn
                                            v-if="userService.hasRight('ex_edit')"
                                            fab
                                            small
                                            color="blue"
                                            @click="editExercise(exercise, index)"
                                        >
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="userService.hasRight('ex_delete')"
                                            fab
                                            small
                                            @click="deleteExercise(exercise, 0)"
                                            color="red"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="userService.hasRight('ex_transfer')"
                                            fab
                                            small
                                            @click="reorderExercise(exercise, 0)"
                                            color="green"
                                        >
                                            <v-icon>mdi-order-bool-ascending</v-icon>
                                        </v-btn>
                                    </v-speed-dial>
                                    <div class="deviceForm__exercises-image mr-5 d-flex justify-start">
                                        <v-img
                                            height="100px"
                                            width="80px"
                                            contain
                                            v-if="exercise.picture"
                                            :src="'/img/uebungen/' + exercise.picture"
                                            class="bgpositionleftcenter my-auto"
                                        ></v-img>
                                        <v-img
                                            height="100px"
                                            width="80px"
                                            contain
                                            v-if="!exercise.picture && exercise.manual[0] && exercise.manual[0].picture"
                                            :src="'/img/uebungen/' + exercise.manual[0].picture"
                                            class="bgpositionleftcenter my-auto"
                                        ></v-img>
                                        <v-img
                                            class="bgpositionleftcenter my-auto"
                                            height="100px"
                                            width="80px"
                                            contain
                                            v-else
                                            src="/img/einmalig/devicePlacholder.jpg"
                                        ></v-img>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <v-card-title
                                            style="word-break: keep-all; line-height: 22px"
                                            class="h6 px-1 pb-0 pt-5 mb-1"
                                        >
                                            <strong>{{ exercise.title }}</strong>
                                        </v-card-title>
                                        <v-card-text class="pb-0 pl-2 pt-1">
                                            {{ exercise.general_exercise_settings.length }} Geräteeinstellungen
                                        </v-card-text>
                                        <v-card-text class="pt-0 pl-2">
                                            {{ exercise.set_exercise_settings.length }} Satzeinstellungen
                                        </v-card-text>
                                        <v-card-text v-if="exercise.tags && exercise.tags.length > 0" class="px-1">
                                            <v-chip
                                                class="listitemcolor"
                                                small
                                                :key="index"
                                                v-for="(tag, index) in exercise.tags"
                                            >
                                                {{ tag.name }}
                                            </v-chip>
                                        </v-card-text>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-form>
            </template>
        </image-overlapping-card>
        <router-view @returnToDeviceForm="setNavbar" @updateExercise="updateExercise"></router-view>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5 pt-5 pb-5">
                    {{ deletingExercise.title }}
                </v-card-title>
                <v-card-subtitle class="pb-0 pt-0">
                    Satzeinstellungen: {{ deletingExercise.set_exercise_settings.length }}
                </v-card-subtitle>
                <v-card-subtitle class="pb-5 pt-0">
                    Geräteeinstellungen: {{ deletingExercise.general_exercise_settings.length }}
                </v-card-subtitle>
                <v-card-text>
                    Möchtest Du die Übung
                    <strong>{{ deletingExercise.title }}</strong>
                    wirklich endgültig löschen?
                </v-card-text>
                <v-card-text class="red--text">
                    Die Übung wird aus allen vorhandenen Trainingsplänen entfernt.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="green darken-1" text @click="deleteExercise(deletingExercise, 1)">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="saveDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5 pt-5 pb-5">Warnung</v-card-title>

                <v-card-text>Du hast ungespeicherte Änderungen. Möchtest Du zuerst speichern?</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="checkChanges(1)">Nein Danke</v-btn>
                    <v-btn color="primary" text @click="validateForm">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showReorderDialog" persistent max-width="320">
            <v-card>
                <v-card-title class="text-h5 pt-5 pb-5">Übung neu zuweisen</v-card-title>

                <v-card-text>
                    Wähle ein Gerät aus der Liste aus, um die Übung in dieses Gerät zu verschieben
                </v-card-text>

                <div class="categorizedDevices">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="category of categories" :key="category">
                            <v-expansion-panel-header>
                                {{ category }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div
                                    class="categorizedDevices__device rounded px-5 my-2 py-2"
                                    :class="{
                                        'white--text': device._id === reorderDeviceId,
                                        primary: device._id === reorderDeviceId,
                                    }"
                                    @click="reorderDeviceId = device._id"
                                    v-for="device of categorizedDevices.filter((e) => e.category === category)"
                                    :key="device._id"
                                >
                                    {{ device.title }}
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="showReorderDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveReorderExercise">Verschieben</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'
import imageOverlappingCard from '../../../components/imageOverlappingCard.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import Slim from '../../../plugins/slimImage/slim/slim.vue'

export default {
    name: 'deviceForm',

    components: {
        'slim-cropper': Slim,
        imageOverlappingCard,
    },
    data() {
        return {
            userService,
            categories: [],
            categorizedDevices: [],
            showReorderDialog: false,
            reorderedExercise: null,
            reorderDeviceId: null,
            search: '',
            categories: ['Gym 80'],
            studios: [],
            saveDialog: false,
            changes: false,
            loaded: false,
            init: true,
            oldForm: {},
            slimOptions: {
                ratio: '4:3',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                dataJpegCompression: 30,
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                instantEdit: true,
                serviceFormat: 'file',
                push: true,
            },
            form: {
                title: '',
                shortForm: '',
                exercises: [],
                studios: [],
                category: '',
                picture: null,
            },
            navigationBarInitiated: false,
            rules: {
                title: [(v) => !!v || 'Dieses Feld wird benötigt', (v) => v.length <= 30 || 'Der Titel ist zu lang'],
                shortForm: [
                    (v) => !!v || 'Dieses Feld wird benötigt',
                    (v) => v.length <= 8 || 'Die Kurzform ist zu lang',
                ],
            },
            deleteDialog: false,
            deletingExercise: {
                title: '',
                set_exercise_settings: 0,
                general_exercise_settings: 0,
            },
        }
    },
    async created() {
        alert('yo')
        this.studios = await api.fetchStudios()
        this.categories = await api.fetchDeviceCategories()

        this.resetEditExercise()

        if (this.$route.params.id) {
            this.form = await api.fetchDevice(this.$route.params.id)
            this.form.exercises.forEach((e) => (e.fab = true))
            this.oldForm = JSON.stringify(this.form)
            this.setDevice(this.form)
        }
        this.loaded = true
        this.setNavbar()
    },
    beforeDestroy() {
        this.setNavigationBarText(null)
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
        ...mapActions('deviceStore', [
            'setDevice',
            'setEditExercise',
            'resetEditExercise',
            'removeExercise',
            'createExercise',
        ]),
        test() {
            console.log('preventing')
        },
        setNavbar() {
            this.navigationBarInitiated = false
            if (this.$route.params.id) {
                this.setNavigationBarText({ text: 'Speichern', state: false, endPoint: 'DeviceEdit' })
            } else {
                this.setNavigationBarText({ text: 'Gerät erstellen', state: false, endPoint: 'DeviceCreate' })
            }
        },

        checkChanges(status = 0) {
            if (status === 0 && this.oldForm !== JSON.stringify(this.form)) {
                this.saveDialog = true
            } else {
                this.$router.push({ name: 'devicesDashboardList' })
            }
        },
        validateForm() {
            if (this.$refs.form.validate()) {
                /*this.form.exercises.forEach((exercise, index) => {
					this.form.exercises[index].general_exercise_settings = exercise.general_exercise_settings.map((setting) => {
						let newSettingObject = {
							input: setting.input.value,
							text: setting.setting.text,
						}
						if (setting.selections) {
							newSettingObject.selections = setting.selections
						}
						return newSettingObject
					})
					this.form.exercises[index].set_exercise_settings = exercise.set_exercise_settings.map((setting) => {
						let newSettingObject = {
							input: setting.input.value,
							text: setting.setting.text,
						}
						if (setting.selections) {
							newSettingObject.selections = setting.selections
						}
						return newSettingObject
					})
				})*/
                if (this.form._id) {
                    api.updateDevice(this.form).then((data) => {
                        this.$emit('devicesChange')
                        this.$router.push({ name: 'devicesDashboardList' })
                    })
                } else {
                    api.createDevice(this.form).then((data) => {
                        this.$emit('devicesChange')
                        this.$router.push({ name: 'devicesDashboardList' })
                    })
                }
            }
        },

        addExercise() {
            this.resetEditExercise()
            if (this.$route.params.id) {
                this.$router.push('/devices/' + this.$route.params.id + '/exercise/create')
            } else {
                this.$router.push('/devices/create/exercise/create')
            }
        },
        async saveReorderExercise() {
            if (!this.reorderDeviceId) return alert('Bitte Gerät auswählen')
            this.showReorderDialog = false

            this.deleteExercise(this.reorderedExercise, 1)
            delete this.reorderedExercise._id
            let status = await api.reorderExercise(this.reorderDeviceId, this.reorderedExercise)
            this.reorderDeviceId = null
            this.reorderedExercise = null
        },
        async reorderExercise(exercise) {
            if (!this.categorizedDevices.length > 0) this.categorizedDevices = await api.fetchDevices()
            this.showReorderDialog = true
            this.reorderedExercise = exercise
            this.reorderDeviceId = null
        },
        deleteExercise(exercise, status) {
            console.log('test', exercise, status)
            if (status === 0) {
                this.deletingExercise = exercise
                this.deleteDialog = true
            } else {
                this.removeExercise(exercise)
                this.form.exercises = this.exercises
                this.deleteDialog = false
            }
        },
        editExercise(exercise, index) {
            exercise.index = index
            this.setEditExercise(exercise)
            let id = exercise._id ? exercise._id : 'edit'
            this.$router.push('/devices/' + this.$route.params.id + '/exercise/' + id)
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])
            let uploadimg = {}
            uploadimg.art = 'device'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.form.picture = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                })
        },
        updateExercise(exercise) {
            this.setNavbar()
            this.form.exercises = this.exercises

            this.resetEditExercise()
        },
    },
    computed: {
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
        ...mapGetters('deviceStore', ['exercises']),
        ...mapState('deviceStore', ['device', 'exerciseEdit']),
        active() {
            if (this.$route.name === 'DeviceCreate' || this.$route.name === 'DeviceEdit') return true
            return false
        },
    },
    watch: {
        navigationBarTextState(val) {
            if (this.navigationBarEndPoint === 'DeviceCreate' || this.navigationBarEndPoint === 'DeviceEdit') {
                if (this.navigationBarInitiated) {
                    this.validateForm()
                } else {
                    this.navigationBarInitiated = true
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.categorizedDevices {
    max-height: 300px;
    overflow: auto;

    &__device {
        &.active {
            background-color: green;
        }
    }
}
::v-deep .slim-btn {
    margin-bottom: 30px;
}
.formCard {
    border-radius: 10px;
}
.formTitle {
    background-color: #313131;
    color: white !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
::v-deep .bgpositionleftcenter .v-image__image {
    background-position: left center !important;
}
.deviceForm {
    &-addExercise {
        width: 100%;
        height: 70px !important;
        font-weight: bold;
    }
    &-short-form {
        input {
            color: var(--v-primary);
        }
    }
}
</style>
