<template>
    <div>
        <v-row class="justify-content-center">
            <v-col cols="auto">
                <v-btn color="primary" @click="openForm('new')"> Neues Produkt erfassen</v-btn>
            </v-col></v-row
        >
        <v-row class="justify-content-center">
            <v-col cols="auto">
                <v-btn color="primary" @click="$router.push('/shop')"> Direktlink zum SHOP</v-btn>
            </v-col></v-row
        >
        <v-row>
            <v-col cols="6" md="4" lg="3" xl="3" v-for="(product, index) of products" :key="'product' + index">
                <v-card
                    @click="openForm(product)"
                    hover
                    class="rounded-xl elevation-7 fill-height"
                    style="background: lightgray">
                    <div class="pa-2">
                        <v-img aspect-ratio="1.6" class="rounded-xl" :src="'/img/' + product.picture"></v-img>
                    </div>
                    <v-card-title class="pt-1 pb-2 font-weight-bold">{{ product.title }}</v-card-title>
                    <v-card-subtitle>{{ product.shortDescription }}</v-card-subtitle>
                    <v-card-title class="py-1" style="opacity: 0.7">
                        {{ getProductPrice(product.variations) }}
                    </v-card-title>
                </v-card>
            </v-col></v-row
        >
        <shop-products-form
            @closeForm="formActive = false"
            :propFormData="editModeProduct"
            :dialogActive="formActive"></shop-products-form>
    </div>
</template>
  
  <script>
import axios from 'axios'
import api from '../../../api'
import userSearch from '@/components/userSearch.vue'
import shopProductsForm from './shopProductsForm.vue'

export default {
    components: { userSearch, shopProductsForm },
    data() {
        return {
            amount: 0,
            products: [],
            editModeProduct: {},
            formActive: false,
        }
    },
    async created() {
        this.products = await api.fetchProductsManagement()
    },
    methods: {
        openForm(product) {
            if (product === 'new') {
                this.formActive = true
                this.editModeProduct = {}
            } else {
                this.editModeProduct = product
                this.formActive = true
            }
        },
        getProductPrice(variations) {
            if (!variations.length) return 'Kein Preis hinterlegt'
            if (variations.length === 1) {
                variations[0].setOwnPrice ? 'Eigene Eingabe Möglich' : variations[0].price
            } else {
                let prices = variations.map((variation) => variation.price)
                let filteredPrices = prices.filter((price) => price !== '')

                return 'Ab ' + Math.min(...filteredPrices.map((price) => Number(price))) + ' CHF'
            }
        },
    },
    computed: {},
}
</script>
  