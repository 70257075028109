<template>
    <div>
        <v-row style="background-color: #343541">
            <!--

               <v-col cols="12">
                   <v-card-title class="font-weight-bold white--text">Heute eingecheckt</v-card-title>
                   <v-data-table
                   dark
                   :loading="!finishedLoading"
                   :headers="checkinTableHeaders"
                   no-data-text="Heute gab es noch keine Checkins"
                   loading-text="Checkins von Heute werden geladen..."
                   :items="checkinTableItems"
                   :items-per-page="8"
                   class="elevation-5"></v-data-table>
                </v-col>
            -->

            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                <v-card class="rounded-xl pa-1" hover dark>
                    <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
                        Anzahl Checkins Insgesamt
                    </v-card-subtitle>
                    <v-col v-if="!finishedLoading" class="d-flex justify-center" cols="12">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>

                    <apexchart
                        height="350"
                        v-else
                        :options="checkinCountOptions"
                        :series="checkinCountSeries"
                    ></apexchart>
                </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                <v-card class="rounded-xl pa-1" hover dark>
                    <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
                        Anzahl Checkins pro Kunde
                    </v-card-subtitle>
                    <v-col v-if="!finishedLoading" class="d-flex justify-center" cols="12">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>

                    <apexchart
                        height="350"
                        v-else
                        :options="barChartOptions"
                        :series="checkinPerUserSeries"
                    ></apexchart>
                </v-card>
            </v-col>
            <v-col
                :cols="$vuetify.breakpoint.mobile ? '12' : '3'"
                v-for="studio of studios"
                :key="'checkins' + studio.code"
            >
                <v-card class="rounded-xl pa-1" hover dark>
                    <v-card-subtitle class="pt-2 pl-2 pb-0 font-weight-bold white--text">
                        Anzahl Checkins {{ studio.name }}
                    </v-card-subtitle>
                    <v-col v-if="!finishedLoading" class="d-flex justify-center" cols="12">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>

                    <apexchart
                        height="350"
                        v-else
                        :options="checkinCountOptions"
                        :series="checkinCountSeriesForStudio(studio)"
                    ></apexchart>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'dashboard',
    props: {
        datePickerRange: {
            type: Array,
            default: () => [],
        },
        studios: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            checkinTableHeaders: [
                {
                    text: 'Uhrzeit',
                    value: 'time',
                },
                {
                    text: 'Studio',
                    value: 'studio',
                },
                {
                    text: 'Vorname',
                    value: 'vorname',
                },
                {
                    text: 'Nachname',
                    value: 'nachname',
                },
                {
                    text: 'Tätigkeit',
                    value: 'doing',
                },
            ],
            finishedLoading: false,
            dashboardCheckinInfos: null,
            avgCheckins: null,
        }
    },
    async created() {
        let data = await api.fetchDashboardCheckinInfos(this.datePickerRange, this.studios)
        this.dashboardCheckinInfos = data.checkinUsers
        this.avgCheckins = data.checkinsAverage
        this.finishedLoading = true
    },
    methods: {
        checkinCountSeriesForStudio(studio) {
            let returnData = [{ name: 'Anzahl Checkins', data: [] }]
            /*  for (let checkin of Object.keys(this.filteredDashboardCheckinInfos)) {
                let denewLength = this.filteredDashboardCheckinInfos[checkin].filter((schegging) => {
                    return schegging.studio === studio.code
                }).length
                returnData[0].data.push([this.$moment(checkin).unix() * 1000, denewLength])
            } */
            if (this.dashboardCheckinInfos && this.dashboardCheckinInfos.length > 0) {
                let studioCheckins = this.dashboardCheckinInfos.find((info) => {
                    return info._id && info._id.toString() === studio._id.toString()
                })

                let moreThan7Days = true
                if (this.$moment(this.datePickerRange[1]).diff(this.$moment(this.datePickerRange[0]), 'days') < 8) {
                    moreThan7Days = false
                }

                if (studioCheckins) {
                    studioCheckins.dates.forEach((checkin) => {
                        if (!moreThan7Days) {
                            returnData[0].data.push([
                                this.$moment(checkin.date + ' ' + checkin.timeSlot.split('-')[0]).unix() * 1000,
                                checkin.count,
                            ])
                        } else {
                            let index = returnData[0].data.findIndex(
                                (datathing) => datathing[0] === this.$moment(checkin.date).unix() * 1000
                            )
                            if (index >= 0) {
                                returnData[0].data[index][1] += checkin.count
                            } else {
                                returnData[0].data.push([this.$moment(checkin.date).unix() * 1000, checkin.count])
                            }
                        }
                    })
                }
                returnData[0].data = returnData[0].data.sort((a, b) => a[0] - b[0])
            }
            return returnData
        },
    },
    watch: {
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.finishedLoading = false
                let data = await api.fetchDashboardCheckinInfos(this.datePickerRange, this.studios)

                this.dashboardCheckinInfos = data.checkinUsers
                this.avgCheckins = data.checkinsAverage
                this.finishedLoading = true
            }
        },
        async studios() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.finishedLoading = false
                let data = await api.fetchDashboardCheckinInfos(this.datePickerRange, this.studios)

                this.dashboardCheckinInfos = data.checkinUsers
                this.avgCheckins = data.checkinsAverage
                this.finishedLoading = true
            }
        },
    },
    computed: {
        moreThan7Days() {
            this.$moment(this.datePickerRange[1]).diff(this.$moment(this.datePickerRange[0]), 'days') < 8
        },
        checkinPerUserSeries() {
            let returnData = [{ name: 'Checkins pro Kunde', data: [] }]
            this.avgCheckins.forEach((data) => {
                returnData[0].data.push(Math.round(data.avgCount * 100) / 100)
            })
            return returnData
        },
        barChartOptions() {
            return {
                xaxis: {
                    labels: {
                        show: false,
                    },
                    categories: this.avgCheckins.map(
                        (data) => this.studios.find((std) => std._id === data.studio).name
                    ),
                },
                yaxis: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                        columnWidth: '45%',

                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: 900,
                                },
                            },
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: '#535A6C',
                },
                tooltip: {
                    theme: 'dark',
                },
                chart: {
                    foreColor: '#ccc',
                    type: 'bar',
                    stacked: true,
                    id: 'genderChart',
                    toolbar: {
                        zoom: false,
                    },
                    fill: {
                        opacity: 1,
                    },
                },
            }
        },
        checkinTableItems() {
            if (this.dashboardCheckinInfos && this.dashboardCheckinInfos[this.$moment().format('YYYY-MM-DD')]) {
                return this.dashboardCheckinInfos[this.$moment().format('YYYY-MM-DD')].reverse()
            }
        },
        filteredDashboardCheckinInfos() {
            return this.dashboardCheckinInfos
            //return this.dashboardCheckinInfos.filter((user) => this.studios.find((stud) => stud.code === user.studio_id))
        },
        checkinCountSeries() {
            let returnData = [{ name: 'Anzahl Checkins', data: [] }]

            if (this.dashboardCheckinInfos && this.dashboardCheckinInfos.length > 0) {
                this.dashboardCheckinInfos.forEach((studioCheckins) => {
                    let moreThan7Days = true
                    if (this.$moment(this.datePickerRange[1]).diff(this.$moment(this.datePickerRange[0]), 'days') < 8) {
                        moreThan7Days = false
                    }

                    if (studioCheckins) {
                        studioCheckins.dates.forEach((checkin) => {
                            if (!moreThan7Days) {
                                let index = returnData[0].data.findIndex(
                                    (datathing) =>
                                        datathing[0] ===
                                        this.$moment(checkin.date + ' ' + checkin.timeSlot.split('-')[0]).unix() * 1000
                                )
                                if (index >= 0) {
                                    returnData[0].data[index][1] += checkin.count
                                } else {
                                    returnData[0].data.push([
                                        this.$moment(checkin.date).unix() + ' ' + checkin.timeSlot.split('-')[0],
                                        checkin.count,
                                    ])
                                }
                            } else {
                                let index = returnData[0].data.findIndex(
                                    (datathing) => datathing[0] === this.$moment(checkin.date).unix() * 1000
                                )
                                if (index >= 0) {
                                    returnData[0].data[index][1] += checkin.count
                                } else {
                                    returnData[0].data.push([this.$moment(checkin.date).unix() * 1000, checkin.count])
                                }
                            }
                        })
                    }
                })
                returnData[0].data = returnData[0].data.sort((a, b) => a[0] - b[0])
            }
            return returnData
        },
        checkinCountOptions() {
            return {
                colors: [this.$vuetify.theme.themes.light.primary],
                chart: {
                    id: 'area-datetime',
                    foreColor: '#ccc',
                    type: 'area',
                    height: 350,
                    zoom: {
                        autoScaleYaxis: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                    min:
                        this.datePickerRange[0] && this.datePickerRange[1]
                            ? this.$moment(this.datePickerRange[0]).unix() * 1000
                            : this.$moment().subtract(1, 'month').unix() * 1000,
                    tickAmount: 6,
                },
                tooltip: {
                    theme: 'dark',
                    x: {
                        format: 'ddd dd MMM yyyy HH:mm',
                    },
                },
            }
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .apexcharts-menu- ::v-deep .apexcharts-menu-open {
    background-color: #343541 !important;
}
</style>
