<template>
    <div>
        <v-text-field dark label="Kunde suchen..." v-model="searchString"></v-text-field>
        <v-btn color="primary" fab @click="addNewCustomer()"><v-icon large>mdi-plus</v-icon></v-btn>

        <v-dialog v-model="customerForm" max-width="800px">
            <v-card dark>
                <v-card-title>{{ customer._id ? 'Kunde bearbeiten' : 'Kunde erstellen' }}</v-card-title>
                <v-col cols="12" class="px-3 mt-5">
                    <user-search
                        :value="customer.coach"
                        :key="customer.coach"
                        ignoreRights
                        instructor
                        label="Ernährungscoach"
                        @update="updateCoach"></user-search>
                </v-col>
                <v-col cols="12" class="px-3 mt-5">
                    <user-search
                        :value="customer.user"
                        label="Kunde"
                        ignoreRights
                        :key="customer.user"
                        @update="updateCustomer"></user-search>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
import axios from 'axios'
import api from '../../../api'
import userSearch from '../../../components/userSearch.vue'

export default {
    components: {
        userSearch: userSearch,
    },
    data() {
        return {
            customer: {
                user: null,
                customer: 'null',
            },
            isLoading: false,
            customerForm: false,
            searchString: '',
            filters: [],
        }
    },
    async created() {},
    methods: {
        addNewCustomer() {
            this.customerForm = true
        },
        updateCoach(val) {},
        updateCustomer(val) {
            console.log(val)
        },
    },
    watch: {},
    computed: {},
}
</script>
  