<template>
    <v-card max-width="800px" class="mx-auto" color="transparent" elevation="0">
        <v-row no-gutters>
            <v-col cols="8"></v-col>
            <v-col align="end" cols="4">
                <figures-income-statements-settings
                    v-if="selectedStudioSingle"
                    :studio="selectedStudioSingle"
                ></figures-income-statements-settings>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-text-field v-model="currencyAmount"></v-text-field>
            </v-col>
            <v-col align="center" cols="12">
                <v-btn @click="displayCurrency" color="primary">Anzeigen</v-btn>
            </v-col>
            <v-col align="center" cols="12">
                <v-card-text>{{ textAmount }}</v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template> 

<script>
import api from '@/api'
import { formatCurrency } from '@/moduls/currency/currency.js'
import figuresIncomeStatementsSettings from './figures/figuresIncomeStatementsSettings.vue'
export default {
    components: { figuresIncomeStatementsSettings },
    props: {
        datePickerRange: {
            type: Array,
            default: () => [],
        },
        selectedStudioSingle: {
            type: String,
        },
    },
    data() {
        return {
            currencyAmount: '',
            textAmount: '',
        }
    },
    created() {},
    methods: {
        displayCurrency() {
            this.textAmount = formatCurrency(this.currencyAmount, 0)
        },
    },
    watch: {
        datePickerRange() {},
        studios() {},
        leadsPerSettingSelection() {},
    },
}
</script>
<style lang="scss" scoped>
</style>