<template>
  <div class="photogs">
    <div class="upload">
      <label for="bild-upload-edit">Foto hochladen</label>
      <input
        v-show="!remove"
        type="file"
        accept="image/*"
        @change="editImage($event)"
        id="bild-upload-edit"
      />
    </div>
    <div v-show="remove" class="removing_bg">
      <img src="/img/icons/loading.gif" /><span
        >Hintergrund wird entfernt.. Bitte warten</span
      >
    </div>

    <PhotoEditorGS ref="PhotoEditor" :upload="foto" />
  </div>
</template>
<script>
import api from "../../api";

import PhotoEditorGS from "../../components/PhotoEditorGS.vue";

export default {
  data() {
    return {
      remove: false,
      user: {},
      foto: "",
    };
  },
  components: { PhotoEditorGS },
  created() {},
  methods: {
    editImage(event) {
      if (event.target.files[0]) {
        let uploadimg = {};
        uploadimg.art = "gs";
        const formData = new FormData();
        let file = event.target.files[0];

        formData.append("file", event.target.files[0]);

        uploadimg.formData = formData;
        this.remove = true;
        api
          .uploadImage(uploadimg)
          .then((data) => {
            if (data.status) {
              this.foto = data.name.url;
              this.remove = false;
              this.$refs.PhotoEditor.showSelection();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.removing_bg {
  width: 400px;
  text-align: center;
  line-height: 90px;
  margin: 0px auto;
  padding: 0px 15px;
  img {
    width: 40px;
    height: 50px;
  }
}
label[for="bild-upload-edit"] {
  /* border: 1px solid #ced4da; */
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  line-height: 60px;
  background-color: #313131;
  display: flex;
  /* border-radius: 0.25rem; */
  justify-content: center;
}
#bild-upload-edit {
  display: block;
  visibility: hidden;
  height: 0px;
  width: 0px;
  position: absolute;
}
</style>
