<template>
	<div class="workingOutGuide__card justify-space-between d-flex flex-column">
		<v-card-title class="workingOutGuide__card-title primary--text h5 justify-center">Anleitung</v-card-title>

		<div class="workingOutGuide__card__step-wrapper">
			<div class="workingOutGuide__card__step" v-for="(step, index) in steps" :key="index">
				<v-card-title class="h5"> Schritt {{ index + 1 }} </v-card-title>

				<v-img class="workingOutGuide__card-img" v-if="step.bild" :src="'/img/uebungen/' + step.bild" contain></v-img>

				<v-card-text class="body-1">
					{{ step.beschreibung }}
				</v-card-text>
			</div>
		</div>

		<v-card-actions class="workingOutGuide__card-actions justify-center">
			<v-btn color="primary" rounded class="text-white" @click="$emit('closeGuide')">
				Anleitung schliessen
			</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
export default {
	name: 'WorkingoutGuide',
	props: {
		steps: {
			type: Array,
			required: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOutGuide__card {
	background-color: white;
	bottom: 0;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 6px solid var(--v-primary) !important;

	&__wrapper {
		height: 100%;
	}

	&__step-wrapper {
		height: 60vh;
		overflow-y: scroll;
	}

	&-title {
		position: sticky;
	}
	&-actions {
		position: sticky;
		bottom: 0;
	}
	&-img {
		margin: 0 10%;
		height: 33vh;
	}
}
</style>
