<template>
    <div style="max-width: 500px" class="ma-auto">
        <div
            class="mainpage-top elevation-5 primary"
            :style="{ height: 80 + 'px', width: '100vw', 'max-width': '520px' }"
        >
            <v-row no-gutters>
                <v-app-bar color="transparent" width="100%" fixed elevation="0" class="justify-space-between py-2">
                    <v-col cols="2">
                        <v-btn icon @click="$router.push('/nutrition')">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <v-img height="50px" @click="$router.push('/')" contain src="/img/roody_logo.png"></v-img>
                    </v-col>
                </v-app-bar>
            </v-row>
        </div>
        <div v-if="data" style="padding-top: 80px">
            <div v-for="(meal, index) in data.filter((d) => d.recipes)" :key="index">
                <v-card-title class="pb-1 font-weight-bold">{{ meal.text }}</v-card-title>
                <v-row no-gutters class="pb-2" style="flex-wrap: nowrap; overflow-x: auto">
                    <v-col
                        cols="7"
                        color="transparent"
                        class="mr-2 fill-height"
                        v-for="(recipe, index) of meal.recipes"
                        :key="'recipe' + index"
                    >
                        <v-card
                            class="rounded-xl fill-height"
                            @click="
                                () => {
                                    selectedRecipe = recipe
                                    selectedMeal = meal
                                }
                            "
                        >
                            <v-img class="rounded-xl" :src="recipe.media.preview[0]" height="150"></v-img>
                            <v-card-title class="black--text" v-if="recipe.recipe">
                                <v-col cols="10" class="pa-0">
                                    <span
                                        style="
                                            text-wrap: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            display: block;
                                            width: 100%;
                                        "
                                    >
                                        {{ recipe.recipe.title }}
                                    </span>
                                </v-col>
                                <v-col cols="2" class="pa-0">
                                    <v-rating
                                        disabled
                                        :length="getRecipeStars(recipe.recipe)"
                                        :value="getRecipeStars(recipe.recipe)"
                                    ></v-rating>
                                </v-col>
                            </v-card-title>
                            <v-card-subtitle>
                                <span v-if="recipe && recipe.time && recipe.time.length">
                                    <v-icon>mdi-clock-outline</v-icon>
                                    {{ recipe.time[0].value }} Minuten
                                </span>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog
            @click:outside="clickedOutside()"
            class="white"
            max-width="500"
            id="nutritionMealDialog"
            content-class="nutritionMealDialog"
            :value="!!selectedMeal"
            transition="dialog-bottom-transition"
            origin="bottom center"
        >
            <div class="white">
                <nutrition-meal-dialog-selected-recipe
                    v-if="selectedMeal && selectedMeal.substanceDistributions"
                    :recipe="selectedRecipe"
                    :mealValue="selectedMeal.value"
                    :date="date"
                    :optimalSubstances="selectedMeal.substanceDistributions"
                ></nutrition-meal-dialog-selected-recipe>
            </div>
        </v-dialog>
        <!-- <input v-if="false" type="file" id="fileUpload" @change="test" name="fileUpload" />
            @close="closeDialog"
        -->
    </div>
</template>
<script>
import api from '@/api'
import nutritionMealDialogSelectedRecipe from './nutritionMealDialogSelectedRecipe.vue'

export default {
    components: { nutritionMealDialogSelectedRecipe },
    data() {
        return {
            loading: true,
            data: null,
            selectedMeal: null,
            selectedRecipe: null,
        }
    },
    props: {
        date: {},
    },
    async created() {
        this.data = await api.fetchAllRecipesForDate(this.date)
        this.loading = false
        console.log('date', this.date)
        console.log(this.$route)
    },
    methods: {
        clickedOutside() {
            setTimeout(() => {
                this.selectedRecipe = null
                this.selectedMeal = null
            }, 100)
        },
        getRecipeStars(recipe) {
            if (recipe.difficulty) {
                return recipe.difficulty === 'einfach' ? 1 : recipe.difficulty === 'schwierig' ? 3 : 2
            }
            return 0
        },
    },
}
</script>
<style scoped>
::v-deep .nutritionMealDialog {
    position: absolute;
    max-height: 70vh;
    bottom: 0 !important;
    margin: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.loader-div {
    position: relative;
    top: 40vh;
    left: 0;
    right: 0;
    width: 80px;
    margin: auto;
}
.mainpage-top {
    position: fixed;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: -15px;
    z-index: 100;
    max-width: 100%;
    width: 100%;
    transition: height 0.05 ease;
}
</style>