<template>
    <image-overlapping-card
        :dialogFullScreen="$vuetify.breakpoint.mobile"
        :dialogMaxWidth="$vuetify.breakpoint.mobile ? '1040px' : '700px'"
        @change-visibility="$emit('close')"
        :changeRoute="false"
        :height100="true"
        :innerCardTopMargin="$vuetify.breakpoint.mobile ? '30vh !important' : '35vh !important'"
        :backgroundColor="product.backgroundColor || 'white'"
        :rightIcon="shareButton"
        @right-clicked="shareProduct(product)">
        <template v-slot:image>
            <div style="height: 50vh" :style="'background: ' + product.backgroundColor">
                <v-img
                    :height="$vuetify.breakpoint.mobile ? '30vh' : '35vh'"
                    :aspect-ratio="1.6"
                    :src="'/img/' + product.picture"></v-img>
            </div>
        </template>
        <template v-slot:content>
            <v-card-title class="h2 font-weight-bold" style="word-break: break-word">{{ product.title }}</v-card-title>
            <v-card-subtitle style="word-break: break-word" v-html="product.description"> </v-card-subtitle>
            <v-card-text>
                <v-chip-group
                    v-if="product.variations.length > 1"
                    v-model="selectedVariation"
                    active-class="primary--text"
                    mandatory>
                    <v-chip v-for="(variation, index) of product.variations" :key="'chip' + index" :value="index">
                        <v-icon left :color="variation.color"> mdi-circle </v-icon> {{ variation.title }}</v-chip
                    >
                </v-chip-group>
            </v-card-text>
            <v-card-title>{{ activeVariation.title }}</v-card-title>
            <v-card-subtitle class="pt-1" v-html="activeVariation.description"> </v-card-subtitle>
            <v-card-title style="word-break: break-word" v-if="activeVariation.setOwnPrice">
                Bestimme selbst den Wert
            </v-card-title>
            <v-form v-model="formValid" v-if="activeVariation.setOwnPrice">
                <v-text-field
                    label="Preis eingeben"
                    v-model="ownPrice"
                    number
                    :rules="rules.minWorth"
                    class="px-5"
                    suffix="CHF"></v-text-field>
            </v-form>
            <v-card-title v-else class="primary--text h3 font-weight-bold">
                {{ activeVariation.pricePrefix ? activeVariation.pricePrefix + ' CHF ' : 'CHF ' }}
                {{ activeVariation.price }}{{ activeVariation.priceSuffix ? '.-' + activeVariation.priceSuffix : '.-' }}
            </v-card-title>
            <v-card-actions class="justify-content-center">
                <v-btn @click="createNewProduct()" x-large rounded color="primary">
                    {{ product.alternateToCartText ? product.alternateToCartText : 'In den Warenkorb' }}</v-btn
                >
            </v-card-actions>
            <v-card-text class="grey--text" style="line-height: 1.25">
                {{ product.condition }}
            </v-card-text>
        </template>
    </image-overlapping-card>
</template>
  
  <script>
import imageOverlappingCard from '@/components/imageOverlappingCard.vue'
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    components: { imageOverlappingCard },
    props: {
        product: { required: true, type: Object },
    },
    data() {
        return {
            rules: {
                minWorth: [(val) => val >= 5 || `Der Mindestwert ist 5 Franken.`],
            },
            shareButton: {
                color: 'black',
                icon: 'mdi-export-variant',
            },
            formValid: null,
            ownPrice: 0,
            selectedVariation: 0,
        }
    },
    methods: {
        ...mapActions('shopStore', ['addProductToCart']),
        createNewProduct() {
            if (this.product.shopExternLink) {
                if (confirm(this.product.externLinkPopupText)) {
                    this.$router.push({ path: this.product.shopExternLink, query: { previousRoute: '/shop' } })
                }
            } else {
                if (!this.activeVariation.setOwnPrice || (this.activeVariation.setOwnPrice && this.formValid)) {
                    let copyProduct = JSON.parse(JSON.stringify(this.product))

                    copyProduct.price = this.activeVariation.price || this.ownPrice
                    copyProduct.variation = this.product.activeVariations
                    this.addProductToCart(copyProduct)
                    this.$toast.success('Dein Produkt wurde in den Warenkorb gelegt.')
                    this.$emit('close')
                }
            }
        },
        shareProduct(product) {
            navigator.share({
                title: 'well come FIT ' + product.title,
                text: 'Hey, schau dir mal dieses Produkt an, das ich bei well come FIT gefunden habe! Ich dachte, es könnte dich auch interessieren.',
                url: 'https://app.wellcomefit.ch/shop/' + product._id,
            })
        },
    },
    computed: {
        activeVariation() {
            return this.product.variations[this.selectedVariation]
        },
    },
}
</script>
  