<template>
    <div class="nutri-score-container">
        <div
            v-for="item in scores"
            :key="item.letter"
            class="nutri-score"
            :class="[`score-${item.letter.toLowerCase()}`, { selected: selected === item.letter }]"
        >
            {{ item.letter }}
        </div>
    </div>
</template>
  
  <script>
export default {
    name: 'NutriScore',
    props: {
        selected: {
            type: String,
            required: true,
            validator: function (value) {
                return ['A', 'B', 'C', 'D', 'E'].includes(value.toUpperCase())
            },
        },
    },
    data() {
        return {
            scores: [
                { letter: 'A', color: '#008800' },
                { letter: 'B', color: '#80c142' },
                { letter: 'C', color: '#fecb02' },
                { letter: 'D', color: '#ee8100' },
                { letter: 'E', color: '#e63e11' },
            ],
        }
    },
}
</script>
  
  <style scoped>
.nutri-score-container {
    display: flex;
    width: 180px;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
    font-size: 0; /* Entfernt den Whitespace zwischen Inline-Block-Elementen */
}
.score-a {
    background-color: #008800;
}
.score-b {
    background-color: #80c142;
}
.score-c {
    background-color: #fecb02;
}
.score-d {
    background-color: #ee8100;
}
.score-e {
    background-color: #e63e11;
}

.nutri-score {
    flex-grow: 1;
    flex-basis: 0; /* Verteilt die Breite gleichmäßig */
    text-align: center;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    place-self: center;
    color: white;
    font-weight: bold;
    font-size: 16px; /* Standardgröße */
    opacity: 0.7;
    transition: font-size 0.3s ease, opacity 0.3s ease, margin 0.3s ease;
}

.nutri-score:last-of-type {
    border-bottom-right-radius: 12.5px;
    border-top-right-radius: 12.5px;
}
.nutri-score:first-of-type {
    border-bottom-left-radius: 12.5px;
    border-top-left-radius: 12.5px;
}

.selected {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 25px !important;
    border: 5px white solid;
    z-index: 1000;
    font-size: 20px; /* Vergrößert den ausgewählten Buchstaben */
    opacity: 1; /* volle Opazität für den ausgewählten Buchstaben */
    margin: 0 -5px; /* fügt Abstand hinzu, um den ausgewählten Buchstaben hervorzuheben */
}

/* Zusätzliche Stile für die Hervorhebung */
</style>