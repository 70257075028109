<template>
	<div class="adventsgewinnspiel">
		<div class="titel">roody | Adventsverlosung</div>

		<div class="gewinnspiel">
			<img class="gewinnspiel-logo" src="https://app.wellcomefit.ch/img/einmalig/logo_alt.jpg" />
			<img class="gewinnspiel-header" src="https://app.wellcomefit.ch/img/einmalig/header.jpg" />

			<div class="gewinnspiel-titel">GROSSE ADVENTSVERLOSUNG</div>
			<div class="gewinnspiel-text">
				1. Preis: 2-Jahres Abonnement <br />2. Preis: 1-Jahres Abonnement <br />3. Preis: Halbjahres Abonnement<br />
				<span v-show="standort != 'glarus'"> 5 x 1/2h Sportmassage im Karappa<br /> </span>
				10 X 1 Woche Fitness & Wellness in einem well come FIT Center

				<br /><br />Mitmachen und bis zum 31.12.2020 2-Jahres Abonnoment und noch viele andere attraktive Preise in einem well come FIT Center
				gewinnen!
			</div>
			<div v-show="!showForm && showTel && !success" class="gewinnspiel-form">
				<div class="gewinnspiel-wrapper">
					<label>Telefonnummer</label>
					<input type="tel" placeholder="Deine Telefonnummer" v-model="formData.tel" />
				</div>
			</div>
			<div v-show="showForm && !success" class="gewinnspiel-form">
				<div class="gewinnspiel-wrapper">
					<label>Anrede</label>
					<div class="anrede-wrapper">
						<div class="form-check">
							<input
								class="gender-input"
								type="radio"
								id="anrede_herr"
								name="gender"
								value="male"
								:checked="formData.male"
								required=""
							/>
							<label class="form-check-label" for="anrede_herr"> Herr </label>
						</div>
						<div class="form-check">
							<input
								class="gender-input"
								type="radio"
								id="anrede_frau"
								name="gender"
								value="female"
								:checked="formData.female"
								required=""
							/>
							<label class="form-check-label" for="anrede_frau"> Frau </label>
						</div>
					</div>
				</div>
				<div class="gewinnspiel-wrapper">
					<label>Vorname</label>
					<input type="text" placeholder="Dein Vorname" v-model="formData.vorname" />
				</div>
				<div class="gewinnspiel-wrapper">
					<label>Nachname</label>
					<input type="text" placeholder="Dein Nachname" v-model="formData.nachname" />
				</div>
				<div class="gewinnspiel-wrapper">
					<label>E-Mail</label>
					<input type="email" placeholder="Deine E-Mail" v-model="formData.email" />
				</div>
				<div class="gewinnspiel-wrapper">
					<label>Telefonnummer</label>
					<input type="tel" placeholder="Deine Telefonnummer" v-model="formData.tel" />
				</div>
			</div>

			<div v-show="!success" class="gewinnspiel-bedingungen-wrapper">
				<input type="checkbox" v-model="teilnahmebedingungen" :checked="teilnahmebedingungen" />
				<span
					>Ich nehme an der Verlosung teil und akzeptiere die
					<span class="show-teilnahmebedingungen" @click="showTeilnahmebedingungen = !showTeilnahmebedingungen"
						>Teilnahmebedingungen</span
					></span
				>
			</div>

			<div class="teilnahmebedingungen" v-show="showTeilnahmebedingungen">
				Die Gewinner werden persönlich benachrichtigt.<br />
				Über den Gewinnverlauf erfolgt kein Schriftverkehr. <br />Gewinne sind nicht übertragbar. <br />Teilnahme ab 16 Jahre.
			</div>
			<div class="error-advent" v-show="fehlermeldung && !success">
				{{ fehlermeldung }}
			</div>
			<div class="success-advent" v-show="success">
				Sie haben erfolgreich an der Verlosung teilgenommen
			</div>
			<div class="absenden" @click="submitForm()" v-show="!success">
				An der Verlosung teilnehmen
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'

export default {
	data() {
		return {
			teilnahmebedingungen: false,
			showTeilnahmebedingungen: false,
			formData: {
				male: true,
				female: false,
				anrede: 0,
				vorname: '',
				nachname: '',
				email: '',
				tel: '',
			},
			standort: null,
			showTel: false,
			success: false,
			fehlermeldung: null,
			showForm: true,
		}
	},
	created() {
		if (this.$route.params.standort) {
			this.standort = this.$route.params.standort
		}
		this.checkIfLoggedIn()
	},
	methods: {
		submitForm() {
			if (
				!this.showForm ||
				(this.formData.vorname.length > 2 &&
					this.formData.nachname.length > 2 &&
					(this.formData.tel.length || (!this.showForm && !this.showTel)) > 4 &&
					this.formData.email.includes('@') &&
					this.formData.email.length > 3)
			) {
				if (this.teilnahmebedingungen) {
					let fd = this.formData
					fd.token = localStorage.getItem('token')
					fd.type = this.$route.params.standort
					api.submitAdventsverlosung(fd)
						.then((data) => {
							this.fehlermeldung = ''
							if (data.status == 0) {
								this.fehlermeldung = 'Bitte überprüfen Sie die eingegebenen Daten'
							} else if (data.status == 1) {
								this.fehlermeldung = ''
								this.success = true
							} else if (data.status == 3) {
								this.fehlermeldung = 'Diese E-Mail Adresse hat bereits an der Verlosung teilgenommen...'
								this.success = true
							} else {
								this.fehlermeldung = 'Es gab einen Fehler mit der Automatischen Anmeldung... Bitte geben Sie die Daten von Hand ein.'
								localStorage.removeItem('token')
								this.showForm = true
							}
						})
						.catch((error) => {
							this.fehlermeldung = 'Es gab einen Fehler... Bitte versuchen Sie es später erneut.'
						})
				} else {
					alert('Bitte akzeptieren Sie die Teilnahmebedingungen')
				}
			} else {
				alert('Bitte füllen Sie alle Daten aus')
			}
		},
		checkIfLoggedIn() {
			if (localStorage.getItem('token')) {
				api.getUser()
					.then((data) => {
						if (data.user._id) {
							if (data.user.telefon.length <= 3) {
								this.showTel = true
							}
							this.showForm = false
						} else {
							console.log('nicht angemeldet')
						}
					})
					.catch((error) => {
						console.log('nicht angemeldet')
					})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.error-advent {
	max-width: 700px;
	margin: auto;
	padding: 10px 15px;
	text-align: center;
	color: red;
}
.success-advent {
	max-width: 700px;
	margin: auto;
	padding: 10px 15px;
	text-align: center;
	color: var(--v-primary);
}
.adventsgewinnspiel {
	.teilnahmebedingungen {
		max-width: 700px;
		margin: auto;
		padding: 10px 15px;
		text-align: center;
	}

	.titel {
		width: 100%;
		background-color: var(--v-primary);
		padding: 5px 10px;
		color: white;
	}

	.gewinnspiel {
		max-width: 700px;
		margin: auto;

		.anrede-wrapper {
			display: flex;
		}

		.gender-input {
			display: none;
		}
		.form-check {
			width: 50%;
			padding: 0px;
		}

		.form-check-label {
			cursor: pointer;
			font-size: 16px;
			font-weight: 400;
			color: #676767;
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			padding: 0 15px;
			height: 42px;
			border: none;
			line-height: 1.86;
			letter-spacing: 0.35px;
			box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
			background: #f5f5f5;
			width: 100%;
		}

		.gender-input:checked ~ label {
			background-color: var(--v-primary);
			color: white;
		}

		&-logo {
			width: 240px;
			margin: 20px auto 15px;
			display: flex;
		}

		&-header {
			width: 100%;
			max-height: 300px;
			object-fit: contain;
		}

		&-titel,
		&-text,
		&-form-wrapper {
			padding: 5px 15px;
		}
		&-titel {
			text-align: center;
			font-weight: bold;
			font-size: 16px;
			max-width: 390px;
			margin: 15px auto 5px auto;
		}
		&-text {
			max-width: 390px;
			text-align: center;
			margin: auto;
		}

		&-form {
			max-width: 700px;
			margin: auto;
			padding: 10px 15px;

			input {
				padding: 0 15px;
				width: 100%;
				height: 42px;
				border: none;
				box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);

				font-size: 16px;
				background-color: rgb(245, 245, 245);
				margin-bottom: 15px;
				background: #f5f5f5 !important;
			}
			label {
				font-weight: bold;
				font-size: 14px;
			}
			.gewinnspiel-wrapper {
				display: flex;
				flex-direction: column;
			}
		}

		.absenden {
			background-color: var(--v-primary);
			color: white;
			margin: 30px auto 170px auto;
			padding: 10px;
			display: block;
			width: 300px;
			text-align: center;
			font-weight: bold;
		}

		.gewinnspiel-bedingungen-wrapper {
			display: flex;
			flex-direction: row;
			max-width: 310px;
			margin: 10px auto;
			.show-teilnahmebedingungen {
				text-decoration: underline;
			}

			span {
				padding-left: 15px;

				&.show-teilnahmebedingungen {
					padding: 0px;
					display: inline;
				}
			}
			input {
				display: flex;
				margin: auto;
				padding: 10px;
				height: 40px;
				width: 40px;
			}
		}
	}
}
</style>
