<template>
    <div class="vue-step-wizard d-flex flex-column d-flex">
        <div class="step-header">
            <div class="step-progress mb-1 mt-2">
                <div class="bar progressbar" :style="{ width: progress + '%' }"></div>
            </div>
            <div class="text-center mb-1" style="font-size: 14px">
                {{ getStepText }}
            </div>
            <ul v-if="false" class="step-pills">
                <li
                    @click.prevent.stop="selectTab(index)"
                    class="step-item"
                    :class="{ active: tab.isActive, validated: tab.isValidated }"
                    v-for="(tab, index) in tabs"
                    v-bind:key="`tab-${index}`"
                >
                    <a class="step-link" href="#">
                        <span class="tabStatus">{{ index + 1 }}</span>
                        <span class="tabLabel">{{ tab.title }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="step-body d-flex flex-grow-1">
            <form class="d-flex flex-column flex-grow-1">
                <slot></slot>
            </form>
        </div>
        <div class="step-footer mt-auto">
            <div class="btn-group d-flex justify-space-around" role="group">
                <template v-if="!submitSuccess">
                    <v-btn v-if="currentTab == 0" color="#3c3c3c" class="white--text" @click="cancel">Abbrechen</v-btn>
                    <v-btn v-else color="#3c3c3c" class="white--text" :loading="buttonsLoading" @click="previousTab">
                        Zurück
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="loading || buttonsLoading"
                        @click="nextTab"
                        v-if="currentTab < totalTabs - 1"
                    >
                        Weiter
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :loading="submitting || buttonsLoading"
                        @click="onTempSubmit"
                        v-if="showTempSubmit && currentTab === totalTabs - 1"
                    >
                        Zwischenspeichern
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="submitting || buttonsLoading"
                        @click="onSubmit"
                        v-if="currentTab === totalTabs - 1"
                    >
                        <span v-if="!publicContract">Speichern</span>
                        <span v-else>Jetzt kaufen</span>
                    </v-btn>
                </template>

                <template v-else>
                    <v-btn color="red" class="white--text" @click="reset">Erneut versuchen</v-btn>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { store } from './store.js'

export default {
    name: 'form-wizard',
    data() {
        return {
            submitting: false,
            loading: false,
            tabs: [],
            currentTab: 0,
            totalTabs: 0,
            storeState: store.state,
            submitSuccess: false,
            progress: 0,
            isValidationSupport: false,
        }
    },
    props: {
        buttonsLoading: {
            type: Boolean,
            default: false,
        },
        tabLength: {
            type: Number,
            default: 0,
        },
        showTempSubmit: {
            type: Boolean,
            default: false,
        },
        publicContract: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.tabs = this.$children
        this.totalTabs = this.tabs.length - 1
        this.currentTab = this.tabs.findIndex((tab) => tab.isActive === true)
        //Select first tab if none is marked selected
        if (this.currentTab === -1 && this.totalTabs > 0) {
            this.tabs[0].isActive = true
            this.currentTab = 0
        }
        //Setup Initial Progress
        this.progress = ((this.currentTab + 1) / this.totalTabs) * 100
    },
    updated() {
        /*
          Using this lifehook - since store variable gets updated after component is mounted
          isValidationSupport checks if user is looking to perform validation on each step
        */
        this.isValidationSupport =
            Object.keys(this.storeState.v).length !== 0 && this.storeState.v.constructor === Object ? true : false
    },
    methods: {
        setSubmit(status = true) {
            this.submitting = status
        },
        setLoading(status = true) {
            this.loading = status
        },
        cancel() {
            this.$emit('onCancel')
        },
        previousTab() {
            this._switchTab(this.currentTab - 1)
            this.$emit('onPreviousStep', this.currentTab)
        },
        nextTab() {
            if (this._validateCurrentTab() === false) return
            // this._switchTab(this.currentTab + 1)
            this.$emit('onNextStep', this.currentTab)
        },
        reset() {
            this.tabs.forEach((tab) => {
                tab.isActive = false
                tab.isValidated = false
            })
            this._switchTab(0)
            this.submitSuccess = false
            this.storeState.v.$reset()
            this.$emit('onReset')
        },
        changeStatus() {
            this.submitSuccess = true
        },
        selectTab(index) {
            //Only switch to filled previous tabs
            if (index < this.currentTab) {
                this._switchTab(index)
                return
            }
            if (this._validateCurrentTab() === false) {
                return
            }
            if (this.tabs[index - 1].isValidated === false) {
                return
            }
            this._switchTab(index)
        },
        onTempSubmit() {
            if (this._validateCurrentTab() === false) return
            this.$emit('onTempSubmit')
        },
        onSubmit() {
            if (this._validateCurrentTab() === false) return
            this.$emit('onSubmit')
        },
        _switchTab(index) {
            //Disable all tabs
            this.tabs.forEach((tab) => {
                tab.isActive = false
            })
            this.currentTab = index
            this.tabs[index].isActive = true
            this.progress = ((this.currentTab + 1) / this.totalTabs) * 100
        },
        _validateCurrentTab() {
            if (!this.isValidationSupport)
                //Check if user wants to validate
                return true
            this.storeState.v.$touch()
            if (this.storeState.v.$invalid) {
                this.tabs[this.currentTab].isValidated = false
                return false
            }
            this.tabs[this.currentTab].isValidated = true
            return true
        },
    },
    computed: {
        getStepText() {
            let activeTab = null
            this.tabs.forEach((tab, index) => {
                if (tab.isActive) {
                    activeTab = index + 1
                }
            })

            return activeTab + ' / ' + this.tabLength
        },
    },
    watch: {
        currentTab() {
            store.setCurrentTab(this.currentTab)
        },
    },
}
</script>
<style>
.progressbar {
    transition: width 1s ease;
}
@media only screen and (min-width: 600px) {
    .vue-step-wizard {
        max-width: 700px !important;
        min-height: 560px !important;
    }
}
.vue-step-wizard {
    margin: auto;
    max-width: 90vw;

    min-height: 90vh;
}
.step-body {
    width: 600px;
    max-width: 90vw;
}
.step-progress {
    height: 1rem;
    background: white;
    border-radius: 1rem;
    margin: 1rem 0rem;
}
.step-progress .bar {
    content: '';
    height: 1rem;
    border-radius: 1rem;
    background-color: var(--v-primary);
}
.step-pills {
    display: flex;
    background-color: white;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 1rem;
    /*box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;*/
}
.step-pills .step-item {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px 20px;
    list-style-type: none;
    padding: 0.5rem 1.5rem;
}
.step-pills .step-item a {
    text-decoration: none;
    color: #7b7b7b;
}
.step-pills .step-item.active {
    border: 1px solid #4b8aeb;
}
.step-pills .step-item.validated {
    border: 1px solid #008011;
}
.step-body {
    background-color: white;
    margin-left: auto;
    padding: 1rem;
    border-radius: 1rem;
    /*box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;*/
}
.step-footer {
    margin-left: auto;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem 0rem;
    text-align: center;
}
.step-button {
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    max-width: 30rem;
    text-align: center;
    border: 1px solid;
    border-radius: 12px;
    color: #22292f;
    color: rgba(34, 41, 47, var(--text-opacity));
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    margin: 0.5rem;
    color: #fff;
    outline: none !important;
    box-shadow: none !important;
}
.step-button-next {
    background-color: var(--v-primary);
}
.step-button-previous {
    background-color: #3c3c3c;
}
.step-button-submit {
    background-color: var(--v-primary);
}
.step-button-reset {
    background-color: #3c3c3c;
}
/** Wizard Ends */
.tabStatus {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    line-height: 1.5rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.38);
    border-radius: 50%;
}
</style>
