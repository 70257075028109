<template>
	<div class="pushDashboard ">
		<v-row>
			<v-col cols="12">
				<v-select dark filled v-model="selectedStudio" :items="studios" item-value="code" item-text="name" label="Studio"></v-select>
				<v-text-field  dark filled label="Titel" v-model="title"></v-text-field>
				<v-textarea  dark filled label="Body" v-model="body"></v-textarea>
				<v-btn color="primary" @click="sendPush">Send</v-btn>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import api from '../../../api'

export default {
	name: 'pushDashboard',
	components: {},
	data() {
		return {
			studios: [],
			selectedStudio: null,
			title: '',
			body: '',
		}
	},
	async created() {
		try {
			let studios = await api.fetchStudios()
			this.studios = studios
		} catch (e) {
			this.$toast.error('Error fetching studios')
		}
	},
	computed: {},
	methods: {
		async sendPush() {
			let notification = { topic: this.selectedStudio, notification: { title: this.title, body: this.body } }
			let status = await api.sendPushToTopic(notification)
			console.log(status)
		},
	},
	watch: {},
}
</script>

<style lang="scss" scoped></style>
