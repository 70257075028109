<template>
    <v-row justify="center">
        <v-dialog
            style="z-index: 2000"
            content-class="habitLightBlue"
            persistent
            v-model="dialog"
            transition="dialog-bottom-transition"
            origin="bottom center"
        >
            <div>
                <v-card-title class="drink-execution__title justify-content-center white--text">
                    Deine Trink Gewohnheit
                    <v-btn icon color="white" @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-row class="justify-space-around ma-0">
                    <v-col cols="4" class="text-center">
                        <span class="font-weight-bold">0.25L</span>
                        <v-img
                            class="ml-auto mr-auto"
                            src="/img/lottiefiles/glas.png"
                            @click="addAmount(0.25)"
                            width="125"
                        ></v-img>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <span class="font-weight-bold">0.33L</span>
                        <v-img
                            class="ml-auto mr-auto"
                            @click="addAmount(0.33)"
                            src="/img/lottiefiles/can.png"
                            width="125"
                        ></v-img>
                        <!-- <v-btn class="drink-execution__add-button" icon><v-icon @click="addAmount(0.33)" color="white">mdi-plus</v-icon></v-btn>
					-->
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col cols="12">In 0.25 Liter Gläser angegeben</v-col>
                    <v-col
                        cols="2"
                        class="px-0"
                        v-for="(glas, index) of amountGlassesShown"
                        :key="'selectedAmount' + index"
                    >
                        <v-img height="35" width="35" :src="getGlasImage(glas)"></v-img>
                    </v-col>
                </v-row>
            </div>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitDrinkForm from './habitDrinkForm.vue'
export default {
    name: 'habitDrinkExecution',
    components: {
        habitDrinkForm,
    },
    props: {
        date: {
            type: Object,
            required: true,
        },
        habit: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedHabit: null,
            dialog: true,
            templateSelection: false,
            templatesList: [],
            halfGlas: false,
        }
    },
    methods: {
        addAmount(amount) {
            this.habit.execute(amount, this.$moment(), this.$moment(this.date).format('YYYY-MM-DD'))
            console.log('add amount finished')
        },
        closeDialog() {
            this.dialog = false
            setTimeout(() => {
                this.$emit('closeExecutionForm')
            }, 500)
        },
        getGlasImage(index) {
            if (index - 1 === this.fullGlasses && this.halfGlas) return '/img/lottiefiles/glas_half.png'
            return index > this.fullGlasses ? 'img/lottiefiles/glas_empty.png' : '/img/lottiefiles/glas.png'
        },
    },
    computed: {
        fullGlasses() {
            let amount = 0
            if (!this.habit.executions || !this.habit.executions[this.date.format('YYYY-MM-DD')]) return 0
            this.habit.executions[this.date.format('YYYY-MM-DD')].forEach((execution) => {
                amount += execution.amount
            })
            this.halfGlas = !((amount / 0.25) % 0.25 === 0)
            return Math.floor(((amount / 0.25) * 4) / 4)
        },
        amountGlassesShown() {
            return this.fullGlasses > Math.round(this.habit.amount / 0.25)
                ? this.halfGlas
                    ? this.fullGlasses + 1
                    : this.fullGlasses
                : Math.round(this.habit.amount / 0.25)
        },
    },
}
</script>

<style lang="scss" scoped>
.drink-execution {
    &__title {
        position: sticky;
        min-height: 68px;
        width: 100%;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        background-color: #4bb2fd;
    }
    &__add-button {
        background-color: #4bb2fd;
        color: white;
    }
}

::v-deep .v-dialog {
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    margin: 0;
    position: absolute;
    min-height: 40vh;
    bottom: 0;
    background: white;
    max-height: 90vh;
}

.option-button {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background: lightgray;
        &-bulb {
            ::v-deep .v-image__image {
                width: 80px;
            }
            flex-direction: row;
        }
    }
    &__wrapper:hover {
        background: gray;
    }
}
</style>
