<template>
    <div>
        <v-btn class="mx-auto my-6" color="primary" @click="showAnamneseTest()">ANAMNESEBOGEN TESTEN</v-btn>
        <v-btn class="mx-auto my-6" color="primary" @click="showAnamneseTest('employeeQuestionnaire')">
            Mitarbeiterfragebogen TESTEN
        </v-btn>
        <v-btn class="mx-auto my-6" color="primary" @click="showAnamneseTest('livestyleAnamnese')">
            Lebensstilfragebogen TESTEN
        </v-btn>

        <v-expansion-panels dark multiple v-model="panel">
            <v-expansion-panel v-for="(studio, i) of studios" :key="studio._id">
                <v-expansion-panel-header>{{ studio.name }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-expansion-panel
                        v-if="
                            studio._id !== 'nutrition' &&
                            studio._id !== 'livestyleAnamnese' &&
                            studio._id !== 'employeeQuestionnaire'
                        "
                    >
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Allgemeine Einstellungen</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-select
                                @change="saveStudio(studio._id)"
                                label="Anamnesebogen aus anderem Studio übernehmen"
                                clearable
                                v-model="studio.anamneseSettings.copiedStudio"
                                :items="studios.map((studio) => ({ text: studio.name, value: studio._id }))"
                            ></v-select>

                            <v-textarea
                                @blur="saveStudio(studio._id)"
                                label="Haftungsausschluss"
                                v-model="studio.anamneseSettings.disclaimer"
                            ></v-textarea>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel :disabled="studio.anamneseSettings.copiedStudio">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Fragen</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <v-list-item-group>
                                            <v-list-item
                                                @click="createUpdateItem(studio._id, questionDialog, question)"
                                                class="primary"
                                                v-for="(question, index) of studio.anamneseQuestions"
                                                :key="index + '-question'"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold">
                                                        {{ question.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="createUpdateItem(studio._id, questionDialog)">
                                        <span>Hinzufügen</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="studio.anamneseQuestions && studio.anamneseQuestions.length">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Seiten</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <draggable v-model="studio.pages" @change="updatePageSorting(studio._id)">
                                            <v-list-item
                                                class="primary"
                                                @click="createUpdateItem(studio._id, pageDialog, page)"
                                                v-for="(page, index) of studio.pages"
                                                :key="index + '-question'"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold">
                                                        Seite {{ index + 1 }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="createUpdateItem(studio._id, pageDialog)">
                                        <span>Hinzufügen</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <basic-dialog
            dark
            :dialogVisible="!!activeDialog"
            :dialogTitle="activeDialog"
            maxWidth="600"
            @onCancel="cancelCalled()"
            @onSave="saveCalled()"
        >
            <anamneseDashboardSettingsQuestionDialog
                ref="questionDialog"
                :studio="activeStudioID"
                :question="activeItem"
                @deleted="finishedQuestionDialog"
                @saveFinished="finishedQuestionDialog"
                v-if="activeDialog === questionDialog"
            />
            <anamneseDashboardSettingsPageDialog
                ref="pageDialog"
                :studio="activeStudioID"
                :page="activeItem"
                @deleted="finishedPageDialog"
                @saveFinished="finishedPageDialog"
                :questions="getNotYetSelectedQuestions(activeStudioID)"
                v-if="activeDialog === pageDialog"
            />
        </basic-dialog>
        <anamnese-bogen-create ref="anamneseBogenRef"></anamnese-bogen-create>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import api from '@/api'
import basicDialog from '@/components/tools/basicDialog'
import anamneseDashboardSettingsQuestionDialog from './anamneseDashboardSettingsQuestionDialog'
import anamneseDashboardSettingsPageDialog from './anamneseDashboardSettingsPageDialog'
import anamneseBogenCreate from '../../../components/anamneseBogenCreate'

export default {
    components: {
        draggable,
        basicDialog,
        anamneseBogenCreate,
        anamneseDashboardSettingsQuestionDialog,
        anamneseDashboardSettingsPageDialog,
    },
    data() {
        return {
            panel: [],
            studios: [],
            activeDialog: '',
            activeItem: null,
            questionDialog: 'Neue Frage erstellen',
            pageDialog: 'Neue Seite erstellen',
        }
    },
    async created() {
        this.studios = await api.fetchAnamneseDashboardInfos()
    },
    methods: {
        getNotYetSelectedQuestions(studioID) {
            let pages = this.studios.find((studio) => studio._id === studioID).pages
            let questions = []
            pages.forEach((page) => {
                page.questions.forEach((question) => {
                    questions.push(question._id)
                })
            })
            return this.studios
                .find((studio) => studio._id === studioID)
                .anamneseQuestions.filter((question) => !questions.includes(question._id))
        },
        showAnamneseTest(variant = null) {
            if (!variant) return this.$refs.anamneseBogenRef.setVisible()

            this.$refs.anamneseBogenRef.setVisible(null, null, variant, null, null)
        },
        async updatePageSorting(studioId) {
            let pages = this.studios.find((studio) => studio._id === studioId).pages
            for (let i = 0; i < pages.length; i++) {
                pages[i].sort = i
            }
            await api.updateAnamnesePageSorting(pages)
            this.$toast.success('Seiten wurden erfoglreich sortiert')
        },
        createUpdateItem(studioID, dialogType, item = null) {
            this.activeDialog = dialogType
            this.activeStudioID = studioID
            this.activeItem = item
        },
        async saveStudio(id) {
            await api.updateStudio(this.studios.find((studio) => studio._id === id))
            this.$toast.success('Studio wurde erfolgreich gespeichert')
        },
        saveCalled() {
            this.activeDialog === this.questionDialog && this.$refs.questionDialog.createQuestion()
            this.activeDialog === this.pageDialog && this.$refs.pageDialog.createPage()
        },
        cancelCalled() {
            this.activeDialog = ''
            this.activeItem = null
        },
        finishedQuestionDialog(newQuestion = null) {
            if (newQuestion) {
                this.studios = this.studios.map((studio) => {
                    if (studio._id === newQuestion.studio) {
                        if (studio.anamneseQuestions.find((question) => question._id === newQuestion._id))
                            studio.anamneseQuestions = studio.anamneseQuestions.map((question) => {
                                if (question._id === newQuestion._id) return newQuestion
                                return question
                            })
                        else studio.anamneseQuestions.push(newQuestion)
                    }
                    return studio
                })
                this.$toast.success('Frage erfolgreich gespeichert')
            } else {
                this.studios = this.studios.map((studio) => {
                    if (studio._id === this.activeStudioID) {
                        studio.anamneseQuestions = studio.anamneseQuestions.filter(
                            (question) => question._id !== this.activeItem._id
                        )
                    }
                    return studio
                })
                this.$toast.error('Frage erfolgreich gelöscht')
            }
            this.activeDialog = ''
            this.activeItem = null
        },
        finishedPageDialog(newPage = null) {
            if (newPage) {
                this.studios = this.studios.map((studio) => {
                    if (studio._id === newPage.studio) {
                        if (studio.pages.find((page) => page._id === newPage._id))
                            studio.pages = studio.pages.map((page) => {
                                if (page._id === newPage._id) return newPage
                                return page
                            })
                        else studio.pages.push(newPage)
                    }
                    return studio
                })
                this.$toast.success('Seite erfolgreich gespeichert')
            } else {
                this.studios = this.studios.map((studio) => {
                    if (studio._id === this.activeStudioID) {
                        studio.pages = studio.pages.filter((page) => page._id !== this.activeItem._id)
                    }
                    return studio
                })
                this.$toast.error('Seite erfolgreich gelöscht')
            }
            this.activeDialog = ''
            this.activeItem = null
        },
    },
}
</script>
