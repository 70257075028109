<template>
	<div>
		<v-row class="flex-column" :key="'einsteincontestlistentry' + index" v-for="(date, index) in data">
			<v-card-title class="primary--text">{{ index }}</v-card-title>
			<v-row :key="'date' + index" v-for="(time, index) in date">
				<v-col cols="12">
					<v-card-title>{{ time.start }} - {{ time.end }}</v-card-title>
					<v-card-text>Anzahl teilnehmer: {{ time.entries }}</v-card-text>
					<v-card-text :key="'user' + index" v-for="(user, index) in time.users">
						{{ user.user.email + ' ' + user.user.vorname + ' ' + user.user.nachname }} Nimmt Gast mit? {{ user.guest ? 'Ja' : 'Nein' }}
					</v-card-text>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>
<script>
import api from '../../api'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
	data() {
		return {
			data: null,
		}
	},
	async created() {
		this.data = await api.getEinsteinSaunaContestAdminList()
		console.log(this.data)
	},
}
</script>

<style lang="scss" scoped></style>
