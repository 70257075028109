<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Bewertungen</span>
		</div>
		<router-link class="kreuztabellen" to="/bewertungen/kt">
			Kreuztabellen ansehen
		</router-link>

		<div class="counter">
			<table>
				<tr>
					<th>Art</th>
					<th>Mails</th>
					<th>Feedbacks</th>
				</tr>
				<tr>
					<td>Anamnese</td>
					<td>{{ counter.tracker_anamnese }}</td>
					<td>
						{{ counter.anamnese_feedback }} ({{
							runden(parseInt(counter.anamnese_feedback * 100) / parseInt(counter.tracker_anamnese))
						}}%)
					</td>
				</tr>
				<tr>
					<td>Trainingspläne</td>
					<td>{{ counter.tracker_tp }}</td>
					<td>{{ counter.tp_feedback }} ({{ runden(parseInt(counter.tp_feedback * 100) / parseInt(counter.tracker_tp)) }}%)</td>
				</tr>
			</table>
		</div>

		<div class="bewertungen-wrapper">
			<div v-for="(feedback, i) of feedbacks" :key="i" class="trainer">
				<div class="name">
					<span>{{ feedback.vorname }} {{ feedback.nachname }}</span>
					<div class="anz-wrapper">
						<span
							>A: {{ feedback.tldr.anamnese }} - {{ feedback.anamnese.length }} ({{
								runden(parseInt(feedback.anamnese.length * 100) / parseInt(feedback.tldr.anamnese))
							}}%) </span
						><span
							>| TP: {{ feedback.tldr.tp }} - {{ feedback.trainingsplaene.length }} ({{
								runden(parseInt(feedback.trainingsplaene.length * 100) / parseInt(feedback.tldr.tp))
							}}%)</span
						>
					</div>
				</div>
				<canvas :class="'trainer-' + feedback.email"></canvas>
				<div class="anamnese-feedbacks">
					<div v-for="(a, y) of feedback.anamnese" :key="y" class="feedback">
						<span class="kundenname">{{ a.user.vorname }} {{ a.user.nachname }}</span>
						<span class="datum"> | {{ a.datum }}</span
						><br />
						<span class="frage">Warst Du mit Deinem Trainer zufrieden?</span>
						<div class="sterne-wrapper">
							<i v-for="(yikes, xy) in a.frage_1.sterne" :key="xy" class="fa fa-2x rating-star fa-star selected"></i>
						</div>
						<div class="kommentar">
							{{ a.frage_1.kommentar }}
						</div>
						<span class="frage">Wie hat es Dir im well come FIT gefallen?</span>
						<div class="sterne-wrapper">
							<i v-for="(yikes, xyy) in a.frage_2.sterne" :key="xyy" class="fa fa-2x rating-star fa-star selected"></i>
						</div>
						<div class="kommentar">
							{{ a.frage_2.kommentar }}
						</div>
					</div>
				</div>
				<div class="trainingsplan-feedbacks">
					<div v-for="(t, x) of feedback.trainingsplaene" :key="x" class="feedback">
						<span class="kundenname">{{ t.user.vorname }} {{ t.user.nachname }}</span>
						<span> | {{ t.datum }}</span
						><br />
						<span class="frage">Bist Du mit Deinem Trainer zufrieden?</span>
						<div class="sterne-wrapper">
							<i v-for="(yikes, xxy) in t.frage_1.sterne" :key="xxy" class="fa fa-2x rating-star fa-star selected"></i>
						</div>
						<div class="kommentar">
							{{ t.frage_1.kommentar }}
						</div>
						<span class="frage">Bist du mit Deinem Trainingsplan zufrieden? </span>
						<div class="sterne-wrapper">
							<i v-for="(yikes, xxyy) in t.frage_2.sterne" :key="xxyy" class="fa fa-2x rating-star fa-star selected"></i>
						</div>
						<div class="kommentar">
							{{ t.frage_2.kommentar }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'

export default {
	data() {
		return {
			counter: {},
			feedbacks: [],
		}
	},
	methods: {
		runden(number) {
			if (number && number !== 'infinity') {
				if (number > 100) {
					return 100
				} else {
					return Math.round(number)
				}
			} else {
				return '0'
			}
		},
	},
	updated() {
		for (let feedback of this.feedbacks) {
			var elements = document.getElementsByClassName('trainer-' + feedback.email)
			var speedCanvas = elements[0]

			Chart.defaults.global.defaultFontFamily = 'Lato'
			Chart.defaults.global.defaultFontSize = 15

			let conf_labels = []

			for (let dat of feedback.anamnese) {
				conf_labels.push(dat.datum)
			}

			for (let dat of feedback.trainingsplaene) {
				conf_labels.push(dat.datum)
			}

			conf_labels = [...new Set(conf_labels)]
			/*
      conf_labels.forEach((l) => {
        console.log("label, ", l);
        let year = l.split(".")[2]
      });
      */

			conf_labels.sort(function(a, b) {
				a = a.split('(')[0]
				b = b.split('(')[0]
				if (a.split('.')[2] < b.split('.')[2]) return -1
				if (a.split('.')[2] > b.split('.')[2]) return 1

				if (a.split('.')[1] < b.split('.')[1]) return -1
				if (a.split('.')[1] > b.split('.')[1]) return 1

				if (a.split('.')[0] < b.split('.')[0]) return -1
				if (a.split('.')[0] > b.split('.')[0]) return 1
			})

			let dset_anamnese_trainer = []
			let dset_anamnese_studio = []

			let prev_anamnese_trainer = 0
			let prev_anamnese_studio = 0

			let dset_tp_trainer = []
			let dset_tp_tplan = []

			let prev_tp_trainer = 0
			let prev_tp_tplan = 0

			for (let dat of conf_labels) {
				let found = false
				for (let af of feedback.anamnese) {
					if (af.datum == dat) {
						dset_anamnese_trainer.push(af.frage_1.sterne)
						dset_anamnese_studio.push(af.frage_2.sterne)
						prev_anamnese_trainer = af.frage_1.sterne
						prev_anamnese_studio = af.frage_2.sterne
						found = true
					}
				}

				if (!found) {
					dset_anamnese_trainer.push(null)
					dset_anamnese_studio.push(null)
				}
				found = false

				for (let tpf of feedback.trainingsplaene) {
					if (tpf.datum == dat) {
						dset_tp_trainer.push(tpf.frage_1.sterne)
						dset_tp_tplan.push(tpf.frage_2.sterne)
						prev_tp_trainer = tpf.frage_1.sterne
						prev_tp_tplan = tpf.frage_2.sterne
						found = true
					}
				}
				if (!found) {
					dset_tp_trainer.push(null)
					dset_tp_tplan.push(null)
				}
			}

			var dataFirst = {
				label: 'Zufriedenheit Trainer (Anamnese)',
				data: dset_anamnese_trainer,
				lineTension: 0,
				fill: false,
				borderColor: '#00f',
			}

			var dataSecond = {
				label: 'Zufriedenheit Studio (Anamnese)',
				data: dset_anamnese_studio,
				lineTension: 0,
				fill: false,
				borderColor: 'var(--v-primary)',
			}

			var dataThird = {
				label: 'Zufriedenheit Trainer (Trainingsplan)',
				data: dset_tp_trainer,
				lineTension: 0,
				fill: false,
				borderColor: '#a900ff',
			}

			var dataFourth = {
				label: 'Zufriedenheit Trainingsplan (Trainingsplan)',
				data: dset_tp_tplan,
				lineTension: 0,
				fill: false,
				borderColor: '#8787ff',
			}

			var speedData = {
				labels: conf_labels,
				datasets: [dataFirst, dataSecond, dataThird, dataFourth],
			}

			var chartOptions = {
				spanGaps: true,
				legend: {
					display: true,
					position: 'top',
					labels: {
						boxWidth: 80,
						fontColor: 'black',
					},
				},
				scales: {
					yAxes: [
						{
							display: true,

							ticks: {
								min: 0, // minimum value
								max: 6, // maximum value
							},
						},
					],
				},
			}

			var lineChart = new Chart(speedCanvas, {
				type: 'line',
				data: speedData,
				options: chartOptions,
			})
		}
	},
	created() {
		api.getBewertungen().then((data) => {
			this.feedbacks = data.values
			this.counter = data.counter
		})
	},
}
</script>
<style lang="scss" scoped>
.kreuztabellen {
	max-width: 700px;
	text-align: center;
	margin: 0px auto;
	color: white;
	font-weight: bold;
	background-color: var(--v-primary);
	padding: 15px 0px;
	display: block;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.kundenname {
	font-weight: bold;
	font-size: 18px;
}
.counter {
	max-width: 700px;
	margin: 0 auto;
	display: flex;

	table {
		border-collapse: collapse;
		width: 100%;
		margin-bottom: 20px;
	}

	td,
	th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}
}
.bewertungen-wrapper {
	max-width: 700px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	.frage {
		font-weight: bold;
	}
	.feedback {
		margin-top: 20px;
		margin-bottom: 20px;

		.sterne-wrapper {
			margin: 10px 0px;
		}

		.kommentar {
			margin-bottom: 20px;
		}
	}

	.trainer {
		.name {
			padding: 10px 5px;
			background-color: var(--v-primary);
			font-weight: bold;
			color: white;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.anz-wrapper {
				margin-right: 5px;
			}
		}
	}
}
.rating-star {
	display: inline-block;
	position: relative;
	padding-right: 5px;
	margin-left: -5px;
	color: #e3cf7a;
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
