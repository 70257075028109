var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',{staticClass:"mx-auto mt-4",staticStyle:{"max-width":"400px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((_vm.selectedPackages),function(selectedPackage){return _c('tr',{key:selectedPackage.packageName + '-selectedPackages'},[_c('td',[(selectedPackage.amountSelectable && selectedPackage.amount)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(selectedPackage.amount)+"x ")]):_vm._e(),_c('span',[_vm._v(_vm._s(selectedPackage.packageName))])]),_c('td',{staticStyle:{"text-align":"end"}},[(selectedPackage.amountSelectable && selectedPackage.amount)?_c('span',[(
                                selectedPackage.usualPrice &&
                                (selectedPackage.calculateWithEndDate ||
                                    (selectedPackage.calculateWithEndDateSixMonths &&
                                        parseInt(selectedPackage.price) !== parseInt(selectedPackage.usualPrice)))
                            )?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-sale-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Regulärpreis: "+_vm._s(_vm.displayCurrency(selectedPackage.amount * selectedPackage.usualPrice))+" ")])])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.displayCurrency(selectedPackage.amount * parseInt(selectedPackage.price)))+" ")]):_c('span',[(
                                selectedPackage.usualPrice &&
                                (selectedPackage.calculateWithEndDate ||
                                    (selectedPackage.calculateWithEndDateSixMonths &&
                                        parseInt(selectedPackage.price) !== parseInt(selectedPackage.usualPrice)))
                            )?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-sale-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Regulärpreis: "+_vm._s(_vm.displayCurrency(selectedPackage.usualPrice))+" ")])])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.displayCurrency(selectedPackage.price))+" ")])])])}),_c('tr',{staticClass:"white--text",staticStyle:{"background-color":"var(--v-primary)","font-weight":"bold","letter-spacing":"0.8px"}},[_c('td',[_vm._v("Gesamt")]),_c('td',{staticStyle:{"text-align":"end"}},[_vm._v(_vm._s(_vm.total))])])],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }