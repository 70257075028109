<template>
    <div class="courseSettings">
        <v-row>
            <v-col cols="12" class="d-flex justify-center mt-5">
                <v-btn @click="addLabelDialog = true" color="primary">Hinzufügen</v-btn>
            </v-col>
            <v-col cols="12">
                <v-dialog v-model="addLabelDialog" transition="dialog-bottom-transition" max-width="600">
                    <v-card dark>
                        <v-toolbar color="primary" dark>
                            <div class="d-block">
                                <strong>Neues Label hinzufügen</strong>
                            </div>
                        </v-toolbar>
                        <div class="px-5 courseSettings_action-dialog d-flex flex-column">
                            <v-text-field filled class="mt-5" label="Text" v-model="addLabel.text"></v-text-field>
                            <v-text-field
                                filled
                                class="mt-5"
                                label="Interne Bezeichnung"
                                v-model="addLabel.code"></v-text-field>
                            <div class="d-flex justify-center">
                                <v-color-picker
                                    hide-canvas
                                    hide-inputs
                                    hide-mode-switch
                                    hide-sliders
                                    show-swatches
                                    :swatches="swatches"
                                    class="px-auto"
                                    mode="hexa"
                                    v-model="addLabel.color"></v-color-picker>
                            </div>
                        </div>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="addLabelDialog = false">Abbrechen</v-btn>
                            <v-btn color="primary" text @click="createLabel">Speichern</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-expansion-panels dark class="py-5">
            <v-expansion-panel v-for="label of labels" :key="label._id">
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <v-col cols="4">
                                {{ label.text }}
                            </v-col>
                            <v-col cols="8" class="text--secondary white--text">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open" key="0"></span>
                                    <span class="white--text" v-else key="1">
                                        {{ label.code }}
                                    </span>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters>
                        <v-text-field filled label="Text" v-model="label.text" placeholder="Text"></v-text-field>
                    </v-row>
                    <v-row no-gutters>
                        <v-text-field
                            filled
                            label="Interne Bezeichnung"
                            v-model="label.code"
                            placeholder="Interne Bezeichnung"></v-text-field>
                    </v-row>
                    <v-row no-gutters class="d-flex justify-center">
                        <v-color-picker
                            hide-canvas
                            hide-inputs
                            hide-mode-switch
                            hide-sliders
                            v-model="label.color"
                            class="ma-2"
                            :swatches="swatches"
                            show-swatches></v-color-picker>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="deleteEntry(label, 'label', 0)"> Löschen </v-btn>
                        <v-btn @click="updateLabel(label)" text color="primary"> Speichern </v-btn>
                    </v-card-actions>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ shorten(deleteDialogTitle, 25) }}
                </v-card-title>
                <v-card-text v-if="deleteDialogPreText" class="red--text">
                    Kurs wird in folgenden Studios verwendet: {{ deleteDialogPreText }}
                </v-card-text>
                <v-card-text>
                    Möchtest du {{ getTextbyType(deleteDialogType) }}
                    <strong>{{ shorten(deleteDialogTitle, 25) }}</strong>
                    wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false"> Abbrechen </v-btn>
                    <v-btn color="green darken-1" text @click="deleteEntry(dialogDeleteObj, deleteDialogType, 1)">
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../../components/userSearch.vue'
import courseSearch from '../../../components/courseSearch.vue'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import EventHome from '../../event/eventHome.vue'

export default {
    name: 'courseSettings',
    components: { courseSearch, 'slim-cropper': Slim, EventHome },
    data() {
        return {
            userService,
            labels: [],
            deleteDialog: false,
            deleteDialogText: '',
            deleteDialogTitle: '',
            dialogDeleteObj: {},
            deleteDialogType: '',
            deleteDialogPreText: '',
            addLabelDialog: false,
            addLabel: { text: '', code: '', color: '' },
            swatches: [
                ['#FF0000', '#ff9257', '#550000'],
                ['#FFFF00', '#AAAA00', '#555500'],
                ['#000000', '#00963f', '#000000'],
                ['#00FFFF', '#00AAAA', '#005555'],
                ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    async created() {
        await this.fetchLabels()
        this.$emit('finishedloading')
    },
    methods: {
        async createLabel() {
            let label = this.addLabel
            let created = await api.createGFLabel(label)
            this.addLabelDialog = false
        },
        async updateLabel(label) {
            let updated = await api.updateGFLabel(label)
        },
        getTextbyType(type) {
            if (type === 'label') return 'das Label'
            if (type === 'room') return 'den Raum'
            if (type === 'course') return 'den Kurs'
        },
        async deleteEntry(entry, type, status = 0) {
            this.dialogDeleteObj = entry
            this.deleteDialogType = type
            this.deleteDialogPreText = ''
            let error = false
            if (type === 'label') this.deleteDialogTitle = entry.text
            if (type === 'label' && status)
                (await api.deleteGFLabel(entry._id).catch((e) => (error = true))) && this.fetchLabels()
            if (type === 'room') this.deleteDialogTitle = entry.name
            if (type === 'room' && status) await api.deleteGFRoom(entry._id).catch((e) => (error = true))
            if (type === 'course') this.deleteDialogTitle = entry.name
            if (type === 'course') {
                let usage = await api.checkGFCourseUsage(entry._id)
                if (usage) this.deleteDialogPreText = usage
            }
            if (type === 'course' && status) await api.deleteGFCourse(entry._id).catch((e) => (error = true))
            if (!status) this.deleteDialog = true
            if (status && !error) this.deleteDialog = false
        },

        async fetchLabels() {
            this.labels = await api.fetchGFLabels()
        },
        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
    },
    computed: {},
}
</script>
<style lang="scss" scoped>
::v-deep .v-color-picker__edit,
:v-deep .v-color-picker__controls {
    display: none !important;
}
::v-deep .v-slide-group__prev {
    display: none !important;
}

.course_picture {
    border-bottom: 1px solid grey;
    cursor: pointer;

    &:hover {
        background-color: rgb(218, 218, 218);
    }
}

.courseSettings {
    &__course {
        &-list {
            border-bottom: 1px solid black;
        }
    }
    &__rating {
        &-wrapper {
            &-course {
                background-color: var(--v-primary);
                color: white;
            }
        }
    }
}
</style>
