<template>
	<div v-if="courseList && courseList.length > 0" class="nextCourses">
		<!---<v-row class="tag my-5 px-5">
			<span @click="pushToCourse()" class="tag_title">LIVESTREAMS</span>
			<span @click="pushToCourse()" class="tag_subtitle"><v-icon color="#585858">mdi-transfer-right</v-icon></span>
		</v-row>-->
		<v-row class="nextCourses__card-container flex-nowrap" :class="{ 'nextCourses__card-container-mobile': $vuetify.breakpoint.mobile }">
			<v-col
				class="pl-0 "
				v-show="
					!loading &&
						course &&
						course.course &&
						(!course.cancelled || userService.hasRight('gf_cp_viewCancelled') || course.isInstructor) &&
						(!('past' in course) || course.current)
				"
				v-for="course of courseList"
				:key="course._id"
				cols="9"
				md="7"
				lg="4"
				xl="4"
			>
				<v-skeleton-loader v-if="loading" class="mx-auto" max-width="300" type="card"></v-skeleton-loader>

				<v-card
					class="nextCourses__card rounded"
					:img="course && course.course && course.course.bild ? '/img/kurse/' + course.course.bild : ''"
				>
					<div class="nextCourses__card-transparency rounded"></div>
					<div v-if="course.signedUp" class="nextCourses__card-signedUp"><v-icon color="primary">mdi-check-circle</v-icon></div>
					<div v-if="!course.signedUp && course.current" class="nextCourses__card-current">LIVE</div>
					<v-responsive @click="pushToCourse()" :aspect-ratio="4 / 3">
						<div class="nextCourses__card-text d-flex flex-column justify-space-between white--text rounded">
							<v-card-text class="nextCourses__card-text-time d-flex">
								<lottie-player
									mode="normal"
									style="width:45px;height:45px;"
									name="watchIconLS"
									src="/img/lottiefiles/home/timer.json"
								></lottie-player>
								<span class="nextCourses__card-text-time-duration">
									{{ course.duration }} Min
									<br />
									{{ courseStartDate(course) }} Uhr bis {{ courseEndDate(course) }} Uhr
								</span>
							</v-card-text>
							<div>
								<v-card-title class="nextCourses__card-text-coursetitle h4 mb-0 pb-0 text-break">
									{{ course && course.course && course.course.name ? course.course.name : '' }}
								</v-card-title>
								<v-card-text class="pb-2">
									<span v-for="(instructor, iy) of course.instructors" :key="iy">
										<span v-if="iy == 0"></span>
										<span>{{ instructor.vorname }} {{ instructor.nachname }}</span>
										<span v-show="iy !== course.instructors.length - 1 && course.instructors.length !== 1">,</span>
									</span>
								</v-card-text>
							</div>
						</div>
					</v-responsive>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="showDialog" transition="dialog-bottom-transition" max-width="600">
			<v-card>
				<v-toolbar color="primary" dark>Livestreams</v-toolbar>
				<v-card-text class="mt-5">
					Wechsel in das Studio "LIVESTREAMS", um dich für Livestream Kurse anzumelden.
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn text @click="showDialog = false">Abbrechen</v-btn>
					<v-btn color="primary" class="text--white" text @click="showStudioPicker()">Weiter</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import abortService from '../../services/abortService'

export default {
	name: 'livestreams',

	data() {
		return {
			showDialog: false,
			userService,
			loading: true,
			courseList: [{}, {}, {}],
			ts: null,
			abortController: null,
		}
	},

	async created() {
		this.abortController = new abortService()
		if (this.alibeyStudio && this.$moment().unix() <= 1653688800 * 1000) {
			this.ts = this.$moment(1653688800 * 1000)
				.set({ hours: 0, minutes: 0, seconds: 0 })
				.unix()
		} else {
			this.ts = this.$moment()
				.set({ hours: 0, minutes: 0, seconds: 0 })
				.unix()
		}
		console.log(this.ts)

		await this.fetchCourseList()
	},
	beforeDestroy() {
		this.abortController.abort()
	},
	methods: {
		showStudioPicker() {
			window.app.$root.$children[0].showStudioSelection = true
		},
		courseEndDate(course) {
			let endTime = this.$moment()
			endTime.set({
				hours: course.hours,
				minutes: course.minutes,
				seconds: 0,
				miliseconds: 0,
			})

			endTime.add('minutes', course.duration)
			return endTime.format('LT')
		},
		courseStartDate(course) {
			let startTime = this.$moment()

			startTime.set({
				hours: course.hours,
				minutes: course.minutes,
				seconds: 0,
				miliseconds: 0,
			})

			return startTime.format('LT')
		},
		pushToCourse() {
			this.showDialog = true
		},
		async fetchCourseList() {
			this.loading = true
			console.log('here im loading')
			this.courseList = await api.fetchLSList(this.abortController.signal, this.ts)
			this.loading = false
		},
	},
	computed: {
		alibeyStudio() {
			let user = JSON.parse(localStorage.getItem('user'))
			let studioCode = null
			this.routeChanged
			if (user && user.user) studioCode = user.user.studio_id

			if (studioCode && (studioCode === 'Alibey' || studioCode === 'alibey' || studioCode === 'ali')) {
				return true
			} else {
				return false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.nextCourses {
	&__card {
		width: 100%;
		position: relative;
		&:first-of-type {
			margin-left: 16px;
		}
		&:last-of-type {
			margin-right: 25px;
		}
		&-current {
			position: absolute;
			right: 10px;
			top: 10px;
			width: 60px;
			height: 30px;
			background-color: #f00;
			border-radius: 50px;
			color: #fff;
			text-align: center;
			line-height: 30px;
			font-weight: bold;
			letter-spacing: 1px;
		}
		&-signedUp {
			position: absolute;
			right: 10px;
			top: 10px;
			width: 30px;
			height: 30px;
			background-color: white;
			border-radius: 50%;
			i {
				font-size: 40px;
				line-height: 100%;
				margin: -5px;
			}
		}
		&-transparency {
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: #0000003d;
		}
		&-text {
			height: 100%;
			&-coursetitle {
				font-weight: bold;
			}
			&-time {
				&-duration {
					line-height: 17px !important;
					margin: auto 5px;
					font-weight: bold;
					line-height: 25px;
				}
			}
		}
		&-container {
			overflow-x: auto;
			&-mobile {
				&::-webkit-scrollbar {
					display: none;
				}
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */
			}
		}
	}
}
.tag {
	display: flex;
	justify-content: space-between;

	.tag_title {
		font-size: 18px;
		letter-spacing: 0.5px;
	}

	.tag_subtitle {
		font-size: 14px;
		margin: auto 0px;
	}
	span {
		font-weight: bold;
	}
}
</style>
