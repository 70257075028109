<template>
    <v-row no-gutters justify="center">
        <v-dialog
            max-width="500"
            persistent
            id="nutritionMealDialog"
            content-class="nutritionMealDialog"
            :value="!!choiceActive"
            transition="dialog-bottom-transition"
            origin="bottom center"
        >
            <div>
                <div style="z-index: 1000; position: sticky; top: 0; background: #e0e0e0">
                    <v-card-title class="h4 mb-0 font-weight-bold">Art des Tages wählen</v-card-title>
                </div>
            </div>
            <div>
                <v-row class="justify-space-around pa-7 mb-5" no-gutters>
                    <v-col cols="6">
                        <div @click="setDay('trainingDay')" class="option-button__wrapper">
                            <v-img height="100px" width="100px" src="/img/lottiefiles/trainingDay.png"></v-img>
                            <span>Trainingstag</span>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div @click="setDay('recoveryDay')" class="option-button__wrapper">
                            <v-img height="100px" width="100px" src="/img/lottiefiles/recoveryDay.png"></v-img>
                            <span>Daily Rezepte</span>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-dialog>
    </v-row>
</template>


<script>
import api from '@/api'
export default {
    props: {
        possibilities: {},
        choiceActive: {},
        date: {},
    },
    name: 'nutritionDayChoice',
    data() {
        return {
            ouchIsmaFirstTime: true,
        }
    },

    methods: {
        async setDay(choice) {
            if (!this.possibilities.find((poss) => poss === choice)) {
                return alert(
                    'Für deinen ' +
                        (choice === 'e' ? 'Daily Rezepte' : 'Trainingstag') +
                        ' hast du aktuell noch keine Rezepte hinterlegt. Wenn du möchtest, kann dein Ernährungscoach dir dabei helfen, Rezepte für beide Tage einzurichten. Kontaktiere ihn einfach dafür.'
                )
            }
            try {
                await api.setNutritionDayChoice({
                    choice: choice,
                    day: this.$moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                })
                this.closeDialog()
            } catch (e) {
                console.log(e)
                this.$toast.error('Ein Fehler ist aufgetreten')
            }
        },
        closeDialog() {
            document.querySelector('.nutritionMealDialog').style.height = 'auto'
            console.log('closing')
            this.$emit('close')
        },
        expandDiv(scrollElement = null) {
            let element = document.querySelector('.nutritionMealDialog')
            if (this.ouchIsmaFirstTime) {
                this.ouchIsmaFirstTime = false
                element.setAttribute('data-collapsed', 'true')
            }
            setTimeout(() => {
                var sectionHeight = element.scrollHeight

                element.style.height = sectionHeight + 'px'

                element.addEventListener('transitionend', function (e) {
                    if (arguments[0].propertyName === 'height') {
                        element.removeEventListener('transitionend', arguments[0].callee)
                    }
                })
            }, 50)

            element.setAttribute('data-collapsed', 'false')
            setTimeout(() => {
                if (scrollElement) scrollElement.scrollIntoView({ behavior: 'smooth' })
            }, 200)
        },
        shrinkDiv() {
            this.selectedHabit = null

            setTimeout(() => {
                let element = document.querySelector('.nutritionMealDialog')

                var sectionHeight = element.scrollHeight

                var elementTransition = element.style.transition
                element.style.transition = ''

                requestAnimationFrame(function () {
                    element.style.height = sectionHeight + 'px'
                    element.style.transition = elementTransition

                    requestAnimationFrame(function () {
                        element.style.height = 50 + 'vh'
                    })
                })
                element.setAttribute('data-collapsed', 'true')
            }, 50)
        },
    },
}
</script>

<style lang="scss" scoped>
.option-button__wrapper {
    margin: 10px;
}
::v-deep .v-dialog {
    overflow: scroll;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    margin: 0;
    max-height: 80vh;
    position: absolute;
    //min-height: 20vh;
    height: auto;
    bottom: 0;
    min-height: none;
    background: white;
    //max-height: 40vh;
}
::v-deep .extended-dialog {
    //max-height: 90vh !important;
}

.option-button {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background: lightgray;
        &-bulb {
            ::v-deep .v-image__image {
                width: 80px;
            }
            flex-direction: row;
        }
    }
    &__wrapper:hover {
        background: gray;
    }
}
</style>
