<template>
    <div>
        <v-row>
            <v-card-title class="white--text" v-if="!$vuetify.breakpoint.mobile">Letzte Bewertungen</v-card-title>
            <v-expansion-panels dark v-if="!$vuetify.breakpoint.mobile">
                <v-expansion-panel v-for="(feedback, index) of newestFeedbacks" :key="'newestFeedbacks' + index">
                    <v-expansion-panel-header
                        class="pa-2"
                        :class="{ primary: feedback.frage_1.kommentar || feedback.frage_2.kommentar }">
                        <v-col cols="6">
                            <span
                                class="font-weight-bold"
                                v-if="feedback.user && feedback.user.vorname && feedback.user.nachname"
                                >{{
                                    feedback.user.vorname.substring(0, 10) + ' ' + feedback.user.nachname.substring(0, 10)
                                }}</span
                            ><span v-else>Nutzer nicht gefunden </span>
                            hat
                            <span
                                class="font-weight-bold"
                                v-if="feedback.trainer && feedback.trainer.vorname && feedback.trainer.nachname">
                                {{
                                    feedback.trainer.vorname.substring(0, 10) +
                                    ' ' +
                                    feedback.trainer.nachname.substring(0, 10)
                                }}
                            </span>
                            <span v-else>Trainer nicht gefunden </span>
                            bewertet
                        </v-col>
                        <v-col cols="3">
                            <v-rating
                                label="Trainer"
                                :value="getStarAverageForTrainer(feedback.frage_1.sterne, 1)"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                half-increments
                                readonly></v-rating>
                        </v-col>
                        <v-col cols="3">
                            <v-rating
                                label="Trainingsplan"
                                :value="getStarAverageForTrainer(feedback.frage_2.sterne, 1)"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                half-increments
                                readonly></v-rating>
                        </v-col>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card-text class="text-left">
                            <v-row>
                                Bewertet am:
                                <span class="font-weight-bold">
                                    {{ ' ' + $moment(feedback.date * 1000).format('DD.MM.YYYY') }}
                                </span>
                            </v-row>
                            <v-row v-if="feedback.plan">
                                Bewerteter Trainingsplan:
                                <span class="font-weight-bold">
                                    {{ ' ' + feedback.plan.form.title }}
                                </span>
                            </v-row>
                            <v-row v-if="feedback.frage_1.kommentar">
                                <v-col cols="12">
                                    <span class="font-weight-bold">Bist Du mit Deinem Trainer zufrieden?</span>
                                </v-col>
                                <v-col cols="12">
                                    <span>{{ feedback.frage_1.kommentar }}</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="feedback.frage_2.kommentar">
                                <v-col cols="12">
                                    <span class="font-weight-bold">Bist Du mit Deinem Trainingsplan zufrieden?</span>
                                </v-col>
                                <v-col cols="12">
                                    <span>{{ feedback.frage_2.kommentar }}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row>
            <v-col cols="7">
                <v-card-title class="white--text">Trainer</v-card-title>
            </v-col>
            <v-col cols="5">
                <v-select label="sortieren" dark filled :items="sortItems" v-model="activeSorting"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                style="margin-top: 60px"
                :cols="$vuetify.breakpoint.mobile ? 6 : 4"
                :key="'trainer' + index"
                v-for="(trainer, index) in sortedTrainerList">
                <v-card
                    dark
                    @click="openDialog($event, trainer)"
                    hover
                    style="height: 100%"
                    class="d-flex flex-column justify-content-center text-center rounded-xl">
                    <v-avatar
                        style="position: absolute; top: -25%; left: 0; right: 0"
                        class="employee-manager__card-avatar ma-auto"
                        color="grey"
                        size="125">
                        <img
                            :src="
                                trainer.image
                                    ? '/img/profilbilder' + trainer.image
                                    : '/img/einmalig/imageUploadPlaceholderPFP.jpg'
                            " />
                    </v-avatar>
                    <v-card-title class="justify-content-center text-break" style="margin-top: 55px">
                        {{ trainer.vorname + ' ' + trainer.nachname }}</v-card-title
                    >
                    <v-card-text>{{ trainer.feedbacks.length }}x Bewertet worden.</v-card-text>
                    <span>
                        Trainer
                        <v-rating
                            :value="getStarAverageForTrainer(trainer.question1, trainer.feedbacks.length)"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            empty-icon="$ratingFull"
                            half-increments
                            readonly></v-rating>
                    </span>
                    <span>
                        Trainingsplan
                        <v-rating
                            :value="getStarAverageForTrainer(trainer.question2, trainer.feedbacks.length)"
                            color="yellow darken-3"
                            background-color="grey darken-1"
                            empty-icon="$ratingFull"
                            half-increments
                            readonly></v-rating>
                    </span>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            style="overflow-x: hidden !important"
            max-width="700px"
            v-model="dialog"
            content-class="workoutsFeedback__dialog"
            :origin="dialogOrigin">
            <v-card v-if="activeTrainer" dark class="d-flex flex-column justify-content-center text-center">
                <div>
                    <v-avatar
                        style="position: relative; left: 0; right: 0"
                        class="employee-manager__card-avatar mx-auto mt-2"
                        color="grey"
                        size="125">
                        <img
                            :src="
                                activeTrainer.image
                                    ? '/img/profilbilder' + activeTrainer.image
                                    : '/img/einmalig/imageUploadPlaceholderPFP.jpg'
                            " />
                    </v-avatar>
                    <v-card-title class="justify-content-center">
                        {{ activeTrainer.vorname + ' ' + activeTrainer.nachname }}</v-card-title
                    >
                    <v-card-text class="pa-0">{{ activeTrainer.feedbacks.length }}x Bewertet worden.</v-card-text>
                    <v-row class="ma-0">
                        <v-col cols="6">
                            <span>
                                Trainer
                                <v-rating
                                    :value="
                                        getStarAverageForTrainer(activeTrainer.question1, activeTrainer.feedbacks.length)
                                    "
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly></v-rating>
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <span>
                                Trainingsplan
                                <v-rating
                                    :value="
                                        getStarAverageForTrainer(activeTrainer.question2, activeTrainer.feedbacks.length)
                                    "
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly></v-rating>
                            </span>
                        </v-col>
                    </v-row>
                    <v-card-title class="white--text">Alle Bewertungen</v-card-title>
                </div>
                <v-expansion-panels dark>
                    <v-expansion-panel
                        v-for="(feedback, index) of activeTrainer.feedbacks"
                        :key="'activeTrainerFeedbacks' + index"
                        :class="{ primary: feedback.frage_1.kommentar || feedback.frage_2.kommentar }">
                        <v-expansion-panel-header>
                            <v-col cols="6">
                                <span v-if="feedback.user">{{
                                    feedback.user.vorname.substring(0, 10) + ' ' + feedback.user.nachname.substring(0, 10)
                                }}</span
                                ><span v-else>Nutzer nicht gefunden</span>
                            </v-col>
                            <v-col cols="3">
                                <v-rating
                                    v-if="!$vuetify.breakpoint.mobile"
                                    :value="getStarAverageForTrainer(feedback.frage_1.sterne, 1)"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly></v-rating>
                                    <span v-else class="font-weight-bold white--text">{{ getStarAverageForTrainer(feedback.frage_1.sterne, 1) }}<v-icon color="yellow darken-3">mdi-star</v-icon></span>
                            </v-col>
                            <v-col cols="3">
                                <v-rating
                                v-if="!$vuetify.breakpoint.mobile"
                                    :value="getStarAverageForTrainer(feedback.frage_2.sterne, 1)"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly></v-rating>
                                    <span v-else class="font-weight-bold white--text">{{ getStarAverageForTrainer(feedback.frage_1.sterne, 1) }}<v-icon color="yellow darken-3">mdi-star</v-icon></span>
                            
                            </v-col>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text class="text-left">
                                <v-row>
                                    Bewertet am:
                                    <span class="font-weight-bold">
                                        {{ ' ' + $moment(feedback.date * 1000).format('DD.MM.YYYY') }}
                                    </span>
                                </v-row>
                                <v-row v-if="feedback.plan">
                                    Bewerteter Trainingsplan:
                                    <span class="font-weight-bold">
                                        {{ ' ' + feedback.plan.form.title }}
                                    </span>
                                </v-row>
                                <v-row v-if="feedback.frage_1.kommentar">
                                    <v-col cols="12">
                                        <span class="font-weight-bold">Bist Du mit Deinem Trainer zufrieden?</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>{{ feedback.frage_1.kommentar }}</span>
                                    </v-col>
                                </v-row>
                                <v-row v-if="feedback.frage_2.kommentar">
                                    <v-col cols="12">
                                        <span class="font-weight-bold">Bist Du mit Deinem Trainingsplan zufrieden?</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <span>{{ feedback.frage_2.kommentar }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import api from '../../../api'

export default {
    data() {
        return {
            activeSorting: 'newest',
            newestFeedbacks: [],
            trainers: null,
            activeTrainer: null,
            dialog: null,
            dialogOrigin: null,
            sortItems: [
                {
                    text: 'Neueste',
                    value: 'newest',
                },
                {
                    text: 'Anzahl Bewertungen ↑',
                    value: 'countup',
                },
                {
                    text: 'Anzahl Bewertungen ↓',
                    value: 'countdown',
                },
                {
                    text: 'Bewertung ↑',
                    value: 'ratingup',
                },
                {
                    text: 'Bewertung ↓',
                    value: 'ratingdown',
                },
            ],
        }
    },
    methods: {
        openDialog(element, trainer) {
            this.activeTrainer = trainer
            const { top, left, width, height } = element.target.getBoundingClientRect()
            this.dialogOrigin = left - 50 + 'px' + ' ' + top - 50 + 'px'
            this.dialog = true
        },
        getStarAverageForTrainer(count, length) {
            return Math.round(count / length)
        },
    },
    computed: {
        sortedTrainerList() {
            if (this.trainers && this.trainers.length) {
                if (this.activeSorting && this.activeSorting !== 'newest') {
                    return JSON.parse(JSON.stringify(this.trainers)).sort((a, b) => {
                        if (this.activeSorting === 'ratingdown') {
                            return (
                                this.getStarAverageForTrainer(b.question1, b.feedbacks.length) -
                                this.getStarAverageForTrainer(a.question1, a.feedbacks.length)
                            )
                        } else if (this.activeSorting === 'ratingup') {
                            return (
                                this.getStarAverageForTrainer(a.question1, a.feedbacks.length) -
                                this.getStarAverageForTrainer(b.question1, b.feedbacks.length)
                            )
                        } else if (this.activeSorting === 'countdown') {
                            return b.feedbacks.length - a.feedbacks.length
                        } else if (this.activeSorting === 'countup') {
                            return a.feedbacks.length - b.feedbacks.length
                        }
                    })
                }
                return this.trainers
            }
        },
    },
    async created() {
        this.newestFeedbacks = await api.getWorkoutFeedbackLatest()
        this.trainers = await api.getWorkoutFeedback()
        this.$emit('finishedloading')
    },
    beforeDestroy() {},
}
</script>
<style scoped>
::v-deep .mdi-star,
::v-deep .mdi-star-half-full {
    padding: 0px !important;
}

.workoutsFeedback__dialog {
    overflow-x: hidden !important;
    border-radius: 24px !important;
}
::v-deep .v-dialog {
    transition: 0.75s cubic-bezier(0.25, 0.8, 0.25, 1);
}
</style>
