<template>
	<swiper ref="viewtProblemSlider" :options="workoutSwiperOptions">
		<swiper-slide class="viewProblem__swiper my-5">
			<v-card class="viewProblem__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div class="wrapper">
					<v-card-title class="viewProblem__swiper-card-title h5 primary--text justify-left">
						Übersicht der offenen Meldungen
					</v-card-title>
					<v-list>
						<v-list-group v-for="(problemes, topic) of problems" :key="topic" :value="true">
							<template v-slot:activator>
								<v-list-item-title
									>{{ topic }} ({{ problemes.filter((prob) => prob.rep.status == 'Offen').length }})</v-list-item-title
								>
							</template>

							<v-list-group v-for="(problem, i) of problemes" :key="i" :value="true" no-action sub-group>
								<template v-slot:activator>
									<v-list-item-content>
										<v-list-item-title> {{ problem.rep.title }} </v-list-item-title>
									</v-list-item-content>
								</template>
								<v-list-item> </v-list-item>
							</v-list-group>
						</v-list-group>
					</v-list>
					<!---    <div v-for="(problem, y) of problems" :key="y" class="viewProblem__entry">
            <v-text class="viewProblem__entry-ts">{{ formatTime(problem.created) }} </v-text><v-text class="viewProblem__entry-description">{{ problem.title }}</v-text>
          </div> --->
				</div>
				<v-card-actions class="justify-center">
					<v-btn color="primary" rounded class="text-white" @click="slideTo(1)"> WEITER </v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>
	</swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import api from '../../api'
import 'swiper/css/swiper.css'
export default {
	components: { Swiper, SwiperSlide },
	data() {
		return {
			problems: null,
		}
	},
	async created() {
		await this.loadSortedProblems()
	},
	methods: {
		timeAgo(ts) {
			return this.$moment.unix(ts).fromNow()
		},
		formatTime(ts) {
			return this.$moment.unix(ts).format('DD.MM.YYYY HH:MM')
		},

		async loadSortedProblems() {
			this.problems = await api.loadSortedProblems()
		},

		setSwiperAllowTouchMove(value) {
			this.$refs.viewProblemSlider.$swiper.allowTouchMove = value
		},
		slideTo(slide) {
			this.setSwiperAllowTouchMove(false)
			this.$refs.viewProblemSlider.$swiper.slideTo(slide, 1000, false)
		},
	},
	computed: {
		workoutSwiperOptions: function() {
			return {
				allowTouchMove: false,
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card {
	transition: opacity 0.4s ease-in-out;
}
.wrapper {
	overflow: auto;
}
.viewProblem {
	p {
		margin-bottom: 5px;
	}
	&__entry {
		display: flex;
		flex-direction: column;
		padding: 10px 15px;
		text-align: left;

		&-ts {
			background-color: var(--v-primary);
			padding: 5px 15px;
			color: #fff;
			margin-bottom: 5px;
		}
		&-description {
			padding: 5px 15px;
		}
	}
	&__swiper {
		width: 90% !important;
		height: 90% !important;
		margin-left: 5%;
		margin-right: 5%;

		&-card {
			text-align: -webkit-center;
			height: 80vh;
			min-height: 500px;

			margin: auto;

			&-wrapper {
				overflow: auto;
				scroll-behavior: smooth;
			}

			&-content {
				overflow-y: auto;
				height: 62vh;
			}
			&-image {
				margin: 0 10%;
				height: 40%;
				background-size: contain;
			}

			&-form {
				width: 80%;
			}

			&-title,
			&-subtitle {
				word-break: break-word;
				text-align: -webkit-center;
			}

			&-title {
				padding-top: 20px;
				padding-bottom: 5px;
			}

			&-subtitle {
				padding-top: 20px;
				padding-bottom: 0px;
			}
		}
	}
}
</style>
