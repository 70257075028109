<template>
    <div class="workingout-submit primary" style="overflow: scroll">
        <v-card-title class="h5 white--text justify-center text-uppercase" style="word-break: break-word"
            >Training abschliessen</v-card-title
        >
        <div class="mt-0">
            <v-row class="mx-5">
                <v-card-title class="h6 white--text justify-center" style="width: 100%"
                    >Wie intensiv war dein Training?</v-card-title
                >
                <v-col
                    class="d-flex flex-column align-items-center pa-0"
                    :key="index"
                    v-for="(item, index) in intensityItems"
                    cols="3">
                    <v-btn icon @click="startAnimation(item)">
                        <lottie-vue-player
                            mode="normal"
                            style="width: 45px; height: 45px; background-color: transparent"
                            class="bottom-navigation__item-lottie"
                            :ref="'btn ' + item.text"
                            :name="item.text"
                            :autoplay="false"
                            :src="item.icon"></lottie-vue-player>
                    </v-btn>
                    <div style="min-height: 30px">
                        <span class="white--text" v-if="activeIntensity === item.value">
                            {{ item.text }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mx-5 mt-4">
                <v-card-title class="h5 white--text justify-center mb-0 pb-0" style="width: 100%">Feedback</v-card-title>
                <v-textarea
                    dark
                    v-model="trainingFeedback"
                    placeholder="Herzlichen Glückwunsch zu deinem erfolgreichen Abschluss des Trainingsplans! Wir freuen uns auf dein Feedback."></v-textarea>
            </v-row>
            <v-row class="pt-1" style="margin-bottom: 200px">
                <v-col cols="12" class="d-flex flex-column justify-center">
                    <v-card-actions class="d-flex flex-column justify-center">
                        <v-btn class="mb-2 white--text mt-7" color="rgb(49, 49, 49)" rounded x-large @click="submitWorkout"
                            >Abschliessen</v-btn
                        >
                        <v-btn class="workingout-submit-button mt-2" color="white" text @click="cancelWorkout"
                            >ohne Speichern beenden
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../api'

export default {
    name: 'WorkingoutExerciseSubmitSlide',
    props: {
        workout: {
            type: Object,
            required: true,
        },
        exercises: {
            type: Array,
            required: true,
        },
        workoutId: {
            type: String,
        },
    },
    created() {},
    data() {
        return {
            activeIntensity: 'moderate',
            animations: [],
            trainingFeedback: '',
            caloriesBurnedPerMin: {
                light: 6,
                moderate: 6.5,
                high: 7.6,
                veryHigh: 8.3,
            },
            intensityItems: [
                {
                    icon: '/img/lottiefiles/mytraining/intensityLight.json',
                    text: 'Leicht',
                    value: 'light',
                },
                {
                    icon: '/img/lottiefiles/mytraining/intensityNormal.json',
                    text: 'Mittel',
                    value: 'moderate',
                },
                {
                    icon: '/img/lottiefiles/mytraining/intensityHard.json',
                    text: 'Stark',
                    value: 'high',
                },
                {
                    icon: '/img/lottiefiles/mytraining/intensityExtreme.json',
                    text: 'sehr Stark',
                    value: 'veryHigh',
                },
            ],
        }
    },
    methods: {
        ...mapActions('workingoutStore', [
            'resetFinishedStepsArray',
            'setWorkoutCompletedInfos',
            'setSavedNote',
            'resetWorkoutStartTime',
        ]),
        async setNewStepStartValues() {
            for await (let [index, ex] of this.exercises.entries()) {
                console.log(index, ex)
                this.exercises[index].exercise_id = ex._id
            }
            /* this.exercises.forEach((ex, index) => {
			}) */

            for await (let step of this.finishedSteps) {
                let tempExerciseIndex = this.exercises.findIndex((ex) => step.exercise_id === ex._id)
                for await (let [stpIndex, stp] of step.steps.entries()) {
                    if (stp && stp.weight && stp.settings) {
                        let weightSetting = stp.settings.find((setting) => setting.text === 'Gewicht')
                        if ((weightSetting && weightSetting.value.toString() !== stp.weight.toString()) || stp.forceValue) {
                            if (
                                this.exercises[tempExerciseIndex].sets[stpIndex] &&
                                this.exercises[tempExerciseIndex].sets[stpIndex].settings
                            ) {
                                for await (let [index, exSetting] of this.exercises[tempExerciseIndex].sets[
                                    stpIndex
                                ].settings.entries()) {
                                    if (exSetting.text === 'Gewicht') {
                                        this.exercises[tempExerciseIndex].sets[stpIndex].settings[index].value = stp.weight
                                        if (stp.forceValue) {
                                            this.exercises[tempExerciseIndex].sets[0].settings[index].forceIT =
                                                stp.forceValue
                                            this.exercises[tempExerciseIndex].sets[0].settings[index].value = stp.forceValue
                                        }
                                        if (stp.forceIT) {
                                            this.exercises[tempExerciseIndex].sets[stpIndex].settings[index].value =
                                                stp.foceIT
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                /* step.steps.forEach((stp, stpIndex) => {
					if (stp && stp.weight && stp.settings) {
						let weightSetting = stp.settings.find((setting) => setting.text === 'Gewicht')
						if ((weightSetting && weightSetting.value.toString() !== stp.weight.toString()) || stp.forceValue) {
							if (this.exercises[tempExerciseIndex].sets[stpIndex] && this.exercises[tempExerciseIndex].sets[stpIndex].settings) {
								this.exercises[tempExerciseIndex].sets[stpIndex].settings.forEach((exSetting, index) => {
									if (exSetting.text === 'Gewicht') {
										console.log(stp.forceValue, this.exercises[tempExerciseIndex].sets[0], index)
										if (stp.forceValue) this.exercises[tempExerciseIndex].sets[0].settings[index].value = stp.forceValue
										console.log(this.exercises[tempExerciseIndex].sets[0].settings[index].value)
										this.exercises[tempExerciseIndex].sets[stpIndex].settings[index].value = stp.weight
									}
								})
							}
						}
					}
				})
			} */
                /*
			this.finishedSteps.forEach((step, stepIndex) => {
				let tempExerciseIndex = this.exercises.findIndex((ex) => step.exercise_id === ex._id)
				step.steps.forEach((stp, stpIndex) => {
					if (stp && stp.weight && stp.settings) {
						let weightSetting = stp.settings.find((setting) => setting.text === 'Gewicht')
						if ((weightSetting && weightSetting.value.toString() !== stp.weight.toString()) || stp.forceValue) {
							if (this.exercises[tempExerciseIndex].sets[stpIndex] && this.exercises[tempExerciseIndex].sets[stpIndex].settings) {
								this.exercises[tempExerciseIndex].sets[stpIndex].settings.forEach((exSetting, index) => {
									if (exSetting.text === 'Gewicht') {
										console.log(stp.forceValue, this.exercises[tempExerciseIndex].sets[0], index)
										if (stp.forceValue) this.exercises[tempExerciseIndex].sets[0].settings[index].value = stp.forceValue
										console.log(this.exercises[tempExerciseIndex].sets[0].settings[index].value)
										this.exercises[tempExerciseIndex].sets[stpIndex].settings[index].value = stp.weight
									}
								})
							}
						}
					}
				})
			})
			*/
            }
        },
        startAnimation(item) {
            this.activeIntensity = item.value
            this.$refs['btn ' + item.text][0].stop()
            this.$refs['btn ' + item.text][0].togglePlayPause()
        },
        addAnimation(animation) {
            this.animations.push(animation)
        },
        cancelWorkout() {
            if (confirm('Bist du sicher, dass du ohne Speichern beenden möchtest?')) {
                this.setSavedNote(null)
                this.resetFinishedStepsArray()
                this.resetWorkoutStartTime()
                this.$router.push({ name: 'Workouts' })
            }
        },
        async submitWorkout() {
            let endTime = this.$moment()
            if (endTime.isBefore(this.workoutStartTime)) {
                endTime.add(1, 'day')
            }

            let duration = endTime.diff(this.$moment(this.workoutStartTime, 'HH:mm:ss'), 'seconds')
            let minutes = endTime.diff(this.$moment(this.workoutStartTime, 'HH:mm:ss'), 'minutes', true)

            let caloriesBurned = this.caloriesBurnedPerMin[this.activeIntensity] * minutes

            caloriesBurned = caloriesBurned.toFixed(1)
            let d = Number(duration)
            var h = Math.floor(d / 3600)
            var m = Math.floor((d % 3600) / 60)
            var s = Math.floor((d % 3600) % 60)
            d = ''
            if (h > 0) d = d = h + ':'
            if (!m) d = d + '00:'
            if (m >= 10) d = d + m + ':'
            if (m < 10 && m > 0) d = d + '0' + m + ':'

            if (!s) d = d + '00'
            if (s >= 10) d = d + s
            if (s < 10 && s > 0) d = d + '0' + s

            duration = d

            let liftedWeight = 0
            let allRepsTogether = 0

            this.finishedSteps.forEach((exercice) => {
                exercice.steps.forEach((step) => {
                    if (step.weight) {
                        allRepsTogether = allRepsTogether + step.reps
                        liftedWeight += step.weight * step.reps
                    }
                })
            })

            let averageWeightLifted = liftedWeight / allRepsTogether

            let cowCount = liftedWeight / 800
            await this.setNewStepStartValues()
            this.setWorkoutCompletedInfos({ cowCount, duration, liftedWeight, minutes, caloriesBurned })

            await api.updateTrainingsPlanSets({ workoutId: this.$route.params.id, exercises: this.exercises })
            api.createWorkoutSet({
                sets: this.finishedSteps,
                workout: this.$route.params.id,
                caloriesBurned,
                duration,
                liftedWeight,
                averageWeightLifted,
                intensity: this.activeIntensity,
                feedBack: this.trainingFeedback,
            }).then((data) => {
                this.setSavedNote(null)
                this.resetFinishedStepsArray()
                this.resetWorkoutStartTime()
                this.$router.push({ name: 'WorkoutCompleted' })
            })
        },
    },
    computed: {
        ...mapState('workingoutStore', ['finishedSteps', 'workoutStartTime']),
    },
}
</script>
<style lang="scss" scoped>
.workingout-submit {
    height: 100%;
    width: 100%;
    background: white;

    &-button {
        background: var(--v-primary) !important;
        border-color: white !important;
        border-width: 4px;
    }
}
</style>
