<template>
    <v-card dark max-width="700px" class="mx-auto mb-15 mt-5" flat>
        <v-row no-gutters>
            <v-col cols="12">
                <v-alert
                    class="mx-5 mt-5"
                    :type="status === 'rejected' ? 'error' : status === 'created' ? 'info' : 'success'"
                    v-if="statusMap[status]"
                >
                    Upgrades nach Status: {{ statusMap[status] }}
                </v-alert>
            </v-col>
            <v-col class="py-0" cols="12">
                <v-select
                    label="Status wechseln"
                    class="mx-5"
                    v-model="selectedStatus"
                    item-text="name"
                    item-value="value"
                    @change="pushToRoute($event)"
                    :items="selectedStatusItems"
                ></v-select>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col class="py-0" cols="12">
                <v-select
                    class="px-5"
                    multiple
                    :items="studios"
                    label="Studio auswählen"
                    v-model="studioSelect"
                    item-text="name"
                    item-value="_id"
                ></v-select>
            </v-col>

            <v-col
                class="mt-0 pt-0 d-flex justify-center"
                v-if="userService.hasRight('c_create') || userService.hasRight('c_open_contracts')"
                cols="12"
            >
                <v-btn
                    class="mt-0 pt-0 mx-auto"
                    @click="
                        () => {
                            if (studioSelect.length === studios.length) {
                                studioSelect = []
                            } else {
                                studioSelect = studios.map((e) => e._id)
                            }
                        }
                    "
                    text
                >
                    <span class="pr-1">Alle Studios</span>
                    <span v-if="studioSelect.length === studios.length">abwählen</span>
                    <span v-else>anwählen</span>
                </v-btn>
            </v-col>
            <v-col align="center" v-if="status === 'accepted' && userService.hasRight('c_open_contracts')" cols="12">
                <v-btn @click="downloadExcelList" color="orange">
                    <span v-if="!showExcelList">Liste 3 Jahres Abo</span>
                    <span v-else>zurück</span>
                </v-btn>
            </v-col>
            <v-col align="center" v-if="status === 'accepted' && userService.hasRight('c_open_contracts')" cols="12">
                <v-btn @click="downloadExcelList2Years" color="orange">
                    <span v-if="!showExcelList2Years">Liste 2 Jahres Abo</span>
                    <span v-else>zurück</span>
                </v-btn>
            </v-col>
            <v-col v-if="!showExcelList && !showExcelList2Years" cols="12" align="center">
                <v-btn color="primary" @click="showContractDialog = true">Upgrade erstellen</v-btn>
            </v-col>

            <v-col cols="12" v-if="!showExcelList && !showExcelList2Years && !fetchingInfo && contracts.length > 0">
                <v-expansion-panels>
                    <v-expansion-panel v-for="(upgrade, index) of contracts" :key="index + '-upgrade-' + status">
                        <v-expansion-panel-header>
                            {{ index + 1 }}) {{ upgrade[0].studio.name }} | {{ upgrade[0].customer.selection.vorname }}
                            {{ upgrade[0].customer.selection.nachname }} |
                            {{ $moment(upgrade[upgrade.length - 1].createdAt).format('DD.MM.YYYY HH:mm') }} Uhr
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-expansion-panels>
                                <v-expansion-panel
                                    v-for="(upgradeContract, index2) of upgrade"
                                    :key="index + '-' + index2 + '-upgrade'"
                                >
                                    <v-expansion-panel-header
                                        :style="
                                            upgradeContract.status === 'rejected'
                                                ? 'background-color: red'
                                                : upgradeContract.status === 'created'
                                                ? 'background-color: orange'
                                                : upgradeContract.status === 'deleted'
                                                ? 'background-color: grey'
                                                : 'background-color: var(--v-primary)'
                                        "
                                    >
                                        {{ $moment(upgradeContract.createdAt).format('DD.MM.YYYY HH:mm') }} Uhr |
                                        {{ statusMap[upgradeContract.status] }} |
                                        {{ upgradeContract.possibilities.roodyMembershipName }} bis
                                        {{ $moment(upgradeContract.possibilities.endDate).format('DD.MM.YYYY') }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row no-gutters>
                                            <v-col cols="12" align="center" class="mt-2">
                                                <v-btn color="primary" @click="downloadPDF(upgradeContract._id)">
                                                    PDF herunterladen
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-simple-table class="mx-auto mt-4" style="max-width: 400px">
                                                    <template v-slot:default>
                                                        <tbody>
                                                            <tr
                                                                v-for="(pck, indexP) of upgradeContract.packages"
                                                                :key="index + '-' + index2 + '-' + indexP + '-packages'"
                                                            >
                                                                <td>
                                                                    <span
                                                                        class="mr-1"
                                                                        v-if="pck.amountSelectable && pck.amount"
                                                                    >
                                                                        {{ pck.amount }}x
                                                                    </span>
                                                                    {{ pck.packageName }}
                                                                </td>
                                                                <td align="right">{{ displayCurrency(pck.price) }}</td>
                                                            </tr>
                                                            <tr
                                                                style="
                                                                    background-color: var(--v-primary);
                                                                    font-weight: bold;
                                                                    letter-spacing: 0.8px;
                                                                "
                                                                class="white--text"
                                                            >
                                                                <td>Gesamt</td>
                                                                <td align="right">
                                                                    {{ upgradeContract.backendPriceString }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>
                                            <v-col align="center" cols="12">
                                                <v-simple-table class="mx-auto mt-4" style="max-width: 400px">
                                                    <template v-slot:default>
                                                        <tbody>
                                                            <tr>
                                                                <td>Visum</td>
                                                                <td align="right">
                                                                    {{
                                                                        upgradeContract.customer.contractCreatorInfos
                                                                            .firstName
                                                                    }}
                                                                    {{
                                                                        upgradeContract.customer.contractCreatorInfos
                                                                            .lastName
                                                                    }}
                                                                    <span v-if="false">
                                                                        (
                                                                        {{
                                                                            upgradeContract.customer
                                                                                .contractCreatorInfos.email
                                                                        }})
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Zahlung</td>
                                                                <td align="right">
                                                                    {{ upgradeContract.payment }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>
                                            <v-col cols="12" class="mt-5">
                                                <v-card-subtitle class="text-center">
                                                    Unterschrift Kunde
                                                </v-card-subtitle>
                                            </v-col>
                                            <v-col align="center" cols="12">
                                                <v-img
                                                    style="background-color: white"
                                                    max-width="250px"
                                                    :src="upgradeContract.signature"
                                                ></v-img>
                                            </v-col>
                                            <v-col cols="12" v-if="upgradeContract.customer.isUnderage" class="mt-5">
                                                <v-card-subtitle class="text-center">
                                                    Gesetzlicher Vertreter
                                                </v-card-subtitle>
                                            </v-col>
                                            <v-col v-if="upgradeContract.customer.isUnderage" align="center" cols="12">
                                                <v-card-text class="mb-0 pb-0">
                                                    {{ upgradeContract.customer.legalRepresentative.firstName }}
                                                    {{ upgradeContract.customer.legalRepresentative.lastName }} ({{
                                                        upgradeContract.customer.legalRepresentative.email
                                                    }})
                                                </v-card-text>
                                                <v-card-text class="mt-0 pt-0">
                                                    {{ upgradeContract.customer.legalRepresentative.address }}
                                                </v-card-text>
                                            </v-col>
                                            <v-col v-if="upgradeContract.customer.isUnderage" align="center" cols="12">
                                                <v-img
                                                    style="background-color: white"
                                                    max-width="250px"
                                                    :src="upgradeContract.signatureLegalRepresentative"
                                                ></v-img>
                                            </v-col>
                                            <v-col v-if="upgradeContract.paymentComment" cols="12">
                                                <v-card-subtitle class="mt-5 mb-0 pb-0 font-weight-bold">
                                                    Notiz
                                                </v-card-subtitle>
                                                <v-card-text>{{ upgradeContract.paymentComment }}</v-card-text>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-card-subtitle class="mb-0 pb-0 font-weight-bold">
                                                    Kommentare
                                                </v-card-subtitle>
                                            </v-col>
                                            <v-col
                                                v-for="comment of upgradeContract.comments"
                                                :key="comment.time + '-comment'"
                                                cols="12"
                                                class="mb-0"
                                            >
                                                <v-card-text class="mb-0 pb-0">
                                                    {{ $moment(comment.time).format('DD.MM.YY HH:mm') }} Uhr
                                                </v-card-text>
                                                <v-card-text class="mt-0 pt-0">
                                                    {{ comment.comment }}
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-textarea
                                                    class="mx-5"
                                                    v-model="upgradeContract.comment"
                                                    type="text"
                                                    label="neuer Kommentar"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col align="center" cols="12">
                                                <v-btn
                                                    @click="addComment(upgradeContract._id, upgradeContract.comment)"
                                                    color="primary"
                                                >
                                                    Kommentar speichern
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" align="center" class="mt-5 mb-3">
                                                <v-btn text @click="deleteEntry(upgradeContract._id)">
                                                    Upgrade löschen
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" align="center">
                                                <v-btn
                                                    @click="updateStatus(upgradeContract._id, 'rejected')"
                                                    class="mr-2"
                                                    color="red"
                                                >
                                                    Ablehnen
                                                </v-btn>
                                                <v-btn
                                                    v-if="upgradeContract.status === 'rejected'"
                                                    @click="showContractDialog = true"
                                                    class="ml-2"
                                                    color="primary"
                                                >
                                                    Neu erstellen
                                                </v-btn>
                                                <v-btn
                                                    v-else
                                                    :disabled="!userService.hasRight('c_open_contracts')"
                                                    @click="updateStatus(upgradeContract._id, 'accepted')"
                                                    class="ml-2"
                                                    color="primary"
                                                >
                                                    Akzeptieren
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" v-else-if="showExcelList || showExcelList2Years">
                <v-alert class="mx-5" type="info">
                    Abo Liste für:
                    {{ studioSelect.map((e) => studios.find((c) => c._id === e).name).join(', ') }}
                </v-alert>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Vorname</th>
                                <th class="text-left">Nachname</th>
                                <th class="text-left">Abo</th>
                                <th class="text-left">Abo Ende</th>
                                <th class="text-left">Pakete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of excelListEntries" :key="item.name">
                                <td>{{ item.firstName }}</td>
                                <td>{{ item.lastName }}</td>
                                <td>{{ item.membershipName }}</td>
                                <td>{{ $moment(item.endDate).format('DD.MM.YYYY') }}</td>
                                <td>{{ item.packages }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" v-else-if="fetchingInfo" align="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else>
                <v-alert type="info" class="mx-5">Keine Upgrades gefunden</v-alert>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" align="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
        <upgrade-contract
            @hide="showContractDialog = false"
            @created="fetchInfos"
            :dialog="showContractDialog"
        ></upgrade-contract>
    </v-card>
</template>
<script>
import userService from '@/services/userService'
import upgradeContract from './upgradeContract.vue'
import { roundToNearestFiveCents } from '@/moduls/currency/currency.js'
import api from '@/api'
export default {
    components: { upgradeContract },
    data() {
        return {
            showExcelList: false,
            showExcelList2Years: false,
            excelListEntries: [],
            showContractDialog: false,
            fetchingInfo: false,
            loading: true,
            userService,
            selectedStatus: null,
            selectedStatusItems: [
                { name: 'Offen', value: 'created' },
                { name: 'Abgelehnt', value: 'rejected' },
                { name: 'Akzeptiert', value: 'accepted' },
            ],
            studios: null,
            studioSelect: [],
            contracts: [],
            statusMap: {
                accepted: 'Akzeptiert',
                created: 'Offen',
                rejected: 'Abgelehnt',
                deleted: 'Gelöscht',
            },
        }
    },
    created() {
        this.init()
        this.selectedStatus = this.status
    },
    methods: {
        downloadExcelList2Years() {
            this.showExcelList = false
            this.excelListEntries = []
            if (this.showExcelList2Years) {
                this.showExcelList2Years = false
                return
            }
            let contractsTemp = []
            this.contracts.forEach((c) => {
                c.forEach((contract) => {
                    let durationCheck = parseInt(contract.possibilities.duration.years) === 2
                    if (durationCheck) {
                        contractsTemp.push({
                            firstName: contract.customer.selection.vorname,
                            lastName: contract.customer.selection.nachname,
                            membershipName: contract.possibilities.membershipName,
                            endDate: contract.possibilities.endDate,
                            packages: contract.packages.map((e) => e.packageName).join(', '),
                        })
                    }
                })
            })

            this.excelListEntries = contractsTemp
            this.showExcelList2Years = true
        },
        downloadExcelList() {
            this.showExcelList2Years = false
            this.excelListEntries = []
            if (this.showExcelList) {
                this.showExcelList = false
                return
            }
            let contractsTemp = []
            this.contracts.forEach((c) => {
                c.forEach((contract) => {
                    let durationCheck = parseInt(contract.possibilities.duration.years) === 3
                    if (durationCheck) {
                        contractsTemp.push({
                            firstName: contract.customer.selection.vorname,
                            lastName: contract.customer.selection.nachname,
                            membershipName: contract.possibilities.membershipName,
                            endDate: contract.possibilities.endDate,
                            packages: contract.packages.map((e) => e.packageName).join(', '),
                        })
                    }
                })
            })

            this.excelListEntries = contractsTemp
            this.showExcelList = true
        },
        downloadPDF(id) {
            api.downloadContractUpgradePDF(id)
        },
        pushToRoute(event) {
            this.$router.push('/dashboard/contract/upgrade/' + event)
        },
        async deleteEntry(id) {
            if (confirm('Möchtest du das Upgrade wirklich löschen?')) {
                this.updateStatus(id, 'deleted')
            }
        },
        async addComment(id, comment) {
            try {
                if (!comment.length > 3) {
                    this.$toast.error('Bitte verfasse einen Kommentar')
                    return
                }
                let contract = this.contracts.flat().find((e) => e._id.toString() === id.toString())
                if (!contract.comments) contract.comments = []
                let newComment = {
                    time: this.$moment().format(),
                    comment,
                    commentedBy: this.userService.getUserId(),
                }
                let status = await api.addCommentContractUpgrade({ data: { _id: id, comment: newComment } })
                contract.comments.push(newComment)
                contract.comment = ''
                this.$toast.success('Kommentar gespeichert')
            } catch (e) {
                this.$toast.error('Fehler beim Speichern des Kommentars')
            }
            console.log(id, comment)
        },
        async updateStatus(id, statusId) {
            try {
                let status = await api.updateContractUpgradeStatus({ data: { _id: id, status: statusId } })
                this.$toast.success('Upgrade wurde ' + this.statusMap[statusId])
            } catch (e) {
                this.$toast.error('Ein Fehler ist aufgetreten')
            }
            this.fetchInfos()
        },
        displayCurrency(amount) {
            return roundToNearestFiveCents(amount)
        },
        async init() {
            this.loading = true
            let filterStudioList = ['ot', 'alibey']
            let studios = await api.fetchStudios()

            this.studios = studios.filter((e) => !filterStudioList.includes(e.code))
            let userStudio = await api.getUserStudio()
            this.studioSelect.push(userStudio.studio._id)
            this.loading = false
        },
        async fetchInfos() {
            this.showExcelList = false
            this.contracts = []
            this.showContractDialog = false
            this.fetchingInfo = true
            let studios = this.studioSelect
            if (!studios) {
                this.fetchingInfo = false
                return
            }
            try {
                let upgradeInfos = await api.fetchUpgradeContracts({ data: { studios, status: this.status } })
                upgradeInfos.infos.forEach((e) => (e.comment = ''))
                this.contracts = upgradeInfos.infos
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim laden der Verträge')
            }

            this.fetchingInfo = false
        },
    },
    computed: {
        status() {
            const pathArray = this.$route.path.split('/')
            return pathArray[pathArray.length - 1]
        },
    },
    watch: {
        $route(newStatus, oldStatus) {
            this.fetchInfos()
            console.log('status chagned')
        },

        studioSelect() {
            this.fetchInfos()
        },
        status() {
            this.fetchInfos()
        },
    },
}
</script>