<template>
    <div class="videoarchiv">
        <div @click="addVideo()" class="new-video">Neues Video erfassen</div>
        <div class="videoeditor" v-show="ShowVideoEditor">
            <div class="ve-wrapper">
                <label>Vimeo-Link</label>
                <input placeholder="https://vimeo.com..." type="text" v-model="videoarchiv.link" />
            </div>
            <div class="ve-wrapper">
                <label>Titel</label>
                <input placeholder="..." type="text" v-model="videoarchiv.titel" />
            </div>
            <div class="ve-wrapper">
                <label>Kurs</label>
                <div v-show="videoarchiv.course_id.length > 0" class="selected-course">
                    {{ courseListNames[videoarchiv.course_id] }}
                </div>

                <div class="kurssuche-wrapper">
                    <input type="text" v-model="kurssuche" placeholder="Kurssuche...." />
                    <div class="searchKurs" @click="searchCourse">Suchen</div>
                </div>
                <div v-show="courseListFiltered.length > 0 && kurssuche.length > 0" class="kursliste-filtered">
                    <div @click="chooseKurs(kurs._id)" class="kurs-found" v-for="(kurs, i) of courseListFiltered" :key="i">
                        {{ kurs.name }}
                    </div>
                </div>

                <div v-show="kursnotfound" class="kursnotfound">Kurs wurde nicht gefunden</div>
            </div>
            <div class="ve-wrapper checkbox">
                <input type="checkbox" v-model="videoarchiv.active" />
                <span>Aktiv</span>
            </div>
            <div class="ve-wrapper">
                <label>Datum (optional)</label>
                <input type="date" v-model="videoarchiv.datum" />
            </div>
            <div @click="saveCourse" class="speichern">Speichern</div>
            <div @click="resetVideoEditor" class="schliessen">Abbrechen</div>
        </div>
        <div v-show="!ShowVideoEditor" class="video-wrapper">
            <div class="video-kurs" v-for="(k, i) in videoListGroup" :key="i">
                <span class="titel">{{ courseListNames[k] }}</span>

                <div :class="{ 'video-kurs-videos': 1, inactive: !kv.active }" v-for="(kv, j) in videoList[k]" :key="j">
                    <span class="video-titel">{{ kv.titel }}</span>
                    <div class="action-wrapper">
                        <span @click="deleteVideo(kv)" class="material-icons"> delete_outline </span>
                        <span @click="editVideo(kv)" class="material-icons">edit</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'
export default {
    data() {
        return {
            kurssuche: '',
            ShowVideoEditor: false,
            courseList: [],
            courseListNames: [],
            kursnotfound: false,
            courseListFiltered: [],
            videoList: [],
            videoListGroup: [],
            videoarchiv: {
                _id: null,
                link: '',
                titel: '',
                course_id: '',
                datum: '',
                active: '',
            },
        }
    },
    methods: {
        editVideo(kv) {
            this.videoarchiv = kv
            this.ShowVideoEditor = true
        },
        deleteVideo(kv) {},
        saveCourse() {
            if (
                this.videoarchiv.link.length > 0 &&
                this.videoarchiv.titel.length > 0 &&
                this.videoarchiv.course_id.length > 0
            ) {
                api.editVideoArchiv({ va: this.videoarchiv })
                    .then((data) => {
                        if ((data.status = 1)) {
                            alert('Erfolgreich gespeichert')
                            this.resetVideoEditor()
                            this.loadVideos()
                        } else {
                            alert('Es gab einen Fehler')
                        }
                    })
                    .catch((error) => {
                        alert('Es gab einen Fehler')
                    })
            } else {
                alert('Bitte fülle alle Daten aus')
            }
        },
        chooseKurs(kursid) {
            this.kurssuche = ''
            this.courseListFiltered = []
            this.videoarchiv.course_id = kursid
        },
        searchCourse() {
            this.kursnotfound = false
            this.courseListFiltered = this.courseList.filter((kurs) => {
                return kurs.name.toLowerCase().indexOf(this.kurssuche.toLowerCase()) > -1
            })
            if (!this.courseListFiltered.length > 0) {
                this.kursnotfound = true
            }
        },
        TimestampToDate(ts) {
            return new Date(new Date(ts * 1000)).toLocaleString()
        },
        loadVideos() {
            this.courseList = []
            this.courseListNames = []
            this.videoList = []
            this.videoListGroup = []
            api.getVideoArchivAdmin().then((va) => {
                this.courseList = va.courses
                for (let k of va.courses) {
                    this.courseListNames[k._id] = k.name
                }
                for (let v of va.videos) {
                    if (!this.videoList[v.course_id]) {
                        this.videoList[v.course_id] = []
                        this.videoListGroup.push(v.course_id)
                    }
                    this.videoList[v.course_id].push(v)
                }
                this.$emit('finishedloading')
            })
        },
        resetVideoEditor() {
            this.kursnotfound = false
            this.courseListFiltered = []
            this.kurssuche = ''
            this.ShowVideoEditor = false
            this.videoarchiv = {
                _id: null,
                link: '',
                titel: '',
                course_id: '',
                datum: '',
                active: '',
            }
        },
        addVideo() {
            this.resetVideoEditor()
            this.ShowVideoEditor = true
        },
    },

    created() {
        this.resetVideoEditor()
        this.loadVideos()
    },
}
</script>
<style lang="scss" scoped>
.videoarchiv {
    color: white;
    margin-bottom: 50px;
    label {
        font-weight: bold;
    }
    .video-wrapper {
        max-width: 500px;
        margin: auto;
        .video-kurs {
            margin: 10px 0px;
            .titel {
                font-weight: bold;
                padding: 5px 10px;
            }
            .video-kurs-videos {
                display: flex;
                justify-content: space-between;
                padding: 5px 15px;
                border-bottom: 1px solid #000;
                &.inactive {
                    background-color: #a7a7a7;
                }
                .video-titel {
                    line-height: 35px;
                }
                .action-wrapper {
                    span {
                        padding: 5px;
                    }
                }
            }
        }
    }
    .kursliste-filtered {
        max-height: 200px;
        overflow: scroll;
        margin-bottom: 10px;
        margin-top: 10px;
        .kurs-found {
            margin: 5px 0px;
            padding: 5px 0px;
        }
    }
    .selected-course {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .schliessen {
        text-align: center;
        background-color: #808080;
        color: #fff;
        border-radius: 4px;
        padding: 10px 0px;
        margin-top: 10px;
    }
    .speichern {
        text-align: center;
        background-color: var(--v-primary);
        color: #fff;
        border-radius: 4px;
        padding: 10px 0px;
        margin-top: 10px;
    }
    .new-video {
        color: white;
        background-color: var(--v-primary);
        text-align: center;
        cursor: pointer;
        padding: 10px 0px;
    }
    .videoeditor {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        margin: auto;
        padding: 20px;
        .ve-wrapper {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            padding-top: 10px;
            color: white;

            input[type='text'] {
                padding: 0 15px;
                width: 100%;
                height: 42px;
                border: none;
                box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
                font-size: 16px;
                background-color: #f5f5f5;

                background: #f5f5f5 !important;
            }

            .kurssuche-wrapper {
                flex-direction: row;
                input {
                    width: 75%;
                    display: inline-block;
                    margin-bottom: 0px !important;
                }
                div {
                    display: inline-block;
                    width: 25%;
                    color: #fff;
                    background-color: var(--v-primary);
                    text-align: center;

                    height: 42px;

                    line-height: 42px;
                }
            }

            &.checkbox {
                flex-direction: row;
                line-height: 22px;
                input {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
.back-navigation {
    background-color: var(--v-primary);
    color: white;
    padding: 0px 10px;
    font-weight: 500;
    display: flex;
    margin-bottom: 20px;

    span {
        margin-top: auto;
        margin-bottom: auto;
    }
    a {
        display: flex;
        padding: 5px;
        color: white;

        span {
            font-size: 34px;
        }
    }
}
</style>
