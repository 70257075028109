<template>
	<v-row class="flex-column" no-gutters>
		<v-select
			item-text="form.title"
			item-value="_id"
			@change="$emit('reTriggerHeight')"
			v-model="selectedPlan"
			label="Trainingsplan"
			placeholder="wähle deinen Plan"
			:items="workouts"
		></v-select>
		<div v-if="selectedPlan">
			<v-col class="h6 px-0">Wann möchtest du diesen Trainingsplan ausführen?</v-col>
			<v-row class="justify-space-around">
				<v-col class="px-0" v-for="(weekday, index) of weekdays" :key="index" cols="1">
					<v-btn @click="clickedWeekday(weekday)" icon :class="{ 'primary white--text': weekday.active }" class="lightgray"
						>{{ weekday.label }}
					</v-btn>
				</v-col>
			</v-row>
			<v-row v-if="anyWeekdayActive">
				<v-col cols="10" class="h6">Möchtest du eine Benachrichtigung erhalten</v-col>
				<v-col cols="1 py-0"><v-checkbox v-model="wantNotification" @click="$emit('reTriggerHeight')" color="primary"></v-checkbox></v-col>
				<v-col cols="12 py-0" v-if="wantNotification">
					<v-menu
						ref="menu"
						v-model="timePicker"
						:close-on-content-click="false"
						:nudge-right="40"
						:return-value.sync="notificatonTime"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="notificatonTime"
								label="Uhrzeit"
								prepend-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-time-picker
							v-if="timePicker"
							v-model="notificatonTime"
							full-width
							@click:minute="$refs.menu.save(notificatonTime)"
						></v-time-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" class="text-center"> <v-btn @click="saveNewHabit" color="primary" outlined>Speichern</v-btn></v-col>
			</v-row>
		</div>
	</v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import api from '@/api.js'

export default {
	name: 'habitWorkoutForm',
	props: {
		selectedHabit: {
			type: Object,
			required: true,
		},
		edit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			workouts: [],
			wantNotification: false,
			timePicker: false,
			notificatonTime: null,
			weekdays: [
				{ label: 'Mo', active: false },
				{ label: 'Di', active: false },
				{ label: 'Mi', active: false },
				{ label: 'Do', active: false },
				{ label: 'Fr', active: false },
				{ label: 'Sa', active: false },
				{ label: 'So', active: false },
			],
			selectedPlan: null,
			selectedDate: null,
			selectedTime: null,
		}
	},
	mounted() {},

	async created() {
		api.getTrainingPlans().then((data) => {
			this.workouts = data
		})
		if (this.edit) {
			this.selectedPlan = this.selectedHabit.workout._id
			this.wantNotification = this.selectedHabit.wantNotification
			this.selectedHabit.days.forEach((day) => (this.weekdays[day].active = true))
		}
	},
	methods: {
		clickedWeekday(weekday) {
			this.weekdays.forEach((day, index) => {
				if (day.label === weekday.label) {
					this.weekdays[index].active = !this.weekdays[index].active
				}
			})
			setTimeout(() => this.$emit('reTriggerHeight'), 50)
		},
		async saveNewHabit() {
			this.selectedHabit.time = this.notificatonTime
			this.selectedHabit.workout = this.selectedPlan
			this.selectedHabit.days = this.weekdays.filter((weekday) => weekday.active).map((weekday) => this.weekdays.indexOf(weekday))
			if (await this.selectedHabit.save()) {
				console.log('saved')
				this.$emit('habitSaved')
			} else {
				// habit not saved
			}
		},
	},
	computed: {
		anyWeekdayActive() {
			return this.weekdays.some((weekday) => weekday.active)
		},
	},
}
</script>

<style lang="scss" scoped></style>
