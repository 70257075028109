<template xmlns="">
    <v-window-item :value="value" style="background: white; height: 100vh; margin-bottom: 100px">
        <v-card-actions class="justify-center mt-10">
            <v-btn color="primary" @click="changeSorting">
                {{ sortActive ? 'Sortierung beenden' : 'Sortierung ändern' }}
            </v-btn>
        </v-card-actions>

        <v-card-title class="workoutCreate__swiper-card-content">
            <v-list rounded width="100%" style="min-height: 300px; max-height: 65vh" class="overflow-y-auto">
                <draggable v-show="edit" v-model="exerciseArray" @change="reSorted" :disabled="!sortActive">
                    <template v-for="(exercise, i) in exerciseArray">
                        <v-list-item class="workoutCreate__swiper-card-item" :key="i" ripple color="lightgrey">
                            <v-list-item-avatar>
                                <v-img :src="getImageUrl(exercise)" contain></v-img>
                            </v-list-item-avatar>
                            <v-list-item-title
                                :key="exercise.title"
                                class="h5 primary--text workoutCreate__swiper-card-item-title"
                                >{{ exercise.title }}</v-list-item-title
                            >
                        </v-list-item>
                    </template>
                </draggable>
                <draggable v-show="!edit" v-model="storeExercises" :disabled="!sortActive">
                    <template v-for="(exercise, i) in storeExercises">
                        <v-list-item class="workoutCreate__swiper-card-item" :key="i" ripple color="lightgrey">
                            <v-list-item-avatar>
                                <v-img :key="getImageUrl(exercise)" :src="getImageUrl(exercise)" contain></v-img>
                            </v-list-item-avatar>
                            <v-list-item-title
                                :key="exercise.title"
                                class="h5 primary--text workoutCreate__swiper-card-item-title"
                                >{{ exercise.title }}</v-list-item-title
                            >
                        </v-list-item>
                    </template>
                </draggable>
            </v-list>
        </v-card-title>
        <v-card-actions class="justify-center">
            <v-btn color="primary" rounded class="text-white" @click="$emit('save')">
                {{ this.$route.params.id ? 'Speichern' : 'Erstellen' }}
            </v-btn>
        </v-card-actions>
    </v-window-item>
</template>

<script>
import { SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'
export default {
    name: 'WorkoutCreateSubmitSlide',
    components: {
        SwiperSlide,
        draggable,
    },
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        exercises: {
            type: Array,
        },
        value: {
            type: Number,
        },
    },
    data() {
        return {
            sortActive: false,
            before: null,
            exerciseArray: [],
        }
    },
    methods: {
        ...mapActions('workingoutStore', ['setWorkoutCreateExercises']),
        changeSorting() {
            this.$emit('changeSorting', this.sortActive)
            this.sortActive = !this.sortActive
        },
        reSorted() {
            this.$emit('resorted', this.exerciseArray)
        },
        action(e) {
            if (edit) {
                if (e === 'edit') this.before = Object.assign([], this.exercises)
                if (e === 'undo') this.$emit('updateExercise', this.before)
            } else {
                if (e === 'edit') this.before = Object.assign([], this.workoutCreateExercises)
                if (e === 'undo') this.workoutCreateExercises = this.before
            }
            this.editing = !this.editing
        },
        getImageUrl(exercise) {
            if (exercise.picture) return '/img/uebungen/' + exercise.picture
            if (exercise.manual) return '/img/uebungen/' + exercise.manual[0].picture
            return '/img/uebungen/einmalig/imageUploadPlaceholder.jpg'
        },
    },
    computed: {
        ...mapState('workingoutStore', ['workoutCreateExercises']),
        storeExercises: {
            get() {
                return this.workoutCreateExercises
            },
            set(value) {
                this.setWorkoutCreateExercises(value)
                this.$emit('updateBottomNavigation', 'finish')
            },
        },
    },
    watch: {
        exercises() {
            if (this.exerciseArray !== this.exercises) {
                this.exerciseArray = JSON.parse(JSON.stringify(this.exercises))
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.h5 {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: -10px;
}

.draggable-flex {
    display: inherit;
    flex-direction: inherit;
}

.v-card {
    transition: opacity 0.4s ease-in-out;
}
.workoutCreate {
    &__swiper {
        height: 100vh !important;
        background: white;
        &-card {
            &-content {
                overflow-y: auto;
            }

            &-title {
                word-break: break-word;
            }

            &-item {
                width: 100%;
                background-color: gainsboro;

                &-title {
                    text-align: left;
                }
            }
        }
    }
}
</style>
