<template>
    <div>
        <v-row no-gutters>
            <v-col cols="12" v-if="finishedLoading">

                <v-card dark>
                    <v-select hide-details filled rounded v-model="selectedQuestion" label="Frage auswählen"
                        :items="statistics.map(stat => stat._id)"></v-select>

                    <v-text-field v-model="search" label="Suche" append-icon="mdi-magnify" clearable
                        class="mx-4"></v-text-field> <v-data-table v-if="selectedQuestion" :headers="headers"
                        :search="search" :items="statistics.find(stat => stat._id === selectedQuestion).answers"
                        item-value="id" class="elevation-1">
                        <template v-slot:item.answers="{ item }">
                            <span v-if="Array.isArray(item.answers)">
                                <span v-for="(answer, index) in item.answers" :key="index">
                                    {{ answer }}<span v-if="index < item.answers.length - 1">, </span>
                                </span>
                            </span>
                            <span v-else>
                                {{ item.answers }}
                            </span>
                        </template>

                        <template v-slot:item.additionalFieldAnswer="{ item }">
                            {{ getFirstValue(item.additionalFieldAnswer) }}
                        </template>

                        <template v-slot:item.birthday="{ item }">
                            {{ formatDate(item.birthday) }}
                        </template>
                    </v-data-table>


                </v-card>
            </v-col>
            <v-col cols="12">
                <apexchart :key="chartSeries[0].data[0]" type="bar" :options="chartOptions" :series="chartSeries"
                    v-if="apexChartsReady">
                </apexchart>
            </v-col>
            <v-col v-if="!finishedLoading" class="d-flex justify-center flex-grow">
                <v-progress-circular indeterminate color="white"></v-progress-circular>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import homeDashboardTrainerStatisticsDialog from './homeDashboardTrainerStatisticsDialog.vue'
export default {
    name: 'dashboard',
    props: {
        studios: {
            type: Array,
        },
        datePickerRange: {
            type: Array,
        },
        selectedStudioSingle: {
            type: String,
        },
    },
    components: {
        homeDashboardTrainerStatisticsDialog,
    },
    data() {
        return {
            chartColors: [
                '#004400', // Dunkles Grün
                '#1d791d',
                '#3aae3a',
                '#66ff66', // Helles Grün
                '#57dae4',
                '#2b6d94',
                '#0e245e',
                '#000044', // Dunkles Blau
                '#09003a',
                '#130030',
                '#26001d',
                '#300013',
                '#440000', // Dunkles Rot
            ],
            apexChartsReady: false,
            search: '',
            headers: [
                { text: "Antworten", value: "answers" },
                { text: "zus. Antwort", value: "additionalFieldAnswer" },
                { text: "Alter", value: "age" },
                { text: "Geburtstag", value: "birthday" },
                { text: "Geschlecht", value: "gender" },
                { text: "Studio", value: "studio" },
            ],
            selectedQuestion: null,
            statistics: [],
            finishedLoading: false,
            chartOptions: {
                chart: {
                    type: "bar",
                    toolbar: { show: true },
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: '#fff',
                            fontWeight: 700,
                            fontSize: "12px",
                        },
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 7.55,
                        horizontal: false,
                        columnWidth: "80%",
                        distributed: true, // Unterschiedliche Farben pro Balken
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val, opts) => {
                        console.log(val, opts)
                        // Berechne den Prozentwert

                        const total = opts.config.series[0].data.reduce((sum, current) => sum + current, 0);
                        const percentage = ((val / total) * 100).toFixed(2);
                        return `${val} (${percentage}%)`; // Zeige den absoluten Wert und den Prozentwert
                    },
                    style: {
                        colors: ["#FFF"],
                    },
                },
                legend: {
                    show: false, // Keine Legende, da Labels unterhalb der Balken angezeigt werden
                },
                colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"], // Ansprechende Farben
            },
            chartSeries: [
                {
                    name: "Antworten",
                    data: [], // Werte für die Y-Achse
                },]
        }
    },
    async created() {
        if (this.selectedStudioSingle) {
            this.statistics = await api.fetchAnamneseDashboardQuestions({
                range: this.datePickerRange,
                selectedStudios: this.studios.map((studio) => studio._id),
                studios: this.studios.map((studio) => {
                    return studio.anamneseSettings && studio.anamneseSettings.copiedStudio
                        ? studio.anamneseSettings.copiedStudio
                        : studio._id
                })
            })

            this.finishedLoading = true
        }
    },
    methods: {
        getFirstValue(array) {
            if (typeof array === "object") {
                return Object.values(array)[0];
            }
            return '';
        },
        formatDate(date) {
            return this.$moment(date).format('DD.MM.YYYY')
        }
    },
    computed: {},
    watch: {
        selectedQuestion() {
            this.apexChartsReady = false;
            const groupedData = this.statistics.find(stat => stat._id === this.selectedQuestion).answers
                .flatMap((item) =>
                    Array.isArray(item.answers) ? item.answers : [item.answers]
                )
                .reduce((acc, answer) => {
                    acc[answer] = (acc[answer] || 0) + 1;
                    return acc;
                }, {});

            if (Object.keys(groupedData).length > 15) {
                this.$toast.error('Zu viele Antworten um als Säulendiagramm darzustellen');
                return;
            }
            console.log('i ha gsetzt')
            this.$set(this.chartOptions.xaxis, 'categories', Object.keys(groupedData));
            this.$set(this.chartSeries[0], 'data', Object.values(groupedData));
            this.apexChartsReady = true;
        },
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.finishedLoading = false
                this.statistics = await api.fetchAnamneseDashboardQuestions({
                    range: this.datePickerRange,
                    selectedStudios: this.studios.map((studio) => studio._id),
                    studios: this.studios.map((studio) => {
                        return studio.anamneseSettings && studio.anamneseSettings.copiedStudio
                            ? studio.anamneseSettings.copiedStudio
                            : studio._id
                    })
                })
                this.finishedLoading = true
            }
        },
        studios: async function (studios) {
            this.finishedLoading = false
            this.statistics = await api.fetchAnamneseDashboardQuestions({
                range: this.datePickerRange,
                selectedStudios: this.studios.map((studio) => studio._id),
                studios: this.studios.map((studio) => {
                    return studio.anamneseSettings && studio.anamneseSettings.copiedStudio
                        ? studio.anamneseSettings.copiedStudio
                        : studio._id
                })
            })
            this.finishedLoading = true
        }
    },
}
</script>
<style lang="scss" scoped>
.anamnese-statistics {
    &__add-question {
        background: transparent;
        border: 5px dashed var(--v-primary);
    }
}
</style>
