<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/bewertungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Bewertungen</span>
		</div>
		<div class="wrap-all">
			<div v-show="loading" class="loading">
				Liste wird generiert... Dies kann je nach Auswahl bis zu 2 Minuten dauern
			</div>
			<div v-show="!loading" class="wrap-actions">
				<div @click="download(0)" v-show="rollen.admin" class="studiovergleich">
					Studiovergleich
				</div>
				<div @click="download(1)" v-show="rollen.leitung || rollen.admin" class="trainervergleich">
					Trainervergleich
				</div>
				<div @click="download(2)" v-show="rollen.mitarbeiter" class="trainer">
					Meine Statistik
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'
import { saveAs } from 'file-saver'
import zipcelx from 'zipcelx-on-steroids'
import writeXlsxFile from 'write-excel-file'
export default {
	data() {
		return {
			counter: {},
			feedbacks: [],
			api: {},
			rollen: {},
			loading: false,
		}
	},
	methods: {
		round(number) {
			if (number) {
				return Math.round(number)
			} else {
				return 0
			}
		},
		download(n) {
			this.loading = true
			if (n == 0) {
				this.getStudiovergleich()
			}

			if (n == 1) {
				let studio_ids = [
					'5f326a24ae8863640c859e75',
					'5f3c1241d91ddb464c2648b0',
					'5f3c30f2d91ddb464c2648b5',
					'5f3c30afd91ddb464c2648b1',
					'5f3c30d0d91ddb464c2648b3',
					'5f3c30bfd91ddb464c2648b2',
					'5f3c30e1d91ddb464c2648b4',
					'5f3d482d5dd7955ad48de5e4',
					'5f3d48195dd7955ad48de5e3',
				]

				this.getTrainervergleich(studio_ids)
			}

			if (n == 2) {
				this.getTrainer()
			}
		},
		getTrainer() {
			let ref = this
			api.getTrainer().then((data) => {
				let exceldata = []

				exceldata.push([
					{
						value: 'Gesamt',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Gesamt erstellt',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'N Total (Antworten)',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '1',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '2',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '3',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '4',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '5',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
				])
				let colors = {}
				colors['Anamnese'] = '#D9E1F2'
				colors['Trainingsplaene'] = '#C6E0B4'
				let topics = {
					Anamnese: {
						frage_1: 'Warst Du mit Deinem Trainer zufrieden?',
						frage_2: 'Wie hat es Dir im well come FIT gefallen?',
					},
					Trainingsplaene: {
						frage_1: 'Bist Du mit Deinem Trainer zufrieden?',
						frage_2: 'Bist du mit Deinem Trainingsplan zufrieden?',
					},
				}
				// Instructor ids
				let instructor_ids = Object.keys(data)

				for (let topic of Object.keys(topics)) {
					exceldata.push([
						{
							value: topic,
							type: String,
							fontWeight: 'bold',
							backgroundColor: colors[topic],
						},
					])

					for (let question of Object.keys(topics[topic])) {
						let key_topic = ''

						for (let instructor of instructor_ids) {
							let pre_excel_data = []
							if (topic == 'Anamnese') {
								key_topic = 'anamnese'
							} else {
								key_topic = 'tp'
							}

							if (instructor == 'gesamt') {
								pre_excel_data.push({
									value: topics[topic][question],
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							} else {
								pre_excel_data.push({
									value: data[instructor].instructor.vorname + ' ' + data[instructor].instructor.nachname,
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							}

							let gesamt_instructor =
								data[instructor][key_topic][question].stars['one'] +
								data[instructor][key_topic][question].stars['two'] +
								data[instructor][key_topic][question].stars['three'] +
								data[instructor][key_topic][question].stars['four'] +
								data[instructor][key_topic][question].stars['five']
							pre_excel_data.push(
								{
									value: data[instructor][key_topic].n_c,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: gesamt_instructor,

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},

								{
									value: data[instructor][key_topic][question].stars['one'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['two'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['three'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['four'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['five'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								}
							)
							exceldata.push(pre_excel_data)

							exceldata.push([
								{
									value: 'Prozent-Angabe',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: Math.round((100 / data[instructor][key_topic].n_c) * gesamt_instructor) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['one']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['two']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['three']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['four']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['five']) + '%',
									type: String,
									align: 'right',
								},
							])

							// Mittelwert
							let mittelwert = 0
							if (gesamt_instructor > 0) {
								mittelwert = parseFloat(
									(
										(data[instructor][key_topic][question].stars['five'] * 5 +
											data[instructor][key_topic][question].stars['four'] * 4 +
											data[instructor][key_topic][question].stars['three'] * 3 +
											data[instructor][key_topic][question].stars['two'] * 2 +
											data[instructor][key_topic][question].stars['one']) /
										gesamt_instructor
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'MW',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: mittelwert,
									type: Number,
								},
							])
							// STAW
							let staw = 0
							if (gesamt_instructor > 0) {
								staw = parseFloat(
									Math.abs(
										(data[instructor][key_topic][question].stars['five'] * Math.pow(5 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['four'] * Math.pow(4 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['three'] * Math.pow(3 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['two'] * Math.pow(2 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['one'] * Math.pow(1 - mittelwert, 2)) /
											gesamt_instructor
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'STAW',
									type: String,
								},
								{
									value: '',
									type: String,
								},
								{
									value: staw,
									type: Number,
								},
							])
							exceldata.push([
								{
									value: '',
									type: String,
								},
							])
						}
					}
				}

				writeXlsxFile(exceldata, {
					fileName: 'Trainer.xlsx',
				}).then((d) => {
					ref.loading = false
				})
			})
		},
		async getTrainervergleich(studio_ids) {
			let exceldata_final = []
			let ref = this
			for (let studio_id of studio_ids) {
				let exceldata = []
				let data = []
				try {
					data = await api.getTrainervergleich(studio_id)
				} catch {
					exceldata_final.push(exceldata)
					continue
				}

				exceldata.push([
					{
						value: 'Gesamt',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Gesamt erstellt',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'N Total (Antworten)',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '1',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '2',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '3',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '4',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '5',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
				])

				let colors = {}
				colors['Anamnese'] = '#D9E1F2'
				colors['Trainingsplaene'] = '#C6E0B4'

				let topics = {
					Anamnese: {
						frage_1: 'Warst Du mit Deinem Trainer zufrieden?',
						frage_2: 'Wie hat es Dir im well come FIT gefallen?',
					},
					Trainingsplaene: {
						frage_1: 'Bist Du mit Deinem Trainer zufrieden?',
						frage_2: 'Bist du mit Deinem Trainingsplan zufrieden?',
					},
				}
				// Instructor ids
				let instructor_ids = Object.keys(data)

				for (let topic of Object.keys(topics)) {
					exceldata.push([
						{
							value: topic,
							type: String,
							fontWeight: 'bold',
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
					])

					for (let question of Object.keys(topics[topic])) {
						let key_topic = ''

						for (let instructor of instructor_ids) {
							let pre_excel_data = []
							if (topic == 'Anamnese') {
								key_topic = 'anamnese'
							} else {
								key_topic = 'tp'
							}

							if (instructor == 'gesamt') {
								pre_excel_data.push({
									value: topics[topic][question],
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							} else {
								pre_excel_data.push({
									value: data[instructor].instructor.vorname + ' ' + data[instructor].instructor.nachname,
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							}

							let gesamt_instructor =
								data[instructor][key_topic][question].stars['one'] +
								data[instructor][key_topic][question].stars['two'] +
								data[instructor][key_topic][question].stars['three'] +
								data[instructor][key_topic][question].stars['four'] +
								data[instructor][key_topic][question].stars['five']
							pre_excel_data.push(
								{
									value: data[instructor][key_topic].n_c,
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: gesamt_instructor,

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},

								{
									value: data[instructor][key_topic][question].stars['one'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['two'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['three'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['four'],

									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								},
								{
									value: data[instructor][key_topic][question].stars['five'],
									type: Number,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								}
							)
							exceldata.push(pre_excel_data)

							exceldata.push([
								{
									value: 'Prozent-Angabe',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: ref.round((100 / data[instructor][key_topic].n_c) * gesamt_instructor) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['one']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['two']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['three']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['four']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: ref.round((100 / gesamt_instructor) * data[instructor][key_topic][question].stars['five']) + '%',
									type: String,
									align: 'right',
								},
							])

							// Mittelwert

							let mittelwert = 0

							if (gesamt_instructor > 0) {
								mittelwert = parseFloat(
									(
										(data[instructor][key_topic][question].stars['five'] * 5 +
											data[instructor][key_topic][question].stars['four'] * 4 +
											data[instructor][key_topic][question].stars['three'] * 3 +
											data[instructor][key_topic][question].stars['two'] * 2 +
											data[instructor][key_topic][question].stars['one']) /
										gesamt_instructor
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'MW',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: mittelwert,
									type: Number,
								},
							])
							// STAW

							let staw = 0
							if (gesamt_instructor > 0) {
								staw = parseFloat(
									Math.abs(
										(data[instructor][key_topic][question].stars['five'] * Math.pow(5 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['four'] * Math.pow(4 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['three'] * Math.pow(3 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['two'] * Math.pow(2 - mittelwert, 2) +
											data[instructor][key_topic][question].stars['one'] * Math.pow(1 - mittelwert, 2)) /
											gesamt_instructor
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'STAW',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: staw,
									type: Number,
								},
							])
							exceldata.push([
								{
									value: '',
									type: String,
								},
							])
						}
					}
				}
				exceldata_final.push(exceldata)
			}

			api.getStudios().then((data) => {
				let sheet_names = []
				for (let stud_id of studio_ids) {
					for (let studio of data) {
						if (studio._id == stud_id) {
							sheet_names.push(
								studio.name
									.replace('A. ', '')
									.replace('B. ', '')
									.replace('C. ', '')
									.replace('D. ', '')
									.replace('E. ', '')
									.replace('F. ', '')
									.replace('G. ', '')
									.replace('H. ', '')
									.replace('I. ', '')
									.replace('J. ', '')
							)
						}
					}
				}
				writeXlsxFile(exceldata_final, {
					sheets: sheet_names,
					fileName: 'Trainervergleich.xlsx',
				}).then((d) => {
					ref.loading = false
				})
			})
		},
		getStudiovergleich() {
			let ref = this
			api.getKreuztabelle().then((data) => {
				let exceldata = []

				exceldata.push([
					{
						value: 'Gesamt',
						type: String,
						fontWeight: 'bold',
					},
					{
						value: 'Gesamt erstellt',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: 'N Total (Antworten)',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '1',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '2',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '3',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '4',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
					{
						value: '5',
						type: String,
						fontWeight: 'bold',
						align: 'center',
					},
				])

				let topics = {
					Anamnese: {
						frage_1: 'Warst Du mit Deinem Trainer zufrieden?',
						frage_2: 'Wie hat es Dir im well come FIT gefallen?',
					},
					Trainingsplaene: {
						frage_1: 'Bist Du mit Deinem Trainer zufrieden?',
						frage_2: 'Bist du mit Deinem Trainingsplan zufrieden?',
					},
				}
				let colors = {}
				colors['Anamnese'] = '#D9E1F2'
				colors['Trainingsplaene'] = '#C6E0B4'
				// Studio ids
				let studio_ids = Object.keys(data).filter((i) => {
					return i !== '5f5f9e6c17392062f44c5753' && i !== '6001858e404af54a24c6ed60'
				})

				for (let topic of Object.keys(topics)) {
					exceldata.push([
						{
							value: topic,
							type: String,
							fontWeight: 'bold',
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
						{
							value: '',
							type: String,
							backgroundColor: colors[topic],
						},
					])

					for (let question of Object.keys(topics[topic])) {
						let key_topic = ''

						for (let studio of studio_ids) {
							let pre_excel_data = []
							if (topic == 'Anamnese') {
								key_topic = 'anamnese'
							} else {
								key_topic = 'tp'
							}
							if (studio == 'gesamt') {
								pre_excel_data.push({
									value: topics[topic][question],
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							} else {
								pre_excel_data.push({
									value: data[studio].studio
										.replace('A. ', '')
										.replace('B. ', '')
										.replace('C. ', '')
										.replace('D. ', '')
										.replace('E. ', '')
										.replace('F. ', '')
										.replace('G. ', '')
										.replace('H. ', '')
										.replace('I. ', '')
										.replace('J. ', ''),
									type: String,
									fontWeight: 'bold',
									backgroundColor: colors[topic],
								})
							}
							let gesamt_studio =
								data[studio][key_topic][question].stars['one'] +
								data[studio][key_topic][question].stars['two'] +
								data[studio][key_topic][question].stars['three'] +
								data[studio][key_topic][question].stars['four'] +
								data[studio][key_topic][question].stars['five']

							pre_excel_data.push(
								{
									value: data[studio][key_topic].n_c,
									type: Number,
									backgroundColor: colors[topic],
									fontWeight: 'bold',
								},
								{
									value: gesamt_studio,
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},

								{
									value: data[studio][key_topic][question].stars['one'],
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['two'],
									type: Number,
									backgroundColor: colors[topic],
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['three'],
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['four'],
									backgroundColor: colors[topic],
									type: Number,
									fontWeight: 'bold',
								},
								{
									value: data[studio][key_topic][question].stars['five'],
									type: Number,
									backgroundColor: colors[topic],
									fontWeight: 'bold',
								}
							)
							exceldata.push(pre_excel_data)

							exceldata.push([
								{
									value: 'Prozent-Angabe',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: Math.round((100 / data[studio][key_topic].n_c) * gesamt_studio) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['one']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['two']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['three']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['four']) + '%',
									type: String,
									align: 'right',
								},
								{
									value: Math.round((100 / gesamt_studio) * data[studio][key_topic][question].stars['five']) + '%',
									type: String,
									align: 'right',
								},
							])

							// Mittelwert
							let mittelwert = 0
							if (gesamt_studio > 0) {
								mittelwert = (
									(data[studio][key_topic][question].stars['five'] * 5 +
										data[studio][key_topic][question].stars['four'] * 4 +
										data[studio][key_topic][question].stars['three'] * 3 +
										data[studio][key_topic][question].stars['two'] * 2 +
										data[studio][key_topic][question].stars['one']) /
									gesamt_studio
								).toFixed(2)
							}

							exceldata.push([
								{
									value: 'MW',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: parseFloat(mittelwert),
									type: Number,
								},
							])
							// STAW

							let staw = 0
							if (gesamt_studio > 0) {
								staw = parseFloat(
									Math.abs(
										(data[studio][key_topic][question].stars['five'] * Math.pow(5 - mittelwert, 2) +
											data[studio][key_topic][question].stars['four'] * Math.pow(4 - mittelwert, 2) +
											data[studio][key_topic][question].stars['three'] * Math.pow(3 - mittelwert, 2) +
											data[studio][key_topic][question].stars['two'] * Math.pow(2 - mittelwert, 2) +
											data[studio][key_topic][question].stars['one'] * Math.pow(1 - mittelwert, 2)) /
											gesamt_studio
									).toFixed(2)
								)
							}

							exceldata.push([
								{
									value: 'STAW',
									type: String,
									fontWeight: 'bold',
								},
								{
									value: '',
									type: String,
								},
								{
									value: staw,
									type: Number,
								},
							])
							exceldata.push([
								{
									value: '',
									type: String,
								},
							])
						}
					}
				}

				writeXlsxFile(exceldata, {
					fileName: 'Studiovergleich.xlsx',
				}).then((d) => {
					ref.loading = false
				})
			})
		},
		runden(number) {
			if (number && number !== 'infinity') {
				if (number > 100) {
					return 100
				} else {
					return Math.round(number)
				}
			} else {
				return '0'
			}
		},
	},
	updated() {},
	created() {
		api.getUserRolle().then((data) => {
			this.rollen = data
		})
	},
}
</script>
<style lang="scss" scoped>
.wrap-all {
	max-width: 500px;
	margin: 0px auto;
	.wrap-actions {
		div {
			width: 100%;
			background-color: var(--v-primary);
			color: white;
			padding: 15px 0px;
			text-align: center;
			margin: 5px 0px;
			letter-spacing: 1px;
			font-weight: bold;
		}
	}
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
