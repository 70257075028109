<template>
    <div class="referralView mb-15">
        <v-row v-if="loading">
            <v-col class="d-flex justify-center align-center" style="min-height: calc(100vh - 130px)">
                <v-progress-circular indeterminate color="primary" />
            </v-col>
        </v-row>
        <v-card max-width="500px" class="mx-auto" v-if="active && !loading && leadSetting">
            <v-img
                v-if="leadSetting.internPicture && leadSetting.internPicture.length > 0"
                :src="'/img' + leadSetting.internPicture">
            </v-img>
            <v-card-title v-if="!conditionsAccepted" class="h5 primary--text mt-5 mb-0 pb-2"> Beschreibung </v-card-title>
            <v-card-text v-if="!conditionsAccepted" class="mb-0 pb-0" v-html="leadSetting.description"></v-card-text>
            <v-card-title v-if="!conditionsAccepted" class="h5 primary--text mb-0 pb-2 mb-0 mt-0 pt-0"
                >Bedingungen</v-card-title
            >
            <v-card-text v-if="!conditionsAccepted" class="mb-0 pb-0" v-html="leadSetting.internConditions"></v-card-text>
            <v-row v-if="!conditionsAccepted" no-gutters class="referralView__conditions pb-5 mt-0 pt-0">
                <v-col cols="12" class="d-flex justify-center align-center">
                    <v-card-text class="pt-0"
                        >Bedingungen akzeptieren und den Link für die Weiterempfehlung erhalten:</v-card-text
                    >
                </v-col>
                <v-col cols="12">
                    <v-card-actions class="d-flex justify-center align-center"
                        ><v-btn @click="returnToHome" text>Zurück</v-btn
                        ><v-btn color="primary" class="white--text" @click="conditionsAccepted = true"
                            >Akzeptieren</v-btn
                        ></v-card-actions
                    >
                </v-col>
            </v-row>
            <v-row v-else no-gutters class="referralView__links py-5 mt-0 pt-0">
                <v-col cols="12">
                    <v-card-title class="h5 primary--text pb-0 mt-5 mb-0">Dein persönlicher Link</v-card-title></v-col
                >
                <v-col class="my-0 py-0 d-flex align-center" cols="10">
                    <v-text-field
                        class="my-auto ml-4 mt-2"
                        :value="referralLink.replace('https://', '')"
                        disabled
                        color="black"></v-text-field
                ></v-col>
                <v-col cols="2" class="d-flex justify-center align-center py-0 my-0"
                    ><v-btn @click="shareViaWebShare(leadSetting.referralText)" icon
                        ><v-icon>mdi-share</v-icon></v-btn
                    ></v-col
                >

                <v-col cols="12"><v-card-text>Link teilen</v-card-text></v-col>
                <v-col cols="3" class="px-5"
                    ><v-img @click="shareViaWebShare(leadSetting.referralText)" src="/img/social/whatsapp.svg"
                /></v-col>
                <v-col cols="3" class="px-5"
                    ><v-img @click="shareViaWebShare(leadSetting.referralText)" src="/img/social/facebook.svg"
                /></v-col>
                <v-col cols="3" class="px-5"
                    ><v-img @click="shareViaWebShare(leadSetting.referralText)" src="/img/social/telegram.svg"
                /></v-col>
                <v-col cols="3" class="px-5">
                    <v-img @click="shareViaWebShare(leadSetting.referralText)" src="/img/social/gmail.svg"
                /></v-col>
                <v-col cols="12" class="justify-center d-flex align-center mt-10 mb-5">
                    <v-btn @click="returnToHome()" color="primary" class="white--text" rounded
                        >zurück zur Startseite</v-btn
                    ></v-col
                >
            </v-row>
        </v-card>
        <v-row v-if="!loading && !active">
            <v-col class="d-flex align-center justify-center flex-column mt-15">
                <v-card-text><h4 class="text-center">Diese Funktion ist nicht aktiv</h4></v-card-text>
                <v-btn @click="returnToHome()" color="primary" class="white--text">Zurück</v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import $ from 'jquery'

export default {
    components: {},
    data() {
        return {
            loading: true,
            active: true,

            conditionsAccepted: false,
            leadSetting: null,
            user: {},
            host: null,
        }
    },
    async created() {
        this.host = window.location.host.replace('www.', '')
        this.setNavigationbarVisibility(false)
        let user = await api.getUser().catch((e) => this.$toast.error('Fehler beim Laden'))
        if (user && user.user) this.user = user.user
        console.log(user)
        this.initReferral()
    },
    beforeDestroy() {
        this.setNavigationbarVisibility(true)
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility', 'toggleNavigationBarVisibility']),
        shareViaWebShare(text) {
            navigator.share({
                title: 'Bring a Friend',
                text,
                url: this.referralLink,
            })
        },
        shareMail(text) {
            let url = 'mailto:?subject=&body=' + encodeURI(text) + encodeURI(' ') + this.referralLink
            window.open(url, '_blank').focus()
        },
        shareTelegram(text) {
            let url = 'https://t.me/share/url?url=' + this.referralLink + '&text=' + encodeURI(text)
            window.open(url, '_blank').focus()
        },
        shareFacebook(text) {
            let url = 'https://www.facebook.com/sharer/sharer.php?u=' + this.referralLink
            window.open(url, '_blank').focus()
        },
        shareWhatsapp(text) {
            let url = 'https://api.whatsapp.com/send?text=' + encodeURI(text + ' ') + this.referralLink
            window.open(url, '_blank').focus()
        },
        copyLink() {
            navigator.clipboard.writeText(this.referralLink)
            this.$toast.success('Link in Zwischenablage kopiert')
        },
        returnToHome() {
            this.$router.push('/')
        },
        async initReferral() {
            let studio = await api.getUserStudio()
            studio = studio.studio
            if (studio && studio.leadSetting && studio.leadSetting.active) {
                this.leadSetting = studio.leadSetting
                console.log(studio.leadSetting)
            } else {
                this.active = false
                console.log('Weiterempfehlung nicht aktiviert')
            }
            this.loading = false
        },
    },
    computed: {
        referralLink() {
            if (this.user && this.user.email && this.host) {
                return 'https://' + this.host + '/r/' + this.user.email.toLowerCase()
            } else {
                return ''
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
