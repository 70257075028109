<template>
    <v-row :class="{ 'mx-auto': marginXAuto }" :style="'max-width: ' + maxWidth" :no-gutters="noGutters" class="mt-5">
        <v-col cols="12">
            <div v-if="!loading" style="overflow-x: auto; white-space: nowrap">
                <v-card
                    :dark="dashboard"
                    flat
                    v-for="worker in workers"
                    :key="worker._id"
                    class="mx-2 text-center"
                    :class="{ 'ma-0': !dashboard }"
                    style="display: inline-block; width: 110px">
                    <v-menu top min-width="200px" rounded offset-y>
                        <template v-slot:activator="{ on }">
                            <v-badge
                                v-if="showBades"
                                :color="worker.status ? 'green' : 'red'"
                                overlap
                                bordered
                                offset-y="20"
                                offset-x="30">
                                <v-avatar v-on="on" size="80">
                                    <v-img :src="worker.picture"></v-img>
                                </v-avatar>
                            </v-badge>

                            <v-avatar v-else v-on="on" size="80">
                                <v-img :src="worker.picture"></v-img>
                            </v-avatar>
                        </template>
                        <v-card class="d-flex justify-center">
                            <v-list-item-content v-if="showTrainerButtons" class="justify-center">
                                <div class="mx-auto text-center">
                                    <v-avatar color="primary" size="100">
                                        <v-img :src="worker.picture"></v-img>
                                    </v-avatar>
                                    <h5 class="mt-2">{{ worker.firstName }} {{ worker.lastName }}</h5>
                                    <p v-if="false" class="text-caption mt-1">
                                        {{ worker.picture }}
                                    </p>
                                    <v-divider class="my-3"></v-divider>
                                    <v-btn depressed rounded text> Trainingsplan anfragen </v-btn>
                                    <v-divider class="my-3"></v-divider>
                                    <v-btn depressed rounded text> Termin vereinbaren </v-btn>
                                </div>
                            </v-list-item-content>
                            <v-list-item-content v-else class="justify-center">
                                <div class="mx-auto text-center px-5">
                                    <v-avatar color="primary" size="100">
                                        <v-img :src="worker.picture"></v-img>
                                    </v-avatar>
                                    <h5 class="mt-2">{{ worker.firstName }} {{ worker.lastName }}</h5>

                                    <p v-if="worker.title" class="text-caption mt-1">
                                        {{ worker.title }}
                                    </p>
                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-menu>

                    <v-card-text class="h6 text-center"
                        ><span class="shortenText">{{ worker.firstName }}</span></v-card-text
                    >
                </v-card>
            </div>
            <div v-else>
                <v-col cols="12" class="px-5">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-col>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import api from '@/api'
export default {
    name: 'attendanceTrainerList',
    data() {
        return {
            loading: false,
            workers: [],
        }
    },
    props: {
        dashboard: {
            type: Boolean,
            default: false,
        },
        marginXAuto: {
            type: Boolean,
            default: false,
        },
        showBades: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '100%',
        },
        noGutters: {
            type: Boolean,
            default: false,
        },
        studio: {
            type: String,
            default: null,
        },
        showTrainerButtons: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        if (!this.dashboard || this.studio) {
            this.fetchAttendanceList()
        }
    },
    watch: {
        studio() {
            this.fetchAttendanceList()
        },
    },
    methods: {
        async fetchAttendanceList() {
            this.loading = true
            try {
                let data = await api.fetchAttendanceTrainers(this.studio)
                data = data.filter((e) => e.firstName !== 'Gelöschter')
                this.workers = data
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>


<style lang="scss" scoped>
.shortenText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>