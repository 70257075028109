<template>
    <v-dialog max-width="800" @click:outside="$emit('closeDialog')" :value="true">
        <v-card dark>
            <v-row no-gutters>
                <v-card-title>
                    Sollstunden Pro Monat für {{ studio ? ' Studio ' + studio.name : ' Team ' + team.name }}
                </v-card-title>
                <v-select
                    filled
                    rounded
                    @change="copyTargetHoursEntries"
                    item-text="name"
                    label="Feiertage übernehmen von"
                    :items="allStudios"
                    v-model="vacationDaysParentStudio"
                ></v-select>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" class="my-0 pa-0">
                    <v-date-picker
                        v-click-outside="{ handler: () => (vacationPickerDate = null), include }"
                        ref="picker"
                        v-model="vacationPickerDate"
                        dark
                        event-color="primary"
                        :events="
                            studio
                                ? studio.vacationDays &&
                                  studio.vacationDays.map((day) => this.$moment(day.date).format('YYYY-MM-DD'))
                                : team.vacationDays &&
                                  team.vacationDays.map((day) => this.$moment(day.date).format('YYYY-MM-DD'))
                        "
                        :picker-date.sync="pickerDate"
                        full-width
                    ></v-date-picker>
                </v-col>
                <v-col cols="12" sm="6" class="my-0 pa-0">
                    <v-card-title>Sollstunden für {{ $moment(pickerDate).format('MMM YYYY') }}</v-card-title>
                    <v-row class="no-gutters included" v-if="vacationPickerDate">
                        <v-card-title class="h6 pl-0">Feiertag hinzufügen</v-card-title>
                        <v-col cols="8">
                            <v-text-field
                                label="Datum"
                                :value="$moment(vacationPickerDate).format('DD MMM YYYY')"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field label="Titel" v-model="newVacationDayTitle"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn color="primary" icon @click="createTargetHoursEntry()">
                                <v-icon>mdiadd</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <div v-if="vacationDaysForMonth.length">
                            <v-card-subtitle class="pb-2">Feiertage</v-card-subtitle>
                            <v-row
                                class="no-gutters"
                                :key="'studiodings' + index"
                                v-for="(day, index) of vacationDaysForMonth"
                            >
                                <v-card style="width: 100%; display: flex" hover @click="remove(index)">
                                    <v-col cols="3" class="pa-1" style="align-self: center">
                                        <v-img src="/img/lottiefiles/confetti.gif"></v-img>
                                    </v-col>
                                    <v-col cols="9" class="pa-1">
                                        <v-card-title>
                                            {{ day.title }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ $moment(day.date).format('dddd D. MMM') }}
                                        </v-card-subtitle>
                                    </v-col>
                                </v-card>
                            </v-row>
                        </div>
                        <v-card-title>
                            {{ calcBusinessDays }}
                            <div v-if="vacationDaysForMonth.length">
                                <span class="ml-1 red--text"></span>
                                {{
                                    ' - ' +
                                    vacationDaysForMonth.length +
                                    (vacationDaysForMonth.length > 1 ? ' Feiertage' : ' Feiertag')
                                }}
                                =
                                {{ calcBusinessDays - vacationDaysForMonth.length }}
                            </div>
                        </v-card-title>
                        <v-card-subtitle class="d-flex flex-row">Arbeitstage</v-card-subtitle>
                        <v-card-title>
                            {{
                                calcBusinessDays -
                                vacationDaysForMonth.length +
                                ' * ' +
                                '8.4 = ' +
                                Math.round((calcBusinessDays - vacationDaysForMonth.length) * 8.4 * 1000) / 1000 +
                                ' Stunden'
                            }}
                        </v-card-title>
                        <v-card-subtitle>Total Soll Stunden</v-card-subtitle>
                    </div>
                </v-col>
            </v-row>
            <v-data-table
                v-if="false"
                :headers="tableHeaders"
                no-data-text="Noch keine Sollstunden hinterlegt"
                :items="targetHours()"
                class="elevation-1"
            >
                <template v-slot:header>
                    <v-row>
                        <v-form lazy-validation ref="createForm" class="d-flex">
                            <v-col cols="5">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="targetHour.date"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="$moment(targetHour.date).format('MMMM YYYY')"
                                            label="Monat"
                                            :rules="rules.month"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="targetHour.date"
                                        @change="$refs.menu.save(targetHour.date)"
                                        type="month"
                                        no-title
                                        scrollable
                                    >
                                        <v-btn text color="primary" @click="menu = false">Abbrechen</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    :rules="rules.terminationsCount"
                                    label="Soll Stunden"
                                    v-model="targetHour.hours"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="text-center" style="place-self: center">
                                <v-btn color="primary" icon @click="createTargetHoursEntry()">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-row>
                </template>
                <template v-slot:item.hours="props">
                    <v-edit-dialog :return-value.sync="props.item.hours" large persistent @save="save(props)">
                        <div>{{ props.item.hours }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">Anzahl Bearbeiten</div>
                            <v-text-field
                                v-model="props.item.hours"
                                :rules="rules.terminationsCount"
                                label="Anzahl"
                                single-line
                                counter
                                autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.date="props">
                    <v-edit-dialog :return-value.sync="props.item.date" large persistent @save="save(props)">
                        <div>{{ $moment(props.item.date).format('MMM YYYY') }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">Monat bearbeiten</div>
                            <v-date-picker v-model="props.item.date" type="month" no-title></v-date-picker>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.actions="props">
                    <v-icon small class="mr-2" color="red" @click="remove(props.index)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>
<script>
import api from '../../../api'

import contractCreator from '../contract/contractCreator.vue'

export default {
    components: { contractCreator },
    props: {
        allStudios: {},
        studio: {
            type: Object,
            default: () => {},
        },
        team: {
            type: Object,
        },
    },
    data() {
        return {
            vacationDaysParentStudio: null,
            targetHour: { date: this.$moment().add(1, 'months').format('YYYY-MM'), hours: null },
            menu: false,
            vacationPickerDate: null,
            pickerDate: this.$moment().format('YYYY-MM'),
            newVacationDayTitle: '',
            editMenu: false,
            tableHeaders: [
                { text: 'Datum', value: 'date' },
                { text: 'Stunden', value: 'hours' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            activeStudio: null,
            rules: {
                month: [(v) => (v.length <= 50 && v.length > 0) || 'Wähle einen Monat aus'],
                terminationsCount: [(v) => (v > -1000 && v < 1000) || 'Zahl eingeben'],
            },
        }
    },
    methods: {
        async remove(item) {
            if (confirm('Wirklich löschen?')) {
                if (this.studio) {
                    this.studio.vacationDays.splice(item, 1)
                    await api.updateStudioVacationDays({
                        studioId: this.studio._id,
                        vacationDays: this.studio.vacationDays,
                    })
                } else {
                    this.team.vacationDays.splice(item, 1)
                    await api.updateTeamVacationDays({
                        teamId: this.team._id,
                        vacationDays: this.team.vacationDays,
                    })
                }
            }
        },
        async copyTargetHoursEntries(item) {
            let copyFrom = this.allStudios.find((std) => std.name === item)
            if (copyFrom.vacationDays.length > 0) {
                if (this.studio) {
                    await api.updateStudioVacationDays({
                        studioId: this.studio._id,
                        vacationDays: copyFrom.vacationDays,
                    })
                    this.studio.vacationDays = copyFrom.vacationDays
                } else {
                    await api.updateTeamVacationDays({ teamId: this.team._id, vacationDays: copyFrom.vacationDays })
                    this.team.vacationDays = copyFrom.vacationDays
                }
            } else {
                alert('Keine Ferientage zum Übernehmen gefunden')
            }
        },
        async createTargetHoursEntry() {
            if (this.studio) {
                if (!this.studio.vacationDays) {
                    this.studio.vacationDays = []
                }
                let newVacationDay = { date: this.vacationPickerDate, title: this.newVacationDayTitle }
                this.studio.vacationDays.push(newVacationDay)
                await api.updateStudioVacationDays({
                    studioId: this.studio._id,
                    vacationDays: this.studio.vacationDays,
                })
                this.newVacationDayTitle = ''
                this.vacationPickerDate = null
            } else {
                if (!this.team.vacationDays) {
                    this.team.vacationDays = []
                }
                let newVacationDay = { date: this.vacationPickerDate, title: this.newVacationDayTitle }
                this.team.vacationDays.push(newVacationDay)
                await api.updateTeamVacationDays({ teamId: this.team._id, vacationDays: this.team.vacationDays })
                this.newVacationDayTitle = ''
                this.vacationPickerDate = null
            }
        },
        targetHours() {
            let items = []
            if (this.studio) {
                items = this.studio.vacationDays
            } else {
                items = this.team.vacationDays
            }
            if (items) {
                return items.map((item) => {
                    return {
                        date: item.date,
                        title: item.title,
                    }
                })
            }
        },
        include() {
            return document.querySelector('.included') ? [document.querySelector('.included')] : []
        },
    },
    computed: {
        calcBusinessDays() {
            var day = this.$moment(this.pickerDate).startOf('month')
            var businessDays = 0

            while (day.isSameOrBefore(this.$moment(this.pickerDate).endOf('month'), 'day')) {
                if (day.day() != 0 && day.day() != 6) businessDays++
                day.add(1, 'd')
            }
            return businessDays
        },
        vacationDaysForMonth() {
            if (this.studio) {
                return (
                    this.studio.vacationDays &&
                    this.studio.vacationDays.filter(
                        (day) => this.$moment(day.date).format('YYYY-MM') === this.pickerDate
                    )
                )
            } else {
                console.log(this.team)
                return (
                    this.team.vacationDays &&
                    this.team.vacationDays.filter((day) => this.$moment(day.date).format('YYYY-MM') === this.pickerDate)
                )
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
 