<template>
	<div class="landing">
		<v-row class="justify-center rounded-b-xl primary pb-3" no-gutters style="height: 200px;">
			<lottie-player
				mode="normal"
				v-if="showLottie"
				style="width:250px;height:200px;"
				name="roody_icon"
				autoplay
				src="/img/lottiefiles/roody_animated.json"
			></lottie-player>
		</v-row>
		<v-row no-gutters>
			<v-card elevation="0">
				<v-card-title class="font-weight-bold h2"> Willkommen zu Roody.</v-card-title>
				<v-card-text
					>Deiner Fitness App für fast alles! Wenn du meinen Vorgänger bereits kennst, wird dir vieles vertraut vorkommen. Wir empfehlen dir
					trotzdem die folgenden Schritte zu lesen und dir die wichtigsten Funktionen von uns noch einmal Erklären zu lassen.</v-card-text
				>
			</v-card>
		</v-row>
		<v-row no-gutters class="justify-content-center mt-7">
			<v-btn color="primary rounded" outlined @click="$router.push({ name: 'home' })">Tour Starten</v-btn>
		</v-row>
	</div>
</template>

<script>
import api from '../../api'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	name: 'landing',
	components: {
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	data() {
		return {
			showLottie: false,
		}
	},
	mounted() {
		setTimeout(() => (this.showLottie = true), 500)
	},
	methods: {
		setLottieTrue() {
			this.showLottie = true
		},
	},
	computed: {
		swiper: function() {
			return this.$refs.landingSwiper.$swiper
		},
		landingSwiperOptions: function() {
			let allowMove = false
			if (this.$refs.landingSwiper) {
				allowMove = this.swiper.activeIndex !== 0
			}
			return {
				allowTouchMove: allowMove,
				pagination: {
					el: '.swiper-pagination',
					dynamicBullets: true,
				},
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.landing {
	height: 100vh;
	&__swiper {
		&--container {
			height: 100%;
		}
	}
}
</style>
