<template>
    <v-dialog :value="true" fullscreen>
        <v-card
            dark
            class="d-flex flex-column text-center"
            style="overflow-y: scroll; overflow-x: scroll; height: 100vh"
        >
            <v-btn absolute icon top right style="z-index: 1000000000" x-large @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-row mt-3>
                <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '6'">
                    <v-avatar
                        style="position: relative; left: 0; right: 0"
                        class="employee-manager__card-avatar mx-auto mt-2"
                        color="grey"
                        size="125"
                    >
                        <img
                            :src="
                                trainer.picture
                                    ? '/img/profilbilder' + trainer.picture
                                    : '/img/einmalig/imageUploadPlaceholderPFP.jpg'
                            "
                        />
                    </v-avatar>
                    <v-card-title class="justify-content-center font-weight-bold">
                        {{ trainer.firstName }} {{ trainer.lastName }}
                    </v-card-title>
                    <v-card-subtitle class="justify-content-center">{{ trainer.title }}</v-card-subtitle>
                    <v-col v-if="!data" class="d-flex justify-center flex-grow">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>
                    <div v-if="data && data.createdContracts">
                        <v-card-title class="justify-content-center h4">Vertragsstatistiken</v-card-title>
                        <v-row>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <number from="6" :to="data.createdContracts.count"></number>

                                <span><strong>Verträge</strong></span>
                                <div>
                                    <v-chip small v-for="(count, name) in data.createdContracts.studios" :key="name">
                                        {{ name }}: {{ count }}
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>{{ data.createdContracts.avgPackages }}</span>
                                <span><strong>Ø Pakete</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <number from="6" :to="data.createdContracts.avgPrice"></number>
                                <span><strong>CHF Ø Vertrag</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>
                                    <number from="6" :to="trainer.percentage"></number>
                                    %
                                </span>
                                <span><strong>Stellenprozent</strong></span>
                            </v-col>
                        </v-row>
                        <apexchart
                            v-if="data.createdContracts.avgPackages"
                            height="500"
                            :series="packagesPieChartSeries"
                            :options="packagesPieChart"
                        ></apexchart>
                    </div>
                    <div
                        class="mt-3"
                        v-if="data && trainer && trainer.interactions && trainer.interactions.interactions.length > 0"
                    >
                        <v-card-title class="justify-content-center h4">Interaktionen</v-card-title>

                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-left">Datum</th>
                                    <th class="text-left">Kunde</th>
                                    <th class="text-left">Wo</th>
                                    <th class="text-left">Was</th>
                                    <th class="text-left">Notiz</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="customerInteraction of trainer.interactions.interactions"
                                    :key="customerInteraction.date"
                                >
                                    <td width="120">
                                        {{ $moment(customerInteraction.date).format('DD.MM.YYYY') }}
                                    </td>
                                    <td width="80">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    {{ customerInteraction.user.vorname.slice(0, 1)
                                                    }}{{ customerInteraction.user.nachname.slice(0, 1) }}
                                                </span>
                                            </template>
                                            <span>
                                                {{ customerInteraction.user.vorname }}
                                                {{ customerInteraction.user.nachname }}
                                                {{ customerInteraction.user.email }}
                                            </span>
                                        </v-tooltip>
                                    </td>
                                    <td width="150">
                                        {{ customerInteraction.location }}
                                    </td>
                                    <td width="200">
                                        {{ customerInteraction.reason }}
                                    </td>
                                    <td width="200">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    {{ truncateText(customerInteraction.notes, 50) }}
                                                </span>
                                            </template>
                                            <span>{{ customerInteraction.notes }}</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div v-if="data && data.workouts" class="mt-3">
                        <v-card-title class="justify-content-center h4">Trainingsplanstatistiken</v-card-title>
                        <v-row @mouseover="hoverRating = true" @mouseleave="hoverRating = false">
                            <v-btn
                                style="z-index: 100000; left: 0; right: 0; margin: auto; max-width: 200px"
                                v-if="hoverRating"
                                color="primary"
                                absolute
                                @click="$router.push({ name: 'workoutsDashboardFeedback' })"
                                center
                            >
                                zu den Bewertungen
                            </v-btn>
                            <v-col
                                :class="{ 'hovering-rating': hoverRating }"
                                v-if="data.feedBacks && data.feedBacks.question_1"
                                class="h5 d-flex flex-column font-weight-bold align-self-center mb-0"
                                cols="6"
                            >
                                <span><strong>Trainer</strong></span>
                                <v-rating
                                    :value="data.feedBacks.question_1"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly
                                ></v-rating>
                            </v-col>
                            <v-col
                                :class="{ 'hovering-rating': hoverRating }"
                                v-if="data.feedBacks && data.feedBacks.question_1"
                                class="h5 d-flex flex-column font-weight-bold align-self-center mb-0"
                                cols="6"
                            >
                                <span><strong>Trainingsplan</strong></span>
                                <v-rating
                                    :value="data.feedBacks.question_2"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly
                                ></v-rating>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <number from="6" :to="data.workouts.count"></number>
                                <span><strong>Trainingspläne</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>{{ data.workouts.avgExerciseCount }}</span>
                                <span><strong>Ø Übungen pro Plan</strong></span>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="data && data.medicalHistory" class="mt-3">
                        <v-card-title class="justify-content-center h4">Anamnesestatistiken</v-card-title>
                        <v-row>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <number from="6" :to="data.medicalHistory.count"></number>
                                <span><strong>Anamnesebögen</strong></span>
                            </v-col>
                        </v-row>
                        <v-row v-if="data.medicalHistoryUsers && data.medicalHistoryUsers.length > 0">
                            <v-col
                                v-for="medical of data.medicalHistoryUsers"
                                :key="medical._id"
                                class="d-flex flex-column align-self-center mb-0"
                                cols="12"
                                md="6"
                            >
                                <v-card flat>
                                    <v-row>
                                        <v-col cols="12" class="mb-0 pb-0">
                                            {{ $moment(medical.createdAt).format('DD.MM.YY HH:mm') }} Uhr
                                        </v-col>
                                        <v-col cols="12" class="my-0 py-0">
                                            {{ medical.customer.vorname }}
                                            {{ medical.customer.nachname }}
                                        </v-col>
                                        <v-col cols="12" class="mt-0 pt-0">
                                            {{ medical.customer.email }}
                                        </v-col>
                                        <v-col cols="12" class="mt-0 pt-0">
                                            <v-btn color="primary" @click="showMedicalHistory(medical._id)">
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col style="border-left: 3px dotted green" v-if="!$vuetify.breakpoint.mobile" cols="6">
                    <v-menu
                        ref="menu"
                        dark
                        v-model="datePickerMenu"
                        :close-on-content-click="false"
                        :return-value.sync="localDatePickerRange"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div style="height: 135px">
                                <v-text-field
                                    width="50vw"
                                    class="mx-auto mt-5"
                                    dark
                                    filled
                                    :value="formattedDatePickerRange"
                                    label="Zeitraum"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </div>
                        </template>
                        <v-date-picker
                            ref="datePicker"
                            range
                            v-model="localDatePickerRange"
                            @update:active-picker="activePickerChanged"
                            @change="$refs.menu.save(localDatePickerRange)"
                            no-title
                            scrollable
                        ></v-date-picker>
                    </v-menu>
                    <v-card-title
                        v-if="
                            storeStudios.find((x) => x._id === trainer.mainStudio) &&
                            storeStudios.find((x) => x._id === trainer.mainStudio).name
                        "
                        class="justify-content-center font-weight-bold"
                    >
                        Durchschnitt im Studio
                        {{
                            trainer.mainStudio
                                ? storeStudios.find((x) => x._id === trainer.mainStudio).name
                                : trainer.studio.split(',')[0]
                        }}
                    </v-card-title>
                    <v-col v-if="!studioData" class="d-flex justify-center flex-grow">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>
                    <div v-if="studioData && studioData.createdContracts">
                        <v-card-title class="justify-content-center h4">Vertragsstatistiken</v-card-title>
                        <v-row>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <number
                                    from="6"
                                    :to="studioData.createdContracts.count / studioTrainers.length"
                                ></number>
                                <span><strong>Verträge</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>{{ studioData.createdContracts.avgPackages }}</span>
                                <span><strong>Ø Pakete</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <number from="6" :to="studioData.createdContracts.avgPrice"></number>
                                <span><strong>CHF Ø Vertrag</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>
                                    <number from="6" :to="getAVGPercentageStudio"></number>
                                    %
                                </span>
                                <span><strong>Stellenprozent</strong></span>
                            </v-col>
                        </v-row>
                        <apexchart
                            v-if="studioData.createdContracts.avgPackages"
                            height="500"
                            :series="packagesStudioPieChartSeries"
                            :options="packagesStudioPieChart"
                        ></apexchart>
                    </div>
                    <div v-if="studioData && studioData.workouts" class="mt-3">
                        <v-card-title class="justify-content-center h4">Trainingsplanstatistiken</v-card-title>
                        <v-row>
                            <v-col
                                v-if="studioData.feedBacks && studioData.feedBacks.question_1"
                                class="h5 d-flex flex-column font-weight-bold align-self-center mb-0"
                                cols="6"
                            >
                                <span><strong>Trainer</strong></span>
                                <v-rating
                                    :value="studioData.feedBacks.question_1"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly
                                ></v-rating>
                            </v-col>
                            <v-col
                                v-if="studioData.feedBacks && studioData.feedBacks.question_1"
                                class="h5 d-flex flex-column font-weight-bold align-self-center mb-0"
                                cols="6"
                            >
                                <span><strong>Trainingsplan</strong></span>
                                <v-rating
                                    :value="studioData.feedBacks.question_2"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    empty-icon="$ratingFull"
                                    half-increments
                                    readonly
                                ></v-rating>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>{{ Math.round(studioData.workouts.avgWorkoutCount * 10) / 10 }}</span>
                                <span><strong>Trainingspläne</strong></span>
                            </v-col>
                            <v-col class="h5 d-flex flex-column font-weight-bold align-self-center mb-0" cols="6">
                                <span>{{ studioData.workouts.avgExerciseCount }}</span>
                                <span><strong>Ø Übungen pro Plan</strong></span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <anamnese-bogen-create
            @closeAnamnesebogen="setBottomNavigationBarVisibilityFalseAnamnese"
            ref="anamneseBogenRef"
        ></anamnese-bogen-create>
    </v-dialog>
</template>

<script>
import api from '../../../api'
import anamneseBogenCreate from '@/components/anamneseBogenCreate'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'dashboard',
    components: { anamneseBogenCreate },
    props: {
        trainer: {
            type: Object,
            required: true,
        },
        datePickerRange: {
            type: Array,
        },
        studioTrainers: {
            type: Array,
        },
    },
    data() {
        return {
            hoverRating: false,
            data: null,
            studioData: null,
            localDatePickerRange: null,
            contractPackagePieLabels: [],
            contractPackagePieLabelsStudio: [],
            loadingTrainer: false,
            datePickerMenu: false,
        }
    },
    async created() {
        this.localDatePickerRange = JSON.parse(JSON.stringify(this.datePickerRange))
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility']),

        truncateText(text, length) {
            if (!text) return ''
            return text.length > length ? text.substring(0, length) + '...' : text
        },
        setBottomNavigationBarVisibilityFalseAnamnese() {
            this.setNavigationbarVisibility(false)
        },
        showMedicalHistory(id) {
            this.$refs.anamneseBogenRef.setVisible(id, false)
        },
        activePickerChanged(value) {
            if (value === 'YEAR' && this.$refs.datePicker && this.$refs.datePicker.tableDate) {
                let pickedYear = this.$moment(this.$refs.datePicker.tableDate, 'YYYY')
                this.$set(this.localDatePickerRange, 0, pickedYear.startOf('year').format('YYYY-MM-DD'))
                this.$set(this.localDatePickerRange, 1, pickedYear.endOf('year').format('YYYY-MM-DD'))
            }
            if (value === 'DATE' && this.$refs.datePicker && this.$refs.datePicker.tableDate) {
                let pickedMonth = this.$moment(this.$refs.datePicker.tableDate, 'YYYY-MM')
                this.$set(this.localDatePickerRange, 0, pickedMonth.startOf('month').format('YYYY-MM-DD'))
                this.$set(this.localDatePickerRange, 1, pickedMonth.endOf('month').format('YYYY-MM-DD'))
            }
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),

        getAVGPercentageStudio() {
            let percentage = 0
            this.studioTrainers.forEach((trainer) => {
                percentage += trainer.percentage
            })
            return percentage / this.studioTrainers.length
        },
        formattedDatePickerRange() {
            if (!this.localDatePickerRange.length) return 'Ganzer Zeitraum'
            if (!this.localDatePickerRange[1])
                return (
                    this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').format('DD.MM.YYYY') + ' - Datum auswählen'
                )
            if (
                this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').isAfter(
                    this.$moment(this.localDatePickerRange[1])
                )
            ) {
                let oldlocalDatePickerRange0 = this.localDatePickerRange[0]
                this.localDatePickerRange[0] = this.localDatePickerRange[1]
                this.localDatePickerRange[1] = oldlocalDatePickerRange0
            }
            if (
                this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD') ===
                    this.localDatePickerRange[0] &&
                this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD') ===
                    this.localDatePickerRange[1] &&
                this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').format('YYYY-MM') ===
                    this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD').format('YYYY-MM')
            ) {
                return this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').format('MMMM YYYY')
            } else if (
                this.$moment().isSame(this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD'), 'day') &&
                this.$moment().isSame(this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD'), 'day')
            ) {
                return 'Heute'
            } else if (
                this.$moment()
                    .subtract(1, 'days')
                    .isSame(this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD'), 'day') &&
                this.$moment()
                    .subtract(1, 'days')
                    .isSame(this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD'), 'day')
            ) {
                return 'Gestern'
            } else if (this.localDatePickerRange[0] === this.localDatePickerRange[1]) {
                return this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').format('DD.MM.YYYY')
            } else {
                let endlocalDatePickerRange = this.$moment().isSame(
                    this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD'),
                    'day'
                )
                    ? 'Heute'
                    : this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD').format('DD.MM.YYYY')
                return (
                    this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').format('DD.MM.YYYY') +
                    ' - ' +
                    endlocalDatePickerRange
                )
            }
            return (
                this.$moment(this.localDatePickerRange[0], 'YYYY-MM-DD').format('DD.MM.YYYY') +
                ' - ' +
                this.$moment(this.localDatePickerRange[1], 'YYYY-MM-DD').format('DD.MM.YYYY')
            )
        },
        packagesPieChart() {
            return {
                chart: {
                    foreColor: '#ccc',
                    type: 'pie',
                },

                theme: {
                    palette: 'palette2',
                },
                legend: {
                    position: 'bottom',
                },
                labels: this.contractPackagePieLabels,
            }
        },
        packagesStudioPieChart() {
            return {
                chart: {
                    foreColor: '#ccc',
                    type: 'pie',
                },

                theme: {
                    palette: 'palette2',
                },
                legend: {
                    position: 'bottom',
                },
                labels: this.contractPackagePieLabelsStudio,
            }
        },
        packagesPieChartSeries() {
            this.contractPackagePieLabels = []
            let returnData = []
            for (const [packageTitle, count] of Object.entries(this.data.createdContracts.packagesCount)) {
                this.contractPackagePieLabels.push(packageTitle)
                returnData.push(count)
            }
            return returnData
        },
        packagesStudioPieChartSeries() {
            this.contractPackagePieLabelsStudio = []
            let returnData = []
            for (const [packageTitle, count] of Object.entries(this.studioData.createdContracts.packagesCount)) {
                this.contractPackagePieLabelsStudio.push(packageTitle)
                returnData.push(count)
            }
            return returnData
        },
        /*   filteredDashboardTrainerInfos() {
            if (!this.percentagesTo100) return this.dashboardTrainerInfos
            let copiedArray = JSON.parse(JSON.stringify(this.dashboardTrainerInfos))
            return copiedArray.map((user) => {
                if (user.percentage < 100) {
                    user.contracts = Math.round((user.contracts / user.percentage) * 100)
                    user.medicalHistory = Math.round((user.medicalHistory / user.percentage) * 100)
                    user.workouts = Math.round((user.workouts / user.percentage) * 100)
                    user.percentage = (user.percentage / user.percentage) * 100
                }
                return user
            })
        }, */
    },
    watch: {
        async localDatePickerRange() {
            console.log(this.trainer)
            if (this.localDatePickerRange[0] && this.localDatePickerRange[1]) {
                this.data = await api.fetchTrainerDashboardInfosSingleTrainer({
                    trainer: this.trainer._id,
                    range: this.localDatePickerRange,
                })
                this.studioData = await api.fetchTrainerDashboardInfosStudio({
                    trainers: this.studioTrainers,
                    range: this.localDatePickerRange,
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.hovering-rating {
    opacity: 0.7;
}
</style>
