<template>
    <v-container>
        <v-card flat dark>
            <v-card-title>Angebote & Services</v-card-title>
            <v-data-table :items="offersAndServices" :headers="headers" item-value="_id" dense>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-btn color="primary" @click="openDialog()">Neu erstellen</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="fetchAll()">Alle anzeigen</v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:body="{ items }">
                    <tr v-for="item in items" :key="item._id">
                        <td class="px-5">{{ item.title }}</td>
                        <td class="px-5">{{ item.isOffer ? 'Angebot' : 'Service' }}</td>
                        <td class="px-5">{{ item.deleted ? 'Ja' : 'Nein' }}</td>
                        <td>
                            <v-btn icon color="primary" @click="edit(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon color="red" @click="markAsDeleted(item._id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ editedItem._id ? 'Bearbeiten' : 'Neu erstellen' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-img class="mx-auto" height="200" width="200" v-if="editedItem.picture"
                            :src="'/api/website/image/' + editedItem.picture"></v-img>
                        <v-file-input outlined hide-details v-model="file" class="mb-2" label="Bild auswählen"
                            @change="onFileChange"></v-file-input>
                        <v-btn class="mx-auto mb-2" color="primary" @click="uploadImage">Bild hochladen</v-btn>
                        <v-text-field v-model="editedItem.icon" label="Icon (z.B. mdi-pencil)"></v-text-field>
                        <v-text-field v-model="editedItem.title" label="Titel"></v-text-field>
                        <v-text-field v-model="editedItem.description" label="Beschreibung"></v-text-field>
                        <v-checkbox v-model="editedItem.isOffer" label="Zusatzpaket "></v-checkbox>
                        <v-checkbox v-model="editedItem.deleted" label="Gelöscht"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDialog">Abbrechen</v-btn>
                    <v-btn text color="primary" @click="save">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            file: null,
            offersAndServices: [],
            headers: [
                { text: "Titel", value: "title" },
                { text: "Typ", value: "isOffer" },
                { text: "Gelöscht", value: "deleted" },
                { text: "Aktionen", value: "actions", sortable: false },
            ],
            dialog: false,
            editedItem: {
                title: "",
                icon: "",
                description: "",
                isOffer: false,
                deleted: false,
                picture: "",
            },
            defaultItem: {
                title: "",
                icon: "",
                description: "",
                isOffer: false,
                deleted: false,
                picture: "",
            },
        };
    },
    created() {
        this.fetchAll();
    },
    methods: {
        async uploadImage() {
            if (!this.file) {
                alert("Bitte ein Bild auswählen.");
                return;
            }

            const formData = new FormData();
            formData.append("image", this.file);

            try {
                const response = await api.uploadWebsiteImage(formData);
                this.editedItem.picture = response.file;
                this.file = null;
            } catch (err) {
                console.error("Fehler beim Hochladen:", err);
            }
        },
        onFileChange(file) {
            this.file = file;
        },
        async fetchAll() {
            this.offersAndServices = await api.fetchOffersAndServices();
        },
        openDialog() {
            this.dialog = true;
            this.editedItem = { ...this.defaultItem };
        },
        closeDialog() {
            this.dialog = false;
        },
        async save() {
            if (this.editedItem._id) {
                await api.updateOffer(this.editedItem._id, this.editedItem);
            } else {
                await api.createOffer(this.editedItem);
            }
            this.dialog = false;
            this.fetchAll();
        },
        edit(item) {
            this.editedItem = { ...item };
            this.dialog = true;
        },
        async markAsDeleted(id) {
            if (confirm("Möchtest du diesen Eintrag löschen?")) {
                await api.deleteOffer(id);
                this.fetchAll();
            }
        },
    },
};
</script>
