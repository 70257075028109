<template xmlns="">
	<div class="workingOutCompleted__wrapper d-flex flex-column justify-space-between align-center">
		<v-card-title class="workingOutCompleted__card-title h5 break-word primary--text justify-center">GRATULATION! </v-card-title>

		<v-row style="width: 100%;" class="align-items-center">
			<v-col class="d-flex flex-column align-items-center" cols="6">
				<lottie-player
					mode="normal"
					class="workingOut__lottie-player"
					style="width:60px;height:60px;"
					name="loading"
					:autoplay="false"
					src="/img/lottiefiles/hourGlass.json"
				></lottie-player>
				<span class="h4"> {{ workingOutCompleted.duration }} </span>
			</v-col>
			<v-col class="d-flex flex-column align-items-center" cols="6">
				<lottie-player
					mode="normal"
					class="workingOut__lottie-player"
					style="width:60px;height:60px;"
					name="loading"
					:autoplay="false"
					src="/img/lottiefiles/callorieBurned.json"
				></lottie-player>
				<span class="h4"> {{ workingOutCompleted.caloriesBurned }} kcal</span>
			</v-col>
		</v-row>

		<v-row style="width: 100%;" class="align-items-center" v-if="workingOutCompleted.liftedWeight">
			<v-col class="d-flex flex-column align-items-center" cols="12">
				<lottie-player
					mode="normal"
					class="workingOut__lottie-player"
					style="width:100px;height:100px;"
					name="loading"
					:autoplay="false"
					src="/img/lottiefiles/dumbell.json"
				></lottie-player>
				<number class="h4" :from="0" :to="workingOutCompleted.liftedWeight"></number><span>KG</span>
			</v-col>
		</v-row>

		<!--
      <v-card-title class="justify-center">
        <v-img class="workingOutCompleted__card-cow-image" src="/img/cow.png" />
      </v-card-title>
-->
		<lottie-player
			mode="normal"
			class="workingOut__lottie-player"
			name="loading"
			:autoplay="false"
			style="width:200px;height:150px;"
			src="/img/lottiefiles/fitnessCow.json"
		></lottie-player>

		<div v-if="workingOutCompleted.cowCount">
			<v-card-title class="h6 justify-center break-all pt-5">Das entspricht einem Gewicht von:</v-card-title>
			<v-card-title class="h6  pt-0 justify-center">
				<number v-if="workingOutCompleted.cowCount" class="pr-3" :from="0" :to="workingOutCompleted.cowCount"></number>
				<span v-else class="mr-3">0</span>

				{{ workingOutCompleted.cowCount !== 1 ? 'Kühen' : 'Kuh' }}
			</v-card-title>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	name: 'WorkingoutCompleted',
	data() {
		return {
			loading: true,
			liftedWeight: null,
			cowAverageWeight: 800,
			cowCount: null,
			duration: null,
			caloriePerMinute: 6.7,
			caloriesBurned: null,
		}
	},
	created() {
		if (!this.workingOutCompleted) {
			alert('Es gab einen Fehler beim berechnen deiner Workout Daten.')
			this.$router.push({ name: 'Home' })
		} else {
			this.setWorkoutStepsHistory()
			this.$confetti.start()
			setTimeout(() => {
				this.$confetti.stop()
			}, 1500)
		}
	},
	mounted() {
		this.setNavigationBarText({ text: 'Schliessen', state: false, endPoint: 'workingoutCompleted' })
	},
	beforeDestroy() {
		this.setSelectedNavigation('workout')
		this.setWorkoutCompletedInfos(null)
	},
	methods: {
		...mapActions('workingoutStore', ['resetFinishedStepsArray', 'resetActiveWorkout', 'setWorkoutStepsHistory', 'setWorkoutCompletedInfos']),
		...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
		...mapActions('navigationStore', ['setSelectedNavigation']),
		closeView() {
			this.$router.push({ name: 'Workouts' })
		},
	},
	computed: {
		...mapState('workingoutStore', ['finishedSteps', 'workoutStartTime', 'workingOutCompleted']),
		...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
	},
	watch: {
		navigationBarTextState(value) {
			if (value && this.navigationBarEndPoint === 'workingoutCompleted') {
				this.closeView()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOutCompleted__card {
	width: 90% !important;
	height: 80vh;
	margin: 5%;
	align-items: center;

	&-cow-image {
		width: 50%;
		flex: unset;
		display: unset;
	}
}
</style>
