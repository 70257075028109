<template>
    <v-container>
        <v-card flat dark>
            <v-card-title>Angebote</v-card-title>
            <v-row>
                <v-col cols="12" v-if="offers.length === 0">
                    <v-alert type="info">
                        Keine Angebote gefunden
                    </v-alert>
                </v-col>
                <v-col cols="12" v-for="item in offers" :key="item._id" class="mb-4">
                    <v-card outlined>
                        <v-card-title>
                            <v-row>
                                <v-col cols="8">
                                    <strong>{{ item.title }}</strong>
                                </v-col>
                                <v-col cols="4" class="text-right">
                                    <v-switch color="primary" v-model="item.isSelected"
                                        @change="toggleStudio(item._id, item.isSelected)"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <p>{{ item.description }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            offers: [],
        };
    },
    created() {
        this.fetchOffers();
    },
    methods: {
        async fetchOffers() {
            this.offers = await api.fetchOffers();
        },
        async toggleStudio(id, isSelected) {
            const action = isSelected ? "add" : "remove";
            await api.toggleOfferService(id, action);
        },
    },
};
</script>
