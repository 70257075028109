<template xmlns="">
	<div class="deviceListWrapper" :style="active ? '' : 'overflow: hidden; max-height: 80vh;'">
		<v-card-title class="h5">
			Geräte- und Übungsverwaltung
		</v-card-title>

		<div class="deviceList__actions mb-5 d-flex flex-row">
			<div class="deviceList__actions-search listitemcolor mr-5 rounded-xl d-flex ">
				<input class="deviceList__actions-search-input" type="text" v-model="searchString" placeholder="Gerät suchen..." />
			</div>
			<v-btn color="primary" class="deviceList-add-button white--text" @click="createDevice()">NEU</v-btn>
		</div>
		<v-expansion-panels>
			<v-expansion-panel v-for="category of categories" :key="category">
				<v-expansion-panel-header>
					{{ category }}
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div
						v-for="device of deviceList.filter((e) => e.category === category)"
						:key="device._id"
						class="deviceList__list-device listitemcolor mb-5 elevation-5 rounded"
					>
						<v-speed-dial :value="device.fab" absolute right top transition="slide-x-reverse-transition" direction="left">
							<template v-slot:activator>
								<v-btn color="grey" small fab>
									<v-icon v-if="device.fab" color="white">mdi-close</v-icon>
									<v-icon v-else color="white">
										mdi-dots-horizontal
									</v-icon>
								</v-btn>
							</template>

							<v-btn v-if="userService.hasRight('ex_edit')" fab small color="blue" @click.stop="editDevice(device)">
								<lottie-player
									mode="normal"
									style="width:35px;height:35px;"
									class="deviceList__list-device-fab-lottie"
									name="edit"
									autoplay
									src="/img/lottiefiles/editWhite.json"
								></lottie-player>
							</v-btn>
							<v-btn v-if="userService.hasRight('ex_delete')" fab small @click.stop="deleteDevice(device, 0)" color="red">
								<lottie-player
									mode="normal"
									style="width:35px;height:35px;"
									class="deviceList__list-device-fab-lottie"
									name="delete"
									autoplay
									src="/img/lottiefiles/binWhite.json"
								></lottie-player>
							</v-btn>
						</v-speed-dial>
						<div class="d-flex flex-row ">
							<div class="deviceList__list-device-image d-flex justify-center">
								<v-img
									v-if="device.picture"
									class="my-auto bgpositionleftcenter"
									contain
									height="100px"
									width="140px"
									:src="'/img/uebungen/' + device.picture"
								/>
								<v-img
									v-else
									class="my-auto bgpositionleftcenter"
									contain
									height="100px"
									width="140px"
									src="/img/einmalig/imageUploadPlaceholder.jpg"
								/>
							</div>
							<div class="d-flex flex-column">
								<div class="deviceList__list-device-short">
									<v-card-subtitle class="pb-0 pl-4" color="primary">{{ device.shortForm }}</v-card-subtitle>
								</div>
								<div class="deviceList__list-device-title">
									<v-card-title class="deviceList__list-device-title-h h6 pt-0 pb-0">{{ device.title }}</v-card-title>
								</div>

								<div class="deviceList__list-device-exercises">
									<div v-if="device.exercises && device.exercises.length > 0">
										<v-card-text class="pt-1">
											<span v-for="(exercise, i) of device.exercises.slice(0, 2)" :key="i + '-exercise'">
												<span v-if="exercise">
													<span v-if="exercise.title && i <= 1">{{ shorten(exercise.title, 10) }}</span>
													<span class="pr-2" v-if="i !== 1">,</span>
													<br v-if="i == 1" />
													<strong v-if="i == 1 && device.exercises.length - (i + 1) > 0">
														{{ device.exercises.length - (i + 1) }} weitere Übungen
													</strong>
												</span>
											</span>
										</v-card-text>
									</div>
									<div v-else><span>Keine Übungen vorhanden</span></div>
								</div>
							</div>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<div class="deviceList__list px-1">
			<div class="text-center my-5">
				<v-pagination v-model="page" :length="33"></v-pagination>
			</div>
			<div
				v-for="device of deviceList.filter((e) => !e.category)"
				:key="device._id"
				class="deviceList__list-device listitemcolor mb-5 elevation-5 rounded"
			>
				<v-speed-dial :value="device.fab" absolute right top transition="slide-x-reverse-transition" direction="left">
					<template v-slot:activator>
						<v-btn v-model="device.fab" color="grey" small fab>
							<v-icon v-if="device.fab" color="white">mdi-close</v-icon>
							<v-icon v-else color="white">
								mdi-dots-horizontal
							</v-icon>
						</v-btn>
					</template>

					<v-btn fab small color="blue" @click.stop="editDevice(device)">
						<lottie-player
							mode="normal"
							style="width:35px;height:35px;"
							class="deviceList__list-device-fab-lottie"
							name="edit"
							autoplay
							src="/img/lottiefiles/editWhite.json"
						></lottie-player>
					</v-btn>
					<v-btn fab small @click.stop="deleteDevice(device, 0)" color="red">
						<lottie-player
							mode="normal"
							style="width:35px;height:35px;"
							class="deviceList__list-device-fab-lottie"
							name="delete"
							autoplay
							src="/img/lottiefiles/binWhite.json"
						></lottie-player>
					</v-btn>
				</v-speed-dial>
				<div class="d-flex flex-row ">
					<div class="deviceList__list-device-image d-flex justify-center">
						<v-img
							v-if="device.picture"
							class="my-auto bgpositionleftcenter"
							contain
							height="100px"
							width="140px"
							:src="'/img/uebungen/' + device.picture"
						/>
						<v-img
							v-else
							class="my-auto bgpositionleftcenter"
							contain
							height="100px"
							width="140px"
							src="/img/einmalig/imageUploadPlaceholder.jpg"
						/>
					</div>
					<div class="d-flex flex-column">
						<div class="deviceList__list-device-short">
							<v-card-subtitle class="pb-0 pl-4" color="primary">{{ device.shortForm }}</v-card-subtitle>
						</div>
						<div class="deviceList__list-device-title">
							<v-card-title class="deviceList__list-device-title-h h6 pt-0 pb-0">{{ device.title }}</v-card-title>
						</div>

						<div class="deviceList__list-device-exercises">
							<div v-if="device.exercises && device.exercises.length > 0">
								<v-card-text class="pt-1">
									<span v-for="(exercise, i) of device.exercises.slice(0, 2)" :key="i + '-exercise'">
										<span v-if="exercise">
											<span v-if="exercise.title && i <= 1">{{ shorten(exercise.title, 10) }}</span>
											<span class="pr-2" v-if="i !== 1">,</span>
											<br v-if="i == 1" />
											<strong v-if="i == 1 && device.exercises.length - (i + 1) > 0">
												{{ device.exercises.length - (i + 1) }} weitere Übungen
											</strong>
										</span>
									</span>
								</v-card-text>
							</div>
							<div v-else><span>Keine Übungen vorhanden</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<router-view @devicesChange="fetchDevices"></router-view>
		<v-dialog v-model="deleteDialog" persistent max-width="290">
			<v-card>
				<v-card-subtitle class="pb-0 pt-5">{{ deletingDevice.shortForm }}</v-card-subtitle>
				<v-card-title class="text-h5 pt-0">
					{{ deletingDevice.title }}
				</v-card-title>

				<v-card-text>
					Möchtest Du das Gerät
					<strong>{{ deletingDevice.title }}</strong>
					mit {{ deletingDevice.exercises.length }} Übungen wirklich endgültig löschen?
				</v-card-text>
				<v-card-text class="red--text">Das Gerät und die Übungen werden aus allen vorhandenen Trainingsplänen entfernt.</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="deleteDialog = false">
						Abbrechen
					</v-btn>
					<v-btn color="green darken-1" text @click="deleteDevice(deletingDevice, 1)">
						Löschen
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	name: 'deviceList',
	data() {
		return {
			categories: [],
			page: 1,
			searchString: '',

			userService,
			deleteDialog: false,
			deletingDevice: {
				shortForm: '',
				title: '',
				exercises: [],
			},
		}
	},
	created() {
		this.fetchDevices()
	},
	methods: {
		...mapActions('deviceStore', ['setDeviceList', 'removeDevice', 'resetDevice']),
		shorten(text, length = 25) {
			if (text.length > length) text = text.substr(0, length) + '...'
			return text
		},
		createDevice() {
			this.resetDevice()
			this.$router.push({ name: 'DeviceCreate' })
		},
		editDevice(device) {
			this.resetDevice()
			this.$router.push('/devices/' + device._id)
		},
		async fetchDevices() {
			this.categories = await api.fetchDeviceCategories()
			api.fetchDevices(null).then((deviceList) => {
				deviceList.forEach((e) => (e.fab = false))

				this.setDeviceList(JSON.parse(JSON.stringify(deviceList)))
			})
		},
		async deleteDevice(device, status) {
			if (status === 0) {
				this.deletingDevice = device
				this.deleteDialog = true
			} else {
				await api.deleteDevice(device)
				this.removeDevice(device)
				this.deleteDialog = false
			}
		},
	},
	computed: {
		...mapState('deviceStore', ['deviceList']),
		...mapGetters('deviceStore', ['exerciseList']),
		//...mapState('deviceStore', ['deviceList']),
		active() {
			if (this.$route.name === 'DeviceList') return true
			return false
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .bgpositionleftcenter .v-image__image {
	background-position: left center !important;
}
.deviceListWrapper {
	position: relative;

	overflow-y: scroll;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.deviceList {
	&__list {
		&-device {
			position: relative;

			&-title {
				&-h {
					word-break: keep-all;
				}
			}
			&-image {
			}
		}
	}
	&-add-button {
		display: block;
		margin: auto;
	}

	&__actions {
		&-search {
			height: 50px;
			width: 100%;

			&-input {
				padding-left: 10px;
			}
		}
	}
}
</style>
