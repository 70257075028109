<template>
    <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
        <v-card-title>Ziele</v-card-title>
        <v-card-text style="overflow: auto">
            <v-list-item v-for="(target, index) of targets" :key="'target-' + index">
                <template v-slot:default="{ active }">
                    <v-list-item-action>
                        <v-checkbox :input-value="target.value" color="primary"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{ target.text }}</v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-list-item>
        </v-card-text>
        <v-card-actions style="justify-content: right">
            <v-btn text x-small>Abgeschlossene Ziele</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userSearch from '../../../components/userSearch.vue'

export default {
    props: {
        targets: {
            required: true,
        },
    },
    computed: {},
}
</script>
