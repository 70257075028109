<template>
    <div class="campaigns">
        <div class="select-studio">
            <select class="white--text" v-show="wellcomefit" v-model="filterstudio" id="studio_code" style="">
                <option disabled="disabled" value="">Studio auswählen</option>
                <option value="Frauenfeld">Frauenfeld</option>
                <option value="Netstal">Netstal</option>
                <option value="Bülach">Bülach</option>
                <option value="West">St. Gallen West</option>
                <option value="Ost">St. Gallen Ost</option>
                <option value="Einstein">St. Gallen Einstein</option>
                <option value="Dietlikon">Dietlikon</option>
                <option value="Pfungen">Pfungen</option>
                <option value="Winterthur">Winterthur</option>
                <option value="Test">Teststudio</option>
            </select>
            <select v-show="!wellcomefit" v-model="filterstudio" id="studio_code" style="">
                <option disabled="disabled" value="">Studio auswählen</option>
                <option value="Wetzikon">Wetzikon</option>
            </select>
        </div>
        <div class="campaigns-wrapper white--text">
            <div v-if="!campaigns.campaigns" class="no-campaigns">
                Es gibt noch keine Kampagne, füge jetzt welche hinzu
            </div>

            <div v-else v-for="(cp, i) of campaigns.campaigns" :key="i" class="campaigns" v-show="checkIfVisible(cp)">
                {{ cp.untertitel }}

                <div class="studio-name-wrapper">
                    <div
                        v-for="(studio, ij) of campaigns.campaigns_studio"
                        :key="ij"
                        class="studio-titel"
                        v-show="studio.campaign_id == cp._id"
                    >
                        {{ studio_names[studio.studio_id] }}
                    </div>
                </div>

                <div class="span-wrapper">
                    <span @click="deleteCampaign(cp)" class="material-icons">delete_outline</span>
                    <span @click="copyCampaign(cp)" class="material-icons">content_copy</span>
                    <span @click="showOrders(cp)" class="material-icons">shopping_cart</span>
                    <span v-show="!cp.untertitel.includes('Helsana')" @click="editCampaign(cp)" class="material-icons">
                        edit
                    </span>
                </div>
            </div>
        </div>

        <div class="create-campaigns" @click="createCampaign()" v-show="!editing">Hinzufügen</div>
        <div v-show="editing" class="editor">
            <div class="editor-wrapper">
                <div class="heading">Kampagne</div>

                <label>Titel</label>
                <input placeholder="Überschrift" type="text" v-model="campaigndata.titel" />
                <label>Untertitel</label>
                <input placeholder="Inhalt" type="text" v-model="campaigndata.untertitel" />
                <label>Beschreibung</label>
                <textarea placeholder="Inhalt" v-model="campaigndata.beschreibung"></textarea>
                <label>Foto</label>
                <label for="bild-upload-edit">Foto hochladen</label>
                <input type="file" accept="image/*" @change="editImage($event)" id="bild-upload-edit" />

                <div class="preview" v-show="campaigndata.foto">
                    <img :src="'/img/news/' + campaigndata.foto" />
                </div>

                <label>Preis</label>
                <input type="number" v-model="campaigndata.preis" />
                <label>Rabatt</label>
                <input type="number" v-model="campaigndata.rabatt" />
                <label>Miete</label>
                <input type="number" v-model="campaigndata.miete" />
                <label>Depot</label>
                <input type="number" v-model="campaigndata.depot" />
                <label>Zusätzliche Information</label>
                <input type="text" v-model="campaigndata.zus_info" />
                <label>Anzahl</label>
                <input type="number" v-model="campaigndata.anzahl" />

                <label>Text nach Reservation</label>
                <textarea placeholder="Inhalt" v-model="campaigndata.reservationstext"></textarea>

                <label>Anzeigen von</label>
                <input type="date" v-model="campaigndata.von" />
                <label>Anzeigen bis</label>
                <input type="date" v-model="campaigndata.bis" />

                <label>in folgenden Studios anzeigen:</label>
                <select
                    v-model="campaigndata.studio_id"
                    v-if="studios_all.length > 0"
                    class="mul-select"
                    multiple="true"
                >
                    <option v-for="(studio, j) of studios_all" :key="j" :value="studio._id">
                        {{ studio.name }}
                    </option>
                </select>
                <div @click="saveCampaign()" class="speichern">Speichern</div>
                <div @click="hide()" class="schliessen">Zurück</div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../../api'
import zipcelx from 'zipcelx'
export default {
    data() {
        return {
            rollen: {},
            wellcomefit: true,
            filterstudio: '',
            filterstudio_array: [],
            campaigns: [],
            campaign_order: [],
            campaigndata: {
                titel: null,
                untertitel: null,
                beschreibung: null,
                foto: null,
                preis: null,
                anzahl: null,
                rabatt: null,
                miete: null,
                depot: null,
                zus_info: null,
                reservationstext: null,
                von: null,
                bis: null,
                studio_id: [],
            },
            studio_names: [],
            studios_all: [],
            editing: false,
        }
    },
    created() {
        this.loadCampaign()
        if (location.host.includes('wellcomefit') || location.host.includes('wintifit')) {
        } else {
            this.wellcomefit = false
        }
    },
    methods: {
        checkIfVisible(cp) {
            if (this.filterstudio == '') {
                return true
            } else {
                if (this.filterstudio_array[cp._id]) {
                    if (this.filterstudio_array[cp._id].indexOf(this.filterstudio) > -1) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
        showOrders(campaign) {
            api.getCampaignList(campaign._id)
                .then((data) => {
                    let exceldata = []
                    exceldata.push([
                        {
                            value: 'Datum',
                            type: 'string',
                        },
                        {
                            value: 'Vorname',
                            type: 'string',
                        },
                        {
                            value: 'Name',
                            type: 'string',
                        },
                        {
                            value: 'Adresse_1',
                            type: 'string',
                        },
                        {
                            value: 'Adresse_2',
                            type: 'string',
                        },

                        {
                            value: 'PLZ',
                            type: 'string',
                        },
                        {
                            value: 'Stadt',
                            type: 'string',
                        },
                        {
                            value: 'Land',
                            type: 'string',
                        },
                        {
                            value: 'Geschlecht',
                            type: 'string',
                        },
                        {
                            value: 'Geburtstag',
                            type: 'string',
                        },
                        {
                            value: 'E-Mail',
                            type: 'string',
                        },
                        {
                            value: 'Telefon',
                            type: 'string',
                        },
                        {
                            value: 'Studio',
                            type: 'string',
                        },
                    ])
                    for (let user of data) {
                        exceldata.push([
                            {
                                value: user.datum,
                                type: 'string',
                            },
                            {
                                value: user.vorname,
                                type: 'string',
                            },
                            {
                                value: user.nachname,
                                type: 'string',
                            },
                            {
                                value: user.adresse_1,
                                type: 'string',
                            },
                            {
                                value: user.adresse_2,
                                type: 'string',
                            },

                            {
                                value: user.plz,
                                type: 'string',
                            },
                            {
                                value: user.stadt,
                                type: 'string',
                            },
                            {
                                value: user.land,
                                type: 'string',
                            },
                            {
                                value: user.geschlecht,
                                type: 'string',
                            },
                            {
                                value: user.geburtstag,
                                type: 'string',
                            },
                            {
                                value: user.email,
                                type: 'string',
                            },
                            {
                                value: user.telefon,
                                type: 'string',
                            },
                            {
                                value: user.studio_name,
                                type: 'string',
                            },
                        ])
                    }
                    const config = {
                        filename: 'Bestellungen',
                        sheet: {
                            data: exceldata,
                        },
                    }

                    zipcelx(config)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        editImage(event) {
            if (event.target.files[0]) {
                let uploadimg = {}
                uploadimg.art = 'news'
                const formData = new FormData()
                let file = event.target.files[0]

                formData.append('file', event.target.files[0])

                uploadimg.formData = formData

                api.uploadImage(uploadimg)
                    .then((data) => {
                        if (data.status) {
                            this.campaigndata.foto = 'uploads/' + data.name
                            alert('bild hochgeladen')
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        deleteCampaign(campaign) {
            if (confirm('Wollen Sie die Kampagne wirklich unwiederruflich löschen?')) {
                api.deleteCampaign(campaign)
                    .then((data) => {
                        if (data.status == 1) {
                            alert('erfolgreich gelöscht')
                            this.loadCampaign()
                        }
                    })
                    .catch((error) => {
                        alert('Ein Fehler trat auf')
                    })
            }
        },
        copyCampaign(campaign) {
            if (confirm('Wollen Sie die Kampagne kopieren?')) {
                api.copyCampaign(campaign)
                    .then((data) => {
                        if (data.status == 1) {
                            alert('erfolgreich kopiert')
                            this.loadCampaign()
                        }
                    })
                    .catch((error) => {
                        alert('Ein Fehler trat auf')
                    })
            }
        },
        loadCampaign() {
            api.getAllCampaigns()
                .then((data) => {
                    api.getStudios()
                        .then((datas) => {
                            this.studios_all = datas
                            for (let studio of datas) {
                                this.studio_names[studio._id] = studio.name
                            }
                            this.campaigns = data
                            for (let c of data.campaigns) {
                                for (let s of data.campaigns_studio) {
                                    if (c._id == s.campaign_id) {
                                        if (!this.filterstudio_array[c._id]) {
                                            this.filterstudio_array[c._id] = ''
                                        }
                                        this.filterstudio_array[c._id] += this.studio_names[s.studio_id]
                                    }
                                }
                            }
                            this.$emit('finishedloading')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        editCampaign(campaign) {
            this.campaigndata = campaign

            let month = new Date(campaign.von * 1000).getMonth() + 1
            if (month < 10) {
                month = '0' + month
            }

            let day = new Date(campaign.von * 1000).getDate()
            if (day < 10) {
                day = '0' + day
            }
            this.campaigndata.von = new Date(campaign.von * 1000).getFullYear() + '-' + month + '-' + day

            month = new Date(campaign.bis * 1000).getMonth() + 1
            if (month < 10) {
                month = '0' + month
            }

            day = new Date(campaign.bis * 1000).getDate()
            if (day < 10) {
                day = '0' + day
            }
            this.campaigndata.bis = new Date(campaign.bis * 1000).getFullYear() + '-' + month + '-' + day

            this.campaigndata.studio_id = []
            for (let cps of this.campaigns.campaigns_studio) {
                if (cps.campaign_id == campaign._id) {
                    this.campaigndata.studio_id.push(cps.studio_id)
                }
            }

            this.editing = true
        },
        createCampaign() {
            this.editing = true
        },
        hide() {
            this.editing = false
            this.campaigndata = {}
        },
        saveCampaign() {
            if (this.campaigndata.titel && this.campaigndata.von && this.campaigndata.bis) {
                this.campaigndata.von = Math.round(new Date(this.campaigndata.von).setHours(0, 0, 0, 0) / 1000)
                this.campaigndata.bis = Math.round(new Date(this.campaigndata.bis).setHours(23, 59, 59, 59) / 1000)

                api.createCampaign(this.campaigndata).then((data) => {
                    if (data.status) {
                        this.hide()
                        this.loadCampaign()
                    }
                })
            } else {
                alert('bitte füllen Sie alle Daten aus')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.campaigns-wrapper > .campaigns {
    .span-wrapper {
        margin-left: auto;
        span:nth-of-type(1) {
            margin-right: 10px;
        }
        span {
            padding-right: 5px;
        }
    }
}
label[for='bild-upload-edit'] {
    border: 1px solid #ced4da;
    color: #495057;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 0.25rem;
}
#bild-upload-edit {
    display: block;
    visibility: hidden;
    height: 0px;
    width: 0px;
    position: absolute;
}
.heading {
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}

.studio-name-wrapper {
    display: flex;
    .studio-titel {
        font-size: 11px;
        margin-right: 10px;
    }
}

.campaigns .campaigns {
    padding: 10px 15px;

    border-bottom: 1px solid black;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.preview {
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
    img {
        height: 200px;
        object-fit: contain;
    }
}

.no-campaigns {
    padding: 10px 15px;
}

.campaigns-wrapper {
    display: flex;
    flex-direction: column;
}

.editor {
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    position: absolute;
    top: 0;
    z-index: 99999;
    background-color: white;
    .editor-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        input,
        textarea {
            width: 300px;

            margin: 0px auto 40px auto;
            color: #4a4a4a;

            min-height: 40px;

            text-rendering: auto;
            padding: 2px;

            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            display: inline-block;
            text-align: start;
            box-sizing: border-box;
            align-items: center;

            border-radius: 0px;
            border-width: 0px;
            border-style: solid;
            -o-border-image: initial;
            border-image: initial;

            border-bottom: 1px solid black;
        }
        select {
            max-width: 400px;
            margin: auto;
            min-width: 300px;
            padding: 10px;
        }
        textarea {
            min-height: 300px;
        }
    }
    .speichern {
        background-color: var(--v-primary);
        color: white;
        margin: 20px auto 0px auto;
        padding: 10px 15px;
        width: 90%;
        text-align: center;
        border-radius: 45px;
    }
    .schliessen {
        background-color: #443838;
        color: white;
        margin: 20px auto;
        padding: 10px 15px;
        width: 90%;
        text-align: center;
        border-radius: 45px;
    }
}

.create-campaigns {
    position: fixed;
    bottom: 10vh;
    /* margin: auto; */
    right: calc(50% - 100px);
    width: 200px;
    padding: 10px 10px;
    background-color: var(--v-primary);
    color: white;
    text-align: center;
    border-radius: 10px;
}

.back-navigation {
    background-color: var(--v-primary);
    color: white;
    padding: 0px 10px;
    font-weight: 500;
    display: flex;
    margin-bottom: 20px;

    span {
        margin-top: auto;
        margin-bottom: auto;
    }
    a {
        display: flex;
        padding: 5px;
        color: white;

        span {
            font-size: 34px;
        }
    }
}
</style>
