<template>
    <div class="text-center">
        <v-select
            class="mx-5"
            v-model="selectedDeviceId"
            :items="videoInputDevices"
            item-text="label"
            item-value="deviceId"
        ></v-select>
        <video ref="video" id="video" style="width: 90vw" class="rounded-xl mx-auto"></video>
        <p>{{ barcode }}</p>
    </div>
</template>
<script>
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library'

export default {
    name: 'BarcodeScanner',
    data() {
        return {
            barcode: null,
            scanner: null,
            videoInputDevices: [],
            selectedDeviceId: null,
        }
    },
    mounted() {
        this.setupCamera()
    },
    watch: {
        selectedDeviceId(newval) {
            if (this.scanner) {
                this.scanner.reset()
            }
            this.initializeScanner()
        },
    },
    methods: {
        async setupCamera() {
            try {
                await navigator.mediaDevices.getUserMedia({ video: true })

                const devices = await navigator.mediaDevices.enumerateDevices()

                const videoInputDevices = devices.filter((device) => device.kind === 'videoinput')
                this.videoInputDevices = videoInputDevices
                if (videoInputDevices.length > 0) {
                    this.videoInputDevices = videoInputDevices
                    this.selectedDeviceId = videoInputDevices[0]?.deviceId
                    if (this.videoInputDevices.find((device) => device.label.includes('Back')))
                        this.selectedDeviceId = this.videoInputDevices.find((device) =>
                            device.label.includes('Back')
                        ).deviceId
                    this.initializeScanner()
                    // Da der Scanner in initializeScanner initialisiert wird, ist kein weiterer Aufruf hier notwendig
                } else {
                    console.log('Keine Video-Eingabegeräte gefunden.')
                }
            } catch (error) {
                console.error('Error initializing scanner:', error)
            }
        },
        initializeScanner() {
            console.log('initialize')
            if (this.selectedDeviceId) {
                this.scanner = new BrowserMultiFormatReader()
                const hints = new Map()
                hints.set(DecodeHintType.POSSIBLE_FORMATS, [
                    BarcodeFormat.EAN_13,
                    BarcodeFormat.UPC_A,
                    BarcodeFormat.CODE_128,
                ])

                this.scanner.decodeFromVideoDevice(
                    this.selectedDeviceId,
                    'video',
                    (result, err) => {
                        // console.log('scan')
                        if (result) {
                            this.barcode = result.text
                            this.$emit('found', result.text)
                        }
                    },
                    hints
                )
            }
        },
    },
    beforeDestroy() {
        this.videoInputDevices = []
        this.selectedDeviceId = null
        this.scanner.reset()
    },
}
</script>