<template>
  <div class="zeitplaene">
    <div class="back-navigation">
      <a class="back-link" href="javascript:history.go(-1)">
        <span class="material-icons">navigate_before</span>
      </a>
      <span>Zeitpläne</span>
      <div v-show="userService.hasRight('gf_c_plan')" class="add-zeitplaene">
        <router-link :to="{ path: '/kurse/create/zeitplan/' + zeitplan_infos.kurs_id }">
          <span class="material-icons">post_add</span>
        </router-link>
      </div>
    </div>
    <div v-show="verwendetin" class="info-text">Kurs wird in folgenden Zentren verwendet: {{ verwendetin }}</div>
    <ul v-show="repeated" class="zeitplaene-wrapper">
      <span class="title-time">Wiederholend</span>
      <li v-for="(zeitplan, i) in zeitplaene_repeated" :key="i">
        <div class="wochentag">{{ weekdays[zeitplan.wochentag] }}</div>
        <div class="startzeit">{{ zeitplan.startzeit }}</div>
        <div class="link-wrapper">
          <router-link :to="{ path: '/kurse/edit/zeitplan/' + zeitplan._id }">
            <span class="material-icons">edit</span>
          </router-link>
        </div>
      </li>
    </ul>
    <ul v-show="einmalig" class="zeitplaene-wrapper">
      <span class="title-time">Einmalig</span>
      <li v-for="(zeitplan, i) in zeitplaene" :key="i">
        <div class="datum">{{ zeitplan.datum }}</div>
        <div class="startzeit">{{ zeitplan.startzeit }}</div>
        <div class="link-wrapper">
          <router-link :to="{ path: '/kurse/edit/zeitplan/' + zeitplan._id }">
            <span class="material-icons">edit</span>
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
export default {
  data() {
    return {
      userService,
      zeitplan_infos: {
        kurs_id: null,
        studio_id: null,
      },
      verwendetin: '',
      zeitplaene: [],
      zeitplaene_repeated: [],
      repeated: false,
      einmalig: false,
      weekdays: ['null', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
    }
  },
  methods: {
    loadTimelines() {
      api
        .getKurstimelineByStudio(this.zeitplan_infos)
        .then((data) => {
          data.course_timelines.forEach((timeline) => {
            if (timeline.wiederholend) {
              this.zeitplaene_repeated.push(timeline)
              this.repeated = true
            } else {
              this.zeitplaene.push(timeline)
              this.einmalig = true
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  async created() {
    this.zeitplan_infos.kurs_id = this.$route.params.id
    let studio = null

    studio = await api.getUserStudio().catch((e) => {
      window.app.$root.$children[0].checkStudio()
    })
    studio = studio.studio
    let studio_id = studio._id
    this.zeitplan_infos.studio_id = studio_id
    this.loadTimelines()
    this.verwendetin = ''

    api
      .checkCourseUsage(this.$route.params.id)
      .then((data) => {
        if (data.length > 0) {
          for (let studio of data) {
            this.verwendetin += studio + ', '
          }
        }
      })
      .catch((error) => {
        this.verwendetin = 'Es gab ein Fehler beim überprüfen des Kurses bitte melde dies bei Dominic'
      })
  },
}
</script>
<style lang="scss" scoped>
.info-text {
  color: #f00;
  font-weight: bold;
  margin: 5px 15px;
}
.zeitplaene-wrapper {
  padding: 0px;
  margin-top: 10px;
  .title-time {
    font-weight: 500;
    padding-left: 15px;
  }

  .wochentag,
  .datum {
    margin-right: 10px;
  }
  .link-wrapper {
    margin-left: auto;
    margin-right: 15px;
  }
  .wochentag,
  .startzeit,
  .datum {
    color: #4a4a4a;
  }

  li {
    font-weight: 300;
    padding: 5px 15px;
    display: flex;

    border-bottom: 1px solid #4a4a4a;
  }
  span {
    color: #4a4a4a;
  }
}
.back-navigation {
  background-color: #52ae32;
  color: white;
  padding: 0px 10px;
  font-weight: 500;
  display: flex;

  .add-zeitplaene {
    margin-left: auto;
    margin-right: 10px;
  }

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
  a {
    display: flex;
    padding: 5px;
    color: white;

    span {
      font-size: 34px;
    }
  }
}
</style>
