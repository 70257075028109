<template>
	<v-row justify="center">
		<v-dialog
			style="z-index: 2000;"
			content-class="habitLightBlue"
			persistent
			v-model="dialog"
			transition="dialog-bottom-transition"
			origin="bottom center"
		>
			<div class="text-center">
				<v-card-title class="drink-execution__title justify-content-center white--text">
					Deine Schlafgewohnheit
					<v-btn icon color="white" @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-img contain height="100px" :src="'/img/lottiefiles/' + habit.icon"></v-img>
				<v-menu
					ref="menu"
					v-model="timePickerMenu"
					:close-on-content-click="false"
					:nudge-right="40"
					:return-value.sync="time"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="time"
							class="ma-auto"
							color="habitBlue"
							readonly
							dense
							v-bind="attrs"
							v-on="on"
							style="width: 40%; text-align-last: center; font-size: 18px;"
						></v-text-field>
					</template>
					<v-time-picker
						color="habitBlue"
						:allowed-hours="allowedHours"
						v-if="timePickerMenu"
						v-model="time"
						full-width
						@click:minute="$refs.menu.save(time)"
					></v-time-picker>
				</v-menu>
				<v-btn @click="addAmount()" height="unset" class="white--text pa-3" elevation="3" rounded color="habitBlue"
					><span style="font-size: 18px;">Einchecken</span></v-btn
				>
			</div>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
export default {
	name: 'habitWakeUpExecution',
	props: {
		date: {
			type: Object,
			required: true,
		},
		habit: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			timePickerMenu: false,
			selectedHabit: null,
			dialog: true,
			templateSelection: false,
			templatesList: [],
			halfGlas: false,
			time: null,
		}
	},
	created() {
		this.time = this.habit.time
	},
	computed: {
		allowedHours() {
			return Array.from({ length: 12 }, (_, i) => i + 1)
		},
	},
	methods: {
		addAmount() {
			this.habit.execute(this.time)
			console.log('add amount finished')
		},
		closeDialog() {
			this.dialog = false
			setTimeout(() => {
				this.$emit('closeExecutionForm')
			}, 500)
		},
	},
}
</script>

<style lang="scss" scoped>
.drink-execution {
	&__title {
		position: sticky;
		min-height: 68px;
		width: 100%;
		border-top-right-radius: 25px;
		border-top-left-radius: 25px;
		background-color: #4bb2fd;
	}
	&__add-button {
		background-color: #4bb2fd;
		color: white;
	}
}

::v-deep .v-dialog {
	transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	width: 100%;
	margin: 0;
	position: absolute;
	min-height: 40vh;
	bottom: 0;
	background: white;
	max-height: 90vh;
}

.option-button {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		background: lightgray;
		&-bulb {
			::v-deep .v-image__image {
				width: 80px;
			}
			flex-direction: row;
		}
	}
	&__wrapper:hover {
		background: gray;
	}
}
::v-deep .v-time-picker-clock__ampm {
	display: none;
}
</style>
