<template>
    <div>
        <v-col cols="12">
            <v-img height="65px" @click="$router.push('/')" contain src="/img/roodyLogoGreen.png"></v-img>
        </v-col>
        <v-row class="justify-space-around" style="height: 80vh">
            <v-card-title class="font-weight-bold" style="word-break: break-word">
                Bitte gedulde dich einen Moment. Deine Zahlung wird geprüft, Du wirst automatisch weitergeleitet.
            </v-card-title>
            <v-col cols="12" class="text-center">
                <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import axios from 'axios'
import api from '@/api'

export default {
    async created() {
        this.checkPayment()
    },
    methods: {
        async checkPayment() {
            try {
                // Hole den redirectResult aus der URL
                let transactionID = this.$route.params.transactionID
                let redirectResult = this.$route.query.redirectResult
                console.log('test')
                if (!redirectResult) {
                    console.error('RedirectResult missing')
                    this.$router.push('/adyen/payment-error') // Leite auf eine Fehlerseite weiter, wenn kein redirectResult vorhanden ist
                    return
                }

                let response
                let retryCount = 0
                const maxRetries = 60 // Maximale Anzahl an Wiederholungen

                // Wiederhole die Anfrage, bis kein 'webhookMissing' mehr zurückgegeben wird oder die maximale Anzahl erreicht ist
                do {
                    // Sende den redirectResult an den Server zur Verifizierung
                    response = await api.verifyAdyenWithDetails(transactionID, redirectResult, retryCount)
                    if (response === 'webhookMissing') {
                        if (retryCount >= maxRetries) {
                            console.error('Maximale Anzahl an Versuchen erreicht')
                            this.$router.push('/adyen/payment-failed')
                            return
                        }
                        retryCount++
                        // Warte 1 Sekunde vor dem nächsten Versuch
                        await new Promise((resolve) => setTimeout(resolve, 2500))
                    }
                } while (response === 'webhookMissing')

                if (response.resultCode === 'Authorised') {
                    // Zahlung erfolgreich, leite auf eine Erfolgsseite weiter
                    this.$router.push('/adyen/payment-success')
                } else {
                    // Zahlung fehlgeschlagen, leite auf eine Fehlerseite weiter
                    this.$router.push('/adyen/payment-failed')
                }
            } catch (error) {
                console.error('Error during payment verification', error)
                //this.$router.push('/adyen/payment-error'); // Leite auf eine generische Fehlerseite weiter
            }
        },
    },
}
</script>