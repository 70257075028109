<template>
    <div>
        <v-progress-circular
            :value="100 - progress"
            :rotate="180"
            :size="200"
            :width="15"
            :color="progress < 100 ? 'primary' : 'success'">
            <div class="text-center">
                <div class="headline font-weight-medium mb-2" :class="{ 'white--text': whiteText }">{{ timeLeft }}</div>
                <div class="caption font-weight-regular progress-text" :class="{ 'white--text': whiteText }">
                    {{ progress < 100 ? 'Verbleibende Zeit' : 'Zeit abgelaufen' }}
                </div>
            </div>
        </v-progress-circular>
    </div>
</template>
  
  <script>
import moment from 'moment'

export default {
    props: {
        startTime: {
            type: String,
            required: true,
        },
        duration: {
            type: Number,
            default: 30,
        },
        whiteText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            endTime: null,
            progress: 0,
            timeLeft: '',
            intervalId: null,
        }
    },
    mounted() {
        this.endTime = moment(this.startTime).add(this.duration, 'minutes')
        this.updateProgress()
        this.intervalId = setInterval(() => {
            this.updateProgress()
            if (moment().isAfter(this.endTime)) {
                clearInterval(this.intervalId)
                this.$emit('timerEnded')
            }
        }, 1000)
    },
    beforeDestroy() {
        clearInterval(this.intervalId)
    },
    methods: {
        updateProgress() {
            const now = moment()
            const diff = this.endTime.diff(this.startTime)
            const duration = moment.duration(diff)
            const elapsed = now.diff(this.startTime)
            this.progress = (elapsed / duration.asMilliseconds()) * 100
            this.progress = Math.min(Math.round(this.progress), 100)
            const diffTime = moment.utc(duration.asMilliseconds() - elapsed).format('mm:ss')
            if (this.progress !== 100) this.timeLeft = diffTime
        },
    },
}
</script>
