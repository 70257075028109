import createPersistedState from 'vuex-persistedstate'
export const studioStore = {
	namespaced: true,
	state: {
		storeStudios: []
	},
	mutations: {
		setStudios(state, data) {
			state.storeStudios = JSON.parse(JSON.stringify(data))
		},
	},
	actions: {
		setStudios({ commit }, data) {
			commit('setStudios', data)
		},
	},
}
