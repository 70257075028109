<template>
  <v-autocomplete
    :loading="loading"
    v-show="userService.hasRight(rights) || ignoreRights"
    v-model="selection"
    :label="label"
    :disabled="disabled"
    :items="users"
    :item-value="itemValue"
    @change="update"
    :rounded="rounded"
    :item-text="getItemText"
    :search-input.sync="search"
    menu-props="auto, overflowY"
    :filled="filled"
    :dark="dark"
    :outlined="outlined"
    prepend-icon="mdi-account-search-outline"
    :rules="[(v) => !!v || 'Auswahl ist erforderlich']"
    cache-items
    noDataText="Kein Benutzer gefunden"
  ></v-autocomplete>
</template>

<script>
import api from "../api";
import userService from "../services/userService";
import abortService from "../services/abortService";

export default {
  name: "userSearch",
  props: {
    noFetch: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    hideEmail: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    returnEmail: {
      type: Boolean,
      default: false,
    },
    returnUser: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Titel",
    },
    instructor: {
      type: Boolean,
      default: false,
    },
    rights: {
      type: String,
      default: "",
    },
    ignoreRights: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userService,
      abortService,
      users: [],
      selection: null,
      search: null,
      loading: false,
      timerId: null,
      abortController: null,
    };
  },
  watch: {
    search(val) {
      if (!val) {
        return;
      }

      this.fetchUsersDebounced(val);
    },
  },
  created() {
    this.abortController = new abortService();
    if (this.noFetch) return;

    this.ignoreRights && this.rights === "";
    this.value && this.fetchSingleUser(this.value);
    this.fetchUsers();
  },
  methods: {
    fetchSingleUser() {
      if (this.noFetch) return;
      this.loading = true;
      let id = this.value._id ? this.value._id : this.value;
      api.getUserById(id).then((user) => {
        this.users.push(user.user);
        this.selection = id;
        this.loading = false;
      });
    },
    fetchUsersDebounced(val) {
      this.$emit("inputEvent");
      // cancel pending call
      this.loading = true;
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(() => {
        this.fetchUsers(val);
      }, 500);
    },

    fetchUsers(val) {
      if (this.noFetch) return;
      this.abortController.abort("not needed anymore");
      this.abortController = new abortService();
      if (localStorage.getItem("token")) {
        if (this.instructor) {
          api
            .lookupInstructors(val, this.abortController.signal)
            .then((data) => {
              this.users = data;
              this.loading = false;
            });
        } else {
          api.lookupUsers(val, this.abortController.signal).then((data) => {
            this.users = data;
            this.loading = false;
          });
        }
      }
    },
    update(item) {
      if (this.returnUser) {
        this.$emit(
          "update",
          this.users.find((user) => user._id === item)
        );
      } else {
        this.$emit("update", this.selection, this.value);
      }
    },
    getItemText(user) {
      if (this.hideEmail) return user.vorname + " " + user.nachname;
      return user.vorname + " " + user.nachname + " (" + user.email + ")";
    },
  },
  beforeDestroy() {
    if (this.noFetch) return;
    this.abortController.abort("not needed anymore");
  },
  computed: {
    itemValue() {
      return this.returnEmail ? "email" : "_id";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__details {
  // Deine allgemeinen Stile für .parent-class

  &:not(:has(.v-messages.error)) {
    // Deine speziellen Stile, die nur angewendet werden,
    // wenn KEIN .v-messages.error als Kind-Element vorhanden ist
    margin: 0;
    display: none !important;
    min-height: 0;
  }
}
</style>