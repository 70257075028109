const api = require('../../api')
const moment = require('moment')
export class medicationHabit {
	constructor(
		medicationHabit = {
			type: 'medicationHabit',
			label: 'Medikament',
			description: '',
			icon: 'medicationReport.png',
			title: '',
			streak: 0,
			note: '',
			interval: null,
			days: [
				{
					day: moment().day(),
				},
			],
		}
	) {
		this.setMedicationHabit(medicationHabit)
	}
	getMedicationHabit() {
		let habit = {}
		for (let key in this) {
			habit[key] = this[key]
		}
		return habit
	}
	setMedicationHabit(habit) {
		for (let key in habit) {
			this[key] = habit[key]
		}
	}
	async execute(index, date) {
		// add execution and save
		let execution = {
			date: date,
		}

		try {
			let execution = await api.executeHabit(execution)
			this.execution.push(execution)
			return true
		} catch (e) {
			console.log('Error: ', e)
			return false
		}
	}
	async save() {
		try {
			let habit = await api.updateHabit(this.getMedicationHabit())
			this.setMedicationHabit(habit)
			return true
		} catch (e) {
			console.log('Error: ', e)
			return false
		}
	}
}
