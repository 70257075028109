<template>
    <div>
        <v-switch v-model="reloadAfterChanges" color="primary" label="Neuladen nach anpassung"></v-switch>
        <v-card-subtitle class="pt-0">
            Wenn das automatische neuladen deaktiviert ist, kann es zu Anzeigefehler kommen. Bitte dann über den Knopf
            neu laden.
            <v-btn color="primary" @click="$emit('fetchEntries')">Neu laden</v-btn>
        </v-card-subtitle>
        <v-row v-if="!editTimeEntry">
            <v-col cols="12">
                <v-card-text class="h6 pb-0" style="letter-spacing: 0.9px"><strong>Zeiterfassung</strong></v-card-text>
                <v-data-table
                    :items-per-page="40"
                    no-data-text="Keine Anwesenheits Einträge gefunden"
                    :headers="tableHeaders"
                    hide-default-footer
                    :expanded.sync="expanded"
                    :item-key="'date'"
                    :show-expand="
                        office ||
                        !timeEntryView.timeTrackerMonths ||
                        !timeEntryView.timeTrackerMonths.find(
                            (ttm) => ttm.month === $moment(this.month).format('YYYY-MM')
                        ) ||
                        (timeEntryView.timeTrackerMonths.find(
                            (ttm) => ttm.month === $moment(this.month).format('YYYY-MM')
                        ) &&
                            timeEntryView.timeTrackerMonths.find(
                                (ttm) => ttm.month === $moment(this.month).format('YYYY-MM')
                            ).state !== 'submitted')
                    "
                    :key="keyey"
                    :single-expand="true"
                    :items="tableItems"
                >
                    <template v-slot:item.startTime="{ item }">
                        <span>
                            {{ getStartTime(item.realDate, item.disableCorrection) }}
                        </span>
                    </template>
                    <template v-slot:item.endTime="{ item }">
                        <span>
                            {{ getEndTime(item.realDate, item.disableCorrection) }}
                        </span>
                    </template>
                    <template v-slot:item.duration="{ item }">
                        <span>
                            {{ getDurationForDay(item.realDate, item.disableCorrection).formattedTime }}
                        </span>
                    </template>
                    <template v-slot:item.workTime="{ item }">
                        <span>
                            {{ getWorkDuration(item.realDate, item.disableCorrection).formattedTime }}
                        </span>
                    </template>
                    <template v-slot:item.pause="{ item }">
                        <v-chip
                            :color="!checkToShortPause(item) ? 'red' : 'grey'"
                            @click="
                                () => {
                                    if (item.correction !== '-') return hasCorrectionsOpenAlert()

                                    if (!checkToShortPause(item)) fixTimeDialogOptionPicker = true
                                    fixTimeDialogItem = JSON.parse(JSON.stringify(item))
                                }
                            "
                        >
                            {{ getPauseDuration(item.realDate, item.disableCorrection).formattedTime }}
                        </v-chip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="tableHeaders.length">
                            <v-data-table
                                hide-default-footer
                                :items-per-page="40"
                                no-data-text="Keine Anwesenheits Einträge gefunden"
                                :headers="innerTableHeaders"
                                :expanded.sync="expanded"
                                :items="tableItemsForItem(item.realDate, item.disableCorrection)"
                            >
                                <template v-slot:item.type="{ item }">
                                    <v-chip :color="mappedColors[item.type]">
                                        {{
                                            item.absenceReason
                                                ? mappedEntryTypes[item.absenceReason]
                                                : mappedEntryTypes[item.type]
                                        }}
                                    </v-chip>
                                </template>

                                <template v-slot:item.correction="{ item }">
                                    <div v-if="timeEntryView.canSelfCheckin">
                                        <v-btn icon @click="changeCanSelfCheckinNoAdmin(item)">
                                            <v-icon color="blue">mdi-pen</v-icon>
                                        </v-btn>
                                    </div>
                                    <div v-else>
                                        <v-btn icon v-if="item.type === 'sick'" @click="deleteSickEntry(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-chip
                                            hover
                                            @click="
                                                item.correction !== 'Versteckt' && showEmployeeCorretionDialog(item)
                                            "
                                            v-if="item.correction !== '-'"
                                            :color="
                                                item.correction === 'Bearbeitet'
                                                    ? 'blue'
                                                    : item.correction === 'Neu'
                                                    ? 'success'
                                                    : item.correction === 'Versteckt'
                                                    ? 'grey'
                                                    : item.correction === 'Akzeptiert'
                                                    ? 'success'
                                                    : 'error'
                                            "
                                        >
                                            <span>{{ item.correction }}</span>
                                        </v-chip>
                                    </div>
                                </template>
                            </v-data-table>
                            <v-textarea
                                disabled
                                color="white"
                                dark
                                v-if="
                                    timeEntryView.timeTrackerDailyNotes &&
                                    timeEntryView.timeTrackerDailyNotes.find(
                                        (noteEntry) => noteEntry.day === item.realDate
                                    )
                                "
                                :value="
                                    timeEntryView.timeTrackerDailyNotes.find(
                                        (noteEntry) => noteEntry.day === item.realDate
                                    ).note
                                "
                            ></v-textarea>
                        </td>
                    </template>
                    <template v-slot:item.correction="{ item }">
                        <div v-if="timeEntryView.canSelfCheckin">
                            <v-btn icon @click="changeCanSelfCheckinNoAdmin(item, true)">
                                <v-icon color="primary">mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-chip
                            hover
                            @click="
                                item.disableCorrection = !item.disableCorrection
                                updateIt()
                            "
                            v-if="item.correction !== '-'"
                            :color="item.disableCorrection ? 'grey' : 'orange'"
                        >
                            <span>{{ item.disableCorrection ? 'versteckt' : item.correction }}</span>
                        </v-chip>
                    </template>
                </v-data-table>
                <v-card-title>Monats Informationen</v-card-title>
                <v-row v-if="timeEntryView.overTime">
                    <v-col cols="1"></v-col>
                    <v-col cols="auto">Überstunden (Stand letzte abgeschlossene Zeiterfassung)</v-col>
                    <v-col cols="auto">{{ timeEntryView.overTime }} Stunden</v-col>
                </v-row>
                <v-row v-if="timeEntryView.percentage">
                    <v-col cols="1"></v-col>
                    <v-col cols="auto">Sollstunden diesen Monat</v-col>

                    <v-col cols="auto">
                        {{
                            Math.round(
                                (calcBusinessDays - vacationDaysForMonth(studio).length) *
                                    ((8.4 * timeEntryView.percentage) / 100) *
                                    1000
                            ) /
                                1000 +
                            ' Stunden (' +
                            timeEntryView.percentage +
                            '%)'
                        }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="auto">Arbeitstage diesen Monat</v-col>

                    <v-col cols="auto">
                        {{ calcBusinessDays - vacationDaysForMonth(studio).length }}
                        <span class="ml-2" v-if="vacationDaysForMonth(studio).length">
                            ( {{ calcBusinessDays }} - {{ vacationDaysForMonth(studio).length }} Feiertag/e )
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="auto">Geleistete Stunden</v-col>

                    <v-col cols="auto">{{ getTotalDuration.formattedTime }}</v-col>
                </v-row>
                <v-row v-if="timeEntryView.percentage">
                    <v-col cols="1"></v-col>
                    <v-col cols="auto">Differenz (in Stunden)</v-col>
                    <v-col cols="auto">
                        {{
                            Math.round(
                                (getTotalDuration.formattedTime -
                                    (calcBusinessDays - vacationDaysForMonth(studio).length) *
                                        ((8.4 * timeEntryView.percentage) / 100)) *
                                    1000
                            ) / 1000
                        }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            @blur="updateNote()"
                            v-if="
                                (!submissionState && !office) ||
                                (submissionState && submissionState.state === 'onlyNotes')
                            "
                            label="Notiz"
                            rows="2"
                            auto-grow
                            v-model="timeTrackerMonth.note"
                        ></v-textarea>
                        <v-textarea
                            v-if="(submissionState && submissionState.state === 'submitted') || office"
                            label="Notiz"
                            rows="2"
                            auto-grow
                            @blur="updateNote()"
                            :disabled="!office"
                            v-model="submissionState.note"
                        ></v-textarea>
                        <v-textarea
                            v-if="submissionState && submissionState.state === 'rejected' && !office"
                            label="Notiz"
                            rows="2"
                            auto-grow
                            @blur="updateNote()"
                            v-model="submissionState.note"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-if="submissionState"
                            :label="submissionState ? 'Status' : ''"
                            :value="
                                submissionState && submissionState.state === 'submitted'
                                    ? 'eingereicht'
                                    : submissionState.state === 'rejected'
                                    ? 'abgelehnt'
                                    : submissionState.state === 'accepted'
                                    ? 'abgeschlossen'
                                    : 'Notiz eingetragen (noch nicht eingereicht)'
                            "
                            placeholder="(Status) Noch nicht eingereicht"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="justify-content-Center">
                        <v-btn
                            v-if="
                                (submissionState && submissionState.state === 'rejected' && !office) ||
                                (!submissionState && !office) ||
                                (submissionState && submissionState.state === 'onlyNotes')
                            "
                            @click="submitTimeTrackerMonth()"
                            color="success"
                        >
                            Einreichen
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="justify-content-Center">
                        <v-btn
                            class="mx-3"
                            v-if="submissionState && submissionState.state === 'submitted' && office"
                            @click="submitTimeTrackerMonth('rejected')"
                            color="error"
                        >
                            Ablehnen
                        </v-btn>
                        <v-btn
                            v-if="submissionState && submissionState.state === 'submitted' && office"
                            @click="submitTimeTrackerMonth('accepted')"
                            color="success"
                        >
                            Akzeptieren
                        </v-btn>
                    </v-col>
                </v-row>
                <v-dialog v-model="showCorrectionDialog">
                    <v-card v-if="correctedItem">
                        <v-card-title style="place-content: center">
                            Korrektur für den {{ $moment(correctedItem.realDate).format('DD.MM.YYYY') }}
                        </v-card-title>
                        <v-row no-gutters>
                            <v-col
                                cols="6"
                                v-if="correctedItem.beforeCorrectionTime || correctedItem.beforeCorrectedTime"
                                style="border-right: 3px solid grey"
                                class="flex-row justify-content-center"
                            >
                                <v-card-title
                                    class="error--text justify-text-center font-weight-bold"
                                    style="place-content: center"
                                >
                                    Vor Korrektur
                                </v-card-title>
                                <v-card-title style="place-content: center">
                                    {{
                                        correctedItem.beforeCorrectionTime || correctedItem.beforeCorrectedTime + ' Uhr'
                                    }}
                                </v-card-title>
                                <v-card-title style="place-content: center">
                                    {{ correctedItem.beforeCorrectionType || correctedItem.beforeCorrectedType }}
                                </v-card-title>
                                <v-card-actions
                                    v-if="
                                        correctedItem.type === 'checkoutCorrectionOpen' ||
                                        correctedItem.type === 'checkinCorrectionOpen'
                                    "
                                    class="justify-content-center"
                                >
                                    <v-btn @click="rejectCorrection(correctedItem)" color="error">Ablehnen</v-btn>
                                </v-card-actions>
                            </v-col>
                            <v-col
                                :cols="
                                    correctedItem.beforeCorrectionTime || correctedItem.beforeCorrectedTime ? '6' : '12'
                                "
                                class="flex-row justify-content-center"
                            >
                                <v-card-title
                                    :class="{ 'error--text': correctedItem.delete === true }"
                                    class="primary--text justify-text-center font-weight-bold"
                                    style="place-content: center"
                                >
                                    {{
                                        correctedItem.beforeCorrectionTime
                                            ? 'Nachher'
                                            : correctedItem.beforeCorrectedTime
                                            ? 'Aktuell'
                                            : correctedItem.correction === 'Abgelehnt'
                                            ? 'Abgelehnter Antrag'
                                            : correctedItem.delete
                                            ? 'Gelöschter Eintrag'
                                            : 'Neuer Eintrag'
                                    }}
                                </v-card-title>
                                <v-card-title style="place-content: center">
                                    {{ correctedItem.time + ' Uhr' }}
                                </v-card-title>
                                <v-card-title style="place-content: center">
                                    {{ correctedItem.type.includes('checkout') ? 'checkout' : 'checkin' }}
                                </v-card-title>
                                <v-card-actions
                                    v-if="
                                        correctedItem.beforeCorrectionTime ||
                                        correctedItem.type === 'checkoutCorrectionOpen' ||
                                        correctedItem.type === 'checkinCorrectionOpen' ||
                                        correctedItem.delete
                                    "
                                    class="justify-content-center"
                                >
                                    <v-btn
                                        v-if="!correctedItem.beforeCorrectionTime && !correctedItem.beforeCorrectedTime"
                                        @click="rejectCorrection(correctedItem)"
                                        color="error"
                                    >
                                        Ablehnen
                                    </v-btn>

                                    <v-btn @click="acceptCorrection(correctedItem)" color="primary">Akzeptieren</v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card v-else>Fehler beim anzeigen bitte neu laden.</v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <office-time-tracker-entry-infos
                    :employeeId="timeEntryView.employee._id"
                    @fetchEntries="timeTrackerInfosUpdated"
                    :entries="editTimeEntry"
                ></office-time-tracker-entry-infos>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="editTimeEntry = null">Zurück</v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="changeCanSelfCheckinNoAdminDialog" persistent max-width="800px">
            <v-card v-if="changeCanSelfCheckinNoAdminDialog">
                <v-card-title>Eintrag anpassen</v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-time-picker full-width v-model="selfCheckinEntryEdit.time" format="24hr"></v-time-picker>
                    </v-row>
                    <v-row no-gutters>
                        <v-select
                            v-model="selfCheckinEntryEdit.type"
                            :items="[
                                { text: 'Check-In', value: 'checkin' },
                                { text: 'Check-Out', value: 'checkout' },
                                { text: 'Pause', value: 'pause' },
                                { text: 'Pause Fertig', value: 'pauseend' },
                            ]"
                            label="Typ"
                        ></v-select>
                    </v-row>
                    <v-row class="justify-content-center">
                        <v-btn color="primary" @click="saveSelfCheckinEntryEdit()">Zeit speichern</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fixTimeDialogOptionPicker" max-width="800px">
            <v-card dark>
                <v-card-title>Wie willst du die Pause anpassen?</v-card-title>
                <v-row class="justify-space-around">
                    <v-col cols="6">
                        <v-card class="d-flex flex-column px-3">
                            <v-card-subtitle class="font-weight-bold px-0">Pause verlängern</v-card-subtitle>
                            <span>Die Pause wird verlängert und von der Arbeitszeit abgezogen</span>
                            <v-card-actions class="justify-content-center">
                                <v-btn
                                    x-large
                                    color="primary"
                                    @click="fixToShortTime(fixTimeDialogItem, 'extendPause')"
                                    icon
                                >
                                    <v-icon>mdi-check-circle</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card class="d-flex flex-column px-3">
                            <v-card-subtitle class="font-weight-bold px-0">
                                Pause verlängern & Arbeitszeit anpassen
                            </v-card-subtitle>
                            <span>Die Pause wird verlängert die Arbeitszeit bleibt die gleiche.</span>
                            <v-card-actions class="justify-content-center">
                                <v-btn
                                    x-large
                                    color="primary"
                                    @click="fixToShortTime(fixTimeDialogItem, 'extendWorktime')"
                                    icon
                                >
                                    <v-icon>mdi-check-circle</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fixTimeDialog" max-width="800px">
            <v-card dark v-if="preSavedAttendances">
                <v-card-title>Soll die Pausenzeit automatisch angepasst werden?</v-card-title>
                <v-row>
                    <v-col cols="5">
                        <div v-for="(att, key) in preSavedAttendances.old" class="pa-2" :key="'att' + key">
                            <v-chip :color="mappedColors[att.type]">
                                {{ mappedEntryTypes[att.type] }}
                            </v-chip>
                            {{ att.time }}
                        </div>
                    </v-col>
                    <v-col cols="2" style="align-self: center">
                        <v-icon class="my-auto" x-large color="primary">mdi-arrow-right-thin</v-icon>
                    </v-col>
                    <v-col cols="5">
                        <div v-for="(att, key) in preSavedAttendances.new" class="pa-2" :key="'att' + key">
                            <v-chip :color="mappedColors[att.type]">
                                {{ mappedEntryTypes[att.type] }}
                            </v-chip>
                            {{ att.time }}
                        </div>
                    </v-col>
                </v-row>
                <v-card-actions class="justify-content-center">
                    <v-btn color="primary" @click="savePreSavedAttendances">Akzeptieren</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import officeTimeTrackerEntryInfos from './OfficeTimeTrackerEntryInfos.vue'
import api from '@/api'
import { mapState } from 'vuex'
export default {
    name: 'OfficeTimeTrackerView',
    props: {
        office: {
            default: false,
            type: Boolean,
        },
        month: {
            required: true,
        },
        timeEntryView: {
            type: Object,
            required: true,
        },
        studio: {
            required: true,
        },
    },
    components: { officeTimeTrackerEntryInfos },
    data() {
        return {
            fixTimeDialogItem: null,
            fixTimeDialogOptionPicker: false,
            fixTimeDialog: false,
            reloadAfterChanges: true,
            timeTrackerMonth: {
                note: '',
            },
            correctedItem: null,
            showCorrectionDialog: false,
            mapHolidayStatus: {
                open: 'Offen',
                accepted: 'Akzeptiert',
                denied: 'Abgelehnt',
            },
            mappedEntryTypes: {
                technicCheckin: 'Checkin Badge',
                technicCheckout: 'Checkout Badge',
                sick: 'Krank',
                school: 'Schule',
                pregnancy: 'Schwangerschaft',
                other: 'Anderes',
                military: 'Militär',
                holiday: 'Ferien',
                holidayFix: 'FERIEN OHNE PENSUM ABZUG',
                checkin: 'Check-In',
                checkout: 'Check-Out',
                pause: 'Pause',
                pauseend: 'Pause fertig',
                error: 'Fehler (Checkin ohne Checkout)',
                error1: 'Fehler (2 Check-Ins hintereinander)',
                error2: 'Fehler (2 Check-Outs hintereinander)',
                error3: 'Fehler (Checkout ohne Checkin)',
                deleteCorrectionOpen: 'Löschen (Korrektur offen)',
                checkinCorrectionOpen: 'Check-In (Korrektur offen)',
                checkinCorrectionDenied: 'Check-In (Korrektur abgelehnt)',
                checkinCorrectionApproved: 'Check-In (Korrektur genehmigt)',
                checkoutCorrectionOpen: 'Check-Out (Korrektur offen)',
                checkoutCorrectionDenied: 'Check-Out (Korrektur abgelehnt)',
                checkoutCorrectionApproved: 'Check-Out (Korrektur genehmigt)',
            },
            keyey: 0,
            mappedColors: {
                technicCheckin: 'green',
                technicCheckout: 'red',
                sick: 'black',
                holiday: 'black',
                checkin: 'grey',
                checkout: 'grey',
                pause: 'grey',
                pauseend: 'grey',
                error: 'red',
                error1: 'red',
                error2: 'red',
                error3: 'red',
                deleteCorrectionOpen: 'red',
                checkinCorrectionOpen: 'orange',
                checkinCorrectionDenied: 'red',
                checkinCorrectionApproved: 'success',
                checkoutCorrectionOpen: 'orange',
                checkoutCorrectionDenied: 'red',
                checkoutCorrectionApproved: 'success',
            },
            tableHeaders: [
                { text: 'Datum', value: 'date' },
                { text: 'Startzeit', value: 'startTime' },
                { text: 'Endzeit', value: 'endTime' },
                { text: 'Abwesend', value: 'absence' },
                { text: 'Dauer', value: 'duration' },
                { text: 'Pause', value: 'pause' },
                { text: 'Gearbeitet', value: 'workTime' },
                { text: 'Korrektur', value: 'correction' },
                { text: '', value: 'data-table-expand' },
            ],
            innerTableHeaders: [
                { text: 'Uhrzeit', value: 'time', sortable: false },
                { text: 'Typ', value: 'type', sortable: false },
                { text: 'Studio', value: 'studio', sortable: false },
                { text: 'Dauer (Abwesenheit)', value: 'duration', sortable: false },
                { text: 'Korrektur', value: 'correction', sortable: false },
                // { text: 'Aktion', value: 'actions', sortable: false },
            ],
            expanded: [],
            editTimeEntry: null,
            preSavedAttendances: null,
            selfCheckinEntryEdit: null,
            changeCanSelfCheckinNoAdminDialog: false,
        }
    },
    created() {},
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        submissionState() {
            if (!this.timeEntryView.timeTrackerMonths) return null
            if (
                this.timeEntryView.timeTrackerMonths &&
                this.timeEntryView.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.month).format('YYYY-MM')
                )
            ) {
                this.timeTrackerMonth.note = this.timeEntryView.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.month).format('YYYY-MM')
                ).note

                return this.timeEntryView.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.month).format('YYYY-MM')
                )
            }
        },
        calcBusinessDays() {
            var day = this.$moment(this.month).startOf('month')
            var businessDays = 0

            while (day.isSameOrBefore(this.$moment(this.month).endOf('month'), 'day')) {
                if (day.day() != 0 && day.day() != 6) businessDays++
                day.add(1, 'd')
            }
            return businessDays
        },
        tableItems() {
            let items = []
            let includesCorrection = false
            if (!this.timeEntryView.attendance) return items
            for (const date of Object.keys(this.timeEntryView.attendance).filter(
                (key) => key !== 'email' && key !== '_id'
            )) {
                includesCorrection = this.timeEntryView.attendance[date].find(
                    (entry) => entry.correction && entry.correction === 'open'
                )
                items.push({
                    realDate: date,
                    date: this.$moment(date).format('DD.MM.'),
                    startTime: '',
                    endTime: '',
                    absence: this.getAbsenceDuration(this.timeEntryView.attendance[date], date),
                    workTime: '',
                    pause: '',
                    duration: '',
                    correction: includesCorrection ? 'Offen' : '-',
                })
            }
            return items
        },
        getTotalDuration() {
            let totalMinutes = 0
            Object.keys(this.timeEntryView.attendance).forEach((date) => {
                totalMinutes += this.getWorkDuration(date).totalWorkTime
            })

            let hours = totalMinutes / 60 // Stunden berechnen

            let formattedTime = parseFloat(hours.toFixed(2))

            return { formattedTime, totalMinutes }
        },
    },
    methods: {
        async updateNote() {
            await api.submitTimeTrackerMonth({
                _id: this.timeEntryView.user,
                month: this.month,
                note: this.timeTrackerMonth.note,
                state: 'onlyNotes',
            })
            this.$toast.success('Notiz wurde gespeichert')
        },
        async saveSelfCheckinEntryEdit() {
            let updated = await api.saveSelfCheckinEntryEditTODB({
                item: this.selfCheckinEntryEdit,
                user: this.timeEntryView,
            })
            this.selfCheckinEntryEdit = null
            this.changeCanSelfCheckinNoAdminDialog = false
            this.$toast.success('Änderung wurde gespeichert')
        },
        changeCanSelfCheckinNoAdmin(item, newEntry = false) {
            if (newEntry) {
                console.log(item)
                this.selfCheckinEntryEdit = {
                    type: '',
                    time: '',
                    realDate: item.realDate,
                }
            } else {
                this.selfCheckinEntryEdit = item
                this.selfCheckinEntryEdit.preservedTime = JSON.parse(JSON.stringify(item.time))
            }
            this.changeCanSelfCheckinNoAdminDialog = true
        },
        hasCorrectionsOpenAlert() {
            alert('Es sind noch Korrekturen offen')
        },
        async fixToShortTime(item, option) {
            let attendances = this.timeEntryView.attendance[item.realDate]
                .filter((att) => !att.type.includes('error'))
                .map((x) => {
                    return {
                        time: x.time,
                        oldType: x.oldType,
                        type: x.type,
                        autoFixedTime: x.time,
                        studio: x.studio,
                    }
                })
            let totalWorkTime = 0
            let totalBreakTime = 0
            let breakPairs = []

            for (let i = 0; i < attendances.length - 1; ) {
                if (attendances[i].oldType === 'checkin' && attendances[i + 1].oldType === 'checkout') {
                    let checkinTime = this.$moment(attendances[i].time, 'HH:mm:ss')
                    let checkoutTime = this.$moment(attendances[i + 1].time, 'HH:mm:ss')

                    totalWorkTime += checkoutTime.diff(checkinTime, 'minutes')
                    i += 2

                    if (i < attendances.length - 1 && attendances[i].oldType === 'checkin') {
                        let nextCheckinTime = this.$moment(attendances[i].time, 'HH:mm:ss')
                        let breakTime = nextCheckinTime.diff(checkoutTime, 'minutes')
                        totalBreakTime += breakTime
                        breakPairs.push({ start: checkoutTime, end: nextCheckinTime, duration: breakTime })
                    }
                } else {
                    i++
                }
            }

            let requiredBreak = this.getRequiredBreak(totalWorkTime)
            console.log(requiredBreak, 'requiredBreak')
            if (attendances.length == 2) {
                let checkinTime = this.$moment(attendances[0].time, 'HH:mm:ss')
                let checkoutTime = this.$moment(attendances[1].time, 'HH:mm:ss')
                let subtractTime = requiredBreak / 2
                let middleTime = this.$moment(JSON.parse(JSON.stringify(checkoutTime)))
                checkoutTime.add(requiredBreak / 2, 'minutes')
                attendances[1].time = checkoutTime.format('HH:mm:ss')
                attendances.splice(
                    1,
                    0,
                    {
                        time: this.$moment(JSON.parse(JSON.stringify(middleTime)))
                            .subtract(requiredBreak / 2, 'minutes')
                            .format('HH:mm:ss'),
                        type: 'pause',
                        oldType: 'checkout',
                    },
                    {
                        time: middleTime.format('HH:mm:ss'),
                        type: 'pauseend',
                        oldType: 'checkin',
                    }
                )

                breakPairs.push({
                    start: this.$moment(JSON.parse(JSON.stringify(middleTime))).subtract(requiredBreak / 2, 'minutes'),
                    end: this.$moment(JSON.parse(JSON.stringify(middleTime))),
                    duration: requiredBreak / 2,
                })
                totalBreakTime += requiredBreak / 2
            }

            if (totalBreakTime < requiredBreak) {
                let diff = requiredBreak - totalBreakTime

                if (option === 'extendPause') {
                    if (breakPairs.length > 0) {
                        let lastBreak = breakPairs[breakPairs.length - 1]
                        if (lastBreak) {
                            lastBreak.end.add(diff, 'minutes')
                            lastBreak.duration += diff
                        }
                    }
                } else if (option === 'extendWorktime') {
                    if (breakPairs.length > 0) {
                        let lastBreak = breakPairs[breakPairs.length - 1]
                        if (lastBreak) {
                            lastBreak.end.add(diff, 'minutes')
                            lastBreak.duration += diff
                        }

                        let lastCheckout = attendances.find(
                            (att) =>
                                att.oldType === 'checkout' && this.$moment(att.time, 'HH:mm:ss').isAfter(lastBreak.end)
                        )
                        let lastCheckoutIndex = attendances.findIndex(
                            (att) =>
                                att.oldType === 'checkout' && this.$moment(att.time, 'HH:mm:ss').isAfter(lastBreak.end)
                        )

                        if (lastCheckout) {
                            let lastCheckoutTime = this.$moment(lastCheckout.time, 'HH:mm:ss')
                            lastCheckoutTime.add(diff, 'minutes')
                            attendances[lastCheckoutIndex].time = lastCheckoutTime.format('HH:mm:ss')
                        } else {
                            let lastCheckout = attendances[attendances.length - 1]
                            if (lastCheckout.oldType === 'checkout') {
                                let lastCheckoutTime = this.$moment(lastCheckout.time, 'HH:mm:ss')
                                lastCheckoutTime.add(diff, 'minutes')
                                lastCheckout.time = lastCheckoutTime.format('HH:mm:ss')
                            }
                        }
                    }
                }
                for (let i = 0; i < attendances.length - 1; i++) {
                    if (attendances[i].oldType === 'checkout' && attendances[i + 1].oldType === 'checkin') {
                        let breakPair = breakPairs.shift()
                        if (breakPair) {
                            attendances[i].time = breakPair.start.format('HH:mm:ss')
                            attendances[i + 1].time = breakPair.end.format('HH:mm:ss')
                        }
                    }
                }
            }
            this.preSavedAttendances = {
                new: attendances,
                old: this.timeEntryView.attendance[item.realDate],
                date: item.realDate,
            }
            this.fixTimeDialog = true
        },
        getRequiredBreak(workDuration) {
            if (workDuration <= 330) {
                return 0
            } else if (workDuration < 420) {
                return 15
            } else if (workDuration < 540) {
                return 30
            } else {
                return 60
            }
        },
        async savePreSavedAttendances() {
            this.timeEntryView.attendance[this.preSavedAttendances.date] = this.preSavedAttendances.new
            await api.savePreSavedAttendancesToDB(this.preSavedAttendances, this.timeEntryView.user)

            this.fixTimeDialog = false
            this.preSavedAttendances = null
            this.fixTimeDialogOptionPicker = false
        },
        async deleteSickEntry(item) {
            if (confirm('Willst du den Eintrag wirklich löschen?')) {
                await api.deleteSickEntry(item)
                if (this.reloadAfterChanges) {
                    this.$emit('fetchEntries')
                } else {
                    this.$toast.warning('Neuladen um Anzeigefehler zu vermeiden.')
                }
            }
        },
        async submitTimeTrackerMonth(state = 'submitted') {
            let validation = true
            let tableItem = null
            let overTime = this.timeEntryView.overTime || 0
            this.tableItems.forEach((item) => {
                if (validation) {
                    validation = this.checkToShortPause(item)
                    if (!validation) tableItem = item
                }
            })
            if (!validation)
                return alert(
                    'Bitte prüfe die Pausenzeit vom ' +
                        this.$moment(tableItem.realDate, 'YYYY-MM-DD').format('DD. MMMM ') +
                        ''
                )
            let text = 'Willst du die Zeiterfassung von  ' + this.timeEntryView.fullName + ' einreichen?'
            if (state === 'accepted') {
                let difference =
                    Math.round(
                        (this.getTotalDuration.formattedTime -
                            (this.calcBusinessDays - this.vacationDaysForMonth(this.studio).length) *
                                ((8.4 * this.timeEntryView.percentage) / 100)) *
                            1000
                    ) / 1000
                text = 'Willst du die Zeiterfassung von ' + this.timeEntryView.fullName + ' abschliessen?'

                if (this.timeEntryView.percentage) text += '\n Stellenprozent: ' + this.timeEntryView.percentage
                text += '\n Geleistete Stunden: ' + this.getTotalDuration.formattedTime
                text += '\n Differenz (in Stunden): ' + difference
                text += '\n Überstunden (aktuell): ' + overTime
                text += '\n Überstunden (neu): ' + Math.round((overTime + difference) * 100) / 100
                this.submissionState.overTimeBefore = overTime
                this.submissionState.overTimeAfter = Math.round((overTime + difference) * 100) / 100
                this.submissionState.percentage = this.timeEntryView.percentage
                this.submissionState.durationInHours = this.getTotalDuration.formattedTime
                this.submissionState.difference = difference
            }
            if (confirm(text)) {
                if (this.submissionState) {
                    if (state === 'accepted') {
                        this.submissionState
                    }
                    await api.updateTimeTrackerMonth({
                        _id: this.timeEntryView.user,
                        submissionState: this.submissionState,
                        state,
                    })
                } else {
                    await api.submitTimeTrackerMonth({
                        _id: this.timeEntryView.user,
                        month: this.month,
                        note: this.timeTrackerMonth.note,
                    })
                }
                if (this.reloadAfterChanges) {
                    this.$emit('fetchEntries')
                } else {
                    this.$toast.warning('Neuladen um Anzeigefehler zu vermeiden.')
                }
            }
        },
        getAbsenceDuration(attendanceItem) {
            let sickDuration = this.getSickDuration(attendanceItem).minutes
            let holidayDuration = this.getHolidayDuration(attendanceItem).minutes
            return this.$moment.utc((sickDuration + holidayDuration) * 60 * 1000).format('HH[H] mm[M]')
        },
        vacationDaysForMonth(studio) {
            if (studio._id) studio = studio._id
            let fullStudio = this.storeStudios.find((std) => std._id === studio)
            if (!fullStudio || !fullStudio.vacationDays) return []
            return fullStudio.vacationDays.filter(
                (day) => this.$moment(this.month).format('YYYY-MM') === this.$moment(day.date).format('YYYY-MM')
            )
        },
        async acceptCorrection(correctedItem) {
            correctedItem.user = this.timeEntryView.user
            let correction = await api.acceptAttendanceCorrection(correctedItem)
            if (this.reloadAfterChanges) {
                this.$emit('fetchEntries')
            } else {
                this.$toast.warning('Neuladen um Anzeigefehler zu vermeiden.')
            }
        },
        async rejectCorrection(correctedItem) {
            correctedItem.user = this.timeEntryView.user
            let correction = await api.rejectAttendanceCorrection(correctedItem)
            if (this.reloadAfterChanges) {
                this.$emit('fetchEntries')
            } else {
                this.$toast.warning('Neuladen um Anzeigefehler zu vermeiden.')
            }
        },
        checkToShortPause(item) {
            let workedMinutes = this.getWorkDuration(item.realDate, item.disableCorrection, true).minutes
            let pauseDuration = this.getPauseDuration(item.realDate, item.disableCorrection).minutes
            if (workedMinutes > 330 && workedMinutes < 470) {
                return pauseDuration >= 15
            } else if (workedMinutes >= 470 && workedMinutes < 540) {
                return pauseDuration >= 30
            } else if (workedMinutes >= 540) {
                return pauseDuration >= 60
            } else {
                return true
            }
        },
        updateIt() {
            this.keyey++
        },
        showEmployeeCorretionDialog(item) {
            this.showCorrectionDialog = true
            this.correctedItem = item
        },
        tableItemsForItem(realDate, disableCorrection = false) {
            return this.timeEntryView.attendance[realDate].map((item) => {
                let correctionReason = '-'
                if (item.correction) {
                    if (item.beforeCorrectionTime) {
                        correctionReason = 'Bearbeitet'
                    } else if (item.delete) {
                        correctionReason = 'Gelöscht'
                    } else {
                        correctionReason = 'Neu'
                    }
                    if (item.deleted) {
                        correctionReason = 'Gelöscht'
                    }
                    if (disableCorrection) {
                        correctionReason = 'Versteckt'
                    }

                    if (item.correction === 'approved') {
                        correctionReason = 'Akzeptiert'
                    }

                    if (item.correction === 'rejected') {
                        correctionReason = 'Abgelehnt'
                    }
                }
                return {
                    absenceReason: item.absenceReason,
                    sickEntryID: item.sickEntryID,
                    date: realDate,
                    correctedTo: item.correctedTo,
                    beforeCorrectionTime: item.beforeCorrectionTime,
                    beforeCorrectedTime: item.beforeCorrectedTime,
                    beforeCorrectedType: item.beforeCorrectedType,
                    beforeCorrectionType: item.beforeCorrectionType,
                    delete: item.delete,
                    time:
                        item.time === '23:59:59'
                            ? '-'
                            : disableCorrection && item.correction && item.correction === 'open'
                            ? item.beforeCorrectionTime || '-'
                            : item.time,
                    type: disableCorrection
                        ? item.beforeCorrectionType || item.correctedType || item.type
                        : item.correctedType || item.type,
                    studio:
                        item.studio && this.storeStudios.find((studio) => studio._id === item.studio)
                            ? this.storeStudios.find((studio) => studio._id === item.studio).name
                            : '-',
                    status: item.status ? this.mapHolidayStatus[item.status] : '-',
                    correction: correctionReason,
                    duration: item.hours ? item.hours + ' Stunden' : '-',
                }
            })
        },
        /*  async deleteSickEntry(id) {
            if (confirm('Krankheitseintrag wirklich löschen?')) {
                try {
                    let status = await api.deleteSickEntryManagement(id)
                    this.$emit('fetchEntries')
                    this.$toast.success('Krankheitseintrag gelöscht')
                } catch (e) {
                    this.$toast.error('Es gab einen Fehler beim löschen des Krankheitseintrags')
                }
            }
        }, */
        async changeHolidayStatus(holidayEntry, status) {
            if (status) {
                try {
                    let status = await api.changeHolidayStatus({ _id: holidayEntry._id, status: 'accepted' })
                    this.$toast.success('Status aktualisiert')
                    holidayEntry.status = 'accepted'
                } catch (e) {
                    this.$toast.error('Es gab einen Fehler beim aktualisieren des Status')
                }
            } else {
                try {
                    let status = await api.changeHolidayStatus({ _id: holidayEntry._id, status: 'denied' })
                    this.$toast.success('Status aktualisiert')
                    holidayEntry.status = 'denied'
                } catch (e) {
                    this.$toast.error('Es gab einen Fehler beim aktualisieren des Status')
                }
            }
        },
        shorten(reason) {
            if (reason && reason.length > 5) {
                return reason.slice(0, 5) + '...'
            } else {
                return '-'
            }
        },
        timeTrackerInfosUpdated(data) {
            if (this.reloadAfterChanges) {
                this.$emit('fetchEntries', data)
            } else {
                this.$toast.warning('Neuladen um Anzeigefehler zu vermeiden.')
            }
        },
        getStartTime(date, disableCorrection) {
            let firstWorkEvent = this.timeEntryView.attendance[date].find((e) => e.type === 'checkin')
            if (date === '2023-06-16') console.log(firstWorkEvent, firstWorkEvent.correction === 'open')
            if (firstWorkEvent)
                return disableCorrection && firstWorkEvent.correction && firstWorkEvent.correction === 'open'
                    ? firstWorkEvent.beforeCorrectionTime || '-'
                    : firstWorkEvent.time
            else return '-'
        },
        getEndTime(date, disableCorrection) {
            let lastWorkEvent = this.timeEntryView.attendance[date]
                .slice()
                .reverse()
                .find((e) => e.type === 'checkout')
            if (lastWorkEvent)
                return disableCorrection && lastWorkEvent.correction && lastWorkEvent.correction === 'open'
                    ? lastWorkEvent.beforeCorrectionTime || '-'
                    : lastWorkEvent.time
            else return '-'
        },
        getHolidayDuration(timeEntries) {
            if (!timeEntries.length)
                return {
                    formattedTime: '-',
                    minutes: 0,
                }
            return timeEntries.find((e) => e.type === 'holiday')
                ? {
                      formattedTime: this.$moment
                          .utc(timeEntries.find((e) => e.type === 'holiday').hours * 60 * 60 * 1000)
                          .format('HH[H] mm[M]'),
                      minutes: timeEntries.find((e) => e.type === 'holiday').hours * 60,
                  }
                : {
                      formattedTime: '-',
                      minutes: 0,
                  }
        },
        getSickDuration(timeEntries) {
            if (!timeEntries.length)
                return {
                    formattedTime: '-',
                    minutes: 0,
                }
            let filteredEntries = timeEntries.filter((e) => e.type === 'sick')
            if (!filteredEntries.length)
                return {
                    formattedTime: '-',
                    minutes: 0,
                }

            let countMinutes = 0

            timeEntries.forEach((entry) => {
                if (entry.hours) {
                    countMinutes += entry.hours * 60
                }
            })

            return {
                formattedTime: this.$moment.utc(countMinutes * 60 * 1000).format('HH[H] mm[M]'),
                minutes: countMinutes,
            }
        },
        getPauseDuration(date, disableCorrection) {
            let timeEntries = this.timeEntryView.attendance[date]

            let totalPauseTime = 0
            let inPause = false
            let lastPauseStart = null
            let startetPauseFirstTime = false

            timeEntries.forEach((entry, index) => {
                if (entry.type === 'pause') entry.oldType = 'checkout'
                if (entry.type === 'pauseend') entry.oldType = 'checkin'

                if (
                    disableCorrection &&
                    entry.correction &&
                    entry.correction === 'open' &&
                    !entry.beforeCorrectionTime
                ) {
                    return
                }

                if (entry.type.includes('error') || (entry.correction === 'rejected' && entry.correction.newEntry)) {
                    return
                }
                if (entry.oldType === 'checkout' && !inPause) {
                    startetPauseFirstTime = true
                    inPause = true
                    lastPauseStart = entry.time
                } else if (inPause && entry.oldType === 'checkin' && startetPauseFirstTime) {
                    inPause = false
                    totalPauseTime += this.$moment(entry.time, 'HH:mm:ss').diff(
                        this.$moment(lastPauseStart, 'HH:mm:ss'),
                        'minutes'
                    )
                }
            })
            let hours = Math.floor(totalPauseTime / 60) // Stunden berechnen
            let minutes = totalPauseTime % 60 // Minuten berechnen
            let formattedTime =
                hours !== 0 || minutes !== 0
                    ? hours.toString().padStart(2, '0') + 'H ' + minutes.toString().padStart(2, '0') + 'M'
                    : '-'
            return { formattedTime, totalPauseTime, minutes: minutes + hours * 60 }
        },

        getWorkDuration(date, disableCorrection, ignoreSickDuration = false) {
            let timeEntries = this.timeEntryView.attendance[date]
            let inWork = false
            let lastWorkStart = null
            let totalWorkTime = 0
            if (!ignoreSickDuration) {
                totalWorkTime = this.getSickDuration(timeEntries).minutes || 0
                totalWorkTime += this.getHolidayDuration(timeEntries).minutes || 0
            }
            timeEntries.forEach((entry, index) => {
                if (disableCorrection && entry.correction && entry.correction === 'open' && !entry.beforeCorrectionTime)
                    return
                if (entry.type === 'checkout' || entry.type === 'pause') {
                    if (inWork) {
                        inWork = false
                        totalWorkTime += this.$moment(
                            date +
                                ' ' +
                                (disableCorrection && entry.correction && entry.correction === 'open'
                                    ? entry.beforeCorrectionTime || ''
                                    : entry.time)
                        ).diff(lastWorkStart, 'minutes')
                    } else if (index === 0) {
                        // assume a pause starting at 00:00 for first entry of type "pauseend"
                        inWork = true
                        lastWorkStart = this.$moment(date).startOf('day')
                        totalWorkTime += this.$moment(
                            date +
                                ' ' +
                                (disableCorrection && entry.correction && entry.correction !== 'appproved'
                                    ? entry.beforeCorrectionTime || ''
                                    : entry.time)
                        ).diff(lastWorkStart, 'minutes')
                    }
                } else if (entry.type === 'checkin' || entry.type === 'pauseend') {
                    if (!inWork) {
                        inWork = true
                        lastWorkStart = this.$moment(
                            date +
                                ' ' +
                                (disableCorrection && entry.correction && entry.correction === 'open'
                                    ? entry.beforeCorrectionTime || ''
                                    : entry.time)
                        )
                    }
                }

                if (index === timeEntries.length - 1 && (entry.type === 'checkin' || entry.type === 'pauseend')) {
                    let startOfDay = this.$moment(date).startOf('day')
                    let isToday = startOfDay.isSame(this.$moment().startOf('day'), 'day')

                    if (isToday) {
                        const time = this.$moment()
                        totalWorkTime += time.diff(lastWorkStart, 'minutes')
                    } else {
                        const endOfDay = this.$moment(startOfDay).endOf('day')
                        totalWorkTime += endOfDay.diff(lastWorkStart, 'minutes')
                    }
                }
            })
            let hours = Math.floor(totalWorkTime / 60) // Stunden berechnen
            let minutes = Math.floor(totalWorkTime % 60) // Minuten berechnen

            let formattedTime = hours.toString().padStart(2, '0') + 'H ' + minutes.toString().padStart(2, '0') + 'M'
            return { formattedTime, totalWorkTime, minutes: minutes + hours * 60 }
        },
        getDurationForDay(date, disableCorrection) {
            let timeEntries = this.timeEntryView.attendance[date]
            let totalWorkTime = this.getWorkDuration(date, disableCorrection).totalWorkTime
            let totalPauseTime = this.getPauseDuration(date, disableCorrection).totalPauseTime
            let durationTimeInMinutes = totalWorkTime + totalPauseTime
            let hours = Math.floor(durationTimeInMinutes / 60) // Stunden berechnen
            let minutes = Math.floor(durationTimeInMinutes % 60) // Minuten berechnen
            let formattedTime = hours.toString().padStart(2, '0') + 'H ' + minutes.toString().padStart(2, '0') + 'M'
            return { formattedTime, durationTimeInMinutes }
        },
        getDuration(date, disableCorrection) {
            let timeEntries = this.timeEntryView.attendance[date]
            let totalWorkTime = this.getWorkDuration(date, disableCorrection).totalWorkTime
            let totalPauseTime = this.getPauseDuration(date, disableCorrection).totalPauseTime
            let durationTimeInMinutes = totalWorkTime - totalPauseTime
            let hours = Math.floor(durationTimeInMinutes / 60) // Stunden berechnen
            let minutes = durationTimeInMinutes % 60 // Minuten berechnen

            let formattedTime = hours.toString().padStart(2, '0') + 'H ' + minutes.toString().padStart(2, '0') + 'M'
            return { formattedTime, durationTimeInMinutes }
        },
    },
}
</script>

<style lang="scss" scoped>
</style>