<template>
    <v-container>
        <v-tabs centered dark v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-tabs-items dark v-model="tab">
            <v-tab-item>

                <website-job-offers></website-job-offers>

            </v-tab-item>
            <v-tab-item>
                <v-card class="ma-5" flat>
                    <website-services></website-services>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card class="ma-5" flat>
                    <website-offers></website-offers>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="userService.hasRight('g_websitecontent')">
                <v-card class="ma-5" flat>
                    <v-row>
                        <v-col v-for="studio of studioListSorted" :key="studio._id" cols="12" md="4">
                            <v-card @click="openStudio(studio)" color="primary" class="d-flex justify-center">
                                <v-card-title class="white--text">{{ studio.name }}</v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="userService.hasRight('g_websitecontent')">
                <v-card class="ma-5" flat>
                    <website-popups></website-popups>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <WebsiteContentDialog v-if="showDialog" :selectedStudio="selectedStudio" @close-dialog="showDialog = false" />
    </v-container>
</template>

<script>
import api from "@/api";

import WebsiteJobOffers from './components/WebsiteJobOffers.vue'
import WebsiteContentDialog from "./components/WebsiteContentDialog.vue";
import WebsiteOffers from "./components/WebsiteOffers.vue";
import WebsiteServices from "./components/WebsiteServices.vue";
import WebsitePopups from "./components/WebsitePopups.vue";
import userService from '@/services/userService'

export default {
    components: { WebsiteContentDialog, WebsiteJobOffers, WebsiteOffers, WebsiteServices, WebsitePopups },
    data() {
        return {
            userService,
            studioList: [],
            jobs: [],
            tabItems: userService.hasRight('g_websitecontent') ? ["Jobs", "Leistungen", "Angebote", "Content", "PopUps"] : ["Jobs", "Leistungen", "Angebote"],
            tab: null,
            showDialog: false,
            selectedStudio: { name: "Bearbeiten", code: "", _id: "" },
        };
    },
    async created() {
        this.fetchStudios();
    },
    computed: {
        studioListSorted() {
            return this.studioList.sort((a) => a.name.toLowerCase());
        },
    },
    methods: {
        async fetchStudios() {
            try {
                let studios = await api.fetchStudios();
                studios = studios.filter((e) => e.code !== "alibey" && e.code !== "ot");
                this.studioList = studios;
                this.fetchJobs();
            } catch (e) {
                this.$toast.error("Fehler beim Laden der Studios");
            }
        },

        showDetails(job) {
            this.$refs.newJobOffer.edit(job);
        },
        async fetchJobs() {
            try {
                let jobs = await api.fetchJobs();
                this.jobs = jobs;
            } catch (e) {
                this.$toast.error("Fehler beim Laden der Jobs");
            }
        },
        openStudio(studio) {
            this.selectedStudio = { name: studio.name, code: studio.code, _id: studio._id };
            this.showDialog = true;
        },
    },
};
</script>
