<template xmlns="">
    <div>
        <image-overlapping-card
            v-on:return-clicked="$router.push('/dashboard/gf/event')"
            :changeRoute="false"
            :returnCallback="true"
        >
            <template v-slot:image>
                <slim-cropper v-if="!loading" style="height: 35vh" :options="slimOptions">
                    <img
                        v-if="form.headerImage"
                        :src="'/img/event/' + form.headerImage"
                        style="background-size: contain"
                    />
                    <input type="file" accept="image/*" />
                </slim-cropper>
            </template>
            <template v-slot:content>
                <v-card-subtitle class="text-center">Format: 3:1 (900x300)</v-card-subtitle>
                <v-form lazy-validation ref="eventForm" class="d-flex pa-2 flex-column">
                    <v-text-field
                        filled
                        class="mx-2"
                        v-model="form.title"
                        label="Titel"
                        required
                        :rules="requiredField"
                    ></v-text-field>
                    <v-checkbox filled class="mx-2" v-model="form.public" label="Öffentlich anzeigen"></v-checkbox>
                    <v-text-field
                        filled
                        class="mx-2"
                        v-model="form.mainColor"
                        label="Hauptfarbe"
                        v-mask="mainColorPicker.mask"
                        required
                        :rules="requiredField"
                    >
                        <template v-slot:append>
                            <v-menu
                                v-model="mainColorPicker.menu"
                                top
                                nudge-bottom="105"
                                nudge-left="16"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on }">
                                    <div :style="swatchStyleMain" v-on="on" />
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-color-picker v-model="form.mainColor" flat />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-text-field>
                    <v-text-field
                        filled
                        class="mx-2"
                        v-model="form.secondaryColor"
                        label="Zweitfarbe"
                        v-mask="secondaryColorPicker.mask"
                        required
                        :rules="requiredField"
                    >
                        <template v-slot:append>
                            <v-menu
                                v-model="secondaryColorPicker.menu"
                                top
                                nudge-bottom="105"
                                nudge-left="16"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on }">
                                    <div :style="swatchStyleSecondary" v-on="on" />
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-color-picker v-model="form.secondaryColor" flat />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-text-field>
                    <v-select
                        filled
                        v-model="form.restrictSignups"
                        label="Anmeldungen pro Kunde beschränken"
                        :items="['Nicht beschränken', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                    ></v-select>
                </v-form>
                <!-- <v-btn @click="shareViaWebShare" icon>
        <lottie-player
							mode="normal" width="48px" height="48px" class="event__lottie mr-3" name="placeholderImage" loop autoplay  src="/img/lottiefiles/userSearchIcon.json"></lottie-player>
      </v-btn>
      <span :style="mainColor">Hol einen Freund dazu</span> -->
                <!--		<v-card-text style="height: 40%">
					<swiper class="fill-height" :options="swiperOption" :key="swiperKey">
						<swiper-slide class="fill-height" v-for="course in form.courses" :key="course._id">
							<v-card class="fill-height d-flex flex-column rounded-xl justify-space-between">
								<v-img height="40%" :src="getCourseImage(course.course_bild)"></v-img>
								<div class="fill-height" :style="backgroundMainColor">
									<v-card-title
										class="event__card-course-title justify-content-center pb-0"
										:class="{ 'event__card-course-title-mobile': $vuetify.breakpoint.mobile }"
									>
										{{ course.course_name }}
									</v-card-title>
									<v-card-text
										class="text-center white--text"
										:class="{ 'event__card-course-content-mobile': $vuetify.breakpoint.mobile }"
									>
										{{ timeService.timestampToTime(course.timestamp) }} -
										{{ timeService.timestampToTime(course.timestamp_end) }} Uhr
									</v-card-text>
								</div>
								<v-btn
									class="event__card-course-button white--text"
									@click="removeCourse(course._id)"
									:style="backgroundSecondaryColor"
									text
								>
									Entfernen
								</v-btn>
							</v-card>
						</swiper-slide>
						<swiper-slide class="fill-height">
							<v-card class="fill-height d-flex flex-column rounded-xl justify-space-between">
								<div class="fill-height" :style="backgroundMainColor">
									<v-card-title
										class="event__card-course-title justify-content-center"
										:class="{ 'event__card-course-title-mobile': $vuetify.breakpoint.mobile }"
									>
										Kurs zu Event hinzufügen
									</v-card-title>
									<v-card-text class="justify-content-center">
										<span v-if="!$vuetify.breakpoint.smAndDown" class="white--text"
											>Die Event ID ist die Zeichenfolge in der URL wenn du die Anmeldeseite deines Kurses öffnest.</span
										>
										<v-form ref="courseIdForm" lazy-validation>
											<v-text-field
												class="event__card-course-id pt-3 mt-3"
												label="KURS ID"
												v-model="newCourse.id"
												:rules="requiredCourseId"
												required
											></v-text-field>
										</v-form>
									</v-card-text>
								</div>
								<v-btn
									class="event__card-course-button white--text"
									:style="backgroundSecondaryColor"
									:loading="newCourse.loading"
									text
									@click="addNewCourse"
								>
									Kurs hinzufügen
								</v-btn>
							</v-card>
						</swiper-slide>
					</swiper></v-card-text -->
                <v-row v-if="$route.params && $route.params.id" no-gutters>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn @click="showDeleteDialog = true" color="red" class="white--text">Event löschen</v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    Über die Kursliste kannst du anschliessend Kursausführungen direkt zum Event hinzufügen.
                </v-card-text>
            </template>
        </image-overlapping-card>
        <v-snackbar :color="snackBar.color" v-model="snackBar.status">
            {{ snackBar.message }}
        </v-snackbar>
        <basic-dialog
            @onCancel="showDeleteDialog = false"
            @onSave="deleteEvent"
            dialogTitle="Event löschen"
            dialogSaveButtonText="Löschen"
            :dialogVisible="showDeleteDialog"
        >
            <v-card-text class="mt-0">Möchtest du den Event unwiederruflich löschen?</v-card-text>
        </basic-dialog>
    </div>
</template>

<script>
import imageOverlappingCard from '../../../components/imageOverlappingCard.vue'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import basicDialog from '@/components/tools/basicDialog'
import api from '../../../api'
import timeService from '../../../services/timeService'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
    name: 'EventForm',
    components: { Swiper, SwiperSlide, imageOverlappingCard, 'slim-cropper': Slim, basicDialog },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            showDeleteDialog: false,
            loading: false,
            slimOptions: {
                ratio: '3:1',
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            navigationBarInitiated: false,
            editMode: false,
            swiperKey: 'swiperkey',
            timeService,
            loading: false,
            mainColorPicker: {
                mask: '!#XXXXXXXX',
                menu: false,
            },
            secondaryColorPicker: {
                mask: '!#XXXXXXXX',
                menu: false,
            },
            newCourse: {
                id: null,
                loading: false,
            },
            form: {
                title: null,
                headerImage: null,
                mainColor: '#3f4043',
                secondaryColor: '#00963f',
                studio_id: null,
                restrictSignups: null,
                courses: [],
            },
            snackBar: {
                color: 'primary',
                status: false,
                message: '',
            },
            requiredCourseId: [(v) => !!v || 'Die Kurs ID muss ausgefüllt sein.'],
            requiredField: [(v) => !!v || 'Dies ist ein Pflichtfeld.'],
        }
    },
    created() {
        this.setNavigationbarVisibility(true)

        this.setNavigationBarText({ text: 'Erstellen', state: false, endPoint: 'EventCreate' })
        if (this.$route.params.id) {
            this.loading = true
            this.setNavigationBarText({ text: 'Speichern', state: false, endPoint: 'EventEdit' })
            this.editMode = true
            api.fetchEvent(this.$route.params.id).then((data) => {
                this.form = data.data
                this.loading = false
            })
        }
        //
        // api.fetchEvent(this.$route.params.id).then((data) => {
        //   this.event = data
        //   this.loading = false
        //   api.fetchEventCourseSignupsForUser(this.$route.params.id).then((data) => {
        //     this.registeredCourses = data
        //   })
        // })
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNavigationbarVisibility']),
        createSnackBar(message, color) {
            this.snackBar = {
                color,
                message,
                status: true,
            }
        },
        validateForm() {
            if (this.$refs.eventForm.validate()) {
                if (this.form.restrictSignups === 'Nicht beschränken') this.form.restrictSignups = null
                //if (!this.form.headerImage) {
                //	this.createSnackBar('Füge ein Titelbild hinzu', 'error')
                //	return false
                //} else {
                return true
                //}
            }
        },
        pushToMain() {
            //this.$router.push('/dashboard/gf/event')
            this.$router.push('/redirect/groupFitnessDashboardEventHome')
        },
        deleteEvent() {
            api.deleteEvent(this.$route.params.id).then((response) => {
                console.log(response)
                if (response.nModified === 1) {
                    this.createSnackBar('Event erfolgreich gelöscht', 'success')
                    this.pushToMain()
                } else {
                    this.createSnackBar('Fehler beim löschen des Events', 'error')
                }
            })
        },
        updateEvent() {
            if (this.validateForm()) {
                let formData = JSON.parse(JSON.stringify(this.form))
                api.updateEvent(formData).then((response) => {
                    this.createSnackBar('Event erfolgreich aktualisiert.', 'success')
                    this.pushToMain()
                })
            }
        },
        createNewEvent() {
            if (this.validateForm()) {
                let formData = JSON.parse(JSON.stringify(this.form))
                if (formData.courses) formData.courses = formData.courses.map((course) => course._id)
                api.createEvent(formData).then((response) => {
                    this.createSnackBar('Event erfolgreich erstellt.', 'success')
                    this.pushToMain()
                })
            }
        },
        removeCourse(id) {
            this.form.courses = this.form.courses.filter((course) => course._id !== id)
            this.createSnackBar('Kurs entfernt.', 'success')
        },
        addNewCourse() {
            if (this.$refs.courseIdForm.validate()) {
                this.newCourse.loading = true
                api.fetchSingleCourse(this.newCourse.id).then((data) => {
                    if (data.name === 'CastError') {
                        this.createSnackBar('Der Kurs wurde nicht gefunden.', 'error')
                    } else {
                        this.createSnackBar('Kurs hinzugefügt.', 'success')
                        this.form.courses.push(data)
                    }
                    this.newCourse.loading = false
                })
            }
        },
        getCourseImage(image) {
            return '/img/kurse/' + image
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'event'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.form.headerImage = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                })
        },
    },
    beforeDestroy() {
        this.setNavigationBarText(null)
        this.setNavigationbarVisibility(true)
    },
    computed: {
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),

        swiperOption() {
            let coursesCount = this.form.courses.length
            let slidesPerView = 3
            if (!coursesCount) {
                this.swiperKey = 'swiper1slide'
                slidesPerView = 1
            } else if (coursesCount === 1 || this.$vuetify.breakpoint.mdAndDown) {
                this.swiperKey = 'swiper2slides'
                slidesPerView = 2
            }
            return {
                slidesPerView,
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            }
        },
        backgroundMainColor() {
            let color = this.form.mainColor || '#3f4043'
            return 'background-color: ' + color
        },
        backgroundSecondaryColor() {
            let color = this.form.secondaryColor || '#00963f'
            return 'background-color: ' + color
        },
        mainColor() {
            let color = this.form.mainColor || '#3f4043'
            return 'color: ' + color
        },
        secondaryColor() {
            let color = this.form.secondaryColor || '#00963f'
            return 'color: ' + color
        },
        swatchStyleMain() {
            return {
                backgroundColor: this.form.mainColor,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.mainColorPicker.menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out',
            }
        },
        swatchStyleSecondary() {
            return {
                backgroundColor: this.form.secondaryColor,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: this.secondaryColorPicker.menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out',
            }
        },
    },
    watch: {
        navigationBarTextState(val) {
            if (this.navigationBarEndPoint === 'EventCreate' || this.navigationBarEndPoint === 'EventEdit') {
                if (this.navigationBarInitiated) {
                    this.validateForm()
                    if (this.navigationBarEndPoint === 'EventCreate') {
                        this.createNewEvent()
                    } else if (this.navigationBarEndPoint === 'EventEdit') {
                        this.updateEvent()
                    }
                } else {
                    this.navigationBarInitiated = true
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .slim-btn {
    margin-bottom: 30px;
}

.h5 {
    text-transform: uppercase;
    font-weight: bold;
}

.event__lottie {
    display: block;
}

.desktop-sizes {
    max-width: 70vh;
    min-width: 30vh;
    max-height: 85vh;
    min-height: 60vh;
}

.v-card {
    transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
    bottom: 0;
}

.event {
    &__card {
        margin: auto;
        width: 100%;
        height: 91vh;
        &-image {
            max-height: 45%;
            background-size: contain;
        }
        &-course {
            &-id {
                ::v-deep label {
                    color: white;
                }
                ::v-deep input {
                    color: white !important;
                }
            }
            &-title {
                color: white;
                font-size: 18px;
                font-weight: bold;
                word-break: break-word;

                &-mobile {
                    font-size: 2.5vw;
                }
            }
            &-content-mobile {
                font-size: 2vw;
            }
            &-button {
                width: 100%;
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
            }
        }
    }
}
</style>
