<template>
    <v-dialog :value="!!bigImagesArray" @click:outside="closeDialog">
        <v-card>
            <v-window v-model="bigImagesIndex" light continuous>
                <v-window-item :transition="null" v-for="(image, index) of bigImagesArray" :key="`card-${index}`">
                    <v-img max-height="90vh" :src="image.dataUrl"></v-img>
                </v-window-item>
            </v-window>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import api from '@/api.js'
export default {
    name: 'habitFormUpdateExecutionImage',
    props: {
        bigImagesArray: {},
    },
    data() {
        return {
            bigImagesIndex: 0,
            bigImagesArrayDay: null,
        }
    },
    methods: {
        closeDialog() {
            console.log('closeDialog')
            this.$emit('close')
        },
    },
}
</script>