<template>
    <div>
        <v-card-title class="white--text font-weight-bold">Trainer</v-card-title>
        <attendance-trainer-list marginXAuto dashboard showBades :studio="selectedStudioSingle"></attendance-trainer-list>
        <v-card-title class="white--text font-weight-bold"
            >Kunden
            <v-btn v-if="false" class="primary ml-2" @click="walkinDialog = true"
                >Interessent hinzufügen</v-btn
            ></v-card-title
        >
        <attendance-customer-list :selectedStudioSingle="selectedStudioSingle"></attendance-customer-list>
        <v-dialog max-width="500px" v-model="walkinDialog">
            <v-card dark class="px-3 text-center">
                <v-card-title> Interessent Hinzufügen</v-card-title>
                <v-text-field label="Vorname" outlined v-model="walkin.surname"></v-text-field>
                <v-text-field label="Name" outlined v-model="walkin.name"></v-text-field>
                <v-text-field label="E-Mail" outlined v-model="walkin.email"></v-text-field>
                <v-text-field label="Telefon" outlined v-model="walkin.phone"></v-text-field>
                <v-select
                    outlined
                    label="Typ"
                    v-model="walkin.type"
                    :items="[
                        { text: 'Walk-In', value: '65000dc796e439bafefaf490' },
                        { text: 'Telefon', value: '6501b2707e1d78fc0506e23b' },
                        { text: 'E-Mail', value: '6501b2a27e1d78fc0506e29c' },
                        { text: 'E-Mail', value: '6501b2a27e1d78fc0506e29c' },
                    ]"></v-select>
                <v-text-field
                    label="Studio"
                    outlined
                    disabled
                    :value="'Wird im ausgewählten Studio hinterlegt'"></v-text-field>
                <v-textarea label="Notiz" outlined v-model="walkin.note"></v-textarea>
                <v-btn class="mx-auto" fab small color="primary" @click="addWalkin"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '@/api'
import attendanceTrainerList from '@/views/dashboard/attendance/attendanceTrainerList.vue'
import attendanceCustomerList from '@/views/dashboard/attendance/attendanceCustomerList.vue'
export default {
    data() {
        return {
            walkinDialog: false,
            walkin: {},
        }
    },
    props: {
        selectedStudioSingle: {
            required: true,
        },
    },
    components: {
        attendanceTrainerList,
        attendanceCustomerList,
    },
    methods: {
        async addWalkin() {
            if (!this.walkin.type) {
                this.$toast.error('Bitte wähle einen Typen aus')
                return
            }
            this.walkin.studio = this.selectedStudioSingle
            await api.createWalkin(this.walkin)
            this.walkin = {}
            this.walkinDialog = false
            this.$toast.success('Walk-In wurde zur Lead Liste hinzugefügt')
        },
    },
}
</script>

<style lang="scss" scoped>
</style>