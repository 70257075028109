<template>
    <div style="width: 100%">
        <v-card-title
            style="font-size: 18px"
            class="pb-3 font-weight-bold justify-content-center d-flex justify-space-around"
        >
            <v-btn icon small @click="updateSelectedWeek(-1)"><v-icon color="white">mdi-chevron-left</v-icon></v-btn>
            <span class="white--text" v-if="isSameWeek">
                {{ 'Woche ' + Math.floor(selectedDate.diff(firstWeek, 'days') / 7) }}
            </span>
            <span class="white--text" v-else>
                {{ 'Woche ' + Math.floor(selectedWeek.diff(firstWeek, 'days') / 7) }}
            </span>
            <v-btn icon small @click="updateSelectedWeek(1)"><v-icon color="white">mdi-chevron-right</v-icon></v-btn>
        </v-card-title>
        <v-row
            no-gutters
            v-dragscroll="{ active: !$vuetify.breakpoint.mobile }"
            data-v-step="4"
            class="habit__calendar flex-nowrap px-0 mb-3"
        >
            <v-col v-for="(date, y) in calendar" :key="y">
                <v-skeleton-loader
                    v-if="loadingCalendar"
                    class="mx-auto mt-8"
                    max-width="30"
                    max-height="30"
                    type="card"
                ></v-skeleton-loader>
                <div v-else>
                    <v-row class="mx-0" :class="{ 'habit__calendar-date': 1, 'justify-center': 1 }">
                        <v-btn
                            plain
                            :ripple="false"
                            :id="date.date"
                            icon
                            class="font-weight-bold"
                            color="primary"
                            :loading="date.active && loading"
                            @click="selectDate(y)"
                            style="
                                border-bottom-left-radius: 0px !important;
                                border-bottom-right-radius: 0px !important;
                                opacity: 1;
                            "
                            :class="{ 'habit__calendar-date-btn': 1, active: date.active }"
                        >
                            <span :class="{ 'white--text ': !date.active }">{{ date.date }}</span>
                        </v-btn>
                    </v-row>
                    <v-row class="justify-center habit__calendar-short__wrapper mt-2 mx-0">
                        <span
                            :class="{ 'white primary--text': date.active }"
                            class="calendar--short pb-1 text-center white--text font-weight-bold"
                        >
                            {{ date.short }}
                        </span>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { dragscroll } from 'vue-dragscroll'

export default {
    directives: {
        dragscroll,
    },
    props: {
        startDate: {
            required: false,
        },
        selectedDays: {
            required: false,
        },
        loading: {
            default: false,
        },
    },
    data() {
        return {
            loadingCalendar: true,
            starCount: 0,
            firstWeek: this.$moment().startOf('isoWeek'),
            selectedWeek: this.$moment(),
            selectedDate: this.$moment(),
            dayIndexes: {
                monday: 0,
                tuesday: 1,
                wednesday: 2,
                thursday: 3,
                friday: 4,
                saturday: 5,
                sunday: 6,
            },
        }
    },
    created() {
        this.firstWeek = this.$moment(this.startDate).startOf('isoWeek')
    },
    mounted() {
        this.$emit('fetchForDay', this.$moment().format('DD-MM-YYYY'))
    },
    computed: {
        calendar() {
            this.loadingCalendar = true
            let data = []
            let mondayOfWeek = this.selectedWeek.clone().startOf('isoWeek')

            /*this.selectedDays.forEach((day) => {
                // Berechne den Wochentag basierend auf dem Montag der Woche
                let dayMoment = mondayOfWeek.clone().add(this.dayIndexes[day], 'days')
                data.push({
                    date: dayMoment.date(),
                    fullDate: dayMoment,
                    active: this.$moment(dayMoment).isSame(this.$moment(), 'day'),
                    short: dayMoment.format('dd'),
                })
            })
            */
            for (let i = 0; i < 7; i++) {
                // Berechne den Wochentag basierend auf dem Montag der Woche
                let dayMoment = mondayOfWeek.clone().add(i, 'days')
                data.push({
                    date: dayMoment.date(),
                    fullDate: dayMoment,
                    active: this.$moment(dayMoment).isSame(this.$moment(), 'day'),
                    short: dayMoment.format('dd'),
                })
            }
            this.loadingCalendar = false
            return data
        },
        isSameWeek() {
            return this.selectedWeek.isoWeek() === this.selectedDate.isoWeek()
        },
    },
    methods: {
        updateSelectedWeek(value) {
            this.selectedWeek = this.$moment(this.selectedWeek).add(value, 'weeks')
        },
        selectDate(index) {
            this.calendar.forEach((date, i) => {
                date.active = i === index
            })
            this.selectedDate = this.calendar[index].fullDate
            this.$emit('fetchForDay', this.$moment(this.calendar[index].fullDate).format('DD-MM-YYYY'))
            // document.getElementById(this.calendar[index].date).scrollIntoView({ behavior: 'smooth', inline: 'center' })
        },
    },
}
</script>
<style lang="scss" scoped>
.active {
    background: white;
}
.calendar {
    &--short {
        width: 36px;
        padding-top: 10px;
        margin-top: -10px !important;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}
</style>