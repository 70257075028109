<template>
	<v-dialog persistent max-width="1140px" :value="true" fullscreen hide-overlay transition="dialog-bottom-transition">
		<div class="workingOutGuide__card__wrapper ">
			<v-card-title style="word-break: break-word;" class="workingOutStatistic__card-title primary--text h3 text-wrap justify-center"
				>Anleitung
			</v-card-title>

			<swiper class="swiper" min-height="160px" :options="swiperOption">
				<div class="swiper-pagination swiper-pagination-bullets " slot="pagination"></div>
				<swiper-slide v-for="step of manual" :key="step._id + 'manualStep'" class="d-flex flex-column justify-center">
					<div class="d-flex flex-column justify-center mt-10">
						<!---   <v-card-title class="h5 d-flex justify-center"> Schritt {{ index + 1 }} </v-card-title> ---->
						<img v-if="step.picture" class="mx-auto swiper__img" :src="'/img/uebungen/' + step.picture" />
						<div v-else class="swiper_placeholder"></div>
						<v-card-text class="body-1 mb-15 d-flex justify-center">
							{{ step.description }}
						</v-card-text>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import 'swiper/css/swiper.css'

export default {
	name: 'WorkingoutExerciseGuide',
	loading: true,
	components: { Swiper, SwiperSlide },
	autoplay: {},
	data() {
		return {
			swiperOption: {
				slidesPerView: 1,

				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					renderBullet(index, className) {
						return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
					},
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
		}
	},
	props: {
		exercise_id: {
			type: String,
			required: true,
		},
		manual: {
			type: Array,
			required: true,
		},
	},
	created() {
		this.setNewBottomNavigationUsage(null)
		this.setNavigationBarText({ text: 'zurück', state: false, endPoint: this.exercise_id })
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNewBottomNavigationUsage']),
	},
}
</script>

<style lang="scss" scoped>
.workingOutGuide__card__wrapper {
	height: 100%;
	background-color: white;
}
::v-deep .swiper-pagination-bullet-active {
	background-color: var(--v-primary) !important;
}
.swiper__img {
	width: 80vw;
	max-width: 500px;
	height: 270px;
	object-fit: contain;
}
.swiper_placeholder {
	height: 150px;
}

.swiper {
	::v-deep .swiper-pagination-bullet-custom {
		width: 30px !important;
		height: 30px !important;
		line-height: 30px !important;
		top: 0px !important;
		text-align: center;
		color: white;
		opacity: 0.7;
		background: var(--v-primary);
		// transition: all $transition-time-normal;

		&:hover {
			opacity: 1;
		}

		&.swiper-pagination-bullet-active {
			opacity: 1;
			color: white;
			background: #007aff;
		}
	}
}
</style>
