<template xmlns="">
    <v-row class="ma-5">
        <v-col cols="12" class="text-center">
            <v-btn color="orange" class="white--text" @click="showStudioPicker">Studio wechseln</v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-btn
                color="primary"
                v-show="userService.hasRight('g_intern')"
                @click="$router.push('/dashboard/gf/event/create')"
            >
                Event erstellen
            </v-btn>
        </v-col>
        <v-col cols="12" lg="6" xl="4" class="event-home__card-wrapper mb-5" v-for="(event, i) of events" :key="i">
            <v-card dark hover class="event-home__card rounded-xl fill-height">
                <v-row>
                    <v-col
                        @click="$router.push('/dashboard/gf/event/edit/' + event._id)"
                        class="pt-0"
                        cols="12"
                        v-show="event.headerImage"
                    >
                        <v-img class="event-home__card-image" :src="'/img/event/' + event.headerImage" />
                    </v-col>
                    <v-col @click="$router.push('/dashboard/gf/event/edit/' + event._id)">
                        <v-card-title class="d-flex flex-row justify-space-between" style="word-break: break-word">
                            <span>{{ event.title }}</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12">
                        <v-card-actions class="justify-center">
                            <v-btn color="primary" text @click="$router.push('/dashboard/gf/event/edit/' + event._id)">
                                Event bearbeiten
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <router-view></router-view>
    </v-row>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'EventHome',
    components: { Swiper, SwiperSlide },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            loading: false,
            events: null,
            userService,
        }
    },
    created() {
        this.loading = true
        api.fetchAllEvents().then((data) => {
            this.events = data
            this.loading = false
            this.$emit('finishedloading')
        })
    },
    methods: {
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
    },
}
</script>
<style lang="scss" scoped>
.h5 {
    text-transform: uppercase;
    font-weight: bold;
}

.event__lottie {
    display: block;
}

.event-home {
    &__card-image {
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
    }
}
</style>
