<template>
	<div class="courseSettings">
		<v-tabs v-model="tab" background-color="transparent" color="basil" grow>
			<v-tab v-for="item in items" :key="item">
				{{ item }}
			</v-tab>
		</v-tabs>
		<v-tabs-items touchless v-model="tab">
			<v-tab-item class="courseSettings__ratings">
				<v-row no-gutters v-if="userService.hasRight('gf_r_viewAll')" class="courseSettings__ratings-datefilter justify-space-evenly ">
					<v-col class="d-flex justify-center" cols="12">
						<v-btn class="mt-10 mx-auto mb-2" @click="showDatePicker = !showDatePicker">
							<span v-if="!showDatePicker">{{ formatDate(datePicker) }}</span>
							<span v-else>Bewertungen anzeigen</span>
						</v-btn>
					</v-col>
					<v-col v-if="showDatePicker" class="d-flex justify-center mt-5" cols="12">
						<v-date-picker color="primary lighten-1" locale="de-CH" v-model="datePicker"></v-date-picker>
					</v-col>
				</v-row>

				<v-row class="courseSettings__ratings-wrapper" v-if="!showDatePicker" no-gutters>
					<v-col class="my-5" xs="12" sm="6" md="4" lg="4" xl="3" cols="12" v-for="rating of ratings" :key="rating._id">
						<div class="courseSettings__rating-wrapper-course px-2 py-2 font-weight-bold mb-2">
							{{ formatCourseDate(rating) }}{{ rating.course.name }}
						</div>
						<div class="courseSettings__rating-wrapper-instructor px-2">
							mit
							<span v-for="instructor of rating.instructors" :key="instructor._id">
								{{ instructor.vorname }} {{ instructor.nachname }}
							</span>
						</div>
						<div class="courseSettings__rating-wrapper-rating my-5" v-for="r of rating.ratings" :key="r._id">
							<div class="courseSettings__rating-wrapper-rating-user text-center">
								{{ formatRatingDate(r.ts) }} Uhr | {{ r.user.vorname }} {{ r.user.nachname }}
							</div>
							<div class="courseSettings__rating-wrapper-rating-stars">
								<v-rating
									background-color="#ffd700"
									color="#ffd700"
									class="courseSettings__rating-wrapper-rating-stars-rating my-0 d-flex justify-center"
									length="5"
									size="35"
									readonly
									:value="r.stars"
								></v-rating>
							</div>
							<div class="courseSettings__ratings-wrapper-rating-message text-center">
								{{ r.message }}
							</div>
						</div>
					</v-col>
				</v-row>
			</v-tab-item>

			<v-tab-item>
				<v-btn @click="showCoursePictureList = !showCoursePictureList"
					><span v-if="showCoursePictureList">Zurück</span><span v-else>Bilder kontrollieren</span></v-btn
				>
				<v-row v-if="!showCoursePictureList" no-gutters class="courseSettings__course my-5 px-5">
					<v-col class="d-flex justify-center" cols="12">
						<v-dialog transition="dialog-bottom-transition" max-width="600">
							<template v-slot:activator="{ on, attrs }">
								<v-btn class="courseSettings_action-add-btn" color="primary" v-bind="attrs" v-on="on">Hinzufügen</v-btn>
							</template>
							<template v-slot:default="dialogAdd">
								<v-card>
									<v-toolbar color="primary" dark>
										<div class="d-block ">
											<strong>Neuen Kurs hinzufügen</strong>
										</div>
									</v-toolbar>
									<div class="px-5 courseSettings_action-dialog  d-flex flex-column">
										<v-text-field class="mt-5" label="Titel" v-model="addCourse.name"></v-text-field>
										<v-textarea label="Beschreibung" v-model="addCourse.beschreibung"></v-textarea>
										<slim-cropper style="height: 200px" :options="slimOptionsExercise">
											<img v-if="addCourse.bild" :src="'/img/kurse/' + addCourse.bild" alt="" />
											<input type="file" accept="image/*" />
										</slim-cropper>
										<!----<input
											ref="addCourseImageInput"
											v-show="false"
											type="file"
											accept="image/*"
											@change="uploadGFImage(addCourse, $event)"
										/>
										<v-img height="120px" v-if="addCourse.bild" :src="'/img/kurse/' + course.bild" contain></v-img>
										<v-btn
											@click="$refs['addCourseImageInput'].click()"
											color="primary"
											v-if="addCourse.bild"
											class="mx-auto my-5"
										>
											Bild bearbeiten
										</v-btn>
										<v-btn @click="$refs['addCourseImageInput'].click()" color="primary" v-else class="mx-auto my-5">
											Bild hochladen
										</v-btn> ---->
									</div>
									<v-checkbox label="slimMe Kurs" v-model="addCourse.slimMe"></v-checkbox>
									<v-card-actions class="justify-end">
										<v-btn text @click="dialogAdd.value = false">Abbrechen</v-btn>
										<v-btn color="primary" text @click="createCourse(dialogAdd)">Speichern</v-btn>
									</v-card-actions>
								</v-card>
							</template>
						</v-dialog>
					</v-col>
					<v-text-field v-model="courseListFilter" label="Suche" placeholder=""></v-text-field>

					<v-col
						cols="12"
						v-for="(course, index) of courseList"
						class="courseSettings__course-list flex-row justify-space-between py-2"
						:class="{
							'd-flex': !courseListFilter.length || course.name.toLowerCase().indexOf(courseListFilter.toLowerCase()) !== -1,
							'd-none': !(!courseListFilter.length || course.name.toLowerCase().indexOf(courseListFilter.toLowerCase()) !== -1),
						}"
						:key="course._id"
					>
						<div class="courseSettings__course-list-title d-flex">
							<span class="my-auto">{{ shorten(course.name) }}</span>
						</div>
						<div class="courseSettings__course-list-action">
							<v-dialog @input="setEditIndex($event, index)" transition="dialog-bottom-transition" max-width="600">
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="red" icon @click="deleteEntry(course, 'course', 0)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
									<v-btn icon class="courseSettings__course-list-action-btn" color="primary" v-bind="attrs" v-on="on">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<template v-slot:default="dialog">
									<v-card>
										<v-toolbar color="primary" dark>
											<div class="d-block ">
												<strong>{{ shorten(course.name) }}</strong>
											</div>
										</v-toolbar>
										<div class="px-5 courseSettings__course-list-action-wrapper  d-flex flex-column">
											<v-text-field class="mt-5" label="Titel" v-model="course.name"></v-text-field>
											<v-textarea label="Beschreibung" v-model="course.beschreibung"></v-textarea>
											<slim-cropper style="height: 300px" :options="slimOptionsExercise">
												<img v-if="course.bild" :src="'/img/kurse/' + course.bild" alt="" />
												<input type="file" accept="image/*" />
											</slim-cropper>
											<v-checkbox label="slimMe Kurs" v-model="course.slimMe">course</v-checkbox>
										</div>
										<v-card-actions class="justify-end">
											<v-btn text @click="dialog.value = false">Abbrechen</v-btn>
											<v-btn color="primary" text @click="updateCourse(course, dialog)">Speichern</v-btn>
										</v-card-actions>
									</v-card>
								</template>
							</v-dialog>
						</div>
					</v-col>
				</v-row>
				<div v-else>
					<v-row class="course_picture" v-for="(course, index) of courseList" :key="'bild-' + course._id">
						<v-col cols="6"
							><v-img height="200px" width="100%" contain v-if="course.bild" :src="'/img/kurse/' + course.bild"></v-img></v-col
						><v-col cols="6" class="d-flex "
							><strong class="my-auto">{{ course.name }}</strong>
							<div class="courseSettings__course-list-action action2">
								<v-dialog @input="setEditIndex($event, index)" transition="dialog-bottom-transition" max-width="600">
									<template v-slot:activator="{ on, attrs }">
										<v-btn color="red" icon @click="deleteEntry(course, 'course', 0)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
										<v-btn icon class="courseSettings__course-list-action-btn" color="primary" v-bind="attrs" v-on="on">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<template v-slot:default="dialog">
										<v-card>
											<v-toolbar color="primary" dark>
												<div class="d-block ">
													<strong>{{ shorten(course.name) }}</strong>
												</div>
											</v-toolbar>
											<div class="px-5 courseSettings__course-list-action-wrapper  d-flex flex-column">
												<v-text-field class="mt-5" label="Titel" v-model="course.name"></v-text-field>
												<v-textarea label="Beschreibung" v-model="course.beschreibung"></v-textarea>
												<slim-cropper style="height: 200px" :options="slimOptionsExercise">
													<img v-if="course.bild" :src="'/img/kurse/' + course.bild" alt="" />
													<input type="file" accept="image/*" />
												</slim-cropper>
											</div>
											<v-card-actions class="justify-end">
												<v-btn text @click="dialog.value = false">Abbrechen</v-btn>
												<v-btn color="primary" text @click="updateCourse(course, dialog)">Speichern</v-btn>
											</v-card-actions>
										</v-card>
									</template>
								</v-dialog>
							</div></v-col
						>
					</v-row>
				</div>
			</v-tab-item>
			<v-tab-item>
				<v-row>
					<v-col cols="12" class="d-flex justify-center mt-5">
						<v-btn @click="addRoomDialog = true" color="primary">Hinzufügen</v-btn>
					</v-col>
					<v-col>
						<v-dialog v-model="addRoomDialog" transition="dialog-bottom-transition" max-width="600">
							<v-card>
								<v-toolbar color="primary" dark>
									<div class="d-block ">
										<strong>Neuen Raum hinzufügen</strong>
									</div>
								</v-toolbar>
								<div class="px-5 courseSettings_action-dialog  d-flex flex-column">
									<v-text-field class="mt-5" label="Name" v-model="addRoom.name"></v-text-field>
								</div>
								<v-card-actions class="justify-end">
									<v-btn text @click="addRoomDialog = false">Abbrechen</v-btn>
									<v-btn :loading="addRoomDisabled" :disabled="addRoomDisabled" color="primary" text @click="createRoom">
										Speichern
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-col>
				</v-row>
				<v-expansion-panels class="py-5">
					<v-expansion-panel v-for="room of rooms" :key="room._id">
						<v-expansion-panel-header>
							<template v-slot:default="{ open }">
								<v-row no-gutters>
									<v-col cols="4">
										{{ shorten(room.name, 10) }}
									</v-col>
									<v-col cols="8" class="text--secondary">
										<v-fade-transition leave-absolute>
											<span v-if="open" key="0"></span>
										</v-fade-transition>
									</v-col>
								</v-row>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-row no-gutters>
								<v-text-field v-model="room.name" label="Raumname" placeholder="Raumname"></v-text-field>
							</v-row>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="red" text @click="deleteEntry(room, 'room', 0)">
									Löschen
								</v-btn>

								<v-btn @click="updateRoom(room)" text color="primary">
									Speichern
								</v-btn>
							</v-card-actions>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
			<v-tab-item>
				<v-row>
					<v-col cols="12" class="d-flex justify-center mt-5">
						<v-btn @click="addLabelDialog = true" color="primary">Hinzufügen</v-btn>
					</v-col>
					<v-col cols="12">
						<v-dialog v-model="addLabelDialog" transition="dialog-bottom-transition" max-width="600">
							<v-card>
								<v-toolbar color="primary" dark>
									<div class="d-block ">
										<strong>Neues Label hinzufügen</strong>
									</div>
								</v-toolbar>
								<div class="px-5 courseSettings_action-dialog  d-flex flex-column">
									<v-text-field class="mt-5" label="Text" v-model="addLabel.text"></v-text-field>
									<v-text-field class="mt-5" label="Interne Bezeichnung" v-model="addLabel.code"></v-text-field>
									<div class="d-flex justify-center">
										<v-color-picker
											hide-canvas
											hide-inputs
											hide-mode-switch
											hide-sliders
											show-swatches
											:swatches="swatches"
											class="px-auto"
											mode="hexa"
											v-model="addLabel.color"
										></v-color-picker>
									</div>
								</div>
								<v-card-actions class="justify-end">
									<v-btn text @click="addLabelDialog = false">Abbrechen</v-btn>
									<v-btn color="primary" text @click="createLabel">Speichern</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-col>
				</v-row>
				<v-expansion-panels class="py-5">
					<v-expansion-panel v-for="label of labels" :key="label._id">
						<v-expansion-panel-header>
							<template v-slot:default="{ open }">
								<v-row no-gutters>
									<v-col cols="4">
										{{ shorten(label.text, 10) }}
									</v-col>
									<v-col cols="8" class="text--secondary">
										<v-fade-transition leave-absolute>
											<span v-if="open" key="0"></span>
											<span v-else key="1">
												{{ shorten(label.code, 10) }}
											</span>
										</v-fade-transition>
									</v-col>
								</v-row>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-row no-gutters>
								<v-text-field label="Text" v-model="label.text" placeholder="Text"></v-text-field>
							</v-row>
							<v-row no-gutters>
								<v-text-field label="Interne Bezeichnung" v-model="label.code" placeholder="Interne Bezeichnung"></v-text-field>
							</v-row>
							<v-row no-gutters class="d-flex justify-center">
								<v-color-picker
									hide-canvas
									hide-inputs
									hide-mode-switch
									hide-sliders
									v-model="label.color"
									class="ma-2"
									:swatches="swatches"
									show-swatches
								></v-color-picker>
							</v-row>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="red" text @click="deleteEntry(label, 'label', 0)">
									Löschen
								</v-btn>
								<v-btn @click="updateLabel(label)" text color="primary">
									Speichern
								</v-btn>
							</v-card-actions>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-tab-item>
			<v-tab-item>
				<event-home> </event-home>
			</v-tab-item>
			<v-tab-item>
				<v-row no-gutters class="py-5 px-5">
					<v-col cols="12">
						<v-text-field
							type="number"
							:label="'Anmeldungen ' + courseSettings.enableSignup + ' Tage vor Kursbeginn'"
							v-model="courseSettings.enableSignup"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
							type="number"
							:label="'Anmeldungen bis ' + courseSettings.signupTimeLimit + ' Min vor Kursbeginn'"
							v-model="courseSettings.signupTimeLimit"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
							type="number"
							:label="'Abmeldungen bis ' + courseSettings.signoffTimeLimit + ' Min vor Kursbeginn'"
							v-model="courseSettings.signoffTimeLimit"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field type="number" label="Anz. sichtbare Tage (Kunden)" v-model="courseSettings.visibleDays"></v-text-field>
					</v-col>
					<v-col class="d-flex justify-center py-5"><v-btn @click="saveCourseSettings" color="primary">Speichern</v-btn></v-col>
				</v-row>
			</v-tab-item>
		</v-tabs-items>
		<v-dialog v-model="deleteDialog" persistent max-width="290">
			<v-card>
				<v-card-title class="text-h5">
					{{ shorten(deleteDialogTitle, 25) }}
				</v-card-title>
				<v-card-text v-if="deleteDialogPreText" class="red--text">
					Kurs wird in folgenden Studios verwendet: {{ deleteDialogPreText }}
				</v-card-text>
				<v-card-text>
					Möchtest du {{ getTextbyType(deleteDialogType) }}
					<strong>{{ shorten(deleteDialogTitle, 25) }}</strong>
					wirklich löschen?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="deleteDialog = false">
						Abbrechen
					</v-btn>
					<v-btn color="green darken-1" text @click="deleteEntry(dialogDeleteObj, deleteDialogType, 1)">
						Löschen
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../components/userSearch.vue'
import courseSearch from '../../components/courseSearch.vue'
import Slim from '../../plugins/slimImage/slim/slim.vue'
import EventHome from '../event/eventHome.vue'

export default {
	name: 'courseSettings',
	components: { courseSearch, 'slim-cropper': Slim, EventHome },
	data() {
		return {
			userService,
			showCoursePictureList: false,
			editIndex: null,
			tab: null,
			items: ['Bewertungen', 'Kurse', 'Räume', 'Labels', 'Events', 'Einstellungen'],
			datePicker: null,
			ratings: [],
			courseList: [],
			courseListFilter: '',
			courseSettings: {
				enableSignup: 3,
				signupTimeLimit: 5,
				signoffTimeLimit: 5,
				visibleDays: 7,
			},
			showDatePicker: false,
			addCourse: {
				name: '',
				beschreibung: '',
				bild: '',
			},
			rooms: [],
			labels: [],
			deleteDialog: false,
			deleteDialogText: '',
			deleteDialogTitle: '',
			dialogDeleteObj: {},
			deleteDialogType: '',
			deleteDialogPreText: '',
			addRoomDialog: false,
			addRoom: { name: '' },
			addRoomDisabled: false,
			addLabelDialog: false,
			addLabel: { text: '', code: '', color: '' },
			swatches: [
				['#FF0000', '#ff9257', '#550000'],
				['#FFFF00', '#AAAA00', '#555500'],
				['#52ae32', '#42ff00', '#005500'],
				['#00FFFF', '#00AAAA', '#005555'],
				['#0000FF', '#0000AA', '#000055'],
			],
			slimOptionsExercise: {
				ratio: '1:1',
				initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
				buttonCancelLabel: 'Abbrechen',
				buttonConfirmLabel: 'Bestätigen',
				label: 'Bild hochladen',
				labelLoading: 'Bild wird hochgeladen',
				statusUploadSuccess: 'gespeichert',
				service: this.uploadPicture,
				serviceFormat: 'file',
				instantEdit: true,
				push: true,
			},
		}
	},
	async created() {
		this.datePicker = this.$moment().format('YYYY-MM-DD')
		await this.fetchRatings()
		await this.fetchCourseList()
		await this.fetchCourseSettings()
		await this.fetchRooms()
		await this.fetchLabels()
	},
	methods: {
		setEditIndex(active, index) {
			if (active) {
				this.editIndex = index
			} else {
				this.editIndex = null
			}
		},
		uploadPicture(formdata, progress, success, failure, slim) {
			const formData = new FormData()
			formData.append('file', formdata[0])

			let uploadimg = {}
			uploadimg.art = 'groupFitness_course'
			uploadimg.formData = formData

			api.uploadImage(uploadimg)
				.then((data) => {
					if (data.status) {
						if (this.editIndex) {
							this.courseList[this.editIndex].bild = data.name
						} else {
							this.addCourse.bild = data.name
						}

						success(data)
					}
				})
				.catch((error) => {
					failure(error)
				})
		},
		uploadGFImage(course, event) {
			if (event.target.files[0]) {
				let uploadimg = {}
				const formData = new FormData()
				let file = event.target.files[0]
				formData.append('file', event.target.files[0])
				uploadimg.formData = formData
				api.uploadGFCourseImage(uploadimg)
					.then((data) => {
						if (data.status) {
							course.bild = 'uploads/' + data.name
						}
					})
					.catch((error) => {
						alert('Fehler beim Hochladen')
					})
			}
		},
		async createCourse(dialog) {
			let course = this.addCourse

			let created = await api.createGFCourse(course)
			await this.fetchCourseList()
			dialog.value = false
		},
		async updateCourse(course, dialog) {
			let updated = await api.updateGFCourse(course)
			await this.fetchCourseList()
			dialog.value = false
		},
		async createLabel() {
			let label = this.addLabel
			let created = await api.createGFLabel(label)
			this.addLabelDialog = false
		},
		async updateLabel(label) {
			let updated = await api.updateGFLabel(label)
		},
		async createRoom() {
			this.addRoomDisabled = true
			let room = this.addRoom

			let created = await api.createGFRoom(room)
			await this.fetchRooms()
			this.addRoomDialog = false
			this.addRoomDisabled = false
		},
		async updateRoom(room) {
			let updated = await api.updateGFRoom(room)
		},
		getTextbyType(type) {
			if (type === 'label') return 'das Label'
			if (type === 'room') return 'den Raum'
			if (type === 'course') return 'den Kurs'
		},
		async deleteEntry(entry, type, status = 0) {
			this.dialogDeleteObj = entry
			this.deleteDialogType = type
			this.deleteDialogPreText = ''
			let error = false
			if (type === 'label') this.deleteDialogTitle = entry.text
			if (type === 'label' && status) (await api.deleteGFLabel(entry._id).catch((e) => (error = true))) && this.fetchLabels()
			if (type === 'room') this.deleteDialogTitle = entry.name
			if (type === 'room' && status) (await api.deleteGFRoom(entry._id).catch((e) => (error = true))) && this.fetchRooms()
			if (type === 'course') this.deleteDialogTitle = entry.name
			if (type === 'course') {
				let usage = await api.checkGFCourseUsage(entry._id)
				if (usage) this.deleteDialogPreText = usage
			}
			if (type === 'course' && status) (await api.deleteGFCourse(entry._id).catch((e) => (error = true))) && this.fetchCourseList()
			if (!status) this.deleteDialog = true
			if (status && !error) this.deleteDialog = false
		},

		async fetchLabels() {
			this.labels = await api.fetchGFLabels()
		},
		async fetchRooms() {
			this.rooms = await api.fetchGFRooms()
		},
		async saveCourseSettings() {
			let saved = await api.updateGFCourseSettings(this.courseSettings)
		},
		async fetchCourseSettings() {
			this.courseSettings = await api.fetchGFCourseSettings()
		},
		shorten(text, length = 25) {
			if (text.length > length) text = text.substr(0, length) + '...'
			return text
		},

		async fetchCourseList() {
			this.courseList = await api.fetchGFCourses()
		},
		formatCourseDate(course) {
			return (
				this.$moment(parseInt(course.ts) * 1000)
					.set({ hours: course.hours, minutes: course.minutes })
					.format('HH:mm') + ' Uhr | '
			)
		},
		formatRatingDate(ts) {
			return this.$moment(ts * 1000).format('HH:mm')
		},
		async fetchRatings() {
			let ratings = await api.fetchCourseRatings(this.$moment(this.datePicker).unix())
			this.ratings = ratings
		},
		formatDate(date) {
			if (date === null) return ''
			let day = this.$moment(date).format('dd')
			return date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0] + ' (' + day + ')'
		},
	},
	watch: {
		datePicker() {
			this.fetchRatings()
		},
	},
	computed: {},
}
</script>
<style lang="scss" scoped>
::v-deep .v-color-picker__edit,
:v-deep .v-color-picker__controls {
	display: none !important;
}
::v-deep .v-slide-group__prev {
	display: none !important;
}

.action2 {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-left: auto;
}

.course_picture {
	border-bottom: 1px solid grey;
	cursor: pointer;

	&:hover {
		background-color: rgb(218, 218, 218);
	}
}

.courseSettings {
	&__course {
		&-list {
			border-bottom: 1px solid black;
		}
	}
	&__rating {
		&-wrapper {
			&-course {
				background-color: var(--v-primary);
				color: white;
			}
		}
	}
}
</style>
