<template>
    <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
        <v-overlay absolute v-if="!displayedImageArray.length"></v-overlay>
        <v-card-title>
            Bilder
            <v-chip v-if="!displayedImageArray.length" class="ml-2">
                {{ finishedLoading ? 'Keine Bilder vorhanden' : 'lädt' }}
            </v-chip>
        </v-card-title>
        <v-row>
            <v-col
                v-for="(img, index) in displayedImageArray"
                :cols="enlargedImageOverwiew ? 2 : 4"
                :key="index"
                class="hover"
            >
                <v-img
                    @click="bigImg = img"
                    style="max-height: 150px; cursor: pointer"
                    class="ma-2"
                    :src="img.dataUrl"
                ></v-img>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="!images.length || !finishedLoading">
            <v-col cols="4" class="pa-2">
                <v-img
                    style="max-height: 150px"
                    src="https://i0.wp.com/simonstocker.ch/wp-content/uploads/2023/03/placeholder.png?ssl=1"
                ></v-img>
            </v-col>
            <v-col cols="4" class="pa-2">
                <v-img
                    style="max-height: 150px"
                    src="https://i0.wp.com/simonstocker.ch/wp-content/uploads/2023/03/placeholder.png?ssl=1"
                ></v-img>
            </v-col>
            <v-col cols="4" class="pa-2">
                <v-img
                    style="max-height: 150px"
                    src="https://i0.wp.com/simonstocker.ch/wp-content/uploads/2023/03/placeholder.png?ssl=1"
                ></v-img>
            </v-col>
        </v-row>

        <v-card-actions style="justify-content: right">
            <v-btn text x-small @click="toggleEnlarge()">
                {{ enlargedImageOverwiew ? 'verkleinern' : 'Alle Bilder' }}
            </v-btn>
        </v-card-actions>
        <v-dialog :value="!!bigImg" @click:outside="bigImg = null">
            <v-card v-if="bigImg">
                <v-btn absolute top right icon @click="bigImg = null"><v-icon color="red">mdi-close</v-icon></v-btn>
                <v-card-title class="font-weight-bold justify-content-center">
                    {{ $moment(bigImg.date).format('DD.MM.YYYY') }}
                </v-card-title>
                <v-img height="60vh" contain :src="bigImg.dataUrl"></v-img>
                <div class="pt-2 px-3 font-weigt-bold h5 text-center" v-html="bigImg.text"></div>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userSearch from '../../../components/userSearch.vue'

export default {
    props: {
        images: {
            default: [],
        },
        text: {},
        user: {},
        enlargedImageOverwiew: {},
    },
    data() {
        return {
            finishedLoading: false,
            imageArray: [],
            bigImg: null,
        }
    },

    async created() {
        this.imageArray = await api.fetchAllAvailableFormUpdateForUser(this.user._id)
        this.imageArray = this.images.concat(this.imageArray)
        for await (let image of this.imageArray) {
            try {
                const response = await api.fetchFormUpdateImage(image.src)
                this.$set(image, 'dataUrl', response)
            } catch (e) {
                image = null
            }
        }
        this.imageArray = this.imageArray.reverse()
        this.finishedLoading = true
    },
    computed: {
        displayedImageArray() {
            if (!this.finishedLoading) return []

            return this.enlargedImageOverwiew ? this.imageArray : this.imageArray.slice(0, 3)
        },
    },
    methods: {
        toggleEnlarge() {
            this.$emit('toggleEnlarge')
        },
        async fetchImageDataSource(image) {
            try {
                const response = await api.fetchFormUpdateImage(image.src)
                return response
            } catch (error) {
                this.$toast.error('Fehler beim laden des Bildes')
            }
        },
    },
}
</script>
