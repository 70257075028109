<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Typ</th>
                        <th class="text-left">Uhrzeit</th>
                        <th width="20px" class="text-right"></th>
                        <th width="20px" class="text-center"></th>
                        <th width="20px" class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="timeEntry in entries.filter((entry) => entry.type !== 'sick')" :key="timeEntry._id">
                        <td>{{ entryTypes[timeEntry.type] }}</td>
                        <td v-if="editTimeEntry && editTimeEntry._id === timeEntry._id">
                            <v-menu
                                :value="$moment(showTimeEntryDatePicker).format('DD.MM.YYYY')"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                v-model="showTimeEntryDatePicker"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editTimeEntryDate"
                                        label="Datum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    locale="de-CH"
                                    v-model="editTimeEntryDate"
                                    @input="showTimeEntryDatePicker = false"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                :ref="'menushowTimeEntryTimePicker' + editTimeEntry._id"
                                v-model="showTimeEntryTimePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editTimeEntryTime"
                                        label="Uhrzeit"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="showTimeEntryTimePicker"
                                    v-model="editTimeEntryTime"
                                    format="24hr"
                                    full-width
                                    @click:minute="showTimeEntryTimePicker = false"
                                ></v-time-picker>
                            </v-menu>
                        </td>
                        <td v-else>{{ $moment(timeEntry.date).format('DD.MM.YY HH:mm') }} Uhr</td>
                        <td>
                            <v-btn
                                v-if="editTimeEntry && editTimeEntry._id === timeEntry._id"
                                text
                                @click="updateTimeEntry()"
                            >
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                            <v-btn v-else x-small text @click="editTimeEntry = timeEntry">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn x-small v-if="!editTimeEntry" text @click="deleteTimeEntry = timeEntry">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn
                                x-small
                                @click="history = timeEntry.history"
                                v-if="!editTimeEntry && timeEntry.history && timeEntry.history.length > 0"
                                text
                            >
                                <v-icon color="white">mdi-history</v-icon>
                                {{ timeEntry.history.length }}
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-row>
            <v-col class="d-flex justify-center mt-5" cols="12">
                <v-btn color="primary" @click="showAddEntryDialog = true"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
        </v-row>
        <basic-dialog
            @onCancel="cancelAddEntry"
            @onSave="saveAddEntry"
            dialogTitle="Eintrag hinzufügen"
            dialogSaveButtonText="Speichern"
            :dialogVisible="showAddEntryDialog"
        >
            <v-row no-gutters>
                <v-col cols="12" class="pb-0 mb-0">
                    <v-select
                        class="mx-5"
                        v-model="addEntryType"
                        :items="entryTypeItems"
                        label="Typ"
                        outlined
                        dense
                        required
                    ></v-select>
                </v-col>
                <v-col cols="12" class="pb-0 mb-0">
                    <v-select
                        class="mx-5"
                        v-model="addEntryStudio"
                        :items="storeStudios"
                        label="Studio"
                        item-text="name"
                        item-value="_id"
                        outlined
                        dense
                        required
                    ></v-select>
                </v-col>
                <v-col cols="12" class="px-7 pt-0 mt-0">
                    <v-menu
                        :value="$moment(showAddEntryDatePicker).format('DD.MM.YYYY')"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        v-model="showAddEntryDatePicker"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="addEntryDate"
                                label="Datum"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            locale="de-CH"
                            v-model="addEntryDate"
                            @input="showAddEntryDatePicker = false"
                        ></v-date-picker>
                    </v-menu>
                    <v-menu
                        v-model="showAddEntryTimePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editAddEntryTime"
                                label="Uhrzeit"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="showAddEntryTimePicker"
                            v-model="editAddEntryTime"
                            format="24hr"
                            full-width
                            @click:minute="showAddEntryTimePicker = false"
                        ></v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </basic-dialog>
        <basic-dialog
            @onCancel="history = null"
            @onSave="history = null"
            dialogTitle="Änderungen"
            dialogSaveButtonText="Fertig"
            :dialogVisible="history !== null"
        >
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Datum</th>
                            <th class="text-left">Vorheriger Wert</th>
                            <th class="text-left">Geändert von</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in history" :key="item.updatedAt">
                            <td>{{ $moment(item.updatedAt).format('DD.MM. HH:mm') }} Uhr</td>
                            <td>{{ $moment(item.previousValue).format('DD.MM. HH:mm') }} Uhr</td>
                            <td>{{ item.updatedBy.vorname }} {{ item.updatedBy.nachname }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </basic-dialog>
        <basic-dialog
            @onCancel="deleteTimeEntry = null"
            @onSave="saveDeleteTimeEntry"
            dialogTitle="Eintrag löschen"
            dialogSaveButtonText="Löschen"
            :dialogVisible="deleteTimeEntry !== null"
        >
            <v-row no-gutters v-if="deleteTimeEntry">
                <v-col cols="12" class="mt-0 pt-0">
                    <v-card-text class="mt-0 pt-0">
                        Möchtest du den Eintrag {{ entryTypes[deleteTimeEntry.type] }} vom
                        {{ $moment(deleteTimeEntry.date).format('DD.MM.YY HH:mm') }} Uhr wirklich löschen?
                    </v-card-text>
                </v-col>
            </v-row>
        </basic-dialog>
    </div>
</template>

<script>
import api from '@/api'
import basicDialog from '@/components/tools/basicDialog'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            editTimeEntry: null,
            entryTypes: {
                checkin: 'Check-In',
                checkout: 'Check-Out',
                pause: 'Pause Start',
                pauseend: 'Pause Ende',
            },
            editAddEntryTime: this.$moment().format('HH:mm'),
            showAddEntryTimePicker: false,
            addEntryDate: this.$moment().format('YYYY-MM-DD'),
            showAddEntryDatePicker: false,
            addEntryType: null,
            addEntryStudio: null,
            showAddEntryDialog: false,
            deleteTimeEntry: null,
            pauseDateSelection: this.$moment().format('YYYY-MM-DD'),
            showPauseStartTimePicker: false,
            showPauseEndTimePicker: false,
            defaultPauseStartTime: this.$moment().set({ hours: '12', minutes: '00' }).format('HH:mm'),
            defaultPauseEndTime: this.$moment().set({ hours: '13', minutes: '00' }).format('HH:mm'),
            addPauseEntryStatus: false,
            showTimeEntryTimePicker: false,
            showTimeEntryDatePicker: false,
            history: null,
            dialog: false,
        }
    },
    components: { basicDialog },
    props: {
        entries: {
            type: Array,
            required: true,
        },
        employeeId: {
            type: String,
            required: true,
        },
    },
    methods: {
        async saveDeleteTimeEntry() {
            try {
                const status = await api.deleteTimetrackerEntryOffice(this.deleteTimeEntry._id)

                this.$toast.success('Erfolgreich gelöscht')
                this.$emit('fetchEntries', { date: this.deleteTimeEntry.date, employeeId: this.employeeId })
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Löschen')
            }
        },
        resetAddEntryPickers() {
            this.editAddEntryTime = this.$moment().format('HH:mm')
            this.addEntryDate = this.$moment().format('YYYY-MM-DD')
            this.addEntryType = null
            this.addEntryStudio = null
        },
        async cancelAddEntry() {
            this.resetAddEntryPickers()
            this.showAddEntryDialog = false
        },
        async saveAddEntry() {
            if (!this.addEntryType || !this.addEntryStudio) return this.$toast.error('Bitte wähle ein Typ aus')

            let date = this.$moment(this.addEntryDate)
                .set({
                    hours: this.editAddEntryTime.split(':')[0],
                    minutes: this.editAddEntryTime.split(':')[1],
                })
                .toDate()

            let newEntry = {
                type: this.addEntryType,
                date: date,
                employee: this.employeeId,
                studio: this.addEntryStudio,
                reason: 'added by office',
            }
            try {
                const status = await api.addTimetrackerEntryOffice(newEntry)

                this.$emit('fetchEntries', { date, employeeId: this.employeeId })
                this.cancelAddEntry()
                this.$toast.success('Erfolgreich gespeichert')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Speichern')
            }
        },
        async updateTimeEntry() {
            try {
                const status = await api.updateTimetrackerEntryEmployee(this.editTimeEntry)
                this.$emit('fetchEntries', { date: this.editTimeEntry.date, employeeId: this.employeeId })
                this.editTimeEntry = null
                this.$toast.success('Erfolgreich gespeichert')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Speichern')
            }
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),

        entryTypeItems() {
            // Generate an array of { value, text } objects for each entry type
            return Object.keys(this.entryTypes).map((key) => ({
                value: key,
                text: this.entryTypes[key],
            }))
        },
        editTimeEntryDate: {
            get() {
                if (this.editTimeEntry) {
                    return this.$moment(this.editTimeEntry.date).format('YYYY-MM-DD')
                }
                return null
            },
            set(value) {
                let hours = this.$moment(this.editTimeEntry.date).format('HH')
                let minutes = this.$moment(this.editTimeEntry.date).format('mm')
                this.editTimeEntry.date = this.$moment(value).set({ hours, minutes })
            },
        },

        editTimeEntryTime: {
            get() {
                if (this.editTimeEntry) {
                    return this.$moment(this.editTimeEntry.date).format('HH:mm')
                }
                return null
            },
            set(value) {
                let hours = value.split(':')[0]
                let minutes = value.split(':')[1]

                this.editTimeEntry.date = this.$moment(this.editTimeEntry.date).set({ hours, minutes })
            },
        },
    },
}
</script>


<style lang="scss" scoped>
</style>