<template>
    <div class="membercard pt-5">
        <div class="id">{{ tendays_id }}</div>
        <span v-show="!user.abo_anfrage" class="time_left">Verbleibende Zeit</span>

        <vue-countdown
            :time="tendays_date"
            v-slot="{ days, hours, minutes, seconds }"
            class="countdown"
            v-show="!user.abo_anfrage">
            <div class="tage">
                <span class="up">
                    {{ days }}
                </span>
                <span class="down">TAGE</span>
            </div>
            <div class="stunden">
                <span class="up">
                    {{ hours }}
                </span>
                <span class="down">STUNDEN</span>
            </div>
            <div class="minuten">
                <span class="up">
                    {{ minutes }}
                </span>
                <span class="down">MINUTEN</span>
            </div>
            <div class="sekunden">
                <span class="up">
                    {{ seconds }}
                </span>
                <span class="down">SEKUNDEN</span>
            </div>
        </vue-countdown>
        <img class="header" :src="getHeader()" />
        <div class="membercard-info">
            <h2 v-show="!user.abo_anfrage">DEINE MEMBERCARD</h2>

            <span v-show="!checkTimer() && !user.abo_anfrage">
                Dies ist Dein Ticket für die Reise auf die Sonnenseite Deines Lebens mit uns. Die Membercard berechtigt Dich
                unser
                <strong
                    >Fitness- und Groupfitness-Angebot während des oben ausgewiesenen Zeitraums zu den betreuten
                    Öffnungszeiten zu nutzen.</strong
                ><br /><br />
                Bitte jeweils die Membercard vor Trainingsbeginn an der Theke vorzeigen. Wir wünschen Dir ein phantastisches
                Training<br />
                Dein {{ getArt() }} Team
            </span>
        </div>

        <div class="day" v-show="checkTimer() && !user.abo_anfrage">
            <div v-show="check_in_counter == 2 && checkTimer()" class="angebot">
                <strong>PROFITIERE JETZT</strong> von unserem unschlagbaren Angebot: Wenn Du innerhalb der angezeigten Zeit,
                ein Abonnement bei uns abschliesst,
                <span v-if="isWCF()"
                    >schenken wir Dir die Einschreibgebühr in Höhe von CHF 99.- und dazu noch ein Zusatzpaket in Höhe von CHF
                    50.-</span
                >
                <span v-else>schenken wir Dir die eine Preisreduktion in Höhe von CHF 150.-</span>
            </div>
            <div v-show="check_in_counter == 3 && checkTimer()" class="angebot">
                <strong>PROFITIERE JETZT</strong> von unserem unschlagbaren Angebot: Wenn Du innerhalb der angezeigten Zeit,
                ein Abonnement bei uns abschliesst,
                <span v-if="isWCF()">schenken wir Dir die Einschreibgebühr in Höhe von CHF 99.-</span>
                <span v-else>schenken wir Dir die eine Preisreduktion in Höhe von CHF 100.-</span>
            </div>
            <div v-show="check_in_counter == 4 && checkTimer()" class="angebot">
                <strong>PROFITIERE JETZT</strong> von unserem unschlagbaren Angebot: Wenn Du innerhalb der angezeigten Zeit,
                ein Abonnement bei uns abschliesst,
                <span v-if="isWCF()">schenken wir Dir ein Zusatzpaket in Höhe von CHF 50.-</span>
                <span v-else>schenken wir Dir die eine Preisreduktion in Höhe von CHF 50.-</span>
            </div>
        </div>
        <h2 class="abo_anfrage_head" v-show="user.abo_anfrage">Herzlich willkommen in der {{ getArt() }} Community!</h2>
        <div v-show="user.abo_anfrage" class="abo_anfrage">
            Herzlich willkommen in der {{ getArt() }} Community und auf der Sonnenseite des Lebens. Wir freuen uns, dass Du
            Dich für uns entschieden hast. Dein Abonnement ist in Vorbereitung und liegt bei Deinem nächsten Besuch zur
            Unterschrift im Center bereit. Wir bedanken uns bei Dir für Dein Vertrauen, und wünschen Dir allzeit ein
            phantastisches Training.
            <br />
            Dein {{ getArt() }} Team
        </div>

        <vue-countdown
            :time="getAngebotCD()"
            v-slot="{ days, hours, minutes, seconds }"
            class="countdown cd"
            v-show="checkTimer()">
            <div class="tage">
                <span class="up">
                    {{ days }}
                </span>
                <span class="down">TAGE</span>
            </div>
            <div class="stunden">
                <span class="up">
                    {{ hours }}
                </span>
                <span class="down">STUNDEN</span>
            </div>
            <div class="minuten">
                <span class="up">
                    {{ minutes }}
                </span>
                <span class="down">MINUTEN</span>
            </div>
            <div class="sekunden">
                <span class="up">
                    {{ seconds }}
                </span>
                <span class="down">SEKUNDEN</span>
            </div>
        </vue-countdown>
        <v-row no-gutters>
            <v-col cols="12" class="pb-15 mb-15 mt-5">
                <div v-show="!user.abo_anfrage" @click="buyAbo()" class="buy">JETZT MITGLIED WERDEN</div>
            </v-col>
        </v-row>

        <!---  <div v-show="user.abo_anfrage" @click="cancelAbo()" class="buy">
      Abo Anfrage Beenden
    </div>---->
    </div>
</template>

<script>
import api from '../api'
import VueCountdown from '@chenfengyuan/vue-countdown'
import userService from '../services/userService'

export default {
    components: { VueCountdown },
    data() {
        return {
            userService,
            polling: null,
            tendays_id: null,
            tendays_date: null,
            user: {},
            showDay: false,
            angebot_countdown_duration: 5400000,
            check_in_counter: 0,
        }
    },
    beforeDestroy() {
        clearInterval(this.polling)
    },
    methods: {
        getHeader() {
            if (this.isWCF()) {
                return '/img/kampagne/membercard/membercard_roody.png'
            } else {
                return '/img/kampagne/membercard/membercard_roody_winti.png'
            }
        },
        isWCF() {
            let studioId = this.user.studio_id
            if (!studioId) return false
            if (studioId === 'winterthur' || studioId === 'pfungen') return false
            return true
        },
        getArt() {
            if (this.isWCF()) {
                return 'well come FIT'
            } else {
                return 'winti FIT'
            }
        },
        pollData() {
            this.polling = setInterval(() => {
                this.loadUser()
            }, 5000)
        },
        getAngebotCD() {
            if (this.checkTimer()) {
                return Math.round(this.user.last_login * 1000 + this.angebot_countdown_duration - new Date())
            } else {
                return 0
            }
        },
        checkTimer() {
            if (
                new Date() < this.user.last_login * 1000 + this.angebot_countdown_duration &&
                !this.user.abo_anfrage &&
                (this.check_in_counter == 2 || this.check_in_counter == 3 || this.check_in_counter == 4)
            ) {
                return true
            } else {
                return false
            }
        },
        getDay() {
            return Math.ceil(Math.round(new Date() / 1000 - this.user.tendays_date) / 86400)
        },
        loadUser() {
            api.getUser().then((data) => {
                if (data.user.tendays_date) {
                    let flag = true
                    if (Object.keys(data.user).length == Object.keys(this.user).length) {
                        for (let key in data.user) {
                            if (key == 'days') {
                                for (let key_days in data.user[key]) {
                                    if (data.user[key][key_days] == this.user[key][key_days]) {
                                        continue
                                    } else {
                                        flag = false
                                        break
                                    }
                                }
                            } else {
                                if (data.user[key] == this.user[key]) {
                                    continue
                                } else {
                                    flag = false
                                    break
                                }
                            }
                        }
                    } else {
                        flag = false
                    }
                    if (!flag) {
                        let now = new Date(data.user.tendays_date * 1000)
                        let end = now.setDate(now.getDate() + 10)
                        now = new Date()

                        this.tendays_date = end - now
                        this.tendays_id = data.user.tendays_id
                        this.user = data.user
                        this.check_in_counter = 0

                        for (let check_in in data.user.days) {
                            if (data.user.days[check_in]) {
                                this.check_in_counter++
                            }
                        }
                    }
                }
            })
        },
        cancelAbo() {
            api.td_cancelAbo()
                .then((data) => {
                    this.loadUser()
                })
                .catch((error) => {
                    alert('Es gab einen Fehler.. bitte kontaktieren Sie einen Trainer')
                })
        },
        buyAbo() {
            api.td_buyAbo()
                .then((data) => {
                    this.loadUser()
                })
                .catch((error) => {
                    alert('Es gab einen Fehler.. bitte kontaktieren Sie einen Trainer')
                })
        },
    },

    created() {
        this.loadUser()
        this.pollData()
    },
}
</script>

<style lang="scss" scoped>
.membercard {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 80px;
    min-height: calc(100vh - 90px);
    margin-bottom: 100px;

    .time_left {
        background-color: #ff9800;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        padding: 10px 0px 0px 0px;
        font-size: 13px;
        letter-spacing: 2px;
        text-align: center;
    }

    h2 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        letter-spacing: 2px;

        &.abo_anfrage_head {
            font-size: 26px;
            padding: 0px 15px;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }

    .abo_anfrage {
        padding: 0 15px;
    }

    .day {
        padding: 0px 15px;
    }

    .id {
        text-align: right;
    }

    .membercard-info {
        padding: 10px 15px;
    }

    .countdown {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: #ff9800;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding-top: 6px;
        justify-content: space-evenly;
        padding-bottom: 5px;

        &.cd {
            margin-top: auto;
            padding-top: 10px;
        }
        > div {
            margin: 5px 10px;
        }
        span.up {
            font-size: 25px;
            line-height: 20px;
            display: block;
        }
        span.down {
            font-size: 12px;
        }
    }
    .header {
        width: 100%;
        /* height: 200px; */
        object-fit: contain;
        max-height: 400px;
    }

    .buy {
        width: 100%;
        height: 80px;
        color: #fff;
        background-color: var(--v-primary);
        line-height: 80px;
        text-align: center;
        font-size: 23px;
        font-weight: bold;
        position: absolute;
        bottom: 0px;
        letter-spacing: 1.5px;
    }
}
</style>
