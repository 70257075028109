<template>
    <div>
        <v-btn icon @click="showSettingDialog = true">
            <v-icon icon color="white">mdi-cog</v-icon>
        </v-btn>
        <v-dialog v-model="showSettingDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="showSettingDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Erfolgsrechnungen</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="showSettingDialog = false">Speichern</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-tabs class="mt-5" centered v-model="tab">
                            <v-tab>Übersicht</v-tab>
                            <v-tab>Importieren</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-row no-gutters>
                                    <v-col align="center" cols="12">
                                        <v-card max-width="500px" flat>
                                            <v-menu
                                                ref="menu"
                                                v-model="monthPickerMenu"
                                                :close-on-content-click="false"
                                                :return-value.sync="monthDatePicker"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="monthDatePicker"
                                                        label="Monat"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="monthDatePicker"
                                                    type="month"
                                                    no-title
                                                    scrollable
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="monthPickerMenu = false">
                                                        Abbrechen
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.menu.save(monthDatePicker)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-card>
                                    </v-col>
                                    <v-col v-if="loadingIncomeStatements" align="center" cols="12">
                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                    </v-col>
                                    <v-col v-else align="center" cols="12">
                                        <v-card flat max-width="500px">
                                            <v-alert type="info" v-if="!incomeStatementData">
                                                Keine Erfolgsrechnungen gefunden
                                            </v-alert>

                                            <v-select
                                                v-if="incomeStatementData"
                                                :items="incomeStatementDataStudios"
                                                label="Studio"
                                                item-text="name"
                                                item-value="_id"
                                                v-model="incomeStatementDataSelect"
                                            ></v-select>
                                            <v-alert
                                                type="info"
                                                v-if="incomeStatementData && incomeStatementDataSelect === null"
                                            >
                                                Bitte wähle ein Studio aus
                                            </v-alert>
                                        </v-card>
                                        <figures-income-statement-data-view
                                            :hideButtons="true"
                                            v-if="incomeStatementDataSelect !== null"
                                            :data="incomeStatementData.studios[incomeStatementDataSelect]"
                                            :studioList="studios"
                                        ></figures-income-statement-data-view>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <figures-import-income-statement
                                    @importDone="tab = 0"
                                    :studioList="studios"
                                    :studio="studio"
                                ></figures-import-income-statement>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '@/api'
import { mapState, mapActions, mapGetters } from 'vuex'
import figuresImportIncomeStatement from './components/figuresImportIncomeStatement.vue'
import figuresIncomeStatementDataView from './components/figuresIncomeStatementDataView.vue'
export default {
    components: { figuresImportIncomeStatement, figuresIncomeStatementDataView },
    data() {
        return {
            incomeStatementDataSelect: null,
            monthPickerMenu: false,
            monthDatePicker: null,
            incomeStatementData: null,
            loadingIncomeStatements: true,
            showSettingDialog: false,
            studioFilter: ['Livestreams', 'Alibey'],
            tab: null,
            incomeStatementDataTab: null,
        }
    },
    props: {
        studio: {
            type: String,
        },
    },
    methods: {
        async fetchIncomeStatements() {
            try {
                this.loadingIncomeStatements = true
                let data = await api.fetchCompanyFiguresIncomeStatementByDate(this.monthDatePicker)
                this.incomeStatementData = data
                this.loadingIncomeStatements = false
                console.log(data, 'fetched Statements')
            } catch (e) {}
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        incomeStatementDataStudios() {
            if (!this.incomeStatementData || !this.incomeStatementData.studios) return []
            let studios = Object.keys(this.incomeStatementData.studios)
            let studioArray = []
            studios.forEach((e) => {
                let studio = this.studios.find((e1) => e1._id.toString() === e.toString())
                if (!studio) studioArray.push({ name: 'Studio Fehler', _id: null })
                else studioArray.push({ name: studio.name, _id: studio._id })
            })
            return studioArray
        },
        activeStudio() {
            let studio = this.studios.find((e) => e._id.toString() === this.studio.toString())
            if (!studio) return { name: '' }
            return studio
        },
        studios() {
            return this.storeStudios
                .filter((e) => e.hasOwnProperty('code') && !this.studioFilter.includes(e.name))
                .map((e) => {
                    return { name: e.name, _id: e._id, code: e.code }
                })
        },
    },
    created() {
        this.monthDatePicker = this.$moment().format('YYYY-MM')
    },
    watch: {
        monthDatePicker(newVal, oldVal) {
            this.incomeStatementDataSelect = null
            if (oldVal !== null) this.fetchIncomeStatements()
        },
        tab() {
            if (this.tab === 0) this.fetchIncomeStatements()
        },
    },
}
</script>