<template>
    <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
        <v-card-text style="overflow: auto">
            <v-select
                label="Allergien"
                chips
                v-model="allergensBackup"
                @change="updateAllergens"
                multiple
                :items="possibilities"
            >
                <template v-slot:selection="{ item }">
                    <v-chip color="grey">{{ item.text }}</v-chip>
                </template>
            </v-select>
            <v-select
                @change="updateDiet"
                label="Ernährungsweise"
                multiple
                v-model="dietBackup"
                :items="[
                    { text: 'vegan', value: 'vegan' },
                    { text: 'vegetarisch', value: 'vegetarian' },
                ]"
            >
                <template v-slot:selection="{ item }">
                    <v-chip color="grey">{{ item.text }}</v-chip>
                </template>
            </v-select>
        </v-card-text>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userSearch from '../../../components/userSearch.vue'

export default {
    props: {
        allergens: {},
        diet: {},
        possibilities: {},
        user: {},
    },
    data() {
        return {
            allergensBackup: [],
            dietBackup: [],
        }
    },
    methods: {
        async updateDiet() {
            try {
                let updated = await api.updateMealPlanDiet({
                    diet: this.dietBackup,
                    user: this.user._id,
                })
                this.$emit('updated', this.dietBackup)
                this.$toast.success('Ernährungsweise angepasst')
            } catch (e) {
                this.$toast.error(e)
            }
        },
        async updateAllergens() {
            console.log('testing')
            try {
                let updated = await api.updateMealPlanAllergens({
                    allergens: this.allergensBackup,
                    user: this.user._id,
                })
                this.$toast.success('Allergien wurden bearbeitet')
            } catch (e) {
                console.log('error', e)
                this.$toast.error(e)
            }
        },
    },
    created() {
        this.allergensBackup = JSON.parse(JSON.stringify(this.allergens))
        this.dietBackup = JSON.parse(JSON.stringify(this.diet))
    },
}
</script>
