<template>
    <div class="management">
        <div class="topics mb-15">
            <v-btn @click="createTopic = true">neuen Topic erstellen</v-btn>
            <div v-if="createTopic">
                <v-text-field filled label="Titel" v-model="newTopic.title"></v-text-field>
                <v-text-field filled label="description" v-model="newTopic.description"></v-text-field>
                <v-btn @click="createNewRightsTopic()">Speichern</v-btn>
            </div>
            <div v-for="topic of rightsAndTopics" :key="topic._id" class="topic d-flex flex-column mb-5">
                <v-card-title>{{ topic.title }}</v-card-title>
                <v-card-text>{{ topic.description }}</v-card-text>
                <v-btn @click="createRight(topic._id)" class="mr-auto mb-5" color="primary">+</v-btn>
                <v-expansion-panels>
                    <v-expansion-panel v-for="right of topic.rights" :key="right._id">
                        <v-expansion-panel-header>
                            <span v-if="right.title">{{ right.title }}</span>
                            <span v-else>Neues Recht</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field placeholder="Titel" v-model="right.title"></v-text-field
                            ><v-text-field placeholder="Kürzel" v-model="right.short"></v-text-field
                            ><v-text-field placeholder="Beschreibung" v-model="right.description"></v-text-field>
                            <v-card-actions
                                ><v-btn :loading="saving" color="primary" @click="saveRight(topic._id, right)"
                                    >Speichern</v-btn
                                ><v-spacer></v-spacer
                                ><v-btn :loading="deleting" color="red" class="white--text" @click="removeRight(right)"
                                    >Löschen</v-btn
                                ></v-card-actions
                            >
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../../api'

export default {
    data() {
        return {
            newTopic: { title: '', description: '' },
            createTopic: false,
            rightsAndTopics: [],
            saving: false,
            deleting: false,
        }
    },

    methods: {
        async removeRight(right) {
            if (right && !right._id) {
                this.rightsAndTopics = this.rightsAndTopics.filter(
                    (e, index) =>
                        (this.rightsAndTopics[index].rights = this.rightsAndTopics[index].rights.filter(
                            (f) => f.short !== right.short
                        ))
                )
            } else {
                this.deleting = true
                let status = await api.deleteRight(right)
                if (status === 'false') {
                    alert('enable this function first in backend >> prolly throws errors')
                }
                this.deleting = false
            }
        },
        async saveRight(topicId, right) {
            this.saving = true
            let status = await api.upsertRight({ topicId, right })
            this.saving = false
        },
        async createRight(topicId) {
            this.rightsAndTopics.forEach((e, index) => {
                if (e._id === topicId) this.rightsAndTopics[index].rights.push({ description: '', short: '', title: '' })
            })
        },
        async createNewRightsTopic() {
            let newItem = await api.createNewRightsTopic(this.newTopic)
            console.log(newItem)
            this.rightsAndTopics.push(newItem)
            this.createTopic = false
            this.newTopic = { title: '', description: '' }
        },
        async fetchRightsAndTopics() {
            let rightsAndTopics = await api.fetchRightsAndTopics()
            this.rightsAndTopics = rightsAndTopics
        },
    },
    updated() {},
    created() {
        this.fetchRightsAndTopics()
    },
}
</script>
<style lang="scss" scoped>
.intern {
    width: fit-content;
    margin: auto;
}

.back-navigation {
    background-color: var(--v-primary);
    color: white;
    padding: 0px 10px;
    font-weight: 500;
    display: flex;
    margin-bottom: 20px;

    span {
        margin-top: auto;
        margin-bottom: auto;
    }

    a {
        display: flex;
        padding: 5px;
        color: white;

        span {
            font-size: 34px;
        }
    }
}
</style>
