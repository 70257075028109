<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bewerte den Kurs</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div v-show="kurs.course_bild" class="kurs-bild">
            <img :src="'/img/kurse/' + kurs.course_bild" />
          </div>
          <div class="kurs-zeit">
            <strong>Zeit:</strong> {{ TimestampToTime(kurs.timestamp) }} -
            {{ TimestampToTime(kurs.timestamp_end) }} Uhr
          </div>
          <div class="kurs-name">
            <strong>Kursname</strong> {{ kurs.course_name }}
          </div>
          <div class="kurs-instruktor">
            <strong>Instruktor:</strong> {{ kurs.instructor_name }}
          </div>
          <div class="kurs-raum">
            <strong>Raum:</strong> {{ kurs.room_name }}
          </div>

          <label v-show="!rated" class="title">Deine Bewertung</label>

          <span v-show="rated" class="after-rating"
            >Wir hören nie auf besser zu werden! Vielen Dank für Dein
            Feedback.</span
          >
          <div v-show="!rated" class="rating" role="optgroup">
            <!-- in Rails just use 1.upto(5) -->
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-1"
              data-rating="1"
              tabindex="0"
              aria-label="Rate as one out of 5 stars"
              role="radio"
            ></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-2"
              data-rating="2"
              tabindex="0"
              aria-label="Rate as two out of 5 stars"
              role="radio"
            ></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-3"
              data-rating="3"
              tabindex="0"
              aria-label="Rate as three out of 5 stars"
              role="radio"
            ></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-4"
              data-rating="4"
              tabindex="0"
              aria-label="Rate as four out of 5 stars"
              role="radio"
            ></i>
            <i
              class="fa fa-star-o fa-2x rating-star"
              id="rating-5"
              data-rating="5"
              tabindex="0"
              aria-label="Rate as five out of 5 stars"
              role="radio"
            ></i>
          </div>
          <textarea
            v-show="!rated"
            v-model="rating_text"
            placeholder="Teile uns hier deine Bewertung für den Kurs mit..."
            rows="4"
          ></textarea>
        </div>
        <div class="modal-footer">
          <button
            v-show="!rated"
            type="button"
            class="btn btn-primary"
            @click.prevent="save"
          >
            Senden
          </button>
          <button
            v-show="!rated"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.prevent="hide"
          >
            Nicht jetzt
          </button>
          <button
            v-show="rated"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.prevent="hide"
          >
            Zurück zum Kursplan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import api from "../api";

export default {
  data() {
    return {
      rated: false,
      error: "",
      kurs: {},
      rating_amount: 0,
      rating_text: "",
    };
  },

  methods: {
    TimestampToTime(timestamp) {
      timestamp = parseInt(timestamp);

      let hours = new Date(timestamp * 1000).getHours();

      let minutes = new Date(timestamp * 1000).getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let value = hours + ":" + minutes;
      return value;
    },
    setRating(rating) {
      this.rating_amount = rating;

      // fill all the stars assigning the '.selected' class
      $(".rating-star")
        .removeClass("fa-star-o")
        .addClass("selected");
      // empty all the stars to the right of the mouse
      $(".rating-star#rating-" + rating + " ~ .rating-star")
        .removeClass("selected")
        .addClass("fa-star-o");
    },
    show(kurs) {
      this.rating_amount = 0;
      this.rating_text = "";
      var ref = this;
      this.kurs = kurs;
      $(".rating-star")
        .removeClass("selected")
        .addClass("fa-star-o");
      api
        .checkRatingStatus(kurs._id)
        .then((data) => {
          this.rated = data.status;
          $(this.$refs["modal"]).modal("show");
          $(".rating-star")
            .on("mouseover", function(e) {
              var rating = $(e.target).data("rating");

              // fill all the stars
              $(".rating-star")
                .removeClass("fa-star-o")
                .addClass("fa-star")
                .removeClass("selected");
              // empty all the stars to the right of the mouse
              $(".rating-star#rating-" + rating + " ~ .rating-star")
                .removeClass("fa-star")
                .addClass("fa-star-o");
            })
            .on("mouseleave", function(e) {
              // empty all the stars except those with class .selected
              $(".rating-star")
                .removeClass("fa-star")
                .addClass("fa-star-o");
            })
            .on("click", function(e) {
              var rating = $(e.target).data("rating");
              ref.setRating(rating);
            })
            .on("keyup", function(e) {
              // if spacebar is pressed while selecting a star
              if (e.keyCode === 32) {
                // set rating (same as clicking on the star)
                var rating = $(e.target).data("rating");
                ref.setRating(rating);
              }
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      if (this.rating_amount == 0 && this.rating_text == "") {
        alert(
          "Bitte wähle die Anzahl Sterne aus oder hinterlasse uns eine Nachricht.. Vielen Dank!"
        );
      } else {
        api
          .newRating({
            stars: this.rating_amount,
            rating: this.rating_text,
            course_timeline_generated_id: this.kurs._id,
          })
          .then((data) => {
            if (data.status) {
              this.rated = true;
            }
          })
          .catch((error) => {
            alert("Es gab einen Fehler, bitte versuche es später erneut...");
          });
      }
    },
    hide() {
      $(this.$refs["modal"]).modal("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.after-rating {
  margin-top: 20px;
  display: flex;
}
.rating {
  cursor: pointer;
  i {
    padding: 10px;

    &:focus {
      outline: 0px transparent !important;
    }
  }
}

label.title {
  margin-left: 5px;
  margin-top: 17px;
}

.rating .rating-star {
  display: inline-block;
  position: relative;
  padding-right: 5px;
  margin-left: -5px;
  color: #e3cf7a;
}
.rating .selected:before {
  content: "\f005";
}
.kurs {
  &-bild img {
    max-width: 400px;
    height: 150px;
    max-height: 22vh;

    object-fit: contain;
    width: 100%;
    margin: auto;
  }

  &-zeit {
    margin-top: 15px;
  }
}

.modal-body {
  overflow: scroll;

  textarea {
    background-color: inherit;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
  }
}

.close {
  text-align: right;
}
button {
  width: 100%;
  margin-bottom: 10px;
}
.modal-title {
  width: 320px;
}
.choose-label {
  width: 100%;
  padding: 10px;
}
.label-editor {
  display: flex;
  flex-direction: column;
  input,
  textarea {
    padding: 10px;
    margin-bottom: 10px;
  }
  input[type="color"] {
    padding: 0px 10px;
  }
  label {
    padding: 0px 10px;
  }
}
.modal-content {
  height: 90vh;
}
</style>
