<template>
    <div>
        <v-card max-width="500px" class="mx-auto" dark>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card-title> Kurslisten herunterladen (Excel-Datei) </v-card-title>
                </v-col>
                <v-col sm="10" cols="12">
                    <v-select
                        class="d-flex my-auto mx-4"
                        v-model="studioSelection"
                        :items="studios"
                        item-text="name"
                        item-value="code"
                        label="Studio"
                        outlined></v-select>
                </v-col>
                <v-col class="d-flex justify-center" sm="2" cols="12"
                    ><v-btn class="ma-2" @click="downloadList" color="primary"><v-icon>mdi-download</v-icon></v-btn></v-col
                >
            </v-row>
        </v-card>
    </div>
</template> 

<script>
import api from '../../../api'

export default {
    data() {
        return {
            studios: [],
            studioSelection: null,
        }
    },
    async created() {
        this.fetchStudios()
        this.$emit('finishedloading')
    },
    methods: {
        async downloadList() {
            if (!this.studioSelection) {
                this.$toast.error('Bitte wähle ein Studio aus')
                return
            }
            this.$toast.success('Kursliste wird heruntergeladen...')
            let status = await api.downloadWeeklyExcel(this.studioSelection)
        },
        async fetchStudios() {
            let skipStudioCodes = ['alibey', 'ot', 'stgallenwest', 'test']
            try {
                let response = await api.fetchStudios()
                response = response.filter((s) => !skipStudioCodes.some((e) => s.code.includes(e)))
                this.studios = response
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
