<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-data-table
                no-data-text="Keine Daten verfügbar"
                disable-pagination
                :headers="headers"
                :items="flattenDataAllLevels(data)"
                item-key="accountNumber"
            >
                <template v-slot:item.account="{ item }">
                    <div
                        :style="{
                            paddingLeft: `${item.level * 20}px`,
                            'font-size': item.level === 0 ? '1.2rem' : '1rem',
                            'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                        }"
                    >
                        {{ item.accountNumber }} {{ item.accountName }}
                    </div>
                </template>
                <template v-slot:item.balance="{ item }">
                    <span
                        :style="{
                            'font-size': item.level === 0 ? '1.2rem' : '1rem',
                            'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                        }"
                    >
                        {{ displayCurrency(item.balance) }}
                    </span>
                </template>
                <template v-slot:item.type="{ item }">
                    <span
                        :style="{
                            'font-size': item.level === 0 ? '1.2rem' : '1rem',
                            'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                        }"
                    >
                        {{ item.type }}
                    </span>
                </template>
            </v-data-table>
        </v-col>
        <v-col v-if="!hideButtons" cols="6" align="end">
            <v-btn class="my-5 mx-2" text @click="$emit('startOver')">Neu starten</v-btn>
        </v-col>
        <v-col v-if="!hideButtons" cols="6" align="start">
            <v-btn class="my-5 mx-2" color="primary" @click="submitImport">Import abschliessen</v-btn>
        </v-col>
    </v-row>
</template>
<script>
import { formatCurrency } from '@/moduls/currency/currency.js'
export default {
    props: {
        data: {
            type: Array,
        },
        studioList: {
            type: Array,
        },
        hideButtons: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            headers: [
                { text: 'Konto', value: 'account' },
                { text: 'Saldo', value: 'balance' },
                { text: 'Typ', value: 'type' },
            ],
        }
    },
    methods: {
        submitImport() {
            this.$emit('submitImport')
        },
        displayCurrency(amount) {
            return formatCurrency(amount, 0)
        },
        flattenDataAllLevels(accounts, level = 0) {
            let result = []
            accounts.forEach((account) => {
                // Nur bis zur zweiten Ebene einschließen
                result.push({ ...account, level })

                if (account.children) {
                    result = result.concat(this.flattenDataAllLevels(account.children, level + 1))
                }
            })
            return result
        },
    },
}
</script>
<style lang="scss" scoped>
</style>