<template>
	<div class="zeitgutschrift">
		<div class="titel">roody | Zeitgutschrift</div>

		<div class="zeitgutschrift">
			<img class="zeitgutschrift-logo" src="https://app.wellcomefit.ch/img/einmalig/thankyou.jpeg" />

			<div class="zeitgutschrift-titel">ZEITGUTSCHRIFT COVID-19</div>
			<div class="zeitgutschrift-text" v-if="wellcomefit" v-show="!success">
				Liebes well come FIT Mitglied. <br />Das well come FIT bietet Dir für den Zeitraum der Schliessung nachfolgend aufgeführte
				Zeitgutschrift-Modelle an:
			</div>
			<div class="zeitgutschrift-text" v-else v-show="!success">
				Liebes winti FIT Mitglied. <br />Das winti FIT bietet Dir für den Zeitraum der Schliessung nachfolgend aufgeführte
				Zeitgutschrift-Modelle an:
			</div>

			<div v-show="showForm && !success" class="zeitgutschrift-form">
				<div class="zeitgutschrift-wrapper">
					<label>Vorname</label>
					<input type="text" placeholder="Dein Vorname" v-model="formData.vorname" />
				</div>
				<div class="zeitgutschrift-wrapper">
					<label>Nachname</label>
					<input type="text" placeholder="Dein Nachname" v-model="formData.nachname" />
				</div>
				<div class="zeitgutschrift-wrapper">
					<label>E-Mail</label>
					<input type="email" placeholder="Deine E-Mail" v-model="formData.email" />
				</div>
				<div class="zeitgutschrift-wrapper">
					<label>Fitnesszenter</label>
					<select v-model="formData.studio" v-if="wellcomefit">
						<option value="" disabled>Studio auswählen...</option>
						<option value="5f326a24ae8863640c859e75">Frauenfeld</option>
						<option value="5f3c1241d91ddb464c2648b0">Netstal</option>
						<option value="5f3c30afd91ddb464c2648b1">Bülach</option>
						<option value="5f3c30bfd91ddb464c2648b2">St. Gallen West</option>
						<option value="5f3c30d0d91ddb464c2648b3">St. Gallen Ost</option>
						<option value="5f3c30e1d91ddb464c2648b4">St. Gallen Einstein</option>
						<option value="5f3c30f2d91ddb464c2648b5">Dietlikon</option>
					</select>
					<select v-else v-model="formData.studio">
						<option value="" disabled>Studio auswählen...</option>
						<option value="5f3d482d5dd7955ad48de5e4">Winterthur</option>
						<option value="5f3d48195dd7955ad48de5e3">Pfungen</option>
					</select>
				</div>
				<div v-if="wellcomefit" class="zeitgutschrift-wrapper">
					<label>Zeitgutschrift</label>
					<div class="radio-wrapper">
						<input
							@click="formData.zeitgutschrift = 1"
							:checked="formData.zeitgutschrift == 1"
							id="rw_1"
							name="zeitgutschrift"
							type="radio"
						/><label @click="formData.zeitgutschrift = 1" for="rw_1"> SOLIDARITÄTS-ANGEBOT: Ich verzichte auf die Zeitgutschrift.</label>
					</div>
					<div class="radio-wrapper">
						<input @click="formData.zeitgutschrift = 2" id="rw_2" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 2"
							for="rw_2"
						>
							HALBE-HALBE: Ich möchte nur die Hälfte der behördlich verordneten Schliessung als Zeitgutschrift.</label
						>
					</div>
					<div class="radio-wrapper">
						<input @click="formData.zeitgutschrift = 3" id="rw_3" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 3"
							for="rw_3"
						>
							ALLES-ALLES: Ich möchte die vollständige Zeitgutschrift.</label
						>
					</div>
					<div class="radio-wrapper">
						<input @click="formData.zeitgutschrift = 4" id="rw_4" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 4"
							for="rw_4"
						>
							GESCHENKT: Ich übertrage meine Zeitgutschrift an eine Person meiner Wahl, die noch nicht im well come FIT trainiert. Das
							well come FIT legt einen drauf und lässt die Person während dieses Zeitraums mit allen Zusatzpaketen trainieren.</label
						>
					</div>
					<div class="radio-wrapper">
						<input @click="formData.zeitgutschrift = 5" id="rw_5" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 5"
							for="rw_5"
						>
							STAND BY ME: Ich verzichte auf die Zeitgutschrift und verlängere grad mein Abonnement. Bitte lasst mir die Rechnung
							zukommen.
						</label>
					</div>
				</div>
				<div v-else class="zeitgutschrift-wrapper">
					<label>Zeitgutschrift</label>
					<div class="radio-wrapper">
						<input
							@click="formData.zeitgutschrift = 6"
							:checked="formData.zeitgutschrift == 1"
							id="rw_6"
							name="zeitgutschrift"
							type="radio"
						/><label for="rw_6" @click="formData.zeitgutschrift = 6"> SOLIDARITÄTS-ANGEBOT: Ich verzichte auf die Zeitgutschrift.</label>
					</div>
					<div class="radio-wrapper">
						<input id="rw_7" @click="formData.zeitgutschrift = 7" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 7"
							for="rw_7"
						>
							HALBE-HALBE: Ich möchte nur die Hälfte des Zeitraums als Zeitgutschrift.</label
						>
					</div>
					<div class="radio-wrapper">
						<input id="rw_8" name="zeitgutschrift" type="radio" @click="formData.zeitgutschrift = 8" /><label
							@click="formData.zeitgutschrift = 8"
							for="rw_8"
						>
							ALLES-ALLES: Ich möchte die vollständige Zeitgutschrift.</label
						>
					</div>
					<div class="radio-wrapper">
						<input @click="formData.zeitgutschrift = 9" id="rw_9" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 9"
							for="rw_9"
						>
							GESCHENKT: Ich übertrage meine Zeitgutschrift an eine Person meiner Wahl, die noch nicht im winti FIT trainiert.
						</label>
					</div>
					<div class="radio-wrapper">
						<input @click="formData.zeitgutschrift = 10" id="rw_10" name="zeitgutschrift" type="radio" /><label
							@click="formData.zeitgutschrift = 10"
							for="rw_10"
						>
							STAND BY ME: Ich verzichte auf die Zeitgutschrift und verlängere grad mein Abonnement. Bitte lasst mir die Rechnung
							zukommen.
						</label>
					</div>
				</div>
			</div>

			<div
				class="danke"
				v-show="
					(formData.zeitgutschrift == 1 ||
						formData.zeitgutschrift == 2 ||
						formData.zeitgutschrift == 5 ||
						formData.zeitgutschrift == 6 ||
						formData.zeitgutschrift == 7 ||
						formData.zeitgutschrift == 10) &&
						success
				"
			>
				An dieser Stelle möchten wir uns auch im Namen aller unserer Mitarbeitenden bei Dir bedanken. <br />Wir werden im 2021 alles dran
				setzen, um Dich mit unserer Leistung und unserem Angebot zu begeistern.
			</div>

			<div class="error-zeitgutschrift" v-show="fehlermeldung && !success">
				{{ fehlermeldung }}
			</div>
			<div class="success-zeitgutschrift" v-show="success">
				Die Zeitgutschrift wurde erfolgreich erfasst
			</div>
			<div class="absenden" @click="submitForm()" v-show="!success">
				Absenden
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'

export default {
	data() {
		return {
			wellcomefit: true,
			formData: {
				vorname: '',
				nachname: '',
				email: '',
				studio: '',
				zeitgutschrift: 1,
			},
			standort: null,
			success: false,
			fehlermeldung: null,
			showForm: true,
		}
	},
	created() {
		if (!location.host.includes('wellcomefit')) {
			this.wellcomefit = false
		}
	},
	methods: {
		submitForm() {
			if (
				!this.showForm ||
				(this.formData.vorname.length > 2 &&
					this.formData.nachname.length > 2 &&
					this.formData.email.includes('@') &&
					this.formData.email.length > 3 &&
					this.formData.studio !== '')
			) {
				let fd = this.formData
				if (localStorage.getItem('user')) {
					fd.user_id = JSON.parse(localStorage.getItem('user'))._id
				}

				api.submitZeitgutschrift({ fd: fd })
					.then((data) => {
						this.fehlermeldung = ''
						if (data.status == 0) {
							this.fehlermeldung = 'Bitte überprüfen Sie die eingegebenen Daten'
						} else if (data.status == 1) {
							this.fehlermeldung = ''
							this.success = true
						} else if (data.status == 3) {
							this.fehlermeldung = 'Diese E-Mail Adresse hat bereits eine Zeitgutschrift eingelöst'
							this.success = true
						}
					})
					.catch((error) => {
						this.fehlermeldung = 'Es gab einen Fehler... Bitte versuchen Sie es später erneut.'
					})
			} else {
				this.fehlermeldung = 'Bitte füllen Sie alle Daten aus'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.danke {
	padding: 10px 15px;
	text-align: center;
	background-color: var(--v-primary);
	color: #fff;
	margin: 10px 0px;
}
.radio-wrapper {
	position: relative;
	margin-bottom: 15px;
	input {
		position: absolute;
		left: 0px;
		width: 20px;
		height: 20px;
		top: calc(50% - 14px);
	}
	label {
		font-weight: normal !important;
		margin-left: 40px;
		display: block;
		line-height: 20px;
	}
}
.error-zeitgutschrift {
	max-width: 700px;
	margin: auto;
	padding: 10px 15px;
	text-align: center;
	color: red;
}
.success-zeitgutschrift {
	max-width: 700px;
	margin: auto;
	padding: 10px 15px;
	text-align: center;
	color: var(--v-primary);
}
.zeitgutschrift {
	.titel {
		width: 100%;
		background-color: var(--v-primary);
		padding: 5px 10px;
		color: white;
	}

	.zeitgutschrift {
		max-width: 700px;
		margin: auto;

		.form-check {
			width: 50%;
			padding: 0px;
		}

		.form-check-label {
			cursor: pointer;
			font-size: 16px;
			font-weight: 400;
			color: #676767;
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			padding: 0 15px;
			height: 42px;
			border: none;
			line-height: 1.86;
			letter-spacing: 0.35px;
			box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
			background: #f5f5f5;
			width: 100%;
		}

		.gender-input:checked ~ label {
			background-color: var(--v-primary);
			color: white;
		}

		&-logo {
			width: 240px;
			margin: 20px auto 15px;
			display: flex;
		}

		select {
			padding: 10px 15px;
		}

		&-header {
			width: 100%;
			max-height: 300px;
			object-fit: contain;
		}

		&-titel,
		&-text,
		&-form-wrapper {
			padding: 5px 15px;
		}
		&-titel {
			text-align: center;
			font-weight: bold;
			font-size: 16px;
			max-width: 390px;
			margin: 15px auto 5px auto;
		}
		&-text {
			max-width: 390px;
			text-align: center;
			margin: auto;
		}

		&-form {
			max-width: 700px;
			margin: auto;
			padding: 10px 15px;

			input:not([type='radio']) {
				padding: 0 15px;
				width: 100%;
				height: 42px;
				border: none;
				box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);

				font-size: 16px;
				background-color: rgb(245, 245, 245);
				margin-bottom: 15px;
				background: #f5f5f5 !important;
			}
			label {
				font-weight: bold;
				font-size: 14px;
			}
			.zeitgutschrift-wrapper {
				display: flex;
				flex-direction: column;
			}
		}

		.absenden {
			background-color: var(--v-primary);
			color: white;
			margin: 30px auto 170px auto;
			padding: 10px;
			display: block;
			width: 300px;
			text-align: center;
			font-weight: bold;
		}
	}
}
</style>
