<template>
  <div class="kinderhort">
    <v-tabs
      dark
      background-color="transparent"
      color="primary"
      class="darkbackground"
      centered
      v-model="tab"
    >
      <v-tabs-slider color="white" class="darkbackground"></v-tabs-slider>

      <v-tab class="darkbackground" v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="darkbackground" v-model="tab">
      <v-tab-item class="mb-15 mt-5 darkbackground">
        <kinderhort-statistik></kinderhort-statistik>
      </v-tab-item>
      <v-tab-item class="darkbackground">
        <v-card flat>
          <v-card-title
            class="d-flex justify-center mb-3 mt-5 h3 font-weight-bold"
          >
            Öffnungszeiten
          </v-card-title>
          <v-text-field
            class="mx-5"
            v-model="notes"
            label="Notiz"
          ></v-text-field>
          <v-switch
            v-model="active"
            :label="active ? 'Aktiviert' : 'Deaktiviert'"
          ></v-switch>
          <business-hours
            v-if="days"
            :switchWidth="120"
            :hourFormat24="true"
            :localization="businessHoursGerman"
            :days="days"
          ></business-hours>
          <v-row class="justfiy-center">
            <v-col cols="12" class="text-center">
              <v-btn
                @click="saveDays()"
                color="primary"
                class="white--text align-self-center"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import api from "../../../api";
import $ from "jquery";
import businessHoursGerman from "../../../assets/businessHoursGerman.json";
import KinderhortStatistik from "./studioDashboardDaycareStatistic.vue";
export default {
  components: { KinderhortStatistik },
  data() {
    return {
      tab: null,
      items: ["anmeldungen", "öffnungszeiten"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      businessHoursGerman: businessHoursGerman,
      days: null,
      active: false,
      notes: "",
    };
  },
  created() {
    this.fetchKinderhortHours();
  },
  methods: {
    async fetchKinderhortHours() {
      let days = await api
        .fetchBusinessHours("kinderhort")
        .catch((e) => console.log(e));
      if (days) {
        this.active = days.active;
        this.days = days.days;
        if (days.notes) {
          this.notes = days.notes;
        }
      } else {
        this.initDays();
      }
      this.$emit("finishedloading");
    },

    initDays() {
      this.days = {
        monday: [
          {
            open: "",
            close: "",
            id: "1",
            isOpen: false,
          },
        ],
        tuesday: [
          {
            open: "",
            close: "",
            id: "2",
            isOpen: false,
          },
        ],
        wednesday: [
          {
            open: "",
            close: "",
            id: "3",
            isOpen: false,
          },
        ],
        thursday: [
          {
            open: "",
            close: "",
            id: "4",
            isOpen: false,
          },
        ],
        friday: [
          {
            open: "",
            close: "",
            id: "5",
            isOpen: false,
          },
        ],
        saturday: [
          {
            open: "",
            close: "",
            id: "6",
            isOpen: false,
          },
        ],
        sunday: [
          {
            open: "",
            close: "",
            id: "0",
            isOpen: false,
          },
        ],
      };
    },
    async saveDays() {
      let businessHours = {
        type: "kinderhort",
        days: this.days,
        active: this.active,
        notes: this.notes,
      };
      let status = await api
        .updateBusinessHours(businessHours)
        .catch((e) => console.log(e));
      if (status) {
        this.$toast.success("Erfolgreich gespeichert");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
