<template>
    <v-autocomplete
        outlined
        :loading="loading"
        v-show="userService.hasRight(rights) || ignoreRights"
        v-model="selection"
        :label="label"
        :items="courses"
        color="primary"
        prepend-icon="mdi-magnify"
        return-object
        @change="update"
        :rules="[(v) => !!v || 'Bitte wählen Sie einen Kurs aus']"
        :item-text="getItemText"
        :search-input.sync="search"
        cache-items
    ></v-autocomplete>
</template>

<script>
import api from '../api'
import userService from '../services/userService'

export default {
    name: 'userSearchApi',

    props: {
        value: {
            default: null,
        },

        label: {
            type: String,
            default: 'Titel',
        },

        rights: {
            type: String,
            default: '',
        },
        ignoreRights: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userService,
            courses: [],
            selection: null,
            search: null,
            loading: false,
            timerId: null,
        }
    },
    watch: {
        search(val) {
            if (!val) {
                return
            }

            this.fetchCoursesDebounced(val)
        },
    },
    created() {
        this.ignoreRights && this.rights === ''
        this.courses.push(this.value)
        this.selection = this.value
        this.loading = false
    },
    methods: {
        fetchCoursesDebounced(val) {
            // cancel pending call
            this.loading = true
            clearTimeout(this.timerId)

            // delay new call 500ms
            this.timerId = setTimeout(() => {
                this.fetchCourses(val)
            }, 500)
        },

        fetchCourses(val) {
            api.lookupCourses(val).then((data) => {
                this.courses = data
                this.loading = false
            })
        },
        update(item) {
            this.$emit('update', this.selection, this.value)
        },
        getItemText(course) {
            return course.name
        },
    },
    computed: {
        itemValue() {
            return '_id'
        },
    },
}
</script>
