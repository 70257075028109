<template>
    <v-card class="mt-3 mb-10" v-if="isActive" elevation="0" @click="dialog = true">
        <v-card-title>Mitgliedskarte</v-card-title>
        <v-card-subtitle>{{ countdown }}</v-card-subtitle>

        <!-- Fortschrittsleiste -->
        <v-progress-linear stream :value="progress"></v-progress-linear>

        <!-- V-Dialog -->
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title style="background-color: var(--v-primary)" class="white--text justify-center">
                    Details
                </v-card-title>
                <v-img src="/img/einmalig/tendaysImage.jpg"></v-img>
                <v-card-subtitle class="mt-5 mb-0 pb-0">
                    {{ firstName }}
                    <br />
                    {{ email }}
                </v-card-subtitle>
                <v-card-subtitle class="mt-2 mb-0 pb-2 mt-0 pt-0">Gültigkeit: {{ countdown }}</v-card-subtitle>
                <v-switch
                    v-show="false"
                    class="mx-5"
                    :loading="fetchingNewsletter"
                    :disabled="fetchingNewsletter"
                    v-model="newsletterEnabled"
                    label="10-Tage Begleitmails"
                    color="primary"
                ></v-switch>
                <v-card-text>
                    Dies ist Dein Ticket für die Reise auf die Sonnenseite Deines Lebens mit uns. Die Membercard
                    berechtigt Dich unser
                    <strong>
                        Fitness- und Groupfitness-Angebot während des oben ausgewiesenen Zeitraums zu den betreuten
                        Öffnungszeiten zu nutzen.
                    </strong>
                    <br />
                    <br />
                    Bitte jeweils die Membercard vor Trainingsbeginn an der Theke vorzeigen. Wir wünschen Dir ein
                    phantastisches Training
                    <br />
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                    <v-btn color="primary" text @click="dialog = false">Schliessen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
    name: 'membercard',
    data() {
        return {
            doneFetching: false,
            fetchingNewsletter: true,
            newsletterEnabled: false,
            dialog: false,
            membercardSample: {
                // Beispiel Daten
            },
            countdown: '',
        }
    },
    props: {
        membercard: {
            type: Object,
            required: true,
        },
        firstName: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
    },
    created() {
        if (this.isActive) this.$emit('hideTraining')
        this.calculateCountdown()
        setInterval(this.calculateCountdown, 1000) // Countdown alle 1 Sekunde aktualisieren
    },
    computed: {
        isActive() {
            return this.membercard && this.membercard.isActive && moment().isBefore(moment(this.membercard.endDate))
        },
        // Fortschrittsbalken berechnen
        progress() {
            const now = moment()
            const start = moment(this.membercard.startDate)
            const end = moment(this.membercard.endDate)
            const total = end.diff(start)
            const elapsed = now.diff(start)

            return (elapsed / total) * 100
        },
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility']),
        async fetchNewsletter() {
            this.fetchingNewsletter = true
            try {
                let status = await api.fetchMembercardNewsletterInfo()
                if (status.status === 'subscribed') {
                    this.newsletterEnabled = true
                    this.fetchingNewsletter = false
                    this.doneFetching = true
                } else if (status.status === 'unsubscribed') {
                    this.newsletterEnabled = false
                    this.fetchingNewsletter = false
                    this.doneFetching = true
                } else {
                    this.newsletterEnabled = false
                    this.fetchingNewsletter = false
                    this.doneFetching = true
                }
            } catch (e) {
                console.log('ERROR')
                this.newsletterEnabled = false
                this.fetchingNewsletter = false
                this.doneFetching = true
            }
        },
        calculateCountdown() {
            const now = moment()
            const end = moment(this.membercard.endDate)
            const duration = moment.duration(end.diff(now))

            this.countdown = `${duration.days()} Tage ${duration.hours()} Stunden ${duration.minutes()} Minuten ${duration.seconds()} Sekunden`
        },
    },
    watch: {
        newsletterEnabled: async function (val) {
            return false
            console.log(this.fetchingNewsletter, this.doneFetching)
            if (!this.fetchingNewsletter && this.doneFetching) {
                if (val) {
                    if (
                        confirm(
                            'Deine Daten (Vorname, Nachname, E-Mail) werden an Mailchimp übertragen. Bist Du damit einverstanden?'
                        )
                    ) {
                        this.fetchingNewsletter = true
                        await api.subscribeMembercardNewsletter()
                        this.fetchingNewsletter = false
                        this.$toast.success('Du hast Dich erfolgreich angemeldet')
                    } else {
                        this.newsletterEnabled = false
                        this.$toast.error('Du hast Dich nicht angemeldet')
                    }
                } else {
                    try {
                        this.fetchingNewsletter = true
                        await api.unsubscribeMembercardNewsletter()
                        this.fetchingNewsletter = false
                        this.$toast.success('Du hast Dich erfolgreich abgemeldet')
                    } catch (e) {
                        this.$toast.error(
                            'Es ist ein Fehler aufgetreten. Versuche es später erneut oder melde dich im Footer einer Begleitmail ab.'
                        )
                    }
                }
            }
        },
        dialog(val) {
            this.doneFetching = false
            if (val) this.fetchingNewsletter = true
            if (val) this.fetchNewsletter()
            this.setNavigationbarVisibility(!val)
        },
    },
}
</script>
