<template>
	<div class="ebt">
		<img class="gs_image" :src="'https://app.wellcomefit.ch/img/gs/done/' + user.gs_image" />
		<h2>Dein erstes bestes Training</h2>
		<span>Du kannst dieses Bild herunterladen und so auch später in Erinnerungen an Dein ERSTES BESTES TRAINING nach dem Lockdown schwärmen.</span
		><br />
		<span>
			Du kannst dieses Ereignis auch mit Deinen Freunden und Bekannten teilen und sie zu einem Training in einem {{ getArt() }} Center einladen.
			Deine Kontakte können dann 10 Tage kostenlos, inkl. persönlicher Betreuung bei uns trainieren. Sollten sie sich für ein Abonnement bei uns
			entschliessen, erhältst Du als Dankeschön bis zu CHF 50.- bar auf die Hand.
		</span>
		<div class="aff-link">
			<div class="share-it">
				Teile dein Erstes Bestes Training jetzt über:
				<br />
				<div class="link-wrapper">
					<a target="__blank" :href="whatsapplink">
						<img class="share-icon" src="/img/social/whatsapp.svg" />
					</a>
					<a target="__blank" :href="facebooklink">
						<img class="share-icon" src="/img/social/facebook.svg" />
					</a>
					<a target="__blank" :href="linkedinlink">
						<img class="share-icon" src="/img/social/linkedin.svg" />
					</a>
					<a target="__blank" :href="telegramlink">
						<img class="share-icon" src="/img/social/telegram.svg" />
					</a>
					<a target="__blank" :href="maillink">
						<img class="share-icon" src="/img/social/gmail.svg" />
					</a>
				</div>
			</div>
			<!---Dein persönlicher Weiterempfehlungs-Link lautet:
      <br />
      <input id="myreflink" class="affiliate-gen-link" v-model="inputlink" />
      <div
        v-if="!copied"
        class="affiliate-gen-link-copy"
        @click.prevent="copylink"
      >
        Link kopieren!
      </div>
      <div v-else class="affiliate-gen-link-copy" @click.prevent="copylink">
        Link wurde kopiert!
      </div>
      ---->
		</div>

		<div @click="download" class="herunterladen">
			Bild herunterladen
		</div>
		<router-link to="/" tag="div" class="skip">Zur Startseite</router-link>
	</div>
</template>
<script>
import api from '../../api'
import { saveAs } from 'file-saver'
export default {
	data() {
		return {
			user: {
				gs_image: '#',
			},

			hostname: '',
			email: '',
			whatsapplink: '',
			facebooklink: '',
			telegramlink: '',
			linkedinlink: '',
			maillink: '',
			inputlink: '',
			copied: false,
			iswellcomefit: true,
		}
	},
	methods: {
		getArt() {
			if (location.host.includes('local') || location.host.includes('wellcomefit')) {
				return 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				return 'winti FIT'
			}
			if (location.host.includes('fzw')) {
				return 'Fitness Zenter Wetzikon'
			}
		},
		download() {
			saveAs('/img/gs/done/' + this.user.gs_image, 'Erstes_Bestes_Training.png')
		},
	},
	created() {
		api.getUser().then((data) => {
			this.user = data.user
			this.email = data.user.email
			this.hostname = location.host
			let text = ''
			if (location.host.includes('wellcomefit') || location.host.includes('localhost')) {
				this.iswellcomefit = 1
				text =
					'Hey%20Du%2C%20ich%20war%20gerade%20beim%20Training%20im%20well%20come%20FIT.%20Macht%20voll%20Spass%20H%C3%A4ttest%20Du%20Lust%20das%20Training%20auch%20mal%20zu%20probieren%3F%20Es%20macht%20eben%20nicht%20nur%20fit%20sondern%20sorgt%20auch%20f%C3%BCr%20ziemlich%20viel%20gute%20Laune%20Klicke%20doch%20einfach%20auf%20den%20Link%20und%20sichere%20Dir%20Deinen%20Platz%20f%C3%BCr%20ein%2010t%C3%A4giges%2C%20kostenloses%20Training%20inkl.%20Trainingsplanung%2C%20Groupfitnesslektionen%20(Indoor%20%2F%20Outdoor%20und%20im%20Livestream)%2C%20K%C3%B6rperanalyse%2C%20Zugang%20zu%20einer%20Fitness-App%20und%20einem%20Kinderhort-Angebot.%20W%C3%A4re%20toll%2C%20wenn%20wir%20uns%20da%20mal%20sehen!%20'
			} else if (location.host.includes('wintifit')) {
				// wellcomefit
				this.iswellcomefit = 2
				// wintifit
				text =
					'Hey%20Du%2C%20ich%20war%20gerade%20beim%20Training%20im%20winti%20FIT.%20Macht%20voll%20Spass%20%20H%C3%A4ttest%20Du%20Lust%20das%20Training%20auch%20mal%20zu%20probieren%3F%20Es%20macht%20eben%20nicht%20nur%20fit%20sondern%20sorgt%20auch%20f%C3%BCr%20ziemlich%20viel%20gute%20Laune%20Klicke%20doch%20einfach%20auf%20den%20Link%20und%20sichere%20Dir%20Deinen%20Platz%20f%C3%BCr%20ein%2010t%C3%A4giges%2C%20kostenloses%20Training%20inkl.%20Trainingsplanung%2C%20Groupfitnesslektionen%20(Indoor%20%2F%20Outdoor%20und%20im%20Livestream)%2C%20K%C3%B6rperanalyse%2C%20Zugang%20zu%20einer%20Fitness-App%20und%20einem%20Kinderhort-Angebot.%20W%C3%A4re%20toll%2C%20wenn%20wir%20uns%20da%20mal%20sehen!%20'
			} else if (location.host.includes('fzw')) {
				this.iswellcomefit = 3
				// wintifit
				text =
					'Hey%0AIch%20war%20gerade%20im%20Training%20im%20Fitness%20Zenter%20Wetzikon.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20dann%20%0Aklicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20f%C3%BCr%20eine%20Schnupper%20Woche%20an.%20'
			}
			this.inputlink = 'https://' + this.hostname + '/schnupperwoche?' + this.email
			let sharelink = 'https%3A%2F%2F' + this.hostname + '%2Fschnupperwoche%3F' + this.email.replace('@', '%40')

			this.whatsapplink = 'https://api.whatsapp.com/send?text=' + text + ' ' + sharelink
			this.linkedinlink = 'https://www.linkedin.com/shareArticle?url=' + sharelink
			this.facebooklink = 'https://www.facebook.com/sharer/sharer.php?u=' + sharelink
			this.maillink = 'mailto:?subject=&body=' + text + '%20' + sharelink
			this.telegramlink = 'https://t.me/share/url?url=' + sharelink + '&text=' + text
		})
	},
}
</script>

<style lang="scss" scoped>
.ebt {
	.affiliate-gen-link {
		width: 100%;
		padding: 5px 10px;
		margin-top: 20px;

		display: flex;
		border: 1px solid var(--v-primary);
		text-align: center;
	}
	.affiliate-gen-link-copy {
		margin-bottom: 20px;
		background-color: var(--v-primary);
		color: white;
		text-align: center;
		padding: 10px 0px;
	}
	.aff-link {
		margin: 20px 10px 40px 10px;

		.share-it {
			max-width: 330px;
			margin: 0px auto;
		}
	}
	.link-wrapper {
		display: flex;
		justify-content: space-between;
		/* margin-left: 10px; */
		/* margin-right: 10px; */
		margin-top: 10px;
		.share-icon {
			width: 60px;
			height: 60px;
			padding: 7px;
		}
	}
	.gs_image {
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		display: block;
	}

	h2 {
		font-size: 22px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 10px 15px;
		margin: 20px auto 10px auto;
		text-align: center;
	}
	span {
		display: block;
		margin: auto;
		max-width: 330px;
	}
	.herunterladen {
		width: 100%;
		max-width: 412px;
		text-align: center;
		margin: 30px auto 10px auto;
		padding: 15px;
		background-color: var(--v-primary);
		color: #fff;
		font-weight: bold;
	}
	.skip {
		width: 100%;
		max-width: 412px;
		text-align: center;
		margin: 0 auto 30px auto;
		padding: 15px;
		background-color: #313131;
		color: #fff;
		font-weight: bold;
	}
}
</style>
