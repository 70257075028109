<template>
    <v-row justify="center">
        <v-dialog
            persistent
            content-class="dialog"
            class="interactive-human-body"
            max-width="1140px"
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition">
            <v-card-title class="justify-center">Muskelgruppe Filtern</v-card-title>
            <vue-flip
                style="margin: auto"
                :height="$vuetify.breakpoint.mobile ? '65vh' : '500px'"
                width="350px"
                v-model="flipped">
                <template v-slot:front>
                    <object
                        class="interactive-human-body__svg"
                        @load="frontInit"
                        id="front"
                        type="image/svg+xml"
                        data="/img/einmalig/humanSVGFront.svg"></object>
                </template>
                <template v-slot:back>
                    <object
                        id="back"
                        class="interactive-human-body__svg"
                        type="image/svg+xml"
                        @load="backInit"
                        data="/img/einmalig/humanSVGBack.svg"></object>
                </template>
            </vue-flip>
            <v-row justify="center" class="mx-0">
                <v-col cols="2">
                    <v-btn @click="animateTurnIcon" icon>
                        <lottie-player
                            id="turnIcon"
                            ref="turnIcon"
                            mode="normal"
                            style="padding-right: 2px; height: 40px; width: 40px"
                            name="health"
                            autoplay
                            src="/img/lottiefiles/turnIcon.json"></lottie-player>
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        @click="animateStretchIcon"
                        icon
                        :style="stretchIconActive ? 'background: red;' : 'background: transparent;'">
                        <lottie-player
                            id="stretchWhiteAnimation"
                            ref="stretchWhiteAnimation"
                            mode="normal"
                            v-show="stretchIconActive"
                            style="padding-right: 2px; height: 40px; width: 40px"
                            name="stretchWhite"
                            autoplay
                            src="/img/lottiefiles/stretchingWhite.json"></lottie-player>
                        <lottie-player
                            mode="normal"
                            id="stretchAnimation"
                            ref="stretchAnimation"
                            v-show="!stretchIconActive"
                            style="padding-right: 2px; height: 40px; width: 40px"
                            name="heart"
                            autoplay
                            src="/img/lottiefiles/stretching.json"></lottie-player>
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        @click="animateHealthIcon"
                        icon
                        :style="healthIconActive ? 'background: red;' : 'background: transparent;'">
                        <lottie-player
                            id="heartWhite"
                            ref="heartWhite"
                            mode="normal"
                            v-show="healthIconActive"
                            style="width: 50px; height: 50px; padding-right: 2px"
                            name="heartWhite"
                            autoplay
                            src="/img/lottiefiles/heartWhite.json"></lottie-player>
                        <lottie-player
                            id="heart"
                            ref="heart"
                            mode="normal"
                            v-show="!healthIconActive"
                            style="width: 50px; height: 50px; padding-right: 2px"
                            name="heart"
                            autoplay
                            src="/img/lottiefiles/heart.json"></lottie-player>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mx-0 mb-13">
                <v-chip
                    close
                    @click:close="removeSelectedTag(tag)"
                    class="ma-1"
                    :key="tag + index"
                    v-for="(tag, index) in getSelectedTags">
                    {{ tag.name }}
                </v-chip>
            </v-row>
        </v-dialog>
    </v-row>
</template>

<script>
import VueFlip from 'vue-flip'
import api from '../api'
import userService from '../services/userService'
import { mapGetters, mapActions } from 'vuex'
import { create } from '@lottiefiles/lottie-interactivity'

export default {
    name: 'interactiveHumanBody',
    components: {
        VueFlip,
    },
    props: {
        filters: {
            type: Array,
            default: [],
            required: false,
        },
    },
    data() {
        return {
            flipped: false,
            activeClasses: [],
            dialog: true,
            tags: [],
            turnLogoAnimation: null,
            healthIconActive: false,
            stretchIconActive: false,
            stretchAnimation: null,
            stretchWhiteAnimation: null,
            initDone: false,
            navBarInit: false,
        }
    },
    async created() {
        this.activeClasses = this.filters
        this.setNavigationBarText({ text: 'Filter setzen', state: false })
        this.tags = await api.fetchExerciseTags()
    },
    mounted() {
        this.$refs.heart.addEventListener('load', function () {
            create({
                mode: 'cursor',
                player: '#heart',
                actions: [
                    {
                        type: 'toggle',
                    },
                ],
            })
        })
        this.$refs.heartWhite.addEventListener('load', function () {
            create({
                mode: 'cursor',
                player: '#heartWhite',
                actions: [
                    {
                        type: 'toggle',
                    },
                ],
            })
        })

        this.$refs.stretchAnimation.addEventListener('load', function () {
            create({
                mode: 'cursor',
                player: '#stretchAnimation',
                actions: [
                    {
                        type: 'toggle',
                    },
                ],
            })
        })

        this.$refs.stretchWhiteAnimation.addEventListener('load', function () {
            create({
                mode: 'cursor',
                player: '#stretchWhiteAnimation',
                actions: [
                    {
                        type: 'toggle',
                    },
                ],
            })
        })
        this.$refs.turnIcon.addEventListener('load', function () {
            create({
                mode: 'cursor',
                player: '#turnIcon',
                actions: [
                    {
                        type: 'click',
                    },
                ],
            })
        })
    },

    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
        removeSelectedTag(tag) {
            console.log('should filda')
            this.activeClasses = this.activeClasses.filter((item) => item !== tag.class)
            const frontSVG = document.getElementById('front').getSVGDocument()
            //const backSVG = back.getSVGDocument()
            const backSVG = document.getElementById('back').getSVGDocument()

            let allElements = [...frontSVG.getElementsByClassName(tag.class), ...backSVG.getElementsByClassName(tag.class)]

            for (let element in allElements) {
                allElements[element].setAttribute('fill', '#a4a4a4')
            }
        },
        animateHealthIcon() {
            this.healthIconActive = !this.healthIconActive
        },
        addAnimationTurnIcon(animation) {
            this.turnLogoAnimation = animation
        },
        animateStretchIcon() {
            this.stretchIconActive = !this.stretchIconActive
        },
        addAnimationStretchIcon(animation) {
            this.stretchAnimation = animation
        },
        addAnimationStretchWhiteIcon(animation) {
            this.stretchWhiteAnimation = animation
        },
        animateTurnIcon() {
            this.flipped = !this.flipped
        },
        frontInit(e) {
            console.log('front init called')
            setTimeout(() => {
                console.log('human front initiated')
                //const frontSVG = front.getSVGDocument()
                const frontSVG = document.getElementById('front').getSVGDocument()
                //const backSVG = back.getSVGDocument()
                const backSVG = document.getElementById('back').getSVGDocument()

                if (!this.initDone && this.filters.length) {
                    console.log('init filters')
                    this.filters.forEach((filter) => {
                        let allElements = [
                            ...frontSVG.getElementsByClassName(filter),
                            ...backSVG.getElementsByClassName(filter),
                        ]
                        for (let element in allElements) {
                            allElements[element].setAttribute('fill', 'red')
                        }
                    })
                }
                this.initDone = true

                frontSVG.addEventListener('click', (e) => {
                    e.target.classList.forEach((klasse) => {
                        if (klasse !== 'unselectable' && klasse !== 'seitliche-schulter') {
                            let allElements = [
                                ...frontSVG.getElementsByClassName(klasse),
                                ...backSVG.getElementsByClassName(klasse),
                            ]
                            let color = ''
                            if (this.activeClasses.find((cls) => cls === klasse)) {
                                this.activeClasses = this.activeClasses.filter((cls) => cls !== klasse)
                                color = '#a4a4a4'
                            } else {
                                this.activeClasses.push(klasse)
                                color = 'red'
                            }

                            for (let element in allElements) {
                                allElements[element].setAttribute('fill', color)
                            }
                        }
                    })
                })
            }, 1000)
        },
        backInit(e) {
            setTimeout(() => {
                //const frontSVG = front.getSVGDocument()
                const frontSVG = document.getElementById('front').getSVGDocument()
                //const backSVG = back.getSVGDocument()
                const backSVG = document.getElementById('back').getSVGDocument()
                backSVG.addEventListener('click', (e) => {
                    e.target.classList.forEach((klasse) => {
                        if (klasse !== 'unselectable' && klasse !== 'seitliche-schulter') {
                            let allElements = [
                                ...frontSVG.getElementsByClassName(klasse),
                                ...backSVG.getElementsByClassName(klasse),
                            ]
                            let color = ''
                            if (this.activeClasses.find((cls) => cls === klasse)) {
                                this.activeClasses = this.activeClasses.filter((cls) => cls !== klasse)
                                color = '#a4a4a4'
                            } else {
                                this.activeClasses.push(klasse)
                                color = 'red'
                            }

                            for (let element in allElements) {
                                allElements[element].setAttribute('fill', color)
                            }
                        }
                    })
                })
            }, 1000)
        },
    },
    computed: {
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
        getSelectedTags() {
            let allActiveClasses = JSON.parse(JSON.stringify(this.activeClasses))
            if (allActiveClasses.includes('vordere-schulter') || allActiveClasses.includes('hintere-schulter'))
                allActiveClasses.push('seitliche-schulter')
            let filteredTags = this.tags.filter((tag) => allActiveClasses.includes(tag.class))
            if (this.healthIconActive) filteredTags.push({ name: 'Herz-Kreislauf' })
            if (this.stretchIconActive) filteredTags.push({ name: 'Dehnen' })
            return filteredTags
        },
    },
    watch: {
        navigationBarTextState(value) {
            this.navBarInit ? this.$emit('setFilter', this.activeClasses) : (this.navBarInit = true)
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
    background: white;
}

.interactive-human-body {
    &__flip {
        position: relative;
    }
    &__svg {
        width: 100%;
        height: 100%;
    }
}
</style>
