<template>
	<swiper-slide class="workingOut__swiper my-5">
		<v-card-title class="workingOut__swiper-card-title h5 break-word primary--text">Training Abschliessen</v-card-title>

		<v-card-text class="body-1">
			Wenn du dein Training beendet hast, kannst du dieses durch drücken auf den Speichern Knopf abschliessen.
		</v-card-text>

		<v-card-actions class="justify-center mt-10">
			<v-btn x-large color="primary" class="text-white" rounded @click="submitWorkout">Speichern</v-btn>
		</v-card-actions>
	</swiper-slide>
</template>

<script>
import { mapState } from 'vuex'
import { SwiperSlide } from 'vue-awesome-swiper'
import api from '../../api'

export default {
	name: 'WorkingoutSubmitSlide',
	components: {
		SwiperSlide,
	},
	methods: {
		submitWorkout() {
			api.createWorkoutSetOld(this.finishedSteps).then((data) => {
				this.$router.push({ name: 'WorkoutCompletedOld' })
			})
		},
	},
	computed: {
		...mapState('workingoutStore', ['finishedSteps']),
	},
}
</script>
<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOut {
	&__swiper {
		width: 90% !important;
		height: 90% !important;
		margin-left: 5%;
		margin-right: 5%;

		&-card {
			height: 80vh;
			&-title {
				word-break: break-word;
			}
		}
	}
}
</style>
