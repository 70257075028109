<template>
    <v-dialog @click:outside="$emit('closeDialog')" :value="dialogActive">
        <v-card dark>
            <v-row no-gutters>
                <v-col cols="8" class="text-align-end">
                    <v-card-title>
                        {{
                            dialogType === 'growth'
                                ? 'Wachstum'
                                : dialogType === 'correction'
                                ? 'Korrektur'
                                : 'Kündigungen'
                        }}
                        pro Monat für
                    </v-card-title>
                </v-col>
                <v-col cols="4">
                    <v-select
                        color="primary"
                        v-model="activeStudio"
                        item-text="name"
                        item-value="code"
                        class="font-weight-bold"
                        :items="studios"
                    ></v-select>
                </v-col>
            </v-row>

            <v-data-table
                v-if="activeStudio"
                :headers="tableHeaders"
                no-data-text="Keine Kündigungen gefunden"
                :items="studioTerminations()"
                class="elevation-1"
            ></v-data-table>
        </v-card>
    </v-dialog>
</template>
<script>
import api from '../../../api'
import contractCreator from '../contract/contractCreator.vue'
export default {
    components: { contractCreator },
    props: {
        dialogType: {
            type: String,
        },
        dialogActive: {
            type: Boolean,
            default: false,
        },
        studios: {
            type: Array,
            default: () => [],
        },
        reasons: {},
    },
    data() {
        return {
            newTermination: { date: this.$moment().add(1, 'months').format('YYYY-MM'), count: null },
            menu: false,
            editMenu: false,
            tableHeaders: [
                { text: 'Datum', value: 'date' },
                { text: 'Grund', value: 'reason' },
                { text: 'Mitglied seit:', value: 'startDate' },
                { text: 'Kunde', value: 'fullName' },
            ],
            activeStudio: null,
        }
    },
    created() {
        if (this.studios[0]) {
            this.activeStudio = this.studios[0].code
        }
    },
    methods: {
        studioTerminations() {
            if (this.reasons && this.reasons[this.activeStudio]) {
                return this.reasons[this.activeStudio].map((reason) => {
                    return {
                        date: this.$moment(reason.terminatedContract.date).format('YYYY-MM-DD'),
                        reason: reason.terminatedContract.reason,
                        startDate: this.$moment(reason.terminatedContract.startDate).format('YYYY-MM-DD'),
                        fullName: reason.terminatedContract.fullName,
                    }
                })
            }
            return []
        },
    },
}
</script>
 