<template xmlns="">
    <v-row justify="center">
        <v-dialog
            no-click-animation
            persistent
            content-class="dialog"
            max-width="1140px"
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
            <div v-if="!finishedLoading" class="workingOut__loading">
                <lottie-player
                    mode="normal"
                    class="workingOut__lottie-player"
                    name="loading"
                    autoplay
                    src="/img/lottiefiles/workoutGo.json"></lottie-player>
            </div>
            <v-window
                v-show="finishedLoading"
                :continuous="false"
                v-model="windowIndex"
                ref="planSwiper"
                @change="changeSlide">
                <v-window-item style="height: 100vh" :value="index + 1" :key="index" v-for="(exercise, index) in exercises">
                    <workingout-exercise
                        :display-content="windowIndex === index + 1 || windowIndex === index || windowIndex === index + 2"
                        :active-slide="!!(windowIndex === index + 1)"
                        :propExercise="exercise"
                        @noForce="exercise.noForce = true"
                        @updateBottomNavigation="updateBottomNavigation"></workingout-exercise>
                </v-window-item>
                <v-window-item v-if="exercises.length" style="height: 100vh" :value="exercises.length + 1">
                    <workingout-exercise-submit-slide
                        :exercises="exercises"
                        :workout="workout"></workingout-exercise-submit-slide>
                </v-window-item>
            </v-window>
        </v-dialog>
    </v-row>
</template>

<script>
import api from '../../api'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import WorkingoutHeader from './WorkingoutHeader'
import WorkingoutGuide from './WorkingoutGuide'
import WorkingoutStep from './WorkingoutStep'
import WorkingoutStatistic from './WorkingoutStatistic'
import WorkingoutExerciseSubmitSlide from './WorkingoutExerciseSubmitSlide'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'
import WorkoutAddExercise from './WorkoutAddExercise'
import WorkingoutExercise from './WorkingoutExercise.vue'
// import orientation from 'o9n'

export default {
    name: 'Workingout',
    components: {
        WorkoutAddExercise,
        WorkingoutHeader,
        WorkingoutGuide,
        WorkingoutStatistic,
        WorkingoutStep,
        WorkingoutExerciseSubmitSlide,
        Swiper,
        SwiperSlide,
        WorkingoutExercise,
    },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            windowIndex: 1,
            dialog: true,
            loading: true,
            loadingAnimationFinished: false,
            workout: {},
            exercises: [],
            expand: {
                guide: false,
                stats: false,
                newStep: false,
            },
            activeSlide: null,
        }
    },
    async created() {
        let minutes = this.$moment().diff(this.$moment(this.workoutStartTime, 'HH:mm:ss'), 'minutes', true)
        if (!this.workoutStartTime || minutes > 180 || minutes <= 0)
            this.setWorkoutStartTime(this.$moment().format('HH:mm:ss'))
        this.setNavigationbarVisibility(false)
        let self = this
        setTimeout(function () {
            self.loadingAnimationFinished = true
        }, 3100)
        await this.fetchItems()
    },
    beforeDestroy() {
        this.setNavigationbarVisibility(true)
        this.setNavigationBarText(null)
    },
    methods: {
        ...mapActions('workingoutStore', ['setWorkoutStartTime', 'setWorkoutStepsHistory']),
        ...mapActions('navigationStore', ['setSelectedNavigation']),
        ...mapActions('navigationStoreNotPersisted', [
            'setWorkoutExecutions',
            'setNavigationbarVisibility',
            'setNavigationBarText',
        ]),
        ...mapGetters('deviceStore', ['exerciseList']),
        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        changeSlide() {
            // console.log(this.windowIndex)
            //console.log(this.windowIndex, this.exercises[this.windowIndex])
            if (this.windowIndex === this.exercises.length + 1) return this.setSelectedNavigation(this.exercises.length + 1)
            this.setSelectedNavigation(this.exercises[this.windowIndex - 1].title)
            // old swiper setTimeout(this.setSelectedNavigation, 100, this.swiper.slides[this.swiper.realIndex].id)
        },
        async fetchItems() {
            let data = await api.getTrainingPlan(this.$route.params.id)
            data.exercises.forEach((exercise) => {
                let foundExercise = this.exerciseList().find((listItem) => listItem._id === exercise.exercise_id)
                if (!foundExercise) alert('Die Übung' + exercise.title + ' wurde nicht gefunden.')
                if (exercise && exercise.sets) foundExercise.sets = exercise.sets
                if (exercise && exercise.noForce) foundExercise.noForce = exercise.noForce
                if (exercise && exercise.general_exercise_settings)
                    foundExercise.general_exercise_settings = exercise.general_exercise_settings
                this.exercises.push(foundExercise)
            })
            this.workout = data.form
            this.updateBottomNavigation()
            this.loading = false

            this.setWorkoutExecutions(await api.getWorkoutExecutions(this.$route.params.id))
            this.setWorkoutStepsHistory(await api.getWorkoutHistory(this.$route.params.id))
        },
        getImageForExercise(exercise) {
            if (exercise.picture) return this.getImageUrl(exercise.picture)
            if (exercise.manual[0] && exercise.manual[0].picture) return this.getImageUrl(exercise.manual[0].picture)
        },
        updateBottomNavigation(state = null) {
            if (!this.exercises.length) {
                alert('In diesem Plan sind keine Übungen enthalten.')
                this.$router.push('/workouts')
            }
            let newState = state || this.exercises[0].title
            let buttons = []
            this.exercises.forEach((exercise) => {
                buttons.push({
                    title: exercise.title,
                    text: exercise.title,
                    image: this.getImageForExercise(exercise),
                })
            })
            buttons.push({
                title: this.exercises.length + 1,
                text: 'Abschliessen',
                icon: '/img/lottiefiles/BottomNavigation/checkFilled.json',
            })

            this.setNavigationBarText({
                customButtons: true,
                buttons,
                componentHandles: true,
                endPoint: 'planRun',
                color: '#313131',
            })
            if (state) newState = state
            this.setSelectedNavigation(newState)
        },
        getImageUrl(imageId) {
            return '/img/uebungen/' + imageId.replace(/\.[^/.]+$/, '') + '.webp'

            return '/img/uebungen/' + imageId
        },
        settingsForExercice(shortForm, index) {
            let settings = this.exercises[index].settings

            return Object.entries(settings)
        },
        getStepCountForExercise(id) {
            let exercise = this.finishedSteps.find((exercise) => exercise.exercise_id === id)
            if (!exercise || exercise.note) return 1
            return exercise.steps.length + 1
        },
        exerciseHasWeightSetting(exercise) {
            return !!exercise.tp_exercise_settings.find((setting) => setting.title === 'Gewicht')
        },
        getTextForNewStepButton(exercise) {
            return this.exerciseHasWeightSetting(exercise)
                ? 'Satz ' + this.getStepCountForExercise(exercise._id) + '.'
                : 'Notiz'
        },
    },
    computed: {
        ...mapState('workingoutStore', ['finishedSteps', 'workoutStartTime']),
        ...mapState('navigationStore', ['selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationBarUsage']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarEndPoint']),
        swiper: function () {
            return this.$refs.planSwiper.$swiper
        },
        finishedLoading() {
            if (this.loadingAnimationFinished) this.setNavigationbarVisibility(true)
            return !this.loading && this.loadingAnimationFinished
        },
        /**	filteredExercises() {
			if (this.activeSlide === 'finish') return this.exercises.filter(exercise, (index) => index === this.exercises.length)
			let activeExerciseIndex = this.exercises.indexOf(this.exercises.find((exercise) => this.activeSlide === exercise.title))
			console.log('da isch de active index')
			let filteredExercises = this.exercises.filter(
				exercise,
				(index) => activeExerciseIndex === index || index === activeExerciseIndex + 1 || index === activeExerciseIndex - 1
			)
			console.log('da isch de array')
			return filteredExercises
		}, **/
    },
    watch: {
        navigationBarUsage() {
            this.allowTouchMove = !(this.navigationBarUsage && this.navigationBarUsage.usage === 'workoutEnterSet')
            /*
            this.$refs.planSwiper.$swiper.allowTouchMove = !(
                this.navigationBarUsage && this.navigationBarUsage.usage === 'workoutEnterSet'
            )*/
        },
        selectedNavigation(value) {
            if (value === this.exercises.length + 1 && this.windowIndex !== value) this.windowIndex = value
            if (
                this.navigationBarEndPoint === 'planRun' &&
                (!this.exercises[this.windowIndex - 1] || this.exercises[this.windowIndex - 1].title !== value)
            ) {
                this.exercises.forEach((exercise) => {
                    if (exercise.title === value) {
                        this.windowIndex = this.exercises.indexOf(exercise) + 1
                    }
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.v-application--wrap {
    background: lightgray !important;
}
.h5 {
    text-transform: uppercase;
    font-weight: bold;
}

.workingOut {
    &__loading {
        height: 100vh;
    }
    &__lottie-player {
        display: unset;
    }
}

::v-deep .dialog {
    background-color: white !important;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
</style>
