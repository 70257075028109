<template xmlns="">
    <v-window-item :value="value" class="justify-center">
        <image-overlapping-card :changeRoute="false" v-on:change-visibility="resetSelectedExercise" v-if="selectedExercise">
            <template v-slot:image>
                <v-img
                    height="35vh"
                    v-if="selectedExercise.picture || (selectedExercise.manual[0] && selectedExercise.manual[0].picture)"
                    :src="
                        selectedExercise.picture
                            ? getImageUrl(selectedExercise.picture)
                            : getImageUrl(selectedExercise.manual[0].picture)
                    "></v-img>
            </template>
            <template v-slot:content>
                <v-row no-gutters class="mb-4 formCard elevation-7">
                    <v-col class="pa-0" cols="12">
                        <v-row no-gutters class="d-flex flex-row py-3 justify-center mx-0 mb-0">
                            <v-card-title
                                style="letter-spacing: 1px"
                                class="h5 pt-5 pb-2 white--text my-0 font-weight-bold text-uppercase">
                                {{ selectedExercise.title }}
                            </v-card-title>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    dense
                    class="workout-add-exercise__card-new-step--container mx-5 mt-3 elevation-7"
                    v-if="selectedExercise.general_exercise_settings.length">
                    <v-col class="pa-0" cols="12">
                        <v-row
                            no-gutters
                            class="workout-add-exercise__card-new-step d-flex flex-row mx-0 workout-add-exercise__card-new-step--active py-3 mt-0 mb-2">
                            <v-col cols="12" class="d-flex pa-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="ml-5 mr-3"
                                    :autoplay="false"
                                    src="/img/lottiefiles/settings/deviceManagement.json"></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h6 mb-0 pa-0 py-3 font-weight-bold text-uppercase">
                                    Geräteeinstellungen
                                </v-card-title>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-row no-gutters class="px-5 py-2">
                        <v-col
                            cols="auto"
                            class="pa-1"
                            :key="index + 'deviceSetting'"
                            v-for="(deviceSetting, index) in selectedExercise.general_exercise_settings">
                            <v-card
                                class="mb-2"
                                :color="deviceSetting.value ? 'listitemcolor' : 'grey'"
                                @click="settingDialog(deviceSetting, $event, 'deviceSetting')">
                                <v-card-title class="pa-1" :class="{ subtitle: !deviceSetting.value }">
                                    {{ shorten(deviceSetting.text, 10) }}
                                </v-card-title>
                                <v-card-subtitle
                                    class="pa-0 text-center"
                                    :class="{ 'white--text h2 pa-0': !deviceSetting.value, 'pa-2': deviceSetting.value }">
                                    {{ deviceSetting.value ? getSettingText(deviceSetting) : '+' }}
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-row>
                <v-row
                    no-gutters
                    v-for="(step, index) in sets"
                    :key="'added-step' + index"
                    dense
                    class="workout-add-exercise__card-new-step--container mx-5 mt-3"
                    :class="{ 'elevation-7': step.active }"
                    :style="[step.active ? { 'max-height': 'unset' } : { 'max-height': '40px' }]">
                    <v-col class="pa-0" cols="12">
                        <v-row
                            class="workout-add-exercise__card-new-step d-flex flex-row mx-0 my-0"
                            :class="{ 'workout-add-exercise__card-new-step--active py-3': step.active }">
                            <v-col cols="7" class="d-flex pa-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="ml-5 mr-3"
                                    :autoplay="false"
                                    src="/img/lottiefiles/set.json"></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h6 mb-0 pa-0 font-weight-bold text-uppercase"
                                    :class="{ 'py-3': step.active }">
                                    Satz {{ index + 1 }}
                                </v-card-title>
                            </v-col>
                            <v-col cols="5" class="pa-0 pr-2" style="text-align: end">
                                <v-btn @click="toggleExistingStep(index)" icon>
                                    <v-icon :color="step.active ? 'red' : 'blue'">
                                        {{ step.active ? 'mdi-close-circle' : 'mdi-pencil-circle' }}
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="!step.active" @click="copyExistingStep(index)" icon>
                                    <v-icon color="orange">
                                        {{ 'mdi-content-copy' }}
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="!step.active" @click="deleteStep(index)" icon>
                                    <v-icon color="red">
                                        {{ 'mdi-delete' }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-expand-transition>
                        <div v-show="step.activeBefore" class="pb-3">
                            <v-row class="px-5 pt-5">
                                <v-col
                                    cols="auto"
                                    class="pa-1"
                                    :key="setIndex + 'setSetting'"
                                    v-for="(setSetting, setIndex) in step.settings">
                                    <v-card
                                        :color="setSetting.value ? 'listitemcolor' : 'grey'"
                                        @click="settingDialog(setSetting, $event, 'editSetting', index)">
                                        <v-card-title class="subitle pa-1 font-weight-bold" style="font-size: 1rem">
                                            {{ shorten(setSetting.text, 10) }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            class="text-center"
                                            :class="{ 'white--text h2 pa-0': !setSetting.value, 'pa-2': setSetting.value }">
                                            {{ setSetting.value ? getSettingText(setSetting) : '+' }}
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expand-transition>
                </v-row>

                <v-row
                    no-gutters
                    v-if="selectedExercise.set_exercise_settings.length"
                    dense
                    class="workout-add-exercise__card-new-step--container mx-5 mt-3"
                    :class="{ 'elevation-7': newStepActive }">
                    <v-col class="pa-0" cols="12">
                        <v-row
                            no-gutters
                            class="workout-add-exercise__card-new-step d-flex flex-row mx-0 my-0"
                            :class="{ 'workout-add-exercise__card-new-step--active pt-3': newStepActive }">
                            <v-col cols="10" class="d-flex pa-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="ml-5 mr-3"
                                    :autoplay="false"
                                    src="/img/lottiefiles/settings/deviceManagement.json"></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h6 mb-0 pa-0 font-weight-bold text-uppercase"
                                    :class="{ 'py-3': newStepActive }">
                                    Satz hinzufügen
                                </v-card-title>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                                <v-btn @click="toggleNewStepAnimation" icon>
                                    <v-icon :color="newStepActive ? 'red' : 'white'">
                                        {{ newStepActive ? 'mdi-close-circle' : 'mdi-plus-circle' }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-expand-transition>
                        <div v-show="newStepActiveBefore">
                            <v-row no-gutters class="px-5 py-2 pt-5">
                                <v-col
                                    cols="auto"
                                    class="pa-1"
                                    :key="index + 'setSetting'"
                                    v-for="(setSetting, index) in selectedExercise.set_exercise_settings">
                                    <v-card
                                        :color="setSetting.value ? 'listitemcolor' : 'grey'"
                                        @click="settingDialog(setSetting, $event, 'setSetting')">
                                        <v-card-title class="pa-1 subtitle">
                                            {{ shorten(setSetting.text, 10) }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            class="text-center pa-0"
                                            :class="{ 'white--text h2 pa-0': !setSetting.value, 'pa-2': setSetting.value }">
                                            {{ setSetting.value ? getSettingText(setSetting) : '+' }}
                                        </v-card-subtitle>
                                        <!--		<v-text-field
							color="white"
							class="white--text v-input--is-focused"
							:type="setSetting.input"
							:label="shorten(setSetting.text, 10)"
							v-model="setSetting.value"
						></v-text-field> -->
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expand-transition>
                </v-row>

                <v-dialog
                    @click:outside="closeSettingDialog()"
                    :retain-focus="false"
                    transition="scroll-y-reverse-transition"
                    v-model="settingDialogVisible"
                    content-class="fixKeyboardApple">
                    <v-card>
                        <v-card-subtitle class="pb-0 pt-1" v-if="settingInEditMode && settingInEditMode.text">
                            {{ settingInEditMode.text }}
                        </v-card-subtitle>
                        <v-select
                            class="px-5"
                            v-model="settingInEditMode.value"
                            :items="settingInEditMode.selections"
                            v-if="settingInEditMode && settingInEditMode.input === 'selection'"
                            @keydown.enter.prevent="closeSettingDialog()"></v-select>
                        <v-text-field
                            v-if="settingInEditMode && settingInEditMode.input !== 'selection'"
                            :type="settingInEditMode.input"
                            class="px-5"
                            v-model="settingInEditMode.value"
                            @keydown.enter.prevent="closeSettingDialog()"
                            autofocus></v-text-field>
                    </v-card>
                </v-dialog>
            </template>
        </image-overlapping-card>

        <div class="workoutAddExercise__swiper">
            <v-scale-transition origin="center center">
                <v-card class="workoutAddExercise d-flex flex-column">
                    <div>
                        <!-- <v-card-title class="h justify-center">Neue Übung hinzufügen</v-card-title>
						-->
                        <v-row no-gutters>
                            <v-col cols="10" class="pr-0 mb-3" style="align-self: center">
                                <v-combobox
                                    v-model="searchString"
                                    label="Suche..."
                                    chips
                                    placheholder="Suche"
                                    full-width
                                    hide-details
                                    hide-no-data
                                    hide-selected
                                    multiple
                                    clearable
                                    append-icon=""
                                    deletable-chips
                                    small-chips
                                    rounded
                                    single-line
                                    disable-lookup
                                    background-color="listitemcolor"
                                    class="workoutAddExercise__search-input my-0">
                                    <template v-slot:prepend-inner>
                                        <div
                                            class="d-flex"
                                            v-if="interactiveHumanBodyFilters && interactiveHumanBodyFilters.length > 0">
                                            <div
                                                class="exercise-search__inner d-flex overflow-hidden"
                                                :class="{ 'exercise-search__inner-inactive': !showSelectedFilters }">
                                                <v-chip
                                                    v-for="(filterClassName, index) in selectedFilters"
                                                    :key="'interactiveHumandBodyFilter' + index"
                                                    small
                                                    color="red"
                                                    class="white--text">
                                                    {{ filterClassName }}</v-chip
                                                >
                                            </div>
                                            <v-btn
                                                class="align-self-center"
                                                icon
                                                x-small
                                                @click="showSelectedFilters = !showSelectedFilters"
                                                ><v-icon color="red">{{
                                                    showSelectedFilters ? 'mdi-chevron-left' : 'mdi-chevron-right'
                                                }}</v-icon></v-btn
                                            >
                                        </div>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col class="pl-0" style="align-self: center" cols="1">
                                <v-btn icon small @click="filterActive = true">
                                    <lottie-player
                                        mode="normal"
                                        v-show="!interactiveHumanBodyFilters.length"
                                        style="width: 45px; height: 45px"
                                        name="filter"
                                        :autoplay="false"
                                        src="/img/lottiefiles/funnel.json"></lottie-player>
                                    <lottie-player
                                        mode="normal"
                                        v-show="interactiveHumanBodyFilters.length"
                                        style="width: 45px; height: 45px"
                                        name="filter"
                                        :autoplay="false"
                                        src="/img/lottiefiles/activeFunnel.json"></lottie-player>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <v-virtual-scroll class="pb-16" :items="filteredExercises" item-height="130" bench="1">
                        <template v-slot:default="{ item }">
                            <v-list-item
                                class="workoutAddExercise__list-item pa-0 mx-5 elevation-3 d-flex listitemcolor rounded"
                                :key="item._id"
                                @click="selectExercise(item)">
                                <div
                                    v-if="item.picture || (item.manual[0] && item.manual[0].picture)"
                                    class="workoutAddExercise__list-item-image__container mr-3">
                                    <v-img
                                        position="left center"
                                        contain
                                        class="workoutAddExercise__list-item-image"
                                        :lazy-src="getImageUrl('placeholder.png')"
                                        :src="
                                            item.picture ? getImageUrl(item.picture) : getImageUrl(item.manual[0].picture)
                                        "></v-img>
                                </div>
                                <div>
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-bold" v-if="item.deviceShortForm">
                                        {{ item.deviceShortForm }}
                                    </v-list-item-subtitle>
                                </div>
                            </v-list-item>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-scale-transition>
        </div>
        <interactive-human-body
            @setFilter="setFilter"
            :filters="interactiveHumanBodyFilters"
            v-if="filterActive"></interactive-human-body>
    </v-window-item>
</template>

<script>
import api from '../../api'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SwiperSlide } from 'vue-awesome-swiper'
import imageOverlappingCard from '../../components/imageOverlappingCard.vue'
import interactiveHumanBody from '../../components/interactiveHumanBody.vue'

export default {
    name: 'WorkoutAddExercise',
    components: {
        SwiperSlide,
        imageOverlappingCard,
        interactiveHumanBody,
    },
    props: {
        value: {
            type: Number,
        },
        exerciseIds: {
            type: Array,
        },
    },
    data() {
        return {
            showSelectedFilters: true,
            x: 0,
            y: 0,
            barInitiated: false,
            searchString: [],
            selectActive: true,
            selectedExercise: null,
            exercises: [],
            filterActive: false,
            settingDialogVisible: false,
            activeSetting: '',
            settingInEditMode: null,
            newStepActive: false,
            newStepActiveBefore: false,
            sets: [],
            exerciseSetSettings: null,
            editStepBackup: null,
            stepIndex: null,
            initialised: false,
            doneTyping: true,
            typingTimer: null,
            doneTypingInterval: 1000,
            exercisesTemp: [],
            tags: [],
            studio_id: null,
        }
    },
    async created() {
        if (!this.studio_id) {
            let studio = null

            studio = await api.getUserStudio().catch((e) => {
                window.app.$root.$children[0].checkStudio()
            })

            this.studio_id = studio.studio._id
        }
        this.tags = await api.fetchExerciseTags()

        this.fetchExercises()
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNavigationbarVisibility']),
        ...mapActions('workingoutStore', ['setWorkoutCreateDefaultValues', 'setInteractiveHumanBodyFilters']),
        ...mapActions('deviceStore', ['setDeviceList']),
        closeSettingDialog() {
            this.setNavigationbarVisibility(true)
            this.settingDialogVisible = false
        },
        copyExistingStep(index) {
            this.sets.push(JSON.parse(JSON.stringify(this.sets[index])))
        },
        deleteStep(index) {
            this.sets.splice(index, 1)
        },
        saveStepEdit() {
            this.setWorkoutCreateDefaultValues(JSON.parse(JSON.stringify(this.sets[this.stepIndex].settings)))
            this.toggleExistingStep(this.stepIndex, true)
        },
        saveNewStep() {
            let setExerciseSettingsTemp = JSON.parse(JSON.stringify(this.selectedExercise.set_exercise_settings))
            setExerciseSettingsTemp.forEach((setting) => {
                if (setting.text === 'Gewicht' && !setting.value) {
                    setting.value = '0'
                }
            })
            this.sets.push({ settings: setExerciseSettingsTemp, active: false, activeBefore: false })
            this.setWorkoutCreateDefaultValues(setExerciseSettingsTemp)
            this.toggleNewStepAnimation()
        },
        toggleExistingStep(index, save = false) {
            this.stepIndex = index
            if (this.sets[index].active) {
                this.setNavigationBarText({ text: 'Hinzufügen', state: false, endPoint: 'addExercise' })
                this.existingStepActiveBeforeToggle(index)
                this.existingStepActiveToggle(index)
                if (!save) this.sets[index].settings = JSON.parse(JSON.stringify(this.editStepBackup))
            } else {
                this.setNavigationBarText({ text: 'Satz überarbeiten', state: false, endPoint: 'saveStepEdit' })
                this.editStepBackup = JSON.parse(JSON.stringify(this.sets[index].settings))
                this.existingStepActiveToggle(index)
                this.existingStepActiveBeforeToggle(index)
            }
        },
        existingStepActiveToggle(index) {
            this.sets[index].active = !this.sets[index].active
        },
        existingStepActiveBeforeToggle(index) {
            this.sets[index].activeBefore = !this.sets[index].activeBefore
        },
        toggleNewStepAnimation() {
            if (this.newStepActive) {
                this.setNavigationBarText({ text: 'Hinzufügen', state: false, endPoint: 'addExercise' })
                this.newStepActiveBeforeToggle()
                this.newStepActiveToggle()
            } else {
                this.setNavigationBarText({ text: 'Satz speichern', state: false, endPoint: 'saveNewStep' })
                let tempSetExerciseSettings = JSON.parse(JSON.stringify(this.exerciseSetSettings))
                tempSetExerciseSettings.forEach((setting) => {
                    let savedValue = this.workoutCreateDefaultValues[setting.text]
                    if (savedValue) setting.value = savedValue
                })
                this.selectedExercise.set_exercise_settings = tempSetExerciseSettings
                this.newStepActiveToggle()
                this.newStepActiveBeforeToggle()
            }
        },
        newStepActiveToggle() {
            this.newStepActive = !this.newStepActive
        },
        newStepActiveBeforeToggle() {
            this.newStepActiveBefore = !this.newStepActiveBefore
        },
        getSettingText(setting) {
            let text = setting.value
            if (setting.text === 'Gewicht') text = text + ' KG'
            if (setting.text === 'Dauer') text = text + ''
            return text
        },
        settingDialog(setting, $event, type, index = null) {
            type === 'editSetting' ? (this.activeSetting = index) : (this.activeSetting = type)
            $event.preventDefault()
            this.settingDialogVisible = false
            this.x = $event.clientX
            this.y = $event.clientY
            this.$nextTick(() => {
                this.setNavigationbarVisibility(false)
                this.settingDialogVisible = true
            })
            this.settingInEditMode = JSON.parse(JSON.stringify(setting))
        },
        shorten(text, length = 25) {
            if (text === 'Min Wiederholungen') return 'Min WH'
            if (text === 'Max Wiederholungen') return 'Max WH'
            if (text === 'Umdrehungen pro Minute') return 'UPM'
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        fetchExercises() {
            if (!this.exerciseList.length) {
                api.fetchDevices().then((data) => {
                    data.forEach((device) => (device.fab = false))
                    this.setDeviceList(data)
                    this.exercises = this.exerciseList
                })
            } else {
                this.exercises = this.exerciseList
            }
            this.$emit('triggerKey')
        },
        setFilter(filters) {
            this.setInteractiveHumanBodyFilters(filters)
            this.filterActive = false
            this.$emit('updateBottomNavigation', this.value)
        },
        selectExercise(exercise) {
            this.initialised = false
            this.sets = []
            this.setNavigationBarText({ text: 'Hinzufügen', state: false, endPoint: 'addExercise' })
            this.selectedExercise = exercise
            this.selectedExercise.set_exercise_settings.sort((a, b) => a.text.localeCompare(b.text))
            let temp_min_rep_index = this.selectedExercise.set_exercise_settings.findIndex(
                (setting) => setting.text === 'Min Wiederholungen'
            )
            let temp_max_rep_index = this.selectedExercise.set_exercise_settings.findIndex(
                (setting) => setting.text === 'Max Wiederholungen'
            )

            if (temp_min_rep_index !== undefined && temp_max_rep_index !== undefined) {
                let temp_min_rep = this.selectedExercise.set_exercise_settings[temp_min_rep_index]
                this.selectedExercise.set_exercise_settings[temp_min_rep_index] =
                    this.selectedExercise.set_exercise_settings[temp_max_rep_index]
                this.selectedExercise.set_exercise_settings[temp_max_rep_index] = temp_min_rep
            }

            this.exerciseSetSettings = JSON.parse(JSON.stringify(this.selectedExercise.set_exercise_settings))
        },
        getImageUrl(image) {
            return '/img/uebungen/' + image.replace(/\.[^/.]+$/, '') + '.webp'
        },
        saveNewExercise() {
            if (!this.sets.length) return alert('Füge mindestens einen Satz hinzu.')
            this.selectedExercise.sets = this.sets
            this.$emit('add', this.selectedExercise)
            this.$emit('updateBottomNavigation', this.value)
            this.selectedExercise = null
        },
        resetSelectedExercise() {
            this.$emit('updateBottomNavigation', this.value)
            this.selectedExercise = null
        },
    },
    computed: {
        ...mapState('workingoutStore', ['defaultValues', 'interactiveHumanBodyFilters', 'workoutCreateDefaultValues']),
        ...mapState('navigationStore', ['selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationBarText']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
        ...mapGetters('deviceStore', ['exerciseList']),
        selectedFilters: function () {
            let classNames = []
            for (let interactiveHumanBodyFilter of this.interactiveHumanBodyFilters) {
                let className = this.tags.find((e) => e.class === interactiveHumanBodyFilter)
                if (className && className.name) {
                    classNames.push(className.name)
                }
            }

            return classNames
        },
        filteredExercises: function () {
            if (this.studio_id || !this.exercisesTemp.length > 0) {
                this.exercisesTemp = this.exercises.filter((exercise) => {
                    if (exercise.deleted) return false
                    if (this.exerciseIds.includes(exercise._id)) return false
                    if (exercise.deviceCategory === '(ALT) MedX Kraftgeräte') return false

                    if (!exercise.studios.includes(this.studio_id.toString())) return false
                    if (!this.searchString.length && !this.interactiveHumanBodyFilters.length) return true
                    let exerciseTags = exercise.tags.map((tag) => tag.class)

                    if (
                        exerciseTags.some((tag) => this.interactiveHumanBodyFilters.includes(tag)) ||
                        !this.interactiveHumanBodyFilters.length
                    ) {
                        let foundSth = 0
                        this.searchString.forEach((searchItem) => {
                            let found = false
                            if (exercise.title.toLowerCase().includes(searchItem.toLowerCase())) found = true
                            if (exercise.deviceTitle.toLowerCase().includes(searchItem.toLowerCase())) found = true
                            if (exercise.deviceShortForm.toLowerCase().includes(searchItem.toLowerCase())) found = true
                            if (found) foundSth = foundSth + 1
                        })

                        return foundSth === this.searchString.length
                    }
                })
            }

            return this.exercisesTemp
        },
    },
    watch: {
        navigationBarTextState(value) {
            if (
                this.navigationBarEndPoint === 'addExercise' ||
                this.navigationBarEndPoint === 'saveNewStep' ||
                this.navigationBarEndPoint === 'saveStepEdit'
            ) {
                if (this.initialised) {
                    if (this.selectedExercise && this.navigationBarEndPoint === 'addExercise') {
                        this.saveNewExercise()
                    }
                    if (this.selectedExercise && this.navigationBarEndPoint === 'saveNewStep') {
                        this.saveNewStep()
                    }
                    if (this.selectedExercise && this.navigationBarEndPoint === 'saveStepEdit') {
                        this.saveStepEdit()
                    }
                    this.initialised = false
                } else {
                    this.initialised = true
                }
            }
        },
        settingDialogVisible(value) {
            if (
                typeof this.settingInEditMode !== 'undefined' &&
                this.settingInEditMode !== null &&
                this.settingInEditMode._id
            ) {
                if (!this.settingDialogVisible) {
                    if (this.activeSetting === 'setSetting') {
                        this.selectedExercise.set_exercise_settings.forEach((exerciseSetting, index) => {
                            if (
                                exerciseSetting &&
                                exerciseSetting._id &&
                                this.settingInEditMode &&
                                this.settingInEditMode._id &&
                                exerciseSetting._id === this.settingInEditMode._id
                            ) {
                                if (!exerciseSetting.value && !this.settingInEditMode.value) {
                                    this.settingInEditMode = null
                                } else {
                                    this.selectedExercise.set_exercise_settings[index] = JSON.parse(
                                        JSON.stringify(this.settingInEditMode)
                                    )
                                    this.settingInEditMode = null
                                }
                            }
                        })
                    } else if (this.activeSetting !== 'deviceSetting') {
                        this.sets[this.activeSetting].settings.forEach((setting, index) => {
                            if (
                                setting &&
                                setting._id &&
                                this.settingInEditMode &&
                                this.settingInEditMode._id &&
                                setting._id === this.settingInEditMode._id
                            ) {
                                if (!this.sets[this.activeSetting].settings[index].value && !this.settingInEditMode.value) {
                                    this.settingInEditMode = false
                                } else {
                                    this.sets[this.activeSetting].settings[index] = JSON.parse(
                                        JSON.stringify(this.settingInEditMode)
                                    )
                                    this.settingInEditMode = null
                                }
                            }
                        })
                    } else {
                        this.selectedExercise.general_exercise_settings.forEach((exerciseSetting, index) => {
                            if (
                                exerciseSetting &&
                                exerciseSetting._id &&
                                this.settingInEditMode &&
                                this.settingInEditMode._id &&
                                exerciseSetting._id === this.settingInEditMode._id
                            ) {
                                this.selectedExercise.general_exercise_settings[index] = JSON.parse(
                                    JSON.stringify(this.settingInEditMode)
                                )
                                this.settingInEditMode = null
                            }
                        })
                    }
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.exercise-search__inner {
    width: 100%;
    transition: width 0.4s ease-in;
    &-inactive {
        width: 0px;
        transition: width 0.4s ease-out;
    }
}
::v-deep .v-input__slot {
    overflow: auto;
}
::v-deep .v-input__slot::-webkit-scrollbar {
    display: none;
}

::v-deep .v-input__prepend-inner {
    margin: auto;
}

::v-deep .v-select__selections {
    flex-wrap: nowrap;
    max-width: unset;
    min-width: unset;
}

.formCard {
    background: #313131;
    margin: -12px 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}
.formTitle {
    background-color: #313131;
    color: white !important;
}
::v-deep .bgpositionleftcenter .v-image__image {
    background-position: left center !important;
}

.workout-add-exercise {
    &__card {
        border-radius: 10px;

        &-title {
            border-radius: 10px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: #313131;
            color: white !important;
        }
        &-new-step {
            background: #313131;
            color: white !important;
            align-items: center;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            border-radius: 10px;
            &--container {
                border-radius: 10px;
            }
            &--active {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }
}
.wrapper-outer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
}
.wrapper-inner {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    height: 84vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.courseListView {
    margin-top: 35vh !important;
    &__container {
        position: relative;
        &-title {
            font-weight: bold;
            color: #071e20;
            word-break: break-word;
        }
    }
    &-course {
        &-signups {
            &-checkbox {
                font-size: 20px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

::v-deep .v-virtual-scroll {
    height: 55vh !important;
}

.workoutAddExercise {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: 100%;

    &__search {
        height: 50px;

        &-input {
            top: 25%;
            left: 10px;
            position: relative;
            width: 90%;
            margin-bottom: auto;
        }
    }

    .h5 {
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: -10px;
    }
    &-actions {
        position: sticky;
        bottom: 0;

        button {
            border-width: 4px;
            font-weight: bold;
        }
    }

    &__list-item {
        height: 100px;
        overflow: hidden;

        .v-list-item__title {
            font-weight: bold;
            margin-bottom: 3px;
        }

        &-image {
            height: 100%;

            &__container {
                height: 100%;
                width: 40%;
            }
        }
    }

    &__swiper {
        height: 100vh;
        background-color: white;
        display: flex;
        &-card {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            &-content {
                overflow-y: auto;
                height: 62vh;

                &-img {
                    margin-top: 25%;
                    width: 50%;
                }
            }

            &-image {
                margin: 0 10%;
                height: 40%;
                background-size: contain;
            }
        }
    }
}
::v-deep .v-input__slot {
    padding-left: 10px !important;
}
</style>
