<template>
    <apexchart type="line" :options="chartOptions" :series="series"></apexchart>
</template> 

<script>
export default {
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'leadsPerTime',
                },
                xaxis: {
                    categories: this.leadsPerTime.map((item) => item.name),
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
            },
            series: [
                {
                    name: 'Leads',
                    data: this.leadsPerTime.map((item) => item.data),
                },
            ],
        }
    },
    props: {
        leadsPerTime: Array,
    },
}
</script>
