<template>
    <v-row>
        <v-dialog persistent max-width="500px" v-model="dialog" fullscreen transition="dialog-bottom-transition">
            <v-card class="courseEditTimeline">
                <v-row max-width="300px" no-gutters>
                    <v-col
                        cols="12"
                        v-if="!loading && !error && courseTimelineCreate"
                        style="max-width: 500px"
                        class="inner-wrapper px-5"
                    >
                        <v-btn
                            class="courseEditTimeline__container-back"
                            fab
                            dark
                            color="white"
                            @click="closeDialog(true)"
                        >
                            <v-icon color="black">mdi-chevron-left</v-icon>
                        </v-btn>

                        <v-col cols="12" class="px-0 pb-0">
                            <course-search
                                :value="courseTimelineCreate.course"
                                label="Kurs"
                                rights="gf_c_plan"
                                @update="updateCourse"
                            ></course-search>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-select
                                v-model="courseTimelineCreate.label"
                                :items="labels"
                                item-text="text"
                                item-value="_id"
                                label="Label"
                                single-line
                                outlined
                                color="primary"
                                prepend-icon="mdi-label-outline"
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="courseTimelineCreate.additionalInfo"
                                outlined
                                label="Zusatzinfo"
                                placeholder="Mindest TN-Zahl 4"
                                prepend-icon="mdi-information-slab-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-select
                                v-model="courseTimelineCreate.day"
                                :items="days"
                                item-text="text"
                                item-value="value"
                                outlined
                                color="primary"
                                prepend-icon="mdi-calendar"
                                label="Wochentag"
                                single-line
                            ></v-select>
                            <v-switch v-model="courseTimelineCreate.repeating" :label="`Wiederholend`"></v-switch>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <div
                                v-if="!courseTimelineCreate.repeating"
                                class="flex-column"
                                :class="{ 'd-flex': courseTimelineCreate.repeating }"
                            >
                                <v-card-text>
                                    Wähle aus von wann, bis wann der Kurs in der Kursliste ersichtlich sein sollte
                                </v-card-text>
                                <v-switch
                                    v-model="visibleAfter"
                                    :label="`Anzeigen ab: ${visibleAfterText} `"
                                ></v-switch>
                                <v-date-picker
                                    :allowed-dates="allowedDates(selectedDay)"
                                    :min="minDate"
                                    locale="de-CH"
                                    v-show="visibleAfter"
                                    v-model="courseTimelineCreate.visibleAfter"
                                ></v-date-picker>
                                <v-switch v-model="hideAfter" :label="`Anzeigen bis: ${hideAfterText} `"></v-switch>
                                <v-date-picker
                                    :allowed-dates="allowedDates(selectedDay)"
                                    :min="courseTimelineCreate.visibleAfter"
                                    locale="de-CH"
                                    v-show="hideAfter"
                                    v-model="courseTimelineCreate.hideAfter"
                                ></v-date-picker>
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-text-field
                                v-model="time"
                                class="pb-2"
                                type="time"
                                outlined
                                prepend-icon="mdi-clock-outline"
                                color="primary"
                                label="Uhrzeit"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-text-field
                                v-model="courseTimelineCreate.duration"
                                class="pb-2"
                                outlined
                                color="primary"
                                prepend-icon="mdi-timer-sand"
                                type="number"
                                label="Dauer in Minuten"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-row no-gutters v-for="instructor of courseTimelineCreate.instructors" :key="instructor">
                                <v-col cols="10">
                                    <user-search
                                        instructor
                                        :value="instructor"
                                        label="Instruktor"
                                        rights="gf_c_plan"
                                        @update="updateInstructor"
                                    ></user-search>
                                </v-col>
                                <v-col cols="2" class="d-flex justify-end">
                                    <v-btn class="my-auto" icon color="red" @click="removeInstructor(instructor)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-row no-gutters class="py-3">
                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="addInstructor">+ Instruktor</v-btn>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-btn class="mx-auto my-2" color="primary" @click="showIcons = !showIcons">
                                    <span v-if="showIcons">schliessen</span>
                                    <span v-else>Icon bearbeiten</span>
                                </v-btn>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-row v-if="showIcons" class="lottie-container-wrapper" no-gutters>
                                <v-col
                                    @click="courseTimelineCreate.icon = ic"
                                    class="lottie-container mb-2"
                                    cols="3"
                                    v-for="ic of icons"
                                    :key="ic"
                                >
                                    <lottie-player
                                        mode="normal"
                                        style="width: 50px; height: 50px"
                                        :class="{ active: courseTimelineCreate.icon === ic }"
                                        class="lottie m-auto"
                                        autoplay
                                        loop
                                        :src="'/api/gf/icons/' + ic"
                                    ></lottie-player>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-select
                                v-model="courseTimelineCreate.room"
                                :items="rooms"
                                item-text="name"
                                item-value="_id"
                                prepend-icon="mdi-door"
                                outlined
                                color="primary"
                                label="Raum"
                                single-line
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-switch
                                v-model="courseTimelineCreate.reqSignup"
                                :label="`Anmeldung wird benötigt`"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" class="px-0 pb-0">
                            <v-text-field
                                v-show="courseTimelineCreate.reqSignup"
                                v-model="courseTimelineCreate.amount"
                                class="pb-2"
                                outlined
                                color="primary"
                                prepend-icon="mdi-account-multiple"
                                type="number"
                                label="Anzahl Plätze"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../components/userSearch.vue'
import courseSearch from '../../components/courseSearch.vue'
export default {
    name: 'courseCreateTimeline',
    components: { userSearch, courseSearch },
    data() {
        return {
            time: '00:00',
            dialog: true,
            visibleAfter: false,
            minDate: null,
            showIcons: false,
            hideAfter: false,
            courseTimelineCreate: {
                amount: 0,
                room: null,
                duration: 0,
                instructors: [],
                day: 1,
                reqSignup: true,
                sendPush: false,
                course: null,
                repeating: true,
                visibleAfter: null,
                hideAfter: null,
                icon: null,
                lsLink: '',
            },
            labels: [],
            icons: [],
            rooms: [],
            days: [
                { value: 1, text: 'Montag' },
                { value: 2, text: 'Dienstag' },
                { value: 3, text: 'Mittwoch' },
                { value: 4, text: 'Donnerstag' },
                { value: 5, text: 'Freitag' },
                { value: 6, text: 'Samstag' },
                { value: 7, text: 'Sonntag' },
            ],
        }
    },
    destroyed() {
        document.body.style = 'overflow:auto;max-height:100vh;'
    },
    async created() {
        this.labels = await api.fetchGFLabels()
        document.body.style = 'overflow:hidden;max-height:100vh;'
        this.minDate = this.$moment().format('YYYY-MM-DD')
        this.setNavigationBarText({ false: 'Speichern', true: 'zurück', state: false })
        // this.fetchCourseTimelineEdit()
        this.courseTimelineCreate.hideAfter = this.$moment().format('YYYY-MM-DD')
        this.courseTimelineCreate.visibleAfter = this.$moment().format('YYYY-MM-DD')
        let rooms = await api.getRooms()
        this.rooms = rooms.room
        let icons = await api.fetchAllGFIcons()
        this.icons = icons.icons
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'toggleNavigationBarTextState']),
        nonRepeatingDateSelection(val) {
            this.courseTimelineCreate.visibleAfter = this.courseTimelineCreate.hideAfter
            this.courseTimelineCreate.day = this.$moment(val).day()
        },
        allowedDates(a) {
            return (val) => !a.includes(new Date(val).getDay())
        },

        fetchGFIcon(name) {
            api.fetchGFIcon(name).then((data) => {
                return data
            })
        },
        async saveCourseTimeline() {
            if (!this.visibleAfter && this.courseTimelineCreate.repeating) this.courseTimelineCreate.visibleAfter = null
            if (!this.hideAfter && this.courseTimelineCreate.repeating) this.courseTimelineCreate.hideAfter = null

            if (!this.courseTimelineCreate.course) {
                this.$toast.error('Bitte wähle einen Kurs aus')
                this.toggleNavigationBarTextState()
                return
            }

            if (this.time === '00:00') {
                this.$toast.error('Bitte gib eine Uhrzeit an')
                this.toggleNavigationBarTextState()
                return
            }

            if (!this.courseTimelineCreate.repeating && (!this.visibleAfter || !this.hideAfter)) {
                this.$toast.error('Bitte wähle ein Start- und Enddatum aus')
                this.toggleNavigationBarTextState()
                return
            }

            let formData = JSON.parse(JSON.stringify(this.courseTimelineCreate))
            formData.course = formData.course._id
            let resp = await api.createCourseTimeline(formData).catch((e) => {
                return false
            })
            this.$emit('updateList')
            this.closeDialog(true)
        },
        updateCourse(newCourse, oldCourse) {
            this.courseTimelineCreate.course = newCourse
        },
        removeInstructor(id) {
            let temp = this.courseTimelineCreate.instructors.filter((e) => {
                if (e === id) return false
                return true
            })
            this.courseTimelineCreate.instructors = []
            this.courseTimelineCreate.instructors = temp
        },
        addInstructor() {
            if (!this.courseTimelineCreate.instructors.includes(null)) this.courseTimelineCreate.instructors.push(null)
        },
        updateInstructor(newId, oldId) {
            if (!this.courseTimelineCreate.instructors.includes(newId)) {
                this.courseTimelineCreate.instructors = this.courseTimelineCreate.instructors.map((e) => {
                    if (e === oldId) {
                        return newId
                    } else {
                        return e
                    }
                })
            } else {
                this.courseTimelineCreate.instructors.pop()
            }
        },
        async fetchCourseTimelineEdit() {
            let courseTimeline = await api.fetchCourseTimeline(this.$route.params.id)

            this.courseTimelineCreate = courseTimeline.timeline

            let hours =
                this.courseTimelineCreate.hours < 10
                    ? '0' + this.courseTimelineCreate.hours
                    : this.courseTimelineCreate.hours
            let minutes =
                this.courseTimelineCreate.minutes < 10
                    ? '0' + this.courseTimelineCreate.minutes
                    : this.courseTimelineCreate.minutes
            this.time = hours + ':' + minutes + ':00'
            this.rooms = courseTimeline.rooms
        },

        closeDialog(resetTab = false) {
            this.setNavigationBarText(null)
            if (resetTab) {
                let tab = 0
                if (this.visibleAfter || this.hideAfter) tab = 1
                this.$emit('resetTab', tab)
            }
            this.dialog = false
            setTimeout(() => {
                this.$router.push('/kurslisten')
            }, 25)
        },
    },
    watch: {
        'courseTimelineCreate.repeating': function (val) {
            if (!val) {
                this.$toast.open({
                    type: 'info',
                    message: 'Aktiviere "Anzeigen ab" und "Anzeigen bis" um den Kurs in die Kursliste einzutragen',
                    duration: 7000,
                })
            }
        },
        time: function (val) {
            this.courseTimelineCreate.hours = val.split(':')[0]
            this.courseTimelineCreate.minutes = val.split(':')[1]
        },
        async navigationBarTextState(value) {
            if (value) {
                this.saveCourseTimeline()
            }
        },
    },
    computed: {
        selectedDay() {
            let day = this.courseTimelineCreate.day
            let days = [0, 1, 2, 3, 4, 5, 6]
            if (day === 7) day = 0
            days = days.filter((e) => e !== day)
            return days
        },
        visibleAfterText() {
            if (this.visibleAfter) {
                let split = this.courseTimelineCreate.visibleAfter.split('-')
                return split[2] + '.' + split[1] + '.' + split[0]
            } else {
                return 'Deaktiviert'
            }
        },
        hideAfterText() {
            if (this.hideAfter) {
                let split = this.courseTimelineCreate.hideAfter.split('-')
                return split[2] + '.' + split[1] + '.' + split[0]
            } else {
                return 'Deaktiviert'
            }
        },
        repeatingText() {
            if (this.courseTimelineCreate.repeating) return 'Aktiviert'
            return 'Deaktiviert'
        },
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
    },
}
</script>
<style lang="scss" scoped>
::v-deep .dialog {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.outer-wrapper {
}
.inner-wrapper {
    overflow-y: auto;
    overflow-x: hidden;

    height: 100vh !important;

    max-width: 500px !important;
    padding-top: 90px !important;
    padding-bottom: 160px !important;
    margin: 0px auto;
}

.courseEditTimeline {
    .lottie-container-wrapper {
        max-height: 200px;
        overflow: auto;
    }
    .lottie-container {
        .lottie {
            border: 1px solid grey !important;
            border-radius: 50%;
            background-color: var(--v-secondary) !important;
            height: 60px;
            width: 60px;
            &.active {
                background-color: var(--v-primary) !important;
            }
        }
    }
    &__container {
        position: relative;

        &-back {
            position: fixed;
            top: 20px;
            left: 20px;
            z-index: 2;
        }
    }
}
</style>
