<template>
    <div>
        <v-col cols="12">
            <v-img height="65px" @click="$router.push('/')" contain src="/img/roodyLogoGreen.png"></v-img>
        </v-col>
        <v-row no-gutters>
            <v-col cols="12">
                <v-card-title class="h3 font-weight-bold" style="word-break: break-word">
                    Vielen Dank für deinen Einkauf!
                </v-card-title>
            </v-col>
            <v-col cols="12">
                <v-card-text class="h4" style="word-break: break-word">
                    Du kannst deine gekauften Produkte / Gutscheine nun direkt herunterladen. Deine Einkäufe findest du im
                    Anschluss auch immer in deinem Profil unter "Einkäufe".
                </v-card-text>
            </v-col>

            <v-col cols="12" md="6" lg="4" xl="4" v-for="(product, index) of data[0].products" :key="'produkt-' + index">
                <v-card hover class="rounded-xl mx-2">
                    <v-img :aspect-ratio="1.6" :src="'/img/' + product.picture"></v-img>
                    <v-card-title>{{ product.title }}</v-card-title>
                    <v-card-text
                        ><p style="word-break: break-word">
                            für {{ product.personalization.prename + ' ' + product.personalization.lastname }}
                        </p>
                        <p>{{ product.personalization.text }}</p></v-card-text
                    >
                    <v-card-text>Preis: {{ 'CHF ' + product.price + '.-' }} </v-card-text>
                    <v-card-actions class="justify-content-center">
                        <v-btn icon color="primary" x-large @click="downloadFile(index)"
                            ><v-icon>mdi-file-download</v-icon></v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" class="text-center mt-4 mb-10">
                <v-btn color="primary" x-large @click="$router.push('/user')"> Zum Profil </v-btn>
            </v-col>
            <div v-if="false">
                <v-col cols="12">
                    <v-card-text class="h4" style="word-break: break-word">
                        Alternativ kannst du dir die PDF's per Mail zusenden lassen.
                    </v-card-text>
                </v-col>
                <v-col cols="8">
                    <v-text-field label="E-Mail Adresse"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-btn color="primary">Senden</v-btn>
                </v-col>
            </div>
        </v-row>
    </div>
</template>
  
  <script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
            data: { products: [] },
        }
    },
    async created() {
        if (this.$route && this.$route.params && this.$route.params.id) {
            this.data = await api.getPaymentInfos(this.$route.params.id)
            console.log(this.data)
        } else {
            this.$toast.error('Dein Einkauf wurde nicht gefunden oder konnte nicht deinem Account zugewiesen werden')
            this.$router.push('/')
        }
    },
    methods: {
        async downloadFile(index) {
            this.$toast.success('Dein Ausdruck wird generiert')
            await api.downloadRealVoucher({ _id: this.data[0]._id, product: index })
        },
    },
}
</script>
  