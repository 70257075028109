<template>
    <v-dialog :origin="dialogOrigin" persistent fullscreen :value="active" :close-on-back="closeOnBack">
        <v-card dark>
            <v-row class="justify-content-center ma-0">
                <v-col cols="auto">
                    <div class="app-bar__image">
                        <lottie-player @click="resetValidation()" mode="normal" style="height: 60px; width: 80px"
                            name="roody_icon" autoplay src="/img/lottiefiles/roody_animated.json"></lottie-player>
                    </div>
                </v-col>
                <v-card-title class="pb-1 font-weight-bold">| Registrieren</v-card-title>
            </v-row>
            <v-form @submit.prevent ref="registerForm" class="ma-auto" style="max-width: 800px" lazy-validation
                novalidate>
                <v-window v-model="registrationFormStepper" :touch="() => { }">
                    <v-window-item :value="1">
                        <v-card-title style="word-break: break-word">
                            Willkommen bei Roody, deinem Trainingspartner! Lass uns gemeinsam deinen Account erstellen.
                            <br />
                            Wie lautet deine E-Mail Adresse?
                        </v-card-title>
                        <v-text-field class="py-0 px-2" type="email" clearable filled color="primary"
                            placeholder="E-MAIL ADRESSE" required rounded v-model="formData.email"
                            :error-messages="emailAlreadyUsed ? 'Diese E-Mail Adresse ist bereits vergeben' : ''"
                            @blur="validateEmail" :loading="validatingEmail === true" validate-on-blur></v-text-field>
                        <div v-if="validatingEmail === 'validated'">
                            <v-card-title class="py-0" style="word-break: break-word">Setze dein Passwort</v-card-title>
                            <v-text-field v-if="requirePassword || forcePassword" class="py-0 px-2"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
                                filled color="primary" placeholder="PASSWORT" clearable rounded
                                v-model="formData.passwort" :rules="rules.password" validate-on-blur></v-text-field>
                            <v-alert type="info" v-if="!requirePassword && !forcePassword">
                                Dem Kunden wird eine E-Mail mit einem Link gesendet. Über diesen Link kann das Passwort
                                gesetzt werden.
                            </v-alert>
                            <v-checkbox v-if="!requirePassword" class="ml-2" v-model="forcePassword"
                                label="Passwort trotzdem setzen."></v-checkbox>
                        </div>
                    </v-window-item>
                    <v-window-item :value="2">
                        <v-card-title style="word-break: break-word">
                            Als nächstes benötige ich deinen Vornamen
                        </v-card-title>
                        <div v-if="registrationFormStepper === 2">
                            <v-text-field autofocus class="py-0 px-2" type="text" filled color="primary"
                                placeholder="VORNAME" rounded :rules="rules.required" v-model="formData.vorname"
                                validate-on-blur></v-text-field>
                        </div>
                    </v-window-item>
                    <v-window-item :value="3">
                        <v-card-title style="word-break: break-word">
                            Alles klar {{ formData.vorname }}. Und wie ist dein Nachname?
                        </v-card-title>
                        <div v-if="registrationFormStepper === 3">
                            <v-text-field autofocus class="py-0 px-2" type="text" filled color="primary"
                                placeholder="NACHNAME" :rules="rules.required" rounded v-model="formData.nachname"
                                validate-on-blur></v-text-field>
                        </div>
                    </v-window-item>
                    <v-window-item :value="4">
                        <v-card-title style="word-break: break-word">
                            Als nächstes benötige ich deine Adress Informationen, {{ formData.vorname }}.
                        </v-card-title>
                        <div v-if="registrationFormStepper === 4">
                            <v-text-field autofocus class="py-0 px-2" type="text" filled color="primary"
                                placeholder="ADRESSE" :rules="rules.required" rounded v-model="formData.adresse_1"
                                validate-on-blur></v-text-field>
                            <v-text-field class="py-0 px-2" filled color="primary" placeholder="PLZ"
                                :rules="rules.isNumber" rounded v-model.number="formData.plz"
                                validate-on-blur></v-text-field>
                            <v-text-field class="py-0 px-2" type="text" filled color="primary" placeholder="STADT"
                                :rules="rules.required" rounded v-model="formData.stadt"
                                validate-on-blur></v-text-field>
                            <v-text-field class="py-0 px-2" type="text" filled color="primary" placeholder="LAND"
                                :rules="rules.isString" rounded v-model="formData.land" validate-on-blur></v-text-field>
                            <v-text-field class="py-0 px-2" type="tel" filled color="primary" placeholder="TELEFON"
                                :rules="rules.telefon" rounded v-model="formData.telefon"
                                validate-on-blur></v-text-field>
                        </div>
                    </v-window-item>
                    <v-window-item :value="5">
                        <v-card-text class="white--text h5">Geschlecht auswählen</v-card-text>
                        <div v-if="registrationFormStepper === 5">
                            <v-row class="d-flex justify-space-around">
                                <v-col cols="6" :class="{ 'gender--selected': formData.geschlecht === 'm' }"
                                    class="gender d-flex flex-column align-center">
                                    <v-btn depressed icon @click="formData.geschlecht = 'm'">
                                        <lottie-player mode="normal" class="register__active-steps-check" name="male"
                                            style="height: 60px; width: 60px" :autoplay="false"
                                            src="/img/lottiefiles/whiteMan.json"></lottie-player>
                                    </v-btn>
                                    <span class="white--text mt-2">Männlich</span>
                                </v-col>
                                <v-col cols="6" :class="{ 'gender--selected': formData.geschlecht === 'w' }"
                                    class="gender d-flex flex-column align-center">
                                    <v-btn depressed icon @click="formData.geschlecht = 'w'">
                                        <lottie-player mode="normal" class="register__active-steps-check" name="female"
                                            style="height: 60px; width: 60px" :autoplay="false"
                                            src="/img/lottiefiles/whiteWoman.json"></lottie-player>
                                    </v-btn>
                                    <span class="white--text mt-2">Weiblich</span>
                                </v-col>
                            </v-row>
                            <div v-if="formData.geschlecht">
                                <!-- PICK BIRTHDATE -->
                                <v-text-field class="py-0 px-2" type="date" filled label="Geburtsdatum" color="primary"
                                    placeholder="GEBURTSDATUM" :rules="rules.required" rounded
                                    v-model="formData.geburtstag" validate-on-blur></v-text-field>
                                <v-text-field class="py-0 px-2" filled color="primary" placeholder="GEWICHT (optional)"
                                    type="number" rounded v-model.number="formData.gewicht" validate-on-blur
                                    suffix="kg"></v-text-field>
                                <v-text-field class="py-0 px-2" filled color="primary" placeholder="GRÖSSE (optional)"
                                    type="number" rounded v-model.number="formData.groesse" validate-on-blur
                                    suffix="cm"></v-text-field>
                            </div>
                        </div>
                    </v-window-item>
                    <v-window-item :value="6">
                        <div v-if="registrationFormStepper === 6">
                            <consent-declaration-card :age="$moment().diff($moment(formData.geburtstag), 'years')"
                                @savedSignature="saveSignature" darkMode></consent-declaration-card>
                            <v-card-title style="word-break: break-word">Haftungsausschluss</v-card-title>
                            <v-card-text>
                                *Haftungsausschluss. Zu Risiken und Nebenwirkungen frage doch immer Deinen Arzt oder
                                Apotheker! Das ist zwar lustig formuliert, bringt es aber auf den Punkt. Wir möchten
                                Dich bitten vorab von medizinischer Seite abklären zu lassen, ob Du fit für ein
                                Fitnesstraining bist. Wir übernehmen keine Haftung.
                            </v-card-text>
                            <div class="d-flex flex-column text-center align-center justify-content-center">
                                <span>Ich habe den Haftungsausschluss gelesen und akzeptiere ihn.</span>
                                <v-checkbox color="primary" class="mt-0" v-model="formData.rulesAccepted"
                                    :rules="rules.required" validate-on-blur></v-checkbox>
                            </div>
                            <div class="d-flex flex-column align-center justify-content-center">
                                <span>Newsletter abonnieren</span>
                                <v-checkbox class="mt-0" v-model="formData.newsletterAccepted"
                                    color="primary"></v-checkbox>
                            </div>
                            <v-select v-if="formData.newsletterAccepted" v-model="formData.newsletterCenter"
                                :items="center" label="Center" class="white--text v-input--is-focused px-5"
                                color="primary"></v-select>
                        </div>
                    </v-window-item>
                </v-window>
                <v-card-actions class="justify-content-center" style="padding-bottom: 100px">
                    <v-btn v-if="registrationFormStepper > 1 && !formData.rulesAccepted" x-large rounded color="grey"
                        class="ma-auto" @click="registrationFormStepper--">
                        zurück
                    </v-btn>
                    <v-btn x-large rounded color="primary" class="ma-auto" @click="nextStep()" v-if="showNextButton"
                        :disabled="!stepFinished">
                        {{ registrationFormStepper === 6 ? 'Registrierung abschliessen' : 'weiter' }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-btn icon color="grey" @click="$emit('close')" fixed x-large bottom left right class="ma-auto">
            <v-icon x-large>mdi-close-circle-outline</v-icon>
        </v-btn>
    </v-dialog>
</template>

<script>
import api from '../../api'
import { EventBus } from '../../event-bus'
import consentDeclarationCard from '../../components/consentDeclaration/consentDeclarationCard.vue'
export default {
    name: 'StepFormValidation',
    components: {
        consentDeclarationCard,
    },
    props: {
        dialogOrigin: {
            type: String,
            default: 'auto',
        },
        autoLogin: {
            type: Boolean,
            default: true,
        },
        active: {
            type: Boolean,
            default: true,
        },
        closeOnBack: {
            type: Boolean,
            default: true,
        },
        requirePassword: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            forcePassword: false,
            showPassword: false,
            dialog: true,
            forgotPassword: false,
            emailAlreadyUsed: false,
            validatingEmail: false,
            registrationFormStepper: 1,
            formData: {},
            center: [
                'Arbon',
                'Basel',
                'Bülach',
                'Dietlikon',
                'Frauenfeld',
                'Herisau',
                'Netstal',
                'Niederurnen',
                'Obfelden',
                'Oerlikon',
                'Pfungen',
                'Rikon',
                'Schwanden',
                'Sirnach',
                'St. Gallen Einstein',
                'St. Gallen Ost',
                'St. Gallen West',
                'Steckborn',
                'Wallisellen',
                'Wetzikon',
                'Winterthur',
            ],
            rules: {
                telefon: [
                    (v) => /^(?:[0-9]⋅?){6,14}[0-9]$/.test(v) || 'Bitte geben Sie eine gültige Telefonnummer ein',
                ],
                plz: [
                    (v) => !!v || 'Dieses Feld wird benötigt.',
                    (v) => v.length < 4 || 'Bitte geben sie eine gültige PLZ ein.',
                    (v) => /^\d*\.?\d+$/.test(v) || 'Bitte gib eine Zahl ein.',
                ],
                email: [
                    (v) => !!v || 'Die E-Mail wird benötigt',
                    (v) => /^[^@]+@[^@]+\.[^@]+$/.test(v) || 'Das ist keine gültige E-Mail',

                ],
                password: [
                    (v) => !!v || 'Bitte gib dein Passwort ein.',
                    (v) => (v && v.length >= 8) || 'Das Passwort muss mindestens 8 Zeichen lang sein.',
                ],
                isString: [
                    (v) => !!v || 'Dieses Feld wird benötigt.',
                    (v) => /^[a-zA-Z\u00C0-\u024F ]+$/.test(v) || 'Dieses Feld sollte keine Zahlen beeinhalten',
                ],
                isNumber: [
                    (v) => !!v || 'Dieses Feld wird benötigt.',
                    (v) => /^\d*\.?\d+$/.test(v) || 'Bitte gib eine Zahl ein.',
                ],
                isNumberNotRequired: [(v) => /^\d*\.?\d+$/.test(v) || 'Bitte gib eine Zahl ein.'],
                required: [(v) => !!v || 'Dieses Feld wird benötigt.'],
            },
            haftungsausschluss: false,
        }
    },
    created() {
        // old regex /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/
        this.setRegisterForm()
    },
    computed: {
        showNextButton() {
            if (this.registrationFormStepper === 6) return this.formData.rulesAccepted
            return this.validatingEmail === 'validated'
        },
        stepFinished() {
            if (
                this.registrationFormStepper === 1 &&
                this.formData.email &&
                (!this.requirePassword || this.forcePassword)
            )
                return true
            if (this.registrationFormStepper === 1 && !this.formData.passwort) return false
            if (this.registrationFormStepper === 2 && !this.formData.vorname) return false
            if (this.registrationFormStepper === 3 && !this.formData.nachname) return false
            if (
                this.registrationFormStepper === 4 &&
                (!this.formData.adresse_1 || !this.formData.plz || !this.formData.stadt || !this.formData.telefon)
            )
                return false
            if (this.registrationFormStepper === 5 && (!this.formData.geschlecht || !this.formData.geburtstag))
                return false
            if (
                this.registrationFormStepper === 6 &&
                (!this.formData.rulesAccepted || !this.formData.consentDeclarationSignature)
            )
                return false
            return this.$refs.registerForm.validate()
        },
    },
    methods: {
        saveSignature(signature) {
            this.formData.consentDeclarationSignature = signature
        },
        resetValidation() {
            this.$refs.registerForm.resetValidation()
        },
        register() {
            if (this.$refs.registerForm.validate()) {
                this.formData.requirePassword = this.requirePassword
                if (this.forcePassword) this.formData.requirePassword = true
                if (this.$route.params.eventId) this.registerForm.eventId = this.$route.params.eventId
                this.formData.geburtstag = this.$moment(this.formData.geburtstag).format('YYYY-MM-DD')
                api.registerUser(this.formData)
                    .then((data) => {
                        // TODO: emit a global event
                        if (this.$route.params.eventId) data.eventId = this.$route.params.eventId
                        this.autoLogin ? EventBus.$emit('login', data) : this.$emit('register', data)
                        this.setRegisterForm()
                        this.registrationFormStepper = 1
                        this.forcePassword = false
                        this.showPassword = false
                        this.forgotPassword = false
                        this.emailAlreadyUsed = false
                        this.validatingEmail = false
                    })
                    .catch((err) => {
                        if (err.response.data.name == 'MongoError') {
                            alert('E-Mail bereits registriert, bitte melden Sie sich an')
                        } else {
                            alert('Fehler beim Registrieren, bitte überprüfen Sie Ihre Eingaben')
                        }
                    })
            } else {
                alert('Fehler beim Registrieren, bitte überprüfen Sie Ihre Eingaben')
            }
        },
        nextStep() {
            if (this.registrationFormStepper === 6) {
                this.register()
            } else {
                this.resetValidation()
                this.registrationFormStepper++
            }
        },
        async validateEmail() {
            if (this.$refs.registerForm.validate()) {
                this.validatingEmail = true
                this.emailAlreadyUsed = await api.getEmailAlreadyUsed({ email: this.formData.email })
                this.validatingEmail = this.emailAlreadyUsed ? false : 'validated'
            }
        },
        setRegisterForm() {
            console.log('setRegisterForm')
            this.formData = {
                email: '',
                consentDeclarationSignature: null,
                passwort: '',
                vorname: null,
                nachname: null,
                adresse_1: null,
                adresse_2: null,
                plz: null,
                stadt: null,
                land: 'Schweiz',
                telefon: null,
                geschlecht: '',
                geburtstag: null,
                gewicht: null,
                groesse: null,
                bild: null,
                error: false,
                active: false,
                request: false,
                birthDayPickerActive: false,
                birthDayPickerState: '',
                errorMessage: '',
                rulesAccepted: false,
                newsletterAccepted: false,
                newsletterCenter: 'Frauenfeld',
                eventId: null,
            }
        },
    },
}
</script>

<style lang="scss">
.v-dialog-transition {
    /* Override default transition */
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}

::v-deep .v-text-field__slot {
    input {
        color: white !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
}

.gender {
    opacity: 0.5;

    &--selected {
        opacity: 1;
    }
}
</style>
