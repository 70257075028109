<template>
    <v-card dark class="mx-auto py-3">
        <v-row v-if="loading">
            <v-col cols="12">
                <v-progress-linear class="my-5 px-5" indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" v-if="holidayEvents.length === 0">
                <v-alert class="mx-5" type="info">Keine Daten vorhanden</v-alert>
            </v-col>
            <v-col cols="12" v-else>
                <v-row>
                    <v-col cols="12" class="d-flex justify-center my-2">
                        <v-chip color="green" pill>Ferien</v-chip>
                        <v-chip class="mx-2" color="orange" pill>Offene Ferienanträge</v-chip>
                        <v-chip color="red" pill>Abwesend</v-chip>
                    </v-col>
                </v-row>
                <v-sheet height="800">
                    <v-calendar
                        locale="de-CH"
                        ref="calendar"
                        dark
                        v-model="selectedMonthCalendar"
                        :now="$moment().format('YYYY-MM-DD')"
                        :weekdays="weekdays"
                        :type="'month'"
                        :events="holidayEvents"
                        :event-color="(event) => event.color"
                        :event-text-color="'white'"
                    >
                        <template #event="{ event }">
                            <div class="d-flex justify-center text-center">
                                {{ event.employeeName }}
                                {{ event.color === 'red' ? '\n  (' + typeItems[event.absenceReason] + ')' : '' }}
                            </div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-col>
        </v-row>
    </v-card>
</template>
  
  <script>
import api from '@/api'
export default {
    name: 'HolidayEntriesView',
    props: {
        datePickerRange: {},
        selectedStudioSingle: {
            required: true,
        },
        studioManager: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: true,
            selectedMonth: this.$moment().startOf('month').format('YYYY-MM'),
            datePickerDialog: false,
            holidayEvents: [],
            weekdays: [1, 2, 3, 4, 5, 6, 0],
            typeItems: {
                sick: 'Krank',
                school: 'Schule',
                military: 'Militär',
                pregnancy: 'Schwangerschaft',
                other: 'Andere',
            },
        }
    },
    created() {
        this.fetchEntries()
    },
    methods: {
        async fetchEntries() {
            this.loading = true
            try {
                let data = await api.fetchHolidayEvents({
                    datePickerRange: this.datePickerRange,
                    selectedStudioSingle: this.selectedStudioSingle,
                    studioManager: this.studioManager,
                })
                this.holidayEvents = data
                this.loading = false
            } catch (e) {
                this.$toast.error('Fehler beim Laden der Ferientage')
                this.holidayEvents = []
                this.loading = false
            }
        },
    },
    computed: {
        selectedMonthCalendar() {
            return this.$moment(this.datePickerRange[0]).startOf('month').format('YYYY-MM-DD')
        },
    },
    watch: {
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.fetchEntries()
            }
        },
        async selectedStudioSingle() {
            this.fetchEntries()
        },
    },
}
</script>
  
  <style scoped>
</style>
  