<template>
	<div class="kurse">
		<div class="filter">
			<div class="title">Aktueller Kursplan</div>
			<div class="filter-options">
				<select @change="filterListCourse" v-model="selectedCourse">
					<option value="all">
						Alle Kurse
					</option>
					<option :value="course" v-for="(course, j) in courseList" :key="j">
						{{ course }}
					</option>
				</select>
				<select @change="filterListInstructor" v-model="selectedInstructor">
					<option value="all">
						Alle Instruktoren
					</option>
					<option :value="ins" v-for="(ins, i) in instructorList" :key="i">
						{{ ins }}
					</option>
				</select>
			</div>
		</div>
		<div class="no-courses" v-show="course_date_array.length == 0">
			Aktuell finden keine Kurse statt
		</div>
		<div class="no-courses" v-show="noCourses">
			Es wurden leider keine Kurse gefunden, die deinen Filteroptionen entsprechen
		</div>
		<div v-show="course_formatted[datum].length > 0" v-for="(datum, i) in course_date_array" :key="i" class="kurse-wrapper">
			<div class="kurs-datum-titel">{{ datum }}</div>
			<ul class="kurs-wrapper-date">
				<li v-for="(kurs, i) in course_formatted[datum]" :key="i">
					<div class="link-wrapper">
						<div @click="showCourseInfo(kurs.course_id, kurs.instructor_name)">
							<div class="kurs-name">
								{{ kurs.course_name }}
							</div>
							<div class="kurs-zeit">
								{{ TimestampToTime(kurs.timestamp) }} -
								{{ TimestampToTime(kurs.timestamp_end) }}
							</div>
							<div class="kurs-instruktor-raum">{{ kurs.instructor_name }}, {{ kurs.room_name }}</div>
							<span class="material-icons">navigate_next</span>
							<div class="labels">
								<div :style="'background-color: ' + label.color" class="label" v-for="(label, ji) of labels[kurs._id]" :key="ji">
									{{ label.code }}
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="show-info" v-show="showinfo">
			<div class="show-info-content">
				<img class="course-bild" :src="'/img/kurse/' + course_info.bild" />
				<span class="course-instructor">Instruktor/in: {{ course_info.instruktor }}</span>
				<div class="course-info">
					<span class="course-titel">{{ course_info.name }}</span>
					<span class="course-beschreibung">{{ course_info.beschreibung }}</span>
				</div>
				<div class="schliessen" @click="showinfo = false">Zurück</div>
			</div>
		</div>
		<div class="download-kursliste" @click="downloadKursliste()">
			Kursliste herunterladen
		</div>
	</div>
</template>

<script>
import api from '../api'
import $ from 'jquery'
import { jsPDF } from 'jspdf'

export default {
	data() {
		return {
			noCourses: false,
			selectedInstructor: 'all',
			selectedCourse: 'all',
			instructorList: [],
			courseList: [],
			studio_id: null,
			signups: [],
			labels: [],
			course_timelines: [],
			course_formatted: [],
			course_date_array: [],
			isAdmin: false,
			x: null,
			rollen: {},
			showinfo: false,

			course_info: {
				name: '',
				bild: '',
				beschreibung: '',
				instruktor: '',
			},
		}
	},
	beforeDestroy() {},
	methods: {
		filterListInstructor() {
			this.formatTimeline()
		},
		filterListCourse() {
			this.formatTimeline()
		},
		downloadKursliste() {
			let doc = new jsPDF({
				orientation: 'p',
				unit: 'mm',
				format: [297, 210],
			})
			doc.setFontSize(11)
			let x_table = 5
			let y_table = 10
			let labeltxt = []
			let studio_name = ''
			let page = 1
			api.getStudios().then((data) => {
				for (let studio of data) {
					if (studio._id == this.studio_id) {
						studio_name = studio.art + ' | ' + studio.name
					}
				}

				if (studio_name.length > 18) {
					studio_name = studio_name.substring(0, 19) + '...'
				}

				doc.text(studio_name, 10, 10)

				doc.text('Seite ' + page, 185, 290)

				for (let datum of this.course_date_array) {
					if (this.course_formatted[datum].length > 0) {
						y_table += 7

						doc.setFontSize(12)
						x_table = 10
						doc.text(datum, x_table, y_table)

						x_table = 10
						doc.setFontSize(10)
						y_table += 4
						for (let kurs of this.course_formatted[datum]) {
							if (
								this.labels[kurs._id].filter((i) => {
									return i.code == 'SOMMERPAUSE' || i.text == 'SOMMERPAUSE'
								}).length == 0
							) {
								//kurs.instructor_name
								//kurs.room_name
								y_table += 2
								labeltxt = []
								for (let label of this.labels[kurs._id]) {
									//label.code
									labeltxt.push(label.code)
								}

								if (kurs.course_name) {
									labeltxt = labeltxt.join(', ')
									if (labeltxt) {
										let kurstext = kurs.course_name.toString() + ' - ' + labeltxt
										if (kurstext.length > 38) {
											kurstext = kurstext.substring(0, 35) + '...'
										}

										doc.text(kurstext, x_table, y_table)
									} else {
										doc.text(kurs.course_name.toString(), x_table, y_table)
									}
								}
								if (kurs.room_name) {
									let room_name = kurs.room_name.toString()
									if (room_name.length > 20) {
										room_name = room_name.substring(0, 20) + '...'
									}

									doc.text(room_name, 110, y_table)
								}

								if (kurs.timestamp && kurs.timestamp_end) {
									doc.text(this.TimestampToTime(kurs.timestamp) + ' - ' + this.TimestampToTime(kurs.timestamp_end), 80, y_table)
								}
								if (kurs.instructor_name) {
									doc.text(kurs.instructor_name, 150, y_table)
								}

								y_table += 2
								doc.line(10, y_table, 200, y_table)
								y_table += 2

								x_table = 10
								if (y_table > 280) {
									doc.addPage()

									page++
									doc.text(studio_name, 10, 10)
									doc.text('Seite ' + page, 185, 290)
									y_table = 20
								}
							}
						}
					}
				}

				//

				var is_chrome = navigator.userAgent.indexOf('Chrome') > -1
				var is_explorer = navigator.userAgent.indexOf('MSIE') > -1
				var is_firefox = navigator.userAgent.indexOf('Firefox') > -1
				var is_safari = navigator.userAgent.indexOf('Safari') > -1
				var is_opera = navigator.userAgent.toLowerCase().indexOf('op') > -1
				if (is_chrome && is_safari) {
					is_safari = false
				}
				if (is_chrome && is_opera) {
					is_chrome = false
				}
				if (is_safari) {
					doc.save('Kursliste')
				} else {
					var blob = doc.output('blob')
					window.open(URL.createObjectURL(blob))
				}
			})
		},
		showCourseInfo(kursid, instructor) {
			api.getKursById(kursid)
				.then((data) => {
					this.showinfo = true

					this.course_info.name = data.course[0].name
					this.course_info.beschreibung = data.course[0].beschreibung
					this.course_info.bild = data.course[0].bild
					this.course_info.instruktor = instructor
				})
				.catch((error) => {
					console.log(error)
				})
		},
		TimestampToTime(timestamp) {
			timestamp = parseInt(timestamp)

			let hours = new Date(timestamp * 1000).getHours()

			let minutes = new Date(timestamp * 1000).getMinutes()
			if (minutes < 10) {
				minutes = '0' + minutes
			}
			if (hours < 10) {
				hours = '0' + hours
			}
			let value = hours + ':' + minutes
			return value
		},
		formatTimeline() {
			let courses_found = false
			let course_formatted_temp = []

			this.course_timelines.forEach((course_timeline) => {
				if (this.instructorList.indexOf(course_timeline.instructor_name) === -1) {
					this.instructorList.push(course_timeline.instructor_name)
				}

				if (this.courseList.indexOf(course_timeline.course_name) === -1) {
					this.courseList.push(course_timeline.course_name)
				}

				var date = new Date(course_timeline.timestamp * 1000).toLocaleDateString()
				var day = new Date(course_timeline.timestamp * 1000).getDay()
				let day_string = ''
				switch (day) {
					case 0:
						day_string = 'Sonntag, '
						break
					case 1:
						day_string = 'Montag, '
						break
					case 2:
						day_string = 'Dienstag, '
						break
					case 3:
						day_string = 'Mittwoch, '
						break
					case 4:
						day_string = 'Donnerstag, '
						break
					case 5:
						day_string = 'Freitag, '
						break
					case 6:
						day_string = 'Samstag, '
						break
				}
				date = day_string + date

				course_formatted_temp.push({
					course_timeline,
					date: date,
				})
			})

			this.course_date_array = []
			this.course_formatted = []
			course_formatted_temp.forEach((kurs, i) => {
				this.course_date_array.push(kurs.date)
				if (!this.course_formatted[kurs.date]) {
					this.course_formatted[kurs.date] = []
				}

				if (this.selectedInstructor !== 'all' || this.selectedCourse !== 'all') {
					let check = false
					if (this.selectedInstructor !== 'all') {
						if (kurs.course_timeline.instructor_name == this.selectedInstructor) {
							check = true
						}
					}
					if (this.selectedCourse !== 'all') {
						if (kurs.course_timeline.course_name == this.selectedCourse) {
							check = true
						}
					}
					if (this.selectedCourse !== 'all' && this.selectedInstructor !== 'all') {
						check = false
						if (
							kurs.course_timeline.course_name == this.selectedCourse &&
							kurs.course_timeline.instructor_name == this.selectedInstructor
						) {
							check = true
						}
					}
					if (check) {
						courses_found = true
						this.course_formatted[kurs.date].push(kurs.course_timeline)
					}
				} else {
					courses_found = true
					this.course_formatted[kurs.date].push(kurs.course_timeline)
				}
			})
			this.instructorList.sort()
			this.courseList.sort()
			this.noCourses = !courses_found

			this.course_date_array = [...new Set(this.course_date_array)]
		},
		refreshList() {
			api.getKursetimelinesGeneratedEx(this.studio_id)
				.then((data_kg) => {
					if (this.course_timelines.length == 0 || data_kg.course_generates.length !== this.course_timelines.length) {
						this.course_timelines = data_kg.course_generates

						this.formatTimeline()
						this.loadLabels(data_kg)
					} else {
						for (let cg of data_kg.course_generates) {
							let found = false
							for (let cga of this.course_timelines) {
								if (cg.course_timeline_id == cga.course_timeline_id) {
									found = true
								}
							}
							if (!found) {
								this.loadLabels(data_kg)
								this.course_timelines = data_kg.course_generates
								this.formatTimeline()
							}
						}
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		loadLabels(data_kg) {
			let gna_ids = []
			for (let gna of data_kg.course_generates) {
				gna_ids.push(gna.course_timeline_id)
			}

			api.getLabelsByGenTimelines({ course_timeline_ids: gna_ids })
				.then((data) => {
					this.labels = []
					for (let gna of data_kg.course_generates) {
						let found_labels = false
						for (let tl of data.timelines_labels) {
							if (tl.course_timeline_id == gna.course_timeline_id) {
								found_labels = true
								if (!this.labels[gna._id]) {
									this.labels[gna._id] = []
								}
								this.labels[gna._id].push(tl.label)
							}
						}
						if (!found_labels) {
							this.labels[gna._id] = []
						}
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
	},
	created() {
		this.studio_id = this.$route.params.id
		this.refreshList()
	},
}
</script>

<style lang="scss" scoped>
.filter {
	background-color: var(--v-primary);
	color: white;
	padding: 5px 15px;

	&-options {
		display: flex;
		flex-direction: column;
	}
	select {
		color: white;
		padding: 10px 0px;

		option {
			color: black;
		}
	}
}
.download-kursliste {
	position: fixed;
	bottom: 50px;
	right: calc(50% - 150px);
	width: 300px;
	height: 50px;
	line-height: 50px;
	border-radius: 5px;
	background-color: var(--v-primary);
	color: white;
	text-align: center;
	cursor: pointer;
}
.show-info {
	display: flex;
	width: 100vw;
	flex-direction: column;
	height: 100vh;
	position: fixed;
	justify-content: center;
	top: 0;
	z-index: 99999;
	background-color: white;
	.show-info-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-height: 80vh;
		overflow: scroll;
	}
	.course-bild {
		height: 170px;

		object-fit: contain;
		width: 320px;
		max-width: 80vw;
		margin: 10px auto;
	}

	.course-instructor {
		width: 320px;
		max-width: 80vw;
		margin: 10px auto;
	}
	.course-info {
		width: 320px;
		max-width: 80vw;
		margin: 0px auto 10px auto;
		display: flex;
		flex-direction: column;
		.course-titel {
			font-weight: bold;
		}
		.course-beschreibung {
			font-size: 14px;
		}
	}

	.schliessen {
		padding: 10px 5px;
		background-color: var(--v-primary);

		width: 190px;
		text-align: center;
		border-radius: 20px;
		color: white;
		margin: 10px auto;
	}
}
.labels {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.label {
		background-color: rgb(225, 81, 81);
		display: inline-block;
		padding: 2px 10px;
		margin-top: 8px;
		margin-right: 10px;
		border-radius: 3px;
		color: white !important;
		font-size: 12px;
		font-weight: bold;
	}
}

.no-courses {
	color: #4a4a4a;
	padding: 10px;
}
.kurse-wrapper {
	color: white;

	.kurs-datum-titel {
		background-color: var(--v-primary);
		padding: 5px 15px;

		font-size: 14px;
	}

	.kurs-wrapper-date {
		padding: 0px;
		margin: 0px;
		li {
			padding: 10px 15px;
			color: #4a4a4a;
			border-bottom: 1px solid grey;
			position: relative;

			&:last-of-type {
				border-bottom: none;
			}

			.kurs-zeit,
			.kurs-instruktor-raum {
				font-weight: 300;
			}

			.kurs-name {
				display: flex;
				.kurs-platz {
					margin-left: auto;
					margin-right: 50px;
				}
			}

			* {
				color: #4a4a4a;
				font-size: 14px;
			}
			span.material-icons {
				position: absolute;
				top: 50%;
				transform: translatey(-50%);
				right: 15px;
				font-size: 34px;

				&.sup {
					color: var(--v-primary);
					font-size: 30px;
				}
			}
		}
	}
}
.edit-kurse {
	background-color: #424242;
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	a {
		display: flex;
		padding: 5px;
		color: white;
		width: 100%;
		justify-content: space-between;
		text-decoration: none;
		span {
			margin-top: auto;
			margin-bottom: auto;
		}
		span.material-icons {
			font-size: 34px;
		}
	}
}
</style>
