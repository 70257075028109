const moment = require('moment')
const api = require('../../api').default
export class wakeUpHabit {
	constructor(
		wakeUpHabit = {
			label: 'Aufwachen',
			type: 'wakeUpHabit',
			description: '',
			icon: 'wakeUp.png',
			streak: 0,
			days: [],
			notificationTime: '',
			executions: {},
		}
	) {
		this.setWakeUpHabit(wakeUpHabit)
	}
	getWakeUpHabit() {
		let habit = {}
		for (let key in this) {
			habit[key] = this[key]
		}
		return habit
	}
	setWakeUpHabit(habit) {
		for (let key in habit) {
			this[key] = habit[key]
		}
	}
	async execute(time) {
		// add execution and save habit
		this.executions[moment().format('YYYY-MM-DD')] = time
		return await this.save()
	}
	async delete() {
		try {
			return await api.deleteHabit(this._id)
		} catch (e) {
			console.log(e)
		}
	}
	async save() {
		try {
			let habit = await api.updateHabit(this)
			this.setWakeUpHabit(habit)
		} catch (error) {
			console.log('Error updating or saving habit: ', error)
		}
	}
}
