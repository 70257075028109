<template xmlns="">
    <div class="working-out-exercise-add-set">
        <v-btn class="abort-button" style="z-index: 1000" absolute top right icon small @click="abort">
            <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
        <div v-if="shownSetInput === 'weightRep'" class="d-flex flex-column justify-space-between fill-height">
            <v-card-title class="font-weight-bold h3 black--text">
                {{
                    navigationBarUsageData.index === 0 || navigationBarUsageData.index
                        ? 'Satz ' + Number(navigationBarUsageData.index + 1) + ' bearbeiten'
                        : 'Satz eintragen'
                }}
            </v-card-title>
            <v-row no-gutters>
                <v-col cols="6" class="pa-1 content-center">
                    <v-text-field
                        label="Gewicht"
                        class="elevation-0"
                        background-color="#dcdcdc"
                        v-model="form.weight"
                        suffix="kg"
                        hide-details
                        solo
                        :disabled="false"
                        :readonly="false"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" class="pa-1 align-center items-center align-items-center">
                    <v-text-field
                        class="elevation-0"
                        label="Wiederholungen"
                        v-model="form.reps"
                        background-color="#dcdcdc"
                        suffix="WH"
                        hide-details
                        solo
                        :disabled="false"
                        :readonly="false"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-card-actions class="pt-0 justify-center">
                <div class="rounded-lg pa-3" style="background: gainsboro" @click="saveSetToStore()">
                    <span class="black--text h5">speichern</span>
                </div>
            </v-card-actions>
        </div>

        <div v-if="shownSetInput === 'sensoPro'" class="d-flex flex-column justify-space-between">
            <v-card-title class="font-weight-bold h3 black--text">Notiz</v-card-title>
            <v-text-field
                id="noteTextField"
                height="100px"
                @change="writtenText"
                v-model="form.note"
                :readonly="false"
                :disabled="false"
                autofocus
                label="Geben Sie hier ihre Notiz ein..."
            ></v-text-field>
            <v-card-actions class="pt-0 justify-center">
                <div class="rounded-lg pa-3" style="background: gainsboro" @click="saveSetToStore()">
                    <span class="black--text h5">speichern</span>
                </div>
            </v-card-actions>
        </div>
        <workingout-exercise-stopwatch
            v-if="shownSetInput === 'time'"
            :given-time="form.time"
        ></workingout-exercise-stopwatch>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import WorkingoutExerciseStopwatch from './WorkingoutExerciseStopwatch.vue'

export default {
    components: {
        WorkingoutExerciseStopwatch,
    },

    name: 'WorkingoutExerciseAddSet',
    data() {
        return {
            form: {
                weight: null,
                reps: null,
                note: '',
                time: null,
            },
            shownSetInput: '',
        }
    },
    created() {
        console.log('add new set created')
        let hasWeight = false
        let hasReps = false
        let hasTime = false
        let isSensoPro = false
        this.navigationBarUsageData.settings.forEach((setting) => {
            if (setting.text === 'Gewicht') {
                this.form.weight = this.navigationBarUsageData.weight
                    ? this.navigationBarUsageData.weight
                    : setting.value
                if (!this.form.reps) this.form.reps = 0
                hasWeight = true
            }
            if (setting.text.toString() === 'WH ') {
                this.form.reps = setting.value
            }
            if (setting.text.toString() === 'Min Wiederholungen') {
                this.form.reps = this.navigationBarUsageData.reps ? this.navigationBarUsageData.reps : setting.value
                hasReps = true
            }
            if (setting.text.toString() === 'Max Wiederholungen' && !hasReps) {
                this.form.reps =
                    this.navigationBarUsageData.reps !== 0 ? this.navigationBarUsageData.reps : setting.value
                hasReps = true
            }
            if (setting.text.toString() === 'Wiederholungen' && !hasReps) {
                this.form.reps =
                    this.navigationBarUsageData.reps !== 0 ? this.navigationBarUsageData.reps : setting.value
                hasReps = true
            }
            if (setting.text === 'Dauer') {
                this.form.time = this.navigationBarUsageData.time !== 0 ? this.navigationBarUsageData.time : null
                hasTime = true
            }
            if (this.navigationBarUsageData.title === 'Senso Pro') {
                isSensoPro = true
                this.form.note = this.navigationBarUsageData.form ? this.navigationBarUsageData.form.note : ''
            }
        })
        if (hasWeight && hasReps && !hasTime) {
            this.shownSetInput = 'weightRep'
        } else if (isSensoPro) {
            this.shownSetInput = 'sensoPro'
        } else if (hasTime) {
            this.shownSetInput = 'time'
        } else {
            this.shownSetInput = 'weightRep'
        }
    },
    methods: {
        ...mapActions('workingoutStore', ['saveSet']),
        ...mapActions('navigationStoreNotPersisted', ['setNewBottomNavigationUsage']),

        toggleField(field) {
            console.log('hallo', field)
            console.log('test', document.getElementById(field))
            document.getElementById(field).focus()
            console.log('test', document.getElementById(field))
        },
        abort() {
            this.savedSet ? this.saveSet(null) : this.saveSet('reset')
        },
        saveSetToStore(object = null, type = null) {
            let set = JSON.parse(JSON.stringify(this.form))
            if (this.navigationBarUsageData.index !== null) set.index = this.navigationBarUsageData.index
            if (type === 'time') set.time = object
            this.saveSet(set)
        },
    },
    computed: {
        ...mapState('workingoutStore', ['stoppedDuration', 'savedSet']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarUsageData']),
        swiper() {
            return this.$refs.addSetSwiper.$swiper
        },
        addSetSwiperOptions: function () {
            return {
                allowTouchMove: false,
            }
        },
        hasWeight: function () {
            return this.findSettingByTitle('Gewicht') && this.findSettingByTitle('Gewicht').value
        },
        hasReps: function () {
            return this.findSettingByTitle('Min Wiederholungen') && this.findSettingByTitle('Min Wiederholungen').value
        },
        hasStopwatch: function () {
            if (this.findSettingByTitle('Dauer') && this.findSettingByTitle('Dauer').value && !this.isSensoPro)
                return true
            if (this.findSettingByTitle('Dehndauer') && this.findSettingByTitle('Dehndauer').value && !this.isSensoPro)
                return true
        },
    },
    watch: {
        stoppedDuration(value) {
            this.saveSetToStore(value, 'time')
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot {
    input {
        text-align: center;
    }
    box-shadow: null !important;
}
.abort-button {
    height: unset !important;
    position: absolute !important;
}
.working-out-exercise-add-set {
    width: 80vw;
    max-width: 500px;
    height: 100%;
    background: white;
}
::v-deep .inputDialogWeight {
    margin-bottom: auto;
}
</style>
