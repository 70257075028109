<template>
	<div class="leadReferral">
		<v-expansion-panels v-model="panel">
			<v-expansion-panel v-for="(studio, i) of studios" :key="studio._id">
				<v-expansion-panel-header> {{ studio.name }} </v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-row>
						<v-col cols="12">
							<v-switch
								v-if="!(studio._id in usedStudios)"
								@change="toggleStudioUseLeadSetting(studio._id, studio.useLeadSetting)"
								v-model="studio.useLeadSetting"
								:key="studio._id + 'useLeadSetting'"
								:label="studio.useLeadSetting ? 'Von anderem Studio übernehmen' : 'Eigene Einstellungen verwenden'"
							></v-switch>
							<v-alert v-else type="info" class="mt-5" outlined>
								Dieses Formular wird von anderen Studios verwendet
							</v-alert>
						</v-col>

						<v-col v-if="studio.useLeadSetting" cols="12">
							<v-select
								v-model="studio.leadSettingId"
								hint="Einstellungen werden von dem ausgewählten Studio übernommen"
								:items="preBuiltLeadSettings.filter((e) => e.studioId !== studio._id)"
								item-text="name"
								item-value="_id"
								label="Studios"
								persistent-hint
								single-line
							></v-select>
						</v-col>
						<v-col v-else cols="12">
							<v-card-title class="mx-0 px-0">Formular Einstellungen</v-card-title>
							<v-card-text v-if="studio.leadSetting.createdAt" class="pb-0 pt-0 px-0"
								>Erstellt am: {{ studio.leadSetting.createdAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-text
							>
							<v-card-text v-if="studio.leadSetting.updatedAt" class="pb-5 pt-0 px-0"
								>Bearbeitet am: {{ studio.leadSetting.updatedAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-text
							>
							<v-switch v-model="studio.leadSetting.active" :label="studio.leadSetting.active ? 'Aktiv' : 'Inaktiv'"></v-switch>
							<v-card-text class="pl-0 pb-0 font-weight-bold">Beispiellink (für Neukunden - von Kunden versendet): </v-card-text>
							<v-card-text class="py-0" v-for="(host, y) of hosts" :key="y">{{ host }}/r/support@roody.ch</v-card-text>

							<v-card-text class="pl-0 pb-0 font-weight-bold">Links für Weiterempfehlung (Für Kunden - von roody): </v-card-text>

							<v-expansion-panels>
								<v-expansion-panel v-for="(host, y) of hosts" :key="y + '-hostLinks'">
									<v-expansion-panel-header> {{ host }}/referral</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-row>
											<v-col cols="12"><img :src="'/api/qrcode/image?data=' + host + '/referral'"/></v-col>
											<v-col cols="12">
												<v-btn class="mr-5 white--text" @click="downloadQRCode(host + '/referral')" color="primary"
													>QR Code herunterladen</v-btn
												>
												<v-btn class="mr-5 white--text" @click="copyLink(host + '/referral')" color="primary"
													>Link kopieren</v-btn
												>
											</v-col>
										</v-row>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>

							<v-card-subtitle class="mx-0 px-0">Internes Bild</v-card-subtitle>
							<slim-cropper class="mx-auto mt-10" :options="slimOptionsPrivate" style="width: 160px; height: 160px;">
								<img v-if="studio.leadSetting.internPicture" :src="'/img' + studio.leadSetting.internPicture" alt="" />
								<input type="file" accept="image/*" />
							</slim-cropper>
							<v-card-subtitle class="mx-0 px-0">Öffentliches Bild</v-card-subtitle>
							<slim-cropper class="mx-auto mt-10" :options="slimOptionsPublic" style="width: 160px; height: 160px;">
								<img v-if="studio.leadSetting.publicPicture" :src="'/img' + studio.leadSetting.publicPicture" alt="" />
								<input type="file" accept="image/*" />
							</slim-cropper>
							<v-text-field class="mt-10" disabled v-model="studio.leadSetting.title" label="Bezeichnung" />
							<v-card-subtitle class="mx-0 px-0">Beschreibung</v-card-subtitle>
							<vue-editor :editorToolbar="customToolbar" v-model="studio.leadSetting.description"></vue-editor>
							<v-card-subtitle class="mx-0 px-0">interne Bedingungen</v-card-subtitle>
							<vue-editor :editorToolbar="customToolbar" v-model="studio.leadSetting.internConditions"></vue-editor
							><v-card-subtitle class="mx-0 px-0">öffentliche Bedingungen</v-card-subtitle>
							<vue-editor :editorToolbar="customToolbar" v-model="studio.leadSetting.publicConditions"></vue-editor>
							<v-textarea
								v-model="studio.leadSetting.referralText"
								label="Weiterempfehlungstext (z.B. teilen per Whatsapp)"
							></v-textarea>
							<v-switch
								v-model="studio.leadSetting.price.active"
								:label="studio.leadSetting.price.active ? 'Rabatt Aktiv' : 'Rabatt Inaktiv'"
							></v-switch>
							<v-text-field
								v-if="studio.leadSetting.price.active"
								type="number"
								v-model="studio.leadSetting.price.value"
								label="Preisreduktion"
							></v-text-field>
							<v-switch
								v-model="studio.leadSetting.showContactInfo"
								:label="studio.leadSetting.showContactInfo ? 'Kontaktinformationen anzeigen' : 'Kontaktinformationen ausblenden'"
							></v-switch>
							<v-switch
								v-model="studio.leadSetting.leadFields.active"
								:label="studio.leadSetting.leadFields.active ? 'Kontaktfelder anzeigen' : 'Kontaktfelder ausblenden'"
							></v-switch>
							<v-switch
								class="ml-5 pt-0 mt-0 mb-0"
								v-if="studio.leadSetting.leadFields.active"
								v-model="studio.leadSetting.leadFields.name"
								label="Vorname"
							></v-switch>
							<v-switch
								class="ml-5 pt-0 mt-0 mb-0"
								v-if="studio.leadSetting.leadFields.active"
								v-model="studio.leadSetting.leadFields.surname"
								label="Nachname"
							></v-switch
							><v-switch
								class="ml-5 pt-0 mt-0 mb-0"
								v-if="studio.leadSetting.leadFields.active"
								v-model="studio.leadSetting.leadFields.phone"
								label="Telefonnummer"
							></v-switch
							><v-switch
								class="ml-5 pt-0 mt-0 mb-0"
								v-if="studio.leadSetting.leadFields.active"
								v-model="studio.leadSetting.leadFields.email"
								label="E-Mail-Adresse"
							></v-switch
							><v-switch
								class="ml-5 pt-0 mt-0 mb-0"
								v-if="studio.leadSetting.leadFields.active"
								v-model="studio.leadSetting.leadFields.message"
								label="Nachricht"
							></v-switch>
						</v-col>
						<v-col v-if="!studio.useLeadSetting" cols="12" class="d-flex flex-column">
							<v-card-title class="mx-0 px-0">Verwaltungseinstellungen</v-card-title>
							<v-list class="px-0 mx-0">
								<v-card-subtitle class="px-0 mx-0 mb-0">Leadstatus</v-card-subtitle>
								<v-list-item-group color="primary">
									<v-list-item @click="removeFromList(list, i)" v-for="(list, y) in studio.leadSetting.status" :key="y + '-status'">
										<v-list-item-content>
											<v-list-item-title v-text="list"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
							<v-text-field
								class="mx-5"
								v-model="status"
								:append-outer-icon="status ? 'mdi-plus' : 'mdi-plus'"
								clear-icon="mdi-close-circle"
								clearable
								label="Hinzufügen"
								type="text"
								@click:append-outer="addToList(i)"
								@click:clear="clearStatusInput()"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="d-flex justify-center align-center mb-10"
							><v-btn :loading="loading" color="primary" @click="saveLead(studio)">Speichern</v-btn></v-col
						>
					</v-row>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'
import { VueEditor } from 'vue2-editor'
import Slim from '../../../plugins/slimImage/slim/slim.vue'

export default {
	components: { VueEditor, 'slim-cropper': Slim },
	data() {
		return {
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
				['blockquote', 'code-block'],
				[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
				[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				['link', 'video'],
				['clean'], // remove formatting button
			],
			panel: [],
			host: '',
			hosts: [],
			studios: [],
			loading: false,
			status: ['Wurde angeworben'],
			preBuiltLeadSettings: [],
			usedLeadSettings: {},
			status: '',
			panel: [],
			usedByDifferentStudio: {},
		}
	},
	async created() {
		this.host = window.location.host.replace('wwww.', '')
		this.init()

		//this.initLeads()
	},
	methods: {
		async downloadQRCode(link) {
			api.fetchQRCode(link)
		},
		copyLink(link) {
			navigator.clipboard.writeText(link)
			this.$toast.info('Der Link wurde in die Zwischenablage kopiert.')
		},
		toggleStudioUseLeadSetting(studioId, value) {
			// studio is using a custom lead setting
			if (!value) {
				for (let key in this.studios) {
					if (this.studios[key]._id === studioId) {
						//	this.studios[key].useLeadSetting = false
						console.log('resetting lead form for studio ' + this.studios[key].name)
						this.studios[key].leadSetting = {
							_id: null,
							active: false,
							active_from: '',
							active_till: '',
							title: 'Weiterempfehlung von ' + this.studios[key].name,
							showContactInfo: false,
							isReferral: true,
							leadFields: {
								active: false,
								name: true,
								surname: true,
								phone: true,
								email: true,
								message: true,
							},
							referralText: '',
							description: '',
							internConditions: '',
							publicConditions: '',
							internPicture: '',
							publicPicture: '',
							price: {
								active: false,
								value: 0,
							},
							status: [
								'Wurde angeworben',
								'Kontaktiert',
								'Probetraining vereinbart',
								'Vertrag abgeschlossen',
								'Kein Abschluss',
								'Geschlossen',
							],
						}
					}
				}
			} else {
				for (let key in this.studios) {
					if (this.studios[key]._id === studioId) {
						//	this.studios[key].useLeadSetting = true
					}
				}
			}
		},
		async init() {
			this.studios = []
			let studios = await api.fetchStudios()
			if (studios) {
				for (let studio of studios) {
					if (!'useLeadSetting' in studio) studio.useLeadSetting = false
					if (studio.useLeadSetting) studio.leadSettingId = studio.leadSetting._id

					if (!studio.leadSetting) {
						studio.leadSetting = {
							_id: null,
							active: false,
							active_from: '',
							active_till: '',
							title: 'Weiterempfehlung von ' + studio.name,
							showContactInfo: false,
							isReferral: true,
							leadFields: {
								active: false,
								name: true,
								surname: true,
								phone: true,
								email: true,
								message: true,
							},
							referralText: '',
							description: '',
							internConditions: '',
							publicConditions: '',
							internPicture: '',
							publicPicture: '',
							price: {
								active: false,
								value: 0,
							},
							status: [
								'Wurde angeworben',
								'Kontaktiert',
								'Probetraining vereinbart',
								'Vertrag abgeschlossen',
								'Kein Abschluss',
								'Geschlossen',
							],
						}
					} else {
						if (!studio.useLeadSetting) {
							this.preBuiltLeadSettings.push({ name: studio.name, _id: studio.leadSetting._id, studioId: studio._id })
						}
					}
					this.studios.push(studio)
				}
			}

			let hostnames = await api.fetchHostnames()
			if (hostnames) this.hosts = hostnames
		},
		async saveLead(studio) {
			if (studio.useLeadSetting || studio.leadSetting.showContactInfo || studio.leadSetting.leadFields.active) {
				this.loading = true
				this.status = ''
				let status = await api.saveReferralSetting(studio)

				this.$toast.open('gespeichert')
				this.init()
				this.loading = false
				this.panel = []
			} else {
				this.$toast.error('Aktiviere Kontaktinformationen oder Kontaktfelder')
			}
		},
		addToList(i) {
			if (this.status) {
				if (this.studios[i].leadSetting.status.includes(this.status)) {
					this.$toast.error('Dieser Status existiert bereits.')
				} else {
					this.studios[i].leadSetting.status.push(this.status)
					this.status = ''
				}
			}
		},

		removeFromList(list, i) {
			if (list === 'Wurde angeworben') {
				this.$toast.error('Dieser Status kann nicht gelöscht werden.')
			} else {
				if (confirm('Möchten Sie den Status ' + list + ' wirklich löschen?')) {
					this.studios[i].leadSetting.status = this.studios[i].leadSetting.status.filter((item) => item !== list)
				}
			}
		},
		uploadPicturePublic(formdata, progress, success, failure, slim) {
			const formData = new FormData()
			formData.append('file', formdata[0])

			let uploadimg = {}
			uploadimg.art = 'lead'
			uploadimg.formData = formData
			api.uploadImage(uploadimg)
				.then((data) => {
					if (data.status) {
						this.studios[this.panel].leadSetting.publicPicture = data.name
						success(data)
					}
				})
				.catch((error) => {
					failure(error)
					console.log(error)
				})
		},
		uploadPicturePrivate(formdata, progress, success, failure, slim) {
			const formData = new FormData()
			formData.append('file', formdata[0])

			let uploadimg = {}
			uploadimg.art = 'lead'
			uploadimg.formData = formData
			api.uploadImage(uploadimg)
				.then((data) => {
					if (data.status) {
						this.studios[this.panel].leadSetting.internPicture = data.name
						success(data)
					}
				})
				.catch((error) => {
					failure(error)
					console.log(error)
				})
		},
	},
	computed: {
		usedStudios() {
			let usedStudios = {}
			for (let studio of this.studios) {
				if (studio.useLeadSetting) {
					let findPrebuildSetting = this.preBuiltLeadSettings.find(
						(item) => item._id === studio.leadSetting._id && item.studioId !== studio._id
					)
					if (findPrebuildSetting) usedStudios[findPrebuildSetting.studioId] = true
				}
			}
			return usedStudios
		},
		slimOptionsPrivate() {
			return {
				ratio: '4:4',
				buttonCancelLabel: 'Abbrechen',
				buttonConfirmLabel: 'Bestätigen',
				label: 'Bild hochladen',
				labelLoading: 'Bild wird hochgeladen',
				statusUploadSuccess: 'gespeichert',
				service: this.uploadPicturePrivate,
				serviceFormat: 'file',
				dataJpegCompression: 70,
				instantEdit: true,
				push: true,
				initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
			}
		},
		slimOptionsPublic() {
			return {
				ratio: '4:4',
				buttonCancelLabel: 'Abbrechen',
				buttonConfirmLabel: 'Bestätigen',
				label: 'Bild hochladen',
				labelLoading: 'Bild wird hochgeladen',
				statusUploadSuccess: 'gespeichert',
				service: this.uploadPicturePublic,
				serviceFormat: 'file',
				dataJpegCompression: 70,
				instantEdit: true,
				push: true,
				initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
			}
		},
	},
	watch: {
		panel: {
			handler: function() {
				this.status = ''
			},
		},
	},
}
</script>
<style lang="scss" scoped></style>
