<template>
  <div>
    <div>
      <v-list>
        <v-list-group :value="frequentUsers.active">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="'Zugelassene Personen (Maximal 30 Kunden)'"></v-list-item-title>
          </v-list-item-content>
        </template>
          <v-list-item>
            <v-list-item-title>Aktuell sind {{ frequentUsers.length }} von 30 Plätzen belegt.</v-list-item-title>
          </v-list-item>
        <v-list-item :key="i" v-for="(frequentUser, i) in frequentUsers">
          <v-list-item-title>
            <div class="d-flex flex-row justify-space-between">
              <span class="d-block my-auto">{{ frequentUser.user.vorname }} {{ frequentUser.user.nachname }}</span>
              <div class="d-flex flex-row ">
                <span class="d-block my-auto">{{ frequentUser.count }}</span>
                <v-btn icon> <v-icon color="red" @click="removeUserFromListDialog(frequentUser.user)">mdi-delete</v-icon> </v-btn>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
        </v-list-group>
      </v-list>
    </div>

<!--    Remove User From List Diaolog-->
    <v-dialog
        v-model="removeUserFromList.active"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
         Kunden entfernen
        </v-card-title>
        <v-card-text>Willst du {{ removeUserFromList.user.vorname }} {{ removeUserFromList.user.nachname }} wirklich von der Liste entfernen?</v-card-text>
        <v-card-actions>
          <v-btn @click="removeUserFromList.active = false">
            Abbrechen
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeUser"
          >
            Entfernen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../api'
export default {
  props: {
    zeitplanId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      frequentUsers: [],
      removeUserFromList: {
        active: false,
        user: {}
      }
     };
  },
  methods: {
    removeUserFromListDialog (user) {
      this.removeUserFromList.active = true
      this.removeUserFromList.user = user
    },
    removeUser () {
      api.removeUserFromCovidGroup(this.zeitplanId, this.removeUserFromList.user._id).then(user_id => {
        this.removeUserFromList.active = false
        this.frequentUsers = this.frequentUsers.filter(user => user.user._id !== user_id)
      })
    },
    fetchFrequentlyVisitingUsers () {
      api.fetchFrequentlyVisitingUsers(this.zeitplanId).then(data => this.frequentUsers = data)
    }
  },
  created() {
    this.fetchFrequentlyVisitingUsers()
  },
};
</script>

<style lang="scss" scoped>
</style>