<template>
    <v-card flat>
        <employee-list></employee-list>
    </v-card>
</template>

<script>
import api from '@/api'
import employeeList from '@/views/dashboard/employee/employeeList.vue'

export default {
    components: { employeeList },
    data() {
        return {}
    },
    async created() {},
}
</script>
