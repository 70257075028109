<template xmlns="">
	<v-scale-transition origin="bottom center">
		<v-card class="workoutEditExercise__swiper-card d-flex flex-column justify-space-between elevation-7 rounded-xl">
			<v-card-title class="workoutEditExercise__swiper-card-title h5 primary--text justify-center text-break">
				{{ exercise.title }}
			</v-card-title>

			<v-card-subtitle class="primary--text text-center">
				{{ exercise.shortForm }}
			</v-card-subtitle>
			<div class="workoutEditExercise__swiper-card-content">
				<v-img contain class="workoutEditExercise__swiper-card-image" :src="getImageUrl(exercise.picture)"></v-img>

				<div class="workoutEditExercise__swiper-card-steps__wrapper">
					<div
						class="workoutEditExercise__swiper-card-steps d-flex flex-direction-column justify-space-between"
						:key="index"
						v-for="(field, index) in getSettings(exercise)"
					>
						<v-card-text class="align-self-center  workoutEditExercise__swiper-card-steps-name pa-2 body-1">
							{{ field.name || field.title }}
						</v-card-text>

						<v-text-field v-model="field.value" v-if="field.art === 2"></v-text-field>

						<v-text-field v-model="field.value" v-if="field.art === 1" type="number"> </v-text-field>

						<v-select v-if="field.art === 3" v-model="field.value" :items="field.auswahl.split(',')"></v-select>
					</div>
				</div>
			</div>

			<v-card-actions class="workoutEditExercise-actions justify-center">
				<v-btn color="grey" rounded class="text-white" outlined @click="resetEdit">
					Abbrechen
				</v-btn>

				<v-btn color="primary" rounded class="text-white" @click="saveEdit">
					Speichern
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-scale-transition>
</template>

<script>
import 'swiper/css/swiper.css'
import { SwiperSlide } from 'vue-awesome-swiper'

export default {
	name: 'workoutCreateEditExercise',
	components: {
		SwiperSlide,
	},
	props: {
		exercise: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getImageUrl(image) {
			return '/img/uebungen/' + image
		},
		saveEdit() {
			this.$emit('saveEdit')
		},
		resetEdit() {
			this.$emit('resetEdit')
		},
		getSettings(exercise) {
			return exercise.fields ? exercise.fields : exercise.tp_exercise_settings
		},
	},
}
</script>
<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.v-card {
	transition: opacity 0.4s ease-in-out;
}

::v-deep .v-virtual-scroll {
	height: 55vh !important;
}

.workoutEditExercise {
	bottom: 0;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
	height: 100%;

	&-actions {
		position: sticky;
		bottom: 0;
	}

	&__list-item {
		height: 100px;
		overflow: hidden;

		&-image {
			height: 100%;

			&__container {
				height: 100%;
				width: 40%;
			}
		}
	}

	&__swiper {
		width: 90% !important;
		height: 90% !important;
		margin-left: 5%;
		margin-right: 5%;

		&-card {
			text-align: -webkit-center;
			height: 80vh;
			min-height: 500px;

			&-content {
				overflow-y: auto;
				height: 62vh;
			}

			&-image {
				margin: 0 10%;
				height: 40%;
				background-size: contain;
			}

			&-steps {
				width: unset;

				&-name {
					width: 150px;
				}

				&__wrapper {
					height: 60%;
					text-align: left;
				}
			}
		}
	}
}
</style>
