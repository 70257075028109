<template>
	<div class="livestream-blacklist">
		<div v-show="!adding" @click="adding = true" class="action-addcourse">
			KURS ZUR BLACKLIST HINZUFÜGEN
		</div>
		<div v-show="adding" @click="adding = false" class="action-addcourse">
			Zurück zur Blacklist
		</div>

		<div v-show="!adding" class="blacklist-wrapper">
			<div class="kurs blacklist" v-for="(bl, y) of blacklist" :key="y">
				<span>{{ bl.name }}</span
				><span @click="removeCourse(bl)" class="material-icons">
					remove
				</span>
			</div>
		</div>

		<div class="nores" v-show="blacklist.length == 0 && !adding">
			Noch keinen Kurs hinzugefügt
		</div>

		<div v-show="adding" class="add-course">
			<div v-show="!blacklist_a[kurs._id]" v-for="(kurs, i) in kurs_array" :key="i" class="kurs">
				<span>{{ kurs.name }}</span>
				<span @click="addCourse(kurs)" class="material-icons">
					add
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../api'
export default {
	data() {
		return {
			blacklist: [],
			kurs_array: {},
			adding: false,
			blacklist_a: {},
		}
	},
	created() {
		this.load()
	},
	methods: {
		load() {
			this.blacklist = []
			this.blacklist_a = {}
			api.getKurse().then((data) => {
				for (let kurs of data.course) {
					this.kurs_array[kurs._id] = kurs
				}
				api.getLSBlacklist().then((data) => {
					for (let blacklist of data) {
						this.blacklist_a[blacklist.course_id] = true
						this.blacklist.push(this.kurs_array[blacklist.course_id])
					}
					this.blacklist.sort(function(a, b) {
						return a.course_name.toLowerCase().localeCompare(b.course_name.toLowerCase())
					})
				})
			})
		},
		removeCourse(kurs) {
			api.removeLSBlacklist(kurs).then((data) => {
				if (data.status == 0) {
					alert('Es gab einen Fehler')
				} else {
					this.load()
				}
			})
		},
		addCourse(kurs) {
			api.addLSBlacklist(kurs).then((data) => {
				if (data.status == 0) {
					alert('Es gab einen Fehler')
				} else {
					this.load()
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.nores {
	padding: 10px 15px;
	max-width: 400px;
	display: flex;
	margin: 0px auto;
}
.add-course,
.blacklist-wrapper {
	padding: 10px 15px;
	display: flex;
	margin-top: 5px;
	flex-direction: column;
	max-width: 400px;
	margin: 0px auto;
}
.kurs {
	line-height: 45px;
	border-bottom: 1px solid black;
	position: relative;
	max-height: 45px;
	overflow: hidden;
	.material-icons {
		position: absolute;
		right: 0px;
		height: 45px;
		width: 45px;
		display: block;
		font-size: 45px;
		background-color: var(--v-primary);
		top: calc(50% - 22.5px);
		color: #fff;
	}

	&.blacklist {
		.material-icons {
			background-color: red;
		}
	}
}
.action-addcourse {
	background-color: #313131;
	text-align: center;
	max-width: 400px;
	/* display: flex; */
	margin: 0px auto;
	line-height: 45px;
	color: #fff;
	/* font-weight: bold; */
	letter-spacing: 1px;
}
</style>
