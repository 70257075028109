<template>
	<div class="push">
		<h1>Push Benachrichtigungen</h1>
		<div v-show="checkBrowser() == 'iOS' || true" class="push_enable_apple" @click="subscribeApplePush()">
			Push Benachrichtigungen aktivieren (Apple)
		</div>
		<div v-show="checkBrowser() == 'Chrome' || true" class="push_enable_chrome" @click="subscribeWebPush()">
			Push Benachrichtigungen aktivieren (Chrome)
		</div>
		<div v-show="checkBrowser() == 'Safari' || true" class="push_enable_chrome" @click="subscribeWebPush()">
			(not supported yet) Push Benachrichtigungen (Safari)
		</div>
	</div>
</template>

<script>
import api from '../api'

export default {
	data() {
		return {}
	},
	methods: {
		// Subscribe to Apple Push
		subscribeApplePush() {
			api.AWdownload().then((data) => {
				var blob = new Blob([data], { type: 'application/vnd.apple.pkpass' })
				saveAs(blob, 'pass.pkpass')
			})
		},
		subscribeWebPush() {
			const publicVapidKey = 'BJbuEWR31mRblzSxCClSeFNiMzCL-7Q4D2iJB6I70xQKtf1kCd1AX00Xqm6sqhxQylUTRsaKqmjGwDrxrEuvxCM'

			// Copied from the web-push documentation
			const urlBase64ToUint8Array = (base64String) => {
				const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
				const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

				const rawData = window.atob(base64)
				const outputArray = new Uint8Array(rawData.length)

				for (let i = 0; i < rawData.length; ++i) {
					outputArray[i] = rawData.charCodeAt(i)
				}
				return outputArray
			}
			if (!('serviceWorker' in navigator)) {
				alert('Fehler..')
			} else {
				navigator.serviceWorker.ready.then((registration) => {
					// Subscribe to push notifications
					registration.pushManager
						.subscribe({
							userVisibleOnly: true,
							applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
						})
						.then((subscription) => {
							api.subscribe(JSON.stringify(subscription)).then((data) => {
								console.log(data)
							})
						})
				})
			}
		},
		checkBrowser() {
			// CHROME
			if (navigator.userAgent.indexOf('Chrome') != -1) {
				return 'Chrome'
			}
			// FIREFOX
			else if (navigator.userAgent.indexOf('Firefox') != -1) {
				return 'Firefox'
			}
			// INTERNET EXPLORER
			else if (navigator.userAgent.indexOf('MSIE') != -1) {
				return 'IE'
			}
			// EDGE
			else if (navigator.userAgent.indexOf('Edge') != -1) {
				return 'Edge'
			}
			// SAFARI
			else if (!!window.navigator.userAgent.match(/iPhone/i)) {
				return 'iOS'
			} else if (navigator.userAgent.indexOf('Safari') != -1) {
				return 'Safari'
			} else {
				return 0
			}
		},
		enablePush() {
			let mobile = false
		},
	},
}
</script>

<style scoped lang="scss">
.push {
	&_enable {
		color: white;
		text-align: center;
		padding: 10px 0px;
		margin: 10px 0px;
		background-color: var(--v-primary);
		text-transform: uppercase;
	}
}
</style>
