<template>
    <v-card flat class="ma-5" width="100%">
        <v-row style="max-width: 100%" no-gutters>
            <v-col v-if="interactions.length === 0" cols="12">
                <v-alert type="info">Keine Interaktionen gefunden</v-alert>
            </v-col>
            <v-col v-else cols="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">Datum</th>
                            <th class="text-left">Wer</th>
                            <th class="text-left">Wo</th>
                            <th class="text-left">Was</th>
                            <th class="text-left">Notiz</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="customerInteraction of interactions" :key="customerInteraction.interactions.date">
                            <td width="120">
                                {{ $moment(customerInteraction.interactions.date).format('DD.MM.YYYY') }}
                            </td>
                            <td width="70">
                                {{ customerInteraction.employee.vorname.slice(0, 1)
                                }}{{ customerInteraction.employee.nachname.slice(0, 1) }}
                            </td>
                            <td width="150">
                                {{ customerInteraction.interactions.location }}
                            </td>
                            <td width="200">
                                {{ customerInteraction.interactions.reason }}
                            </td>
                            <td width="200">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            {{ truncateText(customerInteraction.interactions.notes, 50) }}
                                        </span>
                                    </template>
                                    <span>{{ customerInteraction.interactions.notes }}</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'

export default {
    props: {
        user: {
            required: true,
        },
    },
    data() {
        return {
            userService,
            interactions: [],
        }
    },
    async created() {
        try {
            let interactions = await api.fetchCustomerInteractions(this.user._id)
            this.interactions = interactions
            this.$emit('loaded')
        } catch (e) {
            this.$toast.error('Fehler beim Laden der Interaktionen')
        }
    },
    methods: {
        truncateText(text, length) {
            return text.length > length ? text.substring(0, length) + '...' : text
        },
    },
}
</script>
