<template>
    <v-row no-gutters>
        <v-col v-if="mappingStudioSelectionVisible" align="center" cols="12">
            <v-window v-model="studioSelectionStep">
                <v-window-item :value="1">
                    <v-card max-width="500px" flat>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-select
                                    v-model="companyItemsSelection"
                                    :items="companyItems"
                                    label="Firma"
                                    @change="studioSelectionStep++"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn text @click="$emit('startOver')">Zurück</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="studioSelectionStep++">Weiter</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card max-width="500px" min-height="500px" flat>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-select
                                    v-model="studioSelections"
                                    :items="studioList"
                                    item-text="name"
                                    item-value="_id"
                                    @change="updateStudioSelectionMap"
                                    attach
                                    chips
                                    label="Studios"
                                    multiple
                                ></v-select>

                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Studio</th>
                                            <th>Endziffer Kontonummer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="studio of Object.keys(studioSelectionMap)"
                                            :key="studio + '-studioNameMap'"
                                        >
                                            <td>{{ studioIdMap[studio] }}</td>
                                            <td>
                                                <v-text-field
                                                    v-model="studioSelectionMap[studio]"
                                                    label="Name"
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn text @click="studioSelectionStep--">Zurück</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="startMapping">Weiter</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-window-item>
            </v-window>
        </v-col>
        <v-col align="center" v-else cols="12">
            <v-row no-gutters>
                <v-col align="center" cols="12">
                    <v-alert type="success" max-width="500px">
                        Erfolgsrechnung vom {{ localData.date.from }} bis {{ localData.date.till }}
                    </v-alert>
                    <v-data-table
                        no-data-text="Keine Daten verfügbar"
                        disable-pagination
                        :headers="headers"
                        :items="flattenDataAllLevels(localData.statement)"
                        item-key="accountNumber"
                    >
                        <template v-slot:item.account="{ item }">
                            <div
                                :style="{
                                    paddingLeft: `${item.level * 20}px`,
                                    'font-size': item.level === 0 ? '1.2rem' : '1rem',
                                    'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                                }"
                            >
                                {{ item.accountNumber }} {{ item.accountName }}
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <span v-if="item.studioIsResponsive"></span>
                            <v-select
                                v-else
                                :error="item.studio && item.studio.length > 1"
                                :error-messages="
                                    item.studio && item.studio.length > 1 ? 'Bitte nur ein Studio anwählen' : ''
                                "
                                @change="updateStudioInStatementData(item.accountNumber, $event)"
                                label="Studio"
                                :items="
                                    Object.keys(studioSelectionMap).map((e) => {
                                        return { name: studioIdMap[e], _id: e }
                                    })
                                "
                                multiple
                                item-text="name"
                                item-value="_id"
                                v-model="item.studio"
                            ></v-select>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6" align="end">
                    <v-btn class="my-5 mx-2" @click="goBackToMapping" text>Zurück</v-btn>
                </v-col>
                <v-col cols="6" align="start">
                    <v-btn class="my-5 mx-2" @click="updateStudiosAfterConfirmation" color="primary">Unterteilen</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data() {
        return {
            studioSelectionStep: 1,
            companyItems: [
                'Frei wählbar',
                'well come Invest GmbH',
                'winti FIT AG',
                'well come FIT SG AG',
                'well come FIT Holding GmbH',
                'well come FIT GL AG',
                'well come FIT AG',
            ],
            companyItemsSelection: 'Frei wählbar',
            headers: [
                { text: 'Konto', value: 'account' },
                { text: 'Studio', value: 'action' },
            ],
            mappingStudioSelectionVisible: true,
            localData: JSON.parse(JSON.stringify(this.data)),
            studioFilter: ['Livestreams', 'Alibey'],
            studioSelections: [],
            studioSelectionMap: {},
        }
    },
    props: {
        data: {
            type: Object,
        },
        studioList: {
            type: Array,
        },
    },
    created() {},
    methods: {
        goBackToMapping() {
            // this.studioSelectionStep = 1
            this.localData = JSON.parse(JSON.stringify(this.data))
            this.mappingStudioSelectionVisible = true
        },
        findStatementByAccountNumber(accounts, accountNumber) {
            for (const account of accounts) {
                if (account.accountNumber === accountNumber) {
                    return account
                }
                if (account.children && account.children.length) {
                    const parentArray = this.findStatementByAccountNumber(account.children, accountNumber)
                    if (parentArray) {
                        return parentArray
                    }
                }
            }
            return null
        },
        updateStudioInStatementData(accountNumber, studios) {
            let account = this.findStatementByAccountNumber(this.localData.statement, accountNumber)
            if (account) {
                account.studio = studios
            }
        },
        filterChildren(children, studio, ancestorHasStudio = false) {
            return children
                .map((child) => {
                    const childStudio = child.studio

                    const childHasStudio = childStudio === studio

                    // Ein Vorfahre oder das aktuelle Kind hat das Studio
                    const currentOrAncestorHasStudio = ancestorHasStudio || childHasStudio

                    return {
                        ...child,
                        studio: childStudio,
                        // Rekursiver Aufruf, wobei der Status, ob ein Vorfahre das Studio hat, weitergegeben wird
                        children: this.filterChildren(child.children || [], studio, currentOrAncestorHasStudio),
                    }
                })
                .filter(
                    (child) =>
                        child.studio === studio ||
                        (child.studio && child.studio.includes(studio)) ||
                        ancestorHasStudio || // Behalte das Kind bei, wenn ein Vorfahre das gesuchte Studio hat
                        (child.children && child.children.length > 0)
                )
        },
        filterByStudio(planData, studioArray) {
            const result = {}

            studioArray.forEach((studio) => {
                result[studio] = planData
                    .map((entry) => {
                        return {
                            ...entry,
                            //studio: isStudioArray ? studio : entry.studio,
                            studio: entry.studio,
                            children: this.filterChildren(entry.children || [], studio),
                        }
                    })
                    .filter(
                        (entry) =>
                            entry.studio === studio ||
                            (entry.studio && entry.studio.includes(studio)) ||
                            entry.children.length > 0
                    )
            })

            return result
        },

        flattenDataAllLevels(accounts, level = 0) {
            let result = []
            accounts.forEach((account) => {
                result.push({ ...account, level })

                if (account.children) {
                    result = result.concat(this.flattenDataAllLevels(account.children, level + 1))
                }
            })
            return result
        },
        updateStudiosAfterConfirmation() {
            // this.finalizingImport = true
            const updateStudios = (element) => {
                let childStudios = new Set()

                // Rekursiv für jedes Kind aufrufen und Studios sammeln
                if (element.children && element.children.length > 0) {
                    element.children.forEach((child) => {
                        updateStudios(child)
                        if (Array.isArray(child.studio)) {
                            child.studio.forEach((studio) => childStudios.add(studio))
                        } else if (child.studio) {
                            childStudios.add(child.studio)
                        }
                    })
                }

                // Setze Studios basierend auf den Kindern, wenn das Element studioIsResponsive ist
                if (element.studioIsResponsive) {
                    element.studio = [...childStudios]
                    //delete element.studioIsResponsive
                }

                // Falls das Element selbst kein Studio hat, setze es basierend auf den Kindern
                if (!element.studio || element.studio.length === 0) {
                    element.studio = [...childStudios]
                }
            }

            // Starte den Prozess für jedes Top-Level-Element
            this.localData.statement.forEach((e1) => {
                updateStudios(e1)
            })

            let retValStatement = this.filterByStudio(this.localData.statement, Object.keys(this.studioSelectionMap))

            this.$emit('importData', {
                date: this.localData.date,
                total: this.localData.total,
                statement: retValStatement,
            })
        },

        mapStatementDataStudios(element) {
            let studioMatched = false

            // Prüfe, ob das aktuelle Element einem Studio zugeordnet werden kann
            Object.keys(this.studioSelectionMap).forEach((key) => {
                if (element.accountNumber && element.accountNumber.toString().endsWith(this.studioSelectionMap[key])) {
                    element.studio = [key]
                    studioMatched = true
                }
            })

            // Prüfe, ob Kinder vorhanden sind
            if (element.children && element.children.length > 0) {
                element.studioIsResponsive = true // Setze .studioIsResponsive auf true
                element.studio = null
                element.balance = 0

                // Rekursiv für jedes Kind aufrufen
                element.children.forEach((child) => {
                    this.mapStatementDataStudios(child)
                })
            }

            //new code
            if (!element.studioIsResponsive && !studioMatched) element.studio = Object.keys(this.studioSelectionMap)
        },
        startMapping() {
            this.localData.statement.forEach((e1) => {
                this.mapStatementDataStudios(e1)
            })

            this.mappingStudioSelectionVisible = false
        },
        updateStudioSelectionMap() {
            let tempMapper = {
                Frauenfeld: '0',
                Steckborn: '1',
                Niederurnen: '2',
                Netstal: '0',
                Schwanden: '1',
                Winterthur: '0',
                Pfungen: '1',
                'St. Gallen Einstein': '2',
                'St. Gallen West': '1',
                'St. Gallen Ost': '0',
                Dietlikon: '0',
                Wallisellen: '2',
                Bülach: '1',
                Wetzikon: '3',
                Oerlikon: '4',
                Arbon: '5',
                Basel: '6',
                Herisau: '7',
                Sirnach: '1',
                Rikon: '0',
            }
            this.studioSelectionMap = {}
            this.studioSelections.forEach((studio) => {
                if (!this.studioSelectionMap[studio])
                    this.studioSelectionMap[studio] = tempMapper[this.studioIdMap[studio]]
                        ? tempMapper[this.studioIdMap[studio]]
                        : this.studioIdMap[studio]
            })
            let keys = Object.keys(this.studioSelectionMap)
            keys.forEach((e) => {
                if (!this.studioSelections.includes(e)) delete this.studioSelectionMap[e]
            })
        },
    },
    computed: {
        studioIdMap() {
            let studioMap = {}
            this.studioList.forEach((e) => {
                studioMap[e._id] = e.name
            })
            return studioMap
        },
    },
    watch: {
        companyItemsSelection(val) {
            if (val === 'Frei wählbar') this.studioSelections = []
            if (val === 'well come Invest GmbH')
                this.studioSelections = ['648ff12f5405300798992bdc', '62fb40daf811bd7ac52b7cbc']
            if (val === 'winti FIT AG') this.studioSelections = ['5f3d482d5dd7955ad48de5e4', '5f3d48195dd7955ad48de5e3']
            if (val === 'well come FIT SG AG')
                this.studioSelections = [
                    '5f3c30d0d91ddb464c2648b3',
                    '5f3c30bfd91ddb464c2648b2',
                    '5f3c30e1d91ddb464c2648b4',
                ]
            if (val === 'well come FIT Holding GmbH')
                this.studioSelections = [
                    '5f3c30f2d91ddb464c2648b5',
                    '5f3c30afd91ddb464c2648b1',
                    '6489797472347134883f6451',
                    '648979f672347134883f658e',
                    '6489788772347134883f617f',
                    '6489790d72347134883f6391',
                    '648975b972347134883f5976',
                    '6489776b72347134883f5e17',
                ]
            if (val === 'well come FIT GL AG')
                this.studioSelections = [
                    '5f3c1241d91ddb464c2648b0',
                    '6489780c72347134883f5f8a',
                    '63ff18391a3ea60a03d2d2c6',
                ]
            if (val === 'well come FIT AG')
                this.studioSelections = ['5f326a24ae8863640c859e75', '63e3c3fe7d6de41505928202']
            this.updateStudioSelectionMap()
        },
    },
}
</script>

<style lang="scss" scoped>
</style>