<template>
	<div class="slimMe">
		<v-row class="justify-center pt-5 pb-15 mb-15">
			<v-col cols="11" md="6" sm="6" lg="6" v-for="user of userList" :key="user._id">
				<v-card class=" elevation-7 fill-height">
					<v-card-title class="slimMe__user_title d-flex justify-center" :class="{ past: checkRecentCheckins(user) }"
						><span>{{ user.vorname }} {{ user.nachname }}</span></v-card-title
					>

					<v-card-text class="pb-0 mb-0 pt-5 text-center user-selectable"
						><a :href="'mailto:' + user.email">{{ user.email }}</a></v-card-text
					>
					<v-card-text v-if="user.telefon" class="pb-0 pt-2 mb-0 text-center user-selectable"
						><a :href="'tel:' + user.telefon">{{ user.telefon }}</a></v-card-text
					>
					<v-card-text v-if="user.slimMeUp_date" class="pb-0 mb-0 pt-5 text-center"
						>Anmeldedatum: {{ $moment(user.slimMeUp_date).format('DD.MM.YYYY') }}</v-card-text
					>

					<v-card-text class="text-center pb-0" v-if="user.checkins && user.checkins.length > 0"
						>letztes Check-In: {{ formatDate(user.checkins[user.checkins.length - 1]) }} Uhr</v-card-text
					>
					<v-card-text class="text-center red--text mb-5" v-else>noch keine Check-Ins</v-card-text>
					<v-card-text class="text-center red--text" v-if="checkRecentCheckins(user)"
						>war {{ lastCheckInDaysAgo(user) }} Tage nicht mehr im Center</v-card-text
					>
					<v-card-actions class="justify-center " v-if="user.checkins && user.checkins.length > 0">
						<v-btn class="mb-5" color="primary" @click="openDialog = user._id" icon>
							<lottie-player
								mode="normal"
								style="width:60px;height:60px;"
								class="ma-auto"
								:autoplay="false"
								src="/img/lottiefiles/table.json"
							></lottie-player
						></v-btn>
					</v-card-actions>

					<v-dialog max-width="345px" v-if="openDialog === user._id" :value="true" @click:outside="openDialog = null" class="d-flex">
						<v-date-picker :value="$moment(user.slimMeUp_date).format('YYYY-MM-DD')" :events="formattedCheckins(user.checkins)">
						</v-date-picker>
					</v-dialog>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import api from '../../../api'

export default {
	data() {
		return {
			activeDate: this.$moment().format('YYYY-MM-DD'),
			userList: [],
			openDialog: null,
		}
	},
	created() {
		this.init()
	},
	methods: {
		showUserDialog(user) {
			this.userList.find((u) => u._id === user._id).dialog = true
			console.log(this.userList)
		},
		formattedCheckins(checkins) {
			return checkins.map((checkin) => this.$moment(checkin).format('YYYY-MM-DD'))
		},
		lastCheckInDaysAgo(user) {
			if (!user.checkins || user.checkins.length === 0) return false
			let now = this.$moment()
			let lastLogin = this.$moment(user.checkins[user.checkins.length - 1])
			return now.diff(lastLogin, 'days')
		},
		checkRecentCheckins(user) {
			let startDate = this.$moment(user.slimMeUp_date).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
			let now = this.$moment()
			// if difference between now and startdate is greater than 7 days
			if (!user.checkins || user.checkins.length === 0) return false
			if (now.diff(startDate, 'days') > 7) {
				let lastLogin = this.$moment(user.checkins[user.checkins.length - 1])
				if (now.diff(lastLogin, 'days') > 7) return true
				return false
			} else {
				return false
			}
		},
		showCheckins(user) {
			console.log(user)
		},
		init() {
			this.fetchUsers()
		},
		async fetchUsers() {
			let users = await api.fetchSlimMeUsers()
			if (users) this.userList = users
		},
		formatDate(moment) {
			return this.$moment(moment).format('DD.MM.YYYY HH:mm')
		},
	},
}
</script>

<style lang="scss" scoped>
.slimMe {
	&__user {
		&_title {
			background-color: var(--v-primary);
			color: white;
			&.past {
				background-color: #ff0000;
			}
		}
	}
}
</style>
