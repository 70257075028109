<!-- Payment.vue -->
<template>
    <div v-if="!initLoading && settingsExist">
        <v-card v-if="!transactionInfos" class="ma-auto" max-width="500px" dark>
            <v-row no-gutters>
                <v-col cols="12" class="pa-5">
                    <v-text-field
                        label="Betrag"
                        type="number"
                        :min="0"
                        v-model="amount"
                        :step="0.05"
                        hide-details="auto"></v-text-field>
                </v-col>
                <v-col v-show="!customer.infos" cols="12" class="px-5">
                    <user-search
                        :value="customer.creatorId"
                        ignoreRights
                        :key="customer.creatorId"
                        instructor
                        label="Visum"
                        @update="updateCreator"></user-search>
                </v-col>
                <v-col v-show="customer.creatorId && !customer.infos" cols="12" class="pa-5">
                    <user-search
                        :value="customer.selection"
                        ignoreRights
                        label="Kunde auswählen"
                        :key="customer.selection"
                        @update="updateCustomer"></user-search>
                </v-col>
                <v-col v-if="false && !customer.infos" class="d-flex justify-center align-center" sm="4" cols="12">
                    <v-btn color="primary" @click="showRegisterDialog = true">Neuen Account erstellen</v-btn>
                </v-col>

                <v-col v-if="customer.infos" cols="12" class="d-flex justify-center px-5 pb-5">
                    <v-btn class="mx-0" text @click="resetCustomer">Kunde ändern</v-btn>
                </v-col>
                <v-col class="px-5" cols="12">
                    <v-row no-gutters v-if="customer.infos && !customerConfirmed">
                        <v-col cols="12">
                            <v-select
                                label="Anrede"
                                :items="[
                                    { name: 'Herr', value: 'm' },
                                    { name: 'Frau', value: 'w' },
                                ]"
                                item-value="value"
                                item-text="name"
                                class="my-0 py-0"
                                v-model="customer.infos.gender"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Vorname"
                                class="my-0 py-0"
                                v-model="customer.infos.firstName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Nachname"
                                class="my-0 py-0"
                                v-model="customer.infos.lastName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Geburtstag"
                                type="date"
                                class="my-0 py-0"
                                v-model="customer.infos.birthday"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Telefon" class="my-0 py-0" v-model="customer.infos.phone"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="E-Mail"
                                type="email"
                                class="my-0 py-0"
                                v-model="customer.infos.email"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field label="Strasse" class="my-0 py-0" v-model="customer.infos.street"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                type="number"
                                label="Postleitzahl"
                                class="my-0 py-0"
                                v-model="customer.infos.zip"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Stadt" class="my-0 py-0" v-model="customer.infos.city"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="customer.infos && customerConfirmed">
                        <v-col cols="12">
                            {{ customer.infos.gender === 'm' ? 'Herr ' : 'Frau ' }} {{ customer.infos.firstName }}
                            {{ customer.infos.lastName }} bezahlt CHF {{ amount }}.-
                        </v-col>
                        <v-col cols="12">
                            <v-textarea placeholder="Notizen zur Transaktion" v-model="notes"> </v-textarea>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col class="px-5 mb-5 d-flex justify-center" v-if="customer.infos && !customerConfirmed" cols="12">
                    <v-btn @click="customerConfirmed = true" color="primary"> Kundeninformationen bestätigen </v-btn>
                </v-col>
                <v-col class="px-5 mb-5 d-flex justify-center" v-if="customer.infos && customerConfirmed" cols="12">
                    <v-btn @click="createPayment" :disabled="loading" color="primary"> Zahlung erstellen </v-btn>
                </v-col>
                <v-col class="px-5" v-if="customer.infos && loading" cols="12">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear
                ></v-col>
                <v-col v-if="customer.infos && paymentStatus" class="px-5" cols="12">
                    <v-alert :type="paymentStatus === 'Zahlung erfolgreich' ? 'success' : 'error'" class="mt-3">
                        {{ paymentStatus }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="ma-auto" max-width="500px" dark v-else>
            <v-row no-gutters>
                <v-col cols="12">
                    <payment-entry :showIcon="true" :paymentEntry="transactionInfos"></payment-entry>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="d-flex justify-center mb-5">
                    <v-btn @click="transactionInfos = null" color="primary">Neue Zahlung ausführen</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
    <div v-else-if="!initLoading && !settingsExist">
        <v-card max-width="500px" class="mx-auto mt-10"
            ><v-alert type="error">Es wurden noch keine Zahlungseinstellungen angelegt</v-alert>
        </v-card>
    </div>
    <div v-else>
        <v-card max-width="500px" class="mx-auto mt-10"
            ><v-progress-linear indeterminate color="primary"></v-progress-linear
        ></v-card>
    </div>
</template>
  
  <script>
import axios from 'axios'
import api from '../../../api'
import userSearch from '@/components/userSearch.vue'
import paymentEntry from './PaymentEntry.vue'

export default {
    components: { userSearch, paymentEntry },
    data() {
        return {
            amount: 0,
            paymentStatus: null,
            loading: false,
            initLoading: true,
            showRegisterDialog: false,
            customerConfirmed: false,
            notes: '',
            settingsExist: false,
            transactionInfos: null,
            customer: {
                selection: null,
                infos: null,
                creatorId: null,
                creatorInfos: null,
            },
        }
    },
    created() {
        this.initLoading = true
        this.checkIfSettingsExist()
    },
    methods: {
        async checkIfSettingsExist() {
            try {
                let setting = await api.checkPaymentSetting()
                this.initLoading = false
                if (setting) this.settingsExist = true
                if (setting && this.$route.query.datatransTrxId) {
                    let transactionId = this.$route.query.datatransTrxId
                    console.log(transactionId)
                    this.fetchPaymentStatus(transactionId)
                }
            } catch (e) {
                this.settingsExist = false
                this.initLoading = false
            }
        },
        async fetchPaymentStatus(transactionId) {
            try {
                let status = await api.fetchPaymentStatus(transactionId)
                this.transactionInfos = status
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Abrufen des Zahlungsstatus')
            }
        },
        async updateCreator(id) {
            this.customer.creatorId = id
            let creator = await api.getUserForManager(id)
            let creatorInfos = {
                firstName: creator.vorname,
                lastName: creator.nachname,
                email: creator.email,
            }

            this.customer.creatorInfos = creatorInfos
            this.paymentStatus = null
            this.$forceUpdate()
        },
        resetCustomer() {
            this.customer.infos = null
            this.customer.selection = null
            this.customerConfirmed = false
            this.paymentStatus = null
        },
        checkIfBirthdayIsValid(birthday) {
            return this.$moment(birthday, 'YYYY-MM-DD', true).isValid()
        },
        async updateCustomer(id) {
            this.customer.selection = id
            let customer = await api.getUserForManager(id)
            let customerEntry = {
                gender: customer.geschlecht,
                firstName: customer.vorname,
                lastName: customer.nachname,
                email: customer.email,
                phone: customer.telefon,
                birthday: customer.geburtstag,
                street: customer.adresse_1,
                city: customer.stadt,
                zip: customer.plz,
            }
            this.paymentStatus = null
            this.customerConfirmed = false
            this.customer.infos = customerEntry

            if (!this.checkIfBirthdayIsValid(this.customer.infos.birthday)) {
                this.$toast.error('Bitte gib ein gültiges Geburtsdatum ein')
            }

            this.$forceUpdate()
        },
        async createPayment() {
            this.loading = true
            let paymentData = {
                amount: this.amount,
                customer: this.customer,
                notes: this.notes,
                currency: 'CHF',
            }
            try {
                let paymentInfos = await api.createPayment(paymentData)

                console.log(paymentInfos)
                this.$toast.success('Zahlung wurde erfolgreich erstellt')
                this.$router.push('/dashboard/user/search/' + this.customer.selection)
                this.loading = false
            } catch (e) {
                this.$toast.error('Fehler beim Bezahlen')
            }
        },
    },
    computed: {},
}
</script>
  