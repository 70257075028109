<template>
    <div>
        <v-row no-gutters v-if="data.courses.length">
            <v-col
                v-if="userService.hasRight('ab_create') || userService.hasRight('tp_create')"
                class="mt-5"
                cols="12"
                align="center"
            >
                <v-btn text @click="$router.push('/gfrecommendations/' + user._id)">
                    Group Fitness Kursempfehlungen bearbeiten
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-card-title class="font-weight-bold white--text">Letzte Kurse</v-card-title>
            </v-col>

            <v-col
                class="pt-0 pa-2"
                :key="'groupFitness' + course.course.name"
                v-for="course of data.courses"
                cols="auto"
            >
                <v-card elevation="7" class="d-flex flex-column rounded-lg justify-space-around">
                    <v-responsive :aspect-ratio="2 / 1.5">
                        <v-img
                            class="fill-height"
                            style="position: absolute; opacity: 0.7"
                            :src="'/img/kurse/' + course.course.bild"
                        ></v-img>
                        <v-card-text class="font-weight-black pb-0 px-2 course-top">
                            {{ course.course.name }}
                        </v-card-text>
                        <v-card-text class="font-weight-bold pt-2 px-2 course-bottom">
                            {{ course.time }}
                        </v-card-text>
                    </v-responsive>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="data.workouts.length" no-gutters>
            <v-col cols="12">
                <v-card-title class="font-weight-bold white--text">Trainingspläne</v-card-title>
            </v-col>
            <v-col
                class="pt-0 pa-2"
                :key="'workout' + workoutIndex"
                v-for="(workout, workoutIndex) of data.workouts"
                cols="auto"
            >
                <v-card elevation="7" class="d-flex flex-column justify-space-around rounded-lg">
                    <v-responsive :aspect-ratio="1 / 1">
                        <v-img
                            class="fill-height"
                            style="position: absolute; opacity: 0.4"
                            :src="'/img/uebungen/' + workout.picture"
                        ></v-img>
                        <v-card-text class="font-weight-black pb-0 px-2 course-top">{{ workout.title }}</v-card-text>
                        <v-card-text class="font-weight-bold pt-2 px-2 course-bottom">
                            von {{ workout.instructor }}
                        </v-card-text>
                        <v-row style="position: relative" class="ma-0"></v-row>
                    </v-responsive>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'

export default {
    props: {
        user: {
            required: true,
        },
    },
    data() {
        return {
            userService,
            data: {
                courses: [],
                workouts: [],
            },
        }
    },
    async created() {
        this.data = await api.getCustomerDashboardTraining(this.user._id)
        this.$emit('loaded')
    },
    computed: {
        isLocal() {
            return location.host.includes('local')
        },

        isLocal() {
            return location.host.includes('local')
        },

        isAdmin: function () {
            return localStorage.getItem('admin')
        },
    },
}
</script>
