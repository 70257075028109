<template xmlns="">
	<div class="event__card d-flex flex-column" style="margin-bottom: 150px;" v-if="!loading">
		<v-img contain class="event__card-image" :src="headerImage" />
		<v-card-title v-if="!headerImage" class="h5 justify-content-center mt-5 mb-3"> {{ event.title }} </v-card-title>
		<v-card-title class="h5 justify-content-center">
			<v-row class="justify-center">
				<v-col class="justify-end d-flex" cols="4">
					<lottie-player
						@click="shareViaWebShare"
						mode="normal"
						style="width:100px;height:40px;"
						class="event__lottie mr-3 speech-bubble"
						name="placeholderImage"
						loop
						autoplay
						src="/img/lottiefiles/avatarPlusWhite.json"
					></lottie-player>
				</v-col>
				<v-col class="justify-start d-flex pt-4" cols="6">
					<span class="event__card-friend">Hol einen Freund dazu</span>
				</v-col>
			</v-row>
		</v-card-title>
		<v-row style="height: 50%; flex-wrap: nowrap; overflow-x: auto;">
			<v-col cols="8" md="5" lg="5" xl="5" v-for="course in courseExecutions" :key="course._id">
				<v-card class=" d-flex flex-column rounded-xl ">
					<div style="position: absolute; z-index: 10000; right: 15px; top: 5px;">
						<v-tooltip bottom max-width="200px">
							<template v-slot:activator="{ on, attrs }">
								<v-icon color="white" dark v-bind="attrs" v-on="on">
									mdi-information
								</v-icon>
							</template>
							<span>{{ course.course.beschreibung }}</span>
						</v-tooltip>
					</div>
					<v-img
						height="150px"
						style="border-top-left-radius: 25px; border-top-right-radius: 25px;"
						:src="getCourseImage(course.course.bild)"
					></v-img>
					<div :style="backgroundMainColor">
						<v-card-title
							class="event__card-course-title justify-content-center"
							:class="{ 'event__card-course-title-mobile': $vuetify.breakpoint.mobile }"
						>
							{{ course.course.name }}
						</v-card-title>
						<v-card-text
							class="text-center white--text d-flex flex-column"
							:class="{ 'event__card-course-content-mobile': $vuetify.breakpoint.mobile }"
						>
							<span>{{ timeService.timestampToDate(course.ts) }}</span>
							<span class="mt-1">{{ timeService.getCourseStartTime(course) }} - {{ timeService.getCourseEndTime(course) }} Uhr </span>
						</v-card-text>
					</div>
					<v-btn
						class="event__card-course-button white--text"
						:style="backgroundSecondaryColor"
						text
						@click="$router.push('/kurse/' + course._id)"
					>
						{{ course.signups.find((signup) => signup.user === userID) ? 'Abmelden' : 'Anmelden' }}
					</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import api from '../../api'
import timeService from '../../services/timeService'
import userService from '../../services/userService'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	name: 'EventView',
	components: { Swiper, SwiperSlide },
	directives: {
		swiper: directive,
	},
	data() {
		return {
			loading: false,
			timeService,
			navigationBarInitiated: false,
			event: null,
			userService,
			userID: null,
			registeredCourses: [],
			courseExecutions: [],
			registeringActiveId: null,
		}
	},
	created() {
		this.setNavigationBarText({ text: 'Zurück', state: false, endPoint: 'EventView' })
		this.loading = true
		this.userID = this.userService.getUser()._id
		api.fetchEvent(this.$route.params.id).then((data) => {
			this.event = data.data
			this.courseExecutions = data.courseExecutions
			this.loading = false
		})
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
		async checkIfUserElseRedirect() {
			let user = await api.getUser().catch((e) => {
				this.$router.push({ name: 'Registrieren', params: { eventId: this.event._id } })
			})
		},
		getCourseImage(image) {
			return '/img/kurse/' + image
		},
		pushBackToPrevRoute() {
			if (this.$router.go(-1)) this.$router.go(-1)
			this.$router.push('/')
		},
		async shareViaWebShare() {
			let host = ''

			if (location.host.includes('localhost')) {
				host = 'well come FIT'
			}
			if (location.host.includes('wellcomefit')) {
				host = 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				host = 'winti FIT'
			} else if (location.host.includes('fzw')) {
				host = 'FZW'
			}

			let studio = null

			studio = await api.getUserStudio().catch((e) => {
				window.app.$root.$children[0].checkStudio()
			})
			studio = studio.studio

			navigator.share({
				title: this.event.title,
				text: 'Hey ein super cooles Fitness Event: ' + this.event.title + ' im ' + host + ' ' + studio.name + '. Kommst Du mit?',
				url: 'https://' + location.host + '/event/' + this.event._id,
			})
		},
	},
	watch: {
		navigationBarTextState(val) {
			if (this.navigationBarEndPoint === 'EventView') {
				this.navigationBarInitiated ? this.pushBackToPrevRoute() : (this.navigationBarInitiated = true)
			}
		},
	},
	computed: {
		...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),

		webShareApiSupported() {
			return navigator.share
		},
		swiperOption() {
			let slidesPerView = 3
			if (this.$vuetify.breakpoint.md) {
				slidesPerView = 2
			}
			if (this.$vuetify.breakpoint.smAndDown) {
				slidesPerView = 1
			}

			return {
				slidesPerView: 'auto',
				centeredSlides: true,
				spaceBetween: 30,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			}
		},
		headerImage() {
			return '/img/event/' + this.event.headerImage
		},
		backgroundMainColor() {
			return 'background-color: ' + this.event.mainColor
		},
		backgroundSecondaryColor() {
			console.log(this.event)
			return 'background-color: ' + this.event.secondaryColor
		},
		mainColor() {
			return 'color: ' + this.event.mainColor
		},
		secondaryColor() {
			return 'color: ' + this.event.secondaryColor
		},
	},
}
</script>
<style lang="scss" scoped>
.speech-bubble {
	position: relative;
	background: var(--v-primary) !important;
}

::v-deep .swiper-wrapper {
	height: 90% !important;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 17px solid #0000;
	border-top-color: var(--v-primary);
	border-bottom: 0;
	border-right: 0;
	margin-left: 4.5px;
	margin-bottom: -16px;
}
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.event__lottie {
	display: block;
}

.desktop-sizes {
	max-width: 70vh;
	min-width: 30vh;
	max-height: 85vh;
	min-height: 60vh;
}

.v-card {
	transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
	bottom: 0;
}

.event {
	&__card {
		margin: auto;
		width: 100%;
		height: 91vh;
		min-height: 800px;
		&-friend {
			max-width: 151px;
			word-break: break-word;
			text-align: center;
		}
		&-image {
			max-height: 40%;
			@media only screen and (min-width: 700px) {
				object-fit: cover !important;
			}
			object-fit: contain;
		}
		&-course {
			&-title {
				color: white;
				font-size: 18px;
				font-weight: bold;

				&-mobile {
					font-size: 4vw;
				}
			}
			&-content-mobile {
				font-size: 3.5vw;
			}
			&-button {
				margin-top: -3px;
				width: 100%;
				border-bottom-left-radius: 24px;
				border-bottom-right-radius: 24px;
			}
		}
	}
}
</style>
