<template>
    <v-card flat>
        <v-row>
            <v-col cols="12" v-if="mode === 'searchProfileAndReturn'">
                <search-profile
                    @selectUser="$emit('foundProfile', $event)"
                    :hideEmail="true"
                    :user="user"
                ></search-profile>
            </v-col>
            <v-col cols="12" v-if="mode === 'searchProfileOrCreate'">
                <search-profile
                    @createNewUser="$emit('createNewUser')"
                    @selectUser="openProfileForEdit"
                    @selectUserAllInformation="userSelectedReturnAllInformation"
                    :hideEmail="true"
                    :user="user"
                ></search-profile>
            </v-col>
            <v-col cols="12" v-if="mode === 'searchProfileAndReturnAllData'">
                <search-profile
                    @selectUserAllInformation="userSelectedReturnAllInformation"
                    :hideEmail="true"
                    :hideCreateButton="true"
                    :user="user"
                ></search-profile>
            </v-col>
            <v-col cols="12" v-if="mode === 'editProfile'">
                <edit-profile
                    :accountNameIfNotGiven="userInfosToCompare.email"
                    :contractMapMode="contractMapMode"
                    :userTACId="selectedTACId"
                    :userDataToCopy="userInfosToCompare"
                    @userSelectedReturnId="userSelectedReturnId"
                ></edit-profile>
            </v-col>
            <v-col cols="12" v-if="mode === 'createNewUser'">
                <edit-profile
                    :showBackToSearchButton="true"
                    @toSearch="mode = 'searchProfileOrCreate'"
                    @newAccountRegistered="newAccountRegistered"
                    :resortId="resortId"
                    :accountNameIfNotGiven="userInfosToCompare.email"
                    :contractMapMode="contractMapMode"
                    :userDataFromContract="userInfosToCompare"
                ></edit-profile>
            </v-col>
            <v-col cols="12" v-if="mode === 'viewProfileContract'">
                <edit-profile
                    :viewOnly="true"
                    @newAccountRegistered="newAccountRegistered"
                    :resortId="resortId"
                    :accountNameIfNotGiven="userInfosToCompare.email"
                    :contractMapMode="contractMapMode"
                    :userDataFromContract="userInfosToCompare"
                ></edit-profile>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from '@/api'
import editProfile from './editProfile'
import searchProfile from './searchProfile'
import viewProfile from './viewProfile'
export default {
    components: { editProfile, searchProfile, viewProfile },
    props: {
        contractMapMode: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: null,
        },
        userInfosToCompare: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: null,
        },
        userTACId: {
            type: String,
            default: null,
        },
        resortId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selectedTACId: null,
        }
    },
    created() {
        this.init()
    },
    methods: {
        newAccountRegistered() {
            this.$emit('newAccountRegistered')
        },
        userSelectedReturnId(Ids) {
            this.$emit('userSelectedReturnId', Ids)
        },
        userSelectedReturnAllInformation(info) {
            this.$emit('userSelectedReturnAllInfo', info)
        },
        init() {
            if (this.userTACId) {
                this.selectedTACId = this.userTACId
                this.$emit('openProfileForEdit')
            }
        },
        openProfileForEdit(tacId) {
            this.selectedTACId = tacId
            this.$emit('openProfileForEdit')
        },
    },
    computed: {},
}
</script>