<template>
    <v-dialog max-width="600" @click:outside="$emit('closeDialog')" :value="dialogActive">
        <v-card dark>
            <v-row no-gutters>
                <v-col cols="8" class="text-align-end">
                    <v-card-title>
                        {{
                            dialogType === 'growth' ? 'Wachstum' : dialogType === 'correction' ? 'Korrektur' : 'Kündigungen'
                        }}
                        pro Monat für</v-card-title
                    >
                </v-col>
                <v-col cols="4">
                    <v-select
                        color="primary"
                        v-model="activeStudio"
                        item-text="name"
                        item-value="_id"
                        class="font-weight-bold"
                        :items="studios"></v-select>
                </v-col>
            </v-row>

            <v-data-table
                v-if="activeStudio"
                :headers="tableHeaders"
                :no-data-text="
                    dialogType === 'growth'
                        ? 'Keine Wachstumszahlen hinterlegt'
                        : dialogType === 'correction'
                        ? 'Keine Korrektur hinterlegt'
                        : 'Keine Kündigungen gefunden'
                "
                :items="studioTerminations()"
                class="elevation-1">
                <template v-slot:header>
                    <v-row>
                        <v-form lazy-validation ref="createForm" class="d-flex">
                            <v-col cols="5">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="newTermination.date"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="$moment(newTermination.date).format('MMMM YYYY')"
                                            label="Monat"
                                            :rules="rules.month"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="newTermination.date"
                                        @change="$refs.menu.save(newTermination.date)"
                                        type="month"
                                        no-title
                                        scrollable>
                                        <v-btn text color="primary" @click="menu = false"> Abbrechen </v-btn>
                                    </v-date-picker>
                                </v-menu></v-col
                            >
                            <v-col cols="5"
                                ><v-text-field
                                    :rules="rules.terminationsCount"
                                    :label="
                                        dialogType === 'growth'
                                            ? 'Wachstum'
                                            : dialogType === 'correction'
                                            ? 'anz. zu Korrigieren'
                                            : 'Anz. Kündigungen'
                                    "
                                    v-model="newTermination.count"></v-text-field
                            ></v-col>
                            <v-col cols="2" class="text-center" style="place-self: center">
                                <v-btn color="primary" icon @click="createTerminationsEntry()"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                >
                            </v-col>
                        </v-form>
                    </v-row>
                </template>
                <template v-slot:item.count="props">
                    <v-edit-dialog :return-value.sync="props.item.count" large persistent @save="save(props)">
                        <div>{{ props.item.count }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">Anzahl Bearbeiten</div>
                            <v-text-field
                                v-model="props.item.count"
                                :rules="rules.terminationsCount"
                                label="Anzahl"
                                single-line
                                counter
                                autofocus></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.date="props">
                    <v-edit-dialog :return-value.sync="props.item.date" large persistent @save="save(props)">
                        <div>{{ $moment(props.item.date).format('MMM YYYY') }}</div>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">Monat bearbeiten</div>
                            <v-date-picker v-model="props.item.date" type="month" no-title> </v-date-picker>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:item.actions="props">
                    <v-icon small class="mr-2" color="red" @click="remove(props.index)"> mdi-delete </v-icon></template
                >
            </v-data-table>
        </v-card>
    </v-dialog>
</template>
<script>
import api from '../../../api'
import contractCreator from '../contract/contractCreator.vue'

export default {
    components: { contractCreator },
    props: {
        dialogType: {
            type: String,
        },
        dialogActive: {
            type: Boolean,
            default: false,
        },
        studios: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            newTermination: { date: this.$moment().add(1, 'months').format('YYYY-MM'), count: null },
            menu: false,
            editMenu: false,
            tableHeaders: [
                { text: 'Datum', value: 'date' },
                { text: 'Anzahl', value: 'count' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            activeStudio: null,
            rules: {
                month: [(v) => (v.length <= 50 && v.length > 0) || 'Wähle einen Monat aus'],
                terminationsCount: [(v) => (v > -1000 && v < 1000) || 'Zahl eingeben'],
            },
        }
    },
    created() {
        if (this.studios[0]) {
            this.activeStudio = this.studios[0]._id
        }
    },
    methods: {
        async remove(item) {
            if (confirm('Wirklich löschen?')) {
                if (this.dialogType === 'growth') {
                    this.studios.find((studio) => studio._id === this.activeStudio).growth.splice(item, 1)
                    let activeStudioGrowth = this.studios.find((studio) => studio._id === this.activeStudio).growth
                    await api.updateStudioGrowth({ studioId: this.activeStudio, growth: activeStudioGrowth })
                } else if (this.dialogType === 'correction') {
                    this.studios.find((studio) => studio._id === this.activeStudio).correction.splice(item, 1)

                    let activeStudioCorrection = this.studios.find((studio) => studio._id === this.activeStudio).correction
                    await api.updateStudioCorrection({ studioId: this.activeStudio, correction: activeStudioCorrection })
                } else {
                    this.studios.find((studio) => studio._id === this.activeStudio).terminations.splice(item, 1)
                    let activeStudioTerminations = this.studios.find(
                        (studio) => studio._id === this.activeStudio
                    ).terminations
                    await api.updateStudioTerminations({
                        studioId: this.activeStudio,
                        terminations: activeStudioTerminations,
                    })
                }
            }
        },
        async save(props) {
            if (this.dialogType === 'growth') {
                this.studios.find((studio) => studio._id === this.activeStudio).growth[props.index] = props.item
                let activeStudioGrowth = this.studios.find((studio) => studio._id === this.activeStudio).growth
                await api.updateStudioGrowth({ studioId: this.activeStudio, growth: activeStudioGrowth })
            } else if (this.dialogType === 'correction') {
                this.studios.find((studio) => studio._id === this.activeStudio).correction[props.index] = props.item
                let activeStudioCorrection = this.studios.find((studio) => studio._id === this.activeStudio).correction
                await api.updateStudioCorrection({ studioId: this.activeStudio, correction: activeStudioCorrection })
            } else {
                this.studios.find((studio) => studio._id === this.activeStudio).terminations[props.index] = props.item
                let activeStudioTerminations = this.studios.find((studio) => studio._id === this.activeStudio).terminations
                await api.updateStudioTerminations({ studioId: this.activeStudio, terminations: activeStudioTerminations })
            }
        },
        async createTerminationsEntry() {
            if (this.$refs.createForm.validate()) {
                if (this.dialogType === 'growth') {
                    let activeStudioGrowth = this.studios.find((studio) => studio._id === this.activeStudio).growth
                    activeStudioGrowth.push(this.newTermination)
                    this.newTermination = { date: this.$moment().add(1, 'months').format('YYYY-MM'), count: null }
                    await api.updateStudioGrowth({ studioId: this.activeStudio, growth: activeStudioGrowth })
                } else if (this.dialogType === 'correction') {
                    let activeStudioCorrection = this.studios.find((studio) => studio._id === this.activeStudio).correction
                    activeStudioCorrection.push(this.newTermination)
                    this.newTermination = { date: this.$moment().add(1, 'months').format('YYYY-MM'), count: null }
                    await api.updateStudioCorrection({ studioId: this.activeStudio, correction: activeStudioCorrection })
                } else {
                    let activeStudioTerminations = this.studios.find(
                        (studio) => studio._id === this.activeStudio
                    ).terminations
                    activeStudioTerminations.push(this.newTermination)
                    this.newTermination = { date: this.$moment().add(1, 'months').format('YYYY-MM'), count: null }
                    await api.updateStudioTerminations({
                        studioId: this.activeStudio,
                        terminations: activeStudioTerminations,
                    })
                }
            }
        },
        studioTerminations() {
            let items = []
            if (this.dialogType === 'growth') {
                items = this.studios.find((studio) => studio._id === this.activeStudio).growth
            } else if (this.dialogType === 'correction') {
                items = this.studios.find((studio) => studio._id === this.activeStudio).correction
            }
            /* else {
                items = this.studios.find((studio) => studio._id === this.activeStudio).terminations
            } */
            if (items) {
                return items.map((item) => {
                    return {
                        date: item.date,
                        count: item.count,
                    }
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
 