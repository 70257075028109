<template>
	<div class="nfcScanner">
		<image-overlapping-card
			:headerImageActive="false"
			:changeRoute="false"
			v-on:change-visibility="emitHide"
			:visible="visible"
			:fullScreen="true"
			return-route="-1"
		>
			<template v-slot:content>
				<div class="nfcScanner__action d-flex flex-column justify-center">
					<div class="nfcScanner__action-intro text-center mb-10 mx-auto d-flex">
						<span>Scanne ein Gerät um mehr zu erfahren</span>
					</div>
					<lottie-player
						mode="normal"
						v-show="!showNFCTagInfo"
						style="width:200px;height:200px;"
						class="mx-auto mt-10 mb-15"
						name="placeholderImage"
						loop
						autoplay
						src="/img/lottiefiles/BottomNavigation/nfcScanner.json"
					></lottie-player>
					<lottie-player
						mode="normal"
						v-show="showNFCTagInfo"
						style="width:200px;height:350px;"
						loop
						class="mx-auto  nfcTagroody"
						name="roody_icon"
						autoplay
						src="/img/lottiefiles/roody_animated.json"
					></lottie-player>
				</div>
			</template>
		</image-overlapping-card>
		<v-dialog v-model="showNFCTagInfo" persistent max-width="290">
			<v-card>
				<v-card-subtitle class="pb-0 pt-5">NFC</v-card-subtitle>
				<v-card-title class="text-h5 pt-0" style="word-break:keep-all;">
					Gerät gefunden
				</v-card-title>

				<v-card-text>
					Diese Funktion wird bald verfügbar sein.
				</v-card-text>
				<v-btn @click="lockTag()">Tag Sperren</v-btn>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="grey darken-1" text @click="nfcAction(0)">
						Abbrechen
					</v-btn>
					<v-btn color="primary darken-1" text @click="nfcAction(1)">
						Weiter
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import api from '../../api'
import userService from '../../services/userService'
import imageOverlappingCard from '../../components/imageOverlappingCard.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
	name: 'nfcScanner',
	components: {
		imageOverlappingCard,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			showNFCTagInfo: false,
		}
	},

	async created() {
		window.addEventListener('tagFound', this.tagFound)
		window.addEventListener('writeTagCallback', this.writeTagCallback)
		window.addEventListener('lockTagCallback', this.lockTagCallback)
		window.addEventListener('lockTagErrorCallback', this.lockTagErrorCallback)
		window.addEventListener('writeTagErrorCallback', this.writeTagErrorCallback)
	},
	destroyed: function() {
		window.removeEventListener('tagFound', this.tagFound)
		window.removeEventListener('writeTagCallback', this.writeTagCallback)
		window.removeEventListener('lockTagCallback', this.lockTagCallback)
		window.removeEventListener('lockTagErrorCallback', this.lockTagErrorCallback)
		window.removeEventListener('writeTagErrorCallback', this.writeTagErrorCallback)
		if (localStorage.getItem('isApp')) {
			window.flutter_inappwebview.callHandler('stopNFCScanner')
		}
	},
	computed: {
		...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
		...mapState('navigationStoreNotPersisted', ['navigationVisible']),
		...mapState('userStore', ['user']),
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'toggleNavigationBarVisibility']),
		init() {
			console.log('starting NFC scanner')
			//start nfc search
			if (localStorage.getItem('isApp')) {
				window.flutter_inappwebview.callHandler('startNFCScanner')
			}
		},
		writeTag(id) {
			console.log('writing tag')
			window.flutter_inappwebview.callHandler('writeTag', id)
		},
		lockTag() {
			window.flutter_inappwebview.callHandler('lockTag')
		},
		writeTagCallback() {
			alert('wrote Tag')
		},
		writeTagErrorCallback() {
			alert('ERROR wrote Tag')
		},
		lockTagCallback() {
			alert('locked Tag')
		},
		lockTagErrorCallback() {
			alert('Error locked Tag')
		},
		tagFound(data) {
			console.log('tag found', data)
			this.showNFCTagInfo = true
		},
		nfcAction(status) {
			this.showNFCTagInfo = false
		},
		emitHide() {
			console.log('emitHide')
			this.$emit('change-visibility-nfc', false)
			// stop nfc search
			if (localStorage.getItem('isApp')) {
				window.flutter_inappwebview.callHandler('stopNFCScanner')
			}
		},
	},
	watch: {
		visible: function(newVal, oldVal) {
			if (newVal) this.init()
		},
	},
}
</script>

<style lang="scss" scoped>
.nfcTagroody {
	padding-top: 230px;
}
.nfcScanner {
	&__action {
		height: 100vh;

		background-color: var(--v-primary);

		&-intro {
			max-width: 290px;
			letter-spacing: 3px;
			text-transform: uppercase;

			color: white;
			font-weight: bold;
			font-size: 30px;
		}
	}
}
</style>
