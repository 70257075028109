<template>
    <v-card flat>
        <v-row no-gutters>
            <v-col cols="12" align="center"><v-btn color="primary" @click="save">Speichern</v-btn></v-col>
        </v-row>
        <v-simple-table v-if="infos && infos.tacTypes">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Typ</th>
                        <th class="text-left">roody</th>
                        <th class="text-left">Laufzeit</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tacType of infos.tacTypes" :key="tacType.name">
                        <td>{{ tacType.name }}</td>
                        <td>
                            <v-select
                                v-model="tacType.type"
                                item-value="value"
                                item-text="name"
                                :items="types"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                v-if="tacType.type === 'package'"
                                v-model="tacType.roodyName"
                                :items="infos.packagesRoody"
                            ></v-select>
                            <v-select
                                v-else-if="tacType.type === 'membership'"
                                v-model="tacType.roodyName"
                                item-value="title"
                                item-text="title"
                                :items="infos.membershipsRoody"
                            ></v-select>
                            <span v-else>kein Typ</span>
                        </td>
                        <td>
                            <v-select
                                v-if="tacType.type === 'membership' && tacType.roodyName"
                                v-model="tacType.duration"
                                return-object
                                item-text="text"
                                :items="infos.membershipsRoody.find((e) => e.title === tacType.roodyName).durations"
                            ></v-select>
                            <span v-else>-</span>
                        </td>
                        <td>
                            <v-btn text>
                                <v-icon
                                    @click="
                                        () => {
                                            tacType.duration = null
                                            tacType.roodyName = null
                                            tacType.type = null
                                        }
                                    "
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>
<script>
import api from '@/api'
export default {
    data() {
        return {
            infos: null,
            types: [
                {
                    name: 'Zusatzpaket',
                    value: 'package',
                },
                {
                    name: 'Mitgliedschaft',
                    value: 'membership',
                },
            ],
        }
    },
    created() {
        console.log('CREATED')
        this.fetchRoodyTACContractMap()
    },
    methods: {
        async save() {
            try {
                let status = await api.saveRoodyTACContractMap({ tacTypes: this.infos.tacTypes })
                this.fetchRoodyTACContractMap()
                this.$toast.success('gespeichert')
            } catch (e) {
                this.$toast.error('Fehler beim Speichern')
            }
            console.log(this.infos.tacTypes)
        },
        async fetchRoodyTACContractMap() {
            console.log('FETCHING')
            let infos = await api.fetchRoodyTACContractMap()
            this.infos = infos
        },
    },
}
</script>
