<template>
    <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
        <v-card-title>Fragebogen</v-card-title>
        <div v-if="loading">
            <v-card-actions class="justify-content-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
        </div>
        <div v-else>
            <v-card-text class="py-0" v-if="anamnese && anamnese.createdAt">
                Aktueller Fragebogen vom: {{ $moment(anamnese.updatedAt).format('DD.MM.YYYY') }}
            </v-card-text>
            <v-card-text v-else>Fragebogen für Kunden aufgeschaltet</v-card-text>
            <v-card-actions v-if="!anamnese || !anamnese._id" class="justify-content-center">
                <v-btn @click="createEditAnamnese()">
                    {{ anamnese && anamnese._id ? 'Bearbeiten' : 'Hinzufügen' }}
                </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
                <v-btn icon @click="deleteAnamnese()"><v-icon color="red">mdi-delete</v-icon></v-btn>
                <v-btn icon @click="createEditAnamnese()"><v-icon color="blue">mdi-pencil</v-icon></v-btn>
                <v-btn icon @click="createEditAnamnese(true)"><v-icon color="grey">mdi-eye</v-icon></v-btn>
            </v-card-actions>
        </div>
        <anamnese-bogen-create ref="anamneseBogenRef" @closeAnamnesebogen="closed()"></anamnese-bogen-create>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import anamneseBogenCreate from '../../../components/anamneseBogenCreate'

export default {
    props: {
        user: {},
        creator: {},
    },
    components: {
        anamneseBogenCreate,
    },
    data() {
        return {
            loading: false,
            anamnese: [],
        }
    },
    async created() {
        await this.loadAnamnese()
    },
    methods: {
        async loadAnamnese() {
            this.loading = true
            this.anamnese = await api.getAnamneseNutritionForUser(this.user._id)
            this.loading = false
        },
        async closed() {
            await this.loadAnamnese()
        },
        async deleteAnamnese() {
            if (confirm('Möchtest du den Fragebogen wirklich entfernen?')) {
                try {
                    await api.deleteMedicalHistory(this.anamnese._id)
                    this.anamnese = null
                    this.$toast.success('Ernährungsfragebogen gelöscht')
                } catch (e) {
                    this.$toast.error('Fehler beim löschen des Fragebogens')
                }
            }
        },
        createEditAnamnese(viewOnly = false) {
            this.$refs.anamneseBogenRef.setVisible(
                this.anamnese?._id ? this.user._id : null || null,
                viewOnly ? false : this.anamnese?._id ? this.anamnese._id : true,
                true,
                this.user._id,
                this.creator
            )
        },
    },
}
</script>
