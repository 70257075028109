<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                <span>Stellenanzeigen auf Webseite</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="openDialog"> hinzufügen</v-btn>
            </v-card-title>

            <v-expansion-panels multiple>
                <v-expansion-panel v-for="(job, index) in jobOffers" :key="index">
                    <!-- Panel Header -->
                    <v-expansion-panel-header>
                        <div class="d-flex justify-space-between">
                            <strong class="mr-auto">{{ job.title ? job.title : job.type.title }} <v-chip color="red"
                                    v-if="!job.visible">ausgeblendet</v-chip></strong>

                            <span class="ml-auto white--text">{{ formatDate(job.updatedAt)
                                }}</span>

                        </div>

                    </v-expansion-panel-header>

                    <!-- Panel Content -->
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-checkbox v-model="job.visible" label="Sichtbar"></v-checkbox>
                            </v-col>
                            <!-- Anstellungstyp -->
                            <v-col cols="12" md="6">
                                <v-select v-model="job.durationType"
                                    :items="['Festanstellung', 'Teilzeit', 'Lehrstelle', 'Praktikum', 'Ausbildung']"
                                    label="Anstellungstyp"></v-select>
                            </v-col>

                            <!-- Prozente -->
                            <v-col cols="12" md="6">
                                <v-text-field v-model="job.durationTitle" label="Prozente (z.B. 50% - 60%)"
                                    :rules="[percentValidation]"></v-text-field>

                            </v-col>

                            <!-- Benutzerdefinierter Kontakt -->
                            <v-col cols="12">
                                <v-checkbox v-model="job.useCustomContact"
                                    label="Benutzerdefinierter Kontakt"></v-checkbox>
                            </v-col>

                            <!-- Kontaktinformationen -->
                            <template v-if="job.useCustomContact">
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="job.contactInfo.firstName" label="Vorname"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="job.contactInfo.lastName" label="Nachname"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="job.contactInfo.email" label="E-Mail"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="job.contactInfo.phone" label="Telefon"></v-text-field>
                                </v-col>
                            </template>
                            <template v-else>
                                <v-col cols="12" md="6">
                                    <v-text-field :value="job.type.contactInfo.firstName" label="Vorname"
                                        readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field :value="job.type.contactInfo.lastName" label="Nachname"
                                        readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field :value="job.type.contactInfo.email" label="E-Mail"
                                        readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field :value="job.type.contactInfo.phone" label="Telefon"
                                        readonly></v-text-field>
                                </v-col>
                            </template>
                        </v-row>
                        <v-row justify="center">
                            <v-btn color="primary" :disabled="!isJobOfferValid(job)" @click="saveJobOffer(job)">
                                Speichern
                            </v-btn>

                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- Dialog für Job Angebot hinzufügen -->
        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span>Angebot auf Webseite erstellen</span>
                </v-card-title>
                <v-card-text>
                    <v-select v-model="selectedJobType" :items="availableJobTypes" item-text="title" item-value="_id"
                        label="Job auswählen" return-object></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDialog">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="addJobOffer">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from '@/api';

export default {
    data() {
        return {
            jobOffers: [],
            availableJobTypes: [],
            selectedJobType: null,
            dialog: false,
            percentValidation: value => {
                const regex = /^(\d{1,3}%|\d{1,3}%\s-\s\d{1,3}%)$/;
                return (
                    regex.test(value) || value === '' || 'Das Format muss entweder "50%" oder "50% - 60%" sein'
                );
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        isJobOfferValid(job) {
            const regex = /^(\d{1,3}%|\d{1,3}%\s-\s\d{1,3}%)$/;
            return regex.test(job.durationTitle) || job.durationTitle === '';
        },
        async init() {
            try {
                const jobOffers = await api.fetchJobOffers();
                const jobTypes = await api.fetchJobTypes();

                this.jobOffers = jobOffers;
                this.availableJobTypes = jobTypes;
            } catch (error) {
                this.$toast.error('Fehler beim Laden der Daten');
            }
        },
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        addJobOffer() {
            if (this.selectedJobType) {
                const newJobOffer = {
                    _id: null,
                    type: this.selectedJobType,
                    title: this.selectedJobType.title,
                    visible: false,
                    durationType: '',
                    durationTitle: '',
                    useCustomContact: false,
                    contactInfo: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                    },
                    updatedAt: new Date().toISOString(), // Initiales Datum
                };

                this.jobOffers.push(newJobOffer);
                this.selectedJobType = null;
                this.dialog = false;
            }
        },
        async saveJobOffer(job) {
            try {
                const payload = {
                    type: job.type,
                    visible: job.visible,
                    durationType: job.durationType,
                    durationTitle: job.durationTitle,
                    useCustomContact: job.useCustomContact,
                    contactInfo: job.useCustomContact
                        ? job.contactInfo
                        : {
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: '',
                        },
                };

                if (job._id) {
                    const updatedOffer = await api.updateJobOffer(job._id, payload);
                    job.updatedAt = updatedOffer.updatedAt; // Aktualisiertes Datum zuweisen
                } else {
                    const savedOffer = await api.createJobOffer(payload);
                    job._id = savedOffer._id;
                    job.updatedAt = savedOffer.updatedAt;
                }

                this.$toast.success('JobOffer erfolgreich gespeichert');
            } catch (error) {
                this.$toast.error('Fehler beim Speichern des JobOffers');
            }
        },
        formatDate(date) {
            return this.$moment(date).format("DD.MM.YY HH:mm") + ' Uhr'
        },
    },
};
</script>
