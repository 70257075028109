<template>
    <v-container>
        <v-card flat dark>
            <v-card-title>Job Vorlagen</v-card-title>
            <v-data-table :items="jobTypes" :headers="headers" item-value="id" class="elevation-1" dense>
                <template v-slot:top>
                    <v-toolbar flat>

                        <v-btn color="primary" @click="openDialog()">Neue Vorlage</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="fetchAllJobTypes()">Alle anzeigen</v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:body="{ items }">
                    <tr v-for="item in items" :key="item._id">
                        <td class="px-5">{{ item.title }}</td>
                        <td class="px-5">{{ item.deleted ? 'Ja' : 'Nein' }}</td>
                        <td>
                            <v-btn icon color="primary" @click="editJobType(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon color="red" @click="markAsDeleted(item._id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ editedItem._id ? 'Vorlage bearbeiten' : 'Neue Vorlage' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-img class="mx-auto" height="200" width="200" v-if="editedItem.picture"
                            :src="'/api/website/image/' + editedItem.picture"></v-img>
                        <v-col class="d-flex justify-center flex-column" cols="12">
                            <v-file-input outlined hide-details v-model="file" class="mb-2" label="Bild auswählen"
                                @change="onFileChange"></v-file-input>

                            <v-btn class="mx-auto mb-2" color="primary" @click="uploadImage">Hochladen</v-btn>
                        </v-col>
                        <v-text-field v-model="editedItem.title" label="Titel"></v-text-field>
                        <v-checkbox v-model="editedItem.deleted" label="Gelöscht"></v-checkbox>
                        <vue-editor class="user-selectable" :editorToolbar="customToolbar"
                            v-model="editedItem.content"></vue-editor>
                        <v-text-field v-model="editedItem.contactInfo.firstName" label="Vorname"></v-text-field>
                        <v-text-field v-model="editedItem.contactInfo.lastName" label="Nachname"></v-text-field>
                        <v-text-field v-model="editedItem.contactInfo.email" label="E-Mail"></v-text-field>
                        <v-text-field v-model="editedItem.contactInfo.phone" label="Telefonnummer"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDialog">Abbrechen</v-btn>
                    <v-btn text color="primary" @click="saveJobType">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from '@/api';
import { VueEditor } from 'vue2-editor'

export default {
    components: { VueEditor, },
    data() {
        return {
            file: null,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],

            jobTypes: [],
            headers: [
                { text: 'Titel', value: 'title' },
                { text: 'Gelöscht', value: 'deleted' },
                { text: 'Aktionen', value: 'actions', sortable: false },
            ],
            dialog: false,
            editedItem: {
                title: '',
                content: '',
                deleted: false,
                contactInfo: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                },
            },
            defaultItem: {
                title: '',
                content: '',
                deleted: false,
                contactInfo: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                },
            },
        };
    },
    created() {
        this.fetchJobTypes();
    },
    methods: {
        async uploadImage() {
            if (!this.file) {
                this.$toast.error('Bitte wähle ein Bild aus ')
                return
            }

            let formData = new FormData()
            formData.append('image', this.file)
            formData.append('alt', '')

            try {
                // upload
                let response = await api.uploadWebsiteImage(formData)

                if (response.file) {
                    this.editedItem.picture = response.file

                    this.file = null
                }
            } catch (error) {
                this.$toast.error('Fehler beim hochladen des Bildes')
                console.error(error)
            }
        },
        onFileChange(file) {
            // Temporäres Bild-Element erstellen, um die Dimensionen zu überprüfen
            const img = new Image()

            img.onload = () => {
                const width = img.width
                const height = img.height

                // Überprüfen, ob das Bild im 16:9-Format ist
                if (width / height === 1 / 1) {
                    this.file = file
                } else {
                    this.file = null
                    this.$toast.error('Bitte wählen Sie ein Bild im 1:1-Format.')
                }
            }

            // Fehlerfall, falls das Bild nicht geladen werden kann
            img.onerror = () => {
                this.file = null
                this.$toast.error('Ungültige Bilddatei.')
            }

            // Bildquelle auf das ausgewählte Dateiblob setzen
            img.src = URL.createObjectURL(file)
        },

        async fetchJobTypes() {
            // Holt nur nicht gelöschte Jobtypen
            this.jobTypes = await api.fetchJobTypes();
        },
        async fetchAllJobTypes() {
            // Holt alle Jobtypen, inklusive gelöschter
            this.jobTypes = await api.fetchAllJobTypes();
        },
        openDialog() {
            this.dialog = true;
            this.editedItem = { ...this.defaultItem };
        },
        closeDialog() {
            this.dialog = false;
        },
        async saveJobType() {
            if (this.editedItem._id) {
                // Update bestehender Jobtyp
                await api.updateJobType(this.editedItem._id, this.editedItem);
            } else {
                // Erstelle neuen Jobtyp
                await api.saveJobType(this.editedItem);
            }
            this.dialog = false;
            this.fetchJobTypes();
        },
        editJobType(item) {
            this.editedItem = { ...item };
            this.dialog = true;
        },
        async markAsDeleted(id) {
            // Markiere Jobtyp als gelöscht
            if (confirm('möchtest du den Job löschen?')) {

                await api.deleteJobType(id);
                this.fetchJobTypes();
            }
        },
    },
};
</script>
