<template>
    <div>
        <v-col cols="12">
            <v-img height="65px" contain src="/img/roodyLogoGreen.png"></v-img>
        </v-col>
        <v-row class="mt-5">
            <v-card-title class="font-weight-bold h2" style="word-break: break-word">
                Deine Zahlung konnte leider nicht abgeschlossen werden.
            </v-card-title>
            <v-card-title style="word-break: break-word"
                >Keine Sorge, es wurde noch nichts abgebucht. Bitte überprüfe deine Zahlungsinformationen und versuche es
                erneut. <br />
                Du wirst in Kürze automatisch zurück zum Warenkorb geleitet, um den Zahlungsvorgang fortzusetzen. <br />
                Solltest du weitere Unterstützung benötigen, stehen wir dir gerne zur Verfügung. Danke für dein Verständnis
                und Geduld!</v-card-title
            >
            <v-card-actions class="justify-content-center mt-5">
                <v-btn color="primary" @click="$router.push('/shop/checkout')"> zurück zum Warenkorb </v-btn>
            </v-card-actions>
        </v-row>
    </div>
</template>
  
  <script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    async created() {
        setTimeout(async () => {
            this.$router.push('/shop/checkout')
        }, 10000)
    },
}
</script>
  