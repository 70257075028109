<template>
    <div class="login">
        <img class="login__roody-image pt-10" src="/img/roody_logo.png" />
        <v-card-actions class="login__actions d-flex flex-column">
            <!-- LOGIN FORM -->
            <v-btn
                class="login__actions-btn-login uppercase"
                color="primary"
                x-large
                rounded
                block
                @click="setLoginFormActive($event)">
                anmelden
            </v-btn>
            <v-btn
                class="login__actions-btn-register uppercase mt-5 ml-0"
                color="primary"
                x-large
                rounded
                block
                @click="setRegisterFormActive($event)">
                registrieren
            </v-btn>
        </v-card-actions>
        <login @close="loginActive = false" :origin="dialogOrigin" :active="loginActive"></login>
        <register @close="registerActive = false" :origin="dialogOrigin" :active="registerActive"></register>
    </div>
</template>

<script>
import api from '../../api'
import { EventBus } from '../../event-bus'
import login from './login.vue'
import register from './register.vue'
export default {
    components: {
        login,
        register,
    },
    data() {
        return {
            dialogOrigin: '0px 0px',
            loginActive: false,
            registerActive: false,
        }
    },
    created() {
        if (window.flutter_inappwebview) window.flutter_inappwebview.callHandler('showAppTrackingTransparency')
        if (localStorage.getItem('msg_open_roody')) {
            this.hide_temp = true
        }
        if (this.forgottenLink) this.loginActive = true
    },

    methods: {
        setRegisterFormActive(element) {
            const { top, left, width, height } = element.target.getBoundingClientRect()
            this.dialogOrigin = Math.round(left + width / 2) + 'px' + ' ' + Math.round(top + height / 2) + 'px '
            this.registerActive = true
        },
        setLoginFormActive(element) {
            const { top, left, width, height } = element.target.getBoundingClientRect()
            this.dialogOrigin = Math.round(left + 16) + 'px' + ' ' + Math.round(top + 16) + 'px '
            this.loginActive = true
        },
        saveMsg() {
            localStorage.setItem('msg_open_roody', true)

            this.hide_temp = true
        },
        redirectToBewertung() {
            if (this.$route.query.redirect) {
                return true
            } else {
                return false
            }
        },
        redirectToAffiliate() {
            if (this.$route.query.forward) {
                return true
            } else {
                return false
            }
        },
    },
    computed: {
        forgottenLink() {
            return this.$route.name === 'Forgot'
        },
    },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0px;
}
::v-deep .v-btn:not(.v-btn--round).v-size--x-large {
    max-height: 52px;
}

::v-deep .v-select__selection {
    color: white !important;
}
::v-deep .v-label {
    color: white !important;
}
::v-deep .v-text-field__slot {
    input {
        color: white !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
}

.register {
    &__active-steps-check {
        height: 54px !important;
        width: 54px !important;
    }

    &__active-steps-gender {
        opacity: 0.5;

        &--selected {
            opacity: 1;
        }
    }

    &__active-div {
        opacity: 0;
        transition-duration: 0.3s;
        transition: opacity;
        &--opened {
            opacity: 1;
            transition: opacity;
            transition-duration: 0.3s;
            transition-delay: 0.5s;
        }
        &-container {
            overflow-y: scroll;
            overflow-x: hidden;
            background-image: linear-gradient(
                0deg,
                var(--v-primary) 0%,
                var(--v-darkenprimary) 80%,
                var(--v-darkenprimary2) 100%
            );
            width: 100%;
            height: 52px;
            opacity: 0;
            transition: height 0.5s ease-out, opacity 0.5s;
            &--opened {
                opacity: 1;
                height: unset;
                min-height: 300px;
                max-height: 500px;
                transition: all 1s;
            }
        }
        &-actions {
            border-bottom-left-radius: 23px;
            border-bottom-right-radius: 23px;

            &-registerbutton {
                transition: width 1s ease-out;
                &--inactive {
                    display: none;
                    width: 0px;
                }
            }
        }
    }
}

.login {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-image: url('/img/einmalig/loginImage.jpeg');
    &__active-div {
        opacity: 0;
        transition-duration: 0.3s;
        transition: opacity;
        &--opened {
            opacity: 1;
            transition: opacity;
            transition-duration: 0.3s;
            transition-delay: 0.5s;
        }
        &-container {
            background-image: linear-gradient(
                0deg,
                var(--v-primary) 0%,
                var(--v-darkenprimary) 80%,
                var(--v-darkenprimary2) 100%
            );
            width: 100%;
            height: 52px;
            opacity: 0;
            transition: height 0.5s ease-out, opacity 0.5s;
            // transition-delay: 1s;
            &--opened {
                opacity: 1;
                height: unset;
                transition: height 0.5s ease-in, opacity 0.2s;
            }
        }
        &-actions {
            border-bottom-left-radius: 23px;
            border-bottom-right-radius: 23px;
        }
    }
    &__actions {
        padding-top: 40vh;
        height: 90vh;
        overflow: auto;
        max-width: 500px;
        width: 80%;
        margin-left: auto;
        &-container {
            overflow-y: auto;
        }
        &--desktop {
            overflow: unset !important;
            height: unset;
            max-height: 400px;
            padding-top: 0;
            position: absolute !important;
            right: 20px;
            bottom: 20px;
        }
        &-btn {
            &-login {
                background-image: linear-gradient(
                    0deg,
                    var(--v-primary) 0%,
                    var(--v-darkenprimary) 80%,
                    var(--v-darkenprimary2) 100%
                );
            }
            &-register {
                background-image: linear-gradient(
                    0deg,
                    var(--v-darkenprimary2) 0%,
                    var(--v-darkenprimary) 20%,
                    var(--v-primary) 100%
                );
            }
        }
    }

    &__roody-image {
        height: 150px;
        display: block;
        margin: auto;
    }
}
</style>
