<template>
	<div class="myTraining mb-7">
		<div class="mt-3 mb-2 myTraining__calendar flex-nowrap px-3 d-flex flex-row">
			<div class="myTraining__calendar-wrapper d-flex flex-column justify-center " v-for="(date, y) in calendar"
				:key="y">
				<v-skeleton-loader v-if="loading" class="mx-auto mt-8" max-width="30" max-height="30"
					type="card"></v-skeleton-loader>
				<div class="d-flex flex-column justify-center" v-else>
					<div class="justify-center d-flex">
						<span class="myTraining__calendar-wrapper-short mt-1">{{ date.short }}</span>
					</div>
					<div class="myTraining__calendar-wrapper-date justify-center d-flex">
						<v-btn style="border-radius: 10px;" min-width="32px" min-height="30px" height="auto"
							class="pa-1" text @click="openTrainingCalendar($event, date.ts)" :class="{
								'myTraining__calendar-wrapper-date-btn': 1,
								today: date.today,
								active: openedDate === y,
								'd-flex': 1,
							}">
							<span v-if="date.courseArray.length || date.foundWorkouts.length || date.checkins.length"
								class="ma-auto"><v-icon class="ma-auto"
									:class="{ 'primary--text': openedDate !== y }">mdi-check</v-icon></span>
							<span v-else class="ma-auto">{{ date.date }}</span>
						</v-btn>
					</div>
				</div>
			</div>
		</div>

		<div class="myTraining__progress pt-5">
			<v-progress-linear color="primary" :value="this.activeCount"></v-progress-linear>
		</div>
		<my-training-statistic-dialog v-if="statisticDialog" :openedDate="openedDate" :origin="dialogOrigin"
			@close="statisticDialog = false"></my-training-statistic-dialog>
	</div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import myTrainingStatisticDialog from './myTrainingStatisticDialog.vue'

export default {
	name: 'myTraining',
	components: { myTrainingStatisticDialog },
	data() {
		return {
			loading: true,
			calendar: [{}, {}, {}, {}, {}, {}, {}],
			activeCount: 0,
			openedDate: null,
			dialogOrigin: null,
			statisticDialog: false,
		}
	},

	async created() {
		await this.createCalendar()
	},
	methods: {
		openTrainingCalendar(element, openDate) {
			const { top, left, width, height } = element.target.getBoundingClientRect()
			this.dialogOrigin = Math.round(left + 16) + 'px' + ' ' + Math.round(top + 16) + 'px '
			this.openedDate = this.$moment(openDate * 1000).format('YYYY-MM-DD')
			this.statisticDialog = true
		},
		openDate(index) {
			this.openedDate = this.openedDate === index ? null : index
		},
		async createCalendar() {
			this.loading = true
			let calendar = await api.fetchTrainingCalendar()
			this.calendar = calendar.calendar
			this.activeCount = 0
			let aCount = 0
			this.calendar.forEach((e) => {
				if (e.foundWorkouts.length) aCount++
				if (e.courseArray.length) aCount++
			})
			this.activeCount = (100 / 7) * aCount
			this.loading = false
		},
	},
	computed: {},
}
</script>

<style lang="scss" scoped>
.myTraining {
	&-opened-date {
		border: 3px solid var(--v-primary);
		padding: 8px;
		background-color: rgba(82, 174, 50, 0.1);
		border-radius: 8px;
	}

	&__calendar {
		max-width: 700px;
		margin-right: auto;
		margin-left: auto;
		overflow-x: auto;

		justify-content: space-evenly;

		&::-webkit-scrollbar {
			display: none;
		}

		&-wrapper {
			width: calc(100% / 7);

			&-date {
				&-btn {
					color: black;

					background-color: #efefef;
					font-weight: bold;

					&.today {
						border: 2px solid var(--v-primary);
					}

					&.active {
						color: white;
						background-color: var(--v-primary);
					}
				}
			}

			&-short {
				text-align: center;
				color: #808080;
			}
		}

		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}
}
</style>
