<template>
    <v-card style="background-color: transparent" elevation="0" max-width="1200px" class="mx-auto mb-15 pb-15">
        <v-row v-if="selectedLeadSetting !== null">
            <v-col cols="12" class="d-flex justify-center align-center">
                <v-btn text @click="resetSelection()" color="primary" class="white--text">zurück zur Übersicht</v-btn>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center" v-if="leadSettings.length > 0 && selectedLeadSetting === null">
            <v-col
                class="d-flex justify-center align-center"
                cols="12"
                v-for="(leadSetting, i) of leadSettings.filter((e) => parseInt(e.count) !== 0)"
                :key="i + '-leadSetting'">
                <v-card width="100%" dark hover class="mx-5 rounded-xl">
                    <v-card-text class="text-center h4 mt-5 white--text">{{ leadSetting.title }}</v-card-text>
                    <v-card-text class="text-center h5">Neu angeworben: {{ leadSetting.count }}</v-card-text>

                    <v-row class="d-flex justify-center align-center my-5 mb-5 mx-5 pb-5">
                        <v-expansion-panels>
                            <v-expansion-panel v-for="(host, y) of hosts" :key="y + '-hostLinks'">
                                <v-expansion-panel-header> {{ host }}/l/{{ leadSetting.link }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row class="px-10 mx-5 mb-5">
                                        <v-col cols="12"
                                            ><img
                                                class="white rounded-xl"
                                                :src="'/api/qrcode/image?data=' + host + '/l/' + leadSetting.link"
                                        /></v-col>
                                        <v-col cols="12">
                                            <v-btn
                                                class="mr-5 white--text"
                                                @click="downloadQRCode(host + '/l/' + leadSetting.link)"
                                                color="primary"
                                                >QR Code herunterladen</v-btn
                                            >
                                            <v-btn
                                                class="mr-5 white--text"
                                                @click="copyLink(host + '/l/' + leadSetting.link)"
                                                color="primary"
                                                >Link kopieren</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-btn class="white--text mt-5" color="primary" @click="selectLeadSetting(leadSetting)"
                            >Auswählen</v-btn
                        >
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col
                v-if="selectedLeadSetting && selectedLeadSetting.status"
                cols="12"
                class="d-flex justify-center align-center mt-0 mb-10">
                <v-chip-group v-model="selectedStatus" active-class="primary--text text--accent-4" mandatory>
                    <v-chip v-for="status in selectedLeadSetting.status" :key="status + '-leadStatus'" :value="status">
                        {{ status }}
                    </v-chip>
                </v-chip-group>
            </v-col>
            <v-col cols="12" v-if="loading || leads.length === 0" class="text-center mt-10">
                <v-alert v-if="!loading" type="info" border="left" outlined> Keine Leads gefunden </v-alert>
                <v-progress-circular v-else indeterminate color="primary" />
            </v-col>
            <v-col v-for="lead of leads" :key="lead._id" cols="12" md="4" sm="6">
                <v-card class="mx-auto pb-5 rounded-xl" dark hover max-width="344">
                    <v-row>
                        <v-col class="px-10">
                            <v-card-subtitle class="text-center pb-0 mb-0 d-flex justify-center align-center">
                                {{ getStudioOfId(lead.studio) }}
                            </v-card-subtitle>
                            <v-card-subtitle class="text-center pb-0 mb-0 d-flex justify-center align-center"
                                ><v-icon color="primary" class="mr-2">mdi-calendar-clock</v-icon>
                                {{ lead.createdAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-subtitle
                            >
                            <v-card-subtitle class="text-center mt-0 pt-0 d-flex justify-center align-center"
                                ><v-icon color="primary" class="mr-2">mdi-pencil</v-icon>
                                {{ lead.updatedAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-subtitle
                            >
                            <v-card-text v-if="lead.name" class="user-selectable text-center my-0 py-0">{{
                                lead.name
                            }}</v-card-text>
                            <v-card-text v-if="lead.surname" class="user-selectable text-center my-0 py-0">{{
                                lead.surname
                            }}</v-card-text>
                            <v-card-text v-if="lead.email" class="user-selectable text-center my-0 py-0">{{
                                lead.email
                            }}</v-card-text>
                            <v-card-text v-if="lead.phone" class="user-selectable text-center my-0 py-0">{{
                                lead.phone
                            }}</v-card-text>
                            <v-card-text v-if="lead.message" class="user-selectable text-center my-0 py-0">{{
                                lead.message
                            }}</v-card-text>
                            <v-textarea
                                class="pt-0 mt-5"
                                filled
                                v-model="lead.notes"
                                label="Anmerkungen"
                                outlined></v-textarea>
                            <v-card-text v-if="!selectedLeadSetting.status.includes(lead.status)"
                                >ungültiger Status: {{ lead.status }}</v-card-text
                            >
                            <v-select
                                filled
                                v-model="lead.status"
                                :items="selectedLeadSetting.status"
                                label="Status"
                                persistent-hint
                                single-line></v-select
                        ></v-col>
                    </v-row>

                    <v-card-actions class="d-flex justify-center align-center mb-10"
                        ><v-btn @click="updateLead(lead)" color="primary" class="white--text"
                            >Speichern</v-btn
                        ></v-card-actions
                    >
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'

export default {
    components: {},
    data() {
        return {
            loading: true,
            error: false,
            selectedLeadSetting: null,
            selectedStatus: 'Wurde angeworben',
            loadingLead: false,
            loadingError: false,
            leadSettings: [],
            leads: [],
            hosts: [],
            studios: [],
        }
    },
    created() {
        this.loading = true
        this.init()
    },
    methods: {
        getStudioOfId(id) {
            let studio = this.studios.find((e) => e._id.toString() === id.toString())
            if (studio) return studio.name
            return 'Studio nicht gefunden'
        },
        downloadQRCode(link) {
            api.fetchQRCode(link)
        },
        copyLink(link) {
            navigator.clipboard.writeText(link)
            this.$toast.success('Link wurde kopiert')
        },
        async updateLead(lead) {
            let status = await api.updateLead(lead).catch((e) => this.$toast.error('Es gab einen Fehler'))
            if (status) {
                this.fetchLeads(this.selectedStatus)
                this.$toast.success('Erfolgreich aktualisiert')
            }
        },
        async selectLeadSetting(lead) {
            this.selectedLeadSetting = lead
            if (lead === null) this.leads = []

            this.loadingLead = true
            this.selectedStatus = 'Wurde angeworben'
            this.fetchLeads()
        },
        async fetchLeads(status = 'Wurde angeworben') {
            this.loading = true
            this.leads = []
            if (this.selectedLeadSetting && this.selectedLeadSetting._id) {
                let leadInformation = await api.fetchLeads(this.selectedLeadSetting._id, status).catch((e) => {
                    this.loadingError = true
                    this.$toast.error('Es ist ein Fehler aufgetreten')
                })
                if (leadInformation) this.leads = leadInformation
            }

            this.loading = false
        },
        resetSelection() {
            this.selectedLeadSetting = null
            this.leads = []

            this.init()
        },
        async init() {
            let hostnames = await api.fetchHostnames()
            if (hostnames) this.hosts = hostnames
            let studios = await api.fetchStudios()
            if (studios) this.studios = studios
            let leads = await api.fetchLeadOverview().catch((e) => {
                this.loading = false
                this.error = true
            })
            if (leads) this.leadSettings = leads
            this.loading = false
            this.$emit('finishedloading')
        },
    },
    watch: {
        selectedStatus: async function (newStatus, oldStatus) {
            if (newStatus !== oldStatus) {
                this.fetchLeads(newStatus)
            }
        },
    },
}
</script>
<style lang="scss" scoped></style>
