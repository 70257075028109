<template>
    <div class="courseSettings">
        <v-row no-gutters>
            <v-col cols="12" class="my-5">
                <v-btn color="primary" @click="showStudioPicker">Studio wechseln</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters class="py-5 px-5">
            <v-col cols="12">
                <v-text-field
                    dark
                    filled
                    type="number"
                    :label="'Anmeldungen ' + courseSettings.enableSignup + ' Tage vor Kursbeginn'"
                    v-model="courseSettings.enableSignup"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    dark
                    filled
                    type="number"
                    :label="'Anmeldungen bis ' + courseSettings.signaupTimeLimit + ' Min vor Kursbeginn'"
                    v-model="courseSettings.signupTimeLimit"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    dark
                    filled
                    type="number"
                    :label="'Abmeldungen bis ' + courseSettings.signoffTimeLimit + ' Min vor Kursbeginn'"
                    v-model="courseSettings.signoffTimeLimit"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    dark
                    filled
                    type="number"
                    label="Anz. sichtbare Tage (Kunden)"
                    v-model="courseSettings.visibleDays"></v-text-field>
            </v-col>
            <v-col class="d-flex justify-center py-5"
                ><v-btn @click="saveCourseSettings" color="primary">Speichern</v-btn></v-col
            >
        </v-row>
    </div>
</template> 

<script>
import api from '../../../api'

export default {
    data() {
        return {
            courseSettings: {
                enableSignup: 3,
                signupTimeLimit: 5,
                signoffTimeLimit: 5,
                visibleDays: 7,
            },
        }
    },
    async created() {
        await this.fetchCourseSettings()
        this.$emit('finishedloading')
    },
    methods: {
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
        async saveCourseSettings() {
            let saved = await api.updateGFCourseSettings(this.courseSettings)
        },
        async fetchCourseSettings() {
            this.courseSettings = await api.fetchGFCourseSettings()
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
