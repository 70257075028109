<template>
    <div>
        <v-btn @click="$router.push('/nutrition')"></v-btn>
        <v-autocomplete
            dark
            v-model="model"
            :items="results"
            :loading="isLoading"
            :search-input.sync="searchString"
            color="white"
            hide-no-data
            hide-selected
            @change="getReceiptData"
            item-value="id"
            item-text="optional.title"
            label="CookButler Rezepte"
            placeholder="Tippe um zu suchen"
            prepend-icon="mdi-database-search"
            return-object
        >
            <!-- Filter -->
            <template v-slot:append-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Filter</v-list-item-title>
                        <v-row>
                            <v-col v-for="(filter, filterName) in filters" :key="filterName">
                                <template v-if="filter.type === 'checkbox'">
                                    <v-checkbox
                                        v-for="(valueLabel, valueName) in filter.value"
                                        :key="valueName"
                                        :label="valueLabel"
                                    ></v-checkbox>
                                </template>
                                <template v-else-if="filter.type === 'radio'">
                                    <v-radio-group v-model="selectedFilters[filterName]">
                                        <v-radio
                                            v-for="(valueLabel, valueName) in filter.value"
                                            :key="valueName"
                                            :label="valueLabel"
                                            :value="valueName"
                                        ></v-radio>
                                    </v-radio-group>
                                </template>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:item="data">
                <v-list-item-avatar>
                    <img :src="data.item.media.search[0]" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-html="data.item.optional.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.optional.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <v-card dark v-if="receipt.recipe.title">
            <v-img contain max-height="200px" :src="receipt.media.preview[0]"></v-img>
            <v-card-title>
                {{ receipt.recipe.title }}
            </v-card-title>
            <v-col cols="12">
                <span>Kategorie:</span>
                <v-chip :key="cat" v-for="cat of receipt.category" :id="cat">{{ cat }}</v-chip>
            </v-col>
            <v-col cols="12">
                <span>Schwierigkeitsgrad:</span>
                <v-chip>{{ receipt.recipe.difficulty }}</v-chip>
            </v-col>
            <v-col cols="12">
                <span>Portionen:</span>
                <v-chip>{{ receipt.recipe.serving }}</v-chip>
            </v-col>

            <v-col cols="12">
                <span>Dauer:</span>
                <v-chip class="ml-2" :key="time.timetype" v-for="time of receipt.time" :id="time.timetype">
                    {{ time.timetype + ': ' + time.value + 'Minuten' }}
                </v-chip>
            </v-col>
            <v-col cols="12">
                <span>Diäten:</span>
                <v-chip
                    class="ml-2 mb-1 white--text"
                    v-for="diet of receipt.diets"
                    :id="diet.diet"
                    :key="diet.diet"
                    :color="diet.value ? 'primary' : 'red'"
                >
                    {{ diet.diet }}
                </v-chip>
            </v-col>
            <v-col cols="12">
                <span>Allergene:</span>
                <v-chip
                    class="ml-2 mb-1 white--text"
                    v-for="allergen of receipt.allergens"
                    :key="allergen.allergen"
                    :id="allergen.allergen"
                    :color="allergen.value ? 'primary' : 'red'"
                >
                    {{ allergen.allergen }}
                </v-chip>
            </v-col>
            <v-col cols="12" v-if="receipt && receipt.country && receipt.country.length">
                <span>Ursprung:</span>
                <v-chip>{{ receipt.country[0] }}</v-chip>
            </v-col>
            <v-card-title style="font-weight-bold" class="mb-0">Zutaten</v-card-title>
            <v-col cols="12" :key="'+' + index" v-for="(ingredient, index) of receipt.ingredients">
                <span class="h6">
                    <v-chip>
                        {{ ingredient.full_text }}
                    </v-chip>
                </span>
            </v-col>
            <v-card-title style="font-weight-bold" class="mb-0">Schritte</v-card-title>
            <v-col cols="12" :key="'y' + index" v-for="(step, index) of receipt.steps">
                <span class="h6">
                    {{ '(' + (index + 1) + ')' + step.step_text }}
                </span>
            </v-col>

            <v-col cols="12">
                <v-card-title>Inhaltsstoffe</v-card-title>
                <v-data-table
                    :search="substanceSearch"
                    :headers="substancesHeaders"
                    :items="receipt.substances.filter((subs) => subs.recipe.amount !== 0)"
                >
                    <template v-slot:top>
                        <v-text-field v-model="substanceSearch" label="Search" class="mx-4"></v-text-field>
                    </template>
                    <template v-slot:item.recipe.amount="{ item }">
                        <span>
                            {{ item.recipe.amount + (item.unit ? ' ' + item.unit : item.unit_short) }}
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-card>
    </div>
</template>
  
  <script>
import axios from 'axios'
import api from '../../../api'

export default {
    data() {
        return {
            model: null,
            substanceSearch: '',
            results: [],
            selectedFilters: {},
            receipt: {
                recipe: {
                    title: '',
                },
                media: {
                    preview: [],
                },
            },
            isLoading: false,
            searchString: '',
            filters: [],
            substancesHeaders: [
                {
                    text: 'Name',
                    value: 'substance',
                },
                { text: 'Typ', value: 'type' },
                { text: 'Wert', value: 'recipe.amount' },
            ],
        }
    },
    async created() {
        let filters = await api.getNutritionSearchFilters()
        this.filters = filters.data.filter
        console.log(this.filters)
    },
    methods: {
        async search() {
            if (this.isLoading) return
            this.isLoading = true
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
            }

            // Starten Sie ein neues Timeout für 500 Millisekunden
            this.searchTimeout = setTimeout(async () => {
                let results = await api.searchNutritionApi({ query: this.searchString })
                this.results = results.data.recipes
                this.isLoading = false
            }, 500)
        },
        async getReceiptData(val) {
            let receipt = await api.getReceiptNutritionApi(val.id)

            this.receipt = receipt.data
        },
    },
    watch: {
        async searchString(val) {
            await this.search()
        },
    },
    computed: {},
}
</script>
  