<template>
    <div class="popups">
        <v-row>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn color="primary" class="white--text mx-auto" @click="createPopup()">Hinzufügen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" lg="4" xl="3" v-for="popup of popups" :key="popup._id">
                <v-card
                    dark
                    hover
                    @click="editPopup(popup)"
                    :class="{
                        active:
                            popup.hideAfter &&
                            popup.showAfter &&
                            $moment(popup.hideAfter).set({ hours: 23, minutes: 59, seconds: 59 }).unix() >
                                $moment().unix() &&
                            $moment(popup.showAfter).set({ hours: 0, minutes: 0, seconds: 0 }).unix() <
                                $moment().unix(),
                    }"
                    class="popups__list-entry fill-height rounded-xl"
                >
                    <v-card-title class="">{{ popup.identifier }}</v-card-title>
                    <v-card-subtitle class="pb-2 pt-1">
                        {{ $moment(popup.showAfter).format('DD.MM.YYYY') }} -
                        {{ $moment(popup.hideAfter).format('DD.MM.YYYY') }}
                    </v-card-subtitle>

                    <v-card-subtitle v-if="popup.seenBy && popup.seenBy.length > 0">
                        {{ popup.seenBy.length }} mal gesehen
                    </v-card-subtitle>
                    <v-card-subtitle v-if="popup.clickedBy && popup.clickedBy.length > 0">
                        {{ popup.clickedBy.length }} mal angeklickt
                    </v-card-subtitle>
                    <div class="studios mx-5">
                        <v-chip small class="studio ma-1" v-for="studio of popup.studios" :key="studio._id">
                            {{ studio.name }}
                        </v-chip>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog fullscreen class="popups__dialog" v-model="showEditDialog" persistent max-width="800px">
            <v-card
                max-height="70vh"
                height="800px"
                max-width="500"
                class="mx-auto overflow-y-auto d-flex flex-column editor"
            >
                <v-card-title v-if="popup && popup.identifier" class="popups__dialog-title">
                    {{ popup.identifier }}
                </v-card-title>
                <v-card-title v-else class="popups__dialog-title">Neues PopUp</v-card-title>

                <slim-cropper
                    :key="popup.image"
                    style="width: 300px; margin: auto"
                    class="d-table"
                    :options="slimOptionsExercise"
                >
                    <img :key="popup.image" v-if="popup.image" :src="'/img/' + popup.image" alt="" />
                    <input type="file" accept="image/*" />
                </slim-cropper>

                <v-card-subtitle class="text-center">Format: 16:9 (1920x1080)</v-card-subtitle>

                <v-text-field
                    class="mx-5 mt-1"
                    v-model="popup.identifier"
                    label="interne Bezeichnung"
                    placeholder="10 Tages Kampagne"
                ></v-text-field>
                <v-text-field v-model="popup.title" label="Titel"></v-text-field>
                <vue-editor :editorToolbar="customToolbar" v-model="popup.text"></vue-editor>
                <v-text-field
                    class="mx-5 mt-1"
                    v-model="popup.link"
                    label="Link"
                    placeholder="https://app.wellcomefit.ch/..."
                ></v-text-field>
                <v-text-field
                    class="mx-5"
                    v-model="popup.linkText"
                    label="Link Text"
                    placeholder="Bring a friend"
                ></v-text-field>
                <v-switch
                    class="mx-5"
                    v-if="!popup.customersOnly"
                    v-model="popup.employeesOnly"
                    :label="`Nur für Mitarbeiter anzeigen`"
                ></v-switch>
                <v-switch
                    class="mx-5"
                    v-if="!popup.employeesOnly"
                    v-model="popup.customersOnly"
                    :label="`Nur für Kunden anzeigen`"
                ></v-switch>
                <v-switch class="mx-5" v-model="popup.viewOnlyOnce" :label="`Einmalig anzeigen`"></v-switch>
                <v-text-field
                    v-if="!popup.viewOnlyOnce"
                    type="number"
                    class="mx-5"
                    v-model="popup.cooldown"
                    label="Nach x Stunden erneut anzeigen, falls weggeklickt"
                    placeholder="0"
                ></v-text-field>

                <v-date-picker locale="de-DE" class="mx-auto" v-model="datePicker" range></v-date-picker>
                <v-select
                    class="mx-5"
                    v-model="popup.studios"
                    :items="studios"
                    item-text="name"
                    item-value="_id"
                    attach
                    chips
                    label="Studios"
                    multiple
                ></v-select>
                <v-card-actions class="popups__dialog-actions">
                    <v-btn color="grey darken-1" text @click="showEditDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :loading="working" color="primary darken-1" text @click="savePopup">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'popups',
    components: { VueEditor, 'slim-cropper': Slim },
    data() {
        return {
            studios: [],
            working: false,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
            showEditDialog: false,
            datePicker: null,
            popups: [],
            popup: null,
            slimOptionsExercise: {
                ratio: '16:9',
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
        }
    },
    created() {
        this.fetchPopups()
        this.initPopup()
    },
    computed: {},
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility', 'toggleNavigationBarVisibility']),
        initPopup() {
            this.datePicker = null
            this.popup = {
                hideAfter: null,
                showAfter: null,
                link: '',
                linkText: '',
                customerOnly: false,
                employeesOnly: false,
                cooldown: 24,
                image: '',
                text: '',
                viewOnlyOnce: false,
                studios: [],
                identifier: '',
            }
        },
        editPopup(popup) {
            this.popup = popup
            this.datePicker = [popup.showAfter, popup.hideAfter]

            this.showEditDialog = true
        },
        createPopup() {
            this.initPopup()
            this.showEditDialog = true
        },
        async fetchPopups() {
            let popups = await api.fetchPopups()
            this.studios = popups.studios
            this.popups = popups.popups
            this.$emit('finishedloading')
        },
        async savePopup() {
            this.working = true
            let status = await api.savePopup(this.popup)
            this.showEditDialog = false
            this.working = false
            this.fetchPopups()
            this.initPopup()
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'popup'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.popup.image = data.name

                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
    },
    watch: {
        showEditDialog(value) {
            console.log('popup status', value)
            this.setNavigationbarVisibility(!value)
        },
        datePicker(value) {
            if (value[0] && value[1]) {
                this.popup.showAfter = value[0]
                this.popup.hideAfter = value[1]
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.popups {
    &__list {
        &-entry {
            &.active {
                background-color: rgba(56, 180, 56, 0.822);
            }
        }
    }
    &__dialog {
        position: relative;
        &-title {
            position: sticky;
            top: 0;
            background-color: white;
            border-bottom: 2px solid var(--v-primary);
            z-index: 9;
        }
        &-actions {
            position: sticky;
            bottom: 0;
            background-color: white;
            border-top: 2px solid var(--v-primary);
        }
    }
}
</style>
