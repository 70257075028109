<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Kursausführung bearbeiten</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Kurs ändern zu:</label>
            <input placeholder="Kursliste filtern..." type="text" v-model="kurssuche" @change="filterkurse" />
            <select v-model="course_timeline.course_id">
              <option v-for="(kurs, i) of coursefiltered" :key="i" :value="kurs._id">{{ kurs.name }}</option>
            </select>

            <user-search v-show="!tempvertretungcheck" :value="course_timeline.instructor_id" label="Instruktor ändern" @update="pickInstructor" returnUser instructor></user-search>

            <label>Temporäre Vertretung</label>
            <div class="tempvertretung"><input v-model="tempvertretungcheck" type="checkbox" />Vertretung anstelle von Instruktor</div>

            <input type="text" v-show="tempvertretungcheck" v-model="course_timeline.instructor_name" placeholder="Name Vorname der Vertretung" />
            <label>Raum ändern</label>

            <select v-model="course_timeline.room_name">
              <option v-for="(raum, j) of rooms" :key="j" :value="raum.name">{{ raum.name }}</option>
            </select>

            <label>Datum ändern</label>
            <input id="datum" type="date" />

            <label>Zeit ändern</label>
            <input id="zeit" type="time" />

            <label>Dauer ändern (min)</label>

            <input type="number" id="dauer" />

            <label>Anzahl Plätze ändern</label>

            <input type="number" id="plaetze" />

            <div class="cancel-kurs" v-show="userService.hasRight('gf_cp_cancel')" @click="cancelKurs">
              KURS ABSAGEN
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button v-show="userService.hasRight('gf_cp_edit')" type="button" :disabled="savedisabled" class="btn btn-primary" @click.prevent="save">
            Speichern
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import api from '../api'
import userService from '../services/userService'
import userSearch from './userSearch.vue'

export default {
  components: { userSearch },
  data() {
    return {
      error: '',
      userService,
      savedisabled: false,
      course_timeline: {},
      course: {},
      coursefiltered: {},
      kurssuche: '',
      rooms: [],
      tempvertretung: '',
      tempvertretungcheck: false,
    }
  },
  methods: {
    pickInstructor(instructor) {
      console.log(instructor)
      this.course_timeline.instructor_name = instructor.vorname + ' ' + instructor.nachname

      this.course_timeline.instructor_id = instructor._id
    },
    cancelKurs() {
      api
        .cancelKurs(this.course_timeline._id)
        .then((data) => {
          this.hide()
          this.$emit('kurs-edited')
        })
        .catch((error) => {
          alert('fehler')
          console.log(error)
        })
    },
    filterkurse() {
      this.coursefiltered = this.course.filter((kurs) => {
        return kurs.name.toLowerCase().indexOf(this.kurssuche.toLowerCase()) > -1 || kurs._id == this.course_timeline.course_id
      })
    },
    show(ex) {
      this.course_timeline.instructor_id = ex.instructor_id
      api.getUserRights().then((r) => (this.rights = r))
      document.querySelector('#datum').valueAsDate = new Date(ex.timestamp * 1000)
      document.querySelector('#zeit').value = new Date(ex.timestamp * 1000).toLocaleString('de-CH', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      document.querySelector('#dauer').value = (ex.timestamp_end - ex.timestamp) / 60

      document.querySelector('#plaetze').value = ex.course_free_spaces.split('/')[1]

      this.course_timeline = ex
      api
        .getKurse()
        .then((data) => {
          this.course = data.course
          this.coursefiltered = data.course
        })
        .catch((error) => {
          console.log(error)
        })
      api
        .getRooms(ex.studio_id)
        .then((raeume) => {
          this.rooms = raeume.room
        })
        .catch((error) => {
          alert('fehler')
        })
      $(this.$refs['modal']).modal('show')
    },
    hide() {
      api
        .generateTimelines()
        .then((data) => {
          $(this.$refs['modal']).modal('hide')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    save() {
      this.course_timeline.course_free_spaces = this.course_timeline.course_free_spaces.split('/')[0] + '/' + document.querySelector('#plaetze').value

      let course_name = ''
      let course_bild = ''
      for (let kurs of this.course) {
        if (kurs._id == this.course_timeline.course_id) {
          course_name = kurs.name
          course_bild = kurs.bild
        }
      }
      this.course_timeline.course_name = course_name
      this.course_timeline.course_bild = course_bild
      let ts = new Date(document.querySelector('#datum').value).setHours(parseInt(document.querySelector('#zeit').value.split(':')[0]))

      ts = new Date(ts).setMinutes(parseInt(document.querySelector('#zeit').value.split(':')[1]))
      ts = Math.round(ts / 1000)
      let ts_end = Math.round(ts + document.querySelector('#dauer').value * 60)
      this.course_timeline.timestamp = ts
      this.course_timeline.timestamp_end = ts_end
      this.savedisabled = true
      api
        .editGeneratedTimeline(this.course_timeline)
        .then((data) => {
          if (data.status) {
            this.savedisabled = false
            this.hide()
            this.$emit('kurs-edited')
          }
        })
        .catch((error) => {
          alert('fehler')
          console.log(error)
        })
      this.$emit('kurs-edited')
    },
  },
}
</script>

<style lang="scss" scoped>
.tempvertretung {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  input {
    width: 20px !important;
    height: 20px;
    margin-right: 10px;
  }
}
.cancel-kurs {
  background-color: red;
  width: 130px;
  text-align: center;
  margin: 120px auto 0px auto;
  color: white;
}
.modal-content {
  height: 95vh;
  overflow: scroll;

  .modal-body {
    .form-group {
      label {
        width: 100%;
        font-weight: 500;
      }

      select {
        margin-bottom: 10px;
        width: 100%;
      }

      input,
      textarea {
        margin-bottom: 10px;
        width: 100%;
        border-bottom: 1px solid black;
      }
    }
  }
}
</style>
