<template>
    <div>
        <apexchart type="line" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
 
<script>
export default {
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'leadsPerSettingAndTime',
                    type: 'line',
                },
                legend: {
                    labels: {
                        colors: '#ffffff',
                        useSeriesColors: false,
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                yaxis: {
                    title: {
                        text: 'Anzahl der Weiterempfehlungen',
                    },
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                    min: 0,
                },
                dataLabels: {
                    enabled: false,
                },
            },
            series: this.leadsPerSettingAndTime,
        }
    },
    props: {
        leadsPerSettingAndTime: Array,
    },
}
</script> 