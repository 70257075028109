<template>
    <v-row justify="center">
        <v-dialog
            style="z-index: 2000"
            persistent
            :content-class="selectedHabit ? 'extended-dialog' : ''"
            v-model="dialog"
            transition="dialog-bottom-transition"
            origin="bottom center"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" icon class="elevation-5" x-large>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <v-card-title class="justify-content-center primary white--text">
                {{ selectedHabit.label }} Bearbeiten
                <v-btn icon color="white" @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-row class="justify-space-around pa-7" no-gutters>
                <v-col v-if="selectedHabit" cols="12">
                    <habit-drink-form
                        @habit-saved="closeDialog()"
                        :selectedHabit="selectedHabit"
                        edit
                        v-if="selectedHabit.type === 'drinkHabit'"
                    ></habit-drink-form>
                    <habit-workout-form
                        @habit-saved="closeDialog()"
                        :selectedHabit="selectedHabit"
                        edit
                        v-else-if="selectedHabit.type === 'workoutHabit'"
                    ></habit-workout-form>
                    <habit-group-fitness-form
                        @habit-saved="closeDialog()"
                        :selectedHabit="selectedHabit"
                        edit
                        v-else-if="selectedHabit.type === 'groupFitnessHabit'"
                    ></habit-group-fitness-form>
                    <habit-wake-up-form
                        @habit-saved="closeDialog()"
                        :selectedHabit="selectedHabit"
                        edit
                        v-else-if="selectedHabit.type === 'wakeUpHabit'"
                    ></habit-wake-up-form>
                    <div v-else>Form für habit noch nicht erstellt.</div>
                </v-col>
            </v-row>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitDrinkForm from './habitDrinkForm.vue'
import habitWorkoutForm from './habitWorkoutForm.vue'
import habitGroupFitnessForm from './habitGroupFitnessForm.vue'
import habitWakeUpForm from './habitWakeUpForm.vue'
export default {
    name: 'habitEdit',
    components: {
        habitDrinkForm,
        habitWorkoutForm,
        habitGroupFitnessForm,
        habitWakeUpForm,
    },
    props: {
        selectedHabit: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dialog: true,
        }
    },
    methods: {
        closeDialog() {
            console.log('testttt')
            this.dialog = false
            setTimeout(() => this.$emit('closeEdit'), 250)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    margin: 0;
    position: absolute;
    min-height: 40vh;
    bottom: 0;
    background: white;
    max-height: 50vh;
}
::v-deep .extended-dialog {
    max-height: 90vh !important;
}

.option-button {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background: lightgray;
        &-bulb {
            ::v-deep .v-image__image {
                width: 80px;
            }
            flex-direction: row;
        }
    }
    &__wrapper:hover {
        background: gray;
    }
}
</style>
