var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto py-3",attrs:{"dark":""}},[(_vm.loadingEntries)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Suche","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.searching)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{staticClass:"px-5",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.timeEntries),function(employee,index){return _c('v-expansion-panel',{key:employee.user + '-panel'},[_c('v-expansion-panel-header',[_c('span',{class:{
                                'orange--text':
                                    _vm.submissionState(employee) && _vm.submissionState(employee).state === 'submitted',
                                'primary--text':
                                    _vm.submissionState(employee) && _vm.submissionState(employee).state === 'accepted',
                                'red--text':
                                    _vm.submissionState(employee) && _vm.submissionState(employee).state === 'rejected',
                            }},[_vm._v(" "+_vm._s(employee.fullName + ' ' + employee.email)+" ")]),_c('span',{staticClass:"ml-2"},[(_vm.submissionState(employee) && _vm.submissionState(employee).state !== 'onlyNotes')?_c('v-chip',{attrs:{"color":_vm.submissionState(employee) && _vm.submissionState(employee).state === 'rejected'
                                        ? 'red'
                                        : _vm.submissionState(employee) &&
                                          _vm.submissionState(employee).state === 'accepted'
                                        ? 'primary'
                                        : 'orange'}},[_vm._v(" "+_vm._s(_vm.submissionState(employee) && _vm.submissionState(employee).state === 'rejected' ? 'abgelehnt' : _vm.submissionState(employee) && _vm.submissionState(employee).state === 'accepted' ? 'abgeschlossen' : 'eingereicht')+" ")]):_vm._e()],1)]),_c('v-expansion-panel-content',[(index === _vm.panel)?_c('office-time-tracker-view',{attrs:{"studio":_vm.selectedStudioSingle,"timeEntryView":employee,"month":_vm.$moment(_vm.datePickerRange[0]),"office":""},on:{"fetchEntries":_vm.trackerViewUpdated}}):_vm._e()],1)],1)}),1)],1):_vm._e(),(_vm.searching)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(!_vm.timeEntries.length === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mx-5",attrs:{"type":"info"}},[_vm._v("Es wurden keine Einträge gefunden")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }