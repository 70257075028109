<template>
    <div
        v-if="showModal"
        ref="modal"
        class="modal fade"
        tabindex="-1"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Anamnesebogen</h5>

                    <button @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div :class="{ slider: 1, review: formData._id }">
                        <div :class="{ slides: 1, review: formData._id }">
                            <div
                                :class="{ review2: formData._id }"
                                v-show="!kundensicht && active_slide == 0"
                                id="slide-0"
                            >
                                <div v-show="!formData._id">
                                    <user-search
                                        :key="userSearchKey"
                                        v-show="!formData._id"
                                        :value="formData.trainer_id"
                                        rights="a_add"
                                        label="Trainersuche"
                                        @update="pickTrainer"
                                        instructor
                                    ></user-search>
                                </div>
                                <div class="ueberschrift" v-show="formData._id">Trainer kann nicht geändert werden</div>
                            </div>
                            <div
                                v-show="!kundensicht && active_slide == 1"
                                :class="{ review2: formData._id }"
                                id="slide-1"
                            >
                                <user-search
                                    :key="userSearchKey"
                                    ref="userSearch"
                                    v-show="!formData._id && !newAcc.generate"
                                    rights="a_add"
                                    label="Kundensuche"
                                    :value="formData.user._id"
                                    @update="pickUser"
                                    returnUser
                                ></user-search>
                                <div
                                    class="neuer-account"
                                    v-show="!newAcc.generate"
                                    @click="newAcc.generate = !newAcc.generate"
                                >
                                    Neuen roody Account erstellen
                                </div>
                                <div class="neuer-acc-form" v-show="newAcc.generate">
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <select class="geschlecht" v-model="newAcc.geschlecht">
                                                <option selected value="a">Bitte auswählen...</option>
                                                <option value="w">Frau</option>
                                                <option value="m">Herr</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_2"
                                                class="input__text"
                                                type="text"
                                                required
                                                v-model="newAcc.vorname"
                                            />
                                            <label for="slide-1_2">Vorname</label>
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_3"
                                                class="input__text"
                                                type="text"
                                                required
                                                v-model="newAcc.nachname"
                                            />
                                            <label for="slide-1_3">Nachname</label>
                                        </div>
                                    </div>
                                    <div class="tendays-wrapper">
                                        <label>slim me up (Kampagne):</label>
                                        <input type="checkbox" v-model="newAcc.slimMeUp" />
                                    </div>

                                    <div v-show="newAcc.slimMeUp" class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                disabled
                                                id="slide-1_3_1"
                                                class="input__text"
                                                type="date"
                                                required
                                                v-model="newAcc.slimMeUp_date"
                                            />
                                        </div>
                                    </div>
                                    <div class="tendays-wrapper">
                                        <label>10 Tage (Kampagne):</label>
                                        <input type="checkbox" v-model="newAcc.tendays" />
                                    </div>
                                    <div v-show="newAcc.tendays" class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                disabled
                                                id="slide-1_3_1"
                                                class="input__text"
                                                type="date"
                                                required
                                                v-model="newAcc.tendays_date"
                                            />
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_4"
                                                class="input__text"
                                                type="email"
                                                required
                                                v-model="newAcc.email"
                                            />
                                            <label for="slide-1_4">E-Mail</label>
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_4"
                                                class="input__text"
                                                type="text"
                                                required
                                                v-model="newAcc.password"
                                            />
                                            <label for="slide-1_4">Passwort (optional)</label>
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_41"
                                                class="input__text"
                                                type="tel"
                                                required
                                                v-model="newAcc.telefon"
                                            />
                                            <label for="slide-1_4">Telefon</label>
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper">
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_5"
                                                class="input__text"
                                                type="text"
                                                required
                                                v-model="newAcc.adresse_1"
                                            />
                                            <label for="slide-1_5">Strasse & Nr.</label>
                                        </div>
                                    </div>
                                    <div class="notiz_wrapper_plz">
                                        <div class="input-field__text plz-wrapper">
                                            <input
                                                id="slide-1_6"
                                                class="input__text"
                                                type="text"
                                                required
                                                v-model="newAcc.plz"
                                            />
                                            <label for="slide-1_6">PLZ</label>
                                        </div>
                                        <div class="input-field__text">
                                            <input
                                                id="slide-1_7"
                                                class="input__text"
                                                type="text"
                                                required
                                                v-model="newAcc.stadt"
                                            />
                                            <label for="slide-1_7">Stadt</label>
                                        </div>
                                    </div>

                                    <label class="geb-label">Geburtstag</label>
                                    <div class="geb-wrapper">
                                        <div class="notiz_wrapper">
                                            <div class="input-field__text">
                                                <input
                                                    id="slide-1_8"
                                                    class="input__text"
                                                    type="number"
                                                    required
                                                    v-model="newAcc.geb.tag"
                                                />
                                                <label for="slide-1_8">Tag</label>
                                            </div>
                                        </div>
                                        <div class="notiz_wrapper">
                                            <div class="input-field__text">
                                                <input
                                                    id="slide-1_9"
                                                    class="input__text"
                                                    type="number"
                                                    required
                                                    v-model="newAcc.geb.monat"
                                                />
                                                <label for="slide-1_9">Monat</label>
                                            </div>
                                        </div>
                                        <div class="notiz_wrapper">
                                            <div class="input-field__text">
                                                <input
                                                    id="slide-1_10"
                                                    class="input__text"
                                                    type="number"
                                                    required
                                                    v-model="newAcc.geb.jahr"
                                                />
                                                <label for="slide-1_10">Jahr</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ueberschrift" v-show="formData._id">Kunde kann nicht geändert werden</div>
                            </div>
                            <div v-show="filled || active_slide == 2" :class="{ review2: formData._id }" id="slide-2">
                                <div class="radio_wrapper">
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-2_0"
                                            class="input__radio"
                                            type="radio"
                                            name="trainingsart"
                                            :checked="formData.trainingsart == 'Bereits Mitglied'"
                                            @click="formData.trainingsart = 'Bereits Mitglied'"
                                        />
                                        <label for="slide-2_0">Bereits Mitglied</label>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-2_1"
                                            class="input__radio"
                                            type="radio"
                                            name="trainingsart"
                                            :checked="formData.trainingsart == 'Probetraining mit Trainer'"
                                            @click="formData.trainingsart = 'Probetraining mit Trainer'"
                                        />
                                        <label for="slide-2_1">Probetraining mit Trainer</label>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-2_2"
                                            class="input__radio"
                                            type="radio"
                                            name="trainingsart"
                                            :checked="formData.trainingsart == 'Einzeleintritt'"
                                            @click="formData.trainingsart = 'Einzeleintritt'"
                                        />
                                        <label for="slide-2_2">Einzeleintritt</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-2_3"
                                            class="input__radio"
                                            type="radio"
                                            name="trainingsart"
                                            :checked="formData.trainingsart == 'Probetraining Groupfitness'"
                                            @click="formData.trainingsart = 'Probetraining Groupfitness'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-2_3">Probetraining Groupfitness / slim ME</label>

                                            <div class="input-field__text">
                                                <input
                                                    id="slide-2_35"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.trainingsart_notiz"
                                                />
                                                <label for="slide-2_35">Notiz</label>
                                                <br />
                                                <div
                                                    v-show="formData.trainingsart == 'Probetraining Groupfitness'"
                                                    @click="expressAnamnesebogen()"
                                                    class="express-anamnesebogen"
                                                >
                                                    zum Express Anamnesebogen
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 3" :class="{ review2: formData._id }" id="slide-3">
                                <div class="frage_wrapper">
                                    <span class="ueberschrift">
                                        Wie lange überlegst du dir schon mit Fitness zu beginnen?
                                    </span>

                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-3_1"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.fitnesswunsch_dauer"
                                        />
                                        <label for="slide-3_1">Antwort</label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 4" :class="{ review2: formData._id }" id="slide-4">
                                <span class="ueberschrift">Wie bist du auf uns aufmerksam geworden?</span>
                                <div class="radio_wrapper">
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-4_1"
                                            class="input__radio"
                                            type="radio"
                                            name="affiliate"
                                            :checked="formData.affiliate == 'Homepage'"
                                            @click="formData.affiliate = 'Homepage'"
                                        />
                                        <label for="slide-4_1">Homepage</label>
                                    </div>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-4_2"
                                            class="input__radio"
                                            type="radio"
                                            name="affiliate"
                                            :checked="formData.affiliate == 'Internet'"
                                            @click="formData.affiliate = 'Internet'"
                                        />
                                        <label for="slide-4_2">Internet</label>
                                    </div>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-4_3"
                                            class="input__radio"
                                            type="radio"
                                            name="affiliate"
                                            :checked="formData.affiliate == 'Werbung'"
                                            @click="formData.affiliate = 'Werbung'"
                                        />
                                        <label for="slide-4_3">Werbung</label>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-4_4"
                                            class="input__radio"
                                            type="radio"
                                            name="affiliate"
                                            :checked="formData.affiliate == 'Facebook'"
                                            @click="formData.affiliate = 'Facebook'"
                                        />
                                        <label for="slide-4_4">Facebook</label>
                                    </div>

                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-4_5"
                                            class="input__radio"
                                            type="radio"
                                            name="affiliate"
                                            :checked="formData.affiliate == 'Empfehlung durch:'"
                                            @click="formData.affiliate = 'Empfehlung durch:'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-4_5">Empfehlung durch:</label>

                                            <div class="input-field__text">
                                                <input
                                                    id="slide-4_55"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.affiliate_notiz"
                                                />
                                                <label for="slide-4_55">Empfehlung</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="filled || active_slide == 5" :class="{ review2: formData._id }" id="slide-5">
                                <div class="frage_wrapper">
                                    <span class="ueberschrift">Weiterempfehlung über ROODY?</span>
                                    <div class="radio_wrapper">
                                        <div class="input-field__radio">
                                            <input
                                                id="slide-5_1"
                                                class="input__radio"
                                                type="radio"
                                                name="roody"
                                                :checked="formData.roody == 'Ja'"
                                                @click="formData.roody = 'Ja'"
                                            />
                                            <label for="slide-5_1">Ja</label>
                                        </div>
                                        <div class="input-field__radio">
                                            <input
                                                id="slide-5_2"
                                                class="input__radio"
                                                type="radio"
                                                name="roody"
                                                :checked="formData.roody == 'Nein'"
                                                @click="formData.roody = 'Nein'"
                                            />
                                            <label for="slide-5_2">Nein</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 6" :class="{ review2: formData._id }" id="slide-6">
                                <div class="frage_wrapper">
                                    <span class="ueberschrift">Allgemeine Informationen</span>
                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-6_1"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.allgemeineInfos.krankenkasse"
                                        />
                                        <label for="slide-6_1">Krankenkasse</label>
                                    </div>
                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-6_2"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.allgemeineInfos.beruf"
                                        />
                                        <label for="slide-6_2">Beruf</label>
                                    </div>
                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-6_3"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.allgemeineInfos.kinder"
                                        />
                                        <label for="slide-6_3">Kinder</label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 7" :class="{ review2: formData._id }" id="slide-7">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Fitnesserfahrung</span>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-10_1"
                                            class="input__radio"
                                            type="radio"
                                            name="fitnesserfahrung"
                                            :checked="formData.fitnesserfahrung == 'Ja'"
                                            @click="formData.fitnesserfahrung = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-10_1">Ja</label>

                                            <div class="input-field__text">
                                                <input
                                                    id="slide-10_11"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.fitnesserfahrung_notiz"
                                                />
                                                <label for="slide-10_11">Jahre / Center</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-10_2"
                                            class="input__radio"
                                            type="radio"
                                            name="fitnesserfahrung"
                                            :checked="formData.fitnesserfahrung == 'Nein'"
                                            @click="formData.fitnesserfahrung = 'Nein'"
                                        />
                                        <label for="slide-10_2">Nein</label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 8" :class="{ review2: formData._id }" id="slide-8">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Hast du sonstige Hobbys / Sport?</span>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-11_1"
                                            class="input__radio"
                                            type="radio"
                                            name="hobbys"
                                            :checked="formData.hobbys == 'Ja'"
                                            @click="formData.hobbys = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-11_1">Ja</label>

                                            <div class="input-field__text">
                                                <input
                                                    id="slide-11_11"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.hobbys_notiz"
                                                />
                                                <label for="slide-11_11">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-11_2"
                                            class="input__radio"
                                            type="radio"
                                            name="hobbys"
                                            :checked="formData.hobbys == 'Nein'"
                                            @click="formData.hobbys = 'Nein'"
                                        />
                                        <label for="slide-11_2">Nein</label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 9" :class="{ review2: formData._id }" id="slide-9">
                                <div class="frage_wrapper">
                                    <span class="ueberschrift">Trainingsziele</span>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_1"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.koerperfettreduktion"
                                        />
                                        <label for="slide-7_1">Körperfettreduktion</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_11"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.koerperfettreduktion_notiz"
                                            />
                                            <label for="slide-7_11">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_2"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.muskelaufbau"
                                        />
                                        <label for="slide-7_2">Muskelaufbau</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_21"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.muskelaufbau_notiz"
                                            />
                                            <label for="slide-7_21">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_3"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.rueckenkraeftigung"
                                        />
                                        <label for="slide-7_3">Rückenkräftigung</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_31"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.rueckenkraeftigung_notiz"
                                            />
                                            <label for="slide-7_31">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_4"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.ausdauer"
                                        />
                                        <label for="slide-7_4">Ausdauer verbessern</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_41"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.ausdauer_notiz"
                                            />
                                            <label for="slide-7_41">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_5"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.stressabbau"
                                        />
                                        <label for="slide-7_5">Stressabbau</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_51"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.stressabbau_notiz"
                                            />
                                            <label for="slide-7_51">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_6"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.beweglichkeit"
                                        />
                                        <label for="slide-7_6">Beweglichkeit verbessern</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_61"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.beweglichkeit_notiz"
                                            />
                                            <label for="slide-7_61">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input
                                            id="slide-7_7"
                                            type="checkbox"
                                            v-model="formData.trainingsziele.koordination"
                                        />
                                        <label for="slide-7_7">Koordination verbessern</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_71"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.koordination_notiz"
                                            />
                                            <label for="slide-7_71">Notiz</label>
                                        </div>
                                    </div>

                                    <div class="input-field__checkbox notiz">
                                        <input id="slide-7_8" type="checkbox" v-model="formData.gesundheit" />
                                        <label for="slide-7_8">Gesundheit</label>
                                        <div class="input-field__text">
                                            <input
                                                required
                                                id="slide-7_81"
                                                class="input__text"
                                                type="text"
                                                v-model="formData.trainingsziele.gesundheit_notiz"
                                            />
                                            <label for="slide-7_81">Notiz</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 10" :class="{ review2: formData._id }" id="slide-10">
                                <div class="frage_wrapper">
                                    <span class="ueberschrift">Trainingsziele</span>
                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-8_1"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.trainingsziele_2.kurzfristig"
                                        />
                                        <label for="slide-8_1">Trainingsziel kurzfristig</label>
                                    </div>
                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-8_2"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.trainingsziele_2.mittelfristig"
                                        />
                                        <label for="slide-8_2">Trainingsziel mittelfristig</label>
                                    </div>
                                    <div class="input-field__text">
                                        <input
                                            required
                                            id="slide-8_3"
                                            class="input__text"
                                            type="text"
                                            v-model="formData.trainingsziele_2.langfristig"
                                        />
                                        <label for="slide-8_3">Trainingsziel langfristig</label>
                                    </div>
                                </div>
                            </div>

                            <div v-show="filled || active_slide == 11" :class="{ review2: formData._id }" id="slide-11">
                                <div class="ueberschrift">Wie wichtig ist dir dein Trainingsziel?</div>
                                <div class="container_rate">
                                    <div class="moji">⭐⭐⭐⭐⭐</div>
                                    <div class="slider">
                                        <input
                                            id="slider-9_1"
                                            type="range"
                                            min="1"
                                            max="5"
                                            step="1"
                                            v-model="formData.rate"
                                        />
                                    </div>
                                </div>
                                <div class="input-field__text">
                                    <input
                                        required
                                        id="slide-9_2"
                                        class="input__text"
                                        type="text"
                                        v-model="formData.rate_notiz"
                                    />
                                    <label for="slide-9_2">Antwort</label>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 12" :class="{ review2: formData._id }" id="slide-12">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Wie häufig kannst du wöchentlich trainieren?</span>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-12_1"
                                            class="input__radio"
                                            type="radio"
                                            name="wt"
                                            :checked="formData.intervall == '1-2x'"
                                            @click="formData.intervall = '1-2x'"
                                        />
                                        <label for="slide-12_1">1-2x</label>
                                    </div>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-12_2"
                                            class="input__radio"
                                            type="radio"
                                            name="wt"
                                            :checked="formData.intervall == '2-3x'"
                                            @click="formData.intervall = '2-3x'"
                                        />
                                        <label for="slide-12_2">2-3x</label>
                                    </div>

                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-12_3"
                                            class="input__radio"
                                            type="radio"
                                            name="wt"
                                            :checked="formData.intervall == 'häufiger'"
                                            @click="formData.intervall = 'häufiger'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-12_3">häufiger</label>

                                            <div class="input-field__text">
                                                <input
                                                    id="slide-12_31"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.intervall_notiz"
                                                />
                                                <label for="slide-12_31">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 13" :class="{ review2: formData._id }" id="slide-13">
                                <span class="ueberschrift">Trainingsdauer pro Training?</span>
                                <div class="radio_wrapper">
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-13_1"
                                            class="input__radio"
                                            type="radio"
                                            name="tdauer"
                                            :checked="formData.trainingsdauer == '45 min'"
                                            @click="formData.trainingsdauer = '45 min'"
                                        />
                                        <label for="slide-13_1">45 min</label>
                                    </div>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-13_2"
                                            class="input__radio"
                                            type="radio"
                                            name="tdauer"
                                            :checked="formData.trainingsdauer == '60 min'"
                                            @click="formData.trainingsdauer = '60 min'"
                                        />
                                        <label for="slide-13_2">60 min</label>
                                    </div>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-13_3"
                                            class="input__radio"
                                            type="radio"
                                            name="tdauer"
                                            :checked="formData.trainingsdauer == '90 min'"
                                            @click="formData.trainingsdauer = '90 min'"
                                        />
                                        <label for="slide-13_3">90 min</label>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-13_4"
                                            class="input__radio"
                                            type="radio"
                                            name="tdauer"
                                            :checked="formData.trainingsdauer == 'länger'"
                                            @click="formData.trainingsdauer = 'länger'"
                                        />
                                        <label for="slide-13_4">länger</label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 14" :class="{ review2: formData._id }" id="slide-14">
                                <span class="ueberschrift">Trainingstage</span>
                                <div class="checkbox_wrapper">
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_1"
                                            type="checkbox"
                                            v-model="formData.trainingstage.montag"
                                        />
                                        <label for="slide-14_1">Montag</label>
                                    </div>
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_2"
                                            type="checkbox"
                                            v-model="formData.trainingstage.dienstag"
                                        />
                                        <label for="slide-14_2">Dienstag</label>
                                    </div>
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_3"
                                            type="checkbox"
                                            v-model="formData.trainingstage.mittwoch"
                                        />
                                        <label for="slide-14_3">Mittwoch</label>
                                    </div>
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_4"
                                            type="checkbox"
                                            v-model="formData.trainingstage.donnerstag"
                                        />
                                        <label for="slide-14_4">Donnerstag</label>
                                    </div>
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_5"
                                            type="checkbox"
                                            v-model="formData.trainingstage.freitag"
                                        />
                                        <label for="slide-14_5">Freitag</label>
                                    </div>
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_6"
                                            type="checkbox"
                                            v-model="formData.trainingstage.samstag"
                                        />
                                        <label for="slide-14_6">Samstag</label>
                                    </div>
                                    <div class="input-field__checkbox">
                                        <input
                                            id="slide-14_7"
                                            type="checkbox"
                                            v-model="formData.trainingstage.sonntag"
                                        />
                                        <label for="slide-14_7">Sonntag</label>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 15" :class="{ review2: formData._id }" id="slide-15">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Atemwegsbeschwerden</span>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-15_2"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_15"
                                            :checked="formData.atemweg == 'Nein'"
                                            @click="formData.atemweg = 'Nein'"
                                        />
                                        <label for="slide-15_2">Nein</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-15_1"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_15"
                                            :checked="formData.atemweg == 'Ja'"
                                            @click="formData.atemweg = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-15_1">Ja</label>
                                            <div v-show="formData.atemweg == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.atemweg_beschwerden"
                                                    id="slide-15_11"
                                                    type="checkbox"
                                                    v-model="formData.atemweg_beschwerden.asthma"
                                                />
                                                <label for="slide-15_11">Asthma</label>
                                            </div>
                                            <div v-show="formData.atemweg == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.atemweg_beschwerden"
                                                    id="slide-15_12"
                                                    type="checkbox"
                                                    v-model="formData.atemweg_beschwerden.bronchitis"
                                                />
                                                <label for="slide-15_12">Bronchitis</label>
                                            </div>
                                            <div v-show="formData.atemweg == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.atemweg_beschwerden"
                                                    id="slide-15_13"
                                                    type="checkbox"
                                                    v-model="formData.atemweg_beschwerden.copd"
                                                />
                                                <label for="slide-15_13">COPD</label>
                                            </div>
                                            <div v-show="formData.atemweg == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.atemweg_beschwerden"
                                                    id="slide-15_14"
                                                    type="checkbox"
                                                    v-model="formData.atemweg_beschwerden.lungenkrebs"
                                                />
                                                <label for="slide-15_14">Lungenkrebs</label>
                                            </div>
                                            <div v-show="formData.atemweg == 'Ja'" class="input-field__text">
                                                <input
                                                    v-if="formData.atemweg_beschwerden"
                                                    id="slide-15_15"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.atemweg_notiz"
                                                />
                                                <label for="slide-15_15">Anderes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 16" :class="{ review2: formData._id }" id="slide-16">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Herz-Kreislauf-Beschwerden</span>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-17_2"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_17"
                                            :checked="formData.herz == 'Nein'"
                                            @click="formData.herz = 'Nein'"
                                        />
                                        <label for="slide-17_2">Nein</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-17_1"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_17"
                                            :checked="formData.herz == 'Ja'"
                                            @click="formData.herz = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-17_1">Ja</label>

                                            <div v-show="formData.herz == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.herz_beschwerden"
                                                    id="slide-17_12"
                                                    type="checkbox"
                                                    v-model="formData.herz_beschwerden.blutdruck_hoch"
                                                />
                                                <label for="slide-17_12">Hoher Bluthochdruck</label>
                                            </div>

                                            <div v-show="formData.herz == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.herz_beschwerden"
                                                    id="slide-17_13"
                                                    type="checkbox"
                                                    v-model="formData.herz_beschwerden.blutdruck_tief"
                                                />
                                                <label for="slide-17_13">Niedriger Blutdruck</label>
                                            </div>

                                            <div v-show="formData.herz == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.herz_beschwerden"
                                                    id="slide-17_14"
                                                    type="checkbox"
                                                    v-model="formData.herz_beschwerden.infarkt"
                                                />
                                                <label for="slide-17_14">Infarkt</label>
                                            </div>

                                            <div v-show="formData.herz == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.herz_beschwerden"
                                                    id="slide-17_15"
                                                    type="checkbox"
                                                    v-model="formData.herz_beschwerden.herzschwaeche"
                                                />
                                                <label for="slide-17_15">Herzschwäche</label>
                                            </div>

                                            <div v-show="formData.herz == 'Ja'" class="input-field__checkbox">
                                                <input
                                                    v-if="formData.herz_beschwerden"
                                                    id="slide-17_16"
                                                    type="checkbox"
                                                    v-model="formData.herz_beschwerden.herzrhythmusstoerungen"
                                                />
                                                <label for="slide-17_16">Herzrhythmusstörungen</label>
                                            </div>

                                            <div v-show="formData.herz == 'Ja'" class="input-field__text">
                                                <input
                                                    id="slide-17_11"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.herz_notiz"
                                                />
                                                <label for="slide-17_11">Anderes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 17" :class="{ review2: formData._id }" id="slide-17">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Nimmst du Medikamente?</span>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-18_2"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_18"
                                            :checked="formData.medikamente == 'Nein'"
                                            @click="formData.medikamente = 'Nein'"
                                        />
                                        <label for="slide-18_2">Nein</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-18_1"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_18"
                                            :checked="formData.medikamente == 'Ja'"
                                            @click="formData.medikamente = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-18_1">Ja</label>

                                            <div v-show="formData.medikamente == 'Ja'" class="input-field__text">
                                                <input
                                                    id="slide-18_11"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.medikamente_notiz"
                                                />
                                                <label for="slide-18_11">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 18" :class="{ review2: formData._id }" id="slide-18">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Beschwerden am Bewegungsapparat</span>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-19_2"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_19"
                                            :checked="formData.bewegungsapparat == 'Nein'"
                                            @click="formData.bewegungsapparat = 'Nein'"
                                        />
                                        <label for="slide-19_2">Nein</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-19_1"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_19"
                                            :checked="formData.bewegungsapparat == 'Ja'"
                                            @click="formData.bewegungsapparat = 'Ja'"
                                        />
                                        <div class="notiz_wrapper scroll_fix">
                                            <label for="slide-19_1">Ja</label>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_12"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.impingementsyndrom"
                                                />
                                                <label for="slide-19_12">Impingement-Syndrom</label>
                                            </div>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_13"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.schulterluxation"
                                                />
                                                <label for="slide-19_13">Schulterluxation</label>
                                            </div>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_14"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.arthrose"
                                                />
                                                <label for="slide-19_14">Arthrose</label>
                                            </div>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_15"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.oskoporose"
                                                />
                                                <label for="slide-19_15">Osteoporose</label>
                                            </div>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_16"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.bandscheibenprolaps"
                                                />
                                                <label for="slide-19_16">Bandscheibenprolaps</label>
                                            </div>
                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_17"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="
                                                        formData.bewegungsapparat_beschwerden.bandscheibenprotusion
                                                    "
                                                />
                                                <label for="slide-19_17">Bandscheibenprotusion</label>
                                            </div>
                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    id="slide-19_18"
                                                    type="checkbox"
                                                    v-model="formData.bewegungsapparat_beschwerden.tennisellenbogen"
                                                />
                                                <label for="slide-19_18">Tennisellenbogen</label>
                                            </div>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_18"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.golfellenbogen"
                                                />
                                                <label for="slide-19_19">Golferellenbogen</label>
                                            </div>

                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_20"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.muskelverspannung"
                                                />
                                                <label for="slide-19_20">Muskelverspannung</label>
                                            </div>
                                            <div
                                                v-show="formData.bewegungsapparat == 'Ja'"
                                                class="input-field__checkbox"
                                            >
                                                <input
                                                    id="slide-19_21"
                                                    type="checkbox"
                                                    v-if="formData.bewegungsapparat_beschwerden"
                                                    v-model="formData.bewegungsapparat_beschwerden.ruptur"
                                                />
                                                <label for="slide-19_21">Ruptur</label>
                                            </div>
                                            <div v-show="formData.bewegungsapparat == 'Ja'" class="input-field__text">
                                                <input
                                                    id="slide-19_22"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.bewegungsapparat_notiz"
                                                />
                                                <label for="slide-19_22">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 19" :class="{ review2: formData._id }" id="slide-19">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Einschränkungen durch den Arzt</span>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-16_2"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_16"
                                            :checked="formData.einschraenkungen == 'Nein'"
                                            @click="formData.einschraenkungen = 'Nein'"
                                        />
                                        <label for="slide-16_2">Nein</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-16_1"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_16"
                                            :checked="formData.einschraenkungen == 'Ja'"
                                            @click="formData.einschraenkungen = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-16_1">Ja</label>

                                            <div v-show="formData.einschraenkungen == 'Ja'" class="input-field__text">
                                                <input
                                                    id="slide-16_11"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.einschraenkungen_notiz"
                                                />
                                                <label for="slide-16_11">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="filled || active_slide == 20" :class="{ review2: formData._id }" id="slide-20">
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Weitere gesundheitliche Einschränkungen / OP?</span>

                                    <div class="input-field__radio">
                                        <input
                                            id="slide-20_2"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_20"
                                            :checked="formData.weiteres == 'Nein'"
                                            @click="formData.weiteres = 'Nein'"
                                        />
                                        <label for="slide-20_2">Nein</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-20_1"
                                            class="input__radio"
                                            type="radio"
                                            name="weiteres_20"
                                            :checked="formData.weiteres == 'Ja'"
                                            @click="formData.weiteres = 'Ja'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-20_1">Ja</label>

                                            <div v-show="formData.weiteres == 'Ja'" class="input-field__text">
                                                <input
                                                    id="slide-20_11"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.weiteres_notiz"
                                                />
                                                <label for="slide-20_11">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="filled || active_slide == 21" :class="{ review2: formData._id }" id="slide-21">
                                <div class="ueberschrift">Haftungsausschlusserklärung</div>
                                Ich bin mir bewusst, dass mit körperlichem Training ein erhöhtes Verletzungs- und
                                Beschwerderisiko verbunden ist. Mein Training erfolgt in voller Kenntnis dieser Tatsache
                                und auf eigene Verantwortung. Deshalb schliesse ich gegenüber dem Center alle
                                Haftungsansprüche die aus allfälligen gesundheitlich-medizinischen Problemen als Folge
                                des Trainings entstehen, ausdrücklich aus. Weiterhin bestätige ich die Daten auf diesem
                                Gesundheitsfragebogen und versichere keine gesundheitlichen Probleme verschwiegen zu
                                haben.
                            </div>
                            <div :class="{ hidden_slide: active_slide !== 22 }" id="slide-22">
                                <div v-show="!formData.signaturePad_kunde.length > 0" class="ueberschrift">
                                    Unterschrift Kunde
                                    <br />
                                    <br />
                                    Ich akzeptiere die Haftungsausschlusserklärung
                                </div>
                                <div v-show="!formData.signaturePad_kunde.length > 0" class="container">
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            <VueSignaturePad
                                                id="signature_kunde"
                                                width="100%"
                                                height="200px"
                                                ref="signaturePad_kunde"
                                                :options="signatureOptions"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 mt-2">
                                            <button class="btn btn-outline-secondary" @click="undo(1)">
                                                Zurücksetzen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ueberschrift" v-show="formData.signaturePad_kunde.length > 0">
                                    Kunden Unterschrift kann nicht bearbeitet werden
                                </div>
                                <img
                                    :src="formData.signaturePad_kunde"
                                    v-show="formData.signaturePad_kunde.length > 0"
                                    class="signature_done"
                                />
                            </div>
                            <div :class="{ hidden_slide: active_slide !== 23 }" id="slide-23">
                                <div v-show="!formData.signaturePad_trainer.length > 0" class="ueberschrift">
                                    Unterschrift Trainer
                                </div>
                                <div v-show="!formData.signaturePad_trainer.length > 0" class="container">
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            <VueSignaturePad
                                                id="signature_trainer"
                                                width="100%"
                                                height="200px"
                                                ref="signaturePad_trainer"
                                                :options="signatureOptions"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 mt-2">
                                            <button class="btn btn-outline-secondary" @click="undo(0)">
                                                Zurücksetzen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ueberschrift" v-show="formData.signaturePad_trainer.length > 0">
                                    Trainer Unterschrift kann nicht bearbeitet werden
                                </div>
                                <img
                                    :src="formData.signaturePad_trainer"
                                    v-show="formData.signaturePad_trainer.length > 0"
                                    class="signature_done"
                                />
                            </div>
                            <div
                                v-show="!kundensicht && active_slide == 24"
                                :class="{ review2: formData._id }"
                                id="slide-24"
                            >
                                <div class="radio_wrapper">
                                    <span class="ueberschrift">Abo?</span>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-24_1"
                                            class="input__radio"
                                            type="radio"
                                            name="abo"
                                            :checked="formData.abo == 'Ja'"
                                            @click="formData.abo = 'Ja'"
                                        />
                                        <label for="slide-24_1">Ja</label>
                                    </div>
                                    <div class="input-field__radio">
                                        <input
                                            id="slide-24_12"
                                            class="input__radio"
                                            type="radio"
                                            name="abo"
                                            :checked="formData.abo == 'Offen'"
                                            @click="formData.abo = 'Offen'"
                                        />
                                        <label for="slide-24_12">Offen</label>
                                    </div>
                                    <div class="input-field__radio notiz">
                                        <input
                                            id="slide-24_2"
                                            class="input__radio"
                                            type="radio"
                                            name="abo"
                                            :checked="formData == 'Nein'"
                                            @click="formData.abo = 'Nein'"
                                        />
                                        <div class="notiz_wrapper">
                                            <label for="slide-24_2">Nein</label>

                                            <div class="input-field__text">
                                                <input
                                                    id="slide-24_21"
                                                    class="input__text"
                                                    type="text"
                                                    required
                                                    v-model="formData.abo_notiz"
                                                />
                                                <label for="slide-24_21">Notiz</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="kundensicht && !formData._id" class="page_counter">
                        Seite {{ active_slide - 2 }} von {{ slides - 2 }}
                    </div>
                    <div v-show="!kundensicht && !formData._id" class="page_counter">
                        Seite {{ active_slide }} von {{ slides }}
                    </div>
                    <div v-show="formData._id" class="">Nach links swipen, um zur nächsten Frage zu gelangen</div>
                    <div class="action-wrapper">
                        <div
                            @click="sliderPrevious()"
                            v-show="
                                !formData._id &&
                                active_slide !== 0 &&
                                !submitting &&
                                !newAcc.generate &&
                                !(kundensicht && active_slide == 2)
                            "
                            class="previous"
                        >
                            Zurück
                        </div>
                        <div @click="newAcc.generate = false" v-show="newAcc.generate" class="previous">Zurück</div>
                        <div v-show="submitting" class="previous">Zurück</div>
                        <div
                            @click="sliderNext()"
                            v-show="
                                (!formData._id && filled) ||
                                (!formData._id && active_slide != slides && !submitting && !newAcc.generate)
                            "
                            class="next"
                        >
                            Weiter
                        </div>
                        <div class="next" @click="generateAccount()" v-show="newAcc.generate">Account erstellen</div>
                        <div
                            v-if="!kundensicht"
                            @click="sliderNext()"
                            v-show="formData._id || (active_slide == slides && !submitting)"
                            class="submit"
                        >
                            Abschliessen
                        </div>
                        <div
                            v-else
                            @click="hide()"
                            v-show="formData._id || (active_slide == slides && !submitting)"
                            class="submit"
                        >
                            Schliessen
                        </div>
                        <div v-show="submitting" class="submit submitting">
                            <img src="\img\icons\loading.gif" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import api from '../api'
import VueSignature from 'vue-signature-pad'
import Label from './Label.vue'
import userSearch from './userSearch.vue'

export default {
    components: { VueSignature, userSearch },
    data() {
        return {
            showModal: false,
            userSearchKey: 0,
            filled: false,
            error: '',
            active_slide: 0,
            slides: 24,
            checked: false,
            search: '',
            searchtrainer: '',
            trainers: {},
            users: {},
            submitting: false,
            kundensicht: false,

            newAcc: {
                geschlecht: 'a',
                slimMeUp: false,
                slimMeUp_date: '',
                tendays: false,
                tendays_date: '',
                generate: false,
                telefon: '',
                vorname: '',
                nachname: '',
                email: '',
                password: '',
                adresse_1: '',
                plz: '',
                stadt: '',
                geb: {
                    tag: '',
                    monat: '',
                    jahr: '',
                },
            },
            formData: {
                trainer_id: '',
                user: {
                    _id: '',
                    email: '',
                    name: '',
                },
                abo: 'Offen',
                abo_notiz: '',
                trainingsart: '',
                trainingsart_notiz: '',
                fitnesswunsch_dauer: '',
                affiliate: '',
                affiliate_notiz: '',
                roody: '',
                allgemeineInfos: {
                    krankenkasse: '',
                    beruf: '',
                    kinder: '',
                },
                trainingsziele: {
                    koerperfettreduktion: false,
                    koerperfettreduktion_notiz: '',
                    muskelaufbau: false,
                    muskelaufbau_notiz: '',
                    rueckenkraeftigung: false,
                    rueckenkraeftigung_notiz: '',
                    ausdauer: false,
                    ausdauer_notiz: '',
                    stressabbau: false,
                    stressabbau_notiz: '',
                    beweglichkeit: false,
                    beweglichkeit_notiz: '',
                    koordination: false,
                    koordination_notiz: '',
                    gesundheit: false,
                    gesundheit_notiz: '',
                },
                trainingsziele_2: {
                    kurzfristig: '',
                    mittelfristig: '',
                    langfristig: '',
                },
                fitnesserfahrung: '',
                fitnesserfahrung_notiz: '',
                rate: 5,
                rate_notiz: '',
                hobbys: '',
                hobbys_notiz: '',
                intervall: '',
                intervall_notiz: '',
                trainingsdauer: '',
                trainingstage: {
                    montag: false,
                    dienstag: false,
                    mittwoch: false,
                    donnerstag: false,
                    freitag: false,
                    samstag: false,

                    sonntag: false,
                },
                atemweg: '',
                atemweg_beschwerden: {
                    copd: false,
                    asthma: false,
                    lungenkrebs: false,
                    bronchitis: false,
                },
                atemweg_notiz: '',
                einschraenkungen: '',
                einschraenkungen_notiz: '',
                herz: '',
                herz_beschwerden: {
                    herzrhythmusstoerungen: false,
                    herzschwaeche: false,
                    infarkt: false,
                    blutdruck_tief: false,
                    blutdruck_hoch: false,
                },

                herz_notiz: '',
                medikamente: '',
                medikamente_notiz: '',
                bewegungsapparat: '',
                bewegungsapparat_beschwerden: {
                    impingementsyndrom: false,
                    schulterluxation: false,
                    arthrose: false,
                    oskoporose: false,
                    bandscheibenprolaps: false,
                    bandscheibenprotusion: false,
                    tennisellenbogen: false,
                    golfellenbogen: false,
                    muskelverspannung: false,
                    ruptur: false,
                },
                bewegungsapparat_notiz: '',
                weiteres: '',
                weiteres_notiz: '',
                signaturePad_kunde: '',
                signaturePad_trainer: '',
                date: null,
            },
            formData_backup: {},
        }
    },
    created() {
        this.formData_backup = JSON.parse(JSON.stringify(this.formData))
    },
    computed: {
        signatureOptions() {
            if (this.$vuetify) {
                return { color: this.$vuetify.theme.themes.light.primary }
            }
        },
    },
    methods: {
        expressAnamnesebogen() {
            if (this.formData.trainingsart == 'Probetraining Groupfitness') {
                if (this.formData.trainingsart_notiz.length > 0) {
                    this.active_slide = 15
                    window.location.hash = 'slide-15'
                } else {
                    alert('Bitte fülle das Notizfeld aus (Welcher GF Kurs wird besucht?)')
                }
            } else {
                alert('Diese Funktion ist ausschliesslich für GF-Probetrainings')
            }
        },
        generateAccount() {
            api.generateAccount({
                data: this.newAcc,
                trainer_id: this.formData.trainer_id,
            }).then((data) => {
                if (data.status == 3) {
                    alert('Diese E-Mail Adresse wird bereits verwendet')
                } else if (data.status == 2) {
                    alert('Bitte kontrolliere die Felder')
                } else if (data.status == 4) {
                    alert('Es gab einen Fehler')
                } else if (data.status == 1) {
                    alert('Account erstellt. Du kannst ihn jetzt über die Benutzersuche auswählen')
                    this.newAcc = {
                        geschlecht: 'a',
                        slimMeUp: false,
                        slimMeUp_date: '',
                        tendays: false,
                        tendays_date: '',
                        generate: false,
                        telefon: '',
                        vorname: '',
                        nachname: '',
                        email: '',
                        password: '',
                        adresse_1: '',
                        plz: '',
                        stadt: '',
                        geb: {
                            tag: '',
                            monat: '',
                            jahr: '',
                        },
                    }
                    let date = new Date()

                    this.newAcc.tendays_date = date.toLocaleDateString('en-CA')
                    this.newAcc.slimMeUp_date = date.toLocaleDateString('en-CA')
                    this.newAcc.generate = false
                    this.$refs.userSearch.fetchUsers()
                }
            })
        },
        pickUser(user) {
            this.formData.user.email = user.email
            this.formData.user._id = user._id
            this.formData.user.name = user.vorname + ' ' + user.nachname
        },
        pickTrainer(trainer) {
            this.formData.trainer_id = trainer
        },
        undo(kunde) {
            if (kunde) {
                this.$refs.signaturePad_kunde.clearSignature()
            } else {
                this.$refs.signaturePad_trainer.clearSignature()
            }
        },

        sliderNext() {
            let check = false

            // Validation
            if (this.formData._id) {
                check = true
                this.active_slide = 24
            }
            switch (this.active_slide) {
                case 0:
                    if (this.formData.trainer_id.length > 0) {
                        check = true
                        this.userSearchKey += 1
                    } else {
                        alert('Bitte wählen Sie einen Account aus')
                    }
                    break

                case 1:
                    if (
                        this.formData.user.email.length > 0 &&
                        this.formData.user.email.includes('@') &&
                        this.formData.user._id.length > 0 &&
                        this.formData.user.name.length > 0
                    ) {
                        check = true
                    } else {
                        console.log(this.formData)

                        alert('Bitte wählen Sie einen Account aus')
                    }
                    break
                case 2:
                    if (this.formData.trainingsart.length > 0) {
                        if (this.formData.trainingsart !== 'Probetraining Groupfitness') {
                            check = true
                        } else {
                            if (this.formData.trainingsart_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle die Notiz aus')
                            }
                        }
                    } else {
                        alert('Bitte wähle eine Trainingsart aus')
                    }
                    break
                case 3:
                    if (this.formData.fitnesswunsch_dauer.length > 0) {
                        check = true
                    } else {
                        alert('Bitte gebe eine Antwort ein')
                    }
                    break
                case 4:
                    if (this.formData.affiliate.length > 0) {
                        if (this.formData.affiliate == 'Empfehlung durch:') {
                            if (this.formData.affiliate_notiz.length > 0) {
                                check = true
                            } else {
                                alert("Bitte fülle das Notizfeld 'Empfehlung' aus")
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 5:
                    if (this.formData.roody.length > 0) {
                        check = true
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 6:
                    if (
                        this.formData.allgemeineInfos.krankenkasse.length > 0 &&
                        this.formData.allgemeineInfos.beruf.length > 0 &&
                        this.formData.allgemeineInfos.kinder.length > 0
                    ) {
                        check = true
                    } else {
                        alert('Bitte fülle alle Felder aus')
                    }
                    break
                case 9:
                    if (
                        this.formData.trainingsziele.koerperfettreduktion ||
                        this.formData.trainingsziele.muskelaufbau ||
                        this.formData.trainingsziele.rueckenkraeftigung ||
                        this.formData.trainingsziele.ausdauer ||
                        this.formData.trainingsziele.stressabbau ||
                        this.formData.trainingsziele.beweglichkeit ||
                        this.formData.trainingsziele.koordination ||
                        this.formData.trainingsziele.gesundheit
                    ) {
                        check = true
                    } else {
                        alert('Wähle bitte mindestens eine Antwort aus')
                    }
                    break
                case 10:
                    if (
                        this.formData.trainingsziele_2.kurzfristig.length > 0 &&
                        this.formData.trainingsziele_2.mittelfristig.length > 0 &&
                        this.formData.trainingsziele_2.langfristig.length > 0
                    ) {
                        check = true
                    } else {
                        alert('Bitte fülle die Antworten aus')
                    }
                    break
                case 7:
                    // new
                    if (this.formData.fitnesserfahrung.length > 0) {
                        if (this.formData.fitnesserfahrung == 'Ja') {
                            if (this.formData.fitnesserfahrung_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle die Notiz aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 11:
                    check = true
                    break
                case 8:
                    // new
                    if (this.formData.hobbys.length > 0) {
                        if (this.formData.hobbys == 'Ja') {
                            if (this.formData.hobbys_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte gebe ein Hobby ein')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 12:
                    if (this.formData.intervall.length > 0) {
                        if (this.formData.intervall == 'häufiger') {
                            if (this.formData.intervall_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte gebe eine Häufigkeit ein')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 13:
                    if (this.formData.trainingsdauer.length > 0) {
                        check = true
                    } else {
                        alert('Bitte wähle eine Trainingsdauer')
                    }
                    break
                case 14:
                    if (
                        this.formData.trainingstage.montag ||
                        this.formData.trainingstage.dienstag ||
                        this.formData.trainingstage.mittwoch ||
                        this.formData.trainingstage.donnerstag ||
                        this.formData.trainingstage.freitag ||
                        this.formData.trainingstage.sonntag ||
                        this.formData.trainingstage.samstag
                    ) {
                        check = true
                    } else {
                        alert('Bitte wähle einen oder mehrere Trainingstage aus')
                    }
                    break
                case 15:
                    if (this.formData.atemweg.length > 0) {
                        if (this.formData.atemweg.length == 'Ja') {
                            if (this.formData.atemweg_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle das Textfeld aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 19:
                    if (this.formData.einschraenkungen.length > 0) {
                        if (this.formData.einschraenkungen == 'Ja') {
                            if (this.formData.einschraenkungen_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle das Textfeld aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 16:
                    if (this.formData.herz.length > 0) {
                        if (this.formData.herz.length == 'Ja') {
                            if (this.formData.herz_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle das Textfeld aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 17:
                    if (this.formData.medikamente.length > 0) {
                        if (this.formData.medikamente.length == 'Ja') {
                            if (this.formData.medikamente_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle das Textfeld aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 18:
                    if (this.formData.bewegungsapparat.length > 0) {
                        if (this.formData.bewegungsapparat.length == 'Ja') {
                            if (this.formData.bewegungsapparat_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle das Textfeld aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 20:
                    if (this.formData.weiteres.length > 0) {
                        if (this.formData.weiteres == 'Ja') {
                            if (this.formData.weiteres_notiz.length > 0) {
                                check = true
                            } else {
                                alert('Bitte fülle das Textfeld aus')
                            }
                        } else {
                            check = true
                        }
                    } else {
                        alert('Bitte wähle eine Antwort aus')
                    }
                    break
                case 21:
                    check = true
                    break
                case 22:
                    if (!this.formData.signaturePad_kunde.length > 0) {
                        let sig_kunde = this.$refs.signaturePad_kunde.saveSignature()
                        console.log(sig_kunde)

                        if (sig_kunde.isEmpty) {
                            alert('Bitte unterschreiben')
                        } else {
                            this.formData.signaturePad_kunde = sig_kunde.data
                            check = true
                        }
                    } else {
                        check = true
                    }

                    break
                case 23:
                    if (!this.formData.signaturePad_trainer.length > 0) {
                        let sig_trainer = this.$refs.signaturePad_trainer.saveSignature()

                        if (sig_trainer.isEmpty) {
                            alert('Bitte unterschreiben')
                        } else {
                            this.formData.signaturePad_trainer = sig_trainer.data
                        }
                    } else {
                        check = true
                    }
                    break
                case 24:
                    if (this.formData.abo == 'Nein' || this.formData.abo == 'Offen') {
                        if (this.formData.abo_notiz.length > 0) {
                            this.formData.date = Math.round(new Date() / 1000)
                            this.submitAnamnesebogen()
                            check = true
                        } else {
                            alert('Bitte fülle das Textfeld aus')
                        }
                    } else {
                        this.formData.date = Math.round(new Date() / 1000)
                        this.submitAnamnesebogen()
                        check = true
                    }

                    break
            }

            if (this.formData._id) {
                this.formData.expressAnamnesebogen = false
            }

            if (check) {
                this.$refs.signaturePad_kunde.resizeCanvas()
                this.$refs.signaturePad_trainer.resizeCanvas()
                if (this.active_slide != this.slides) {
                    localStorage.setItem('anamnesebogen', JSON.stringify(this.formData))
                    this.active_slide++
                    if (this.formData._id) {
                        window.location.hash = 'slide-' + this.active_slide
                    }
                    // remove this
                    //window.location.hash = "slide-" + this.active_slide;
                }
            }
        },
        sliderPrevious() {
            if (this.active_slide !== 0) {
                this.active_slide--

                // remove this
                //window.location.hash = "slide-" + this.active_slide;
            }
        },
        submitAnamnesebogen() {
            this.submitting = true

            api.submitAnamnesebogen(this.formData).then((apiData) => {
                switch (apiData.status) {
                    case 0:
                        this.submitting = false
                        alert('Fehler... Bitte überprüfen Sie die Daten')
                        break
                    case 1:
                        localStorage.removeItem('anamnesebogen')
                        this.undo(0)
                        this.undo(1)
                        this.submitting = false
                        this.hide()
                        if (!this.formData._id && !this.kundensicht) {
                            this.$router.push('/workouts/create')
                        }
                        break
                    default:
                        this.submitting = false
                        alert('Fehler... Bitte überprüfen Sie die Daten')
                        break
                }
            })
        },
        show(fd = false, kundensicht = false) {
            console.log(fd)
            this.userSearchKey += 1
            this.kundensicht = kundensicht

            if (fd) {
                // this.filled = true;
                this.formData = fd
            } else {
                if (localStorage.getItem('anamnesebogen')) {
                    if (confirm('Es wurden ungespeicherte Änderungen gefunden, möchtest Du diese jetzt laden?')) {
                        this.formData = JSON.parse(localStorage.getItem('anamnesebogen'))
                    } else {
                        this.formData = JSON.parse(JSON.stringify(this.formData_backup))
                        localStorage.removeItem('anamnesebogen')
                        this.undo(0)
                        this.undo(1)
                    }
                } else {
                    this.formData = JSON.parse(JSON.stringify(this.formData_backup))
                }
            }
            this.showModal = true

            setTimeout(() => {
                $(this.$refs['modal']).modal('show')
            }, 100)

            if (kundensicht) {
                this.active_slide = 2
                this.slides = 22
                window.location.hash = 'slide-2'
            } else {
                window.location.hash = 'slide-0'
                let date = new Date()

                this.newAcc.tendays_date = date.toLocaleDateString('en-CA')
                this.newAcc.slimMeUp_date = date.toLocaleDateString('en-CA')
                this.slides = 24
                this.active_slide = 0
            }

            const range = document.querySelector('.container_rate input')
            const div = document.querySelector('.moji')
            const mojis = ['0', '⭐', '⭐⭐', '⭐⭐⭐', '⭐⭐⭐⭐', '⭐⭐⭐⭐⭐']

            range.addEventListener('input', (e) => {
                let rangeValue = e.target.value
                div.textContent = mojis[rangeValue]
            })
        },
        hide() {
            this.formData = JSON.parse(JSON.stringify(this.formData_backup))
            $(this.$refs['modal']).modal('hide')
            this.showModal = false
            window.location.hash = ''
        },
    },
}
</script>

<style lang="scss" scoped>
.modal {
    z-index: 9999999999;
    color: black !important;
}
.geschlecht {
    width: 100%;
}
.tendays-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    line-height: 30px;
    input {
        width: 30px;
        height: 30px;
    }
}
.scroll_fix {
    overflow-y: scroll;
    max-height: 270px;
    overflow-x: hidden;
    padding-right: 20px;
}
.express-anamnesebogen {
    padding: 10px 15px;
    background-color: var(--v-primary);
    border-radius: 5px;
    color: #fff;
}
.notiz_wrapper_plz {
    display: flex;
    flex-direction: row;

    .input-field__text:not(.plz-wrapper) {
        width: 65%;
        margin-left: auto;
    }
}
.plz-wrapper {
    width: 30%;
    margin-right: 10px;
}
.geb-label {
    margin-top: 10px;
    text-align: left;
}
.geb-wrapper {
    display: flex;
    justify-content: space-between;

    .notiz_wrapper {
        width: 29%;
    }
}
.neuer-account {
    background-color: var(--v-primary);
    padding: 10px 15px;
    width: 250px;
    margin: 10px auto 20px auto;
    color: white;
    border-radius: 5px;
}

.neuer-acc-form {
    display: flex;
    flex-direction: column;
    input {
        margin-bottom: 5px;
        margin-top: 5px;
    }
}
.modal-title {
    font-size: 18px;
    min-width: 180px;
}
.modal-body {
    overflow: auto;
}

.modal-header .close {
    text-align: right;
}

.signature_done {
    height: 200px;
    width: 100%;
    position: static;
    margin: 70px auto 0px auto;

    object-fit: contain;
}

.row {
    display: flex;
    justify-content: space-between;
}

.submit {
    margin-right: 20px;
    width: 170px !important;

    &.submitting {
        img {
            position: static;
            height: 20px;
            width: 40px;
            filter: brightness(5);
        }
    }
}

.user {
    border-radius: 5px;
    margin: 10px 20px 0px 20px;
    padding: 15px 0px;
    border: 1px solid grey;
    cursor: pointer;
    &.active {
        background-color: var(--v-primary);
        color: white;
        border: none;
    }
}

#signature_kunde,
#signature_trainer {
    border: double 3px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

// SLIDER

.table_uebungen {
    td {
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        &:first-of-type {
            padding: 10px;
        }
        input {
            height: 100%;
            width: 100%;
            text-align: center;
        }
    }
}

.page_counter {
    text-align: center;
    position: absolute;
    bottom: 5px;
    left: calc(50% - 65px);
    width: 130px;
}
.action-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    div {
        width: 160px;
        text-align: center;

        color: white;
        padding: 10px 15px;
        border-radius: 5px;
        &.next {
            background-color: var(--v-primary);
            margin-left: auto;
        }
        &.submit {
            background-color: var(--v-primary);
            margin-left: auto;
        }
        &.previous {
            background-color: #858585;
            margin-right: auto;
        }
    }
}
#slide-1,
#slide-0 {
    height: 430px;
}
.hidden_slide {
    top: -500% !important;
    position: absolute !important;
    display: block !important;
}
.slider {
    width: 100%;
    height: 80%;
    max-height: 450px;
    text-align: center;
    overflow: hidden;
}

.slides {
    display: flex;
    height: 100%;
    overflow: hidden;

    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}

.slider.review {
    max-height: 100% !important;
    overflow: auto !important;
}

.slides.review {
    overflow: auto !important;
    .review2 {
        display: block !important;
    }
}

.slides::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.slides::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
    background: transparent;
}
.ueberschrift {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}
.slides > div {
    scroll-snap-align: start;
    flex-shrink: 0;

    width: 100%;
    margin-right: 50px;
    border-radius: 10px;

    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;

    flex-direction: column;

    //Slide Style
    height: 100%;
    overflow: auto;

    .checkbox_wrapper {
        margin: 0px auto;
        .input-field__checkbox {
            margin-bottom: 10px;

            input {
                margin-right: 15px;
                width: 25px;
                height: 25px;
            }
            label {
                line-height: 26px;
            }
        }
    }

    .container_rate {
        width: 300px;
        margin: 0 auto;
        .moji {
            font-size: 40px;
            text-align: center;
        }
        input {
            width: 100%;
            margin: 0 10px;
            height: 20px;
        }
        .slider {
            display: flex;
            justify-content: space-between;
        }
        span {
            font-weight: bold;
            color: #5fcf80;
        }
        span:last-child {
            color: tomato;
        }
    }

    .frage_wrapper {
        margin: 0 auto;
    }

    .radio_wrapper {
        margin: 0 auto;
        display: flex;

        flex-direction: column;
    }

    .notiz_wrapper {
        display: flex;
        flex-direction: column;

        input {
            margin-bottom: 20px;
        }

        > label {
            text-align: left;
        }

        .input-field__text {
            margin-top: 5px;
        }
    }

    .input-field__radio {
        display: flex;
        flex-direction: row;
        line-height: 1;
        margin-bottom: 10px;

        input {
            margin-right: 10px;
            height: 30px;
            width: 30px;
            margin-bottom: 10px;
        }
        label {
            line-height: 32px;
        }
    }

    .input-field {
        &__checkbox {
            line-height: 1;
            display: flex;

            .input-field__text {
                margin-top: 0px;
            }

            &.notiz {
                > input {
                    height: 45px;
                }
                > label {
                    width: 200px;
                    text-align: left;
                    line-height: 45px;
                }
            }

            input {
                margin-right: 5px;
            }
            .input-field__text {
                margin-left: 10px;
            }
        }
        &__text {
            position: relative;
            margin-top: 15px;
            input {
                width: 100%;
                padding: 10px 0;
                font-size: 16px;
                color: black;

                border: none;
                border-bottom: 1px solid black;
                outline: none;
                background: transparent;

                &:focus ~ label,
                &:valid ~ label {
                    top: -20px;
                    left: 0;
                    color: var(--v-primary);
                    font-size: 12px;
                }
            }
            label {
                line-height: 1 !important;
                position: absolute;
                top: 0;
                left: 0;
                padding: 10px 0;
                font-size: 16px;
                color: black;
                pointer-events: none;
                transition: 0.5s;
            }
        }
    }
}

.author-info {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 0.75rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
}
.author-info a {
    color: white;
}
img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider > a {
    display: inline-flex;
    width: 1.5rem;
    height: 1.5rem;
    background: white;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0 0.5rem 0;
    position: relative;
}
.slider > a:active {
    top: 1px;
}
.slider > a:focus {
    background: #000;
}
</style>
