<template>
    <v-autocomplete
        class="px-3"
        dark
        ref="autocompleteMenu"
        v-model="model"
        :items="showMyFavorites ? [{}, ...favoriteRecipesComp] : itemsArray"
        :loading="isLoading"
        :search-input.sync="searchString"
        color="white"
        hide-no-data
        clearable
        filled
        rounded
        hide-selected
        @change="sendRecipeToParent"
        item-value="id"
        item-text="optional.title"
        label="CookButler Rezepte"
        placeholder="Tippe um zu suchen"
        prepend-icon="mdi-database-search"
        return-object
    >
        <template v-slot:item="data">
            <v-list-item-avatar v-if="data.item.optional && data.item.media">
                <img :src="data.item.media.search[0]" />
            </v-list-item-avatar>

            <v-list-item-content v-if="data.item.optional && data.item.media">
                <v-list-item-title v-html="data.item.optional.title"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.optional.subtitle"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar @click.stop.prevent="test()" v-if="!data.item.media && !data.item.img">
                <v-icon color="yellow">mdi-star</v-icon>
            </v-list-item-avatar>

            <v-list-item-content @click.stop.prevent="test()" v-if="!data.item.media && !data.item.img">
                <v-list-item-title @click.stop.prevent="test()" class="font-weight-bold">
                    {{ showMyFavorites ? 'Zurück zur Suche' : 'Meine Favoriten' }}
                </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar v-if="!data.item.media && data.item.id">
                <img :src="data.item.img" />
            </v-list-item-avatar>

            <v-list-item-content v-if="!data.item.media">
                <v-list-item-title v-html="data.item.title"></v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>
<script>
import api from '../../../api'

export default {
    props: {
        favoriteRecipes: {},
        offset: {
            default: 0,
        },
        allergens: {},
        diet: {},
        addFilters: {},
        substanceFilter: {},
    },
    data() {
        return {
            model: null,
            results: null,
            isLoading: false,
            searchTimeout: null,
            searchString: '',
            showMyFavorites: false,
        }
    },
    computed: {
        favoriteRecipesComp() {
            return this.favoriteRecipes.map((recipe) => {
                recipe.optional = {
                    title: recipe.title,
                }
                return recipe
            })
        },
        itemsArray() {
            if (this.results) {
                return [
                    {
                        text: 'Meine Favoriten',
                        icon: 'mdi-star',
                    },
                    ...this.results,
                ]
            }
            return [
                {
                    text: 'Meine Favoriten',
                    icon: 'mdi-star',
                },
            ]
        },
    },
    methods: {
        test() {
            console.log('clicked')
            this.showMyFavorites = !this.showMyFavorites
        },
        async sendRecipeToParent() {
            if (this.showMyFavorites) {
                this.$toast.info('Rezept wird geladen...')
                let fetchedRecipe = await api.getReceiptNutritionApi(this.model.id)

                this.$emit('newReceipt', fetchedRecipe.data)
            } else {
                this.$emit('newReceipt', this.model)
            }
        },
        async search() {
            let localSearchstring = JSON.parse(JSON.stringify(this.searchString)) || ''

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
            }

            // Starten Sie ein neues Timeout für 500 Millisekunden
            this.searchTimeout = setTimeout(async () => {
                let filters = JSON.parse(JSON.stringify(this.addFilters))
                filters.substanceFilter = this.substanceFilter
                if (this.allergens) filters.allergen = this.allergens
                if (this.diet) filters.diet = this.diet
                let results = await api.searchNutritionApi({
                    query: localSearchstring,
                    offset: this.offset,
                    filters,
                })
                this.results = results.data.recipes
                this.isLoading = false
            }, 500)
        },
    },
    watch: {
        async searchString(val) {
            await this.search()
        },
    },
}
</script>
