<template>
    <v-dialog fullscreen v-model="visible">
        <v-card style="position: relative; height: 100vh; overflow: scroll">
            <v-card-title style="position: absolute; top: 0; width: 100%"
                class="primary white--text d-flex justify-center">
                {{ selectedStudio.name }}
            </v-card-title>
            <div v-if="studioContent.studio"
                style="max-width: 600px; max-height: calc(100% - 120px); overflow: scroll; margin-top: 70px; margin-bottom: 20px;"
                class="d-flex flex-column mx-auto px-5">
                <v-row class="mt-3">
                    <v-col cols="12" v-if="studioContent && studioContent.headerVideo">
                        <v-responsive aspect-ratio="16/9">
                            <div class="video-container">
                                <video autoplay loop>
                                    <source
                                        :src="`https://app.wellcomefit.ch/api/website/video/${studioContent.headerVideo.replace('.mp4', '-original.mp4')}`"
                                        type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </v-responsive>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center flex-column">
                        <v-file-input v-model="videoFile" label="Video hochladen" accept="video/*"></v-file-input>
                        <v-btn color="primary" @click="uploadVideo" :disabled="processingVideo" class="mx-auto">
                            Video hochladen
                        </v-btn>
                        <div v-if="uploadProgress > 0 && uploadProgress <= 98" class="text-center mt-5">
                            <v-progress-linear :value="uploadProgress" class="mb-1"></v-progress-linear>
                            <p>Das Video wird hochgeladen</p>
                        </div>
                        <div v-if="processingVideo && !(uploadProgress >= 0 && uploadProgress <= 98)"
                            class="text-center mt-5">
                            <v-progress-circular class="mb-1" indeterminate color="primary"
                                size="45"></v-progress-circular>
                            <p>Das Video wird verarbeitet, bitte verlasse die Seite nicht.</p>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea label="Club Infos" filled placeholder="Willkommen im well come FIT..."
                            class="px-5 mt-5" v-model="studioContent.clubSection.text"></v-textarea>
                    </v-col>
                    <!-- Bildergalerie -->
                    <v-col cols="12">

                        <v-carousel v-model="activeSlide"
                            v-if="studioContent.clubSection && studioContent.clubSection.pictures && studioContent.clubSection.pictures.length > 0"
                            height="auto" hide-delimiters class="position-relative">

                            <v-carousel-item v-for="(picture, index) in studioContent.clubSection.pictures"
                                :key="index">
                                <v-img :src="`https://app.wellcomefit.ch/api/website/image/${picture.src}`"
                                    :alt="picture.alt" aspect-ratio="3/2" height="300px" class="rounded-lg"></v-img>
                                <v-icon @click="removePicture(index)" color="red" dark class="remove-btn">
                                    mdi-close
                                </v-icon>
                                <div class="alt-text-container">
                                    <v-text-field label="Alt Text (Bildbeschreibung | SEO)"
                                        v-model="studioContent.clubSection.pictures[index].alt" filled
                                        dense></v-text-field>
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col class="d-flex justify-center flex-column" cols="12">
                        <v-file-input v-model="file" label="Bild hochladen" @change="onFileChange"></v-file-input>
                        <v-text-field label="Alt Text (Bildbeschreibung | SEO)" v-model="altText"></v-text-field>
                        <v-btn class="mx-auto" color="primary" @click="uploadImage">Hochladen</v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions style="position: absolute; bottom: 0; width: 100%; background-color: white"
                class="d-flex justify-center">
                <v-btn class="mr-5" @click="closeDialog" text>Abbrechen</v-btn>
                <v-btn class="ml-5" @click="saveContent" color="primary">Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
    props: {
        selectedStudio: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeSlide: 0,
            visible: true,
            studioContent: {},
            file: null,
            altText: "",
            videoFile: null,
            uploadProgress: 0,
            processingVideo: false,
        };
    },
    watch: {
        selectedStudio: {
            immediate: true,
            handler() {
                this.loadContent();
            },
        },
    },
    methods: {
        closeDialog() {
            this.visible = false;
            this.$emit("close-dialog");
        },
        async loadContent() {
            try {
                const { result } = await api.fetchWebsiteContent(this.selectedStudio.code);
                this.studioContent = result || this.initNewContent();
                this.$forceUpdate()
            } catch (e) {
                this.$toast.error("Fehler beim Laden des Inhalts");
            }
        },
        initNewContent() {
            return {
                studio: this.selectedStudio._id,
                headerVideo: null,
                clubSection: {
                    text: null,
                    pictures: [],
                },
            };
        },
        async saveContent() {
            try {
                await api.updateWebsiteContent(this.studioContent);
                this.$toast.success("Inhalt erfolgreich gespeichert!");
                this.closeDialog();
            } catch (e) {
                this.$toast.error("Fehler beim Speichern des Inhalts.");
            }
        },
        async uploadVideo() {
            if (!this.videoFile) {
                this.$toast.error("Bitte wähle ein Video aus");
                return;
            }
            this.processingVideo = true;

            const formData = new FormData();
            formData.append("video", this.videoFile);

            try {
                const response = await axios.post("/api/website/upload/video", formData, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    },
                });

                if (response.data.file) {
                    this.studioContent.headerVideo = response.data.file;
                    this.$toast.success("Video erfolgreich hochgeladen");
                }
            } catch (error) {
                this.$toast.error("Fehler beim Hochladen des Videos");
                console.error(error);
            } finally {
                this.processingVideo = false;
                this.uploadProgress = 0;
                this.videoFile = null;
            }
        },
        async uploadImage() {
            if (!this.file) {
                this.$toast.error("Bitte wähle ein Bild aus ");
                return;
            }

            const formData = new FormData();
            formData.append("image", this.file);
            formData.append("alt", this.altText);

            try {
                const response = await axios.post("/api/website/upload/image", formData, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data.file) {
                    if (!this.studioContent.clubSection.pictures) {
                        this.studioContent.clubSection.pictures = [];
                    }
                    this.studioContent.clubSection.pictures.push({
                        src: response.data.file,
                        alt: response.data.alt,
                    });
                    this.activeSlide = this.studioContent.clubSection.pictures.length - 1;
                    this.file = null
                    this.altText = ""
                    this.$toast.success("Bild hochgeladen")
                }
            } catch (error) {
                this.$toast.error("Fehler beim Hochladen des Bildes");
                console.error(error);
            }
        },
        removePicture(index) {
            this.studioContent.clubSection.pictures.splice(index, 1);
            if (this.activeSlide >= this.studioContent.clubSection.pictures.length) {
                this.activeSlide = this.studioContent.clubSection.pictures.length - 1;
            }
        },
        onFileChange(file) {
            this.file = file;
        },
    },
};
</script>

<style scoped>
.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 ratio */
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.position-relative {
    position: relative;
}

.remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}

.alt-text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}
</style>
