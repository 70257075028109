<template>
    <div class="courseSettings">
        <v-btn @click="showCoursePictureList = !showCoursePictureList">
            <span v-if="showCoursePictureList">Zurück</span>
            <span v-else>Bilder kontrollieren</span>
        </v-btn>
        <v-row v-if="!showCoursePictureList" no-gutters class="courseSettings__course my-5 px-5">
            <v-col class="d-flex justify-center" cols="12">
                <v-dialog transition="dialog-bottom-transition" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="courseSettings_action-add-btn" color="primary" v-bind="attrs" v-on="on">
                            Hinzufügen
                        </v-btn>
                    </template>
                    <template v-slot:default="dialogAdd">
                        <v-card dark>
                            <v-toolbar color="primary" dark>
                                <div class="d-block">
                                    <strong>Neuen Kurs hinzufügen</strong>
                                </div>
                            </v-toolbar>
                            <div class="px-5 courseSettings_action-dialog d-flex flex-column">
                                <v-text-field filled class="mt-5" label="Titel" v-model="addCourse.name"></v-text-field>
                                <v-textarea filled label="Beschreibung" v-model="addCourse.beschreibung"></v-textarea>
                                <slim-cropper style="height: 200px" :options="slimOptionsExercise">
                                    <img v-if="addCourse.bild" :src="'/img/kurse/' + addCourse.bild" alt="" />
                                    <input type="file" accept="image/*" />
                                </slim-cropper>
                            </div>
                            <v-checkbox filled label="slimMe Kurs" v-model="addCourse.slimMe"></v-checkbox>
                            <v-card-actions class="justify-end">
                                <v-btn text @click="dialogAdd.value = false">Abbrechen</v-btn>
                                <v-btn color="primary" text @click="createCourse(dialogAdd)">Speichern</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
            <v-text-field dark filled v-model="courseListFilter" label="Suche" placeholder=""></v-text-field>

            <v-col
                cols="12"
                v-for="(course, index) of courseList"
                class="courseSettings__course-list flex-row justify-space-between py-2"
                :class="{
                    'd-flex':
                        !courseListFilter.length ||
                        course.name.toLowerCase().indexOf(courseListFilter.toLowerCase()) !== -1,
                    'd-none': !(
                        !courseListFilter.length ||
                        course.name.toLowerCase().indexOf(courseListFilter.toLowerCase()) !== -1
                    ),
                }"
                :key="course._id"
            >
                <div class="courseSettings__course-list-title white--text d-flex">
                    <span class="my-auto">{{ shorten(course.name) }}</span>
                </div>
                <div class="courseSettings__course-list-action">
                    <v-dialog
                        @input="setEditIndex($event, index)"
                        transition="dialog-bottom-transition"
                        max-width="600"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="red" icon @click="deleteEntry(course, 'course', 0)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                class="courseSettings__course-list-action-btn"
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="primary" dark>
                                    <div class="d-block">
                                        <strong>{{ shorten(course.name) }}</strong>
                                    </div>
                                </v-toolbar>
                                <div class="px-5 courseSettings__course-list-action-wrapper d-flex flex-column">
                                    <v-text-field class="mt-5" label="Titel" v-model="course.name"></v-text-field>
                                    <v-textarea label="Beschreibung" v-model="course.beschreibung"></v-textarea>
                                    <slim-cropper style="height: 300px" :options="slimOptionsExercise">
                                        <img v-if="course.bild" :src="'/img/kurse/' + course.bild" alt="" />
                                        <input type="file" accept="image/*" />
                                    </slim-cropper>
                                    <v-checkbox label="slimMe Kurs" v-model="course.slimMe">course</v-checkbox>
                                </div>
                                <v-card-actions class="justify-end">
                                    <v-btn text @click="dialog.value = false">Abbrechen</v-btn>
                                    <v-btn color="primary" text @click="updateCourse(course, dialog)">Speichern</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </div>
            </v-col>
        </v-row>
        <div v-else>
            <v-row class="course_picture" v-for="(course, index) of courseList" :key="'bild-' + course._id">
                <v-col cols="6">
                    <v-img
                        height="200px"
                        width="100%"
                        contain
                        v-if="course.bild"
                        :src="'/img/kurse/' + course.bild"
                    ></v-img>
                </v-col>
                <v-col cols="6" class="d-flex">
                    <strong class="my-auto">{{ course.name }}</strong>
                    <div class="courseSettings__course-list-action action2">
                        <v-dialog
                            @input="setEditIndex($event, index)"
                            transition="dialog-bottom-transition"
                            max-width="600"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="red" icon @click="deleteEntry(course, 'course', 0)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    class="courseSettings__course-list-action-btn"
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card dark>
                                    <v-toolbar color="primary" dark>
                                        <div class="d-block">
                                            <strong>{{ shorten(course.name) }}</strong>
                                        </div>
                                    </v-toolbar>
                                    <div class="px-5 courseSettings__course-list-action-wrapper d-flex flex-column">
                                        <v-text-field
                                            filled
                                            class="mt-5"
                                            label="Titel"
                                            v-model="course.name"
                                        ></v-text-field>
                                        <v-textarea
                                            filled
                                            label="Beschreibung"
                                            v-model="course.beschreibung"
                                        ></v-textarea>
                                        <slim-cropper style="height: 200px" :options="slimOptionsExercise">
                                            <img v-if="course.bild" :src="'/img/kurse/' + course.bild" alt="" />
                                            <input type="file" accept="image/*" />
                                        </slim-cropper>
                                    </div>
                                    <v-card-actions class="justify-end">
                                        <v-btn text @click="dialog.value = false">Abbrechen</v-btn>
                                        <v-btn color="primary" text @click="updateCourse(course, dialog)">
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ shorten(deleteDialogTitle, 25) }}
                </v-card-title>
                <v-card-text v-if="deleteDialogPreText" class="red--text">
                    Kurs wird in folgenden Studios verwendet: {{ deleteDialogPreText }}
                </v-card-text>
                <v-card-text>
                    Möchtest du {{ getTextbyType(deleteDialogType) }}
                    <strong>{{ shorten(deleteDialogTitle, 25) }}</strong>
                    wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="green darken-1" text @click="deleteEntry(dialogDeleteObj, deleteDialogType, 1)">
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../../components/userSearch.vue'
import courseSearch from '../../../components/courseSearch.vue'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import EventHome from '../../event/eventHome.vue'

export default {
    name: 'courseSettings',
    components: { courseSearch, 'slim-cropper': Slim, EventHome },
    data() {
        return {
            userService,
            showCoursePictureList: false,
            editIndex: null,
            courseList: [],
            courseListFilter: '',
            addCourse: {
                name: '',
                beschreibung: '',
                bild: '',
            },
            deleteDialog: false,
            deleteDialogText: '',
            deleteDialogTitle: '',
            dialogDeleteObj: {},
            deleteDialogType: '',
            deleteDialogPreText: '',
            slimOptionsExercise: {
                ratio: '16:9',
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
        }
    },
    async created() {
        await this.fetchCourseList()
        this.$emit('finishedloading')
    },
    methods: {
        setEditIndex(active, index) {
            if (active) {
                this.editIndex = index
            } else {
                this.editIndex = null
            }
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'groupFitness_course'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        if (this.editIndex) {
                            this.courseList[this.editIndex].bild = data.name
                        } else {
                            this.addCourse.bild = data.name
                        }

                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                })
        },
        async createCourse(dialog) {
            let course = this.addCourse

            let created = await api.createGFCourse(course)
            await this.fetchCourseList()
            dialog.value = false
        },
        async updateCourse(course, dialog) {
            let updated = await api.updateGFCourse(course)
            await this.fetchCourseList()
            dialog.value = false
        },
        getTextbyType(type) {
            if (type === 'label') return 'das Label'
            if (type === 'room') return 'den Raum'
            if (type === 'course') return 'den Kurs'
        },
        async deleteEntry(entry, type, status = 0) {
            this.dialogDeleteObj = entry
            this.deleteDialogType = type
            this.deleteDialogPreText = ''
            let error = false
            if (type === 'label') this.deleteDialogTitle = entry.text
            if (type === 'label' && status) await api.deleteGFLabel(entry._id).catch((e) => (error = true))
            if (type === 'room') this.deleteDialogTitle = entry.name
            if (type === 'room' && status) await api.deleteGFRoom(entry._id).catch((e) => (error = true))
            if (type === 'course') this.deleteDialogTitle = entry.name
            if (type === 'course') {
                let usage = await api.checkGFCourseUsage(entry._id)
                if (usage) this.deleteDialogPreText = usage
            }
            if (type === 'course' && status)
                (await api.deleteGFCourse(entry._id).catch((e) => (error = true))) && this.fetchCourseList()
            if (!status) this.deleteDialog = true
            if (status && !error) this.deleteDialog = false
        },

        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },

        async fetchCourseList() {
            this.courseList = await api.fetchGFCourses()
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-slide-group__prev {
    display: none !important;
}

.action2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
}

.course_picture {
    border-bottom: 1px solid white;
    cursor: pointer;

    &:hover {
        background-color: rgb(218, 218, 218);
    }
}

.courseSettings {
    &__course {
        &-list {
            font-weight: bold;
            border-bottom: 1px solid white;
        }
    }
}
</style>
