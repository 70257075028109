<template>
	<div class="d-flex flex-column justify-justify-space-around">
		<v-card-title class="justify-center h4 mt-10">{{ time }}</v-card-title>
		<v-card-actions class="d-flex flex-row justify-space-around">
			<v-btn color="error" @click="reset">reset</v-btn>
			<v-btn color="primary" @click="runButtonClicked">{{ running ? 'pause' : 'start' }}</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'WorkingOutStepStopwatch',
	data() {
		return {
			timeBegan: null,
			timeStopped: null,
			stoppedDuration: 0,
			running: false,
			time: '00:00.000',
			started: null,
		}
	},
	methods: {
		runButtonClicked() {
			this.running ? this.stop() : this.start()
		},
		start() {
			if (this.running) return

			if (this.timeBegan === null) {
				this.reset()
				this.timeBegan = new Date()
			}

			if (this.timeStopped !== null) {
				this.stoppedDuration += new Date() - this.timeStopped
			}
			this.started = setInterval(this.clockRunning, 10)
			this.running = true
		},
		stop() {
			this.running = false
			this.timeStopped = new Date()
			clearInterval(this.started)
			this.$emit('saveTime', this.time)
		},
		reset() {
			this.running = false
			clearInterval(this.started)
			this.stoppedDuration = 0
			this.timeBegan = null
			this.timeStopped = null
			this.time = '00:00.000'
		},
		clockRunning() {
			let currentTime = new Date()
			let timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration)
			let min = timeElapsed.getUTCMinutes()
			let sec = timeElapsed.getUTCSeconds()
			let ms = timeElapsed.getUTCMilliseconds()

			this.time = this.zeroPrefix(min, 2) + ':' + this.zeroPrefix(sec, 2) + '.' + this.zeroPrefix(ms, 3)
		},
		zeroPrefix(num, digit) {
			let zero = ''
			for (let i = 0; i < digit; i++) {
				zero += '0'
			}
			return (zero + num).slice(-digit)
		},
	},
}
</script>

<style lang="scss" scoped>
.v-application ul {
	padding-left: 0;
}
.picker {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: center;
	color: rgba(0, 0, 0, 0.7);
}

::v-deep .picker_chosen {
	background: var(--v-primary);
	color: white;
	padding-left: 0;
	border-radius: 20px;
	font-weight: bold;
}
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOutStep__card {
	bottom: 0;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 6px solid var(--v-primary) !important;

	&-lottie-player {
		display: unset;
	}

	&-form-text {
		height: 45%;
	}

	&__wrapper {
		height: 100%;
	}

	&__step-wrapper {
		height: 60vh;
		overflow-y: auto;
	}

	&-title {
		position: sticky;
	}

	&-actions {
		position: sticky;
		bottom: 0;
	}
}
</style>
