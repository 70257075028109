<template>
    <div>
        <router-view :studios="studios" @finishedloading="finishedloading()"></router-view>
    </div>
</template>

<script>
export default {
    props: {
        studios: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        finishedloading() {
            this.$emit('finishedloading')
        },
    },
}
</script>
