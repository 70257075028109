<template>
    <v-card style="padding-bottom: 150px" transparent elevation="0">
        <v-card-text style="word-break: break-word">
            Zentrale<br />
            Walzmühlestrasse 50<br />
            8500 Frauenfeld<br />
            Tel. 052 721 36 92<br />
            www.wellcomefit.ch info@wellcomefit.ch<br />
        </v-card-text>
        <v-card-title style="word-break: break-word">1. Datenschutz</v-card-title>
        <v-card-text style="word-break: break-word">
            Die Datenschutzerklärung beschreibt, wie und wozu wir Personendaten erheben, bearbeiten und verwenden. Der
            verantwortungsvolle Umgang mit Daten von Kunden und Kundinnen ist uns ein wichtiges Anliegen. Die nachfolgende
            Datenschutzerklärung gilt für die Nutzung unserer Webseite sowie der darauf angebotenen Dienstleistungen.<br />
            <br />
            Sie erfahren in dieser Datenschutzerklärung unter anderem:<br />
            <ul>
                <li>welche Personendaten wir erheben und bearbeiten;</li>
                <li>zu welchen Zwecken wir Ihre Personendaten verwenden;</li>
                <li>welchen Nutzen unsere Datenbearbeitung für Sie hat;</li>
                <li>wie lange wir Ihre Personendaten bearbeiten;</li>
                <li>welche Rechte Sie in Bezug auf Ihre Personendaten haben; und</li>
                <li>wie Sie uns kontaktieren können.</li>
            </ul>

            Die Erhebung und die Bearbeitung Ihrer personenbezogenen Daten geschehen unter Beachtung der geltenden
            datenschutzrechtlichen Gesetze und Anforderungen. Im Übrigen lehnt das Fitness-Center jegliche Haftung bei der
            Nutzung dieser Webseite ab, soweit zulässig.<br /><br />
            Unser Fitness-Center bietet seine Dienstleistungen nur in der Schweiz an und richtet sich nur an in der Schweiz
            wohnhafte Personen.
        </v-card-text>
        <v-card-title style="word-break: break-word">2. Verantwortlicher</v-card-title>
        <v-card-text style="word-break: break-word">
            Verantwortlicher im Sinne des schweizerischen Datenschutzgesetzes (DSG) ist: <br />
            Tunç Karapalanci<br />
            well come FIT <br />Walzmühlestrasse 50 <br />8500 Frauenfeld
        </v-card-text>
        <v-card-title style="word-break: break-word">3. Erhebung und Bearbeitung von Personendaten</v-card-title>
        <v-card-title style="word-break: break-word">3.1 Besuch der Webseite</v-card-title>
        <v-card-text
            >Beim Besuch unserer Webseite erfassen wir automatisch Informationen über den Zugriff (z.B. Datum und Uhrzeit,
            aufgerufene Seiten, Art des Webbrowsers, Betriebssystem u.a.). Diese Daten werden autom. Erhoben und dienen
            ausschliesslich statistischen Zwecken oder zur Verbesserung unserer Webseite. Es kann von uns keine Zuordnung
            dieser Daten zu einer bestimmten Person erfolgen. <br />Um unsere Webseite ordnungsgemäss zu präsentieren, deren
            Inhalte zu verbessern, die langfristige Funktionsfähigkeit unserer IT-Infrastruktur und der technischen Aspekte
            der Webseite sicherzustellen und im Falle eines Cyberangriffs den Strafverfolgungsbehörden relevante
            Informationen bereitzustellen, benötigen wir diese Daten und Informationen. Die gesammelten Daten werden
            anschliessend gelöscht.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.2. Links anderer Webseiten</v-card-title>
        <v-card-text
            >Diese Webseite kann Links zu Webseiten anderer Anbieter enthalten. Unser Fitness-Center hat keinen Einfluss auf
            diese Webseiten, deren Inhalte, Angebote oder Verfügbarkeit oder die dort geltenden Datenschutzerklärungen und
            -bestimmungen. Sie lehnt daher jegliche Haftung soweit zulässig ab.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.3. Cookies und weitere Serviceangebote </v-card-title>
        <v-card-text
            >Unser Fitness-Center verwendet auf der Webseite auch Cookies. Cookies sin kleine Textdateien mit einer
            sogenannte Cookie-ID, eine eindeutige Kennung des Cookies, welche Webseiten und Server einem konkreten
            Internetbrowser zuordnet. Ein bestimmter Internetbrowser kann über diese eindeutige Cookie-ID wiedererkannt und
            identifiziert werden. Wir weisen Sie gemäss Art. 45c lit. b FMG darauf hin, dass Sie die Möglichkeit haben in
            Ihrem Browser das Setzen von Cookies zu verhindern, wozu Sie die jeweiligen Hinweise des Browser-Anbieters
            beiziehen können. Weitere Informationen zu den genutzten Cookies finden Sie am Ende dieser
            Datenschutzerklärung.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.4. Social Media / Soziale Netzwerke </v-card-title>
        <v-card-text
            >Auf dieser Webseite können Schaltflächen («Social-Media-Plugins») platziert sein, die einen Kontakt mit den
            Servern von sozialen Netzwerken (Facebook, Twitter, Instagram etc.) herstellen. Mit dem Klick auf einen dieser
            Buttons erteilen Sie Ihre Zustimmung, dass notwendige Daten wie IP-Adresse, Besuch der Webseite u.a. an den
            jeweiligen Betreiber des sozialen Netzwerks übermittelt werden. Die Bearbeitung Ihrer Personendaten erfolgt dann
            in Verantwortung dieses Betreibers nach dessen Datenschutzbestimmungen. Wir erhalten von ihm keine Angaben zu
            Ihnen.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.5. Newsletter</v-card-title>
        <v-card-text
            >Über unsere Webseite haben Sie die Möglichkeit, unseren Newsletter zu abonnieren. Daten, welche Sie dem
            Fitness-Center hierbei zur Verfügung stellen, insbesondere Ihre E-Mail-Adresse und Ihren Namen, können für den
            regelmässigen Versand unseres Newsletters verwendet werden. Der Versand erfolgt entweder direkt über das
            Fitness-Center oder über ein beauftragtes Unternehmen. Sie können den Newsletter jederzeit wieder abbestellen
            (jeweils über den Link im Newsletter). Auch können Sie uns dies per E-Mail oder über die im Impressum angegebenen
            Kontaktdaten mitteilen. Die personenbezogenen Daten verbleiben bei unserem Fitness-Center und allenfalls beim für
            den Newsletter-Versand beauftragten Unternehmen, solange Sie den Newsletter abonniert haben. Die Einwilligung zur
            Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletter-Versand können Sie jederzeit mit
            Wirkung für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.6. Kontaktaufnahme</v-card-title>
        <v-card-text
            >Die Webseite unserer Fitness-Center enthält gewisse Angaben, die eine schnelle elektronische Kontaktaufnahme
            sowie eine unmittelbare Kommunikation ermöglichen, was ebenfalls eine allgemeine E-Mail-Adresse umfasst. Nehmen
            Sie über die auf unserer Webseite angebotenen Kommunikationsmöglichkeiten Kontakt mit uns auf, werden Ihre
            Kontaktangaben und Inhalte lediglich zur Bearbeitung und Beantwortung Ihrer Anfrage verwendet. Wenn Sie mit uns
            über das Kontaktformular, per E-Mail oder auf andere Weise in Verbindung treten, speichern und bearbeiten wir die
            von Ihnen bereitgestellten personenbezogenen Daten (z.B. Name, E-Mail-Adresse, Telefonnummer) zur Bearbeitung
            Ihrer Anfrage und für eventuelle Anschlussfragen.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.7. Mitgliedschaft im Fitness-Center</v-card-title>
        <v-card-text
            >Wenn Sie sich für eine Mitgliedschaft in unserem Fitness-Center anmelden, erfassen und bearbeiten wir Ihre
            personenbezogenen Daten (z.B. Name, Adresse, Geburtsdatum, Kontaktdaten, Zahlungsinformationen) zum Zweck der
            Vertragsabwicklung, der Durchführung von Kursen und Trainingseinheiten sowie für administrative
            Zwecke.</v-card-text
        >
        <v-card-title style="word-break: break-word">3.8. Videoüberwachung</v-card-title>
        <v-card-text
            >Zur Sicherheit unserer Kunden und zur Verhinderung von Straftaten können in unseren Räumlichkeiten
            Videoüberwachungssysteme eingesetzt werden. Die dabei erfassten Aufnahmen werden ausschliesslich zu
            Sicherheitszwecken verwendet und nach einer angemessenen Frist gelöscht, sofern sie nicht als Beweismittel für
            etwaige Vorfälle benötigt werden.</v-card-text
        >
        <v-card-title style="word-break: break-word">4. Zwecke der Datenbearbeitung</v-card-title>
        <v-card-text
            >Wir verwenden Personendaten, die wir erhalten oder erhoben haben, in erster Linie zur Abwicklung von Verträgen
            mit Kunden und Geschäftspartnern in Fitness-Centren. Zusätzlich bearbeiten wir diese Daten für Zwecke wie die
            Weiterentwicklung unserer Angebote, die Kommunikation mit Dritten, Werbung und Marketing, die Sicherheit unserer
            Einrichtungen sowie geschäftliche Transaktionen. Wir bearbeiten Personendaten auch, um rechtliche Pflichten
            einzuhalten und Verstösse zu verhindern. Wenn Sie in einer solchen Funktion tätig sind, können auch Ihre
            personenbezogenen Daten betroffen sein.
        </v-card-text>
        <v-card-title style="word-break: break-word">5. Weitergabe von Daten an Dritte</v-card-title>
        <v-card-text
            >FITWORX ist das neue Gastcenter-Netzwerk der selbstständigen und durch Fitnessguide zertifizierten
            Trainingscenter. Als Mitglied von unserem Center können Sie dank FITWORX bei allen Partnercentern schweizweit
            trainieren. Eine Übermittlung der personenbezogenen Daten an Dritte erfolgt nur, sofern dies zur Erfüllung
            unserer vertraglichen Pflichten erforderlich ist oder wir aufgrund gesetzlicher Bestimmungen dazu verpflichtet
            sind. In der Regel bearbeiten diese Dienstleister Personendaten in unserem Auftrag als sog. «Auftragsbearbeiter».
            Unsere Auftragsbearbeiter sind verpflichtet, Personendaten ausschliesslich nach unseren Instruktionen zu
            bearbeiten sowie geeignete Massnahmen zur Datensicherheit zu treffen. Bestimmte Dienstleister sind auch gemeinsam
            mit uns oder selbständig verantwortlich (z.B. Inkassounternehmen). Wir stellen durch die Auswahl der
            Dienstleister und durch geeignete vertragliche Vereinbarungen sicher, dass der Datenschutz während der gesamten
            Bearbeitung Ihrer Personendaten sichergestellt ist. Dabei geht es z.B. um Dienstleistungen in folgenden
            Bereichen:
            <ul>
                <li>Spedition und Logistik, z.B. für den Versand bestellter Waren;</li>
                <li>Werbe- und Marketingdienstleistungen, z.B. für den Newsletter Versand;</li>
                <li>Unternehmensverwaltung, z.B. Buchhaltung;</li>
                <li>Zahlungsdienste;</li>
                <li>Bonitätsinformationen, z.B. wenn Sie Leistungen auf Rechnung tätigen wollen;</li>
                <li>Inkassodienstleistungen;</li>
                <li>IT-Dienstleistungen, z.B. Hosting, Cloud-Dienste, Versand von E-Mail;</li>
                <li>Beratungsdienstleistungen, z.B. Steuerberater*innen, Rechtsanwält*innen.</li>
            </ul>
        </v-card-text>
        <v-card-title style="word-break: break-word">6. Datensicherheit</v-card-title>
        <v-card-text
            >Wir treffen angemessene technische und organisatorische Sicherheitsmassnahmen, um Ihre Personendaten gegen
            unbeabsichtigte oder unrechtmässige Vernichtung, Verlust, Veränderung, unbefugte Offenlegung oder unbefugten
            Zugriff zu schützen. Unsere Sicherheitsmassnahmen werden regelmässig überprüft und an den technologischen
            Fortschritt angepasst.
        </v-card-text>
        <v-card-title style="word-break: break-word">7. Ihre Rechte</v-card-title>
        <v-card-text
            >Sie haben das Recht, Auskunft über die von uns zu Ihrer Person gespeicherten Daten zu erhalten. Darüber hinaus
            haben Sie das Recht auf Berichtigung unrichtiger Daten, Sperrung und Löschung Ihrer personenbezogenen Daten,
            sofern keine gesetzlichen Aufbewahrungspflichten bestehen. Zur Geltendmachung Ihrer Rechte setzen Sie sich bitte
            mit dem Fitness-Center in Verbindung. Wir stehen Ihnen für weitere diesbezügliche Informationen gerne zur
            Verfügung.
        </v-card-text>
        <v-card-title style="word-break: break-word">8. Cookies und Tracking</v-card-title>
        <v-card-text
            >Diese Cookie-Informationen beschreiben, wie und wozu wir bei der Verwendung unserer Webseiten – insbesondere im
            Zusammenhang mit Cookies und ähnlichen Technologien – Personen- und andere Daten erheben, bearbeiten und
            verwenden. Was sind Cookies und ähnliche Technologien? Cookies sind Dateien, die Ihr Browser automatisch auf
            Ihrem Endgerät speichert, wenn Sie unsere Webseiten besuchen. Cookies enthalten eine eindeutige Kennnummer (eine
            ID), durch die wir einzelne Besucher*innen von anderen unterscheiden können, in der Regel aber ohne sie zu
            identifizieren. Je nach Verwendungszweck enthalten Cookies weitere Informationen, z.B. über aufgerufene Seiten
            und die Dauer des Besuchs einer Seite. Wir verwenden einerseits Session-Cookies, die beim Schliessen des Browsers
            wieder gelöscht werden, und andererseits dauerhafte Cookies, die nach dem Schliessen des Browsers für eine
            bestimmte Dauer gespeichert bleiben (in der Regel zwischen wenigen Tagen und zwei Jahren) und dazu dienen,
            Besucher*innen bei einem späteren Besuch wiederzuerkennen. Wir können ausserdem ähnliche Technologien wie z.B.
            Pixel Tags, Fingerprints und andere Technologien zur Speicherung von Daten im Browser verwenden. Pixel Tags
            bezeichnen kleine, üblicherweise nicht sichtbare Bilder oder ein Programmcode, die von einem Server geladen
            werden und dem Betreiber des Servers dadurch bestimmte Angaben übermitteln, z.B. ob und wann eine Webseite
            besucht wurde. Fingerprints sind Informationen, die bei Ihrem Webseitenbesuch über die Konfiguration Ihres
            Endgeräts oder Ihren Browser gesammelt werden, und die Ihr Endgerät von anderen Geräten unterscheidbar machen.<br />
            <br />
            Die meisten Browser unterstützen zudem weitere Technologien zur Ablage von Daten im Browser, ähnlich wie bei
            Cookies, die wir ebenfalls einsetzen können (z.B. «Web Storage»). Wie können Cookies und ähnliche Technologien
            deaktiviert werden? Teilweise haben Sie beim Aufruf unserer Webseiten die Möglichkeit, über eine im Browser
            angezeigte Schaltfläche bestimmte Kategorien von Cookies zu aktivieren oder zu deaktivieren. Ferner können Sie
            Ihren Browser in den Einstellungen so konfigurieren, dass er bestimmte Cookies oder ähnliche Technologien
            blockiert oder bestehende Cookies und andere im Browser gespeicherte Daten löscht. Sie können Ihren Browser auch
            mit Software (sog. «Plug-Ins») erweitern, die ein Tracking durch bestimmte Dritte sperrt. Mehr dazu finden Sie in
            den Hilfeseiten Ihres Browsers (meist unter dem Stichwort «Datenschutz»). Bitte beachten Sie, dass unsere
            Webseiten allenfalls nicht mehr in vollem Umfang funktionieren, wenn Sie Cookies und ähnliche Technologien
            blockieren. <br />Notwendige Cookies: Notwendige Cookies sind erforderlich, damit eine Webseite und ihre
            Funktionen verwendet werden können. Diese Cookies stellen z.B. sicher, dass Sie zwischen den Seiten wechseln
            können, ohne dass die in einem Formular eingegebenen Angaben oder die in einen Warenkorb gelegten Produkte
            verloren gehen. Leistungs-Cookies: Leistungs-Cookies erheben Informationen über die Nutzungsweise einer Webseite
            und ermöglichen es uns, Analysen durchzuführen, z.B. welche Seiten am beliebtesten sind und wie sich
            Besucher*innen auf einer Webseite bewegen. Diese Cookies dienen dazu, den Besuch auf der Webseite zu vereinfachen
            und zu beschleunigen sowie generell die Nutzerfreundlichkeit zu verbessern. <br />Funktionale Cookies:
            Funktionale Cookies ermöglichen es uns, erweiterte Funktionalitäten bereitzustellen und personalisierte Inhalte
            anzuzeigen. Diese Cookies erlauben es uns z.B., bereits getätigte Angaben (z.B. Sprachauswahl) zu speichern oder
            Ihnen basierend auf angesehenen Artikeln Produkte anzuzeigen, die Ihnen ebenfalls gefallen könnten.
            Marketing-Cookies: Marketing-Cookies helfen uns und unseren Werbepartnern, Sie auf unseren Webseiten und auf
            Webseiten Dritter mit Werbung für Produkte oder Dienstleistungen anzusprechen, die für Sie interessant sein
            könnten, oder nach einem Besuch unserer Webseiten bei Ihrer weiteren Internetnutzung unsere Werbeanzeigen
            anzuzeigen.<br /><br />
            Google Analytics<br />
            Zwei der wichtigsten Drittanbieter sind Google und Facebook. Zu diesen finden Sie nachstehend weitere Angaben.
            Andere Drittanbieter bearbeiten Personen- und andere Daten in der Regel in ähnlicher Weise. Wir nutzen Google
            Analytics, einen Analyse-Dienst von Google. Google verwendet Cookies und ähnliche Technologien, um bestimmte
            Informationen über das Verhalten einzelner Nutzer*innen auf bzw. in der betreffenden Webseite und das dazu
            verwendete Endgerät (Tablet, PC, Smartphone etc.) zu erfassen (z.B. wie oft Sie unsere Webseite geöffnet haben,
            wie viele Käufe getätigt wurden oder welche Interessen Sie haben sowie Daten über Ihr genutztes Endgerät wie
            bspw. das Betriebssystem). Dazu finden Sie weitere Angaben unter diesem Link. Wir haben den Dienst so
            konfiguriert, dass die IP-Adressen der Besucher*innen der Webseiten von Google innerhalb von Europa vor der
            Weiterleitung in die USA gekürzt werden und sie so nicht zurückverfolgt werden können. Google liefert uns
            Berichte und kann in diesem Sinne als unser Auftragsbearbeiter betrachtet werden. Google bearbeitet bestimmte
            Daten aber auch für eigene Zwecke. Google kann unter Umständen aufgrund der gesammelten Daten Rückschlüsse auf
            die Identität der Besucher*innen der Webseiten ziehen und daher personenbezogene Profile erstellen und die
            gewonnenen Daten mit allfällig vorhandenen Google-Konten dieser Personen verknüpfen. Informationen zum
            Datenschutz von Google Analytics finden Sie hier:
            https://www.blog.google/topics/google-europe/gdpr-europe-data-protection-rules/<br />
            <br />
            Facebook<br />
            Unsere Webseiten können ferner den sog. «Facebook-Pixel» und ähnliche Technologien von Facebook verwenden. Wir
            setzen diese Technologien ein, um die durch uns geschalteten Facebook-Ads nur Nutzer bei Facebook und Partnern,
            die ein Interesse an uns gezeigt haben, die wir zu diesem Zweck an Facebook übermitteln (Präferenzen). Wir können
            hierüber die Wirksamkeit der Facebook-Werbeanzeigen nachvollziehen. Weitere Angaben dazu finden Sie hier:
            https://de-de.facebook. com/legal/terms/customaudience Wir haben mit Facebook eine entsprechende
            Zusatzvereinbarung geschlossen. Nutzer*innen können daher Auskunftsbegehren und andere Betroffenenanfragen im
            Zusammenhang mit der gemeinsamen Verantwortung direkt an Facebook richten.
        </v-card-text>
        <v-card-title style="word-break: break-word">9. Änderungen</v-card-title>
        <v-card-text
            >Diese Datenschutzerklärung kann jederzeit ohne Vorankündigung angepasst werden. Es gilt die jeweils aktuelle,
            auf unserer Webseite publizierte Fassung. Soweit die Datenschutzerklärung Teil eines Vertrages mit Ihnen ist,
            werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise
            informieren.
        </v-card-text>
    </v-card>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
</style>
