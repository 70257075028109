const api = require('../../api').default
const moment = require('moment')
export class drinkingHabit {
	constructor(
		drinkingHabit = {
			label: 'Trinken',
			type: 'drinkHabit',
			description: 'Als Merkregel gilt zwischen 30 und 40 ml pro Kilogramm Körpergewicht pro Tag.',
			icon: 'glas.png',
			amount: 0,
		}
	) {
		this.setDrinkHabit(drinkingHabit)
	}
	getDrinkHabit() {
		let habit = {}
		for (let key in this) {
			habit[key] = this[key]
		}
		return habit
	}
	setDrinkHabit(habit) {
		for (let key in habit) {
			this[key] = habit[key]
		}
	}
	async execute(amount, time = moment().format(), date = moment().format('YYYY-MM-DD')) {
		if (!this.executions) this.executions = {}
		if (!this.executions[date]) this.executions[date] = []
		this.executions[date].push({ amount, time })
		return await this.save()
	}
	async delete() {
		try {
			let habit = await api.deleteHabit(this)
			console.log(habit)
		} catch (e) {
			console.log(e)
		}
	}
	async save() {
		try {
			let habit = await api.updateHabit(this)
			this.setDrinkHabit(habit)
		} catch (e) {
			console.log('Error: ', e)
		}
	}
}
