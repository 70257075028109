<template>
<div class="covid_cert_wrapper">
  <h2 class="text-center my-5">Offene Covid-Zertifikate</h2>
  <div class="user_list_wrapper mt-3 mb-5">
   <div style="line-height: 33px;" class="d-flex justify-space-between px-5 my-5"> Insgesamt: {{user_list.length}} <v-btn color="#52ae32" inline-block class="ml-auto white--text" @click="$router.push('/covidcerts/archive')">zum Archiv</v-btn></div>
    <div :class="{user_list: 1, invalid: user.certInvalid, 'd-flex': 1}" v-for="(user, i) in user_list" :key="i">
      <span>{{formatDate(user.certDate)}} <strong>{{user.vorname}} {{user.nachname}}</strong> | {{user.email}} {{user.telefon}}</span> <v-btn inline-block color="#313131" class="white--text ml-auto mr-3" @click="downloadCert(user._id)">Ansehen</v-btn> <v-btn inline-block color="#52ae32" class="white--text" @click="checkCert(user._id)">Gültig</v-btn><v-btn inline-block color="red" class="white--text ml-1" @click="invalidCert(user._id)">Ungültig</v-btn>
     </div>
  </div>
  </div>
</template>
<script>
import api from "../../api";

export default {
  data() {
    return {
      user_list: []
    };
  },
  methods: {
    checkCert(userid) {
      if (confirm("Ist das Zertifikat korrekt?")) {
        api.checkUserCert(userid).then((data) => {
          this.user_list = [];
          this.loadUserCerts();
        })
      }
    },
    invalidCert(userid) {
      api.invalidUserCert(userid).then((data) => {
            if (confirm("Ist das Zertifikat ungültig?")) {
              this.user_list = [];
              this.loadUserCerts();
            }
      })
    },
    downloadCert(userid) {
      api.downloadCertForUser(userid).then((data) => {
        saveAs(data, "Covid_cert_"+Math.round(new Date() / 1000));
      })
    },
    formatDate(date) {
      let d = new Date(parseInt(date)*1000);
      let hours = d.getHours();
      let minutes = d.getMinutes();
      if (hours < 10) {
        hours = "0"+hours
      }
      if (minutes < 10) {
        minutes = "0"+minutes
      }
      return d.toLocaleDateString(
          "de-CH"
      )+" "+hours+":"+minutes+" | "
    },
    loadUserCerts() {
      api.getUserCerts().then((data) => {
        this.user_list = data;
      });
    }
  },
  updated() {},
  created() {
   this.loadUserCerts();
  },
};
</script>
<style lang="scss" scoped>
.user_list_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0px auto;
}
.user_list {
  border-bottom: 1px solid black;
  margin-bottom: 5px;

  padding: 10px 15px;

  span {
    line-height: 34px;
  }
}
.user_list.invalid {
  color: red;
}
.back-navigation {
  background-color: #52ae32;
  color: white;
  padding: 0px 10px;
  font-weight: 500;
  display: flex;
  margin-bottom: 20px;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
  a {
    display: flex;
    padding: 5px;
    color: white;

    span {
      font-size: 34px;
    }
  }
}
</style>
