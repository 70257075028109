<template>
	<v-row justify="center">
		<v-dialog
			style="z-index: 2000;"
			persistent
			:content-class="selectedHabit ? 'extended-dialog' : 'extended-dialog'"
			v-model="dialog"
			transition="dialog-bottom-transition"
			origin="bottom center"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" color="primary" icon class="elevation-5" x-large><v-icon>mdi-plus</v-icon></v-btn>
			</template>
			<v-card-title class="justify-content-center primary white--text">
				{{ selectedHabit.label }} Statistik
				<v-btn icon color="white" @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
			</v-card-title>

			<v-row class="justify-center">
				<v-col cols="8">
					<v-dialog ref="dialog" v-model="datePicker" :return-value.sync="date" persistent width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								class="font-weight-bold"
								style="text-align-last: center;"
								:value="formattedDate"
								readonly
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							ref="datePicker"
							color="primary"
							:events="habitEvents"
							event-color="green lighten-1"
							:locale-first-day-of-year="4"
							:max="$moment().format('YYYY-MM-DD')"
							range
							v-model="date"
							no-title
							@update:active-picker="activePickerChanged"
							@change="$refs.dialog.save(date)"
						>
							<v-btn class="ma-auto pt-0" text color="primary" @click="datePicker = false">
								Abbrechen
							</v-btn>
							<!--	<v-btn text color="primary" @click="$refs.dialog.save(date)">
								Speichern
							</v-btn> -->
						</v-date-picker>
					</v-dialog>
				</v-col>
			</v-row>
			<v-row>
				<habit-workout-statistic v-if="selectedHabit.type === 'workoutHabit'" :selectedHabit="habitWithStatitics"></habit-workout-statistic>
			</v-row>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitWorkoutStatistic from './habitWorkoutStatistic.vue'
import api from '@/api.js'
export default {
	name: 'habitStatistic',
	components: {
		habitWorkoutStatistic,
	},
	props: {
		selectedHabit: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			dialog: true,
			date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
			datePicker: false,
			habitWithStatitics: null,
		}
	},
	async created() {
		this.habitWithStatitics = JSON.parse(JSON.stringify(this.selectedHabit))
		this.habitWithStatitics.executions = await api.getHabitStatistic(this.selectedHabit._id)
	},
	methods: {
		test(test) {
			console.log(test)
		},
		activePickerChanged(value) {
			if (value === 'DATE' && this.$refs.datePicker && this.$refs.datePicker.tableDate) {
				let pickedMonth = this.$moment(this.$refs.datePicker.tableDate, 'YYYY-MM')
				this.date[0] = pickedMonth.startOf('month').format('YYYY-MM-DD')
				this.date[1] = pickedMonth.endOf('month').format('YYYY-MM-DD')
			}
		},
		closeDialog() {
			this.dialog = false
			setTimeout(() => this.$emit('closeStatistic'), 250)
		},
	},
	computed: {
		habitEvents() {
			let events = Object.keys(this.habitWithStatitics.executions).map((key) => {
				if (this.habitWithStatitics.executions[key].createdAt) {
					return this.$moment(this.habitWithStatitics.executions[key].createdAt).format('YYYY-MM-DD')
				}
				return key
			})
			return events
		},
		formattedDate() {
			if (this.$moment(this.date[0], 'YYYY-MM-DD').isAfter(this.$moment(this.date[1]))) {
				let oldDate0 = this.date[0]
				this.date[0] = this.date[1]
				this.date[1] = oldDate0
			}
			if (
				this.$moment(this.date[0], 'YYYY-MM-DD')
					.startOf('month')
					.format('YYYY-MM-DD') === this.date[0] &&
				this.$moment(this.date[1], 'YYYY-MM-DD')
					.endOf('month')
					.format('YYYY-MM-DD') === this.date[1] &&
				this.$moment(this.date[0], 'YYYY-MM-DD').format('YYYY-MM') === this.$moment(this.date[1], 'YYYY-MM-DD').format('YYYY-MM')
			) {
				return this.$moment(this.date[0], 'YYYY-MM-DD').format('MMMM YYYY')
			} else if (
				this.$moment().isSame(this.$moment(this.date[0], 'YYYY-MM-DD'), 'day') &&
				this.$moment().isSame(this.$moment(this.date[1], 'YYYY-MM-DD'), 'day')
			) {
				return 'Heute'
			} else if (
				this.$moment()
					.subtract(1, 'days')
					.isSame(this.$moment(this.date[0], 'YYYY-MM-DD'), 'day') &&
				this.$moment()
					.subtract(1, 'days')
					.isSame(this.$moment(this.date[1], 'YYYY-MM-DD'), 'day')
			) {
				return 'Gestern'
			} else if (this.date[0] === this.date[1]) {
				return this.$moment(this.date[0], 'YYYY-MM-DD').format('DD.MM.YYYY')
			} else {
				let endDate = this.$moment().isSame(this.$moment(this.date[1], 'YYYY-MM-DD'), 'day')
					? 'Heute'
					: this.$moment(this.date[1], 'YYYY-MM-DD').format('DD.MM.YYYY')
				return this.$moment(this.date[0], 'YYYY-MM-DD').format('DD.MM.YYYY') + ' - ' + endDate
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
	transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	width: 100%;
	margin: 0;
	position: absolute;
	min-height: 40vh;
	bottom: 0;
	background: white;
	max-height: 90vh !important;
}

.option-button {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		background: lightgray;
		&-bulb {
			::v-deep .v-image__image {
				width: 80px;
			}
			flex-direction: row;
		}
	}
	&__wrapper:hover {
		background: gray;
	}
}
</style>
