<template>
    <div>
        <v-card v-for="(device, index) in devices" :key="device._id" dark class="mb-4">
            <v-card-text>
                <v-card-subtitle class="px-0">{{ device.Shortname }}</v-card-subtitle>
                <v-text-field v-model="device.title" label="Title"></v-text-field>
                <v-text-field v-model="device.studio" label="Studio"></v-text-field>
                <v-select v-model="device.purpose" :items="purposes" label="Purpose"></v-select>
                <v-btn @click="updateDevice(device)" color="primary" class="mt-2">Save</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>
  
  <script>
import api from '@/api'
export default {
    data() {
        return {
            devices: [],
            purposes: ['zugang', 'wellness', 'automat'],
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            try {
                let devices = await api.fetchAccessDevices()
                this.devices = devices.map((device) => ({
                    ...device,
                    title: device.title || null,
                    studio: device.studio || null,
                    purpose: device.purpose || null,
                }))
            } catch (e) {
                console.error('Error fetching devices:', e)
            }
        },
        async updateDevice(device) {
            try {
                await api.updateAccessDevice(device._id, {
                    title: device.title,
                    studio: device.studio,
                    purpose: device.purpose,
                })
                this.$toast.success('Device updated successfully!')
            } catch (e) {
                console.error('Error updating device:', e)
                this.$toast.error('Failed to update device')
            }
        },
    },
}
</script>
  
  <style scoped>
.mb-4 {
    margin-bottom: 16px;
}
</style>
  