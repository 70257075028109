<template>
    <v-dialog class="dialog" persistent v-model="dialogVisible" max-width="400">
        <v-card class="mx-auto editor">
            <v-card-title class="font-weight-bold dialog-title justify-center mb-5">{{ dialogTitle }}</v-card-title>
            <v-card-text class="headline ma-auto">Möchtest du das Item {{ itemName }} wirklich löschen?</v-card-text>
            <v-row no-gutters class="px-5"> </v-row>
            <v-card-actions class="dialog-actions">
                <v-btn class="white--text" text @click="cancelDelete"> Abbrechen </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="white--text" text @click="deleteItem"> Löschen </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>  

<script>
export default {
    props: {
        itemName: {
            type: String,
            required: true,
        },
        dialogTitle: {
            type: String,
            required: true,
        },
        dialogVisible: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {
        cancelDelete() {
            this.$emit('onCancel')
        },
        deleteItem() {
            this.$emit('onDelete')
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog {
    position: relative;
    &-title {
        position: sticky;
        top: 0;
        color: white;
        border-bottom: 2px solid var(--v-primary);
        z-index: 9;
        background-color: var(--v-primary);
    }
    &-actions {
        background-color: var(--v-primary);
        border-top: 2px solid var(--v-primary);
        width: 100%;
    }
}
</style>