<template>
    <div class="employee-manager">
        <v-row>
            <v-col align="center" class="mb-5" cols="12">
                <v-btn class="mx-2" @click="downloadBLSList">BLS-AED Liste herunterladen</v-btn>
                <v-btn class="mx-2" @click="downloadBLSListFileZip">BLS-AED Ausweise ZIP</v-btn>
            </v-col>
        </v-row>
        <v-expansion-panels dark>
            <v-expansion-panel v-for="(studio, index) in studios" :key="index">
                <v-expansion-panel-header>
                    {{ studio.name }}
                    <span class="orange--text ml-2">
                        {{
                            studio.studio
                                ? '  Center (' + storeStudios.find((std) => std._id === studio.studio).name + ')'
                                : ''
                        }}
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-if="employee.length">
                        <v-col cols="auto">
                            <v-card-title class="pb-0 justify-content-center">Anzahl Mitarbeiter</v-card-title>
                            <v-card-title class="pt-1 justify-content-center font-weight-bold">
                                {{ employeeForStudio(studio).length }}
                            </v-card-title>
                        </v-col>
                        <v-col cols="auto">
                            <v-card-title class="pb-0 justify-content-center">Anzahl Stellenprozent</v-card-title>
                            <v-card-title class="pt-1 justify-content-center font-weight-bold">
                                {{
                                    employeeForStudio(studio).reduce(
                                        (acc, curr) =>
                                            acc +
                                            (Number(
                                                curr.studio.find(
                                                    (std) => std._id === studio._id || std.team === studio._id
                                                ).percentage
                                            ) || 0),
                                        0
                                    )
                                }}%
                            </v-card-title>
                        </v-col>
                        <v-col cols="auto">
                            <v-card
                                hover
                                @click="
                                    () => {
                                        studio.code ? (targetHoursStudio = studio) : (targetHoursTeam = studio)
                                    }
                                "
                                elevation-0
                            >
                                <v-card-title class="pb-0 justify-content-center">
                                    Soll Stunden {{ $moment().format('MMM YYYY') }}
                                </v-card-title>
                                <v-card-title class="pt-1 justify-content-center font-weight-bold">
                                    {{
                                        Math.round(
                                            (calcBusinessDays - vacationDaysForMonth(studio).length) * 8.4 * 1000
                                        ) /
                                            1000 +
                                        ' Stunden'
                                    }}
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="auto" style="align-self: center">
                            <v-btn icon @click="deleteTeam(studio._id)"><v-icon color="red">mdi-delete</v-icon></v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col style="margin-top: 60px" cols="12" md="6" lg="4" xl="3">
                            <v-card
                                v-if="addActive"
                                hover
                                class="black employee-manager__card d-flex flex-column fill-height justify-space-between rounded-xl align-center"
                            >
                                <v-avatar
                                    class="employee-manager__card-avatar"
                                    :class="{
                                        'gold-border': user && user.border === 'gold',
                                        'blue-border': user && user.border === 'blue',
                                        'green-border': user && user.boder === 'green',
                                    }"
                                    color="grey"
                                    size="125"
                                ></v-avatar>

                                <user-search
                                    v-if="!loaded"
                                    style="display: unset !important; margin-top: 70px"
                                    label="Benutzer auswählen"
                                    @update="pickUser"
                                ></user-search>

                                <v-text-field
                                    filled
                                    v-if="loaded"
                                    class="fullWidth px-3"
                                    style="margin-top: 70px"
                                    label="angezeigter Name"
                                    v-model="user.fullName"
                                ></v-text-field>

                                <v-text-field
                                    filled
                                    v-if="loaded"
                                    class="fullWidth px-3"
                                    label="Titel"
                                    v-model="user.title"
                                ></v-text-field>
                                <v-text-field
                                    filled
                                    v-if="loaded"
                                    class="fullWidth px-3"
                                    label="Stellenprozent"
                                    v-model="user.percentage"
                                ></v-text-field>
                                <v-text-field
                                    v-if="loaded"
                                    number
                                    class="fullWidth px-3"
                                    :disabled="!userService.hasRight('tt_targetHours')"
                                    label="Ferienstunden"
                                    v-model="user.vacationHours"
                                ></v-text-field>

                                <v-select
                                    filled
                                    v-if="loaded"
                                    v-model="user.border"
                                    class="fullWidth px-3"
                                    :items="borderPossibilities"
                                    label="Rahmen"
                                ></v-select>
                                <v-card-actions class="d-flex justify-space-between px-3">
                                    <v-btn color="grey" @click="abortForm" outlined>Abbrechen</v-btn>

                                    <v-btn v-if="loaded" @click="saveForm(studio)" color="primary">Speichern</v-btn>
                                </v-card-actions>
                            </v-card>

                            <v-card
                                v-else
                                hover
                                class="black rounded-xl employee-manager__card d-flex fill-height flex-column justify-content-center align-center"
                            >
                                <v-avatar class="employee-manager__card-avatar" color="grey" size="125"></v-avatar>
                                <v-btn
                                    @click="addActive = true"
                                    color="primary"
                                    class=""
                                    style="position: absolute; bottom: 50px"
                                >
                                    Hinzufügen
                                </v-btn>
                            </v-card>
                        </v-col>
                        <v-col
                            style="margin-top: 65px"
                            v-for="employe of employeeForStudio(studio)"
                            :key="'employe' + studio.name + employe._id"
                            cols="12"
                            md="6"
                            lg="4"
                            xl="3"
                        >
                            <v-card
                                @click="employeeInEdit(employe)"
                                hover
                                class="black employee-manager__card d-flex flex-column fill-height rounded-xl justify-space-between align-center"
                            >
                                <v-avatar
                                    class="employee-manager__card-avatar"
                                    :class="{
                                        'gold-border':
                                            employe.studio.find(
                                                (std) => std._id === studio._id || std.team === studio._id
                                            ).border === 'gold',
                                        'blue-border':
                                            employe.studio.find(
                                                (std) => std._id === studio._id || std.team === studio._id
                                            ).border === 'blue',
                                        'green-border':
                                            employe.studio.find(
                                                (std) => std._id === studio._id || std.team === studio._id
                                            ).border === 'green',
                                    }"
                                    color="grey"
                                    size="125"
                                >
                                    <slim-cropper
                                        v-if="employe.editPic"
                                        :options="slimOptions"
                                        style="width: 125px; height: 125px"
                                    >
                                        <img
                                            v-if="employe.picture"
                                            :src="'/img/profilbilder' + employe.picture"
                                            alt=""
                                        />
                                        <input type="file" accept="image/*" />
                                    </slim-cropper>
                                    <img v-else :src="'/img/profilbilder' + employe.picture" alt="" />
                                </v-avatar>

                                <v-text-field
                                    filled
                                    v-if="employe.fullName"
                                    class="fullWidth px-3"
                                    style="margin-top: 70px"
                                    label="angezeigter Name"
                                    @blur="updateEmployee(employe)"
                                    v-model="employe.fullName"
                                ></v-text-field>
                                <v-text-field
                                    disabled
                                    filled
                                    v-if="employe.userEmail"
                                    class="fullWidth px-3"
                                    label="E-Mail"
                                    @blur="updateEmployee(employe)"
                                    v-model="employe.userEmail"
                                ></v-text-field>

                                <v-text-field
                                    filled
                                    class="fullWidth px-3"
                                    label="Titel"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .title
                                    "
                                    @blur="updateEmployee(employe)"
                                ></v-text-field>
                                <v-checkbox
                                    filled
                                    class="fullWidth px-3"
                                    label="Centerleitung"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .centerManager
                                    "
                                    @click="updateEmployee(employe)"
                                ></v-checkbox>
                                <v-text-field
                                    v-if="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .centerManager &&
                                        !(
                                            employe.userEmail.includes('wellcomefit') ||
                                            employe.userEmail.includes('wintifit')
                                        )
                                    "
                                    filled
                                    class="fullWidth px-3"
                                    label="offizielle Centerleiter E-Mail"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .centerManagerEmail
                                    "
                                    @blur="updateEmployee(employe)"
                                ></v-text-field>
                                <v-select
                                    filled
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .border
                                    "
                                    class="fullWidth px-3"
                                    :items="borderPossibilities"
                                    label="Rahmen"
                                    @blur="updateEmployee(employe)"
                                ></v-select>
                                <v-row no-gutters class="pa-0 pb-5">
                                    <v-col cols="8" class="pa-0">
                                        <v-text-field
                                            filled
                                            hide-details
                                            v-model="employe.blsAed"
                                            class="fullWidth px-3"
                                            label="BLS-AED gemacht am:"
                                            @blur="updateEmployee(employe)"
                                        ></v-text-field>
                                    </v-col>
                                    <input
                                        type="file"
                                        :ref="'fileInput' + employe._id"
                                        style="display: none"
                                        @change="onBLSFileChange($event, employe)"
                                        accept=".pdf,image/*"
                                    />
                                    <v-col cols="2" class="align-content-center">
                                        <v-btn
                                            fab
                                            x-small
                                            color="primary"
                                            @click="triggerBLSFileInput($event, employe)"
                                        >
                                            <v-icon color="white">mdi-file-upload</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="align-content-center">
                                        <v-btn
                                            fab
                                            x-small
                                            @click="downloadBLSAEDFile(employe.blsAedFile)"
                                            :disabled="!employe.blsAedFile"
                                        >
                                            <v-icon color="white">mdi-file-download</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-text-field
                                    :disabled="!userService.hasRight('tt_set_percentage')"
                                    filled
                                    class="fullWidth px-3"
                                    label="Stellenprozent (Statistiken)"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .percentage
                                    "
                                    @blur="updateEmployee(employe)"
                                ></v-text-field>

                                <v-text-field
                                    v-if="!employe.noFixPercentage"
                                    filled
                                    :disabled="!userService.hasRight('tt_targetHours')"
                                    class="fullWidth px-3"
                                    @blur="updateEmployee(employe)"
                                    label="Stellenprozent (Zeiterfassung)"
                                    v-model="employe.timeTrackerPercentage"
                                ></v-text-field>
                                <v-text-field
                                    v-if="!employe.noFixPercentage"
                                    filled
                                    :disabled="!userService.hasRight('tt_targetHours')"
                                    class="fullWidth px-3"
                                    @blur="updateEmployee(employe)"
                                    label="Überzeit in Stunden (Zeiterfassung)"
                                    v-model="employe.overTime"
                                ></v-text-field>
                                <v-checkbox
                                    label="Zeiterfassung ohne Stellenprozent"
                                    @change="updateEmployee(employe)"
                                    v-model="employe.noFixPercentage"
                                ></v-checkbox>
                                <v-text-field
                                    filled
                                    number
                                    class="fullWidth px-3"
                                    label="Ferienstunden"
                                    :suffix="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .vacationHours
                                            ? ' (' +
                                              Math.round(
                                                  (employe.studio.find(
                                                      (std) => std._id === studio._id || std.team === studio._id
                                                  ).vacationHours /
                                                      ((8.4 / 100) * getPercentageForEmployee(employe, studio))) *
                                                      100
                                              ) /
                                                  100 +
                                              ' Tage)'
                                            : ''
                                    "
                                    :disabled="!userService.hasRight('tt_targetHours')"
                                    @blur="updateEmployee(employe)"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .vacationHours
                                    "
                                ></v-text-field>

                                <v-text-field
                                    number
                                    filled
                                    class="fullWidth px-3"
                                    label="Sortierung"
                                    @blur="updateEmployee(employe)"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .sort
                                    "
                                ></v-text-field>
                                <v-checkbox
                                    v-if="employe.studio.length > 1"
                                    label="Hauptstudio (Zeiterfassung)"
                                    @change="updateEmployee(employe)"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .mainStudio
                                    "
                                ></v-checkbox>

                                <v-checkbox
                                    v-if="employe.studio.find((std) => std.team)"
                                    label="Kann sich selbst einchecken?"
                                    :disabled="!userService.hasRight('tt_targetHours')"
                                    @change="updateEmployee(employe)"
                                    v-model="employe.canSelfCheckin"
                                ></v-checkbox>
                                <v-checkbox
                                    label="Mitarbeiter verstecken?"
                                    @change="updateEmployee(employe)"
                                    v-model="
                                        employe.studio.find((std) => std._id === studio._id || std.team === studio._id)
                                            .hide
                                    "
                                ></v-checkbox>
                                <div v-if="employe.studio.length > 1">
                                    <v-card-title>Weitere Teams</v-card-title>
                                    <v-row class="mb-2">
                                        <v-col
                                            cols="auto"
                                            :key="'other-studios' + index"
                                            v-for="(std, index) of employe.studio.filter(
                                                (empStudio) =>
                                                    empStudio._id !== studio._id && empStudio.team !== studio._id
                                            )"
                                        >
                                            <v-chip :class="{ primary: employe.studio.length === 1 || std.mainStudio }">
                                                {{
                                                    std.team
                                                        ? storeStudios.find((stad) => stad._id === std.team)
                                                            ? storeStudios.find((stad) => stad._id === std.team).name
                                                            : 'Team'
                                                        : storeStudios.find((stad) => stad._id === std._id)
                                                        ? storeStudios.find((stad) => stad._id === std._id).name
                                                        : 'Studio'
                                                }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-card-actions class="d-flex justify-space-between px-3">
                                    <!--	<v-btn v-if="!employe.edit" color="blue" class="white--text" @click="employe.edit = true">Bearbeiten</v-btn>
						<v-btn v-else color="primary" class="white--text" @click="saveForm(employe)">Speichern</v-btn> -->
                                    <v-btn @click="deleteEmployee(employe, studio._id)" class="white--text" color="red">
                                        Löschen
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Neues Team hinzufügen
                    <v-icon style="justify-content: left" color="primary">mdiadd</v-icon>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field label="Titel" v-model="newTeam.name"></v-text-field>
                    <v-select
                        label="Sichtbar für Studio"
                        :items="
                            storeStudios.map((studio) => {
                                return { text: studio.name, value: studio._id }
                            })
                        "
                        v-model="newTeam.studio"
                    ></v-select>
                    <v-btn color="primary" @click="createNewTeam()">Erstellen</v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row class="">
            <!-- 

        <v-col style="margin-top: 60px" cols="12" md="6" lg="4" xl="3">
                <v-card
                    dark
                    v-if="addActive"
                    hover
                    class="employee-manager__card d-flex flex-column fill-height justify-space-between rounded-xl align-center">
                    <v-avatar
                        class="employee-manager__card-avatar"
                        :class="{
                            'gold-border': user && user.border === 'gold',
                            'blue-border': user && user.border === 'blue',
                            'green-border': user && user.boder === 'green',
                        }"
                        color="grey"
                        size="125">
                    </v-avatar>

                    <user-search
                        v-if="!loaded"
                        style="display: unset !important; margin-top: 70px"
                        label="Benutzer auswählen"
                        @update="pickUser"></user-search>


                    <v-text-field
                        filled
                        v-if="loaded"
                        class="fullWidth px-3"
                        style="margin-top: 70px"
                        label="angezeigter Name"
                        v-model="user.fullName"></v-text-field>
                    <v-text-field
                        filled
                        v-if="loaded"
                        class="fullWidth px-3"
                        label="Titel"
                        v-model="user.title"></v-text-field>
                    <v-text-field
                        filled
                        v-if="loaded"
                        class="fullWidth px-3"
                        label="Stellenprozent"
                        v-model="user.percentage"></v-text-field>
                    <v-text-field
                        filled
                        v-if="loaded"
                        number
                        class="fullWidth px-3"
                        label="Ferientage"
                        v-model="user.vacationdays"></v-text-field>
                    <v-select
                        filled
                        v-if="loaded"
                        v-model="user.border"
                        class="fullWidth px-3"
                        :items="borderPossibilities"
                        label="Rahmen"></v-select>
                    <v-card-actions class="d-flex justify-space-between px-3">
                        <v-btn color="grey" @click="abortForm" outlined>Abbrechen</v-btn>

                        <v-btn v-if="loaded" @click="saveForm" color="primary">Speichern</v-btn>
                    </v-card-actions>
                </v-card>

                <v-card
                    v-else
                    dark
                    hover
                    class="rounded-xl employee-manager__card d-flex fill-height flex-column justify-content-center align-center">
                    <v-avatar class="employee-manager__card-avatar" color="grey" size="125"></v-avatar>
                    <v-btn @click="addActive = true" color="primary" class="" style="position: absolute; bottom: 50px"
                        >Hinzufügen</v-btn
                    >
                </v-card>
            </v-col>
        --></v-row>
        <v-snackbar v-model="snackbar" color="primary">Gespeichert</v-snackbar>
        <studio-dashboard-employee-target-hours-dialog
            v-if="targetHoursStudio || targetHoursTeam"
            :studio="targetHoursStudio"
            :team="targetHoursTeam"
            :allStudios="studios.filter((std) => std.code)"
            @closeDialog="
                () => {
                    targetHoursStudio = null
                    targetHoursTeam = null
                }
            "
        ></studio-dashboard-employee-target-hours-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import userService from '../../../services/userService'
import userSearch from '../../../components/userSearch.vue'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import studioDashboardEmployeeTargetHoursDialog from './studioDashboardEmployeeTargetHoursDialog.vue'

export default {
    name: 'studioDashboardEmployee',
    props: {
        studios: {
            type: Array,
            required: true,
        },
    },
    components: {
        userSearch,
        'slim-cropper': Slim,
        studioDashboardEmployeeTargetHoursDialog,
    },
    data() {
        return {
            userService,
            targetHoursStudio: null,
            targetHoursTeam: null,
            employeeToUpdate: null,
            employee: [],
            newTeam: {
                name: '',
                targetHours: [],
            },
            loaded: false,
            snackbar: false,
            addActive: false,
            user: null,
            borderPossibilities: [
                { text: 'Gold', value: 'gold' },
                { text: 'Grün', value: 'green' },
                { text: 'Blau', value: 'blue' },
            ],
        }
    },

    mounted() {},
    async created() {
        console.log(this.studios)
        if (!this.employee.length) {
            this.employee = await api.fetchAllEmployee(this.studios.map((studio) => studio._id))

            this.$emit('finishedloading')
        }
    },
    watch: {
        async studios() {
            this.employee = await api.fetchAllEmployee(this.studios.map((studio) => studio._id))
            console.log(this.studios)
            this.$emit('finishedloading')
        },
    },
    methods: {
        async downloadBLSAEDFile(fileName) {
            try {
                const response = await api.downloadBLSAEDFile(fileName)
                this.$toast.success('Download wird gestartet')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Download')
            }
        },
        async downloadBLSListFileZip() {
            try {
                const response = await api.downloadBLSAEDZip()
                this.$toast.success('Download wird gestartet')
            } catch (e) {
                this.$toast.error('Fehler beim Downloaded des ZIP Ordners')
            }
            console.log('meh')
        },
        async onBLSFileChange(event, emp) {
            let selectedFile = event.target.files[0]
            console.log(selectedFile.name)
            let newFileName = emp.fullName + '_' + this.$moment().format('DD.MM.YYYY')
            let newFile = new File([selectedFile], newFileName, {
                type: selectedFile.type,
                lastModified: selectedFile.lastModified,
            })

            if (!selectedFile) {
                this.$toast.error('Keine Datei ausgewählt')
                return
            }
            console.log('hey')
            const formData = new FormData()
            formData.append('file', newFile)
            formData.append('user', emp.fullName)
            console.log('formData', formData)
            try {
                const response = await api.uploadBLSAEDFile(formData)
                console.log(response)
                emp.blsAedFile = response.name
                await this.updateEmployee(emp)
                this.$toast.success('Datei erfolgreich hochgeladen!')
            } catch (error) {
                console.log(error)
                this.$toast.error('Fehler beim Hochladen der Datei!')
            }
        },
        triggerBLSFileInput(event, emp) {
            this.$refs['fileInput' + emp._id][0].click()
        },
        async downloadBLSList() {
            try {
                await api.downloadBLSList()
            } catch (e) {
                this.$toast.error('Fehler beim Herunterladen der Liste')
            }
        },
        getPercentageForEmployee(employe, studio) {
            return employe.timeTrackerPercentage
                ? employe.timeTrackerPercentage
                : employe.studio.find((std) => std._id === studio._id || std.team === studio._id).percentage
        },
        async deleteTeam(team) {
            let teamMembers = this.employee.filter((employe) =>
                employe.studio.find((studio) => studio.team && studio.team.toString() === team.toString())
            )
            if (teamMembers.length) {
                alert('Team kann nicht gelöscht werden, da noch Mitarbeiter im Team sind')
                return
            }
            if (confirm('Möchten Sie das Team wirklich löschen?')) {
                await api.deleteTeam(team)
                location.reload()
            }
        },
        vacationDaysForMonth(studio) {
            if (!studio.vacationDays || !studio.vacationDays.length) return []
            return studio.vacationDays.filter(
                (day) => this.$moment().format('YYYY-MM') === this.$moment(day.date).format('YYYY-MM')
            )
        },
        async createNewTeam() {
            await api.createTeam(this.newTeam)
            alert('Team wurde erstellt. Seite wird nun neu geladen')
            location.reload()
            this.newTeam = {
                name: '',
                studio: '',
                targetHours: [],
            }
        },
        employeeForStudio(studio) {
            return this.employee.filter((employe) =>
                employe.studio.find((std) => {
                    return (
                        (std._id && studio._id && std._id.toString() === studio._id.toString()) ||
                        (std.team && studio._id && std.team.toString() === studio._id.toString())
                    )
                })
            )
        },
        changeSorting(employe, newValue) {
            this.employee.forEach((emp, index) => {
                if (emp._id === employe._id) {
                    this.employee[index].sort = newValue
                } else if (emp.sort) {
                    if (emp.sort >= newValue) {
                        this.employee[index].sort = emp.sort + 1
                    }
                }
            })
            api.updateEmployeeSorting(this.employee)
        },
        async updateEmployee(employee) {
            let returnedEmployee = await api.updateEmployee(employee)
            this.snackbar = true
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'pfp'
            uploadimg._id = this.employeeToUpdate
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.employee.find((employe) => {
                            if (employe._id === this.employeeToUpdate._id) {
                                employe.picture = data.name
                            }
                        })
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
        employeeInEdit(employee) {
            employee.editPic = true
            this.$forceUpdate()
            this.employeeToUpdate = employee._id
        },
        abortForm() {
            this.addActive = false
            this.user = null
            this.loaded = false
        },
        async pickUser(user_id) {
            let response = await api.getUserById(user_id)
            this.user = response.user
            this.user.fullName = this.user.vorname + ' ' + this.user.nachname
            this.loaded = true
        },
        async saveForm(studio) {
            studio.code ? (this.user.studio = studio._id) : (this.user.team = studio._id)
            let savedUser = await api.createEmployee(this.user)
            // this.employee.push(savedUser)
            this.abortForm()
        },
        async deleteEmployee(employee, studio) {
            let deleted = await api.deleteEmployee(employee._id, studio)
            if (deleted.ok === 1) {
                this.employee = this.employee.filter((emp) => emp._id !== employee._id)
            }
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        calcBusinessDays() {
            var day = this.$moment().startOf('month')
            var businessDays = 0

            while (day.isSameOrBefore(this.$moment().endOf('month'), 'day')) {
                if (day.day() != 0 && day.day() != 6) businessDays++
                day.add(1, 'd')
            }
            return businessDays
        },
        sortItems() {
            let items = []
            this.employee.forEach((emp, index) => items.push(index + 1))
            return items
        },
        slimOptions() {
            return {
                ratio: '83:92',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
                initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.blue-border {
    border: 5px solid blue !important;
}
.green-border {
    border: 5px solid green !important;
}
.gold-border {
    border: 5px solid gold !important;
}
.fullWidth {
    width: 100%;
}
.employee-manager {
    &__card {
        min-height: 200px;
        &-avatar {
            position: absolute;
            top: -62px;
        }
        &-add-button {
            width: 60%;
        }
    }
}
</style>
