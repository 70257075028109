<template>
    <v-dialog persistent :value="visible" transition="dialog-bottom-transition" max-width="600">
        <v-card>
            <v-toolbar color="primary" dark>Antrag zur Prüfung eines Vertragsunterbruchs</v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">Kundeninformationen</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">Timestop</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 3" step="3">Anhänge</v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4">Abschluss</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card color="transparent" elevation="0">
                            <v-row no-gutters v-if="!customerInfos.selection">
                                <v-col cols="12" class="px-0 mt-5">
                                    <user-search
                                        :value="creatorInfos.selection"
                                        :disabled="viewOnly"
                                        ignoreRights
                                        :key="creatorInfos.selection"
                                        instructor
                                        label="Visum (Ersteller)"
                                        @update="updateCreator"
                                    ></user-search>
                                </v-col>
                                <v-col v-show="!customerInfos.selection" cols="12" class="px-0 mt-5">
                                    <user-search
                                        :value="customerInfos.selection"
                                        :disabled="viewOnly || creatorInfos === null"
                                        ignoreRights
                                        label="Kunde"
                                        :key="customerInfos.selection"
                                        @update="updateCustomer"
                                    ></user-search>
                                </v-col>
                            </v-row>
                            <v-row v-if="customerInfos.selection">
                                <v-col cols="12" class="d-flex justify-end">
                                    <v-btn class="mx-0" v-if="!viewOnly" color="primary" @click="resetCustomer">
                                        Kunde ändern
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="customerInfos.selection">
                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Mitgliedernummer"
                                        type="text"
                                        class="my-0 py-0"
                                        v-model="customerInfos.number"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-select
                                        :disabled="viewOnly"
                                        label="Anrede"
                                        :items="[
                                            { name: 'Herr', value: 'm' },
                                            { name: 'Frau', value: 'w' },
                                        ]"
                                        item-value="value"
                                        item-text="name"
                                        class="my-0 py-0"
                                        v-model="customerInfos.gender"
                                    ></v-select>
                                </v-col>

                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Vorname"
                                        class="my-0 py-0"
                                        v-model="customerInfos.firstName"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Nachname"
                                        class="my-0 py-0"
                                        v-model="customerInfos.lastName"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        @change="updateCustomerBirthday"
                                        label="Geburtstag"
                                        type="date"
                                        class="my-0 py-0"
                                        v-model="customerInfos.birthday"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Telefon"
                                        class="my-0 py-0"
                                        v-model="customerInfos.phone"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="E-Mail"
                                        type="email"
                                        class="my-0 py-0"
                                        v-model="customerInfos.email"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Strasse"
                                        class="my-0 py-0"
                                        v-model="customerInfos.street"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="pt-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        type="number"
                                        label="Postleitzahl"
                                        class="my-0 py-0"
                                        v-model="customerInfos.zip"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pt-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Stadt"
                                        class="my-0 py-0"
                                        v-model="customerInfos.city"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-btn @click="closeForm" text>Abbrechen</v-btn>
                        <!---  :disabled=" !customerInfos.selection || !creatorInfos.selection" --->
                        <v-btn color="primary" @click="e1 = 2">Weiter</v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card class="mb-12" color="transparent" elevation="0">
                            <v-row>
                                <v-col class="pb-0" cols="6">
                                    <v-text-field
                                        v-model="timestopInfos.start"
                                        locale="de-DE"
                                        type="date"
                                        label="Beginn"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pb-0" cols="6">
                                    <v-text-field v-model="timestopInfos.end" type="date" label="Ende"></v-text-field>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        v-model="timestopInfos.reason"
                                        label="Grund des Unterbruchs"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        v-model="timestopInfos.duration"
                                        label="Gesamtdauer"
                                        type="number"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-radio-group row v-model="timestopInfos.durationSelection">
                                        <v-radio key="months" label="Monat/e" :value="'months'"></v-radio>
                                        <v-radio key="weeks" label="Wochen" :value="'weeks'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-text-field v-model="timestopInfos.fee" label="Gebühr"></v-text-field>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        v-model="timestopInfos.timeAndPlace"
                                        label="Ort, Datum"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-btn text @click="e1--">Zurück</v-btn>

                        <!--- :disabled="infoPageRules" -->
                        <v-btn color="primary" @click="e1 = 3">Weiter</v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card class="mb-12" color="transparent" elevation="0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="upload-section">
                                        <v-col cols="12" class="mb-0">
                                            <v-file-input
                                                multiple
                                                @change="uploadImagesTimestops"
                                                label="Bilder auswählen"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="timestopInfos.gallery && timestopInfos.gallery.length > 0">
                                        <v-col cols="12">
                                            <v-window v-model="activeIndexTimestops">
                                                <v-window-item
                                                    v-for="(image, index) in timestopInfos.gallery"
                                                    :key="`card-${index}`"
                                                >
                                                    <v-card color="transparent" height="200">
                                                        <v-row class="fill-height" align="center" justify="center">
                                                            <v-col align="center" cols="12">
                                                                <v-img
                                                                    contain
                                                                    class="mx-auto"
                                                                    v-if="image.dataUrl"
                                                                    height="150px"
                                                                    max-width="200px"
                                                                    :src="image.dataUrl"
                                                                    alt="Uploaded Image"
                                                                ></v-img>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-window-item>
                                            </v-window>
                                            <v-card-actions class="justify-center">
                                                <v-item-group
                                                    v-model="activeIndexTimestops"
                                                    class="text-center"
                                                    mandatory
                                                >
                                                    <v-item
                                                        v-for="n in timestopInfos.gallery.length"
                                                        :key="`btn-${n}`"
                                                        v-slot="{ active, toggle }"
                                                    >
                                                        <v-btn :input-value="active" icon @click="toggle">
                                                            <v-icon>mdi-record</v-icon>
                                                        </v-btn>
                                                    </v-item>
                                                </v-item-group>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>

                                    <!-- Gallery Section -->
                                    <v-row v-if="false" class="gallery-section">
                                        <v-col cols="12">
                                            <div
                                                ref="scrollContainer"
                                                @scroll="updateActiveIndexPackages"
                                                style="
                                                    overflow-x: scroll;
                                                    white-space: nowrap;
                                                    scroll-snap-type: x mandatory;
                                                "
                                            >
                                                <v-card
                                                    elevation="0"
                                                    transparent
                                                    v-for="(image, index) in timestopInfos.gallery"
                                                    :key="index"
                                                    ref="cards"
                                                    style="
                                                        display: inline-block;
                                                        scroll-snap-align: center;
                                                        width: 100%;
                                                    "
                                                >
                                                    <v-img
                                                        contain
                                                        max-height="200px"
                                                        :src="'/api/' + image.src"
                                                        alt="Uploaded Image"
                                                    ></v-img>

                                                    <v-card-actions>
                                                        <v-btn
                                                            class="mx-auto"
                                                            @click="deleteImageTimestops(index)"
                                                            color="error"
                                                        >
                                                            Löschen
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col class="mb-5" cols="12" style="text-align: center">
                                            <span
                                                v-for="(image, index) in timestopInfos.gallery"
                                                :key="'dot-' + index"
                                                @click="scrollToIndexPackages(index)"
                                                :class="['dot', { active: activeIndexPackages === index }]"
                                            ></span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-btn text @click="e1--">Zurück</v-btn>
                        <v-btn color="primary" @click="e1 = 4">Weiter</v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                        <v-card class="mb-12" color="transparent" elevation="0">
                            <v-card-text>{{ formattedTimestopText }}</v-card-text>

                            <signature-pad
                                class="mx-auto"
                                :signature-data="customerInfos.signature"
                                @startDrawing="signature = true"
                                @resetDrawing="resetSignature"
                                @saveSignature="saveSignature"
                                ref="signatureConditions"
                            ></signature-pad>
                        </v-card>

                        <v-btn text @click="e1--">Zurück</v-btn>
                        <v-btn color="primary" @click="saveEntry()">Speichern</v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>

<script>
import FormWizard from '../../../components/vueStepWizard/FormWizard.vue'
import userSearch from '../../../components/userSearch.vue'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import api from '@/api'
export default {
    components: { FormWizard, userSearch, signaturePad },
    data() {
        return {
            activeIndexTimestops: 0,
            signature: false,
            timestopText: '',
            e1: 1,
            timestopInfos: {
                gallery: [],
                start: null,
                end: null,
                reason: null,
                duration: null,
                durationSelection: null,
                fee: null,
                timeAndPlace: null,
            },
            creatorInfos: { selection: null },
            customerInfos: { selection: null, signature: null },
            selectedFile: null,
            uploadStatus: '',
            imageSources: {},
            timestopFormId: null,
        }
    },
    created() {},
    methods: {
        deleteImageTimestops(index) {
            this.timestopInfos.gallery.splice(index, 1)
            this.$forceUpdate()
        },

        uploadImagesTimestops(files) {
            console.log('uploading image')
            for (let file of files) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    const formData = new FormData()
                    formData.append('file', file)

                    api.uploadTimestopImage(formData)
                        .then((response) => {
                            const uploadedImageName = response.name

                            console.log(this.timestopInfos.gallery)
                            if (!this.timestopInfos.gallery) this.timestopInfos.gallery = []
                            this.timestopInfos.gallery.push({
                                title: '',
                                src: uploadedImageName,
                                dataUrl: e.target.result,
                            })

                            this.$forceUpdate()
                        })
                        .catch((error) => {
                            console.log('Upload failed', error)
                        })
                }
                reader.readAsDataURL(file)
            }
        },
        uploadImagesPackage(files) {
            for (let file of files) {
                const formData = new FormData()
                formData.append('file', file)

                api.uploadTimestopImage(formData)
                    .then((response) => {
                        const uploadedImageName = response.name

                        if (!this.timestopInfos.gallery) this.timestopInfos.gallery = []
                        this.timestopInfos.gallery.push({ title: '', src: uploadedImageName })
                        this.$forceUpdate()
                    })
                    .catch((error) => {
                        console.log('Upload failed', error)
                    })
            }
        },
        resetSignature() {
            this.signature = false
            this.customerInfos.signature = null
        },
        async saveEntry() {
            this.$refs.signatureConditions.saveSignature()
            let entry = {
                creatorInfos: this.creatorInfos,
                customerInfos: this.customerInfos,
                timestopInfos: this.timestopInfos,
                timestopFormId: this.timestopFormId,
                status: 'created',
            }

            try {
                await api.saveTimestopEntry(entry)
                this.$toast.success('Eintrag erfolgreich gespeichert')
                this.$emit('onSuccess')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim abspeichern des Eintrags')
            }
            console.log(entry)
        },

        saveSignature(e) {
            this.customerInfos.signature = e
        },
        closeForm() {
            this.$emit('onClose')
        },
        updateCustomerBirthday() {},
        resetCustomer() {
            this.customerInfos = { selection: null, signature: null }
        },
        async updateCreator(id) {
            let contractCreator = await api.getUserForManager(id)
            let contractCreatorInfos = {
                selection: id,
                firstName: contractCreator.vorname,
                lastName: contractCreator.nachname,
                email: contractCreator.email,
            }

            this.creatorInfos = contractCreatorInfos

            this.$forceUpdate()
        },

        async updateCustomer(id) {
            let customer = await api.getUserForManager(id)
            let customerEntry = {
                selection: id,
                gender: customer.geschlecht,
                firstName: customer.vorname,
                lastName: customer.nachname,
                email: customer.email,
                phone: customer.telefon,
                birthday: customer.geburtstag,
                street: customer.adresse_1,
                city: customer.stadt,
                zip: customer.plz,
                signature: null,
            }

            //this.resetLegalRepresentative()

            this.customerInfos = customerEntry

            /*if (!this.checkIfBirthdayIsValid(this.fieldEntries.customer.infos.birthday)) {
                this.$toast.error('Bitte gib ein gültiges Geburtsdatum ein')
            }*/

            this.$forceUpdate()
        },
    },
    props: {
        viewOnly: {
            type: Boolean,
            default: false,
        },

        newEntry: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
        },
        visible: {
            type: Boolean,
        },
    },
    computed: {
        infoPageRules() {
            // Überprüfen, ob die Start- und Enddaten vorhanden und korrekt sind
            if (
                !this.timestopInfos.start ||
                !this.timestopInfos.end ||
                new Date(this.timestopInfos.start) > new Date(this.timestopInfos.end)
            ) {
                return true
            }

            // Überprüfen, ob die restlichen Felder ausgefüllt sind
            if (
                !this.timestopInfos.reason ||
                !this.timestopInfos.duration ||
                !this.timestopInfos.durationSelection ||
                !this.timestopInfos.fee ||
                !this.timestopInfos.timeAndPlace
            ) {
                return true
            }

            // Wenn bis hierher alles in Ordnung ist, ist die Validierung erfolgreich
            return false
        },
        formattedTimestopText() {
            if (this.timestopText) {
                return this.timestopText
            } else return ''
        },
    },
    watch: {
        async visible() {
            if (this.visible) {
                this.e1 = 1
            }
            let text = await api.fetchTimestopTextStudio()
            if (text && text.text && text._id) {
                this.timestopFormId = text._id
                this.timestopText = text.text
            } else {
                this.closeForm()
                this.$toast.error('Bitte erstelle zuerst einen Formulat Text.')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}
</style>