<template xmlns="">
    <v-window-item :value="exerciseIndex + 2" style="transition: none !important">
        <!-- 
      <div v-intersect="onIntersect" style="position: absolute; left: 10vw; width: 80vw; height: 10px; top: 50vh"></div>

     -->
        <image-overlapping-card
            :noDialog="true"
            :rightIcon="editButton"
            :leftIcon="deleteButton"
            @left-clicked="deleteExercise"
            @right-clicked="clickedEditIcon"
        >
            <template v-slot:image>
                <v-img
                    height="35vh"
                    v-if="
                        exercise.picture ||
                        (exercise && exercise.manual && exercise.manual[0] && exercise.manual[0].picture)
                    "
                    :src="exercise.picture ? getImageUrl(exercise.picture) : getImageUrl(exercise.manual[0].picture)"
                ></v-img>
            </template>
            <template v-slot:content>
                <v-row no-gutters class="formCard elevation-7 mb-4">
                    <v-col class="pa-0" cols="12">
                        <v-row no-gutters class="d-flex flex-row pt-5 pb-0 justify-center mx-0 mb-0">
                            <v-card-title
                                style="letter-spacing: 1px"
                                class="white--text h6 py-5 my-0 font-weight-bold text-uppercase"
                            >
                                {{ exercise.title }}
                            </v-card-title>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row
                    no-gutters
                    dense
                    class="workout-add-exercise__card-new-step--container mx-5 mt-3 elevation-7"
                    v-if="exercise.general_exercise_settings.length"
                >
                    <v-col class="pa-0" cols="12">
                        <v-row
                            no-gutters
                            class="workout-add-exercise__card-new-step--active workout-add-exercise__card-new-step d-flex flex-row mx-0 py-3 mt-0 mb-2"
                        >
                            <v-col cols="12" class="d-flex pa-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="ml-5 mr-3"
                                    :autoplay="false"
                                    src="/img/lottiefiles/settings/deviceManagement.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h6 mb-0 pa-0 py-3 font-weight-bold text-uppercase"
                                >
                                    Geräteeinstellungen
                                </v-card-title>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-row no-gutters class="px-5 py-2" v-if="getGeneralSettings.length">
                        <v-col
                            cols="auto"
                            class="pa-1"
                            :key="index + 'deviceSetting'"
                            v-for="(deviceSetting, index) in getGeneralSettings"
                        >
                            <v-card
                                class="mb-2"
                                :color="deviceSetting.value ? 'listitemcolor' : 'grey'"
                                @click="settingDialog(deviceSetting, $event, 'deviceSetting')"
                            >
                                <v-card-title class="pa-1" :class="{ subtitle: !deviceSetting.value }">
                                    {{ shorten(deviceSetting.text, 10) }}
                                </v-card-title>
                                <v-card-subtitle
                                    class="pa-0 text-center"
                                    :class="{
                                        'white--text h2 pa-0': !deviceSetting.value,
                                        'pa-2': deviceSetting.value,
                                    }"
                                >
                                    {{ deviceSetting.value ? getSettingText(deviceSetting) : '+' }}
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-2" v-else>
                        <span class="pb-2">Es wurden keine Geräteeinstellungen hinzugefügt.</span>
                    </v-row>
                </v-row>
                <v-row
                    no-gutters
                    v-for="(set, index) in exercise.sets"
                    :key="'added-step' + index"
                    dense
                    class="workout-add-exercise__card-new-step--container mx-5 mt-3"
                    :class="{ 'elevation-7': set.active }"
                    :style="[set.active ? { 'max-height': 'unset' } : { 'max-height': '50px' }]"
                >
                    <v-col class="pa-0" cols="12">
                        <v-row
                            no-gutters
                            class="workout-add-exercise__card-new-step d-flex flex-row mx-0 my-0"
                            :class="{ 'workout-add-exercise__card-new-step--active': set.active }"
                        >
                            <v-col cols="7" class="d-flex pa-1">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="ml-2 mr-3"
                                    :autoplay="false"
                                    src="/img/lottiefiles/set.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h6 mb-0 pa-0 font-weight-bold text-uppercase"
                                    :class="{ 'py-3': set.active }"
                                >
                                    Satz {{ index + 1 }}
                                </v-card-title>
                            </v-col>
                            <v-col cols="5" class="pa-0 pr-2" style="text-align: end">
                                <v-btn @click="toggleExistingStep(index)" icon>
                                    <v-icon :color="getStepIconColor(set.active)">
                                        {{ getStepIcon(set.active) }}
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="editModeActive" @click="copyExistingStep(index)" icon>
                                    <v-icon color="orange">
                                        {{ 'mdi-content-copy' }}
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="!set.active" @click="deleteStep(index)" icon>
                                    <v-icon color="red">
                                        {{ 'mdi-delete' }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-expand-transition>
                        <div v-if="set.activeBefore">
                            <v-row no-gutters class="px-5 pt-5" :class="{ 'pb-3': !editModeActive }">
                                <v-col
                                    cols="auto"
                                    class="pa-1"
                                    :key="setIndex + 'setSetting'"
                                    v-for="(setSetting, setIndex) in getSetSettings(set.settings)"
                                >
                                    <v-card
                                        :color="setSetting.value ? 'listitemcolor' : 'grey'"
                                        @click="settingDialog(setSetting, $event, 'editSetting', index)"
                                    >
                                        <v-card-title class="subitle pa-1 font-weight-bold" style="font-size: 1rem">
                                            {{ shorten(setSetting.text, 10) }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            class="text-center"
                                            :class="{
                                                'white--text h2 pa-0': !setSetting.value,
                                                'pa-2': setSetting.value,
                                            }"
                                        >
                                            {{ setSetting.value ? getSettingText(setSetting) : '+' }}
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expand-transition>
                </v-row>

                <v-row
                    no-gutters
                    v-if="exercise.set_exercise_settings && exercise.set_exercise_settings.length && editModeActive"
                    dense
                    class="workout-add-exercise__card-new-step--container mx-5 mt-3"
                    :class="{ 'elevation-7': newStepActive }"
                >
                    <v-col class="pa-0" cols="12">
                        <v-row
                            no-gutters
                            class="workout-add-exercise__card-new-step d-flex flex-row mx-0 my-0"
                            :class="{ 'workout-add-exercise__card-new-step--active pt-3': newStepActive }"
                        >
                            <v-col cols="10" class="d-flex pa-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="ml-5 mr-3"
                                    :autoplay="false"
                                    src="/img/lottiefiles/settings/deviceManagement.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h6 mb-0 pa-0 font-weight-bold text-uppercase"
                                    :class="{ 'py-3': newStepActive }"
                                >
                                    Satz hinzufügen
                                </v-card-title>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                                <v-btn @click="toggleNewStepAnimation" icon>
                                    <v-icon :color="newStepActive ? 'red' : 'white'">
                                        {{ newStepActive ? 'mdi-close-circle' : 'mdi-plus-circle' }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-expand-transition>
                        <div v-show="newStepActiveBefore">
                            <v-row no-gutters class="px-5 pt-5">
                                <v-col
                                    cols="auto"
                                    class="pa-1"
                                    :key="index + 'setSetting'"
                                    v-for="(setSetting, index) in exercise.set_exercise_settings"
                                >
                                    <v-card
                                        :color="setSetting.value ? 'listitemcolor' : 'grey'"
                                        @click="settingDialog(setSetting, $event, 'setSetting')"
                                    >
                                        <v-card-title class="pa-1 subtitle font-weight-bold" style="font-size: 1rem">
                                            {{ shorten(setSetting.text, 10) }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            class="text-center pa-2"
                                            :class="{ 'white--text h2': !setSetting.value }"
                                        >
                                            {{ setSetting.value ? getSettingText(setSetting) : '+' }}
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expand-transition>
                </v-row>

                <v-dialog
                    @click:outside="closeSettingDialog()"
                    :retain-focus="false"
                    transition="scroll-y-reverse-transition"
                    v-model="settingDialogVisible"
                    fixed
                    content-class="fixKeyboardApple"
                >
                    <v-card>
                        <v-card-subtitle class="pb-0 pt-1" v-if="settingInEditMode && settingInEditMode.text">
                            {{ settingInEditMode.text }}
                        </v-card-subtitle>
                        <v-select
                            class="px-5"
                            v-model="settingInEditMode.value"
                            :label="settingInEditMode.text"
                            :items="settingInEditMode.selections"
                            v-if="settingInEditMode && settingInEditMode.input === 'selection'"
                            @keydown.enter.prevent="closeSettingDialog()"
                        ></v-select>
                        <v-text-field
                            v-if="settingInEditMode && settingInEditMode.input !== 'selection'"
                            autofocus
                            :type="settingInEditMode.input"
                            :label="settingInEditMode.text"
                            class="px-5"
                            v-model="settingInEditMode.value"
                            @keydown.enter.prevent="closeSettingDialog()"
                        ></v-text-field>
                    </v-card>
                </v-dialog>
            </template>
        </image-overlapping-card>
    </v-window-item>
</template>

<script>
import api from '../../api'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SwiperSlide } from 'vue-awesome-swiper'
import imageOverlappingCard from '../../components/imageOverlappingCard.vue'

export default {
    name: 'WorkoutStepEditable',
    components: {
        SwiperSlide,
        imageOverlappingCard,
    },
    props: {
        addState: {
            type: Number,
            default: 0,
        },
        propExercise: {
            type: Object,
            required: true,
        },
        exerciseIndex: {
            type: Number,
            required: true,
        },
        displayContent: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            editModeActive: false,
            deleteButton: {
                color: 'white',
                background: 'red',
                icon: 'mdi-delete',
            },
            exerciseBackup: null,
            exercise: {
                title: '',
                general_exercise_settings: [],
                set_exercise_settings: [],
                sets: [],
                manual: [],
            },
            isIntersecting: false,

            searchString: '',
            selectActive: true,
            selectedExercise: null,
            exercises: [],
            filterActive: false,
            settingDialogVisible: false,
            activeSetting: '',
            settingInEditMode: null,
            newStepActive: false,
            newStepActiveBefore: false,
            sets: [],
            exerciseSetSettings: null,
            editStepBackup: null,
            initialised: false,
            openStepIndex: null,
        }
    },
    created() {
        let realExercise = this.exerciseList.find((exercise) => exercise._id === this.propExercise.exercise_id)
        if (realExercise && realExercise.general_exercise_settings) {
            realExercise.general_exercise_settings.forEach((setting, index) => {
                if (setting && setting.selections && this.propExercise.general_exercise_settings[index]) {
                    this.propExercise.general_exercise_settings[index].selections = setting.selections
                }
            })
        }
        this.exercise = JSON.parse(JSON.stringify(this.propExercise))
        this.exerciseBackup = JSON.parse(JSON.stringify(this.propExercise))

        if (this.exercise.set_exercise_settings) {
            this.exercise.set_exercise_settings.sort((a, b) => a.text.localeCompare(b.text))
            this.exerciseSetSettings = JSON.parse(JSON.stringify(this.exercise.set_exercise_settings))
        }
    },
    methods: {
        ...mapActions('navigationStore', ['setSelectedNavigation']),
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNavigationbarVisibility']),
        ...mapActions('workingoutStore', [
            'setWorkoutCreateDefaultValues',
            'updateExerciseInWorkoutCreateExercises',
            'removeExerciseByIndex',
        ]),
        ...mapActions('deviceStore', ['setDeviceList']),
        onIntersect(entries, observer) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            this.isIntersecting = entries[0].isIntersecting
            console.log('bechumi do was?', this.exercise.title)
        },

        closeSettingDialog() {
            this.setNavigationbarVisibility(true)
            this.settingDialogVisible = false
        },
        copyExistingStep(index) {
            this.exercise.sets.push(JSON.parse(JSON.stringify(this.exercise.sets[index])))
        },
        deleteExercise() {
            let newState = this.addState
            this.removeExerciseByIndex(this.exerciseIndex)
            if (this.workoutCreateExercises[this.exerciseIndex]) newState = this.exerciseIndex + 2
            this.$emit('updateBottomNavigation', newState)
        },
        getSetSettings(settings) {
            if (this.editModeActive) return settings
            if (settings) return settings.filter((setting) => setting.value)
        },
        clickedEditIcon() {
            if (this.editModeActive) {
                this.editModeActive = false
                this.exercise = JSON.parse(JSON.stringify(this.exerciseBackup))
                this.setNavigationBarText(null)
                this.$emit('updateBottomNavigation', this.exerciseIndex + 2)
                this.initialised = false
            } else {
                this.editModeActive = true
                this.setNavigationBarText({ text: 'Speichern', state: false, endPoint: this.propExercise._id + 'save' })
            }
        },
        getStepIconColor(active) {
            if (this.editModeActive) return active ? 'red' : 'blue'
            return 'white'
        },
        getStepIcon(active) {
            if (this.editModeActive) return active ? 'mdi-close-circle' : 'mdi-pencil-circle'
            return 'mdi-alert-circle-outline'
        },
        deleteStep(index) {
            this.exercise.sets.splice(index, 1)
        },
        saveStepEdit() {
            this.setWorkoutCreateDefaultValues(
                JSON.parse(JSON.stringify(this.exercise.sets[this.openStepIndex].settings))
            )
            this.toggleExistingStep(this.openStepIndex, true)
        },
        saveNewStep() {
            this.toggleNewStepAnimation()
            this.setWorkoutCreateDefaultValues(JSON.parse(JSON.stringify(this.exercise.set_exercise_settings)))
            this.exercise.sets.push({
                settings: JSON.parse(JSON.stringify(this.exercise.set_exercise_settings)),
                active: false,
                activeBefore: false,
            })
        },
        toggleExistingStep(index, save = false) {
            if (this.openStepIndex !== null && this.openStepIndex !== index) {
                this.existingStepActiveBeforeToggle(this.openStepIndex)
                this.existingStepActiveToggle(this.openStepIndex)
                //setTimeout(this.existingStepActiveToggle, 150, this.openStepIndex)
                this.openStepIndex = index
            } else if (this.openStepIndex === index) {
                this.openStepIndex = null
            } else {
                this.openStepIndex = index
            }
            if (this.exercise.sets[index].active) {
                this.setNavigationBarText({ text: 'Speichern', state: false, endPoint: this.propExercise._id + 'save' })
                this.existingStepActiveBeforeToggle(index)
                this.existingStepActiveToggle(index)
                //setTimeout(this.existingStepActiveToggle, 150, index)
                if (!save) this.exercise.sets[index].settings = JSON.parse(JSON.stringify(this.editStepBackup))
            } else {
                if (this.exercise.sets[index].active) {
                    this.setNavigationBarText({
                        text: 'Satz überarbeiten',
                        state: false,
                        endPoint: this.propExercise._id + 'saveStepEdit',
                    })
                }
                this.editStepBackup = JSON.parse(JSON.stringify(this.exercise.sets[index].settings))
                this.existingStepActiveToggle(index)
                this.existingStepActiveBeforeToggle(index)
            }
        },
        existingStepActiveToggle(index) {
            this.exercise.sets[index].active = !this.exercise.sets[index].active
        },
        existingStepActiveBeforeToggle(index) {
            this.exercise.sets[index].activeBefore = !this.exercise.sets[index].activeBefore
        },
        toggleNewStepAnimation() {
            if (this.newStepActive) {
                this.setNavigationBarText({ text: 'Speichern', state: false, endPoint: this.propExercise._id + 'save' })

                this.newStepActiveBeforeToggle()
                this.newStepActiveToggle()
                //setTimeout(this.newStepActiveToggle, 150)
            } else {
                this.setNavigationBarText({
                    text: 'Satz speichern',
                    state: false,
                    endPoint: this.propExercise._id + 'saveNewStep',
                })

                let tempSetExerciseSettings = JSON.parse(JSON.stringify(this.exerciseSetSettings))
                tempSetExerciseSettings.forEach((setting) => {
                    let savedValue = this.workoutCreateDefaultValues[setting.text]
                    if (savedValue) setting.value = savedValue
                })
                this.exercise.set_exercise_settings = tempSetExerciseSettings
                this.newStepActiveToggle()
                this.newStepActiveBeforeToggle()
                //setTimeout(this.newStepActiveBeforeToggle, 75)
            }
        },
        newStepActiveToggle() {
            this.newStepActive = !this.newStepActive
        },
        newStepActiveBeforeToggle() {
            this.newStepActiveBefore = !this.newStepActiveBefore
        },
        getSettingText(setting) {
            let text = setting.value
            if (setting.text === 'Gewicht') text = text + ' KG'

            return text
        },
        settingDialog(setting, $event, type, index = null) {
            console.log('opening')
            type === 'editSetting' ? (this.activeSetting = index) : (this.activeSetting = type)
            $event.preventDefault()
            this.settingDialogVisible = false
            this.x = $event.clientX
            this.y = $event.clientY
            this.$nextTick(() => {
                this.setNavigationbarVisibility(false)
                this.settingDialogVisible = true
            })
            this.settingInEditMode = JSON.parse(JSON.stringify(setting))
        },
        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        getImageUrl(image) {
            return '/img/uebungen/' + image.replace(/\.[^/.]+$/, '') + '.webp'

            return '/img/uebungen/' + image
        },
    },
    computed: {
        ...mapState('workingoutStore', ['defaultValues', 'workoutCreateExercises', 'workoutCreateDefaultValues']),
        ...mapState('navigationStore', ['selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationBarText']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
        ...mapGetters('deviceStore', ['exerciseList']),
        editButton() {
            return this.editModeActive
                ? { color: 'white', background: 'red', icon: 'mdi-close' }
                : { color: 'white', background: 'blue', icon: 'mdi-pencil' }
        },
        getGeneralSettings() {
            if (this.editModeActive) return this.exercise.general_exercise_settings
            return this.exercise.general_exercise_settings.filter((setting) => setting.value)
        },
    },
    watch: {
        navigationBarTextState(value) {
            if (
                this.navigationBarEndPoint === this.propExercise._id + 'save' ||
                this.navigationBarEndPoint === this.propExercise._id + 'saveNewStep' ||
                this.navigationBarEndPoint === this.propExercise._id + 'saveStepEdit'
            ) {
                if (this.initialised) {
                    if (this.navigationBarEndPoint === this.propExercise._id + 'save') {
                        this.updateExerciseInWorkoutCreateExercises(JSON.parse(JSON.stringify(this.exercise)))
                        this.exerciseBackup = JSON.parse(JSON.stringify(this.exercise))
                        this.editModeActive = false
                        this.$emit('updateBottomNavigation', this.exerciseIndex + 2)
                    }
                    if (this.navigationBarEndPoint === this.propExercise._id + 'saveNewStep') {
                        this.saveNewStep()
                    }
                    if (this.navigationBarEndPoint === this.propExercise._id + 'saveStepEdit') {
                        this.saveStepEdit()
                    }
                    this.initialised = false
                } else {
                    this.initialised = true
                }
            }
        },
        settingDialogVisible(value) {
            if (
                typeof this.settingInEditMode !== 'undefined' &&
                this.settingInEditMode !== null &&
                this.settingInEditMode._id
            ) {
                if (!this.settingDialogVisible) {
                    if (this.activeSetting === 'setSetting') {
                        this.exercise.set_exercise_settings.forEach((exerciseSetting, index) => {
                            if (
                                exerciseSetting &&
                                exerciseSetting._id &&
                                this.settingInEditMode &&
                                this.settingInEditMode._id &&
                                exerciseSetting._id === this.settingInEditMode._id
                            ) {
                                if (
                                    !exerciseSetting.value &&
                                    !exerciseSetting.value === 0 &&
                                    !this.settingInEditMode.value &&
                                    !this.settingInEditMode.value === 0
                                ) {
                                    this.settingInEditMode = null
                                } else {
                                    this.exercise.set_exercise_settings[index] = JSON.parse(
                                        JSON.stringify(this.settingInEditMode)
                                    )
                                    this.settingInEditMode = null
                                }
                            }
                        })
                    } else if (this.activeSetting !== 'deviceSetting') {
                        this.exercise.sets[this.activeSetting].settings.forEach((setting, index) => {
                            if (
                                setting &&
                                setting._id &&
                                this.settingInEditMode &&
                                this.settingInEditMode._id &&
                                setting._id === this.settingInEditMode._id
                            ) {
                                if (
                                    !this.exercise.sets[this.activeSetting].settings[index].value &&
                                    !this.settingInEditMode.value
                                ) {
                                    this.settingInEditMode = false
                                } else {
                                    this.exercise.sets[this.activeSetting].settings[index] = JSON.parse(
                                        JSON.stringify(this.settingInEditMode)
                                    )
                                    this.settingInEditMode = null
                                }
                            }
                        })
                    } else {
                        this.exercise.general_exercise_settings.forEach((exerciseSetting, index) => {
                            if (
                                exerciseSetting &&
                                exerciseSetting._id &&
                                this.settingInEditMode &&
                                this.settingInEditMode._id &&
                                exerciseSetting._id === this.settingInEditMode._id
                            ) {
                                this.exercise.general_exercise_settings[index] = JSON.parse(
                                    JSON.stringify(this.settingInEditMode)
                                )
                                this.settingInEditMode = null
                            }
                        })
                    }
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.formCard {
    background: #313131;
    margin: -12px 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}
.formTitle {
    background-color: #313131;
    color: white !important;
}
::v-deep .bgpositionleftcenter .v-image__image {
    background-position: left center !important;
}
.workout-add-exercise {
    &__card {
        border-radius: 10px;

        &-title {
            border-radius: 10px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: #313131;
            color: white !important;
        }
        &-new-step {
            background: #313131;
            color: white !important;
            align-items: center;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            border-radius: 10px;
            &--container {
                border-radius: 10px;
            }
            &--active {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }
}
.wrapper-outer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
}
.wrapper-inner {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    height: 84vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.courseListView {
    margin-top: 35vh !important;
    &__container {
        position: relative;
        &-title {
            font-weight: bold;
            color: #071e20;
            word-break: break-word;
        }
    }
    &-course {
        &-signups {
            &-checkbox {
                font-size: 20px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

::v-deep .v-virtual-scroll {
    height: 55vh !important;
}

.workoutAddExercise {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: 100%;

    &__search {
        height: 50px;

        &-input {
            top: 25%;
            left: 10px;
            position: relative;
            width: 90%;
        }
    }

    .h6 {
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: -10px;
    }
    &-actions {
        position: sticky;
        bottom: 0;

        button {
            border-width: 4px;
            font-weight: bold;
        }
    }

    &__list-item {
        height: 100px;
        overflow: hidden;

        .v-list-item__title {
            font-weight: bold;
            margin-bottom: 3px;
        }

        &-image {
            height: 100%;

            &__container {
                height: 100%;
                width: 40%;
            }
        }
    }

    &__swiper {
        height: 100vh;
        background-color: white;
        display: flex;
        &-card {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: -10px;
            &-content {
                overflow-y: auto;
                height: 62vh;

                &-img {
                    margin-top: 25%;
                    width: 50%;
                }
            }

            &-image {
                margin: 0 10%;
                height: 40%;
                background-size: contain;
            }
        }
    }
}
</style>
