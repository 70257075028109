const api = require('../../api').default
const moment = require('moment')
export class workoutHabit {
	constructor(
		workoutHabit = {
			type: 'workoutHabit',
			icon: 'workout.png',
			workout: '',
			label: 'Trainingsplan',
			days: [],
			time: '',
			streak: '',
		}
	) {
		for (let key in workoutHabit) {
			this[key] = workoutHabit[key]
		}

		if (this.workout) this.fetchExecutions()
	}
	getWorkoutHabit() {
		let habit = {}
		for (let key in this) {
			habit[key] = this[key]
		}
		return habit
	}
	setWorkoutHabit(habit) {
		for (let key in habit) {
			this[key] = habit[key]
		}
		this.fetchExecutions()
	}
	pastDayOfWeek(weekAgo = 0, dayOfWeek = 0) {
		return moment()
			.isoWeekday(7 + (weekAgo + 1) * -7 + dayOfWeek)
			.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
			.format()
	}
	calulateStreak() {
		let streak = 0
		let counter = 0

		let days = [...new Set(this.days)]

		let dayDateExecutions = this.executions.map((e) =>
			moment(e)
				.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
				.format()
		)

		let countingStreaks = true
		while (countingStreaks) {
			for (let day of days) {
				if (day < moment().isoWeekday() || counter !== 0) {
					if (!dayDateExecutions.includes(this.pastDayOfWeek(counter, day))) {
						countingStreaks = false
					}
				}
			}
			if (countingStreaks) streak++
			counter++
		}

		this.streak = streak
	}
	fetchExecutions() {
		api.fetchWorkoutHabit(this.workout._id)
			.then((e) => {
				this.executions = e

				this.calulateStreak()
			})
			.catch((e) => {
				console.log('Error', e)
			})
	}
	async delete() {
		try {
			let habit = await api.deleteHabit(this._id)
		} catch (e) {
			console.log(e)
		}
	}
	async save() {
		try {
			let habit = this.getWorkoutHabit()
			delete habit.executions
			delete habit.streak
			habit = await api.updateHabit(habit)
			this.setWorkoutHabit(habit)
			return true
		} catch (e) {
			console.log('Error: ', e)
			return false
		}
	}
}
