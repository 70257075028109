<template>
    <v-row justify="center">
        <v-dialog
            persistent
            content-class="dialog"
            max-width="1140px"
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
            <div class="wrapper-outer">
                <div class="wrapper-inner">
                    <v-img class="position-fixed headerImage" height="35vh" :src="headerImageSrc"></v-img>
                    <v-card v-if="courseEdit !== null" class="courseListEdit">
                        <v-btn class="courseListEdit__container-back" fab dark color="white" @click="closeDialog()">
                            <v-icon color="black">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-row no-gutters class="courseListEdit__container flex-column">
                            <v-row no-gutters class="courseListEdit__container-info">
                                <v-col cols="12" class="px-5 pt-3">
                                    <v-breadcrumbs class="pl-0" :items="breadcrumbs" divider="-"></v-breadcrumbs
                                ></v-col>
                                <v-col cols="12" class="px-5 pt-3">
                                    <course-search
                                        :value="courseEdit.course"
                                        label="Kurs"
                                        rights="gf_c_plan"
                                        @update="updateCourse"></course-search>
                                </v-col>
                                <!---	<v-col cols="12" class="px-5 pt-3">
								<v-text-field
										v-model="courseEdit.lsLink"
										class="pb-2"
										type="String"
										label="Event Link (Vimeo)"
										hide-details="auto"
									></v-text-field
								></v-col> ---->
                                <v-row no-gutters
                                    ><v-btn large class="mx-auto mb-5" color="primary" @click="showIcons = !showIcons"
                                        ><span v-if="showIcons">schliessen</span><span v-else>Icon bearbeiten</span></v-btn
                                    ></v-row
                                >

                                <v-row v-show="showIcons" class="lottie-container-wrapper" no-gutters
                                    ><v-col
                                        @click="setIcon(ic)"
                                        class="lottie-container mb-2"
                                        cols="3"
                                        v-for="ic of icons"
                                        :key="ic">
                                        <lottie-player
                                            mode="normal"
                                            style="width: 50px; height: 50px"
                                            :class="{ active: selectedIcon === ic }"
                                            class="lottie m-auto"
                                            autoplay
                                            loop
                                            :key="ic"
                                            :src="'/api/gf/icons/' + ic"></lottie-player> </v-col
                                ></v-row>
                                <v-col cols="12" class="px-5 mt-2">
                                    <v-row no-gutters v-for="instructor of courseEdit.instructors" :key="instructor">
                                        <v-col cols="10">
                                            <user-search
                                                instructor
                                                :value="instructor"
                                                label="Instruktor"
                                                rights="gf_c_plan"
                                                @update="updateInstructor"></user-search
                                        ></v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-btn class="my-auto" icon color="red" @click="removeInstructor(instructor)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn></v-col
                                        >
                                    </v-row>

                                    <v-col cols="12" class="d-flex justify-center mb-7">
                                        <v-btn large color="primary" @click="addInstructor">+ Instruktor</v-btn>
                                    </v-col>
                                    <v-select
                                        v-model="courseEdit.label"
                                        :items="labels"
                                        item-text="text"
                                        item-value="_id"
                                        outlined
                                        color="primary"
                                        prepend-icon="mdi-label-outline"
                                        label="Label"
                                        single-line></v-select>
                                    <v-text-field
                                        v-model="courseEdit.additionalInfo"
                                        outlined
                                        label="Zusatzinfo"
                                        color="primary"
                                        placeholder="Mindest TN-Zahl 4"
                                        prepend-icon="mdi-information-slab-circle-outline"></v-text-field>
                                    <v-select
                                        v-model="courseEdit.room"
                                        :items="rooms"
                                        item-text="name"
                                        item-value="_id"
                                        prepend-icon="mdi-door"
                                        outlined
                                        :rules="[(v) => !!v || 'Auswahl ist erforderlich']"
                                        color="primary"
                                        label="Raum"
                                        single-line></v-select>
                                    <v-text-field
                                        v-model="time"
                                        class="py-2"
                                        type="time"
                                        prepend-icon="mdi-clock-outline"
                                        outlined
                                        color="primary"
                                        label="Zeit"
                                        hide-details="auto"></v-text-field>

                                    <v-text-field
                                        v-model="courseEdit.duration"
                                        class="pb-2"
                                        type="number"
                                        outlined
                                        color="primary"
                                        prepend-icon="mdi-timer-sand"
                                        label="Dauer in Minuten"
                                        hide-details="auto"></v-text-field>
                                    <v-switch
                                        outlined
                                        color="primary"
                                        prepend-icon="mdi-account-multiple"
                                        v-model="courseEdit.reqSignup"
                                        :label="`Anmeldung wird benötigt`"></v-switch>
                                    <v-text-field
                                        v-if="courseEdit.reqSignup"
                                        outlined
                                        color="primary"
                                        prepend-icon="mdi-account-multiple"
                                        v-model="courseEdit.amount"
                                        class="pb-2"
                                        type="number"
                                        label="Anzahl Plätze"
                                        hide-details="auto"></v-text-field>
                                    <v-select
                                        v-if="events.length"
                                        v-model="courseEdit.event"
                                        item-value="_id"
                                        item-text="title"
                                        outlined
                                        color="primary"
                                        prepend-icon="mdi-calendar"
                                        :items="events"
                                        label="Event"></v-select>
                                    <v-col cols="12" class="d-flex flex-column justify-center">
                                        <span v-if="courseEdit.cancelled && courseEdit.cancelledVisibility"
                                            >Sichtbar in Kursliste</span
                                        >
                                        <span v-if="courseEdit.cancelled && !courseEdit.cancelledVisibility"
                                            >Unsichtbar in Kursliste</span
                                        >
                                        <v-btn class="text-white" color="red" @click="changeCourseActivation">{{
                                            courseActivationText
                                        }}</v-btn>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-card>
                </div>
            </div>
        </v-dialog>
        <router-view></router-view>
        <v-dialog v-model="showCancelledPopup" persistent max-width="290">
            <v-card>
                <v-card-subtitle class="pb-0 pt-5">Sichtbarkeit</v-card-subtitle>
                <v-card-title class="text-h5 pt-0" style="word-break: keep-all"> Abgesagter Kurs </v-card-title>

                <v-card-text> Möchtest Du den Kurs trotz Absage in der Kursliste anzeigen? </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelledCourseVisible(false)"> Nein </v-btn>
                    <v-btn color="primary darken-1" text @click="cancelledCourseVisible(true)"> Ja </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showPushPopUp" persistent max-width="290">
            <v-card>
                <v-card-subtitle class="pb-0 pt-5">Benachrichtigung</v-card-subtitle>
                <v-card-title class="text-h5 pt-0" style="word-break: keep-all"> Teilnehmer benachrichtigen </v-card-title>

                <v-card-text>
                    Möchtest Du die Teilnehmer per Benachrichtigung (Push) über die Anpassung informieren?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="saveUpdatedCourse(0)"> Nein </v-btn>
                    <v-btn color="primary darken-1" text @click="saveUpdatedCourse(1)"> Ja </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../components/userSearch.vue'
import courseSearch from '../../components/courseSearch.vue'
export default {
    name: 'courseListEdit',
    components: { userSearch, courseSearch },
    data() {
        return {
            breadcrumbs: [
                {
                    text: 'Kursliste',
                    disabled: false,
                    to: {
                        name: 'courseList',
                    },
                },

                {
                    text: 'bearbeiten',
                    disabled: true,
                    href: '#',
                },
            ],
            events: [],
            showCancelledPopup: false,
            showPushPopUp: false,
            icons: [],
            selectedIcon: null,
            dialog: true,
            notifications: false,
            sound: true,
            widgets: false,
            courseEdit: null,
            time: '00:00:00',
            showIcons: false,
            rooms: [],
            labels: [{ _id: null, text: 'Kein Label anzeigen ----' }],
            shareText: 'Hoi chunnsch au in Kurs? :)',
        }
    },
    async created() {
        this.events = await api.fetchEventsForSelect()
        if (this.events.length) this.events.unshift({ _id: null, title: 'Kein Event anzeigen ----' })
        this.setNavigationBarText({ false: 'Speichern', true: 'loading', state: false })
        let dbInfos = await api.fetchCourseEdit(this.$route.params.id)
        this.rooms = dbInfos.rooms
        this.labels = this.labels.concat(dbInfos.labels)
        this.courseEdit = dbInfos.execution
        if (!this.courseEdit.cancelledVisibility) this.courseEdit.cancelledVisibility = false
        if (!this.courseEdit.room) this.courseEdit.room = null
        if (!this.courseEdit.event) this.courseEdit.event = null
        if (!this.courseEdit.additionalInfo) this.courseEdit.additionalInfo = ''

        if (!this.courseEdit.icon) {
            this.courseEdit.icon = 'dumbell.json'
            this.selectedIcon = 'dumbell.json'
        } else {
            this.selectedIcon = this.courseEdit.icon
        }

        let hours = this.courseEdit.hours < 10 ? '0' + this.courseEdit.hours : this.courseEdit.hours
        let minutes = this.courseEdit.minutes < 10 ? '0' + this.courseEdit.minutes : this.courseEdit.minutes
        this.time = hours + ':' + minutes + ':00'
        let icons = await api.fetchAllGFIcons()
        this.icons = icons.icons
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
        cancelledCourseVisible(visible) {
            this.courseEdit.cancelledVisibility = visible
            this.showCancelledPopup = false
        },
        setIcon(ic) {
            this.selectedIcon = ic
            this.courseEdit.icon = ic
        },
        async saveUpdatedCourse(sendPush) {
            this.showPushPopUp = false
            let updatedCourse = this.courseEdit
            updatedCourse.course = updatedCourse.course._id
            updatedCourse.sendPush = sendPush
            let RespUpdatedCourse = await api.updateCourseExecution(updatedCourse)
            this.$emit('updateCourse')
            this.closeDialog()
        },
        changeCourseActivation() {
            this.courseEdit.cancelled = !this.courseEdit.cancelled
            if (this.courseEdit.cancelled) {
                this.showCancelledPopup = true
            } else {
                this.courseEdit.cancelledVisibility = false
            }
        },
        removeInstructor(id) {
            let temp = this.courseEdit.instructors.filter((e) => {
                if (e === id) return false
                return true
            })
            this.courseEdit.instructors = []
            this.courseEdit.instructors = temp
        },
        addInstructor() {
            if (!this.courseEdit.instructors.includes(null)) this.courseEdit.instructors.push(null)
        },
        updateCourse(newCourse, oldCourse) {
            this.courseEdit.course = newCourse
        },
        updateInstructor(newId, oldId) {
            if (!this.courseEdit.instructors.includes(newId)) {
                this.courseEdit.instructors = this.courseEdit.instructors.map((e) => {
                    if (e === oldId) {
                        return newId
                    } else {
                        return e
                    }
                })
            } else {
                this.courseEdit.instructors.pop()
            }
        },
        signupCounter() {
            if (this.courseEdit.signups) {
            } else {
                return this.courseEdit.amount + ' Plätze'
            }
        },

        destroyed() {
            this.setNavigationBarText(null)
        },
        closeDialog() {
            this.setNavigationBarText(null)

            this.dialog = false
            let ref = this
            setTimeout(() => {
                ref.$router.push('/kurse/' + this.courseEdit._id)
                ref = null
            }, 25)
        },
        courseEndDate(course) {
            let endTime = this.$moment()
            endTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            endTime.add('minutes', course.duration)
            return endTime.format('LT')
        },
        courseStartDate(course) {
            let startTime = this.$moment()
            startTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            return startTime.format('LT')
        },
    },
    watch: {
        time: function (val) {
            this.courseEdit.hours = val.split(':')[0]
            this.courseEdit.minutes = val.split(':')[1]
        },

        navigationBarTextState(value) {
            if (value) {
                //this.saveUpdatedCourse(0)
                // Enable for push
                this.showPushPopUp = true
            }
        },
    },
    computed: {
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
        courseActivationText() {
            if (this.courseEdit.cancelled) {
                return 'Absage aufheben'
            } else {
                return 'Absagen'
            }
        },
        headerImageSrc() {
            if (this.courseEdit && this.courseEdit.course) {
                return '/img/kurse/' + this.courseEdit.course.bild
            } else {
                return ''
            }
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .dialog {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.wrapper-outer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
}
.wrapper-inner {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    max-height: 84vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.headerImage {
    max-width: 500px;
    min-height: 35vh;
    background-color: white;

    width: 100%;
}
.speech-bubble {
    position: relative;
    background-color: var(--v-primary) !important;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 26px solid #0000;
    border-top-color: var(--v-primary);
    border-bottom: 0;
    border-left: 4.5px;
    margin-left: -25.5px;
    margin-bottom: -20px;
}
.hide-lower-1 {
    position: fixed;
    width: 100vw;
    height: 30px;
    bottom: 130px;
    right: 0px;
    background-image: linear-gradient(#ffffff4d, #fffffff5);
}
.hide-lower-2 {
    position: fixed;
    width: 100vw;
    height: 130px;
    bottom: 0px;
    right: 0px;
    background-image: linear-gradient(#fffffff5, #ffffff);
}
.signupsCount {
    z-index: 1;
    position: fixed;
    bottom: 105px;
    width: 100vw;
    display: flex;
    justify-content: center;
    right: 0px;
}

.courseListEdit {
    margin-top: 35vh !important;
    .lottie-container-wrapper {
        max-height: 200px;
        overflow: auto;
    }
    .lottie-container {
        .lottie {
            border: 1px solid #dbdbdb !important;
            border-radius: 50%;
            /* background-color: var(--v-secondary)!important; */
            height: 60px;
            width: 60px;
            &.active {
                background-color: var(--v-primary) !important;
            }
        }
    }
    &__container {
        position: relative;
        &-like {
            position: absolute;
            top: -80px;
            right: 11%;
            z-index: 2;
        }
        &-instructor {
            max-height: 130px;
            &-img {
                border-radius: 100%;
            }
        }
        &-back {
            position: fixed;
            top: 20px;
            left: 20px;
            z-index: 2;
        }
        &-info {
            margin-top: -50px;
            z-index: 1;
            background-color: white;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            padding-top: 10px;

            &-title {
                font-weight: bold;
                color: #071e20;
                word-break: break-word;
            }
        }
    }
}
</style>
