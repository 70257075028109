<template xmlns="">
    <v-dialog persistent :value="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-window :touchless="true" v-model="windowIndex" :continous="false" @change="changeSlide">
            <v-window-item :value="1">
                <div class="wrapper-outer">
                    <div @scroll="fixPosition" class="wrapper-inner">
                        <slim-cropper v-if="loaded" style="height: 35vh" :options="slimOptions">
                            <img v-if="isInEditMode && form.picture" :src="'/img/uebungen/' + form.picture" alt="" />
                            <input type="file" accept="image/*" />
                        </slim-cropper>
                        <v-card class="courseListView" elevation="0">
                            <v-btn
                                class="courseListView__container-back"
                                fab
                                dark
                                color="white"
                                @click="closeCreateView"
                            >
                                <v-icon color="black">mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-row no-gutters class="courseListView__container flex-column">
                                <v-row class="mb-4 create-title">
                                    <v-col class="pa-0" cols="12">
                                        <v-row class="d-flex flex-row py-3 justify-center mx-0 mb-0">
                                            <v-card-title
                                                style="letter-spacing: 1px"
                                                class="h5 pt-5 pb-2 white--text my-0 font-weight-bold text-uppercase"
                                            >
                                                {{ formTitle }}
                                            </v-card-title>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters justify="center" style="max-height: 100vh; padding-bottom: 50vh">
                                    <v-form lazy-validation ref="createForm" class="workoutCreate__swiper-card-form">
                                        <v-text-field
                                            label="Titel"
                                            class="pt-3"
                                            outlined
                                            ref="title"
                                            :value="form.title"
                                            :rules="rules.title"
                                            prepend-icon="mdi-format-title"
                                            counter="50"
                                            @change="updateFormByChange('title', $event)"
                                            required
                                        ></v-text-field>

                                        <v-text-field
                                            outlined
                                            label="Dauer in Minuten"
                                            class="pt-0"
                                            prepend-icon="mdi-clock-outline"
                                            ref="duration"
                                            :value="form.duration"
                                            :rules="rules.duration"
                                            counter="3"
                                            @change="updateFormByChange('duration', $event)"
                                            required
                                            type="number"
                                        ></v-text-field>
                                        <user-search
                                            @inputEvent="fixPosition"
                                            :value="form.user_id"
                                            rights="tp_create"
                                            class="mb-5"
                                            v-if="userService.hasRight('tp_create')"
                                            label="Kunde"
                                            @update="updateCustomer"
                                        ></user-search>

                                        <user-search
                                            @inputEvent="fixPosition"
                                            :value="form.instructor_id"
                                            rights="tp_create"
                                            v-if="userService.hasRight('tp_create')"
                                            label="Trainer"
                                            class="mb-5"
                                            instructor
                                            @update="updateInstructor"
                                        ></user-search>
                                        <p class="red--text" v-if="noInstructorSnackbar">Bitte Trainer auswählen</p>

                                        <v-menu
                                            ref="menu1"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            auto
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="formattedDate"
                                                    label="Neuer Plan fällig"
                                                    ref="calendar"
                                                    readonly
                                                    outlined
                                                    prepend-icon="mdi-calendar"
                                                    :rules="[(v) => !!v || 'Dies ist ein Pflichtfeld']"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="date"
                                                no-title
                                                @input="menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-form>
                                </v-row>
                            </v-row>
                        </v-card>
                    </div>
                </div>
            </v-window-item>
            <workout-step-editable
                v-for="(exercise, index) in workoutCreateExercises"
                :id="exercise.title"
                :addState="workoutCreateExercises.length + 2"
                :key="'workoutStepEditable' + index + exercise._id"
                :prop-exercise="exercise"
                :exercise-index="index"
                @updateBottomNavigation="updateBottomNavigation"
            ></workout-step-editable>
            <!-- .filter((exercise) => exercise._id !== exerciseInEditMode._id) -->
            <!-- <workout-create-edit-exercise v-else :exercise="exerciseInEditMode" @resetEdit="resetExerciseInEditMode" @saveEdit="saveExerciseEdited"></workout-create-edit-exercise>
    -->

            <workout-add-exercise
                :value="workoutCreateExercises.length + 2"
                @add="addExercise"
                :exerciseIds="workoutCreateExercises.map((exercise) => exercise._id)"
                @triggerKey="updateSwiperKey()"
                @updateBottomNavigation="updateBottomNavigation"
            ></workout-add-exercise>
            <workout-create-submit-slide
                :value="workoutCreateExercises.length + 3"
                @save="createWorkoutPlan"
                @changeSorting="setSwiperAllowTouchMove"
                :exercises="workoutCreateExercises"
                @updateBottomNavigation="updateBottomNavigation"
            ></workout-create-submit-slide>
        </v-window>
    </v-dialog>
</template>

<script>
import api from '../../api'
import userSearch from '../../components/userSearch.vue'
import Slim from '../../plugins/slimImage/slim/slim.vue'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapState, mapActions, mapGetters } from 'vuex'
import WorkoutAddExercise from './WorkoutAddExercise'
import WorkoutCreateEditExercise from './WorkoutCreateEditExercise'
import WorkoutCreateSubmitSlide from './WorkoutCreateSubmitSlide.vue'
import userService from '../../services/userService'
import imageOverlappingCard from '../../components/imageOverlappingCard.vue'
import workoutStepEditable from './WorkoutStepEditable'

export default {
    name: 'WorkoutCreate',
    components: {
        'slim-cropper': Slim,
        WorkoutAddExercise,
        userSearch,
        Swiper,
        SwiperSlide,
        WorkoutCreateEditExercise,
        WorkoutCreateSubmitSlide,
        imageOverlappingCard,
        workoutStepEditable,
    },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            windowIndex: 1,
            allowTouchMove: false,
            slimOptions: {
                ratio: '1:1',
                initialImage: '/img/einmalig/imageUploadPlaceholderBottomBorder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            swiperKey: 0,
            loaded: false,
            navigationBarInitiated: false,
            dialog: true,
            noInstructorSnackbar: false,
            userService,
            showDialog: false,
            menu: false,
            exercises: [],
            exerciseInEditMode: {
                _id: null,
            },
            date: this.parseDate(this.$moment().add(10, 'weeks')),
            form: {
                picture: null,
                title: '',
                duration: '',
                user_id: null,
                instructor_id: null,
            },
            rules: {
                title: [(v) => (v.length <= 50 && v.length > 0) || 'Der Titel darf bis zu 50 Zeichen lang sein.'],
                duration: [(v) => (v > 0 && v < 1000) || 'Maximal 3 Zeichen'],
            },
            exampleImages: ['workoutExample.png', 'workoutExample1.png', 'workoutExample2.png'],
        }
    },
    created() {
        if (this.workoutCreate.form.title && !this.isInEditMode && !this.planInEditMode) {
            if (!confirm('Es wurden zwischengespeicherte Daten gefunden. Möchtest du diese laden?'))
                this.closeWorkoutForm(false)
            this.form = this.workoutCreate.form
        } else {
            if (this.isInEditMode) {
                this.form = this.planInEditMode.form
            } else if (this.planInEditMode) {
                this.resetPlanInEditMode()
                this.closeWorkoutForm(false)
            }
        }
        if (!this.form.ts) {
            let ts = {
                key: 'ts',
                value: this.$moment().unix().toString(),
            }
            this.updateWorkoutCreateForm(ts)
            this.form.ts = ts
        }
        let due = {
            key: 'due',
            value: this.formatDate(this.$moment().add(10, 'weeks')),
        }
        this.updateWorkoutCreateForm(due)
        this.form.due = due.value
        this.setNavigationBarText({ text: 'weiter', state: false })

        this.loaded = true
    },
    beforeDestroy() {
        window.removeEventListener('orientationchange', this.reloadLocation)
        this.setNavigationBarText(null)
    },
    mounted() {
        window.addEventListener('orientationchange', this.reloadLocation)
    },
    methods: {
        ...mapActions('navigationStore', ['setSelectedNavigation']),
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
        ...mapActions('workingoutStore', [
            'updateWorkoutCreateForm',
            'addExerciseToWorkoutCreateExercises',
            'resetWorkoutCreateExercises',
            'resetWorkoutCreateForm',
            'resetPlanInEditMode',
            'setWorkoutCreateDefaultValues',
        ]),
        changeSlide() {
            return this.setSelectedNavigation(this.windowIndex)
        },
        fixPosition() {
            const menu = document.querySelector('.v-menu__content:not([style*="display: none"])')

            if (!menu) return
            const input = document.querySelector('.v-select--is-menu-active').getBoundingClientRect()

            menu.style.top = input.bottom - 20 + 'px'
        },
        reloadLocation() {
            //location.reload()
        },
        getRandomImage() {
            let index = Math.floor(Math.random() * 3)
            return this.exampleImages[index]
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'workout'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.form.picture = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                })
        },
        updateSwiperKey() {},
        async updateBottomNavigation(state = null) {
            if (this.windowIndex === this.workoutCreateExercises.length + 3) return
            console.log('enter update')
            let buttons = [
                {
                    title: 1,
                    text: 'Informationen',
                    icon: '/img/lottiefiles/BottomNavigation/infosFilled.json',
                },
            ]
            let newState = this.workoutCreateExercises.length + 2
            this.workoutCreateExercises.forEach((exercise, index) => {
                buttons.push({
                    title: index + 2,
                    text: exercise.title,
                    ignoreIndex: true,
                    image: this.getImageForExercise(exercise),
                })
            })

            buttons.push({
                title: this.workoutCreateExercises.length + 2,
                text: 'neue Übung',
                icon: '/img/lottiefiles/BottomNavigation/addExerciseFilled.json',
            })
            buttons.push({
                title: this.workoutCreateExercises.length + 3,

                text: 'Abschliessen',
                icon: '/img/lottiefiles/BottomNavigation/checkFilled.json',
            })

            await this.setNavigationBarText({
                customButtons: true,
                buttons,
                componentHandles: true,

                endPoint: 'createWorkout',
            })
            if (this.workoutCreateExercises.length) newState = 2
            if (state) newState = state
            console.log('ddddd', newState)
            this.setSelectedNavigation(newState)
        },
        updateFormByChange(key, value) {
            this.updateWorkoutCreateForm({ key, value })
        },
        updateCustomer(id) {
            this.updateFormByChange('user_id', id)
            this.form.user_id = id
        },
        getImageForExercise(exercise) {
            if (exercise.picture) return this.getImageUrl(exercise.picture)
            if (exercise.manual && exercise.manual[0] && exercise.manual[0].picture)
                return this.getImageUrl(exercise.manual[0].picture)
            return '/img/uebungen/einmalig/imageUploadPlaceholder.jpg'
        },
        updateInstructor(id) {
            this.updateFormByChange('instructor_id', id)
            this.form.instructor_id = id
        },
        setSwiperAllowTouchMove(value = null) {
            if (!value) value = !this.allowTouchMove
            this.allowTouchMove = value
        },
        async createWorkoutPlan() {
            this.$route.params.id
                ? await api.updateTrainingsPlan(this.getFormData())
                : await api.createTrainingsPlan(this.getFormData())
            this.closeWorkoutForm()
        },
        closeWorkoutForm(push = true) {
            this.resetWorkoutCreateExercises()
            this.resetWorkoutCreateForm()
            this.$emit('reload-workouts')
            if (push) {
                if (
                    this.form.user_id &&
                    this.form.instructor_id &&
                    this.form.user_id.toString() !== this.form.instructor_id.toString() &&
                    this.userService.hasRight('tp_create')
                ) {
                    this.$router.push('/gfrecommendations/' + this.form.user_id)
                } else {
                    this.$router.push('/workouts')
                }
            }
        },
        validateForm() {
            console.log('validating')
            this.noInstructorSnackbar = false
            if (this.$refs.createForm.validate()) {
                if (this.userService.hasRight('tp_create') && this.form.instructor_id === null) {
                    console.log('no instructor')
                    this.noInstructorSnackbar = true
                } else {
                    console.log('updating')
                    this.updateBottomNavigation()
                }
            } else {
                console.log('not valid')
            }
        },
        getFormData() {
            let formData = {}
            formData.form = { ...this.workoutCreate.form }
            if (this.$route.params.id) formData.form.plan_id = this.$route.params.id
            formData.form.user_id = this.form.user_id
            formData.form.instructor_id = this.form.instructor_id
            formData.form.picture = this.form.picture
            if (!formData.form.picture) formData.form.picture = this.getRandomImage()
            formData.exercises = this.workoutCreateExercises.map((exercise) => {
                exercise.sets = exercise.sets.map((set, index) => {
                    if (!set.settings)
                        this.$toast.error(
                            'Bei der Übung ' +
                                exercise.title +
                                ' ist im Satz ' +
                                (index + 1) +
                                ' keine Einstellung vorhanden.'
                        )
                    let serializedSettings = set.settings.filter((setting) => setting && setting.value)
                    let weightSettingIndex = set.settings.findIndex((setting) => setting.text === 'Gewicht')
                    if (
                        weightSettingIndex !== -1 &&
                        !set.settings[weightSettingIndex].startValue &&
                        !this.$route.params.id
                    )
                        set.settings[weightSettingIndex].startValue = set.settings[weightSettingIndex].value

                    return {
                        settings: serializedSettings,
                    }
                })
                let returnObject = {
                    general_exercise_settings: exercise.general_exercise_settings,
                    sets: exercise.sets,
                }
                if (exercise.exercise_id) {
                    returnObject.exercise_id = exercise.exercise_id
                    returnObject._id = exercise._id
                } else {
                    returnObject.exercise_id = exercise._id
                }
                return returnObject
            })
            formData.form.due = this.getDateAsUnixString()
            formData.form.duration = parseInt(formData.form.duration)
            return formData
        },
        addExercise(exercise) {
            this.addExerciseToWorkoutCreateExercises(exercise)
        },
        getImageUrl(imageId) {
            return '/img/uebungen/' + imageId.replace(/\.[^/.]+$/, '') + '.webp'

            return '/img/uebungen/' + imageId
        },
        getItemText(user) {
            return user.vorname + ' ' + user.nachname + ' (' + user.email + ')'
        },
        formatDate(date) {
            return this.$moment(date).format('DD. MM. YYYY')
        },
        parseDate(date) {
            return this.$moment(date).format('YYYY-MM-DD')
        },
        getDateAsUnixString() {
            return this.$moment(this.workoutCreate.form.due, 'DD. MM. YYYY').unix().toString()
        },
        goToWorkouts() {
            this.$router.push({ name: 'Workouts' })
        },
        closeCreateView() {
            this.dialog = false
            this.setSelectedNavigation(null)
            setTimeout(() => {
                this.setSelectedNavigation('workout')
                this.goToWorkouts()
            }, 100)
        },
    },
    computed: {
        ...mapState('workingoutStore', [
            'workoutCreateExercises',
            'workoutCreate',
            'planInEditMode',
            'workoutCreateDefaultValues',
        ]),
        ...mapState('navigationStore', ['selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationBarText']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
        displayedPicture() {
            let url = '/img/'

            return this.form.picture
                ? url + 'workout/' + this.form.picture
                : url + 'einmalig/imageUploadPlaceholder.jpg'
        },
        swiper() {
            return this.$refs.workoutCreateSwiper.$swiper
        },
        formattedDate() {
            return this.formatDate(this.date)
        },
        isInEditMode() {
            return !!this.$route.params.id
        },
        formTitle() {
            return this.$route.params.id ? 'Trainingsplan Bearbeiten' : 'Trainingsplan Erstellen'
        },
    },
    watch: {
        navigationBarTextState(value) {
            console.log('here1')
            if (
                (this.navigationBarText && !this.navigationBarText.text) ||
                (this.navigationBarText && this.navigationBarText.text === 'weiter')
            ) {
                console.log('here')
                if (this.navigationBarInitiated && this.navigationBarText.text === 'weiter') {
                    this.validateForm()
                } else {
                    this.navigationBarInitiated = true
                }
            }
        },
        selectedNavigation(value) {
            if (this.navigationBarEndPoint === 'createWorkout' && this.windowIndex !== value) {
                if (value === 1) {
                    this.navigationBarInitiated = false
                    this.setNavigationBarText({ text: 'weiter', state: false })
                }
                console.log('setting the index', this.windowIndex, value)
                this.windowIndex = value
            }
        },
        date(val) {
            // ToDo: Überprüfen pls
            this.updateWorkoutCreateForm({ key: 'due', value: this.formatDate(this.date) })
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .swiper-slide {
    transform: unset !important;
}

.create-title {
    background: #313131;
    margin: -12px 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}
::v-deep .v-menu {
    z-index: 1000 !important;
}

.h5 {
    text-transform: uppercase;
    font-weight: bold;
}

.v-card {
    transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
    bottom: 5px;
}

::v-deep .dialog {
    background-color: white !important;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.wrapper-outer {
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
}
.wrapper-inner {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    padding-bottom: 100px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.headerImage {
    max-width: 500px;
    min-height: 35vh;
    background-color: white;
    width: 100%;
}
.courseListView {
    // margin-top: 35vh !important;
    &__container {
        position: relative;
        &-like {
            position: absolute;
            top: -80px;
            right: 11%;
            z-index: 2;
        }
        &-edit {
            position: absolute;
            top: -80px;
            left: 11%;
            z-index: 2;
        }
        &-instructor {
            max-height: 130px;
            overflow-x: auto;
            &-img {
                border-radius: 100%;
            }
        }
        &-back {
            position: fixed;
            top: 20px;
            left: 11%;
            z-index: 3;
        }
        &-info {
            margin-top: -50px;
            z-index: 1;
            background-color: white;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            padding-top: 10px;

            &-title {
                font-weight: bold;
                color: #071e20;
                word-break: break-word;
            }
        }
    }
    &-course {
        &-signups {
            &-checkbox {
                font-size: 20px;
                width: 30px;
                height: 30px;
            }
        }
    }
}

.workoutCreate {
    &__swiper {
        &-card {
            &-content {
                overflow-y: auto;
                height: 62vh;
            }
            &-image {
                margin: 0 10%;
                height: 40%;
                background-size: contain;
            }

            &-form {
                width: 80%;
            }

            &-title {
                word-break: break-word;
            }

            &-delete {
                position: absolute;
                right: 20px;
            }

            &-steps {
                width: unset;

                &__wrapper {
                    width: 80%;
                    height: 60%;
                    text-align: center;
                    margin-left: 10%;
                }
            }
        }
    }
    &__new-exercise-card {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>
