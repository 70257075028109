<template>
	<div>
		<v-tabs v-model="activeTab" color="primary" centered>
			<v-tabs-slider></v-tabs-slider>
			<v-tab key="overview"> Feed </v-tab>

			<v-tab key="manage">
				Freunde
				<span class="friendrequest-counter-smol ml-2" v-show="friendrequests.length > 0">{{ friendrequests.length }}</span>
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="activeTab">
			<v-tab-item key="overview">
				<lottie-player
					mode="normal"
					style="width:200px;height:200px;"
					class="ma-auto"
					name="friendlist-empty"
					src="/img/lottiefiles/friends/friendsPrimaryBubble.json"
				></lottie-player>
				<v-card-title class="justify-content-center mt-5" v-if="feed.length == 0" style="word-break: break-word;">
					Momentan nichts los.. Füge jetzt neue Freunde hinzu und bleibe auf dem Laufenden!
				</v-card-title>
				<v-card-text v-else>
					<v-row class="mt-5" v-for="(entry, i) of filteredFeed" :key="i">
						<v-card class="friends__card-overview d-flex flex-row rounded-lg">
							<v-col cols="3" class="pa-0 ml-2 align-self-center">
								<v-img v-if="entry.signup.user.bild" class="profile-pic" :src="'/img/profilbilder' + entry.signup.user.bild" />
								<v-img v-else class="profile-pic" src="/img/profilbilder/uploads/1605087902456roodytest.png" />
							</v-col>

							<v-col cols="9" class="d-flex flex-column">
								<span class="caption"> {{ $moment.unix(entry.signup.ts).format('DD.MM.YYYY HH:MM') }} </span>
								<span class="font-weight-bold primary--text subtitle-1">
									{{ entry.signup.user.vorname + ' ' + entry.signup.user.nachname }}
								</span>
								<span> Angemeldet für {{ entry.course }} </span>
								<span> am {{ getPostTime(entry) }}</span>
							</v-col>
						</v-card>
					</v-row>
					<!-- <router-link :event="fs.kurs_ts_end > time ? 'click' : ''" :disabled="fs.kurs_ts_end < time" :to="'/kurse/anmeldung/' + fs.cg_id" class="friendship-signup">
              <div class="feed-post">
                <div class="feed-post-info"></div>
                <div class="post-wrapper">
                  <div class="post-pfp"></div>
                  <div class="post-info">
                    <div class="post-user">
                      {{ fs.benutzer }}
                    </div>
                    <div class="post-text">
                      Hat sich für folgenden Kurs angemeldet:
                    </div>
                    <div class="post-kurs">{{ fs.kurs }} am {{ getPostTime(fs.kurs_ts) }}</div>
                  </div>
                </div>
                <span v-show="fs.kurs_ts_end > time" class="material-icons post-action-kurs">navigate_next</span>
              </div>
            </router-link> -->
				</v-card-text>
			</v-tab-item>
			<v-tab-item key="manage">
				<v-card-title class="h4 primary--text" style="word-break: break-word;">Neuen Freund hinzufügen</v-card-title>
				<v-row>
					<v-col cols="9">
						<v-text-field class="pl-4" v-model="mailfr" placeholder="roody@roody.ch" label="E-Mail Adresse"></v-text-field>
					</v-col>
					<v-col cols="3" class="ma-auto" style="text-align: center;"
						><v-btn @click="addFriend()" icon>
							<lottie-player
								mode="normal"
								style="width:60px;height:60px;"
								class="ma-auto"
								name="addNewFriend"
								autoplay
								src="/img/lottiefiles/friends/addFriend.json"
							></lottie-player></v-btn
					></v-col>
				</v-row>
				<div v-if="friendrequests.length">
					<v-card-title class="h4 primary--text"> Freundschaftsanfragen</v-card-title>
					<v-row class="px-4">
						<v-col v-for="(fr, i) of friendrequests" :key="i" lg="4" xl="4" md="12" sm="12">
							<v-card style="width: 100%;" class="d-flex elevation-0">
								<v-col v-if="fr.user.bild" class="ma-auto" cols="4">
									<v-img class="profile-pic" :src="'/img/profilbilder' + fr.user.bild"></v-img>
								</v-col>

								<v-col :cols="fr.user.bild ? 6 : 10" class="d-flex flex-column ma-auto"
									><span class="font-weight-bold">{{ fr.user.name }}</span>
									<span class="font-weight-bold">{{ fr.user.mail }}</span>
								</v-col>
								<v-col cols="2" class="d-flex flex-column">
									<v-btn class="green--text" @click="acceptFriend(fr.request._id)" icon>
										<v-icon>mdi-check</v-icon>
									</v-btn>
									<v-btn class="red--text" @click="declineFriendRequest(fr.request._id)" icon>
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</v-col>
							</v-card>
						</v-col>
					</v-row>
				</div>
				<div v-if="friendlist.length">
					<v-card-title class="h4 primary--text"> Freundesliste</v-card-title>
					<v-row class="px-4">
						<v-col v-for="(fr, i) of friendlist" :key="i" lg="4" xl="4" md="12" sm="12">
							<v-card style="width: 100%;" class="d-flex elevation-0">
								<v-col v-if="fr.user.bild" class="ma-auto" cols="4">
									<v-img class="profile-pic" :src="'/img/profilbilder' + fr.user.bild"></v-img>
								</v-col>
								<v-col :cols="fr.user.bild ? 6 : 10" class="d-flex flex-column ma-auto"
									><span class="font-weight-bold">{{ fr.user.vorname }} {{ fr.user.nachname }}</span>
									<span class="font-weight-bold">{{ fr.user.email }}</span>
								</v-col>
								<v-col cols="2" class="d-flex flex-column ma-auto">
									<v-btn @click="deleteFriend(fr.request_id)" icon>
										<v-icon class="red--text">mdi-delete</v-icon>
									</v-btn>
								</v-col>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'

import Vue from 'vue'

export default {
	data() {
		return {
			activeTab: null,
			friendlist: [],
			friendrequests: [],
			feed: [],
			mailfr: '',
			animations: [],
		}
	},
	methods: {
		addAnimation(animation) {
			this.animations.push(animation)
		},
		getPostTime(entry) {
			let date = this.$moment.unix(entry.ts).format('DD.MM.YY')
			if (!entry.timeline) return
			if (entry.timeline.minutes === 0) entry.timeline.minutes = '00'
			return date + ' um ' + entry.timeline.hours + ':' + entry.timeline.minutes + ' Uhr'
		},
		courseEndDate(course) {
			let endTime = this.$moment()
			endTime.set({
				hours: course.hours,
				minutes: course.minutes,
				seconds: 0,
				miliseconds: 0,
			})

			endTime.add('minutes', course.duration)
			return endTime.format('LT')
		},
		courseStartDate(course) {
			let startTime = this.$moment()

			startTime.set({
				hours: course.hours,
				minutes: course.minutes,
				seconds: 0,
				miliseconds: 0,
			})

			return startTime.format('LT')
		},
		acceptFriend(id) {
			api.acceptFriend(id)
				.then((data) => {
					if (data.status == 1) {
						alert('Freund hinzugefügt')
						this.getFriendsFeed()
						this.getFriendrequests()
						window.app.$root.$children[0].loadFriendrequests()
					}
				})
				.catch((error) => {})
		},
		addFriend() {
			this.animations.forEach((animation) => {
				animation.name === 'addNewFriend' ? animation.play() : animation.stop()
			})
			if (this.mailfr !== '') {
				api.addFriend(this.mailfr)
					.then((data) => {
						if (data.status == 1) {
							alert('Freundschaftsanfrage verschickt')
							this.mailfr = ''
						}
						if (data.status == 2) {
							alert('Freund wurde bereits angefragt')
							this.mailfr = ''
						}
					})
					.catch((error) => {})
			} else {
				alert('Bitte gebe eine gültige Mail Adresse ein')
			}
		},
		declineFriendRequest(id) {
			api.removeFriend(id)
				.then((data) => {
					if (data.status == 1) {
						alert('Freundschaftsanfrage abgelehnt.')
						this.getFriendrequests()
					}
				})
				.catch((error) => {})
		},
		deleteFriend(id) {
			if (confirm('Freund wirklich entfernen?')) {
				api.removeFriend(id)
					.then((data) => {
						if (data.status == 1) {
							alert('Freund entfernt')
							this.getFriendrequests()
						}
					})
					.catch((error) => {})
			}
		},
		async getFriends() {
			let data = await api.getFriends()
			this.friendlist = []
			for (let freund of data.freunde) {
				if (freund.user) {
					this.friendlist.push({
						user: freund.user,
						request_id: freund.request_id,
					})
				}
			}
			this.getFriendsFeed()
		},
		async getFriendrequests() {
			this.friendrequests = await api.getFriendrequests()
			this.getFriends()
		},
		async getFriendsFeed() {
			this.feed = await api.getFriendsFeed()
			console.log(this.feed)
		},
	},
	computed: {
		filteredFeed() {
			return this.feed.filter((entry) => entry.course && entry.signup.user)
		},
	},
	async created() {
		await this.getFriendrequests()
	},
}
</script>

<style lang="scss" scoped>
.friends__card-overview {
	width: 100%;
}
.profile-pic {
	border-radius: 50%;
}
</style>
