<template>
    <v-row>
        <v-dialog persistent max-width="500px" v-model="dialog" fullscreen transition="dialog-bottom-transition">
            <v-card transparent class="courseEditTimeline">
                <v-row max-width="300px" no-gutters>
                    <v-col
                        cols="12"
                        v-if="!loading && !error && courseTimelineEdit"
                        style="max-width: 500px"
                        class="inner-wrapper px-5"
                    >
                        <v-btn class="courseEditTimeline__container-back" fab dark color="white" @click="closeDialog()">
                            <v-icon color="black">mdi-chevron-left</v-icon>
                        </v-btn>

                        <v-card-title class="px-0 pb-5">{{ courseTimelineEdit.course.name }}</v-card-title>
                        <v-alert type="info">
                            Achtung: manuell angepasste Kursausführungen werden nicht automatisch aktualisiert.
                        </v-alert>
                        <v-row class="mb-5" no-gutters>
                            <v-btn class="mx-auto mb-2" color="primary" @click="showIcons = !showIcons">
                                <span v-if="showIcons">schliessen</span>
                                <span v-else>Icon bearbeiten</span>
                            </v-btn>
                        </v-row>
                        <v-row v-if="showIcons" class="lottie-container-wrapper mb-5" no-gutters>
                            <v-col
                                @click="setIcon(ic)"
                                class="lottie-container mb-2"
                                cols="3"
                                v-for="ic of icons"
                                :key="ic"
                            >
                                <lottie-player
                                    mode="normal"
                                    style="width: 50px; height: 50px"
                                    :class="{ active: selectedIcon === ic }"
                                    class="lottie m-auto"
                                    :key="ic"
                                    :src="'/api/gf/icons/' + ic"
                                ></lottie-player>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" class="px-0 pb-0">
                                <course-search
                                    :value="courseTimelineEdit.course"
                                    label="Kurs"
                                    rights="gf_c_plan"
                                    @update="updateCourse"
                                ></course-search>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="courseTimelineEdit.label"
                                    :items="labels"
                                    item-text="text"
                                    item-value="_id"
                                    clearable
                                    outlined
                                    color="primary"
                                    prepend-icon="mdi-label-outline"
                                    clear-icon
                                    label="Label"
                                    single-line
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="courseTimelineEdit.additionalInfo"
                                    outlined
                                    label="Zusatzinfo"
                                    placeholder="Mindest TN-Zahl 4"
                                    prepend-icon="mdi-information-slab-circle-outline"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="courseTimelineEdit.day"
                                    outlined
                                    color="primary"
                                    :items="days"
                                    item-text="text"
                                    prepend-icon="mdi-calendar"
                                    label="Wochentag"
                                    item-value="value"
                                    single-line
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="time"
                                    class="pb-5"
                                    type="time"
                                    prepend-icon="mdi-clock-outline"
                                    outlined
                                    color="primary"
                                    label="Zeit"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="courseTimelineEdit.duration"
                                    class="pb-5"
                                    type="number"
                                    outlined
                                    color="primary"
                                    prepend-icon="mdi-timer-sand"
                                    label="Dauer in Minuten"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            class="mt-5"
                            no-gutters
                            v-for="instructor of courseTimelineEdit.instructors"
                            :key="instructor"
                        >
                            <v-col cols="10">
                                <user-search
                                    instructor
                                    :value="instructor"
                                    label="Instruktor"
                                    rights="gf_c_plan"
                                    @update="updateInstructor"
                                ></user-search>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-center align-center">
                                <v-btn x-large color="red lighten-1" icon @click="removeInstructor(instructor)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" class="d-flex justify-center mt-1 pb-5">
                                <v-btn color="primary" @click="addInstructor">+ Instruktor</v-btn>
                            </v-col>
                            <v-col cols="12" class="mt-5">
                                <v-switch
                                    v-model="courseTimelineEdit.reqSignup"
                                    :label="`Anmeldungen: ${reqSignupText}`"
                                ></v-switch>
                            </v-col>
                            <v-col class="mb-5" cols="12">
                                <v-text-field
                                    v-show="courseTimelineEdit.reqSignup"
                                    v-model="courseTimelineEdit.amount"
                                    type="number"
                                    outlined
                                    color="primary"
                                    prepend-icon="mdi-account-multiple"
                                    label="Anzahl Plätze"
                                    :rules="[(v) => !!v || 'Auswahl ist erforderlich']"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="courseTimelineEdit.room"
                                    :items="rooms"
                                    item-text="name"
                                    item-value="_id"
                                    prepend-icon="mdi-door"
                                    outlined
                                    :rules="[(v) => !!v || 'Auswahl ist erforderlich']"
                                    color="primary"
                                    label="Raum"
                                    single-line
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    v-model="courseTimelineEdit.repeating"
                                    :label="`Wöchentlich anzeigen: ${repeatingText}`"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-show="!courseTimelineEdit.repeating" class="flex-column pb-15">
                            <v-switch v-model="visibleAfter" :label="`Anzeigen ab: ${visibleAfterText} `"></v-switch>
                            <v-date-picker
                                :allowed-dates="allowedDates(selectedDay)"
                                :min="minDate"
                                locale="de-CH"
                                v-show="visibleAfter"
                                v-model="courseTimelineEdit.visibleAfter"
                            ></v-date-picker>
                            <v-switch v-model="hideAfter" :label="`Anzeigen bis: ${hideAfterText} `"></v-switch>
                            <v-date-picker
                                :allowed-dates="allowedDates(selectedDay)"
                                :min="courseTimelineEdit.visibleAfter"
                                locale="de-CH"
                                v-show="hideAfter"
                                v-model="courseTimelineEdit.hideAfter"
                            ></v-date-picker>
                        </v-row>
                        <v-row class="justify-center pt-2">
                            <v-btn @click="deleteTimeline(courseTimelineEdit)" color="red" class="text-white">
                                Ausführung löschen
                            </v-btn>
                        </v-row>
                    </v-col>

                    <v-col cols="12" v-else class="d-flex justify-center align-center inner-wrapper pt-10 px-5 pb-15">
                        <div v-if="loading" class="loadingScreen">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            <v-btn color="primary" text @click="$router.push('/kurslisten')">abbrechen</v-btn>
                        </div>
                        <div v-if="error">error</div>
                        <div v-if="!courseTimelineEdit && !loading">wurde nicht gefunden</div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showPushPopUp" persistent max-width="290">
            <v-card>
                <v-card-subtitle class="pb-0 pt-5">Benachrichtigung</v-card-subtitle>
                <v-card-title class="text-h5 pt-0" style="word-break: keep-all">
                    Teilnehmer benachrichtigen
                </v-card-title>

                <v-card-text>
                    Möchtest Du die Teilnehmer per Benachrichtigung (Push) über die Anpassung informieren?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="initSaveTimeline(0)">Nein</v-btn>
                    <v-btn color="primary darken-1" text @click="initSaveTimeline(1)">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../components/userSearch.vue'
import courseSearch from '../../components/courseSearch.vue'
export default {
    name: 'courseEditTimeline',
    components: { userSearch, courseSearch },
    data() {
        return {
            error: false,
            loading: true,
            showPushPopUp: false,
            time: '00:00',
            dialog: true,
            courseTimelineEdit: null,
            labels: [{ _id: null, text: 'Kein Label anzeigen' }],
            rooms: [],
            visibleAfter: false,
            minDate: null,
            hideAfter: false,
            icons: [],
            showIcons: false,
            selectedIcon: null,
            days: [
                { value: 1, text: 'Montag' },
                { value: 2, text: 'Dienstag' },
                { value: 3, text: 'Mittwoch' },
                { value: 4, text: 'Donnerstag' },
                { value: 5, text: 'Freitag' },
                { value: 6, text: 'Samstag' },
                { value: 7, text: 'Sonntag' },
            ],
        }
    },
    async created() {
        let allLabels = await api.fetchGFLabels()
        this.labels = this.labels.concat(allLabels)

        //document.body.style = 'overflow:hidden;max-height:100vh;'
        this.setNavigationBarText({ false: 'Speichern', true: 'zurück', state: false })
        this.fetchCourseTimelineEdit()
    },
    destroyed() {
        //document.body.style = 'overflow:auto;max-height:100vh;'
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'toggleNavigationBarTextState',
            'setNavigationbarVisibility',
        ]),
        updateCourse(newCourse) {
            this.courseTimelineEdit.course = newCourse
        },
        async deleteTimeline(timeline) {
            if (confirm('Möchtest du die Kursplanung defintiv löschen?')) {
                if (timeline._id) await api.deleteCourseTimeline(timeline._id)
                this.$emit('updateList')
                this.closeDialog()
            }
        },
        async initSaveTimeline(sendPush) {
            this.saveCourseTimeline(sendPush)
        },
        setIcon(ic) {
            this.selectedIcon = ic
            this.courseTimelineEdit.icon = ic
        },
        async saveCourseTimeline(sendPush) {
            this.setNavigationbarVisibility(true)
            if (!this.visibleAfter) this.courseTimelineEdit.visibleAfter = null
            if (!this.hideAfter) this.courseTimelineEdit.hideAfter = null

            if (!this.courseTimelineEdit.course) {
                this.$toast.error('Bitte wähle einen Kurs aus')
                this.showPushPopUp = false
                this.toggleNavigationBarTextState()

                return
            }

            if (this.time === '00:00') {
                this.$toast.error('Bitte gib eine Uhrzeit an')
                this.showPushPopUp = false
                this.toggleNavigationBarTextState()

                return
            }

            if (!this.courseTimelineEdit.repeating && (!this.visibleAfter || !this.hideAfter)) {
                this.$toast.error('Bitte wähle ein Start- und Enddatum aus')
                this.showPushPopUp = false
                this.toggleNavigationBarTextState()

                return
            }

            this.courseTimelineEdit.sendPush = sendPush
            let formData = JSON.parse(JSON.stringify(this.courseTimelineEdit))

            let resp = await api.updateCourseTimeline(formData).catch((e) => {
                this.$toast.error('Fehler beim Speichern der Kursplanung. Bitte versuche es erneut.')
                return false
            })
            this.$emit('updateList')

            this.closeDialog()
        },
        allowedDates(a) {
            return (val) => !a.includes(new Date(val).getDay())
        },
        removeInstructor(id) {
            let temp = this.courseTimelineEdit.instructors.filter((e) => {
                if (e === id) return false
                return true
            })
            this.courseTimelineEdit.instructors = []
            this.courseTimelineEdit.instructors = temp
        },
        addInstructor() {
            if (!this.courseTimelineEdit.instructors.includes(null)) this.courseTimelineEdit.instructors.push(null)
        },
        updateInstructor(newId, oldId) {
            if (!this.courseTimelineEdit.instructors.includes(newId)) {
                this.courseTimelineEdit.instructors = this.courseTimelineEdit.instructors.map((e) => {
                    if (e === oldId) {
                        return newId
                    } else {
                        return e
                    }
                })
            } else {
                this.courseTimelineEdit.instructors.pop()
            }
        },
        async fetchCourseTimelineEdit() {
            this.loading = true
            let courseTimeline = await api.fetchCourseTimeline(this.$route.params.id).catch((e) => {
                this.error = true
            })
            this.courseTimelineEdit = courseTimeline.timeline

            if (!this.courseTimelineEdit.icon) {
                this.courseTimelineEdit.icon = 'deadlift.json'
                this.selectedIcon = 'deadlift.json'
            } else {
                this.selectedIcon = this.courseTimelineEdit.icon
            }
            if (this.courseTimelineEdit.visibleAfter) {
                this.visibleAfter = true
                this.courseTimelineEdit.visibleAfter = this.$moment(this.courseTimelineEdit.visibleAfter * 1000).format(
                    'YYYY-MM-DD'
                )
            }
            if (this.courseTimelineEdit.hideAfter) {
                this.courseTimelineEdit.hideAfter = this.$moment(this.courseTimelineEdit.hideAfter * 1000).format(
                    'YYYY-MM-DD'
                )
                this.hideAfter = true
            }

            let hours =
                this.courseTimelineEdit.hours < 10 ? '0' + this.courseTimelineEdit.hours : this.courseTimelineEdit.hours
            let minutes =
                this.courseTimelineEdit.minutes < 10
                    ? '0' + this.courseTimelineEdit.minutes
                    : this.courseTimelineEdit.minutes
            this.time = hours + ':' + minutes + ':00'
            this.rooms = courseTimeline.rooms
            let icons = await api.fetchAllGFIcons()
            this.icons = icons.icons
            this.loading = false
        },
        closeDialog() {
            this.setNavigationBarText(null)

            this.dialog = false
            setTimeout(() => {
                this.$router.push('/kurslisten')
            }, 25)
        },
    },

    watch: {
        time: function (val) {
            this.courseTimelineEdit.hours = val.split(':')[0]
            this.courseTimelineEdit.minutes = val.split(':')[1]
        },
        error(val) {
            if (val) {
                this.dialog = false
                setTimeout(() => {
                    this.$router.push('/kurslisten')
                    alert('Es gab einen Fehler bei dieser Kursausführung')
                }, 25)
            }
        },
        async navigationBarTextState(value) {
            if (value) {
                //this.initSaveTimeline(0)
                // enable for push
                this.setNavigationbarVisibility(false)
                this.showPushPopUp = true
            }
        },
    },
    computed: {
        selectedDay() {
            let day = this.courseTimelineEdit.day
            let days = [0, 1, 2, 3, 4, 5, 6]
            if (day === 7) day = 0
            days = days.filter((e) => e !== day)
            return days
        },
        visibleAfterText() {
            if (this.visibleAfter && this.courseTimelineEdit && this.courseTimelineEdit.visibleAfter) {
                let split = this.courseTimelineEdit.visibleAfter.split('-')
                return split[2] + '.' + split[1] + '.' + split[0]
            } else {
                return 'Deaktiviert'
            }
        },
        hideAfterText() {
            if (this.hideAfter && this.courseTimelineEdit && this.courseTimelineEdit.hideAfter) {
                let split = this.courseTimelineEdit.hideAfter.split('-')
                return split[2] + '.' + split[1] + '.' + split[0]
            } else {
                return 'Deaktiviert'
            }
        },
        reqSignupText() {
            if (this.courseTimelineEdit.reqSignup) return 'Aktiviert'
            return 'Deaktiviert'
        },
        repeatingText() {
            if (this.courseTimelineEdit.repeating) return 'Aktiviert'
            return 'Deaktiviert'
        },
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
    },
}
</script>
<style lang="scss" scoped>
.inner-wrapper {
    overflow-y: auto;
    overflow-x: hidden;

    height: 100vh !important;

    max-width: 500px !important;
    padding-top: 90px !important;
    padding-bottom: 160px !important;
    margin: 0px auto;
}

.courseEditTimeline {
    .lottie-container-wrapper {
        max-height: 200px;
        overflow: auto;
    }
    .lottie-container {
        .lottie {
            border: 1px solid #dbdbdb !important;
            border-radius: 50%;
            /* background-color: var(--v-secondary)!important; */
            height: 60px;
            width: 60px;
            &.active {
                background-color: var(--v-primary) !important;
            }
        }
    }
    &__container {
        position: relative;
        &-back {
            position: fixed;
            top: 20px;
            left: 20px;
            z-index: 2;
        }
    }
}
</style>
