<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button v-show="changeStandort" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="form-group">
            <label for="studio_code">Centerauswahl</label>

            <v-select v-model="studiocode" item-text="text" item-value="value" :items="studios" item-te menu-props="auto" label="Center auswählen..." hide-details prepend-icon="mdi-map" single-line></v-select>

            <!----
            <input
              v-else
              id="studio_code"
              placeholder="Hier Studiocode eingeben"
              v-model="studiocode"
              type="text"
              class="form-control"
            /> ----->
            <div class="form-group text-danger" v-show="error">{{ error }}</div>
          </div>
          <div @click="logout" class="not-working"><span> Abmelden</span><span class="material-icons">login</span></div>
        </div>
        <div class="modal-footer">
          <button type="button" v-show="changeStandort" class="btn btn-secondary" data-dismiss="modal" @click.prevent="hide">
            Abbrechen
          </button>
          <button type="button" class="btn btn-primary" @click.prevent="save">
            Bestätigen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import api from '../api'

export default {
  data() {
    return {
      studiocode: null,
      error: '',
      changeStandort: false,
      rollen: {},
      studios: [],
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('studio')
      location.reload()
    },
    async show(force) {
      try {
        this.studiocode = await api.getUserStudio()

        this.studiocode = this.studiocode.studio.code
      } catch (e) {
        force = true
      }
      api.getStudios().then((studios) => {
        this.studios = []

        this.studios = studios.sort((s, s1) => {
          if (s.name == 'Livestreams') {
            return -1
          } else {
            if (s1.name == 'Livestreams') {
              return 1
            } else {
              return s.stat_filter - s1.stat_filter
            }
          }
        })

        this.studios = this.studios.map((s) => {
          if (s.name && s.code) return { text: s.name, value: s.code }
        })
      })
      if (!location.pathname.includes('login')) {
        if (force) {
          $(this.$refs['modal']).data('backdrop', 'static')
          $(this.$refs['modal']).data('keyboard', false)
          $(this.$refs['modal']).modal('show')
        } else {
          this.changeStandort = true
          $(this.$refs['modal']).modal('show')
        }
      }
    },
    hide() {
      $(this.$refs['modal']).modal('hide')
    },
    save() {
      localStorage.removeItem('kursliste')
      localStorage.removeItem('kursliste_labels')
      this.$emit('save-studio', {
        studio_code: this.studiocode,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: 17px;
  top: 18px;
}
.not-working {
  width: 95px;
  text-align: right;
  font-size: 10px;
  padding: 5px;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  line-height: 21px;
  margin-left: auto;

  span {
    padding-right: 5px;

    &.material-icons {
      font-size: 18px;
    }
  }
}
.modal-content {
  label {
    font-size: 20px;
    font-weight: bold;
    color: #313131;
    text-transform: uppercase;
    width: 100%;
  }
  select {
    width: 100%;
    padding: 20px 5px;
    font-weight: bold;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    button {
      text-transform: uppercase;
      width: 50%;
      font-size: 13px;
    }

    .btn-secondary {
      background-color: #313131;
    }
  }
}
</style>
