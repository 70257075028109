<template>
    <v-dialog persistent max-width="500" v-model="active">
        <v-card>
            <v-card-title class="text-h5 primary white--text"> Altersprüfung </v-card-title>

            <v-card-text class="mt-5">
                um sicherzustellen, dass du alt genug bist, um unseren Vertrag auszufüllen, müssen wir dein Alter überprüfen.
                Bist du bereits 18 Jahre alt? Falls nicht, benötigen wir die Unterstützung deiner gesetzlichen Vertreter.
                Vielen Dank für dein Verständnis!
            </v-card-text>

            <v-card-actions>
                <v-btn color="primary" text @click="acceptDialog()"> Vertreter nötig </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="acceptDialog(false)"> Volljährig</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'contractMinorPopup',
    props: {
        active: {
            type: Boolean,
        },
    },

    data() {
        return {
            contractMinorPopup: false,
        }
    },
    methods: {
        acceptDialog(status = true) {
            this.$emit('acceptDialog', status)
        },
    },
}
</script>

<style lang="scss" scoped>
</style>