<template>
	<v-row class="flex-column" no-gutters>
		<v-text-field filled rounded placeholder="Name des Medikaments" v-model="selectedHabit.title"></v-text-field>
		<div class="d-flex">
			<v-text-field filled rounded label="Dosierung" class="mr-2" v-model="selectedHabit.dosage"></v-text-field>
			<v-select filled rounded label="Einheit" class="ml-2" :items="possibleUnits" v-model="selectedHabit.unit"></v-select>
		</div>
		<v-row>
			<v-card-text class="h6 font-weight-bold">Wähle ein passendes Icon</v-card-text>
			<v-col cols="3" @click="activeIcon = icon" :class="{grayOverlay: activeIcon !== icon}" :key="('icon' + index)" v-for="(icon, index) in possibleIcons"><img  :src="'/img/lottiefiles/habit/mediTypes/' + icon" class="svg"></img></v-col>
		</v-row>
		<v-row>
			<habit-form-frequency-picker @reTriggerHeight="$emit('reTriggerHeight')" :selectedHabit="selectedHabit"></habit-form-frequency-picker>
		</v-row>
	</v-row>
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitFormFrequencyPicker from './habitFormFrequencyPicker.vue'
import api from '@/api.js'
import $ from 'jquery'

export default {
    name: 'habitWakeUpForm',
    components: {
        habitFormFrequencyPicker,
    },
    props: {
        selectedHabit: {
            type: Object,
            required: true,
        },
        edit: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeIcon: null,
            possibleIcons: [
                'capsules.svg',
                'tube.svg',
                'syrup.svg',
                'oil.svg',
                'inhale.svg',
                'vaccin.svg',
                'spray.svg',
                'plaster.svg',
            ],
            possibleUnits: ['g', 'mg', 'µg', 'ml', 'Stk', 'Tropfen'],
            wakeUpTime: null,
            wakeUpTimeWeekend: null,
            weekendDifferent: false,
            weekdays: [
                { label: 'Mo', active: false },
                { label: 'Di', active: false },
                { label: 'Mi', active: false },
                { label: 'Do', active: false },
                { label: 'Fr', active: false },
                { label: 'Sa', active: false },
                { label: 'So', active: false },
            ],
        }
    },
    async created() {
        //https://stackoverflow.com/questions/11978995/how-to-change-color-of-svg-image-using-css-jquery-svg-image-replacement fookn genius
        /*	evtl. edit gad so überneh
	  if (this.edit) {
			this.wakeUpTime = this.selectedHabit.days[0].time
			this.wakeUpTimeWeekend = this.selectedHabit.days[6].time
			if (this.wakeUpTime !== this.wakeUpTimeWeekend) {
				this.weekendDifferent = true
			}
		} */
    },
    mounted() {
        /* $('img.svg').each(function() {
			var $img = $(this)
			var imgID = $img.attr('id')
			var imgClass = $img.attr('class')
			var imgURL = $img.attr('src')

			$.get(
				imgURL,
				function(data) {
					// Get the SVG tag, ignore the rest
					var $svg = $(data).find('svg')

					// Add replaced image's ID to the new SVG
					if (typeof imgID !== 'undefined') {
						$svg = $svg.attr('id', imgID)
					}
					// Add replaced image's classes to the new SVG
					if (typeof imgClass !== 'undefined') {
						$svg = $svg.attr('class', imgClass + ' replaced-svg')
					}

					// Remove any invalid XML tags as per http://validator.w3.org
					$svg = $svg.removeAttr('xmlns:a')

					// Replace image with new SVG
					$img.replaceWith($svg)
				},
				'xml'
			)
		}) */
    },
    methods: {
        async saveNewHabit() {
            for (let i = 0; i < 7; i++) {
                this.selectedHabit.days[i] = {}
                this.selectedHabit.days[i].time = this.wakeUpTime
            }
            if (await this.selectedHabit.save()) {
                // save habit to list
                console.log('saved')
                this.$emit('habitSaved')
            } else {
                // habit not saved
            }
        },
    },
    computed: {
        anyWeekdayActive() {
            return this.weekdays.some((weekday) => weekday.active)
        },
        allowedHours() {
            return Array.from({ length: 12 }, (_, i) => i + 1)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-time-picker-clock__ampm {
    display: none;
}
.active {
    background: #f5f5f5;
}

.grayOverlay {
    filter: grayscale(1);
}
</style>
