<template>
	<div class="kursstatistik">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Kurs Übersicht</span>
		</div>
		<div v-show="filter.kurs_id !== 0" class="zurueck" @click="filter.kurs_id = 0">
			zurück zur Kursauswahl
		</div>
		<div v-show="filter.kurs_id !== 0" class="zeitauswahl">
			<v-md-date-range-picker @change="loadKursStatistik" :start-date="zeitauswahl.start" :end-date="zeitauswahl.end"></v-md-date-range-picker>
		</div>
		<div v-show="filter.kurs_id !== 0" class="kursstatistik_charts">
			<canvas id="kursstatistik_normal" width="400" height="400"></canvas>
		</div>

		<div v-show="filter.kurs_id == 0" class="select-kurs">
			<span>Bitte wähle einen Kurs aus, um die Statistik einzusehen:</span>
			<div class="kurs-wrapper">
				<div class="search">
					<input placeholder="Suche..." v-on:input="filterCourses" type="text" v-model="search" />
					<span class="material-icons">search</span>
				</div>
				<ul id="kursliste">
					<li v-for="(course, course_id) in courses" :key="course_id">
						<div class="kursname">
							<span>{{ course.name }}</span>
						</div>
						<div @click="changeFilter(course._id)" class="link-wrapper">
							<span class="material-icons">insights</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'

export default {
	data() {
		return {
			filter: {
				ts: Math.round(new Date() / 1000 - 604000),
				ts_end: Math.round(new Date() / 1000),
				kurs_id: 0,
			},
			coursesloaded: [],
			courses: [],
			search: '',
			zeitauswahl: {
				start:
					new Date(Math.round(new Date() / 1000 - 604000) * 1000).getFullYear() +
					'-' +
					(new Date(Math.round(new Date() / 1000 - 604000) * 1000).getMonth() + 1) +
					'-' +
					new Date(Math.round(new Date() / 1000 - 604000) * 1000).getDate(),
				end: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
			},
		}
	},
	methods: {
		filterCourses() {
			this.courses = this.coursesloaded.filter((course) => {
				return course.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
			})
		},
		changeFilter(kurs_id) {
			this.filter.kurs_id = kurs_id
			this.loadKursStatistik()
		},
		loadKursStatistik(x) {
			if (x) {
				this.filter.ts = Math.round(new Date(x[0]) / 1000)
				this.filter.ts_end = Math.round(new Date(x[1]) / 1000)
			}

			api.getKursStatistik(this.filter).then((data) => {
				let m = 30
				var ctx = document.getElementById('kursstatistik_normal').getContext('2d')
				let labels = []
				let dset = []
				let dset_l = []
				for (let n in data.normal) {
					labels.push(new Date(n * 1000).toLocaleString('de-DE'))
					dset.push(data.normal[n])
					if (data.normal[n] > m) {
						m = data.normal[n] + 10
					}
				}
				let dsetts = []

				if (data.livestream) {
					for (let n in data.livestream) {
						dset_l.push(data.livestream[n])
						if (data.livestream[n] > m) {
							m = data.livestream[n] + 10
						}
					}
					dsetts = [
						{
							label: 'Normal',
							data: dset,
							fill: false,
							borderColor: 'rgb(82, 174, 50)',
							tension: 0.1,
						},
						{
							label: 'Livestream',
							data: dset_l,
							fill: false,
							borderColor: 'rgb(53, 112, 245)',
							tension: 0.1,
						},
					]
				} else {
					dsetts = [
						{
							label: 'Normal',
							data: dset,
							fill: false,
							borderColor: 'rgb(82, 174, 50)',
							tension: 0.1,
						},
					]
				}

				var myChart = new Chart(ctx, {
					type: 'line',
					data: {
						labels: labels,
						datasets: dsetts,
					},
					options: {
						scales: {
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
										stepSize: 1,
										max: m,
									},
								},
							],
						},
					},
				})
			})
		},
	},
	created() {
		api.getKurse()
			.then((data) => {
				this.coursesloaded = data.course
				this.filterCourses()
			})
			.catch((err) => {
				console.log(err)
			})

		console.log('Kursstatistik')
	},
}
</script>
<style lang="scss" scoped>
.zeitauswahl {
	text-align: center;
	margin: 15px 0px;
}
.kursstatistik_charts {
	padding: 0px 15px;
	margin-bottom: 500px;
}
.zurueck {
	text-align: center;
	margin-bottom: 40px;
	padding: 10px 0px;
	background-color: var(--v-primary);
	color: #fff;
	font-weight: bold;
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
.select-kurs {
	padding: 0px 15px;
	display: flex;
	flex-direction: column;
	.search {
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: 0px 25px 0px 0px;
		margin-top: 15px;
		margin-bottom: 0px;
		input {
			width: 100%;
			padding: 10px 5px;
		}
		.material-icons {
			margin: auto 0px;
		}
	}
}

#kursliste {
	display: flex;
	flex-direction: column;
	padding: 10px;

	li {
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 5px 0px;
		border-bottom: 1px solid black;
		.kursname {
			display: flex;
			span {
				display: block;
				margin: auto 0px;
			}
		}
		.material-icons {
			padding: 15px;
		}
	}
}
</style>
