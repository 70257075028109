<template>
    <v-row class="dashboard">
        <v-col cols="12">
            <v-btn @click="$router.push('/workouts/createnew')">testing</v-btn>
            <v-expansion-panels dark>
                <v-expansion-panel v-for="(studio, i) of studios" :key="i + '-studio'">
                    <v-expansion-panel-header>
                        {{ studio.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-text-field filled label="Name" type="text" v-model="studio.name"></v-text-field>
                        <v-text-field filled label="Code" type="text" v-model="studio.code"></v-text-field>
                        <v-text-field filled label="Adresse" type="text" v-model="studio.adresse"></v-text-field>
                        <v-text-field filled label="Kanton" type="text" v-model="studio.canton"></v-text-field>
                        <v-text-field filled label="Google Maps (URL)" type="text" v-model="studio.maps"></v-text-field>
                        <v-text-field filled label="Telefon" type="text" v-model="studio.telefon"></v-text-field>
                        <v-text-field filled label="Email" type="email" v-model="studio.email"></v-text-field>
                        <v-text-field filled label="Webseite (url)" type="url" v-model="studio.website"></v-text-field>
                        <v-text-field filled label="Firma" type="text" v-model="studio.art"></v-text-field>
                        <v-text-field filled label="Logo" type="text" v-model="studio.logo"></v-text-field>
                        <v-text-field
                            filled
                            label="Instagram (url)"
                            type="url"
                            v-model="studio.instagram"
                        ></v-text-field>
                        <v-text-field filled label="Facebook (url)" type="url" v-model="studio.facebook"></v-text-field>
                        <v-text-field filled label="Mietläche" type="number" v-model="studio.qm"></v-text-field>
                        <v-text-field
                            filled
                            label="Latitude"
                            type="number"
                            v-model="studio.location.lat"
                        ></v-text-field>
                        <v-text-field
                            filled
                            label="Longitude"
                            type="number"
                            v-model="studio.location.long"
                        ></v-text-field>
                        <v-text-field
                            filled
                            label="Center ID / ZSR Nummer (KK Bestätigung)"
                            type="text"
                            v-model="studio.healtcareConfirmationNumber"
                        ></v-text-field>
                        <v-text-field
                            filled
                            label="TAC PropertyID"
                            type="number"
                            v-model="studio.tacPropertyId"
                        ></v-text-field>
                        <v-text-field
                            filled
                            label="TAC ResortID"
                            type="number"
                            v-model="studio.tacResortId"
                        ></v-text-field>
                        <v-btn color="primary" @click="saveStudio(studio)" large>Speichern</v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <v-btn @click="$router.push({ name: 'kfjasdklfjakldsfjklsf' })">Test register</v-btn>
        <v-col cols="12" class="d-flex justify-center my-5">
            <v-btn color="primary" @click="addStudio" large class="">Hinzufügen</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import api from '../../api'

import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'studioEditor',
    data() {
        return {
            studios: [],
            studio: {
                name: 'Neues Studio',
                canton: '',
                code: '',
                adresse: '',
                maps: '',
                telefon: '',
                email: '',
                website: '',
                art: '',
                logo: '',
                instagram: '',
                facebook: '',
                qm: 0,
                location: {
                    lat: 0,
                    long: 0,
                },
                healtcareConfirmationNumber: '',
                tacPropertyId: '',
                tacResortId: '',
            },
        }
    },
    created() {
        this.fetchStudios()
    },
    methods: {
        addStudio() {
            this.studios.push(this.studio)
        },
        async saveStudio(studio) {
            let studioA = await api.updateStudio(studio)
        },
        async fetchStudios() {
            let studios = await api.fetchStudios()

            studios.forEach((studio) => {
                if (studio.code !== 'ot') {
                    if (!studio.location) studio.location = { lat: 0, long: 0 }
                    if (!studio.healtcareConfirmationNumber) studio.healtcareConfirmationNumber = ''
                    if (!studio.tacResortId) studio.tacResortId = ''
                    if (!studio.tacPropertyId) studio.tacPropertyId = ''
                    this.studios.push(studio)
                }
            })
        },
    },

    computed: {},
}
</script>
<style lang="scss" scoped>
.dashboard {
}
</style>
