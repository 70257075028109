<template>
	<div class="workingOutStep__card">
		<lottie-player
			mode="normal"
			v-if="loading"
			class="workingOutStep__card-lottie-player"
			name="loading"
			:autoplay="false"
			src="/img/lottiefiles/dumbleIcon.json"
		></lottie-player>
		<div v-if="!loading" class="workingOutStep__card__wrapper justify-space-between d-flex flex-column">
			<v-card-title class="workingOutStep__card-title primary--text h5 justify-center"> {{ title }}</v-card-title>
			<div class="workingOutStep__card__step-wrapper">
				<v-form class="workingOutStep__card-form fill-height mx-5">
					<div class="fill-height" v-if="(hasWeight || hasReps) && !hasStopwatch">
						<v-card-text class="workingOutStep__card-form-text h6 d-flex justify-space-between align-center">
							<Picker :options="repsOptions" v-model="form.reps"></Picker>
						</v-card-text>

						<v-card-text class="workingOutStep__card-form-text-weight  h6 d-flex justify-space-between align-center">
							<v-text-field type="number" suffix="kg" v-model="form.weight"></v-text-field>
						</v-card-text>
					</div>
					<v-textarea
						v-else-if="isSensoPro"
						v-model="form.note"
						auto-grow
						:placeholder="textFieldPlaceholder"
						background-color="rgba(82,174,50, 0.1)"
					>
					</v-textarea>

					<working-out-step-stopwatch v-else @saveTime="saveTime"></working-out-step-stopwatch>
				</v-form>
			</div>

			<v-card-actions class="workingOutStep__card-actions justify-center">
				<v-btn color="grey" class="text-white" outlined rounded @click="emitClose">
					Abbrechen
				</v-btn>
				<v-btn color="primary" class="text-white" rounded @click="saveNewStep">
					{{ submitButtonText }}
				</v-btn>
			</v-card-actions>
		</div>
	</div>
</template>

<script>
import WorkingOutStepStopwatch from './WorkingoutStepStopwatch.vue'
import { mapState, mapActions } from 'vuex'
import Picker from 'vue-wheel-picker'

export default {
	name: 'workingOutStep',
	components: {
		Picker,
		WorkingOutStepStopwatch,
	},
	props: {
		exercise: {
			type: Object,
			required: true,
		},
		stepCount: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			form: {
				exercise_id: this.exercise._id,
				plan_id: this.$route.params.id,
				set: this.stepCount,
				date: this.$moment().unix(),
				reps: { value: 8 },
				note: '',
				weight: 0,
				time: '',
			},
			loading: true,
			repsOptions: [],
			title: '',
			submitButtonText: '',
			textFieldPlaceholder: 'Gib hier deine Notizen zur ausgeführten Übung ein.',
		}
	},
	mounted() {
		let times = 31
		for (let i = 0; i < times; i++) {
			this.repsOptions.push({
				value: i,
				text: i + ' Wiederholungen',
			})
		}
		if (this.hasReps) {
			this.form.reps.value = parseInt(this.exercise.tp_exercise_settings.find((es) => es.title === 'Min Wiederholungen').value)
		}

		if (this.hasWeight) {
			let lastWorkout = this.workoutStepsHistory.reverse().find((workout) => workout.exercise_id === this.exercise._id)
			if (lastWorkout && lastWorkout.steps[this.stepCount - 1]) {
				this.form.weight = lastWorkout.steps[this.stepCount - 1].weight
			} else {
				this.form.weight = parseInt(this.exercise.tp_exercise_settings.find((es) => es.title === 'Gewicht').value)
			}
		}
		if (this.hasWeight || this.hasReps) {
			this.title = 'Satz ' + this.stepCount + ' erfassen'
			this.submitButtonText = 'Satz eintragen'
		} else if (this.exercise.title === 'Senso Pro') {
			this.title = 'Notiz erfassen'
			this.submitButtonText = 'Notiz eintragen'
			if (this.finishedSteps.find((exercise) => exercise.exercise_id === this.exercise._id)) {
				this.form.note = this.finishedSteps.find((exercise) => exercise.exercise_id === this.exercise._id).note
			}
		} else {
			this.title = 'Zeit erfassen'
			this.submitButtonText = 'Zeit eintragen'
		}
		this.loading = false
	},
	methods: {
		...mapActions('workingoutStore', ['addNote', 'addNewStep']),
		emitClose() {
			this.$emit('closeNewStep')
		},
		saveNewStep() {
			if (this.form.time) {
				this.form.weight = null
				this.form.reps.value = null
			}
			this.form.note ? this.addNote(this.form) : this.addNewStep(this.form)
			this.emitClose()
		},
		saveTime(time) {
			this.form.time = time
		},
		findSettingByTitle(title) {
			return this.exercise.tp_exercise_settings.find((es) => es.title === title)
		},
	},
	computed: {
		...mapState('workingoutStore', ['finishedSteps', 'workoutStepsHistory']),
		hasWeight: function() {
			return this.findSettingByTitle('Gewicht') && this.findSettingByTitle('Gewicht').value
		},
		hasReps: function() {
			return this.findSettingByTitle('Min Wiederholungen') && this.findSettingByTitle('Min Wiederholungen').value
		},
		hasStopwatch: function() {
			if (this.findSettingByTitle('Dauer') && this.findSettingByTitle('Dauer').value && !this.isSensoPro) return true
		},
		isSensoPro: function() {
			return this.exercise.title === 'Senso Pro'
		},
	},
}
</script>

<style lang="scss" scoped>
.v-application ul {
	padding-left: 0;
}
.picker {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: center;
	color: rgba(0, 0, 0, 0.7);
}

::v-deep .picker_chosen {
	background: var(--v-primary);
	color: white;
	padding-left: 0;
	min-width: 70%;
	max-width: 100%;
	border-radius: 20px;
	font-weight: bold;
}
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOutStep__card {
	bottom: 0;
	background-color: white;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 6px solid var(--v-primary) !important;

	&-lottie-player {
		display: unset;
	}

	&-form-text {
		height: 45%;

		&-weight {
			margin: auto;
			height: 45%;
			width: 30%;
			min-width: 50px;
		}
	}

	&__wrapper {
		height: 100%;
	}

	&__step-wrapper {
		height: 60vh;
		overflow-y: auto;
	}

	&-title {
		position: sticky;
	}

	&-actions {
		position: sticky;
		bottom: 0;
	}
}
</style>
