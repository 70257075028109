import { render, staticRenderFns } from "./UebungenSteps.vue?vue&type=template&id=04a063fa&scoped=true"
import script from "./UebungenSteps.vue?vue&type=script&lang=js"
export * from "./UebungenSteps.vue?vue&type=script&lang=js"
import style0 from "./UebungenSteps.vue?vue&type=style&index=0&id=04a063fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a063fa",
  null
  
)

export default component.exports