<template>
	<div class="mailCampaign d-flex flex-column">
		<v-btn class="ml-auto primary pa-6" @click="createCampaign" icon><v-icon color="white">mdi-shape-square-rounded-plus</v-icon></v-btn>
		<v-btn max-width="200px" class="mx-auto mb-5" @click="signupCustomerDialog = true" color="primary">Kunde anmelden</v-btn>

		<v-dialog class="mailCampaign__dialog" v-model="showEditDialog" persistent max-width="800px">
			<v-card max-height="70vh" class="overflow-y-auto d-flex flex-column ">
				<v-form ref="campaignForm">
					<v-card-title v-if="campaign && campaign.title" class="mailCampaign__dialog-title">{{ campaign.title }}</v-card-title>
					<v-card-title v-else class="mailCampaign__dialog-title">Neue Kampagne erstellen</v-card-title>
					<v-text-field
						:rules="[rules.required]"
						class="mx-5 mt-3"
						v-model="campaign.title"
						label="Titel (Interne Bezeichnung)"
						placeholder="darmFIT"
					></v-text-field>
					<v-select :rules="[rules.required]" class="mx-5" v-model="campaign.interval" :items="intervals" label="Intervall"></v-select>
					<v-select
						:rules="[rules.required]"
						v-if="!campaign.sendFirstMail"
						class="mx-5"
						v-model="campaign.weekday"
						:items="weekdays"
						label="Wochentag"
					></v-select>
					<v-dialog ref="dialog" v-model="timeModal" :return-value.sync="campaign.time" persistent width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								class="mx-5"
								v-model="campaign.time"
								:rules="[rules.required]"
								label="Uhrzeit"
								prepend-icon="mdi-clock-time-four-outline"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-time-picker format="24hr" v-if="timeModal" v-model="campaign.time" full-width>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="timeModal = false">
								Cancel
							</v-btn>
							<v-btn text color="primary" @click="$refs.dialog.save(campaign.time)">
								OK
							</v-btn>
						</v-time-picker>
					</v-dialog>
					<v-switch class="mx-5" v-model="campaign.sendFirstMail" label="Kundenanmeldung startet Kampagne"></v-switch>
					<v-select :rules="[rules.required]" class="mx-5" v-model="campaign.email" :items="emails" label="E-Mail Adresse"></v-select>
					<v-text-field
						:rules="[rules.required]"
						class="mx-5 mt-3"
						v-model="campaign.from"
						label="Name des Versenders"
						placeholder="Darmpflege"
					></v-text-field>
					<v-select class="mx-5" v-model="campaign.templates" :items="templates" attach chips label="Templates" multiple></v-select>
					<v-card-text v-show="campaign.templates.length">Insgesamt werden {{ campaign.templates.length }} Mails versendet.</v-card-text>
					<div class="d-flex flex-column">
						<v-text-field
							v-for="(email, i) of campaign.subjects"
							:key="i"
							class="mx-5 mt-3"
							v-model="campaign.subjects[i]"
							label="Betreff"
							placeholder="Darmpflege Start"
							:rules="[rules.required]"
						></v-text-field>
					</div>
					<v-card-actions class="mailCampaign__dialog-actions">
						<v-btn color="grey darken-1" text @click="showEditDialog = false">
							Abbrechen
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn :loading="loading" color="primary darken-1" text @click="saveCampaign">
							Speichern
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<v-dialog class="mailCampaign__dialog" v-model="signupCustomerDialog" persistent max-width="500px">
			<v-card max-height="70vh" class="overflow-y-auto d-flex flex-column ">
				<v-form ref="signupForm">
					<v-card-title class="mailCampaign__dialog-title ">Kunde anmelden</v-card-title>
					<user-search
						v-if="!signup.unregisteredUser.email"
						class="mx-5 mt-3"
						ref="userSearch"
						rights="a_add"
						label="Kundensuche"
						@update="pickUser"
						returnUser
					></user-search>
					<v-card-subtitle v-if="!signup.user && !signup.unregisteredUser.email">oder</v-card-subtitle>
					<v-text-field
						v-if="!signup.user"
						class="mx-5"
						v-model="signup.unregisteredUser.email"
						label="E-Mail"
						:rules="[rules.required2, rules.email]"
						placeholder="test@mail.com"
					></v-text-field>
					<v-text-field
						v-if="!signup.user"
						class="mx-5"
						v-model="signup.unregisteredUser.name"
						label="Name"
						:rules="[rules.required2]"
						placeholder="Markus Babus"
					></v-text-field>

					<v-select
						class="mx-5"
						v-model="signup.campaign"
						:items="campaigns"
						item-text="title"
						item-value="_id"
						:rules="[rules.required]"
						label="Kampagne"
					></v-select>
					<v-card-actions class="mailCampaign__dialog-actions">
						<v-btn color="grey darken-1" text @click="signupCustomerDialog = false">
							Abbrechen
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn :loading="loading" color="primary darken-1" text @click="signupCustomer">
							Anmelden
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<div class="mailCampaign__list d-flex flex-row flex-wrap justify-center">
			<v-card
				min-width="200px"
				width="300px"
				@click="editCampaign(entry)"
				v-for="entry of campaigns"
				:key="entry._id"
				class="mailCampaign__list-entry mb-5 pb-5 mx-2"
			>
				<v-card-title class="">{{ entry.title }}</v-card-title>
				<v-card-subtitle class="">{{ entry.interval }} {{ entry.weekday }} um {{ entry.time }} Uhr</v-card-subtitle>
				<v-card-subtitle v-if="entry.email" class="">{{ entry.email }}</v-card-subtitle>
			</v-card>
		</div>
	</div>
</template>

<script>
import api from '../../api'
import userSearch from '../../components/userSearch.vue'
export default {
	name: 'mailCampaign',
	components: { userSearch },
	data() {
		return {
			rules: {
				required: (value) => !!value || 'Pflichtfeld.',
				required2: (value) => !(!value || !signup.user) || 'Pflichtfeld.',
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'ungültige e-mail.'
				},
			},
			loading: false,
			showEditDialog: false,
			signupCustomerDialog: false,
			timeModal: false,
			emails: ['darmpflege@wellcomefit.ch'],
			templates: ['darmFIT1', 'darmFIT2', 'darmFIT3', 'darmFIT4'],
			intervals: ['Täglich', 'Wöchentlich', 'Monatlich'],
			weekdays: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
			signup: {
				campaign: null,
				user: null,
				unregisteredUser: {
					name: null,
					email: null,
				},
			},
			campaigns: [],
			campaign: {
				time: null,
				title: '',
				templates: [],
				subjects: [],
				weekday: '',
				interval: '',
				sendFirstMail: false,
				email: '',
				from: '',
			},
		}
	},
	async created() {
		this.campaigns = await api.fetchMailCampaigns()
	},
	methods: {
		initCampaign() {
			this.campaign = {
				time: null,
				title: '',
				templates: [],
				subjects: [],
				weekday: '',
				interval: '',
				sendFirstMail: false,
				email: '',
				from: '',
			}
		},
		pickUser(user) {
			this.signup.user = user._id
		},
		createCampaign() {
			this.initCampaign()
			this.showEditDialog = true
		},
		editCampaign(campaign) {
			this.campaign = campaign
			this.showEditDialog = true
		},
		async saveCampaign() {
			if (!this.$refs.campaignForm.validate()) return false
			this.showEditDialog = false
			if (this.campaign.sendFirstMail) this.campaign.weekday = null
			let status = await api.createMailCampaign(this.campaign)
		},
		async signupCustomer() {
			let status = null
			if (this.$refs.signupForm.validate()) status = await api.signupMailCampaign(this.signup)
		},
	},

	watch: {
		'campaign.templates': function(newVal, oldVal) {
			for (let x = 0; x < newVal.length; x++) {
				if (!this.campaign.subjects[x]) this.campaign.subjects[x] = this.campaign.templates[x]
			}

			if (this.campaign.subjects.length > this.campaign.templates.length)
				this.campaign.subjects = this.campaign.subjects.slice(0, this.campaign.templates.length - this.campaign.subjects.length)
		},
	},
}
</script>

<style lang="scss" scoped>
.mailCampaign {
	&__dialog {
		position: relative;
		&-title {
			position: sticky;
			top: 0;
			background-color: white;
			border-bottom: 2px solid var(--v-primary);
			z-index: 9;
		}
		&-actions {
			position: sticky;
			bottom: 0;
			background-color: white;
			border-top: 2px solid var(--v-primary);
		}
	}
}
</style>
