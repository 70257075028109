<template>
    <v-card flat>
        <v-row v-if="profileResults.length === 0" no-gutters>
            <v-col cols="12">
                <v-card-title>TAC Suche</v-card-title>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Vorname" v-model="searchValues.FirstName"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Nachname" v-model="searchValues.LastName"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field label="E-Mail" v-model="searchValues.EMail"></v-text-field>
            </v-col>
            <v-col v-if="false" class="mb-5" align="center" cols="12">
                <v-btn @click="initSearch" text color="primary">Zurücksetzen</v-btn>
            </v-col>
            <v-col align="center" cols="12">
                <v-btn @click="searchTACProfile" :loading="searching" color="primary">Suchen</v-btn>
            </v-col>
            <v-col class="mt-5" align="center" cols="12" v-if="noProfileFound">
                <v-alert type="info">
                    Es wurde kein Profil gefunden. Starte eine manuelle Suche oder erstelle das Kundenprofil
                </v-alert>

                <v-btn color="primary" @click="createNewUser">Neues Konto erstellen</v-btn>
            </v-col>
        </v-row>
        <v-row v-else no-gutters>
            <v-col cols="12">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Profil ID</th>
                                <th class="text-left">Name</th>
                                <th class="text-left">Geb</th>

                                <th class="text-left">Stadt</th>
                                <th class="text-left">Strasse</th>
                                <th class="text-left">roody E-Mail / Kundenkonto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                @click="openProfile(item)"
                                v-for="(item, index) of profileResults"
                                :key="index + '-searchResult'"
                            >
                                <td>{{ getProfileID(item) }}</td>
                                <td>
                                    {{ item.Customer.PersonName.FirstName }} {{ item.Customer.PersonName.LastName }}
                                </td>
                                <td>{{ $moment(item.Customer.birthDate).format('DD.MM.YYYY') }}</td>
                                <td>
                                    <span v-if="item.Addresses && item.Addresses.NameAddress">
                                        {{ parseCustomerAddress(item.Addresses.NameAddress).PostalCode }}
                                        {{ parseCustomerAddress(item.Addresses.NameAddress).CityName }}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span v-if="item.Addresses && item.Addresses.NameAddress">
                                        {{ parseCustomerAddress(item.Addresses.NameAddress).AddressLine1 }}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span v-if="item.Customer.AccountName">{{ item.Customer.AccountName }}</span>
                                    <span v-else>-</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col align="center" cols="12" class="mt-5">
                <v-btn text color="primary" @click="resetSearch">Manuelle Suche</v-btn>
            </v-col>
            <v-col v-if="!hideCreateButton" align="center" cols="12" class="mt-5">
                <v-btn color="primary" @click="createNewUser">Neues Konto erstellen</v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from '@/api'
export default {
    props: {
        user: {
            type: Object,
            default: null,
        },
        hideEmail: {
            type: Boolean,
            default: false,
        },
        hideCreateButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localUser: null,
            searching: false,
            loading: true,
            searchValues: {
                FirstName: 'do',
                LastName: 'am',
                EMail: '',
                ProfileID: '',
            },
            profileResults: [],
            noProfileFound: true,
        }
    },
    created() {
        if (this.user) {
            this.localUser = JSON.parse(JSON.stringify(this.user))

            this.searchValues = {
                FirstName: this.localUser.FirstName.replace(/\s+/g, '').toLowerCase().substring(0, 4),
                LastName: this.localUser.LastName.replace(/\s+/g, '').toLowerCase().substring(0, 4),
                EMail: '',
                ProfileID: '',
            }
            this.searchTACProfile()
        }
        this.loading = false
    },
    methods: {
        createNewUser() {
            this.$emit('createNewUser')
        },
        getProfileID(user) {
            return user.IDs?.UniqueID?.find((e) => e.source === 'TAC')?.value || '-'
        },
        openProfile(user) {
            let tacId = user.IDs.UniqueID.find((e) => e.source === 'TAC')
            if (tacId && tacId.value) {
                this.$emit('selectUser', tacId.value)
                this.$emit('selectUserAllInformation', user)
            } else {
                this.$toast.error('Keine TAC ID gefunden')
            }
        },
        parseCustomerAddress(adress) {
            let parsedAddress = {
                PostalCode: '',
                CityName: '',
                AddressLine1: '',
                CountryCode: '',
            }

            let homeAddress = adress.find((e) => e.addressType === 'HOME')
            if (homeAddress) {
                parsedAddress.PostalCode = homeAddress.PostalCode || ''
                parsedAddress.CityName = homeAddress.CityName || ''
                parsedAddress.AddressLine1 = homeAddress.AddressLine1 || ''
                parsedAddress.CountryCode = homeAddress.CountryCode || ''
            }
            return parsedAddress
        },
        resetSearch() {
            this.initSearch()
            this.profileResults = []
        },
        async searchTACProfile() {
            this.noProfileFound = false
            this.profileResults = []
            this.searching = true
            let data = this.searchValues
            try {
                let profiles = await api.searchTACProfiles(data)
                if (profiles.Result.code === '183') {
                    this.$toast.error('Keine Profile gefunden')
                    this.noProfileFound = true
                    this.profileResults = []
                }
                if (profiles.Profiles?.Profile.length > 0) {
                    let tempProfiles = profiles.Profiles.Profile.map((e) => e.Profile.Profile)
                    this.profileResults = tempProfiles
                }
            } catch (e) {
                this.$toast.error('Fehler beim Suchen')
            }
            this.searching = false
        },
        initSearch() {
            this.searchValues = {
                FirstName: '',
                LastName: '',
                EMail: '',
                ProfileID: '',
            }
        },
    },
}
</script>