<template>
    <v-card flat>
        <v-row v-if="!loading && userData" no-gutters>
            <v-col class="mb-5" cols="12" v-if="userDataToCopy">
                <v-row>
                    <v-col class="my-0 py-0" v-for="fieldKey of copyableFields" :key="fieldKey + '-key'" cols="6">
                        <v-checkbox
                            v-if="userDataToCopy[fieldKey]"
                            class="my-0 py-0"
                            hide-details
                            v-model="copyFields"
                            :label="userDataToCopy[fieldKey]"
                            :value="fieldKey"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="copyToProfile" color="primary">ins Profil übertragen</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-select label="Geschlecht" :items="['MALE', 'FEMALE']" v-model="userData.Customer.gender"></v-select>
            </v-col>
            <v-col cols="12">
                <v-select
                    label="Anrede"
                    :items="['Herr', 'Frau']"
                    v-model="userData.Customer.PersonName.Salutation"
                ></v-select>
            </v-col>
            <v-col cols="12">
                <v-text-field label="Geburtstag" type="date" v-model="userData.Customer.birthDate"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Vorname" v-model="userData.Customer.PersonName.FirstName"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Nachname" v-model="userData.Customer.PersonName.LastName"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    type="email"
                    label="Kundenkonto (roody Verknüpfung)"
                    v-model="userData.Customer.AccountName"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').AddressLine1"
                    label="Strasse"
                ></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-select
                    :items="['CH', 'DE', 'FR', 'IT']"
                    v-model="userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').CountryCode"
                    label="Land"
                ></v-select>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    type="number"
                    v-model="userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').PostalCode"
                    label="PLZ"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').CityName"
                    label="Ort"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="userData.Phones.NamePhone.find((e) => e.phoneType === 'HOME').PhoneData.PhoneNumber"
                    label="Telefon"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="userData.Phones.NamePhone.find((e) => e.phoneType === 'MOBILE').PhoneData.PhoneNumber"
                    label="Mobil"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    type="email"
                    v-model="userData.Phones.NamePhone.find((e) => e.phoneType === 'EMAIL').PhoneData.PhoneNumber"
                    label="E-Mail"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="userData.Phones.NamePhone.find((e) => e.phoneType === 'WEB').PhoneData.PhoneNumber"
                    label="Web (ges. Vertreter)"
                ></v-text-field>
            </v-col>
            <v-col align="center" cols="12">
                <v-btn :loading="saving" color="primary" @click="updateProfile">
                    <span v-if="contractMapMode && userDataFromContract">Erstellen und Auswählen</span>
                    <span v-else-if="contractMapMode">Speichern und Auswählen</span>
                    <span v-else>Speichern</span>
                </v-btn>
            </v-col>
            <v-col v-if="showBackToSearchButton" class="mt-5" cols="12" align="center">
                <v-btn @click="$emit('toSearch')" text>zurück zur Suche</v-btn>
            </v-col>
        </v-row>
        <v-row v-else no-gutters>
            <v-col cols="12" align="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-dialog max-width="500px" v-model="showAccountNameDialog">
            <v-card flat v-if="showAccountNameDialog">
                <v-card-title style="background-color: var(--v-primary)" class="white--text">
                    Roody Verknüpfung
                </v-card-title>

                <v-card-text class="mt-5 pb-0">
                    <span v-if="userData.Customer.AccountName.toString() === ''">
                        Aktuell ist kein Kundenkonto bei diesem Profil hinterlegt
                    </span>
                    <span
                        v-if="
                            !(userData.Customer.AccountName.toString() === '') &&
                            userData.Customer.AccountName.toLowerCase().toString() !==
                                accountNameIfNotGiven.toLowerCase()
                        "
                    >
                        Aktuell ist folgendes Kundenkonto beim Profil hinterlegt: {{ userData.Customer.AccountName }}
                    </span>
                </v-card-text>
                <v-card-text class="mt-5 pb-0">
                    Darum wird roody nicht in der Lage sein, das TAC Konto des Kunden mit dem roody Konto zu Verknüpfen.
                </v-card-text>
                <v-card-text class="mt-5 pb-5 font-weight-bold">
                    Möchtest du die E-Mail des Vertrags ({{ accountNameIfNotGiven }}) in das TAC Kundenprofil
                    (Kundenkonto) übernehmen?
                </v-card-text>

                <v-card-actions>
                    <v-btn text @click="showAccountNameDialog = false">Nicht übernehmen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="
                            () => {
                                userData.Customer.AccountName = accountNameIfNotGiven.toLowerCase()
                                showAccountNameDialog = false
                            }
                        "
                        color="primary"
                    >
                        übernehmen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import api from '@/api'
export default {
    props: {
        contractMapMode: {
            type: Boolean,
            default: false,
        },
        showBackToSearchButton: {
            type: Boolean,
            default: false,
        },

        userEmail: {
            type: String,
            default: null,
        },
        accountNameIfNotGiven: {
            type: String,
            default: null,
        },
        userTACId: {
            type: String,
            default: null,
        },
        userDataFromContract: {
            type: Object,
            default: null,
        },
        userDataToCopy: {
            type: Object,
            default: null,
        },
        resortId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            viewMode: false,
            saving: false,
            loading: true,
            ResortId: null,
            userData: null,
            showAccountNameDialog: false,
            newAccount: false,
            copyableFields: ['firstName', 'lastName', 'email', 'phone', 'birthday', 'street', 'zip', 'city'],
            copyFields: ['firstName', 'lastName', 'email', 'phone', 'birthday', 'street', 'city', 'zip'],
        }
    },
    created() {
        //this.viewMode = false
        if (this.userEmail) {
            this.fetchUserAccount(this.userEmail)
        } else if (this.userTACId) {
            this.fetchUserAccountByTACId(this.userTACId)
        } else {
            let tempUserData = null
            if (this.userDataFromContract) {
                tempUserData = JSON.parse(JSON.stringify(this.userDataFromContract))
            }
            let profile = this.prepareUserData(null, tempUserData)
            this.userData = profile.Profile

            this.ResortId = profile.ResortId
            this.loading = false
        }
    },
    methods: {
        copyToProfile() {
            for (let field of this.copyFields) {
                if (this.userDataToCopy[field]) {
                    let value = this.userDataToCopy[field]

                    if (field === 'firstName') {
                        this.userData.Customer.PersonName.FirstName = value
                    }

                    if (field === 'lastName') {
                        this.userData.Customer.PersonName.LastName = value
                    }

                    if (field === 'email') {
                        this.userData.Phones.NamePhone.find((e) => e.phoneType === 'EMAIL').PhoneData.PhoneNumber =
                            value
                        this.userData.Customer.AccountName = value
                    }

                    if (field === 'phone') {
                        value = value.replace(/\D/g, '')

                        // Prüfe, ob die Nummer mit '0041' beginnt
                        if (!value.startsWith('0041')) {
                            // Falls die Nummer mit '0' beginnt, entferne die führende Null
                            if (value.startsWith('0')) {
                                value = value.substring(1)
                            }
                            // Füge '0041' am Anfang hinzu
                            value = '0041' + value
                        }
                        this.userData.Phones.NamePhone.find((e) => e.phoneType === 'MOBILE').PhoneData.PhoneNumber =
                            value
                    }

                    if (field === 'birthday') {
                        this.userData.Customer.birthDate = value
                    }

                    if (field === 'zip') {
                        this.userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').PostalCode = value
                    }

                    if (field === 'street') {
                        this.userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').AddressLine1 = value
                    }

                    if (field === 'city') {
                        this.userData.Addresses.NameAddress.find((e) => e.addressType === 'HOME').CityName = value
                    }
                }
            }
        },
        async updateProfile() {
            this.saving = true
            let request = {
                Profile: this.userData,
                ResortId: [this.ResortId],
            }

            let countryCodeCheck = false
            if (this.userData && this.userData.Addresses && this.userData.Addresses.NameAddress) {
                for (const address of this.userData.Addresses.NameAddress) {
                    if (address.CountryCode !== null && address.CountryCode !== '') {
                        countryCodeCheck = true
                        console.log(address.CountryCode, 'CountryCodeVal')
                    }
                }
            }

            if (!countryCodeCheck) {
                alert('Bitte wähle ein Land aus')
                return
            }

            try {
                let status = await api.updateTACUserProfile(request)
                this.$toast.success('Erfolgreich gespeichert')
                if (this.contractMapMode) {
                    if (this.newAccount) {
                        console.log('NEW ACCOUNT REGISTERED')
                        this.$emit('newAccountRegistered')
                    } else {
                        this.$emit('userSelectedReturnId', this.userData.IDs)
                    }
                }
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Speichern')
            }
            this.saving = false
        },
        prepareUserData(userData = null, contractUserData = null) {
            this.newAccount = false
            let profile = null

            if (!userData) {
                this.newAccount = true
                profile = {
                    ResortId: this.resortId || '15',
                }

                let gender = ''
                let Salutation = ''
                let FirstName = ''
                let LastName = ''
                let AccountName = ''
                let PhoneNumber = ''
                let email = ''
                let web = ''
                let AddressLine1 = ''
                let CityName = ''
                let PostalCode = ''
                let birthDate = ''

                if (contractUserData) {
                    if (contractUserData.gender) {
                        if (contractUserData.gender === 'm') {
                            gender = 'MALE'
                            Salutation = 'Herr'
                        }
                        if (contractUserData.gender === 'w') {
                            gender = 'FEMALE'
                            Salutation = 'Frau'
                        }
                    }

                    if (contractUserData.legalRepresentativeText) {
                        web = contractUserData.legalRepresentativeText || ''
                    }

                    console.log(contractUserData)

                    FirstName = contractUserData.firstName || ''
                    LastName = contractUserData.lastName || ''

                    AccountName = contractUserData.email || ''
                    email = contractUserData.email || ''
                    PhoneNumber = contractUserData.phone || ''

                    AddressLine1 = contractUserData.street || ''
                    CityName = contractUserData.city || ''
                    PostalCode = contractUserData.zip || ''

                    birthDate = this.$moment(contractUserData.birthday).format('YYYY-MM-DD') || ''
                }

                profile.Profile = {
                    nameType: 'INDIVIDUAL',
                    languageCode: 'de',
                    nationality: 'ch',
                    walkinCustomer: false,
                    Customer: {
                        gender,
                        birthDate,
                        PersonName: {
                            Salutation,
                            FirstName,
                            LastName,
                        },
                        Handicap: 0,
                        Discount: '',
                        Commission: '',
                        TaxNumber: '',
                        Languages: {
                            Language: [
                                {
                                    Code: 'de',
                                },
                            ],
                        },
                        Interest: '',
                        HomeClub: '',
                        ContactPerson: '',
                        PersonalTarget: '',
                        AccountName,
                    },
                    Addresses: {
                        NameAddress: [
                            {
                                addressType: 'HOME',
                                AddressLine1,
                                CityName,
                                CountryCode: 'CH',
                                PostalCode,
                                primary: true,
                                displaySequence: 1,
                                deleted: false,
                            },
                        ],
                    },
                    Phones: {
                        NamePhone: [
                            {
                                phoneType: 'MOBILE',
                                phoneRole: 'PHONE',
                                PhoneData: {
                                    PhoneNumber,
                                },
                                primary: true,
                                displaySequence: 1,
                                deleted: false,
                            },
                            {
                                phoneType: 'EMAIL',
                                phoneRole: 'EMAIL',
                                PhoneData: {
                                    PhoneNumber: email,
                                },
                                primary: true,
                                displaySequence: 1,
                                deleted: false,
                            },
                            {
                                phoneType: 'WEB',
                                phoneRole: 'WEBPAGE',
                                PhoneData: {
                                    PhoneNumber: web,
                                },
                                primary: true,
                                displaySequence: 1,
                                deleted: false,
                            },
                        ],
                    },
                    Preferences: {
                        Preference: [
                            {
                                preferenceType: 'GENDER_PREFERENCE',
                                Value: [
                                    {
                                        preferenceMode: 'PREFERRED_ONLY',
                                        value: 'NEUTRAL',
                                    },
                                ],
                            },
                        ],
                    },
                }
            } else {
                profile = userData
            }

            if (!profile.Profile.Phones) profile.Profile.Phones = { NamePhone: [] }
            profile.Profile.Phones.NamePhone = profile.Profile.Phones.NamePhone.filter((e) => !e.deleted && e.primary)
            let homePhone = profile.Profile.Phones.NamePhone.find((e) => e.phoneType === 'HOME')
            if (!homePhone) {
                profile.Profile.Phones.NamePhone.push({
                    phoneType: 'HOME',
                    phoneRole: 'PHONE',
                    PhoneData: {
                        PhoneNumber: '',
                    },
                    primary: true,
                    displaySequence: 1,
                    deleted: false,
                })
            }
            let mobilePhone = profile.Profile.Phones.NamePhone.find((e) => e.phoneType === 'MOBILE')
            if (!mobilePhone) {
                profile.Profile.Phones.NamePhone.push({
                    phoneType: 'MOBILE',
                    phoneRole: 'PHONE',
                    PhoneData: {
                        PhoneNumber: '',
                    },
                    primary: true,
                    displaySequence: 1,
                    deleted: false,
                })
            }

            let emailCheck = profile.Profile.Phones.NamePhone.find((e) => e.phoneType === 'EMAIL')
            if (!emailCheck) {
                profile.Profile.Phones.NamePhone.push({
                    phoneType: 'EMAIL',
                    phoneRole: 'EMAIL',
                    PhoneData: {
                        PhoneNumber: '',
                    },
                    primary: true,
                    displaySequence: 1,
                    deleted: false,
                })
            }

            let webCheck = profile.Profile.Phones.NamePhone.find((e) => e.phoneType === 'WEB')
            if (!webCheck) {
                profile.Profile.Phones.NamePhone.push({
                    phoneType: 'WEB',
                    phoneRole: 'WEBPAGE',
                    PhoneData: {
                        PhoneNumber: '',
                    },
                    primary: true,
                    displaySequence: 1,
                    deleted: false,
                })
            }

            if (!profile.Profile.Addresses || !profile.Profile.Addresses.NameAddress) {
                profile.Profile.Addresses = {
                    NameAddress: [],
                }
            }

            profile.Profile.Addresses.NameAddress = profile.Profile.Addresses.NameAddress.filter(
                (e) => !e.deleted && e.primary
            )

            let homeAddress = profile.Profile.Addresses.NameAddress.find((e) => e.addressType === 'HOME')
            if (!homeAddress) {
                profile.Profile.Addresses.NameAddress.push({
                    addressType: 'HOME',
                    AddressLine1: '',
                    CityName: '',
                    CountryCode: '',
                    PostalCode: '',
                    primary: true,
                    displaySequence: 1,
                    deleted: false,
                })
            }

            if (!profile.Profile.Customer.AccountName) profile.Profile.Customer.AccountName = ''

            if (this.accountNameIfNotGiven) {
                if (profile.Profile.Customer.AccountName.toLowerCase() !== this.accountNameIfNotGiven.toLowerCase()) {
                    this.showAccountNameDialog = true
                }
            }

            return profile
        },
        async fetchUserAccountByTACId(userId) {
            this.loading = true
            try {
                let profile = await api.fetchTACProfileByTACId(userId)
                profile = this.prepareUserData(profile)

                if (profile.Profile) this.userData = profile.Profile
                if (profile.ResortId) this.ResortId = profile.ResortId
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Laden des TAC Profils')
            }
            this.loading = false
        },
        async fetchUserAccount(email) {
            this.loading = true
            try {
                let profile = await api.fetchTACProfileByEmail(email)
                profile = this.prepareUserData(profile)
                if (profile.Profile) this.userData = profile.Profile
                if (profile.ResortId) this.ResortId = profile.ResortId
            } catch (e) {
                this.$toast.error('Fehler beim Laden des TAC Profils')
            }
            this.loading = false
        },
    },
}
</script>