<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                    <v-icon color="white" class="pr-2">mdi-car</v-icon>
                    Fahrtenkontrolle
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 primary white--text mb-5">
                    <span v-if="tab === null || tab === 0">Übersicht</span>
                    <span v-else>Neue Fahrt erfassen</span>
                </v-card-title>
                <v-tabs v-model="tab">
                    <v-tab>Übersicht</v-tab>
                    <v-tab>Erfassen</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Datum</th>
                                                <th class="text-left">Route</th>
                                                <th class="text-left">KM Stand nach der Fahrt</th>
                                                <th class="text-left">gefahrene KM</th>
                                                <th class="text-left">Nummernschild</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="drivingEntry of tripControlHistoryEntries"
                                                :key="drivingEntry._id"
                                            >
                                                <td>{{ $moment(drivingEntry.date).format('DD.MM.YY') }}</td>
                                                <td>{{ drivingEntry.routeStart }} - {{ drivingEntry.routeEnd }}</td>
                                                <td>{{ drivingEntry.kmAfterTrip }}</td>
                                                <td>{{ drivingEntry.addedKm }}</td>
                                                <td>{{ drivingEntry.licensePlate }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-stepper v-model="stepperModel" vertical>
                                <v-stepper-step :complete="stepperModel > 1" step="1">
                                    Fahrtendatum auswählen
                                </v-stepper-step>

                                <v-stepper-content step="1">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-date-picker
                                                first-day-of-week="1"
                                                locale="de-CH"
                                                v-model="newEntry.date"
                                            ></v-date-picker>
                                        </v-col>
                                    </v-row>

                                    <v-btn color="primary" @click="stepperModel = 2">Weiter</v-btn>
                                </v-stepper-content>

                                <v-stepper-step :complete="stepperModel > 2" step="2">Route eintragen</v-stepper-step>

                                <v-stepper-content step="2">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="newEntry.routeStart" label="Von"></v-text-field>
                                            <v-text-field v-model="newEntry.routeEnd" label="Nach"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn color="primary" @click="stepperModel = 3">Weiter</v-btn>
                                    <v-btn text @click="stepperModel -= 1">Zurück</v-btn>
                                </v-stepper-content>

                                <v-stepper-step :complete="stepperModel > 3" step="3">
                                    KM Stand nach der Fahrt
                                </v-stepper-step>

                                <v-stepper-content step="3">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="newEntry.kmAfterTrip"
                                                :min="0"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn color="primary" @click="stepperModel = 4">Weiter</v-btn>
                                    <v-btn text @click="stepperModel -= 1">Zurück</v-btn>
                                </v-stepper-content>
                                <v-stepper-step :complete="stepperModel > 4" step="3">gefahrene KM</v-stepper-step>

                                <v-stepper-content step="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="newEntry.addedKm"
                                                :min="0"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn color="primary" @click="stepperModel = 5">Weiter</v-btn>
                                    <v-btn text @click="stepperModel -= 1">Zurück</v-btn>
                                </v-stepper-content>
                                <v-stepper-step :complete="stepperModel > 5" step="3">Nummernschild</v-stepper-step>

                                <v-stepper-content step="5">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                type="text"
                                                label="Schweizer Nummernschild (KFZ)"
                                                v-model="newEntry.licensePlate"
                                                :rules="[nummernschildRule]"
                                                name="nummernschild"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn text @click="stepperModel -= 1">Zurück</v-btn>
                                </v-stepper-content>
                            </v-stepper>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <v-card-actions v-if="tab === 1" class="d-flex justify-center align-center">
                    <v-btn
                        color="primary"
                        text
                        @click="
                            () => {
                                this.tab = null
                                this.resetNewEntry()
                            }
                        "
                    >
                        Abbrechen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveTripControlEntry">Speichern</v-btn>
                </v-card-actions>
                <v-card-actions v-else class="d-flex justify-center align-center">
                    <v-btn color="primary" text @click="dialog = false">Fertig</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
import api from '@/api'
export default {
    name: 'TripControlHistory',
    props: {
        tripControlHistoryEntries: {
            type: Array,
        },
    },
    data() {
        return {
            newEntry: {
                date: this.$moment().format('YYYY-MM-DD'),
                routeStart: '',
                routeEnd: '',
                kmAfterTrip: 0,
                addedKm: 0,
                licensePlate: '',
            },
            nummernschildRule: (v) =>
                /^[A-Z]{2} \S+$/.test(v) ||
                'Gebe das Nummernschild im Format "XX 1234" oder "XX 123456" ein, wobei "XX" die Abkürzung des Kantons ist und "1234" oder "123456" die Nummernkombination darstellt.',
            tab: null,
            stepperModel: 1,
            dialog: false,
        }
    },
    methods: {
        async saveTripControlEntry() {
            try {
                let status = await api.createTripControlEntryEmployee(this.newEntry)
                this.$emit('fetchEntries')
                this.$toast.success('Eintrag gespeichert')
                this.tab = null
                this.resetNewEntry()
            } catch (e) {
                this.$toast.error('Es gab einen Fehler beim Speichern des Eintrags')
            }
        },
        resetNewEntry() {
            this.$emit('fetchEntries')
            this.stepperModel = 1
            this.newEntry = {
                date: this.$moment().format('YYYY-MM-DD'),
                routeStart: '',
                routeEnd: '',
                kmAfterTrip: 0,
                addedKm: 0,
                licensePlate: '',
            }
        },
    },
    computed: {},
}
</script>
  
  <style scoped>
</style>
  