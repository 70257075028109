<template>
    <v-card :dark="darkMode" elevation="0" transparent>
        <v-card-title style="word-break: break-word" :class="{ 'white--text': darkMode }">
            Geheimhaltungsvereinbarung
        </v-card-title>

        <v-card-text style="max-height: 300px; overflow-y: scroll">
            Ich verpflichte mich, personenbezogene Daten im Zusammenhang mit meiner Tätigkeit bei der {{ studioArt }} streng
            vertraulich zu behandeln, nicht unbefugt zu erheben, zu nutzen, weiterzugeben oder anders als gemäss der
            Anweisung der {{ studioArt }} zu bearbeiten. Die Bindung an diese Vertraulichkeitsverpflichtung endet nicht mit
            der Beendigung meiner Tätigkeit für die {{ studioArt }}, sondern bleibt auch danach bestehen. Personendaten im
            Sinne dieser Vertraulichkeitsverpflichtung sind alle Informationen, die sich auf eine identifizierbare oder
            benannte Person beziehen. Eine Verletzung dieser Vertraulichkeitsverpflichtung kann auf Grundlage von Art. 62 DSG
            und anderen Gesetzen in einem persönlichen Strafverfahren mit einer Geldbusse in Höhe von bis zu CHF 250’000
            bestraft werden. Darüber hinaus kann die Verletzung der Vertraulichkeitsverpflichtung gleichzeitig eine
            Verletzung der arbeitsvertraglichen Pflichten oder anderer Geheimhaltungspflichten darstellen. Ein solcher
            Verstoss kann daher zu einer Abmahnung oder einer fristlosen oder fristgerechten Kündigung führen und es kann zu
            Schadensersatzpflichten kommen. Schadensersatzansprüche können im Fall eines Verstosses gegen die
            Vertraulichkeitsverpflichtung auch von den Personen geltend gemacht werden, um deren personenbezogene Daten es
            sich handelt. Für diese Schadensersatzansprüche hafte ich unter Umständen unbeschränkt mit meinem gesamten
            Vermögen ohne eine Möglichkeit zur Restschuldbefreiung durch ein Insolvenzverfahren. Neben dieser
            Vertraulichkeitsverpflichtung können weitere Geheimhaltungsverpflichtungen, die sich beispielsweise aus dem
            Arbeitsvertrag ergeben, bestehen.
        </v-card-text>

        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header> Anhang Datenschuzterklärung für Mitarbeiter </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-expansion-panels>
                        <v-expansion-panel :key="'expansionpanel' + index" v-for="(consent, index) in consentText1">
                            <v-expansion-panel-header>
                                {{ consent.title }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="consent.text.replace('//addy//', studioAdresse)"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-card-text class="text-center">
            <signature-pad
                v-if="!withoutSignature"
                class="mx-auto mt-2"
                :signatureData="customerSignature"
                :darkMode="darkMode"
                autoSaveSignature
                @resetDrawing="resetSignature"
                @saveSignature="setSignature"></signature-pad
            ><br />
            <small v-if="!withoutSignature">
                Mit meiner Unterschrift bestätige ich, dass ich heute hinsichtlich der Bedeutung meiner Verpflichtung zur
                Verschwiegenheit über personenbezogene Daten belehrt wurde. Ich habe ein Exemplar der
                Vertraulichkeitsverpflichtung. Beachten Sie auch den Anhang, der Sie über die Bearbeitung über Ihrer
                Personendaten informiert.</small
            >
        </v-card-text>
        <v-card-actions v-if="withSave" class="justify-content-center pb-3">
            <v-btn :disabled="!customerSignature" color="primary" @click="saveConsent()">Einreichen</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import signaturePad from '../signaturePad/signaturePad.vue'
import api from '@/api'
export default {
    name: 'consentDeclarationCard',
    props: {
        darkMode: {
            type: Boolean,
            default: true,
        },
        withSave: {
            type: Boolean,
            default: false,
        },
        withoutSignature: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        signaturePad,
    },
    methods: {
        saveConsent() {
            api.saveDisclosureAggreement(this.customerSignature)
                .then(() => {
                    console.log('oii')
                    this.$emit('savedConsent')
                })
                .catch((e) => {
                    console.log(e)
                    this.$toast.error('Fehler beim abspeichern')
                })
        },
        resetSignature() {
            this.customerSignature = null
            this.$emit('savedSignature', this.customerSignature)
        },
        setSignature(signature) {
            this.customerSignature = signature
            this.$emit('savedSignature', this.customerSignature)
        },
    },
    data() {
        return {
            customerSignature: null,
            consentText1: [
                {
                    title: '1. Einleitung',
                    text: 'Die Datenschutzerklärung für Mitarbeiter beschreibt, wie und wozu wir Personendaten erheben, bearbeiten und verwenden. Der verantwortungsvolle Umgang mit Daten ist uns ein wichtiges Anliegen. Sie erfahren in dieser Datenschutzerklärung unter anderem: <br> <ul><li>welche Personendaten wir erheben und bearbeiten</li><li>zu welchen Zwecken wir Ihre Personendaten verwenden</li><li>welchen Nutzen unsere Datenbearbeitung für Sie hat</li><li> wie lange wir Ihre Personendaten bearbeiten</li> <li>welche Rechte Sie mit Bezug auf Ihre Personendaten haben</li> <li>und wie Sie uns kontaktieren können.</li></ul> Die Erhebung und die Bearbeitung Ihrer personenbezogenen Daten geschehen unter Beachtung der geltenden datenschutzrechtlichen Gesetze und Anforderungen. Im Übrigen lehnt das Fitness-Center jegliche Haftung bei der Nutzung dieser Webseite ab.Grundsätzlich bietet unsere Fitness-Center ihre Dienstleistungen nur in der Schweiz an und richtet sich nur an in der Schweiz wohnhafte Personen.',
                },
                {
                    title: '2. Verantwortlicher im Sinne des schweizerischen Datenschutzgesetzes (DSG) ist:',
                    text: 'Tunç Karapalanci; //addy//',
                },
                {
                    title: '3. Erhebung und Bearbeitung von Personendaten',
                    text: 'Die Bearbeitung und Weitergabe von Daten erfolgt im Rahmen der Lohn-, Gehalts- und Entgeltsverrechnung sowie zur Erfüllung von gesetzlichen, evtl. auch kollektivrechtlichen und arbeitsvertraglichen Pflichten. Dies beinhaltet auch automationsunterstützte und archivierte Textdokumente wie Korrespondenz. Ohne diese Daten ist es uns nicht möglich, den Vertrag abzuschliessen oder durchzuführen. Zusätzlich gilt dies für freiwilli-ge Sozialleistungen des Arbeitgebers und externe Bildungs- und Weiterbildungsange-bote. Ihre Daten werden entweder direkt von Ihnen bereitgestellt oder im Rahmen des Arbeitsverhältnisses erhoben',
                },
                {
                    title: '4. Zwecke der Datenbearbeitung',
                    text: 'Aufgrund der geltenden gesetzlichen Datensicherheitsbestimmungen werden be-stimmte Daten zur Verwaltung und Sicherheit des Systems bearbeitet, einschliesslich der Verwaltung von Benutzerkennzeichen, die Zuteilung von Hard- und Software an die Systembenutzer und der Gewährleistung der Systemsicherheit. Dabei werden auch automatisationsunterstützte und archivierte Textdokumente (wie beispielsweise Kor-respondenz) in diesen Angelegenheiten berücksichtigt. Eine sichere Systemnutzung und Beschäftigung in unserem Unternehmen ist ohne diese Datenbearbeitung nicht möglich. Veröffentlichung von Foto und beruflicher Kontaktdaten im Intranet Zur Kontaktaufnahme durch Kollegen werden berufliche Kontaktdaten im Intranet veröffentlicht. Dies erfolgt aus unserem berechtigten Interesse an einem reibungslosen Geschäftsablauf. Falls Sie aus legitimen Gründen gegen die Veröffentlichung sind, ha-ben Sie die Möglichkeit, Widerspruch einzulegen. Veröffentlichung von Foto und beruflicher Kontaktdaten auf der Firmen-Website Zur Kontaktaufnahme durch Kunden und Lieferanten werden berufliche Kontaktdaten von Mitarbeitern mit externen Kontakten im Internet veröffentlicht. Dies erfolgt aus unserem berechtigten Interesse an einem reibungslosen Geschäftsablauf. Falls Sie aus legitimen Gründen gegen die Veröffentlichung sind, haben Sie die Möglichkeit, Widerspruch einzulegen',
                },
                {
                    title: '5. Nutzung von Geschäfts-E-Mail-Adressen',
                    text: 'Es ist den Mitarbeitern untersagt, ihre geschäftliche E-Mail-Adresse für private Zwecke zu verwenden. Geschäftliche E-Mail-Adressen dienen ausschliesslich der Kommunikation zu geschäftlichen Zwecken und nicht für private Korrespondenz, einschliesslich. Im Falle von Abwesenheiten oder einem Austritt des Mitarbeiters haben wir das Recht auf das Postfach und Mails für geschäftliche Zwecke jederzeit zuzugreifen.',
                },
                {
                    title: '6. Weitergabe von Daten an Dritte',
                    text: 'Eine Übermittlung Ihrer personenbezogenen Daten an Dritte erfolgt nur, sofern dies zur Erfüllung unserer vertraglichen Pflichten erforderlich ist oder wir aufgrund gesetzlicher Bestimmungen dazu verpflichtet sind. In der Regel bearbeiten diese Dienstleister Per-sonendaten in unserem Auftrag als sog. «Auftragsbearbeiter». Unsere Auftragsbear-beiter sind verpflichtet, Personendaten ausschliesslich nach unseren Instruktionen zu bearbeiten sowie geeignete Massnahmen zur Datensicherheit zu treffen. Bestimmte Dienstleister sind auch gemeinsam mit uns oder selbständig verantwortlich (z.B. Inkas-sounternehmen). Wir stellen durch die Auswahl der Dienstleister und durch geeignete vertragliche Vereinbarungen sicher, dass der Datenschutz während der gesamten Be-arbeitung Ihrer Personendaten sichergestellt ist.Dabei geht es z.B. um Dienstleistungen in folgenden Bereichen: <br> <ul><li>Weitergabe von Daten an Dritte</li><li>Zahlungsdienste</li><li>IT-Dienstleistungen, z.B. Hosting, Cloud-Dienste, Datenanalyse</li><li>Beratungsdienstleistungen, z.B. Steuerberater*innen, Rechtsanwält*innen</li><li>Weitere</li></ul> <br> Eine Übermittlung der im jeweiligen Einzelfall relevanten Daten erfolgt auch auf Grundlage der gesetzlichen Bestimmungen an folgende Stellen <br> <ul><li>Sozialversicherungen</li><li>BVG</li><li>UVG</li><li>KTG</li><li>Sowie weiteren Gesetze und Branchenvorgaben</li></ul>',
                },
                {
                    title: '7. Datensicherheit',
                    text: 'Wir treffen angemessene technische und organisatorische Sicherheitsmassnahmen, um Ihre Personendaten gegen unbeabsichtigte oder unrechtmässige Vernichtung, Verlust, Veränderung, unbefugte Offenlegung oder unbefugten Zugriff zu schützen. Unsere Sicherheitsmassnahmen werden regelmässig überprüft und an den technologischen Fortschritt angepasst.',
                },
                {
                    title: '8. Ihre Rechte',
                    text: 'Sie haben das Recht, Auskunft über die von uns zu Ihrer Person gespeicherten Daten zu erhalten. Darüber hinaus haben Sie das Recht auf Berichtigung unrichtiger Daten oder Löschung Ihrer personenbezogenen Daten, sofern keine gesetzlichen Aufbewahrungspflichten, Vertrag oder ein überwiegendes berechtigtes Interesse an der Aufbewahrung besteht. Zur Geltendmachung Ihrer Rechte setzen Sie sich bitte mit dem Fitness-Center in Verbindung. Wir stehen Ihnen für weitere diesbezügliche Informationen gerne zur Verfügung.',
                },
                {
                    title: '9. Änderungen',
                    text: 'Unser Fitness-Center kann diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, publizierte Fassung. Diese tritt 30 Tagen nach Zustellung, z.B. per E-Mail oder auf andere geeignete Weise in Kraft.',
                },
            ],
        }
    },
    computed: {
        studioAdresse() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null

            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && (studioCode === 'pfungen' || studioCode === 'winterthur')) {
                return '  winti FIT; Im Hölderli 10, 8405 Winterthur'
            } else {
                return '  well come FIT; Walzmühlestrasse 50; 8500 Frauenfeld'
            }
        },
        studioArt() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null

            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && (studioCode === 'pfungen' || studioCode === 'winterthur')) {
                return 'winti FIT'
            } else {
                return 'well come FIT'
            }
        },
    },
}
</script>