<template>
    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'leadsPerSetting',
                },
                xaxis: {
                    categories: this.leadsPerSetting.map((item) => item.name),
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#FFFFFF', // Set the color to white
                        },
                    },
                },
            },
            series: [
                {
                    name: 'Leads',
                    data: this.leadsPerSetting.map((item) => item.data),
                },
            ],
        }
    },
    props: {
        leadsPerSetting: Array,
    },
}
</script>
