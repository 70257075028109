<template>
    <div>
        <v-card-text class="pt-0">
            Um eine genaue Einschätzung deiner Essgewohnheiten zu erhalten, bitten wir dich, Fotos von deinen typischen
            Portionsgrößen hochzuladen. Dies ermöglicht es uns, deinen Ernährungsplan noch besser auf dich abzustimmen.
        </v-card-text>
        <v-row no-gutters class="mb-2 px-2">
            <v-col cols="4">
                <label for="images" class="drop-container" id="dropcontainer">
                    <div class="image-upload">
                        <v-icon class="ma-auto" x-large>mdi-image-plus</v-icon>
                    </div>
                    <v-file-input
                        multiple
                        @change="uploadImages($event)"
                        style="display: none"
                        type="file"
                        id="images"
                        accept="image/*"
                        required
                    />
                </label>
            </v-col>
            <v-col cols="4" v-for="(image, index) in portionSizes.images" :key="'image' + index">
                <v-img
                    contain
                    class="mx-auto"
                    v-if="image.dataUrl"
                    height="65px"
                    width="65px"
                    :src="image.dataUrl"
                    alt="Uploaded Image"
                ></v-img>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-textarea class="px-2" label="Anmerkungen" v-model="portionSizes.text"></v-textarea>
        </v-row>
        <v-card-actions class="justify-content-center">
            <v-btn color="primary" @click="$emit('cancel')">Zurück</v-btn>
            <v-btn color="primary" @click="savePortionSizes()">Speichern</v-btn>
        </v-card-actions>
    </div>
</template>
<script>
import api from '@/api'

export default {
    name: 'habitFormUpdateExecution',
    data() {
        return {
            portionSizes: {
                images: [],
                text: '',
            },
            dialog: true,
            templateSelection: false,
            templatesList: [],
            halfGlas: false,
            bigImagesArray: [],
            bigImagesIndex: 0,
            bigImagesArrayDay: null,
        }
    },
    methods: {
        async savePortionSizes() {
            if (!this.portionSizes.images.length) return alert('Bitte füge erst mind. 1 Bild hinzu')
            if (
                confirm(
                    'Die Bilder werden an deinen Ernährungscoach weitergeleitet und können nicht mehr geändert werden.'
                )
            ) {
                this.portionSizes.images = this.portionSizes.images.map((image) => {
                    return { src: image.src }
                })
                await api.saveNutritionPortionSizes(this.portionSizes)
                this.$toast.success('Bilder wurden gespeichert')
                this.$emit('saved')
            }
        },
        async fetchImageDataSource(image) {
            try {
                const response = await api.fetchFormUpdateImage(image.src)
                return response
            } catch (error) {
                this.$toast.error('Fehler beim laden des Bildes')
            }
        },
        uploadImages(files) {
            for (let file of files) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    const formData = new FormData()
                    formData.append('file', file)

                    api.uploadFormUpdateImage(formData)
                        .then((response) => {
                            const uploadedImageName = response.name

                            this.portionSizes.images.push({
                                title: '',
                                src: uploadedImageName,
                                dataUrl: e.target.result,
                            })
                            this.$forceUpdate()
                        })
                        .catch((error) => {
                            console.log('Upload failed', error)
                        })
                }
                reader.readAsDataURL(file)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.image-upload {
    height: 65px;
    width: 65px;
    display: flex;
    border: dashed;
    border-color: grey;
    border-radius: 6px;
}
.drink-execution {
    &__title {
        position: sticky;
        min-height: 68px;
        width: 100%;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
    }
    &__add-button {
        background-color: #4bb2fd;
        color: white;
    }
}

::v-deep .v-dialog {
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    margin: 0;
    position: absolute;
    min-height: 40vh;
    bottom: 0;
    background: white;
    max-height: 90vh;
}

.option-button {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background: lightgray;
        &-bulb {
            ::v-deep .v-image__image {
                width: 80px;
            }
            flex-direction: row;
        }
    }
    &__wrapper:hover {
        background: gray;
    }
}
</style>
