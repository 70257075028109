<template>
    <v-row no-gutters>
        <v-btn
            @click="startMembercardDialog"
            color="primary"
            class="mx-auto mt-5"
            v-if="!(data.membercard && data.membercard.startDate)"
        >
            <span v-if="elevenDaysActive">11</span>
            <span v-else>10</span>
            -Tages-Mitgliedskarte aktivieren
        </v-btn>
        <v-col v-else cols="12">
            <v-card max-width="500px" color="transparent" class="white--text mx-auto mt-5" outlined elevation="0">
                <v-alert v-if="!checkMembercardActiveDate(data.membercard)" type="error">
                    Membercard ist abgelaufen
                </v-alert>
                <v-row>
                    <v-col cols="12" class="mb-0 pb-0">
                        <v-card-title class="h4">
                            <span v-if="elevenDaysActive">11</span>
                            <span v-else>10</span>
                            -Tage-Mitgliedskarte
                        </v-card-title>
                        <v-row no-gutters>
                            <v-col align="center" cols="12">
                                <v-card-text>
                                    Membercard Lead wurde automatisch erstellt und auf Kontaktiert gesetzt. Alle
                                    laufenden Membercards findest du im Mitarbeiter Panel -> Membercard
                                </v-card-text>
                            </v-col>
                        </v-row>
                        <v-card-subtitle class="mb-0 pb-0">
                            Startdatum:
                            {{ $moment(data.membercard.startDate).format('DD.MM.YY HH:mm') }} Uhr
                        </v-card-subtitle>
                        <v-card-subtitle class="mt-0 pt-0 pb-0 mb-0">
                            Enddatum: {{ $moment(data.membercard.endDate).format('DD.MM.YY HH:mm') }} Uhr
                        </v-card-subtitle>
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <v-card-subtitle>Checkins: {{ data.membercard.checkIns.length }}</v-card-subtitle>
                        <v-list color="transparent" class="white--text">
                            <v-list-item-group>
                                <v-list-item v-for="(checkin, index) in data.membercard.checkIns" :key="index">
                                    <v-list-item-content class="white--text">
                                        {{ index + 1 }}. {{ $moment(checkin.date).format('DD.MM.YY HH:mm') }} Uhr
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon @click="removeCheckin(index)">
                                            <v-icon color="red lighten-1">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>

                        <!-- Button zum Hinzufügen eines neuen Check-Ins -->
                        <v-card-actions>
                            <v-row>
                                <v-col align="center" cols="12">
                                    <v-btn
                                        small
                                        color="primary"
                                        @click="
                                            () => {
                                                showNewCheckinDialog = true
                                                newCheckinDate = $moment().format('YYYY-MM-DD')
                                                newCheckinTime = $moment().format('HH:mm')
                                                editNewCheckinDate = false
                                                editNewCheckinTime = false
                                            }
                                        "
                                    >
                                        Neues Check-In hinzufügen
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-switch
                                        style="color: white"
                                        class="white--text"
                                        color="white"
                                        v-model="data.membercard.isActive"
                                        label="Mitgliedskarte aktiviert"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        <v-dialog v-model="showNewCheckinDialog" max-width="400px">
                            <v-card>
                                <v-card-title>Datum und Uhrzeit auswählen</v-card-title>
                                <v-card-text>
                                    <v-date-picker locale="de-CH" v-if="editNewCheckinDate" v-model="newCheckinDate" />
                                    <v-card-subtitle v-else>
                                        {{ $moment(newCheckinDate).format('DD.MM.YY') }}
                                        <v-btn icon @click="editNewCheckinDate = true">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-card-subtitle>
                                    <v-time-picker v-if="editNewCheckinTime" v-model="newCheckinTime" />
                                    <v-card-subtitle v-else>
                                        {{ newCheckinTime }} Uhr
                                        <v-btn icon @click="editNewCheckinTime = true">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-card-subtitle>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text @click="showNewCheckinDialog = false">Abbrechen</v-btn>
                                    <v-btn text @click="addNewCheckinWithDateTime">Hinzufügen</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col align="center" cols="12">
                        <v-btn @click="updateMembercard" color="primary">Speichern</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-dialog v-model="showMembercardDialog" max-width="400px">
            <v-card>
                <v-card-title class="primary white--text mb-2">Startdatum der Membercard</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col class="pb-0 mb-0" cols="12">
                                <v-text-field
                                    hide-details
                                    label="Startdatum"
                                    prepend-icon="mdi-calendar"
                                    type="date"
                                    v-model="membercardStartDate"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 mt-0" cols="12">Membercard gültig bis: {{ membercardEndDate }}</v-col>
                            <v-col cols="12">
                                <v-select
                                    hide-details
                                    label="Studio"
                                    v-model="studio"
                                    outlined
                                    :items="studios.map((e) => ({ text: e.name, value: e.id }))"
                                ></v-select>
                            </v-col>
                            <v-col v-if="!employeeInfos._id" cols="12">
                                <user-search
                                    class=""
                                    :value="employeeInfos._id"
                                    ignoreRights
                                    instructor
                                    label="Trainer"
                                    @update="updateEmployee"
                                ></user-search>
                            </v-col>
                            <v-col v-else cols="12">
                                Trainer: {{ employeeInfos.firstName }} {{ employeeInfos.lastName }}
                                <br />
                                <v-btn class="mt-2" @click="() => (employeeInfos = { _id: null })">
                                    Trainer wechseln
                                </v-btn>
                            </v-col>
                            <v-col class="pb-0 mb-0" cols="12">
                                <v-textarea v-model="notes" hide-details label="Notizen" outlined></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-space-between">
                    <v-btn text @click="showMembercardDialog = false">Abbrechen</v-btn>

                    <v-btn class="primary" text @click="setMembercardStartDate(data._id)">Bestätigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userSearch from '@/components/userSearch.vue'

export default {
    components: { userSearch },
    props: {
        user: {
            required: true,
        },
    },
    data() {
        return {
            userService,
            data: {},
            membercardStartDate: null,
            membercardEndDate: null,
            showMembercardDialog: false,
            showNewCheckinDialog: false,
            newCheckinDate: null,
            editNewCheckinDate: false,
            editNewCheckinTime: false,
            newCheckinTime: null,
            leadFound: false,
            studios: [],
            studio: null,
            notes: '',
            employeeInfos: { _id: null },
        }
    },
    async created() {
        this.data = this.user
        this.fetchLead()
        this.$emit('loaded')
        try {
            let studios = await api.fetchStudios()
            let filterCodes = ['ot', 'alibey']
            studios = studios
                .filter((e) => !filterCodes.includes(e.code))
                .map((e) => {
                    return { id: e._id, name: e.name }
                })

            this.studios = studios
        } catch (e) {
            this.$toast.error('Fehler beim Laden der Studios')
        }
        try {
            let userStudio = await api.getUserStudio()

            if (userStudio && userStudio.studio && userStudio.studio._id) this.studio = userStudio.studio._id
        } catch (e) {
            this.$toast.error('Fehler beim Laden des Studios')
        }
    },
    methods: {
        async updateEmployee(id) {
            try {
                let employeeManager = await api.getUserForManager(id)

                this.employeeInfos = {
                    _id: id,
                    firstName: employeeManager.vorname,
                    lastName: employeeManager.nachname,
                    email: employeeManager.email,
                }
            } catch (e) {
                this.$toast.error('Fehler beim Laden des Accounts')
            }

            this.$forceUpdate()
        },
        async createLead() {
            try {
                let status = await api.createMembercardLead(this.data._id)
                this.leadFound = true
                this.$toast.success('Lead erstellt')
            } catch (e) {
                this.$toast.error('Fehler bei Erstellung')
                console.log(e)
            }
        },
        async fetchLead() {
            try {
                let status = await api.fetchMembercardLead(this.data._id)
                this.leadFound = status.found
                console.log(status)
            } catch (e) {
                console.log(e)
            }
        },
        async updateMembercard() {
            try {
                let saveMembercard = await api.updateMembercard({
                    userId: this.user._id,
                    membercard: this.data.membercard,
                })
                this.$toast.success('Membercard gespeichert')
            } catch (e) {
                this.$toast.error('Fehler beim speichern')
            }
        },
        checkMembercardActiveDate(membercard) {
            return this.$moment().isBefore(this.$moment(membercard.endDate))
        },
        setMembercardStartDate(id) {
            if (!(this.employeeInfos._id && this.employeeInfos._id.length > 3)) {
                this.$toast.error('Bitte wähle einen Trainer aus ')
                return false
            }
            if (!(this.studio && this.studio.length > 3)) {
                this.$toast.error('Bitte wähle ein Studio aus ')
                return false
            }

            this.showMembercardDialog = false
            this.startMembercard(id)
        },
        startMembercardDialog() {
            this.membercardStartDate = this.$moment().format('YYYY-MM-DD')
            this.showMembercardDialog = true
        },
        async startMembercard(id) {
            try {
                let memberCardInfos = {
                    userId: id,
                    startDate: this.$moment(this.membercardStartDate).format('YYYY-MM-DD'),
                    studio: this.studio,
                    employee: this.employeeInfos._id,
                    notes: this.notes,
                }
                let apiData = await api.startMembercard(memberCardInfos)
                if (apiData.membercard) this.data.membercard = apiData.membercard
                this.$toast.success('Mitgliedskarte erfolgreich erstellt')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim erstellen der Membercard')
            }
        },
        createMembercard(id) {
            api.createMembercard(id).then((data) => {
                if (data.status == 1) {
                    alert('erfolgreich erstellt')
                } else {
                    alert('es gab ei nen Fehler')
                }
            })
        },
        editCheckin(index) {
            const dateToEdit = this.data.membercard.checkIns[index].date
            // Implementiere hier deine Logik zum Bearbeiten
        },
        removeCheckin(index) {
            if (
                confirm(
                    'Wollen Sie diesen Check-In wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden'
                )
            )
                this.data.membercard.checkIns.splice(index, 1)
        },
        addNewCheckinWithDateTime() {
            const newCheckinDateTime = `${this.newCheckinDate}T${this.newCheckinTime}`
            const newCheckin = {
                date: new Date(newCheckinDateTime),
            }
            this.data.membercard.checkIns.push(newCheckin)
            this.showNewCheckinDialog = false

            // ... Deine anderen Methoden
        },
        addNewCheckin() {
            const newCheckin = {
                date: new Date(),
                // Weitere Felder hier
            }
            this.data.membercard.checkIns.push(newCheckin)
        },
    },
    watch: {
        membercardStartDate() {
            let amount = 9
            if (this.elevenDaysActive) amount = 10
            this.membercardEndDate = this.$moment(this.membercardStartDate).add(amount, 'days').format('DD.MM.YYYY')
        },
    },
    computed: {
        elevenDaysActive() {
            return this.$moment().isAfter(this.$moment('01-05-2024', 'DD-MM-YYYY'))
        },
        isLocal() {
            return location.host.includes('local')
        },
    },
}
</script>
