const api = require('../../api').default
export class groupFitnessHabit {
	constructor(
		groupFitnessHabit = {
			type: 'groupFitnessHabit',
			label: 'Group Fitness Kurs',
			description:
				'Durch favoritisieren eines Kurses wird dieser als Gewohnheit gespeichert. Gehe zum Kursplan und wähle einen Kurs aus. Klicke auf das Herz um den Kurs als Gewohnheit zu speichern.',
			icon: 'groupFitness.png',
			courseTimeline: '',
			executions: [],
			streak: 0,
		}
	) {
		for (let key in groupFitnessHabit) {
			this[key] = groupFitnessHabit[key]
		}
		if (this.courseTimeline) {
			this.fetchExecutionsAndStreak()
		}
	}
	getGroupFitnessHabit() {
		let habit = {}
		for (let key in this) {
			habit[key] = this[key]
		}
		return habit
	}
	setGroupFitnessHabit(habit) {
		for (let key in habit) {
			this[key] = habit[key]
		}
		this.fetchExecutionsAndStreak()
	}
	fetchExecutionsAndStreak() {
		api.fetchGroupFitnessHabit(this.courseTimeline)
			.then((e) => {
				this.executions = e.executions
				this.streak = e.streak
			})
			.catch((e) => {
				console.log('Error', e)
			})
	}
	async delete() {
		try {
			let habit = await api.deleteHabit(this)
			console.log(habit)
		} catch (e) {
			console.log(e)
		}
	}
	async save() {
		try {
			let habit = this.getGroupFitnessHabit()
			delete habit.executions
			delete habit.streak
			habit = await api.updateHabit(habit)
			this.setGroupFitnessHabit(habit)
			return true
		} catch (e) {
			console.log('Error: ', e)
			return false
		}
	}
}
