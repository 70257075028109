<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" text v-bind="attrs" v-on="on"
                    ><v-icon class="pr-2">mdi-information-slab-circle </v-icon>
                    <span v-if="!$vuetify.breakpoint.smAndDown">Pause</span>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 primary white--text" style="word-break: break-word">
                    Pausenregelung in der Schweiz
                </v-card-title>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">Tägliche Arbeitszeit</th>
                            <th class="text-left">Gesetzlicher Pausenanspruch</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>bis zu 5.5 Stunden</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>5.5 bis 7 Stunden</td>
                            <td>15 Minuten</td>
                        </tr>
                        <tr>
                            <td>7 bis 9 Stunden</td>
                            <td>30 Minuten</td>
                        </tr>
                        <tr>
                            <td>mehr als 9 Stunden</td>
                            <td>60 Minuten</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-card-text class="pb-0"
                    >Pausen, die mehr als eine halbe Stunde andauern, dürfen aufgeteilt werden. Ausserdem unterliegt die
                    Mittagspause einer Mindestdauer von 30 Minuten.</v-card-text
                >
                <v-divider></v-divider>

                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn color="primary" text @click="dialog = false"> Okay </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
export default {
    name: 'BreakRules',
    data() {
        return {
            dialog: false,
        }
    },
    methods: {},
}
</script>
  
  <style scoped>
</style>
  