<template>
    <div :style="'max-width: ' + maxWidth">
        <v-img v-if="disabled" :src="signatureData"></v-img>
        <canvas
            :style="color ? 'background-color: ' + color : ''"
            v-else
            class="signaturePad"
            :class="{ 'signaturePad--darkMode': darkMode }"
            ref="canvas"
            @mousedown="startDrawing"
            @mousemove="continueDrawing"
            @mouseup="stopDrawing"
            @touchstart="startDrawing"
            @touchmove="continueDrawing"
            @touchend="stopDrawing"
        ></canvas>
        <br />
        <v-btn v-if="!disabled" text @click="resetCanvas">Reset</v-btn>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
        signatureData: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: String,
            default: '80%',
        },
        autoSaveSignature: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            drawing: false,
            context: null,
            lastX: 0,
            lastY: 0,
            startedDrawing: false,
            autoSaveTimeout: null,
        }
    },
    mounted() {
        if (!this.disabled) {
            this.context = this.$refs.canvas.getContext('2d')
            if (this.signatureData) {
                this.loadSignature()
            }
        }
    },
    methods: {
        loadSignature() {
            const image = new Image()
            image.onload = () => {
                this.context.drawImage(image, 0, 0)
            }
            image.src = this.signatureData
        },
        startDrawing(event) {
            if (!this.startedDrawing) {
                this.$emit('startDrawing')
                this.startedDrawing = true
            }
            event.preventDefault()
            this.drawing = true
            const touch = event.touches ? event.touches[0] : event
            const canvas = this.$refs.canvas
            const rect = canvas.getBoundingClientRect()
            const x = touch.clientX - rect.left
            const y = touch.clientY - rect.top
            this.lastX = (x * canvas.width) / canvas.clientWidth
            this.lastY = (y * canvas.height) / canvas.clientHeight
        },

        continueDrawing(event) {
            if (!this.drawing) return
            event.preventDefault()
            const touch = event.touches ? event.touches[0] : event
            const canvas = this.$refs.canvas
            if (this.darkMode) canvas.getContext('2d').strokeStyle = 'white'
            const rect = canvas.getBoundingClientRect()
            const x = touch.clientX - rect.left
            const y = touch.clientY - rect.top
            this.context.beginPath()
            this.context.moveTo(this.lastX, this.lastY)
            this.context.lineTo((x * canvas.width) / canvas.clientWidth, (y * canvas.height) / canvas.clientHeight)
            this.context.lineWidth = 2
            this.context.stroke()
            this.lastX = (x * canvas.width) / canvas.clientWidth
            this.lastY = (y * canvas.height) / canvas.clientHeight
        },
        stopDrawing() {
            this.drawing = false
            if (this.autoSaveSignature) {
                clearTimeout(this.autoSaveTimeout)
                this.autoSaveTimeout = setTimeout(() => {
                    this.saveSignature()
                }, 500)
            }
        },

        resetCanvas() {
            this.startedDrawing = false
            this.$emit('resetDrawing')
            this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
        },
        saveSignature() {
            const canvas = this.$refs.canvas
            const context = canvas.getContext('2d')
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
            const isCanvasEmpty = !Array.from(imageData.data).some((channel) => channel !== 0)

            if (isCanvasEmpty) {
                console.log('saved Signature is null')
                this.$emit('saveSignature', null) // Wenn das Canvas leer ist, null emittieren
            } else {
                console.log('saved Signature is not null')
                const signature = canvas.toDataURL()
                this.$emit('saveSignature', signature)
            }
            /*
            const signature = this.$refs.canvas.toDataURL()
            this.$emit('saveSignature', signature)*/
        },
    },
}
</script>¨
<style lang="scss" scoped>
.signaturePad {
    border: 1px solid #3c3c3c;
    width: 100%;
    height: 200px;
    &--darkMode {
        border: 4px solid var(--v-primary);
    }
}
</style>
