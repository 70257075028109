<template>
	<div class="kinderhort">
		<v-tabs centered v-model="tab">
			<v-tabs-slider color="black"></v-tabs-slider>

			<v-tab v-for="item in items" :key="item">
				{{ item }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item class="mb-15 mt-5"><referral-list></referral-list></v-tab-item>
			<v-tab-item class="mb-15 mt-5"><lead-list></lead-list></v-tab-item>
			<v-tab-item class="mb-15 mt-5"><weiterempfehlung></weiterempfehlung></v-tab-item>
			<v-tab-item class="mb-15 mt-5"><lead-settings></lead-settings></v-tab-item>
			<v-tab-item class="mb-15 mt-5"><lead-referral></lead-referral></v-tab-item>
		</v-tabs-items>
	</div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'
import LeadList from './LeadList.vue'
import ReferralList from './ReferralList.vue'
import LeadSettings from './LeadSettings.vue'
import LeadReferral from './LeadReferral.vue'
import weiterempfehlung from '../Weiterempfehlungen.vue'
import userService from '../../../services/userService'
export default {
	components: { LeadList, LeadSettings, LeadReferral, ReferralList, weiterempfehlung },
	data() {
		return {
			userService,
			tab: 2,
			items: ['Referrals', 'Leads', 'Weiterempfehlungen'],
		}
	},
	created() {
		if (this.userService.hasRight('af_settingsEdit')) {
			this.items.push('Formulare')
			this.items.push('Weiterempfehlungseinstellungen')
		}
	},
	methods: {},
}
</script>
<style lang="scss" scoped></style>
