<template>
    <v-row>
        <v-col class="d-flex justify-center mt-3" cols="12">
            <v-btn color="primary" @click="openStudioPicker()">Studio wechseln</v-btn>
        </v-col>
        <v-col cols="12">
            <!-- Loop through each rightGroup to display employees under each group -->
            <v-list v-for="rightGroup in rightGroups" :key="rightGroup" shaped>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong>{{ rightGroup }}</strong>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <!-- Loop through employees and display those whose roles match the current rightGroup -->
                <v-list-item v-for="employee in filteredEmployees(rightGroup)" :key="employee._id">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ employee.firstName }} {{ employee.lastName }} ({{ employee.email }})
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="employee.tacMembership && employee.tacMembership.name">
                            Abo: {{ employee.tacMembership.name }} | Status: {{ employee.tacMembership.status }} |
                            Gültig von {{ formatDate(employee.tacMembership.startDate) }} bis
                            {{ formatDate(employee.tacMembership.endDate) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                            <v-chip class="white--text" color="red">Keine TAC Mitgliedschaft mit dieser E-Mail</v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-if="employee.groupFitness.courses && employee.groupFitness.courses.length > 0"
                        >
                            Gibt Stunden in: {{ getGFCourses(employee) }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <!-- Display employees without roles in a separate section -->
            <v-list v-if="employeesWithoutRoles.length > 0" shaped>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong>Weitere</strong>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item v-for="employee in employeesWithoutRoles" :key="employee.id">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ employee.firstName }} {{ employee.lastName }} ({{ employee.email }})
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="employee.tacMembership && employee.tacMembership.name">
                            Abo: {{ employee.tacMembership.name }} | Status: {{ employee.tacMembership.status }} |
                            Gültig von {{ formatDate(employee.tacMembership.startDate) }} bis
                            {{ formatDate(employee.tacMembership.endDate) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                            <v-chip class="white--text" color="red">Keine TAC Mitgliedschaft mit dieser E-Mail</v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
import api from '@/api'

export default {
    components: {},
    data() {
        return {
            employeeList: [],
            rightGroups: [],
        }
    },
    async created() {
        let list = await api.fetchCurrentEmployeeList()

        this.employeeList = list.employeeList
        this.rightGroups = list.rightGroups.sort()
    },
    methods: {
        openStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
        getGFCourses(employee) {
            return [...new Set(employee.groupFitness.courses.map((e) => e.studioName))].join(', ')
        },
        filteredEmployees(rightGroup) {
            // Return employees that have at least one role matching the current right group
            return this.employeeList.filter((employee) => employee.roles && employee.roles.includes(rightGroup))
        },

        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return new Date(date).toLocaleDateString('de-DE', options)
        },
    },
    computed: {
        employeesWithoutRoles() {
            // Return employees without any roles or with an empty roles array
            return this.employeeList.filter((employee) => !employee.roles || employee.roles.length === 0)
        },
    },
}
</script>
