<template>
    <div class="text-center">
        <v-dialog persistent v-model="dialog" dark width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" class="white--text" v-on="on">
                    <v-icon class="white--text pr-2">mdi-pencil</v-icon>
                    Daten korrigieren
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 primary white--text">
                    {{ adminAccess ? 'Admin Stundenkorrektur' : 'letzte 24H korrigieren' }}
                </v-card-title>

                <v-row v-if="!deleteTimeEntry">
                    <v-col cols="12" class="d-flex justify-center mt-5">
                        <v-btn @click="addPauseEntryStatus = !addPauseEntryStatus" text>
                            <span v-if="!addPauseEntryStatus">
                                {{ adminAccess ? 'Zeit nachtragen' : 'Pause nachtragen' }}
                            </span>
                            <span v-else>Zurück</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" class="d-flex justify-center mt-5">
                        <v-btn @click="deleteTimeEntry = null" text><span>Abbrechen</span></v-btn>
                    </v-col>
                </v-row>
                <div v-if="addPauseEntryStatus && adminAccess">
                    <v-row no-gutters>
                        <v-time-picker full-width v-model="newItem.time" format="24hr"></v-time-picker>
                    </v-row>
                    <v-row no-gutters>
                        <v-select
                            v-model="newItem.type"
                            :items="[
                                { text: 'Check-In', value: 'checkin' },
                                { text: 'Check-Out', value: 'checkout' },
                                { text: 'Pause', value: 'pause' },
                                { text: 'Pause Fertig', value: 'pauseend' },
                            ]"
                            label="Typ"
                        ></v-select>
                    </v-row>
                    <v-row class="justify-content-center">
                        <v-btn color="primary" @click="saveTimeEntry">Zeit speichern</v-btn>
                    </v-row>
                </div>
                <v-row v-if="addPauseEntryStatus && !adminAccess">
                    <v-col cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Pause</th>
                                        <th class="text-left">Uhrzeit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Beginn</td>
                                        <td>
                                            <v-radio-group v-model="pauseDateSelection">
                                                <v-radio
                                                    label="Gestern"
                                                    :value="$moment().subtract(1, 'days').format('YYYY-MM-DD')"
                                                ></v-radio>
                                                <v-radio
                                                    label="Heute"
                                                    :value="$moment().format('YYYY-MM-DD')"
                                                ></v-radio>
                                            </v-radio-group>
                                            <v-menu
                                                ref="menushowPauseStartTimePicker"
                                                v-model="showPauseStartTimePicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="defaultPauseStartTime"
                                                        label="Beginn der Pause"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="showPauseStartTimePicker"
                                                    v-model="defaultPauseStartTime"
                                                    format="24hr"
                                                    full-width
                                                    @click:minute="showPauseStartTimePicker = false"
                                                ></v-time-picker>
                                            </v-menu>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ende</td>
                                        <td>
                                            <v-menu
                                                ref="menushowPauseEndTimePicker"
                                                v-model="showPauseEndTimePicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="defaultPauseEndTime"
                                                        label="Ende der Pause"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="showPauseEndTimePicker"
                                                    v-model="defaultPauseEndTime"
                                                    format="24hr"
                                                    :min="defaultPauseStartTime"
                                                    full-width
                                                    @click:minute="showPauseEndTimePicker = false"
                                                ></v-time-picker>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-btn color="primary" @click="savePauseEntry">Pause speichern</v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="!deleteTimeEntry">
                    <v-col cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Typ</th>
                                        <th class="text-left">Uhrzeit</th>
                                        <th class="text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="timeEntry in getRightEntries" :key="timeEntry._id">
                                        <td>{{ entryTypes[timeEntry.type] }}</td>
                                        <td v-if="editTimeEntry && editTimeEntry._id === timeEntry._id">
                                            <v-menu
                                                v-if="false"
                                                :value="$moment(showTimeEntryDatePicker).format('DD.MM.YYYY')"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="editTimeEntryDate"
                                                        label="Datum"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    :min="$moment().subtract(1, 'days').format('YYYY-MM-DD')"
                                                    :max="$moment().format('YYYY-MM-DD')"
                                                    locale="de-CH"
                                                    v-model="editTimeEntryDate"
                                                    @input="showTimeEntryDatePicker = false"
                                                ></v-date-picker>
                                            </v-menu>
                                            <v-menu
                                                :ref="'menushowTimeEntryTimePicker' + editTimeEntry._id"
                                                v-model="showTimeEntryTimePicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="editTimeEntryTime"
                                                        label="Uhrzeit"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="showTimeEntryTimePicker"
                                                    v-model="editTimeEntryTime"
                                                    format="24hr"
                                                    full-width
                                                    @click:minute="showTimeEntryTimePicker = false"
                                                ></v-time-picker>
                                            </v-menu>
                                        </td>
                                        <td v-else>{{ $moment(timeEntry.date).format('DD.MM.YY HH:mm') }} Uhr</td>
                                        <td>
                                            <v-btn
                                                v-if="editTimeEntry && editTimeEntry._id === timeEntry._id"
                                                text
                                                @click="updateTimeEntry()"
                                            >
                                                <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                            <v-btn v-else text @click="editTimeEntry = timeEntry">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn v-if="!editTimeEntry" text @click="deleteTimeEntry = timeEntry">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <v-card-text>
                            Bist du sicher, dass du den {{ entryTypes[deleteTimeEntry.type] }} vom
                            {{ $moment(deleteTimeEntry.date).format('DD.MM.YY HH:mm') }} Uhr löschen möchtest?
                        </v-card-text>
                        <v-alert class="mx-5" type="error">
                            <strong>Wichtig:</strong>
                            <span v-if="deleteTimeEntry.type === 'checkin'">
                                Bitte beachte, dass du gegebenfalls auch den dazugehörigen Check-Out Eintrag löschst, um
                                die Richtigkeit sicherzustellen
                            </span>
                            <span v-if="deleteTimeEntry.type === 'checkout'">
                                Bitte beachte, dass du gegebenfalls auch den dazugehörigen Check-In Eintrag löschst, um
                                die Richtigkeit sicherzustellen
                            </span>
                            <span v-if="deleteTimeEntry.type === 'pause'">
                                Bitte beachte, dass du gegebenfalls auch den dazugehörigen Pause Ende Eintrag löschst,
                                um die Richtigkeit sicherzustellen
                            </span>
                            <span v-if="deleteTimeEntry.type === 'pauseend'">
                                Bitte beachte, dass du gegebenfalls auch den dazugehörigen Pause Start Eintrag löschst,
                                um die Richtigkeit sicherzustellen
                            </span>
                        </v-alert>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-btn class="white--text" @click="confirmDeleteTimeEntry" color="red">
                                    Eintrag löschen
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="!addPauseEntryStatus && !deleteTimeEntry">
                    <v-col cols="12">
                        <v-card-text>
                            Falls du einen Check-in oder Checkout vergessen hast, kannst du einen normalen Check-In bzw.
                            Check-Out durchführen und die Zeit hier anpassen.
                            <br />
                            <br />

                            Falls du einen Check-Out vergessen hast, wirst du automatisch nach 10 Stunden ausgecheckt.
                            Die Uhrzeit des Check-Outs lässt sich hier korrigieren.
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn color="primary" text @click="closeDialog">Fertig</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
import api from '@/api'
import userService from '@/services/userService'

export default {
    name: 'EditTimeEntry',
    props: {
        recentEntries: {
            type: Array,
            required: false,
        },
        day: {
            required: false,
        },
    },
    components: {},
    data() {
        return {
            adminEntries: null,
            newItem: {
                type: 'checkin',
                time: '',
            },
            userService,
            entryTypes: {
                checkin: 'Check-In',
                checkout: 'Check-Out',
                pause: 'Pause Start',
                pauseend: 'Pause Ende',
            },
            deleteTimeEntry: null,
            pauseDateSelection: this.$moment().format('YYYY-MM-DD'),
            showPauseStartTimePicker: false,
            showPauseEndTimePicker: false,
            defaultPauseStartTime: this.$moment().set({ hours: '12', minutes: '00' }).format('HH:mm'),
            defaultPauseEndTime: this.$moment().set({ hours: '13', minutes: '00' }).format('HH:mm'),
            addPauseEntryStatus: false,
            showTimeEntryTimePicker: false,
            showTimeEntryDatePicker: false,

            editTimeEntry: null,
            dialog: false,
        }
    },
    created() {
        if (this.adminAccess) {
            this.fetchAdminEntries()
        }
    },
    methods: {
        saveTimeEntry() {
            let entryToCreate = {
                date: this.$moment(this.day + ' ' + this.newItem.time).format('YYYY-MM-DD HH:mm'),
                type: this.newItem.type,
                reason: null,
            }
            api.createTimetrackerEntryEmployee(entryToCreate)
                .then(() => {
                    this.$emit('fetchEntries')
                    this.newItem = {
                        type: 'checkin',
                        time: '',
                    }
                    this.addPauseEntryStatus = false

                    this.$toast.success('Eintrag wurde gespeichert')
                })
                .catch(() => {
                    this.$toast.error('Eintrag konnte nicht gespeichert werden')
                })
        },
        fetchAdminEntries() {
            api.getTimetrackerEntriesAdmin(this.day)
                .then((entries) => {
                    this.adminEntries = entries
                })
                .catch(() => {
                    this.$toast.error('Einträge konnten nicht geladen werden')
                })
        },
        async confirmDeleteTimeEntry() {
            try {
                let status = await api.deleteTimetrackerEntryEmployee(this.deleteTimeEntry._id)
                console.log(status)
                this.deleteTimeEntry = null
                this.$emit('fetchEntries')
                this.$toast.success('Eintrag wurde gelöscht')
            } catch (e) {
                this.$toast.error('Eintrag konnte nicht gelöscht werden')
            }
        },
        async savePauseEntry() {
            let pauseStartDate = this.$moment(this.pauseDateSelection)
                .set({
                    hours: this.defaultPauseStartTime.split(':')[0],
                    minutes: this.defaultPauseStartTime.split(':')[1],
                    seconds: 0,
                    milliseconds: 0,
                })
                .format('YYYY-MM-DD HH:mm')
            let pauseEndDate = this.$moment(this.pauseDateSelection)
                .set({
                    hours: this.defaultPauseEndTime.split(':')[0],
                    minutes: this.defaultPauseEndTime.split(':')[1],
                    seconds: 0,
                    milliseconds: 0,
                })
                .format('YYYY-MM-DD HH:mm')

            try {
                let pauseStartEntry = {
                    date: pauseStartDate,
                    type: 'pause',
                    reason: null,
                }

                let status = await api.createTimetrackerEntryEmployee(pauseStartEntry)

                let entry = {
                    date: pauseEndDate,
                    type: 'pauseend',
                    reason: null,
                }

                status = await api.createTimetrackerEntryEmployee(entry)
                this.addPauseEntryStatus = false
                this.$toast.success('Erfolgreich gespeichert')
                this.$emit('fetchEntries')
                this.pauseDateSelection = this.$moment().format('YYYY-MM-DD')
                this.defaultPauseStartTime = this.$moment().set({ hours: '12', minutes: '00' }).format('HH:mm')
                this.defaultPauseEndTime = this.$moment().set({ hours: '13', minutes: '00' }).format('HH:mm')
            } catch (e) {
                this.$toast.error('Fehler beim Speichern')
            }
        },
        closeDialog() {
            this.$emit('fetchEntries')
            this.dialog = false
        },
        async updateTimeEntry() {
            console.log(this.editTimeEntry)
            try {
                const status = await api.updateTimetrackerEntryEmployee(this.editTimeEntry)
                this.editTimeEntry = null
                this.$toast.success('Erfolgreich gespeichert')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Speichern')
            }
        },
    },
    watch: {
        day() {
            this.fetchAdminEntries()
        },
    },
    computed: {
        getRightEntries() {
            return this.adminAccess ? this.adminEntries : this.recentEntries
        },
        adminAccess() {
            return (
                this.userService.getUserId() === '6110f9ddd2d4d90279c938d3' ||
                this.userService.getUserId() === '5f55fdad6716c132adf93baa' ||
                this.userService.getUserId() === '5f576b7b4a79770a841e819b' ||
                this.userService.getUserId() === '5f9bede251371940367ecbf8'
            )
        },
        editTimeEntryDate: {
            get() {
                if (this.editTimeEntry) {
                    return this.$moment(this.editTimeEntry.date).format('YYYY-MM-DD')
                }
                return null
            },
            set(value) {
                let hours = this.$moment(this.editTimeEntry.date).format('HH')
                let minutes = this.$moment(this.editTimeEntry.date).format('mm')
                this.editTimeEntry.date = this.$moment(value).set({ hours, minutes })
            },
        },

        editTimeEntryTime: {
            get() {
                if (this.editTimeEntry) {
                    return this.$moment(this.editTimeEntry.date).format('HH:mm')
                }
                return null
            },
            set(value) {
                let hours = value.split(':')[0]
                let minutes = value.split(':')[1]

                this.editTimeEntry.date = this.$moment(this.editTimeEntry.date).set({ hours, minutes })
            },
        },
    },
}
</script>
  
  <style scoped>
</style>
  