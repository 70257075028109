<template>
    <v-card dark max-width="700" class="mx-auto" flat>
        <v-row>
            <v-col cols="12" md="6">
                <v-select
                    class="mx-5"
                    v-model="selectedYear"
                    :items="years"
                    label="Jahr auswählen"
                    @change="updateWeeks"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    class="mx-5"
                    v-model="selectedWeek"
                    :items="formattedWeeks"
                    item-text="label"
                    item-value="week"
                    label="Woche auswählen"
                ></v-select>
            </v-col>
        </v-row>

        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">URL</th>
                        <th class="text-left">Kosten</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in settings" :key="item._id">
                        <td class="py-2">{{ item.externalLink }}</td>
                        <td class="py-2">
                            <v-text-field
                                hide-details
                                v-model="item.weeklyCostForSelectedWeek"
                                @blur="updateWeeklyCost(item)"
                                label="Kosten"
                                type="number"
                                outlined
                            ></v-text-field>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>
  
  <script>
import api from '@/api'
import moment from 'moment'

export default {
    data() {
        return {
            selectedYear: null,
            selectedWeek: null,
            years: [],
            formattedWeeks: [],
            settings: [],
        }
    },
    created() {
        this.selectedYear = moment().year()
        this.selectedWeek = moment().week()
        const currentYear = moment().year()
        this.years = Array.from({ length: 20 }, (_, i) => currentYear - 10 + i)
        this.updateWeeks()
        this.init()
    },
    methods: {
        async init() {
            try {
                let settings = await api.fetchLeadCostsSettings()
                this.settings = settings.map((setting) => ({
                    ...setting,
                    weeklyCostForSelectedWeek: this.getWeeklyCostForSelectedWeek(setting.weeklyCost),
                }))
            } catch (e) {
                console.log('error fetching settings', e)
            }
        },
        updateWeeks() {
            const numberOfWeeks = moment().year(this.selectedYear).weeksInYear()
            this.formattedWeeks = Array.from({ length: numberOfWeeks }, (_, i) => {
                const week = i + 1
                const start = moment().year(this.selectedYear).week(week).startOf('isoWeek')
                const end = moment().year(this.selectedYear).week(week).endOf('isoWeek')
                return {
                    week,
                    label: `Woche ${week} (${start.format('DD.MM')} - ${end.format('DD.MM')})`,
                }
            })
        },
        getWeeklyCostForSelectedWeek(weeklyCosts) {
            const cost = weeklyCosts.find((cost) => cost.week === this.selectedWeek && cost.year === this.selectedYear)
            return cost ? cost.cost : null
        },
        async updateWeeklyCost(item) {
            try {
                const newWeeklyCost = {
                    week: this.selectedWeek,
                    year: this.selectedYear,
                    cost: item.weeklyCostForSelectedWeek,
                }
                await api.updateLeadCost(item._id, newWeeklyCost)

                this.$toast.success('Kosten gespeichert')
                const settingToUpdate = this.settings.find((s) => s._id === item._id)
                if (settingToUpdate) {
                    const costIndex = settingToUpdate.weeklyCost.findIndex(
                        (c) => c.week === this.selectedWeek && c.year === this.selectedYear
                    )
                    if (costIndex !== -1) {
                        settingToUpdate.weeklyCost[costIndex].cost = newWeeklyCost.cost
                    } else {
                        settingToUpdate.weeklyCost.push(newWeeklyCost) // Wenn keine Kosten für diese Woche/Jahr existieren, füge sie hinzu
                    }
                    this.updateSettings() // Stelle sicher, dass alle Anzeigen aktualisiert werden
                }
            } catch (e) {
                console.error('Error updating weekly cost', e)
                this.$toast.error('Fehler beim speichern')
            }
        },
        updateSettings() {
            this.settings = this.settings.map((setting) => ({
                ...setting,
                weeklyCostForSelectedWeek: this.getWeeklyCostForSelectedWeek(setting.weeklyCost),
            }))
        },
    },
    watch: {
        selectedYear() {
            this.updateSettings()
        },
        selectedWeek() {
            this.updateSettings()
        },
    },
}
</script>
  
  <style lang="scss" scoped>
</style>
  