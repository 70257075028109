<template>
    <div class="pdf-drag-drop">
        <div class="dropzone" @dragover.prevent @drop="onDrop">
            <p class="mb-0">Ziehe die PDFs hier rein</p>
            <span class="mt-2" v-if="files.length">Dateien hinzugefügt: {{ files.length }}</span>
        </div>
        <v-btn v-if="files.length" text @click="files = []">Zurücksetzen</v-btn>
        <v-btn color="primary" class="mt-5" v-if="files.length" @click="mergeAndSave">
            Zusammenführen und speichern
        </v-btn>
    </div>
</template>
  
  <script>
import { PDFDocument } from 'pdf-lib'

export default {
    data() {
        return {
            files: [],
        }
    },
    methods: {
        onDrop(event) {
            event.preventDefault()
            const files = event.dataTransfer.files
            for (const file of files) {
                if (file.type === 'application/pdf') {
                    this.files.push(file)
                }
            }
        },
        async mergeAndSave() {
            if (this.files.length > 1) {
                const mergedPdfDoc = await this.mergePDFs()
                const pdfBytes = await mergedPdfDoc.save()
                this.downloadMergedPDF(pdfBytes)
            } else {
                alert('Bitte fügen Sie mindestens zwei PDF-Dateien hinzu.')
            }
        },
        async mergePDFs() {
            const mergedPdfDoc = await PDFDocument.create()

            for (const file of this.files) {
                const arrayBuffer = await file.arrayBuffer()
                const pdfDoc = await PDFDocument.load(arrayBuffer)
                const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices())
                copiedPages.forEach((page) => mergedPdfDoc.addPage(page))
            }

            return mergedPdfDoc
        },
        downloadMergedPDF(pdfBytes) {
            const blob = new Blob([pdfBytes], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'merged-document.pdf'
            link.click()
            URL.revokeObjectURL(link.href)
        },
    },
}
</script>
  
  <style scoped>
.pdf-drag-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.dropzone {
    border: 2px dashed #ccc;
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.dropzone:hover {
    background-color: #eee;
}
</style>
  