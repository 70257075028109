<!-- Payment.vue -->
<template>
    <div>
        <v-card max-width="500px" dark class="ma-auto">
            <v-expansion-panels>
                <v-expansion-panel v-for="(studio, i) of studios" :key="i + '-studioExpansionPanel'">
                    <v-expansion-panel-header>{{ studio.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content
                        v-if="paymentSettings && paymentSettings.find((e) => e.studio._id === studio._id)">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-subtitle class="px-0">Datatrans Login</v-card-subtitle>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    label="Benutzername"
                                    type="text"
                                    dark
                                    disabled
                                    v-model="paymentSettings.find((e) => e.studio._id === studio._id).datatrans.username">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    disabled
                                    label="Passwort (verschlüsselt)"
                                    v-model="paymentSettings.find((e) => e.studio._id === studio._id).datatrans.password">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    label="Notiz"
                                    disabled
                                    v-model="paymentSettings.find((e) => e.studio._id === studio._id).notes"></v-textarea>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-btn
                                    class="mx-auto white--text"
                                    color="red"
                                    @click="
                                        deletePaymentSetting(paymentSettings.find((e) => e.studio._id === studio._id)._id)
                                    "
                                    >Löschen</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-alert type="info" class="text-center">
                                    <span>Es wurden noch keine Zahlungseinstellungen für diese Studio angelegt.</span>
                                </v-alert>
                            </v-col>
                            <v-col cols="12">
                                <v-card-subtitle class="px-0">Datatrans Login</v-card-subtitle>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field label="Benutzername" type="text" dark v-model="newEntry.datatrans.username">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Passwort (wird verschlüsselt gespeichert)"
                                    v-model="newEntry.datatrans.password">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Notiz" v-model="newEntry.notes"></v-textarea>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-btn class="mx-auto" color="primary" @click="createPaymentSetting(studio._id)"
                                    >Erstellen</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </div>
</template>   
   
  <script>
import axios from 'axios'
import api from '../../../api'

export default {
    components: {},
    data() {
        return {
            studios: [],
            paymentSettings: [],
            newEntry: {
                notes: '',
                datatrans: {
                    username: '',
                    password: '',
                },
            },
        }
    },
    created() {
        this.fetchAllPaymentSettings()
    },
    methods: {
        resetNewEntryField() {
            this.newEntry = {
                notes: '',
                datatrans: {
                    username: '',
                    password: '',
                },
            }
        },
        async deletePaymentSetting(paymentSettingId) {
            if (confirm('Möchtest du die Zahlungseinstellung wirklich löschen?')) {
                try {
                    await api.deletePaymentSetting(paymentSettingId)
                    this.$toast.success('Zahlungseinstellungen wurden erfolgreich gelöscht.')
                    this.fetchAllPaymentSettings()
                    this.resetNewEntryField()
                } catch (e) {
                    this.$toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.')
                }
            } else {
                this.$toast.error('löschen abgebrochen')
            }
        },
        async createPaymentSetting(studioId) {
            this.newEntry.studio = studioId
            try {
                await api.createPaymentSetting(this.newEntry)
                this.$toast.success('Zahlungseinstellungen wurden erfolgreich angelegt.')
                this.fetchAllPaymentSettings()
                this.resetNewEntryField()
            } catch (e) {
                this.$toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.')
            }
        },
        async fetchAllPaymentSettings() {
            try {
                let settings = await api.fetchAllPaymentSettings()
                this.studios = settings.studios
                this.paymentSettings = settings.settings
            } catch (e) {
                this.$toast.error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.')
            }
        },
    },
    computed: {},
}
</script>
  