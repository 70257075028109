<template>
    <v-row no-gutters class="text-center">
        <v-col cols="12">
            <v-text-field class="mx-5" label="Titel" v-model="newProduct.title"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-text-field class="mx-5" label="Marke" v-model="newProduct.brand"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-text-field class="mx-5" label="Kategorie" v-model="newProduct.category"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-card-title>Vorderseite des Produkts</v-card-title>
            <label for="image_front" class="drop-container" id="dropcontainer">
                <div class="image-upload">
                    <v-icon class="ma-auto" x-large>mdi-image-plus</v-icon>
                </div>
                <v-file-input
                    multiple
                    @change="addImage($event, 'Imgupload_front')"
                    style="display: none"
                    type="file"
                    id="image_front"
                    accept="image/*"
                    required
                />
            </label>
        </v-col>
        <v-col cols="12">
            <v-card-title>Zutaten des Produkts</v-card-title>
            <label for="image_ingredients" class="drop-container" id="dropcontainer">
                <div class="image-upload">
                    <v-icon class="ma-auto" x-large>mdi-image-plus</v-icon>
                </div>
                <v-file-input
                    multiple
                    @change="addImage($event, 'Imgupload_ingredients')"
                    style="display: none"
                    type="file"
                    id="image_ingredients"
                    accept="image/*"
                    required
                />
            </label>
        </v-col>
        <v-col cols="12">
            <v-card-title>Nährwerte des Produkts</v-card-title>
            <label for="image_nutrition" class="drop-container" id="dropcontainer">
                <div class="image-upload">
                    <v-icon class="ma-auto" x-large>mdi-image-plus</v-icon>
                </div>
                <v-file-input
                    multiple
                    @change="addImage($event, 'Imgupload_nutrition')"
                    style="display: none"
                    type="file"
                    id="image_nutrition"
                    accept="image/*"
                    required
                />
            </label>
        </v-col>
        <!-- 

           <v-col cols="4" v-for="(image, index) in portionSizes.images" :key="'image' + index">
               <v-img
               contain
               class="mx-auto"
               v-if="image.dataUrl"
               height="65px"
               width="65px"
               :src="image.dataUrl"
               alt="Uploaded Image"
               ></v-img>
            </v-col>
        -->
        <v-col cols="12" class="justify-content-center text-center">
            <v-btn @click="saveNewProduct()">Speichern</v-btn>
        </v-col>
    </v-row>
</template>
<script>
import api from '@/api'

export default {
    name: 'BarcodeScanner',
    data() {
        return {
            newProduct: {
                title: '',
                category: '',
                brand: '',
                Imgupload_nutrition: null,
                Imgupload_ingredients: null,
                Imgupload_front: null,
            },
        }
    },
    methods: {
        async saveNewProduct() {
            const formData = new FormData()
            formData.append('code', 7610900863273)
            formData.append('product_name', this.newProduct.title.replaceAll(' ', '%20'))
            formData.append('brands', this.newProduct.brand)
            formData.append('category', this.newProduct.category)
            formData.append('user_id', 'wellcomefit')
            formData.append('password', '$81CPKv5dL1a#foQ')
            formData.append('Imgupload_nutrition', this.newProduct.Imgupload_nutrition)
            formData.append('Imgupload_ingredients', this.newProduct.Imgupload_ingredients)
            formData.append('Imgupload_front', this.newProduct.Imgupload_front)
            try {
                const response = await api.createOpenFoodProduct(formData)
                console.log('Produkt erfolgreich erstellt', response.data)
            } catch (error) {
                console.error('Fehler beim Erstellen des Produkts:', error)
            }
        },
        addImage(files, type) {
            for (let file of files) {
                this.newProduct[type] = file

                /*
                    api.uploadFormUpdateImage(formData)
                        .then((response) => {
                            const uploadedImageName = response.name

                            this.portionSizes.images.push({
                                title: '',
                                src: uploadedImageName,
                                dataUrl: e.target.result,
                            })
                            this.$forceUpdate()
                        })
                        .catch((error) => {
                            console.log('Upload failed', error)
                        })
                    */
            }
        },
    },
}
</script>
<style scoped>
.image-upload {
    height: 65px;
    width: 65px;
    display: flex;
    border: dashed;
    border-color: grey;
    border-radius: 6px;
}
</style>