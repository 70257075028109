var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"no-data-text":"Keine Daten verfügbar","disable-pagination":"","headers":_vm.headers,"items":_vm.flattenDataAllLevels(_vm.data),"item-key":"accountNumber"},scopedSlots:_vm._u([{key:"item.account",fn:function({ item }){return [_c('div',{style:({
                        paddingLeft: `${item.level * 20}px`,
                        'font-size': item.level === 0 ? '1.2rem' : '1rem',
                        'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                    })},[_vm._v(" "+_vm._s(item.accountNumber)+" "+_vm._s(item.accountName)+" ")])]}},{key:"item.balance",fn:function({ item }){return [_c('span',{style:({
                        'font-size': item.level === 0 ? '1.2rem' : '1rem',
                        'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                    })},[_vm._v(" "+_vm._s(_vm.displayCurrency(item.balance))+" ")])]}},{key:"item.type",fn:function({ item }){return [_c('span',{style:({
                        'font-size': item.level === 0 ? '1.2rem' : '1rem',
                        'font-weight': item.level === 0 || item.level === 1 ? 'bold' : 'normal',
                    })},[_vm._v(" "+_vm._s(item.type)+" ")])]}}])})],1),(!_vm.hideButtons)?_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-btn',{staticClass:"my-5 mx-2",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('startOver')}}},[_vm._v("Neu starten")])],1):_vm._e(),(!_vm.hideButtons)?_c('v-col',{attrs:{"cols":"6","align":"start"}},[_c('v-btn',{staticClass:"my-5 mx-2",attrs:{"color":"primary"},on:{"click":_vm.submitImport}},[_vm._v("Import abschliessen")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }