<template>
    <div class="terminplaner mb-15">
        <v-btn elevation="7" icon large @click="$router.push('/')"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <div class="intro-text">
            <div v-if="!editIntrotext" v-show="planer.introtext" v-html="format(planer.introtext)" class="tp-inhalt"></div>
            <vue-editor v-else v-model="planer.introtext"></vue-editor>
        </div>
        <div
            @click="editIntrotext = true"
            class="edit-intro-text"
            v-show="(user.admin || userService.hasRight('pt_admin')) && !editIntrotext">
            Intro Text Anpassen
        </div>
        <div
            @click="saveIntrotext"
            class="edit-intro-text_save"
            v-show="(user.admin || userService.hasRight('pt_admin')) && editIntrotext">
            Speichern
        </div>
        <div
            @click="editIntrotext = false"
            class="edit-intro-text"
            v-show="(user.admin || userService.hasRight('pt_admin')) && editIntrotext">
            Abbrechen
        </div>

        <div v-show="stwest || online" class="action-termine stwest">
            <span v-show="stwest"
                >Bitte wechsle auf das Studio St. Gallen Ost über den Button "Studio Auswählen", um deinen persönlich Termin
                zu wählen</span
            >
            <span v-show="online"
                >Bitte wechsle über den Button "Studio Auswählen" in ein Center Deiner Wahl, um deinen persönlich Termin zu
                wählen.</span
            >
            <div @click="studPicker()" class="stud-picker">Studio Auswählen</div>
        </div>

        <v-btn
            class="mx-auto font-weight-bold"
            large
            color="primary"
            elevation="7"
            block
            v-show="!disableNext && !anmeldung && !stwest && !online"
            @click="$router.push('/terminept/' + planer._id)">
            <span>Zur Termin Auswahl</span>
            <span class="material-icons"> navigate_next </span>
        </v-btn>
        <div v-show="!disableNext && anmeldung" class="action-termine_angemeldet">
            <span
                >Du hast dich für den Termin<br />
                <strong
                    >{{ convertToDate(anmeldung.datum) }} {{ convertToTime(anmeldung.startzeit) }} -
                    {{ convertToTime(anmeldung.endzeit) }} Uhr</strong
                >
                <br />
                angemeldet
            </span>
            <div @click="signOffCourse()" class="abmelden">Abmelden</div>
        </div>
        <div class="action-termine" v-show="disableNext">
            <span>Bitte passe zuerst den Introtext an, um das Modul zu aktivieren (nur Admins)</span>
        </div>
    </div>
</template>

<script>
import api from '../api'
import { VueEditor } from 'vue2-editor'
import userService from '@/services/userService.js'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    components: {
        VueEditor,
    },
    data() {
        return {
            userService: userService,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
            stwest: false,
            online: false,
            user: {},
            editIntrotext: false,
            disableNext: false,
            anmeldung: false,
            planer: {
                introtext: '',
            },
        }
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'toggleNavigationBarVisibility',
            'setNavigationbarVisibility',
        ]),
        format(i) {
            return i.split('{{studio}}').join()
        },
        studPicker() {
            window.app.$root.$children[0].checkStudio(true)
        },
        signOffCourse() {
            api.PTSignOff().then((data) => {
                if (data.status) {
                    this.checkSignup()
                }
            })
        },
        convertToDate(datum) {
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }
            return new Date(datum).toLocaleDateString('de-DE', options)
        },
        convertToTime(ts) {
            let hours = new Date(ts * 1000).getHours()
            let minutes = new Date(ts * 1000).getMinutes()

            if (hours < 10) {
                hours = '0' + hours
            }
            if (minutes < 10) {
                minutes = '0' + minutes
            }
            return hours + ':' + minutes
        },
        saveIntrotext() {
            api.PTEditIntro({ terminplaner: this.planer })
                .then((data) => {
                    if (data.status) {
                        this.editIntrotext = false
                    } else {
                        alert('fehler')
                    }
                })
                .catch((error) => {
                    alert('fehler')
                })
        },
        checkSignup() {
            this.anmeldung = false
            api.PTcheck().then((data) => {
                this.anmeldung = data
            })
        },
        loadIntrotext() {
            api.PTgetIntro().then((data) => {
                if (data.terminplaner) {
                    this.planer = data.terminplaner
                    if (data.terminplaner.studio_id == '5f3c30bfd91ddb464c2648b2') {
                        this.stwest = true
                    }
                    if (data.terminplaner.studio_id == '6009e4098b98783294411156') {
                        this.online = true
                    }
                } else {
                    this.disableNext = true
                    this.planer.introtext =
                        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam'
                }
            })
        },
    },
    mounted() {
        this.setNavigationbarVisibility(false)
    },
    created() {
        api.getUser().then((data) => {
            this.user = data.user
        })
        this.checkSignup()
        this.loadIntrotext()
    },
    beforeDestroy() {
        this.setNavigationbarVisibility(true)
    },
}
</script>

<style lang="scss" scoped>
.tp-inhalt {
    white-space: pre-wrap;
    p {
        margin-bottom: 0px !important;
    }
}
.tp-inhalt p {
    margin-bottom: 0px;
}
.terminplaner {
    .intro-text {
        padding: 20px 15px 10px 15px;
        h2 {
            font-size: 24px;
            line-height: 30px;
        }

        textarea {
            width: 100%;
        }
        span,
        textarea {
            line-height: 22px !important;
            display: flex;
            white-space: pre-wrap;
        }
    }
    .edit-intro-text {
        text-align: right;
        background-color: #474747;
        color: #fff;
        padding: 10px;
        &_save {
            text-align: right;
            margin-bottom: 1px;
            color: #fff;
            padding: 10px;
            background-color: var(--v-primary);
        }
    }

    .action-termine_angemeldet {
        width: 100%;
        padding-top: 20px;
        background-color: var(--v-primary);
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: white;
        margin-top: 20px;

        .abmelden {
            margin-bottom: 40px;
            margin-top: 40px;
            line-height: 50px;
            background-color: #474747;
        }
    }

    .action-termine {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100px;
        background-color: var(--v-primary);
        margin-top: 20px;

        &.stwest {
            flex-direction: column;
            padding-left: 10%;
            padding-right: 10%;
            padding-top: 20px;
            height: auto;

            .stud-picker {
                margin-top: 30px;
                text-align: center;
                margin-bottom: 20px;
                color: #fff;
                line-height: 40px;
                background-color: #474747;
            }
            * {
                font-weight: normal !important;
            }
        }
        span {
            margin: auto 0px auto 0px;
            color: white;
            font-weight: bold;
        }

        .material-icons {
            margin-left: 10px;
        }
    }
}
</style>
