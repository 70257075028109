<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Räume</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="rooms.length > 0" class="select-label">
           <label>Räume bearbeiten:</label>
            <select class="choose-label" @change="editRoom" v-model="editaroom">
              <option value="1" disabled>Raum auswählen (anklicken)</option>
              <option v-for="(raum, j) of rooms" :key="j" :value="raum._id">{{raum.name}}</option>
            </select>
          </div>

          <div v-else>Es gibt noch keine Räume, füge jetzt einen neuen Raum hinzu</div>
            <label v-if="editaroom == 1">Neuen Raum erstellen:</label>
             
          <div class="label-editor">
             <label v-if="editaroom != 1">Name:</label>
            <input placeholder="Name" type="text" v-model="aroom.name" />
            
          </div>
             <button v-if="editaroom == 1" type="button" class="btn btn-primary" @click.prevent="save">Hinzufügen</button>
             <div v-else  class="wrap-edit">
 <button type="button" class="btn btn-primary" @click.prevent="save">Speichern</button>
              <button type="button" class="btn btn-primary" @click.prevent="newRoom">Neuen Raum hinzufügen</button>
             </div>
            

            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal" key=" "
              @click.prevent="hide"
            >Zurück zum Zeitplan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import api from "../api";

export default {
  data() {
    return {
      error: "",
      rooms: [],
      editaroom: 1,
      aroom: {
        _id: null,
        name: "",
        studio_id: "",
      },
    };
  },
  methods: {
    newRoom(){
      this.editaroom = 1;
       this.aroom = {
        _id: null,
        name: "",
        studio_id: ""
      };
    },
    editRoom() {
      for (let rm of this.rooms) {
        if (rm._id == this.editaroom){
          this.aroom._id = rm._id;
          this.aroom.name = rm.name;
          this.aroom.studio_id = rm.studio_id;
        }
      }
    },
    show(roomsx) {
      this.rooms = roomsx;
      this.aroom.studio_id = JSON.parse(localStorage.getItem("studio"))._id;
      $(this.$refs["modal"]).modal("show");
    },
    hide() {
      $(this.$refs["modal"]).modal("hide");
    },
    save() {
       this.aroom.studio_id = JSON.parse(localStorage.getItem("studio"))._id;
      if (this.aroom.name) {
        api
          .createRoom(this.aroom)
          .then((data) => {
            if (data.status) {
              this.hide();
              this.editaroom = 1;
              this.aroom = {
                _id: null,
                name: "",
                studio_id: "",
              };
              this.$emit("rooms-edited");
            } else {
              alert("fehler");
            }
          })
          .catch((error) => {
            alert("fehler");
            console.log(error);
          });
      } else {
        alert("Bitte fülle alle benötigten Felder aus");
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  margin-bottom: 10px;
}
.choose-label {
  width: 100%;
  padding: 10px;
}
.label-editor {
  display: flex;
  flex-direction: column;
  input,
  textarea {
    padding: 10px;
    margin-bottom: 10px;
  }
  input[type="color"] {
    padding: 0px 10px;
  }
  label {
    padding: 0px 10px;
  }
}
.modal-content {
  height: 90vh;
}
</style>