:key="workout._id" v-for="workout of workouts"<template>
	<v-dialog
		:fullscreen="$vuetify.breakpoint.xs"
		max-width="500px"
		persistent
		v-model="statisticDialog"
		content-class="statisticDialog"
		:origin="origin"
	>
		<v-card style="background-color: white; overflow-x: hidden;">
			<div style="background-color: #f4fdf2; min-height: 100vh; ">
				<v-btn @click="closeStatisticDialog" absolute icon x-large right class="mt-2" style="z-index: 1000;"
					><v-icon class="primary--text">mdi-close</v-icon></v-btn
				>
				<v-card-title class="h4 "> Deine Trainings Statistik</v-card-title>
				<v-card-title class="h5 font-weight-bold">Trainingspläne</v-card-title>
				<v-row class="pa-1" style="flex-wrap: nowrap; overflow-x: scroll;">
					<v-col :key="workout._id" v-for="workout of workouts" class="pr-2" cols="5">
						<v-card class="rounded-lg" :img="'/img/uebungen/' + workout.form.picture">
							<v-responsive :aspect-ratio="1 / 1">
								<div class="workouts__card-text d-flex flex-column justify-space-between white--text rounded-lg">
									<v-card-title class="h5 pb-0 text-break">{{ workout.form.title }}</v-card-title>
								</div>
							</v-responsive>
						</v-card>
					</v-col>
				</v-row>
				<v-card-title class="h5 font-weight-bold">Übungen</v-card-title>
				<v-row class="pa-1" style="flex-wrap: nowrap; overflow-x: scroll;">
					<v-col :key="exercise._id" v-for="(exercise, index) of usedExercises" class="pr-2" cols="5">
						<v-card class="rounded-lg" :img="exercise.picture ? getImageUrl(exercise.picture) : getImageUrl(exercise.manual[0].picture)">
							<v-responsive :aspect-ratio="1 / 1">
								<lottie-player
									mode="normal"
									style="position: absolute; right: -12px; top: -12px; width:60px; height:60px;"
									v-if="index === 0"
									autoplay
									loop
									src="/img/lottiefiles/workout/firstPlace.json"
								></lottie-player>
								<v-img
									height="60px"
									width="60px"
									v-if="index === 1"
									src="/img/lottiefiles/workout/secondPlace.png"
									style="position: absolute; right: -12px; top: -12px;"
								></v-img>
								<v-img
									height="60px"
									width="60px"
									v-if="index === 2"
									src="/img/lottiefiles/workout/thirdPlace.png"
									style="position: absolute; right: -12px; top: -12px;"
								></v-img>
								<div class="workouts__card-text d-flex flex-column justify-space-between white--text rounded-lg">
									<v-card-title class="h5 pb-0 text-break">{{ exercise.title }}</v-card-title>
								</div>
							</v-responsive>
						</v-card>
					</v-col>
					<v-col class="pr-2" cols="5">
						<v-card class="rounded-lg" background="primary">
							<v-responsive :aspect-ratio="1 / 1">
								<div class="workouts__card-text d-flex flex-column justify-space-between white--text rounded-lg">
									<v-card-title class="h5 pb-0 text-break">Alle Übungen anzeigen</v-card-title>
								</div>
							</v-responsive>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</v-card>
	</v-dialog>
</template>
<script>
import api from '../../api'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
	components: {},
	props: {
		origin: {
			type: String,
			default: 'center center',
		},
	},
	data() {
		return {
			statisticDialog: true,
			workouts: [],
			usedExercises: [],
		}
	},
	async created() {
		this.toggleNavigationBarVisibility()
		let data = await api.getWorkoutsForStatistics()
		this.workouts = data.workouts
		data.workoutSets.forEach((exercise) => {
			let foundExercise = this.exerciseList().find((listItem) => listItem._id === exercise._id)
			foundExercise.count = exercise.count
			this.usedExercises.push(foundExercise)
		})
	},
	methods: {
		...mapGetters('deviceStore', ['exerciseList']),

		...mapActions('navigationStoreNotPersisted', ['toggleNavigationBarVisibility']),
		getImageUrl(image) {
			return '/img/uebungen/' + image.replace(/\.[^/.]+$/, '') + '.webp'
		},
		closeStatisticDialog() {
			this.toggleNavigationBarVisibility()
			this.statisticDialog = false
			setTimeout(() => {
				this.$emit('close')
			}, 500)
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
	overflow-y: hidden !important;
}
.statisticDialog {
	overflow-x: hidden !important;
}
::v-deep .v-dialog {
	transition: 0.75s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.workouts__card-text {
	position: absolute;
	left: 10px;
	bottom: 10px;
	max-width: 90%;
	background: rgba(grey, 80%);
}
</style>
