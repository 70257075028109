<template>
	<div class="rollenverwaltung">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Livestream Einstellungen</span>
		</div>
		<div class="wrapper">
			<label>Vimeo-Link</label>
			<input type="text" class="zoom-link" v-model="zoom.link" />
			<div class="zoom-active-wrapper">
				<input type="checkbox" v-model="zoom.active" />
				<label>Vimeo Link Aktiv?</label>
			</div>
			<div class="speichern" @click="save">Speichern</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'

export default {
	data() {
		return {
			zoom: {
				link: 'test',
				active: false,
			},
		}
	},
	methods: {
		save() {
			api.editZoomLink(this.zoom)
				.then((data) => {
					if (data.status == 1) {
						alert('Zoomlink erfolgreich gespeichert')
					}
				})
				.catch((error) => {
					alert('es gab einen Fehler')
				})
		},
	},
	created() {
		api.getZoomLink()
			.then((data) => {
				this.zoom = data
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>
<style lang="scss" scoped>
#rolleneditor {
	padding: 20% 10px 20% 10px;
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	background-color: white;
	h4 {
		text-align: center;
		margin-bottom: 30px;
	}
	h5 {
		text-align: center;
	}
	.rolle {
		padding-left: 0px !important;
	}
	.rollen-wrapper {
		max-width: 400px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		padding: 5px;
		input {
			width: 20px;
			height: 20px;
			padding: 10px;
		}
	}

	.speichern {
		background-color: var(--v-primary);
		color: white;
		margin: 20px auto 0px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
	.schliessen {
		background-color: #443838;
		color: white;
		margin: 20px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
	.delete {
		background-color: red;
		color: white;
		margin: 40px auto 0px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
}
label {
	padding-left: 10px;
}
.search {
	position: relative;
	.material-icons {
		position: absolute;
		top: 10px;
		right: 20px;
		color: #4a4a4a;
	}
	input {
		color: #4a4a4a;
		width: 100%;
		padding: 15px 15px 10px 15px;
		border-bottom: 1px solid #4a4a4a;
	}
}
.save {
	color: white;
	width: 130px;
	background-color: var(--v-primary);
	margin: 20px auto;
	text-align: center;
	padding: 10px;
	border-radius: 40px;
}

.user {
	color: #4a4a4a;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	display: flex;
	border-bottom: 1px solid #4a4a4a;

	.username {
		max-width: 80%;
		display: block;
	}
	.material-icons {
		margin-left: auto;
		margin-right: 10px;
		margin-top: auto;
		margin-bottom: auto;
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
