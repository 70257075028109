<template>
    <div>
        <v-navigation-drawer v-model="drawer" absolute right temporary>
            <v-card-title class="font-weight-bold">Warenkorb</v-card-title>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item
                    @click="openProduct(product._id)"
                    class="justify-space-around mb-2 py-1"
                    v-for="(product, index) in cartProducts"
                    :key="product.title + index"
                    link>
                    <div style="width: 20%">
                        <v-btn icon color="grey" @click.stop="removeProduct(product)"
                            ><v-icon>mdi-minus-circle</v-icon></v-btn
                        >
                    </div>
                    <div style="max-width: 60%">
                        <v-list-item-title>{{ product.title }}</v-list-item-title>
                        <v-list-item-subtitle> {{ product.shortDescription }}</v-list-item-subtitle>
                        {{ product.amount }}x à {{ product.price }} CHF
                        <v-divider class="my-1"></v-divider>
                        <span class="primary--text">{{ product.amount * product.price }} CHF </span>
                    </div>
                    <div style="width: 20%">
                        <v-btn icon color="grey" @click.stop="addProduct(product)"><v-icon>mdi-plus-circle</v-icon></v-btn>
                    </div>
                </v-list-item>
            </v-list>
            <v-divider v-if="cartProducts.length"></v-divider>

            <v-card-title style="word-break: break-word">{{
                cartTotalPrice ? cartTotalPrice + ' CHF' : 'Keine Produkte im Warenkorb'
            }}</v-card-title>
            <v-card-actions v-if="cartProducts.length"
                ><v-btn @click="proceedToCheckout()" color="primary" rounded x-large
                    ><span class="pa-2" style="word-break: break-word">Personalisieren & <br />zur Kasse</span></v-btn
                ></v-card-actions
            >
        </v-navigation-drawer>
        <v-dialog v-model="noUserFoundDialog">
            <v-card>
                <v-col cols="12">
                    <v-card-title style="word-break: keep-all" class="justify-center"
                        >Melde dich mit deinem Roody Account an oder erstelle einen neuen Account um
                        fortzufahren</v-card-title
                    >
                </v-col>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn color="primary" @click="showLoginDialog = true">Anmelden</v-btn></v-col
                >
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn text color="primary" @click="showRegisterDialog = true">Neuen Account erstellen</v-btn>
                </v-col>
            </v-card>
        </v-dialog>
        <register
            @register="newAccountRegistered"
            :auto-login="false"
            requirePassword
            @close="showRegisterDialog = false"
            :active="showRegisterDialog"></register>
        <login @login="accountLogin" :auto-login="false" @close="showLoginDialog = false" :active="showLoginDialog"></login>
    </div>
</template>
  
  <script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'
import register from '../launchScreen/register.vue'
import login from '../launchScreen/login.vue'

export default {
    components: {
        register: register,
        login: login,
    },
    data() {
        return {
            noUserFoundDialog: false,
            showLoginDialog: false,
            showRegisterDialog: false,
            drawer: false,
            products: [],
            user: null,
        }
    },
    async created() {
        this.fetchCustomer()
    },
    computed: {
        ...mapState('shopStore', ['cartVisibilityToggle', 'cartProducts']),
        ...mapGetters('shopStore', ['cartTotalPrice']),
    },
    methods: {
        ...mapActions('shopStore', ['addProductToCart', 'removeProductFromCart', 'openProductFromStore']),
        async fetchCustomer() {
            let customer = await api.getUser()
            this.user = customer.user
        },
        proceedToCheckout() {
            if (!this.user) {
                return (this.noUserFoundDialog = true)
            }
            if (this.cartProducts.length) {
                this.$router.push('/shop/checkout')
            } else {
                this.$toast.error('Füge mindestens ein Produkt zum Warenkorb hinzu.')
            }
        },
        addProduct(product) {
            this.addProductToCart(product)
        },
        removeProduct(product) {
            this.removeProductFromCart(product)
        },
        openProduct(id) {
            console.log('opening')
            this.openProductFromStore(id)
        },
        async newAccountRegistered(data) {
            localStorage.setItem('token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))
            this.showRegisterDialog = false

            api.getUserRights().then((r) => {
                localStorage.setItem('userRights', JSON.stringify({ userRights: r }))
                this.setUserRights(JSON.parse(JSON.stringify(r)))
            })
            this.fetchCustomer()
            this.noUserFoundDialog = false
            this.$toast.success('Neuer Account wurde erfolgreich erstellt.')
        },
        async accountLogin(login) {
            localStorage.setItem('token', login.token)
            localStorage.setItem('user', JSON.stringify(login.user))

            api.getUserRights().then((r) => {
                localStorage.setItem('userRights', JSON.stringify({ userRights: r }))
                this.setUserRights(JSON.parse(JSON.stringify(r)))
            })
            this.showLoginDialog = false
            this.noUserFoundDialog = false
            this.$toast.success('Erfolgreich eingeloggt.')
            this.fetchCustomer()
        },
    },
    watch: {
        cartVisibilityToggle() {
            this.drawer = true
        },
    },
}
</script>
  