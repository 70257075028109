<template>
    <div class="news">
        <v-row>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn color="primary" class="white--text mx-auto" @click="addNews()">Hinzufügen</v-btn>
            </v-col>
            <v-col v-for="(newsItem, key) of news" :key="key" cols="12" md="6" lg="4" xl="3">
                <v-card
                    hover
                    dark
                    @click="editNews(key)"
                    class="mx-auto rounded-xl pb-5 news__entry fill-height"
                    :class="{ active: checkIfActive(newsItem.von, newsItem.bis) }"
                    max-width="344"
                >
                    <v-card-title>{{ trimLength(newsItem.titel, 20) }}</v-card-title>
                    <v-card-subtitle>
                        {{ formatDate(newsItem.von) }} Uhr - {{ formatDate(newsItem.bis) }} Uhr
                    </v-card-subtitle>
                    <v-card-text class="pb-0" v-if="newsItem.readBy && newsItem.readBy.length > 0">
                        gelesen von: {{ uniqueReaders(newsItem.readBy) }}
                    </v-card-text>
                    <v-card-text class="my-0 py-0">
                        Foto hinterlegt:
                        <span v-if="newsItem.foto && newsItem.foto.length > 0">
                            <v-icon color="primary">mdi-check</v-icon>
                        </span>
                        <span v-else><v-icon color="primary">mdi-close</v-icon></span>
                    </v-card-text>

                    <v-card-text v-if="newsItem.studio_id">{{ getStudioById(newsItem.studio_id) }}</v-card-text>
                    <v-card-text v-if="newsItem.studios && newsItem.studios.length > 0">
                        <v-chip small class="studio ma-1" v-for="studio of newsItem.studios" :key="studio">
                            {{ getStudioById(studio) }}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog class="news__dialog" background="white" v-model="newsDialog" fullscreen persistent>
            <v-card v-if="editNewsItem" elevation="0" max-width="500" class="mx-auto editor">
                <div style="max-width: 500px" class="mx-auto">
                    <v-card-title v-if="editNewsItem.titel && editNewsItem.titel.length > 0" class="news__dialog-title">
                        {{ trimLength(editNewsItem.titel, 20) }}
                    </v-card-title>
                    <v-card-title v-else class="news__dialog-title">Neuer Eintrag</v-card-title>
                    <v-row no-gutters class="px-5">
                        <v-col cols="12" class="mt-5">
                            <v-text-field v-model="editNewsItem.titel" label="Titel"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="editNewsItem.showTitle" label="Titel anzeigen"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <vue-editor
                                class="user-selectable"
                                :editorToolbar="customToolbar"
                                v-model="editNewsItem.inhalt"
                            ></vue-editor>
                        </v-col>
                        <!---
					<v-switch v-model="showLink" :label="showLink ? 'Link aktiv' : 'Link inaktiv'"></v-switch>
					<v-col v-if="showLink" cols="12"><v-text-field label="Link (optional)"></v-text-field></v-col>
					<v-col v-if="showLink" cols="12"><v-text-field label="HEX Farbe des Links (optional)"></v-text-field></v-col>
						-->
                        <v-col class="my-5" cols="12">
                            <slim-cropper
                                :key="editNewsItem.foto"
                                style="width: 300px; margin: auto"
                                class="d-table"
                                :options="slimOptionsExercise"
                            >
                                <img
                                    :key="editNewsItem.foto"
                                    v-if="editNewsItem.foto"
                                    :src="'/img/news/' + editNewsItem.foto"
                                    alt=""
                                />
                                <input type="file" accept="image/*" />
                            </slim-cropper>
                            <v-card-subtitle class="mx-auto text-center">Format: 1:1 (800x800)</v-card-subtitle>
                        </v-col>

                        <v-col cols="12" class="d-flex justify-center align-center">
                            <v-date-picker @change="setDates()" locale="de-DE" v-model="dates" range></v-date-picker>
                        </v-col>
                        <v-col cols="12 mb-15 ">
                            <v-select
                                class="mx-5"
                                v-model="editNewsItem.studios"
                                :items="studios"
                                item-text="name"
                                item-value="_id"
                                chips
                                label="Studios"
                                multiple
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-actions class="news__dialog-actions">
                        <v-btn color="grey darken-1" text @click="newsDialog = false">Abbrechen</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary darken-1" text @click="saveNews">Speichern</v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import { VueEditor } from 'vue2-editor'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    components: {
        VueEditor,
        'slim-cropper': Slim,
    },
    data() {
        return {
            slimOptionsExercise: {
                ratio: '1:1',
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
            newsDialog: false,
            newsdata: {
                _id: null,
                titel: null,
                inhalt: null,
                link: '',
                link_text: '',
                link_color: '',
                foto: null,
                von: null,
                bis: null,
                studio_id: null,
                showTitle: false,
                studios: [],
            },
            showLink: false,
            news: {},
            editNewsItem: {},
            dates: [],
            studios: [],
            loading: true,
        }
    },
    created() {
        this.init()
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility', 'toggleNavigationBarVisibility']),
        uniqueReaders(array) {
            let uniqueArray = [...new Set(array)]
            return uniqueArray.length
        },
        getStudioById(studioId) {
            let studio = this.studios.find((studio) => studio._id === studioId)
            if (studio && studio.name) return studio.name
            return ''
        },
        saveNews() {
            if (!(this.editNewsItem.titel && this.editNewsItem.titel.length > 2))
                return this.$toast.error('Der Titel muss mindestens 3 Zeichen lang sein')

            api.saveNews(this.editNewsItem).then((data) => {
                this.$toast.success('Die News wurde gespeichert')

                this.init()
                this.newsDialog = false
            })
        },
        setDates() {
            if (this.dates.length > 0) {
                if (this.dates.length > 1) {
                    this.editNewsItem.von = this.$moment(this.dates[0]).unix()
                    this.editNewsItem.bis = this.$moment(this.dates[1]).unix()
                } else {
                    this.editNewsItem.von = this.$moment(this.dates[0]).unix()
                    this.editNewsItem.bis = this.$moment(this.dates[0]).unix()
                }
            } else {
                this.editNewsItem.von = null
                this.editNewsItem.bis = null
            }
        },
        addNews() {
            this.showLink = false
            this.newsDialog = true
            this.editNewsItem = this.newsdata
            this.dates = []

            this.editNewsItem.titel = ''
        },
        editNews(key) {
            this.showLink = true
            this.dates = []
            this.editNewsItem = JSON.parse(JSON.stringify(this.news[key]))
            if (this.editNewsItem.bis)
                this.dates.push(this.$moment(parseInt(this.editNewsItem.bis) * 1000).format('YYYY-MM-DD'))
            if (this.editNewsItem.von && !this.editNewsItem.bis)
                this.dates.push(this.$moment(parseInt(this.editNewsItem.von) * 1000).format('YYYY-MM-DD'))
            if (this.editNewsItem.von)
                this.dates.push(this.$moment(parseInt(this.editNewsItem.von) * 1000).format('YYYY-MM-DD'))
            if (this.editNewsItem.studio_id && !this.editNewsItem.studios.length)
                this.editNewsItem.studios.push(this.editNewsItem.studio_id)
            this.newsDialog = true
        },
        checkIfActive(from, till) {
            let now = new Date()
            let from_ts = new Date(parseInt(from) * 1000)
            let till_ts = new Date(parseInt(till) * 1000)
            if (now > from_ts && now < till_ts) {
                return true
            }
            return false
        },
        trimLength(str, length) {
            if (str.length > length) {
                return str.substring(0, length) + '...'
            }
            return str
        },
        async init() {
            let news = await api.getAllNews().catch((e) => this.$toast.error('Fehler beim Laden der News'))
            let studios = await api.fetchStudios().catch((e) => this.$toast.error('Fehler beim Laden der Studios'))
            this.news = news
            this.studios = studios
            console.log('im news ischs fertig')
            this.$emit('finishedloading')
        },
        formatDate(ts) {
            return this.$moment(parseInt(ts) * 1000).format('DD.MM.YYYY')
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'news'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.editNewsItem.foto = 'uploads/' + data.name

                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
    },
    watch: {
        newsDialog(value) {
            this.setNavigationbarVisibility(!value)
        },
    },
}
</script>

<style lang="scss" scoped>
.news {
    &__entry {
        &.active {
            background-color: var(--v-primary);
            * {
                color: white !important;
            }
        }
    }
    &__dialog {
        position: relative;
        &-title {
            position: sticky;
            top: 0;
            background-color: white;
            border-bottom: 2px solid var(--v-primary);
            z-index: 9;
        }
        &-actions {
            z-index: 999;
            position: fixed;
            bottom: 0;
            background-color: #fff;
            border-top: 2px solid var(--v-primary);
            width: 100%;
            max-width: 500px;
        }
    }
}
</style>
