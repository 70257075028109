<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Bewertungen</span>
		</div>
		{{ api }}
		<div class="counter"></div>

		<div class="bewertungen-wrapper"></div>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'

export default {
	data() {
		return {
			counter: {},
			feedbacks: [],
			api: {},
		}
	},
	methods: {
		runden(number) {
			if (number && number !== 'infinity') {
				if (number > 100) {
					return 100
				} else {
					return Math.round(number)
				}
			} else {
				return '0'
			}
		},
	},
	updated() {},
	created() {
		api.getStudiovergleich().then((data) => {
			this.api = data
		})
	},
}
</script>
<style lang="scss" scoped>
.kundenname {
	font-weight: bold;
	font-size: 18px;
}
.counter {
	max-width: 700px;
	margin: 0 auto;
	display: flex;

	table {
		border-collapse: collapse;
		width: 100%;
		margin-bottom: 20px;
	}

	td,
	th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}
}
.bewertungen-wrapper {
	max-width: 700px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	.frage {
		font-weight: bold;
	}
	.feedback {
		margin-top: 20px;
		margin-bottom: 20px;

		.sterne-wrapper {
			margin: 10px 0px;
		}

		.kommentar {
			margin-bottom: 20px;
		}
	}

	.trainer {
		.name {
			padding: 10px 5px;
			background-color: var(--v-primary);
			font-weight: bold;
			color: white;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.anz-wrapper {
				margin-right: 5px;
			}
		}
	}
}
.rating-star {
	display: inline-block;
	position: relative;
	padding-right: 5px;
	margin-left: -5px;
	color: #e3cf7a;
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
