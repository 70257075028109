<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Deine Kursbewertungen</span>
		</div>

		<div class="kursbewertungen">
			<input @change="loadRatings()" v-model="filterDate" type="date" />
			<div v-for="(leiter, kursname, i) in kursbewertungen" :key="i" class="kurs">
				<div class="kurs-name">
					{{ kursname }}
				</div>

				<div v-for="(rating, name, y) in leiter" :key="y" class="leiter">
					<div class="leiter-name">
						{{ name }}
					</div>

					<div v-for="(r, j) in rating" :key="j" class="rating">
						<div class="rating-name">
							{{ r.name }} |
							{{ r.ts }}
						</div>
						<div class="rating-stars">
							<i v-for="(yikes, y) in r.stars" :key="y" class="fa fa-2x rating-star fa-star selected"></i>
						</div>
						<div v-show="rating.stars > 3 || userService.hasRight('gf_r_threestars')" class="rating-rating">
							{{ r.rating }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'
import userService from '../../services/userService'
export default {
	data() {
		return {
			kursbewertungen: {},
			filterDate: null,
			rollen: {},
			userService,
		}
	},
	methods: {
		loadRatings() {
			api.getAllRatings(Math.round(new Date(this.filterDate) / 1000))
				.then((data) => {
					this.kursbewertungen = data
				})
				.catch((error) => {
					console.log(error)
				})
		},
	},
	created() {
		this.filterDate = new Date().toLocaleDateString('en-CA')

		api.getAllRatings(Math.round(new Date(this.filterDate) / 1000))
			.then((data) => {
				this.kursbewertungen = data
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>
<style lang="scss" scoped>
.kursbewertungen {
	display: flex;
	flex-direction: column;
	max-width: 400px;
	padding: 15px;
	margin: auto;
	input {
		padding: 10px 15px;
	}
	.kurs {
		&-name {
			background-color: var(--v-primary);
			padding: 10px 15px;
			color: white;
			margin-bottom: 5px;
		}

		.leiter {
			padding: 10px 15px;
			margin-bottom: 10px;
			&-name {
				font-weight: bold;
			}
			.rating {
				margin-bottom: 20px;
				&-rating {
					margin: 5px 5px;
				}
				&-name {
					margin-top: 5px;
				}
				&-stars {
					margin: 5px 5px;

					.rating-star {
						display: inline-block;
						position: relative;
						padding-right: 5px;
						margin-left: -5px;
						color: #e3cf7a;
					}
				}
			}
		}
	}
}
.download-survey {
	position: fixed;
	bottom: 50px;
	width: 300px;

	background-color: var(--v-primary);
	color: white;
	text-align: center;
	right: calc(50% - 150px);
	padding: 10px 0px;
	border-radius: 5px;
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
