<template>
    <div>
        <v-col cols="12">
            <v-img height="65px" contain src="/img/roodyLogoGreen.png"></v-img>
        </v-col>
        <v-row class="mt-5">
            <v-card-title class="font-weight-bold h2" style="word-break: break-word">Zahlung Erfolgreich</v-card-title>
            <v-card-title style="word-break: break-word">
                Vielen Dank für deine Zahlung!
                <br />
                Du wirst in Kürze automatisch zurück zur Startseite weitergeleitet.
            </v-card-title>
            <v-card-actions class="justify-content-center mt-5">
                <v-btn color="primary" @click="$router.push('/')">zurück zur Startseite</v-btn>
            </v-card-actions>
        </v-row>
    </div>
</template>
  
  <script>
export default {
    async created() {
        setTimeout(async () => {
            this.$router.push('/')
        }, 10000)
    },
}
</script>
  