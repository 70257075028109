<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Gerät und Übungen importieren</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="studio_code">Studio auswählen</label>
            <br />
            <!----   <label v-else for="studio_code">Studio Code eingeben</label> --->
            <select id="studio_code" @change="pickstudio" v-model="studiocode">
              <option disabled value>Studio auswählen</option>
              <option
                v-for="(studio, index) of studios"
                :key="index"
                :value="studio.code"
              >
                {{ studio.name }}
              </option>
            </select>
          </div>
          <div class="geraete">
            <div class="search">
              <input
                placeholder="Gerät suchen..."
                v-on:input="filterGeraete"
                type="text"
                v-model="search"
              />
              <span class="material-icons">search</span>
            </div>
            <div
              class="panel-group"
              v-for="(site, i) of filtered_geraete_db"
              :key="i"
            >
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" :href="'#collapse' + site._id">{{
                      site.titel
                    }}</a>
                    <div class="action-import-wrapper">
                      <a
                        data-toggle="collapse"
                        :href="'#collapse' + site._id"
                        class="show-import-info"
                      >
                        <span class="material-icons"> visibility </span>
                      </a>
                      <div @click="importGeraet(site)" class="import-geraet">
                        <span class="material-icons"> file_copy </span>
                      </div>
                    </div>
                  </h4>
                </div>
                <div
                  :id="'collapse' + site._id"
                  class="panel-collapse collapse"
                >
                  <div class="panel-body">
                    <div
                      v-for="(uebung, x) of site.uebungen"
                      :key="x"
                      class="uebungen-geraet"
                    >
                      <div class="uebung-geraet-titel">
                        {{ uebung.uebung.titel }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.prevent="hide"
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import api from "../api";

export default {
  data() {
    return {
      studiocode: "",
      error: "",
      studios: [],
      changeStandort: false,
      geraet: {},
      geraete_db: [],
      filtered_geraete_db: [],
      search: "",
    };
  },
  methods: {
    importGeraet(geraet) {
      let studio_id = JSON.parse(localStorage.getItem("studio"))._id;
    
      if (
        confirm(
          "Wollen Sie das Gerät " +
            geraet.titel +
            " mit sämtlichen dazugehörigen Übungen importieren?"
        )
      ) {
        api
          .checkGeraetbeforeImport({ "geraet": geraet, "studio_id": studio_id })
          .then((data) => {
            if (data.length > 0){
              if (confirm("Es gibt bereits "+data.length+" Geräte mit dem gleichen / ähnlichen Namen.. Trotzdem importieren?")){
                 this.importGeraetConf({"geraet": geraet, "studio_id": studio_id});
              }
            }else{
              this.importGeraetConf({"geraet": geraet, "studio_id": studio_id});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    importGeraetConf(data){
      api.importGeraet(data).then((data) => {
        if (data.status) {
           alert("import erfolgreich");
            this.$emit("imported");
        }else{
          alert("fehler");
        }
      }).catch((error) => {
        console.log("fehler");
      });
    },
    pickstudio() {
      this.geraete_db = [];
      this.filtered_geraete_db = [];
      this.search = "";

      let studio_id = "";
      for (let studio of this.studios) {
        if (studio.code == this.studiocode) {
          studio_id = studio._id;
        }
      }
      api
        .getUebungbyStudioId(studio_id)
        .then((uebungen) => {
          console.log(uebungen);
          api
            .getGeraetebyStudioId(studio_id)
            .then((geraete) => {
              for (let geraet of geraete) {
                let geraet_tmp = geraet;
                geraet_tmp.uebungen = [];

                for (let uebung of uebungen) {
                  if (geraet.old_id == uebung.geraet.old_id) {
                    geraet_tmp.uebungen.push(uebung);
                  }
                }
                this.geraete_db.push(geraet_tmp);
              }
              this.filtered_geraete_db = this.geraete_db;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterGeraete() {
      this.filtered_geraete_db = this.geraete_db.filter((geraet) => {
        return (
          geraet.titel.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    show() {
      api
        .getStudios()
        .then((studios) => {
          this.studios = studios;
        })
        .catch((error) => {
          console.log(error);
        });
      $(this.$refs["modal"]).modal("show");
    },
    hide() {
      
       this.$emit("imported", {
         "test": 1
       });
      $(this.$refs["modal"]).modal("hide");
    },
    save() {
      this.$emit("save-studio", {
        studio_code: this.studiocode,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  height: 90vh;
}
.modal-body {
  overflow: scroll;
}

.panel-title {
  display: flex;
  justify-content: space-between;
  > a {
    line-height: 32px;
  }
  .action-import-wrapper {
    display: flex;

    div,
    a {
      padding: 5px;
    }
  }
  a {
    color: black;
    font-size: 16px;

    &:hover {
      text-decoration: none;
    }
  }
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}

#studio_code {
  width: 94%;
}

.search {
  position: relative;
  margin-bottom: 10px;
  .material-icons {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  input {
    width: 100%;
    padding: 15px 15px 10px 15px;
    border-bottom: 1px solid black;
  }
}
</style>