<template>
    <div class="studioSelection">
        <v-dialog v-model="visible" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card flat>
                <v-btn v-if="selected" @click="back" class="back-btn" fab dark color="white">
                    <v-icon color="black">mdi-chevron-left</v-icon>
                </v-btn>
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-center">
                        <GmapMap
                            ref="map"
                            :options="{
                                zoomControl: false,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUI: true,
                            }"
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="z-index: 999; width: 100vw; height: 35vh; max-width: 1140px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="false"
                                :draggable="false"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-col>
                </v-row>
                <v-row style="max-width: 620px" class="mx-auto" v-if="!selected && !loading" no-gutters>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-card-title class="text-center">WÄHLE DEIN STANDORT AUS</v-card-title>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-chip
                            :color="selectedCanton === canton ? 'primary' : 'default'"
                            @click="selectCanton(canton)"
                            v-for="canton of studioCantons"
                            :key="canton"
                            class="ma-2"
                        >
                            {{ canton }}
                        </v-chip>
                    </v-col>
                    <v-col
                        v-show="!selectedCanton || selectedCanton === canton"
                        v-for="canton of studioCantons"
                        :key="canton + '-col'"
                        cols="12"
                    >
                        <v-row no-gutters v-if="!selectedCanton">
                            <v-col class="text-center" cols="12">
                                <v-card-subtitle>{{ canton }}</v-card-subtitle>
                            </v-col>
                        </v-row>

                        <v-row :class="{ 'mt-5': selectedCanton }" class="studioSelection__list" no-gutters>
                            <v-col
                                class="studioSelection__list-studio py-3 mb-5 d-flex flex-column px-2 mx-1"
                                v-for="studio of studios.filter((e) => e.canton === canton)"
                                :key="studio._id"
                                @click="selectStudio(studio)"
                                :class="{ active: studio.active }"
                                cols="5"
                            >
                                <div class="d-flex flex-row pb-2">
                                    <v-icon class="pr-2" color="white">mdi-map-marker</v-icon>
                                    <strong class="">{{ studio.name }}</strong>
                                </div>

                                <div
                                    v-if="studio.code !== 'ot'"
                                    class="studioSelection__list-studio-adresse d-flex flex-column"
                                >
                                    <span>{{ studio.adresse.split(',')[0] }}</span>
                                    <span>{{ studio.adresse.split(',')[1] }}</span>
                                </div>
                                <div v-else class="studioSelection__list-studio-adresse d-flex flex-column">
                                    <span>Online Training mit Livestreams</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row style="max-width: 620px" class="mx-auto" v-else-if="selected && !loading" no-gutters>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-card-title class="text-center">{{ selectedStudio.name }}</v-card-title>
                    </v-col>
                    <v-col cols="12">
                        <div class="studioSelection__selected__infos px-5">
                            <div v-if="selectedStudio.code !== 'ot'" class="pb-3">
                                <v-icon class="pr-2" color="primary">mdi-map-marker</v-icon>
                                <span>{{ selectedStudio.adresse }}</span>
                            </div>
                            <div v-if="selectedStudio.code !== 'ot'" class="pb-3">
                                <v-icon class="pr-2" color="primary">mdi-email</v-icon>
                                <a :href="'mailto:' + selectedStudio.email">{{ selectedStudio.email }}</a>
                            </div>
                            <div v-if="selectedStudio.code !== 'ot'" class="pb-3">
                                <v-icon class="pr-2" color="primary">mdi-phone</v-icon>
                                <a :href="'tel:' + selectedStudio.telefon">{{ selectedStudio.telefon }}</a>
                            </div>
                            <div v-else class="pb-3"><span>Online Training mit Livestreams</span></div>
                        </div>
                    </v-col>
                </v-row>
                <attendance-trainer-list
                    :showBades="userService.hasRight('tp_create')"
                    v-if="selected && !loading"
                    :maxWidth="'620px'"
                    :marginXAuto="true"
                    :noGutters="true"
                    :showTrainerButtons="false"
                    :key="selectedStudio._id"
                    :studio="selectedStudio._id"
                ></attendance-trainer-list>
                <v-row
                    style="max-width: 620px; padding-bottom: 200px"
                    class="mx-auto"
                    v-if="selected && !loading && selectedStudio.openingHours"
                    no-gutters
                >
                    <v-col cols="12" class="justify-content-center">
                        <v-card-title class="justify-content-center pb-0" v-if="selectedOpeningHours">
                            Öffnungszeiten
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-chip
                            class="ma-1"
                            :class="{ primary: selectedOpeningHours === 'Checkin' }"
                            @click="selectedOpeningHours = 'Checkin'"
                            v-if="selectedStudio.alwaysCheckin"
                        >
                            Checkin
                        </v-chip>
                        <v-chip
                            class="ma-1"
                            :class="{ primary: selectedOpeningHours === openingHours.title }"
                            @click="selectedOpeningHours = openingHours.title"
                            v-for="(openingHours, index) of selectedStudio.openingHours"
                            :key="'chip' + index"
                        >
                            {{ openingHours.title }}
                        </v-chip>
                    </v-col>
                    <v-col cols="12" v-if="selectedStudio.alwaysCheckin && selectedOpeningHours === 'Checkin'">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Checkin</th>
                                        <th class="text-left">Zeiten</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="width: 40%">MO - SO / FT</td>
                                        <td style="width: 60%">24H</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col
                        cols="12"
                        v-for="(openingHours, index) of selectedStudio.openingHours.filter(
                            (oh) => oh.title === selectedOpeningHours
                        )"
                        :key="'openingHours' + index"
                    >
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">{{ openingHours.title }}</th>
                                        <th class="text-left">Zeiten</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(hours, index) of openingHours.hours" :key="openingHours.title + index">
                                        <td style="width: 40%">{{ hours.text }}</td>
                                        <td style="width: 60%">
                                            <span>
                                                {{ hours.from + ' - ' + hours.to }}
                                            </span>
                                            <span v-if="hours.from2 && hours.to2">
                                                <br />
                                                {{ hours.from2 + ' - ' + hours.to2 }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <div height="300px" v-if="selected && !loading"></div>

                    <v-row v-if="loading">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import api from '../../api'
import userService from '../../services/userService'

import attendanceTrainerList from '@/views/dashboard/attendance/attendanceTrainerList.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'studioSelection',
    components: {
        attendanceTrainerList,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            userService,
            zoom: 8,
            error: false,
            selected: false,
            selectedOpeningHours: null,
            studios: [],
            studiosBackup: [],
            selectedCanton: null,
            show: false,
            center: {
                lat: 10.0,
                lng: 10.0,
            },
            markers: [],
        }
    },

    async created() {
        this.loading = true
        if (localStorage.getItem('token')) this.init()
    },
    computed: {
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
        studioCantons() {
            if (!this.studios.length > 0) return []
            return [...new Set(this.studios.filter((e) => e.canton).map((e) => e.canton))]
        },
        selectedStudioId() {
            if (!this.studios.length > 0) return null
            return this.studios.find((e) => e.active)._id || null
        },
        selectedStudio() {
            if (!this.studios.length > 0) return null
            return this.studios.find((e) => e.active) || null
        },
    },
    methods: {
        ...mapActions('studioStore', ['setStudios']),
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'toggleNavigationBarVisibility']),
        ...mapActions('deviceStore', ['resetDeviceList']),
        back() {
            if (this.selected) {
                this.toOverview()
            } else {
                this.$emit('studio-picked')
            }
        },
        toOverview() {
            console.log(
                this.studios.find((E) => E.active),
                this.studiosBackup.find((E) => E.active)
            )
            this.studios = JSON.parse(JSON.stringify(this.studiosBackup))
            console.log('TO OVERVIEW HERE')
            if (this.navigationVisible && this.visible) this.toggleNavigationBarVisibility()
            this.selected = false
            this.resetView()
        },
        reloadLocation() {
            location.reload()
        },
        selectCanton(canton) {
            if (this.selectedCanton === canton) {
                this.selectedCanton = null
            } else {
                this.selectedCanton = canton
            }
        },
        async init() {
            this.loading = true
            let studios = await api.fetchStudios().catch((e) => (this.error = true))
            let teams = await api.fetchTeamsForTitle()

            this.markers = studios.map((e) => {
                if (!e.location) return { position: { lat: 47.54789, lng: 8.89278 } }
                return { position: { lat: e.location.lat, lng: e.location.long } }
            })

            this.center = {
                lat: Math.min(...this.markers.map((e) => e.position.lat)) + 0.2,
                lng: Math.max(...this.markers.map((e) => e.position.lng)) - 0.3,
            }

            this.studios = studios
            this.studiosBackup = JSON.parse(JSON.stringify(studios))
            if (this.selectedStudio && this.visible) {
                this.selectedCanton = this.selectedStudio.canton
                this.toOverview()
                /*this.selected = true
                this.setNavigationBarText({ text: 'AUSWÄHLEN', state: false })
                if (!this.navigationVisible) this.toggleNavigationBarVisibility()*/
            } else {
                this.toOverview()
            }
            this.loading = false
        },
        changeStudioSelectionVisibility(visible) {
            if (!this.selected) {
                this.$emit('change-visibility', visible)
            } else {
                this.resetView()
            }
        },
        animateMapZoomTo(map, targetZoom) {
            var currentZoom = arguments[2] || map.getZoom()
            let ref = this
            if (currentZoom != targetZoom) {
                google.maps.event.addListenerOnce(map, 'zoom_changed', function (event) {
                    ref.animateMapZoomTo(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1))
                })
                setTimeout(function () {
                    map.setZoom(currentZoom)
                }, 100)
            }
        },

        changeZoom(val) {
            this.zoom = val
        },
        async resetView() {
            // this.init()
            this.markers = this.studiosBackup.map((e) => {
                if (!e.location) return { position: { lat: 47.54789, lng: 8.89278 } }
                return { position: { lat: e.location.lat, lng: e.location.long } }
            })

            this.center = {
                lat: Math.min(...this.markers.map((e) => e.position.lat)) + 0.2,
                lng: Math.max(...this.markers.map((e) => e.position.lng)) - 0.3,
            }
            if (this.navigationVisible && this.visible) this.toggleNavigationBarVisibility()
            this.selected = false

            let map = await this.$refs.map.$mapPromise
            this.animateMapZoomTo(map, 7)
        },
        studioInfos(key) {
            let selectedStudio = this.studios.find((e) => {
                return e.active
            })

            if (selectedStudio[key]) return selectedStudio[key]
            return ''
        },
        async selectStudio(studio) {
            this.selectedCanton = studio.canton
            if (!this.navigationVisible && this.visible) this.toggleNavigationBarVisibility()
            this.selected = true

            let map = await this.$refs.map.$mapPromise

            if (studio.location && studio.location.lat && studio.location.long) {
                this.center = { lat: studio.location.lat, lng: studio.location.long }
                this.animateMapZoomTo(map, 15)
            }
            this.selectedOpeningHours = studio.alwaysCheckin
                ? 'Checkin'
                : studio.openingHours && studio.openingHours.length
                ? studio.openingHours[0].title
                : null
            this.studios = this.studios.map((e) => {
                e.active = e._id === studio._id
                return e
            })

            this.setNavigationBarText({ text: 'AUSWÄHLEN', state: false })
        },
    },
    watch: {
        visible: function (newVal, oldVal) {
            // watch it
            if (newVal) this.init()
            if (!newVal) {
                if (!this.navigationVisible) this.toggleNavigationBarVisibility()
            }
        },
        async navigationBarTextState(value) {
            if (value && this.visible)
                for (let studio of this.studios) {
                    if (studio.active) {
                        let user = JSON.parse(localStorage.getItem('user'))

                        if (user && user.user) {
                            user.user.studio_id = studio.code

                            localStorage.setItem('user', JSON.stringify(user))
                        }
                        await api.pickStudio({ studio_code: studio.code })

                        this.resetDeviceList()
                        this.$emit('studio-picked')
                        this.resetView()

                        break
                    }
                }
        },
    },
}
</script>

<style lang="scss" scoped>
.studioSelection {
    max-width: 100vw;
    &__title {
        background-color: #313131;
        color: #fff;
        margin: -12px 0px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        text-align: center !important;
        display: flex;
        justify-content: center;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 55px;
        margin-bottom: 25px;
        max-width: 100vw;
    }
    &__list {
        justify-content: space-evenly;
        max-width: 500px;
        margin: 0px auto;
        &-studio {
            border-radius: 5px;
            border: 5px solid var(--v-primary);
            background-color: var(--v-primary);
            color: white;
            &.active:before {
                position: absolute;
                /* height: 10px; */
                width: 50px;
                font-family: 'Material Icons';
                content: 'check';
                -webkit-font-feature-settings: 'liga';
                top: -15px;
                background-color: #353535;
                text-align: center;
                border-radius: 4px;

                font-size: 15px;
                left: calc(50% - 25px);
            }

            &-adresse {
                font-size: 13px;
            }
        }
    }
}
.back-btn {
    position: fixed;
    top: 20px;
    left: 11%;
    z-index: 398383;
}
</style>
