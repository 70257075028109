<template>
    <div class="d-flex flex-column ma-auto" style="max-width: 450px">
        <div class="pb-2 habit-home__container">
            <v-card-title
                style="font-size: 18px"
                class="pb-3 font-weight-bold justify-content-center d-flex justify-space-around"
            >
                <v-btn icon small><v-icon color="primary">mdi-chevron-left</v-icon></v-btn>
                <span>{{ selectedDate.format('MMMM YYYY') }}</span>
                <v-btn icon small><v-icon color="primary">mdi-chevron-right</v-icon></v-btn>
            </v-card-title>
            <v-row
                v-dragscroll="{ active: !$vuetify.breakpoint.mobile }"
                data-v-step="4"
                class="habit__calendar flex-nowrap px-0 mb-3 pr-4"
            >
                <v-col v-for="(date, y) in calendar" :key="y">
                    <v-skeleton-loader
                        v-if="loadingCalendar"
                        class="mx-auto mt-8"
                        max-width="30"
                        max-height="30"
                        type="card"
                    ></v-skeleton-loader>
                    <div v-else>
                        <v-row :class="{ 'habit__calendar-date': 1, 'justify-center': 1 }">
                            <v-btn
                                :id="date.date"
                                icon
                                :loading="date.active && loading"
                                @click="selectDate(y)"
                                :class="{
                                    'elevation-7': date.active,
                                    'habit__calendar-date-btn': 1,
                                    active: date.active,
                                }"
                            >
                                <span>{{ date.date }}</span>
                            </v-btn>
                        </v-row>
                        <v-row class="justify-center habit__calendar-short__wrapper">
                            <span class="habit__calendar-short mt-1">{{ date.short }}</span>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <habit-home-star @changeChip="setActiveChip"></habit-home-star>
        <habit-home-list
            @finishedLoading="loading = false"
            class="pt-3"
            :active-chip="activeChip"
            :date="selectedDate"
        ></habit-home-list>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import habitHomeList from './habitHomeList.vue'
import habitHomeStar from './habitHomeStar.vue'
import { dragscroll } from 'vue-dragscroll'

export default {
    name: 'habitHome',
    components: { habitHomeList, habitHomeStar },
    directives: {
        dragscroll,
    },
    data() {
        return {
            activeChip: 5,
            calendar: [],
            loading: false,
            loadingCalendar: true,
            starCount: 0,
            selectedDate: this.$moment(),
            canvas: null,
            ctx: null,
        }
    },
    async created() {
        console.log('oi papi')
        this.setNavigationbarVisibility(false)

        for (let i = 0; i < 30; i++) {
            let date = this.$moment().subtract(i, 'days')
            let ts = this.$moment()
                .subtract(i, 'days')
                .set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    miliseconds: 0,
                })
                .unix()
            this.calendar.push({
                date: date.date(),
                ts,
                short: date.format('dd'),
                active: i === 0,
            })
        }
        console.log(this.calendar, 'oiiii')
        this.loadingCalendar = false
        this.selectedDate = this.$moment(this.calendar[index].ts * 1000)
    },

    beforeDestroy() {
        this.setNavigationbarVisibility(true)
    },

    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility', 'toggleNavigationBarVisibility']),
        setActiveChip(chip) {
            this.activeChip = chip
        },
        selectDate(index) {
            this.loading = true
            this.calendar.forEach((date, i) => {
                date.active = i === index
            })
            this.selectedDate = this.$moment(this.calendar[index].ts * 1000)
            document.getElementById(this.calendar[index].date).scrollIntoView({ behavior: 'smooth', inline: 'center' })
        },
    },
}
</script>

<style lang="scss" scoped>
.habit {
    &-home__container {
        background-color: #f4fdf2;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
    }
    &__calendar {
        flex-direction: row-reverse;
        max-width: 700px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &-date {
            min-width: 60px;
            &-btn {
                font-size: 18px;
                font-weight: bold;
                color: black;
                background: white;
                height: 45px;
                width: 45px;
                &.active {
                    background: #488130;
                    //background: var(--v-primary);
                    color: white;
                }
            }
        }
        &-short {
            text-align: center;
            color: rgba(0, 0, 0, 0.8);
        }
    }
}
</style>
