<template xmlns="">
	<div v-if="loading" class="fill-height"></div>
	<v-card class="christmas__card d-flex flex-column" :class="{ 'rounded-xl mt-5 mb-3 desktop-sizes': !$vuetify.breakpoint.mobile }" v-else>
		<img class="christmas__card-image" :src="headerImage" />
		<v-card-title @click="shareViaWebShare" class="h5 justify-content-center mt-5 mb-3">
			<v-row class="justify-center">
				<v-col class="justify-end d-flex" cols="4">
					<lottie-player
						mode="normal"
						style="width:100px;height:40px;"
						class="event__lottie mr-3 speech-bubble"
						name="placeholderImage"
						loop
						autoplay
						src="/img/lottiefiles/avatarPlusWhite.json"
					></lottie-player>
				</v-col>
				<v-col class="justify-start d-flex pt-5" cols="6">
					<span class="christmas__card-friend">Bring einen Freund mit</span>
				</v-col>
			</v-row>
		</v-card-title>
		<v-row class="justify-center pt-5">
			<v-col cols="10">
				<v-row class="d-flex flex-column">
					<v-card-subtitle block color="#414247" class="text-left pb-0"> Von Experten entwickelt </v-card-subtitle>
					<v-card-title color="#414247" class="text-left font-weight-bold h3 pb-1 pt-0" style="word-break:break-word !important;">
						Advents-Power-Aktion
					</v-card-title>
					<v-card-text class="text-left body-1 pb-1" style="word-break:break-word !important;">
						Hol Dir Dein 10-Tages Programm, mit dem auch unsere Trainer fit und schlank bleiben.
					</v-card-text>
				</v-row>
			</v-col>

			<v-col @click="dialog = true" cols="10" class="justify-center mt-1 mb-1">
				<lottie-player
					mode="normal"
					style="width:120px;height:120px;"
					class="event__lottie m-auto"
					name="placeholderImage"
					loop
					autoplay
					src="/img/lottiefiles/christmasGift.json"
				></lottie-player>
			</v-col>
		</v-row>

		<template>
			<v-row justify="center" class="mt-0">
				<v-dialog v-model="dialog" persistent max-width="600px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							elevation="3"
							color="#DC2513"
							class="font-weight-bold pt-1 pb-0 mb-0 mt-0"
							min-width="300px"
							min-height="50px"
							dark
							v-bind="attrs"
							v-on="on"
						>
							STARTE MIT DEINEN <br />10-TAGES PROGRAMM
						</v-btn>
						<v-card-text class="text-center mt-0 pt-0">
							{{ countdown.minutes }} Minuten {{ countdown.seconds }} Sekunden verbleibend</v-card-text
						>
					</template>
					<v-card>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-select
											:items="gymList"
											:error-messages="studioError"
											v-model="formData.studio_id"
											item-text="name"
											item-value="_id"
											label="Wähle Dein Standort aus*"
											required
										></v-select>
									</v-col>
									<v-form style="width: 100%;" v-show="formData.studio_id !== null" ref="formValidation" lazy-validation>
										<v-col cols="12" sm="6">
											<v-text-field v-model="formData.vorname" label="Vorname*" :rules="requiredField" required></v-text-field>
										</v-col>

										<v-col cols="12" sm="6">
											<v-text-field
												v-model="formData.nachname"
												label="Nachname*"
												:rules="requiredField"
												persistent-hint
												required
											></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-text-field :error-messages="emailTelError" v-model="formData.email" label="Email"></v-text-field>
										</v-col>

										<v-col class="text-center" cols="12">
											<span>ODER</span>
										</v-col>
										<v-col cols="12">
											<v-text-field :error-messages="emailTelError" v-model="formData.telefon" label="Telefon"></v-text-field>
										</v-col>
									</v-form>
								</v-row>
							</v-container>
							<v-col v-show="formData.studio_id !== null" cols="12">
								<span>Falls Du lieber uns kontaktieren möchtest:</span>
							</v-col>
							<v-col v-show="formData.studio_id !== null" class="text-center" cols="12">
								<v-btn :href="callMeLink" class="align-center" color="primary">
									Ruf' uns an
								</v-btn>
							</v-col>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="grey" text @click="dialog = false">
								Abbrechen
							</v-btn>
							<v-btn :disabled="formData.studio_id === null" color="primary" text @click="submitForm()">
								Absenden
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</template>
		<v-snackbar :color="snackBar.color" v-model="snackBar.status">
			{{ snackBar.message }}
		</v-snackbar>
	</v-card>
</template>

<script>
import api from '../../api'
import timeService from '../../services/timeService'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	name: 'ChristmasHome',
	components: { Swiper, SwiperSlide },

	directives: {
		swiper: directive,
	},
	data() {
		return {
			countdown: {
				minutes: null,
				seconds: null,
			},
			startTime: null,
			isEnded: false,
			emailTelError: null,
			timer: null,
			studioError: null,
			loading: false,
			dialog: false,
			share: {
				title: 'Advents-Power-Aktion',
				text:
					'Zu Zweit macht es einfach mehr Spass! Hättest Du Lust mit mir ins well come FIT zu gehen und Dir mal anzuhören, was die für unsere Figur auf Lager haben?',
			},

			formData: {
				studio_id: null,
				vorname: null,
				nachname: null,
				email: null,
				telefon: null,
			},
			gymList: [],
			requiredField: [(v) => !!v || 'Dies ist ein Pflichtfeld'],
			snackBar: {
				color: 'primary',
				status: false,
				message: '',
			},
		}
	},
	created() {
		this.loading = true
		api.fetchPublicStudios().then((studios) => {
			this.gymList = this.gymList.concat(studios)
			this.startTime = this.$moment().unix() + 419
			this.tick()
			this.timer = setInterval(this.tick.bind(this), 1000)
			this.loading = false
		})
	},
	methods: {
		updateRemaining(distance) {
			distance = distance * 1000
			this.countdown.minutes = Math.floor(distance / 60000)
			let seconds = ((distance % 60000) / 1000).toFixed(0)

			if (seconds < 10) {
				this.countdown.seconds = '0' + seconds
			} else {
				this.countdown.seconds = seconds
			}
		},
		tick() {
			let currentTime = this.$moment().unix()

			let distance = Math.max(this.startTime - currentTime, 0)

			this.updateRemaining(distance)

			if (distance === 0) {
				clearInterval(this.timer)
				this.isEnded = true
			}
		},
		createSnackBar(message, color) {
			this.snackBar = {
				color,
				message,
				status: true,
			}
		},
		submitForm() {
			let checkMailTel =
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.formData.email) ||
				/^\+(?:[0-9]⋅?){6,14}[0-9]$/.test(this.formData.telefon) ||
				/^(?:[0-9]⋅?){6,14}[0-9]$/.test(this.formData.telefon)
			if (this.$refs.formValidation.validate() && checkMailTel && this.formData.studio_id !== null) {
				let lead = {
					content: this.formData,
					category: 'christmasLead',
				}
				api.createLeadEntry(lead)
					.then((data) => {
						this.dialog = false
						this.createSnackBar('Eintrag erfolgreich gespeichert', 'success')
					})
					.catch((e) => {
						this.createSnackBar('Es gab einen Fehler. Bitte versuche es später erneut oder kontaktiere uns direkt.', 'error')
					})
			} else {
				if (!checkMailTel) {
					this.emailTelError = 'Bitte gültige E-Mail oder Telefon angeben'
				}
				if (this.formData.studio_id == null) {
					this.studioError = 'Bitte wähle ein Standort aus'
				}
			}
		},
		getCourseImage(image) {
			return 'https://app.wellcomefit.ch/img/kurse/' + image
		},
		shareViaWebShare() {
			navigator.share({
				title: this.share.title,
				text: this.share.text,
				url: location.href,
			})
		},
	},
	watch: {
		'formData.email': function() {
			this.emailTelError = null
		},
		'formData.telefon': function() {
			this.emailTelError = null
		},
		'formData.studio_id': function() {
			this.studioError = null
		},
	},

	computed: {
		callMeLink() {
			if (this.formData.studio_id !== null) {
				let gym = this.gymList.filter((e) => e._id === this.formData.studio_id)[0]
				return 'tel:' + gym.telefon.split(' ').join('')
			} else {
				return '#'
			}
		},
		webShareApiSupported() {
			return navigator.share
		},
		swiperOption() {
			let slidesPerView = 3
			if (this.$vuetify.breakpoint.mdAndDown) {
				slidesPerView = 2
			}

			return {
				slidesPerView: slidesPerView,
				spaceBetween: 30,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			}
		},
		headerImage() {
			return '/img/einmalig/christmas/christmas.jpg'
		},
	},
}
</script>
<style lang="scss" scoped>
.speech-bubble {
	position: relative;
	background-color: #f10909 !important;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 17px solid #0000;
	border-top-color: #f10909;
	border-bottom: 0;
	border-right: 0;
	margin-left: 4.5px;
	margin-bottom: -16px;
}
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.christmas__lottie {
	display: block;
}

.desktop-sizes {
	max-width: 70vh;
	min-width: 30vh;
	max-height: 85vh;
	min-height: 60vh;
}

.v-card {
	transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
	bottom: 0;
}

.christmas {
	&__card {
		margin: auto;
		width: 100%;
		height: 91vh;
		min-height: 850px;

		&-friend {
			max-width: 151px;
			word-break: break-word;
			text-align: center;
			font-size: 18px;
		}

		.h3,
		.h4 {
			color: #414247 !important;
		}

		.body-1,
		.body-2 {
			color: #999 !important;
		}
		&-image {
			max-height: 400px;
			@media only screen and (max-width: 375px) {
				max-height: 240px !important;
			}
			object-fit: contain;
		}

		&-course {
			&-title {
				font-size: 18px;
				font-weight: bold;

				&-mobile {
					font-size: 2.5vw;
				}
			}
			&-content-mobile {
				font-size: 2vw;
			}
			&-button {
				width: 100%;
				border-bottom-left-radius: 24px;
				border-bottom-right-radius: 24px;
			}
		}
	}
}
</style>
