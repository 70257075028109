<template>
	<div class="impressum">
		<div class="back-navigation">
			<a href="/">
				<span class="material-icons">navigate_before</span>
			</a>
			<span>Impressum</span>
		</div>
		<br />
		<div class="text">
			<div class="et_pb_text_inner">
				<p>
					<strong>Inhalt und Verantwortung</strong>
					<span v-if="wellcomefit == 1"> <br />well come Fit AG<br />Walzmühlestrasse 50<br />8500 Frauenfeld </span>
					<span v-if="wellcomefit == 2"> <br />winti FIT AG<br />Im Hölderli 10 50<br />8405 Winterthur </span>
					<span v-if="wellcomefit == 3"> <br />Fitness Zentrum Wetzikon<br />Breitistrasse 21 <br />8623 Wetzikon </span>
				</p>
				<p v-if="wellcomefit == 1"><strong>Kontakt</strong><br />Telefon: +41 52 721 36 96<br />Email: info@wellcomefit.ch</p>
				<p v-if="wellcomefit == 2"><strong>Kontakt</strong><br />Telefon: +41 52 233 14 00<br />Email: info@wintifit.ch</p>
				<p v-if="wellcomefit == 3"><strong>Kontakt</strong><br />Telefon: +44 930 22 44<br />Email: box@fzw.ch</p>
			</div>
			<div class="et_pb_text_inner">
				<p>
					<strong>Haftung</strong>
				</p>
				<p>
					Wir übernehmen keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
					Vollständigkeit der Informationen. Haftungsansprüche gegen uns wegen Schäden materieller oder immaterieller Art, welche aus dem
					Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch
					technische Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind unverbindlich. Wir behalten uns ausdrücklich vor,
					Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
					Veröffentlichung zeitweise oder endgültig einzustellen.
				</p>
				<p><strong>Verweise und Links</strong></p>
				<p>
					Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Der Zugriff und die Nutzung solcher
					Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin. Wir erklären ausdrücklich, dass wir keinerlei Einfluss auf die
					Gestaltung, den Inhalt und die Angebote der verknüpften Seiten haben. Informationen und Dienstleistungen von verknüpften Webseiten
					liegen vollumfänglich in der Verantwortung des jeweiligen Dritten. Es wird jegliche Verantwortung für solche Webseiten abgelehnt.
				</p>
				<p><strong>Copyright</strong></p>
				<p>
					Die dieser Website enthaltenen Informationen werden der Öffentlichkeit zugänglich gemacht. Durch das Herunterladen oder Kopieren
					von Inhalten, Bildern, Fotos oder anderen Dateien werden keinerlei Rechte bezüglich der Inhalte übertragen.<br />
					Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website gehören ausschliesslich
					uns oder explizit genannten Rechtsinhabern. Sämtliche Bilder und Fotos stammen aus eigenem Archiv oder aus öffentlichen
					Bildarchiven, mit welchen keine Rechte dritter verletzt werden. Für die Reproduktion jeglicher Elemente ist die schriftliche
					Zustimmung der Urheberrechtsträger im Voraus einzuholen.
				</p>
				<p v-if="wellcomefit == 1">Frauenfeld, 17.&nbsp;Januar 2021</p>
				<p v-if="wellcomefit == 2">Winterthur, 17.&nbsp;Januar 2021</p>
				<p v-if="wellcomefit == 3">Wetzikon, 17.&nbsp;Januar 2021</p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			wellcomefit: true,
		}
	},
	methods: {},
	created() {
		if (location.host.includes('wellcomefit')) {
			this.wellcomefit = 1
		} else if (location.host.includes('wintifit')) {
			this.wellcomefit = 2
		} else if (location.host.includes('fzw')) {
			this.wellcomefit = 3
		}
	},
}
</script>

<style lang="scss" scoped>
.text {
	padding: 0px 10px;
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
.infox {
	margin-bottom: 20px;
}
.login-now {
	a {
		color: black;
		display: flex;
		margin-top: 20px;
	}
}
.login {
	color: #4a4a4a;

	.title-well {
		padding: 5px 10px;
		color: white;
		background-color: var(--v-primary);
		width: 100%;
		margin-bottom: 20px;
	}

	.btn-login {
		width: 100%;
	}

	h3 {
		margin-bottom: 30px;
	}

	.register-now a {
		color: #4a4a4a;
	}
}
</style>
