<template xmlns="">
    <div class="device-exercise-form">
        <image-overlapping-card :return-callback="true" v-on:return-clicked="returnToDeviceForm" return-route="-1">
            <template v-slot:image>
                <slim-cropper v-if="loaded" style="height: 35vh" :options="slimOptionsExercise">
                    <img v-if="form.picture" :src="'/img/uebungen/' + form.picture" alt="" />
                    <input type="file" accept="image/*" />
                </slim-cropper>
            </template>
            <template v-slot:content>
                <v-form ref="form" lazy-validation @submit.prevent="preventReload">
                    <v-row class="ma-0 formCard elevation-7">
                        <v-col class="pa-0" cols="12">
                            <v-row class="formTitle formCard-title d-flex flex-row py-3 mx-0 mb-0">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="settings__item-lottie ml-5 mr-3"
                                    loop
                                    autoplay
                                    src="/img/lottiefiles/gf/deadlift.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h5 px-0 py-3 my-0 font-weight-bold text-uppercase"
                                >
                                    Übung
                                </v-card-title>
                            </v-row>
                        </v-col>
                        <v-row class="ma-0 px-5">
                            <v-col cols="12">
                                <v-text-field
                                    class="h5 pb-0"
                                    placeholder="Titel"
                                    v-model="form.title"
                                    :rules="rules.title"
                                    counter="30"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 px-5">
                            <v-col class="pt-0" cols="12">
                                <v-select
                                    v-if="availableTags"
                                    class="pt-0 device-exercise-form__tags"
                                    v-model="form.tags"
                                    :items="availableTags"
                                    deletable-chips
                                    return-object
                                    item-value="_id"
                                    item-text="name"
                                    multiple
                                    chips
                                    label="Tags"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-row>

                    <v-row dense class="ma-0 formCard pb-5 elevation-7 my-10">
                        <v-col class="pa-0" cols="12">
                            <v-row class="formTitle formCard-title d-flex flex-row py-3 mx-0 mb-5">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="settings__item-lottie ml-5 mr-3"
                                    loop
                                    autoplay
                                    src="/img/lottiefiles/gf/todo.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h5 px-0 py-3 my-0 font-weight-bold text-uppercase"
                                >
                                    Anleitung
                                </v-card-title>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="form.manual && !form.manual.length > 0" class="mb-5 px-5">
                            Es wurde noch keine Übungsanleitung erstellt. Mit der Schaltfläche unten kannst Du neue
                            Übungsanleitungsschritte erfassen.
                        </v-col>
                        <v-row class="px-5">
                            <v-col cols="4" v-for="(manual, index) in form.manual" :key="index + 'manual'">
                                <v-card
                                    class="d-flex align-center justify-center device-exercise-form__manual-step"
                                    height="80px"
                                    width="80px"
                                    :img="
                                        manual.picture
                                            ? '/img/uebungen/' + manual.picture
                                            : '/img/einmalig/imageUploadPlaceholder.jpg'
                                    "
                                    @click="editManual(index)"
                                >
                                    <span class="h3">{{ index + 1 }}</span>
                                </v-card>
                            </v-col>
                            <v-col :class="{ 'mx-auto': form.manual && !form.manual.length > 0 }" cols="auto">
                                <v-dialog v-model="manualDialog" persistent max-width="500px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            height="80px"
                                            width="80px"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="form.manual && form.manual.length > 0"
                                        >
                                            <span class="h1">+</span>
                                        </v-btn>
                                        <v-btn
                                            v-else
                                            style="height: 70px"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            large
                                        >
                                            <strong>
                                                Neuen Anleitungsschritt
                                                <br />
                                                hinzufügen
                                            </strong>
                                        </v-btn>
                                    </template>
                                    <v-card class="d-flex flex-column">
                                        <v-form ref="manualForm" @submit.prevent="preventReload">
                                            <v-card-title>{{ getManualFormTitle }}</v-card-title>
                                            <v-card-text>
                                                <slim-cropper
                                                    :key="manualForm.picture"
                                                    v-if="loaded"
                                                    style="height: 35vh"
                                                    :options="slimOptionsManual"
                                                >
                                                    <img
                                                        v-if="manualForm.picture"
                                                        :src="'/img/uebungen/' + manualForm.picture"
                                                        alt=""
                                                    />
                                                    <input type="file" accept="image/*" />
                                                </slim-cropper>
                                                <v-text-field
                                                    v-model="manualForm.description"
                                                    label="Beschreibung"
                                                    required
                                                    @keydown.enter.prevent="test"
                                                    :rules="rules.required"
                                                ></v-text-field>
                                            </v-card-text>
                                        </v-form>
                                        <v-card-actions class="d-flex justify-space-between">
                                            <v-btn color="grey" outlined @click="abortManual">Abbrechen</v-btn>
                                            <v-btn
                                                v-if="manualForm.index"
                                                color="red"
                                                class="white--text"
                                                @click="deleteManual"
                                            >
                                                Löschen
                                            </v-btn>

                                            <v-btn color="primary" @click="saveManual">
                                                {{ manualForm.index >= 0 ? 'Bearbeiten' : 'Hinzufügen' }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row dense class="my-10 mx-0 formCard pb-5 elevation-7">
                        <v-col class="pa-0" cols="12">
                            <v-row class="formTitle formCard-title d-flex flex-row py-3 mx-0 mb-5">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="settings__item-lottie ml-5 mr-3"
                                    loop
                                    autoplay
                                    src="/img/lottiefiles/settings/deviceManagement.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h5 px-0 py-3 my-0 font-weight-bold text-uppercase"
                                >
                                    Geräteeinstellungen
                                </v-card-title>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="form.general_exercise_settings && !form.general_exercise_settings.length > 0"
                            class="mb-5 px-5"
                        >
                            Es wurden noch keine Geräteeinstellungen erstellt. Mit der Schaltfläche unten kannst Du eine
                            neue Einstellung für das Gerät hinzufügen.
                        </v-col>

                        <v-row class="px-5">
                            <v-col
                                cols="auto"
                                :key="index + 'setting'"
                                v-for="(exerciseSetting, index) in form.general_exercise_settings"
                            >
                                <v-card
                                    @click="deleteExerciseSetting(exerciseSetting, 0, 'Device')"
                                    color="listitemcolor"
                                    class="d-flex flex-column justify-space-between text-center"
                                >
                                    <v-card-title class="py-1 justify-center h6 font-weight-bold">
                                        {{ shorten(exerciseSetting.text, 10) }}
                                    </v-card-title>
                                    <v-card-subtitle class="py-1">{{ exerciseSetting.input }}</v-card-subtitle>
                                    <v-chip-group
                                        v-if="exerciseSetting.input === 'selection'"
                                        active-class="primary--text"
                                        column
                                    >
                                        <v-chip
                                            x-small
                                            v-for="selection in exerciseSetting.selections"
                                            :key="selection + ''"
                                        >
                                            <span>{{ selection }}</span>
                                        </v-chip>
                                    </v-chip-group>
                                </v-card>
                            </v-col>
                            <v-col
                                :class="{
                                    'mx-auto':
                                        form.general_exercise_settings && !form.general_exercise_settings.length > 0,
                                }"
                                cols="auto"
                            >
                                <v-dialog v-model="generalExerciseSettingsDialog" persistent max-width="500px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            min-width="100px"
                                            min-height="50px"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="primary"
                                            v-if="
                                                form.general_exercise_settings &&
                                                form.general_exercise_settings.length > 0
                                            "
                                        >
                                            <span class="h1 white--text mb-0">+</span>
                                        </v-btn>
                                        <v-btn
                                            large
                                            style="height: 70px"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-else
                                        >
                                            <strong>
                                                Neue Geräteeinstellung
                                                <br />
                                                hinzufügen
                                            </strong>
                                        </v-btn>
                                    </template>
                                    <v-card class="d-flex flex-column">
                                        <v-card-title class="h5">Geräteeinstellung hinzufügen</v-card-title>
                                        <v-form style="width: 90%; margin: auto" @submit.prevent="preventReload">
                                            <v-combobox
                                                v-model="exerciseSetting"
                                                :search-input.sync="exerciseSettingSearch"
                                                item-text="text"
                                                return-object
                                                item-value="text"
                                                label="Einstellungsmöglichkeit"
                                                :items="availableDeviceExerciseSettingsFiltered"
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="exerciseSettingSearch">
                                                                "
                                                                <strong>{{ exerciseSettingSearch }}</strong>
                                                                " Hinzufügen
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                            <v-select
                                                v-if="exerciseSetting.text"
                                                v-model="exerciseSetting.input"
                                                label="Eingabe"
                                                item-text="text"
                                                item-value="input"
                                                :items="exerciseSettingInputPossibilities"
                                            ></v-select>
                                            <v-combobox
                                                v-model="exerciseSetting.selections"
                                                v-if="exerciseSetting.input && exerciseSetting.input === 'selection'"
                                                :search-input.sync="selectionPossibilitiesSearch"
                                                label="Auswahlmöglichkeiten"
                                                multiple
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="selectionPossibilitiesSearch">
                                                                "
                                                                <strong>{{ selectionPossibilitiesSearch }}</strong>
                                                                " mit Enter hinzufügen
                                                            </v-list-item-title>
                                                            <v-list-item-title v-else>
                                                                Auswahlmöglichkeit eingeben
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </v-form>
                                        <v-card-actions class="d-flex justify-space-between">
                                            <v-btn color="grey" outlined @click="abortSettingForm">Abbrechen</v-btn>
                                            <v-btn color="primary" @click="saveGeneralSetting">Hinzufügen</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-row dense class="mx-0 my-10 formCard elevation-7 pb-5">
                        <v-col class="pa-0" cols="12">
                            <v-row class="formTitle formCard-title d-flex flex-row py-3 mx-0 mb-5">
                                <lottie-player
                                    mode="normal"
                                    style="width: 45px; height: 45px"
                                    class="settings__item-lottie ml-5 mr-3"
                                    loop
                                    autoplay
                                    src="/img/lottiefiles/settings/deviceManagement.json"
                                ></lottie-player>
                                <v-card-title
                                    style="letter-spacing: 1px"
                                    class="h5 px-0 py-3 my-0 font-weight-bold text-uppercase"
                                >
                                    Satzeinstellungen
                                </v-card-title>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="form.set_exercise_settings && !form.set_exercise_settings.length > 0"
                            class="mb-5 px-5"
                        >
                            Es wurde noch keine Satzeinstellungen erstellt. Mit der Schaltfläche unten kannst Du eine
                            neue Einstellung für die Sätze der Übung erfassen.
                        </v-col>

                        <v-row class="px-5">
                            <v-col
                                cols="auto"
                                :key="index + 'setting'"
                                v-for="(exerciseSetting, index) in form.set_exercise_settings"
                            >
                                <v-card
                                    @click="deleteExerciseSetting(exerciseSetting, 0, 'set')"
                                    color="listitemcolor"
                                    class="d-flex flex-column justify-space-between text-center"
                                >
                                    <v-card-title class="py-1 justify-center h6 font-weight-bold">
                                        {{ shorten(exerciseSetting.text, 10) }}
                                    </v-card-title>
                                    <v-card-subtitle class="py-1">{{ exerciseSetting.input }}</v-card-subtitle>
                                    <v-chip-group
                                        v-if="exerciseSetting.input === 'selection'"
                                        active-class="primary--text"
                                        column
                                    >
                                        <v-chip
                                            x-small
                                            v-for="selection in exerciseSetting.selections"
                                            :key="selection + ''"
                                        >
                                            <span>{{ selection }}</span>
                                        </v-chip>
                                    </v-chip-group>
                                </v-card>
                            </v-col>
                            <v-col
                                :class="{
                                    'mx-auto': form.set_exercise_settings && !form.set_exercise_settings.length > 0,
                                }"
                                cols="auto"
                            >
                                <v-dialog v-model="setExerciseSettingsDialog" persistent max-width="500px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            min-width="100px"
                                            min-height="50px"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="primary"
                                            v-if="form.set_exercise_settings && form.set_exercise_settings.length > 0"
                                        >
                                            <span class="h1 white--text mb-0">+</span>
                                        </v-btn>
                                        <v-btn
                                            large
                                            style="height: 70px"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-else
                                        >
                                            <strong>
                                                Neue Satzeinstellung
                                                <br />
                                                hinzufügen
                                            </strong>
                                        </v-btn>
                                    </template>
                                    <v-card class="d-flex flex-column">
                                        <v-card-title class="h5">Satzeinstellung hinzufügen</v-card-title>
                                        <v-form @submit.prevent="preventReload" style="width: 90%; margin: auto">
                                            <v-combobox
                                                v-model="exerciseSetting"
                                                :search-input.sync="exerciseSettingSearch"
                                                item-text="text"
                                                return-object
                                                item-value="text"
                                                label="Einstellungsmöglichkeit"
                                                :items="availableSetExerciseSettingsFiltered"
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="exerciseSettingSearch">
                                                                "
                                                                <strong>{{ exerciseSettingSearch }}</strong>
                                                                " Hinzufügen
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                            <v-select
                                                v-if="exerciseSetting.text"
                                                v-model="exerciseSetting.input"
                                                label="Eingabe"
                                                item-text="text"
                                                item-value="input"
                                                :items="exerciseSettingInputPossibilities"
                                            ></v-select>
                                            <v-combobox
                                                v-model="exerciseSetting.selections"
                                                v-if="exerciseSetting.input && exerciseSetting.input === 'selection'"
                                                :search-input.sync="selectionPossibilitiesSearch"
                                                label="Auswahlmöglichkeiten"
                                                multiple
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-if="selectionPossibilitiesSearch">
                                                                "
                                                                <strong>{{ selectionPossibilitiesSearch }}</strong>
                                                                " mit Enter hinzufügen
                                                            </v-list-item-title>
                                                            <v-list-item-title v-else>
                                                                Auswahlmöglichkeit eingeben
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </v-form>
                                        <v-card-actions class="d-flex justify-space-between">
                                            <v-btn color="grey" outlined @click="abortSettingForm">Abbrechen</v-btn>
                                            <v-btn color="primary" @click="saveSetSetting">Hinzufügen</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-form>
            </template>
        </image-overlapping-card>
        <router-view></router-view>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-subtitle class="pb-0 pt-5">{{ deletingExerciseSetting.input }}</v-card-subtitle>
                <v-card-title class="text-h5 pt-0">
                    {{ deletingExerciseSetting.text }}
                </v-card-title>

                <v-card-text>
                    Möchtest Du die Einstellung
                    <strong>{{ deletingExerciseSetting.text }}</strong>
                    wirklich endgültig löschen?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="red darken-1" text @click="deleteExerciseSetting(deletingExerciseSetting, 1)">
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'
import imageOverlappingCard from '../../../components/imageOverlappingCard.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import Slim from '../../../plugins/slimImage/slim/slim.vue'

export default {
    name: 'deviceExerciseForm',
    components: {
        imageOverlappingCard,
        'slim-cropper': Slim,
    },
    data() {
        return {
            slimOptionsExercise: {
                ratio: '4:3',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                dataJpegCompression: 30,
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            slimOptionsManual: {
                ratio: '4:3',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                dataJpegCompression: 30,
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadManualImage,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            navigationBarInitiated: false,
            isEditing: true,
            form: {
                _id: null,
                title: '',
                tags: [],
                picture: '',
                general_exercise_settings: [],
                set_exercise_settings: [],
                manual: [],
            },
            exerciseSetting: {
                text: null,
                input: null,
                selections: [],
            },
            deleteDialog: false,
            deletingExerciseSetting: {
                text: '',
                input: '',
            },
            selectionPossibilitiesSearch: '',
            exerciseSettingSearch: '',
            exerciseSettingInputPossibilities: [
                { text: 'Text', input: 'text' },
                { text: 'Zahl', input: 'number' },
                { text: 'Auswahl', input: 'selection' },
            ],
            availableExerciseSettings: [
                { text: 'Programm', input: 'text' },
                { text: 'Thema', input: 'text' },
                { text: 'Level', input: 'text' },
                { text: 'Dehnmethode', input: 'text' },
                { text: 'Herzfrequenz', input: 'text' },
                { text: 'Ausdauermethode', input: 'text' },
                { text: 'Watt', input: 'number' },
                { text: 'Geschwindigkeit', input: 'number' },
                { text: 'Umdrehungen pro Minute', input: 'number' },
                { text: 'Kalorien', input: 'number' },
                { text: 'Pause', input: 'text' },
                { text: 'Dauer', input: 'number' },
                { text: 'Notiz', input: 'text' },
                { text: 'Gewicht', input: 'number' },
                { text: 'Max Wiederholungen', input: 'number' },
                { text: 'Min Wiederholungen', input: 'number' },
                { text: 'Sätze', input: 'number' },
                { text: 'Lehne', input: 'number' },
                { text: 'Sitz', input: 'text' },
                { text: 'Winkel', input: 'text' },
                { text: 'Rolle', input: 'text' },
                { text: 'Polster', input: 'text' },
                { text: 'Position', input: 'text' },
                { text: 'Platte', input: 'text' },
                { text: 'Wiederstand', input: 'text' },
                { text: 'Band', input: 'text' },
                { text: 'Höhe Box', input: 'text' },
                { text: 'Griffe', input: 'text' },
                { text: 'Höhe', input: 'number' },
                { text: 'Ball', input: 'text' },
                { text: 'Variante', input: 'text' },
                { text: 'Bandstärke', input: 'text' },
                { text: 'Pad', input: 'text' },
            ],
            generalExerciseSettingsDialog: false,
            setExerciseSettingsDialog: false,
            manualDialog: false,
            deleteType: '',
            loaded: false,
            manualForm: {
                picture: '',
                description: '',
            },
            availableTags: null,
            rules: {
                title: [(v) => !!v || 'Dieses Feld wird benötigt', (v) => v.length <= 30 || 'Der Titel ist zu lang'],
                required: [(v) => !!v || 'Dieses Feld wird benötigt'],
            },
        }
    },
    beforeDestroy() {
        if (!location.host.includes('localhost')) window.removeEventListener('beforeunload', this.preventReload)
    },
    beforeMount() {
        if (!location.host.includes('localhost')) window.addEventListener('beforeunload', this.preventReload)
    },
    async created() {
        this.availableTags = await api.fetchExerciseTags()
        if (this.exerciseEdit) {
            this.form = this.exerciseEdit
            this.setNavigationBarText({ text: 'Weiter', state: false, endPoint: this.$route.name })
            this.loaded = true
        } else {
            this.loaded = true
            this.setNavigationBarText({ text: 'Übung hinzufügen', state: false, endPoint: this.$route.name })
        }
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
        ...mapActions('deviceStore', ['updateExercise', 'createExercise']),

        returnToDeviceForm() {
            this.$emit('returnToDeviceForm')
        },
        async deleteExerciseSetting(es, status, type) {
            if (status === 0) {
                this.deleteType = type
                this.deletingExerciseSetting = es
                this.deleteDialog = true
            } else {
                if (this.deleteType === 'Device') {
                    this.form.general_exercise_settings = this.form.general_exercise_settings.filter(
                        (setting) => setting.text !== es.text
                    )
                } else {
                    this.form.set_exercise_settings = this.form.set_exercise_settings.filter(
                        (setting) => setting.text !== es.text
                    )
                }
                this.deleteDialog = false
            }
        },
        test() {
            console.log('prevent reload')
        },
        preventReload(event) {
            console.log(event)
            if (!this.isEditing) return
            event.preventDefault()
            // Chrome requires returnValue to be set.
            event.returnValue = ''
        },
        shorten(text, length = 25) {
            if (text && text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        validateForm() {
            if (this.$refs.form.validate()) {
                if (this.exerciseEdit) {
                    this.updateExercise(this.form)
                } else {
                    this.createExercise(this.form)
                }
                this.$emit('updateExercise', this.form)
                this.$router.push({ name: this.$route.matched[1].name })
            }
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'device_exercise'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.form.picture = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                })
        },
        uploadManualImage(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'device_exercise_manual'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.manualForm.picture = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
        abortSettingForm() {
            this.generalExerciseSettingsDialog = false
            this.setExerciseSettingsDialog = false
            this.exerciseSetting = {
                input: null,
                text: null,
                selections: [],
            }
        },
        saveGeneralSetting() {
            if (
                !this.exerciseSetting.selections ||
                (this.exerciseSetting.selections && !this.exerciseSetting.selections.length)
            )
                delete this.exerciseSetting.selections
            this.form.general_exercise_settings.push(this.exerciseSetting)
            this.abortSettingForm()
        },
        saveSetSetting() {
            if (
                !this.exerciseSetting.selections ||
                (this.exerciseSetting.selections && !this.exerciseSetting.selections.length)
            )
                delete this.exerciseSetting.selections
            this.form.set_exercise_settings.push(this.exerciseSetting)
            this.abortSettingForm()
        },
        saveManual() {
            if (this.manualForm.index >= 0) {
                let index = this.manualForm.index
                delete this.manualForm.index
                this.form.manual[index] = this.manualForm
            } else {
                this.form.manual.push(this.manualForm)
            }
            this.abortManual()
        },
        abortManual() {
            this.manualDialog = false
            this.manualForm = { picture: '', description: '' }
        },
        deleteManual(index) {
            this.form.manual = this.form.manual.filter((manual, index) => index !== this.manualForm.index)
            this.abortManual()
        },
        editManual(index) {
            this.manualForm = JSON.parse(JSON.stringify(this.form.manual[index]))
            this.manualForm.index = index
            this.manualDialog = true
        },
    },
    computed: {
        ...mapState('deviceStore', ['exerciseEdit']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
        getManualFormTitle() {
            if (!this.manualForm.index && this.manualForm.index !== 0) return 'neuen Schritt hinzufügen'
            return 'Schritt ' + (this.manualForm.index + 1) + ' bearbeiten'
        },
        availableDeviceExerciseSettingsFiltered() {
            let activatedSettings = this.form.general_exercise_settings
            return this.availableExerciseSettings.filter((setting) => {
                return !activatedSettings.find((activatedSetting) => activatedSetting.text === setting.text)
            })
        },
        availableSetExerciseSettingsFiltered() {
            let activatedSettings = this.form.set_exercise_settings
            return this.availableExerciseSettings.filter((setting) => {
                return !activatedSettings.find((activatedSetting) => activatedSetting.text === setting.text)
            })
        },
        active() {
            if (this.$route.name === 'DeviceExerciseCreate' || this.$route.name === 'DeviceExerciseEdit') return true
            return false
        },
    },
    watch: {
        navigationBarTextState(val) {
            if (
                this.navigationBarEndPoint === 'DeviceExerciseCreate' ||
                this.navigationBarEndPoint === 'DeviceExerciseEdit'
            )
                this.validateForm()
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .slim-btn {
    margin-bottom: 30px;
}
.formCard {
    border-radius: 10px;

    &-title {
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}
.formTitle {
    background-color: #313131;
    color: white !important;
}
.device-exercise-form {
    &__manual-step {
        span {
            background: var(--v-primary);
            color: white;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
        }
    }
    &__manual-add {
        background: var(--v-primary);
        span {
            color: white;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
        }
    }
    &__manual-item {
        background: var(--v-primary);
    }
    &__tags {
        ::v-deep .v-chip {
            background: var(--v-listitemcolor);
        }
    }
}
</style>
