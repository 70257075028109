<template>
    <div>
        <v-app-bar color="transparent" fixed elevation="0" class="justify-space-between py-2">
            <v-col cols="2">
                <v-btn class="elevation-0" icon color="white" @click="$router.push('/')">
                    <v-icon color="black">mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="8">
                <v-img height="65px" @click="$router.push('/')" contain src="/img/roodyLogoGreen.png"></v-img>
            </v-col>
            <v-col cols="2">
                <v-badge color="red" :value="productsLength" :content="productsLength">
                    <v-btn
                        @click="toggleCartVisiblity()"
                        style="position: absolute; right: 0; top: 0; bottom: 0; margin: auto"
                        icon
                        x-large
                        color="rgba(0, 0, 0, 0.87)"
                    >
                        <v-icon>mdi-cart</v-icon>
                    </v-btn>
                </v-badge>
            </v-col>
        </v-app-bar>
        <v-card-title class="font-weight-bold h6" style="word-break: break-word; margin-top: 65px">
            PRODUKTE DIE BEWEGEN
        </v-card-title>
        <v-row no-gutters>
            <v-chip-group v-model="categorySelection" active-class="primary--text" mandatory>
                <v-chip value="all">Übersicht</v-chip>
                <v-chip value="abo" class="ml-7">Abonnemente</v-chip>
                <v-chip value="voucher">Gutscheine</v-chip>
            </v-chip-group>
        </v-row>
        <v-row class="justify-content-center" v-if="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" lg="3" xl="3" v-for="(product, index) of filteredProducts" :key="'product' + index">
                <v-card
                    @click="openProductPage(product)"
                    hover
                    class="rounded-xl elevation-7 fill-height"
                    style="background: lightgray"
                >
                    <div class="pa-2">
                        <v-img aspect-ratio="1.6" class="rounded-xl" :src="'/img/' + product.picture"></v-img>
                    </div>
                    <v-card-title class="pt-1 pb-2 font-weight-bold" style="word-break: break-word">
                        {{ product.title }}
                    </v-card-title>
                    <v-card-subtitle style="word-break: break-word">{{ product.shortDescription }}</v-card-subtitle>
                    <v-card-title class="py-1" style="opacity: 0.7">
                        {{ getProductPrice(product.variations) }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <shop-product-page
            @close="selectedProduct = null"
            :product="selectedProduct"
            v-if="selectedProduct"
        ></shop-product-page>
        <router-view></router-view>
    </div>
</template>
  
  <script>
import api from '../../api'
import shopProductPage from './shopProductPage.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    components: { shopProductPage },
    data() {
        return {
            products: [],
            selectedProduct: null,
            categorySelection: 'all',
            visibilityToggle: false,
        }
    },
    async created() {
        // FIX If we ever Sell
        let art = 'well come FIT AG'
        if (window.location.host.includes('winti')) {
            art = 'winti FIT AG'
        }

        if (this.user && this.user.studio && this.user.studio.art) {
            art = this.user.studio.art
        }
        this.loading = true

        this.products = await api.fetchProductsPublicStore({ art })
        this.loading = false
        if (this.$route && this.$route.params && this.$route.params.id) {
            this.openProductPage(this.products.find((product) => product._id === this.$route.params.id))
        }
    },
    computed: {
        ...mapState('shopStore', ['openProduct']),
        ...mapState('userStore', ['user']),
        ...mapGetters('shopStore', ['productsLength']),
        filteredProducts() {
            return this.products.filter(
                (product) => this.categorySelection === 'all' || product.category === this.categorySelection
            )
        },
    },
    watch: {
        openProduct(val) {
            this.openProductPage(this.products.find((product) => product._id === val))
        },
    },
    methods: {
        ...mapActions('shopStore', ['toggleCartVisiblity']),

        openProductPage(product) {
            this.selectedProduct = null
            this.selectedProduct = product
        },
        getProductPrice(variations) {
            if (!variations.length) return 'Kein Preis hinterlegt'
            if (variations.length === 1) {
                return variations[0].setOwnPrice ? 'Eigene Eingabe Möglich' : 'Ab CHF ' + variations[0].price + '.-'
            } else {
                let prices = variations.map((variation) => variation.price)
                let filteredPrices = prices.filter((price) => price !== '')

                return 'Ab CHF ' + Math.min(...filteredPrices.map((price) => Number(price))) + '.-'
            }
        },
    },
}
</script>
  