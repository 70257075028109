<template>
    <v-card dark elevation="0" max-width="700px" class="mx-auto mb-15 mt-5">
        <v-row v-if="!loading">
            <v-col cols="12">
                <v-select
                    class="px-5"
                    multiple
                    :disabled="!userService.hasRight('c_create')"
                    :items="studios"
                    label="Studio auswählen"
                    v-model="studioSelect"
                    item-text="name"
                    item-value="_id"
                ></v-select>
            </v-col>
            <v-col
                class="mt-0 pt-0 d-flex justify-center"
                v-if="userService.hasRight('c_create') || userService.hasRight('c_open_contracts')"
                cols="12"
            >
                <v-btn class="mt-0 pt-0 mx-auto" @click="studioSelect = studios.map((e) => e._id)" text>
                    Alle Studios auswählen
                </v-btn>
            </v-col>
            <v-col cols="12" class="px-5">
                <v-expansion-panels v-model="userContractPanel" dark v-if="openContracts.length > 0">
                    <v-expansion-panel v-for="(user, i) of openContracts" :key="i + '-contractIndex'">
                        <v-expansion-panel-header :color="getExpansionColorByDate(user.newestCreatedAt)">
                            {{ $moment(user.newestCreatedAt).format('DD.MM.YY HH:mm') }}
                            Uhr | {{ user.vorname }} {{ user.nachname }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-expansion-panels v-model="openContractPanel">
                                <v-expansion-panel
                                    :disabled="contract.status.toString() !== 'temporary'"
                                    v-for="(contract, j) of user.contracts"
                                    :key="j + '-contractUserIndex'"
                                >
                                    <v-expansion-panel-header>
                                        {{ $moment(contract.createdAt).format('DD.MM.YY HH:mm') }} Uhr |
                                        {{ getNameOfContract(contract.contractId) }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col v-if="contract.customer.contractCreatorInfos" cols="12">
                                                <v-card-title class="h4 mx-0 px-0">Vertragersteller:</v-card-title>
                                                <span>
                                                    {{ contract.customer.contractCreatorInfos.firstName }}
                                                    {{ contract.customer.contractCreatorInfos.lastName }}
                                                </span>
                                                <br />
                                                <span>{{ contract.customer.contractCreatorInfos.email }}</span>
                                                <br />
                                            </v-col>
                                            <v-col
                                                v-if="contract.category.isTakeover && contract.category.takeover"
                                                cols="12"
                                            >
                                                <v-card-title class="h4 mx-0 px-0">Abonnementübernahme:</v-card-title>
                                                <span>
                                                    {{ contract.category.takeover.firstName }}
                                                    <br />
                                                    {{ contract.category.takeover.lastName }}
                                                    <br />
                                                    {{ contract.category.takeover.email }}
                                                </span>
                                                <br />
                                                <v-card-text class="pl-0">
                                                    Hiermit bestätige ich, dass ich mein Abonnement übergebe.
                                                </v-card-text>
                                                <br />
                                                <v-img
                                                    style="background-color: white"
                                                    max-width="200px"
                                                    :src="contract.category.takeover.signature"
                                                ></v-img>
                                            </v-col>
                                            <v-col
                                                v-if="contract.category.isAffiliate && contract.category.affiliateInfos"
                                                cols="12"
                                            >
                                                <v-card-title class="h4 mx-0 px-0">Weiterempfehlung:</v-card-title>
                                                <span>
                                                    {{ contract.category.affiliateInfos.firstName }}
                                                    <br />
                                                    {{ contract.category.affiliateInfos.lastName }}
                                                    <br />
                                                    {{ contract.category.affiliateInfos.email }}
                                                </span>
                                                <br />
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    Array.isArray(contract.customer.additionalImage) &&
                                                    contract.customer.additionalImage &&
                                                    contract.customer.additionalImage.length > 0
                                                "
                                                cols="12"
                                            >
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card-title class="h4 mx-0 px-0">Anhänge:</v-card-title>
                                                        <v-card-subtitle class="mx-0 px-0">
                                                            Bild anklicken, um Download zu starten
                                                        </v-card-subtitle>
                                                    </v-col>
                                                    <v-col
                                                        v-for="additionalImage of contract.customer.additionalImage"
                                                        :key="additionalImage + '-additionalImage'"
                                                        cols="12"
                                                    >
                                                        <v-img
                                                            :key="imageSources[additionalImage] + 'imgsourceKey'"
                                                            v-if="imageSources[additionalImage]"
                                                            :src="imageSources[additionalImage]"
                                                            @click="downloadContractImage(additionalImage)"
                                                            max-width="200px"
                                                            max-height="100%"
                                                        ></v-img>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" v-if="contract.hasPurchasedPersonalTraining">
                                                <v-alert type="info">Personal Training gekauft</v-alert>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-card-title class="h4 mx-0 px-0">Kommentare</v-card-title>
                                                <div
                                                    v-for="comment of contract.comments"
                                                    :key="comment.date + '-comment'"
                                                >
                                                    <div v-if="comment.date && comment.comment">
                                                        <span>
                                                            {{ $moment(comment.date).format('DD.MM.YY HH:mm') }} Uhr
                                                        </span>
                                                        <v-card-text>{{ comment.comment }}</v-card-text>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-textarea
                                                    v-model="contract.comment"
                                                    rows="3"
                                                    label="Kommentar"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col cols="8">
                                                <v-btn
                                                    color="primary"
                                                    @click="
                                                        updateContract(
                                                            contract.customer.selection,
                                                            contract.index,
                                                            contract.comment,
                                                            'temporary',
                                                            false,
                                                            false
                                                        )
                                                    "
                                                >
                                                    Kommentar speichern
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="8">
                                                <v-btn
                                                    @click="
                                                        updateContract(
                                                            contract.customer.selection,
                                                            contract.index,
                                                            contract.comment,
                                                            'created',
                                                            false
                                                        )
                                                    "
                                                    color="primary"
                                                    class="white--text mr-3"
                                                >
                                                    Einreichen
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="4" class="d-flex justify-end">
                                                <v-btn
                                                    v-if="userService.hasRight('c_delete_temporary')"
                                                    @click="
                                                        visibleDeleteDialogIndex =
                                                            contract.customer.selection + '-' + contract.index
                                                    "
                                                    icon
                                                >
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    @click="
                                                        $router.push(
                                                            '/dashboard/contract/edit/' +
                                                                contract.customer.selection +
                                                                '/' +
                                                                contract.index
                                                        )
                                                    "
                                                    icon
                                                >
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    icon
                                                    @click="
                                                        printUserContract(
                                                            contract.customer.selection,
                                                            contract.index,
                                                            getNameOfContract(contract.contractId)
                                                        )
                                                    "
                                                >
                                                    <v-icon>mdi-printer</v-icon>
                                                </v-btn>
                                            </v-col>

                                            <v-col cols="12">
                                                <object
                                                    v-if="!isApp"
                                                    :key="openContractPanel"
                                                    width="100%"
                                                    height="300px"
                                                    :data="contractSauce"
                                                ></object>
                                                <v-btn
                                                    color="blue"
                                                    v-else
                                                    @click="
                                                        printUserContract(
                                                            contract.customer.selection,
                                                            contract.index,
                                                            getNameOfContract(contract.contractId)
                                                        )
                                                    "
                                                >
                                                    Vertrag öffnen
                                                </v-btn>
                                            </v-col>
                                            <delete-dialog
                                                @onDelete="deleteContract(contract.customer.selection, contract.index)"
                                                @onCancel="visibleDeleteDialogIndex = null"
                                                :dialog-title="'Vertrag löschen'"
                                                :dialog-visible="
                                                    visibleDeleteDialogIndex ===
                                                    contract.customer.selection + '-' + contract.index
                                                "
                                                :itemName="'Vertrag'"
                                            ></delete-dialog>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-card-text v-else>Keine Verträge gefunden</v-card-text>
            </v-col>
        </v-row>
        <v-row v-else class="justify-center d-flex">
            <v-col cols="11">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import contractCreator from './contractCreator.vue'
import deleteDialog from '../../../components/tools/deleteDialog.vue'

export default {
    components: { contractCreator, deleteDialog },
    data() {
        return {
            contractSauce: null,
            openContractPanel: null,
            userContractPanel: null,
            visibleDeleteDialogIndex: null,
            userService,
            studios: [],
            disableStudioSelect: false,
            studioSelect: [],
            loading: true,
            showDialog: { active: false, infos: { userId: null, index: null, text: '' } },
            contracts: [],
            openContracts: [],
            contractsBackup: [],
            imageSources: {},
        }
    },
    async created() {
        this.fetchContracts()
        this.fetchContractsByStatus('temporary')
        let studios = await api.fetchStudios()

        this.studios = studios
        let userStudio = await api.getUserStudio()
        this.studioSelect.push(userStudio.studio._id)
    },
    methods: {
        getExpansionColorByDate(date) {
            let color =
                this.$moment().diff(this.$moment(date), 'days') <= 3
                    ? 'default'
                    : this.$moment().diff(this.$moment(date), 'days') <= 5
                    ? 'orange'
                    : 'red'

            return color
        },
        downloadContractImage(additionalImage) {
            api.downloadContractImage(additionalImage)
        },
        async checkImageSources(imagePaths) {
            this.imageSources = []
            for (let image of imagePaths) {
                if (!this.imageSources[image]) {
                    console.log(image, 'IMAGE PATH')
                    this.imageSources[image] = await api.getContractImage(image)
                    console.log(this.imageSources)
                }
            }
            this.$forceUpdate()
        },
        deleteContract(customerId, contractIndex) {
            api.deleteTemporaryContract({ customerId, contractIndex })
                .then((res) => {
                    this.fetchContractsByStatus('temporary')
                    this.$toast.success('Vertrag gelöscht')
                })
                .catch((e) => {
                    this.$toast.error('Fehler beim Löschen des Vertrags')
                })
            this.visibleDeleteDialogIndex = null
        },
        filterContracts() {
            console.log(this.openContracts.length, this.contractsBackup.length)
            this.openContracts = this.contractsBackup.filter((c) => {
                for (let contract of c.contracts) {
                    let studioId = contract.studio._id.toString()

                    if (this.studioSelect.includes(studioId)) return true
                }
            })
        },
        updateContract(userId, index, comment, status = 'rejected', externallySaved = false, showConfirm = true) {
            if (!showConfirm || confirm('Vertrag einreichen?')) {
                api.updateContractStatus({ status, userId, index, comment, externallySaved })
                    .then((e) => {
                        this.$toast.success('Vertrag aktualisiert')
                        this.fetchContractsByStatus('rejected')
                    })
                    .catch((e) => {
                        this.$toast.error('Fehler bei Aktualisierung')
                    })
            } else {
                this.$toast.info('Vertrag nicht eingereicht')
            }
        },

        resetDialog() {
            this.showDialog = { active: false, infos: { userId: null, index: null, text: '' } }
        },
        openDialog(userId, index) {
            this.showDialog.active = true
            this.showDialog.infos = { userId, index }
        },
        printUserContract(userId, index, title) {
            api.printUserContract(userId, index, title)
        },
        getNameOfContract(contractId) {
            try {
                let foundContract = this.contracts.find((c) => c._id.toString() === contractId.toString())
                console.log(foundContract)
                let title = foundContract.formFields.find((f) => f.inputType === 'title').inputValue
                return title
            } catch (e) {
                return 'Vertrag nicht gefunden'
            }
        },
        async fetchContractsByStatus(status) {
            this.openContracts = []
            let contracts = await api.fetchContractsByStatus(status)

            //contracts = contracts.filter((e) => e.customer && e.customer.selection)
            contracts.forEach((c) => {
                c.newestCreatedAt = c.contracts.findLast((c) => c.status === 'temporary').createdAt
                c.contracts.forEach((contract) => {
                    contract.comment = ''
                })
            })
            // sort contracts by contracts.newestCreatedAt
            contracts = contracts.sort((a, b) => {
                return new Date(b.newestCreatedAt) - new Date(a.newestCreatedAt)
            })
            this.openContracts = contracts
            this.loading = false
            this.contractsBackup = JSON.parse(JSON.stringify(contracts))
            console.log(this.contractsBackup.length, 'PRE MODIFIED BACKUP LENGTH')
            this.filterContracts()
        },
        async fetchContracts() {
            let contracts = await api.fetchContracts()
            this.contracts = contracts
        },
    },
    computed: {
        isApp() {
            return localStorage.getItem('isApp')
        },
    },
    watch: {
        studioSelect() {
            this.filterContracts()
        },
        async openContractPanel(val) {
            if (val !== undefined) {
                let user = this.openContracts[this.userContractPanel]
                let contract = user.contracts[val]
                if (contract && contract.customer && contract.customer.additionalImage) {
                    this.checkImageSources(contract.customer.additionalImage)
                }
                try {
                    let blob = await api.getIframeprintUserContract(
                        contract.customer.selection,
                        val,
                        this.getNameOfContract(contract.contractId)
                    )
                    this.contractSauce = URL.createObjectURL(blob)
                } catch (e) {
                    this.$toast.error('Vertrag konnte nicht gedruckt werden')
                }
            } else {
                this.contractSauce = null
            }
            console.log(val, 'open contract panel')
        },
        userContractPanel(val) {
            this.openContractPanel = undefined
            console.log(val, 'user panel')
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
