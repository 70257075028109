<template>
    <v-card max-width="800px" class="mx-auto" color="transparent" elevation="0">
        <v-row>
            <v-col cols="12">
                <v-card-title class="white--text">Leads pro Studio</v-card-title>
            </v-col>
            <v-col cols="12" v-if="statisticInfo.leadsPerStudio">
                <leads-per-setting
                    :key="JSON.stringify(statisticInfo.leadsPerStudio).length + '-leads-per-studio'"
                    :chartData="statisticInfo.leadsPerStudio"
                ></leads-per-setting>
            </v-col>
            <v-col cols="12">
                <v-card-title class="white--text">Leads pro Formular</v-card-title>
            </v-col>
            <v-col cols="12">
                <v-select
                    hide-details
                    class="white--text"
                    dark
                    outlined
                    @change="dataKey++"
                    multiple
                    :items="['Alle'].concat(getKeys())"
                    v-model="leadsPerSettingSelection"
                ></v-select>
            </v-col>
            <v-col cols="12">
                <v-tabs centered dark v-model="leadFormTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab v-for="item in ['Auswahl', 'Downloads']" :key="item">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items dark v-model="leadFormTab">
                    <v-tab-item>
                        <v-row class="my-2">
                            <v-col class="py-2" align="center" cols="12">
                                <v-btn
                                    text
                                    class="white--text"
                                    @click="
                                        leadsPerSettingSelection = getKeys().filter((e) =>
                                            e.includes('Weiterempfehlung')
                                        )
                                    "
                                >
                                    Weiterempfehlungen auswählen
                                </v-btn>
                            </v-col>
                            <v-col class="py-2" align="center" cols="12">
                                <v-btn
                                    text
                                    class="white--text"
                                    @click="leadsPerSettingSelection = getOnlineLeadsKeys()"
                                >
                                    10-Tage-Kampagne Leads auswählen
                                </v-btn>
                            </v-col>
                            <v-col class="py-2" align="center" cols="12">
                                <v-btn
                                    text
                                    class="white--text"
                                    @click="leadsPerSettingSelection = getKeys().filter((e) => e.includes('11'))"
                                >
                                    11-Tage-Kampagne Leads auswählen
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="my-2">
                            <v-col class="py-2" align="center" cols="12">
                                <v-btn @click="downloadLeadCostExcel" text>
                                    <v-icon color="white">mdi-microsoft-excel</v-icon>
                                    Kosten 11-Tage-Kampagne
                                </v-btn>
                            </v-col>
                            <v-col class="py-2" align="center" cols="12">
                                <v-btn text @click="downloadExcel">
                                    <v-icon color="white">mdi-microsoft-excel</v-icon>
                                    Alle Leads in diesem Zeitraum
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>

            <v-col class="justify-center d-flex" cols="12">
                <v-btn icon class="mb-2" @click="showStudio = !showStudio">
                    <v-icon color="white" v-if="!showStudio">mdi-map-marker</v-icon>
                    <v-icon v-else color="white">mdi-arrow-left-circle</v-icon>
                </v-btn>
                <v-btn v-if="!showStudio" icon class="mb-2" @click="showTables = !showTables">
                    <v-icon color="white" v-if="showTables">mdi-chart-line</v-icon>
                    <v-icon v-else color="white">mdi-table</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="!showStudio && showTables && statisticInfo.leadsPerFormAndStudioTable">
                <leads-per-form-and-studio-table
                    :key="dataKey + '-leads-per-form-and-studio-table'"
                    :tableData="statisticInfo.leadsPerFormAndStudioTable"
                ></leads-per-form-and-studio-table>
            </v-col>
            <v-col cols="12" v-if="!showStudio && !showTables && statisticInfo.leadsPerSetting">
                <leads-per-setting
                    :key="dataKey + '-leads-per-setting'"
                    :chartData="statisticInfo.leadsPerSetting"
                ></leads-per-setting>
            </v-col>
            <v-col cols="12" v-if="showStudio">
                <leads-per-setting
                    key="yolo"
                    :chartData="getDataGroupedForStudio(statisticInfo.leadsPerFormAndStudioTable)"
                ></leads-per-setting>
            </v-col>
            <v-col v-if="false && statisticInfo.leadsPerSetting" cols="12">
                <v-select
                    dark
                    :items="['Alle'].concat(statisticInfoBackup.leadsPerSettingAndTime.map((e) => e.name))"
                    v-model="leadsPerSettingSelection"
                ></v-select>
            </v-col>
            <v-col cols="12" v-if="!showStudio && !showTables && statisticInfo.leadsPerSettingAndTime">
                <leads-per-form-and-time
                    :key="dataKey + '-leads-per-form-and-time'"
                    :leadsPerSettingAndTime="statisticInfo.leadsPerSettingAndTime"
                ></leads-per-form-and-time>
            </v-col>
            <v-col cols="12" md="6" v-if="statisticInfo.leadsPerStatus">
                <v-card-title class="white--text">Alle Leads / Weiterempfehlungen pro Status</v-card-title>

                <leads-per-status
                    :key="dataKey + '-leads-per-status'"
                    :leadsPerStatus="statisticInfo.leadsPerStatus"
                ></leads-per-status>
            </v-col>
            <v-col cols="12" md="6" v-if="statisticInfo.leadsPerTime">
                <v-card-title class="white--text">Alle Leads / Weiterempfehlungen im Zeitverlauf</v-card-title>
                <leads-per-time
                    :key="dataKey + '-leads-per-time'"
                    :leadsPerTime="statisticInfo.leadsPerTime"
                ></leads-per-time>
            </v-col>
        </v-row>
    </v-card>
</template> 

<script>
import api from '@/api'
import leadsPerForm from './leadStats/leadsPerForm.vue'
import leadsPerStatus from './leadStats/leadsPerStatus.vue'
import leadsPerStudio from './leadStats/leadsPerStudio.vue'
import leadsPerTime from './leadStats/leadsPerTime.vue'
import referralLeadsCount from './leadStats/referralLeadsCount.vue'
import leadsPerFormAndTime from './leadStats/leadsPerFormAndTime.vue'
import leadsPerFormAndStudioTable from './leadStats/leadsPerFormAndStudioTable.vue'
import leadsPerSetting from './leadStats/leadsPerSetting.vue'
export default {
    components: {
        leadsPerForm,
        leadsPerStatus,
        leadsPerStudio,
        leadsPerTime,
        referralLeadsCount,
        leadsPerFormAndTime,
        leadsPerSetting,
        leadsPerFormAndStudioTable,
    },
    props: {
        datePickerRange: {
            type: Array,
            default: () => [],
        },
        studios: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            leadFormTab: null,
            showStudio: false,
            showTables: false,
            dataKey: 0,
            statisticInfo: {
                leadsPerFormAndStudioTable: {},
                leadsPerStudio: [],
                leadsPerStatus: [],
                leadsPerSetting: [],
                leadsPerSettingAndTime: [],
                leadsPerTime: [],
                referralLeadsCount: [],
            },
            leadsPerSettingSelection: ['Alle'],
            statisticInfoBackup: {},
        }
    },
    created() {
        this.fetchLeadStatistics()
    },
    methods: {
        getDataGroupedForStudio(data) {
            console.log('leads per studio', this.statisticInfo.leadsPerStudio)
            let formattedData = {}
            let total = 0
            for (let form of Object.keys(data)) {
                for (let studio of Object.keys(data[form])) {
                    if (studio === 'Gesamt') continue
                    if (!formattedData[studio]) formattedData[studio] = {}
                    for (let status of Object.keys(data[form][studio])) {
                        if (!formattedData[studio][status]) formattedData[studio][status] = 0
                        formattedData[studio][status] += data[form][studio][status]
                        total += data[form][studio][status]
                    }
                }
            }

            return formattedData
        },
        getOnlineLeadsKeys() {
            return this.getKeys().filter((e) => e.includes('10 tage') || e.includes('Google'))
        },
        async downloadLeadCostExcel() {
            try {
                let excelFile = await api.fetchLeadCostExcel()
            } catch (e) {
                this.$toast.error('Es ist ein Fehler aufgetreten')
            }
        },
        async downloadExcel() {
            try {
                let excelFile = await api.fetchLeadExcel({
                    start_date: this.datePickerRange[0],
                    end_date: this.datePickerRange[1],
                    studios: this.studios.map((studio) => studio._id),
                })
            } catch (e) {
                this.$toast.error('Es ist ein Fehler aufgetreten')
            }
        },
        getKeys() {
            if (this.statisticInfoBackup.leadsPerSetting) return Object.keys(this.statisticInfoBackup.leadsPerSetting)
            else return []
        },
        async fetchLeadStatistics() {
            let statisticInfos = await api.getLeadStatistics({
                start_date: this.datePickerRange[0],
                end_date: this.datePickerRange[1],
                studios: this.studios.map((studio) => studio._id),
            })

            this.statisticInfoBackup = JSON.parse(JSON.stringify(statisticInfos))
            this.filterLeadsPerSetting()
            this.statisticInfo = statisticInfos
            this.dataKey++
        },
        filterleadsPerFormAndStudioTable() {
            if (!this.leadsPerSettingSelection.includes('Alle')) {
                //   this.statisticInfo.leadsPerFormAndStudioTable
                let filteredObj = {}
                Object.keys(this.statisticInfoBackup.leadsPerFormAndStudioTable).forEach((key) => {
                    if (this.leadsPerSettingSelection.includes(key)) {
                        filteredObj[key] = this.statisticInfoBackup.leadsPerFormAndStudioTable[key]
                    }
                })
                this.statisticInfo.leadsPerFormAndStudioTable = filteredObj
            } else {
                this.statisticInfo.leadsPerFormAndStudioTable = this.statisticInfoBackup.leadsPerFormAndStudioTable
            }
        },
        filterLeadsPerSetting() {
            if (!this.leadsPerSettingSelection.includes('Alle')) {
                /* this.statisticInfo.leadsPerSetting = this.statisticInfoBackup.leadsPerSetting.filter((lead) =>
                    this.leadsPerSettingSelection.includes(lead.name)
                )*/

                this.statisticInfo.leadsPerSetting = Object.keys(this.statisticInfoBackup.leadsPerSetting)
                    .filter((key) => this.leadsPerSettingSelection.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = this.statisticInfoBackup.leadsPerSetting[key]
                        return obj
                    }, {})

                this.statisticInfo.leadsPerSettingAndTime = this.statisticInfoBackup.leadsPerSettingAndTime.filter(
                    (lead) => this.leadsPerSettingSelection.includes(lead.name)
                )
            } else {
                this.statisticInfo.leadsPerSetting = this.statisticInfoBackup.leadsPerSetting
                this.statisticInfo.leadsPerSettingAndTime = this.statisticInfoBackup.leadsPerSettingAndTime
            }
        },
    },
    watch: {
        datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.fetchLeadStatistics()
            }
        },
        studios() {
            this.fetchLeadStatistics()
        },
        leadsPerSettingSelection() {
            this.filterLeadsPerSetting()
            this.filterleadsPerFormAndStudioTable()
        },
    },
}
</script>
<style lang="scss" scoped>
</style>