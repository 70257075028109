<template>
    <v-simple-table class="mx-auto mt-4" style="max-width: 400px">
        <template v-slot:default>
            <tbody>
                <tr
                    v-for="selectedPackage of selectedPackages"
                    :key="selectedPackage.packageName + '-selectedPackages'"
                >
                    <td>
                        <span v-if="selectedPackage.amountSelectable && selectedPackage.amount" class="mr-1">
                            {{ selectedPackage.amount }}x
                        </span>
                        <span>{{ selectedPackage.packageName }}</span>
                    </td>
                    <td style="text-align: end">
                        <span v-if="selectedPackage.amountSelectable && selectedPackage.amount">
                            <span
                                v-if="
                                    selectedPackage.usualPrice &&
                                    (selectedPackage.calculateWithEndDate ||
                                        (selectedPackage.calculateWithEndDateSixMonths &&
                                            parseInt(selectedPackage.price) !== parseInt(selectedPackage.usualPrice)))
                                "
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-1" @click.stop icon>
                                            <v-icon v-on="on" v-bind="attrs">mdi-sale-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        Regulärpreis:
                                        {{ displayCurrency(selectedPackage.amount * selectedPackage.usualPrice) }}
                                    </span>
                                </v-tooltip>
                            </span>
                            {{ displayCurrency(selectedPackage.amount * parseInt(selectedPackage.price)) }}
                        </span>
                        <span v-else>
                            <span
                                v-if="
                                    selectedPackage.usualPrice &&
                                    (selectedPackage.calculateWithEndDate ||
                                        (selectedPackage.calculateWithEndDateSixMonths &&
                                            parseInt(selectedPackage.price) !== parseInt(selectedPackage.usualPrice)))
                                "
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-1" @click.stop icon>
                                            <v-icon v-on="on" v-bind="attrs">mdi-sale-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>
                                        Regulärpreis:
                                        {{ displayCurrency(selectedPackage.usualPrice) }}
                                    </span>
                                </v-tooltip>
                            </span>
                            {{ displayCurrency(selectedPackage.price) }}
                        </span>
                    </td>
                </tr>
                <tr
                    style="background-color: var(--v-primary); font-weight: bold; letter-spacing: 0.8px"
                    class="white--text"
                >
                    <td>Gesamt</td>
                    <td style="text-align: end">{{ total }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>
<script>
import { roundToNearestFiveCents } from '@/moduls/currency/currency.js'
export default {
    props: {
        total: {
            default: 0,
            required: true,
        },
        selectedPackages: {
            default: [],
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {
        displayCurrency(amount) {
            return roundToNearestFiveCents(amount)
        },
    },
}
</script>