<template>
    <v-card dark elevation="0" max-width="700px" class="mx-auto mb-15 mt-5">
        <v-row>
            <v-col cols="12" v-if="false && fetchingSales">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
            <v-col cols="12" v-else-if="sales.length > 0">
                <v-expansion-panels>
                    <v-expansion-panel v-for="sale in sortedSales" :key="sale._id">
                        <v-expansion-panel-header color="blue">
                            <span>
                                {{ $moment(sale.createdAt).format('DD.MM.YYYY HH:mm') }} Uhr | {{ sale.studio.name }} |
                                {{ sale.customer.vorname }} {{ sale.customer.nachname }} -
                                {{ sale.selectionMap.durationMonths }} Monate
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    <v-row>
                                        <v-col cols="6">
                                            <strong>Preis:</strong>
                                            {{ sale.selectionMap.price }} CHF
                                        </v-col>
                                        <v-col cols="6">
                                            <strong>Dauer:</strong>
                                            {{ sale.selectionMap.durationMonths }} Monate
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <strong>Startdatum:</strong>
                                            {{ $moment(sale.startDate).format('DD.MM.YYYY') }}
                                        </v-col>
                                        <v-col cols="6">
                                            <strong>Enddatum:</strong>
                                            {{ $moment(sale.endDate).format('DD.MM.YYYY') }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <strong>Studio:</strong>
                                            {{ sale.studio.name }}
                                        </v-col>
                                        <v-col cols="6">
                                            <strong>Zahlung:</strong>
                                            {{ sale.paymentOption }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <strong>Erstellt von:</strong>
                                            {{ sale.creator.vorname }} {{ sale.creator.nachname }}
                                        </v-col>
                                    </v-row>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <v-textarea
                                        class="mt-5"
                                        label="Notizen"
                                        v-model="sale.notes"
                                        @blur="updateNotes(sale)"
                                        outlined
                                        dense
                                    ></v-textarea>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-if="userService.hasRight('c_open_contracts')"
                                    class="d-flex justify-center"
                                >
                                    <v-btn @click="updateTACStatus(sale)" color="primary">
                                        wurde ins TAC übertragen
                                    </v-btn>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" v-else>
                <v-alert type="info">Keine Ernährungsberatungen gefunden</v-alert>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import userService from '@/services/userService'
import api from '@/api'
export default {
    props: {
        studios: {
            default: [],
            required: true,
        },
    },
    data() {
        return {
            sales: [],
            userService,
            fetchingSales: true,
        }
    },
    computed: {
        sortedSales() {
            return this.sales.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
        },
    },
    async created() {
        this.fetchNutritionSales()
    },
    methods: {
        async updateTACStatus(sale) {
            try {
                await api.updateNutritionSale(sale._id, { 'status.tacConfirmed': true })
                this.sales = this.sales.filter((e) => e._id !== sale._id)
                this.$toast.success('Status erfolgreich gespeichert')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Speichern der Notizen')
            }
        },
        async fetchNutritionSales() {
            this.fetchingSales = true
            try {
                let studios = []

                studios = this.studios

                let sales = await api.fetchOpenNutritionSales({ studios })
                this.sales = sales
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim laden')
            } finally {
                this.fetchingSales = false
            }
        },
        async updateNotes(sale) {
            try {
                await api.updateNutritionSale(sale._id, { notes: sale.notes })
                this.$toast.success('Notizen erfolgreich gespeichert')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Speichern der Notizen')
            }
        },
    },
    watch: {
        studios() {
            if (this.studios.length === 0) {
                this.sales = []
            } else {
                this.fetchNutritionSales()
            }
        },
    },
}
</script>