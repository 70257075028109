<template>
    <div class="home">
        <div class="informationen-location">
            <div class="informationen-wrapper">
                <!---<img :src="'/img/' + standort_bild" /> -->
                <button class="affiliate-button-head" @click="affiliateSharing">
                    <span class="material-icons"> forum </span>Deine CHF 50.- in Bar
                </button>
            </div>

            <div class="informationen-wrapper">
                <div class="standort">{{ standort }}</div>
                <div class="contact-studio">
                    <a target="__blank" :href="studio_info.maps">
                        <span class="material-icons">location_on</span>
                    </a>
                    <a target="__blank" :href="telefon">
                        <span class="material-icons">email</span>
                    </a>
                    <router-link to="/studioinfos" :href="studio_info.website">
                        <span class="material-icons">info</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="anamnesebogen" @click="showAnamesebogen()" v-show="rollen.admin || rollen.leitung || rollen.mitarbeiter">
            Anamnesebogen
        </div>
        <div class="label">Videos</div>
        <router-link to="/videos" class="videoarchiv"
            ><span>Groupfitness Videos</span> <span class="material-icons"> video_library </span></router-link
        >
        <div class="next-kurse">
            <div v-if="!meinekurse" class="label">Nächste Kurse</div>
            <div v-else class="label">Meine Kurse</div>
            <div class="loading" v-show="loading"><img src="\img\icons\loading.gif" />Kurse werden geladen..</div>
            <div v-show="!loading" class="next-kurse-wrapper">
                <router-link
                    v-for="(kurs, i) in course_timelines"
                    :key="i"
                    class="next-kurs"
                    :to="{ path: '/kurse/anmeldung/' + kurs.course_timeline._id }"
                    v-bind:style="{
                        backgroundImage:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(/img/kurse/' +
                            kurs.course_timeline.course_bild +
                            ')',
                    }">
                    <span class="titel">{{ kurs.course_timeline.course_name }}</span>
                    <span class="datum">{{ kurs.date }}</span>
                    <span class="zeit"
                        >{{ TimestampToTime(kurs.course_timeline.timestamp) }} -
                        {{ TimestampToTime(kurs.course_timeline.timestamp_end) }}</span
                    >
                </router-link>
            </div>
            <router-link to="/kurse/" class="zum_gfplan" v-show="meinekurse"
                >Aktueller Group Fitness Plan<span data-v-1ba24358="" class="material-icons">navigate_next</span>
            </router-link>
        </div>

        <div class="friendship-feed">
            <div class="label friend-label">
                Freunde-Feed
                <router-link to="/friends" class="new-friends" v-show="friendrequests.length > 0"
                    >{{ friendrequests.length }} Freundschaftsanfrage/n</router-link
                >
            </div>
            <div class="friendship-no-signpus" v-show="feed.length == 0">
                Momentan nichts los.. Füge jetzt neue Freunde hinzu und bleibe auf dem Laufenden!
            </div>
            <div v-if="feed.length > 0" class="friendship-wrapper">
                <router-link
                    :event="fs.kurs_ts_end > time ? 'click' : ''"
                    :disabled="fs.kurs_ts_end < time"
                    :to="'/kurse/anmeldung/' + fs.cg_id"
                    class="friendship-signup"
                    v-for="(fs, i) of feed"
                    :key="i">
                    <div class="feed-post">
                        <div class="feed-post-info">{{ getPostTime(fs.anmelde_datum) }} | {{ fs.studio }}</div>
                        <div class="post-wrapper">
                            <div class="post-pfp">
                                <img
                                    class="profile-pic"
                                    v-if="fs.benutzer_pfp"
                                    :src="'/img/profilbilder' + fs.benutzer_pfp" />
                                <img v-else class="profile-pic" src="/img/profilbilder/uploads/1605087902456roodytest.png" />
                            </div>
                            <div class="post-info">
                                <div class="post-user">
                                    {{ fs.benutzer }}
                                </div>
                                <div class="post-text">Hat sich für folgenden Kurs angemeldet:</div>
                                <div class="post-kurs">{{ fs.kurs }} am {{ getPostTime(fs.kurs_ts) }}</div>
                            </div>
                        </div>
                        <span v-show="fs.kurs_ts_end > time" class="material-icons post-action-kurs">navigate_next</span>
                    </div>
                </router-link>
            </div>
            <router-link to="/friends/" class="friendship-action"
                >Zum Freunde-Feed<span class="material-icons">navigate_next</span>
            </router-link>
        </div>

        <div v-show="news.length && !affiliating" class="label">News</div>
        <div id="news-scroll" class="news" v-for="(n, ni) of news" :key="ni">
            <div v-show="n.foto" class="news-header">
                <img :src="'/img/news/' + n.foto" />
            </div>
            <div v-show="n.titel" class="news-titel">{{ n.titel }}</div>
            <div v-show="n.inhalt" class="news-inhalt">{{ n.inhalt }}</div>
        </div>
        <!----
    <div class="label">Trainingsbetrieb geht weiter</div>
    <div class="umfrage red">
      <div class="umfrage-titel">Liebe Mitglieder</div>
      <div class="umfrage-text">
        Aufgrund des bundesbehördlichen Entscheids werden wir ab morgen,
        Donnerstag 29. Oktober 2020, die Teilnehmerzahl in unseren Groupfitness
        Kursen auf 14 Teilnehmende begrenzen.
        <br /><br />
        Wir dürfen den Fitness Betrieb momentan aufrechterhalten. <br />
        Über weitere Massnahmen werden wir euch im Verlauf des morgigen
        Nachmittags auf unserer Website und unserer App informieren.
      </div>
    </div>
---->
        <div id="mitgliederaktion-label" v-show="campaigns.length" class="label">Mitgliederaktion</div>

        <div v-show="campaigns.length && !affiliating" class="campaigns" v-for="c of campaigns" :key="c._id">
            <div v-show="c.foto" class="c-header">
                <img :src="'/img/news/' + c.foto" />
            </div>
            <div v-show="c.titel" class="campaign-titel">{{ c.titel }}</div>
            <div v-show="c.untertitel" class="campaign-untertitel">
                {{ c.untertitel }}
            </div>
            <div v-show="c.beschreibung" class="campaign-beschreibung">
                {{ c.beschreibung }}
            </div>
            <div
                :class="{
                    'campaign-preis-wrapper': 1,
                    zus_info: !c.rabatt && c.zus_info,
                }">
                <div v-show="c.rabatt" class="campaign-mitgliedervorteil">
                    <span class="mitglieder-rabatt">CHF {{ formatPrice(c.rabatt) }} </span>
                    <span class="mitglieder-vorteil">Mitgliedervorteil</span>
                </div>
                <div v-show="c.zus_info && !c.rabatt" class="campaign-mitgliedervorteil zus_info">
                    <span class="mitglieder-rabatt">{{ formatPrice(c.zus_info) }} </span>
                </div>
                <div class="rabatt-wrapper">
                    <div v-show="c.rabatt" class="campaign-rabatt">statt CHF {{ formatPrice(c.preis) }}</div>
                    <div v-show="c.preis && c.rabatt" class="campaign-preis">CHF {{ formatPrice(c.preis - c.rabatt) }}</div>
                    <div v-show="c.preis && !c.rabatt" class="campaign-preis">CHF {{ formatPrice(c.preis) }}</div>
                    <div v-show="c.miete" class="campaign-miete">Miete CHF {{ formatPrice(c.miete) }}</div>
                    <div v-show="c.depot" class="campaign-depot">Depot CHF {{ formatPrice(c.depot) }}</div>
                </div>
            </div>
            <div class="soldout" v-show="getAnzahl(c._id, c.anzahl) < 1 && !user_campaign_reservations[c._id]">
                Ausverkauft
            </div>
            <div v-show="!user_campaign_reservations[c._id] && getAnzahl(c._id, c.anzahl) > 0" class="lager">
                Noch {{ getAnzahl(c._id, c.anzahl) }} Stück verfügbar!
            </div>
            <div v-show="user_campaign_reservations[c._id]" class="reservationstext">
                {{ c.reservationstext }}
            </div>
            <div
                v-if="!user_campaign_reservations[c._id]"
                v-show="getAnzahl(c._id, c.anzahl) > 0"
                @click.prevent="campaign_signup(c._id)"
                class="reservieren">
                Jetzt reservieren
            </div>
            <div v-else @click.prevent="campaign_signoff(c._id)" class="reservieren reserviert">Reservation aufheben</div>
        </div>
        <div class="label">Weiterempfehlung</div>
        <div @click.prevent="affiliateSharing" class="affiliate">
            Sichere Dir jetzt CHF 50.- bar für deine Weiterempfehlung!
            <div class="share-now"><span class="material-icons">share</span> Jetzt teilen!</div>
        </div>
        <div id="sharemenu">
            <div class="affiliate-header">CHF 50.- IN BAR FÜR DEINE EMPFEHLUNG</div>
            <div class="affiliate-roody-wrapper">
                <img src="/img/affiliate_1.png" class="affiliate-roody" />
            </div>
            <div class="label">Beschreibung</div>
            <div v-if="iswellcomefit == 1" class="affiliate-text">
                <span class="heading-affiliate">TEILE DIESE PROMOTION JETZT MIT DEINEN FREUNDEN</span>
                Schicke diese Promotion an Deine Freunde, Familie und Bekannten. Trainiert gemeinsam im well come FIT in
                Frauenfeld, Netstal, Bülach, Dietlikon oder St Gallen (exkl. SPA St. Gallen Einstein).
                <br />
                <br />Deine Freunde erhalten die Möglichkeit eine Woche kostenlos zu trainieren. Sollte sich einer Deiner
                Kontakte nach seinem einwöchigen Gratistraining für eine Mitgliedschaft entscheiden, erhältst Du CHF 50.- bar
                auf die Hand.
            </div>
            <div v-if="iswellcomefit == 2" class="affiliate-text">
                <span class="heading-affiliate">TEILE DIESE PROMOTION JETZT MIT DEINEN FREUNDEN</span>
                Schicke diese Promotion an Deine Freunde, Familie und Bekannten. Trainiert gemeinsam an den zwei Standorten
                der winti FIT AG in Winterthur und Pfungen sowie an den well come FIT in Frauenfeld, Netstal, Bülach,
                Dietlikon oder St Gallen (exkl. SPA St. Gallen Einstein).
                <br />
                <br />Deine Freunde erhalten die Möglichkeit eine Woche kostenlos zu trainieren. Sollte sich einer Deiner
                Kontakte nach seinem einwöchigen Gratistraining für eine Mitgliedschaft entscheiden, erhältst Du CHF 50.- bar
                auf die Hand.
            </div>
            <div v-if="iswellcomefit == 3" class="affiliate-text">
                <span class="heading-affiliate">TEILE DIESE PROMOTION JETZT MIT DEINEN FREUNDEN</span>
                Schicke diese Promotion an Deine Freunde, Familie und Bekannten. Trainiert gemeinsam im Fitness Zentrum
                Wetzikon.
                <br />
                <br />Deine Freunde erhalten die Möglichkeit eine Woche kostenlos zu trainieren. Sollte sich einer Deiner
                Kontakte nach seinem einwöchigen Gratistraining für eine Mitgliedschaft entscheiden, erhältst Du CHF 50.- bar
                auf die Hand.
            </div>
            <div class="label">Bedingungen</div>
            <div class="affiliate-text">
                Die CHF 50.- werden nur für Neukunden und bei einem Aboabschluss nach Bezahlung ausbezahlt. CHF 50.- für ein
                Jahresabo, CHF 25 für ein Halbjahresabo.
                <br />
                Rabatte können nicht kumuliert werden. Gültig bei Aboabschluss bis 31.12.{{ year }}.
            </div>
            <div class="agb">
                <input @click="toggleAgb()" type="checkbox" />
                <div class="agb-text-wrapper">Bedingungen akzeptieren und den Link für die Weiterempfehlung erhalten</div>
            </div>
            <div v-show="agbchecker" class="aff-link">
                Dein persönlicher Weiterempfehlungs-Link lautet:
                <br />
                <input id="myreflink" class="affiliate-gen-link" v-model="inputlink" />
                <div v-if="!copied" class="affiliate-gen-link-copy" @click.prevent="copylink">Link kopieren!</div>
                <div v-else class="affiliate-gen-link-copy" @click.prevent="copylink">Link wurde kopiert!</div>
                <div class="share-it">
                    Teile deinen Link jetzt über:
                    <br />
                    <div class="link-wrapper">
                        <a target="__blank" :href="whatsapplink">
                            <img class="share-icon" src="/img/social/whatsapp.svg" />
                        </a>
                        <a target="__blank" :href="facebooklink">
                            <img class="share-icon" src="/img/social/facebook.svg" />
                        </a>
                        <a target="__blank" :href="linkedinlink">
                            <img class="share-icon" src="/img/social/linkedin.svg" />
                        </a>
                        <a target="__blank" :href="telegramlink">
                            <img class="share-icon" src="/img/social/telegram.svg" />
                        </a>
                        <a target="__blank" :href="maillink">
                            <img class="share-icon" src="/img/social/gmail.svg" />
                        </a>
                    </div>
                </div>
            </div>
            <div @click.prevent="cancelAffiliate" class="schliessen">Zurück</div>
        </div>
        <!----
    <div id="umfrage-label" class="label">Aktualisiertes Schutzkonzept</div>
   
    <div class="umfrage">
      <div class="umfrage-titel">
        Deine <span class="umfrage-yellow">Sicherheit</span> hat Vorrang
      </div>
      <div class="umfrage-text">
  Wir möchten, dass Du Dich bei uns sicher fühlst. Gibt es für Dich noch
      weitere Schutz- und Hygienemassnahmen, die Du Dir von uns wünschst?
       <br /><br />Dann teile uns <strong>hier</strong> Deinen Wunsch mit.<br />
      </div>
    
     
      <img class="umfrage-pfeil" src="/img/icons/pfeil_survey.png">
      <div @click="showSurvey" class="umfrage-senden animated infinite pulse">
        Dein Sicherheitswunsch
      </div>
      <div @click="showSurvey" class="survey-side">Dein Sicherheitswunsch</div>
    </div>
---------------------
    <div class="umfrage" v-show="!affiliating">
      <div class="umfrage-header">
        <img src="/img/icons/header_umfrage.jpeg" alt="" />
      </div>
      <div class="umfrage-titel">
        Aktualisiertes <span class="umfrage-yellow">Schutzkonzept</span> ab
        Montag, 26. Oktober 2020
      </div>
      <div class="umfrage-text">
        Vielen Dank für eure Teilnahme an unserer Umfrage zum Thema "Dein
        Sicherheitswunsch". Wir haben eure Wünsche sofort analysiert und diese
        mit dem aktualisierten Sicherheitskonzept unseres Verbandes SFGV
        abgeglichen. <br /><br />
        Ab Montag, 26. Oktober 2020 werden wir, wie bereits in Phase 1 nach dem
        Lockdown, mit unserem Groupfitness-Konzept der verschärften Situation in
        der Schweiz Rechnung tragen. Wir möchten euch bitten eure Schutzmasken
        auf dem Weg zu den Garderoben, in den Garderoben, auf dem Weg zur
        Trainingsfläche und zu den Groupfitness-Räumen, sowie auf den Korridoren
        und in den Treppenhäusern zu tragen. Bitte tragt die Masken auch in den
        Groupfitness-Räumen, wenn ihr die Trainingsgeräte holt und wieder
        versorgt. Während des Trainings könnt ihr die Masken absetzen.
        <br />Selbstverständlich informieren wir euch zeitnah über die
        Auswirkungen bundesbehördlicher Entscheide auf unseren Fitness-Betrieb.
        Wir wünschen euch ein phantastisches Training & ganz viel Gesundheit.<br /><br />
        Euer <span v-if="iswellcomefit">well come FIT </span
        ><span v-else>winti FIT</span> Team
      </div>

      <img class="umfrage-pfeil" src="/img/icons/pfeil_survey.png" />
      <div @click="showSurvey" class="umfrage-senden animated infinite pulse">
        noch Fragen?
      </div>
      <div @click="showSurvey" class="survey-side">noch Fragen?</div>
    </div>
     ---->

        <div v-show="!affiliating" class="yt-wrapper">
            <iframe
                id="roody-home-video"
                src="https://www.youtube-nocookie.com/embed/qS2vOLCeoGM?rel=0&controls=0&loop=1&playsinline=1"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
        <div @click="scrollPageDown()" v-if="scrolldown && !scrolldownhidden" class="scroll-down animated infinite bounce">
            <img src="/img/downbelow.svg" />
        </div>
        <div @click="affiliateSharing" class="affiliate-floating">
            <!--Freunde einladen -->
            Erhalte CHF 50.-
        </div>
        <anamnesebogen ref="anamnesebogen"></anamnesebogen>
        <survey ref="survey"></survey>
    </div>
</template>

<script>
// @ is an alias to /src
import api from '../api'
import $ from 'jquery'
import Survey from '../components/Survey'
import Anamnesebogen from '../components/Anamnesebogen'

export default {
    name: 'Home',
    components: { Survey, Anamnesebogen },
    beforeDestroy() {
        console.log('interval cleared')
        clearInterval(this.x)
    },
    data() {
        return {
            affiliating: false,
            submitStatus: 0,
            umfragetext: '',
            loading: true,
            meinekurse: false,
            signups: [],
            scrolldown: false,
            course_timelines: [],
            course_timelines_x: [],
            studio_info: null,
            scrolldownhidden: false,
            standort: '',
            feed: [],
            telefon: '',
            x: null,
            agbchecker: false,
            hostname: '',
            email: '',
            whatsapplink: '',
            facebooklink: '',
            telegramlink: '',
            linkedinlink: '',
            maillink: '',
            inputlink: '',
            copied: false,
            disable_campaign: false,
            news: {},
            campaigns: {},
            year: '',
            iswellcomefit: true,
            user_campaign_reservations: [],
            friendship_feed: [],
            time: null,
            friendrequests: [],
            rollen: {
                mitarbeiter: false,
                admin: false,
                leitung: false,
            },
        }
    },
    metaInfo() {
        return {
            title: 'roody',
            meta: [
                {
                    name: 'description',
                    content:
                        'Melde dich für Kurse an, verwalte deine Trainingspläne und noch vieles mehr mit unserer neuen App roody',
                },
            ],
        }
    },
    updated() {
        if (this.agbchecker) {
            this.scrolltolink()
        }
        if (window.scrollY == 0 && (this.news.length > 0 || this.campaigns.length > 0)) {
            this.scrolldown = true
        } else {
            this.scrolldown = false
        }
    },
    created() {
        api.getUserRolle().then((data) => {
            this.rollen = data
        })
        this.time = Math.round(new Date() / 1000)
        api.getFriendsFeed()
            .then((data) => {
                this.feed = data
            })
            .catch((error) => {
                console.log('fehler')
            })

        api.getFriendrequests().then((data) => {
            this.friendrequests = data
        })
        api.getUserid()
            .then((data) => {
                if (!JSON.parse(localStorage.getItem('user'))._id) {
                    let user_x = JSON.parse(localStorage.getItem('user'))
                    user_x._id = data._id
                    localStorage.setItem('user', JSON.stringify(user_x))
                    location.reload()
                }
            })
            .catch((error) => {
                console.log(error)
            })
        api.getVersion().then((data) => {
            if (localStorage.getItem('version')) {
                let storage_code = JSON.parse(localStorage.getItem('studio')).code
                if (storage_code !== data.studio_code) {
                    localStorage.removeItem('studio')
                    location.reload(true)
                }
                if (localStorage.getItem('version') !== data.version.toString()) {
                    localStorage.setItem('version', data.version.toString())

                    let found = false
                    api.getStudios()
                        .then((studios) => {
                            for (let studio of studios) {
                                if (studio.code == storage_code) {
                                    found = true
                                }
                            }

                            if (!found) {
                                localStorage.removeItem('studio')
                            }

                            location.reload(true)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            } else {
                localStorage.setItem('version', data.version.toString())
            }
        })
        this.year = new Date(new Date()).getFullYear()
        this.email = JSON.parse(localStorage.getItem('user')).email
        this.hostname = location.host
        let text = ''
        if (location.host.includes('wellcomefit')) {
            this.iswellcomefit = 1
            text =
                'Hey%0AIch%20war%20gerade%20im%20Training%20im%20well%20come%20FIT.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20%0Adann%20klicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20f%C3%BCr%20eine%20Schnupper%20Woche%20an.%20'
        } else if (location.host.includes('wintifit')) {
            // wellcomefit
            this.iswellcomefit = 2
            // wintifit
            text =
                'Hey%0AIch%20war%20gerade%20im%20Training%20im%20winti%20FIT.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20dann%20%0Aklicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20f%C3%BCr%20eine%20Schnupper%20Woche%20an.%20'
        } else if (location.host.includes('fzw')) {
            this.iswellcomefit = 3
            // wintifit
            text =
                'Hey%0AIch%20war%20gerade%20im%20Training%20im%20Fitness%20Zenter%20Wetzikon.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20dann%20%0Aklicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20f%C3%BCr%20eine%20Schnupper%20Woche%20an.%20'
        }
        this.inputlink = 'https://' + this.hostname + '/schnupperwoche?' + this.email
        let sharelink = 'https%3A%2F%2F' + this.hostname + '%2Fschnupperwoche%3F' + this.email.replace('@', '%40')

        this.whatsapplink = 'https://api.whatsapp.com/send?text=' + text + ' ' + sharelink
        this.linkedinlink = 'https://www.linkedin.com/shareArticle?url=' + sharelink
        this.facebooklink = 'https://www.facebook.com/sharer/sharer.php?u=' + sharelink
        this.maillink = 'mailto:?subject=&body=' + text + '%20' + sharelink
        this.telegramlink = 'https://t.me/share/url?url=' + sharelink + '&text=' + text
        if (localStorage.getItem('studio')) {
            this.studio_info = JSON.parse(localStorage.getItem('studio'))
            this.standort = this.studio_info.name
            this.standort_bild = this.studio_info.logo

            this.telefon = 'mailto:' + this.studio_info.email

            api.getNews(this.studio_info._id)
                .then((data) => {
                    this.news = data

                    if (data.length) {
                        this.scrolldown = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

            this.loadCampaigns()

            this.loadNextKurse()
            this.x = setInterval(() => {
                this.loadCampaigns()
                this.loadFriendrequests()
            }, 5000)
        }
    },

    methods: {
        toggleAgb() {
            if (this.agbchecker) {
                this.agbchecker = false
            } else {
                this.agbchecker = true
            }
        },
        loadFriendrequests() {
            api.getFriendrequests().then((data) => {
                this.friendrequests = data
            })
        },
        getPostTime(ts) {
            let datefromstamp = new Date(ts * 1000)
            let hours = datefromstamp.getHours()
            let minutes = datefromstamp.getMinutes()
            let date =
                datefromstamp.getDate() + '.' + (datefromstamp.getMonth() + 1) + '.' + datefromstamp.getFullYear() + ' '

            if (hours < 10) {
                date += '0'
            }
            date += hours + ':'
            if (minutes < 10) {
                minutes += '0'
            }
            date += minutes + ' Uhr'

            return date
        },
        showSurvey() {
            this.$refs['survey'].show()
        },
        showAnamesebogen() {
            this.$refs['anamnesebogen'].show()
        },
        submitUmfrage() {
            api.submitUmfrage({
                text: this.umfragetext,
                user: JSON.parse(localStorage.getItem('user'))._id,
                studio_id: this.studio_info._id,
            })
                .then((data) => {
                    this.submitStatus = 1
                })
                .catch((error) => {
                    this.submitStatus = 2
                })
        },
        getAnzahl(campaignid, anzahl) {
            let counter = 0
            for (let cr of this.campaigns_reservations) {
                if (cr.campaign_id == campaignid) {
                    counter++
                }
            }

            return anzahl - counter
        },
        formatPrice(price) {
            let pricestring = price + ''
            if (pricestring.split('.')[1] < 10) {
                pricestring += '0'
            }
            return pricestring
        },
        campaign_signoff(id) {
            if (!this.disable_campaign) {
                this.disable_campaign = true
                let userid = JSON.parse(localStorage.getItem('user'))._id
                api.signoffCampaign(id, userid).then((data) => {
                    if (data.status == 1) {
                        this.disable_campaign = false
                        this.loadCampaigns()
                    } else {
                        if (data.status == 3) {
                            this.disable_campaign = false
                            alert('Leider ist unser Vorrat aufgebraucht. Viel Erfolg bei der nächsten Aktion.')
                            this.loadCampaigns()
                        } else {
                            this.disable_campaign = false
                            alert('es gab einen Fehler')
                        }
                    }
                })
            }
        },
        campaign_signup(id) {
            if (!this.disable_campaign) {
                this.disable_campaign = true

                let userid = JSON.parse(localStorage.getItem('user'))._id
                let studio_id = JSON.parse(localStorage.getItem('studio'))._id
                api.signupCampaign(id, userid, studio_id).then((data) => {
                    console.log('data', data)
                    if (data.status == 1) {
                        this.disable_campaign = false
                        this.loadCampaigns()
                    } else {
                        if (data.status == 3) {
                            this.disable_campaign = false
                            alert('Leider ist unser Vorrat aufgebraucht. Viel Erfolg bei der nächsten Aktion.')
                            this.loadCampaigns()
                        } else {
                            this.disable_campaign = false
                            alert('es gab einen Fehler')
                        }
                    }
                })
            }
        },
        /*
    loadCampaigns() {
      api
        .getCampaign(this.studio_info._id)
        .then((data) => {
          this.campaigns_reservations = [];
          this.campaigns = [];
          this.user_campaign_reservations = [];
          this.campaigns = data.campaigns;
          this.campaigns_reservations = data.campaign_reservations;

          let userid = JSON.parse(localStorage.getItem("user"))._id;

          for (let cr of data.campaign_reservations) {
            if (cr.ben_id == userid) {
              this.user_campaign_reservations[cr.campaign_id] = true;
            }
          }

          if (data.campaigns.length > 0) {
            this.scrolldown = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },*/
        scrollPageDown() {
            if (document.getElementById('news-scroll')) {
                document.getElementById('news-scroll').scrollIntoView({ behavior: 'smooth', block: 'start' })
            } else {
                document.getElementById('mitgliederaktion-label').scrollIntoView({ behavior: 'smooth', block: 'start' })
            }

            this.scrolldownhidden = true
        },
        scrolltolink() {
            document.getElementById('myreflink').scrollIntoView()
        },
        copylink() {
            var copyText = document.getElementById('myreflink')

            /* Select the text field */
            copyText.select()
            copyText.setSelectionRange(0, 99999) /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand('copy')

            this.copied = true
        },
        cancelAffiliate() {
            this.affiliating = false
            $('#sharemenu').hide()
        },
        affiliateSharing() {
            this.copied = false
            this.affiliating = true
            $('#sharemenu').show()
            window.scroll(0, 0)
        },
        TimestampToTime(timestamp) {
            timestamp = parseInt(timestamp)

            let hours = new Date(timestamp * 1000).getHours()

            let minutes = new Date(timestamp * 1000).getMinutes()
            if (minutes < 10) {
                minutes = '0' + minutes
            }
            let value = hours + ':' + minutes
            return value
        },
        formatNextCourses() {
            let course_formatted_temp = []
            api.loadSignupsByUser(JSON.parse(localStorage.getItem('user'))._id)
                .then((data) => {
                    let signups = []
                    let user_id = JSON.parse(localStorage.getItem('user'))._id
                    for (let signup of data) {
                        signups[signup.course_generate_id] = true
                    }
                    this.course_timelines_x.forEach((course_timeline) => {
                        if (signups[course_timeline._id] || course_timeline.instructor_id == user_id) {
                            var date = new Date(course_timeline.timestamp * 1000).toLocaleDateString()
                            course_formatted_temp.push({
                                course_timeline,
                                date: date,
                            })
                        }
                    })

                    if (course_formatted_temp.length == 0) {
                        this.meinekurse = false
                        this.course_timelines_x.forEach((course_timeline) => {
                            var date = new Date(course_timeline.timestamp * 1000).toLocaleDateString()
                            course_formatted_temp.push({
                                course_timeline,
                                date: date,
                            })
                        })
                    } else {
                        this.meinekurse = true
                    }

                    this.course_timelines = course_formatted_temp
                    this.loading = false
                })
                .catch((error) => {})
        },
        loadNextKurse() {
            api.getNextKursetimelinesGenerated(this.studio_info._id)
                .then((data) => {
                    this.course_timelines_x = data.course_generates
                    this.formatNextCourses()
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.mgs {
    .wms:first-of-type {
        margin-top: 10px;
    }
}
.wmsg {
    display: flex;
    margin-bottom: 10px;
    font-weight: bold;

    .wmsgl {
        margin: auto;
    }
    .wmsgr {
        padding-left: 19px;
        text-align: initial;
        font-size: 17px;
    }
}
.mgs {
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    z-index: 999;
    cursor: pointer;
    background-color: #fff;
    > div {
        max-width: 500px;
        text-align: justify;
        margin: auto;
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 500;
        color: #2f2f2f;
        padding-bottom: 10px;

        div.wrapper {
            padding: 15px;
            max-width: 360px;
            margin: auto;
            font-weight: 400;
        }

        img {
            height: 200px;
            -o-object-fit: cover;
            object-fit: cover;
            width: 380px;
            margin: 0px auto;
            display: flex;
            max-height: 40vh;
        }
        span {
            display: block;
            /* text-align: right; */
            padding-bottom: 10px;
            font-weight: bold !important;
            font-size: 22px;
            text-align: center;
            font-weight: 100;
        }
        .msg-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: var(--v-primary);
            color: #fff;
            height: 50px;
            line-height: 50px;
            margin: 20px auto 10px auto;
            span {
                line-height: 50px;
                margin-left: 10px;
            }
        }
    }
}
.videoarchiv {
    width: 100%;
    background-color: var(--v-primary);
    color: #fff;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 20px 15px;
    justify-content: center;
    text-decoration: none;

    .material-icons {
        margin-left: 10px;
    }
}
.weihnachten-text-wrapper {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}
.weihnachten-mitarbeiter {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    iframe {
        margin: 0px auto 0px auto;
        max-width: 100vw;
    }
}
.campaign-beschreibung,
.reservationstext {
    white-space: pre-wrap;
}
.campaign-miete,
.campaign-depot {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
}
.anamnesebogen {
    max-width: 200px;
    padding: 10px 15px;
    background-color: var(--v-primary);
    color: white;
    margin: 20px auto;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.friend-label {
    position: relative;
}
.new-friends {
    background-color: red;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    height: 25px;
    line-height: 18px;
    top: calc(50% - 12px);
    color: white;
    text-decoration: none;
}
.post-action-kurs {
    position: absolute;
    top: calc(50% - 12px);
    right: 15px;
}
.feed-post {
    border-bottom: 1px solid #707070;
    padding: 10px 15px;
    position: relative;
}
.friendship-wrapper {
    max-width: 400px;
    max-height: 170px;
    overflow: auto;
    margin: auto;
    * {
        color: #000000de;
        &:hover {
            text-decoration: none;
        }
    }

    .feed-post-info {
        font-size: 11px;
        font-weight: bold;
        margin-bottom: 5px;
    }
}
.post-wrapper {
    display: flex;
    flex-wrap: nowrap;

    .post-pfp {
        margin-right: 10px;
        display: flex;
        justify-content: center;
    }

    .post-info {
        padding-right: 50px;
        .post-user,
        .post-kurs {
            font-size: 13px;
            font-weight: bold;
        }

        .post-text {
            font-size: 13px;
        }
    }
}

.profile-pic {
    display: flex;
    height: 50px;
    width: 50px;
    object-fit: cover;
    background-color: grey;
    border-radius: 50%;
    border: 2px solid #4a4a4a;
    margin: auto;
}
.friendship-action {
    background-color: var(--v-primary);
    color: white;
    display: flex;
    text-decoration: none;
    padding: 10px;
}
.friendship-no-signpus {
    padding: 10px;
}
.affiliate-button-head {
    line-height: 30px;
    display: flex;
    text-transform: uppercase;
    /* FONT-WEIGHT: 500; */
    font-size: 14px;
    background-color: #72c753;
    padding: 7px 15px;
    font-weight: bold;
    border-radius: 3px;
    margin: auto;
    border: 2px solid white;
    @media screen and (max-width: 370px) {
        padding: 2px 5px;
        font-size: 11px;
    }

    span {
        line-height: 28px;
        margin-right: 5px;
    }
}

.affiliate-floating {
    position: fixed;
    bottom: 80px;
    right: -49px;
    font-size: 14px;
    color: white;
    /* border: 3px solid #545655; */
    background-color: var(--v-primary);
    text-align: center;
    transform: rotate(-90deg);
    z-index: 9999;
    width: 127px;
    padding: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: none;
}
.survey-side {
    position: absolute;
    top: 41%;
    right: -70px;
    font-weight: 500;
    width: 180px;
    font-size: 15px;
    transform: rotate(-90deg);
    border-top: 2px solid #c93131;
    border-right: 2px solid #c93131;
    border-left: 2px solid #c93131;
    background-color: #fff540;
    color: #c93131;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.umfrage {
    width: 100%;
    margin: auto;

    text-align: center;
    background-color: #f49723;
    position: relative;
    border: 10px solid pink;
    border-image: repeating-linear-gradient(-55deg, #000, #000 20px, #fff540 20px, #fff540 40px) 10;

    &.red {
        background-color: #ff1517;
        color: white;
        padding: 10px;
    }

    &-text {
        max-width: 600px;
        margin: auto;
        padding: 10px 15px;
    }

    &-pfeil {
        height: 40px;
        margin-top: 10px;
    }
    &-yellow {
        color: #fff540;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-shadow: -1px -1px 0 #3c3c3c, -1px 0px 0 #3c3c3c, -1px 1px 0 #3c3c3c, 0px -1px 0 #3c3c3c, 0px 0px 0 #3c3c3c,
            0px 1px 0 #3c3c3c, 1px -1px 0 #3c3c3c, 1px 0px 0 #3c3c3c, 1px 1px 0 #3c3c3c;
    }
    &-header {
        img {
            max-width: 600px;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    &-titel {
        text-align: center;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 18px;
        text-transform: uppercase;
    }

    &-senden {
        width: 80%;
        max-width: 400px;
        height: 50px;
        line-height: 45px;
        text-align: center;
        margin: 10px auto 10px auto;
        border: 2px solid #c93131;
        font-weight: 500;

        background-color: #fff540;
        color: #c93131;
        text-transform: uppercase;
        margin-bottom: 10px;
        border-radius: 5px;
        -webkit-animation-duration: 4s !important;
        animation-duration: 4s !important;
    }
}

.loading {
    img {
        width: 40px;
        height: 40px;
    }

    padding: 20px;
    font-weight: 400;
    font-size: 18px;
    background-color: white;
}
/*
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
    }
  }
  @return $shadow;
}
*/

.zum_gfplan {
    display: none;
    justify-content: center;
    padding: 10px 0px;
    color: white;
    background-color: var(--v-primary);
    font-weight: 300;
}
.campaigns {
    max-width: 600px;
    margin: auto;

    .soldout {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 20px;
    }

    .lager {
        text-align: center;
    }

    .reservationstext {
        text-align: center;
        margin: 60px 0px 20px 0px;
    }

    .reservieren {
        width: 90vw;
        margin: 10px auto 30px auto;
        text-align: center;
        background-color: var(--v-primary);
        max-width: 400px;
        border-radius: 5px;
        line-height: 60px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;

        &.reserviert {
            background-color: #d75e5d;
        }
    }

    > div {
        padding: 0px 10px;
    }

    .campaign {
        &-titel {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            padding: 10px 10px 0px 10px;
        }
        &-untertitel {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            padding: 5px 10px 10px 10px;
        }
        &-beschreibung {
            text-align: center;
        }
        &-preis-wrapper {
            position: relative;
            margin: 20px 0px 40px 0px;
            &.zus_info {
                margin: 0px 0px 20px 0px;
            }
            .campaign {
                &-mitgliedervorteil:not(.zus_info) {
                    position: absolute;
                }
                &-rabatt {
                    text-align: right;
                    font-weight: bold;
                }
                &-preis {
                    font-size: 30px;
                    text-align: right;
                    line-height: 28px;
                    font-weight: bold;
                    color: var(--v-primary);
                }
                &-mitgliedervorteil {
                    text-align: center;
                    transform: rotate(-10deg);
                    font-weight: bold;

                    padding: 0px 10px;
                    border: 3px solid #c93131;
                    border-radius: 15px;
                    background-color: #fff540;
                    color: #c93131;
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: column;

                    &.zus_info {
                        transform: rotate(0deg);

                        margin: 20px auto auto auto;
                        width: 250px;
                    }
                    @media only screen and (min-width: 400px) {
                        left: 5%;
                    }
                    .mitglieder {
                        &-rabatt {
                            font-size: 30px;
                            line-height: 34px;
                            margin-top: 3px;
                        }
                        &-vorteil {
                            line-height: 18px;
                            margin-bottom: 9px;
                            font-size: 16px;
                            @media only screen and (max-width: 350px) {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    .c-header {
        padding: 0px;
        width: 100%;
        img {
            width: 100%;
            max-height: 200px;
            object-fit: contain;
        }
    }
}
.home {
    max-width: 100vw;
    overflow: hidden;
}
.yt-wrapper {
    width: 100vw;
    overflow: hidden;
    height: 400px;
    display: flex;
    position: relative;
    justify-content: center;
    #roody-home-video {
        width: 600px;
        margin: 0px auto;
        position: absolute;
        display: block;
        height: 600px;
        top: -100px;
    }
}

.scroll-down {
    position: fixed;
    top: 90%;
    width: 35px;
    animation-duration: 3s !important;
    right: calc(50% - 17.5px);
    height: 35px;

    img {
        background-color: var(--v-primary);
        padding: 13px 10px;
        border-radius: 200px;
    }
}
.news {
    max-width: 600px;
    margin: auto;
    .news-header {
        max-height: 450px;
        width: 100%;
        text-align: center;

        img {
            width: 100%;
            max-height: 450px;
            object-fit: contain;
        }
    }
    .news-titel {
        padding: 10px 15px 5px 15px;
        font-size: 18px;
        font-weight: bold;
    }
    .news-inhalt {
        white-space: pre-wrap;
        padding: 10px 15px;
    }
}
.heading-affiliate {
    width: 100%;
    display: flex;
    color: #8ebf24;
    font-size: 18px;
    /* font-weight: bold; */
    /* text-align: center; */
    margin-bottom: 10px;
}
.affiliate-gen-link {
    width: 100%;
    padding: 5px 10px;
    margin-top: 20px;

    display: flex;
    border: 1px solid var(--v-primary);
    text-align: center;
}
.affiliate-gen-link-copy {
    margin-bottom: 20px;
    background-color: var(--v-primary);
    color: white;
    text-align: center;
    padding: 10px 0px;
}
.aff-link {
    margin: 20px 10px 40px 10px;
}
.link-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    .share-icon {
        width: 60px;
        height: 60px;
        padding: 7px;
    }
}
.agb-toggler {
    font-weight: 500;
    text-decoration: underline;
}
.affiliate-header {
    font-weight: 500;
    font-size: 23px;
    text-align: center;
    margin-top: 20px;
    color: var(--v-primary);
}
.affiliate-roody-wrapper {
    width: 100%;
    display: flex;

    margin-top: 20px;

    .affiliate-roody {
        max-height: 340px;
        max-width: 100vw;
        margin: 0px auto;
        display: block;
        text-align: center;
    }
}

.affiliate-text {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.agb-text-wrapper {
    margin-right: 10px;
    margin-left: 12px;
    max-width: 240px;
}
.agb {
    width: 100%;
    background-color: var(--v-primary);
    color: white;
    padding: 17px;
    justify-content: center;
    display: flex;
    input {
        padding: 5px;

        width: 30px;
        height: 30px;
        margin: auto 10px;
        margin-right: 10px;
    }
    .agb-toggle {
    }
}
#sharemenu {
    display: none;
    width: 100vw;
    min-height: 100%;
    position: absolute;
    top: 0;
    z-index: 99999;
    background-color: white;
    .schliessen {
        background-color: #443838;
        color: white;
        margin: 20px auto;
        padding: 10px 15px;
        width: 90%;
        text-align: center;
        border-radius: 45px;
    }
}
.next-kurse-wrapper {
    display: flex;
    overflow-x: scroll;
    a {
        text-decoration: none;
    }
    .next-kurs {
        background-size: 103% auto;
        background-position: center;
        color: white;
        display: flex;
        flex-direction: column;
        height: 100px;
        min-width: 170px;
        max-width: 170px;
        margin-right: 1px;
        text-align: center;
        padding-top: 10px;

        text-shadow: #313131 1px -1px 1px, #313131 -1px 0px 3px, #313131 0px 0px 5px, #313131 2px 2px 3px,
            #313131 2px 2px 3px, #313131 2px 2px 3px;

        -webkit-font-smoothing: antialiased;

        .titel {
            font-size: 16px;
            font-weight: 500;
        }
        .datum {
            font-weight: 400;
            font-size: 14px;
        }
        .zeit {
            font-weight: 400;
            font-size: 14px;
        }
    }
}

.label {
    height: 40px;
    line-height: 40px;
    font-weight: 300;
    padding-left: 15px;
    background-color: #545655;
    color: white;
}
.informationen-location {
    display: flex;
    justify-content: space-between;
    color: white;
    a {
        color: white;
    }
    background-color: var(--v-primary);
    padding: 10px;

    .informationen-wrapper {
        display: flex;
        flex-direction: column;

        img {
            margin: auto;
            width: 180px;
        }

        .contact-studio {
            display: flex;
            justify-content: flex-end;

            span {
                margin: 5px;
                font-size: 37px;
            }
        }

        .standort {
            display: none;
            text-align: left;
            font-weight: 300;
        }
    }
}
.affiliate {
    background-color: var(--v-primary);
    color: white;
    font-weight: 300;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    text-align: center;

    .share-now {
        text-align: center;
        position: relative;
        span {
            position: absolute;
            top: 5px;
            left: 60px;
        }
    }
}
</style>
