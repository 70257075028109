<template xmlns="">
    <div>
        <div
            :style="showWeekly ? 'margin-bottom:15px;' : 'max-width:395px;'"
            class="d-flex mx-auto mt-2"
            :class="showWeekly ? 'justify-start' : 'justify-end'"
        >
            <div text @click="showWeekly = !showWeekly">
                <span style="color: #181818; font-size: 0.9rem" class="font-weight-bold" v-if="!showWeekly">
                    zur Wochenansicht
                    <v-icon class="pl-2" color="#585858">mdi-transfer-right</v-icon>
                </span>
                <span style="color: #181818; font-size: 0.9rem" class="font-weight-bold" v-else>
                    <v-icon class="pr-2" color="#585858">mdi-transfer-left</v-icon>
                    zur Tagesansicht
                </span>
            </div>
        </div>

        <v-row v-if="schwandenStudio || niederurnenStudio">
            <v-col cols="12" class="d-flex justify-center mt-5 pb-0">
                <v-alert class="mb-0" style="max-width: 400px" type="info">
                    <strong>Groupfitness im well come FIT Netstal</strong>
                </v-alert>
            </v-col>
        </v-row>

        <course-list-weekly :key="courseListWeeklyKey" v-if="showWeekly"></course-list-weekly>
        <div
            v-else
            style="padding-bottom: 150px"
            :class="{
                courseList: 1,
                modalOpen: $route.params.id,
            }"
        >
            <div
                class="courseList__edit"
                no-gutters
                v-if="
                    !showDatePicker &&
                    userService.hasRight('gf_c_edit', 'gf_c_delete', 'gf_c_plan', 'gf_c_create', 'gf_cp_edit') &&
                    !livestreamStudio &&
                    !schwandenStudio &&
                    !niederurnenStudio
                "
            >
                <v-btn x-large @click="$router.push('/kurslisten')" class="mx-auto primary elevation-6">
                    <v-icon class="text-white">mdi-calendar-edit</v-icon>
                    <span class="pr-1">Bearbeiten</span>
                </v-btn>
            </div>

            <v-row
                data-v-step="4"
                no-gutters
                v-if="
                    userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    ) &&
                    !alibeyStudio &&
                    !schwandenStudio &&
                    !niederurnenStudio
                "
                class="courseList__filter justify-center"
            >
                <v-col class="d-flex justify-center" v-if="!showDatePicker" cols="4">
                    <v-btn color="primary" class="my-auto mr-auto" @click="swipe('Right')">
                        <v-icon>mdi-calendar-arrow-left</v-icon>
                    </v-btn>
                </v-col>

                <v-col class="d-flex justify-center" cols="4">
                    <v-btn
                        :class="{ 'ma-auto': !showDatePicker, 'mt-10': showDatePicker, 'mx-auto': showDatePicker }"
                        @click="showDatePicker = !showDatePicker"
                    >
                        <strong v-if="!showDatePicker">{{ formatDate(datePicker) }}</strong>
                        <span v-else>Schliessen</span>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-center" v-if="!showDatePicker" cols="4">
                    <v-btn color="primary" class="my-auto ml-auto" @click="swipe('Left')">
                        <v-icon>mdi-calendar-arrow-right</v-icon>
                    </v-btn>
                </v-col>
                <v-col v-if="showDatePicker" class="d-flex justify-center mt-5" cols="12">
                    <v-date-picker color="primary lighten-1" locale="de-CH" v-model="datePicker"></v-date-picker>
                </v-col>
            </v-row>

            <v-row
                v-dragscroll="{ active: !$vuetify.breakpoint.mobile }"
                data-v-step="4"
                v-else
                class="mt-5 courseList__calendar flex-nowrap px-3 mb-5"
            >
                <v-col v-for="(date, y) in calendar" :key="y">
                    <v-skeleton-loader
                        v-if="loadingCalendar"
                        class="mx-auto mt-8"
                        max-width="30"
                        max-height="30"
                        type="card"
                    ></v-skeleton-loader>
                    <div v-else>
                        <v-row :class="{ 'courseList__calendar-date': 1, 'justify-center': 1 }">
                            <v-btn
                                @click="setCalendarDate(y) && fetchCourseList()"
                                icon
                                :class="{ 'courseList__calendar-date-btn': 1, active: date.active }"
                            >
                                <span>{{ date.date }}</span>
                            </v-btn>
                        </v-row>
                        <v-row class="justify-center">
                            <span class="courseList__calendar-short mt-1">{{ date.short }}</span>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <div class="ls">
                <livestream-information></livestream-information>
            </div>
            <div v-if="!loadingKinderhort && showDaycare" class="daycare">
                <span @click="showDaycareSelect = false" v-if="showDaycareSelect" class="cancelDaycare">
                    <v-icon color="white">mdi-close</v-icon>
                </span>
                <span class="title">Kinderhort</span>
                <span
                    class="px-5"
                    style="line-height: 1.1"
                    v-if="
                        daycareSettings &&
                        daycareSettings.notes &&
                        daycareSettings.notes.length > 2 &&
                        showDaycareSelect
                    "
                >
                    {{ daycareSettings.notes }}
                </span>
                <span class="daycareHours" v-if="daycareHours && !showDaycareSelect">{{ daycareHours }}</span>

                <span
                    class="subtitle"
                    @click="showDaycareSelect = true"
                    v-if="!showDaycareSelect && !daycareSignups.signedUp"
                >
                    Jetzt anmelden
                </span>
                <span
                    class="subtitle"
                    @click="showDaycareSelect = true"
                    v-if="daycareSignups.signedUp && !showDaycareSelect"
                >
                    {{
                        daycareSignups.signedUpAmount && parseInt(daycareSignups.signedUpAmount) > 0
                            ? daycareSignups.signedUpAmount
                            : ''
                    }}
                    {{
                        daycareSignups.signedUpAmount && parseInt(daycareSignups.signedUpAmount) > 0
                            ? parseInt(daycareSignups.signedUpAmount) > 1 ||
                              parseInt(daycareSignups.signedUpAmount) == 0
                                ? 'Kinder'
                                : 'Kind'
                            : ''
                    }}
                    {{
                        daycareSignups.signedUpAmount &&
                        parseInt(daycareSignups.signedUpAmount) > 0 &&
                        daycareSignups.signedUpBabiesAmount &&
                        parseInt(daycareSignups.signedUpBabiesAmount) > 0
                            ? 'und'
                            : ''
                    }}
                    {{
                        daycareSignups.signedUpBabiesAmount && parseInt(daycareSignups.signedUpBabiesAmount) > 0
                            ? daycareSignups.signedUpBabiesAmount
                            : ''
                    }}
                    {{
                        daycareSignups.signedUpBabiesAmount && parseInt(daycareSignups.signedUpBabiesAmount) > 0
                            ? parseInt(daycareSignups.signedUpBabiesAmount) > 1 ||
                              parseInt(daycareSignups.signedUpBabiesAmount) == 0
                                ? 'Babies'
                                : 'Baby'
                            : ''
                    }}
                    {{
                        (daycareSignups.signedUpBabiesAmount && parseInt(daycareSignups.signedUpBabiesAmount) > 0) ||
                        (daycareSignups.signedUpAmount && parseInt(daycareSignups.signedUpAmount) > 0)
                            ? 'angemeldet'
                            : 'Jetzt anmelden'
                    }}
                </span>
                <img v-if="!showDaycareSelect" class="icon" src="/img/icons/teddyBear.gif" />
                <img v-if="!showDaycareSelect" class="icon2" src="/img/icons/teddyBear.gif" />
                <span
                    style="position: absolute; top: 5px; right: 0px"
                    class="px-5"
                    v-if="
                        daycareSettings &&
                        daycareSettings.notes &&
                        daycareSettings.notes.length > 2 &&
                        !showDaycareSelect
                    "
                >
                    <v-tooltip color="primary" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="white" dark v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                        </template>
                        <span>{{ daycareSettings.notes }}</span>
                    </v-tooltip>
                </span>
                <div class="d-flex flex-row align-baseline py-2" style="padding: 0px 20px" v-if="showDaycareSelect">
                    <v-select
                        style="width: 50%"
                        class="daycareSelect pr-2"
                        v-model="daycareSelection"
                        :items="daycareChildrenSelect"
                        label="Kinder"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            <span style="color: white">
                                {{ data.item }}
                                {{ parseInt(data.item) > 1 || parseInt(data.item) == 0 ? 'Kinder' : 'Kind' }}
                            </span>
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item }}
                            {{ parseInt(data.item) > 1 || parseInt(data.item) == 0 ? 'Kinder' : 'Kind' }}
                        </template>
                    </v-select>
                    <v-select
                        style="width: 50%"
                        class="daycareSelect pr-2"
                        v-model="daycareBabiesSelection"
                        :items="daycareBabiesSelect"
                        label="Babies (bis 1.5 Jahre)"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            <span style="color: white">
                                {{ data.item }}
                                {{ parseInt(data.item) > 1 || parseInt(data.item) == 0 ? 'Babies' : 'Baby' }}
                            </span>
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item }}
                            {{ parseInt(data.item) > 1 || parseInt(data.item) == 0 ? 'Babies' : 'Baby' }}
                        </template>
                    </v-select>
                    <v-btn @click="signupDaycare()" class="align-self-center" icon>
                        <v-icon color="white">mdi-check-circle-outline</v-icon>
                    </v-btn>
                </div>
            </div>
            <v-skeleton-loader
                v-if="loadingKinderhort"
                class="mx-auto mb-5"
                max-height="100px"
                type="card"
            ></v-skeleton-loader>
            <div @scroll="courseListScrollHandler" class="courseListWrapper">
                <div v-if="!loading" class="courseListWrapper-timeline"></div>
                <v-col
                    v-touch="{
                        left: () => swipe('Left'),
                        right: () => swipe('Right'),
                    }"
                    class="courseList__view pa-0"
                    v-for="(course, y) in courseList"
                    :key="y"
                >
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto mb-5"
                        max-height="100px"
                        type="card"
                    ></v-skeleton-loader>
                    <div
                        v-if="
                            !loading &&
                            course &&
                            course.hours &&
                            course.course &&
                            course.course.name &&
                            (!course.cancelled ||
                                course.cancelledVisibility ||
                                userService.hasRight('gf_cp_viewCancelled') ||
                                course.isInstructor)
                        "
                    >
                        <v-row v-show="showDivider(y)" class="courseList__view-date mt-5">
                            <v-col cols="3" class="courseList__view-date-hours text-center d-flex px-0">
                                <span class="ma-auto">ab {{ course.hours }} Uhr</span>
                            </v-col>
                            <!----<v-col cols="9" class="courseList__view-date-seperator pr-0"><v-divider></v-divider></v-col>---->
                        </v-row>
                        <v-card
                            @click="pushToCourse(course)"
                            class="rounded-lg courseList__view-course elevation-6 my-5 ml-15 mr-2 d-flex"
                            :class="{
                                'courseList__view-course-signedup': course.signedUp,
                                'courseList__view-course-slim-me':
                                    course.course && course.course.slimMe && !course.signedUp,
                                'courseList__view-course-recommended':
                                    course.course &&
                                    courseRecommendations.includes(course.course._id) &&
                                    !course.signedUp,
                                'py-2': !course.past || (course.current && course.reqSignup),
                                'py-4': (!course.past || (course.current && course.reqSignup)) && !course.label,
                                'pb-4': (!course.past && !course.label) || (course.current && course.reqSignup),
                                'pt-10': (!course.past && course.label) || (course.current && course.reqSignup),
                                'v-step-5': !course.past || (course.current && course.reqSignup),
                                cancelledVisibility: course.cancelledVisibility,
                            }"
                            :key="course._id"
                        >
                            <div
                                class="courseList__view-course-marker rounded-xl"
                                :class="{
                                    'courseList__view-course-marker-claimed':
                                        userService.hasRight('gf_claimforinstructor') &&
                                        course.past &&
                                        course.hoursClaimed,
                                    'courseList__view-course-marker-not-claimed':
                                        userService.hasRight('gf_claimforinstructor') &&
                                        course.past &&
                                        !course.hoursClaimed,
                                    'courseList__view-course-marker-cancelled':
                                        !userService.hasRight('gf_claimforinstructor') &&
                                        (course.cancelled || course.cancelledVisibility),
                                    'courseList__view-course-marker-signedup':
                                        !userService.hasRight('gf_claimforinstructor') &&
                                        course.signedUp &&
                                        !course.cancelled &&
                                        !course.cancelledVisibility,
                                    'courseList__view-course-marker-recommended':
                                        !userService.hasRight('gf_claimforinstructor') &&
                                        courseRecommendations.includes(course.course._id) &&
                                        !course.signedUp &&
                                        !course.cancelled &&
                                        !course.cancelledVisibility,
                                    'courseList__view-course-marker-slim-me':
                                        !userService.hasRight('gf_claimforinstructor') &&
                                        course.course.slimMe &&
                                        !course.signedUp &&
                                        !course.cancelled &&
                                        !course.cancelledVisibility,
                                }"
                            ></div>
                            <div
                                class="courseList__view-course-cancelled rounded d-flex justify-center"
                                v-if="course.cancelled && !course.cancelledVisibility"
                            ></div>
                            <div
                                class="courseList__view-course-past rounded d-flex justify-center"
                                v-if="course.past && !course.current"
                            >
                                <v-rating
                                    background-color="#ffd700"
                                    color="#ffd700"
                                    v-if="
                                        course.visited &&
                                        course.ratings &&
                                        !(
                                            course.isInstructor ||
                                            userService.hasRight(
                                                'gf_c_edit',
                                                'gf_c_delete',
                                                'gf_c_plan',
                                                'gf_c_create',
                                                'gf_cp_edit',
                                                'gf_f_courses'
                                            )
                                        )
                                    "
                                    class="my-5 d-flex justify-center"
                                    length="5"
                                    size="35"
                                    readonly
                                    :value="course.ratings.stars"
                                ></v-rating>
                                <v-dialog
                                    v-if="
                                        course.past &&
                                        !course.current &&
                                        (course.visited || course.lsSignedUp) &&
                                        !course.ratings
                                    "
                                    transition="dialog-bottom-transition"
                                    max-width="600"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="courseList__view-course-past-rating-btn"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="hideGoogleRating()"
                                        >
                                            Jetzt bewerten
                                        </v-btn>
                                    </template>
                                    <template v-slot:default="dialog">
                                        <v-card>
                                            <v-toolbar color="primary" dark>
                                                <div class="d-block">
                                                    <strong v-if="course && course.course && course.course.name">
                                                        {{ course.course.name }}
                                                    </strong>
                                                    <br />
                                                    <span>mit</span>
                                                    <span v-for="(instructor, iy) of course.instructors" :key="iy">
                                                        {{ instructor.vorname }} {{ instructor.nachname }}
                                                        <span
                                                            class="pr-1"
                                                            v-show="
                                                                iy !== course.instructors.length - 1 &&
                                                                course.instructors.length !== 1
                                                            "
                                                        >
                                                            ,
                                                        </span>
                                                    </span>
                                                </div>
                                            </v-toolbar>
                                            <v-card-text class="justify-center">
                                                <v-rating
                                                    v-if="!rating.showGoogle && !rating.showFeedbackBox"
                                                    background-color="#ffd700"
                                                    color="#ffd700"
                                                    class="my-5 d-flex justify-center"
                                                    length="5"
                                                    size="35"
                                                    v-model="rating.stars"
                                                ></v-rating>
                                                <v-textarea
                                                    v-if="!rating.showGoogle && !rating.showFeedbackBox"
                                                    v-model="rating.message"
                                                    solo
                                                    name="input-7-4"
                                                    label="Deine Bewertung..."
                                                ></v-textarea>
                                                <v-card-text v-if="rating.showGoogle">
                                                    Wir freuen uns auch über eine Bewertung auf Google.
                                                    <br />
                                                    <v-checkbox
                                                        v-model="rating.dontShowAnymore"
                                                        label="nicht mehr anzeigen"
                                                    ></v-checkbox>
                                                </v-card-text>

                                                <v-card-text v-if="rating.showFeedbackBox">
                                                    Schade, dass Du mit dem Kurs nicht ganz so zufrieden gewesen bist.
                                                    Kannst Du uns sagen, was aus Deiner Sicht schief gelaufen ist?
                                                </v-card-text>
                                                <v-textarea
                                                    v-if="rating.showFeedbackBox"
                                                    label="Deine Rückmeldung"
                                                    v-model="rating.inputFeedbackBox"
                                                    color="teal"
                                                ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions class="justify-end">
                                                <v-btn
                                                    :key="rating.stars"
                                                    :ref="'closeRatingDialog-' + course._id"
                                                    text
                                                    @click="abortRatingDialog(dialog)"
                                                >
                                                    Abbrechen
                                                </v-btn>

                                                <v-btn
                                                    color="primary"
                                                    :loading="loadingFeedback"
                                                    text
                                                    @click="
                                                        !rating.showGoogle
                                                            ? !rating.showFeedbackBox
                                                                ? sendRating(course._id, dialog)
                                                                : sendFeedbackBox(dialog, course._id)
                                                            : openGoogleRating(dialog)
                                                    "
                                                >
                                                    <span v-if="!rating.showGoogle && !rating.showFeedbackBox">
                                                        Bewerten
                                                    </span>
                                                    <span v-else-if="rating.showGoogle">Zu Google</span>
                                                    <span v-else-if="rating.showFeedbackBox">Absenden</span>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </template>
                                </v-dialog>
                            </div>

                            <div
                                v-if="(course.label && !course.past) || (course.current && course.reqSignup)"
                                class="courseList__view-course-label"
                            >
                                <span
                                    v-if="!course.current && course.label"
                                    class="text-white rounded-lg"
                                    :style="'background-color:' + course.label.color + ';'"
                                >
                                    {{ course.label.text }}
                                </span>
                                <span
                                    v-if="course.current && course.reqSignup"
                                    class="text-white rounded-lg"
                                    :style="'background-color:red;'"
                                >
                                    LIVE
                                </span>
                            </div>
                            <v-col
                                v-if="
                                    !course.past ||
                                    course.current ||
                                    course.visited ||
                                    course.isInstructor ||
                                    userService.hasRight(
                                        'gf_c_edit',
                                        'gf_c_delete',
                                        'gf_c_plan',
                                        'gf_c_create',
                                        'gf_cp_edit',
                                        'gf_f_courses'
                                    )
                                "
                                cols="5"
                                class="courseList__view-course-icon d-flex mt-0"
                                :data-index="y"
                            >
                                <span
                                    :data-index="y"
                                    v-show="visibleItems.includes(y + '-courseListEntry')"
                                    class="mx-auto"
                                >
                                    <lottie-player
                                        mode="normal"
                                        v-if="!course.icon"
                                        style="width: 52px; height: 66px"
                                        class="courseList__view-course-lottie m-auto"
                                        src="/img/lottiefiles/gf/dumbell.json"
                                    ></lottie-player>

                                    <lottie-player
                                        mode="normal"
                                        v-else
                                        style="width: 52px; height: 66px"
                                        class="courseList__view-course-lottie m-auto"
                                        :key="course.icon"
                                        :src="'/api/gf/icons/' + course.icon"
                                    ></lottie-player>
                                    <div class="single-chart">
                                        <svg viewBox="0 0 36 36" class="circular-chart green-color">
                                            <path
                                                class="circle-bg"
                                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                            <path
                                                :style="
                                                    getStrokeColor(
                                                        calcPercentage(course),
                                                        course.course.slimMe,
                                                        course.cancelled || course.cancelledVisibility,
                                                        course.signedUp,
                                                        courseRecommendations.includes(course.course._id)
                                                    )
                                                "
                                                class="circle"
                                                :stroke-dasharray="calcPercentage(course) + ', 100'"
                                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                        </svg>
                                    </div>
                                </span>
                            </v-col>

                            <v-col cols="5" class="d-flex justify-center" v-else>
                                <v-icon class="ma-auto" color="primary">mdi-clock-check-outline</v-icon>
                            </v-col>

                            <v-col
                                v-if="course && course.course"
                                cols="6"
                                :class="{ 'white--text': course.signedUp }"
                                class="courseList__view-course-info my-auto"
                            >
                                <v-row v-if="course.course.name" class="courseList__view-course-info-title">
                                    {{ course.course.name }}
                                </v-row>
                                <v-row v-if="course.additionalInfo" class="courseList__view-course-info-additionalInfo">
                                    {{ course.additionalInfo }}
                                </v-row>
                                <v-row
                                    v-if="course.cancelledVisibility"
                                    class="courseList__view-course-info-additionalInfo"
                                >
                                    Leider abgesagt
                                </v-row>
                                <v-row
                                    v-if="
                                        ((!course.past || course.current) && !course.visited) ||
                                        course.isInstructor ||
                                        userService.hasRight(
                                            'gf_c_edit',
                                            'gf_c_delete',
                                            'gf_c_plan',
                                            'gf_c_create',
                                            'gf_cp_edit',
                                            'gf_f_courses'
                                        )
                                    "
                                    class="courseList__view-course-info-instructor"
                                >
                                    <span v-for="(instructor, iy) of course.instructors" :key="iy">
                                        {{ instructor.vorname }} {{ instructor.nachname }}
                                        <span
                                            class="pr-1"
                                            v-show="
                                                iy !== course.instructors.length - 1 && course.instructors.length !== 1
                                            "
                                        ></span>
                                    </span>
                                </v-row>

                                <v-row class="courseList__view-course-info-time">
                                    {{ courseStartDate(course) }} - {{ courseEndDate(course) }} Uhr
                                </v-row>
                                <!----<v-row class="courseList__view-course-info-time"> {{ course.duration }} Min </v-row>---->
                                <v-row
                                    v-if="
                                        course.room &&
                                        course.room.name &&
                                        (!course.past || course.current) &&
                                        !course.visited
                                    "
                                    class="courseList__view-course-info-room"
                                >
                                    {{ course.room.name }}
                                </v-row>
                            </v-col>
                            <span
                                v-show="
                                    !course.cancelled &&
                                    course.reqSignup &&
                                    (!course.past ||
                                        course.current ||
                                        userService.hasRight(
                                            'gf_c_edit',
                                            'gf_c_delete',
                                            'gf_c_plan',
                                            'gf_c_create',
                                            'gf_cp_edit',
                                            'gf_f_courses'
                                        ))
                                "
                                class="signupCounter"
                                :style="
                                    !course.past
                                        ? getTextColor(
                                              calcPercentage(course),
                                              course.course.slimMe,
                                              course.cancelled || course.cancelledVisibility,
                                              course.signedUp,
                                              courseRecommendations.includes(course.course._id)
                                          )
                                        : 'color: black;'
                                "
                            >
                                {{ course.signupAmount }}/{{ course.amount }}
                            </span>

                            <span
                                class="onQueue"
                                :style="course.label ? 'margin-top: 30px;' : ''"
                                v-if="course.onQueue"
                            >
                                <v-btn class="my-auto ml-1" plain icon large>
                                    <v-icon class="my-auto ml-1" color="red">mdi-clipboard-alert</v-icon>
                                    <span>{{ course.queuePosition }}</span>
                                </v-btn>
                            </span>
                        </v-card>
                    </div>
                </v-col>
            </div>
        </div>
        <router-view @updateCourse="fetchCourseList"></router-view>
    </div>
    <!--
       
       <div v-else>
           <v-row>
               <v-col cols="12">
                   <v-alert type="info" class="d-flex align-center">
                       <span>Hier entsteht gerade der fabelhafte Groupfitnessplan für Deine Alibey Ferien</span>
                       <v-icon color="white" class="pl-2">mdi-beach</v-icon>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
    -->
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import courseListWeekly from './courseListWeekly.vue'
import livestreamInformation from '../../components/gf/livestreamInformation.vue'
import abortService from '../../services/abortService'
import { mapState, mapActions, mapGetters } from 'vuex'
import { dragscroll } from 'vue-dragscroll'

export default {
    name: 'courseList',
    components: { courseListWeekly, livestreamInformation },
    directives: {
        dragscroll,
    },
    data() {
        return {
            loadingKinderhort: true,
            daycareHours: null,
            loadingFeedback: false,
            courseListWeeklyKey: 0,
            visibleItems: [],
            showDaycare: false,
            daycareSignups: { amount: 0, signedUp: false, signedUpAmount: 0 },
            daycareChildrenSelect: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
            daycareBabiesSelect: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
            showDaycareSelect: false,
            daycareSelection: '0',
            daycareBabiesSelection: '0',
            daycareSettings: null,
            showWeekly: false,
            abortController: null,
            datePicker: null,
            showDatePicker: false,
            currentDate: null,
            loadingCalendar: true,
            loading: true,
            calendar: [{}, {}, {}, {}, {}, {}, {}],
            userService,
            courseList: [{}, {}, {}, {}, {}, {}, {}],
            googleLinks: {
                winterthur: 'https://g.page/r/CbEcf8fUAQ7mEAg/review',
                buelach: 'https://g.page/r/CduhTKFhuVH-EAg/review',
                dietlikon: 'https://g.page/r/Ccq9WOxhQEaWEAg/review',
                ot: 'https://g.page/r/CQ63PE1DJf0HEAg/review',
                frauenfeld: 'https://g.page/r/CQ63PE1DJf0HEAg/review',
                stgallenwest: 'https://g.page/r/Ca4GhjxnDHDsEAg/review',
                stgallenost: 'https://g.page/r/CXNpi2V1vu-AEAg/review',
                stgalleneinstein: 'https://g.page/r/CZFO-y-BdXzYEAg/review',
                netstal: 'https://g.page/r/CeGPiIDt6z3LEAg/review',
                pfungen: 'https://g.page/r/CQpI8Wm0PWhcEAg/review',
                arbon: 'https://search.google.com/local/writereview?placeid=ChIJ1RIRBOccm0cRrGlGsiQazgI',
                basel: 'https://search.google.com/local/writereview?placeid=ChIJI_FXJ_K5kUcRLc9DflrHsZo',
                herisau: 'https://search.google.com/local/writereview?placeid=ChIJR8GJASzemkcRH1sSS0jDOcI',
                niederurnen: 'https://search.google.com/local/writereview?placeid=ChIJQ2XO3f7TmkcRNd7XIa-JVEs',
                obfelden: 'https://search.google.com/local/writereview?placeid=ChIJrz8roHoGkEcRpdEg8jdGms0',
                oerlikon: 'https://search.google.com/local/writereview?placeid=ChIJURt31IIKkEcRzSZLmDYIoDo',
                rikon: 'https://g.page/r/CRdzXqfjriqgEB0/review',
                sirnach: 'https://search.google.com/local/writereview?placeid=ChIJh5LPyA7rmkcRVfx67gTD4Gw',
                steckborn: 'https://search.google.com/local/writereview?placeid=ChIJVxwbF3mLmkcR1x9w3-z4dIU',
                schwanden: 'https://search.google.com/local/writereview?placeid=ChIJ0VSslTAvhUcRCeTL4u9X2Wc',
                wallisellen: 'https://search.google.com/local/writereview?placeid=ChIJzQVcOzOhmkcRuhhXRQArsh8',
                wetzikon: 'https://search.google.com/local/writereview?placeid=ChIJRVBvqcu7mkcR8MfxJIud_pE',
            },
            rating: {
                _id: null,
                stars: 0,
                message: '',
                showGoogle: false,
                dontShowAnymore: false,
                showFeedbackBox: false,
                inputFeedbackBox: '',
            },
        }
    },
    beforeRouteUpdate(to, from, next) {
        if (!this.$route.params.id) document.body.style = 'overflow:auto;max-height:100vh;'
        next()
    },
    mounted() {},
    async created() {
        this.abortController = new abortService()
        document.body.style = 'overflow:auto;max-height:100vh;'
        this.datePicker = this.$moment().format('YYYY-MM-DD')
        this.$emit('fetchNotifications')
        this.setNavigationBarText(null)
        this.setSelectedNavigation('groupFitness')
        if (!this.navigationVisible) this.toggleNavigationBarVisibility()

        await this.createCalendar()

        //await this.fetchKinderhortHours()
    },
    beforeDestroy() {
        // delete this.$options.sockets.onmessage
        this.abortController.abort('not needed anymore')
    },
    methods: {
        ...mapActions('navigationStore', ['setSelectedNavigation']),
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'toggleNavigationBarVisibility',
            'setNavigationbarVisibility',
        ]),

        websocketHandler(data) {
            if (data.topic === 'groupFitness') {
                if (data.courseExecution) {
                    for (let course in this.courseList) {
                        if (this.courseList[course]._id.toString() === data.courseExecution.toString()) {
                            if (data.type === 'signup') this.courseList[course].signupAmount += 1
                            if (data.type === 'signoff') this.courseList[course].signupAmount -= 1
                        }
                    }
                }
            }
        },

        handleMessage(data) {
            // Handle the incoming message
            if (data.topic === 'groupFitness' && data.type === 'newAmount') {
                if (data.courseExecution) {
                    for (let course in this.courseList) {
                        if (this.courseList[course]._id.toString() === data.courseExecution.toString()) {
                            this.courseList[course].signupAmount = data.amount === null ? 0 : data.amount
                        }
                    }
                }
            }
        },
        async signupDaycare() {
            let amountChildren = this.daycareSelection
            let amountBabies = this.daycareBabiesSelection
            let ts = this.calendar.filter((c) => c.active)[0].ts
            if (
                this.userService.hasRight(
                    'gf_c_edit',
                    'gf_c_delete',
                    'gf_c_plan',
                    'gf_c_create',
                    'gf_cp_edit',
                    'gf_f_courses'
                ) &&
                !this.alibeyStudio
            ) {
                ts = this.$moment(this.datePicker).set({ hours: 0, minutes: 0, seconds: 0, miliseconds: 0 }).unix()
            }
            let date = this.$moment(ts * 1000).format('YYYY-MM-DD')

            let signupDate = this.$moment().format()
            let signup = { date, signup: { date: signupDate, amountChildren, amountBabies, active: true } }
            let status = await api.signupDaycare(signup)
            this.fetchKinderhortSignups(false)
            this.showDaycareSelect = false
        },
        async fetchKinderhortSignups(loading = true) {
            if (loading) this.loadingKinderhort = true
            let ts = this.calendar.filter((c) => c.active)[0].ts
            if (
                this.userService.hasRight(
                    'gf_c_edit',
                    'gf_c_delete',
                    'gf_c_plan',
                    'gf_c_create',
                    'gf_cp_edit',
                    'gf_f_courses'
                ) &&
                !this.alibeyStudio
            ) {
                ts = this.$moment(this.datePicker).set({ hours: 0, minutes: 0, seconds: 0, miliseconds: 0 }).unix()
            }
            let signups = await api.fetchDaycareSignups(this.$moment(ts * 1000).format('YYYY-MM-DD'))
            if (signups) {
                this.daycareSignups = signups
                this.daycareSelection = signups.signedUpAmount.toString()
                this.daycareBabiesSelection = signups.signedUpBabiesAmount.toString()
            } else {
                this.daycareSignups = { amount: 0, signedUp: false, signedUpAmount: 0, signedUpBabiesAmount: 0 }
                this.daycareSelection = '0'
                this.daycareBabiesSelection = '0'
            }
            this.loadingKinderhort = false
        },
        async fetchKinderhortHours() {
            this.daycareSelection = '0'
            this.daycareBabiesSelection = '0'
            let openingHours = await api
                .fetchBusinessHours('kinderhort')
                .catch((e) => console.log('error loading kinderhort'))

            if (openingHours && openingHours.active) {
                this.daycareSettings = openingHours
                let ts = this.calendar.filter((c) => c.active)[0].ts
                if (
                    this.userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    ) &&
                    !this.alibeyStudio
                ) {
                    ts = this.$moment(this.datePicker).set({ hours: 0, minutes: 0, seconds: 0, miliseconds: 0 }).unix()
                }

                let date = this.$moment(ts * 1000).weekday()
                let daysArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                let day = daysArray[date]
                let hours = openingHours.days[day] ? openingHours.days[day] : null
                if (hours) {
                    let open = hours.filter((e) => e.isOpen === true)

                    if (open.length > 0) {
                        let active = false
                        open.forEach((o) => {
                            let subtractAmount = 3
                            let start = this.$moment(ts * 1000)
                                .subtract(subtractAmount, 'days')
                                .set({ hours: parseInt(o.open.slice(0, 2)), minutes: parseInt(o.open.slice(2, 4)) })

                            let end = this.$moment(ts * 1000).set({
                                hours: parseInt(o.close.slice(0, 2)),
                                minutes: parseInt(o.close.slice(2, 4)),
                            })
                            this.daycareHours = start.format('HH:mm') + ' - ' + end.format('HH:mm') + ' Uhr'
                            let now = this.$moment()

                            if (now.isBetween(start, end)) active = true
                        })

                        this.showDaycare = active
                        this.fetchKinderhortSignups()
                    } else {
                        this.showDaycare = false
                        this.loadingKinderhort = false
                    }
                }
            } else {
                this.loadingKinderhort = false
            }
        },

        swipe(direction) {
            if (direction === 'Right') {
                if (
                    this.userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    )
                ) {
                    this.datePicker = this.$moment(this.datePicker).subtract(1, 'days').format('YYYY-MM-DD')
                    this.fetchCourseList()
                } else {
                    let index = this.calendar.findIndex((e) => e.active)

                    if (index !== 0) {
                        this.calendar[index].active = false
                        this.calendar[index - 1].active = true
                        this.fetchCourseList()
                    }
                }
            } else {
                if (
                    this.userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    )
                ) {
                    this.datePicker = this.$moment(this.datePicker).add(1, 'days').format('YYYY-MM-DD')
                    this.fetchCourseList()
                } else {
                    let index = this.calendar.findIndex((e) => e.active)
                    let calendarLength = this.calendar.length
                    if (index + 1 !== calendarLength) {
                        this.calendar[index].active = false

                        this.calendar[index + 1].active = true
                        this.fetchCourseList()
                    }
                }
            }
        },
        courseListScrollHandler(x) {
            let visibleItems = []
            document.getElementsByClassName('courseList__view-course-icon').forEach((e, i) => {
                let rect = e.getBoundingClientRect()

                if (x.target.scrollTop < rect.top + x.target.scrollTop) {
                    visibleItems.push(e.dataset.index + '-courseListEntry')
                }
            })
            this.visibleItems = visibleItems
        },
        async abortRatingDialog(dialog) {
            if (this.rating.dontShowAnymore) {
                localStorage.setItem('hideGoogleRating', true)
            }
            this.rating._id = null

            this.rating.message = ''
            this.rating.stars = 0
            this.setNavigationbarVisibility(true)
            await this.fetchCourseList()
            return (dialog.value = false)
        },
        hideGoogleRating() {
            this.rating.dontShowAnymore = false
            this.setNavigationbarVisibility(false)
            this.rating.showFeedbackBox = false
            this.rating.showGoogle = false
        },
        async sendFeedbackBox(dialog, courseId) {
            this.loadingFeedback = true
            let status = await api
                .sendFeedbackBox(this.rating.inputFeedbackBox, 'groupFitness', courseId)
                .catch((e) => {
                    alert('Fehler beim Senden des Feedbacks: Versuche es später erneut')
                    this.loadingFeedback = false
                })
            this.loadingFeedback = false
            return (dialog.value = false)
        },
        async openGoogleRating(dialog) {
            let user = await api.getUser().catch((e) => {
                this.$router.push('/login')
            })

            if (this.rating.dontShowAnymore) {
            }

            let studioId = user.user.studio_id

            window.open(this.googleLinks[studioId], '_blank').focus()
        },

        async sendRating(id, dialog) {
            this.loadingFeedback = true
            this.rating.dontShowAnymore = false
            if (this.rating.stars !== 0) {
                this.rating._id = id
                let user = await api.getUser().catch((e) => {
                    this.$router.push('/login')
                })

                let studioId = user.user.studio_id
                let ratingStatus = await api.sendGFRating({ rating: this.rating }).catch((e) => {
                    this.loadingFeedback = false
                })
                this.loadingFeedback = false
                if (this.rating.stars && this.rating.stars > 3) {
                    if (this.googleLinks[studioId] && !localStorage.getItem('hideGoogleRating')) {
                        this.rating.showGoogle = true
                    } else {
                        this.abortRatingDialog(dialog)
                    }
                } else {
                    this.rating.showFeedbackBox = true
                }
            } else {
                alert('Bitte wähle eine Bewertung aus')
            }
        },
        getTextColor(percentage, slimMe = false, cancelled = false, signedUp = false, recommended = false) {
            if (cancelled) return 'color: white;'

            if (percentage <= 0) {
                return 'color: red;'
            } else {
                return 'color: white;'
            }
        },
        getStrokeColor(percentage, slimMe = false, cancelled = false, signedUp, recommended = false) {
            if (recommended) return 'stroke: #FFD700;'
            if (cancelled) return 'stroke: red;'
            if (slimMe && percentage > 80 && !signedUp) return 'stroke: #88b9c7;'
            if (percentage > 50) {
                return 'stroke: var(--v-primary);'
            }
            if (percentage > 33) {
                return 'stroke: #ff9f00;'
            }
            if (percentage <= 33) {
                return 'stroke: red;'
            }
        },
        formatDate(date) {
            if (date === null) return ''
            let day = this.$moment(date).format('dd')
            return date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0] + ' (' + day + ')'
        },
        calcPercentage(course) {
            if (course.signups) {
                return 100 - Math.round((100 / course.amount) * course.signups.length)
            } else {
                return 100
            }
        },
        pushToCourse(course) {
            if (
                course.past &&
                !this.userService.hasRight(
                    'gf_c_edit',
                    'gf_c_delete',
                    'gf_c_plan',
                    'gf_c_create',
                    'gf_cp_edit',
                    'gf_f_courses'
                ) &&
                this.userService.hasRight('gf_cp_viewPastCourses')
            ) {
                let ts = this.calendar.filter((c) => c.active)[0].ts
                document.body.style = 'overflow:hidden;max-height:100vh;'

                if (course.courseTimelineId) {
                    ts = 0
                }

                this.$router.push({ name: 'courseListView', params: { id: course._id, ts } })
                return null
            }
            if (
                !course.past ||
                course.current ||
                course.isInstructor ||
                this.userService.hasRight(
                    'gf_c_edit',
                    'gf_c_delete',
                    'gf_c_plan',
                    'gf_c_create',
                    'gf_cp_edit',
                    'gf_f_courses'
                )
            ) {
                let ts = this.calendar.filter((c) => c.active)[0].ts
                if (
                    this.userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    ) &&
                    !this.alibeyStudio
                ) {
                    ts = this.$moment(this.datePicker).set({ hours: 0, minutes: 0, seconds: 0, miliseconds: 0 }).unix()
                }
                if (course.courseTimelineId) {
                    ts = 0
                }
                document.body.style = 'overflow:hidden;max-height:100vh;'

                this.$router.push({ name: 'courseListView', params: { id: course._id, ts } })
            }
        },
        courseEndDate(course) {
            let endTime = this.$moment()
            endTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            endTime.add('minutes', course.duration)
            return endTime.format('LT')
        },
        courseStartDate(course) {
            let startTime = this.$moment()

            startTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            return startTime.format('LT')
        },
        showDivider(y) {
            if (y - 1 >= 0) {
                if (this.courseList[y].hours !== this.courseList[y - 1].hours) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        async createCalendar() {
            this.loadingCalendar = true
            this.calendar = await api.fetchCourseCalendar(this.abortController.signal)
            this.loadingCalendar = false
        },
        setCalendarDate(i) {
            this.loading = true
            this.courseList = [{}, {}, {}, {}, {}]
            this.calendar.forEach((c, y) => {
                if (y == i) {
                    c.active = true
                } else {
                    c.active = false
                }
            })
            return true
        },
        async fetchCourseList() {
            this.loadingKinderhort = true
            if (this.showWeekly) this.courseListWeeklyKey += 1

            let ts = this.calendar.filter((c) => c.active)
            if (ts) ts = ts[0] ? (ts[0].ts ? ts[0].ts : false) : false
            if (
                ts &&
                this.userService.hasRight(
                    'gf_c_edit',
                    'gf_c_delete',
                    'gf_c_plan',
                    'gf_c_create',
                    'gf_cp_edit',
                    'gf_f_courses'
                ) &&
                !this.alibeyStudio &&
                !this.schwandenStudio &&
                !this.niederurnenStudio
            ) {
                if (this.datePicker === null) this.datePicker = this.$moment().format('YYYY-MM-DD')
                ts = this.$moment(this.datePicker).set({ hours: 0, minutes: 0, seconds: 0, miliseconds: 0 }).unix()
            }
            if (ts) {
                this.fetchKinderhortHours()
                let courseList = await api.fetchCourseList(this.abortController.signal, ts)
                this.loading = true

                courseList.forEach((i, y) =>
                    (this.visibleItems.lenth <= 5 && (!i.past || i.current)) ||
                    i.isInstructor ||
                    this.userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    ) ||
                    !i.past ||
                    i.current ||
                    i.isInstructor ||
                    this.userService.hasRight(
                        'gf_c_edit',
                        'gf_c_delete',
                        'gf_c_plan',
                        'gf_c_create',
                        'gf_cp_edit',
                        'gf_f_courses'
                    )
                        ? this.visibleItems.push(y + '-courseListEntry')
                        : ''
                )

                this.courseList = courseList
                this.loading = false
            }
        },
    },
    computed: {
        ...mapGetters('userStore', [
            'courseRecommendations', // Zugriff auf den Getter courseRecommendations
        ]),
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
        schwandenStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id
            if (studioCode && studioCode === 'schwanden') {
                return true
            } else {
                return false
            }
        },
        niederurnenStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id
            if (studioCode && studioCode === 'niederurnen') {
                return true
            } else {
                return false
            }
        },

        livestreamStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id
            if (studioCode && studioCode === 'ot') {
                return true
            } else {
                return false
            }
        },

        alibeyStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && (studioCode === 'Alibey' || studioCode === 'alibey' || studioCode === 'ali')) {
                return true
            } else {
                return false
            }
        },
    },
    watch: {
        datePicker() {
            this.courseList = [{}, {}, {}, {}, {}]
            this.loading = true
            this.fetchCourseList()
        },
        loadingCalendar(loading) {
            if (!loading) this.fetchCourseList()
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/circle';
::v-deep .daycareSelect .v-label,
::v-deep .daycareSelect .v-icon,
::v-deep .daycareSelect .v-select__selection,
::v-deep .daycareSelect .v-input__slot::before {
    border-color: white !important;
    color: white !important;
}
.daycare {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(82, 174, 50, 1) 0%, rgba(72, 148, 46, 1) 35%, rgba(60, 124, 39, 1) 79%);
    border-radius: 8px;
    cursor: pointer;
    transition: height 1s ease;
    color: white;
    height: auto;
    position: relative;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;

    .title {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px 20px 0px 20px;
    }

    .cancelDaycare {
        position: absolute;
        top: 15px;
        right: 25px;
    }

    .daycareSelect {
    }
    .daycareHours {
        font-size: 0.8rem;
        padding: 0px 20px 5px 20px;
    }

    .subtitle {
        padding: 0px 20px 15px 20px;
    }

    .icon {
        position: absolute;
        top: 10px;
        right: 50px;
        height: 80px;
    }
    .icon2 {
        position: absolute;
        top: 40px;
        right: 20px;
        height: 50px;
    }
}

::v-deep .v-input__slot .v-label {
    margin: auto !important;
}
.onQueue {
    position: absolute;
    right: 10px;
    top: 5px;
}
.signupCounter {
    position: absolute;
    font-size: 13px;
    letter-spacing: 1.5px;
    bottom: 10px;
    right: 15px;
}
.courseListWrapper {
    position: relative;
    &-timeline {
        height: 100%;
        width: 1px;
        background-color: #00000075;
        left: 20px;
        position: absolute;
        top: 13px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.courseList {
    max-width: 395px;

    margin: 0px auto;

    &__edit {
        position: fixed;
        right: 0;
        bottom: 15vh;
        z-index: 89;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    &.modalOpen {
        overflow-y: hidden;
    }
    @media only screen and (min-width: 499px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    position: relative;

    &__filter {
        min-height: 100px;
    }

    &__calendar {
        max-width: 700px;
        margin-right: auto;
        margin-left: auto;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &-date {
            min-width: 60px;
            &-btn {
                color: black;
                height: 45px;
                width: 45px;
                border: 1px solid;
                &.active {
                    color: white;
                    background-color: var(--v-primary);
                }
            }
        }
        &-short {
            text-align: center;
        }
    }
    &__view {
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
        &-date {
            &-hours {
                font-size: 13px;
                span {
                    background-color: #fff;
                    padding-bottom: 10px;
                    padding-top: 10px;
                }
            }
        }
        &-course {
            background: linear-gradient(180deg, rgba(209, 223, 223, 1) 0%, rgba(136, 199, 181, 1) 79%);
            position: relative;
            &.cancelledVisibility {
                opacity: 0.5;
            }
            &-label {
                display: flex;
                justify-content: center;
                text-align: center;
                position: absolute;
                width: 100%;
                top: 0px;

                span {
                    font-weight: bold;
                    background-color: #000;
                    width: 100%;
                    border-bottom-right-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    padding: 5px 10px;
                    font-size: 13px;
                    letter-spacing: 1.2px;
                }
            }

            &-marker {
                height: 13px;
                width: 13px;
                background-color: #88c7b5;
                position: absolute;
                left: -46px;
                top: calc(50% - 7px);
                padding: 0px;
                margin: 0px;
                &-cancelled {
                    background-color: red;
                }
                &-not-claimed {
                    background-color: red;
                }
                &-claimed {
                    background-color: green;
                }
                &-signedup {
                    background-color: var(--v-primary);
                }
                &-slim-me {
                    background-color: #88b9c7;
                }
                &-recommended {
                    background-color: #ffd700;
                }
            }
            &-cancelled {
                top: 0px;
                right: 0px;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 0, 0, 0.46589057986475846) 35%,
                    rgba(191, 10, 10, 0.46589057986475846) 93%
                ) !important;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 2;
                display: flex;
            }

            &-past {
                top: 0px;
                right: 0px;
                background-color: #e9f4f5bd;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 1;
                display: flex;
                &-rating-btn {
                    margin: auto;
                }
            }
            &-signedup {
                background: linear-gradient(
                    180deg,
                    rgba(82, 174, 50, 1) 0%,
                    rgba(72, 148, 46, 1) 35%,
                    rgba(60, 124, 39, 1) 79%
                ) !important;
            }
            &-slim-me {
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(136, 185, 199, 1) 35%,
                    rgba(226, 182, 215, 1) 100%
                );
            }

            &-recommended {
                background: linear-gradient(180deg, #fff6c1 0%, #e6c300 35%, rgb(252, 214, 0) 100%);
            }
            &-icon {
                span {
                    height: 65px;
                    width: 65px;
                    background-color: #ffffff;
                    position: relative;
                    border-radius: 100%;
                }
            }
            &-info {
                &-subtitle {
                    span {
                        letter-spacing: 1px;
                        padding: 0px 10px;
                        font-weight: bold;
                        display: flex;
                        border-radius: 15px;
                    }
                }

                &-title {
                    font-weight: bold;
                    font-size: 16px;
                }
                &-additionalInfo {
                    font-size: 14px;
                    line-height: 1;
                    padding: 5px 0px;
                }
                &-time,
                &-room,
                &-instructor {
                    //font-weight: bold;
                    font-size: 13px;
                }
                &-room {
                }
                &-instructor {
                }
            }
        }
    }
}
</style>
