<template>
	<div class="livestream">
		<div class="back-navigation">
			<router-link to="/kurse">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Livestream</span>
		</div>

		<div class="video">
			<div class="video-stream">
				<iframe
					id="vimeoLivestream"
					:src="link"
					width="100vw"
					height="20vh"
					frameborder="0"
					allow="autoplay; fullscreen"
					allowfullscreen
				></iframe>

				<lottie-player
					mode="normal"
					v-show="showLoader"
					class="lottie"
					name="loading"
					loop
					autoplay
					src="/img/lottiefiles/signal.json"
				></lottie-player>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'

export default {
	data() {
		return {
			user: {},
			link: '#',
			showLoader: true,
		}
	},
	components: {},
	created() {
		let links = []
		links['one'] = 'https://vimeo.com/event/567883/embed/fce7714458'
		links['two'] = 'https://vimeo.com/event/1287052/embed/38ba9af484'
		this.link = links[this.$route.params.roomName]

		this.showLoader = true
		this.checkIframeLoaded()
	},
	methods: {
		checkIframeLoaded() {
			var iframe = document.getElementById('vimeoLivestream')

			try {
				var iframeDoc = iframe.contentDocument || iframe.contentWindow.document
				if (iframeDoc.readyState == 'complete') {
					//iframe.contentWindow.alert("Hello");

					// The loading is complete, call the function we want executed once the iframe is loaded
					this.afterLoading()
					return
				}

				// If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
				window.setTimeout(checkIframeLoaded, 100)
			} catch (e) {
				this.showLoader = false
				window.setTimeout(checkIframeLoaded, 100)
			}
		},
		afterLoading() {
			this.showLoader = false
		},
	},
}
</script>

<style lang="scss" scoped>
.lottie {
	display: block;
	margin: 0 auto;
}
.video-stream {
	position: relative;
	padding-bottom: 56.25%; /* ratio 16x9 */
	height: 0;
	overflow: hidden;
	width: 100%;
	height: auto;
	/* background-image: url("https://picsum.photos/id/870/640/320");*/
}
.video-stream iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	min-height: 40px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}

	a,
	.back-wrapper {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
