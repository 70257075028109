<template>
	<div class="edit-uebungen">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Übungen verwalten</span>
		</div>
		<div class="geraete">
			<div class="edit-wrapper">
				<div @click="addGeraet" class="add-geraet">Gerät hinzufügen</div>
				<div @click="importGeraet" class="import-geraet">Gerät importieren</div>
			</div>

			<div class="search">
				<input placeholder="Gerät suchen..." v-on:input="filterGeraete" type="text" v-model="search" />
				<span class="material-icons">search</span>
			</div>
			<div class="panel-group" v-for="(site, i) of filtered_geraete_db" :key="i">
				<div class="panel panel-default">
					<div class="panel-heading">
						<h4 class="panel-title">
							<a data-toggle="collapse" :href="'#collapse' + i">{{ site.titel }}</a>
							<div class="edit-site">
								<span @click="addUebung(site.old_id)" class="material-icons">library_add</span>
								<span @click="editGeraet(site._id)" class="material-icons">edit</span>
							</div>
						</h4>
					</div>
					<div :id="'collapse' + i" class="panel-collapse collapse">
						<div class="panel-body">
							<div v-for="(uebung, x) of site.uebungen" :key="x" class="uebungen-geraet">
								<div class="uebung-geraet-titel">
									{{ uebung.uebung.titel }}
									<div class="edit-wrapper">
										<span
											@click="toggleVisibility(uebung.uebung, x, i)"
											v-if="uebung.uebung.active"
											class="togglevisible material-icons"
										>
											visibility
										</span>
										<span @click="toggleVisibility(uebung.uebung, x, i)" v-else class="togglevisible material-icons">
											visibility_off
										</span>
										<span @click="editUebungSteps(uebung)" class="material-icons">
											format_list_numbered_rtl
										</span>
										<span @click="editUebung(uebung)" class="material-icons edit-uebung-ac">edit</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<geraete v-on:saved="loadUebungen" ref="geraete"></geraete>
		<uebungen v-on:saved="loadUebungen" ref="uebungen"></uebungen>
		<uebungensteps ref="uebungsteps"></uebungensteps>
		<geraeteimport v-on:imported="loadUebungen" ref="geraeteimport"></geraeteimport>
	</div>
</template>
<script>
import api from '../../api'
import { EventBus } from '../../event-bus'
import Geraeteimport from '../../components/Geraeteimport'
import Geraete from '../../components/Geraete'
import Uebungen from '../../components/Uebungen'
import Uebungensteps from '../../components/UebungenSteps'

export default {
	components: { Geraete, Geraeteimport, Uebungen, Uebungensteps },
	data() {
		return {
			geraete_db: [],
			filtered_geraete_db: [],
			search: '',
		}
	},
	methods: {
		toggleVisibility(uebung, counter, i) {
			api.toggleExcerciseVisibility({ uebung: uebung })
				.then((data) => {
					if (data.status) {
						this.filtered_geraete_db[i].uebungen[counter].uebung.active = !this.filtered_geraete_db[i].uebungen[counter].uebung.active
					}
				})
				.catch((error) => {
					alert('fehler')
				})
		},
		importGeraet() {
			this.$refs['geraeteimport'].show()
		},
		editUebungSteps(id) {
			this.$refs['uebungsteps'].show(id)
		},
		addUebung(old_id) {
			this.$refs['uebungen'].show(old_id)
		},
		editUebung(id) {
			this.$refs['uebungen'].show(id)
		},
		editGeraet(id) {
			this.$refs['geraete'].show(id)
		},
		addGeraet() {
			this.$refs['geraete'].show()
		},
		filterGeraete() {
			this.filtered_geraete_db = this.geraete_db.filter((geraet) => {
				return geraet.titel.toLowerCase().indexOf(this.search.toLowerCase()) > -1
			})
		},
		async loadUebungen() {
			this.geraete_db = []
			this.filtered_geraete_db = []

			let studio = null

			studio = await api.getUserStudio().catch((e) => {
				window.app.$root.$children[0].checkStudio()
			})
			studio = studio.studio
			let studio_id = studio._id
			api.getUebungbyStudioId(studio_id)
				.then((uebungen) => {
					api.getGeraetebyStudioId(studio_id)
						.then((geraete) => {
							for (let geraet of geraete) {
								let geraet_tmp = geraet
								geraet_tmp.uebungen = []

								for (let uebung of uebungen) {
									if (geraet.old_id == uebung.geraet.old_id) {
										geraet_tmp.uebungen.push(uebung)
									}
								}
								this.geraete_db.push(geraet_tmp)
							}
							this.filtered_geraete_db = this.geraete_db
						})
						.catch((error) => {
							console.log(error)
						})
				})
				.catch((error) => {
					console.log(error)
				})
		},
	},

	created() {
		this.loadUebungen()
	},
}
</script>
<style lang="scss" scoped>
.togglevisible {
	margin-right: 10px;
}
.edit-uebung-ac {
	margin-left: 5px;
}
.edit-site {
	span {
		padding: 5px 0px 5px 5px;
	}
}
.edit-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	.add-geraet {
		padding: 10px 15px;
		background-color: var(--v-primary);
	}
	.import-geraet {
		padding: 10px 15px;
		background-color: var(--v-primary);
	}
}
.search {
	position: relative;
	margin-bottom: 10px;
	.material-icons {
		position: absolute;
		top: 10px;
		right: 20px;
		color: black;
	}
	input {
		color: black;
		width: 100%;
		padding: 15px 15px 10px 15px;
		border-bottom: 1px solid black;
	}
}
.geraete {
	width: 85vw;
	margin: auto;
	color: white;
	.panel-group {
		padding: 5px 15px;
		color: white;
		background-color: #4a4a4a;
		border-radius: 3px;

		margin-bottom: 5px;
	}
	.panel-title {
		margin-bottom: 0px;
		line-height: 32px;
		display: flex;
		justify-content: space-between;
	}
	.uebung-geraet-titel {
		margin-top: 10px;
		margin-bottom: 16px;
		/* border-bottom: 1px solid black; */
		display: flex;
		justify-content: space-between;
	}
	a {
		color: white;
		font-size: 16px;
	}
}
.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
