export const loadinganimationStore = {
  namespaced: true,
  state: {
    loading_animation: false,
  },
  mutations: {
    toggleLoading(state) {
      state.loading_animation = !state.loading_animation
    },
  },

  actions: {
    toggleLoading({ commit }) {
      commit('toggleLoading')
    },
  },
}
