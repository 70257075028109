<template>
    <v-card dark elevation="0" max-width="700px" class="mx-auto mb-15 mt-5">
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-card-title class="font-weight-bold">Einstellungen</v-card-title>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <v-btn @click="deleteGeschaefte">delete Geschäfte from all packages</v-btn>
                    </v-col>
                </v-row>
                <v-expansion-panels flat multiple>
                    <v-expansion-panel ref="company">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Vertragspartner</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <v-list-item-group color="primary">
                                            <v-list-item
                                                @click="editField(index, 'editCompanyEntry', 'companies')"
                                                v-for="(company, index) of companies"
                                                :key="index + '-companyIndex'"
                                                :style="getTextColor(company)"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        class="font-weight-bold"
                                                        v-text="company.name"
                                                    ></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="addField('company')"><span>Hinzufügen</span></v-btn>
                                </v-col>
                            </v-row>
                            <v-dialog
                                max-width="500px"
                                class="contractSettings__dialog"
                                background="white"
                                :value="editCompanyEntry"
                                persistent
                            >
                                <v-card v-if="editCompanyEntry" elevation="0" max-width="500px" class="mx-auto editor">
                                    <v-card-title
                                        class="font-weight-bold contractSettings__dialog-title justify-center mb-5"
                                    >
                                        {{ editCompanyEntry.name }}
                                    </v-card-title>
                                    <v-row no-gutters class="px-5">
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editCompanyEntry.name"
                                                label="Firmenname"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editCompanyEntry.street"
                                                label="Strasse"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editCompanyEntry.city"
                                                label="PLZ / Ort"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editCompanyEntry.phone"
                                                label="Telefon"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                label="Studios"
                                                multiple
                                                v-model="editCompanyEntry.studios"
                                                :items="filterStudios('companies')"
                                                item-text="name"
                                                item-value="_id"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch
                                                @change="$forceUpdate()"
                                                v-model="editCompanyEntry.useMainCompany"
                                                label="Stammcenter verwenden"
                                            ></v-switch>
                                        </v-col>

                                        <v-col v-if="editCompanyEntry.useMainCompany" cols="12">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        class="py-0 my-0"
                                                        v-model="editCompanyEntry.mainCompany.name"
                                                        label="Firmenname"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        class="py-0 my-0"
                                                        v-model="editCompanyEntry.mainCompany.street"
                                                        label="Strasse"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        class="py-0 my-0"
                                                        v-model="editCompanyEntry.mainCompany.city"
                                                        label="PLZ / Ort"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        class="py-0 my-0"
                                                        v-model="editCompanyEntry.mainCompany.phone"
                                                        label="Telefonnummer"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col class="d-flex justify-center" cols="12">
                                            <v-btn
                                                class="mb-5"
                                                small
                                                text
                                                @click="
                                                    editCompanyEntry.studios = [
                                                        ...filterStudios('companies').map((e) => e._id),
                                                    ]
                                                "
                                            >
                                                Alle Studios auswählen
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-card-actions class="contractSettings__dialog-actions">
                                        <v-btn class="white--text" text @click="editCompanyEntry = null">
                                            Abbrechen
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="white--text"
                                            text
                                            @click="saveField('editCompanyEntry', 'companies')"
                                        >
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel ref="category">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Mitgliedschaftskategorien</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <v-list-item-group color="primary">
                                            <v-list-item
                                                @click="editField(index, 'editCategoryEntry', 'categories')"
                                                v-for="(category, index) of categories"
                                                :key="index + '-categoryIndex'"
                                                :style="getTextColor(category)"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        class="font-weight-bold"
                                                        v-text="category.title"
                                                    ></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="addField('category')"><span>Hinzufügen</span></v-btn>
                                </v-col>
                            </v-row>
                            <v-dialog
                                max-width="500px"
                                class="contractSettings__dialog"
                                background="white"
                                :value="editCategoryEntry"
                                persistent
                            >
                                <v-card v-if="editCategoryEntry" elevation="0" max-width="500px" class="mx-auto editor">
                                    <v-card-title
                                        class="font-weight-bold contractSettings__dialog-title justify-center mb-5"
                                    >
                                        {{ editCategoryEntry.title }}
                                    </v-card-title>
                                    <v-row no-gutters class="px-5">
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editCategoryEntry.title"
                                                label="Bezeichnung"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-textarea
                                                counter="150"
                                                v-model="editCategoryEntry.description"
                                                label="Beschreibung"
                                            ></v-textarea>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="editCategoryEntry.notes"
                                                label="Notizfeld anzeigen"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col v-if="editCategoryEntry.notes" cols="12">
                                            <v-text-field
                                                v-model="editCategoryEntry.additionalInfo"
                                                label="Label für Notizfeld"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col v-if="editCategoryEntry.notes" cols="12">
                                            <v-switch
                                                v-model="editCategoryEntry.notesSelection"
                                                label="vorgegebene Antwortmöglichkeiten"
                                            ></v-switch>
                                        </v-col>
                                        <v-col v-if="editCategoryEntry.notesSelection" cols="12">
                                            <div>
                                                <v-text-field
                                                    v-model="newNote"
                                                    label="neue Auswahlmöglichkeit"
                                                ></v-text-field>
                                                <v-btn color="primary" @click="addNote">Hinzufügen</v-btn>
                                                <v-list>
                                                    <v-list-item
                                                        v-for="(note, index) in editCategoryEntry.notesSelectionOptions"
                                                        :key="index"
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ note }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn color="error" icon @click="removeNote(index)">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list>
                                                <!--- <v-btn color="primary" @click="sortNotes">Sort Notes</v-btn>-->
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                counter="150"
                                                v-model="editCategoryEntry.notesDescription"
                                                label="Beschreibung für Auswahl"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="editCategoryEntry.noSignUpFee"
                                                label="Kein Aktivierungs-Tarif"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                label="Studios"
                                                multiple
                                                v-model="editCategoryEntry.studios"
                                                :items="filterStudios('categories')"
                                                item-text="name"
                                                item-value="_id"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="d-flex justify-center" cols="12">
                                            <v-btn
                                                class="mb-5"
                                                small
                                                text
                                                @click="
                                                    editCategoryEntry.studios = [
                                                        ...filterStudios('categories').map((e) => e._id),
                                                    ]
                                                "
                                            >
                                                Alle Studios auswählen
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-card-actions class="contractSettings__dialog-actions">
                                        <v-btn class="white--text" text @click="editCategoryEntry = null">
                                            Abbrechen
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="white--text"
                                            text
                                            @click="saveField('editCategoryEntry', 'categories')"
                                        >
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel ref="memberships">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Mitgliedschaften</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <v-list-item-group>
                                            <v-list-item v-if="false">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-btn color="red" @click="deleteMempershipGeschaefte">
                                                            Geschäfte löschen
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item>
                                            <v-list-item
                                                v-for="(membership, index) of memberships"
                                                :key="index + '-membershipIndex'"
                                                :style="getTextColorMembership(membership)"
                                            >
                                                <v-list-item-content>
                                                    <v-row no-gutters>
                                                        <v-col cols="8" class="align-center d-flex">
                                                            <v-list-item-title
                                                                class="font-weight-bold"
                                                                v-text="
                                                                    membership.title + ' ' + getFlexText(membership)
                                                                "
                                                            ></v-list-item-title>
                                                        </v-col>
                                                        <v-col cols="4" class="d-flex align-center justify-end">
                                                            <v-btn
                                                                v-if="userService.hasRight('c_create')"
                                                                icon
                                                                @click="showMembershipDeleteDialog = membership.title"
                                                            >
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                            <v-btn
                                                                icon
                                                                @click="
                                                                    editField(
                                                                        index,
                                                                        'editMembershipEntry',
                                                                        'memberships'
                                                                    )
                                                                "
                                                            >
                                                                <v-icon>mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <basic-dialog
                                                            @onCancel="showMembershipDeleteDialog = null"
                                                            @onSave="deleteMembership(membership)"
                                                            dialogTitle="Mitgliedschaft löschen"
                                                            dialogSaveButtonText="Löschen"
                                                            :cancelButtonDisabledState="
                                                                membershipDeleteDialogLoadingState
                                                            "
                                                            :saveButtonLoadingState="membershipDeleteDialogLoadingState"
                                                            :dialogVisible="
                                                                showMembershipDeleteDialog === membership.title
                                                            "
                                                        >
                                                            <v-card-text class="mt-0">
                                                                Möchtest du die Mitgliedschaft unwiederruflich löschen?
                                                            </v-card-text>
                                                        </basic-dialog>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="addField('membership')">
                                        <span>Hinzufügen</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-dialog
                                max-width="500px"
                                class="contractSettings__dialog"
                                background="white"
                                :value="editMembershipEntry"
                                persistent
                            >
                                <v-card
                                    v-if="editMembershipEntry"
                                    elevation="0"
                                    max-width="500px"
                                    class="mx-auto editor"
                                >
                                    <v-card-title
                                        class="font-weight-bold contractSettings__dialog-title justify-center mb-5"
                                        style="word-break: break-word"
                                    >
                                        {{ editMembershipEntry.title }}
                                    </v-card-title>
                                    <v-row no-gutters class="px-5">
                                        <v-col cols="12">
                                            <v-text-field
                                                v-if="false"
                                                :disabled="checkIfUsedInPackages(editMembershipEntry.title).used"
                                                v-model="editMembershipEntry.title"
                                                label="Bezeichnung"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="editMembershipEntry.title"
                                                label="Bezeichnung"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                v-model="editMembershipEntry.description"
                                                label="Beschreibung (wird als zus. Text unterhalb der Mitgliedschaft angezeigt)"
                                                counter="150"
                                            ></v-textarea>
                                            <v-card-subtitle class="mt-0 mb-2">
                                                Umbrüche können mit &lt;br&gt; eingefügt werden
                                            </v-card-subtitle>
                                        </v-col>
                                        <v-col cols="12" v-if="checkIfUsedInPackages(editMembershipEntry.title).used">
                                            <v-alert type="info">
                                                Wird in folgenden Zusatzpaketen benutzt:
                                                <br />
                                                {{ checkIfUsedInPackages(editMembershipEntry.title).text }}
                                            </v-alert>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch
                                                :disabled="checkIfUsedInPackages(editMembershipEntry.title).used"
                                                v-model="editMembershipEntry.flexActive"
                                                label="Flexible Laufzeit?"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12" v-if="editMembershipEntry.flexActive">
                                            <v-select
                                                :disabled="checkIfUsedInPackages(editMembershipEntry.title).used"
                                                v-model="editMembershipEntry.flexSelection"
                                                :items="[
                                                    { name: 'pro Tag', value: 'perDay' },
                                                    { name: 'pro Monat', value: 'perMonth' },
                                                ]"
                                                @change="changeStudios('editMembershipEntry')"
                                                item-text="name"
                                                item-value="value"
                                                label="Flexibles Intervall"
                                            ></v-select>
                                        </v-col>
                                        <v-row>
                                            <v-col
                                                cols="6"
                                                v-if="editMembershipEntry.flexActive && editMembershipEntry.flexLimits"
                                            >
                                                <v-text-field
                                                    type="number"
                                                    min="1"
                                                    max="100"
                                                    @change="changeMembershipEntryFlexLimit(editMembershipEntry)"
                                                    v-model="editMembershipEntry.flexLimits.min"
                                                    :label="
                                                        'min Anzahl ' + getLabelText(editMembershipEntry.flexSelection)
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                                v-if="editMembershipEntry.flexActive && editMembershipEntry.flexLimits"
                                            >
                                                <v-text-field
                                                    type="number"
                                                    @change="changeMembershipEntryFlexLimit(editMembershipEntry)"
                                                    min="1"
                                                    max="100"
                                                    v-model="editMembershipEntry.flexLimits.max"
                                                    :label="
                                                        'max Anzahl ' + getLabelText(editMembershipEntry.flexSelection)
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-col class="d-flex justify-center" cols="12">
                                            <v-btn
                                                class="mb-5"
                                                small
                                                text
                                                @click="setStudios('editMembershipEntry', 'memberships')"
                                            >
                                                Alle Studios auswählen
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                label="Studios"
                                                multiple
                                                v-model="editMembershipEntry.studios"
                                                @change="changeStudios('editMembershipEntry')"
                                                :items="filterStudios('memberships')"
                                                item-text="name"
                                                item-value="_id"
                                            ></v-select>
                                        </v-col>

                                        <v-expansion-panels
                                            class="mb-5"
                                            v-model="membershipDurationPanel"
                                            v-if="editMembershipEntry && editMembershipEntry.studioSettings"
                                        >
                                            <v-expansion-panel
                                                v-for="(d, studioId) in editMembershipEntry.studioSettings"
                                                :key="studioId + '-pricingMembership' + d.durations.length"
                                            >
                                                <v-expansion-panel-header>
                                                    {{ studios.find((e) => e._id === studioId).name }}
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row v-if="!editMembershipEntry.flexActive">
                                                        <v-col cols="12">
                                                            <v-card-subtitle class="px-0 mx-0">
                                                                Laufzeiten und Preise
                                                            </v-card-subtitle>
                                                        </v-col>
                                                    </v-row>

                                                    <v-expansion-panels v-if="!editMembershipEntry.flexActive">
                                                        <v-expansion-panel
                                                            v-for="(duration, i) of d.durations"
                                                            :key="i + '-durationIndex' + studioId + d.durations.length"
                                                        >
                                                            <v-expansion-panel-header>
                                                                {{ i + 1 }}. Laufzeit
                                                            </v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                <v-row>
                                                                    <v-col cols="4">
                                                                        <v-text-field
                                                                            v-model="duration.years"
                                                                            type="number"
                                                                            label="Jahre"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="4">
                                                                        <v-text-field
                                                                            v-model="duration.months"
                                                                            type="number"
                                                                            label="Monate"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="4">
                                                                        <v-text-field
                                                                            v-model="duration.days"
                                                                            type="number"
                                                                            label="Tage"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col class="my-0 py-0" cols="12">
                                                                        <v-switch
                                                                            @change="$forceUpdate()"
                                                                            v-model="duration.saleActive"
                                                                            label="Aktion"
                                                                        ></v-switch>
                                                                    </v-col>
                                                                    <v-col
                                                                        class="my-0 py-0"
                                                                        v-if="duration.saleActive"
                                                                        cols="12"
                                                                    >
                                                                        <v-switch
                                                                            @change="$forceUpdate()"
                                                                            v-model="duration.saleGiftSignUpFee"
                                                                            label="Aktivierungs-Tarif schenken"
                                                                        ></v-switch>
                                                                    </v-col>
                                                                    <v-col
                                                                        class="my-0 py-0"
                                                                        v-if="duration.saleActive"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="duration.salePrice"
                                                                            type="number"
                                                                            label="Aktionspreis"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col
                                                                        class="my-0 py-0"
                                                                        v-if="duration.saleActive"
                                                                        cols="12"
                                                                    >
                                                                        <v-switch
                                                                            @change="$forceUpdate()"
                                                                            v-model="duration.saleTimeLimit"
                                                                            label="Zeitlich begrenzt"
                                                                        ></v-switch>
                                                                    </v-col>
                                                                    <v-col v-if="duration.saleTimeLimit" cols="12">
                                                                        <v-text-field
                                                                            type="date"
                                                                            v-model="duration.saleFrom"
                                                                            label="Aktion von"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col
                                                                        class="mt-0 pt-0"
                                                                        v-if="duration.saleTimeLimit"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            type="date"
                                                                            v-model="duration.saleTill"
                                                                            label="Aktion bis"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col class="my-0 py-0" cols="12">
                                                                        <v-text-field
                                                                            v-model="duration.price"
                                                                            type="number"
                                                                            label="Preis"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col class="my-0 py-0" cols="12">
                                                                        <v-text-field
                                                                            v-model="duration.signUpFee"
                                                                            type="number"
                                                                            label="Aktivierungs-Tarif"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="12" class="d-flex justify-center">
                                                                        <v-btn
                                                                            color="red"
                                                                            class="white--text"
                                                                            @click="
                                                                                removeDurationFromStudio(
                                                                                    i,
                                                                                    studioId,
                                                                                    'membershipDurationPanel',
                                                                                    'editMembershipEntry'
                                                                                )
                                                                            "
                                                                        >
                                                                            Löschen
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-expansion-panel-content>
                                                        </v-expansion-panel>
                                                    </v-expansion-panels>
                                                    <v-row
                                                        v-if="
                                                            d.durations.length === 0 &&
                                                            !editMembershipEntry.flexActive &&
                                                            Object.keys(editMembershipEntry.studioSettings).filter(
                                                                (e) => e !== studioId
                                                            ).length > 0
                                                        "
                                                    >
                                                        <v-col cols="8">
                                                            <v-select
                                                                :items="
                                                                    Object.keys(editMembershipEntry.studioSettings)
                                                                        .filter((e) => e !== studioId)
                                                                        .map((e) => {
                                                                            return {
                                                                                name: studios.find((s) => s._id === e)
                                                                                    .name,
                                                                                value: e,
                                                                            }
                                                                        })
                                                                "
                                                                v-model="adaptPeriodSelect"
                                                                item-text="name"
                                                                item-value="value"
                                                                label="von anderem Studio übernehmen"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-btn
                                                                @click="
                                                                    adaptPeriod(
                                                                        studioId,
                                                                        'editMembershipEntry',
                                                                        'membershipDurationPanel'
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row v-if="!editMembershipEntry.flexActive">
                                                        <v-col cols="12" class="d-flex justify-center mt-3">
                                                            <v-btn
                                                                color="primary"
                                                                @click="
                                                                    addDurationToStudio(
                                                                        studioId,
                                                                        'membershipDurationPanel',
                                                                        'editMembershipEntry'
                                                                    )
                                                                "
                                                            >
                                                                <span style="font-size: 24px; font-weight: bold">
                                                                    +
                                                                </span>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row
                                                        v-if="
                                                            editMembershipEntry.flexActive &&
                                                            editMembershipEntry.flexSelection
                                                        "
                                                    >
                                                        <v-col
                                                            class="pb-0 mb-0"
                                                            cols="12"
                                                            v-if="
                                                                d.flex.prices &&
                                                                editMembershipEntry.flexSelection in d.flex.prices
                                                            "
                                                        >
                                                            <v-card-subtitle class="px-0 mx-0">
                                                                Preise
                                                                {{
                                                                    editMembershipEntry.flexSelection.toString() ===
                                                                    'perMonth'
                                                                        ? 'pro Monat'
                                                                        : 'pro Tag'
                                                                }}
                                                            </v-card-subtitle>
                                                            <v-text-field
                                                                v-model="
                                                                    d.flex.prices[editMembershipEntry.flexSelection]
                                                                        .price
                                                                "
                                                                type="number"
                                                                label="Preis"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col class="pt-0 mt-0" cols="12">
                                                            <v-text-field
                                                                v-model="
                                                                    d.flex.prices[editMembershipEntry.flexSelection]
                                                                        .signUpFee
                                                                "
                                                                type="number"
                                                                label="Aktivierungs-Tarif"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-row>

                                    <v-card-actions class="contractSettings__dialog-actions">
                                        <v-btn class="white--text" text @click="editMembershipEntry = null">
                                            Abbrechen
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="white--text"
                                            text
                                            @click="saveField('editMembershipEntry', 'memberships')"
                                        >
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel ref="packages">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Zusatzpakete</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <v-list-item-group>
                                            <v-list-item
                                                v-for="(packag, index) of packages"
                                                :key="index + '-packageIndex'"
                                                :style="getTextColor(packag)"
                                            >
                                                <v-list-item-content>
                                                    <v-row no-gutters>
                                                        <v-col cols="8">
                                                            <v-list-item-title
                                                                class="font-weight-bold"
                                                                v-text="packag.title"
                                                            ></v-list-item-title>
                                                        </v-col>
                                                        <v-col cols="4" class="d-flex justify-end">
                                                            <v-btn
                                                                icon
                                                                @click="
                                                                    editField(index, 'editPackageEntry', 'packages')
                                                                "
                                                            >
                                                                <v-icon>mdi-pencil</v-icon>
                                                            </v-btn>
                                                            <v-btn icon @click="copyPackage(packag)">
                                                                <v-icon>mdi-content-copy</v-icon>
                                                            </v-btn>
                                                            <v-btn icon @click="packages.splice(index, 1)">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="addField('package')"><span>Hinzufügen</span></v-btn>
                                </v-col>
                            </v-row>
                            <v-dialog
                                max-width="500px"
                                class="contractSettings__dialog"
                                background="white"
                                :value="editPackageEntry"
                                persistent
                            >
                                <v-card v-if="editPackageEntry" elevation="0" max-width="500px" class="mx-auto editor">
                                    <v-card-title
                                        class="font-weight-bold contractSettings__dialog-title justify-center mb-5"
                                    >
                                        {{ editPackageEntry.title }}
                                    </v-card-title>

                                    <v-row no-gutters class="px-5">
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editPackageEntry.title"
                                                label="Bezeichnung"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row class="upload-section">
                                                <v-col cols="12" class="mb-0">
                                                    <v-file-input
                                                        multiple
                                                        @change="uploadImagesPackage"
                                                        label="Bilder auswählen"
                                                    ></v-file-input>
                                                </v-col>
                                            </v-row>

                                            <!-- Gallery Section -->
                                            <v-row class="gallery-section">
                                                <v-col cols="12">
                                                    <div
                                                        ref="scrollContainer"
                                                        @scroll="updateActiveIndexPackages"
                                                        style="
                                                            overflow-x: scroll;
                                                            white-space: nowrap;
                                                            scroll-snap-type: x mandatory;
                                                        "
                                                    >
                                                        <v-card
                                                            elevation="0"
                                                            transparent
                                                            v-for="(image, index) in editPackageEntry.gallery"
                                                            :key="index"
                                                            ref="cards"
                                                            style="
                                                                display: inline-block;
                                                                scroll-snap-align: center;
                                                                width: 100%;
                                                            "
                                                        >
                                                            <v-img
                                                                contain
                                                                max-height="200px"
                                                                :src="'/api/' + image.src"
                                                                alt="Uploaded Image"
                                                            ></v-img>
                                                            <v-text-field
                                                                label="Titel"
                                                                v-model="image.title"
                                                            ></v-text-field>
                                                            <v-card-actions>
                                                                <v-btn
                                                                    class="mx-auto"
                                                                    @click="deleteImagePackage(index)"
                                                                    color="error"
                                                                >
                                                                    Löschen
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </div>
                                                </v-col>
                                                <v-col class="mb-5" cols="12" style="text-align: center">
                                                    <span
                                                        v-for="(image, index) in editPackageEntry.gallery"
                                                        :key="'dot-' + index"
                                                        @click="scrollToIndexPackages(index)"
                                                        :class="['dot', { active: activeIndexPackages === index }]"
                                                    ></span>
                                                </v-col>
                                                <v-col
                                                    v-if="
                                                        false &&
                                                        editPackageEntry.gallery &&
                                                        editPackageEntry.gallery.length > 0
                                                    "
                                                    cols="12"
                                                    style="text-align: center"
                                                >
                                                    <p>
                                                        Total Bilder:
                                                        {{ editPackageEntry.gallery.length }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                counter="150"
                                                v-model="editPackageEntry.description"
                                                label="Beschreibung (wird in einem Popup angezeigt)"
                                            ></v-textarea>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-switch
                                                v-model="editPackageEntry.amountSelectable"
                                                label="Anzahl auswählbar"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch
                                                v-model="editPackageEntry.showAsUpgradePossibility"
                                                label="Als Upgrade anzeigen"
                                            ></v-switch>
                                        </v-col>
                                        <v-col v-if="editPackageEntry.showAsUpgradePossibility" cols="12">
                                            <v-switch
                                                v-model="editPackageEntry.calculateWithEndDate"
                                                label="Upgrade Anteilsmässig berechnen"
                                            ></v-switch>
                                        </v-col>
                                        <v-col v-if="editPackageEntry.showAsUpgradePossibility" cols="12">
                                            <v-switch
                                                v-model="editPackageEntry.calculateWithEndDateSixMonths"
                                                label="Upgrade Rabatt ab 6 Monate 50%"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch
                                                v-model="editPackageEntry.hideForMinors"
                                                label="Für Minderjährige (<18) im online Shop ausblenden"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch
                                                v-if="false"
                                                v-model="editPackageEntry.flexActive"
                                                label="Flexible Laufzeit?"
                                            ></v-switch>
                                        </v-col>

                                        <v-col v-if="false" cols="12">
                                            {{ editPackageEntry }}
                                        </v-col>

                                        <v-col class="d-flex justify-center" cols="12">
                                            <v-btn
                                                class="mb-5"
                                                small
                                                text
                                                @click="setStudios('editPackageEntry', 'packages')"
                                            >
                                                Alle Studios auswählen
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                label="Studios"
                                                multiple
                                                v-model="editPackageEntry.studios"
                                                @change="changeStudios('editPackageEntry')"
                                                :items="filterStudios('packages')"
                                                item-text="name"
                                                item-value="_id"
                                            ></v-select>
                                        </v-col>

                                        <v-expansion-panels
                                            class="mb-5"
                                            v-model="packagesDurationPanel"
                                            v-if="editPackageEntry && editPackageEntry.studioSettings"
                                        >
                                            <v-expansion-panel
                                                v-for="(d, studioId) in editPackageEntry.studioSettings"
                                                :key="studioId + '-pricingPackages' + d.durations.length"
                                            >
                                                <v-expansion-panel-header
                                                    :color="
                                                        checkDurationsForAllMemberships(
                                                            editPackageEntry.studioSettings[studioId].memberships,
                                                            studioId
                                                        ).status
                                                            ? 'red'
                                                            : 'white'
                                                    "
                                                >
                                                    {{ studios.find((e) => e._id === studioId).name }}
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row>
                                                        <v-col
                                                            cols="12"
                                                            v-if="
                                                                checkDurationsForAllMemberships(
                                                                    editPackageEntry.studioSettings[studioId]
                                                                        .memberships,
                                                                    studioId
                                                                ).status
                                                            "
                                                        >
                                                            <v-btn
                                                                @click="
                                                                    checkDurationsForAllMemberships(
                                                                        editPackageEntry.studioSettings[studioId]
                                                                            .memberships,
                                                                        studioId,
                                                                        true
                                                                    )
                                                                "
                                                            >
                                                                Änderungen übernehmen
                                                            </v-btn>
                                                            <ul>
                                                                <li
                                                                    v-for="(
                                                                        reason, index
                                                                    ) in checkDurationsForAllMemberships(
                                                                        editPackageEntry.studioSettings[studioId]
                                                                            .memberships,
                                                                        studioId
                                                                    ).reason"
                                                                    :key="index"
                                                                >
                                                                    {{ reason }}
                                                                </li>
                                                            </ul>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-select
                                                                label="Mitgliedschaften auswählen"
                                                                multiple
                                                                v-model="
                                                                    editPackageEntry.studioSettings[studioId]
                                                                        .memberships
                                                                "
                                                                @change="changeMembershipsInPackage(studioId)"
                                                                :items="
                                                                    memberships.filter((m) =>
                                                                        m.studios.includes(studioId)
                                                                    )
                                                                "
                                                                item-text="title"
                                                                return-object
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-switch
                                                                label="zusätzliche Bestätigung erforderlich"
                                                                v-model="
                                                                    editPackageEntry.studioSettings[studioId]
                                                                        .showAdditionalConfirmation
                                                                "
                                                                @change="changeAdditionalConfirmation"
                                                            ></v-switch>
                                                        </v-col>

                                                        <v-col
                                                            cols="12"
                                                            v-if="
                                                                editPackageEntry.studioSettings[studioId]
                                                                    .showAdditionalConfirmation
                                                            "
                                                        >
                                                            <v-textarea
                                                                placeholder="Text für zusätzliche Bestätigung"
                                                                v-model="
                                                                    editPackageEntry.studioSettings[studioId]
                                                                        .additionalConfirmationText
                                                                "
                                                            ></v-textarea>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-switch
                                                                label="zusätzliche Information anzeigen (auch Printversion)"
                                                                v-model="
                                                                    editPackageEntry.studioSettings[studioId]
                                                                        .showAdditionalInfoPrint
                                                                "
                                                                @change="changeAdditionalConfirmation"
                                                            ></v-switch>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            class="mt-0 pt-0"
                                                            v-if="
                                                                editPackageEntry.studioSettings[studioId]
                                                                    .showAdditionalInfoPrint
                                                            "
                                                        >
                                                            <v-text-field
                                                                placeholder="Text für zusätzliche Information (auch Print)"
                                                                v-model="
                                                                    editPackageEntry.studioSettings[studioId]
                                                                        .showAdditionalInfoPrintText
                                                                "
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-expansion-panels>
                                                                <v-expansion-panel
                                                                    v-for="(membership, j) of editPackageEntry
                                                                        .studioSettings[studioId].memberships"
                                                                    :key="j + '-packageMembershipIndex' + studioId"
                                                                >
                                                                    <v-expansion-panel-header
                                                                        :color="
                                                                            checkMembershipSettings(
                                                                                membership,
                                                                                studioId,
                                                                                false
                                                                            ).status
                                                                                ? 'red'
                                                                                : 'white'
                                                                        "
                                                                    >
                                                                        {{ membership.title }}
                                                                    </v-expansion-panel-header>
                                                                    <v-expansion-panel-content>
                                                                        <v-row>
                                                                            <v-col
                                                                                cols="12"
                                                                                v-if="
                                                                                    checkMembershipSettings(
                                                                                        membership,
                                                                                        studioId,
                                                                                        false
                                                                                    ).status
                                                                                "
                                                                            >
                                                                                <v-btn
                                                                                    @click="
                                                                                        checkMembershipSettings(
                                                                                            membership,
                                                                                            studioId,
                                                                                            true
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    Änderungen übernehmen
                                                                                </v-btn>
                                                                                <ul>
                                                                                    <li
                                                                                        v-for="(
                                                                                            reason, index
                                                                                        ) in checkMembershipSettings(
                                                                                            membership,
                                                                                            studioId,
                                                                                            false
                                                                                        ).reason"
                                                                                        :key="index"
                                                                                    >
                                                                                        {{ reason }}
                                                                                    </li>
                                                                                </ul>
                                                                            </v-col>
                                                                            <v-col cols="12">
                                                                                <v-expansion-panels
                                                                                    v-if="!membership.flexActive"
                                                                                >
                                                                                    <v-expansion-panel
                                                                                        v-for="(
                                                                                            duration, y
                                                                                        ) of membership.packageDurations"
                                                                                        :key="
                                                                                            y +
                                                                                            '-packageMembershipDurationIndex' +
                                                                                            studioId
                                                                                        "
                                                                                    >
                                                                                        <v-expansion-panel-header>
                                                                                            <span
                                                                                                v-if="
                                                                                                    duration.years !== 0
                                                                                                "
                                                                                            >
                                                                                                {{ duration.years }}
                                                                                                {{
                                                                                                    parseInt(
                                                                                                        duration.years
                                                                                                    ) !== 1
                                                                                                        ? 'Jahre'
                                                                                                        : 'Jahr'
                                                                                                }}
                                                                                            </span>
                                                                                            <span
                                                                                                v-if="
                                                                                                    duration.months !==
                                                                                                    0
                                                                                                "
                                                                                            >
                                                                                                {{ duration.months }}
                                                                                                {{
                                                                                                    parseInt(
                                                                                                        duration.months
                                                                                                    ) !== 1
                                                                                                        ? 'Monate'
                                                                                                        : 'Monat'
                                                                                                }}
                                                                                            </span>
                                                                                            <span
                                                                                                v-if="
                                                                                                    duration.days !== 0
                                                                                                "
                                                                                            >
                                                                                                {{ duration.days }}
                                                                                                {{
                                                                                                    parseInt(
                                                                                                        duration.days
                                                                                                    ) !== 1
                                                                                                        ? 'Tage'
                                                                                                        : 'Tag'
                                                                                                }}
                                                                                            </span>
                                                                                        </v-expansion-panel-header>
                                                                                        <v-expansion-panel-content>
                                                                                            <v-text-field
                                                                                                type="number"
                                                                                                v-model="
                                                                                                    duration.packagePrice
                                                                                                "
                                                                                                label="Preis"
                                                                                            ></v-text-field>
                                                                                        </v-expansion-panel-content>
                                                                                    </v-expansion-panel>
                                                                                </v-expansion-panels>
                                                                                <v-expansion-panels v-else>
                                                                                    <v-expansion-panel>
                                                                                        <v-expansion-panel-header>
                                                                                            FLEX
                                                                                            {{
                                                                                                membership.flexSelection.toString() ===
                                                                                                'perMonth'
                                                                                                    ? 'Monatlich'
                                                                                                    : 'Täglich'
                                                                                            }}
                                                                                        </v-expansion-panel-header>
                                                                                        <v-expansion-panel-content>
                                                                                            <v-text-field
                                                                                                type="number"
                                                                                                v-model="
                                                                                                    membership
                                                                                                        .packageFlex
                                                                                                        .price
                                                                                                "
                                                                                                label="Preis"
                                                                                            ></v-text-field>
                                                                                            <v-text-field
                                                                                                type="number"
                                                                                                v-model="
                                                                                                    membership
                                                                                                        .packageFlex.max
                                                                                                "
                                                                                                label="max. Preis"
                                                                                            ></v-text-field>
                                                                                        </v-expansion-panel-content>
                                                                                    </v-expansion-panel>
                                                                                </v-expansion-panels>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-expansion-panel-content>
                                                                </v-expansion-panel>
                                                            </v-expansion-panels>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-row>

                                    <v-card-actions class="contractSettings__dialog-actions">
                                        <v-btn class="white--text" text @click="editPackageEntry = null">
                                            Abbrechen
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="white--text"
                                            text
                                            @click="saveField('editPackageEntry', 'packages')"
                                        >
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel ref="paymentOptions">
                        <v-expansion-panel-header class="font-weight-bold">
                            <v-card-title class="ma-0 pa-0">Zahlungsbedingungen</v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <v-row class="d-flex justify-center">
                                <v-col cols="12">
                                    <v-list>
                                        <v-list-item-group color="primary">
                                            <v-list-item
                                                @click="editField(index, 'editPaymentOptionsEntry', 'paymentOptions')"
                                                v-for="(paymentOption, index) of paymentOptions"
                                                :key="index + '-paymentIndex'"
                                                :style="getTextColor(paymentOption)"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        class="font-weight-bold"
                                                        v-text="paymentOption.title"
                                                    ></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="primary" @click="addField('paymentOption')">
                                        <span>Hinzufügen</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-dialog
                                max-width="500px"
                                class="contractSettings__dialog"
                                background="white"
                                :value="editPaymentOptionsEntry"
                                persistent
                            >
                                <v-card
                                    v-if="editPaymentOptionsEntry"
                                    elevation="0"
                                    max-width="500px"
                                    class="mx-auto editor"
                                >
                                    <v-card-title
                                        class="font-weight-bold contractSettings__dialog-title justify-center mb-5"
                                    >
                                        {{ editPaymentOptionsEntry.title }}
                                    </v-card-title>
                                    <v-row no-gutters class="px-5">
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editPaymentOptionsEntry.title"
                                                label="Bezeichnung"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                counter="150"
                                                v-model="editPaymentOptionsEntry.description"
                                                label="Beschreibung"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editPaymentOptionsEntry.additionalInfo"
                                                label="Weitere Informationen"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="editPaymentOptionsEntry.selectDate"
                                                label="Datum auswählbar?"
                                            ></v-checkbox>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-select
                                                label="Studios"
                                                multiple
                                                v-model="editPaymentOptionsEntry.studios"
                                                :items="filterStudios('paymentOptions')"
                                                item-text="name"
                                                item-value="_id"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="d-flex justify-center" cols="12">
                                            <v-btn
                                                class="mb-5"
                                                small
                                                text
                                                @click="
                                                    editPaymentOptionsEntry.studios = [
                                                        ...filterStudios('paymentOptions').map((e) => e._id),
                                                    ]
                                                "
                                            >
                                                Alle Studios auswählen
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-card-actions class="contractSettings__dialog-actions">
                                        <v-btn class="white--text" text @click="editPaymentOptionsEntry = null">
                                            Abbrechen
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="white--text"
                                            text
                                            @click="saveField('editPaymentOptionsEntry', 'paymentOptions')"
                                        >
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row class="mt-10">
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn color="primary" @click="createNewContract">
                            <v-icon class="pr-2">mdi-folder-plus</v-icon>
                            <span>Vertrag erstellen</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-5">
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn @click="showBackupOptions = !showBackupOptions" text>
                            <span class="pr-2">Backup optionen</span>
                            <span v-if="showBackupOptions">ausblenden</span>
                            <span v-else>anzeigen</span>
                        </v-btn>
                    </v-col>
                    <v-col v-if="showBackupOptions" cols="12" class="d-flex justify-center">
                        <v-btn text @click="downloadBackup">Backup herunterladen</v-btn>
                    </v-col>
                    <v-col v-if="showBackupOptions" cols="12" class="d-flex justify-center">
                        <div class="input-wrapper" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop">
                            <label for="jsonFile" class="input-label">Ziehe dein Backup hier rein</label>
                            <input
                                id="jsonFile"
                                type="file"
                                class="input-hidden"
                                ref="fileInput"
                                @change="importJson"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import api from '../../../api'
import basicDialog from '@/components/tools/basicDialog'
import userService from '@/services/userService'

export default {
    components: { basicDialog },
    data() {
        return {
            activeIndexPackages: 0,
            userService,
            membershipDeleteDialogLoadingState: false,
            showMembershipDeleteDialog: null,
            newNote: '',
            studios: [],
            showBackupOptions: false,
            companies: [],
            editCompanyEntry: null,
            categories: [],
            editCategoryEntry: null,
            memberships: [],
            editMembershipEntry: null,
            membershipDurationPanel: null,
            adaptPeriodSelect: null,
            packages: [],
            editPackageEntry: null,
            packagesDurationPanel: null,
            paymentOptions: [],
            editPaymentOptionsEntry: null,
        }
    },
    mounted() {},

    created() {
        window.addEventListener('beforeunload', this.preventPageReload)
        this.fetchStudios()

        this.fetchContract()
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.preventPageReload)
    },
    methods: {
        scrollToIndexPackages(index) {
            /*
            const target = this.$refs.cards[index].$el
            target.scrollIntoView({ behavior: 'smooth', inline: 'center' })
            */
            const container = this.$refs.scrollContainer
            const target = this.$refs.cards[index].$el
            const rect = target.getBoundingClientRect()
            const containerRect = container.getBoundingClientRect()
            const scrollLeft = rect.left - containerRect.left + container.scrollLeft

            container.scrollTo({
                left: scrollLeft,
                behavior: 'smooth',
            })
        },
        updateActiveIndexPackages() {
            const cards = this.$refs.cards
            for (let i = 0; i < cards.length; i++) {
                const card = cards[i].$el
                const { left, right } = card.getBoundingClientRect()
                if (left < window.innerWidth / 2 && right > window.innerWidth / 2) {
                    this.activeIndexPackages = i
                    break
                }
            }
            this.$forceUpdate()
        },
        uploadImagesPackage(files) {
            for (let file of files) {
                const formData = new FormData()
                formData.append('file', file)

                api.uploadContractPicture(formData)
                    .then((response) => {
                        const uploadedImageName = response.name

                        if (!this.editPackageEntry.gallery) this.editPackageEntry.gallery = []
                        this.editPackageEntry.gallery.push({ title: '', file: {}, src: uploadedImageName })
                        this.$forceUpdate()
                    })
                    .catch((error) => {
                        console.log('Upload failed', error)
                    })
            }
        },

        deleteImagePackage(index) {
            this.editPackageEntry.gallery.splice(index, 1)
            this.$forceUpdate()
        },

        handleDragOver() {
            // Du könntest hier das Styling ändern, um dem Benutzer Feedback zu geben
        },
        handleDrop(event) {
            const files = event.dataTransfer.files
            if (files.length > 0) {
                const file = files[0]
                this.readFile(file)
            }
        },
        triggerInput() {
            this.$refs.fileInput.click()
        },
        importJson(event) {
            const file = event.target.files[0]
            this.readFile(file)
        },
        readFile(file) {
            const reader = new FileReader()

            reader.onload = (e) => {
                // Deine vorhandene Logik zum Importieren der JSON-Daten
                const importedJson = JSON.parse(e.target.result)
                const keys = Object.keys(importedJson)

                const keyMapper = {
                    paymentOptions: 'Zahlungsoptionen',
                    packages: 'Zusatzpakete',
                    memberships: 'Mitgliedschaften',
                    categories: 'Mitgliedschaftskategorien',
                    companies: 'Vertragspartner',
                }

                keys.forEach((key) => {
                    let name = keyMapper[key] || key
                    const shouldImport = window.confirm(`Möchtest du ${name} importieren?`)
                    if (shouldImport) {
                        this[key] = importedJson[key]
                    }
                })
            }

            reader.readAsText(file)
        },
        importBackup() {},
        downloadBackup() {
            let json = {
                paymentOptions: this.paymentOptions,
                packages: this.packages,
                memberships: this.memberships,
                categories: this.categories,
                companies: this.companies,
            }
            const jsonString = JSON.stringify(json)
            const blob = new Blob([jsonString], { type: 'application/json' })

            const url = URL.createObjectURL(blob)

            // Erstelle ein neues "a"-Element und konfiguriere es
            const a = document.createElement('a')
            a.href = url
            a.download = 'roodyContractBackup.json'

            // Füge das "a"-Element zum DOM hinzu und simuliere einen Klick
            document.body.appendChild(a)
            a.click()

            // Entferne das "a"-Element und die URL
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        },
        preventPageReload(event) {
            // Check if the dialog is open, and if so, prevent the page reload
            event.preventDefault()
            event.returnValue = ''
        },
        checkDurationsForAllMemberships(memberships, studioId, submit = false) {
            let isChanged = { status: false, reason: [] }
            try {
                for (let membership of memberships) {
                    let status = this.checkMembershipSettings(membership, studioId, submit)
                    if (status.status) {
                        isChanged.status = true
                        isChanged.reason = isChanged.reason.concat(status.reason)
                    }
                }
                return isChanged
            } catch (e) {
                console.log(e)
                return isChanged
            }
        },
        checkMembershipSettings(membership, studioId, change = false) {
            let isChanged = { status: false, reason: [] }
            try {
                if (!membership.flexActive) {
                    let membershipTitle = membership.title

                    let membershipOfficial = this.memberships.find((m) => m.title === membershipTitle)

                    if (!membership.studioSettings[studioId]) return isChanged
                    let membershipDurations = [...membership.studioSettings[studioId].durations]
                    let officialDurations = membershipOfficial.studioSettings[studioId].durations
                    // Bestehender Code

                    let newMembershipDurations = membershipDurations.filter((memDuration) =>
                        officialDurations.some(
                            (offDuration) =>
                                parseInt(offDuration.days) === parseInt(memDuration.days) &&
                                parseInt(offDuration.months) === parseInt(memDuration.months) &&
                                parseInt(offDuration.years) === parseInt(memDuration.years)
                        )
                    )

                    // Neue Laufzeiten identifizieren, die entfernt wurden
                    let removedDurations = membershipDurations.filter(
                        (memDuration) => !newMembershipDurations.includes(memDuration)
                    )

                    // Detailinformationen zu den entfernten Laufzeiten hinzufügen
                    removedDurations.forEach((removedDuration) => {
                        let reasonText = 'Laufzeit entfernt'

                        if (parseInt(removedDuration.years) !== 0) {
                            reasonText += ` ${removedDuration.years} Jahre`
                        }

                        if (parseInt(removedDuration.months) !== 0) {
                            reasonText += ` ${removedDuration.months} Monate`
                        }

                        if (parseInt(removedDuration.days) !== 0) {
                            reasonText += ` ${removedDuration.days} Tage`
                        }

                        if (reasonText !== 'Laufzeit entfernt') {
                            isChanged.status = true
                            isChanged.reason.push(reasonText)
                        }
                    })

                    officialDurations.forEach((offDuration) => {
                        const matchingMemDuration = newMembershipDurations.find(
                            (memDuration) =>
                                parseInt(offDuration.days) === parseInt(memDuration.days) &&
                                parseInt(offDuration.months) === parseInt(memDuration.months) &&
                                parseInt(offDuration.years) === parseInt(memDuration.years)
                        )

                        if (matchingMemDuration) {
                            if (offDuration.signUpFee !== matchingMemDuration.signUpFee) {
                                isChanged.status = true
                                isChanged.reason.push(
                                    'Anmeldegebühr aktualisiert von ' +
                                        matchingMemDuration.signUpFee +
                                        ' auf ' +
                                        offDuration.signUpFee +
                                        ''
                                )
                                if (change) {
                                    matchingMemDuration.signUpFee = offDuration.signUpFee.toString()
                                }
                            }
                        } else {
                            isChanged.status = true
                            let reasonText = 'Neue Laufzeiten hinzugefügt'

                            if (parseInt(offDuration.years) !== 0) {
                                reasonText += ` ${offDuration.years} Jahre`
                            }

                            if (parseInt(offDuration.months) !== 0) {
                                reasonText += ` ${offDuration.months} Monate`
                            }

                            if (parseInt(offDuration.days) !== 0) {
                                reasonText += ` ${offDuration.days} Tage`
                            }

                            if (reasonText !== 'Neue Laufzeiten hinzugefügt') {
                                isChanged.status = true
                                isChanged.reason.push(reasonText)
                            }

                            if (change) {
                                newMembershipDurations.push({ ...offDuration })
                            }
                        }
                    })

                    if (change) {
                        // Aktualisiere membership.studioSettings[studioId].durations
                        membership.studioSettings[studioId].durations = newMembershipDurations

                        // Aktualisiere jetzt membership.packageDurations auf ähnliche Weise
                        let newPackageDurations = membership.packageDurations.filter((pkgDuration) =>
                            newMembershipDurations.some(
                                (newDuration) =>
                                    parseInt(newDuration.days) === parseInt(pkgDuration.days) &&
                                    parseInt(newDuration.months) === parseInt(pkgDuration.months) &&
                                    parseInt(newDuration.years) === parseInt(pkgDuration.years)
                            )
                        )

                        newMembershipDurations.forEach((newDuration) => {
                            const matchingPkgDuration = newPackageDurations.find(
                                (pkgDuration) =>
                                    parseInt(newDuration.days) === parseInt(pkgDuration.days) &&
                                    parseInt(newDuration.months) === parseInt(pkgDuration.months) &&
                                    parseInt(newDuration.years) === parseInt(pkgDuration.years)
                            )

                            if (matchingPkgDuration) {
                                if (newDuration.signUpFee !== matchingPkgDuration.signUpFee) {
                                    isChanged.status = true
                                    isChanged.reason.push('Paket-Anmeldegebühr aktualisiert')
                                    matchingPkgDuration.signUpFee = newDuration.signUpFee.toString()
                                }
                            } else {
                                isChanged.status = true
                                isChanged.reason.push('Neue Paket-Laufzeiten hinzugefügt')
                                newPackageDurations.push({ ...newDuration })
                            }
                        })

                        membership.packageDurations = newPackageDurations
                    }
                } else {
                    // console.log('flexi found skipping')
                }
            } catch (e) {
                //   this.$toast.error('Fehler beim synchronisieren der Zusatzpakete')
            }
            if (isChanged.status && change) {
                this.$forceUpdate()
            }
            return isChanged
        },

        checkIfUsedInPackages(title) {
            let usedInPackage = {}

            this.packages.forEach((p) => {
                for (let studioId in p.studioSettings) {
                    if (p.studioSettings[studioId] && p.studioSettings[studioId].memberships) {
                        p.studioSettings[studioId].memberships.forEach((pm) => {
                            if (pm.title === title) {
                                if (!usedInPackage[p.title]) usedInPackage[p.title] = []
                                let studioName = this.studios.find((e) => e._id === studioId).name
                                if (!usedInPackage[p.title].includes(studioName))
                                    usedInPackage[p.title].push(studioName)
                            }
                        })
                    }
                }
            })

            if (Object.keys(usedInPackage).length === 0) return { used: false, text: '' }
            else {
                let text = ''
                for (let packageTitle in usedInPackage) {
                    text += packageTitle + ' (' + usedInPackage[packageTitle].join(', ') + '), '
                }
                text = text.slice(0, -2)
                return { used: true, text }
            }
        },
        changeAdditionalConfirmation() {
            this.$forceUpdate()
        },
        async deleteGeschaefte() {
            let names = ['Kombi Card Geschäfte', 'Kombi Card Geschäfte, 2 Raten', 'Kombi Card Geschäfte, 12 Raten']
            let backupPackages = JSON.parse(JSON.stringify(this.packages))
            for (let membershipTitle of names) {
                let activeMemberships = this.memberships.map((e) => e.title)
                this.memberships = this.memberships.filter((e) => e.title !== membershipTitle)
                backupPackages.forEach((packag) => {
                    packag.studios.forEach((packageStudio) => {
                        let preAmount = packag.studioSettings[packageStudio].memberships.length

                        packag.studioSettings[packageStudio].memberships = packag.studioSettings[
                            packageStudio
                        ].memberships.filter(
                            (e) =>
                                e.title.toString() !== membershipTitle.toString() && activeMemberships.includes(e.title)
                        )
                        let afterAmount = packag.studioSettings[packageStudio].memberships.length

                        if (preAmount !== afterAmount) {
                            console.log('DELETED')
                        }
                    })
                })
            }
            this.packages = backupPackages
        },
        async deleteMempershipGeschaefte() {
            let names = ['Kombi Card Geschäfte', 'Kombi Card Geschäfte, 2 Raten', 'Kombi Card Geschäfte, 12 Raten']
            for (name of names) {
                let membershipTitle = name
                console.log(membershipTitle)

                let activeMemberships = this.memberships.map((e) => e.title)
                this.memberships = this.memberships.filter((e) => e.title !== membershipTitle)
                this.packages.forEach((packag) => {
                    packag.studios.forEach((packageStudio) => {
                        let preAmount = packag.studioSettings[packageStudio].memberships.length
                        packag.studioSettings[packageStudio].memberships = packag.studioSettings[
                            packageStudio
                        ].memberships.filter(
                            (e) =>
                                e.title.toString() !== membershipTitle.toString() ||
                                !activeMemberships.includes(e.title)
                        )
                        let afterAmount = packag.studioSettings[packageStudio].memberships.length
                        if (preAmount !== afterAmount) {
                            console.log('DELETED')
                        }
                    })
                })

                let status = await api
                    .saveContractSettings({ data: this.memberships }, 'memberships')
                    .catch((e) => this.$toast.error('Es gab einen Fehler beim Speichern der Mitgliedschaften'))
                if (status) {
                    let status2 = await api
                        .saveContractSettings({ data: this.packages }, 'packages')
                        .catch((e) => this.$toast.error('Es gab einen Fehler beim Speichern der Zusatzpakete'))

                    if (status2) this.$toast.success('Erfolgreich gespeichert')
                }
            }
        },
        async deleteMembership(membership) {
            this.membershipDeleteDialogLoadingState = true
            let membershipTitle = membership.title

            this.memberships = this.memberships.filter((e) => e.title !== membershipTitle)
            let activeMemberships = this.memberships.map((e) => e.title)
            this.packages.forEach((packag) => {
                packag.studios.forEach((packageStudio) => {
                    let preAmount = packag.studioSettings[packageStudio].memberships.length
                    packag.studioSettings[packageStudio].memberships = packag.studioSettings[
                        packageStudio
                    ].memberships.filter(
                        (e) => e.title.toString() !== membershipTitle.toString() && activeMemberships.includes(e.title)
                    )
                    let afterAmount = packag.studioSettings[packageStudio].memberships.length
                    if (preAmount !== afterAmount) {
                        console.log('DELETED')
                    }
                })
            })

            let status = await api
                .saveContractSettings({ data: this.memberships }, 'memberships')
                .catch((e) => this.$toast.error('Es gab einen Fehler beim Speichern der Mitgliedschaften'))
            if (status) {
                let status2 = await api
                    .saveContractSettings({ data: this.packages }, 'packages')
                    .catch((e) => this.$toast.error('Es gab einen Fehler beim Speichern der Zusatzpakete'))

                if (status2) this.$toast.success('Erfolgreich gespeichert')
            }

            this.membershipDeleteDialogLoadingState = false
            this.showMembershipDeleteDialog = null
        },
        createNewContract() {
            this.$router.push('/dashboard/contract/create')
        },
        addNote() {
            if (this.newNote !== '') {
                this.editCategoryEntry.notesSelectionOptions.push(this.newNote)
                this.newNote = ''
            }
        },
        removeNote(index) {
            this.editCategoryEntry.notesSelectionOptions.splice(index, 1)
        },
        sortNotes() {
            this.editCategoryEntry.notesSelectionOptions.sort()
        },
        copyPackage(packag) {
            this.packages.push(JSON.parse(JSON.stringify(packag)))
            console.log(packag)
        },
        getTextColor(key) {
            if (key && key.studios && key.studios.length === 0) {
                return 'background-color: red; color: white; letter-spacing: 1px;border-radius: 5px;letter-spacing: 1px;margin-bottom: 15px;'
            } else {
                return 'background-color: var(--v-primary); color: white; letter-spacing: 1px;border-radius: 5px;letter-spacing: 1px;margin-bottom: 15px;'
            }
        },

        getTextColorMembership(membership) {
            if (membership && membership.studios && membership.studios.length === 0) {
                return 'background-color: red; color: white; letter-spacing: 1px;border-radius: 5px;letter-spacing: 1px;margin-bottom: 15px;'
            } else if (membership.flexActive) {
                return 'background-color: #c08b2b; color: white; letter-spacing: 1px;border-radius: 5px;letter-spacing: 1px;margin-bottom: 15px;'
            } else {
                return 'background-color: var(--v-primary); color: white; letter-spacing: 1px;border-radius: 5px;letter-spacing: 1px;margin-bottom: 15px;'
            }
        },
        changeMembershipEntryFlexLimit(membershipEntry) {
            if (membershipEntry.flexLimits && membershipEntry.flexLimits.min && membershipEntry.flexLimits.max) {
                membershipEntry.flexLimits.options = []
                for (
                    let i = parseInt(membershipEntry.flexLimits.min);
                    i <= parseInt(membershipEntry.flexLimits.max);
                    i++
                ) {
                    membershipEntry.flexLimits.options.push(i.toString())
                }
            }
        },
        getLabelText(membershipEntryLimit) {
            let text = ''
            if (membershipEntryLimit === 'perMonth') text = 'Monate'
            else text = 'Tage'
            return text
        },
        changeMembershipsInPackage(studioId) {
            this.editPackageEntry.studioSettings[studioId].memberships.forEach((e) => {
                if (e.flexActive) {
                    if (!e.packageFlex) e.packageFlex = { price: 0, max: 0 }
                } else {
                    if (!e.packageDurations) {
                        e.packageDurations = e.studioSettings[studioId].durations
                        e.packageDurations.forEach((e) => {
                            if (!e.packagePrice) e.packagePrice = 0
                        })
                    }
                }
            })

            this.$forceUpdate()
        },
        getFlexText(value) {
            let text = ''
            if (value.flexActive && value.flexSelection) {
                if (value.flexSelection.toString() === 'perMonth') text = 'Flex-monatlich'
                else text = 'Flex-täglich'
            }
            return text
        },
        adaptPeriod(studioId, editKey, durationKey) {
            let temp = this[durationKey]

            // remove element at index
            this[editKey].studioSettings[studioId].durations = JSON.parse(
                JSON.stringify(this[editKey].studioSettings[this.adaptPeriodSelect].durations)
            )

            this.$forceUpdate()

            this.$nextTick(() => {
                if (parseInt(temp) !== Object.keys(this[editKey].studioSettings).length - 1)
                    temp = Object.keys(this[editKey].studioSettings).length - 1
                this[durationKey] = temp
            })
        },
        setStudios(editKey, key) {
            this[editKey].studios = [...this.filterStudios(key).map((e) => e._id)]
            this.changeStudios(editKey)
        },
        removeDurationFromStudio(index, studioId, panelKey, editKey) {
            let temp = this[panelKey]

            // remove element at index
            this[editKey].studioSettings[studioId].durations.splice(index, 1)

            this.$forceUpdate()

            this.$nextTick(() => {
                if (parseInt(temp) !== Object.keys(this[editKey].studioSettings).length - 1)
                    temp = Object.keys(this[editKey].studioSettings).length - 1
                this[panelKey] = temp
            })
        },
        addDurationToStudio(studioId, durationKey = 'membershipDurationPanel', editKey = 'editMembershipEntry') {
            let temp = this[durationKey]

            this[editKey].studioSettings[studioId].durations.push({
                years: 0,
                months: 0,
                days: 0,
                price: 0,
                signUpFee: 0,
            })
            this.$forceUpdate()

            this.$nextTick(() => {
                if (parseInt(temp) !== Object.keys(this[editKey].studioSettings).length - 1)
                    temp = Object.keys(this[editKey].studioSettings).length - 1
                this[durationKey] = temp
            })
        },
        changeStudios(key) {
            if (!this[key].studioSettings) this[key].studioSettings = {}

            this[key].studios.forEach((studioId) => {
                if (!this[key].studioSettings[studioId])
                    if (key === 'editMembershipEntry') {
                        this[key].studioSettings[studioId] = {
                            flex: {
                                prices: {
                                    perDay: { price: 0, signUpFee: 0 },
                                    perMonth: { price: 0, signUpFee: 0 },
                                    perYear: { price: 0, signUpFee: 0 },
                                },
                            },
                            durations: [],
                        }
                    } else if (key === 'editPackageEntry') {
                        this[key].studioSettings[studioId] = {
                            flex: {
                                prices: {
                                    perDay: { price: 0, signUpFee: 0 },
                                    perMonth: { price: 0, signUpFee: 0 },
                                    perYear: { price: 0, signUpFee: 0 },
                                },
                            },
                            durations: {},
                        }
                    }
            })

            for (let studioId in this[key].studioSettings) {
                if (!this[key].studios.includes(studioId)) delete this[key].studioSettings[studioId]
            }
        },
        async fetchContract() {
            let contract = await api.fetchContractSettings()
            if (contract) {
                for (let key in contract) {
                    this[key] = contract[key]
                }
            }
            //if (contract === null) this.createDefaultEntries()
        },
        filterStudios(fieldName) {
            if (fieldName === 'packages') {
                return this.studios.filter((s) => {
                    if (this.memberships.filter((m) => m.studios.includes(s._id)).length > 0) return true
                    else return false
                })
            }
            return this.studios
        },
        editField(index, editFieldName, fieldName) {
            this[editFieldName] = JSON.parse(JSON.stringify(this[fieldName][index]))
            console.log('editFieldName', editFieldName)
            if (editFieldName.toString() === 'editCategoryEntry' && !('notesSelectionOptions' in this[editFieldName])) {
                this[editFieldName].notesSelectionOptions = []
            }
            if (editFieldName.toString() === 'editCategoryEntry' && !('noSignUpFee' in this[editFieldName])) {
                this[editFieldName].noSignUpFee = false
            }

            if (editFieldName.toString() === 'editCompanyEntry' && !('useMainCompany' in this[editFieldName])) {
                this[editFieldName].useMainCompany = false
                this[editFieldName].mainCompany = { name: '', street: '', city: '', phone: '' }
            }
            this[editFieldName].index = index
        },
        async saveField(editFieldName, fieldName) {
            this.membershipDurationPanel = null
            let index = this[editFieldName].index
            if (index !== -1) {
                this[fieldName][this[editFieldName].index] = JSON.parse(JSON.stringify(this[editFieldName]))
            } else {
                this[fieldName].push(JSON.parse(JSON.stringify(this[editFieldName])))
            }

            let status = await api
                .saveContractSettings({ data: this[fieldName] }, fieldName)
                .catch((e) => this.$toast.error('Es gab einen Fehler beim Speichern'))
            if (status) this.$toast.success('Erfolgreich gespeichert')

            this[editFieldName] = null

            this[fieldName] = status[fieldName]
        },
        saveCompanyEntry() {
            console.log(this.editCompanyEntry)
            this.editCompanyEntry = null
        },
        addField(fieldName) {
            if (fieldName === 'company')
                this.editCompanyEntry = {
                    name: '',
                    street: '',
                    city: '',
                    phone: '',
                    useMainCompany: false,
                    mainCompany: { name: '', street: '', city: '', phone: '' },
                    studios: [],
                    index: -1,
                }
            else if (fieldName === 'category')
                this.editCategoryEntry = {
                    title: '',
                    description: '',
                    additionalInfo: null,
                    notes: false,
                    notesSelection: false,
                    noSignUpFee: false,
                    notesSelectionOptions: [],
                    studios: [],
                    index: -1,
                }
            else if (fieldName === 'membership')
                this.editMembershipEntry = {
                    title: '',
                    description: '',
                    studioSettings: {},
                    flexActive: false,
                    flexSelection: null,
                    flexLimits: { min: 1, max: 1, options: [] },
                    studios: [],
                    index: -1,
                }
            else if (fieldName === 'package')
                this.editPackageEntry = {
                    title: '',
                    description: '',
                    price: 0,
                    studios: [],
                    index: -1,
                    studioSettings: {},
                    flexActive: false,
                    flexSelection: null,
                    amountSelectable: false,
                    hideForMinors: false,
                    showAsUpgradePossibility: false,
                    calculateWithEndDate: false,
                    calculateWithEndDateSixMonths: false,
                }
            else if (fieldName === 'paymentOption')
                this.editPaymentOptionsEntry = {
                    title: '',
                    additionalInfo: '',
                    selectDate: false,
                    studios: [],
                    index: -1,
                }
        },
        async fetchStudios() {
            let studios = await api.fetchStudios()

            studios.forEach((studio) => {
                if (studio.code !== 'ot') {
                    if (!studio.location) studio.location = { lat: 0, long: 0 }
                    this.studios.push(studio)
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}
.input-wrapper {
    width: 300px;
    height: 150px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border-color: #333;
    }
}

.input-hidden {
    display: none;
}

.input-label {
    text-align: center;
}
.contractSettings {
    &__dialog {
        position: relative;
        &-title {
            position: sticky;
            top: 0;
            color: white;
            border-bottom: 2px solid var(--v-primary);
            z-index: 9;
            background-color: var(--v-primary);
        }
        &-actions {
            background-color: var(--v-primary);
            border-top: 2px solid var(--v-primary);
            width: 100%;
        }
    }
}
</style>
