<template>
    <v-card dark class="mx-auto py-3">
        <v-row v-if="loading">
            <v-col cols="12">
                <v-progress-linear class="my-5 px-5" indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-stepper v-model="stepper" vertical>
                    <v-stepper-step step="1" complete>Auswahl</v-stepper-step>

                    <v-stepper-content step="1">
                        <user-search
                            class="pt-0 ma-5"
                            :value="sicko"
                            ignoreRights
                            label="Mitarbeiter"
                            @update="updateSicko"
                        ></user-search>
                        <v-btn color="primary" @click="stepper++">Weiter</v-btn>
                    </v-stepper-content>
                    <v-stepper-step step="2" complete>
                        Typ auswählen
                        <span class="orange--text">
                            {{ type ? '(' + typeItems.find((item) => item.value === type).text + ')' : '' }}
                        </span>
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <v-select label="Abwesenheitstyp auswählen" v-model="type" :items="typeItems"></v-select>
                        <v-row>
                            <v-col class="d-flex justify-space-around" cols="12">
                                <v-btn @click="stepper--">Zurück</v-btn>
                                <v-btn color="primary" class="mr-2" @click="stepper++">Weiter</v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-step step="3" complete>Tage auswählen</v-stepper-step>

                    <v-stepper-content step="3">
                        <v-form ref="form">
                            <v-row v-for="(sickDay, index) of sickDays" :key="index">
                                <v-col cols="5">
                                    <v-menu
                                        v-model="sickDays[index].datePickerMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :rules="rules.required"
                                                v-model="sickDays[index].date"
                                                label="Tag auswählen"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            first-day-of-week="1"
                                            locale="de-DE"
                                            v-model="date"
                                            :rules="rules.required"
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        label="Anz. Stunden (Bitte mit Punkt nicht mit Komma)"
                                        v-model="sickDays[index].hours"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" class="align-self-center">
                                    <v-btn
                                        icon
                                        color="error"
                                        @click="sickDays = sickDays.filter((day, sickIndex) => sickIndex !== index)"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    <v-menu
                                        v-model="datePickerMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date"
                                                label="Tag auswählen"
                                                readonly
                                                :rules="rules.required"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            first-day-of-week="1"
                                            locale="de-DE"
                                            v-model="date"
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        label="Anz. Stunden (Bitte mit Punkt nicht mit Komma)"
                                        :rules="rules.required"
                                        v-model="hours"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" class="align-self-center">
                                    <v-btn icon color="primary" @click="addSickDay"><v-icon>mdi-check</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="d-flex justify-space-around" cols="12">
                                    <v-btn @click="stepper--">Zurück</v-btn>
                                    <v-btn color="primary" class="mr-2" @click="saveSickEntry">Speichern</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-stepper-content>
                </v-stepper>
            </v-col>
        </v-row>
    </v-card>
</template>
  
  <script>
import api from '@/api'
import userSearch from '@/components/userSearch.vue'
export default {
    name: 'HolidayEntriesView',
    components: { userSearch },
    data() {
        return {
            stepper: 1,
            date: null,
            type: null,
            datePickerMenu: false,
            sickDays: [],
            loading: false,
            sicko: null,
            hours: 0,
            rules: {
                required: [(v) => !!v || 'Dieses Feld ist ein Pflichtfeld'],
            },
            typeItems: [
                { value: 'sick', text: 'Krank' },
                { value: 'school', text: 'Schule' },
                { value: 'military', text: 'Militär' },
                { value: 'pregnancy', text: 'Schwangerschaft' },
                { value: 'other', text: 'Andere' },
            ],
        }
    },
    created() {},
    methods: {
        addSickDay() {
            if (this.$refs.form.validate()) {
                this.sickDays.push({
                    date: this.date,
                    hours: this.hours,
                    datePickerMenu: false,
                })
                this.date = this.$moment(this.date).add('days', 1).format('YYYY-MM-DD')
                this.$refs.form.resetValidation()
            }
        },
        updateSicko(sickoId) {
            this.sicko = sickoId
        },
        reset() {
            this.sicko = null
            this.stepper = 1
            this.sickDays = []
            this.today = null
        },
        async saveSickEntry() {
            if (!this.sickDays.length) {
                this.$toast.error('Bitte mindestens einen Tag eintragen (Grünes Häckchen gedrückt? ;)')
                return
            }
            if (!this.sicko) {
                this.$toast.error('Bitte eine Person auswählen')
                return
            }
            if (!this.type) {
                this.$toast.error('Bitte einen Abwesenheitstyp auswählen')
                return
            }
            this.sickDays.forEach((sickDay) => delete sickDay.datePickerMenu)
            this.loading = true
            let status = await api.createSickEntryManagement({
                employee: this.sicko,
                sickDays: this.sickDays,
                absenceReason: this.type,
            })
            if (!status._id) {
                this.$toast.error('Fehler beim Speichern des Abwesenheitseintrags')
                this.loading = false
                return
            }
            this.$toast.success('Abwesenheitseintrag gespeichert')
            this.$emit('fetchEntries')
            this.reset()
            this.loading = false
        },
    },
    computed: {},
    watch: {},
}
</script>
  
  <style scoped>
</style>
  