<template>
	<div class="py-5">
		<v-row v-for="(notificationOption, index) in notificationOptions" :key="'notificationOption' + index" justify="center">
			<v-btn @click="subscribeToNotification(notificationOption.option)">{{ notificationOption.title }}</v-btn>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'notificationSettings',
	data() {
		return {
			notificationOptions: [
				{
					title: 'News',
					option: 'news',
				},
			],
		}
	},
	methods: {
		subscribeToNotification(option) {
			console.log('should subscribe to option', option)
			if (window.flutter_inappwebview) window.flutter_inappwebview.callHandler('subscribeToTopic', option, localStorage.getItem('token'))
		},
	},
}
</script>
