<template>
    <v-card dark max-width="600px" class="mx-auto">
        <v-row>
            <v-col class="d-flex justify-center" cols="12">
                <v-date-picker locale="de-CH" v-model="selectedDate"></v-date-picker>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="payments.length === 0" cols="12">
                <v-alert type="info" class="ma-5">Kein Einträge gefunden</v-alert>
            </v-col>
            <v-col v-else cols="12">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Alle Zahlungen</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row justify="center">
                                <v-simple-table style="max-width: 400px">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Datum</th>
                                            <th class="text-left">Karte</th>
                                            <th class="text-left">Betrag</th>
                                            <th width="20px" class="text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="payment of payments" :key="payment._id">
                                            <td>{{ $moment(payment.transactionInitDate).format('HH:mm') }} Uhr</td>
                                            <td>{{ payment.card.info.brand }}</td>
                                            <td>{{ formatPrice(payment.amount) }} CHF</td>
                                            <td>
                                                <v-btn
                                                    text
                                                    @click="$router.push('/dashboard/user/search/' + payment.user._id)"
                                                    ><v-icon>mdi-eye</v-icon></v-btn
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col v-if="payments.length > 0" class="d-flex justify-center">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">Karte</th>
                            <th class="text-left">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="paymentOverviewEntry of Object.keys(mappedOverview)" :key="paymentOverviewEntry">
                            <td>{{ paymentOverviewEntry }}</td>
                            <td>{{ parseInt(mappedOverview[paymentOverviewEntry].total) / 100 }} CHF</td>
                        </tr>
                        <tr>
                            <td><strong>Total</strong></td>
                            <td>{{ mappedOverviewTotal }}</td>
                        </tr>
                    </tbody>
                </v-simple-table></v-col
            >
        </v-row>
    </v-card>
</template>

<script>
import api from '@/api'
export default {
    name: 'PaymentOverview',
    data() {
        return {
            payments: [],
            selectedDate: this.$moment('2023-04-26').format('YYYY-MM-DD'),
        }
    },
    created() {
        this.fetchOverview()
    },
    methods: {
        formatPrice(price) {
            let newPrice = price / 100
            return newPrice
        },
        async fetchOverview() {
            try {
                let data = await api.fetchPaymentsOverview(this.selectedDate)
                this.payments = data
                console.log(data)
            } catch (e) {
                this.$toast.error('Fehler beim Laden der Übersicht')
            }
        },
    },
    watch: {
        selectedDate() {
            this.fetchOverview()
        },
    },
    computed: {
        mappedOverviewTotal() {
            let total = 0
            this.payments.forEach((e) => {
                total += e.amount
            })
            return parseInt(total) / 100 + ' CHF'
        },
        mappedOverview() {
            let mappedData = {}

            this.payments.forEach((e) => {
                if (!mappedData[e.card.info.brand]) {
                    mappedData[e.card.info.brand] = {
                        total: 0,
                    }
                }
                mappedData[e.card.info.brand].total += e.amount
            })
            return mappedData
        },
    },
}
</script>

<style lang="scss" scoped>
</style>