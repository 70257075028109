import { loadinganimationStore } from './loadinganimationStore'
import { navigationStore } from './navigationStore'
import { navigationStoreNotPersisted } from './navigationStoreNotPersisted'
import { userStore } from './userStore'
import { deviceStore } from './deviceStore'
import { studioStore } from './studioStore'
import { workingoutStore } from './workingoutStore'
import { shopStore } from './shopStore'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage'

const vuexLocal = new VuexPersistence({
	modules: ['loadinganimationStore', 'navigationStore', 'userStore', 'workingoutStore', 'deviceStore', 'shopStore'],
	storage: localForage,
	asyncStorage: true,
})

import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

export const store = new Vuex.Store({
	plugins: [vuexLocal.plugin],
	modules: {
		loadinganimationStore,
		navigationStore,
		navigationStoreNotPersisted,
		userStore,
		studioStore,
		workingoutStore,
		deviceStore,
		shopStore,
	},
})
