<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="text-h5 primary white--text">Zusatzpakete Upgrade</v-card-title>
            <v-window v-model="step" touchless>
                <!-- Erstes Fenster: Kundenauswahl und Vertragsersteller-Auswahl -->
                <v-window-item :value="1">
                    <v-container>
                        <!-- Inhalte für die Kundenauswahl und Vertragsersteller hier... -->
                        <v-row class="mt-2" v-if="!customer.selection || !customer.contractCreator">
                            <v-col cols="12">
                                <user-search
                                    :value="customer.contractCreator"
                                    :disabled="viewOnly"
                                    ignoreRights
                                    instructor
                                    label="Visum (Vertragersteller)"
                                    @update="updateCreator"
                                ></user-search>
                            </v-col>
                            <v-col cols="12">
                                <user-search
                                    :value="customer.selection"
                                    :disabled="viewOnly || customer.contractCreator === null"
                                    ignoreRights
                                    label="Kunde"
                                    @update="updateCustomer"
                                ></user-search>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <v-btn
                                    class="mx-0 my-2"
                                    v-if="!viewOnly && customer.infos"
                                    color="primary"
                                    @click="resetCustomer"
                                >
                                    Kunde ändern
                                </v-btn>
                                <v-progress-circular
                                    class="my-5"
                                    v-else
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                                <v-row no-gutters v-if="customer.infos">
                                    <v-col cols="12">
                                        <v-select
                                            :disabled="viewOnly"
                                            label="Anrede"
                                            :items="[
                                                { name: 'Herr', value: 'm' },
                                                { name: 'Frau', value: 'w' },
                                            ]"
                                            item-value="value"
                                            item-text="name"
                                            class="my-0 py-0"
                                            v-model="customer.infos.gender"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="Vorname"
                                            class="my-0 py-0"
                                            v-model="customer.infos.firstName"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="Nachname"
                                            class="my-0 py-0"
                                            v-model="customer.infos.lastName"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="Geburtstag"
                                            type="date"
                                            class="my-0 py-0"
                                            v-model="customer.infos.birthday"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="Telefon"
                                            class="my-0 py-0"
                                            v-model="customer.infos.phone"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="E-Mail"
                                            type="email"
                                            class="my-0 py-0"
                                            v-model="customer.infos.email"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="Strasse"
                                            class="my-0 py-0"
                                            v-model="customer.infos.street"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            type="number"
                                            label="Postleitzahl"
                                            class="my-0 py-0"
                                            v-model="customer.infos.zip"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="viewOnly"
                                            label="Stadt"
                                            class="my-0 py-0"
                                            v-model="customer.infos.city"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-window-item>

                <v-window-item :value="2">
                    <v-container>
                        <v-row v-if="fetchingPossibilities">
                            <v-col cols="12" align="center">
                                <v-progress-circular class="my-5" color="primary" indeterminate></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row v-else-if="possibilities">
                            <v-col class="pb-0" cols="12">
                                <v-alert v-if="!showExternalPackages" class="mx-2" type="info">
                                    {{ possibilities.roodyMembershipName }} bis
                                    {{ $moment(possibilities.endDate).format('DD.MM.YYYY') }}
                                </v-alert>
                                <v-alert v-else class="mx-2" type="info" color="primary">
                                    Zusatzpakete aus anderen Studios
                                </v-alert>
                            </v-col>

                            <v-col v-if="possibilities.partialContractRuntime" class="pb-0 pt-0" cols="12">
                                <v-alert class="mx-2" type="info" color="primary">
                                    Deine Zusatzpakete werden bis zum
                                    {{ $moment(possibilities.partialContractEndDate).format('DD.MM.YYYY') }} verlängert.
                                    Danach verlängern sie sich jährlich zum regulären Preis.
                                </v-alert>
                            </v-col>

                            <v-col class="py-0" cols="12">
                                <v-row no-gutters>
                                    <v-col cols="12" md="6" v-for="(pck, index) in packages" :key="index">
                                        <v-card
                                            :color="selectedPackages.includes(pck) ? 'primary' : ''"
                                            class="mb-4 py-3 mx-2"
                                            :class="{ 'white--text': selectedPackages.includes(pck) }"
                                            elevation="2"
                                            @click="togglePackage(pck)"
                                        >
                                            <v-row no-gutters justify="space-between">
                                                <v-col cols="auto" class="mx-5">
                                                    <v-card-title
                                                        style="font-size: 16px; line-height: 22px"
                                                        class="ma-0 pa-0"
                                                    >
                                                        <span class="mr-2" v-if="pck.amountSelectable && pck.amount">
                                                            {{ pck.amount }}x
                                                        </span>
                                                        {{ pck.packageName }}
                                                    </v-card-title>
                                                    <v-card-subtitle
                                                        style="font-size: 12px; line-height: 18px"
                                                        class="ma-0 pa-0"
                                                        v-if="pck.amountSelectable && pck.amount"
                                                    >
                                                        {{ displayCurrency(pck.amount * parseInt(pck.price)) }}
                                                        <span
                                                            v-if="
                                                                pck.usualPrice &&
                                                                (pck.calculateWithEndDate ||
                                                                    (pck.calculateWithEndDateSixMonths &&
                                                                        parseInt(pck.price) !==
                                                                            parseInt(pck.usualPrice)))
                                                            "
                                                        >
                                                            anstatt {{ displayCurrency(pck.amount * pck.usualPrice) }}
                                                        </span>
                                                    </v-card-subtitle>
                                                    <v-card-subtitle
                                                        style="font-size: 12px; line-height: 18px"
                                                        class="ma-0 pa-0"
                                                        v-else
                                                    >
                                                        {{ displayCurrency(pck.price) }}
                                                        <span
                                                            v-if="
                                                                pck.usualPrice &&
                                                                (pck.calculateWithEndDate ||
                                                                    (pck.calculateWithEndDateSixMonths &&
                                                                        parseInt(pck.price) !==
                                                                            parseInt(pck.usualPrice)))
                                                            "
                                                        >
                                                            anstatt {{ displayCurrency(pck.usualPrice) }}
                                                        </span>
                                                    </v-card-subtitle>
                                                </v-col>
                                                <v-col align="right" cols="2">
                                                    <v-tooltip
                                                        bottom
                                                        v-if="showExternalPackages && pck.availableInStudios"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn class="mr-1" @click.stop icon>
                                                                <v-icon
                                                                    :color="
                                                                        selectedPackages.includes(pck) ? 'white' : ''
                                                                    "
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                >
                                                                    mdi-map-marker-outline
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ pck.availableInStudios.join(', ') }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                        <div
                                            class="special-offer-chip"
                                            :class="{ showBorder: selectedPackages.includes(pck) }"
                                            v-if="pck.packageName.includes('mescan')"
                                        >
                                            NEU
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                v-if="possibilities.packages && possibilities.packages.length > 0"
                                class="mt-0 py-0"
                                cols="12"
                            >
                                <v-alert class="mx-2" type="info" color="primary">
                                    vorhandene Zusatzpakete: {{ possibilities.packages.join(', ') }}
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <v-alert max-width="500px" type="error">
                                    Keine Mitgliedschaft oder keine verfügbaren Zusatzpakete gefunden, stimmt die E-Mail
                                    im TAC überein?
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-window-item>
                <v-window-item :value="3">
                    <upgrade-contract-package-table
                        :selectedPackages="selectedPackages"
                        :total="displayCurrency(totalPackagePrice)"
                        v-if="step === 3 && !checkbox"
                    ></upgrade-contract-package-table>

                    <v-row no-gutters>
                        <v-col
                            v-if="
                                step === 3 &&
                                !checkbox &&
                                selectedPackages.find(
                                    (s) =>
                                        s.usualPrice &&
                                        (s.calculateWithEndDate ||
                                            (s.calculateWithEndDateSixMonths &&
                                                parseInt(s.price) !== parseInt(s.usualPrice)))
                                )
                            "
                            align="center"
                            class="px-5 mb-0 pb-0"
                            cols="12"
                        >
                            <v-alert class="mt-5" max-width="500px" type="info">
                                {{ salePackages.length === 1 ? 'Das Zusatzpaket' : 'Die Zusatzpakete' }}
                                {{
                                    salePackages
                                        .map((e) => {
                                            if (e.amountSelectable && e.amount) {
                                                return (
                                                    e.packageName +
                                                    ' (' +
                                                    displayCurrency(e.amount * e.usualPrice) +
                                                    ')'
                                                )
                                            } else {
                                                return e.packageName + ' (' + displayCurrency(e.usualPrice) + ')'
                                            }
                                        })
                                        .join(', ')
                                }}
                                {{ salePackages.length === 1 ? 'erneuert' : 'erneuern' }} sich zum Regulärpreis.
                            </v-alert>
                        </v-col>
                        <v-col class="justify-center d-flex mt-0" cols="12">
                            <v-checkbox
                                style="max-width: 400px"
                                v-model="checkbox"
                                label="Ich bestätige hiermit, dass ich meinen aktuellen Mitgliedschaftsvertrag erfolgreich mit den ausgewählten Paketen aktualisiert habe und dem Upgrade zugestimmt habe."
                            ></v-checkbox>
                        </v-col>
                        <v-col v-if="checkbox" align="center" cols="12">
                            <v-card-subtitle>Unterschrift Kunde</v-card-subtitle>
                            <signature-pad
                                :signature-data="signature"
                                @startDrawing="signatureFound = true"
                                @resetDrawing="signatureFound = false"
                                @saveSignature="saveSignature"
                                ref="signatureConditions"
                            ></signature-pad>
                        </v-col>
                        <v-col v-if="checkbox && customer.isUnderage" align="center" class="px-5" cols="12">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-card-subtitle>Gesetzlicher Vertreter</v-card-subtitle>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        class="mr-1"
                                        v-model="customer.legalRepresentative.firstName"
                                        label="Vorname"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        class="m1"
                                        v-model="customer.legalRepresentative.lastName"
                                        label="Nachname"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="customer.legalRepresentative.email"
                                        label="E-Mail"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="customer.legalRepresentative.address"
                                        label="Adresse"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col v-if="checkbox && customer.isUnderage" align="center" cols="12">
                            <signature-pad
                                :signature-data="signatureLegalRepresentative"
                                @saveSignature="saveSignatureLR"
                                ref="signatureLRConditions"
                            ></signature-pad>
                        </v-col>
                    </v-row>
                </v-window-item>
                <v-window-item :value="4">
                    <upgrade-contract-package-table
                        :selectedPackages="selectedPackages"
                        :total="displayCurrency(totalPackagePrice)"
                        v-if="step === 4"
                    ></upgrade-contract-package-table>

                    <v-alert v-if="salePackages" class="mt-5 mx-auto" max-width="500px" type="info">
                        {{ salePackages.length === 1 ? 'Das Zusatzpaket' : 'Die Zusatzpakete' }}
                        {{
                            salePackages
                                .map((e) => {
                                    if (e.amountSelectable && e.amount) {
                                        return e.packageName + ' (' + displayCurrency(e.amount * e.usualPrice) + ')'
                                    } else {
                                        return e.packageName + ' (' + displayCurrency(e.usualPrice) + ')'
                                    }
                                })
                                .join(', ')
                        }}
                        {{ salePackages.length === 1 ? 'erneuert' : 'erneuern' }} sich zum Regulärpreis.
                    </v-alert>

                    <v-card-text class="text-center">Wie möchtest Du bezahlen?</v-card-text>
                    <v-row>
                        <v-col align="right" cols="6">
                            <v-card
                                max-width="130px"
                                :color="payment === 'Kartenzahlung' ? 'primary' : ''"
                                class="mb-4 py-3 text-center"
                                :class="{ 'white--text': payment === 'Kartenzahlung' }"
                                elevation="2"
                                @click="payment = 'Kartenzahlung'"
                            >
                                Kartenzahlung
                            </v-card>
                        </v-col>
                        <v-col align="left" cols="6">
                            <v-card
                                max-width="130px"
                                :color="payment === 'Bar' ? 'primary' : ''"
                                class="mb-4 py-3 text-center"
                                :class="{ 'white--text': payment === 'Bar' }"
                                elevation="2"
                                @click="payment = 'Bar'"
                            >
                                Bar
                            </v-card>
                        </v-col>
                        <v-col class="mx-5" cols="12">
                            <v-textarea label="Notiz" v-model="paymentComment"></v-textarea>
                        </v-col>
                    </v-row>
                </v-window-item>
            </v-window>

            <!-- Navigations-Buttons -->
            <v-card-actions class="justify-center">
                <v-btn color="primary" @click="step === 1 ? cancel() : preStep()">
                    {{ step === 1 ? 'Abbrechen' : 'Zurück' }}
                </v-btn>
                <v-btn
                    :disabled="
                        !customer.selection ||
                        !customer.contractCreator ||
                        (customer.selection && !customer.infos) ||
                        fetchingPossibilities ||
                        (step === 2 && !selectedPackages.length > 0 && !(step === 2 && !showExternalPackages)) ||
                        (step === 3 && !checkbox) ||
                        (step === 4 && !payment)
                    "
                    :loading="confirming || updatingUserData"
                    color="primary"
                    @click="step === 1 ? updateUserData() : step !== 4 ? nextStep() : confirm()"
                >
                    {{ step !== 4 ? 'Weiter' : 'Bestätigen' }}
                </v-btn>
            </v-card-actions>
            <v-dialog persistent max-width="300px" v-model="showPackageAmountSelect">
                <v-card v-if="packageAmountSelect">
                    <v-card-title class="white--text primary">
                        <span class="mx-auto">Anzahl {{ packageAmountSelect.packageName }}</span>
                    </v-card-title>
                    <v-card-text align="center" class="pt-4">
                        <span>Wähle die Anzahl aus</span>
                        <v-row class="mt-5" align="center" justify="center">
                            <v-btn color="red" class="white--text" rounded-xl @click="decrement">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>

                            <span style="font-size: 16px; font-weight: bold" class="mx-4">
                                {{ packageAmountSelect.amount }}
                            </span>

                            <v-btn color="primary" rounded-xl @click="increment">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn class="mx-auto" text @click="setPackageAmount">Bestätigen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-dialog>
</template>
  
<script>
import userSearch from '@/components/userSearch.vue'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import { roundToNearestFiveCents, roundToNearestFiveCentsWithoutFormatting } from '@/moduls/currency/currency.js'
import upgradeContractPackageTable from './upgradeContractPackageTable.vue'
import api from '@/api'
export default {
    components: { userSearch, signaturePad, upgradeContractPackageTable },
    props: {
        dialog: {
            default: false,
            required: true,
        },
    },
    data() {
        return {
            updatingUserData: false,
            showExternalPackages: false,
            confirming: false,
            payment: null,
            signature: null,
            signatureLegalRepresentative: null,
            signatureFound: false,
            checkbox: false,
            packageAmountSelect: null,
            selectedPackages: [],
            showPossibilities: false,
            possibilities: null,
            fetchingPossibilities: false,
            viewOnly: false,
            paymentComment: '',
            step: 1,
            customer1: {
                isUnderage: false,
                contractCreator: '5f55fdad6716c132adf93baa',
                contractCreatorInfos: {
                    firstName: 'Dom',
                    lastName: 'Ammann',
                    email: 'dominic.ammann.ch@gmail.com',
                },
                selection: '63a98151c8c2993f5c6a6f72',
                legalRepresentative: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    address: null,
                    firstSignature: null,
                    secondSignature: null,
                },
                infos: {
                    gender: 'm',
                    firstName: 'Marcel',
                    lastName: 'Ammann',
                    email: 'marcel.ammann@axacom.ch',
                    phone: '+41763852899',
                    birthday: '1999-12-28',
                    street: 'Hauptstrasse 138',
                    city: 'Tägerwilen',
                    zip: '8274',
                },
            },
            customer: {
                isUnderage: false,
                contractCreator: null,
                contractCreatorInfos: null,
                selection: null,
                legalRepresentative: null,
                infos: {
                    gender: null,
                    firstName: null,
                    lastName: null,
                    birthday: null,
                    phone: null,
                    email: null,
                    street: null,
                    zip: null,
                    city: null,
                },
            },
        }
    },
    created() {
        if (location.host.includes('localhost')) this.customer = this.customer1
    },
    watch: {},
    computed: {
        salePackages() {
            return this.selectedPackages.filter(
                (s) =>
                    s.usualPrice &&
                    (s.calculateWithEndDate ||
                        (s.calculateWithEndDateSixMonths && parseInt(s.price) !== parseInt(s.usualPrice)))
            )
        },
        packages() {
            let pack = []
            if (this.showExternalPackages) {
                if (!this.possibilities) return pack
                pack = this.possibilities.externalPackages || []
            } else {
                if (!this.possibilities) return pack
                pack = this.possibilities.availablePackages || []
            }
            if (this.customer.isUnderage) pack = pack.filter((e) => !e.hideForMinors)
            return pack
        },
        studio() {
            return this.possibilities.studioPopulated || { code: null, name: null }
        },

        totalPackagePrice() {
            let price = 0
            this.selectedPackages.forEach((e) => {
                let amount = e.amountSelectable && e.amount ? e.amount : 1
                let priceLocal = parseFloat(e.price) * amount
                price += roundToNearestFiveCentsWithoutFormatting(priceLocal)
            })
            return price
        },
        showPackageAmountSelect() {
            return this.packageAmountSelect !== null
        },
    },
    methods: {
        cancel() {
            this.updatingUserData = false
            this.$emit('hide')
            this.checkbox = false
            this.selectedPackages = []
            this.step = 1
            this.signature = null
            this.signatureLegalRepresentative = null
            this.customer = {
                isUnderage: false,
                contractCreator: null,
                contractCreatorInfos: null,
                selection: null,
                legalRepresentative: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    address: null,
                    firstSignature: null,
                    secondSignature: null,
                },
                infos: {
                    gender: null,
                    firstName: null,
                    lastName: null,
                    birthday: null,
                    phone: null,
                    email: null,
                    street: null,
                    zip: null,
                    city: null,
                },
            }
        },
        async confirm() {
            this.confirming = true
            let contractInfo = {
                paymentComment: this.paymentComment,
                possibilities: this.possibilities,
                studio: this.possibilities.studio,
                customer: this.customer,
                signature: this.signature,
                signatureLegalRepresentative: this.signatureLegalRepresentative,
                packages: this.selectedPackages,
                payment: this.payment,
                checkbox: this.checkbox,
                frontendPrice: this.totalPackagePrice,
            }
            try {
                let status = await api.createContractUpgrade({ contractInfo })
                console.log(status)
                this.$toast.success('Upgrade erstellt')
                this.cancel()
                this.$emit('created')
            } catch (e) {
                this.$toast.error('Fehler beim Erstellen, bitte versuche es erneut')
            }
            this.confirming = false
        },
        saveSignature(signature) {
            this.signature = signature
        },
        saveSignatureLR(signature) {
            this.signatureLegalRepresentative = signature
        },
        increment() {
            if (this.packageAmountSelect.amount < 10) {
                this.packageAmountSelect.amount++
            }
            this.$forceUpdate()
        },
        decrement() {
            if (this.packageAmountSelect.amount > 1) {
                this.packageAmountSelect.amount--
            }
            this.$forceUpdate()
        },
        preStep() {
            if (this.step === 2 && this.showExternalPackages) {
                this.showExternalPackages = false
                return
            }
            if (this.step !== 1) {
                this.step--
            }
        },
        nextStep() {
            if (this.step === 2) {
                this.checkIfUnderage()
            }

            if (
                this.step === 2 &&
                !this.showExternalPackages &&
                this.possibilities.externalPackages &&
                this.possibilities.externalPackages.length > 0
            ) {
                this.showExternalPackages = true
                return
            }
            if (this.step === 3) {
                this.$refs.signatureConditions.saveSignature()
                if (this.customer.isUnderage) {
                    this.$refs.signatureLRConditions.saveSignature()
                }
                console.log('saving signatures')
            }
            this.step++
        },
        setPackageAmount() {
            this.packageAmountSelect = null
        },
        togglePackage(pck) {
            this.packageAmountSelect = null
            const index = this.selectedPackages.indexOf(pck)
            if (index > -1) {
                if (pck.amountSelectable) pck.amount = 0
                this.selectedPackages.splice(index, 1)
                if (
                    this.studio.code === 'stgalleneinstein' &&
                    pck.packageName === 'Wellness/Spa plus' &&
                    this.selectedPackages.map((e) => e.packageName).includes('Hydrojet')
                ) {
                    this.selectedPackages = this.selectedPackages.filter((e) => e.packageName !== 'Hydrojet')
                }
            } else {
                if (
                    this.studio.code === 'stgalleneinstein' &&
                    pck.packageName === 'Hydrojet' &&
                    !(
                        this.selectedPackages.map((e) => e.packageName).includes('Wellness/Spa plus') ||
                        this.possibilities.packages.includes('Wellness/Spa plus')
                    )
                ) {
                    alert('Nur mit Wellness/Spa plus')
                } else {
                    if (pck.amountSelectable) {
                        this.packageAmountSelect = pck
                        this.packageAmountSelect.amount = 1
                    }
                    this.selectedPackages.push(pck)
                }
            }
        },
        displayCurrency(amount) {
            return roundToNearestFiveCents(amount)
        },
        async fetchRunningTAC() {
            let customerId = this.customer.selection
            try {
                let infos = await api.fetchRunningTACContract(customerId, false)

                this.possibilities = infos
            } catch (e) {
                this.$toast.error('Fehler beim laden der Möglichkeiten')
                console.log(e)
            }
        },
        async updateUserData() {
            console.log('UPDATING USER DATA')
            this.updatingUserData = true
            try {
                let status = await api.editUserProfileFromContract({
                    data: {
                        infos: this.customer.infos,
                        userId: this.customer.selection,
                    },
                })

                if (!status.success && status.message) this.$toast.error(status.message)
                this.updatingUserData = false
                if (!status.success) return false
            } catch (e) {
                this.$toast.error('Fehler beim aktualisieren des Profils, bitte versuche es erneut')
                this.updatingUserData = false
                return false
            }
            this.updatingUserData = false
            this.fetchUpgradePossibilities()
        },
        async fetchUpgradePossibilities() {
            this.checkIfUnderage()
            this.selectedPackages = []
            this.step = 2

            this.fetchingPossibilities = true
            let customerId = this.customer.selection
            try {
                let infos = await api.fetchPackageUpgradePossibilites(customerId, false)
                this.possibilities = infos
            } catch (e) {
                this.$toast.error('Fehler beim laden der Möglichkeiten')
                console.log(e)
            }
            this.fetchingPossibilities = false
        },
        logthis() {
            console.log(this.customer)
        },
        resetCustomer() {
            this.customer.infos = null
            this.resetLegalRepresentative()
            this.customer.isUnderage = false
            this.customer.selection = null
        },
        checkIfUnderage() {
            let birthday = this.customer.infos.birthday
            let age = this.$moment().diff(birthday, 'years')

            if (age < 18) {
                this.customer.isUnderage = true
            } else {
                this.customer.isUnderage = false
            }

            this.$forceUpdate()
        },
        resetLegalRepresentative() {
            this.customer.legalRepresentative = {
                firstName: null,
                lastName: null,
                email: null,
                address: null,
                firstSignature: null,
                secondSignature: null,
            }
        },
        async updateCreator(id) {
            this.customer.contractCreator = id
            let contractCreator = await api.getUserForManager(id)
            let contractCreatorInfos = {
                firstName: contractCreator.vorname,
                lastName: contractCreator.nachname,
                email: contractCreator.email,
            }

            this.customer.contractCreatorInfos = contractCreatorInfos

            this.$forceUpdate()
        },
        checkIfBirthdayIsValid(birthday) {
            return this.$moment(birthday, 'YYYY-MM-DD', true).isValid()
        },
        async updateCustomer(id) {
            this.customer.selection = id
            let customer = await api.getUserForManager(id)
            let customerEntry = {
                gender: customer.geschlecht,
                firstName: customer.vorname,
                lastName: customer.nachname,
                email: customer.email,
                phone: customer.telefon,
                birthday: customer.geburtstag,
                street: customer.adresse_1,
                city: customer.stadt,
                zip: customer.plz,
            }

            this.resetLegalRepresentative()

            this.customer.infos = customerEntry

            if (!this.checkIfBirthdayIsValid(this.customer.infos.birthday)) {
                this.$toast.error('Bitte gib ein gültiges Geburtsdatum ein')
            } else {
                this.checkIfUnderage()
            }

            this.$forceUpdate()
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-label.theme--light {
    margin-bottom: 0px;
}
.showBorder {
    border: 1px solid white;
}
.special-offer-chip {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--v-primary);
    color: white;
    font-size: 12px;
    width: 50px;
    padding: 1px 5px;
    border-radius: 15px;
    text-align: center;
    /* Weitere Stilisierung nach Bedarf */
}
</style>