<template>
    <div class="workouts">
        <div class="action-link">
            <router-link to="/workouts/workouts" class="nav-link">
                <span class="material-icons">timer</span>
                Deine Workouts
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAdmin: false,
        }
    },
    created() {
        if (localStorage.getItem('token')) {
            this.isAuthenticated = true
        } else {
            this.$router.push('/login')
        }
        let user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            this.isAdmin = user.isAdmin
        }
    },
}
</script>

<style scoped lang="scss">
.workouts {
    background-color: black;
    color: white;

    .trainer-action-links {
        .label {
            height: 40px;
            line-height: 40px;
            font-weight: 300;
            padding-left: 15px;

            background-color: #80808026;
        }
        .action-link {
            &:nth-of-type(4) {
                a {
                    border-bottom: 1px solid grey;
                }
            }
        }
    }
    .action-link {
        &:nth-of-type(4) {
            a {
                border-bottom: 0px;
            }
        }
    }

    .action-link {
        a {
            border-bottom: 1px solid grey;
            color: white;
            display: flex;
            line-height: 35px;
            position: relative;
            font-weight: 200;

            &::after {
                content: '';
                position: absolute;
                right: 18px;
                top: 15px;
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;

                border-left: 10px solid white;
            }

            span {
                color: var(--v-primary);
                font-size: 31px;
                line-height: 35px;
                padding-right: 15px;
            }
        }
    }
}
</style>
