<template>
  <div class="kinderhort">
    <!-- Datumsauswahl für den Zeitraum -->
    <v-row class="justify-center mb-4">
      <v-col cols="12" align="center">
        <!-- v-menu für den Datumsbereich -->
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
          dark
        >
          <!-- Aktivator (Angezeigter Text) -->
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dark
              style="max-width: 500px"
              v-model="formattedDateRange"
              label="Zeitraum"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <!-- v-date-picker innerhalb des v-menu -->
          <v-card>
            <v-date-picker v-model="dateRange" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="applyDateRange"
                >Zeitraum anwenden</v-btn
              >
            </v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Gesamtanzahl Kinder und Babies im ausgewählten Zeitraum -->

    <!-- Anmeldungen anzeigen -->
    <v-card-title
      class="d-flex justify-center mb-5 h3 white--text font-weight-bold"
      >Anmeldungen</v-card-title
    >
    <v-row
      v-if="totalChildren !== null && totalBabies !== null"
      class="justify-center mb-5"
    >
      <v-col cols="12" class="text-center font-weight-bold white--text">
        Insgesamt {{ totalChildren }} Kinder und {{ totalBabies }} Babies im
        ausgewählten Zeitraum
      </v-col>
    </v-row>
    <v-row class="flex-column justify-center align-center" v-if="statistic">
      <v-col
        class="entry mb-10 pa-0 elevation-7"
        cols="10"
        md="6"
        sm="6"
        lg="6"
        v-for="entry of statistic"
        :key="entry.date"
      >
        <div class="d-flex flex-column" style="position: relative">
          <span class="entry__title py-3">
            {{
              entry.date === today
                ? "Heute"
                : entry.date === tomorrow
                ? "Morgen"
                : $moment(entry.date).format("DD.MM.YY")
            }}
          </span>
          <v-btn
            @click="addDaycareSignup(entry.date)"
            style="position: absolute; right: 12px; top: 8px"
            icon
          >
            <v-icon color="white">mdi-account-plus</v-icon>
          </v-btn>
          <div
            :class="{ borderBottom: index + 1 !== entry.signups.length }"
            class="entry__signup py-2"
            v-for="(signup, index) of entry.signups"
            :key="signup._id"
          >
            <v-row class="py-2">
              <v-col class="py-0 d-flex justify-start align-center" cols="7">
                <span class="pl-3" v-if="signup.user.vorname">{{
                  signup.user.vorname
                }}</span>
                <span class="pl-1" v-if="signup.user.nachname">{{
                  signup.user.nachname
                }}</span>
                <span
                  class="pl-1"
                  v-if="
                    signup.user.daycareAmount &&
                    parseInt(signup.user.daycareAmount) > 0
                  "
                >
                  ({{ signup.user.daycareAmount }})
                </span>
              </v-col>
              <v-col class="py-0 justify-end d-flex align-center" cols="5">
                <span
                  v-if="signup.amountChildren"
                  class="px-1 font-weight-bold"
                >
                  {{ signup.amountChildren }} K
                </span>
                <span v-else class="px-2 font-weight-bold">0 K</span>
                <span v-if="signup.amountBabies" class="px-1 font-weight-bold">
                  {{ signup.amountBabies }} B
                </span>
                <span v-else class="px-1 font-weight-bold">0 B</span>
                <v-btn @click="editDaycareSignup(signup._id, entry)" icon>
                  <v-icon color="white">mdi-information-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <span class="entry__amount py-3 text-center font-weight-bold">
            Insgesamt {{ entry.childrenAmount }} Kinder und
            {{ entry.amountBabies }} Babies
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" class="justify-center d-flex"
        >Keine Anmeldungen gefunden</v-col
      >
    </v-row>

    <!-- Dialog für Anmeldungen -->
    <v-dialog
      v-model="showDaycareDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card dark>
        <v-toolbar color="primary" dark>{{ daycareDialog.title }}</v-toolbar>
        <v-card-text>
          <v-row>
            <v-col v-if="daycareDialog.new == false" cols="12">
              <v-row v-if="daycareDialog.user">
                <v-col
                  class="py-0 mt-8"
                  v-if="
                    daycareDialog.user.vorname && daycareDialog.user.nachname
                  "
                  cols="12"
                >
                  {{ daycareDialog.user.vorname }}
                  {{ daycareDialog.user.nachname }}
                </v-col>
                <v-col class="py-0" v-if="daycareDialog.user.telefon" cols="12">
                  {{ daycareDialog.user.telefon }}
                </v-col>
                <v-col class="py-0" v-if="daycareDialog.user.email" cols="12">
                  {{ daycareDialog.user.email }}
                </v-col>
              </v-row>
              <v-row v-else> Benutzer wurde nicht gefunden </v-row>
            </v-col>
            <v-col class="mt-2 pb-0" v-else cols="12">
              <user-search
                dark
                filled
                :key="daycareDialog.user"
                :value="daycareDialog.user"
                label="Kunde"
                rights="g_daycare"
                @update="updateUser"
              ></user-search>
            </v-col>
            <v-col cols="12">
              <v-text-field
                filled
                v-model="daycareDialog.childrenAmount"
                label="Anzahl Kinder"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                filled
                v-model="daycareDialog.amountBabies"
                label="Anzahl Babies (bis 1.5 Jahre)"
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-if="
                daycareDialog.user &&
                daycareDialog.user.daycareAmount &&
                parseInt(daycareDialog.user.daycareAmount) > 0
              "
              cols="12"
            >
              Anzahl Kinderhort: {{ daycareDialog.user.daycareAmount }}
            </v-col>
            <v-col v-else cols="12">
              Die Anzahl Kinderhort Pakete wurde noch nicht über die
              Benutzersuche hinterlegt
            </v-col>
            <v-col cols="6" class="d-flex justify-start pl-3">
              <v-menu
                ref="arrivedAtPicker"
                v-model="showArrivedAtPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="daycareDialog.arrivedAt"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="daycareDialog.arrivedAt"
                    label="Check-in"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="showArrivedAtPicker"
                  format="24hr"
                  v-model="daycareDialog.arrivedAt"
                  full-width
                  @click:minute="
                    $refs.arrivedAtPicker.save(daycareDialog.arrivedAt)
                  "
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="d-flex justify-end pr-3">
              <v-menu
                ref="leftAtPicker"
                v-model="showLeftAtPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="daycareDialog.leftAt"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="daycareDialog.leftAt"
                    label="Check-out"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="showLeftAtPicker"
                  format="24hr"
                  v-model="daycareDialog.leftAt"
                  full-width
                  @click:minute="$refs.leftAtPicker.save(daycareDialog.leftAt)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="showDaycareDialog = false">Abbrechen</v-btn>
          <v-btn color="primary" class="white-text" text @click="updateEntry()"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../../api";
import moment from "moment";
import userSearch from "../../../components/userSearch.vue";

export default {
  components: { userSearch },
  data() {
    return {
      dateRange: [
        moment().subtract(5, "weeks").format("YYYY-MM-DD"), // Aktuelle Woche als Standard
        moment().add(3, "days").endOf("isoWeek").format("YYYY-MM-DD"),
      ],
      dateMenu: false, // Steuerung des v-menu
      formattedDateRange: "", // Anzeige des formatierten Datumsbereichs
      showArrivedAtPicker: false,
      showLeftAtPicker: false,
      showDaycareDialog: false,
      statistic: null,
      totalChildren: null,
      totalBabies: null,
      today: moment().format("YYYY-MM-DD"),
      tomorrow: moment().add(1, "days").format("YYYY-MM-DD"),
      daycareDialog: {
        title: "",
        childrenAmount: 0,
        amountBabies: 0,
        new: false,
        date: null,
        user: {
          _id: "",
          vorname: "",
          nachname: "",
          email: "",
          telefon: "",
        },
        signupId: null,
        arrivedAt: null,
        leftAt: null,
      },
    };
  },
  created() {
    this.formattedDateRange = `${moment(this.dateRange[0]).format(
      "DD.MM.YYYY"
    )} - ${moment(this.dateRange[1]).format("DD.MM.YYYY")}`;
    this.fetchDaycareStatistic();
  },
  watch: {
    dateRange(val) {
      this.formattedDateRange = `${moment(val[0]).format(
        "DD.MM.YYYY"
      )} - ${moment(val[1]).format("DD.MM.YYYY")}`;
    },
  },
  methods: {
    applyDateRange() {
      this.dateMenu = false; // Schließt das Menü
      this.fetchDaycareStatistic(); // Ruft die Statistik ab
    },
    async fetchDaycareStatistic() {
      const start = this.dateRange[0];
      const end = this.dateRange[1];
      let statisticDB = await api
        .fetchDaycareStatistic(start, end)
        .catch((err) => console.log(err));

      if (statisticDB) {
        this.statistic = statisticDB;

        // Berechnung der Gesamtzahl der Kinder und Babies im ausgewählten Zeitraum
        this.totalChildren = statisticDB.reduce(
          (total, entry) => total + entry.childrenAmount,
          0
        );
        this.totalBabies = statisticDB.reduce(
          (total, entry) => total + entry.amountBabies,
          0
        );
      }
    },
    async updateEntry() {
      if (this.daycareDialog.new) {
        if (
          (this.daycareDialog.childrenAmount !== 0 ||
            this.daycareDialog.amountBabies !== 0) &&
          this.daycareDialog.user !== ""
        ) {
          let status = await api
            .signupDaycareAdmin(this.daycareDialog)
            .catch((e) => console.log(e));
          console.log(status);
          this.showDaycareDialog = false;
          this.fetchDaycareStatistic();
        } else {
          alert("Bitte alle Felder ausfüllen");
        }
      } else {
        if (
          this.daycareDialog.childrenAmount !== "" ||
          this.daycareDialog.amountBabies !== ""
        ) {
          let status = await api
            .signupDaycareAdmin(this.daycareDialog)
            .catch((e) => console.log(e));
          console.log(status);
          this.showDaycareDialog = false;
          this.fetchDaycareStatistic();
        } else {
          alert("Bitte alle Felder ausfüllen");
        }
      }
    },
    updateUser(newId) {
      this.daycareDialog.user = newId;
    },
    async addDaycareSignup(date) {
      this.daycareDialog.date = date;
      this.daycareDialog.new = true;
      this.daycareDialog.signupId = null;
      this.daycareDialog.title = "Neue Anmeldung hinzufügen";
      this.daycareDialog.childrenAmount = 0;
      this.daycareDialog.amountBabies = 0;
      this.daycareDialog.arrivedAt = null;
      this.daycareDialog.leftAt = null;
      this.daycareDialog.user = null;
      this.showDaycareDialog = true;
    },
    async editDaycareSignup(signupId, entry) {
      this.daycareDialog.date = entry.date;
      this.daycareDialog.signupId = signupId;
      this.daycareDialog.new = false;
      this.daycareDialog.title = "Anmeldung bearbeiten";
      this.daycareDialog.user = entry.signups.find(
        (signup) => signup._id === signupId
      ).user;
      this.daycareDialog.leftAt =
        entry.signups.find((signup) => signup._id === signupId).leftAt || null;
      this.daycareDialog.arrivedAt =
        entry.signups.find((signup) => signup._id === signupId).arrivedAt ||
        null;
      this.daycareDialog.childrenAmount =
        entry.signups.find((signup) => signup._id === signupId)
          .amountChildren || 0;
      this.daycareDialog.amountBabies =
        entry.signups.find((signup) => signup._id === signupId).amountBabies ||
        0;
      console.log(this.daycareDialog);
      this.showDaycareDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.kinderhort {
  .entry {
    background-color: var(--v-primary);
    color: white;
    border-radius: 8px;
    &__title {
      font-weight: bold;
      text-align: center;
      background-color: #313131;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &__amount {
      background-color: #313131;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__signup.borderBottom {
      border-bottom: 1px solid;
    }
  }
}
</style>
