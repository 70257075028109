<!-- Payment.vue -->
<template>
    <div class="d-flex justify-center" v-if="showForm">
        <v-btn
            @click="$router.push('/user/payments')"
            x-large
            color="primary"
            outlined
            max-width="400px"
            class="mx-auto my-5"
            v-if="openPayments">
            <v-row>
                <v-col cols="12" class="d-flex justify-center align-center py-7">
                    <v-icon>mdi-cash-clock</v-icon>
                    <span class="my-auto mx-5 font-weight-bold"
                        >{{ openPayments.length }} offene Rechnung{{ openPayments.length === 1 ? '' : 'en' }}</span
                    >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-col>
            </v-row>

            <!--- <v-expansion-panels v-if="false" v-model="paymentModel" flat>
                <v-expansion-panel v-for="(payment, i) of openPayments" :key="i + '-openPayments'">
                    <v-expansion-panel-header>
                        {{ $moment(payment.createdAt).format('DD.MM.YY HH:mm') }} Uhr |
                        {{ statusMapper[payment.status] }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <payment-entry
                            @closePayment="fetchPayments()"
                            @errorPayment="fetchPayments()"
                            @recreatePayment="fetchPayments()"
                            :dark="false"
                            :hideDate="true"
                            :paymentEntry="payment"></payment-entry>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>-->
        </v-btn>
    </div>
</template>
   
<script>
import axios from 'axios'
import api from '../../../api'
import PaymentEntry from './PaymentEntry.vue'
import userService from '@/services/userService'

export default {
    components: { PaymentEntry },
    data() {
        return {
            showForm: false,
            userService,
            payments: null,
            paymentModel: 0,
            openPayments: null,
            statusMapper: {
                init: 'Offen',
                initialized: 'Offen',
                authenticated: 'Authentifiziert',
                authorized: 'Autorisiert',
                settled: 'Abgerechnet',
                canceled: 'Abgebrochen',
                transmitted: 'Übermittelt',
                failed: 'Fehlgeschlagen',
                created: 'Offen',
                deprecated: 'Abgebrochen',
            },
        }
    },
    created() {
        this.fetchPayments()
    },
    methods: {
        async fetchPayments() {
            console.log('fetching Payments')
            this.payments = null

            let paymentsLoaded = await api.getPaymentsForUserSelf()
            console.log('payments loaded', paymentsLoaded)
            let openPayments = paymentsLoaded.filter(
                (e) => e.status === 'created' || e.status === 'init' || e.status === 'failed'
            )
            if (openPayments.length > 0) {
                this.showForm = true
                this.$emit('foundOpenPayments')
            }
            this.openPayments = openPayments
            if (paymentsLoaded) this.payments = paymentsLoaded
        },
    },
    computed: {},
}
</script>
  