const moment = require('moment')
const api = require('../api').default
const { drinkingHabit } = require('../class/habit/drinkHabit')
const { medicationHabit } = require('../class/habit/medicationHabit')
const { wakeUpHabit } = require('../class/habit/wakeUpHabit')
const { workoutHabit } = require('../class/habit/workoutHabit')
const { groupFitnessHabit } = require('../class/habit/groupFitnessHabit')
const { formUpdateHabit } = require('../class/habit/formUpdateHabit')

export const habitService = {
	getHabits: async (date) => {
		date = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
		const habits = await api.getHabits(date)
		// loop through habits and create habit objects
		const habitObjects = habits.map((habit) => {
			switch (habit.type) {
				case 'drinkHabit':
					return new drinkingHabit(habit)
				case 'medicationHabit':
					return new medicationHabit(habit)
				case 'wakeUpHabit':
					return new wakeUpHabit(habit)
				case 'workoutHabit':
					return new workoutHabit(habit)
				case 'groupFitnessHabit':
					return new groupFitnessHabit(habit)
				case 'formUpdateHabit':
					return new formUpdateHabit(habit)
			}
		})
		return habitObjects
	},

	debug: () => {
		let habit = new workoutHabit({ workout: '6267d2629ca09d091ce74a68', days: [{ hour: '8', days: [0, 1, 2, 3] }] })
		console.log('DEBUG ----')
		console.log(habit)
		console.log('---- DEBUG END')
	},
	debugLoading: async () => {
		const habits = await api.getHabits(moment().format('YYYY-MM-DD'))
	},
	// empty list of habits
	list: () => {
		return [new wakeUpHabit(), new drinkingHabit(), new groupFitnessHabit(), new workoutHabit(), new medicationHabit(), new formUpdateHabit()]
	},
	getNewFormUpdateHabit: () => {
		return new formUpdateHabit()
	},
	getNewGroupFitnessHabit: () => {
		return new groupFitnessHabit()
	},
}
