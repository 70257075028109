<template>
	<div class="sensordaten">
		{{Sensordaten]}
	</div>
</template>

<script>
import api from '../api'
export default {
	data() {
		sensordaten: {
		}
	},
	created() {
		api.getSensordaten().then((data) => {
			this.sensordaten = data
		})
	},
}
</script>

<style scoped lang="scss"></style>
