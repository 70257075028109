<template>
    <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
    data() {
        return {
            chartOptions: {
                labels: this.leadsPerStatus.map((item) => item.name),
                legend: {
                    labels: {
                        colors: '#ffffff',
                        useSeriesColors: false,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom',
                                labels: {
                                    colors: '#ffffff',
                                    useSeriesColors: false,
                                },
                            },
                        },
                    },
                ],
            },
            series: this.leadsPerStatus.map((item) => item.data),
        }
    },
    props: {
        leadsPerStatus: Array,
    },
}
</script>
