import { dateTimeFormat } from '@walletpass/pass-js/dist/constants'
import createPersistedState from 'vuex-persistedstate'
//import { store } from './stores'
import { userStore } from './userStore'
import api from '../api'
const moment = require('moment')
export const deviceStore = {
	namespaced: true,
	state: {
		device: {
			title: '',
			shortForm: '',
			picture: '',
			studio_id: '',
			exercises: [],
		},
		deviceList: [],
		deviceListFetched: '',
		exerciseEdit: null,
	},
	getters: {
		exercises: (state) => {
			return state.device.exercises
		},
		exerciseList: (state) => {
			//let studio_id = null
			//if (userStore.state.user && userStore.state.user.studio && userStore.state.user.studio._id) studio_id = userStore.state.user.studio._id

			let allExercises = []

			state.deviceList.forEach(async (device) => {
				//await store.restored
				//console.log(userStore.state)
				/*if (
					userStore.state.user &&
					userStore.state.user.studio &&
					userStore.state.user.studio._id &&
					device.studios.includes(userStore.state.user.studio._id.toString())
				) {*/
				device.exercises.forEach((exercise) => {
					exercise.deviceCategory = device.category
					exercise.studios = device.studios
					exercise.deviceTitle = device.title
					exercise.deviceShortForm = device.shortForm
					allExercises.push(exercise)
				})
				/*console.log('pass ex')*/
				/*} else {
					console.log('filtered ex')
				}*/
			})

			return allExercises
		},
	},
	mutations: {
		setDeviceExercises(state, data) { },
		setDevice(state, data) {
			state.device = JSON.parse(JSON.stringify(data))
		},
		resetDevice(state, data) {
			state.device = {
				title: '',
				shortForm: '',
				picture: '',
				studio_id: '',
				exercises: [],
			}
		},
		setDeviceList(state, data) {
			state.deviceList = data
		},

		resetDeviceList(state) {
			state.deviceListFetched = ''
		},
		resetEditExercise(state, data) {
			state.exerciseEdit = null
		},
		setEditExercise(state, data) {
			state.exerciseEdit = JSON.parse(JSON.stringify(data))
		},
		removeDevice(state, data) {
			state.deviceList = state.deviceList.map((e) => {
				if (e === data) {
					return { ...e, deleted: true };
				}
				return e;
			});
		},

		removeExercise(state, data) {
			state.device.exercises = state.device.exercises.map((e) => {
				if (e === data) {
					return { ...e, deleted: true };
				}
				return e;
			});
			let dev = state.deviceList.find((e) => e._id === state.device._id);
			if (dev && dev.exercises) dev.exercises = state.device.exercises;
		},

		/*removeExercise(state, data) {
			state.device.exercises = state.device.exercises.filter((e) => e !== data)
			let dev = state.deviceList.find((e) => e._id === state.device._id)
			if (dev && dev.exercises) dev.exercises = state.device.exercises
		},*/
		createExercise(state, data) {
			state.device.exercises.push(JSON.parse(JSON.stringify(data)))
		},
		updateExercise(state, data) {
			let index = data.index
			delete data.index
			state.device.exercises[index] = JSON.parse(JSON.stringify(data))
		},
	},
	actions: {
		setEditExercise({ commit }, data) {
			console.log('setting edit exercise', data)
			commit('setEditExercise', data)
		},

		resetEditExercise({ commit }, data) {
			commit('resetEditExercise', data)
		},
		resetDevice({ commit }, data) {
			commit('resetDevice', data)
		},
		setDevice({ commit }, data) {
			commit('setDevice', data)
		},
		setDeviceList({ commit }, force = false) {
			commit('setDeviceList', force)
		},
		resetDeviceList({ commit }) {
			commit('resetDeviceList')
		},
		removeDevice({ commit }, data) {
			commit('removeDevice', data)
		},
		removeExercise({ commit }, data) {
			commit('removeExercise', data)
		},
		createExercise({ commit }, data) {
			commit('createExercise', data)
		},
		updateExercise({ commit }, data) {
			commit('updateExercise', data)
		},
	},
}
