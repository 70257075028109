<template>
    <div>
        <div class="d-flex mb-5">
            <v-btn
                class="mx-auto"
                color="primary"
                v-if="userService.hasRight('af_view', 'af_edit', 'af_downloadList')"
                @click="downloadKampagne"
                >Lead Liste herunterladen</v-btn
            >
        </div>
        <label class="white--text">Benutzer / Weiterempfehlung suchen (mind. 3 Buchstaben):</label>
        <v-text-field v-model="search" label="Suchen" dark filled></v-text-field>
        <v-row>
            <v-col cols="auto" v-for="(invite, j) of invites" :key="j">
                <v-card dark hover class="rounded-xl">
                    <v-card-title class="primary white--text">{{ timestamptodate(invite.datum) }}</v-card-title>
                    <v-card-text class="inviter user-selectable"
                        >{{ invite.invitedby }}
                        hat
                        {{ invite.name }}
                        {{ invite.telnummer }}
                        eingeladen..
                    </v-card-text>
                    <v-select
                        dark
                        filled
                        :items="statusItems"
                        @change="changeStatus(invite._id, invite.status)"
                        v-model="invite.status">
                    </v-select>
                    <v-textarea v-model="invite.notiz" label="Notizen...."></v-textarea>
                    <v-card-actions class="justify-space-around">
                        <v-btn color="primary" @click="changeStatus(invite._id, invite.status, invite.notiz, true)">
                            Notiz speichern
                        </v-btn>
                        <v-btn color="red" class="white--text" @click="deleteWeiterempfehlung(invite._id)">löschen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <div class="latest-invites">
            <v-select :items="statusItems" dark filled label="Status filtern" @change="filterInvites()" v-model="filter">
            </v-select>
            <v-row>
                <v-col cols="auto" v-for="(linvite, i) of latestinvites" :key="i">
                    <v-card dark hover class="rounded-xl d-flex flex-column white--text">
                        <v-card-title class="primary white--text">{{ timestamptodate(linvite.datum) }}</v-card-title>
                        <v-card-text class="text-center white--text user-selectable"
                            >{{ linvite.invitedby }}
                            hat
                            <span class="user-selectable">{{ linvite.name }}</span>
                            <br />
                            <span class="user-selectable">{{ linvite.telnummer }}</span>
                        </v-card-text>
                        <span>eingeladen..</span>
                        <v-select
                            dark
                            label="status"
                            filled
                            @change="changeStatus(linvite._id, linvite.status, linvite.notiz, false)"
                            v-model="linvite.status"
                            :items="statusItems">
                        </v-select>
                        <v-textarea dark filled v-model="linvite.notiz" placeholder="Notizen...."></v-textarea>
                        <v-card-actions class="justify-space-around">
                            <v-btn color="primary" @click="changeStatus(linvite._id, linvite.status, linvite.notiz, true)">
                                Notiz speichern
                            </v-btn>
                            <v-btn color="red" class="white--text" @click="deleteWeiterempfehlung(linvite._id)"
                                >löschen</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'
import zipcelxBasic from 'zipcelx'
import userService from '../../../services/userService'
export default {
    data() {
        return {
            userService,
            search: '',
            users: {},
            rollen: {},
            editing: {},
            latestinvites: {},
            invites: {},
            filter: 1,
            /*   <option value="1">Wurde angeworben</option>
                            <option value="12">Kontaktiert</option>
                            <option value="2">Probetraining vereinbart</option>
                            <option value="3">Vertrag Abgeschlossen</option>
                            <option value="4">Kein Abschluss</option>
                            <option value="5">Geschlossen</option>*/
            statusItems: [
                { value: 12, text: 'Kontaktiert' },
                { value: 2, text: 'Probetraining vereinbart' },
                { value: 3, text: 'Vertrag Abgeschlossen' },
                { value: 4, text: 'Kein Abschluss' },
                { value: 5, text: 'Geschlossen' },
            ],
            awaitingSearch: false,
        }
    },
    watch: {
        search: function (val) {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.findInvites()
                    this.awaitingSearch = false
                }, 1000) // 1 sec delay
            }
            this.awaitingSearch = true
        },
    },
    methods: {
        downloadKampagne() {
            api.getRecentWeiterempfehlungen().then((data) => {
                const config = {
                    filename: 'Lead_Liste',
                    sheet: {
                        data: data,
                    },
                }

                zipcelxBasic(config)
            })
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
        },
        deleteWeiterempfehlung(id) {
            if (confirm('Wollen Sie die Weiterempfehlung wirklich löschen?')) {
                api.deleteWeiterempfehlung(id).then((data) => {
                    if (data.status) {
                        alert('wurde gelöscht')
                        this.findInvites()
                        this.filterInvites()
                    }
                })
            }
        },
        async filterInvites() {
            let studio = null

            studio = await api.getUserStudio().catch((e) => {
                window.app.$root.$children[0].checkStudio()
            })
            studio = studio.studio
            let studio_id = studio._id
            api.getInvites({ studio: studio_id, filter: this.filter })
                .then((data) => {
                    this.latestinvites = data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        changeStatus(id, status, notiz, al) {
            api.updateInvite({ _id: id, status: status, notiz: notiz })
                .then((data) => {
                    if (!data.status) {
                        alert('fehler')
                    }
                    console.log(alert)
                    if (al) {
                        alert('Gespeichert')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        timestamptodate(ts) {
            return new Date(ts * 1000).toLocaleString()
        },

        async findInvites() {
            if (this.search.length > 2) {
                let studio = await api.getUserStudio().catch((e) => {
                    window.app.$root.$children[0].checkStudio()
                })
                studio = studio.studio
                let studio_id = studio._id
                api.searchInvites({ studio_id: studio_id, search: this.search })
                    .then((data) => {
                        this.invites = data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.invites = {}
            }
        },
        saveEditing() {
            let senddata = {
                userId: this.editing._id,
                admin: this.editing.admin,
                mitarbeiter: this.editing.mitarbeiter,
                leitung: this.editing.leitung,
            }

            api.editRolle(senddata)
                .then((data) => {
                    $('#rolleneditor').hide()
                    alert('Benutzer erfolgreich bearbeitet')
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        cancelEditing() {
            $('#rolleneditor').hide()
        },
    },
    async created() {
        this.rollen = await api.getUserRolle()
        let studio = null

        studio = await api.getUserStudio().catch((e) => {
            window.app.$root.$children[0].checkStudio()
        })
        studio = studio.studio
        let studio_id = studio._id
        this.latestinvites = await api.getInvites({ studio: studio_id, filter: this.filter })
        this.$emit('finishedloading')
    },
}
</script>
<style lang="scss" scoped>
.show-pointer {
    cursor: pointer;
}
.kampagne {
    background-color: var(--v-primary);
    width: 100%;
    padding: 15px;
    margin: 20px auto 20px auto;
    color: #fff;
    /* text-transform: uppercase; */
    border-radius: -25px;
    text-align: center;
}
.save-invite {
    margin-bottom: 10px;
    background-color: var(--v-primary);
    color: #fff;
    padding: 10px;
    border-radius: 2px;
}
.delete {
    margin-top: 10px;
    color: red;
}
select.filter {
    width: 100%;
    padding: 10px 15px;
    margin: 0px 0px 20px 0px;
}
.latest-invites {
    margin-top: 20px;
    padding: 10px;
}
.invites-header {
    font-size: 20px;
}

.invite {
    border: 1px solid var(--v-primary);
    width: 100%;
    max-width: 400px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;

    .inviter {
        padding: 2px;
        font-weight: 500;
    }
    .invited {
        font-weight: 500;
        padding: 2px;
    }
    .status {
        margin: 20px auto;
    }

    .datum {
        background-color: var(--v-primary);
        color: white;
        padding: 5px 10px;
        margin-bottom: 10px;
    }
}
#rolleneditor {
    padding: 20% 10px 20% 10px;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    h4 {
        text-align: center;
        margin-bottom: 30px;
    }
    .rolle {
        padding-left: 0px !important;
    }
    .rollen-wrapper {
        max-width: 400px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        input {
            width: 20px;
            height: 20px;
            padding: 10px;
        }
    }

    .speichern {
        background-color: var(--v-primary);
        color: white;
        margin: 20px auto 0px auto;
        padding: 10px 15px;
        width: 90%;
        text-align: center;
        border-radius: 45px;
    }
    .schliessen {
        background-color: #443838;
        color: white;
        margin: 20px auto;
        padding: 10px 15px;
        width: 90%;
        text-align: center;
        border-radius: 45px;
    }
}
label {
    padding-left: 10px;
}
.search {
    position: relative;
    .material-icons {
        position: absolute;
        top: 10px;
        right: 20px;
        color: #4a4a4a;
    }
    input {
        color: #4a4a4a;
        width: 100%;
        padding: 15px 15px 10px 15px;
        border-bottom: 1px solid #4a4a4a;
    }
}
.save {
    color: white;
    width: 130px;
    background-color: var(--v-primary);
    margin: 20px auto;
    text-align: center;
    padding: 10px;
    border-radius: 40px;
}

.user {
    color: #4a4a4a;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    display: flex;
    border-bottom: 1px solid #4a4a4a;

    .username {
        max-width: 80%;
        display: block;
    }
    .material-icons {
        margin-left: auto;
        margin-right: 10px;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.back-navigation {
    background-color: var(--v-primary);
    color: white;
    padding: 0px 10px;
    font-weight: 500;
    display: flex;
    margin-bottom: 20px;

    span {
        margin-top: auto;
        margin-bottom: auto;
    }
    a {
        display: flex;
        padding: 5px;
        color: white;

        span {
            font-size: 34px;
        }
    }
}
</style>
