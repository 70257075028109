<template>
    <div class="text-center">
        <v-dialog max-width="800px" v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" text v-bind="attrs" v-on="on">
                    <v-icon class="pr-2" color="white">mdi-palm-tree</v-icon>
                    <span v-if="!$vuetify.breakpoint.smAndDown">Ferien</span>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 primary white--text mb-5">Ferienplanung</v-card-title>
                <v-tabs v-model="tab">
                    <v-tab>Übersicht</v-tab>
                    <v-tab>Erfassen</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-row v-if="vacations">
                            <v-col cols="12">
                                <v-alert class="ma-5" :type="vacations.hours ? 'success' : 'error'">
                                    Du hast dieses Jahr noch
                                    {{ vacations.days ? Math.round(vacations.days * 1000) / 1000 : 0 }} Ferientage übrig
                                    ({{ vacations.hours ? Math.round(vacations.hours * 1000) / 1000 : 0 }} Stunden bei
                                    einem Pensum von {{ vacations.percentage }}%)
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-if="holidayEntries.length === 0">
                            <v-col cols="12">
                                <v-alert class="ma-5" type="info">Es wurden keine Ferieneinträge gefunden</v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr style="text-wrap: nowrap">
                                                <th class="text-left">Von</th>
                                                <th class="text-left">Bis</th>
                                                <th class="text-left">Dauer</th>
                                                <th class="text-left">Abgezogene Tage</th>
                                                <th class="text-left">Status</th>
                                                <th class="text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                style="text-wrap: nowrap"
                                                v-for="holidayEntry of holidayEntries"
                                                :key="holidayEntry._id"
                                            >
                                                <td>{{ $moment(holidayEntry.dates[0].date).format('DD.MM.YY') }}</td>
                                                <td>
                                                    {{
                                                        $moment(
                                                            holidayEntry.dates[holidayEntry.dates.length - 1].date
                                                        ).format('DD.MM.YY')
                                                    }}
                                                </td>
                                                <td>{{ Math.round(holidayEntry.duration * 100) / 100 }}</td>
                                                <td>{{ holidayEntry.subtractedDays }}</td>
                                                <td>
                                                    <v-chip
                                                        class="white--text"
                                                        :color="
                                                            holidayEntry.status === 'open'
                                                                ? 'orange'
                                                                : holidayEntry.status === 'accepted'
                                                                ? 'green'
                                                                : 'red'
                                                        "
                                                    >
                                                        {{ mapHolidayStatus[holidayEntry.status] }}
                                                    </v-chip>
                                                </td>
                                                <td>
                                                    <v-btn
                                                        v-if="holidayEntry.status === 'open'"
                                                        text
                                                        @click="selfCancelHoliday(holidayEntry._id)"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-stepper v-model="stepperModel" vertical>
                                <v-stepper-step :complete="stepperModel > 1" step="1">
                                    Startdatum auswählen
                                </v-stepper-step>

                                <v-stepper-content step="1">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-date-picker
                                                first-day-of-week="1"
                                                locale="de-CH"
                                                v-model="startDate"
                                            ></v-date-picker>
                                        </v-col>
                                    </v-row>

                                    <v-btn :disabled="startDate == null" color="primary" @click="stepperModel = 2">
                                        Weiter
                                    </v-btn>
                                </v-stepper-content>

                                <v-stepper-step :complete="stepperModel > 2" step="2">
                                    Enddatum auswählen
                                </v-stepper-step>

                                <v-stepper-content step="2">
                                    <v-row v-if="startDate !== null">
                                        <v-col cols="12">
                                            <v-date-picker
                                                first-day-of-week="1"
                                                locale="de-CH"
                                                v-model="endDate"
                                            ></v-date-picker>
                                        </v-col>
                                    </v-row>
                                    <v-btn :disabled="endDate == null" color="primary" @click="calculateDates()">
                                        Weiter
                                    </v-btn>
                                    <v-btn text @click="stepperModel -= 1">Zurück</v-btn>
                                </v-stepper-content>

                                <v-stepper-step :complete="stepperModel > 3" step="3">
                                    Überprüfen und speichern
                                </v-stepper-step>

                                <v-stepper-content step="3">
                                    <v-row v-if="startDate && endDate">
                                        <v-col cols="12" style="padding: 1px">
                                            <v-card-text class="pb-0 font-weight-bold">
                                                {{ $moment(startDate).format('DD.MM.YYYY') }} -
                                                {{ $moment(endDate).format('DD.MM.YYYY') }}
                                            </v-card-text>
                                            <br />
                                            <v-col v-for="date of dates" :key="date.date">
                                                <v-card :class="{ primary: date.vacationDay }">
                                                    <v-card-text :class="{ 'white--text': date.vacationDay }">
                                                        <span class="font-weight-bold">
                                                            {{ $moment(date.date).format('dddd DD.MM.YYYY') }}
                                                            {{
                                                                date.vacationDay ? ' ( ' + date.vacationDay + ' )' : ''
                                                            }}
                                                        </span>
                                                        <v-select
                                                            :disabled="!!date.vacationDay"
                                                            :dark="!!date.vacationDay"
                                                            v-model="date.duration"
                                                            :items="
                                                                date.vacationDay
                                                                    ? [{ text: 'Feiertag oder Wochenende', value: 0 }]
                                                                    : [
                                                                          {
                                                                              text:
                                                                                  'ganzer Tag (' +
                                                                                  fullDay +
                                                                                  ' Stunden)',
                                                                              value: fullDay,
                                                                          },
                                                                          {
                                                                              text:
                                                                                  'halber Tag (' +
                                                                                  fullDay / 2 +
                                                                                  ' Stunden)',
                                                                              value: fullDay / 2,
                                                                          },
                                                                      ]
                                                            "
                                                        ></v-select>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-card-text v-if="dates.length" class="pt-0">
                                                Dauer Tage: {{ getDuration() }}
                                            </v-card-text>
                                            <v-card-text class="pt-0">
                                                Von deinem Saldo abgezogene Tage: {{ getDuration(true) }}
                                            </v-card-text>
                                            <v-card-text class="pt-0">
                                                Dein Restsaldo:
                                                <span color="primary">
                                                    {{ Math.round((vacations.days - getDuration(true)) * 1000) / 1000 }}
                                                </span>
                                            </v-card-text>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-textarea label="Grund angeben (optional)" v-model="reason"></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <div class="d-flex justify-space-between">
                                        <v-btn
                                            text
                                            @click="
                                                stepperModel -= 1
                                                dates = []
                                            "
                                        >
                                            Zurück
                                        </v-btn>
                                        <v-btn :loading="!buttonEnabled" color="primary" text @click="saveHolidayEntry">
                                            Ferien beantragen
                                        </v-btn>
                                    </div>
                                </v-stepper-content>
                            </v-stepper>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <v-card-actions v-if="tab === null || tab === 0" class="d-flex justify-center align-center">
                    <v-btn color="primary" text @click="dialog = false">Fertig</v-btn>
                </v-card-actions>
                <v-card-actions v-else class="d-flex justify-center align-center">
                    <v-btn
                        color="primary"
                        text
                        @click="
                            () => {
                                this.tab = null
                                this.stepperModel = 1
                                this.startDate = this.$moment().format('YYYY-MM-DD')
                                this.endDate = this.$moment().add(1, 'days').format('YYYY-MM-DD')
                            }
                        "
                    >
                        Abbrechen
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
import api from '@/api'
import { mapState } from 'vuex'
export default {
    name: 'EnterHolidays',
    data() {
        return {
            buttonEnabled: true,
            holidayEntries: [],
            mapHolidayStatus: {
                open: 'Offen',
                accepted: 'Akzeptiert',
                rejected: 'Abgelehnt',
            },
            tab: null,
            stepperModel: 1,
            startDate: this.$moment().format('YYYY-MM-DD'),
            endDate: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
            dialog: false,
            vacations: {
                percentage: 0,
            },
            dates: [],
            reason: '',
        }
    },
    async created() {
        this.holidayEntries = await api.fetchHolidayEntryEmployee()
        this.vacations = await api.fetchVacationDaysLeft()
    },
    methods: {
        getDuration(filter = false) {
            return (
                this.dates
                    .filter((date) => {
                        return filter ? !date.vacationDay : true
                    })
                    .reduce((acc, date) => acc + date.duration * 100, 0) /
                (8.4 * 100 * ((this.vacations.percentage * 100) / 10000))
            )
        },
        calculateDates() {
            // add each day between start and end date to dates array
            let start = this.$moment(this.startDate)
            let end = this.$moment(this.endDate)
            let vacationDays = []
            if (
                this.vacations.studio ||
                (this.vacations.team &&
                    this.storeStudios.find(
                        (studio) => studio._id === this.vacations.studio || studio._id === this.vacations.team
                    ))
            )
                vacationDays = this.storeStudios.find(
                    (studio) => studio._id === this.vacations.studio || studio._id === this.vacations.team
                ).vacationDays
            while (start <= end) {
                this.dates.push({
                    date: this.$moment(start).format('YYYY-MM-DD'),
                    vacationDay: vacationDays.find((day) => day.date === this.$moment(start).format('YYYY-MM-DD'))
                        ? vacationDays.find((day) => day.date === this.$moment(start).format('YYYY-MM-DD')).title
                        : this.$moment(start).weekday() === 5 || this.$moment(start).weekday() === 6
                        ? this.$moment(start).format('dddd')
                        : false,
                    duration: vacationDays.find((day) => day.date === this.$moment(start).format('YYYY-MM-DD'))
                        ? 0
                        : this.$moment(start).weekday() === 5 || this.$moment(start).weekday() === 6
                        ? 0
                        : this.fullDay,
                })
                start = this.$moment(start).add(1, 'days')
            }
            this.stepperModel = 3
        },
        async selfCancelHoliday(id) {
            if (!confirm('Bist du sicher, dass du diese Anfrage selbst ablehnen möchtest?')) return
            try {
                await api.changeHolidayStatus({ _id: id, status: 'rejected' })
                this.$toast.success('Anfrage erfolgreich abgelehnt')
                alert('Diese Seite wird nun neu geladen')
                location.reload()
                this.holidayEntries = await api.fetchHolidayEntryEmployee()
            } catch (e) {
                this.$toast.error('Anfrage konnte nicht abgelehnt werden')
            }
        },
        async saveHolidayEntry() {
            this.buttonEnabled = false
            if (this.getDuration(true) > this.vacations.days) {
                this.$toast.error('Du hast nicht genügend Ferientage')
                //     return
            }
            try {
                let newEntry = {
                    vacationDays: this.dates,
                    status: 'open',
                    duration: this.getDuration(),
                    subtractedDays: this.getDuration(true),
                    vacationDaysLeft: this.vacations.days - this.getDuration(true),
                    subtractedVacationHours:
                        Math.round(
                            ((this.getDuration(true) * 1000 * (8.4 * (this.vacations.percentage / 100))) / 1000) * 100
                        ) / 100,
                    reason: this.reason,
                }
                let status = await api.createHolidayEntryEmployee(newEntry)
                this.$toast.success('Ferien erfolgreich eingetragen')
                alert('Die Seite wird nun neu geladen')
                location.reload()
                this.$emit('fetchEntries')
                this.tab = null
                this.startDate = this.$moment().format('YYYY-MM-DD')
                this.endDate = this.$moment().add(1, 'days').format('YYYY-MM-DD')
                this.reason = ''
                this.stepperModel = 1
                this.vacations = await api.fetchVacationDaysLeft()
                this.holidayEntries = await api.fetchHolidayEntryEmployee()
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Eintragen der Ferien')
            }
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        fullDay() {
            return Math.round(8.4 * (this.vacations.percentage / 100) * 1000) / 1000
        },
    },
}
</script>
  
  <style scoped>
</style>
  