<template>
	<swiper ref="personalTrainingSwiper" :options="workoutSwiperOptions">
		<swiper-slide class="personalTraining__swiper my-5">
			<v-card class="personalTraining__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div id="personalTrainingIntro" class="personalTraining__swiper-card-wrapper">
					<v-img contain lazy-src="/img/einmalig/personaltraining.jpg" src="/img/einmalig/personaltraining.jpg"></v-img>
					<v-card-subtitle class="personalTraining__swiper-card-subtitle h7 justify-center">READY FOR TAKEOFF</v-card-subtitle>
					<v-card-title class="personalTraining__swiper-card-title h5 primary--text justify-center"> DEIN PERSONALTRAINING </v-card-title>
					<v-card-text
						><p>
							In unserem PERSONALTRAINING finden wir gemeinsam mit Dir heraus, welche Hebel wir bei Dir umlegen müssen, damit Du Dein
							Ziel erreichst: <strong>Bikinifigur, Alltag ohne Schmerzen, mehr Muskulatur oder Beweglichkeit ...</strong>
						</p>
						<p>Du kannst sehr gerne jemand zu diesem Training mitnehmen.</p>
						<p>
							Das erste Training ist kostenfrei und dient dem gegenseitigen Kennenlernen, der Zielermittlung sowie verschiedener
							Bewegungs- und Krafttests. <br /><br />Dann starten wir mit einem 20minütigen Workout bereits auch schon mit einer ersten
							Trainingseinheit.
						</p>
						<v-list>
							<v-subheader class="font-weight-bold">Preise</v-subheader>

							<v-list-item style="min-height: 0px !important" v-for="(item, i) in firstPrices" :key="i">
								<v-list-item-icon class="m-0 p-0">
									<v-icon v-text="item.icon"></v-icon>
								</v-list-item-icon>
								<v-list-item-content class="m-0 p-0">
									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<br />
							<v-subheader class="font-weight-bold">Weitere Termine</v-subheader>
							<v-list-item
								v-intersect="{
									handler: onIntersect,
								}"
								style="min-height: 0px !important"
								v-for="(item, y) in secondPrices"
								:key="y + 'x'"
							>
								<v-list-item-icon class="m-0 p-0">
									<v-icon v-text="item.icon"></v-icon>
								</v-list-item-icon>
								<v-list-item-content class="m-0 p-0">
									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<br />
						<p>
							Ruf’ uns an oder sichere Dir hier Deinen Beratungstermin.
						</p></v-card-text
					>
				</div>

				<v-card-actions class="justify-center">
					<v-btn v-if="isIntersecting" color="primary" rounded class="text-white" @click="slideTo(1)"> sichere Dir Deinen Termin</v-btn>

					<v-btn v-else color="primary" rounded class="text-white" @click="scrollToBottom"> Mehr Informationen</v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>
		<swiper-slide class="personalTraining__swiper my-5">
			<v-card class="personalTraining__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div class="personalTraining__swiper-card-wrapper">
					<v-img contain lazy-src="/img/einmalig/personaltraining.jpg" src="/img/einmalig/personaltraining.jpg"></v-img>
					<v-card-subtitle class="personalTraining__swiper-card-subtitle h7 justify-center">READY FOR TAKEOFF</v-card-subtitle>
					<v-card-title class="personalTraining__swiper-card-title h5 primary--text justify-center mb-5">
						DEIN PERSONALTRAINING
					</v-card-title>
					<v-btn
						min-width="200"
						:loading="callLoading"
						:disabled="callLoading"
						color="primary"
						class="ma-2 white--text"
						@click="loader = 'callLoading'"
					>
						RUF' UNS AN
						<v-icon right dark>
							mdi-phone
						</v-icon>
					</v-btn>
					<v-subheader class="d-flex font-weight-bold text-center"><span class="m-auto">oder</span></v-subheader>
					<v-btn
						min-width="250"
						:loading="listEntryLoading"
						:disabled="listEntryLoading"
						color="primary"
						class="ma-2 white--text"
						@click="loader = 'listEntryLoading'"
					>
						SICHERE HIER DEINEN TERMIN
						<v-icon right dark>
							mdi-calendar-check
						</v-icon>
					</v-btn>
					<v-subheader v-show="errorMessage" class="d-flex font-weight-bold text-center"
						><span color="red" class="m-auto">{{ errorMessage }}</span></v-subheader
					>
				</div>

				<v-card-actions class="justify-center">
					<v-btn color="primary" rounded class="text-white" @click="slideTo(0)"> Informationen </v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>
		<swiper-slide class="personalTraining__swiper my-5">
			<v-card class="personalTraining__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div class="personalTraining__swiper-card-wrapper">
					<v-img contain lazy-src="/img/einmalig/personaltraining.jpg" src="/img/einmalig/personaltraining.jpg"></v-img>
					<v-card-subtitle class="personalTraining__swiper-card-subtitle h7 justify-center">READY FOR TAKEOFF</v-card-subtitle>
					<v-card-title class="personalTraining__swiper-card-title h5 primary--text justify-center mb-5">
						DEIN PERSONALTRAINING
					</v-card-title>
					<v-card-text class="font-weight-bold primary--text">
						Vielen Dank für Dein Interesse an unserem Angebot für ein Personaltraining.</v-card-text
					>
					<v-card-text class="font-weight-bold primary--text"> Wir werden uns umgehend mit Dir in Verbindung setzen.</v-card-text>
				</div>
				<v-card-actions class="justify-center">
					<v-btn color="primary" rounded class="text-white" @click="$router.push('/')"> ABSCHLIESSEN </v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>
	</swiper>
</template>

<script>
import api from '../api'
import userService from '../services/userService'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			listSettings: { category: 'personalTraining' },
			errorMessage: null,
			user: null,
			studio: null,
			loader: null,
			listEntryLoading: false,
			callLoading: false,
			isIntersecting: false,
			userService,
			firstPrices: [{ text: '1 Termin kostenlos', icon: 'mdi-hand-pointing-right' }],
			secondPrices: [
				{ text: '1 Person à CHF 50.00', icon: 'mdi-hand-pointing-right' },
				{ text: '2 Personen à CHF 40.00', icon: 'mdi-hand-pointing-right' },
				{ text: '3 Personen à CHF 30.00', icon: 'mdi-hand-pointing-right' },
			],
		}
	},
	methods: {
		scrollToBottom() {
			const container = this.$el.querySelector('#personalTrainingIntro')
			container.scrollTo({ top: container.scrollHeight, behaviour: 'smooth' })
		},
		onIntersect(entries, observer) {
			this.isIntersecting = entries[0].isIntersecting
		},
		setSwiperAllowTouchMove(value) {
			this.$refs.personalTrainingSwiper.$swiper.allowTouchMove = value
		},
		slideTo(slide) {
			this.setSwiperAllowTouchMove(false)
			this.$refs.personalTrainingSwiper.$swiper.slideTo(slide, 1000, false)
		},
	},
	async created() {
		let user = await api.getUser()

		this.user = user.user
		let studio = await api.getUserStudio().catch((e) => {})
		this.studio = studio.studio
		let check = await api.checkListEntry(this.listSettings.category)
		if (check) {
			this.slideTo(2)
		}
	},
	watch: {
		loader() {
			const l = this.loader
			this[l] = !this[l]
			this.errorMessage = null
			if (l.includes('call')) {
				this[l] = false
				this.loader = null
				let newListEntry = {
					category: this.listSettings.category,
					content: {
						phoneCall: true,
						contactMe: false,
					},
				}

				api.createListEntry(newListEntry)
					.then((data) => {
						this[l] = false
						this.loader = null
					})
					.catch((e) => {})
				window.open('tel:' + this.studio.telefon)
			} else {
				let newListEntry = {
					category: this.listSettings.category,
					content: {
						phoneCall: false,
						contactMe: true,
					},
				}

				api.createListEntry(newListEntry)
					.then((data) => {
						this[l] = false
						this.loader = null
						this.slideTo(2)
					})
					.catch((e) => {
						this[l] = false
						this.loader = null

						this.errorMessage = "Es gab einen Fehler. Ruf' uns an oder probiere es später erneut."
					})
				// Ajax request here
			}
		},
	},
	computed: {
		workoutSwiperOptions: function() {
			return {
				allowTouchMove: false,
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card {
	transition: opacity 0.4s ease-in-out;
}
.personalTraining {
	p {
		margin-bottom: 5px;
	}
	&__swiper {
		width: 90% !important;
		height: 90% !important;
		margin-left: 5%;
		margin-right: 5%;

		&-card {
			text-align: -webkit-center;
			height: 80vh;
			min-height: 500px;
			max-width: 700px;
			margin: auto;

			&-wrapper {
				overflow: auto;
				scroll-behavior: smooth;
			}

			&-content {
				overflow-y: auto;
				height: 62vh;
			}
			&-image {
				margin: 0 10%;
				height: 40%;
				background-size: contain;
			}

			&-form {
				width: 80%;
			}

			&-title,
			&-subtitle {
				word-break: break-word;
				text-align: -webkit-center;
			}

			&-title {
				padding-top: 5px;
				padding-bottom: 5px;
			}

			&-subtitle {
				padding-top: 20px;
				padding-bottom: 0px;
			}
		}
	}
}
</style>
