<template>
    <div class="terminplaner_auswahl">
        <v-btn class="mb-5" elevation="7" icon large @click="$router.push('/terminept')"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <div @click="showEditor" v-show="user.admin || user.leitung || userService.hasRight('pt_admin')" class="editor">
            <span class="material-icons"> create </span>
        </div>
        <div class="editor-form">
            <div class="editor-form-wrapper">
                <h2 v-if="!newDate._id">Neues Datum erfassen</h2>
                <h2 v-else>Datum bearbeiten</h2>

                <label>Datum</label>
                <input type="date" v-model="newDate.datum" />
                <label>Startzeit</label>
                <input type="time" v-model="newDate.startzeit" />
                <label>Endzeit</label>
                <input type="time" v-model="newDate.endzeit" />
                <label>Name des Physiotherapeuts</label>

                <input placeholder="Name des Physiotherapeuts" type="text" v-model="newDate.instructor" />
                <label>Kunde</label>

                <input placeholder="Kunde eingeben..." type="text" v-model="newDate.customer" />

                <label>Wie vielter Termin?</label>

                <input placeholder="0" type="text" v-model="newDate.count" />

                <!-- 

					<label v-show="!newDate.user_id">Kunde Anmelden (optional)</label>
					<label v-show="newDate.user_id">Angemeldeter Benutzer</label>
					<div v-show="newDate.user_id">
						<span class="logged_in-user">{{ newDate.user_name }}<br />{{ newDate.user_email }}<br />{{ newDate.user_tel }}</span>
						<div @click="resetUser()" class="logoff_user">Benutzer abmelden</div>
					</div>
					<input
					v-show="!newDate.user_id"
					v-on:input="findUsers"
					v-model="search"
					class="input__text"
					type="text"
					placeholder="Kunden suchen..."
					/>
					
					<div v-show="!newDate.user_id" class="userwrap">
						<div
                        @click="pickUser(user)"
                        :class="{ user: 1, active: user._id == newDate.user_id }"
                        v-for="(user, i) of users"
                        :key="i">
                        <span class="username">{{ user.vorname }} {{ user.nachname }} ({{ user.email }})</span>
                    </div>
                </div>
			-->
                <div class="action-wrapper">
                    <div @click="submitEditor" class="speichern">Speichern</div>
                    <div v-show="newDate._id" @click="deleteTermin" class="loeschen">Löschen</div>
                    <div @click="hideEditor" class="abbrechen">Abbrechen</div>
                </div>
            </div>
        </div>
        <div class="wrap-termine">
            <v-btn block @click="filter = true" elevation="2" v-show="!filter" class="datum-filter mb-5">
                <span>Nach Datum filtern</span>
                <span class="material-icons"> calendar_today </span>
            </v-btn>
            <v-btn
                color="#313131"
                block
                elevation="2"
                v-show="filter && !filtered"
                @click="removeFilteroption()"
                class="white--text alle-termine mb-2 py-6 font-weight-bold">
                Alle Termine anzeigen
            </v-btn>

            <div v-show="filter" class="kalender">
                <div v-for="(datum, y) in termine_daten" :key="y" class="filter_date">
                    <div @click="filterDates(datum)" class="filter_date_wrapper">
                        <span @click="removeFilter()" class="material-icons" v-show="filtered"> clear </span>
                        <span class="top">{{ calendarDate(datum) }}</span>
                        <span class="bottom">{{ calendarDay(datum) }}</span>
                    </div>
                </div>
            </div>

            <div v-show="!filter || filtered" class="termin_datum" v-for="(datum, i) in termine_daten" :key="i">
                <span class="termin_datum_label">{{ convertToDate(datum) }}</span>
                <div class="termin_eintrag_wrapper" v-for="(termin, j) in termine[datum]" :key="j">
                    <div :class="{ termin_eintrag: 1, besetzt: termin.user_id }">
                        <div class="wrap-trainer-time">
                            <span class="termin_instruktor"> Instruktor/in: {{ termin.instructor }}</span>
                            <span v-show="!termin.user_name" class="termin_eintrag_zeit"
                                >{{ convertToTime(termin.startzeit) }} - {{ convertToTime(termin.endzeit) }} Uhr</span
                            >

                            <div class="termin_eintrag_infos" v-show="termin.user_name">
                                <span class="termin_eintrag_infos_zeit"
                                    ><br />{{ convertToTime(termin.startzeit) }} -
                                    {{ convertToTime(termin.endzeit) }} Uhr</span
                                >
                                <span class="termin_eintrag_infos_kundeninfos">
                                    <br /><br />
                                    {{ termin.user_name }}<br />
                                    {{ termin.user_email }}<br />
                                    {{ termin.user_tel }}<br /><br />
                                </span>
                            </div>
                        </div>
                        <span
                            v-show="termin.user_id && !(user.admin || user.leitung || userService.hasRight('pt_admin'))"
                            class="termin_eintrag_besetzt"
                            >Besetzt</span
                        >
                        <span
                            @click="showEditor(termin)"
                            v-show="user.admin || user.leitung || userService.hasRight('pt_admin')"
                            class="material-icons">
                            edit
                        </span>
                        <span
                            @click="signUp(termin._id)"
                            v-show="!termin.user_id && !(user.admin || user.leitung || userService.hasRight('pt_admin'))"
                            class="termin_eintrag_anmelden"
                            >Anmelden</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../api'
import $ from 'jquery'
import { mapState, mapActions, mapGetters } from 'vuex'
import userService from '../services/userService'
export default {
    data() {
        return {
            user: {},
            userService,
            filtered: false,
            search: '',
            filter: false,
            users: {},
            newDate: {
                datum: '',
                startzeit: '',
                endzeit: '',
                instructor: 'Wladimir',
                user_id: '',
                studio_id: '',
                user_id: '',
                user_name: '',
                user_email: '',
                user_tel: '',
                count: '',
                customer: '',
            },
            termine_daten: [],
            termine: [],
            studio_id: '',
        }
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'toggleNavigationBarVisibility',
            'setNavigationbarVisibility',
        ]),
        resetUser() {
            this.newDate.user_id = ''
            this.newDate.user_name = ''
            this.newDate.user_email = ''
            this.newDate.user_tel = ''
        },
        pickUser(user) {
            this.newDate.user_id = user._id

            this.newDate.user_name = user.vorname + ' ' + user.nachname
            this.newDate.user_email = user.email
            this.newDate.user_tel = user.telefon
        },
        findUsers() {
            if (this.search.length > 2) {
                api.searchUserforRolle({ search: this.search })
                    .then((data) => {
                        this.users = data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.users = {}
            }
        },
        removeFilteroption() {
            this.loadDates()
            this.filter = false
        },
        removeFilter() {
            this.loadDates()
        },
        calendarDate(date) {
            console.log
            return new Date(date).toLocaleDateString()
        },
        calendarDay(date) {
            let retval = ''
            let days = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']
            return days[new Date(date).getDay()]
        },
        filterDates(date) {
            if (this.filtered == false) {
                this.termine_daten = []
                this.termine_daten.push(date)
                this.filtered = true
            }
        },
        deleteTermin() {
            if (confirm('Termin löschen?')) {
                api.PTdeleteDate(this.newDate._id).then((data) => {
                    if (data.status == 1) {
                        this.hideEditor()
                    }
                })
            }
        },
        signUp(id) {
            api.PTgetDates(this.studio_id).then((data) => {
                let alreadybooked = false
                for (let termin of data.termine) {
                    if (termin._id == id) {
                        if (termin.user_id) {
                            alreadybooked = true
                        }
                    }
                }
                if (alreadybooked) {
                    alert('Der Termin wurde leider bereits belegt')
                    this.loadDates()
                } else {
                    api.PTsignUp(id)
                        .then((data) => {
                            if (data.status == 2) {
                                alert('Der Termin wurde leider bereits belegt')
                                this.loadDates()
                            }
                            if (data.status == 1) {
                                this.$router.push('/terminept')
                            }
                        })
                        .catch((error) => {
                            alert('Es ist ein Fehler aufgetreten, bitte versuche es später erneut')
                        })
                }
            })
        },
        convertToDate(datum) {
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }
            return new Date(datum).toLocaleDateString('de-DE', options)
        },
        convertToTime(ts) {
            let hours = new Date(ts * 1000).getHours()
            let minutes = new Date(ts * 1000).getMinutes()

            if (hours < 10) {
                hours = '0' + hours
            }
            if (minutes < 10) {
                minutes = '0' + minutes
            }
            return hours + ':' + minutes
        },
        loadDates() {
            api.PTgetDates(this.studio_id).then((data) => {
                this.termine = []
                this.termine_daten = []
                for (let termin of data.termine) {
                    if (!this.termine[termin.datum]) {
                        this.termine[termin.datum] = []
                    }
                    this.termine[termin.datum].push(termin)
                }
                for (let datum in this.termine) {
                    this.termine_daten.push(datum)
                }
                this.filtered = false
            })
        },
        checkNewDate() {
            if (
                this.newDate.datum !== '' &&
                this.newDate.startzeit !== '' &&
                this.newDate.instructor !== '' &&
                this.newDate.endzeit !== '' &&
                this.newDate.customer !== '' &&
                this.newDate.count !== ''
            ) {
                return true
            } else {
                return false
            }
        },
        showEditor(termin) {
            if (termin._id) {
                this.newDate = termin
                this.newDate.startzeit =
                    new Date(termin.startzeit * 1000).getHours() + ':' + new Date(termin.startzeit * 1000).getMinutes()
                this.newDate.endzeit =
                    new Date(termin.endzeit * 1000).getHours() + ':' + new Date(termin.endzeit * 1000).getMinutes()
            } else {
                this.newDate = {
                    datum: '',
                    startzeit: '',
                    endzeit: '',
                    count: '',
                    customer: '',
                    instructor: 'Wladimir',
                    studio_id: '',
                    user_id: '',
                    user_name: '',
                    user_email: '',
                    user_tel: '',
                    studio_id: this.studio_id,
                }
            }

            $('.editor-form').show()
            $('.wrap-termine').hide()
            $('.editor').hide()
        },
        hideEditor() {
            this.loadDates()
            $('.editor-form').hide()
            $('.wrap-termine').show()
            $('.editor').show()
        },
        submitEditor() {
            if (this.checkNewDate()) {
                api.PTAddDate({ termin: this.newDate })
                    .then((data) => {
                        if (data.status == 1) {
                            $('.editor').show()
                            $('.wrap-termine').show()
                            $('.editor-form').hide()
                            this.loadDates()
                        }
                    })
                    .catch((err) => {
                        alert('fehler')
                    })
            } else {
                alert('Bitte füllen Sie alle Felder aus')
            }
        },
    },
    created() {
        api.getUser().then((data) => {
            this.user = data.user
        })
        this.studio_id = this.$route.params.studio
        this.loadDates()
    },
    mounted() {
        this.setNavigationbarVisibility(false)
    },
    beforeDestroy() {
        this.setNavigationbarVisibility(true)
    },
}
</script>

<style lang="scss" scoped>
.logoff_user {
    background-color: #313131;
    color: white;
    margin-top: 10px;
    padding: 10px 15px;
}
label {
    font-weight: bold;
}
.info-edit {
    padding: 10px 15px;
    color: red;
    font-weight: bold;
}
.wrap-trainer-time {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    .termin_instruktor {
        font-size: 14px;
    }
}
.userwrap {
    margin-top: 10px;
    .user {
        margin: 5px 0px;
        padding: 10px 15px;
        border: 1px solid grey;
        cursor: pointer;
        &.active {
            background-color: var(--v-primary);
            color: white;
            border: none;
        }
    }
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: scroll;
}

.alle-termine {
    background-color: #313131;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 1px;
    color: white;
}
.kalender {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .filter_date:nth-of-type(3n + 2) {
        margin-left: calc(100px / 75);
        margin-right: auto;
    }
    .filter_date {
        width: calc(100vw / 3 - 1px);

        .filter_date_wrapper {
            background-color: var(--v-primary);
            color: #fff;
            height: 85px;
            margin-bottom: 1px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            position: relative;

            span:not(.material-icons) {
                text-align: center;

                &.top {
                    font-weight: bold;
                    font-size: 16px;
                }

                &.bottom {
                    font-weight: bold;
                    font-size: 23px;
                }
            }
            .material-icons {
                position: absolute;
                padding: 5px;
                top: 0px;
                right: 0px;
                font-size: 20px;
                color: white;
            }
        }
    }
}
.termin_datum_label {
    background-color: #313131;
    width: 100%;
    color: #fff;
    display: block;
    font-size: 19px;
    padding-left: 15px;
    line-height: 50px;
}

.termin_eintrag {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: var(--v-primary);
    color: white;
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 25px;
    font-weight: bold;
    padding: 10px 15px;
    line-height: 70px;
    justify-content: space-between;

    .termin_eintrag_infos {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
    }
    .material-icons {
        margin: auto 0px auto 0px;

        padding: 10px;
    }
    .termin_eintrag_infos_zeit {
        padding-top: 5px;
        font-weight: bold;
    }
    &.besetzt {
        background-color: red;
    }

    span.termin_eintrag_anmelden,
    span.termin_eintrag_besetzt {
        font-size: 15px;
    }
}
.terminplaner_auswahl {
    .editor-form {
        display: none;

        &-wrapper {
            display: flex;
            flex-direction: column;
            padding: 10px 15px;

            .action-wrapper {
                div {
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    width: 100%;
                    color: white;
                    margin-bottom: 1px;
                }
                .speichern {
                    background-color: var(--v-primary);
                    margin-top: 40px;
                }
                .loeschen {
                    background-color: red;
                    margin-top: 2px;
                    margin-bottom: 2px;
                }
                .abbrechen {
                    background-color: #474747;
                }
            }
            label {
                margin-top: 20px;
                margin-bottom: 5px;
            }
            input {
                padding: 0 15px;
                width: 100%;
                height: 42px;
                border: none;
                box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.24);
                font-size: 16px;
                background-color: #f5f5f5;

                background: #f5f5f5 !important;
            }
        }
    }
    .editor {
        position: absolute;
        top: 7px;
        right: 6px;
        padding: 10px;
        background-color: #474747;
        border-radius: 50%;
        display: block;
        line-height: 1;
        color: white;
        width: 45px;
        height: 45px;
    }
    .datum-filter {
        display: flex;
        flex-direction: row;
        height: 50px;
        justify-content: space-between;
        padding: 0px 15px 0px 15px;
        background-color: var(--v-primary);
        margin-top: 1px;
        margin-bottom: 1px;

        span {
            margin: auto 0px auto 0px;
            color: white;
            font-weight: bold;
        }
        .material-icons {
            margin-left: 10px;
            font-weight: normal;
        }
    }
}
</style>
