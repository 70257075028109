<template>
	<v-row class="initPush ma-0" justify="center">
		<v-dialog v-model="visible" persistent max-width="290">
			<v-card>
				<v-card-title class="initPush__title d-flex justify-center">Benachrichtigungen</v-card-title>
				<lottie-player
					mode="normal"
					class="mx-auto"
					style="width:250px;height:300px;"
					loop
					autoplay
					src="/img/lottiefiles/notification/notificationSample.json"
				></lottie-player>
				<v-card-text class="text-center">
					Erhalte jetzt Benachrichtigungen zu Deinem Trainingsplan, Group Fitness Kursanmeldungen, News, etc.
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="grey darken-1" text @click="closeDialog(0)">
						Später
					</v-btn>
					<v-btn color="green darken-1" text @click="closeDialog(1)">Zulassen</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>
<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {}
	},
	created() {},

	methods: {
		closeDialog(status) {
			if (status) {
				this.$emit('acceptPush')
			} else {
				this.$emit('declinePush')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.initPush {
	&__title {
		background-color: #313131;
		font-weight: bold !important;
		text-transform: uppercase;
		letter-spacing: 1px !important;
		color: white;
		font-size: 17px !important;
	}
}
</style>
