<template>
	<div class="d-flex justify-space-between flex-column overflow-y-auto">
		<v-row style="border-bottom: 2px solid #000;" :key="index" v-for="(entry, index) of formattedList">
			<v-col cols="12" md="12">
				<v-text-field label="von" v-model="entry.dateRange[0]"></v-text-field>
				<v-text-field label="bis" v-model="entry.dateRange[1]"></v-text-field>
				<v-text-field label="Dauer" v-model="entry.duration"></v-text-field>
				<v-text-field label="Raum" v-model="entry.title"></v-text-field>
				<v-text-field label="Untertitel" v-model="entry.subtitle"></v-text-field>
				<v-text-field label="Preis pro Tag" v-model="entry.pricePerDay"></v-text-field>

				<div :key="i" v-for="(person, i) of entry.persons">
					<v-text-field label="Vorname" v-model="person.firstName"></v-text-field>
					<v-text-field label="Nachname" v-model="person.lastName"></v-text-field>
					<v-text-field label="Geburtsdatum" v-model="person.birthDayPicker.date"></v-text-field>
					<v-text-field label="Alter" v-model="person.age"></v-text-field>
					<v-text-field label="T-Shirt" v-model="person.shirtSize"></v-text-field>
				</div>
			</v-col>

			<v-btn color="primary" class="mx-5" @click="updateFormEntry(entry)">Speichern</v-btn>
			<v-btn color="red" class="white--text" @click="deleteFormEntry(entry)">Löschen</v-btn>
		</v-row>
	</div>
</template>

<script>
import api from '../../api'
export default {
	name: 'aliBeyEditor',
	data() {
		return { formattedList: null, backupList: null }
	},
	async created() {
		await this.fetchAlibeyList()
	},
	methods: {
		async deleteFormEntry(entry) {
			let deleted = await api.deleteListEntry({ category: 'alibey', _id: entry._id })
			await this.fetchAlibeyList()
		},
		async updateFormEntry(entry) {
			const oldEntry = this.backupList.find((e) => e._id === entry._id)

			for (let key in entry) {
				if (JSON.stringify(entry[key]) !== JSON.stringify(oldEntry[key])) {
					console.log('CHANGED THIS', key)
				}
			}

			//await api.updateAlibeyEntry(entry)
		},
		async fetchAlibeyList() {
			let list = await api.downloadListCategory('alibey').catch((e) => console.log('error loading alibey messages'))

			this.formattedList = list.map((item) => {
				console.log(item)
				return {
					_id: item._id,
					dateRange: item.content.dateRange,
					persons: item.content.form.persons,
					duration: item.content.duration,
					subtitle: item.content.room.subtitle ? item.content.room.subtitle : '',
					title: item.content.room.title,
					pricePerDay: item.content.room.possibility.price.toString(),
					room: item.content.room,
				}
			})
			this.backupList = JSON.parse(JSON.stringify(this.formattedList))
		},
	},
}
</script>

<style lang="scss" scoped>
.alibey-lottie {
	display: unset;
}
.h5 {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 19px;
}

.ali-bey {
	&__card {
		height: 80vh;

		&-carousel {
			width: 90%;
			max-width: 600px;
			margin: auto;
		}
	}
}
</style>
