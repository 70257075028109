<template>
    <div class="courseSettings">
        <v-row no-gutters>
            <v-col cols="12" class="my-5">
                <v-btn color="primary" @click="showStudioPicker">Studio wechseln</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="userService.hasRight('gf_r_viewAll')" class="justify-content-center">
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '3'">
                <v-menu
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dark
                            filled
                            :value="formatDate(datePicker)"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="datePicker" @input="showDatePicker = false"></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="courseSettings__ratings-wrapper" v-if="ratings.length">
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '3'" v-for="rating of ratings" :key="rating._id">
                <v-card dark hover class="fill-height rounded-xl">
                    <v-card-title class="primary white--text font-weight-bold">
                        {{ formatCourseDate(rating) }}{{ rating.course.name }}
                    </v-card-title>
                    <v-card-subtitle class="primary white--text">
                        mit
                        <span v-for="instructor of rating.instructors" :key="instructor._id">
                            {{ instructor.vorname }} {{ instructor.nachname }}
                        </span>
                    </v-card-subtitle>
                    <v-row v-for="r of rating.ratings" :key="r._id">
                        <v-col cols="12" class="text-center">
                            {{ formatRatingDate(r.ts) }} Uhr | {{ r.user.vorname }} {{ r.user.nachname }}
                        </v-col>
                        <v-col cols="12" class="justify-content-center py-1">
                            <v-rating
                                background-color="#ffd700"
                                color="#ffd700"
                                class="courseSettings__rating-wrapper-rating-stars-rating my-0 d-flex justify-center"
                                length="5"
                                size="35"
                                readonly
                                :value="r.stars"
                            ></v-rating>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            {{ r.message }}
                        </v-col>
                        <v-col v-if="r.message" align="center" cols="12">
                            <v-btn color="primary" @click="copyToClipboard(r.message)">kopieren</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-card-title class="text-center white--text">An diesem Tag wurde noch kein Kurs bewertet.</v-card-title>
        </v-row>
    </div>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'

export default {
    name: 'courseSettings',
    data() {
        return {
            userService,
            showCoursePictureList: false,
            datePicker: null,
            ratings: [],
            showDatePicker: false,
        }
    },
    async created() {
        this.datePicker = this.$moment().format('YYYY-MM-DD')
        await this.fetchRatings()
        this.$emit('finishedloading')
    },
    methods: {
        copyToClipboard(message) {
            navigator.clipboard.writeText(message)
            this.$toast.success('Bewertung kopiert')
        },
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
        formatDate(date) {
            if (date === null) return ''
            let day = this.$moment(date).format('dd')
            return date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0] + ' (' + day + ')'
        },
        formatRatingDate(ts) {
            return this.$moment(ts * 1000).format('HH:mm')
        },
        formatCourseDate(course) {
            return (
                this.$moment(parseInt(course.ts) * 1000)
                    .set({ hours: course.hours, minutes: course.minutes })
                    .format('HH:mm') + ' Uhr | '
            )
        },
        async fetchRatings() {
            let ratings = await api.fetchCourseRatings(this.$moment(this.datePicker).unix())
            this.ratings = ratings
        },
    },
    watch: {
        datePicker() {
            this.fetchRatings()
        },
    },
    computed: {},
}
</script>
<style lang="scss" scoped>
.courseSettings {
    &__course {
        &-list {
            border-bottom: 1px solid black;
        }
    }
    &__rating {
        &-wrapper {
            &-course {
                background-color: var(--v-primary);
                color: white;
            }
        }
    }
}
</style>
