<template>
	<v-row :class="{ 'habit-home-star--open': starOpen }">
		<v-col :class="{ 'habit-home-star__star--open': starOpen }" cols="6" class="pt-0 text-center"
			><canvas @click="openStar()" style="height: 120px;" height="165" width="165" id="canvas"></canvas
		></v-col>
		<v-col :cols="starOpen ? 12 : 6" class="pt-0 d-flex justify-space-around" :class="{ 'flex-row': starOpen, 'flex-column': !starOpen }">
			<div class="d-flex flex-row align-items-center">
				<div v-if="!starOpen" class="star__list-bullet life mr-5"></div>
				<span
					@click="changeChip(1)"
					:class="{
						active: starOpen && activeChip === 1,
						'habit-home-star__button life font-weight-bold white--text pa-2 rounded-xl elevation-7': starOpen,
					}"
					>Leben</span
				>
			</div>
			<div class="d-flex flex-row align-items-center">
				<div v-if="!starOpen" class="star__list-bullet recovery mr-5"></div>
				<span
					@click="changeChip(2)"
					:class="{
						active: starOpen && activeChip === 2,
						'habit-home-star__button recovery font-weight-bold white--text pa-2 rounded-xl  elevation-7': starOpen,
					}"
					>Erholung</span
				>
			</div>
			<div class="d-flex flex-row align-items-center">
				<div v-if="!starOpen" class="star__list-bullet movement mr-5"></div>
				<span
					@click="changeChip(3)"
					:class="{
						active: starOpen && activeChip === 3,
						'habit-home-star__button movement font-weight-bold white--text pa-2 rounded-xl  elevation-7': starOpen,
					}"
					>Bewegung</span
				>
			</div>
			<div class="d-flex flex-row align-items-center">
				<div v-if="!starOpen" class="star__list-bullet nutrition mr-5"></div>
				<span
					@click="changeChip(4)"
					:class="{
						active: starOpen && activeChip === 4,
						'habit-home-star__button nutrition font-weight-bold white--text pa-2 rounded-xl  elevation-7': starOpen,
					}"
					>Ernährung</span
				>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { dragscroll } from 'vue-dragscroll'

export default {
	name: 'habitHomeStar',
	data() {
		return {
			starCount: 0,
			canvas: null,
			ctx: null,
			starOpen: false,
			activeChip: 5,
		}
	},
	mounted() {
		this.canvas = document.getElementById('canvas')
		this.ctx = this.canvas.getContext('2d')
		// oldColors #C3CEDA #738FA7 #0C4160 #304881
		this.drawStar(82, 90, 5, 80, 40, '#cedac3', 100)
		this.drawStar(82, 90, 5, 60, 30, '#8fa773', 70)
		this.drawStar(82, 90, 5, 40, 20, '#41600c', 80)
		this.drawStar(82, 90, 5, 20, 10, '#488130', 40)
	},
	methods: {
		changeChip(number) {
			this.ctx.clearRect(0, 0, canvas.width, canvas.height)
			this.activeChip = this.activeChip === number ? 5 : number
			if (this.activeChip === 1 || this.activeChip === 5) this.drawStar(82, 90, 5, 80, 40, '#cedac3', 100)
			if (this.activeChip === 2 || this.activeChip === 5) this.drawStar(82, 90, 5, 60, 30, '#8fa773', 70)
			if (this.activeChip === 3 || this.activeChip === 5) this.drawStar(82, 90, 5, 40, 20, '#41600c', 80)
			if (this.activeChip === 4 || this.activeChip === 5) this.drawStar(82, 90, 5, 20, 10, '#488130', 20)
			this.$emit('changeChip', this.activeChip)
		},
		openStar() {
			this.starOpen = !this.starOpen
			if (!this.starOpen && this.activeChip !== 5) this.changeChip(5)
		},
		drawStar(cx, cy, spikes, outerRadius, innerRadius, color, percentage) {
			let resolvedPercentage = this.resolvePercentage(percentage)
			this.starCount = this.starCount + 1
			var rot = (Math.PI / 2) * 3
			var x = cx
			var y = cy
			var step = Math.PI / spikes

			this.ctx.lineWidth = 9
			this.ctx.beginPath()
			this.ctx.strokeStyle = color
			this.ctx.globalAlpha = '0.3'
			this.ctx.moveTo(cx, cy - outerRadius)

			for (let i = -1; i < spikes; i++) {
				x = cx + Math.cos(rot) * outerRadius
				y = cy + Math.sin(rot) * outerRadius
				this.ctx.lineTo(x, y)
				rot += step

				x = cx + Math.cos(rot) * innerRadius
				y = cy + Math.sin(rot) * innerRadius

				this.ctx.lineTo(x, y)
				rot += step
			}

			this.ctx.stroke()

			var rot = (Math.PI / 2) * 3
			var x = cx
			var y = cy
			var step = Math.PI / spikes

			this.ctx.lineWidth = 10
			this.ctx.beginPath()
			this.ctx.strokeStyle = color
			this.ctx.globalAlpha = '1'
			this.ctx.moveTo(cx, cy - outerRadius)

			for (let i = resolvedPercentage.int - 1; i < spikes; i++) {
				let fixCorner = 0
				if (i === resolvedPercentage.int - 1) {
					fixCorner = 1
				}

				x = cx + Math.cos(rot) * outerRadius
				y = cy + Math.sin(rot) * outerRadius
				//y = y - 2
				//x = x + 2
				this.ctx.lineTo(x, y)
				rot += step

				if (i !== spikes - 1 || resolvedPercentage.half) {
					x = cx + Math.cos(rot) * innerRadius
					y = cy + Math.sin(rot) * innerRadius
					this.ctx.lineTo(x, y)
				}
				rot += step
			}

			this.ctx.stroke()
		},
		resolvePercentage(percentage) {
			if (percentage === 100) {
				return { int: 0, half: false }
			}
			if (percentage >= 90) {
				return { int: 1, half: true }
			}
			if (percentage >= 80) {
				return { int: 1, half: false }
			}
			if (percentage >= 70) {
				return { int: 2, half: true }
			}
			if (percentage >= 60) {
				return { int: 2, half: false }
			}
			if (percentage >= 50) {
				return { int: 3, half: true }
			}
			if (percentage >= 40) {
				return { int: 3, half: false }
			}
			if (percentage >= 30) {
				return { int: 4, half: true }
			}
			if (percentage >= 20) {
				return { int: 4, half: false }
			}
			if (percentage >= 10) {
				return { int: 5, half: true }
			}

			return { int: 5, half: false }
		},
	},
}
</script>

<style lang="scss" scoped>
.habit-home-star {
	&--open {
		flex-direction: column;
		align-items: center;
	}
	&__star--open {
	}
}

.star__list-bullet {
	height: 12px;
	width: 12px;
	border-radius: 50%;
}

.active {
	color: var(--v-primary) !important;
}

.life {
	background: #cedac3;
}

.recovery {
	background: #8fa773;
}

.movement {
	background: #41600c;
}

.nutrition {
	background: #488130;
}
</style>
