var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"d-flex flex-column py-0",attrs:{"cols":"2"}},[_c('v-img',{attrs:{"src":"/img/lottiefiles/sunset.png","contain":"","height":"35","width":"35"}}),_c('div',{staticStyle:{"flex":"1 1 auto","height":"100%","width":"50%","border-right":"2px dashed lightgray"}}),_c('v-img',{attrs:{"src":"/img/lottiefiles/moon.png","contain":"","height":"35","width":"35"}})],1),_c('v-col',{attrs:{"cols":"10"}},[(_vm.habits)?_c('habit-home-list-nutrition',{attrs:{"habitsList":_vm.habits,"dateProp":_vm.date,"selectedExecution":_vm.selectedExecution},on:{"deleted":_vm.removeHabit}}):_vm._e(),(_vm.loading)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((5),function(template){return _c('v-col',{key:template,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg my-2",staticStyle:{"width":"98%","margin-left":"1%","margin-right":"1%"},on:{"click":function($event){return _vm.$emit('selectMeal', _vm.meal)}}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}})],1)],1)}),1):_vm._e(),(_vm.mealDistribution)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.mealDistribution),function(meal){return _c('v-col',{key:meal.text,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg my-2",class:{
                        'background-lightgreen':
                            Math.abs(_vm.calorieGoalForMeal(meal) - meal.calories) <= 30 && meal.calories !== 0,
                        'background-lightorange':
                            Math.abs(_vm.calorieGoalForMeal(meal) - meal.calories) <= 100 &&
                            Math.abs(_vm.calorieGoalForMeal(meal) - meal.calories) > 30 &&
                            meal.calories !== 0,
                        'background-lightred':
                            Math.abs(_vm.calorieGoalForMeal(meal) - meal.calories) > 100 && meal.calories !== 0,
                    },staticStyle:{"width":"98%","margin-left":"1%","margin-right":"1%"},on:{"click":function($event){return _vm.$emit('selectMeal', meal)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column pa-2",attrs:{"cols":"3"}},[_c('v-img',{attrs:{"contain":"","height":"52px","src":'/img/habitory/' + _vm.getItemImage(meal.text) + '.png'}})],1),_c('v-col',{staticClass:"d-flex flex-column justify-content-center",attrs:{"cols":"9"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(meal.text))]),_c('span',[_vm._v(" "+_vm._s(Math.round(meal.calories) + ' / ' + _vm.calorieGoalForMeal(meal) + ' kcal')+" ")])])],1)],1)],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }