<template>
	<div v-if="newsList && newsList.length > 0" class="nextNews">
		<!---	<v-row class="tag mb-5 px-5" v-if="!location.hostname.includes('local')">
			<span @click="$router.push('/aktuelles')" class="tag_title">NEWS</span>
			<span @click="$router.push('/aktuelles')" class="tag_subtitle"><v-icon color="#585858">mdi-transfer-right</v-icon></span>
		</v-row>-->

		<v-row class="nextNews__card-container flex-nowrap py-3 " :class="{ 'nextNews__card-container-mobile': $vuetify.breakpoint.mobile }">
			<v-col class="pl-0 pt-0" v-for="news of newsList" :key="news._id" cols="7" md="5" lg="3" xl="3">
				<v-skeleton-loader v-if="loading" class="mx-auto rounded-lg ml-2 mb-4 mt-0" max-width="200" type="card"></v-skeleton-loader>
				<v-card
					@click="activeNews = news"
					v-else
					class="nextNews__card rounded-lg elevation-7 mx-4 mb-4 mt-0"
					:img="'/img/news/' + news.foto"
				>
					<div v-if="news.showTitle" class="nextNews__card-transparency rounded-lg"></div>
					<v-responsive :aspect-ratio="4 / 4">
						<div v-if="news.showTitle" class="nextNews__card-text d-flex flex-column justify-space-between white--text rounded">
							<v-card-title class="nextNews__card-text-coursetitle h4 mb-0 pb-0 ">{{ format(shorten(news.titel, 45)) }}</v-card-title>
						</div>
					</v-responsive>
				</v-card>
			</v-col>
		</v-row>
		<aktuelles-reading-view v-if="activeNews" :news="activeNews" @resetActiveNews="activeNews = null"></aktuelles-reading-view>
	</div>
	<div v-else>
		<v-card-text class="text-center ">Keine News gefunden</v-card-text>
	</div>
</template>

<script>
import api from '../../api'

import userService from '../../services/userService'
import aktuellesReadingView from '../../views/aktuellesReadingView.vue'

export default {
	name: 'nextNews',
	components: { aktuellesReadingView },
	data() {
		return {
			loading: true,
			newsList: [{}, {}, {}],
			activeNews: null,
		}
	},

	async created() {
		await this.fetchNews()
	},
	methods: {
		getArt() {
			let ret = false
			if (location.host.includes('localhost')) {
				ret = 'well come FIT'
			}
			if (location.host.includes('wellcomefit')) {
				ret = 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				ret = 'winti FIT'
			} else if (location.host.includes('fzw')) {
				ret = 'FZW'
			}
			return ret
		},
		format(i) {
			return i.split('{{studio}}').join(this.getArt())
		},
		shorten(html, length = 70) {
			let tmp = document.createElement('DIV')
			tmp.innerHTML = html
			let text = tmp.textContent || tmp.innerText || ''
			if (text.length > length) text = text.substr(0, length) + '...'

			return text
		},
		pushToNews(news) {
			this.$router.push({ path: '/aktuelles' })
		},
		async fetchNews() {
			this.loading = true
			this.newsList = await api.getNews()

			this.loading = false
		},
	},
	computed: {
		location() {
			return location
		},
	},
}
</script>

<style lang="scss" scoped>
.tag {
	display: flex;
	justify-content: space-between;

	.tag_title {
		font-size: 18px;
		letter-spacing: 0.5px;
	}

	.tag_subtitle {
		font-size: 14px;
		margin: auto 0px;
	}
	span {
		font-weight: bold;
	}
}
.nextNews {
	&__card {
		width: 100%;
		position: relative;
		&:first-of-type {
			margin-left: 20px;
		}
		&:last-of-type {
			margin-right: 25px;
		}
		&-transparency {
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: #00000070;
		}
		&-text {
			height: 100%;
			&-coursetitle {
				font-weight: bold;
				font-size: 18px;
				word-break: keep-all;
			}
			&-time {
				&-duration {
					margin: auto 0px;
					font-weight: bold;
					line-height: 25px;
				}
			}
		}
		&-container {
			overflow-x: auto;
			&-mobile {
				&::-webkit-scrollbar {
					display: none;
				}
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */
			}
		}
	}
}
</style>
