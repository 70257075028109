<template>
	<div class="login">
		<div class="title-well">roody</div>
		<div class="container">
			<h3>Passwort vergessen</h3>
			<form v-if="!key" @submit.prevent="reset">
				<div v-if="!info" class="form-group">
					<input type="email" v-model="email" placeholder="Deine Email" class="form-control" />
				</div>
				<div v-if="!info" class="infox">
					Geben Sie Ihre bei der Registration verwendete E-Mail Adresse an und klicken Sie auf "Wiederherstellen".
				</div>
				<div v-else class="infox">
					{{ info }}
				</div>
				<div v-if="!info" class="form-group">
					<button class="btn btn-login btn-primary" :disabled="!email">
						Wiederherstellen
					</button>
				</div>
				<div class="form-group text-danger" v-show="error">{{ error }}</div>
			</form>
			<form v-else @submit.prevent="renew">
				<div class="form-group">
					<input type="password" v-model="passwordcheck" placeholder="Dein neues Passwort" class="form-control" />
				</div>
				<div class="form-group">
					<input type="password" v-model="password" placeholder="Passwort wiederholen" class="form-control" />
				</div>
				<div class="form-group">
					<button class="btn btn-login btn-primary" :disabled="password !== passwordcheck || password == ''">
						Passwort zurücksetzen
					</button>
				</div>
			</form>
			<div v-if="!info" class="register-now">
				<router-link to="/register" class="nav-link">Registrieren</router-link>
			</div>
			<div class="login-now">
				<router-link to="/login" class="nav-link"> <span class="material-icons">keyboard_arrow_left</span>zurück zum Login </router-link>
			</div>
		</div>
	</div>
</template>
<script>
import api from '../api'
import { EventBus } from '../event-bus'
export default {
	data() {
		return {
			email: '',
			password: '',
			passwordcheck: '',
			info: '',
			error: '',
			studio: '',
			key: '',
		}
	},
	methods: {
		renew() {
			if (window.location.hostname == 'app.wellcomefit.ch') {
				this.studio = 'well come FIT AG'
			} else {
				if (window.location.hostname.includes('fzw')) {
					this.studio = 'fzw'
				} else {
					this.studio = 'winti FIT AG'
				}
			}
			api.changePassword({
				studio: this.studio,
				key: this.key,
				password: this.password,
			})
				.then((data) => {
					if (data.status) {
						alert('Passwort Aktualisiert.. Bitte melden Sie sich erneut an')
						this.$router.push('/login')
					} else {
						alert('Bitte versuchen Sie es erneut...')
					}
				})
				.catch((error) => {
					alert('es gab einen Fehler')
					console.log(error)
				})
		},
		reset() {
			if (window.location.hostname == 'app.wellcomefit.ch') {
				this.studio = 'well come FIT AG'
			} else {
				this.studio = 'winti FIT AG'
			}
			api.resetPassword({
				studio: this.studio,
				email: this.email,
			})
				.then((data) => {
					this.info =
						'Falls diese E-Mail in unserem System registriert ist, wurde eine E-Mail an diese versendet. Um das Passwort wiederherzustellen, klicken Sie auf den Link in der Mail. Falls sie keine E-Mail erhalten haben, prüfen Sie bitte Ihren Spam / Werbungs Ordner und die Schreibweise der eingegebenen E-Mail.'
				})
				.catch((err) => {
					this.error = 'There was an issue logging in.'
				})
		},
	},
	created() {
		if (location.search) {
			this.key = location.search.substring(1)
		}
	},
}
</script>

<style lang="scss" scoped>
.infox {
	margin-bottom: 20px;
}
.login-now {
	a {
		color: black;
		display: flex;
		margin-top: 20px;
	}
}
.login {
	color: #4a4a4a;

	.title-well {
		padding: 5px 10px;
		color: white;
		background-color: var(--v-primary);
		width: 100%;
		margin-bottom: 20px;
	}

	.btn-login {
		width: 100%;
	}

	h3 {
		margin-bottom: 30px;
	}

	.register-now a {
		color: #4a4a4a;
	}
}
</style>
