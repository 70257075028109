import { userStore } from '../store/userStore'
import { store } from '../store/stores'
export default new (class {
	hasRight(...array) {
		//	await store.restored
		let userRights = JSON.parse(localStorage.getItem('userRights'))
		if (userRights) userRights = userRights.userRights
		if (userRights) {
			return userRights.some((right) => array.includes(right))
		} else {
			return false
		}
	}
	getUserId() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let userId = user && user._id ? user._id : null
		return userId
	}
	getTACUserId() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let userId = user && user.tac_user_id ? user.tac_user_id : null
		return userId
	}
	getUserPicture() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let picture = user && user.bild ? user.bild : null
		return picture
	}
	getUserBirthday() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let date = user && user.geburtstag ? user.geburtstag : null
		return date
	}
	getUserFirstName() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let name = user && user.vorname ? user.vorname : null

		return name
	}
	getUser() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		return user
	}
	getStudio() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let studio = user && user.studio ? user.studio : null
		if (!studio) studio = { art: "roody", email: "support@roody.ch" }
		return studio
	}
	getEmail() {
		let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null
		let email = user && user.email ? user.email : null
		return email
	}
	hasRightArray(array) {
		//await store.restored
		let userRights = JSON.parse(localStorage.getItem('userRights'))
		if (userRights) userRights = userRights.userRights
		if (userRights) {
			return userRights.some((right) => array.includes(right))
		} else {
			return false
		}
	}
})()
