<template>
    <div class="courseSettings">
        <v-row>
            <v-col cols="12" class="d-flex justify-center mt-5">
                <v-btn @click="addRoomDialog = true" color="primary">Hinzufügen</v-btn>
            </v-col>
            <v-col>
                <v-dialog v-model="addRoomDialog" transition="dialog-bottom-transition" max-width="600">
                    <v-card dark>
                        <v-toolbar color="primary" dark>
                            <div class="d-block">
                                <strong>Neuen Raum hinzufügen</strong>
                            </div>
                        </v-toolbar>
                        <div class="px-5 courseSettings_action-dialog d-flex flex-column">
                            <v-text-field filled class="mt-5" label="Name" v-model="addRoom.name"></v-text-field>
                        </div>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="addRoomDialog = false">Abbrechen</v-btn>
                            <v-btn
                                :loading="addRoomDisabled"
                                :disabled="addRoomDisabled"
                                color="primary"
                                text
                                @click="createRoom">
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-expansion-panels class="py-5" dark>
            <v-expansion-panel v-for="room of rooms" :key="room._id">
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <v-col cols="4"> {{ room.name }} </v-col>
                            <v-col cols="8" class="text--secondary">
                                <v-fade-transition leave-absolute>
                                    <span v-if="open" key="0"></span>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters>
                        <v-text-field filled v-model="room.name" label="Raumname" placeholder="Raumname"></v-text-field>
                    </v-row>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="deleteEntry(room, 'room', 0)"> Löschen </v-btn>

                        <v-btn @click="updateRoom(room)" text color="primary"> Speichern </v-btn>
                    </v-card-actions>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ shorten(deleteDialogTitle, 25) }}
                </v-card-title>
                <v-card-text v-if="deleteDialogPreText" class="red--text">
                    Kurs wird in folgenden Studios verwendet: {{ deleteDialogPreText }}
                </v-card-text>
                <v-card-text>
                    Möchtest du {{ getTextbyType(deleteDialogType) }}
                    <strong>{{ shorten(deleteDialogTitle, 25) }}</strong>
                    wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false"> Abbrechen </v-btn>
                    <v-btn color="green darken-1" text @click="deleteEntry(dialogDeleteObj, deleteDialogType, 1)">
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import userService from '../../../services/userService'
import { mapGetters, mapActions } from 'vuex'
import userSearch from '../../../components/userSearch.vue'
import courseSearch from '../../../components/courseSearch.vue'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import EventHome from '../../event/eventHome.vue'

export default {
    components: { courseSearch, 'slim-cropper': Slim, EventHome },
    data() {
        return {
            userService,
            rooms: [],
            deleteDialog: false,
            deleteDialogText: '',
            deleteDialogTitle: '',
            dialogDeleteObj: {},
            deleteDialogType: '',
            deleteDialogPreText: '',
            addRoomDialog: false,
            addRoom: { name: '' },
            addRoomDisabled: false,
        }
    },
    async created() {
        await this.fetchRooms()
        this.$emit('finishedloading')
    },
    methods: {
        async createRoom() {
            this.addRoomDisabled = true
            let room = this.addRoom

            let created = await api.createGFRoom(room)
            await this.fetchRooms()
            this.addRoomDialog = false
            this.addRoomDisabled = false
        },
        async updateRoom(room) {
            let updated = await api.updateGFRoom(room)
        },
        getTextbyType(type) {
            if (type === 'label') return 'das Label'
            if (type === 'room') return 'den Raum'
            if (type === 'course') return 'den Kurs'
        },
        async deleteEntry(entry, type, status = 0) {
            this.dialogDeleteObj = entry
            this.deleteDialogType = type
            this.deleteDialogPreText = ''
            let error = false
            if (type === 'label') this.deleteDialogTitle = entry.text
            if (type === 'label' && status) await api.deleteGFLabel(entry._id).catch((e) => (error = true))
            if (type === 'room') this.deleteDialogTitle = entry.name
            if (type === 'room' && status)
                (await api.deleteGFRoom(entry._id).catch((e) => (error = true))) && this.fetchRooms()
            if (type === 'course') this.deleteDialogTitle = entry.name
            if (type === 'course') {
                let usage = await api.checkGFCourseUsage(entry._id)
                if (usage) this.deleteDialogPreText = usage
            }
            if (type === 'course' && status) await api.deleteGFCourse(entry._id).catch((e) => (error = true))
            if (!status) this.deleteDialog = true
            if (status && !error) this.deleteDialog = false
        },

        async fetchRooms() {
            this.rooms = await api.fetchGFRooms()
        },
        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .v-color-picker__edit,
:v-deep .v-color-picker__controls {
    display: none !important;
}
::v-deep .v-slide-group__prev {
    display: none !important;
}

.action2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
}

.courseSettings {
    &__course {
        &-list {
            border-bottom: 1px solid black;
        }
    }
    &__rating {
        &-wrapper {
            &-course {
                background-color: var(--v-primary);
                color: white;
            }
        }
    }
}
</style>
