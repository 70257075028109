<template>
    <v-container style="max-width: 600px">
        <v-timeline dense clipped>
            <v-timeline-item
                v-if="userService.hasRight('createChangelogEvents')"
                fill-dot
                class="white--text mb-12"
                color="primary"
                large
            >
                <template v-slot:icon>
                    <span>roody</span>
                </template>
                <vue-editor :editorToolbar="customToolbar" v-model="input"></vue-editor>
                <v-btn class="mx-0" depressed @click="comment">Speichern</v-btn>
            </v-timeline-item>
            <v-progress-circular v-if="loading"></v-progress-circular>
            <v-slide-x-transition v-else group>
                <v-timeline-item v-for="event of events" :key="event._id" class="mb-4" color="primary" small>
                    <v-row justify="space-between">
                        <v-col cols="7">
                            <div v-html="event.text"></div>
                        </v-col>
                        <v-col class="text-right" cols="5">
                            <v-card-text>{{ $moment(event.createdAt).format('DD.MM.YY HH:mm') + ' Uhr' }}</v-card-text>
                            <v-btn icon @click="deleteEntry(event._id)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-timeline-item>
            </v-slide-x-transition>
        </v-timeline>
    </v-container>
</template>

<script>
import api from '@/api'
import { VueEditor } from 'vue2-editor'
import userService from '@/services/userService'
export default {
    components: { VueEditor },
    data() {
        return {
            userService,
            loading: true,
            events: [],
            input: null,
            nonce: 0,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
        }
    },
    computed: {},
    created() {
        this.fetchChangelogEvents()
    },
    methods: {
        async deleteEntry(id) {
            if (confirm('Eintrag löschen?')) {
                this.loading = true
                try {
                    let events = await api.deleteChangelogEvent(id)
                    this.events = events.logs
                } catch (e) {
                    this.$toast.error('Fehler beim löschen')
                }
                this.loading = false
            }
        },
        async fetchChangelogEvents() {
            this.loading = true
            try {
                let events = await api.fetchChangelogEvents()
                this.events = events.logs
            } catch (e) {
                this.$toast.error('Fehler beim Laden des Changelogs')
            }
            this.loading = false
        },
        async comment() {
            let entry = {
                text: this.input,
            }
            try {
                let status = await api.submitChangelogEvent(entry)
                this.events = status.logs
            } catch (e) {
                this.$toast.error('Fehler beim Speichern')
            }
            this.input = null
        },
    },
}
</script>