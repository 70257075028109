<template>
	<div class="terminplaner">
		<div class="intro-text">
			<h2>READY FOR TAKE-OFF: DEIN KOSTENLOSER BERATUNGSTERMIN</h2>
			<span v-show="!editIntrotext">{{ planer.introtext }}</span>
			<textarea v-show="editIntrotext" rows="10" v-model="planer.introtext"></textarea>
		</div>
		<div @click="editIntrotext = true" class="edit-intro-text" v-show="user.admin && !editIntrotext">
			Intro Text Anpassen
		</div>
		<div @click="saveIntrotext" class="edit-intro-text_save" v-show="user.admin && editIntrotext">
			Speichern
		</div>
		<div @click="editIntrotext = false" class="edit-intro-text" v-show="user.admin && editIntrotext">
			Abbrechen
		</div>

		<div v-show="stwest || online" class="action-termine stwest">
			<span v-show="stwest"
				>Bitte wechsle auf das Studio St. Gallen Ost oder St. Gallen Einstein über den Button "Studio Auswählen", um deinen persönlich Termin
				zu wählen</span
			>
			<span v-show="online"
				>Bitte wechsle über den Button "Studio Auswählen" in ein Center Deiner Wahl, um deinen persönlich Termin zu wählen</span
			>
			<div @click="studPicker()" class="stud-picker">Studio Auswählen</div>
		</div>

		<router-link v-show="!disableNext && !anmeldung && !stwest && !online" tag="div" :to="'/termine/' + planer._id" class="action-termine">
			<span>Zur Termin Auswahl</span>
			<span class="material-icons">
				navigate_next
			</span>
		</router-link>
		<div v-show="!disableNext && anmeldung" class="action-termine_angemeldet">
			<span
				>Du hast dich für den Termin<br />
				<strong
					>{{ convertToDate(anmeldung.datum) }} {{ convertToTime(anmeldung.startzeit) }} -
					{{ convertToTime(anmeldung.endzeit) }} Uhr</strong
				>
				<br />
				angemeldet
			</span>
			<div @click="signOffCourse()" class="abmelden">Abmelden</div>
		</div>
		<div class="action-termine" v-show="disableNext">
			<span>Bitte passe zuerst den Introtext an, um das Modul zu aktivieren (nur Admins)</span>
		</div>
	</div>
</template>

<script>
import api from '../api'

export default {
	data() {
		return {
			stwest: false,
			online: false,
			user: {},
			editIntrotext: false,
			disableNext: false,
			anmeldung: false,
			planer: {
				introtext: '',
			},
		}
	},
	methods: {
		studPicker() {
			window.app.$root.$children[0].checkStudio(true)
		},
		signOffCourse() {
			api.TPSignOff().then((data) => {
				if (data.status) {
					this.checkSignup()
				}
			})
		},
		convertToDate(datum) {
			const options = {
				weekday: 'long',
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			}
			return new Date(datum).toLocaleDateString('de-DE', options)
		},
		convertToTime(ts) {
			let hours = new Date(ts * 1000).getHours()
			let minutes = new Date(ts * 1000).getMinutes()

			if (hours < 10) {
				hours = '0' + hours
			}
			if (minutes < 10) {
				minutes = '0' + minutes
			}
			return hours + ':' + minutes
		},
		saveIntrotext() {
			api.TPEditIntro({ terminplaner: this.planer })
				.then((data) => {
					if (data.status) {
						this.editIntrotext = false
					} else {
						alert('fehler')
					}
				})
				.catch((error) => {
					alert('fehler')
				})
		},
		checkSignup() {
			this.anmeldung = false
			api.TPcheck().then((data) => {
				this.anmeldung = data
			})
		},
		loadIntrotext() {
			api.TPgetIntro().then((data) => {
				if (data.terminplaner) {
					this.planer = data.terminplaner
					if (data.terminplaner.studio_id == '5f3c30bfd91ddb464c2648b2') {
						this.stwest = true
					}
					if (data.terminplaner.studio_id == '6009e4098b98783294411156') {
						this.online = true
					}
				} else {
					this.disableNext = true
					this.planer.introtext =
						'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam'
				}
			})
		},
	},
	created() {
		api.getUser().then((data) => {
			this.user = data.user
		})
		this.checkSignup()
		this.loadIntrotext()
	},
}
</script>

<style lang="scss" scoped>
.terminplaner {
	.intro-text {
		padding: 20px 15px 10px 15px;
		h2 {
			font-size: 24px;
			line-height: 30px;
		}

		textarea {
			width: 100%;
		}
		span,
		textarea {
			line-height: 22px !important;
			display: flex;
			white-space: pre-wrap;
		}
	}
	.edit-intro-text {
		text-align: right;
		background-color: #474747;
		color: #fff;
		padding: 10px;
		&_save {
			text-align: right;
			margin-bottom: 1px;
			color: #fff;
			padding: 10px;
			background-color: var(--v-primary);
		}
	}

	.action-termine_angemeldet {
		width: 100%;
		padding-top: 20px;
		background-color: var(--v-primary);
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		color: white;
		margin-top: 20px;

		.abmelden {
			margin-bottom: 40px;
			margin-top: 40px;
			line-height: 50px;
			background-color: #474747;
		}
	}

	.action-termine {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100px;
		background-color: var(--v-primary);
		margin-top: 20px;

		&.stwest {
			flex-direction: column;
			padding-left: 10%;
			padding-right: 10%;
			padding-top: 20px;
			height: auto;

			.stud-picker {
				margin-top: 30px;
				text-align: center;
				margin-bottom: 20px;
				color: #fff;
				line-height: 40px;
				background-color: #474747;
			}
			* {
				font-weight: normal !important;
			}
		}
		span {
			margin: auto 0px auto 0px;
			color: white;
			font-weight: bold;
		}

		.material-icons {
			margin-left: 10px;
		}
	}
}
</style>
