<template>
    <v-bottom-navigation
        id="bottom-navigation"
        data-v-step="3"
        v-if="navigationVisible && !$route.meta.hideBottomNavigation"
        class="bottom-navigation justify-space-between px-3"
        :class="{
            'bottom-navigation-small rounded-xl': navigationBarText && !navigationBarText.customButtons,
            'bottom-navigation-large': $vuetify.breakpoint.mobile,
            'bottom-navigation-small': !$vuetify.breakpoint.mobile,
            'bottom-navigation-workout-set-add': navigationBarUsedForWorkoutSet,
            'bottom-navigation-workout-set-add': navigationBarUsedForWorkoutSetNew,
        }"
        :value="selectedNavigation"
        :height="navigationBarUsedForWorkoutSet || navigationBarUsedForWorkoutSetNew ? 270 : 60"
        :background-color="getNavigationBarColor()"
        app
        fixed
        mandatory
        color="white"
    >
        <div
            v-if="navigationBarText && !navigationBarUsedForWorkoutSet && !navigationBarUsedForWorkoutSetNew"
            style="width: 100%; height: 100%"
        >
            <div
                v-if="navigationBarText.customButtons"
                :key="'navigationBarTextCustomKey' + navigationBarTextCustomKey"
                :class="{ 'bottom-navigation-with-scrollbar': !$vuetify.breakpoint.mobile }"
                class="d-flex justify-space-between bottom-navigation__custom-buttons--wrapper"
                v-dragscroll="{ active: !$vuetify.breakpoint.mobile }"
            >
                <v-btn
                    :key="index"
                    v-for="(item, index) in navigationBarText.buttons"
                    class="bottom-navigation__item mx-3 v-btn--active show"
                    :id="'button' + item.title"
                    depressed
                    plain
                    :ripple="false"
                    :value="item.title"
                    @click="startAnimation(item, true)"
                    fab
                    x-small
                >
                    <span
                        v-if="item.icon || item.image"
                        class="bottom-navigation__item-title"
                        :class="{
                            'bottom-navigation__item-title--active':
                                selectedNavigation === item.title ||
                                (selectedNavigation === item.title + index && !item.ignoreIndex),
                        }"
                    >
                        {{ item.text && item.text.length > 8 ? item.text.substring(0, 8) + '...' : item.text }}
                    </span>
                    <!--- @animControl="addAnimation" -->
                    <lottie-vue-player
                        style="background-color: transparent"
                        :ref="'btn ' + item.title"
                        mode="normal"
                        v-if="item.icon"
                        class="bottom-navigation__item-lottie"
                        :name="item.title"
                        :autoplay="false"
                        :src="item.icon"
                    ></lottie-vue-player>
                    <v-img
                        class="rounded-lg"
                        v-if="item.image"
                        height="40px"
                        width="40px"
                        contain
                        :src="item.image"
                    ></v-img>
                    <div v-if="!item.icon && !item.image">
                        {{ item.text && item.text.length > 8 ? item.text.substring(0, 8) + '...' : item.text }}
                    </div>
                </v-btn>
            </div>
            <v-btn
                class="bottom-navigation__text h3 transparent px-0"
                style="
                    max-width: unset !important;
                    width: 100% !important;
                    border-radius: 23px;
                    height: 100% !important;
                "
                block
                x-large
                v-if="navigationBarText && !navigationBarText.customButtons"
                text
                depressed
                plain
                @click="toggleNavigationBarTextState"
            >
                <span class="h5 mb-0" v-if="navigationBarText.text">{{ navigationBarText.text }}</span>
                <span class="h5 mb-0" v-else>
                    {{ navigationBarText.state ? navigationBarText.true : navigationBarText.false }}
                </span>
            </v-btn>
        </div>

        <workingout-exercise-add-set
            v-if="navigationBarUsedForWorkoutSet && !navigationBarText"
        ></workingout-exercise-add-set>
        <workingout-exercise-add-set-new
            v-if="navigationBarUsedForWorkoutSetNew && !navigationBarText"
        ></workingout-exercise-add-set-new>

        <div
            :key="'standardButtonsKey' + standardButtonsKey"
            class="d-flex"
            :class="{ 'bottom-navigation-with-scrollbar': !$vuetify.breakpoint.mobile }"
            v-if="!navigationBarUsedForWorkoutSet && !navigationBarText && !navigationBarUsedForWorkoutSetNew"
            v-dragscroll="{ active: !$vuetify.breakpoint.mobile }"
        >
            <v-btn
                :key="index"
                v-for="(item, index) in filterNavigationItems"
                class="primary bottom-navigation__item mx-3 v-btn--active"
                :class="{ show: isButtonIntersected(item.title) }"
                :id="item.title"
                v-intersect="onIntersect"
                depressed
                plain
                :ripple="false"
                :value="'standardbtn-' + item.title"
                @click="startAnimation(item, false, 'standardbtn-')"
                fab
                x-small
            >
                <span
                    class="bottom-navigation__item-title"
                    :class="{ 'bottom-navigation__item-title--active': selectedNavigation === item.title }"
                >
                    {{ item.text }}
                </span>

                <!--- @animControl="addAnimation" :ref="'standardbtn-' + item.title"  -->
                <!---<lottie-vue-player
					style="background-color: transparent"
					mode="normal"
					:key="item.icon.toString()"
					class="bottom-navigation__item-lottie"
					:name="item.title"
					:autoplay="false"
					:src="item.icon.toString()"
				></lottie-vue-player> ---->

                <v-img class="rounded-lg" height="45px" width="45px" contain :src="item.icon"></v-img>
            </v-btn>
        </div>
    </v-bottom-navigation>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import WorkingoutExerciseAddSet from '../views/workouts/WorkingoutExerciseAddSet.vue'
import WorkingoutExerciseAddSetNew from '../views/workouts/WorkingoutExerciseAddSetNew.vue'
import { dragscroll } from 'vue-dragscroll'
import userService from '../services/userService'
import api from '@/api'

export default {
    name: 'BottomNavigation',
    components: {
        WorkingoutExerciseAddSet,
        WorkingoutExerciseAddSetNew,
    },
    directives: {
        dragscroll,
    },
    data() {
        return {
            userService,
            routeChanged: false,
            intersections: [],
            navigationBarTextCustomKey: 1,
            standardButtonsKey: 1,
            animations: [],
            hasNutrition: null,
        }
    },
    mounted() {
        if (document.getElementById(this.selectedNavigation)) {
            document.getElementById(this.selectedNavigation).scrollIntoView({ behavior: 'smooth', inline: 'center' })
        }
    },
    async created() {
        this.hasNutrition = await api.nutritionForUserExists()
    },
    computed: {
        ...mapState('navigationStore', ['selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', [
            'navigationVisible',
            'navigationBarText',
            'navigationItems',
            'navigationBarUsage',
        ]),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
        filterNavigationItems() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null
            this.routeChanged
            if (user && user.user) studioCode = user.user.studio_id

            let filteredItems = this.navigationItems.filter((i) =>
                i.rights ? this.userService.hasRightArray(i.rights) : true
            )
            filteredItems = filteredItems.filter((i) =>
                i.excludeStudioCodes && studioCode
                    ? i.excludeStudioCodes.some((itemStudioCode) => studioCode === itemStudioCode)
                        ? false
                        : true
                    : true
            )

            filteredItems = filteredItems.filter((i) => {
                if (!this.hasNutrition) return i.title !== 'nutrition'
                return i
            })
            this.standardButtonsKey = this.standardButtons + 1
            return filteredItems
        },
        navigationBarUsedForWorkoutSet() {
            return this.navigationBarUsage && this.navigationBarUsage.usage === 'workoutEnterSet'
        },
        navigationBarUsedForWorkoutSetNew() {
            return this.navigationBarUsage && this.navigationBarUsage.usage === 'workoutEnterSetNew'
        },
        dragScrollOptions() {
            let object = !this.$vuetify.breakpoint.mobile ? { active: true } : { active: false }
            return object
        },
    },
    methods: {
        ...mapActions('navigationStore', ['setSelectedNavigation']),
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'toggleNavigationBarTextState']),
        getNavigationBarColor() {
            if (this.navigationBarUsage) return 'white'

            if (this.navigationBarText && this.navigationBarText.color) return this.navigationBarText.color
            return this.navigationBarText && this.navigationBarText.true && this.navigationBarText.state
                ? 'error'
                : 'primary'
        },
        isButtonIntersected(title) {
            return this.intersections.find((intersect) => intersect === title)
        },
        onIntersect(entries) {
            let targetId = entries[0].target.id
            if (entries[0].isIntersecting) {
                this.intersections.push(targetId)
            } else {
                this.intersections = this.intersections.filter((intersection) => intersection !== targetId)
            }
        },
        addAnimation(animation) {
            this.animations.push(animation)
        },
        startAnimation(item, custom = false, title = null) {
            //		console.log('wa häts i de refs?', this.$refs, title, item)
            //		console.log(this.$refs[title + item.title])
            //		if (this.$refs[title + item.title]) {
            //			this.$refs[title + item.title][0].stop()
            //			this.$refs[title + item.title][0].togglePlayPause()
            //		}
            //this.$refs[title + item.title][0].togglePlayPause()
            //		this.animations.forEach((animation) => {
            //			animation.name === item.title ? animation.play() : animation.stop()
            //		})
            //			console.log('die alt', this.selectedNavigation)
            //			index !== null && item.image ? this.setSelectedNavigation(item.title + index) : this.setSelectedNavigation(item.title)
            //			console.log('die neu', this.selectedNavigation)
            //			console.log('startet animation für', item, custom, title, index)
            if (item.text === 'NFC') return (window.app.$root.$children[0].showNFCScanner = true)
            if (item.text === 'Standort') return (window.app.$root.$children[0].showStudioSelection = true)
            this.setSelectedNavigation(item.title)
            if (!custom) {
                this.$route.path !== item.to && this.$router.push(item.to)
            }
        },
    },
    watch: {
        '$route.name': {
            handler: function (search) {
                this.routeChanged = !this.routeChanged
            },
            deep: true,
            immediate: true,
        },
        selectedNavigation(value) {
            if (document.getElementById('button' + this.selectedNavigation)) {
                document
                    .getElementById('button' + this.selectedNavigation)
                    .scrollIntoView({ behavior: 'smooth', inline: 'center' })
            } else if (document.getElementById(this.selectedNavigation)) {
                document
                    .getElementById(this.selectedNavigation)
                    .scrollIntoView({ behavior: 'smooth', inline: 'center' })
            }
        },
        navigationBarText: {
            deep: true,
            handler(value) {
                this.navigationBarTextCustomKey = this.navigationBarTextCustomKey + 1
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.v-item-group.v-bottom-navigation .v-btn {
    // background-color: #52ae32 !important;
    min-width: unset !important;
    width: 45px !important;
    height: 45px !important;
    border-radius: unset;

    .v-btn__content {
        // background-color: #52ae32 !important;
    }
}

.bottom-navigation::-webkit-scrollbar {
    display: none;
}

.show {
    opacity: 1 !important;
    transition-property: opacity !important;
    transition-duration: 0.7 !important;
}

.bottom-navigation {
    z-index: 999 !important;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: auto;
    align-items: center;
    margin-bottom: 40px;
    border-radius: 20px;
    left: 0 !important;
    right: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    &-with-scrollbar {
        overflow-x: auto;
        overflow-y: hidden;
        height: 100%;
        align-items: center;
    }
    &-large {
        width: fit-content !important;
        max-width: 90% !important;
        min-width: 30% !important;
    }
    &-button-small {
        width: 50% !important;
    }
    &-with-scrollbar::-webkit-scrollbar {
        display: none;
    }
    &-small {
        max-width: 80% !important;
        width: fit-content !important;
    }
    &_workout-set-add {
        transition-property: height !important;
        transition-duration: 0.5s !important;
    }

    &__custom-buttons {
        &--wrapper {
            height: 100%;
            align-items: center;
        }
    }

    &__text {
        width: 100% !important;
        margin: 0 !important;
        text-transform: uppercase;
    }

    &__item {
        border-radius: 50% !important;
        opacity: 0;

        &-title {
            display: none;
            opacity: 0;

            &--active {
                opacity: 1 !important;
                display: unset !important;
                transition-property: opacity !important;
                transition-duration: 2s !important;
            }
        }

        &-lottie {
            height: 45px !important;
            width: 45px !important;
            display: flex;
        }
    }
}
</style>
