<template>
    <v-row justify="center">
        <v-dialog
            style="z-index: 2000"
            persistent
            id="habitCreateDialog"
            content-class="habitCreateDialog"
            v-model="dialog"
            transition="dialog-bottom-transition"
            origin="bottom center"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" icon class="elevation-5" x-large>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <div>
                <div style="z-index: 1000; position: sticky; top: 0">
                    <v-card-title class="justify-space-between primary white--text h4 mb-0">
                        <span>
                            <v-icon
                                :style="selectedHabit ? '' : 'visibility: hidden;'"
                                class="text-white"
                                @click="shrinkDiv()"
                            >
                                mdi-arrow-left-bold
                            </v-icon>
                        </span>

                        <span @click="expandDiv()">{{ selectedHabit ? selectedHabit.label : 'neue Gewohnheit' }}</span>
                        <v-btn icon color="white" @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <div style="background: rgba(255, 255, 255, 0.8); height: 7px; width: 100%"></div>
                </div>
                <v-row
                    class="justify-space-around px-7 py-0 mb-7"
                    id="habitCreateButtonRow"
                    v-if="templateSelection"
                    no-gutters
                >
                    <v-col cols="12" v-if="!selectedHabit">
                        <v-card
                            @click="setTemplateActive(template)"
                            :key="'template' + template.label"
                            v-for="template in templatesList"
                            class="d-flex py-1 mb-3"
                            style="background: #00000014"
                        >
                            <v-col class="py-0" cols="4">
                                <v-img height="45px" width="45px" :src="'/img/lottiefiles/' + template.icon"></v-img>
                            </v-col>
                            <v-col class="py-0 align-self-center" cols="8">{{ template.label }}</v-col>
                        </v-card>
                        <!-- <v-select @change="expandDiv()" v-model="selectedHabit" :items="templatesList" placeholder="Gewohnheit auswählen...">
							<template v-slot:item="{ item }">
								<v-row> </v-row>
							</template>
							<template v-slot:selection="{ item }">
								<v-row>
									<v-col class="py-0" cols="4"
										><v-img height="45px" width="45px" :src="'/img/lottiefiles/' + item.icon"></v-img
									></v-col>
									<v-col class="py-0 align-self-center" cols="8">{{ item.label }}</v-col>
								</v-row>
							</template>
						</v-select>-->
                    </v-col>

                    <!-- All Habit Forms Here -->
                    <v-col v-if="selectedHabit" cols="12">
                        <habit-drink-form
                            @habitSaved="closeDialog()"
                            @reTriggerHeight="expandDiv()"
                            :selectedHabit="selectedHabit"
                            v-if="selectedHabit.type === 'drinkHabit'"
                        ></habit-drink-form>
                        <habit-workout-form
                            @habitSaved="closeDialog()"
                            :selectedHabit="selectedHabit"
                            @reTriggerHeight="expandDiv()"
                            v-else-if="selectedHabit.type === 'workoutHabit'"
                        ></habit-workout-form>
                        <habit-group-fitness-form
                            @habitSaved="closeDialog()"
                            @reTriggerHeight="expandDiv()"
                            :selectedHabit="selectedHabit"
                            v-else-if="selectedHabit.type === 'groupFitnessHabit'"
                        ></habit-group-fitness-form>
                        <habit-wake-up-form
                            @habitSaved="closeDialog()"
                            @reTriggerHeight="expandDiv"
                            :selectedHabit="selectedHabit"
                            v-else-if="selectedHabit.type === 'wakeUpHabit'"
                        ></habit-wake-up-form>
                        <habit-medication-form
                            @habitSaved="closeDialog()"
                            @reTriggerHeight="expandDiv()"
                            :selectedHabit="selectedHabit"
                            v-else-if="selectedHabit.type === 'medicationHabit'"
                        ></habit-medication-form>
                        <div v-else>Form für habit noch nicht erstellt.</div>
                    </v-col>
                </v-row>
                <div v-else>
                    <v-row class="justify-space-around pa-7" no-gutters>
                        <v-col cols="4">
                            <div @click="setTemplateSelectionActive()" class="option-button__wrapper">
                                <v-img height="100px" width="100px" src="/img/lottiefiles/ruler.png"></v-img>
                                <span>Vorlage</span>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="option-button__wrapper">
                                <v-img height="100px" width="100px" src="/img/lottiefiles/dice.png"></v-img>
                                <span>Eigene</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="justify-space-around px-7 pb-7" no-gutters>
                        <v-col cols="10">
                            <div class="option-button__wrapper option-button__wrapper-bulb">
                                <v-img
                                    style="flex: 0 1 auto"
                                    height="80px"
                                    width="80px"
                                    src="/img/lottiefiles/bulb.png"
                                ></v-img>
                                <span style="flex: 1">Ideen</span>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitDrinkForm from './habitDrinkForm.vue'
import habitWorkoutForm from './habitWorkoutForm.vue'
import habitGroupFitnessForm from './habitGroupFitnessForm.vue'
import habitWakeUpForm from './habitWakeUpForm.vue'
import habitMedicationForm from './habitMedicationForm.vue'
import goTo from 'vuetify/lib/services/goto'

export default {
    name: 'habitCreateButton',
    components: {
        habitDrinkForm,
        habitWorkoutForm,
        habitGroupFitnessForm,
        habitWakeUpForm,
        habitMedicationForm,
    },
    data() {
        return {
            ouchIsmaFirstTime: true,
            selectedHabit: null,
            dialog: false,
            templateSelection: false,
            templatesList: [],
        }
    },
    created() {
        habitService.debugLoading()
    },
    methods: {
        setTemplateActive(template) {
            console.log(template)
            this.selectedHabit = template
            this.expandDiv()
        },
        async setTemplateSelectionActive() {
            this.templateSelection = true
            this.templatesList = habitService.list()
            this.shrinkDiv()
        },
        closeDialog() {
            document.querySelector('.habitCreateDialog').style.height = 'auto'
            this.dialog = false
            this.templateSelection = false
            this.selectedHabit = null
        },
        expandDiv(scrollElement = null) {
            console.log(scrollElement)
            let element = document.querySelector('.habitCreateDialog')
            if (this.ouchIsmaFirstTime) {
                this.ouchIsmaFirstTime = false
                element.setAttribute('data-collapsed', 'true')
            }
            // get the height of the element's inner content, regardless of its actual size
            setTimeout(() => {
                var sectionHeight = element.scrollHeight

                // have the element transition to the height of its inner content
                element.style.height = sectionHeight + 'px'

                // when the next css transition finishes (which should be the one we just triggered)
                element.addEventListener('transitionend', function (e) {
                    // remove this event listener so it only gets triggered once
                    if (arguments[0].propertyName === 'height') {
                        element.removeEventListener('transitionend', arguments[0].callee)
                    }
                    // remove "height" from the element's inline styles, so it can return to its initial value
                })
            }, 50)

            element.setAttribute('data-collapsed', 'false')
            setTimeout(() => {
                if (scrollElement) scrollElement.scrollIntoView({ behavior: 'smooth' })
            }, 200)
        },
        shrinkDiv() {
            this.selectedHabit = null

            setTimeout(() => {
                let element = document.querySelector('.habitCreateDialog')

                // get the height of the element's inner content, regardless of its actual size
                var sectionHeight = element.scrollHeight

                // temporarily disable all css transitions
                var elementTransition = element.style.transition
                element.style.transition = ''

                // on the next frame (as soon as the previous style change has taken effect),
                // explicitly set the element's height to its current pixel height, so we
                // aren't transitioning out of 'auto'
                requestAnimationFrame(function () {
                    element.style.height = sectionHeight + 'px'
                    element.style.transition = elementTransition

                    // on the next frame (as soon as the previous style change has taken effect),
                    // have the element transition to height: 0
                    requestAnimationFrame(function () {
                        element.style.height = 50 + 'vh'
                    })
                })
                element.setAttribute('data-collapsed', 'true')
            }, 50)

            // mark the section as "currently collapsed"
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
    overflow: scroll;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    width: 100%;
    margin: 0;
    max-height: 80vh;
    position: absolute;
    //min-height: 20vh;
    height: auto;
    bottom: 0;
    min-height: none;
    background: white;
    //max-height: 40vh;
}
::v-deep .extended-dialog {
    //max-height: 90vh !important;
}

.option-button {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background: lightgray;
        &-bulb {
            ::v-deep .v-image__image {
                width: 80px;
            }
            flex-direction: row;
        }
    }
    &__wrapper:hover {
        background: gray;
    }
}
</style>
