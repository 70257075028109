<template>
    <v-row class="mt-5">
        <v-col v-if="!processingPlan && !planDataPreProcessed && !planDataFinal" cols="12">
            <div class="input-wrapper mx-auto" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop">
                <label for="xslxFile" class="input-label">Ziehe die Erfolgsrechnung hier rein</label>
                <input
                    id="xslxFile"
                    type="file"
                    class="input-hidden"
                    ref="fileInputER"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    @change="importXlsx"
                />
            </div>
        </v-col>
        <v-col cols="12" v-else-if="processingPlan">
            <v-row no-gutters>
                <v-col align="center" cols="12">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
                <v-col align="center" cols="12">
                    <span>Kontenplan wird verarbeitet</span>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" v-else-if="planDataPreProcessed">
            <figures-income-statement-data-map-view
                :studioList="studioList"
                @importData="dataMappedHandler"
                @startOver="startOver"
                :data="planDataPreProcessed"
            ></figures-income-statement-data-map-view>
        </v-col>
        <v-col cols="12" v-else-if="planDataFinal">
            <v-tabs centered v-model="finalDataTab">
                <v-tab v-for="studioId of Object.keys(planDataFinal.statement)" :key="studioId + '-finalListTabs'">
                    {{ studioIdMap[studioId] }}
                </v-tab>
            </v-tabs>

            <figures-income-statement-data-view
                @startOver="startOver"
                @submitImport="submitImport"
                v-if="finalDataTab !== null"
                :data="planDataFinal.statement[Object.keys(planDataFinal.statement)[finalDataTab]]"
                :studioList="studioList"
            ></figures-income-statement-data-view>
        </v-col>
    </v-row>
</template>
<script>
import figuresIncomeStatementDataMapView from './figuresIncomeStatementDataMapView.vue'
import figuresIncomeStatementDataView from './figuresIncomeStatementDataView.vue'
import api from '@/api'
export default {
    components: { figuresIncomeStatementDataMapView, figuresIncomeStatementDataView },
    data() {
        return {
            finalDataTab: null,
            processingPlan: false,
            planDataPreProcessed: null,
            planDataFinal: null,
        }
    },
    props: {
        studio: {
            type: String,
        },
        studioList: {
            type: Array,
        },
    },
    methods: {
        async submitImport() {
            try {
                let status = await api.importCompanyFiguresIncomeStatement({ statement: this.planDataFinal })
                this.$emit('importDone')
                this.startOver()
                this.$toast.success('Import abgeschlossen')
            } catch (e) {
                this.$toast.error('Import fehlgeschlagen, bitte versuche es erneut')
            }
            console.log(this.planDataFinal, 'plan Data Final')
        },
        startOver() {
            this.processingPlan = false
            this.planDataPreProcessed = null
            this.planDataFinal = null
            this.finalDataTab = null
        },
        calculateBalances(element) {
            //delete element.studio
            //delete element.studioIsResponsive
            // Überprüfen, ob das Element Kinder hat
            if (element.children && element.children.length > 0) {
                // Initialisiere die Balance des Elements mit 0
                element.balance = 0
                // Rekursiver Aufruf für jedes Kind
                element.children.forEach((child) => {
                    element.balance += this.calculateBalances(child)
                })
            } else {
                // Wenn es keine Kinder gibt, konvertiere die Balance des aktuellen Elements zu einer Fließkommazahl
                return parseFloat(element.balance)
            }

            // Konvertiere die Balance zu einer Fließkommazahl, runde sie auf zwei Dezimalstellen und konvertiere zurück zu einer Fließkommazahl
            element.balance = parseFloat(parseFloat(element.balance).toFixed(2))

            // Übertrage die summierte Balance auf das Elternelement, wenn studioIsResponsive gesetzt ist
            if (element.studioIsResponsive) {
                delete element.studioIsResponsive
                return element.balance
            } else {
                // Wenn studioIsResponsive nicht gesetzt ist, ignoriere die Balance der Kinder

                return 0
            }
        },

        dataMappedHandler(importData) {
            for (let studioId of Object.keys(importData.statement)) {
                let statement = importData.statement[studioId]
                statement.forEach((e1) => {
                    this.calculateBalances(e1)
                })
            }
            this.planDataFinal = importData
            this.planDataPreProcessed = null
        },
        handleDragOver() {},
        handleDrop(event) {
            const files = event.dataTransfer.files
            if (files.length > 0) {
                const file = files[0]
                this.readFile(file)
            }
        },
        importXlsx(event) {
            const file = event.target.files[0]
            this.readFile(file)
        },
        async readFile(file) {
            this.processingPlan = true
            try {
                const response = await api.uploadCompanyFiguresIncomeStatement(file, this.studio)

                this.planDataPreProcessed = response

                this.$toast.success('Datei erfolgreich hochgeladen')
            } catch (error) {
                this.$toast.error('Datei konnte nicht hochgeladen werden')
                this.planDataPreProcessed = null
                console.log('Upload fehlgeschlagen:', error)
            }
            this.processingPlan = false
            /*   if (this.$refs.fileInputER) {
                this.$refs.fileInputER.value = ''
            } else if (this.$refs.fileInputER[0]) {
                this.$refs.fileInputER[0].value = ''
            }*/
        },
    },
    computed: {
        studioIdMap() {
            let studioMap = {}
            this.studioList.forEach((e) => {
                studioMap[e._id] = e.name
            })
            return studioMap
        },
    },
}
</script>
<style lang="scss" scoped>
.input-wrapper {
    width: 300px;
    height: 150px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border-color: #333;
    }
}

.input-hidden {
    display: none;
}

.input-label {
    text-align: center;
}
</style>