export const navigationStore = {
	namespaced: true,
	state: {
		selectedNavigation: 'home',
		preSelectedNavigation: 'home',
	},
	mutations: {
		setSelectedNavigation(state, data) {
			state.preSelectedNavigation = state.selectedNavigation
			state.selectedNavigation = data
		},
	},
	actions: {
		setSelectedNavigation({ commit }, data) {
			commit('setSelectedNavigation', JSON.parse(JSON.stringify(data)))
		},
	},
}
