import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
	options: {
		customProperties: true,
	},
})

export default new Vuetify({
	lang: {
		current: 'CH-DE',
	},
	theme: {
		themes: {
			light: {
				darkbackground: '#343541',
				//primary: '#00963f',
				primary: '#00963f',
				lightenprimary: '#c2e2b7',
				darkenprimary: '#346f20',
				darkenprimary2: '#2c5f1c',
				alibey: '#0d7e9e',
				listitemcolor: '#e9f4f5',
				habitBlue: '#4bb2fd',
				habitLightBlue: '#c2e2f7',
				habitLightGreen: '#f4fdf2',
				habitCompleted: '#c2e2b7',
				habitDelayed: '#ffcccc',
				protein: '#0096DD',
				fat: '#FFA500',
				carbs: '#F62F49'
			},
		},
	},
})
