<template>
	<div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Schutzkonzept</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="umfrage">
						<div v-show="submitStatus == 0" class="text">
							<div class="umfrage-titel">Deine Gesundheit ist uns wichtig!</div>
							<div class="umfrage-paragraph">
								Du hast noch ein Anliegen oder Fragen zum Schutzkonzept, dann teile es uns doch hier mit.
							</div>
						</div>
						<div class="erfolgreich" v-show="submitStatus == 1">
							Feedback wurde erfolgreich erfasst. Vielen Dank!
						</div>
						<div class="error" v-show="submitStatus == 2">
							Es gab einen Fehler. Bitte versuchen Sie es später nocheinmal.
						</div>
						<textarea
							placeholder="Teile uns hier deine Anliegen oder Fragen mit..."
							v-show="submitStatus == 0"
							v-model="umfragetext"
						></textarea>
					</div>
					<div @click="submitSurvey" v-show="submitStatus == 0" class="umfrage-senden">
						Senden
					</div>
					<button v-show="submitStatus == 1" type="button" class="btn btn-secondary cancel" data-dismiss="modal" @click.prevent="hide">
						Zurück
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import api from '../api'

export default {
	data() {
		return {
			error: '',
			submitStatus: 0,
			umfragetext: '',
		}
	},
	methods: {
		submitSurvey() {
			if (this.umfragetext != '') {
				api.submitUmfrage({
					text: this.umfragetext,
					user_id: JSON.parse(localStorage.getItem('user'))._id,
					studio_id: JSON.parse(localStorage.getItem('studio'))._id,
				})
					.then((data) => {
						this.submitStatus = 1
					})
					.catch((error) => {
						this.submitStatus = 2
					})
			} else {
				alert('Bitte fülle das Rückmeldungsfeld aus')
			}
		},
		show(labelx) {
			$(this.$refs['modal']).modal('show')
		},
		hide() {
			$(this.$refs['modal']).modal('hide')
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-title {
	font-size: 15px;
	min-width: 180px;
}
.cancel {
	margin-top: 20px;
}
.umfrage {
	margin: auto;
	padding: 0px 15px;
	&-titel {
		font-size: 18px;
		font-weight: bold;
		margin: 0px 0px;
	}
	.text {
	}
	textarea {
		width: 100%;
		height: 100px;
		margin: 10px 0px;
		padding: 5px;
		font-size: 16px;
	}
	.error {
		color: red;
	}
	&-paragraph {
		margin: 5px 0px;
		font-size: 16px;
	}
	&-senden {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		margin: auto;
		background-color: var(--v-primary);
		color: white;

		margin-bottom: 10px;
		border-radius: 5px;
	}
}
button {
	width: 100%;
	margin-bottom: 10px;
}
.choose-label {
	width: 100%;
	padding: 10px;
}
.label-editor {
	display: flex;
	flex-direction: column;
	input,
	textarea {
		padding: 10px;
		margin-bottom: 10px;
	}
	input[type='color'] {
		padding: 0px 10px;
	}
	label {
		padding: 0px 10px;
	}
}
.modal-body {
	overflow: scroll;
}
.modal-content {
	height: 90vh;
}
.modal-header .close {
	text-align: right;
}
</style>
