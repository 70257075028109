<template>
	<div class="rollenverwaltung">
		<v-card-title>Anamnesebögen</v-card-title>
		<div class="filter">
			<div v-for="(f, i) in filter" :key="i" class="filter-wrapper">
				<span>{{ i }}</span>

				<select @change="filterAnamneseboegen()" v-model="filter_values[i]">
					<option :value="filter[i][k]" v-for="(j, k) in filter[i]" :key="k">{{ filter[i][k] }}</option>
				</select>
			</div>
			<div class="filter-wrapper" v-show="checkWcf()">
				<span>Studio</span>
				<select @change="filterAnamneseboegen()" v-model="filter_values['studio_id']">
					<option value="Alle Anzeigen">Alle Anzeigen</option>
					<option value="frauenfeld">Frauenfeld</option>
					<option value="netstal">Netstal</option>
					<option value="buelach">Bülach</option>
					<option value="stgallenwest">St. Gallen West</option>
					<option value="stgallenost">St. Gallen Ost</option>
					<option value="stgalleneinstein">St. Gallen Einstein</option>
					<option value="dietlikon">Dietlikon</option>
					<option value="pfungen">Pfungen</option>
					<option value="winterthur">Winterthur</option>
				</select>
			</div>
		</div>

		<div class="search">
			<input placeholder="Benutzer suchen (mind. 3 Buchstaben):" v-on:input="findUsers" type="text" v-model="search" />
			<span class="material-icons">search</span>
		</div>
		<div v-show="loading" class="loading">
			Lädt...
		</div>
		<div class="herunterladen" @click="downloadAnamnesebogen()" v-show="userService.hasRight('ab_download') && users.length > 0">
			Liste herunterladen
		</div>
		<div class="user" v-for="(user, i) of users" :key="i">
			{{ TimestampToDate(user.date) }} | {{ user.user.name }} ({{ user.user.email }})
			<span class="material-icons" @click="showAnamesebogen(user)">edit</span>
		</div>

		<anamnesebogen ref="anamnesebogen"></anamnesebogen>
	</div>
</template>
<script>
import api from '../../api'
import $ from 'jquery'
import Anamnesebogen from '../../components/Anamnesebogen'
import zipcelx from 'zipcelx'
import userService from '../../services/userService'
export default {
	components: { Anamnesebogen },
	data() {
		return {
			userService,
			search: '',
			users: {},
			rollen: {},
			editing: {},
			filter: {},
			loading: false,
			filter_values: {},
		}
	},
	methods: {
		downloadAnamnesebogen() {
			alert('coming soon')
		},
		checkWcf() {
			if (location.host.includes('wellcomefit') || location.host.includes('winti') || location.host.includes('local')) {
				return true
			} else {
				return false
			}
		},
		filterAnamneseboegen() {
			this.users = ''
			this.loading = true
			let found = false
			let filter_ex = {}
			for (let fv in this.filter_values) {
				if (this.filter_values[fv] !== 'Alle Anzeigen') {
					filter_ex[fv] = this.filter_values[fv]
					found = true
				}
			}

			if (!found) {
				console.log('reset')
				this.loading = false
				this.users = ''
			} else {
				api.ABFilter(JSON.stringify(filter_ex)).then((data) => {
					this.users = data
					this.loading = false
				})
			}
		},
		TimestampToDate(ts) {
			return new Date(new Date(ts * 1000)).toLocaleString()
		},
		showAnamesebogen(formData) {
			this.$refs['anamnesebogen'].show(formData, false)
		},
		findUsers() {
			if (this.search.length > 2) {
				api.searchAnamnesebogen(this.search)
					.then((data) => {
						this.users = data
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				this.users = {}
			}
		},
	},
	created() {
		api.ABgetFilter()
			.then((data) => {
				for (let s in data) {
					this.filter_values[s] = 'Alle Anzeigen'
				}
				this.filter_values['studio_id'] = 'Alle Anzeigen'
				this.filter = data
			})
			.catch((e) => {
				console.log('fehler', e)
			})
	},
}
</script>
<style lang="scss" scoped>
.herunterladen {
	text-align: center;
	line-height: 50px;
	color: #fff;
	background-color: var(--v-primary);
	margin-bottom: 10px;
}
.loading {
	padding: 20px;
}
.filter {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.filter-wrapper {
	display: flex;
	flex-direction: column;

	padding: 11px;
	width: 50%;
	span {
		font-weight: bold;
		font-size: 12px;
		text-transform: uppercase;
	}
}
#rolleneditor {
	padding: 20% 10px 20% 10px;
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	background-color: white;
	h4 {
		text-align: center;
		margin-bottom: 30px;
	}
	h5 {
		text-align: center;
	}
	.rolle {
		padding-left: 0px !important;
	}
	.rollen-wrapper {
		max-width: 400px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		padding: 5px;
		input {
			width: 20px;
			height: 20px;
			padding: 10px;
		}
	}

	.speichern {
		background-color: var(--v-primary);
		color: white;
		margin: 20px auto 0px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
	.schliessen {
		background-color: #443838;
		color: white;
		margin: 20px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
	.delete {
		background-color: red;
		color: white;
		margin: 40px auto 0px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		border-radius: 45px;
	}
}
label {
	padding-left: 10px;
}
.search {
	position: relative;
	.material-icons {
		position: absolute;
		top: 10px;
		right: 20px;
		color: #4a4a4a;
	}
	input {
		color: #4a4a4a;
		width: 100%;
		padding: 15px 15px 10px 15px;
		border-bottom: 1px solid #4a4a4a;
	}
}
.save {
	color: white;
	width: 130px;
	background-color: var(--v-primary);
	margin: 20px auto;
	text-align: center;
	padding: 10px;
	border-radius: 40px;
}

.user {
	color: #4a4a4a;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	display: flex;
	border-bottom: 1px solid #4a4a4a;

	.username {
		max-width: 80%;
		display: block;
	}
	.material-icons {
		margin-left: auto;
		margin-right: 10px;
		margin-top: auto;
		margin-bottom: auto;
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
