<template>
    <div v-if="showInformation" class="livestreamInformation">
        <v-row>
            <v-col cols="12">
                <div>
                    Du befindest dich im Livestream Studio. Um dich für einen Kurs vor Ort anzumelden, wechsel in das Studio,
                    in welchem du trainieren möchtest
                </div>
            </v-col>
            <v-col class="d-flex justify-center mb-2" cols="12">
                <v-btn color="primary" class="align-center" @click="showStudioPicker()"
                    ><v-icon class="pr-2">mdi-map-marker-radius-outline</v-icon><span>Studio wechseln</span></v-btn
                >
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import abortService from '../../services/abortService'

export default {
    name: 'livestreamInformation',

    data() {
        return {
            userService,
            showInformation: false,
        }
    },

    async created() {
        let user = userService.getUser()
        if (user && user.studio_id && user.studio_id === 'ot') this.showInformation = true
    },
    beforeDestroy() {},
    methods: {
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
    },
    computed: {},
}
</script>

<style lang="scss" scoped>
.livestreamInformation {
}
</style>
