<template>
  <div v-if="authenticated" class="Home">
    <div class="Home__greeting">
      <v-card-subtitle class="Home__greeting-subtitle text-black"
        >Hey {{ user.vorname }},</v-card-subtitle
      >
      <v-card-title class="Home__greeting-title h6 pb-0">
        Bist Du bereit für
        <br />
        dein heutiges
        <span class="Home__greeting-title-training">Training</span>
        ?
      </v-card-title>
      <div
        @click="$router.push('/intern/referral')"
        :class="{ 'Home__greeting-referral': roodyGeneral }"
      ></div>
    </div>

    <div class="Home__training">
      <my-training data-v-step="0"></my-training>
    </div>

    <v-row class="personalTraining" v-if="alibeyStudio">
      <v-btn
        x-large
        class="white--text mx-auto py-4 my-5"
        color="#ffa500"
        @click="$router.push('/terminept')"
      >
        <span class="py-5">
          Dein
          <br />
          Willkommensgeschenk
        </span>
      </v-btn>
    </v-row>

    <router-link
      v-if="tendays_date != null && tendays_date > 0 && !user.abo"
      to="/membercard"
      tag="div"
      class="link"
    >
      <div class="membercard-wrapper">
        <div class="countdown-wrapper">
          <span class="time_left">Verbleibende Zeit</span>
          <vue-countdown
            :time="tendays_date"
            v-slot="{ days, hours, minutes, seconds }"
            class="countdown"
          >
            <div class="tage">
              <span class="up">
                {{ days }}
              </span>
              <span class="down">TAGE</span>
            </div>
            <div class="stunden">
              <span class="up">
                {{ hours }}
              </span>
              <span class="down">STUNDEN</span>
            </div>
            <div class="minuten">
              <span class="up">
                {{ minutes }}
              </span>
              <span class="down">MINUTEN</span>
            </div>
            <div class="sekunden">
              <span class="up">
                {{ seconds }}
              </span>
              <span class="down">SEKUNDEN</span>
            </div>
          </vue-countdown>
        </div>

        <span class="mcard-span">MEMBERCARD</span>
      </div>
    </router-link>

    <div
      v-if="!local"
      class="Home__diverse"
      :class="{
        'mb-10': $vuetify.breakpoint.mdAndUp,
        'mb-5': $vuetify.breakpoint.mdAndDown,
      }"
    >
      <diverse-slider data-v-step="1"></diverse-slider>
    </div>
    <div>
      <payment-home></payment-home>
    </div>

    <div
      v-if="!local"
      class="Home__groupFitness"
      :class="{ 'pb-5': hideLSStudio }"
    >
      <next-courses data-v-step="2"></next-courses>
    </div>
    <div v-else class="Home__groupFitness" :class="{ 'pb-5': hideLSStudio }">
      <next-courses-home data-v-step="2"></next-courses-home>
    </div>

    <div
      v-if="roodyGeneral && !hideLSStudio"
      class="Home_livestreams mb-5 pb-5"
    >
      <livestreams></livestreams>
    </div>

    <div class="Home__news mb-10"><news-slider></news-slider></div>
  </div>
</template>

<script>
import api from "../api";
import PaymentHome from "./dashboard/payment/PaymentHome.vue";
import nextCourses from "../components/gf/nextCourses.vue";
import nextCoursesHome from "../components/gf/nextCoursesHome.vue";
import livestreams from "../components/gf/livestreams.vue";
import newsSlider from "../components/news/newsSlider.vue";
import myTraining from "../components/training/myTraining.vue";
import diverseSlider from "../components/home/diverseSlider.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";

import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    VueCountdown,
    diverseSlider,
    nextCourses,
    nextCoursesHome,
    newsSlider,
    myTraining,
    livestreams,
    PaymentHome,
  },
  data() {
    return {
      hideLSStudio: true,
      tendays_date: null,
    };
  },
  mounted() {
    if (
      localStorage.getItem("token") &&
      !localStorage.getItem("tourFinished") &&
      (!localStorage.getItem("tourSkipped") ||
        this.checkTime(localStorage.getItem("tourSkipped")) > 23)
    ) {
      this.$emit("showTour");
    }
  },
  async created() {
    if (localStorage.getItem("token")) {
      if (!localStorage.getItem("pushToken") && window.flutter_inappwebview) {
        //|| this.checkTime(localStorage.getItem('initPushCooldown')) > 23
        if (!localStorage.getItem("initPushCooldown")) {
          this.$emit("showPushPopUp");
        }
      }
      this.$emit("checkPopup");
      this.$emit("checkVersion");
      this.$emit("fetchNotifications");
      this.setNavigationBarText(null);

      if (!this.navigationVisible) this.toggleNavigationBarVisibility();
      this.setNewBottomNavigationUsage(null);
    }

    // ToDo: vllt chli geiler mache aber er fetched nachm logout -> login de user ned neu wills i de app.vue isch

    console.log("set user");
    api.getUser().then((u) => {
      // if (!u.user.hideLandingPage) this.$router.push({ name: 'Landing' })

      this.setUser(u.user);

      localStorage.setItem("user", JSON.stringify({ user: u.user }));
      if (
        !u.user.studio_id ||
        u.user.studio_id === "frauenfeld" ||
        u.user.studio_id === "livestreams" ||
        u.user.studio_id === "ot"
      ) {
        this.hideLSStudio = true;
      } else {
        this.hideLSStudio = false;
      }
      if (u.user.tendays_date) {
        let now = new Date(this.user.tendays_date * 1000);
        let end = now.setDate(now.getDate() + 10);
        now = new Date();

        this.tendays_date = end - now;
      }
    });
  },
  computed: {
    ...mapState("navigationStoreNotPersisted", ["navigationVisible"]),
    ...mapState("userStore", ["user"]),
    local() {
      return location.host.includes("local");
    },
    alibeyStudio() {
      let user = JSON.parse(localStorage.getItem("user"));
      let studioCode = null;

      if (user && user.user) studioCode = user.user.studio_id;

      if (
        studioCode &&
        (studioCode === "Alibey" ||
          studioCode === "alibey" ||
          studioCode === "ali")
      ) {
        return true;
      } else {
        return false;
      }
    },

    authenticated() {
      if (localStorage.getItem("token")) return true;
      return false;
    },
    roodyGeneral() {
      if (
        location.host.includes("wellcomefit") ||
        location.host.includes("localhost") ||
        location.host.includes("wintifit")
      )
        return true;
      return false;
    },
  },

  methods: {
    ...mapActions("navigationStoreNotPersisted", [
      "setNavigationBarText",
      "setNewBottomNavigationUsage",
      "toggleNavigationBarVisibility",
    ]),
    ...mapActions("userStore", ["setUser"]),

    checkTime(item) {
      var currentTime = this.$moment();
      var timeStore = this.$moment(item.created);

      return currentTime.diff(timeStore, "h");
    },
  },
};
</script>

<style lang="scss" scoped>
.membercard-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;

  .countdown-wrapper {
    margin: auto 0 0 0;
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .time_left {
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      padding: 5px 0px 5px 0px;
      font-size: 13px;
      letter-spacing: 2px;
      text-align: center;
      background-color: #ff9800;
      margin-bottom: 7px;
    }
    .countdown {
      display: flex;
      flex-direction: row;
      width: 305px;
      margin: auto;
      > div {
        margin: 10px;
        display: flex;
        flex-direction: column;
      }
      span.up {
        text-align: center;
      }
      span.down {
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .mcard-span {
    margin: auto;
    padding-top: 100px;
  }
}
.section {
  position: relative;
}

.tag {
  display: flex;
  justify-content: space-between;

  .tag_title {
    font-size: 18px;
    letter-spacing: 0.5px;
  }

  .tag_subtitle {
    font-size: 14px;
    margin: auto 0px;
  }
  span {
    font-weight: bold;
  }
}

.Home {
  &__greeting {
    display: block;
    position: relative;
    padding-top: 40px;
    &-referral {
      height: 110px;
      width: 160px;
      background-size: contain;
      background-image: url(/img/kampagne/refer/referAFriend100.png);
      position: absolute;
      top: 0px;
      right: 0px;
    }

    &-subtitle {
      color: black !important;
      letter-spacing: 1px;
      font-weight: bold;
      padding-bottom: 0px;
    }
    &-title {
      letter-spacing: 1px;
      padding-top: 0px;
      display: block;
      font-weight: bold;
      &-training {
        color: orange;
      }
    }
  }
}
</style>
