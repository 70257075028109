export const userStore = {
	namespaced: true,
	state: {
		user: {},
		userRights: [],
	},
	getters: {
		courseRecommendations(state) {
			return state.user?.courseRecommendations || []
		},
		userName(state) {
			return state.user ? state.user.userName : ''
		},
		userWeight(state) {
			return state.user ? state.user.gewicht : ''
		},
	},
	mutations: {
		setUser(state, data) {
			state.user = data
		},
		setUserRights(state, data) {
			state.userRights = data
		},
	},
	actions: {
		setUser({ commit }, data) {
			console.log('setting user')
			commit('setUser', JSON.parse(JSON.stringify(data)))
		},
		setUserRights({ commit }, data) {
			console.log('setting user rights')
			commit('setUserRights', JSON.parse(JSON.stringify(data)))
		},
	},
}
