const moment = require('moment')

export default new (class {
	timestampToDate(timestamp) {
		return new Date(new Date(timestamp * 1000)).toLocaleDateString('de-CH')
	}

	timestampToTime(timestamp) {
		timestamp = parseInt(timestamp)

		let hours = new Date(timestamp * 1000).getHours()

		let minutes = new Date(timestamp * 1000).getMinutes()
		if (minutes < 10) {
			minutes = '0' + minutes
		}
		let value = hours + ':' + minutes
		return value
	}

	getCourseEndTime(course) {
		let endTime = moment()
		endTime.set({
			hours: course.hours,
			minutes: course.minutes,
			seconds: 0,
			miliseconds: 0,
		})

		endTime.add(course.duration, 'minutes')
		return endTime.format('LT')
	}

	getCourseStartTime(course) {
		let startTime = moment()
		startTime.set({
			hours: course.hours,
			minutes: course.minutes,
			seconds: 0,
			miliseconds: 0,
		})

		return startTime.format('LT')
	}
})()
