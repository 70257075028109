<template>
	<div class="kurse">
		<div
			v-if="
				userService.hasRight('gf_c_edit', 'gf_c_delete', 'gf_c_plan', 'gf_c_create') &&
					user.studio_id == 'ot' &&
					user.customer_id == '5ffc504f26abe65228e36a21'
			"
			class="edit-kurse"
		>
			<router-link to="/kurse/livestream/blacklist">
				<span>Livestream Blacklist</span>
				<span class="material-icons">navigate_next</span>
			</router-link>
		</div>
		<div v-if="userService.hasRight('gf_c_edit', 'gf_c_delete', 'gf_c_plan', 'gf_c_create')" class="edit-kurse">
			<router-link to="/kurse/edit">
				<span>Kurse bearbeiten</span>
				<span class="material-icons">navigate_next</span>
			</router-link>
		</div>

		<div
			v-if="userService.hasRight('gf_c_edit', 'gf_c_delete', 'gf_c_plan', 'gf_c_create', 'gf_cp_edit', 'gf_f_courses')"
			class="filterkurse-bydate"
		>
			<input @change="refreshList(true)" v-model="filterDate" type="date" />
		</div>
		<div class="filter">
			<div class="title">Aktueller Kursplan</div>
			<div class="filter-options">
				<select @change="filterListCourse" v-model="selectedCourse">
					<option value="all">Alle Kurse</option>
					<option :value="course" v-for="(course, j) in courseList" :key="j">
						{{ course }}
					</option>
				</select>
				<select @change="filterListInstructor" v-model="selectedInstructor">
					<option value="all">Alle Instruktoren</option>
					<option :value="ins" v-for="(ins, i) in instructorList" :key="i">
						{{ ins }}
					</option>
				</select>
			</div>
		</div>
		<!---
    <div class="nachricht" v-show="checkHost()">
      <span
        >Um auf die Livestreams zuzugreifen klicke auf "Auswählen" und wähle
        anschliessend "A. Online Training" aus</span
      >
      <div @click="openStudioPicker" class="pickstud">
        Auswählen
      </div>
    </div> -->
		<div class="loading" v-show="loading"><img src="\img\icons\loading.gif" />Kurse werden geladen..</div>

		<div v-if="user.studio_id == 'ot'" class="no-courses_livestream" v-show="course_date_array.length == 0 && !loading">
			Unser Livestream Angebot ist ab Dienstag, 20. April wieder verfügbar.<br />
			Ab dann bieten wir hier wöchentlich bis zu 70 Livestreams.<br /><br />

			Wir bitten Dich um einen Moment Geduld und freuen uns sehr auf Dich!<br />
			Dein {{ getArt() }} Team
		</div>

		<div v-if="user.studio_id == 'stgallenwest'" class="no-courses_livestream" v-show="course_date_array.length == 0 && !loading">
			<span
				>Um die Groupfitness Pläne einsehen zu können, wechsle bitte über den Button "Studio Auswählen" auf das Studio "St. Gallen Ost" oder
				"St. Gallen Einstein"</span
			>
			<div @click="studPicker()" class="stud-picker">Studio Auswählen</div>
		</div>

		<div
			v-if="user.studio_id !== 'ot' && user.studio_id !== 'stgallenwest'"
			class="no-courses"
			v-show="course_date_array.length == 0 && !loading"
		>
			Aktuell finden keine Kurse statt
		</div>
		<div class="no-courses" v-show="noCourses && user.studio_id !== 'ot' && user.studio_id !== 'stgallenwest'">
			Es wurden leider keine Kurse gefunden, die deinen Filteroptionen entsprechen
		</div>
		<div v-show="!loading" v-for="(datum, i) in course_date_array" :key="i" class="kurse-wrapper">
			<div v-show="course_formatted[datum].length > 0" class="kurs-datum-titel">
				{{ datum }}
			</div>
			<ul class="kurs-wrapper-date">
				<li
					:data-id="kurs._id"
					:class="{
						covid_warteliste: covid_warteliste_leitung.includes(kurs._id),
						'signed-up': signups[kurs._id],
						abgelaufen: kurs.timestamp_end < time || kurs.cancelled,
						cancelled: kurs.cancelled,
						big: ls_toggle_signin[kurs._id],
						'bewerten-wrapper':
							kurs.timestamp_end < time && signups[kurs._id] && !userService.hasRight('gf_cp_edit', 'gf_c_plan', 'gf_cp_cancel'),
					}"
					v-for="(kurs, i) in course_formatted[datum]"
					:key="i"
				>
					<div class="ls_wrapper" v-if="studio._id == '6009e4098b98783294411156' && ls_toggle_signin[kurs._id]">
						<div class="ls_title">
							<span>
								{{ ls_toggle_signin_kurs.course_name }}
							</span>
						</div>
						<div class="ls_info">LIVESTREAM STARTET IN</div>
						<div class="ls_countdown">
							<div v-show="ls_countdown.days > 0" class="days t-wrap">
								<span class="top">
									{{ ls_countdown.days }}
								</span>
								<span class="bottom"> TAGE </span>
							</div>
							<div class="hours t-wrap">
								<span class="top">
									{{ ls_countdown.hours }}
								</span>
								<span class="bottom"> STUNDEN </span>
							</div>
							<div class="minutes t-wrap">
								<span class="top">
									{{ ls_countdown.minutes }}
								</span>
								<span class="bottom"> MINUTEN </span>
							</div>
							<div class="seconds t-wrap">
								<span class="top">
									{{ ls_countdown.seconds }}
								</span>
								<span class="bottom"> SEKUNDEN </span>
							</div>
						</div>
						<div @click="ls_signup(kurs)" class="ls_anmelden">
							HIER ANMELDEN
						</div>
					</div>

					<div
						v-else
						:class="{
							'link-wrapper': 1,
							abgelaufen: kurs.timestamp_end < time,
						}"
					>
						<router-link
							:event="
								studio._id !== '6009e4098b98783294411156' &&
								(kurs.timestamp_end > time ||
									kurs.instructor_id === user._id ||
									userService.hasRight('gf_cp_edit', 'gf_c_plan', 'gf_cp_cancel'))
									? 'click'
									: ''
							"
							:disabled="
								studio._id == '6009e4098b98783294411156' ||
									(kurs.timestamp_end < time &&
										!(kurs.instructor_id === user._id) &&
										!userService.hasRight('gf_cp_edit', 'gf_c_plan', 'gf_cp_cancel'))
							"
							:to="{
								path: '/kurse/anmeldung/' + kurs._id,
							}"
						>
							<div class="kurs-name">
								{{ kurs.course_name }}
								<span
									v-show="
										studio._id !== '6009e4098b98783294411156' &&
											!labels[kurs._id].filter((label) => label.code == 'SOMMERPAUSE').length > 0
									"
									:class="{
										'kurs-platz': 1,
										ueberbucht: parseInt(kurs.course_free_spaces.split('/')[0]) > parseInt(kurs.course_free_spaces.split('/')[1]),
									}"
									>{{ kurs.course_free_spaces }}</span
								>
							</div>
							<div class="kurs-zeit">
								{{ TimestampToTime(kurs.timestamp) }} -
								{{ TimestampToTime(kurs.timestamp_end) }}
							</div>
							<div class="kurs-instruktor-raum">
								{{ kurs.instructor_name }}{{ nameSeperator(kurs.instructor_name) }} {{ kurs.room_name }}
							</div>
							<span
								class="evaluate"
								@click="bewerten(kurs)"
								v-show="
									kurs.timestamp_end < time &&
										!userService.hasRight('gf_cp_edit', 'gf_c_plan', 'gf_cp_cancel') &&
										signups[kurs._id] &&
										!warteliste[kurs._id] &&
										!covid_warteliste.includes(kurs._id)
								"
								>Jetzt bewerten</span
							>

							<span v-if="signups[kurs._id] && !warteliste[kurs._id] && !covid_warteliste.includes(kurs._id)" class="material-icons sup"
								>check_circle</span
							>
							<div v-if="signups[kurs._id] && warteliste[kurs._id] && !covid_warteliste.includes(kurs._id)" class="warteliste-wrapper">
								{{ warteliste[kurs._id] }}
								<span class="material-icons">priority_high</span>
							</div>

							<div v-if="covid_warteliste.includes(kurs._id)" class="warteliste-wrapper mr-2">
								<span class="material-icons">pending_actions</span>
							</div>

							<router-link
								class="watch_live red"
								:id="'wl-btn-' + kurs._id"
								:to="'/watch/' + kurs.room_name"
								v-show="
									studio._id == '6009e4098b98783294411156' &&
										kurs.timestamp_end > time &&
										kurs.timestamp < time &&
										!labels[kurs._id].filter(
											(label) => label.code.toLowerCase().includes('pause') || label.code.toLowerCase().includes('ferien')
										).length > 0
								"
								>JETZT LIVE
							</router-link>
							<div
								@click="ls_signoff(kurs)"
								class="watch_live abmelden"
								:id="'wl-btn-' + kurs._id"
								v-show="studio._id == '6009e4098b98783294411156' && kurs.timestamp > time && ls_signups[kurs._id]"
							>
								ABMELDEN
							</div>
							<div
								@click="watchLive(kurs)"
								class="watch_live"
								:id="'wl-btn-' + kurs._id"
								v-show="
									studio._id == '6009e4098b98783294411156' &&
										kurs.timestamp > time &&
										!ls_signups[kurs._id] &&
										!labels[kurs._id].filter((label) => label.code == 'SOMMERPAUSE').length > 0
								"
							>
								LIVE ANSEHEN
							</div>

							<span
								v-if="
									!covid_warteliste.includes(kurs._id) &&
										!signups[kurs._id] &&
										!warteliste[kurs._id] &&
										studio._id !== '6009e4098b98783294411156'
								"
								class="material-icons"
								>navigate_next</span
							>
							<div class="labels">
								<div :style="'background-color: ' + label.color" class="label" v-for="(label, ji) of labels[kurs._id]" :key="ji">
									{{ label.text }}
								</div>
							</div>
						</router-link>
					</div>
				</li>
			</ul>
		</div>

		<span v-show="userService.hasRight('gf_cp_edit', 'gf_c_plan')" class="download-kursliste material-icons" @click="excelfyKursliste">
			download_for_offline
		</span>

		<span v-show="!userService.hasRight('gf_cp_edit', 'gf_c_plan')" class="download-kursliste material-icons" @click="downloadKursliste()">
			download_for_offline
		</span>

		<ratecourse ref="ratecourse"></ratecourse>
	</div>
</template>

<script>
import api from '../api'
import $ from 'jquery'
import { jsPDF } from 'jspdf'
import zipcelx from 'zipcelx'
import ratecourse from '../components/RateCourse'
import userService from '../services/userService'

export default {
	components: { ratecourse },
	data() {
		return {
			userService,
			ls_countdown: {
				days: '00',
				hours: '00',
				minutes: '00',
				seconds: '00',
			},
			user: {},
			ls_signups: {},
			ls_toggle_signin: {},
			temp_kurs: null,
			noCourses: false,
			selectedInstructor: 'all',
			selectedCourse: 'all',
			instructorList: [],
			courseList: [],
			studio_id: null,
			studio: null,
			signups: [],
			warteliste: [],
			covid_warteliste: [],
			covid_warteliste_leitung: [],
			filterDate: null,
			labels: [],
			course_timelines: [],
			course_formatted: [],
			course_date_array: [],
			isAdmin: false,
			x: null,
			rollen: {},
			loading: true,
			time: Math.round(new Date() / 1000),
			interval: null,
		}
	},
	beforeDestroy() {
		clearInterval(this.interval)
		clearInterval(this.wl_interval)
	},
	updated() {
		let query = Object.assign({}, this.$route.query)
		if (query.scrollpos) {
			let locscroll = query.scrollpos

			if (document.querySelectorAll('[data-id="' + locscroll + '"]')[0]) {
				if (document.querySelectorAll('[data-id="' + locscroll + '"]')[0].offsetTop > 150) {
					window.scroll(0, document.querySelectorAll('[data-id="' + locscroll + '"]')[0].offsetTop - 150)

					$('[data-id="' + locscroll + '"]').addClass('highlight')
					setTimeout(function() {
						$('[data-id="' + locscroll + '"]').removeClass('highlight')
					}, 3500)
					let query = Object.assign({}, this.$route.query)
					delete query.scrollpos
					this.$router.replace({ query })
				}
			}
		}
	},

	methods: {
		nameSeperator(name) {
			if (name) {
				return ','
			} else {
				return ' '
			}
		},
		ls_load() {
			api.loadLivestreamsignups().then((data) => {
				for (let signup of data) {
					this.ls_signups[signup.course_generate_id] = true
				}
			})
		},
		ls_signup(kurs) {
			this.resetLSCountdown()
			this.ls_toggle_signin = []
			this.ls_toggle_signin_kurs = []
			api.ls_signup(kurs).then((data) => {
				this.ls_signups[kurs._id] = true
			})
		},
		ls_signoff(kurs) {
			api.ls_signoff(kurs).then((data) => {
				this.ls_signups[kurs._id] = false
			})
		},
		resetLSCountdown() {
			this.ls_countdown.hours = '00'
			this.ls_countdown.minutes = '00'
			this.ls_countdown.seconds = '00'
			this.ls_countdown.days = '00'
		},
		showTeilnehmer(kurs) {
			api.loadLSSignups(kurs._id).then((data) => {
				alert('Anzahl Teilnehmer: ' + data.length)
			})
		},
		watchLive(kurs) {
			this.resetLSCountdown()
			clearInterval(this.wl_interval)
			this.ls_toggle_signin = []
			this.ls_toggle_signin[kurs._id] = true
			this.ls_toggle_signin_kurs = kurs

			let ref = this

			this.wl_interval = setInterval(function() {
				// Get today's date and time
				var now = new Date().getTime()
				var countDownDate = new Date(kurs.timestamp * 1000).getTime()
				// Find the distance between now and the count down date
				var distance = countDownDate - now

				// Time calculations for days, hours, minutes and seconds
				var days = Math.floor(distance / (1000 * 60 * 60 * 24))
				var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
				var seconds = Math.floor((distance % (1000 * 60)) / 1000)

				days = days < 10 ? '0' + days : days
				seconds = seconds < 10 ? '0' + seconds : seconds
				minutes = minutes < 10 ? '0' + minutes : minutes
				hours = hours < 10 ? '0' + hours : hours
				// Output the result in an element with id="demo"
				ref.ls_countdown.days = days
				ref.ls_countdown.hours = hours
				ref.ls_countdown.minutes = minutes
				ref.ls_countdown.seconds = seconds
				// If the count down is over, write some text
				if (distance < 0) {
					clearInterval(ref.wl_interval)
					console.log('abgelaufen')
				}
			}, 1000)
		},
		studPicker() {
			window.app.$root.$children[0].checkStudio(true)
		},
		getArt() {
			if (location.host.includes('local') || location.host.includes('wellcomefit')) {
				return 'well come FIT'
			}
			if (location.host.includes('wintifit')) {
				return 'winti FIT'
			}
			if (location.host.includes('fzw')) {
				return 'Fitness Zenter Wetzikon'
			}
		},
		checkHost() {
			if (location.host.includes('wintifit') || location.host.includes('wellcomefit')) {
				return true
			} else {
				return false
			}
		},
		openStudioPicker() {
			window.app.$root.$children[0].checkStudio(true)
		},
		excelfyKursliste() {
			/*
      let exceldata = [];
      exceldata.push([
        {
          value: "Datum",
          type: "string",
        },
        {
          value: "Startzeit",
          type: "string",
        },
        {
          value: "Endzeit",
          type: "string",
        },
        {
          value: "Dauer (min)",
          type: "string",
        },
        {
          value: "Kurs",
          type: "string",
        },
        {
          value: "Instruktor*in",
          type: "string",
        },
        {
          value: "Raum",
          type: "string",
        },
        {
          value: "Labels",
          type: "string",
        },
        {
          value: "Anz. Teilnehmer",
          type: "string",
        },
        {
          value: "Anz. Plätze",
          type: "string",
        },
      ]);

      for (let datum of this.course_date_array) {
        for (let kurs of this.course_formatted[datum]) {
          let labeltext = "";
          for (let label of this.labels[kurs._id]) {
            labeltext = labeltext + label.code;
            if (this.labels[kurs._id].length > 1) {
              labeltext = labeltext + " / ";
            }
          }

          exceldata.push([
            {
              value: datum,
              type: "string",
            },
            {
              value: this.TimestampToTime(kurs.timestamp),
              type: "string",
            },
            {
              value: this.TimestampToTime(kurs.timestamp_end),
              type: "string",
            },
            {
              value: kurs.timestamp_end / 60 - kurs.timestamp / 60,
              type: "string",
            },
            {
              value: kurs.course_name,
              type: "string",
            },
            {
              value: kurs.instructor_name,
              type: "string",
            },
            {
              value: kurs.room_name,
              type: "string",
            },
            {
              value: labeltext,
              type: "string",
            },
            {
              value: parseInt(kurs.course_free_spaces.split("/")[0]),
              type: "string",
            },
            {
              value: parseInt(kurs.course_free_spaces.split("/")[1]),
              type: "string",
            },
          ]);
        }
      }

      const config = {
        filename:
          "Kursliste_" + new Date().toLocaleDateString().replace(" ", "_"),
        sheet: {
          data: exceldata,
        },
      };

      zipcelx(config);
      */
			let exceldata = []
			exceldata.push([
				{
					value: 'Kursliste',
					type: 'string',
				},
			])

			for (let datum of this.course_date_array) {
				for (let kurs of this.course_formatted[datum]) {
					let labeltext = ''
					for (let label of this.labels[kurs._id]) {
						labeltext = labeltext + label.code
						if (this.labels[kurs._id].length > 1) {
							labeltext = labeltext + ' / '
						}
					}

					exceldata.push([
						{
							value: datum.substring(0, 2).toUpperCase() + ' ' + this.TimestampToTime(kurs.timestamp) + ' ' + kurs.course_name,
							type: 'string',
						},
					])
				}
			}

			const config = {
				filename: 'Kursliste_' + new Date().toLocaleDateString().replace(' ', '_'),
				sheet: {
					data: exceldata,
				},
			}

			zipcelx(config)
		},
		filterListInstructor() {
			this.formatTimeline()
		},
		filterListCourse() {
			this.formatTimeline()
		},
		bewerten(id) {
			this.$refs['ratecourse'].show(id)
		},
		TimestampToTime(timestamp) {
			timestamp = parseInt(timestamp)

			let hours = new Date(timestamp * 1000).getHours()
			if (hours < 10) {
				hours = '0' + hours
			}
			let minutes = new Date(timestamp * 1000).getMinutes()
			if (minutes < 10) {
				minutes = '0' + minutes
			}
			let value = hours + ':' + minutes
			return value
		},
		formatTimeline() {
			let course_formatted_temp = []
			let courses_found = false
			let ref = this
			this.course_timelines.forEach((course_timeline) => {
				if (this.instructorList.indexOf(course_timeline.instructor_name) === -1) {
					this.instructorList.push(course_timeline.instructor_name)
				}

				if (this.courseList.indexOf(course_timeline.course_name) === -1) {
					this.courseList.push(course_timeline.course_name)
				}
				var date = new Date(course_timeline.timestamp * 1000).toLocaleDateString()
				var day = new Date(course_timeline.timestamp * 1000).getDay()
				let day_string = ''
				switch (day) {
					case 0:
						day_string = 'Sonntag, '
						break
					case 1:
						day_string = 'Montag, '
						break
					case 2:
						day_string = 'Dienstag, '
						break
					case 3:
						day_string = 'Mittwoch, '
						break
					case 4:
						day_string = 'Donnerstag, '
						break
					case 5:
						day_string = 'Freitag, '
						break
					case 6:
						day_string = 'Samstag, '
						break
				}
				date = day_string + date

				course_formatted_temp.push({
					course_timeline,
					date: date,
				})
			})

			/*
      course_formatted_temp.forEach((kurs, i) => {
        this.course_date_array.push(kurs.date);

        if (i == 0) {
          this.course_formatted[kurs.date] = [];
          this.course_formatted[kurs.date].push(kurs.course_timeline);
        } else {
          if (kurs.date !== course_formatted_temp[i - 1].date) {
            this.course_formatted[kurs.date] = [];
            this.course_formatted[kurs.date].push(kurs.course_timeline);
          }
        }

      });
      */
			this.course_date_array = []
			this.course_formatted = []
			course_formatted_temp.forEach((kurs, i) => {
				this.course_date_array.push(kurs.date)
				if (!this.course_formatted[kurs.date]) {
					this.course_formatted[kurs.date] = []
				}

				if (this.selectedInstructor !== 'all' || this.selectedCourse !== 'all') {
					let check = false
					if (this.selectedInstructor !== 'all') {
						if (kurs.course_timeline.instructor_name == this.selectedInstructor) {
							check = true
						}
					}
					if (this.selectedCourse !== 'all') {
						if (kurs.course_timeline.course_name == this.selectedCourse) {
							check = true
						}
					}
					if (this.selectedCourse !== 'all' && this.selectedInstructor !== 'all') {
						check = false
						if (
							kurs.course_timeline.course_name == this.selectedCourse &&
							kurs.course_timeline.instructor_name == this.selectedInstructor
						) {
							check = true
						}
					}
					if (check) {
						courses_found = true
						this.course_formatted[kurs.date].push(kurs.course_timeline)
					}
				} else {
					courses_found = true
					this.course_formatted[kurs.date].push(kurs.course_timeline)
				}
			})
			this.instructorList.sort()
			this.courseList.sort()
			this.noCourses = !courses_found

			this.course_date_array = [...new Set(this.course_date_array)]
			this.ls_load()
		},

		async refreshList(bydate = false) {
			this.loading = true
			try {
				let courseList = null
				if (!bydate) {
					courseList = await api.getKursetimelinesGenerated(this.studio._id)
				} else {
					let date = Math.round(new Date(this.filterDate) / 1000)
					courseList = await api.getKursetimelinesGeneratedbyDate({ date })
				}

				await this.loadLabels(courseList, true)
				this.course_timelines = courseList.course_generates
				await this.formatTimeline()
				if (userService.hasRight('covid_gf')) {
					this.loadCovidQueueAll(this.course_timelines.map((e) => e._id))
				}
			} catch (e) {
				console.log('Failed to refresh List')
			}
		},
		loadLabels(data_kg, load = false) {
			let gna_ids = []
			for (let gna of data_kg.course_generates) {
				gna_ids.push(gna.course_timeline_id)
			}
			api.getLabelsByGenTimelines({ course_timeline_ids: gna_ids })
				.then((data) => {
					this.labels = []
					for (let gna of data_kg.course_generates) {
						let found_labels = false
						for (let tl of data.timelines_labels) {
							if (tl.course_timeline_id == gna.course_timeline_id) {
								found_labels = true
								if (!this.labels[gna._id]) {
									this.labels[gna._id] = []
								}
								this.labels[gna._id].push(tl.label)
							}
						}
						if (!found_labels) {
							this.labels[gna._id] = []
						}
					}
				})
				.catch((error) => {})

			this.loading = false
		},
		downloadKursliste() {
			let doc = new jsPDF({
				orientation: 'p',
				unit: 'mm',
				format: [297, 210],
			})
			doc.setFontSize(11)
			let x_table = 5
			let y_table = 10
			let labeltxt = []
			let studio_name = ''
			let page = 1
			api.getStudios().then((data) => {
				for (let studio of data) {
					if (studio._id == this.studio._id) {
						studio_name = studio.art + ' | ' + studio.name
					}
				}

				if (studio_name.length > 18) {
					studio_name = studio_name.substring(0, 19) + '...'
				}

				doc.text(studio_name, 10, 10)

				doc.text('Seite ' + page, 185, 290)

				for (let datum of this.course_date_array) {
					if (this.course_formatted[datum].length > 0) {
						y_table += 7

						doc.setFontSize(12)
						x_table = 10
						doc.text(datum, x_table, y_table)

						x_table = 10
						doc.setFontSize(10)
						y_table += 4
						for (let kurs of this.course_formatted[datum]) {
							if (
								this.labels[kurs._id].filter((i) => {
									return i.code == 'SOMMERPAUSE' || i.text == 'SOMMERPAUSE'
								}).length == 0
							) {
								//kurs.instructor_name
								//kurs.room_name
								y_table += 2
								labeltxt = []
								for (let label of this.labels[kurs._id]) {
									//label.code
									labeltxt.push(label.code)
								}

								if (kurs.course_name) {
									labeltxt = labeltxt.join(', ')
									if (labeltxt) {
										let kurstext = kurs.course_name.toString() + ' - ' + labeltxt
										if (kurstext.length > 38) {
											kurstext = kurstext.substring(0, 35) + '...'
										}

										doc.text(kurstext, x_table, y_table)
									} else {
										doc.text(kurs.course_name.toString(), x_table, y_table)
									}
								}
								if (kurs.room_name) {
									let room_name = kurs.room_name.toString()
									if (room_name.length > 20) {
										room_name = room_name.substring(0, 20) + '...'
									}

									doc.text(room_name, 110, y_table)
								}

								if (kurs.timestamp && kurs.timestamp_end) {
									doc.text(this.TimestampToTime(kurs.timestamp) + ' - ' + this.TimestampToTime(kurs.timestamp_end), 80, y_table)
								}
								if (kurs.instructor_name) {
									doc.text(kurs.instructor_name, 150, y_table)
								}

								y_table += 2
								doc.line(10, y_table, 200, y_table)
								y_table += 2

								x_table = 10
								if (y_table > 280) {
									doc.addPage()

									page++
									doc.text(studio_name, 10, 10)
									doc.text('Seite ' + page, 185, 290)
									y_table = 20
								}
							}
						}
					}
				}

				//

				var is_chrome = navigator.userAgent.indexOf('Chrome') > -1
				var is_explorer = navigator.userAgent.indexOf('MSIE') > -1
				var is_firefox = navigator.userAgent.indexOf('Firefox') > -1
				var is_safari = navigator.userAgent.indexOf('Safari') > -1
				var is_opera = navigator.userAgent.toLowerCase().indexOf('op') > -1
				if (is_chrome && is_safari) {
					is_safari = false
				}
				if (is_chrome && is_opera) {
					is_chrome = false
				}
				if (is_safari) {
					doc.save('Kursliste')
				} else {
					var blob = doc.output('blob')
					window.open(URL.createObjectURL(blob))
				}
			})
		},
		loadCovidQueue() {
			api.loadCovidQueue().then((data) => {
				this.covid_warteliste = data
			})
		},
		loadCovidQueueAll(cg_ids) {
			api.loadCovidQueueAll(cg_ids).then((data) => {
				this.covid_warteliste_leitung = data
			})
		},
	},
	async created() {
		this.loadCovidQueue()
		let studio = null

		this.studio = await api.getUserStudio().catch((e) => {
			window.app.$root.$children[0].checkStudio(true)
		})
		this.studio = this.studio.studio

		this.user = await api.getUser().catch((e) => {
			console.log('Failed to load user')
			this.$router.push('/login')
		})

		this.user = this.user.user

		let signups = await api.loadSignupsByUser(this.user._id).catch((e) => {
			console.log('Failed to load signups')
		})

		for (let signup of signups) {
			if (signup.wartelistepos) {
				this.warteliste[signup.course_generate_id] = signup.wartelistepos
			}
			this.signups[signup.course_generate_id] = true
		}

		await this.refreshList()

		// fix this.rollen

		let version = await api.getVersion().catch((e) => {
			console.log('Failed to load verison')
		})
		version = version.version
		if (!localStorage.getItem('version')) localStorage.setItem('version', version.toString())

		let dx = new Date()
		this.filterDate = dx.toLocaleDateString('en-CA')
	},
}
</script>

<style lang="scss" scoped>
.download-kursliste-c {
	position: fixed;
	bottom: 50px;
	right: calc(50% - 150px);
	width: 300px;
	height: 50px;
	line-height: 50px;
	border-radius: 5px;
	background-color: var(--v-primary);
	color: white;
	text-align: center;
	cursor: pointer;
}
.ls_wrapper {
	.ls_info {
		padding-top: 20px;
		color: #fff !important;
		letter-spacing: 1px;
		text-align: center;
		padding-bottom: 5px;
	}
	.ls_title {
		background-color: red;
		text-align: center;
		span {
			color: #fff !important;

			font-weight: bold;
			font-size: 22px !important;
			line-height: 60px !important;
			/* margin-top: 20px; */
			display: inline-block;
			letter-spacing: 2px;
		}
	}

	.ls_anmelden {
		width: 100%;
		display: block;
		text-align: center;
		letter-spacing: 1px;
		background-color: #f00;
		line-height: 60px;
		margin-top: 30px;
		color: #fff !important;
		font-size: 22px !important;
		text-align: center;
		letter-spacing: 2px;
	}

	.ls_countdown {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		max-width: 350px;
		margin: 0px auto 10px auto;

		.t-wrap {
			display: flex;
			flex-direction: column;
			.top {
				text-align: center;
				font-size: 40px;
				color: white;
				letter-spacing: 1px;
			}
			.bottom {
				text-align: center;
				font-size: 15px;
				color: white;
				letter-spacing: 1px;
			}
		}
	}
}
li.big {
	min-height: 270px;
	padding: 0px !important;
	background-color: var(--v-primary);
}

li.load {
	padding-top: 15px;
	color: red;
}

.cl-wait {
	img {
		display: none;
	}
	&.load {
		img {
			display: block;
		}
	}
}
.watch_live {
	display: block;
	text-align: right;
	position: absolute;
	right: 15px;
	top: calc(50% - 15px);
	line-height: 20px;
	padding: 5px 10px;
	background-color: var(--v-primary);
	color: #fff !important;
	letter-spacing: 0.5px;
	text-transform: uppercase;

	&.abmelden {
		background-color: #fff;
		color: #4a4a4a !important;
	}
	&.red {
		background-color: red;
	}
}
.nachricht {
	max-width: 340px;
	padding: 10px 15px;
	margin: auto;
	text-align: center;

	.pickstud {
		background-color: var(--v-primary);
		color: white;
		text-align: center;
		margin: 10px auto 10px auto;
		width: 100%;
		padding: 10px 0px;
		border-radius: 5px;
	}
}
.download-kursliste {
	cursor: pointer;
	position: fixed;
	bottom: 50px;
	left: calc(50% - 22px);
	font-size: 45px;
	/* width: 40px; */
	color: var(--v-primary);
	background-color: #fff;
	/* border-radius: 49px; */
	/* border-color: #f00; */
	border-radius: 520px;
	/* height: 40px; */
	border: 5px solid var(--v-primary);
	/* padding: 0px; */
	/* margin: 0px; */
	/* border-width: 0px; */
	display: flex;
}
.filter {
	background-color: var(--v-primary);
	color: white;
	padding: 5px 15px;

	&-options {
		display: flex;
		flex-direction: column;
	}
	select {
		color: white;
		padding: 10px 0px;

		option {
			color: black;
		}
	}
}
.evaluate {
	background-color: var(--v-primary);
	padding: 5px 10px;
	color: white !important;
	border-radius: 5px;
	margin-left: auto;
}
.filterkurse-bydate {
	padding: 5px 15px;
	display: flex;
	background-color: #313131;
	justify-content: center;
	input {
		color: white;
		width: 100%;
		max-width: 400px;
		height: 50px;
	}
}
.loading {
	img {
		width: 40px;
		height: 40px;
	}

	padding: 20px;
	font-weight: 400;
	font-size: 18px;
	background-color: white;
}
@keyframes highlight {
	0% {
		background: var(--v-primary);
	}
	100% {
		background: none;
	}
}

.highlight {
	animation: highlight 2s;
}
.link-wrapper {
	&.abgelaufen {
		a {
			display: flex;
			.kurs-instruktor-raum,
			.kurs-platz,
			.material-icons,
			.labels {
				display: none;
			}

			.kurs-zeit {
				margin-left: auto;
				margin-right: 10px;
			}
			.warteliste-wrapper {
				display: none !important;
			}
		}
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}
.labels {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.label {
		background-color: rgb(225, 81, 81);
		display: inline-block;
		padding: 2px 10px;
		margin-top: 8px;
		margin-right: 10px;
		border-radius: 3px;
		color: white !important;
		font-size: 12px;
		font-weight: bold;
	}
}

.no-courses {
	color: #4a4a4a;
	padding: 10px;
}
.no-courses_livestream {
	max-width: 400px;
	margin: 20px auto;
	background-color: #ff9800;
	color: #fff;
	text-align: center;
	font-weight: bold;
	letter-spacing: 1.2px;
	padding: 10px;
	display: flex;
	flex-direction: column;

	.stud-picker {
		margin-top: 30px;
		text-align: center;
		margin-bottom: 20px;
		color: #fff;
		line-height: 40px;
		background-color: #474747;
	}
}
.kurse-wrapper {
	color: white;

	.kurs-datum-titel {
		background-color: var(--v-primary);
		padding: 5px 15px;

		font-size: 14px;
	}

	.kurs-wrapper-date {
		padding: 0px;
		margin: 0px;

		li {
			padding: 10px 15px;
			color: #313131;
			font-weight: bold;
			border-bottom: 1px solid grey;
			position: relative;

			&.bewerten-wrapper {
				.kurs-name,
				.kurs-zeit {
					line-height: 30px;
				}
				.kurs-zeit {
					display: none;
				}
			}

			&.cancelled {
				background-color: #ff000042;
			}
			&.covid_warteliste {
				background-color: #eba31f7d !important;
			}

			&:last-of-type {
				border-bottom: none;
			}

			.kurs-zeit,
			.kurs-instruktor-raum {
				font-weight: 300;
			}

			.kurs-name {
				display: flex;
				.kurs-platz {
					margin-left: auto;
					margin-right: 50px;

					&.ueberbucht {
						color: red;
					}
				}
			}

			* {
				color: #4a4a4a;
				font-size: 14px;
			}
			.warteliste-wrapper {
				position: absolute;
				right: 0px;
				display: flex;
				line-height: 0px;
				color: #e15151;
				font-size: 26px;
				top: 50%;
				font-weight: bold;
				span.material-icons {
					position: static !important;
					color: #e15151;
				}
			}
			span.material-icons {
				position: absolute;
				top: 50%;
				transform: translatey(-50%);
				right: 15px;
				font-size: 34px;

				&.sup {
					color: var(--v-primary);
					font-size: 30px;
				}
			}
		}
	}
}
.edit-kurse {
	background-color: #313131;
	color: #fff;
	padding: 0 10px;
	font-weight: 500;
	border-bottom: 1px solid var(--v-primary);
	display: flex;

	a {
		display: flex;
		padding: 5px;
		color: white;
		width: 100%;
		justify-content: space-between;
		text-decoration: none;
		span {
			margin-top: auto;
			margin-bottom: auto;
		}
		span.material-icons {
			font-size: 34px;
		}
	}
}
</style>
