<template>
	<v-row class="flex-column" no-gutters>
		<v-card-text class="px-0">Wann möchtest du künftig morgens aufstehen?</v-card-text>
		<v-time-picker @change="$emit('reTriggerHeight')" v-model="wakeUpTime" :allowed-hours="allowedHours" format="ampm"></v-time-picker>
		<v-row>
			<v-col cols="10">Möchtest du eine andere Zeit für das Wochenede?</v-col>
			<v-col cols="1"><v-checkbox color="primary" @change="triggerHeightNew()" v-model="weekendDifferent"></v-checkbox></v-col>
		</v-row>
		<v-time-picker v-if="weekendDifferent" v-model="wakeUpTimeWeekend" :allowed-hours="allowedHours" format="ampm"></v-time-picker>
		<v-card-actions class="justify-content-center" v-show="wakeUpTime">
			<v-btn @click="saveNewHabit()" color="primary" outlined>Speichern</v-btn>
		</v-card-actions>
		<div id="saveButton"></div>
	</v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import api from '@/api.js'

export default {
	name: 'habitWakeUpForm',
	props: {
		selectedHabit: {
			type: Object,
			required: true,
		},
		edit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			wakeUpTime: '06:30',
			wakeUpTimeWeekend: null,
			weekendDifferent: false,
			weekdays: [
				{ label: 'Mo', active: false },
				{ label: 'Di', active: false },
				{ label: 'Mi', active: false },
				{ label: 'Do', active: false },
				{ label: 'Fr', active: false },
				{ label: 'Sa', active: false },
				{ label: 'So', active: false },
			],
		}
	},
	async created() {
		if (this.edit) {
			this.wakeUpTime = this.selectedHabit.days[0].time
			this.wakeUpTimeWeekend = this.selectedHabit.days[6].time
			if (this.wakeUpTime !== this.wakeUpTimeWeekend) {
				this.weekendDifferent = true
			}
		}
	},
	methods: {
		triggerHeightNew() {
			let saveButton = document.querySelector('#saveButton')
			if (this.wakeUpTimeWeekend) {
				this.wakeUpTimeWeekend = null
			} else {
				this.wakeUpTimeWeekend = this.wakeUpTime
			}
			this.$emit('reTriggerHeight', saveButton)
		},
		async saveNewHabit() {
			for (let i = 0; i < 7; i++) {
				this.selectedHabit.days[i] = {}
				this.selectedHabit.days[i].time = this.wakeUpTime
			}
			if (this.wakeUpTimeWeekend) {
				this.selectedHabit.days[5].time = this.wakeUpTimeWeekend
				this.selectedHabit.days[6].time = this.wakeUpTimeWeekend
			}
			if (await this.selectedHabit.save()) {
				// save habit to list
				console.log('saved')
				this.$emit('habitSaved')
			} else {
				// habit not saved
			}
		},
	},
	computed: {
		anyWeekdayActive() {
			return this.weekdays.some((weekday) => weekday.active)
		},
		allowedHours() {
			return Array.from({ length: 12 }, (_, i) => i + 1)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-time-picker-clock__ampm {
	display: none;
}
</style>
