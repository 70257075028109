export const workingoutStore = {
	namespaced: true,
	state: {
		savedNote: null,
		interactiveHumanBodyFilters: [],
		finishedSteps: [],
		workingOutCompleted: null,
		workoutStartTime: null,
		workoutStepsHistory: [],
		workoutCreate: {
			form: {
				title: '',
				ts: '',
				due: '',
				duration: '',
				instructor_id: '',
				user_id: '',
			},
		},
		workoutCreateExercises: [],
		defaultValues: {},
		stoppedDuration: null,
		savedSet: null,
		planInEditMode: null,
		workoutCreateDefaultValues: {},
	},
	mutations: {
		setSavedNote(state, value) {
			state.savedNote = value
		},
		setWorkoutCreateDefaultValues(state, value) {
			value.forEach((setting) => {
				if (setting.text !== 'Notiz') state.workoutCreateDefaultValues[setting.text] = setting.value
			})
		},
		setWorkoutStartTime(state, value) {
			state.workoutStartTime = value
		},
		resetWorkoutStartTime(state) {
			state.workoutStartTime = null
		},
		setWorkoutCompletedInfos(state, value) {
			state.workingOutCompleted = value
		},
		saveSet(state, value) {
			state.savedSet = value
		},
		setStoppedDuration(state, value) {
			state.stoppedDuration = value
		},
		setInteractiveHumanBodyFilters(state, value) {
			state.interactiveHumanBodyFilters = value
		},
		setWorkoutStepsHistory(state, value) {
			state.workoutStepsHistory = value
		},
		resetWorkoutCreateForm(state, value) {
			state.workoutCreate.form = {
				title: '',
				ts: '',
				due: '',
				duration: '',
			}
		},
		resetFinishedStepsArray(state) {
			state.finishedSteps = []
		},
		updateWorkoutCreateForm(state, value) {
			state.workoutCreate.form[value.key] = value.value
		},
		setDefaultValue(state, value) {
			state.defaultValues[value.title] = value.value
		},
		addNote(state, note) {
			if (state.finishedSteps.find((exercise) => exercise.exercise_id === note.exercise_id)) {
				state.finishedSteps.find((exercise) => exercise.exercise_id === note.exercise_id).note = note.note
			} else {
				state.finishedSteps.push({
					plan_id: note.plan_id,
					exercise_id: note.exercise_id,
					timestamp: note.date,
					note: note.note,
					steps: [],
				})
			}
		},
		removeExerciseByIndex(state, removedIndex) {
			state.workoutCreateExercises.splice(removedIndex, 1)
		},
		addExerciseToWorkoutCreateExercises(state, newExercise) {
			state.workoutCreateExercises.push(newExercise)
		},
		updateExerciseInWorkoutCreateExercises(state, updatedExercise) {
			let index = state.workoutCreateExercises.findIndex((exercise) => exercise._id === updatedExercise._id)
			state.workoutCreateExercises[index] = updatedExercise
		},
		addAdditionalStepToExercise(state, newStep) {
			state.finishedSteps
				.find((exercise) => exercise.plan_id === newStep.plan_id && exercise.exercise_id === newStep.exercise_id)
				.steps.push({
					active: newStep.active || false,
					activeBefore: newStep.activeBefore || false,
					settings: newStep.settings || [],
					set: newStep.set,
					weight: newStep.weight,
					reps: newStep.reps.value,
					time: newStep.time,
					note: newStep.note,
				})
		},
		resetWorkoutCreateExercises(state) {
			state.workoutCreateExercises = []
		},

		removeStepByIndex(state, data) {
			state.finishedSteps.find((exercise) => exercise.exercise_id === data.exercise_id).steps.splice(data.index, 1)
		},

		updateStep(state, updatedStep) {
			let exercise = state.finishedSteps.find(
				(exercise) => exercise.plan_id === updatedStep.plan_id && exercise.exercise_id === updatedStep.exercise_id
			)
			exercise.steps.forEach((step, index) => {
				console.log('step', step, 'index', index, 'updatedStep', updatedStep)
				if (index === updatedStep.value.index || !updatedStep.value.index) {
					step.note = updatedStep.value.note
					step.reps = updatedStep.value.reps
					step.forceValue = updatedStep.value.forceValue
					step.time = updatedStep.value.time
					step.weight = updatedStep.value.weight
					exercise.steps[index] === step
				}
			})
		},

		addFirstStepToExercise(state, newStep) {
			state.finishedSteps.push({
				plan_id: newStep.plan_id,
				exercise_id: newStep.exercise_id,
				timestamp: newStep.date,
				steps: [
					{
						active: newStep.active || false,
						activeBefore: newStep.activeBefore || false,
						settings: newStep.settings || [],
						set: newStep.set,
						weight: newStep.weight,
						reps: newStep.reps.value,
						time: newStep.time,
						note: newStep.note,
					},
				],
			})
		},
		toggleFinishedStepActive(state, data) {
			state.finishedSteps.find((exercise) => exercise.exercise_id === data.exercise_id).steps[data.index].active = !state.finishedSteps.find(
				(exercise) => exercise.exercise_id === data.exercise_id
			).steps[data.index].active
		},
		toggleFinishedStepActiveBefore(state, data) {
			state.finishedSteps.find((exercise) => exercise.exercise_id === data.exercise_id).steps[
				data.index
			].activeBefore = !state.finishedSteps.find((exercise) => exercise.exercise_id === data.exercise_id).steps[data.index].activeBefore
		},
		setEditInStore(state, data) {
			state.workoutCreate.form = data.form
			state.planInEditMode = data
			state.workoutCreateExercises = data.exercises
		},
		setWorkoutCreateExercises(state, data) {
			state.workoutCreateExercises = data
		},
		resetPlanInEditMode(state) {
			state.planInEditMode = null
		},
	},
	actions: {
		setWorkoutCompletedInfos({ commit }, data) {
			commit('setWorkoutCompletedInfos', JSON.parse(JSON.stringify(data)))
		},
		setWorkoutCreateDefaultValues({ commit }, data) {
			commit('setWorkoutCreateDefaultValues', JSON.parse(JSON.stringify(data)))
		},
		resetPlanInEditMode({ commit }) {
			commit('resetPlanInEditMode')
		},
		setWorkoutCreateExercises({ commit }, data) {
			commit('setWorkoutCreateExercises', JSON.parse(JSON.stringify(data)))
		},
		setWorkoutStartTime({ commit }, data) {
			commit('setWorkoutStartTime', JSON.parse(JSON.stringify(data)))
		},
		resetWorkoutStartTime({ commit }) {
			commit('resetWorkoutStartTime')
		},
		setEditInStore({ commit }, data) {
			commit('setEditInStore', JSON.parse(JSON.stringify(data)))
		},
		setSavedNote({ commit }, data) {
			commit('setSavedNote', JSON.parse(JSON.stringify(data)))
		},
		toggleFinishedStepActive({ commit }, data) {
			commit('toggleFinishedStepActive', JSON.parse(JSON.stringify(data)))
		},
		toggleFinishedStepActiveBefore({ commit }, data) {
			commit('toggleFinishedStepActiveBefore', JSON.parse(JSON.stringify(data)))
		},
		removeStepByIndex({ commit }, data) {
			commit('removeStepByIndex', JSON.parse(JSON.stringify(data)))
		},
		saveSet({ commit }, value) {
			commit('saveSet', value)
		},
		setStoppedDuration({ commit }, value) {
			commit('setStoppedDuration', value)
		},
		setInteractiveHumanBodyFilters({ commit }, value) {
			commit('setInteractiveHumanBodyFilters', value)
		},
		setWorkoutStepsHistory({ commit }, value) {
			commit('setWorkoutStepsHistory', value)
		},
		updateWorkoutCreateForm({ commit }, value) {
			commit('updateWorkoutCreateForm', value)
		},
		setDefaultValue({ commit }, value) {
			commit('setDefaultValue', value)
		},
		addNote({ commit }, note) {
			commit('addNote', note)
		},
		addNewStep({ commit, state }, newStep) {
			console.log(newStep)
			state.finishedSteps.find((exercise) => exercise.plan_id === newStep.plan_id && exercise.exercise_id === newStep.exercise_id)
				? commit('addAdditionalStepToExercise', newStep)
				: commit('addFirstStepToExercise', newStep)
		},
		updateStep({ commit }, updatedStep) {
			commit('updateStep', updatedStep)
		},
		addExerciseToWorkoutCreateExercises({ commit }, newExercise) {
			commit('addExerciseToWorkoutCreateExercises', newExercise)
		},
		updateExerciseInWorkoutCreateExercises({ commit }, updatedExercise) {
			commit('updateExerciseInWorkoutCreateExercises', updatedExercise)
		},
		resetFinishedStepsArray({ commit }) {
			commit('resetFinishedStepsArray')
		},
		removeExerciseByIndex({ commit }, removedIndex) {
			commit('removeExerciseByIndex', removedIndex)
		},
		resetWorkoutCreateExercises({ commit }) {
			commit('resetWorkoutCreateExercises')
		},
		resetWorkoutCreateForm({ commit }) {
			commit('resetWorkoutCreateForm')
		},
	},
}
