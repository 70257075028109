<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Kurseinstellungen</span>
		</div>

		<div class="kurseinstellungen">
			<label>Anmeldung ist x Tage vorher möglich:</label>
			<input type="number" v-model="kurseinstellungen.anmeldung_wochenfrist" />
			<!----<label>Abmeldung ist bis x Minuten vor Trainingsbeginn möglich:</label>
        <input type="number" v-model="kurseinstellungen.abmeldung_minutenfrist"> ---->
			<div @click="saveEinstellungen" class="speichern">Speichern</div>
		</div>
	</div>
</template>
<script>
import api from '../../api'

export default {
	data() {
		return {
			kurseinstellungen: {
				_id: '',
				anmeldung_wochenfrist: 0,
				abmeldung_minutenfrist: 0,
			},
		}
	},
	methods: {
		saveEinstellungen() {
			api.saveCourseSettings({
				anmeldung_wochenfrist: this.kurseinstellungen.anmeldung_wochenfrist,
				abmeldung_minutenfrist: this.kurseinstellungen.abmeldung_minutenfrist,
			})
				.then((data) => {
					if (data.status) {
						alert('Erfolgreich gespeichert')
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
	},
	created() {
		api.getCourseSettings()
			.then((data) => {
				if (data) {
					this.kurseinstellungen.abmeldung_minutenfrist = data.abmeldung_minutenfrist
					this.kurseinstellungen.anmeldung_wochenfrist = data.anmeldung_wochenfrist
				}
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>
<style lang="scss" scoped>
.kurseinstellungen {
	display: flex;
	flex-direction: column;
	max-width: 400px;
	padding: 15px;
	margin: auto;
	input {
		width: 100%;
		margin-bottom: 10px;
	}

	.speichern {
		background-color: var(--v-primary);
		color: white;
		text-align: center;
		border-radius: 5px;
		padding: 10px 15px;
		cursor: pointer;
		user-select: none;
	}
}
.download-survey {
	position: fixed;
	bottom: 50px;
	width: 300px;

	background-color: var(--v-primary);
	color: white;
	text-align: center;
	right: calc(50% - 150px);
	padding: 10px 0px;
	border-radius: 5px;
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
