import { render, staticRenderFns } from "./aliBeyBooking.vue?vue&type=template&id=105cf633&scoped=true&xmlns=true"
import script from "./aliBeyBooking.vue?vue&type=script&lang=js"
export * from "./aliBeyBooking.vue?vue&type=script&lang=js"
import style0 from "./aliBeyBooking.vue?vue&type=style&index=0&id=105cf633&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105cf633",
  null
  
)

export default component.exports