<template>
	<div v-show="upload != null && upload != ''" class="photoEditor">
		<div v-show="endForm" class="endform">
			<div class="benutzer-wrapper">
				<div class="benutzer" v-for="(login, i) of logins" :key="i">
					<span class="name">{{ login.vorname }} {{ login.nachname }}</span
					><span @click="removeUser(login)" class="material-icons">
						delete
					</span>
				</div>
			</div>
			<div class="more" @click="addMoreUser()">
				weiteren Benutzer hinzufügen
			</div>
		</div>
		<div class="saveForm" v-show="saveForm && !endForm">
			<input v-on:input="findUsers" v-model="search" class="input__text" type="text" placeholder="Kunden suchen..." />

			<div class="userwrap">
				<div @click="pickUser(user)" :class="{ user: 1, active: logins_checkers[user._id] }" v-for="(user, i) of users" :key="i">
					<span class="username">{{ user.vorname }} {{ user.nachname }} ({{ user.email }})</span>
				</div>
			</div>
		</div>
		<div v-show="selection && !saveForm && !endForm" class="choose-picture">
			<img @click="preparePicture(img)" v-for="(img, i) in images" :key="i" :src="img" />
		</div>

		<div v-show="!selection && showGSfix && !saveForm && !endForm" class="gs-fix-wrapper">
			<div class="gs-fix-a"><input min="70" max="110" step="1" type="range" @change="createPicture" v-model="color.r" /><span>Rot</span></div>

			<div class="gs-fix-a"><input min="80" max="130" step="1" type="range" @change="createPicture" v-model="color.g" /><span>Grün</span></div>
			<div class="gs-fix-a"><input min="100" max="160" step="1" type="range" @change="createPicture" v-model="color.b" /><span>Blau</span></div>
		</div>

		<div @click="rotatePicture()" v-show="!selection && !saveForm && !endForm" class="rotate-img">
			Bild drehen
		</div>

		<div class="wrapper" v-show="!selection && !saveForm && !endForm">
			<img v-show="false" class="gs" :src="upload" />
			<div class="wrap-box">
				<div class="oben" @click="moveY(-1)">
					<span class="material-icons"> arrow_drop_up </span>
				</div>
				<div class="wrap-box-middle">
					<div class="links" @click="moveX(-1)">
						<span class="material-icons"> arrow_left </span>
					</div>
					<div class="box">
						<canvas width="800" height="800"></canvas>
					</div>
					<div class="rechts" @click="moveX(1)">
						<span class="material-icons"> arrow_right </span>
					</div>
				</div>
				<div class="unten" @click="moveY(1)">
					<span class="material-icons"> arrow_drop_down </span>
				</div>
			</div>
		</div>
		<div v-show="!selection && !saveForm && !endForm" class="groesse">
			<input type="range" min="0" v-model="slider" @input="drawPicture" step="1" max="200" class="groesse" />
		</div>

		<div v-show="!selection && !saveForm && !endForm" class="button-wrap">
			<div @click="saveForm = true" class="next">Weiter</div>
		</div>
		<div v-show="!selection && endForm" class="button-wrap">
			<div @click="download" class="next">Speichern</div>
		</div>
		<div v-show="!selection" class="button-wrap">
			<div @click="resetAll" class="back">Zurücksetzen</div>
		</div>
	</div>
</template>

<script>
import api from '../api'
export default {
	props: {
		upload: String,
	},
	data() {
		return {
			saveForm: false,
			showGSfix: false,
			endForm: false,
			selection: false,
			slider: 0,
			posx: 0,
			posy: 0,
			height: 0,
			width: 0,
			nwidth: 0,
			nheight: 0,
			background: new Image(),
			background2: new Image(),
			logins: [],
			logins_checkers: [],
			bilder: [
				'/img/gs/vorlagen/frauen_wcf.jpg',
				'/img/gs/vorlagen/mann_wcf.jpg',
				'/img/gs/vorlagen/roter_teppich_wcf.jpg',
				'/img/gs/vorlagen/hantel_wcf.png',
				'/img/gs/vorlagen/mann_winti.jpg',
				'/img/gs/vorlagen/frauen_winti.jpg',
				'/img/gs/vorlagen/roter_teppich_winti.jpg',
				'/img/gs/vorlagen/hantel_winti.png',
			],
			bild: '',
			images: [],
			c_processed: null,
			// if (g > ref.color.g && b < ref.color.b && r < ref.color.r) {
			color: {
				r: 90,
				g: 100,
				b: 145,
			},
			search: '',
			users: {},
		}
	},

	updated() {},
	created() {
		//const box = document.querySelector(".box")
	},
	methods: {
		removeUser(login) {
			this.logins_checkers[login._id] = false
			let logins_tmp = []
			for (let loginn of this.logins) {
				if (loginn._id != login._id) {
					logins_tmp.push(loginn)
				}
			}
			this.logins = logins_tmp
		},
		addMoreUser() {
			this.saveForm = true
			this.endForm = false
		},
		pickUser(user) {
			this.logins_checkers[user._id] = true
			this.logins.push(user)
			this.users = {}
			this.search = ''
			this.endForm = true
			this.saveForm = false
		},
		findUsers() {
			if (this.search.length > 2) {
				api.searchUserforRolle({ search: this.search })
					.then((data) => {
						this.users = data
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				this.users = {}
			}
		},
		toggleGSfix() {
			if (this.showGSfix) {
				this.showGSfix = false
			} else {
				this.showGSfix = true
			}
		},
		moveX(v) {
			for (let i = 0; i < 15; i++) {
				this.posx += 1 * v
				this.drawPicture()
			}
		},
		moveY(v) {
			for (let i = 0; i < 15; i++) {
				this.posy += 1 * v
				this.drawPicture()
			}
		},
		showSelection() {
			if (location.host.includes('wellcomefit')) {
				this.images = this.bilder.slice(0, 4)
			} else {
				this.images = this.bilder.slice(4, 8)
			}

			this.selection = true
		},
		download() {
			this.endForm = false
			const c = document.querySelector('canvas')
			// const ctx = c.getContext("2d");
			var img = c.toDataURL('image/png')

			api.PhotoEditorUpload({ img: img, users: this.logins }).then((data) => {
				if (data.status == 1) {
					location.reload()
				}
			})
		},

		preparePicture(i) {
			this.selection = false
			this.bild = i
			this.createPicture()
		},
		resetAll() {
			this.selection = true
			this.bild = ''
			this.c_processed = null
			const c = document.querySelector('canvas')
			c.setAttribute('height', '800')
			c.setAttribute('width', '800')
			const ctx = c.getContext('2d')
			ctx.clearRect(0, 0, 800, 800)
			this.logins = []
			this.logins_checkers = []
			this.endForm = false
			this.showGSfix = false
			this.saveForm = false
		},
		createPicture() {
			if (document.querySelector('.gs').naturalHeight !== 0) {
				this.background2.crossOrigin = 'Anonymous'
				this.background2.src = this.upload

				if (this.bild.includes('frauen')) {
					this.posx = 165
					this.posy = 75
					this.slider = 41
				} else if (this.bild.includes('mann')) {
					this.posx = 45
					this.posy = -15
					this.slider = 46
				} else {
					this.posx = 135
					this.posy = 75
					this.slider = 40
				}

				let ref = this

				this.background2.onload = function() {
					ref.nwidth = ref.background2.naturalWidth
					ref.nheight = ref.background2.naturalHeight
					ref.background.src = ref.bild

					ref.background.onload = function() {
						ref.drawPicture()
					}
				}
			}
		},
		rotatePicture() {
			if (document.querySelector('.gs').naturalHeight !== 0) {
				// Create a canvas object.
				let canvas = document.createElement('canvas')

				let ctx = canvas.getContext('2d')

				// Assign width and height.
				canvas.width = this.background2.width
				canvas.height = this.background2.height

				ctx.translate(canvas.width / 2, canvas.height / 2)

				// Rotate the image and draw it on the canvas.
				// (I am not showing the canvas on the webpage.
				ctx.rotate((90 * Math.PI) / 180)
				ctx.drawImage(this.background2, -this.background2.width / 2, -this.background2.height / 2)

				this.background2.src = canvas.toDataURL('image/png')
				let ref = this
				this.background2.onload = function() {
					ref.drawPicture()
				}
			}
		},
		/*
    createPicture() {
      var ref = this;

      if (document.querySelector(".gs").naturalHeight !== 0) {
        const gs = document.querySelector(".gs");
        ref.nheight = gs.naturalHeight;
        ref.nwidth = gs.naturalWidth;

        const c = document.querySelector("canvas");
        c.setAttribute("width", gs.naturalWidth);
        c.setAttribute("height", gs.naturalHeight);
        const ctx = c.getContext("2d");

        ctx.imageSmoothingEnabled = true;
        ctx.drawImage(gs, 0, 0, gs.naturalWidth, gs.naturalHeight);

        let frame = ctx.getImageData(0, 0, gs.naturalWidth, gs.naturalHeight);

        for (let i = 0; i < frame.data.length; i += 4) {
          let r = frame.data[i];
          let g = frame.data[i + 1];
          let b = frame.data[i + 2];
          if (g > ref.color.g && b < ref.color.b && r < ref.color.r) {
            frame.data[i + 3] = 0;
          }
        }
        ctx.putImageData(frame, 0, 0);

        //ref.c_processed = ref.trim(c);
        ref.c_processed = c.toDataURL("image/png");
        ctx.clearRect(0, 0, gs.naturalHeight, gs.naturalWidth);
        c.setAttribute("width", 800);
        c.setAttribute("height", 800);
        if (this.bild.includes("frauen")) {
          ref.posx = 165;
          ref.posy = 75;
          this.slider = 41;
        } else if (this.bild.includes("mann")) {
          ref.posx = 45;
          ref.posy = -15;
          this.slider = 46;
        } else {
          ref.posx = 135;
          ref.posy = 75;
          this.slider = 40;
        }

        this.background.src = this.bild;

        var ref = this;
        this.background.onload = function() {
          ctx.drawImage(ref.background, 0, 0, 800, 800);

          ref.background2.src = ref.c_processed;
          // Make sure the image is loaded first otherwise nothing will draw.
          ref.background2.onload = function() {
            console.log(ref.posx);
            console.log(ref.posy);
            console.log(ref.slider);
            ctx.drawImage(
              ref.background2,
              ref.posx,
              ref.posy,
              ref.nwidth * (ref.slider / 100),
              ref.nheight * (ref.slider / 100)
            );
          };
        };
        ref.drawPicture();
      }
    },
    */

		drawPicture() {
			const c = document.querySelector('canvas')
			const ctx = c.getContext('2d')
			ctx.drawImage(this.background, 0, 0, 800, 800)
			ctx.drawImage(this.background2, this.posx, this.posy, this.nwidth * (this.slider / 100), this.nheight * (this.slider / 100))
		},
	},
}
</script>

<style lang="scss" scoped>
.rotate-img {
	text-align: center;
	margin: 20px;
	background-color: #313131;
	max-width: 150px;
	padding: 10px 0px;
	color: #fff;
}
.more {
	padding: 10px 15px;
	margin-bottom: 20px;
	margin-top: 20px;
	background-color: #313131;
	color: #fff;
}
.benutzer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 15px;
	border-bottom: 1px solid #000;
	padding: 10px 15px;
}
.saveForm {
	input {
		background-color: inherit;
		width: 100%;
		/* margin: 10px 15px; */
		padding: 10px 15px;
		border-bottom: 1px solid #000;
	}
}
.photoEditor {
	.userwrap {
		margin-top: 10px;
		.user {
			margin: 5px 0px;
			padding: 10px 15px;
			border-top: 1px solid #808080;
			border-bottom: 1px solid #808080;
			cursor: pointer;
			&.active {
				background-color: var(--v-primary);
				color: white;
				border: none;
			}
		}
		display: flex;
		flex-direction: column;
		max-height: 300px;
		overflow: scroll;
	}
	.gs-fix {
		padding: 10px 15px;
		background-color: #015;
		color: #fff;
		display: inline-block;
		font-size: 15px;
		margin-bottom: 10px;
		margin-top: 5px;
	}
	.gs-fix-a {
		padding: 10px 15px;
		display: flex;
		justify-content: space-between;

		span {
			width: 20%;
			text-align: center;
		}
		input {
			background-color: inherit;
			width: 80%;
		}
	}
	.choose-picture {
		display: flex;
		flex-direction: row;
		justify-content: center;
		img {
			width: 80px;
			height: 80px;
			margin: 10px;
		}
	}
	.button-wrap {
		.back {
			width: 100%;
			/* display: flex; */
			height: 60px;
			background-color: #313131;
			line-height: 60px;
			text-align: center;
			color: white;
			margin-top: 2px;
		}
		.next {
			width: 100%;
			font-weight: bold;
			/* display: flex; */
			height: 60px;
			background-color: #f4da01;
			line-height: 60px;
			text-align: center;
			color: white;
		}
	}

	.groesse {
		width: 100%;
		margin: 30px auto;
		max-width: 330px;
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		text-align: center;
		img {
			height: 100px;
			width: 100px;
			margin: 20px auto 20px auto;
		}

		.wrap-box {
			.oben,
			.unten {
				display: flex;
				background-color: #313131;
				border-radius: 50%;
				color: #ffffff;
				width: 45px;
				height: 45px;
				margin: 10px auto 10px auto;
				span {
					margin: auto;
					font-size: 45px;
				}
			}
			.wrap-box-middle {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				.box {
					width: 60%;
					canvas {
						background-repeat: no-repeat;
						background-size: cover;
						width: 100%;
					}
				}
				.links,
				.rechts {
					display: flex;
					width: 20%;
					background-color: #313131;
					border-radius: 50%;
					color: #ffffff;
					width: 45px;
					height: 45px;
					margin: auto 10px auto 10px;
					span {
						margin: auto;
						font-size: 45px;
					}
				}
			}
		}
	}
}
</style>
