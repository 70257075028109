<template>
    <v-dialog
        class="workingOutStatistic"
        persistent
        max-width="1140px"
        :value="true"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
        <div class="workingOutStatistic__card__wrapper">
            <v-card-title
                style="word-break: break-word"
                class="workingOutStatistic__card-title primary--text h3 text-wrap justify-center"
                >Statistik für
                {{ title }}
            </v-card-title>

            <v-row v-if="chartData.categories.length >= 3 && title !== 'Senso Pro'" class="my-5 justify-content-center">
                <v-col cols="4" class="d-flex flex-column text-center">
                    <span class="caption grey--text">Anfang</span>
                    <div>
                        <number class="h2 font-weight-bold" :from="0" :to="chartData.data[0]"></number>
                        <span class="h6 font-weight-bold">
                            {{ type === 'weight' ? 'kg' : type === 'reps' ? 'Wh' : 's' }}</span
                        >
                    </div>
                </v-col>
                <v-col cols="2" class="workingOutStatistic__arrow">
                    <lottie-player
                        mode="normal"
                        style="width: 50px; height: 50px"
                        class="ma-auto"
                        :autoplay="false"
                        src="/img/lottiefiles/arrowPrimary.json"></lottie-player>
                </v-col>
                <v-col cols="4" class="d-flex flex-column text-center">
                    <span class="caption grey--text">Aktuell</span>
                    <div>
                        <number
                            class="h2 font-weight-bold primary--text"
                            :from="0"
                            :to="chartData.data[chartData.data.length - 1]"></number>
                        <span class="h6 font-weight-bold primary--text">
                            {{ type === 'weight' ? 'kg' : type === 'reps' ? 'Wh' : 's' }}</span
                        >
                    </div>
                </v-col>
            </v-row>

            <div v-if="chartData.categories.length < 1 && !tableActive" style="max-width: 80%" class="ma-auto my-5">
                <lottie-player
                    mode="normal"
                    style="width: 200px; height: 200px"
                    class="ma-auto"
                    :autoplay="false"
                    src="/img/lottiefiles/noDataChart.json"></lottie-player>
                <span class="h6 ma-auto font-weight-bold"
                    >Sobald du dein erstes Training abgeschlossen hast, kannst du hier deine Trainingserfolg einsehen.
                </span>
            </div>
            <div
                v-if="chartData.categories.length > 0 && chartData.categories.length < 3 && !tableActive"
                style="max-width: 80%"
                class="ma-auto my-5">
                <lottie-player
                    mode="normal"
                    style="width: 200px; height: 200px"
                    class="ma-auto"
                    :autoplay="false"
                    src="/img/lottiefiles/noDataChart.json"></lottie-player>
                <span class="h6 ma-auto font-weight-bold"
                    >Sobald du 3 Trainings abgeschlossen hast, kannst du hier deine Trainingserfolg als Graph einsehen. Bis
                    dann kannst du dir die Daten als Tabelle ansehen.
                </span>
            </div>

            <v-row class="my-5" v-if="chartData.categories.length >= 1 && title !== 'Senso Pro'">
                <v-btn class="ma-auto" icon @click="tableActive = !tableActive">
                    <lottie-player
                        mode="normal"
                        v-show="!tableActive"
                        style="width: 60px; height: 60px"
                        class="ma-auto"
                        :autoplay="false"
                        src="/img/lottiefiles/table.json"></lottie-player>
                    <lottie-player
                        mode="normal"
                        v-show="tableActive"
                        style="width: 60px; height: 60px"
                        class="ma-auto"
                        :autoplay="false"
                        src="/img/lottiefiles/graph.json"></lottie-player>
                </v-btn>
            </v-row>

            <apexchart
                v-if="chartData.categories.length >= 3 && !tableActive && title !== 'Senso Pro'"
                style="max-width: 600px"
                class="ma-auto rounded-0"
                :options="chartOptions"
                :series="chartItems"></apexchart>
            <v-expansion-panels
                v-if="(exerciseStepHistory.length && tableActive) || title === 'Senso Pro'"
                class="workingOutStatistic__card-panels pb-7"
                hover
                focusable>
                <v-expansion-panel :key="index" v-for="(workout, index) in exerciseStepHistory">
                    <v-expansion-panel-header class="h5">
                        {{ convertDate(workout.timestamp) }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-textarea
                            v-if="workout.steps && workout.steps[0].note"
                            v-model="workout.steps[0].note"
                            readonly></v-textarea>
                        <v-data-table
                            v-else
                            :headers="datatableHeaders"
                            :items="workout.steps"
                            hide-default-footer
                            mobile-breakpoint="0"
                            class="elevation-1"></v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </v-dialog>
</template>

<script>
import api from '../../api'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'WorkingoutStatistic',
    props: {
        plan_id: {
            type: String,
            required: true,
        },
        exercise_id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tableActive: false,
            type: '',
            selectedDate: null,
            chart: {
                options: {},
                items: {},
            },
        }
    },
    created() {
        this.setNewBottomNavigationUsage(null)
        this.setNavigationBarText({ text: 'zurück', state: false, endPoint: this.exercise_id })
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'setNewBottomNavigationUsage']),
        convertDate(date) {
            return this.$moment(date * 1000).format('DD. MM. YYYY')
        },
    },
    computed: {
        ...mapState('workingoutStore', ['workoutStepsHistory']),
        ...mapState('navigationStoreNotPersisted', ['workoutExecutions']),
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarEndPoint']),
        chartData() {
            let allValues = {
                data: [],
                categories: [],
            }
            if (this.exerciseStepHistory.length) {
                if (this.exerciseStepHistory[this.exerciseStepHistory.length - 1].steps[0].weight) this.type = 'weight'
                if (this.exerciseStepHistory[this.exerciseStepHistory.length - 1].steps[0].reps && this.type !== 'weight')
                    this.type = 'reps'
                if (this.exerciseStepHistory[this.exerciseStepHistory.length - 1].steps[0].time) this.type = 'time'
                for (let history of this.exerciseStepHistory) {
                    let historyValue = 0
                    console.log('do bini no ine cho')
                    for (let step of history.steps) {
                        if (this.type === 'weight') {
                            if (step.weight) historyValue = historyValue + step.weight
                        } else if (this.type === 'time') {
                            if (historyValue === 0) {
                                if (step.time) historyValue = this.$moment(step.time, 'mm:ss:SSS')
                            } else {
                                if (step.time) historyValue.add(this.$moment(step.time, 'mm:ss:SSS'))
                            }
                        } else if (this.type === 'reps') {
                            if (step.reps) historyValue = historyValue + step.reps
                        }
                    }
                    if (this.type === 'weight') historyValue = Math.round(historyValue / history.steps.length)
                    allValues.categories.push(this.$moment(history.timestamp * 1000).format('DD.MM.YY'))
                    if (this.type !== 'weight' && this.type !== 'time' && this.type !== 'reps')
                        return allValues.data.push(historyValue)
                    if (this.type === 'time')
                        historyValue = historyValue.diff(this.$moment().startOf('day'), 'seconds') + 's'
                    if (this.type === 'weight') historyValue = historyValue + 'KG'
                    if (this.type === 'reps') historyValue = historyValue + 'WH'
                    allValues.data.push(historyValue)
                }
            }
            return allValues
        },
        chartItems() {
            let chartName = this.type === 'weight' ? 'Durchschnittsgewicht in KG' : 'Durchschnitsszeit in Sekunden'
            if (this.type === 'reps') chartName = 'Wiederholungen'
            return [
                {
                    name: chartName,
                    data: this.chartData.data,
                },
            ]
        },

        chartOptions() {
            return {
                colors: [
                    this.$vuetify.theme.themes.light.primary,
                    this.$vuetify.theme.themes.light.darkenprimary1,
                    this.$vuetify.theme.themes.light.darkenprimary2,
                ],
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: false,
                            selection: true,
                            pan: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            reset: true,
                        },
                    },
                    zoom: {
                        enabled: true,
                        type: 'x',
                        resetIcon: {
                            offsetX: -10,
                            offsetY: 0,
                            fillColor: '#293a16',
                            strokeColor: '#37474F',
                        },
                        selection: {
                            background: '#90CAF9',
                            border: '#0D47A1',
                        },
                    },
                    events: {
                        beforeResetZoom: (ctx, opt) => {
                            return {
                                xaxis: {
                                    min: this.chartData.categories.length - 3,
                                    max: this.chartData.categories.length,
                                },
                            }
                        },
                        beforeZoom: (ctx, opt) => {
                            return {
                                xaxis: {
                                    min: opt.xaxis.min < 1 ? 1 : opt.xaxis.min,
                                    max:
                                        opt.xaxis.max > this.chartData.categories.length
                                            ? this.chartData.categories.length
                                            : opt.xaxis.max,
                                },
                            }
                        },
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                    type: 'area',
                    id: 'exerciseStatistic',
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (value, opt, shizzle = this) {
                        //	let shouldBeShown = (opt.dataPointIndex + 1) & 1
                        //	if (opt.dataPointIndex === opt.w.config.series[0].data.length - 1) shouldBeShown = true
                        //	return shouldBeShown ? value : ''
                        return value
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            // return value + 'KG'
                            return value
                        },
                    },
                },
                xaxis: {
                    labels: {
                        rotate: 0,
                    },
                    tickAmount: 4,
                    categories: this.chartData.categories,
                    min: this.chartData.categories.length - 3,
                    max: this.chartData.categories.length,
                },
            }
        },
        exerciseStepHistory() {
            return this.workoutStepsHistory.filter((history) => history.exercise_id === this.exercise_id)
        },
        datatableHeaders() {
            let headers = [{ text: 'Satz', value: 'set', align: 'center' }]
            let lastHistoryItem = this.exerciseStepHistory[this.exerciseStepHistory.length - 1]
            if (lastHistoryItem.steps.find((step) => step.time))
                headers.push({ text: 'Zeit', value: 'time', align: 'center' })
            lastHistoryItem.steps.find((step) => step.weight)
                ? headers.push(
                      { text: 'Wh', value: 'reps', align: 'center' },
                      { text: 'Gewicht', value: 'weight', align: 'center' }
                  )
                : headers.push({ text: 'Wh', value: 'reps', align: 'center' })
            return headers
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
    overflow-x: hidden !important;
}
.workingOutStatistic {
    &__arrow {
        align-self: flex-end;
    }
}
.workingOutStatistic__card__wrapper {
    height: 100%;
    background: white;
}
.h5 {
    text-transform: uppercase;
    font-weight: bold;
}

.v-card {
    transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
    opacity: 0.6;
}

.v-item--active {
    background-color: rgba(82, 174, 50, 0.1) !important;
}

.v-expansion-panel-content__wrap {
    padding-bottom: 0;
    padding-left: 2%;
    padding-right: 2%;
}

.workingOutStatistic__card {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid var(--v-primary) !important;

    &-lottie {
        display: unset !important;
        align-self: center;
        height: 80vh !important;
        width: 80vh !important;
    }

    &-panels {
        width: 80%;
        margin-left: 10%;
        height: 60vh;
        overflow-y: auto;
    }

    &-title {
        position: sticky;
    }
    &-actions {
        position: sticky;
        bottom: 0;
    }
}
</style>
