<template>
	<swiper ref="reportProblemSlider" :options="workoutSwiperOptions">
		<swiper-slide class="reportProblem__swiper my-5">
			<v-card class="reportProblem__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div class="wrapper">
					<v-card-title class="reportProblem__swiper-card-title h5 primary--text justify-left"> Offene Meldungen </v-card-title>
					<div v-for="(problem, y) of problems" :key="y" class="reportProblem__entry">
						<v-text class="reportProblem__entry-ts">{{ formatTime(problem.created) }} </v-text
						><v-text class="reportProblem__entry-description">{{ problem.title }}</v-text>
					</div>
				</div>
				<v-card-actions class="justify-center">
					<v-btn color="primary" rounded class="text-white" @click="slideTo(1)"> HINZUFÜGEN </v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>
		<swiper-slide class="reportProblem__swiper my-5">
			<v-card class="reportProblem__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div class="wrapper">
					<v-card-title class="reportProblem__swiper-card-title h5 primary--text justify-left"> Problem melden </v-card-title>
					<report-problem-form @submitForm="submitForm"></report-problem-form>
				</div>
			</v-card>
		</swiper-slide>
		<swiper-slide class="reportProblem__swiper my-5">
			<v-card class="reportProblem__swiper-card elevation-7 d-flex flex-column justify-space-between rounded-xl">
				<div class="wrapper">
					<v-card-title class="reportProblem__swiper-card-title h5 primary--text justify-left">
						Vielen Dank für Deine Nachricht. Wir werden sie so schnell wie möglich bearbeiten :)
					</v-card-title>
				</div>
				<v-card-actions class="justify-center">
					<v-btn color="primary" rounded class="text-white" @click="slideTo(0)"> ÜBERSICHT </v-btn>
				</v-card-actions>
			</v-card>
		</swiper-slide>
	</swiper>
</template>

<script>
import reportProblemForm from './components/reportProblemForm'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import api from '../../api'
import 'swiper/css/swiper.css'
export default {
	components: { reportProblemForm, Swiper, SwiperSlide },
	data() {
		return {
			error: null,
			problems: null,
		}
	},
	created() {
		this.loadProblems()
	},
	methods: {
		formatTime(ts) {
			return this.$moment.unix(ts).format('DD.MM.YYYY HH:MM')
		},
		loadProblems() {
			api.loadProblems().then((data) => {
				this.problems = data
			})
		},
		async submitForm(formData) {
			let submittedForm = await api.submitProblemForm(formData).catch((e) => {
				this.error = 'Es gab einen Fehler. Bitte mache ein Screenshot von deinen angaben und sende diese an support@roody.ch'
			})
			if (!this.error) this.slideTo(2) && this.loadProblems()
		},
		setSwiperAllowTouchMove(value) {
			this.$refs.reportProblemSlider.$swiper.allowTouchMove = value
		},
		slideTo(slide) {
			this.setSwiperAllowTouchMove(false)
			this.$refs.reportProblemSlider.$swiper.slideTo(slide, 1000, false)
		},
	},
	computed: {
		workoutSwiperOptions: function() {
			return {
				allowTouchMove: false,
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card {
	transition: opacity 0.4s ease-in-out;
}
.wrapper {
	overflow: auto;
}
.reportProblem {
	p {
		margin-bottom: 5px;
	}
	&__entry {
		display: flex;
		flex-direction: column;
		padding: 10px 15px;
		text-align: left;

		&-ts {
			background-color: var(--v-primary);
			padding: 5px 15px;
			color: #fff;
			margin-bottom: 5px;
		}
		&-description {
			padding: 5px 15px;
		}
	}
	&__swiper {
		width: 90% !important;
		height: 90% !important;
		margin-left: 5%;
		margin-right: 5%;

		&-card {
			text-align: -webkit-center;
			height: 80vh;
			min-height: 500px;
			max-width: 500px;
			margin: auto;

			&-wrapper {
				overflow: auto;
				scroll-behavior: smooth;
			}

			&-content {
				overflow-y: auto;
				height: 62vh;
			}
			&-image {
				margin: 0 10%;
				height: 40%;
				background-size: contain;
			}

			&-form {
				width: 80%;
			}

			&-title,
			&-subtitle {
				word-break: break-word;
				text-align: -webkit-center;
			}

			&-title {
				padding-top: 20px;
				padding-bottom: 5px;
			}

			&-subtitle {
				padding-top: 20px;
				padding-bottom: 0px;
			}
		}
	}
}
</style>
