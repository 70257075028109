<template>
    <div class="schnupperwoche">
        <div v-show="!studio._id && show_select" class="select-studio">
            <div class="select-studio-wrapper">
                <img :src="getMoreInfoImage()" />
                <span>Wähle deinen Standort:</span>
                <div class="studio-wrapper-select">
                    <div
                        class="studio-for-select"
                        @click="pickstudio(studio._id)"
                        v-for="(studio, index) of studios"
                        :key="index"
                    >
                        <span>
                            {{
                                studio.name
                                    .replace(' - Outdoor Kurse', '')
                                    .replace('A. ', '')
                                    .replace('B. ', '')
                                    .replace('C. ', '')
                                    .replace('D. ', '')
                                    .replace('E. ', '')
                                    .replace('F. ', '')
                                    .replace('G. ', '')
                                    .replace('H. ', '')
                                    .replace('I. ', '')
                                    .replace('J. ', '')
                            }}
                        </span>
                    </div>
                    <div class="studio-for-select zuruck" @click="goBack()">
                        <span>Zurück</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="hmp-content">
            <div class="schnupperwoche-infos">
                <div v-show="!studio._id" class="show-infos">
                    <div v-show="show_select == false" class="mehr_infos">
                        <!----<h2 @click="showStudioselect()">FERIEN FÜR DEINEN KÖRPER AUFTANKEN & ABSCHALTEN 10 TAGE FITNESS & WELLNESS</h2> ---->
                        <img
                            @click="showStudioselect()"
                            class="more_info_image"
                            v-show="getMoreInfoImage()"
                            :src="getMoreInfoImage()"
                        />

                        Registriere Dich jetzt
                        <span class="bold_txt" @click="showStudioselect()">kostenlos und unverbindlich</span>
                        und profitiere von unserem 10tägigen Trainings-Angebot, das speziell auf Deine Bedürfnisse
                        zugeschnitten ist.
                        <br />

                        <v-btn
                            @click="showStudioselect()"
                            class="register-now elevation-5 px-10"
                            large
                            color="warning"
                            dark
                        >
                            REGISTRIERE DICH JETZT
                        </v-btn>
                        Das alles ist in unserem kostenlosen 10tägigen Trainingsprogramm enthalten
                        <br />

                        <div class="wrap-msgs">
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">FITNESS- UND CARDIOPARCOURS</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">MUSKELAUFBAU-PROGRAMM</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">GEWICHTSREDUKTION-KONZEPT</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">GROUPFITNESS INDOOR, OUTDOOR UND LIVESTREAM KURSE</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">INDIVIDUELLE TRAININGS- UND ERNÄHRUNGSPLANUNG</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">LIVESTREAM TRAININGS- UND ERNÄHRUNGSPLANUNG</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">COMPUTERGESTÜTZTE KÖRPERANALYSE</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">10TÄGIGES PERSÖNLICH BEGLEITETES ERFOLGSPROGRAMM</span>
                            </div>
                        </div>
                        <br />

                        Wir glauben, dass sich jeder in seinem Körper wohlfühlen kann. Wir haben bereits mehr als 700
                        Mitgliedern mit diesen Programm dabei geholfen ihr Wunschgewicht bzw. ihren Traumkörper zu
                        erreichen.
                        <br />
                        <br />

                        Wirst Du die Nächste oder der Nächste sein?
                        <br />

                        <br />
                        Hier nur beispielhaft ein paar Bilder von Mitgliedern, die ihr Ziel erreicht haben. Wir wollen
                        Dir helfen, dass Du Dein Ziel auch erreichst.
                        <div class="container">
                            <div class="img background-img"></div>
                            <div class="img foreground-img"></div>
                            <input type="range" min="1" max="100" value="50" class="slider" name="slider" id="slider" />
                            <div class="slider-button"></div>
                        </div>

                        <div class="container1">
                            <div class="img background-img_1"></div>
                            <div class="img foreground-img_1"></div>
                            <input
                                type="range"
                                min="1"
                                max="100"
                                value="50"
                                class="slider1"
                                name="slider1"
                                id="slider1"
                            />
                            <div class="slider-button1"></div>
                        </div>

                        <v-btn
                            @click="showStudioselect()"
                            class="register-now elevation-5 px-10"
                            large
                            color="warning"
                            dark
                        >
                            REGISTRIERE DICH JETZT
                        </v-btn>
                        In unseren Centern {{ centerText() }} erwartet Dich eine freundliche und professionelle
                        Atmosphäre:

                        <br />
                        <div class="wrap-msgs">
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">WEITLÄUFIGE FITNESS- UND CARDIOPARCOURS</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">PROFESSIONELLES PERSONALTRAINING</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">LICHTDURCHFLUTETE RÄUME</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">GROUPTFITNESS- UND LIVESTREAMANGEBOTE</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">KINDERHORT</span>
                            </div>

                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">GRATIS WLAN UND DUSCHEN SIND EINE SELBSTVERSTÄNDLICHKEIT</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">24H TRAININGSMÖGLICHKEIT</span>
                            </div>
                            <div class="wrap-msgs-wrapper">
                                <span class="emoji">👉</span>
                                <span class="msg">VIELFÄLTIGES WELLNESS- UND SPA-ANGEBOT</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="image !== ''" class="img-wrapper">
                    <img :src="'https://app.wellcomefit.ch/img/gs/done/' + image" />
                </div>
                <div v-show="studio._id && image == ''" class="img-wrapper">
                    <img :src="getImageLink()" />
                </div>
                <div v-show="invitedby !== ''" class="info-text">
                    <div v-show="sent !== 1 && studio._id" class="kontakt">
                        <span class="tit">Melde dich bei uns</span>
                        <div class="tel-email">
                            <a :href="'tel:' + telefon">{{ telefon }}</a>
                            <a :href="'emailto:' + mail">{{ mail }}</a>
                        </div>
                    </div>

                    <span class="infos" v-if="sent == 0 && studio._id">oder lass Dich von uns kontaktieren</span>
                    <span class="infos" v-if="sent == 1 && studio._id">
                        Wir melden uns umgehend bei Dir.
                        <br />
                        <br />
                        Wir freuen uns schon sehr auf Dich!
                    </span>
                    <span class="infos" v-if="sent == 2 && studio._id">
                        Es gab einen Fehler, bitte versuche es später erneut.
                    </span>
                </div>
                <input
                    v-show="sent !== 1 && studio._id"
                    type="text"
                    id="name"
                    v-model="name"
                    placeholder="Vorname Nachname"
                    required
                />
                <input
                    v-show="sent !== 1 && studio._id"
                    type="tel"
                    id="telnummer"
                    v-model="telnummer"
                    placeholder="+41"
                    required
                />
                <button
                    v-show="sent !== 1 && studio._id"
                    :disabled="!telnummer || telnummer == '+41' || telnummer.length < 9 || !name"
                    class="rueckruf"
                    @click.prevent="sendAffiliate()"
                >
                    <span class="material-icons">phone</span>
                    Bitte um Rückruf
                </button>
                <span class="infos" v-show="sent !== 1 && studio._id">Wir freuen uns auf Dich!</span>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../api'

import $ from 'jquery'
export default {
    data() {
        return {
            heatmap: {
                max: 100,
                min: 0,
                data: [],
                moveDrawable: false,
                clickDrawable: true,
                drawValue: 5,
            },
            res_model: 1,
            studio_select: '0',
            showInfos: true,
            invitedby: '',
            telnummer: '',
            telefon: '',
            mail: '',
            name: '',
            studiotitle: '',
            sent: 0,
            studio: {},
            studios: [],
            kontakt: [],
            image: '',
            heatmap_variant: 0,
            page_model: 1,
            showRes: false,
            resolutions: [],
            show_select: false,
        }
    },
    methods: {
        showStudioselect() {
            if (location.pathname == '/schnupperwoche') {
                this.$router.push('/schnupperwoche/studioauswahl')
            }
            if (location.pathname == '/schnupperwochen') {
                this.$router.push('/schnupperwochen/studioauswahl')
            }
            this.show_select = true
        },
        goBack() {
            this.show_select = false

            if (location.pathname.includes('/schnupperwoche/')) {
                this.$router.push('/schnupperwoche')
            } else {
                this.$router.push('/schnupperwochen')
            }
        },
        getScreenRes() {
            /*
      return {
        width: screen.width,
        height: screen.height,
      };
      */
        },
        getOS() {
            /*
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }

      return os;
      */
        },
        handleChange(val) {
            /*
      //this.heatmap.data = val;
      let variant = 0;
      if (this.invitedby == "" && this.showInfos) {
        variant = 1;
      } else if (this.invitedby !== "") {
        if (
          this.heatmap_variant !== variant &&
          this.heatmap_variant !== 0 &&
          this.heatmap_variant !== 2
        ) {
          this.heatmap.data = [];
          api.submitHeatMap({
            clicks: val,
            device: {
              screen: this.getScreenRes(),
              os: this.getOS(),
            },
            variant: 1,
            page: "Sonnenseite_LP",
          });
        }
        variant = 2;
      }
      this.heatmap_variant = variant;
      */
        },
        centerText() {
            if (location.host.includes('wellcomefit')) {
                return 'in Frauenfeld, Netstal, Bülach, Dietlikon und St. Gallen'
            } else {
                return 'in Winterthur und Pfungen'
            }
        },
        getArt() {
            if (location.host.includes('wellcomefit')) {
                return 'well come FIT'
            }
            if (location.host.includes('wintifit')) {
                return 'winti FIT'
            }
            if (location.host.includes('fzw')) {
                return 'Fitness Zenter Wetzikon'
            }
        },
        getMoreInfoImage() {
            let ret = false
            if (location.host.includes('localhost')) {
                ret = '/img/kampagne/ten/wellcomeFit10Days.jpeg'
            }
            if (location.host.includes('wellcomefit')) {
                ret = '/img/kampagne/ten/wellcomeFit10Days.jpeg'
            }
            if (location.host.includes('wintifit')) {
                ret = '/img/kampagne/weiterempfehlung/winticomeFit10Days.jpeg'
            }

            return ret
        },
        getImageLink() {
            if (location.host.includes('local')) {
                return 'https://app.wellcomefit.ch/img/kampagne/ten/wellcomeFit10Days.jpeg'
            }
            if (location.host.includes('wellcomefit')) {
                return '/img/kampagne/ten/wellcomeFit10Days.jpeg'
            }
            if (location.host.includes('wintifit')) {
                return '/img/kampagne/ten/winticomeFit10Days.jpeg'
            }
            if (location.host.includes('fzw')) {
                return '/img/affiliate_1.png'
            }
        },
        pickstudio(ps) {
            if (location.pathname == '/schnupperwoche/studioauswahl') {
                this.$router.push('/schnupperwoche/kontakt')
            }
            if (location.pathname == '/schnupperwochen/studioauswahl') {
                this.$router.push('/schnupperwochen/kontakt')
            }

            /*if (!location.pathname.includes("schnupperwochen")) {
        hj("stateChange", "/schnupperwoche/button");
      }*/

            /*
      this.invitedby =
        this.studios.filter((s) => {
          return s._id == ps;
        })[0].code + "|?|";
        */

            if (!this.studio._id) {
                this.studio = this.studios.filter((s) => {
                    return s._id == ps
                })[0]
            }

            this.telefon = this.studio.telefon
            this.mail = this.studio.email
            this.showInfos = false
        },

        sendAffiliate() {
            if (location.pathname == '/schnupperwoche/kontakt') {
                this.$router.push('/schnupperwoche/versendet')
                fbq('track', 'Lead')
                console.log('lead')
            }
            if (location.pathname == '/schnupperwochen/kontakt') {
                this.$router.push('/schnupperwochen/versendet')
            }

            /*
      api.submitHeatMap({
        clicks: this.heatmap.data,
        device: {
          screen: this.getScreenRes(),
          os: this.getOS(),
        },
        variant: 2,
        page: "Sonnenseite_LP",
      });
      */
            api.sendSchnuppermail({
                tel: this.telnummer,
                name: this.name,
                invitedby: this.invitedby,
                studio: this.studio,
                url: location.pathname,
            })
                .then((data) => {
                    if (data.status) {
                        this.sent = 1
                    } else {
                        this.sent = 2
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        changePage() {
            //this.changeHeatMap();
        },
        changeHeatMap() {
            /*
      if (this.res_model == 1) {
        alert("Bitte wähle eine Auflösung aus");
      } else {
        if (this.page_model == 2) {
          this.invitedby = "dominic.ammann.ch@gmail.com";
        }
        if (this.page_model == 1) {
          this.invitedby = "";
        }
        $(".htmp-container").width(this.res_model.width);
        $(".htmp-container").height(this.res_model.height);

        this.loadHeatMapData(
          this.res_model.width,
          this.res_model.height,
          this.page_model
        );
      }*/
        },
        loadHeatMapData(width, height, pm) {
            /* api.getHeatMap({ width, height, variant: pm }).then((data) => {
        this.heatmap.data = data.clicks;
      });*/
        },
        loadHeatMapRes() {
            /*api.loadHeatMapRes().then((data) => {
        console.log("HeatMap Resolutions", data);
        this.resolutions = data;
      });*/
        },
    },
    beforeDestroy() {
        /* console.log(this.heatmap.data);*/
    },
    watch: {
        $route(to, from) {
            if (from.params.status == 'kontakt' && to.params.status == 'studioauswahl') {
                this.show_select = true
                this.studio = {}
            }
            if (from.params.status == 'studioauswahl' && !to.params.status) {
                this.show_select = false
                this.studio = {}
            }
        },
    },
    created() {
        if (location.hostname.includes('wellcomefit') || location.hostname.includes('local')) {
            // redirect to wellcomefit.ch/10-days
            window.location.replace('https://wellcomefit.ch/11-tage-4-website/')
        } else {
            // redirect to wintifit.ch/page-10tage-website/
            window.location.replace('https://wintifit.ch/page-11tage-website')
        }

        if (this.$route.params.status == 'versendet') {
            if (location.pathname.includes('/schnupperwochen')) {
                this.$router.push('/schnupperwochen')
            } else {
                this.$router.push('/schnupperwoche')
            }
        }

        if (this.$route.params.status == 'studioauswahl') {
            this.show_select = true
        }

        if (this.$route.params.status == 'kontakt') {
            if (location.pathname.includes('/schnupperwochen')) {
                this.$router.push('/schnupperwochen')
            } else {
                this.$router.push('/schnupperwoche')
            }
        }

        let ref = this
        window.onload = function () {
            $('img.menu-icon').click(function () {
                ref.show_select = true
            })
            $('select').each(function () {
                console.log($(this).width())
            })
            $('#slider').on('input change', (e) => {
                const sliderPos = e.target.value
                // Update the width of the foreground image
                $('.foreground-img').css('width', `${sliderPos}%`)
                // Update the position of the slider button
                $('.slider-button').css('left', `calc(${sliderPos}% - 18px)`)
            })
            $('#slider1').on('input change', (e) => {
                const sliderPos = e.target.value
                // Update the width of the foreground image
                $('.foreground-img_1').css('width', `${sliderPos}%`)
                // Update the position of the slider button
                $('.slider-button1').css('left', `calc(${sliderPos}% - 18px)`)
            })
            if (
                (location.host.includes('wellcomefit') ||
                    location.host.includes('localhost') ||
                    location.host.includes('wintifit')) &&
                (location.pathname == '/schnupperwoche' || location.pathname.includes('/schnupperwoche/')) &&
                !location.search.includes('@') &&
                !location.search.includes('%40')
            ) {
                console.log('contact')

                fbq('track', 'Contact')
            }
        }

        if (location.host.includes('wellcomefit')) {
            this.studiotitle = 'bei der well come FIT AG'
        } else if (location.host.includes('wintifit')) {
            this.studiotitle = 'bei der winti FIT AG'
        } else if (location.host.includes('fzw')) {
            this.studiotitle = 'beim Fitness Zentrum Wetzikon'
        }

        if (location.search.includes('@') || location.search.includes('%40')) {
            this.invitedby = location.search.substring(1).replace('%40', '@')

            api.getEBTImage(this.invitedby).then((data) => {
                if (data.image) {
                    this.image = data.image
                }

                this.studio = data.studio

                this.telefon = this.studio.telefon
                this.mail = this.studio.email
                this.showInfos = false
            })
        } else {
            this.invitedby = '10 Tage Sonnenseite Fitness (Flyer)'
            api.getStudios()
                .then((studios) => {
                    this.studios = []
                    let studios_temp = []

                    for (let s of studios) {
                        if (s.code != 'test' && s.code != 'ot') {
                            studios_temp.push(s)
                            this.kontakt[s.code] = s
                        }
                    }
                    this.studios = studios_temp.sort((s, s1) => {
                        return s.stat_filter - s1.stat_filter
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },
}
</script>

<style lang="scss" scoped>
.register-now {
    background-color: #fcb827;
    color: #fff;
    text-align: center !important;
    display: flex;
    max-width: 500px;
    margin: 20px auto 20px auto;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}
.bold_txt {
    font-weight: bold;
}
.select-studio {
    position: fixed;
    top: 0%;
    right: 0%;
    height: 100vh;
    width: 100vw;
    background-color: white;
    overflow: scroll;

    z-index: 9999;
    .select-studio-wrapper {
        max-width: 500px;
        margin: 0px auto 70px auto;
        img {
            width: 100%;
        }

        > span {
            width: 100%;
            background-color: var(--v-primary);
            display: flex;
            padding: 10px 16px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: center;
            margin-bottom: 2px;
        }

        .studio-wrapper-select {
            flex-direction: row;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;

            .studio-for-select {
                width: calc(50% - 1px);
                display: flex;
                background-color: var(--v-primary);

                height: 90px;
                margin-bottom: 2px;

                &.zuruck {
                    background-color: #313131 !important;
                }
                span {
                    padding: 0px 15px 0px 15px;
                    text-align: center;
                    margin: auto;
                    color: white;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
        }
    }
}
@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container1 {
    height: 310px !important;
    .img {
        background-size: 280px 310px !important;
    }
}
.container,
.container1 {
    position: relative;
    width: 280px;
    height: 370px;
    border: 2px solid #fff;
    padding: 0px !important;
    margin: 40px auto !important;

    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 280px 370px;
    }
    .background-img {
        background-image: url('https://app.wellcomefit.ch/img/kampagne/ten/kampagne_1_pre.jpeg');
    }
    .foreground-img {
        background-image: url('https://app.wellcomefit.ch/img/kampagne/ten/kampagne_1_after.jpeg');
        width: 50%;
    }

    .background-img_1 {
        background-image: url('https://app.wellcomefit.ch/img/kampagne/ten/kampagne_2_pre.jpeg');
    }
    .foreground-img_1 {
        background-image: url('https://app.wellcomefit.ch/img/kampagne/ten/kampagne_2_after.jpeg');
        width: 50%;
    }
    .slider1 {
        &::-webkit-slider-thumb {
            height: 310px !important;
        }
        &::-moz-range-thumb {
            height: 310px !important;
        }
    }
    .slider,
    .slider1 {
        max-width: 280px !important;
        position: absolute;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 100%;
        padding: 0px;
        outline: none;
        margin: 0;
        transition: all 0.2s;
        @include center;
        &:hover {
            background: rgba(#f2f2f2, 0.1);
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 6px;
            height: 370px;
            background: white;
            cursor: pointer;
        }
        &::-moz-range-thumb {
            width: 6px;
            height: 370px;
            background: white;
            cursor: pointer;
        }
    }

    .slider-button,
    .slider-button1 {
        $size: 30px;
        pointer-events: none;
        position: absolute;
        width: $size;
        height: $size;
        border-radius: 50%;
        background-color: white;
        left: calc(50% - 18px);
        top: calc(50% - 18px);
        @include center;

        @mixin arrow-helper() {
            content: '';
            padding: 3px;
            display: inline-block;
            border: solid #5d5d5d;
            border-width: 0 2px 2px 0;
        }
        &:after {
            @include arrow-helper();
            transform: rotate(-45deg);
        }
        &:before {
            @include arrow-helper();
            transform: rotate(135deg);
        }
    }
}
.res_wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    justify-content: center;

    select {
        width: 300px;
        margin: auto;
    }
}
.hmp-content {
    z-index: 1 !important;
}
.heatmapjs-container {
    width: 100%;
    height: 100%;
}
.schnupperwoche {
    overflow: auto;
    margin: auto auto 200px auto;
}
.wrap-msgs {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    &-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        span.emoji {
            padding-right: 15px;
        }
        span.msg {
        }
    }
}

.more_info_image {
    width: 100%;
    margin-bottom: 20px;
}
.zurueck {
    padding: 10px 15px;
    background-color: #313131;
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.mehr_infos {
    max-width: 400px;
    margin: 0px auto;
    padding: 10px 15px;
    overflow: hidden;

    h2 {
        text-align: center;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 135%;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: bold;
    }
}
.img-wrapper {
    img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        display: block;
    }
    margin-bottom: 20px;
}
.pick {
    font-weight: bold;
    font-size: 20px;
    /* line-height: 20px; */
    background-color: var(--v-primary);
    margin-bottom: 2px;
    color: #fff;
    padding: 20px 15px;
    display: block;
    text-align: center;
}
.studiowrapper {
    width: 290px;
    background-color: var(--v-primary);
    color: #fff;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;

    &.bott {
        margin-top: 15px;
    }
    select {
        /* text-align: -webkit-center; */
        width: 100%;
        /* text-align: center; */
        text-transform: uppercase;
        color: #fff;
        -moz-text-align-last: center;
        /* text-align-last: center; */
        width: 290px;
        height: 60px;
        -moz-padding-start: calc(10px - 3px);
        /* padding-left: 10px; */
        /* background: url(fff-0-2.png) repeat; */
        color: #fff;
        font-size: 16px;
        -moz-appearance: none;
        -webkit-appearance: none !important;
        appearance: none;
        border: none;
        cursor: pointer;
        padding-left: 70px;

        -moz-appearance: none;
        -webkit-appearance: none !important;
        appearance: none;
        border: none;

        cursor: pointer;

        &::-ms-expand {
            display: none;
        }

        &:focus::-ms-value {
            background-color: transparent;
        }

        option {
            color: black;
        }
    }
    /*
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  .studio {
    width: calc(50% - 1px);
    text-align: center;
    height: 80px;
    line-height: 80px;
    background-color: var(--v-primary);
    color: white;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    &.infox {
      background-color: #ff9800;
    }
  }*/
}
.info-title {
    color: var(--v-primary);
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 500;
    max-width: 300px;
    margin: 20px auto;
    font-size: 20px;
}
.infos {
    max-width: 280px;
    display: block;
    text-align: center;
    margin: 10px auto;
    font-weight: bold;
    color: #313131;
}

.kontakt {
    display: flex;
    flex-direction: column;
    text-align: center;
    .tit {
        font-weight: bold;
        color: #313131;
        margin-bottom: 5px;
    }
    .tel-email {
        display: flex;
        flex-direction: column;
        justify-self: center;
        a {
            text-decoration: none;
            color: #313131;
        }
    }
}

.schnupperwoche-infos {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.rueckruf:disabled {
    background-color: var(--v-primary) b5;
}
input {
    width: 80%;
    max-width: 300px;
    background-color: inherit;
    margin: 10px auto;
    text-align: center;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.rueckruf {
    background-color: var(--v-primary);
    color: white;
    width: 80%;
    max-width: 300px;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    margin: auto;
    justify-content: center;
    span {
        margin-right: 10px;
    }
}
</style>
