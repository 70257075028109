<template>
    <v-dialog :value="true" fullscreen persistent>
        <v-card flat>
            <v-card-title class="primary white--text">
                {{ leadCategory && leadCategory.title ? leadCategory.title : 'Kampagne' }}
            </v-card-title>
            <v-container style="max-width: 500px" v-if="leadCategory">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            hide-details
                            class="mt-5"
                            outlined
                            label="Kampagnen Titel"
                            v-model="leadCategory.title"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn outlined color="primary" @click="showCategoryDialog = true">Kategorie hinzufügen</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="d-flex justify-center" v-else>
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-container>
            <v-container>
                <v-row v-if="leadSettings.length === 0">
                    <v-col cols="12">
                        <v-alert type="info">Es wurden keine Kategorien in dieser Kampagne gefunden</v-alert>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12"></v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-btn @click="$router.push('/dashboard/leads/category-settings')" text>Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary">Speichern</v-btn>
            </v-card-actions>
            <v-dialog :value="showCategoryDialog" fullscreen persistent>
                <v-card flat>
                    <v-card-title class="primary white--text">
                        {{ leadCategory && leadCategory.title ? leadCategory.title + ' - Kategorie' : 'Kategorie' }}
                    </v-card-title>
                    <div v-if="category" style="max-width: 700px; margin: 0px auto">
                        <v-row>
                            <v-col>
                                <v-text-field filled class="mt-5" v-model="category.title" label="Kategorie Titel" />
                                <v-text-field
                                    filled
                                    hint="Keine Abstände und nur Kleinbuchstaben (ohne Sonderzeichen)"
                                    @keydown.space.prevent
                                    v-model="category.link"
                                    label="Link"
                                />
                                <slim-cropper class="mx-auto mt-2 mb-7" :options="slimOptions">
                                    <img v-if="category.image" :src="'/img' + category.image" alt="" />
                                    <input type="file" accept="image/*" />
                                </slim-cropper>
                                <vue-editor :editorToolbar="customToolbar" v-model="category.description"></vue-editor>
                                <v-switch
                                    class="mt-5"
                                    filled
                                    v-model="category.showContactInfo"
                                    :label="'Studio Kontaktinformationen anzeigen'"
                                ></v-switch>
                                <div v-show="category.showContactInfo">
                                    <v-switch
                                        filled
                                        v-model="category.leadFields.active"
                                        :label="'Eingabefelder anzeigen (Kunde)'"
                                    ></v-switch>
                                    <v-switch
                                        filled
                                        class="ml-5 pt-0 mt-0 mb-0"
                                        v-if="category.leadFields.active"
                                        v-model="category.leadFields.name"
                                        label="Vorname"
                                    ></v-switch>
                                    <v-switch
                                        filled
                                        class="ml-5 pt-0 mt-0 mb-0"
                                        v-if="category.leadFields.active"
                                        v-model="category.leadFields.surname"
                                        label="Nachname"
                                    ></v-switch>
                                    <v-switch
                                        filled
                                        class="ml-5 pt-0 mt-0 mb-0"
                                        v-if="category.leadFields.active"
                                        v-model="category.leadFields.phone"
                                        label="Telefon"
                                    ></v-switch>
                                    <v-switch
                                        filled
                                        class="ml-5 pt-0 mt-0 mb-0"
                                        v-if="category.leadFields.active"
                                        v-model="category.leadFields.email"
                                        label="E-Mail"
                                    ></v-switch>
                                    <v-switch
                                        filled
                                        class="ml-5 pt-0 mt-0 mb-5"
                                        v-if="category.leadFields.active"
                                        v-model="category.leadFields.message"
                                        label="Nachricht"
                                    ></v-switch>
                                </div>

                                <v-select
                                    filled
                                    v-model="category.studios"
                                    hint="Diese Studios werden als Auswahl angezeigt"
                                    :items="studios"
                                    item-text="name"
                                    item-value="_id"
                                    label="Studios"
                                    persistent-hint
                                    multiple
                                    single-line
                                ></v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-actions>
                        <v-btn @click="resetCategoryDialog" text>Abbrechen</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveCategory" class="primary">Speichern</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-dialog>
</template>
 
<script>
import api from '@/api'
import { VueEditor } from 'vue2-editor'
import Slim from '@/plugins/slimImage/slim/slim.vue'
export default {
    components: { VueEditor, 'slim-cropper': Slim },
    data() {
        return {
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
            leadCategory: null,
            leadSettings: [],
            setting: null,
            showCategoryDialog: false,
            studios: null,
        }
    },
    created() {
        this.init()
    },
    computed: {
        slimOptions() {
            return {
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                dataJpegCompression: 70,
                instantEdit: true,
                allLinks: [],
            }
        },
    },
    methods: {
        async saveCategory() {
            console.log(this.category)
        },
        async init() {
            try {
                let codeFilterList = ['ot', 'alibey']
                let studios = await api.fetchStudios()
                if (studios) this.studios = studios.filter((e) => !codeFilterList.includes(e.code))
            } catch (e) {}
            this.fetchLeadsByCategory()
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'lead'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.category.image = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
        resetCategoryDialog() {
            this.showCategoryDialog = false
            this.category = {
                studios: this.studios.map((e) => e._id) || [],
                customer: this.leadCategory.customer,
                link: null,

                title: null,
                showContactInfo: true,
                leadFields: {
                    active: true,
                    name: true,
                    surname: true,
                    phone: true,
                    email: true,
                    message: true,
                },
                referralText: null,
                isReferral: false,
                description: null,
                internConditions: null,
                publicConditions: null,
                image: null,
                internPicture: null,
                publicPicture: null,
                price: {
                    active: false,
                    value: null,
                },
                status: [
                    'Wurde angeworben',
                    'Kontaktiert',
                    'Probetraining vereinbart',
                    'Vertrag abgeschlossen',
                    'Kein Abschluss',
                    'Geschlossen',
                ],
                externalLink: String,
            }
        },
        async fetchLeadsByCategory() {
            this.loading = true
            try {
                let status = await api.fetchLeadsByCategory(this.$route.params.id)
                this.leadCategory = status.leadCategory
                this.allLinks = status.allLinks
                if (this.leadCategory) {
                    this.resetCategoryDialog()
                }
                this.leadSettings = status.leadSettings
            } catch (e) {
                this.$toast.error('Fehler beim laden')
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
