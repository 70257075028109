<template>
    <v-row style="max-width: 100%" no-gutters>
        <v-col cols="12">
            <v-row no-gutters>
                <v-col align="center" cols="12">
                    <v-tabs class="mt-5" v-model="tab" centered dark>
                        <v-tab v-for="(tab, index) of tabs" :key="index">{{ tab.text }}</v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-tabs-items dark v-model="tab">
                <v-tab-item v-if="userService.hasRight('c_create_customer')">
                    <v-col align="center" cols="12" class="mt-2 px-5">
                        <v-expansion-panels dark>
                            <v-expansion-panel
                                v-for="(medicalHistory, i) of data.medicalHistory"
                                :key="i + '-medicalHistory'"
                            >
                                <v-expansion-panel-header>
                                    Erstellt am: {{ $moment(medicalHistory.createdAt).format('DD.MM.YYYY') }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col class="text-left" cols="12">
                                            Visum: {{ medicalHistory.creator.vorname }}
                                            {{ medicalHistory.creator.nachname }}
                                            {{ medicalHistory.creator.email }}
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="px-1 py-0 ml-auto text-right"
                                            style="align-self: center"
                                        >
                                            <v-btn small @click="showMedicalHistory(medicalHistory._id)" icon>
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn
                                                small
                                                @click="showMedicalHistory(medicalHistory._id, true)"
                                                icon
                                                color="blue"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn
                                                small
                                                @click="deleteMedicalHistory(medicalHistory._id)"
                                                color="red"
                                                icon
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="12">
                        <v-btn v-if="data.anamnese" @click.prevent="showAnamesebogen" class="mt-2 mb-5">
                            alter Anamnesebogen öffnen
                        </v-btn>
                    </v-col>
                </v-tab-item>
                <v-tab-item v-else>
                    <v-col cols="12" class="mx-5 px-5">
                        <v-alert>Funktion nicht freigeschaltet</v-alert>
                    </v-col>
                </v-tab-item>
                <v-tab-item v-if="userService.hasRight('d_c_healthcare')">
                    <v-col align="center" cols="12" class="mt-2 px-5">
                        <v-btn rounded-xl color="primary" @click="showNewHealthcareForm = true">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="data && data.healthcareForms && data.healthcareForms.length > 0">
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="(healthcareForm, index) of data.healthcareForms"
                                :key="index + '-healthcareForm'"
                            >
                                <v-expansion-panel-header>
                                    {{ index + 1 }}. Bestätigung |
                                    {{ $moment(healthcareForm.createdAt).format('DD.MM.YY HH:mm') }}
                                    Uhr
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Feld</th>
                                                <th class="text-left">Wert</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="false">
                                                <td>Status</td>
                                                <td>{{ mapHealthcareStatus(healthcareForm.status) }}</td>
                                            </tr>
                                            <tr>
                                                <td>Versicherung</td>
                                                <td>{{ healthcareForm.customerInfos.healthcareName }}</td>
                                            </tr>

                                            <tr>
                                                <td>Abo Typen</td>
                                                <td>
                                                    {{ healthcareForm.healthcareInfos.subscription.type.join(', ') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Vertragsstart</td>
                                                <td>
                                                    {{
                                                        $moment(
                                                            healthcareForm.healthcareInfos.subscription.start
                                                        ).format('DD.MM.YYYY')
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Vertragsende</td>
                                                <td>
                                                    {{
                                                        $moment(healthcareForm.healthcareInfos.subscription.end).format(
                                                            'DD.MM.YYYY'
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Dauer</td>
                                                <td>
                                                    <span
                                                        v-if="
                                                            healthcareForm.healthcareInfos.subscription.duration
                                                                .additionalText
                                                        "
                                                    >
                                                        {{ healthcareForm.healthcareInfos.subscription.durationText }}
                                                    </span>
                                                    {{ healthcareForm.healthcareInfos.subscription.duration.text }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Gebühr</td>
                                                <td>{{ healthcareForm.healthcareInfos.subscription.price }}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <v-btn
                                                        @click="downloadHealthcarePDF(healthcareForm._id)"
                                                        color="primary"
                                                    >
                                                        <v-icon class="pr-1">mdi-file-pdf-box</v-icon>
                                                        PDF erstellen
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" v-else class="mt-5 px-5">
                        <v-alert type="info">Keine Krankenkassenbestätigung gefunden</v-alert>
                    </v-col>
                </v-tab-item>
                <v-tab-item v-else>
                    <v-col cols="12" class="mt-5 px-5">
                        <v-alert type="info">Funktion nicht freigeschaltet</v-alert>
                    </v-col>
                </v-tab-item>
                <v-tab-item v-if="userService.hasRight('d_c_timestop')">
                    <v-col align="center" cols="12" class="mt-2 px-5">
                        <v-btn
                            :loading="loadingTS"
                            :disabled="loadingTSError || !timestopText || !timestopText._id"
                            rounded-xl
                            color="primary"
                            @click="showManual"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-if="data && data.timestops && data.timestops.length > 0" cols="12">
                        <v-expansion-panels dark>
                            <v-expansion-panel v-for="timestop of data.timestops" :key="timestop._id + '-timestop'">
                                <v-expansion-panel-header>
                                    <span>
                                        {{ $moment(timestop.createdAt).format('DD.MM.YY HH:mm') }} Uhr |
                                        <span v-if="timestop.timestopFormId && timestop.timestopFormId.studio">
                                            {{ timestop.timestopFormId.studio.name }}
                                        </span>
                                        |
                                        {{ timestop.customerInfos.firstName }}
                                        {{ timestop.customerInfos.lastName }}
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Feld</th>
                                                            <th class="text-left">Wert</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Von</td>
                                                            <td>
                                                                {{
                                                                    $moment(timestop.timestopInfos.start).format(
                                                                        'DD.MM.YYYY'
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bis</td>
                                                            <td>
                                                                {{
                                                                    $moment(timestop.timestopInfos.end).format(
                                                                        'DD.MM.YYYY'
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Grund</td>
                                                            <td>{{ timestop.timestopInfos.reason }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gebühr</td>
                                                            <td>{{ timestop.timestopInfos.fee }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>PDF</td>
                                                            <td>
                                                                <v-btn
                                                                    @click="downloadPDF(timestop._id)"
                                                                    color="primary"
                                                                >
                                                                    <v-icon class="mr-1">mdi-file-pdf-box</v-icon>
                                                                    herunterladen
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-tab-item>
                <v-tab-item v-else>
                    <v-col cols="12" class="mt-5 px-5">
                        <v-alert type="info">Funktion nicht freigeschaltet</v-alert>
                    </v-col>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
        <timestop-form
            @onClose="showNewDialog = false"
            @onSuccess="
                () => {
                    showNewDialog = false
                    fetchUserDocuments()
                }
            "
            :newEntry="true"
            :visible="showNewDialog"
        ></timestop-form>
        <v-dialog persistent :value="showManualDialog" transition="dialog-bottom-transition" max-width="600">
            <v-card>
                <v-stepper v-model="manualStepper">
                    <v-stepper-header>
                        <v-stepper-step
                            v-for="(step, index) of steps"
                            :key="index + '-manualIndex'"
                            :complete="manualStepper > steps.length"
                            :step="index + 1"
                        >
                            {{ step.title }}
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content
                            v-for="(step, index) of steps"
                            :key="index + '-manualContentIndex'"
                            :step="index + 1"
                        >
                            <v-card transparent elevation="0">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-text class="mt-0 pt-0">{{ step.text }}</v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-btn v-if="manualStepper === 1" @click="showManualDialog = false" text>Abbrechen</v-btn>
                            <v-btn v-else @click="manualStepper--" text>Zurück</v-btn>
                            <v-btn v-if="manualStepper !== steps.length" color="primary" @click="manualStepper++">
                                Weiter
                            </v-btn>
                            <v-btn v-else color="primary" @click="showTimestop">zum Timestop</v-btn>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-dialog>
        <anamnese-bogen-create ref="anamneseBogenRef"></anamnese-bogen-create>
        <healthcare-form
            @onClose="showNewHealthcareForm = false"
            @onSuccess="
                () => {
                    showNewHealthcareForm = false
                    fetchUserDocuments()
                }
            "
            ref="healthcareFormRef"
            :visible="showNewHealthcareForm"
        ></healthcare-form>
        <anamnesebogen ref="anamnesebogen"></anamnesebogen>
    </v-row>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import healthcareForm from '../../dashboard/healthcareConfirmation/healthcareForm.vue'
import Anamnesebogen from '../../../components/Anamnesebogen'
import anamneseBogenCreate from '../../../components/anamneseBogenCreate'
import timestopForm from '../timestop/timestopForm.vue'
export default {
    components: { healthcareForm, Anamnesebogen, anamneseBogenCreate, timestopForm },
    props: {
        user: {
            required: true,
        },
    },
    data() {
        return {
            userService,
            showNewDialog: false,
            manualStepper: 1,
            showManualDialog: false,
            timestopText: null,
            steps: [],
            tab: null,
            loadingTS: false,
            loadingTSError: false,
            tabs: [
                {
                    text: 'Anamnese',
                },
                {
                    text: 'KK-Bestätigungen',
                },
                {
                    text: 'Timestops',
                },
            ],
            showNewHealthcareForm: false,
            data: {
                medicalHistory: [],
                timestops: [],
                healthcareConfirmations: [],
                anamnese: null,
            },
        }
    },
    async created() {
        this.fetchUserDocuments()
    },
    methods: {
        showAnamesebogen() {
            this.$refs['anamnesebogen'].show(this.data.anamnese, 1)
        },
        showMedicalHistory(id, editMode = false) {
            this.$refs.anamneseBogenRef.setVisible(id, editMode)
        },
        async deleteMedicalHistory(id) {
            if (confirm('Soll der Anamnesebogen wirklich gelöscht werden?')) {
                try {
                    await api.deleteMedicalHistory(id)
                    this.$toast.success('Erfolgreich gelöscht')
                } catch (e) {
                    this.$toast.error('Fehler beim löschen')
                }

                this.data.medicalHistory = this.data.medicalHistory.filter((e) => e._id != id)
            }
        },
        showManual() {
            if (this.steps.length === 0) {
                this.showNewDialog = true
            } else {
                this.showManualDialog = true
            }
        },
        showTimestop() {
            this.manualStepper = 1
            this.showManualDialog = false
            this.showNewDialog = true
        },
        async getTimestopText() {
            try {
                this.loadingTSError = false
                this.loadingTS = true
                const text = await api.fetchTimestopTextStudio()
                if (!text._id) {
                    //this.$toast.error('Diese Funktion ist nicht verfügbar')
                    this.loadingTSError = true
                } else {
                    this.timestopText = text
                }
            } catch (e) {
                this.$toast.error('Timestop Text konnte nicht geladen werden')
            }
            this.fetchTimestopManual()
        },
        async fetchTimestopManual() {
            try {
                let steps = await api.fetchTimestopManual()
                this.steps = steps || []
            } catch (e) {
                this.loadingTSError = true
                this.$toast.error('Fehler beim laden der Anleitung')
            }
            this.loadingTS = false
        },
        async downloadPDF(id) {
            try {
                api.fetchTimestopPDF(id)
            } catch (e) {}
        },
        async downloadHealthcarePDF(healthcareId) {
            try {
                api.downloadHealthcarePDF(healthcareId)
            } catch (error) {
                this.$toast.error('Fehler beim herunterladen des PDFs')
            }
        },
        mapHealthcareStatus(status) {
            let statusName = ''
            if (status === 'created') statusName = 'Offen'
            return statusName
        },
        async fetchUserDocuments() {
            try {
                let dataTemp = await api.getCustomerDocuments(this.user._id)
                this.data = dataTemp
                this.$emit('loaded')
            } catch (e) {
                this.$toast.error('Fehler beim laden der Dokumente')
            }
            this.getTimestopText()
        },
    },
    computed: {
        isLocal() {
            return location.host.includes('local')
        },
    },
}
</script>
