<template>
    <v-dialog persistent :value="visible" transition="dialog-bottom-transition" max-width="600">
        <v-card>
            <v-toolbar color="primary" dark>Abonnementbestätigung</v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">Informationen</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">Abo-Infos</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 3" step="3">Laufzeit</v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4">Abschluss</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card color="transparent" elevation="0">
                            <v-row no-gutters v-if="!customerInfos.selection">
                                <v-col cols="12" class="px-0 mt-5">
                                    <user-search
                                        :value="creatorInfos.selection"
                                        :disabled="viewOnly"
                                        ignoreRights
                                        :key="creatorInfos.selection"
                                        instructor
                                        label="Visum (Vertragersteller)"
                                        @update="updateCreator"
                                    ></user-search>
                                </v-col>
                                <v-col v-show="!customerInfos.selection" cols="12" class="px-0 mt-5">
                                    <user-search
                                        :value="customerInfos.selection"
                                        :disabled="viewOnly || creatorInfos === null"
                                        ignoreRights
                                        label="Kunde"
                                        :key="customerInfos.selection"
                                        @update="updateCustomer"
                                    ></user-search>
                                </v-col>
                            </v-row>
                            <v-row v-if="customerInfos.selection">
                                <v-col cols="12" class="d-flex justify-end">
                                    <v-btn class="mx-0" v-if="!viewOnly" color="primary" @click="resetCustomer">
                                        Kunde ändern
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="customerInfos.selection">
                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Name der Krankenversicherung"
                                        type="text"
                                        class="my-0 py-0"
                                        v-model="customerInfos.healthcareName"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Zusatzversicherung"
                                        type="text"
                                        class="my-0 py-0"
                                        v-model="customerInfos.additionalInsurance"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Versichertennummer"
                                        class="my-0 py-0"
                                        v-model="customerInfos.insuranceNumber"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Vorname"
                                        class="my-0 py-0"
                                        v-model="customerInfos.firstName"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Nachname"
                                        class="my-0 py-0"
                                        v-model="customerInfos.lastName"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Strasse"
                                        class="my-0 py-0"
                                        v-model="customerInfos.street"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="pt-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        type="number"
                                        label="Postleitzahl"
                                        class="my-0 py-0"
                                        v-model="customerInfos.zip"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pt-0" cols="6">
                                    <v-text-field
                                        :disabled="viewOnly"
                                        label="Stadt"
                                        class="my-0 py-0"
                                        v-model="customerInfos.city"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-btn @click="closeForm" text>Abbrechen</v-btn>
                        <v-btn
                            :disabled="!customerInfos.selection || !creatorInfos.selection"
                            color="primary"
                            @click="e1 = 2"
                        >
                            Weiter
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card class="mb-12" color="transparent" elevation="0">
                            <v-row>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="healthcareInfos.subscription.type"
                                        :items="[
                                            'Kombi-Abo',
                                            'Kraft- und Ausdauertraining',
                                            'Krafttraining',
                                            'Gruppenfitness (ohne Kampfsport)',
                                            'inkl. Sauna und/oder Bad',
                                            'Wellness',
                                        ]"
                                        label="Abo-Typ"
                                        multiple
                                        chips
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <v-radio-group v-model="healthcareInfos.subscription.duration">
                                        <v-radio
                                            @change="healthcareInfos.subscription.durationText = ''"
                                            v-for="radio of durationTypes"
                                            :key="radio.text"
                                            :label="`${radio.text}`"
                                            :value="radio"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col
                                    v-if="
                                        healthcareInfos.subscription.duration &&
                                        healthcareInfos.subscription.duration.additionalText
                                    "
                                    cols="12"
                                >
                                    <v-text-field
                                        :label="'Anzahl ' + healthcareInfos.subscription.duration.text"
                                        v-model="healthcareInfos.subscription.durationText"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-btn text @click="e1--">Zurück</v-btn>

                        <v-btn
                            color="primary"
                            :disabled="
                                healthcareInfos.subscription.type === null ||
                                healthcareInfos.subscription.type === [] ||
                                !healthcareInfos.subscription.duration ||
                                (healthcareInfos.subscription.duration &&
                                    healthcareInfos.subscription.duration.additionalText &&
                                    !healthcareInfos.subscription.durationText)
                            "
                            @click="e1 = 3"
                        >
                            Weiter
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card class="mb-12" color="transparent" elevation="0">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Abo Beginn"
                                        type="date"
                                        v-model="healthcareInfos.subscription.start"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Abo Ende"
                                        type="date"
                                        v-model="healthcareInfos.subscription.end"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Abonnementpreis CHF"
                                        v-model="healthcareInfos.subscription.price"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-btn text @click="e1--">Zurück</v-btn>
                        <v-btn color="primary" @click="e1 = 4">Weiter</v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                        <v-card class="mb-12" color="transparent" elevation="0">
                            <v-text-field v-model="healthcareInfos.timeAndPlace" label="Ort/Datum"></v-text-field>
                            <v-card-title>Unterschrift Versicherter</v-card-title>
                            <signature-pad
                                class="mx-auto"
                                :signature-data="customerInfos.signature"
                                @startDrawing="signature = true"
                                @resetDrawing="resetSignature"
                                @saveSignature="saveSignature"
                                ref="signatureConditions"
                            ></signature-pad>
                        </v-card>

                        <v-btn text @click="e1--">Zurück</v-btn>
                        <v-btn color="primary" @click="saveEntry()">Speichern</v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>

<script>
import FormWizard from '../../../components/vueStepWizard/FormWizard.vue'
import userSearch from '../../../components/userSearch.vue'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import api from '@/api'
export default {
    components: { FormWizard, userSearch, signaturePad },
    data() {
        return {
            subscriptionInfo: null,
            durationTypes: [
                {
                    text: '... Monats-/10-er Abo',
                    additionalText: true,
                },
                {
                    text: '1/2 Jahr',
                    additionalText: false,
                },
                {
                    text: '1 Jahr',
                    additionalText: false,
                },
                {
                    text: '... Jahre',
                    additionalText: true,
                },
            ],
            signature: false,
            e1: 1,
            healthcareInfos: {
                subscription: {
                    type: null,
                    start: null,
                    end: null,
                    duration: null,
                    durationText: '',
                    price: null,
                },
                timeAndPlace: null,
            },
            creatorInfos: { selection: null },
            customerInfos: { selection: null, signature: null },
        }
    },
    created() {},
    methods: {
        logthis(log) {
            console.log(log)
        },
        resetSignature() {
            this.signature = false
            this.customerInfos.signature = null
        },
        async saveEntry() {
            this.$refs.signatureConditions.saveSignature()
            let entry = {
                creatorInfos: this.creatorInfos,
                customerInfos: this.customerInfos,
                healthcareInfos: this.healthcareInfos,
                status: 'created',
                version: 1,
            }

            try {
                //  await api.saveTimestopEntry(entry)
                let status = await api.saveHealthcareFormEntry(entry)
                this.$emit('onSuccess')
                this.$toast.success('Eintrag erfolgreich gespeichert')
                // this.$emit('close')
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim abspeichern des Eintrags')
            }
        },

        saveSignature(e) {
            this.customerInfos.signature = e
        },
        closeForm() {
            this.$emit('onClose')
        },

        resetCustomer() {
            this.customerInfos = { selection: null, signature: null }
            this.subscriptionInfo = null
        },
        async updateCreator(id) {
            let contractCreator = await api.getUserForManager(id)
            let contractCreatorInfos = {
                selection: id,
                firstName: contractCreator.vorname,
                lastName: contractCreator.nachname,
                email: contractCreator.email,
            }

            this.creatorInfos = contractCreatorInfos

            this.$forceUpdate()
        },

        async updateCustomer(id) {
            let customer = await api.getUserForManager(id)
            try {
                let infos = await api.fetchPackageUpgradePossibilites(id, false)
                this.subscriptionInfo = infos
                console.log(this.subscriptionInfo)
            } catch (e) {
                this.$toast.error('Fehler beim laden der TAC Daten (stimmt E-Mail überein?)')
            }
            let customerEntry = {
                selection: id,
                firstName: customer.vorname,
                lastName: customer.nachname,
                insuranceNumber: null,
                healthcareName: null,
                additionalInsurance: null,
                street: customer.adresse_1,
                city: customer.stadt,
                zip: customer.plz,
                signature: null,
            }

            //this.resetLegalRepresentative()

            this.customerInfos = customerEntry

            /*if (!this.checkIfBirthdayIsValid(this.fieldEntries.customer.infos.birthday)) {
                this.$toast.error('Bitte gib ein gültiges Geburtsdatum ein')
            }*/

            this.$forceUpdate()
        },
    },
    props: {
        viewOnly: {
            type: Boolean,
            default: false,
        },

        newEntry: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
        },
        visible: {
            type: Boolean,
        },
    },
    computed: {
        infoPageRules() {
            return true
            // Überprüfen, ob die Start- und Enddaten vorhanden und korrekt sind
            if (
                !this.timestopInfos.start ||
                !this.timestopInfos.end ||
                new Date(this.timestopInfos.start) > new Date(this.timestopInfos.end)
            ) {
                return true
            }

            // Überprüfen, ob die restlichen Felder ausgefüllt sind
            if (
                !this.timestopInfos.reason ||
                !this.timestopInfos.duration ||
                !this.timestopInfos.durationSelection ||
                !this.timestopInfos.fee ||
                !this.timestopInfos.timeAndPlace
            ) {
                return true
            }

            // Wenn bis hierher alles in Ordnung ist, ist die Validierung erfolgreich
            return false
        },
        formattedTimestopText() {
            if (this.timestopText) {
                return this.timestopText
            } else return ''
        },
    },
    watch: {
        async visible() {
            if (this.visible) {
                this.e1 = 1
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>