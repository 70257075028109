<template>
    <div>
        <v-data-table
            :items-per-page="40"
            no-data-text="Keine Einkäufe gefunden"
            :headers="tableHeaders"
            hide-default-footer
            :custom-filter="filterPurchases"
            :expanded.sync="expanded"
            :item-key="'_id'"
            dark
            :search="search"
            show-expand
            :single-expand="true"
            :items="purchases"
        >
            <template v-slot:top>
                <v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item.user.firstName="{ item }">
                <span @click="$router.push('/dashboard/user/search/' + item.user._id)">
                    {{ item.user.firstName }}
                </span>
            </template>
            <template v-slot:item.amount="{ item }">
                <span>
                    {{ item.amount + ' CHF' }}
                </span>
            </template>
            <template v-slot:item.inTAC="{ item }">
                <v-chip :color="item.products.find((product) => !product.inTac) ? 'orange' : 'primary'">
                    {{ item.products.find((product) => !product.inTac) ? 'Übertragung OFFEN' : 'Abgeschlossen' }}
                </v-chip>
            </template>
            <template v-slot:item.redeemed="{ item }">
                <v-chip :color="item.products.find((product) => !product.redeemed) ? 'orange' : 'success'">
                    {{ item.products.filter((product) => product.redeemed).length + '/' + item.products.length }}
                </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="tableHeaders.length">
                    <v-data-table
                        :items-per-page="10"
                        no-data-text="Keine Produkte gefunden"
                        :headers="innerTableHeaders"
                        hide-default-footer
                        dark
                        :items="item.products"
                    >
                        <template v-slot:item.code="{ item }">
                            <v-chip @click="copyCode(item.code)">
                                {{ item.code }}
                            </v-chip>
                        </template>
                        <template v-slot:item.inTac="{ item }">
                            <v-chip
                                :disabled="item.inTac"
                                :color="item.inTac ? 'success' : 'orange'"
                                @click="startTACSavingDialog(item)"
                            >
                                {{ item.inTac ? 'Übertragen' : 'jetzt Übertragen' }}
                            </v-chip>
                        </template>
                        <template v-slot:item.tacCode="{ item }">
                            <v-chip @click="copyCode(item.tacCode)">
                                {{ item.tacCode ? item.tacCode : '-' }}
                            </v-chip>
                        </template>
                        <template v-slot:item.redeemed="{ item }">
                            <v-tooltip top>
                                {{ item.email }}
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        v-on="on"
                                        v-bind="attrs"
                                        :color="item.redeemed ? 'success' : 'orange'"
                                        @click="savedItemInTac(item, 'redeemed')"
                                    >
                                        {{ item.redeemed ? 'Eingelöst' : 'jetzt einlösen' }}
                                    </v-chip>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </td>
            </template>
        </v-data-table>
        <v-dialog persistent v-model="loading">
            <v-card dark>
                <v-col cols="12" class="text-center">
                    <v-card-title class="justify-content-center">Speichern...</v-card-title>
                    <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="tacSavingDialog" max-width="800px">
            <v-card dark>
                <v-col cols="12" class="text-center">
                    <v-card-title class="justify-content-center">
                        Gebe die Nummer des Gutscheins im TAC ein.
                    </v-card-title>
                    <v-text-field v-model="tacVoucherCode" label="TAC Gutschein Code"></v-text-field>
                    <v-btn @click="savedItemInTac(presavedItem, 'inTac', tacVoucherCode)">Speichern</v-btn>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>
  
  <script>
import axios from 'axios'
import api from '../../../api'

export default {
    data() {
        return {
            loading: false,
            search: '',
            tacSavingDialog: false,
            presavedItem: null,
            purchases: [],
            expanded: [],
            tacVoucherCode: '',
            tableHeaders: [
                { text: 'Vorname', value: 'user.firstName' },
                { text: 'Nachname', value: 'user.lastName' },
                { text: 'anz. Produkte', value: 'products.length' },
                { text: 'Datatrans ID', value: 'transactionId' },
                { text: 'Preis', value: 'amount' },
                { text: 'Übertragen', value: 'inTAC' },
                { text: 'Eingelöst', value: 'redeemed' },
                { text: '', value: 'data-table-expand' },
            ],
            innerTableHeaders: [
                { text: 'Titel', value: 'title' },
                { text: 'Preis', value: 'price' },
                { text: 'Code', value: 'code' },
                { text: 'TAC Code', value: 'tacCode' },
                { text: 'Übertragen', value: 'inTac' },
                { text: 'Eingelöst', value: 'redeemed' },
            ],
        }
    },
    async created() {
        this.purchases = await api.fetchAllPayedPurchasesAdmin()
    },
    methods: {
        filterPurchases(x, search, item) {
            return (
                item.transactionId.includes(search) ||
                item.user.firstName.includes(search) ||
                item.user.lastName.includes(search) ||
                item.products.find(
                    (prod) =>
                        (prod.code && prod.code.includes(search)) || (prod.tacCode && prod.tacCode.includes(search))
                )
            )
        },
        copyCode(code) {
            navigator.clipboard.writeText(code)
            this.$toast.success('Code wurde kopiert.')
        },
        startTACSavingDialog(item) {
            this.presavedItem = item
            this.tacSavingDialog = true
        },
        async savedItemInTac(item, type = 'inTac', code = null) {
            if (type === 'redeemed') {
                if (item.redeemed) return this.$toast.error('Wurde bereits eingelöst')
                if (!item.inTac) return this.$toast.error('Zuerst den TAC Code hinterlegen')
            }

            this.tacSavingDialog = false
            this.loading = true
            item.saveReason = type
            item.tacCode = code
            try {
                let saved = await api.itemSavedInTAC(item)
                this.purchases = await api.fetchAllPayedPurchasesAdmin()
                setTimeout(null, 500)
                this.loading = false

                this.$toast.success('Status erfolgreich geändert')
                this.tacVoucherCode = ''
            } catch (e) {
                this.$toast.error('Fehler ist aufgetreten')
            }
        },
    },
    computed: {},
}
</script>
  