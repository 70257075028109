<template>
    <v-dialog class="dialog" persistent v-model="dialogVisible" :max-width="maxWidth">
        <v-card class="mx-auto editor" :dark="dark">
            <v-card-title class="font-weight-bold dialog-title justify-center mb-5">{{ dialogTitle }}</v-card-title>
            <slot></slot>
            <v-card-actions class="dialog-actions">
                <v-btn :disabled="cancelButtonDisabledState" class="white--text" text @click="cancel">
                    {{ dialogCancelButtonText }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="white--text" :loading="saveButtonLoadingState" text @click="save">
                    {{ dialogSaveButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>  

<script>
export default {
    props: {
        saveButtonLoadingState: {
            type: Boolean,
            default: false,
        },
        cancelButtonDisabledState: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '400',
        },
        dialogTitle: {
            type: String,
            required: true,
        },
        dialogSaveButtonText: {
            type: String,
            default: 'Speichern',
        },
        dialogCancelButtonText: {
            type: String,
            default: 'Abbrechen',
        },
        dialogVisible: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {
        cancel() {
            this.$emit('onCancel')
        },
        save() {
            this.$emit('onSave')
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog {
    position: relative;
    &-title {
        position: sticky;
        top: 0;
        color: white;
        border-bottom: 2px solid var(--v-primary);
        z-index: 9;
        background-color: var(--v-primary);
    }
    &-actions {
        background-color: var(--v-primary);
        border-top: 2px solid var(--v-primary);
        width: 100%;
    }
}
</style>