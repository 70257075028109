<template>
    <v-card flat class="mt-5 mx-auto" style="padding-bottom: 120px" max-width="500px">
        <v-card-title style="background-color: var(--v-primary)" class="white--text">
            Group Fitness Empfehlungen
        </v-card-title>
        <v-row v-if="!loading" no-gutters>
            <v-col class="pt-5 px-5 pb-0" cols="12">
                <course-search-studio
                    :key="courseKey + '-courseSearch'"
                    ignoreRights
                    :value="newCourse"
                    label="Kurs"
                    @update="addCourse"
                ></course-search-studio>
            </v-col>
            <v-col class="px-5" cols="12">
                <!-- Container für horizontales Scrollen -->
                <div class="scroll-container">
                    <v-card
                        flat
                        v-for="(course, index) in recommendations"
                        :key="course._id"
                        class="course-card"
                        inline
                    >
                        <div class="relative-position">
                            <v-img :src="'/img/kurse/' + course.bild" height="120px"></v-img>
                            <v-btn
                                style="background-color: white"
                                icon
                                small
                                class="ma-2 delete-icon"
                                @click.stop="removeCourse(index)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <v-card-title style="line-height: 1.2" class="mb-0 pb-0 h6">
                            {{ course.name.substring(0, 30) }}
                        </v-card-title>
                        <v-card-text>
                            <div v-if="!expanded[index]">
                                {{ course.beschreibung.substring(0, 30) + '...' }}
                                <v-btn text color="primary" @click="toggleDescription(index)">Mehr erfahren</v-btn>
                            </div>
                            <div v-else>
                                {{ course.beschreibung }}
                                <v-btn text color="primary" @click="toggleDescription(index)">Weniger</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-row v-else no-gutters>
            <v-col class="pt-5" cols="12" align="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-card-actions>
            <v-btn @click="$router.push('/')" text>zur Startseite</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="loading" @click="updateRecommendations">Speichern</v-btn>
        </v-card-actions>

        <v-alert class="mt-5" type="info">
            Bitte vergiss nicht, uns eine Google-Bewertung zu hinterlassen. Dein Feedback ist uns wichtig!
        </v-alert>
    </v-card>
</template>

<script>
import courseSearchStudio from '@/components/courseSearchStudio.vue'
import api from '@/api'
export default {
    components: { courseSearchStudio },

    data() {
        return {
            loading: true,
            courseKey: 0,
            newCourse: null,
            visible: true,
            recommendations: [],
            expanded: {},
        }
    },
    computed: {
        userId() {
            return this.$route.params.userId
        },
    },
    created() {
        this.fetchRecommendations()
    },
    methods: {
        async updateRecommendations() {
            this.loading = true
            try {
                let courseIds = this.recommendations.map((e) => e._id)

                let status = await api.updateGFRecommendations(this.userId, courseIds)

                this.$toast.success('Erfolgreich gespeichert')
                this.visible = false
            } catch (e) {
                this.$toast.error('Fehler beim speichern')
            }
            this.loading = false
        },
        async fetchRecommendations() {
            this.loading = true
            try {
                let status = await api.fetchGFRecommendations(this.userId)
                console.log(status)
                this.recommendations = status.recommendations
            } catch (e) {
                this.$toast.error('Fehler beim laden')
            }
            console.log(this.userId)
            this.loading = false
        },
        toggleDescription(index) {
            this.$set(this.expanded, index, !this.expanded[index])
        },
        removeCourse(index) {
            this.recommendations.splice(index, 1)
        },
        addCourse(course) {
            if (!this.recommendations.map((e) => e._id).includes(course._id)) this.recommendations.push(course)
            this.newCourse = null
            this.courseKey++
            this.$forceUpdate()
        },
    },
}
</script>

<style>
.scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 20px; /* Abstand zwischen den Karten */
}

.course-card {
    width: 200px; /* Stelle sicher, dass die Karten eine Mindestbreite haben */
}

.relative-position {
    position: relative;
}

.delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2; /* Stellt sicher, dass der Button über dem Bild liegt */
}
</style>
