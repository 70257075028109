<template>
    <div class="leadSettings">
        <v-expansion-panels v-model="panel" v-if="leads && leads.length > 0">
            <v-expansion-panel dark v-for="(item, i) of leads" :key="i">
                <v-expansion-panel-header> {{ item.title }} </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12">
                            <v-card-title class="mx-0 px-0">Formular Einstellungen</v-card-title>

                            <v-card-text v-if="item.createdAt" class="pb-0 pt-0 px-0"
                                >Erstellt am: {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-text
                            >
                            <v-card-text v-if="item.updatedAt" class="pb-5 pt-0 px-0"
                                >Bearbeitet am: {{ item.updatedAt | moment('DD.MM.YYYY HH:mm') }} Uhr</v-card-text
                            >
                            <v-switch filled v-model="item.active" :label="item.active ? 'Aktiv' : 'Inaktiv'"></v-switch>
                            <v-text-field
                                filled
                                hint="Nur Kleinbuchstaben und keine Abstände erlaubt"
                                @keydown.space.prevent
                                @keydown.shift.prevent
                                v-model="item.link"
                                label="Link" />
                            <v-card-text class="pb-5">Links: </v-card-text>
                            <v-expansion-panels dark>
                                <v-expansion-panel v-for="(host, y) of hosts" :key="y + '-hostLinks'">
                                    <v-expansion-panel-header> {{ host }}/l/{{ item.link }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col cols="12"
                                                ><img :src="'/api/qrcode/image?data=' + host + '/l/' + item.link"
                                            /></v-col>
                                            <v-col cols="12">
                                                <v-btn
                                                    class="mr-5 white--text"
                                                    @click="downloadQRCode(host + '/l/' + item.link)"
                                                    color="primary"
                                                    >QR Code herunterladen</v-btn
                                                >
                                                <v-btn
                                                    class="mr-5 white--text"
                                                    @click="copyLink(host + '/l/' + item.link)"
                                                    color="primary"
                                                    >Link kopieren</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <slim-cropper class="mx-auto mt-10" :options="slimOptions" style="width: 320px; height: 160px">
                                <img v-if="item.image" :src="'/img' + item.image" alt="" />
                                <input type="file" accept="image/*" />
                            </slim-cropper>

                            <v-text-field filled class="mt-10" v-model="item.title" label="Titel" />
                            <vue-editor :editorToolbar="customToolbar" v-model="item.description"></vue-editor>
                            <v-switch
                                v-model="item.showContactInfo"
                                :label="
                                    item.showContactInfo
                                        ? 'Kontaktinformationen anzeigen'
                                        : 'Kontaktinformationen ausblenden'
                                "></v-switch>
                            <v-switch
                                filled
                                v-model="item.leadFields.active"
                                :label="
                                    item.leadFields.active ? 'Kontaktfelder anzeigen' : 'Kontaktfelder ausblenden'
                                "></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.name"
                                label="Vorname"></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.surname"
                                label="Nachname"></v-switch
                            ><v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.phone"
                                label="Telefonnummer"></v-switch
                            ><v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.email"
                                label="E-Mail-Adresse"></v-switch
                            ><v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.message"
                                label="Nachricht"></v-switch>
                            <v-select
                                filled
                                v-model="item.studios"
                                hint="Folgende Studios werden als Auswahl angezeigt"
                                :items="studios"
                                item-text="name"
                                item-value="_id"
                                label="Studios"
                                persistent-hint
                                multiple
                                single-line></v-select>
                        </v-col>
                        <v-col cols="12" class="d-flex flex-column">
                            <v-card-title class="mx-0 px-0">Verwaltungseinstellungen</v-card-title>
                            <v-list class="px-0 mx-0">
                                <v-card-subtitle class="px-0 mx-0 mb-0">Leadstatus</v-card-subtitle>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        @click="removeFromList(list, i)"
                                        v-for="(list, y) in item.status"
                                        :key="y + '-status'">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="list"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <v-text-field
                                filled
                                class="mx-5"
                                v-model="status"
                                :append-outer-icon="status ? 'mdi-plus' : 'mdi-plus'"
                                clear-icon="mdi-close-circle"
                                clearable
                                label="Hinzufügen"
                                type="text"
                                @click:append-outer="addToList(i)"
                                @click:clear="clearStatusInput()"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center mb-10"
                            ><v-btn :loading="loading" color="primary" @click="saveLead(item)">Speichern</v-btn></v-col
                        >
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-else>
            <v-col><span>Es wurden keine Leadformulare gefunden.</span></v-col>
        </v-row>
        <v-row class="mt-5 mb-15"
            ><v-col class="d-flex justify-center align-center"
                ><v-btn @click="addLeadSetting" color="primary" class="white--text"
                    ><v-icon>mdi-plus</v-icon><span class="pr-2">Hinzufügen</span></v-btn
                ></v-col
            ></v-row
        >
    </div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'
import { VueEditor } from 'vue2-editor'
import Slim from '../../../plugins/slimImage/slim/slim.vue'

export default {
    components: { VueEditor, 'slim-cropper': Slim },
    data() {
        return {
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
            host: null,
            hosts: [],
            studios: null,
            tab: null,
            items: ['Liste', 'Einstellungen'],
            leads: [],
            loading: false,
            panel: [],
            status: '',

            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        }
    },
    async created() {
        this.host = window.location.host
        let studios = await api.fetchStudios()
        if (studios) this.studios = studios
        let hostnames = await api.fetchHostnames()
        if (hostnames) this.hosts = hostnames
        console.log(studios)
        this.initLeads()
    },
    methods: {
        async downloadQRCode(link) {
            api.fetchQRCode(link)
        },
        copyLink(link) {
            navigator.clipboard.writeText(link)
            this.$toast.info('Der Link wurde in die Zwischenablage kopiert.')
        },
        addToList(i) {
            if (this.status) {
                if (this.leads[i].status.includes(this.status)) {
                    this.$toast.error('Dieser Status existiert bereits.')
                } else {
                    this.leads[i].status.push(this.status)
                    this.status = ''
                }
            }
        },

        removeFromList(list, i) {
            if (list === 'Wurde angeworben') {
                this.$toast.error('Dieser Status kann nicht gelöscht werden.')
            } else {
                if (confirm('Möchten Sie den Status ' + list + ' wirklich löschen?')) {
                    this.leads[i].status = this.leads[i].status.filter((item) => item !== list)
                }
            }
        },

        async saveLead(item) {
            if (item.showContactInfo || item.leadFields.active) {
                if (item.studios.length > 0) {
                    let find = this.leads.filter((lead) => lead._id !== item._id)
                    console.log(find)
                    find = find.filter((lead) => lead.link === item.link)
                    console.log(find)
                    if (find.length === 0) {
                        this.loading = true
                        this.status = ''
                        let status = await api.saveLeadSetting(item)
                        console.log(status)
                        this.$toast.open('gespeichert')
                        this.loading = false
                        this.panel = []
                    } else {
                        this.$toast.error('Dieser Link existiert bereits.')
                    }
                } else {
                    this.$toast.error('Bitte wähle mindestens ein Studio aus')
                }
            } else {
                this.$toast.error('Aktiviere Kontaktinformationen oder Kontaktfelder')
            }
        },
        clearStatusInput() {
            this.status = ''
        },
        addLeadSetting() {
            let check = this.leads.filter((e) => e._id === null)

            if (check.length > 0) {
                this.$toast.error('Bitte speichere zuerst das Formular ' + check[0].title)
            } else {
                let leadSetting = {
                    _id: null,
                    active: false,
                    active_from: '',
                    active_till: '',
                    studios: [],
                    link: 'neu',
                    title: 'Neues Leadformular',
                    showContactInfo: false,
                    leadFields: {
                        active: false,
                        name: true,
                        surname: true,
                        phone: true,
                        email: true,
                        message: true,
                    },
                    description: '',
                    image: '',
                    status: [
                        'Wurde angeworben',
                        'Kontaktiert',
                        'Probetraining vereinbart',
                        'Vertrag abgeschlossen',
                        'Kein Abschluss',
                        'Geschlossen',
                    ],
                }
                this.leads.push(leadSetting)
            }
        },
        async initLeads() {
            let leadSettings = await api.fetchLeadSettings()
            if (leadSettings) {
                this.leads = leadSettings
            }
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'lead'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.leads[this.panel].image = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
    },
    computed: {
        slimOptions() {
            return {
                ratio: '16:9',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                dataJpegCompression: 70,
                instantEdit: true,
                push: true,
                initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
            }
        },
    },
    watch: {
        panel: {
            handler: function () {
                console.log(this.panel)
                this.status = ''
            },
        },
    },
}
</script>
<style lang="scss" scoped></style>
