<template>
	<image-overlapping-card setFixed no-dialog>
		<template v-slot:image>
			<v-img class="mySlides" height="35vh" src="/img/einstein/einsteinSauna1.jpg"></v-img>
			<v-img class="mySlides" height="35vh" src="/img/einstein/einsteinSauna2.jpg"></v-img>
			<v-img class="mySlides" height="35vh" src="/img/einstein/einsteinSauna3.jpg"></v-img>
		</template>
		<template v-slot:content>
			<v-row no-gutters class="formCard elevation-7 mb-4">
				<v-col class="pa-0" cols="12">
					<v-row no-gutters class="d-flex flex-row pb-0 justify-center mx-0 mb-0">
						<v-card-title style="letter-spacing: 1px" class="white--text h6 py-5 my-0 font-weight-bold text-uppercase">
							SAUNAERÖFFNUNG 5. STOCK
						</v-card-title>
					</v-row>
				</v-col>
			</v-row>
			<div v-if="alreadyEntered">
				<v-card-title v-if="entry.lost" style="word-break: break-word;"
					>Du hast heute bereits am Wettbewerb teilgenommen. Wir freuen uns, wenn du dein Glück morgen wieder versuchst.
				</v-card-title>
				<v-card-title v-else style="word-break: break-word;"
					><span class="font-weight-bold">Gratuliere!</span> <br />Dein VIP Platz ist bereits reserviert. Wir freuen uns dich
					{{ entry.guest ? 'und deine Begleitung ' : '' }}am
					<span class="font-weight-bold">{{ $moment(entry.time).format('DD.MM.YYYY HH:mm') }} Uhr </span> bei uns willkommen heissen zu
					dürfen. Gerne darfst du dich bereits 10 Minuten früher bei uns an der Theke melden.
				</v-card-title>
			</div>
			<div v-else>
				<v-col cols="12" v-if="!notTooLate">
					<v-card-title>Das Gewinnspiel ist leider vorbei.</v-card-title>
				</v-col>
				<v-col v-if="notTooLate" class="pa-2" cols="12">
					<span style="word-break: break-word;" v-if="!dateSelectionActive">
						<span class="font-weight-bold text-center">VERLOSUNG TESTZUGANG SAUNALANDSCHAFT</span> <br />
						<span class="text-align-left"
							>Drücke auf die Türe, um sie zu öffnen und schau nach, ob Du zu den Gewinnern gehörst, die unsere Sauna mit einem Cüpli
							und Häppli auf Herz und Nieren testen dürfen.</span
						>
					</span>
					<span style="word-break: break-word;" v-else>
						<span class="font-weight-bold">GLÜCKWUNSCH! DU HAST GEWONNEN</span> <br />
						Wähle nun einen Termin für Dich und eine Begleitung Deiner Wahl aus.
					</span>
				</v-col>
				<v-col v-if="notTooLate" class="pa-0" cols="12">
					<div class="door mb-10" v-if="!dateSelectionActive" @click="enterContest">
						<div class="door-front" :class="{ doorActive: entry }">
							<div class="knob"></div>
						</div>
						<div class="door-back">
							<div v-if="entry">
								<div class="steam-flow">
									<span class="sauna-text red--text font-weight-bold" v-if="entry.lost">
										Leider nicht gewonnen. Versuche Dein Glück doch morgen nochmal.
									</span>
									<div class="d-flex flex-column sauna-text" v-else>
										<span class="green--text font-weight-bold">GEWONNEN!</span>
										<span class="font-weight-bold">Reserviere gleich hier den Eintritt für dich und deine Begleitung. </span>
										<v-btn
											style="z-index: 10"
											class="mt-2 mx-auto"
											v-if="showCalendarButton"
											@click="dateSelectionActive = true"
											icon
											><v-icon>mdi-calendar</v-icon></v-btn
										>
									</div>
									<svg>
										<g>
											<path
												class="steam-cloud cloud-1"
												d="M64.2,40.6c-2.7,23.8,18,21,18.3,39.6c0.3,17.5-31.7,16.8-32.8,36c1.5,26.9,39.4,18.9,41.4,49.5c1.8,27.8-42.1,37.8-41,53.1"
											/>
										</g>
										<g>
											<path
												class="steam-cloud cloud-3"
												d="M64.2,40.6c-2.7,23.8,18,21,18.3,39.6c0.3,17.5-31.7,16.8-32.8,36c1.5,26.9,39.4,18.9,41.4,49.5c1.8,27.8-42.1,37.8-41,53.1"
											/>
										</g>
										<g>
											<path
												class="steam-cloud cloud-2"
												d="M39.2,10.5C41.3,30.2,24,27.9,24.8,43.2C25.4,55.1,50.5,58.9,50.5,73c0,19.3-29.7,20.6-31.3,45.8c-1.9,30.8,37.8,25.6,40.4,57.7"
											/>
										</g>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div v-else style="width: 100%;">
						<v-row no-gutters>
							<v-col cols="12">
								<v-card-title>Wann willst du den 5. Stock testen? </v-card-title>
							</v-col>
						</v-row>
						<v-row class="flex-nowrap mb-4 ma-auto" style="width: 100%;">
							<v-col v-for="(date, y) in calendar" :key="y">
								<v-row :class="{ 'habit__calendar-date': 1, 'justify-center': 1 }">
									<v-btn
										:id="date.date"
										icon
										@click="selectDate(y)"
										:class="{
											'elevation-7 primary white--text': date.active,
											'habit__calendar-date-btn': 1,
											active: date.active,
										}"
									>
										<span>{{ date.date }}</span>
									</v-btn>
								</v-row>
								<v-row class="justify-center habit__calendar-short__wrapper">
									<span class="habit__calendar-short mt-1">{{ date.short }}</span>
								</v-row>
							</v-col>
						</v-row>
						<v-row class="mx-0" v-if="activeDate" style="place-content: center;">
							<v-col :key="'availableTimes' + index" cols="5" v-for="(time, index) of availableSpaces[activeDate]">
								<v-card
									elevation="7"
									class="d-flex flex-column pa-3  text-center"
									:class="{ primary: time.active, 'white--text': time.active, grey: time.available < 1 }"
									@click="setTimeActive(time)"
								>
									<span style="word-break: break-word;" class="white--text" v-if="time.available < 1">
										Leider bereits alle Plätze belegt</span
									>
									<span v-else
										><strong
											>von <br />
											{{ time.start }} - {{ time.end }}Uhr</strong
										></span
									>
								</v-card>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12">
								<v-card-title>Nimmst du eine Begleitung mit? </v-card-title>
							</v-col>
							<v-col cols="12">
								<v-btn class="ml-3" @click="entry.guest = !entry.guest" :class="{ primary: entry.guest, grey: !entry.guest }">
									{{ entry.guest ? 'JA' : 'NEIN' }}
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</div>
		</template>
	</image-overlapping-card>
</template>
<script>
import api from '../../api'
import { mapState, mapGetters, mapActions } from 'vuex'
import imageOverlappingCard from '../../components/imageOverlappingCard.vue'

export default {
	components: {
		imageOverlappingCard,
	},
	data() {
		return {
			notTooLate: true,
			alreadyEntered: false,
			availableSpaces: 0,
			dateSelectionActive: false,
			activeTime: null,
			saved: true,
			entry: null,
			slideIndex: 1,
			stateInitiated: false,
			calendar: [],
			reloadTimer: null,
			showCalendarButton: false,
		}
	},
	beforeMount() {
		window.addEventListener('beforeunload', this.preventReload)
	},
	async beforeDestroy() {
		clearTimeout(this.reloadTimer)
		window.removeEventListener('beforeunload', this.preventReload)
		if (!this.saved && !this.alreadyEntered) this.deleteExistingEntry()
	},
	mounted() {
		this.animateSlider()
	},
	async created() {
		if (this.$moment().isAfter(this.$moment('2023-01-29 14:00'))) {
			this.notTooLate = false
		}
		for (let i = 0; i < 7; i++) {
			let date = this.$moment('2023-01-30').add(i, 'days')
			let ts = this.$moment('2023-01-30')
				.add(i, 'days')
				.set({
					hours: 0,
					minutes: 0,
					seconds: 0,
					miliseconds: 0,
				})
				.unix()
			this.calendar.push({
				date: date.date(),
				ts,
				short: date.format('dd'),
				active: i === 0,
			})
		}
		this.entry = await api.getEinsteinContestOwnEntry()
		if (this.entry.length === 0) this.entry = null
		if (this.entry) {
			this.alreadyEntered = true
		}
		this.setNavigationBarText({ text: 'zu Roody', state: false })
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
		preventReload(event) {
			clearTimeout(this.reloadTimer)
			if (this.entry && !this.entry.lost && !this.alreadyEntered) {
				event.preventDefault()
				event.returnValue = ''
			}
		},
		animateSlider() {
			var i
			var x = document.getElementsByClassName('mySlides')
			for (i = 0; i < x.length; i++) {
				x[i].style.display = 'none'
			}
			this.slideIndex++
			if (this.slideIndex > x.length) {
				this.slideIndex = 1
			}
			x[this.slideIndex - 1].style.display = 'block'

			setTimeout(() => this.animateSlider(), 10000)
		},
		async updateEntry() {
			return await api.updateEinsteinContestEntry({
				_id: this.entry._id,
				guest: this.entry.guest,
				time: this.$moment(this.activeTime, 'YYYY-MM-DD HH:mm'),
			})
		},
		setTimeActive(item) {
			if (item.available > 1) {
				for (const space in this.availableSpaces) {
					this.availableSpaces[space].forEach((time) => {
						if (this.activeDate === space && time.start === item.start) {
							time.active = true
							this.activeTime = this.$moment(space + ' ' + time.start, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm')
						} else {
							time.active = false
						}
					})
				}
				this.setNavigationBarText({ text: 'Platz reservieren', state: false })
			}
		},
		selectDate(index) {
			this.loading = true
			this.calendar.forEach((date, i) => {
				date.active = i === index
			})
		},
		async deleteExistingEntry() {
			await api.updateEinsteinContestEntry({ delete: true })
			this.entry = null
			this.dateSelectionActive = false
			this.alreadyEntered = false
			this.showCalendarButton = false
			this.activeTime = null
		},
		async enterContest() {
			this.reloadTimer = setTimeout(() => {
				this.$router.push('/')
			}, 600000)
			if (this.entry) return
			let data = await api.reserveEinsteinContestSpace()
			this.entry = data.ownEntry

			this.availableSpaces = data.availableSpaces
			if (this.entry && !this.entry.lost) setTimeout(() => (this.showCalendarButton = true), 3000)
			if (this.entry && !this.entry.lost) setTimeout(() => (this.dateSelectionActive = true), 8000)
		},
	},
	computed: {
		...mapState('navigationStoreNotPersisted', ['navigationBarText']),
		...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState', 'navigationBarEndPoint']),
		activeDate() {
			let date = this.calendar.find((date) => date.active)
			return this.$moment(date.ts * 1000).format('DD.MM.YYYY')
		},
	},
	watch: {
		async navigationBarTextState(value) {
			if (this.stateInitiated) {
				if (this.activeTime) {
					this.entry = await this.updateEntry()
					this.alreadyEntered = true
					this.saved = true
					this.$toast.open({
						type: 'success',
						message:
							'Dein Platz am ' +
							this.$moment(this.activeTime, 'YYYY-MM-DD HH:mm').format('DD.MM HH:mm') +
							'Uhr wurde erfolgreich reserviert!',
						duration: 7000,
					})
					this.$router.push('/')
				} else {
					if (this.entry && this.entry.lost === false && !this.alreadyEntered) {
						if (
							confirm(
								'Wenn du die Seite jetzt verlässt, geht dein Gewinn verloren. Bist du sicher, dass du die Seite verlassen willst?'
							)
						) {
							this.$router.push('/')
						} else {
							console.log('cancelled')
						}
					} else {
						this.$router.push('/')
					}
				}
			}
			this.stateInitiated = true
		},
	},
}
</script>

<style lang="scss" scoped>
.sauna-text {
	position: absolute;
	width: 90%;
	left: 5%;
	text-align: center;
	font-size: 18px;
	top: 10%;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
	padding: 2px;
}
.steam {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 200px;
	justify-content: center;
	width: 200px;
}

svg {
	display: flex;
	flex-direction: column;
	justify-content: center;
	filter: blur(25px);
	overflow: visible;
}

.steam-cloud {
	fill: none;
	stroke: #f7f7f7;
	stroke-width: 200;
	stroke-linecap: round;
	opacity: 0%;
	z-index: 10;
}

.cloud-1 {
	z-index: 15;
	display: block;
	stroke: white;
	stroke-dasharray: 200;
	stroke-dashoffset: 0;
	animation: steamCloud;

	animation-duration: 3s;
	animation-timing-function: ease-in;
}

.cloud-2 {
	z-index: 15;
	display: block;
	stroke: white;
	stroke-dasharray: 200;
	stroke-dashoffset: 0;
	animation: steamCloud;
	animation-timing-function: ease-in;
	animation-duration: 3s;
}

.cloud-3 {
	z-index: 15;
	display: block;
	stroke: white;
	stroke-dasharray: 200;
	stroke-dashoffset: 0;
	animation: steamCloud;
	animation-timing-function: ease-in;
	animation-duration: 3s;
}
@keyframes steamCloud {
	0% {
		stroke-dashoffset: 50;
		opacity: 1;
	}
	30% {
		stroke-dashoffset: 100;
		opacity: 0.9;
	}
	60% {
		stroke-dashoffset: 150;
		opacity: 0.8;
	}
	80% {
		opacity: 0.6;
	}
	100% {
		opacity: 0.1;
		z-index: 0;
	}
}

.formCard {
	background: #313131;
	margin: -12px 0px;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
}
.formTitle {
	background-color: #313131;
	color: white !important;
}
.door {
	margin: auto;
	position: relative;
	width: 170px;
	height: 270px;
	cursor: pointer;
	transform-style: preserve-3d;
	transform: perspective(2500px);
}

.doorActive {
	transform: rotateY(-160deg);
}
.door-front {
	width: 170px;
	height: 250px;
	top: 10px;
	overflow: hidden;
	transform-origin: left;
	box-shadow: 30px 0 50px rgba(0, 0, 0, 0.2);
	position: absolute;
	background-image: url('/img/einstein/wood.webp');
	background-color: #924500;
	z-index: 1;
	transition: 0.8s;
}

.door-front:before {
	top: 25px;
}

.door-front:after {
	top: 155px;
}
.knob {
	position: absolute;
	width: 20px;
	height: 30px;
	background-color: #eeba0b;
	top: 122px;
	left: 145px;
	border-radius: 2px;
}

.knob:before {
	content: '';
	position: absolute;
	border-radius: 50%;
	background-color: #f1c83c;
	width: 18px;
	height: 18px;
	left: -1px;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.knob:after {
	content: '';
	position: absolute;
	width: 4px;
	height: 7px;
	background-color: #333;
	top: 20px;
	left: 8.5px;
}

.door-back {
	position: relative;
	background-image: url('/img/einstein/doorBack.png');

	width: 100%;
	height: 100%;
	overflow: hidden;
	border-width: 10px;
	border-style: solid;
	background-size: cover;
	border-image: url('/img/einstein/wood.webp') 50;
}
</style>
