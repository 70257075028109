<template>
    <v-container style="position: relative">
        <grid-layout
            class="paper"
            :layout.sync="layout"
            :col-num="20"
            :row-height="13"
            :maxRows="65"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="false"
            :margin="[0, 0]"
            :use-css-transforms="true">
            <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i">
                <h6 v-if="item.type === 'title'">{{ item.text }}</h6>
                <span v-else> {{ item.text }}</span>
            </grid-item>
        </grid-layout>
        <div class="sidepanel">
            <v-card>
                <v-card-title>Vertrag</v-card-title>
                <v-card-text>Felder</v-card-text>
                <draggable class="list-group" tag="ul" v-model="layout">
                    <transition-group type="transition" :name="'flip-list'">
                        <li class="list-group-item" v-for="item in layout" :key="item.i">
                            <i aria-hidden="true"></i>
                            {{ item.title }}
                        </li>
                    </transition-group>
                </draggable>
                <v-btn @click="addText" color="primary">Text hinzufügen</v-btn>
            </v-card>
        </div>
    </v-container>
</template>
<script>
import api from '../../../api'
import VueGridLayout from 'vue-grid-layout'
import draggable from 'vuedraggable'
export default {
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        draggable,
    },
    data() {
        return {
            layout: [],
        }
    },
    created() {
        this.initTest()
    },
    methods: {
        addText() {
            this.layout.push({
                x: 0,
                y: this.layout.length,
                w: 10,
                h: 2,
                i: this.layout.length.toString(),

                title: 'Text',
                text: 'Text',
            })
        },
        initTest() {
            let formFields = [
                { inputValue: 'Mitgliedschaftsvertrag well come FIT AG', inputType: 'title', identifier: 'Titel' },
                {
                    inputValue: [
                        {
                            title: 'Erneuerung',
                            additionalInfo: null,
                            notes: false,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 0,
                        },
                        {
                            title: 'Ehemalig',
                            additionalInfo: null,
                            notes: false,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 1,
                        },
                        {
                            title: 'Neumitglied',
                            additionalInfo: 'Chip vorhanden? (Ja / Nein)',
                            notes: true,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 2,
                        },
                    ],
                    inputType: 'category',
                    identifier: 'Mitgliedschaftskategorien',
                },
                {
                    inputValue: [
                        {
                            name: 'well come FIT AG',
                            street: 'Walzmühlestrasse 50',
                            city: '8500 Frauenfeld',
                            phone: '052 721 36 96',
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 0,
                        },
                    ],
                    inputType: 'company',
                    identifier: 'Firma - Vertragspartner',
                },
                {
                    inputValue: {
                        firstname: { identifier: 'Vorname', active: true, value: 'Max' },
                        lastname: { identifier: 'Nachname', active: true, value: 'Mustermann' },
                        street: { identifier: 'Strasse', active: true, value: 'Hauptstrasse 3' },
                        city: { identifier: 'PLZ / Ort', active: true, value: '8500 Frauenfeld' },
                        birthday: { identifier: 'Geburtstag', active: true, value: 'Geburtstag' },
                        phone: { identifier: 'Tel. P.', active: true, value: '000 000 00 00' },
                        phoneMobile: { identifier: 'Mobile', active: true, value: '000 000 00 00' },
                        phoneBusiness: { idenfitier: 'Tel. G.', active: true, value: '000 000 00 00' },
                        email: { identifier: 'E-Mail', active: true, value: 'vertrag@wellcomefit.ch' },
                    },
                    inputType: 'customer',
                    identifier: 'Kundeninformationen',
                },
                {
                    inputValue: [
                        {
                            title: 'Kombi Card Fitness & Group Fitness',
                            signUpFee: 100,
                            price: 599,
                            duration: { years: 1, months: 0, days: 0 },
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 0,
                        },
                        {
                            title: 'Group Fitness Card',
                            price: 690,
                            signUpFee: 100,
                            duration: { years: 1, months: 0, days: 0 },
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 1,
                        },
                        {
                            title: 'Wellness Spa Card',
                            price: 540,
                            signUpFee: 100,
                            duration: { years: 1, months: 0, days: 0 },
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 2,
                        },
                    ],
                    inputType: 'membership',
                    identifier: 'Mitgliedschaften',
                },
                {
                    inputValue: [
                        {
                            title: 'Bar',
                            additionalInfo: '',
                            selectDate: true,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 0,
                        },
                        {
                            title: 'EC-direkt / Postcard',
                            additionalInfo: '',
                            selectDate: true,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 1,
                        },
                        {
                            title: 'Eurocard / Visa',
                            additionalInfo: '',
                            selectDate: true,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 2,
                        },
                        {
                            title: 'Einzahlungsschein',
                            additionalInfo:
                                'innert 5 Tagen nach Datum des Vertragsabschlussses. Rechnungen werden per E-Mail versandt.',
                            selectDate: false,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 3,
                        },
                    ],
                    inputType: 'paymentOptions',
                    identifier: 'Zahlungsbedingungen',
                },
                {
                    inputValue: [
                        {
                            title: 'Getränke',
                            amountSelectable: false,
                            price: 599,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 0,
                        },
                        {
                            title: 'Solarium',
                            amountSelectable: false,
                            price: 690,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 1,
                        },
                        {
                            title: 'Power Plate',
                            amountSelectable: false,
                            price: 540,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 2,
                        },
                        {
                            title: 'Kinderhort pro Kind',
                            price: 540,
                            amountSelectable: true,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 3,
                        },
                        {
                            title: 'Massage-Hydrojet',
                            amountSelectable: false,
                            price: 540,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 4,
                        },
                        {
                            title: 'Wellness/Spa',
                            amountSelectable: false,
                            price: 540,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 5,
                        },
                        {
                            title: 'slim ME',
                            amountSelectable: false,
                            price: 540,
                            studios: ['5f326a24ae8863640c859e75'],
                            index: 6,
                        },
                    ],
                    inputType: 'packages',
                    identifier: 'Pakete',
                },
                {
                    inputValue: [
                        {
                            inputValue:
                                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
                            highlight: false,
                        },
                    ],
                    inputType: 'conditions',
                    identifier: 'Mitgliedschaftsbedingungen',
                },
            ]

            formFields.forEach((f) => {
                console.log(f.inputType)
                console.log(f.inputValue)
                let entry = {
                    x: 0,
                    y: this.layout.length,
                    w: 10,
                    h: 2,
                    i: this.layout.length.toString(),
                    type: '',
                    title: '',
                    text: '',
                }
                if (f.inputType === 'title') {
                    entry.title = f.identifier
                    entry.text = f.inputValue
                    entry.type = f.inputType
                }
                if (f.inputType === 'category') {
                    entry.title = f.identifier
                    entry.text = f.inputValue[0].title
                    entry.type = f.inputType
                }
                if (f.inputType === 'company') {
                    entry.title = f.identifier
                    for (let key in f.inputValue[0]) {
                        if (key !== 'index' && key !== 'studios') entry.text += f.inputValue[0][key] + ', '
                    }
                    entry.type = f.inputType
                }
                if (f.inputType === 'customer') {
                    entry.title = f.identifier
                    for (let key in f.inputValue) {
                        if (key !== 'index' && key !== 'studios') entry.text += f.inputValue[key].value + ', \n'
                    }
                    entry.type = f.inputType
                }
                if (f.inputType === 'membership') {
                    entry.title = f.identifier
                    entry.text = f.inputValue[0].title
                    entry.type = f.inputType
                }
                if (f.inputType === 'packages') {
                    entry.title = f.identifier
                    entry.text = f.inputValue[0].title
                    entry.type = f.inputType
                }
                if (f.inputType === 'paymentOptions') {
                    entry.title = f.identifier
                    entry.text = f.inputValue[0].title
                    entry.type = f.inputType
                }
                /*if (f.inputType === 'conditions') {
					entry.title = f.identifier
					entry.text = f.inputValue[0].inputValue
					entry.type = f.inputType
				}*/

                this.layout.push(entry)
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.paper {
    background-color: white;
    border: 1px solid #ccc;

    width: 595px;
    height: 842px !important;

    font-size: 13px !important;
}
.sidepanel {
    position: fixed;
    top: 100px;
    right: 100px;
    width: 300px;
    height: 100%;
}
</style>
