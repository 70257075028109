<template>
	<div class="kurs-anmeldung">
		<div class="back-navigation">
			<router-link class="backlink" :to="{ path: '/kurse', query: getScrollPos() }">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Kurs Details</span>
			<div v-show="userService.hasRight('gf_cp_edit', 'gf_cp_cancel')" class="edit-kurs">
				<span @click.prevent="editKurs" class="material-icons">edit</span>
			</div>
		</div>

		<div v-show="loading" class="loading"><img src="\img\icons\loading.gif" />Kursinformationen werden geladen..</div>

		<div class="kurs-header-wrapper" id="khw">
			<div
				v-bind:style="{
					backgroundImage:
						'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(https://app.wellcomefit.ch/img/kurse/' + course.bild + ')',
				}"
				v-show="!loading"
				class="kurs-header"
			>
				<img v-show="insert_img.wellcomefit" class="insert-wcf" src="https://app.wellcomefit.ch/img/icons/well_come_fit_insert_white.png" />
				<img v-show="insert_img.wintifit" class="insert-wcf" src="https://app.wellcomefit.ch/img/icons/winti_fit_insert_white.png" />
				<div class="kurs-info-wrapper">
					<div class="kurs-name">{{ course.name }}</div>

					<div class="kurs-zeit">{{ datum }} | {{ TimestampToTime(course_timeline.timestamp) }} UHR | {{ dauer }} MIN</div>
					<div class="raum-name">{{ course_timeline.room_name }}</div>

					<div v-show="!labels.filter((label) => label.code == 'SOMMERPAUSE').length > 0" class="raum-platz">
						{{ course_timeline.course_free_spaces }}
					</div>
				</div>
			</div>
		</div>
		<div v-show="userService.hasRight('gf_cp_downloadImage')" class="label">
			Bilder Download
		</div>
		<div v-show="userService.hasRight('gf_cp_downloadImage')" class="bilder-download-wrapper">
			<div class="bilder-download" @click="downloadBild(800, 800)">
				<span>INSTAGRAM / FACEBOOK POST<br />(800x800px)</span>
			</div>
			<div class="bilder-download" @click="downloadBild(1080, 1920)">
				<span>INSTAGRAM / FACEBOOK STORY<br />(1080x1920px)</span>
			</div>
		</div>

		<div v-show="(instructor_visiting || userService.hasRight('gf_r_viewAll')) && !loading" class="ratings">
			<div class="label">Bewertungen</div>
			<div v-for="(rating, i) of ratings" :key="i" class="rating">
				<div class="rating-user">
					{{ rating.user }}
					<span class="ls_rating" v-show="rating.ls_bewertung">Livestream</span>
				</div>
				<div class="rating-stars">
					<i v-for="(yikes, y) in rating.stars" :key="y" class="fa fa-2x rating-star fa-star selected"></i>
				</div>
				<div class="rating-rating">
					{{ rating.rating }}
				</div>
			</div>
			<div class="no-ratings" v-show="!ratings.length > 0">
				Momentan gibt es noch keine Bewertungen
			</div>
		</div>

		<div v-show="(instructor_visiting || userService.hasRight('gf_cp_viewSignups', 'gf_cp_manageSignups')) && !loading" class="registrations">
			<div class="label">Angemeldete Benutzer</div>
			<div v-show="userService.hasRight('gf_cp_manageSignups') && !loading" @click="showusersignin = !showusersignin" class="signin-user">
				<span class="material-icons">person_add</span> Kunde für diesen Kurs anmelden
			</div>

			<div v-show="showusersignin" class="user-signin">
				<user-search
					:ignoreRights="instructor_visiting"
					rights="gf_cp_manageSignups"
					label="Kunde suchen"
					@update="updateCustomer"
					returnEmail
				></user-search>
				<div class="user-sign-in-action">
					<div @click="signinuser" class="sign-in-user">Benutzer anmelden</div>
					<div @click="showusersignin = !showusersignin" class="sign-in-user-cancel">
						Abbrechen
					</div>
				</div>
			</div>

			<div
				v-show="(instructor_visiting || userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups')) && !loading"
				class="anmeldung"
				v-for="(anmeldung, i) of anmeldungen"
				:key="i"
			>
				<span @click="showUserInfo(anmeldung)">{{ anmeldung.user.vorname }} {{ anmeldung.user.nachname }}</span>
				<span class="warteliste-icon" v-if="warteliste_array[anmeldung.user._id]">
					<span class="material-icons">pending_actions</span>
				</span>
				<div class="sign-off d-flex">
					<span class="mr-2" v-if="anmeldung.user.eventId"> NEU! </span>
					<div @click="signoffuser(anmeldung.user.email)">
						<span
							class="material-icons user_verified mr-2"
							style="color:#52ae32"
							v-show="anmeldung.user.certChecked == true && anmeldung.user.certInvalid == false"
							>verified_user</span
						>
						<span class="material-icons mr-2">person_remove</span>
					</div>
					<input @change="updateTeilgenommen(anmeldung)" type="checkbox" v-model="anmeldung.anmeldung.teilgenommen" />
				</div>
			</div>
		</div>
		<div
			v-show="abmeldungen.length > 0 && (instructor_visiting || userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups')) && !loading"
			class="registrations"
		>
			<div class="label">Abgemeldete Benutzer</div>
			<div class="anmeldung" v-for="(abmeldung, i) of abmeldungen" :key="i">
				<span @click="showUserInfo(abmeldung)">{{ abmeldung.user.vorname }} {{ abmeldung.user.nachname }}</span>
			</div>
		</div>

		<covid_-group
			:key="refreshableKey"
			v-if="course_timeline.course_timeline_id && userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups')"
			:zeitplan-id="course_timeline.course_timeline_id"
		></covid_-group>
		<div v-show="covidQueue.length > 0 && userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups') && !loading" class="registrations">
			<div class="label">Benutzer auf Covid Warteliste</div>

			<div class="anmeldung" v-for="(user, i) of covidQueue" :key="i">
				<span @click="showUserInfo(user)"> {{ user.vorname }} {{ user.nachname }} </span>
				<div class="sign-off">
					<v-icon class="user_verified mr-2" @click="moveFromQueueToCovidList(user)">mdi-plus</v-icon>
				</div>
			</div>
		</div>

		<div
			class="registrations"
			v-show="
				getLSLength(livestream_anmeldungen) &&
					(instructor_visiting || userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups')) &&
					!loading
			"
		>
			<div class="label">Livestream Anmeldungen</div>
			<div
				v-show="instructor_visiting || userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups')"
				class="anmeldung"
				v-for="(anmeldung, i) in livestream_anmeldungen"
				:key="i"
			>
				<span @click="showUserInfo(anmeldung)">{{ anmeldung.vorname }} {{ anmeldung.nachname }}</span>
			</div>
		</div>

		<div
			v-show="!loading && (zeitfenster_anmeldung || angemeldet) && !labels.filter((label) => label.code == 'SOMMERPAUSE').length > 0"
			class="label"
		>
			Anmeldung
		</div>

		<!---
    <div
      v-show="anmeldungsinfo"
      style="
    background-color: #ff0000b0;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 19px;
    text-align: center;
"
    >
      ❗ ACHTUNG ❗ <br />Livestream Kurse unter Einstellungen - "Studio
      auswählen" - "ONLINE TRAINING"
    </div>---->

		<div
			v-show="
				!isOnCovidQueue &&
					!angemeldet &&
					!loading &&
					zeitfenster_anmeldung &&
					!labels.filter((label) => label.code == 'SOMMERPAUSE').length > 0
			"
			class="anmeldung-status"
		>
			<div v-show="signinstatus" @click="signup()" class="anmelden">
				Jetzt anmelden
			</div>
			<div class="anmelden" v-show="!signinstatus">
				Sie werden angemeldet...
			</div>
		</div>
		<div v-show="zeitfenster_anmeldung && !isOnCovidQueue && angemeldet && !warteliste && !loading" class="anmeldung-status">
			<div v-show="signoffstatus" @click="signoff()" class="abmelden">
				Jetzt abmelden
			</div>
			<div v-show="!signoffstatus" class="abmelden">
				Sie werden abgemeldet...
			</div>
		</div>

		<div v-show="!isOnCovidQueue && angemeldet && warteliste && !loading" class="anmeldung-status">
			<span class="status"
				>Sie sind für diesen Kurs auf der Warteliste eingetragen. Wir benachrichtigen Sie per Mail, sobald ein Platz freiwurde.</span
			>
			<div @click="signoff()" class="abmelden">Jetzt austragen</div>
		</div>
		<div v-show="isOnCovidQueue && !warteliste && !loading" class="anmeldung-status">
			<span class="status"
				>Besten Dank für Deine Anmeldung in eine beständige Groupfitness Gruppe. Wir prüfen Deine Anmeldung und geben Dir umgehend Bescheid.
				Dein Anmeldestatus ist nach der Bearbeitung umgehend auf der Kursliste für Dich ersichtlich.</span
			>
			<div @click="signoffCovidList()" class="abmelden">Jetzt austragen</div>
		</div>
		<div v-show="!loading" class="helsana">
			<router-link to="/kampagne/60d4a41a2e2f056a08945585" tag="div" class="link">
				<img src="/img/einmalig/helsana_new.png" />
			</router-link>
		</div>
		<div v-show="!loading" class="label">Instruktor</div>
		<div v-show="!loading" class="kurs-instruktor kursinfo">
			{{ course_timeline.instructor_name }}
		</div>
		<div v-if="(labels.length > 0 && !loading) || (userService.hasRight('gf_cp_edit') && !loading)" class="label">
			Anmerkungen
		</div>
		<div class="anmerkungen" v-show="!userService.hasRight('gf_cp_edit') && !loading" v-for="(label, i) of labels" :key="i">
			{{ label.text }}
		</div>

		<div class="labels-editor" v-show="userService.hasRight('gf_cp_edit') && !loading">
			<select v-model="labels_selected" v-if="labels_all.length > 0" class="mul-select" multiple="true" @change.prevent="changeLabels()">
				<option v-for="(label, j) of labels_all" :key="j" :value="label._id">
					{{ label.code }}
				</option>
			</select>
			<div @click="editLabels" class="edit-labels">Labels bearbeiten</div>
		</div>
		<div v-show="!loading" class="label">Beschreibung</div>
		<div v-show="!loading" class="kurs-beschreibung kursinfo">
			{{ course.beschreibung }}
		</div>
		<kurs-editor v-on:kurs-edited="loadKursafteredit" ref="kurseditor"></kurs-editor>
		<labeledit ref="labeleditor" v-on:labels-edited="loadLabels"></labeledit>
		<div
			v-show="showUserInfoCheck && (instructor_visiting || userService.hasRight('gf_cp_manageSignups', 'gf_cp_viewSignups'))"
			class="show-user-info"
		>
			<div class="show-user-info-wrapper">
				<span> Angemeldet am:<br />{{ TimestampToDate(showuser.datum) }}</span>

				<span v-show="showuser.abgemeldet"> Abgemeldet am:<br />{{ TimestampToDate(showuser.abgemeldet) }}</span>
				<span>{{ showuser.name }}</span>
				<span>{{ showuser.email }}</span>
				<span>{{ showuser.tel }}</span>
				<span v-show="showuser.certChecked">Zertifikat gültig erklärt am:</span>
				<span v-show="showuser.certChecked">{{ showuser.certChecked }}</span>
			</div>

			<div class="schliessen" @click="showUserInfoCheck = false">
				Schliessen
			</div>
		</div>
		<nav class="button-share-container">
			<a
				target="_blank"
				:href="'https://twitter.com/intent/tweet?text=' + sharetext + '&url=' + getUrl()"
				class="buttons"
				tooltip="Auf Twitter teilen"
			>
				<i class="fa fa-twitter"></i>
			</a>

			<a target="_blank" :href="'mailto:?subject=' + '' + '&body=' + sharetext + getUrl()" class="buttons" tooltip="Per E-Mail teilen">
				<i class="fa fa-envelope"></i>
			</a>

			<a
				target="_blank"
				:href="'https://www.linkedin.com/shareArticle?mini=true&url=' + sharetext + getUrl()"
				class="buttons"
				tooltip="Auf LinkedIn teilen"
			>
				<i class="fa fa-linkedin-square"></i>
			</a>

			<a
				target="_blank"
				:href="'https://www.facebook.com/sharer/sharer.php?u=' + sharetext + getUrl()"
				class="buttons"
				tooltip="Auf Facebook teilen"
			>
				<i class="fa fa-facebook"></i>
			</a>
			<a target="_blank" :href="'https://api.whatsapp.com/send?text=' + sharetext + getUrl()" class="buttons" tooltip="Per Whatsapp teilen">
			</a>
			<a class="buttons" href="#" @click="toggleSharing">
				<span class="buttons" tooltip="Teilen">
					<span class="rotate">
						<span class="material-icons"> share </span>
					</span>
				</span>
			</a>
		</nav>

		<canvas id="bilderdownload" v-show="false"> </canvas>
	</div>
</template>

<script>
import api from '../../api'
//import { EventBus } from "../../event-bus";
import KursEditor from '../../components/KursEditor'
import Labeledit from '../../components/Label'
import $ from 'jquery'
import { saveAs } from 'file-saver'
//import { google, outlook, office365, yahoo, ics } from "calendar-link";
import userService from '../../services/userService'
import Covid_Group from './Covid_Group'
import userSearch from '../../components/userSearch.vue'

export default {
	components: { Covid_Group, KursEditor, Labeledit, userSearch },
	data() {
		return {
			userService: userService,
			refreshableKey: 1,
			isOnCovidQueue: false,
			event: {},
			insert_img: {
				wintifit: false,
				wellcomefit: false,
			},

			anmeldungsinfo: false,

			loading: true,
			showuser: {
				name: '',
				email: '',
				tel: '',
				datum: '',
				certChecked: '',
			},
			kundenergebnisse: [],
			user_array: [],
			ratings: [],
			instructor_visiting: false,
			signinstatus: true,
			signoffstatus: true,
			users: [],
			users_filtered: [],
			kundensuche: '',
			adduser: 1,
			datum: '',
			dauer: '',
			angemeldet: false,
			isAdmin: false,
			livestream_anmeldungen: [],
			anmeldungen: [],
			abmeldungen: [],
			labels_all: [],
			sharetext: '',
			warteliste: false,
			warteliste_array: [],
			covidQueue: [],
			api: {
				course_timeline: 'test',
				course: 'test',
			},
			course_timeline: {
				course_free_spaces: '',
				course_id: '',
				course_name: '',
				instructor_name: '',
				room_name: '',
				studio_id: '',
				timestamp: '',
				timestamp_end: '',
				_id: '',
			},
			course: {
				_id: '',
				plaetze: '',
				beschreibung: '',
				bild: '',
				name: '',
				video: '',
			},
			rollen: {},
			x: null,
			y: null,
			showusersignin: false,
			labels_tmp: [],
			labels_selected: [],
			labels_selected_tmp: [],
			labels: [],
			showUserInfoCheck: false,
			showSharing: false,
			zeitfenster_anmeldung: false,
		}
	},
	metaInfo() {
		return {
			title: `Anmeldung`,
			meta: [
				{
					property: 'og:description',
					content:
						this.course.name +
						' am ' +
						this.datum +
						', ' +
						this.TimestampToTime(this.course_timeline.timestamp) +
						' | ' +
						this.dauer +
						'min bei ' +
						this.course_timeline.instructor_name,
				},
				{ property: 'og:type', content: 'website' },
				{ property: 'og:url', content: location.href },
				{
					property: 'og:image',
					content: location.origin + '/img/kurse/' + this.course.bild,
				},
			],
		}
	},
	destroyed() {
		window.removeEventListener('resize', this.updateHeight)
	},
	methods: {
		updateCustomer(email) {
			this.adduser = email
		},
		signoffCovidList() {
			api.signoffCovidQueue(this.course_timeline._id).then((data) => {
				this.covidQueue = this.covidQueue.filter((queueUser) => queueUser._id !== data)

				this.isOnCovidQueue = false
			})
		},
		fetchCovidQueue() {
			try {
				api.loadCovidQueue().then((queue) => (this.isOnCovidQueue = queue.includes(this.$route.params.id)))
			} catch (e) {
				console.log('Fehler beim Laden der Covid Queue')
			}

			try {
				api.fetchCovidQueueForCourse(this.$route.params.id).then((data) => (this.covidQueue = data))
			} catch (e) {
				console.log('Fehler beim Laden der Covid Queue für den Kurs')
			}
		},
		moveFromQueueToCovidList(user) {
			api.moveFromQueueToCovidGroup(this.course_timeline.course_timeline_id, user._id, this.course_timeline._id)
				.then((data) => {
					this.adduser = data
					this.signinuser()
					this.refreshableKey += 1
					this.covidQueue = this.covidQueue.filter((queueUser) => queueUser._id !== user._id)
				})
				.catch((error) =>
					alert(
						'Es muss zuerst ein Benutzer von der Covid Liste entfernt werden um einen neuen hinzufügen zu können. Die Liste ist auf 30 Personen beschränkt.'
					)
				)
		},
		getLSLength(ls) {
			let counter = 0

			for (let i in ls) {
				counter++
			}
			if (counter > 0) {
				return true
			} else {
				return false
			}
		},
		getLinkGoogle() {
			// return google(this.event);
			return ''
		},
		getLinkICS() {
			//return ics(this.event);
			return ''
		},
		getLinkOffice365() {
			// return office365(this.event);
			return ''
		},
		getLinkYahoo() {
			// return yahoo(this.event);
			return ''
		},
		getLinkOutlook() {
			// return outlook(this.event);
			return ''
		},
		initializeEvent() {
			let date = this.datum.substring(3).split('.')
			if (date[0] < 10) {
				date[0] = '0' + date[0]
			}
			if (date[1] < 10) {
				date[1] = '0' + date[1]
			}

			let loc = ''
			if (location.host.includes('winti')) {
				loc = 'winti FIT'
			} else if (location.host.includes('wellcomefit')) {
				loc = 'well come FIT'
			} else if (location.host.includes('fzw')) {
				loc = 'Fitness Zenter Wetzikon'
			} else {
				loc = 'Fitnesszenter'
			}
			/*
      this.event = {
        title: this.course.name,
        description: this.course.beschreibung,
        location: loc,
        start:
          date[2] +
          "-" +
          date[1] +
          "-" +
          date[0] +
          " " +
          this.TimestampToTime(this.course_timeline.timestamp) +
          ":00 +0200",
        duration: [this.dauer, "minute"],
      };

      console.log(this.event);*/
		},
		downloadBild(w, h) {
			let ref = this

			var c = document.getElementById('bilderdownload')
			c.setAttribute('width', w)
			c.setAttribute('height', h)
			c.style.letterSpacing = '-1px'

			var ctx = c.getContext('2d')
			ctx.clearRect(0, 0, w, h)
			ctx.lineWidth = 3

			var img = new Image()
			/* img.crossOrigin = "Anonymous"; */
			img.src = '/img/kurse/' + ref.course.bild

			img.onload = function() {
				let width = 0
				let height = 0
				let x = 0
				let y = 0
				if (img.naturalHeight > img.naturalWidth) {
					width = (h / img.naturalHeight) * img.naturalWidth
					height = h
					x = w / 2 - width / 2
				} else {
					height = (w / img.naturalWidth) * img.naturalHeight
					width = w
					y = h / 2 - height / 2
				}
				ctx.fillStyle = '#52ae32'
				ctx.fillRect(0, 0, w, h)
				ctx.drawImage(img, x, y, width, height)
				var img_insert = new Image()
				if (ref.insert_img.wellcomefit) img_insert.src = 'https://app.wellcomefit.ch/img/icons/well_come_fit_insert_white.png'
				if (ref.insert_img.wintifit) img_insert.src = 'https://app.wintifit.ch/img/icons/winti_fit_insert_white.png'
				console.log('loading image')
				img_insert.onload = function() {
					if (h == w) {
						ctx.drawImage(img_insert, 200, 20, 400, 100)
						ctx.font = '600 70px Helvetica, Arial, sans-serif'
					} else {
						ctx.drawImage(img_insert, 140, 40, 800, 200)
						ctx.font = '600 80px Helvetica, Arial, sans-serif'
					}

					ctx.fillStyle = 'white'
					ctx.strokeStyle = 'black'
					ctx.textAlign = 'center'
					ctx.fillText(ref.course.name, c.width / 2, c.height / 2 - 60)
					ctx.strokeText(ref.course.name, c.width / 2, c.height / 2 - 60)
					if (h == w) {
						ctx.font = '600 50px Helvetica, Arial, sans-serif'
					} else {
						ctx.font = '600 60px Helvetica, Arial, sans-serif'
					}

					ctx.fillText(
						ref.datum + '|' + ref.TimestampToTime(ref.course_timeline.timestamp) + ' UHR | ' + +ref.dauer + ' MIN',
						c.width / 2,
						c.height / 2 + 0
					)
					ctx.strokeText(
						ref.datum + '|' + ref.TimestampToTime(ref.course_timeline.timestamp) + ' UHR | ' + +ref.dauer + ' MIN',
						c.width / 2,
						c.height / 2 + 0
					)
					ctx.fillText(ref.course_timeline.room_name, c.width / 2, c.height / 2 + 60)
					ctx.strokeText(ref.course_timeline.room_name, c.width / 2, c.height / 2 + 60)

					ctx.fillText(ref.course_timeline.course_free_spaces, c.width / 2, c.height / 2 + 120)
					ctx.strokeText(ref.course_timeline.course_free_spaces, c.width / 2, c.height / 2 + 120)
					c.toBlob(function(blob) {
						saveAs(blob, 'bild.png')
					})
				}
			}
		},

		updateHeight() {
			var square = $('#khw')
			var size = square.width()

			square.css('height', size)
		},
		getUrl() {
			return location.href
		},
		toggleSharing() {
			this.showSharing = !this.showSharing
			if (this.showSharing) {
				$('.buttons').addClass('show-buttons')
			} else {
				$('.buttons').removeClass('show-buttons')
			}
		},
		getScrollPos() {
			return { scrollpos: this.$route.params.id }
		},
		showUserInfo(anmeldung) {
			let anmeldedaten = {}
			let anmeldedaten_a = {}
			if (anmeldung.user) {
				anmeldedaten = anmeldung.user
				anmeldedaten_a = anmeldung.anmeldung
			} else {
				anmeldedaten = anmeldung
				anmeldedaten_a = anmeldung
			}

			this.showuser.name = anmeldedaten.vorname + ' ' + anmeldedaten.nachname
			this.showuser.email = anmeldedaten.email
			this.showuser.datum = anmeldedaten_a.datum
			this.showuser.tel = anmeldedaten.telefon

			if (anmeldedaten.certCheckedDate && !anmeldedaten.certInvalid) {
				let date = new Date(parseInt(anmeldedaten.certCheckedDate) * 1000)
				let minutes = date.getMinutes()
				let hours = date.getHours()
				if (minutes < 10) {
					minutes = '0' + minutes
				}
				if (hours < 10) {
					hours = '0' + hours
				}
				this.showuser.certChecked = date.toLocaleDateString('de-CH') + ' ' + hours + ':' + minutes
			} else {
				this.showuser.certChecked = null
			}

			this.showuser.abgemeldet = anmeldedaten_a.abgemeldet
			this.showUserInfoCheck = true
		},
		changeLabels() {
			api.changeLabels({
				timeline_id: this.course_timeline.course_timeline_id,
				label_ids: this.labels_selected,
			})
				.then((data) => {
					this.loadKurs()
				})
				.catch((error) => {
					console.log(error)
				})
		},
		editLabels() {
			this.$refs['labeleditor'].show(this.labels_all)
		},
		loadLabels() {
			api.getLabels(this.$route.params.id)
				.then((data) => {
					this.labels_all = data
				})
				.catch((error) => {})
		},
		filterKunden() {
			if (this.kundensuche.length > 2) {
				api.searchUserforRolle({ search: this.kundensuche }).then((data) => {
					this.kundenergebnisse = data
				})
			}
		},
		signinuser() {
			if (this.adduser == 1) {
				alert("Bitte wählen Sie einen Kunden aus der Auswahl 'Kunde auswählen' aus")
			} else {
				let already = false
				for (let an of this.anmeldungen) {
					if (an.user.email == this.adduser) {
						already = true
						break
					}
				}
				if (already) {
					alert('Benutzer ist bereits angemeldet...')
				} else {
					let signupdata = {
						course_generate_id: this.course_timeline._id,
						ben_id: this.adduser,
						datum: Math.round(Date.now() / 1000),
					}

					api.signUpUserForKurs(signupdata)
						.then((data) => {
							if (data.status == 2) {
								alert('Du hast dich bereits für einen Kurs angemeldet, welcher zur gleichen Zeit stattfindet.')
							}
							this.loadKurs()
							this.loadSignups()

							this.loadSignUpStatus()
						})
						.catch((error) => {
							alert('fehler')
						})
					this.showusersignin = false
				}
			}
		},
		signoffuser(usermail) {
			if (confirm('Wollen Sie den Benutzer abmelden?')) {
				let signupdata = {
					course_generate_id: this.course_timeline._id,
					ben_id: usermail,
				}

				api.signOffUserKursStatus(signupdata)
					.then((data) => {
						this.loadSignups()
						this.loadKurs()
						this.loadSignUpStatus()
					})
					.catch((error) => {
						alert('fehler')
					})
			}
		},
		shallowEqual(object1, object2) {
			const keys1 = Object.keys(object1)
			const keys2 = Object.keys(object2)

			if (keys1.length !== keys2.length) {
				return false
			}

			for (let key of keys1) {
				if (object1[key] !== object2[key]) {
					return false
				}
			}

			return true
		},
		loadKursafteredit() {
			this.loadKurs()
		},
		updateTeilgenommen(data) {
			api.kursTeilgenommen(data)
				.then((data) => {
					if (data.status) {
						console.log('changed')
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		editKurs() {
			this.$refs['kurseditor'].show(this.course_timeline)
		},
		async loadSignups(auto = false) {
			this.fetchCovidQueue()
			let anmeldungen_temp = []
			let abmeldungen_temp = []
			let data_tmp = {}
			let userid = await api.getUserid()
			userid = userid._id

			this.instructor_visiting = this.course_timeline.instructor_id === userid

			let special_rights = false

			if (this.instructor_visiting || userService.hasRight('gf_cp_viewSignups', 'gf_cp_manageSignups', 'gf_cp_manageVisited')) {
				try {
					let data = await api.loadSignups(this.course_timeline._id)

					let lsdata = await api.loadLSUserSignups(this.course_timeline._id)
					this.livestream_anmeldungen = lsdata

					data_tmp.signups = data.signups
					let ben_id_signups = []

					for (let anmeldung of data_tmp.signups) {
						ben_id_signups.push(anmeldung.ben_id)
					}
					data = await api.getUserforSignups(ben_id_signups, this.$route.params.id)

					for (let u of data.user) {
						this.user_array[u._id] = u
					}
					this.users = data.user
					this.users_filtered = this.users

					for (let anmeldung of data_tmp.signups) {
						if (!anmeldung.abgemeldet) {
							for (let user of data.user) {
								if (user._id == anmeldung.ben_id) {
									anmeldungen_temp.push({
										anmeldung: anmeldung,
										user: user,
									})
									break
								}
							}
						} else {
							for (let user of data.user) {
								if (user._id == anmeldung.ben_id) {
									abmeldungen_temp.push({
										anmeldung: anmeldung,
										user: user,
									})

									break
								}
							}
						}
					}
					let an_tmp = false
					for (let a of anmeldungen_temp) {
						if (a.anmeldung.ben_id.toString() == userid) {
							an_tmp = true
						}
					}
					this.angemeldet = an_tmp
					this.warteliste_array = []
					if (
						parseInt(this.course_timeline.course_free_spaces.split('/')[0]) >
						parseInt(this.course_timeline.course_free_spaces.split('/')[1])
					) {
						let toomany =
							parseInt(this.course_timeline.course_free_spaces.split('/')[0]) -
							parseInt(this.course_timeline.course_free_spaces.split('/')[1])

						this.warteliste = false
						let user = await api.getUser().catch((e) => {
							console.log('failed to load user')
							this.$router.push('/login')
						})

						user = user.user
						let userid = user._id
						for (let i = 0; i < toomany; i++) {
							if (anmeldungen_temp[i]) {
								this.warteliste_array[anmeldungen_temp[i].user._id] = true
								if (userid == anmeldungen_temp[i].user._id) {
									this.warteliste = true
								}
							}
						}
					} else {
						this.warteliste = false
					}
					this.anmeldungen = anmeldungen_temp

					this.abmeldungen = abmeldungen_temp

					special_rights = true
				} catch (e) {
					console.log('Fehler beim Anmeldungen Laden')
				}
			}
			if (this.instructor_visiting || userService.hasRight('gf_r_viewAll')) {
				try {
					this.ratings = []
					let data = await api.getRatings(this.course_timeline._id)
					for (let rating of data.ratings) {
						if (this.user_array[rating.user_id]) {
							this.ratings.push({
								user: this.user_array[rating.user_id].vorname + ' ' + this.user_array[rating.user_id].nachname,
								stars: rating.stars,
								rating: rating.rating,
								ls_bewertung: false,
							})
						} else {
							this.ratings.push({
								user:
									this.livestream_anmeldungen[rating.user_id].vorname + ' ' + this.livestream_anmeldungen[rating.user_id].nachname,
								stars: rating.stars,
								rating: rating.rating,
								ls_bewertung: true,
							})
						}
					}
					special_rights = true
				} catch (e) {
					console.log('Fehler beim Rating laden')
				}
			}

			if (!special_rights) {
				let data = await api.loadSignups(this.course_timeline._id)

				data_tmp.signups = data.signups
				let counter = 0
				for (let anmeldung of data.signups) {
					if (!anmeldung.abgemeldet) {
						anmeldungen_temp.push({
							anmeldung: anmeldung,
						})
					}
				}

				let an_tmp = false
				for (let a of anmeldungen_temp) {
					if (a.anmeldung.ben_id.toString() == userid) {
						an_tmp = true
					}
				}
				this.angemeldet = an_tmp
				if (
					parseInt(this.course_timeline.course_free_spaces.split('/')[0]) > parseInt(this.course_timeline.course_free_spaces.split('/')[1])
				) {
					let toomany =
						parseInt(this.course_timeline.course_free_spaces.split('/')[0]) -
						parseInt(this.course_timeline.course_free_spaces.split('/')[1])

					this.warteliste = false

					for (let i = 0; i < toomany; i++) {
						this.warteliste_array[anmeldungen_temp[i].anmeldung.ben_id] = true
						if (userid == anmeldungen_temp[i].anmeldung.ben_id) {
							this.warteliste = true
						}
					}
				} else {
					this.warteliste = false
				}
			}
			if (!auto) {
				this.signinstatus = true
				this.signoffstatus = true
			}
			this.loading = false
			this.initializeEvent()
			this.updateHeight()
		},

		loadSignUpStatus() {
			/*
      let useremail = JSON.parse(localStorage.getItem("user"));
      useremail = useremail.email;
      let signupdata = {
        course_generate_id: this.course_timeline._id,
        ben_id: useremail,
      };

      api
        .signUpKursStatus(signupdata)
        .then((data) => {
          this.angemeldet = data.status;
        })
        .catch((error) => {
          alert("fehler");
        });
        */
		},
		signup() {
			if (this.signinstatus) {
				api.getCourseSettings()
					.then(async (data) => {
						if (this.course_timeline.timestamp / 60 < 1440 * data.anmeldung_wochenfrist + Math.round(Date.now() / 60000)) {
							/* ZEITBESCHRÄNKUNG VOR KURSBEGINN TODO
              if (
                (this.course_timeline.timestamp -
                  Math.round(Date.now() / 1000)) /
                  60 >
                5
              ) {
                */
							this.signinstatus = false
							let user = await api.getUser().catch((e) => {
								console.log('failed to load user')
								this.$router.push('/login')
							})

							user = user.user
							let useremail = user
							useremail = useremail.email
							let signupdata = {
								course_generate_id: this.$route.params.id,
							}

							api.signUpKurs(signupdata)
								.then((data) => {
									if (data.status == 'covidtimelineadded') {
									} else if (data.status == 2) {
										alert('Du hast dich bereits für einen Kurs angemeldet, welcher zur gleichen Zeit stattfindet.')
									}
									this.loadKurs()
									this.loadSignups()
								})
								.catch((error) => {
									console.log('fehler')
								})
							/*
              } else {
                alert(
                  "Sie können sich nur bis 5 Minuten vor Kursbeginn anmelden, melden Sie sich bei dem Instruktor, um eventuell trotzdem noch teilnehmen zu können."
                );
              }
              */
						} else {
							alert('Sie können sich erst ' + data.anmeldung_wochenfrist + ' Tage vor Kursbeginn eintragen.')
						}
					})
					.catch((error) => {
						console.log('Konnte Kurseinstellungen nicht laden')
					})
			}
		},
		async signoff() {
			if (this.signoffstatus) {
				this.signoffstatus = false
				let timecheck = false

				if ((this.course_timeline.timestamp - Math.round(Date.now() / 1000)) / 60 > 0) {
					let user = await api.getUser().catch((e) => {
						console.log('failed to load user')
						this.$router.push('/login')
					})

					user = user.user
					let useremail = user
					useremail = useremail.email
					let signupdata = {
						course_generate_id: this.course_timeline._id,
						ben_id: useremail,
					}

					api.signOffKursStatus(signupdata)
						.then((data) => {
							this.loadKurs()
							this.loadSignups()
						})
						.catch((error) => {
							alert('fehler')
						})
				} else {
					alert('Der Kurs hat bereits begonnen.. Bitte kontaktieren Sie den Instruktor.')
				}
			}
		},
		TimestampToDate(ts) {
			return new Date(new Date(ts * 1000)).toLocaleString()
		},
		TimestampToTime(timestamp) {
			timestamp = parseInt(timestamp)

			let hours = new Date(timestamp * 1000).getHours()

			let minutes = new Date(timestamp * 1000).getMinutes()
			if (minutes < 10) {
				minutes = '0' + minutes
			}
			let value = hours + ':' + minutes
			return value
		},
		loadKurs(auto = false) {
			this.course_timeline._id = this.$route.params.id

			api.getKurstimelineById(this.course_timeline._id)
				.then((data) => {
					if (!this.shallowEqual(this.api.course_timeline, data.course_timelines[0])) {
						this.course_timeline = data.course_timelines[0]
						this.api.course_timeline = data.course_timelines[0]
					}

					if (this.course_timeline.cancelled && !userService.hasRight('gf_cp_viewCancelled')) {
						this.$router.push('/kurse')
					}

					if (this.course_timeline.new_course_timeline_id) {
						this.$router.push('/kurse/anmeldung/' + this.course_timeline.new_course_timeline_id)
					}
					this.loadSignups(auto)
					this.labels_selected_tmp = []
					this.labels_tmp = []
					api.loadLabels(this.course_timeline.course_timeline_id)
						.then((thislabels) => {
							api.getLabels()
								.then((studiolabels) => {
									for (let tlabel of thislabels) {
										for (let slabels of studiolabels) {
											if (tlabel.label_id == slabels._id) {
												this.labels_selected_tmp.push(slabels._id)
												this.labels_tmp.push({
													_id: slabels._id,
													code: slabels.code,
													text: slabels.text,
													color: slabels.color,
												})
											}
										}
									}
								})
								.catch((error) => {})
						})
						.catch((error) => {})

					let found = false

					if (this.labels_tmp.length != this.labels.length) {
						for (let lbtmp of this.labels_tmp) {
							for (let lb of this.labels) {
								if (lb._id == lbtmp._id) {
									found = true
								}
							}
							if (!found) {
								this.labels = this.labels_tmp
								this.labels_selected = this.labels_selected_tmp
								break
							}
						}
					}
					if (this.labels.length == 0) {
						this.labels = this.labels_tmp
						this.labels_selected = this.labels_selected_tmp
					}

					api.getKursById(this.course_timeline.course_id)
						.then((data) => {
							if (!this.shallowEqual(this.api.course, data.course[0])) {
								this.course = data.course[0]
								this.api.course = data.course[0]
							}

							this.dauer = (this.course_timeline.timestamp_end - this.course_timeline.timestamp) / 60
							let date = new Date(this.course_timeline.timestamp * 1000)
							let days = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']
							let month = date.getMonth() + 1
							if (month < 10) {
								month = '0' + month
							}
							let day = date.getDate()
							if (day < 10) {
								day = '0' + day
							}
							this.datum = days[date.getDay()] + ' ' + day + '.' + month + '.' + date.getFullYear()
							this.loadSignUps(auto)
						})
						.catch((error) => {})

					api.getCourseSettings()
						.then((data) => {
							if (this.course_timeline.timestamp_end / 60 + 15 < 1440 * data.anmeldung_wochenfrist + Math.round(Date.now() / 60000)) {
								this.zeitfenster_anmeldung = true
							} else {
								this.zeitfenster_anmeldung = false
							}
						})
						.catch((error) => {
							console.log('fehler')
						})
				})
				.catch((error) => {
					console.log('kurs_timeline_changed')
				})
		},
	},

	created() {
		window.addEventListener('resize', this.updateHeight)

		if (location.host.includes('wellcomefit') || location.host.includes('local')) {
			this.insert_img.wellcomefit = true
		}
		if (location.host.includes('wintifit')) {
			this.insert_img.wintifit = true
		}
		if (location.host.includes('wellcomefit') || location.host.includes('wintifit')) {
			this.anmeldungsinfo = true
		}

		this.loadLabels()

		this.loadKurs()

		this.sharetext = ''
		if (!location.host.includes('wellcomefit')) {
			// wintifit
			this.sharetext =
				'Hey%20Ich%20war%20gerade%20im%20Group%20Fitness%20im%20winti%20FIT.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20dann%20klicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20auch%20f%C3%BCr%20den%20Kurs%20an.%0A%0A'
		} else {
			// wellcomefit
			this.sharetext =
				'Hey%20Ich%20war%20gerade%20im%20Group%20Fitness%20im%20well%20come%20FIT.%20H%C3%A4ttest%20Du%20Lust%20mich%20zu%20begleiten%3F%20Wenn%20ja%2C%20dann%20klicke%20doch%20einfach%20auf%20den%20Link%20und%20melde%20Dich%20auch%20f%C3%BCr%20den%20Kurs%20an.%0A%0A'
		}
		this.fetchCovidQueue()
	},
}
</script>

<style lang="scss" scoped>
.rating-user {
	display: flex;
	.ls_rating {
		padding: 5px;
		background-color: #f00;
		color: #fff;
		margin-left: auto;
		display: block;
		width: 104px;
		font-size: 13px;
		text-align: center;
		text-transform: uppercase;
		margin-right: 15px;
	}
}

.addToKalendar {
	span {
		padding: 10px 15px;
		display: block;
	}

	.wrap-calendars {
		display: flex;
		justify-content: space-between;
		.wrapper-calendar {
			background-color: #52ae32;

			width: calc(100% / 4 - 1px);
			display: inline-block;
			position: relative;
			* {
				user-select: none;
			}

			a {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				text-decoration: none;
				display: flex;
				span {
					display: block;
					margin: auto;
					color: #fff;
					text-align: center;
					font-size: 13px;
				}
			}
			.flexer {
				margin-top: 70%;
			}
		}
	}
}
.bilder-download-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 100px;
	background-color: #313131;
	.bilder-download {
		width: calc(50% - 1px);

		background-color: #52ae32;
		display: flex;
		height: 100%;

		span {
			margin: auto;
			display: flex;
			color: white;
			text-align: center;
		}
	}
}
.helsana {
	width: 100%;
	padding: 10px 0px;
	height: 90px;
	background-color: #52ae32;
	.link {
		display: flex;
		/* display: none; */
		height: 100%;
		width: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			margin: auto;
		}
	}
}
.insert-wcf {
	position: absolute;
	width: 200px;
	margin: auto;
	right: calc(50% - 100px);
	top: 15px;
}
.hinweis {
	text-align: center;
	font-size: 12px;
	position: absolute;
	color: #fff;
	bottom: 2px;
	z-index: 99999;
	right: calc(50% - 145px);
	width: 290px;
}

.kunden-wrapper-suche {
	height: 200px;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	.kunden-suche {
		&.active {
			background-color: #52ae32;
			color: white;
		}
		cursor: pointer;
		padding: 10px 15px;
		margin: 5px 15px;
		border-radius: 5px;
		border: 1px solid grey;
	}
}

.button-share-container {
	bottom: 20px;
	position: fixed;
	margin: 1em;
	right: 0;
}

.buttons {
	box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18), 0px 4px 12px -7px rgba(0, 0, 0, 0.15);
	border-radius: 50%;
	display: block;
	width: 56px;
	height: 56px;
	margin: 20px auto 0;
	position: relative;
	-webkit-transition: all 0.1s ease-out;
	transition: all 0.1s ease-out;
}

.buttons:active,
.buttons:focus,
.buttons:hover {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

.buttons:not(:last-child) {
	width: 0px;
	height: 0px;
	margin: 20px auto 0 auto;
	opacity: 0;
	margin: 0px;
}

.buttons i {
	margin-left: 10px;
	margin-top: 10px;
}
.button-share-container .show-buttons.buttons:not(:last-child) {
	opacity: 1;
	width: 40px;
	height: 40px;
	margin: 15px auto 0;
	color: #fff;
	font-size: 1.4em;
}
/* Styling for showing buttons at differnt speeds */

.buttons:nth-last-child(1) {
	-webkit-transition-delay: 25ms;
	transition-delay: 25ms;
	background-color: #52ae32;
}

.buttons:not(:last-child):nth-last-child(2) {
	-webkit-transition-delay: 60ms;
	transition-delay: 60ms;
	background-size: contain;

	background-image: url('/img/social/whatsapp.svg');
}

.buttons:not(:last-child):nth-last-child(3) {
	-webkit-transition-delay: 40ms;
	transition-delay: 40ms;
	background-color: #3b5998;
	background-position: center;
	background-repeat: no-repeat;
}

.buttons:not(:last-child):nth-last-child(4) {
	-webkit-transition-delay: 20ms;
	transition-delay: 20ms;

	background-color: #007bb5;
	background-position: center;
	background-repeat: no-repeat;
}

.buttons:not(:last-child):nth-last-child(5) {
	-webkit-transition-delay: 80ms;
	transition-delay: 80ms;
	background-size: contain;
	background-color: #00bf8f;
}

.buttons:not(:last-child):nth-last-child(6) {
	-webkit-transition-delay: 100ms;
	transition-delay: 100ms;
	background-size: contain;
	background-color: #00aced;
}
/* Show tooltip content on hover */

[tooltip]:before {
	bottom: 20%;
	font-family: 'Roboto';
	font-weight: 600;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background-color: #585858;
	color: #fff;
	content: attr(tooltip);
	font-size: 12px;
	visibility: hidden;
	opacity: 0;
	padding: 5px 7px;
	margin-right: 10px;
	position: absolute;
	right: 100%;
	white-space: nowrap;
}

[tooltip].show-buttons:before,
[tooltip].show-buttons::after {
	visibility: visible;
	opacity: 1;
}
/* Rotate primary button on hover */

.rotate {
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
}

.rotate {
	position: absolute;
	margin: 15px auto auto 15px;
	max-width: 24px;
	width: 24px;
	height: 24px;

	background-size: contain;
	color: white;
}

.button-share-container .show-buttons .rotate {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.rating .rating-star {
	display: inline-block;
	position: relative;
	padding-right: 5px;
	margin-left: -5px;
	color: #e3cf7a;
}
.rating {
	padding: 10px;
	margin: 10px 0px;
	&-stars {
		i {
			padding: 5px;
			font-size: 24px;
		}
		margin-bottom: 5px;
	}

	&-user {
		font-weight: bold;
	}
}
.no-ratings {
	padding: 10px 15px;
}
.loading {
	img {
		width: 40px;
		height: 40px;
	}

	padding: 20px;
	font-weight: 400;
	font-size: 18px;
	background-color: white;
}

.show-user-info {
	position: fixed;
	top: 20vh;
	width: calc(80vw);
	height: 50vh;
	background-color: white;
	padding: 20px;
	justify-content: center;
	/* max-width: 80vw; */
	margin: 0px 10vw 0px 10vw;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	.show-user-info-wrapper {
		max-width: 400px;
		margin: auto;
		display: flex;
		flex-direction: column;
		span {
			font-weight: 500;
			max-width: 400px;
			margin: 10px auto;
		}
	}
	.schliessen {
		width: 200px;
		margin: 10px auto 0px auto;
		text-align: center;
		padding: 10px 5px;
		background-color: #3a2a2a;
		color: white;
		border-radius: 5px;
	}
}
.edit-labels {
	padding: 10px 10px;
	font-size: 12px;
}
.mul-select {
	width: 100%;
	option {
		color: #4a4a4a !important;
		padding: 5px;
		&:checked {
			background: #52ae32 linear-gradient(0deg, #52ae32 0%, #52ae32 100%);
		}
	}
}
.filter-customers {
	color: black !important;
	background-color: inherit;
	border-bottom: 1px solid black !important;
	margin-right: 10px imp !important;
}
.warteliste-icon {
	position: absolute;
	right: 80px;
}
.anmerkungen {
	padding: 10px 15px;
	width: 100%;
	color: #4a4a4a;
}
.user-signin {
	border-bottom: 1px solid white;

	.user-sign-in-action {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
		.sign-in-user {
			background-color: #52ae32;
			color: white;
			margin: 0px auto;
			padding: 10px 15px;
			width: 90%;
			text-align: center;

			border-radius: 45px;
		}
		.sign-in-user-cancel {
			background-color: #443838;
			color: white;
			margin: 20px auto;
			padding: 10px 15px;
			width: 90%;
			text-align: center;

			border-radius: 45px;
		}
	}

	label {
		color: white;
		margin: 10px 15px 0px 15px;
	}
	input {
		padding: 10px 0px;
		width: calc(100% - 30px);
		color: black;
		margin: 10px 15px;
		border-bottom: 1px solid white;
	}
	select {
		color: #4a4a4a;
		margin: 10px 15px;
		padding: 10px 0px;
		width: calc(100% - 30px);
		option {
			color: black;
		}
	}
}
.signin-user {
	background-color: #52ae32;
	text-align: center;
	color: white;
	padding: 10px;
	display: flex;
	span {
		margin-right: 5px;
	}
}
.anmeldung {
	padding: 10px 15px;
	color: #4a4a4a;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #808080bf;

	span {
		line-height: 31px;
	}
	.sign-off {
		margin-left: auto;
		margin-right: 10px;
	}
	input {
		height: 20px;
		width: 19px;
		margin: 2px;
	}
}
.edit-kurs {
	margin-left: auto;
	padding-right: 10px;
	display: flex;
}

.label {
	height: 40px;
	line-height: 40px;
	font-weight: 300;
	padding-left: 15px;
	background-color: #313131;
	color: white;
}
/*
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
    }
  }
  @return $shadow;
}*/
.kurs-header-wrapper {
	position: relative;
	width: 100%;
	display: block;
	max-height: 500px;

	.kurs-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: contain;
		background-position: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fff;

		// text-shadow: stroke(2px, #3c3c3c);
		text-shadow: #313131 1px -1px 1px, #313131 -1px 0px 3px, #313131 0px 0px 5px, #313131 2px 2px 3px, #313131 2px 2px 3px, #313131 2px 2px 3px;
		-webkit-font-smoothing: antialiased;

		background-color: #52ae32;

		.kurs-info-wrapper {
			position: absolute;
			top: calc(50% - 57px);
			right: calc(50% - 170px);
			width: 340px;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}

		div {
			text-align: center;
			font-size: 18px;
			font-weight: 500;
		}
		div.kurs-name {
			font-size: 22px;
		}
	}
}

.anmeldung-status {
	color: #4a4a4a;
	display: flex;
	flex-direction: column;

	.status {
		font-size: 15px;
		padding: 15px 15px;
	}

	.anmelden {
		color: white;
		margin: 20px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		background-color: #52ae32;
		border-radius: 45px;
	}
	.abmelden {
		color: white;
		margin: 20px auto;
		padding: 10px 15px;
		width: 90%;
		text-align: center;
		background-color: #a73232;
		border-radius: 45px;
	}
}

.kursinfo {
	color: #4a4a4a;
	font-size: 15px;
	padding: 5px 15px;
}

.back-navigation {
	background-color: #313131;
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;

	.add-zeitplaene {
		margin-left: auto;
		margin-right: 10px;
	}

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
