<template xmlns="">
	<div class="workingOutCompleted__wrapper">
		<v-card-title class="workingOutCompleted__card-title h5 break-word primary--text justify-center">GRATULATION! </v-card-title>

		<v-card-title class="h4 justify-center break-word"
			>Du hast
			<number v-if="liftedWeight" ref="weight" class="px-3 primary--text" :from="100" :to="liftedWeight" :duration="3"></number>
			<span v-else class="px-3 primary--text"> 0 </span>
			kg gehoben
		</v-card-title>
		<!--
      <v-card-title class="justify-center">
        <v-img class="workingOutCompleted__card-cow-image" src="/img/cow.png" />
      </v-card-title>
-->
		<lottie-player
			mode="normal"
			style="max-width: 500px;"
			class="workingOut__lottie-player"
			name="loading"
			:autoplay="false"
			src="/img/lottiefiles/fitnessCow.json"
		></lottie-player>

		<div>
			<v-card-title class="h6 justify-center break-all pt-5">Das entspricht einem Gewicht von:</v-card-title>
			<v-card-title class="h6  pt-0 justify-center">
				<number v-if="cowCount" class="pr-3" :from="0" :to="cowCount"></number>
				<span v-else class="mr-3">0</span>

				{{ cowCount !== 1 ? 'Kühen' : 'Kuh' }}
			</v-card-title>
		</div>
		<v-card-actions class="justify-center mb-5">
			<v-btn x-large color="primary" class="text-white" @click="closeView">Übersicht schliessen</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	name: 'WorkingoutCompleted',
	data() {
		return {
			loading: true,
			liftedWeight: null,
			cowAverageWeight: 800,
			cowCount: null,
		}
	},
	created() {
		this.calculateLiftedWeight()
		this.$confetti.start()
		setTimeout(() => {
			this.$confetti.stop()
		}, 1500)
	},
	methods: {
		...mapActions('workingoutStore', ['resetFinishedStepsArray', 'setWorkoutStepsHistory']),
		calculateLiftedWeight() {
			this.finishedSteps.forEach((exercice) => {
				exercice.steps.forEach((step) => {
					if (step.weight) {
						this.liftedWeight += step.weight * step.reps
					}
				})
			})
			this.cowCount = this.liftedWeight / 800
			this.resetFinishedStepsArray()
			this.setWorkoutStepsHistory()
		},
		closeView() {
			this.$router.push({ name: 'Workouts' })
		},
	},
	computed: {
		...mapState('workingoutStore', ['finishedSteps']),
	},
}
</script>
<style lang="scss" scoped>
.h5 {
	text-transform: uppercase;
	font-weight: bold;
}

.workingOutCompleted__card {
	width: 90% !important;
	height: 80vh;
	margin: 5%;
	align-items: center;

	&-cow-image {
		width: 50%;
		flex: unset;
		display: unset;
	}
}
</style>
