<template>
    <div class="courseListExternal scrollable" :style="{ 'overflow-x: hidden': clean }" id="printContent">
        <v-row class="pb-5 pt-3">
            <v-col :cols="$vuetify.breakpoint.mobile ? 9 : 11" no-gutters class="pa-0">
                <v-autocomplete
                    v-model="search"
                    :disabled="isUpdating"
                    :items="searchItems"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label="Filter"
                    item-text="name"
                    item-value="name"
                    multiple
                    @input="searchInput = null"
                    :search-input.sync="searchInput"
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                        >
                            <v-avatar left>
                                <v-img v-if="data.item.image" :src="data.item.image"></v-img>
                            </v-avatar>
                            {{ data.item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-avatar>
                                <img v-if="data.item.image" :src="data.item.image" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <!----<v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle> ---->
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 3 : 1" no-gutters class="pa-0">
                <div style="height: 100%" class="d-flex justify-center">
                    <v-btn
                        style="height: 100%; border-radius: 0px"
                        block
                        @click="downloadPrint"
                        class="mx-auto"
                        color="#343a40"
                    >
                        <lottie-player
                            mode="normal"
                            style="width: 45px; height: 45px"
                            class="app-bar__lottie"
                            src="/img/lottiefiles/gf/downloadIcon.json"
                        ></lottie-player>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <div
            v-for="(courseDay, i) of courseListFiltered"
            :key="i + 'courseDay'"
            class="courseListExternal__courseList-day d-flex flex-column"
        >
            <v-card-title class="courseListTitle h6" v-if="courseDay && courseDay.length > 0">
                {{ getDateFromIndex(i) }}
            </v-card-title>
            <div v-if="courseDay && courseDay.length > 0">
                <v-row
                    v-for="(course, y) of courseDay"
                    :key="y + 'courseEntry'"
                    class="courseListExternal__courseList-day-course d-flex px-5"
                    :class="{
                        'py-2': !($vuetify.breakpoint.sm || $vuetify.breakpoint.xs),
                        'pb-3': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs,
                        'pt-7': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs,
                        signedUp: course.signedUp,
                        isInstructor: course.isInstructor,
                        onQueue: course.onQueue,
                        cancelledVisibility: course.cancelledVisibility,
                    }"
                    @click="showDialog(course, i)"
                >
                    <v-col
                        cols="12"
                        md="3"
                        v-if="!($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) && (course.hours || course.minutes)"
                        class="d-flex flex-row"
                    >
                        <v-icon class="pr-2">mdi-watch</v-icon>

                        <div class="from">
                            <span>{{ getCourseStartDate(course) }}</span>
                        </div>
                        <div class="until">
                            <span class="px-2">-</span>
                            <span v-if="course.duration">{{ getCourseEndDate(course) }} Uhr</span>
                        </div>
                    </v-col>

                    <v-col
                        v-if="
                            !($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) && course.course && course.course.name
                        "
                        cols="12"
                        md="3"
                    >
                        <v-icon class="pr-2">mdi-dumbbell</v-icon>
                        <strong class="primary--text">{{ course.course.name }}</strong>
                    </v-col>

                    <v-col
                        class="py-0"
                        v-if="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) && course.course && course.course.name"
                        cols="12"
                        md="12"
                    >
                        <strong class="primary--text">{{ course.course.name }}</strong>
                    </v-col>

                    <v-col
                        cols="12"
                        md="12"
                        v-if="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) && (course.hours || course.minutes)"
                        class="d-flex flex-row py-0"
                    >
                        <div class="from">
                            <span>{{ getCourseStartDate(course) }}</span>
                        </div>
                        <div class="until">
                            <span class="px-2">-</span>
                            <span v-if="course.duration">{{ getCourseEndDate(course) }} Uhr</span>
                        </div>
                    </v-col>

                    <v-col
                        cols="12"
                        :md="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '12' : '3'"
                        v-if="course.room && course.room.name"
                        :class="{ 'py-0': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs }"
                        class="d-flex flex-row"
                    >
                        <v-icon v-if="!($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)" class="pr-2">
                            mdi-map-marker
                        </v-icon>
                        <span>{{ course.room.name }}</span>
                    </v-col>
                    <v-col
                        :class="{
                            'text-right': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs,
                            'py-0': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs,
                        }"
                        cols="12"
                        md="3"
                        v-if="course.instructors && course.instructors.length > 0"
                    >
                        <span
                            style="word-break: keep-all"
                            class="pr-1"
                            v-for="(instructor, iy) of course.instructors"
                            :key="iy + 'courseInstructor'"
                        >
                            {{ instructor.vorname }}
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog v-model="dialog" persistent max-width="300">
            <v-card>
                <v-card-title
                    style="word-break: keep-all"
                    v-if="showCourse.course && showCourse.course.name"
                    class="text-h5 pt-2"
                >
                    <v-row no-gutters>
                        <v-icon class="pr-2">mdi-dumbbell</v-icon>
                        <span>{{ showCourse.course.name }}</span>
                    </v-row>
                </v-card-title>

                <v-card-text
                    v-if="showCourse.instructors && showCourse.instructors.length && showCourse.instructors[0].image"
                    class="justify-center"
                >
                    <v-img
                        :src="'/img/profilbilder' + showCourse.instructors[0].image"
                        style="border-radius: 50%"
                        class="ma-auto"
                        max-width="80px"
                        max-height="80px"
                        height="80px"
                        width="80px"
                    ></v-img>
                </v-card-text>
                <v-card-text class="mt-3">
                    <v-icon class="pr-2">mdi-watch</v-icon>
                    <span>{{ getCourseStartDate(showCourse) }}</span>
                    <span class="px-1">-</span>
                    <span v-if="showCourse.duration">{{ getCourseEndDate(showCourse) }}</span>
                </v-card-text>
                <v-card-text v-if="showCourse.room && showCourse.room.name">
                    <v-icon class="pr-2">mdi-map-marker</v-icon>
                    <span>{{ showCourse.room.name }}</span>
                </v-card-text>

                <v-card-text v-if="showCourse.instructors && showCourse.instructors.length > 0">
                    <span class="pr-1">mit</span>
                    <span
                        class="pr-1"
                        v-for="(instructor, iy) of showCourse.instructors"
                        :key="iy + 'courseInstructor'"
                    >
                        {{ instructor.vorname }}
                    </span>
                </v-card-text>
                <v-card-text
                    style="max-height: 90px; overflow-y: auto; overflow-x: hidden"
                    max-height="200px"
                    v-if="showCourse.course && showCourse.course.beschreibung"
                >
                    {{ showCourse.course.beschreibung }}
                </v-card-text>
                <v-card-title style="word-break: keep-all" class="text-h6">Jetzt Anmelden</v-card-title>
                <v-card-text>Um den Kurs zu buchen, melde Dich mit Deinem roody Login an.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialog = false">Schliessen</v-btn>
                    <v-btn color="primary" text href="/" target="_blank">Anmelden</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'
import userService from '../../services/userService'
import { jsPDF } from 'jspdf'
export default {
    name: 'courseListWeekly',

    data() {
        return {
            clean: null,
            userService,
            searchInput: null,
            searchItems: [],
            search: [],
            isUpdating: false,
            serchItems: [],
            courseList: [],
            courseListFiltered: [],
            dialog: false,
            showCourse: {},
            instructors: [],
            courses: [],
        }
    },
    async created() {
        if (this.$route.name === 'courseListWeeklyClean') this.clean = true
        this.fetchCourseList()
    },
    mounted() {
        console.log('HEREEEEEE')
        if (this.$route.params.studiocode && this.navigationVisible) this.toggleNavigationBarVisibility()
    },
    destroyed() {
        if (this.$route.params.studiocode && !this.navigationVisible) this.toggleNavigationBarVisibility()
    },
    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),

        alibeyStudio() {
            let studioCode = null
            if (!this.$route.params.studiocode) {
                let user = JSON.parse(localStorage.getItem('user'))
                if (user && user.user) studioCode = user.user.studio_id
            } else {
                studioCode = this.$route.params.studiocode
            }

            if (studioCode && (studioCode === 'Alibey' || studioCode === 'alibey' || studioCode === 'ali')) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['toggleNavigationBarVisibility', 'setNavigationBarText']),
        redirectToHome() {
            let host = window.location.hostname
            console.log('redirecting to https://' + host)
            window.parent.location.href = 'https://' + host
        },
        async downloadPrint() {
            if (this.$route.params.studiocode) {
                let pdf = await api.fetchGFWeeklyPDF(this.$route.params.studiocode)
            } else {
                let user = JSON.parse(localStorage.getItem('user'))
                let studioCode = null

                if (user && user.user) {
                    studioCode = user.user.studio_id
                    let pdf = await api.fetchGFWeeklyPDF(studioCode)
                }
            }
        },
        getDateFromIndex(index) {
            if (this.alibeyStudio) {
                if (this.alibeyStudio) return this.$moment('2024-05-25').add(index, 'days').format('dddd DD.MM.YY')
            } else {
                return this.$moment().add(index, 'days').format('dddd DD.MM.YY')
            }
        },
        remove(item) {
            const index = this.search.indexOf(item.name)
            if (index >= 0) this.search.splice(index, 1)
        },
        async fetchCourseList() {
            let data = null
            if (this.$route.params.studiocode) {
                data = await api.fetchExCourseList(this.$route.params.studiocode)
            } else {
                data = await api.fetchWeeklyCourseList()
            }

            this.courseList = data.list
            this.courseListFiltered = JSON.parse(JSON.stringify(this.courseList))

            this.searchItems = []
            this.searchItems = [{ header: 'Kurse' }]
                .concat(data.courses)
                .concat([{ header: 'Instruktoren' }].concat(data.instructors))
            console.log(this.searchItems)
        },
        getCourseStartDate(course) {
            let startTime = this.$moment()

            startTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            return startTime.format('LT')
        },
        getCourseEndDate(course) {
            let endTime = this.$moment()
            endTime.set({
                hours: course.hours,
                minutes: course.minutes,
                seconds: 0,
                miliseconds: 0,
            })

            endTime.add('minutes', course.duration)
            return endTime.format('LT')
        },
        showDialog(course, index) {
            if (this.$route.params.studiocode) {
                this.showCourse = course
                this.dialog = true
            } else {
                this.pushToCourse(course, index)
            }
        },
        pushToCourse(course, index) {
            if (
                !course.past ||
                course.current ||
                course.isInstructor ||
                this.userService.hasRight(
                    'gf_c_edit',
                    'gf_c_delete',
                    'gf_c_plan',
                    'gf_c_create',
                    'gf_cp_edit',
                    'gf_f_courses'
                )
            ) {
                let ts = null
                if (this.alibeyStudio) {
                    if (this.alibeyStudio) ts = this.$moment('2024-05-25').add(index, 'days').unix()
                } else {
                    ts = this.$moment()
                        .add(index, 'days')
                        .set({ hours: 0, minutes: 0, seconds: 0, miliseconds: 0 })
                        .unix()
                }

                if (course.courseTimelineId) {
                    ts = 0
                }
                document.body.style = 'overflow:hidden;max-height:100vh;'

                this.$router.push({ name: 'courseListView', params: { id: course._id, ts } })
            }
        },
    },
    watch: {
        search(val) {
            console.log(val)
            let courseList = []
            if (val.length > 0) {
                this.courseList.forEach((e) => {
                    let test = e.filter((course) => {
                        let found = false
                        if (course.searchText) {
                            val.forEach((e) => {
                                if (course.searchText.search(e) !== -1) found = true
                            })
                        }
                        return found
                    })

                    if (test.length > 0) {
                        courseList.push(test)
                    } else {
                        courseList.push(null)
                    }
                })
                this.courseListFiltered = courseList
            } else {
                this.courseListFiltered = this.courseList
            }
        },
        isUpdating(val) {
            if (val) {
                console.log('updating')
                setTimeout(() => (this.isUpdating = false), 3000)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.courseListTitle {
    background-color: var(--v-primary);
    color: #fff;

    letter-spacing: 1.3px;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 12px;

    font-weight: bold;
}
::v-deep .mdi-menu-down::before {
    font-size: 3.5rem;
}
::v-deep .v-text-field__details {
    display: none !important;
}
::v-deep .v-input__slot {
    margin-bottom: 0 !important;
}
.courseListExternal__courseList {
    &-day {
        &-course {
            &.cancelledVisibility {
                opacity: 0.5;
            }
            &.signedUp,
            &.isInstructor {
                background-color: #c5e6a980 !important;
            }
            &.onQueue {
                background-color: #e49f9f80 !important;
            }
            border-bottom: 1px solid var(--v-primary);
            cursor: pointer;

            &:hover {
                color: white !important;
                background-color: var(--v-primary);
                .v-icon {
                    color: white !important;
                }
                strong {
                    color: white !important;
                }
            }
        }
    }
}
</style>
