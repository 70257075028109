<template>
	<v-row class="flex-column" no-gutters>
		<v-card-text class="px-0">
			{{ selectedHabit.description }}
		</v-card-text>
		<v-card-actions class="text-align-center">
			<v-btn color="primary" outlined @click="$router.push({ name: 'courseList' })"> Zum Kursplan </v-btn>
		</v-card-actions>
	</v-row>
</template>

<script>
export default {
	name: 'habitGroupFitnessForm',
	props: {
		selectedHabit: {
			type: Object,
			required: true,
		},
	},
}
</script>
