<template>
    <image-overlapping-card
        v-if="visible"
        :dialogFullScreen="$vuetify.breakpoint.mobile"
        :dialogMaxWidth="$vuetify.breakpoint.mobile ? '1040px' : '700px'"
        :height100="true"
        :innerCardTopMargin="$vuetify.breakpoint.mobile ? '32vh !important' : '35vh !important'"
        :leftIcon="shareButton"
        @left-clicked="closeDialog()"
    >
        <template v-slot:image>
            <div>
                <v-img
                    contain
                    class="pic-top"
                    aspect-ratio="16/9"
                    :height="$vuetify.breakpoint.mobile ? '32vh' : '35vh'"
                    :src="'/img/' + popup.image"
                ></v-img>
            </div>
        </template>
        <template v-slot:content>
            <v-card-title class="h2 font-weight-bold" style="word-break: break-word">
                {{ popup.title }}
            </v-card-title>
            <v-card-text v-html="popup.text"></v-card-text>
            <v-card-actions class="justify-content-center">
                <v-btn @click="closeDialog()" rounded color="primary">zurück</v-btn>
            </v-card-actions>
        </template>
        <!--
			<v-row class="popup ma-0" justify="center">
        <v-dialog class="popup-dialog" v-if="active" v-model="visible" persistent max-width="300">
            <v-card class="contentCard" :class="{ 'pt-1': !popup.image.length > 0 }">
                <v-img
                    :key="popup.image"
                    @click="pushToLink"
                    v-if="popup.image"
                    :src="'/img/' + popup.image"
                    max-height="60vh"
                    contain
                ></v-img>
                <div
                    :class="{ showMore: moreText }"
                    class="popup__description ma-5"
                    v-if="popup.text"
                    v-html="popup.text"
                ></div>
                <v-btn
                    class="mx-auto d-flex my-3"
                    color="primary"
                    v-if="popup.linkText && popup.link"
                    @click="pushToLink"
                >
                    <v-icon class="pr-2">mdi-information</v-icon>
                    {{ popup.linkText }}
                </v-btn>
                <v-card-actions>
                    <v-btn color="grey darken-1" text @click="closeDialog()">Später</v-btn>
                    <v-spacer></v-spacer>
                    <div v-if="popup.text && popup.text.length > 0">
                        <v-btn v-if="!moreText" color="green darken-1" text @click="showMoreText()">Mehr</v-btn>
                        <v-btn v-else color="green darken-1" text @click="moreText = false">Weniger</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
		-->
    </image-overlapping-card>
</template>
<script>
import api from '../../api'
import imageOverlappingCard from '@/components/imageOverlappingCard.vue'
import { mapActions } from 'vuex'

export default {
    components: {
        imageOverlappingCard,
    },
    data() {
        return {
            popups: [],
            visible: false,
            moreText: false,
            shareButton: {
                color: 'black',
                icon: 'mdi-chevron-left',
            },
            popup: {
                _id: 'test',
                hideAfter: null,
                showAfter: null,
                link: '/news',
                linkText: 'BRING A FRIEND',
                customerOnly: false,
                employeesOnly: false,
                cooldown: 24,
                image: '/img/einmalig/workoutExample.png',
                text: '<strong>News</strong><br><span>Hey Ho wie laufts?</span>',
            },
        }
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility']),

        async showMoreText() {
            if (this.popup._id) await api.readPopup(this.popup._id)
            this.moreText = true
        },
        pushToLink() {
            this.visible = false
            var windowReference = window
            console.log(this.popup.link)
            if (this.popup._id && this.popup.link && this.popup.link.length > 0) {
                console.log('FOLLOWING LINK')
                api.followLinkPopup(this.popup._id).then((cb) => {
                    windowReference.location = this.popup.link
                })
            }
        },
        skipLocation() {
            let skipUrls = ['/plan', '/login', '/register', '/referral', '/plan', '/schnupperwoche']
            let skip = false

            let href = location.href.toString()
            skipUrls.forEach((s) => {
                if (href.includes(s)) skip = true
            })

            return skip
        },
        async fetchPopup() {
            let popup = await api.fetchPopup()

            this.popups = popup
            this.popup = popup[0]
            this.visible = this.active
            if (this.visible) {
                this.setNavigationbarVisibility(false)
            }
            console.log('this.active', this.active)
            console.log('popup', popup)
        },

        async closeDialog() {
            console.log('closing')
            this.visible = false

            if (localStorage.getItem('popupCooldown')) {
                let popupCooldowns = JSON.parse(localStorage.getItem('popupCooldown'))
                popupCooldowns[this.popup._id] = this.$moment().format()
                localStorage.setItem('popupCooldown', JSON.stringify(popupCooldowns))
            } else {
                let popupCooldowns = {}
                popupCooldowns[this.popup._id] = this.$moment().format()
                localStorage.setItem('popupCooldown', JSON.stringify(popupCooldowns))
            }
            this.setNavigationbarVisibility(true)
        },
    },
    computed: {
        active() {
            let ts = this.$moment().unix()
            if (
                this.popup.hideAfter &&
                this.popup.showAfter &&
                this.$moment(this.popup.hideAfter).set({ hours: 23, minutes: 59, seconds: 59 }).unix() > ts &&
                this.$moment(this.popup.showAfter).set({ hours: 0, minutes: 0, seconds: 0 }).unix() < ts
            ) {
                if (this.popup.cooldown) {
                    if (
                        localStorage.getItem('popupCooldown') &&
                        JSON.parse(localStorage.getItem('popupCooldown'))[this.popup._id]
                    ) {
                        if (
                            this.$moment(JSON.parse(localStorage.getItem('popupCooldown'))[this.popup._id])
                                .add(this.popup.cooldown, 'hours')
                                .unix() < ts
                        ) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            } else {
                return false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .pic-top .v-image__image {
    background-position: center top !important;
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 70% !important;
}
.popup {
    .contentCard {
        max-height: calc(100vh - 219px);
        background-color: #fff;
        overflow: auto;
    }

    &__description {
        max-height: 20px;
        overflow: hidden;
        &.showMore {
            max-height: 100px;
            overflow-y: auto;
            max-height: unset !important;
        }
    }
    &__title {
        background-color: #313131;
        font-weight: bold !important;
        text-transform: uppercase;
        letter-spacing: 1px !important;
        color: white;
        font-size: 17px !important;
    }
}
</style>
