<template>
    <v-dialog :value="dialogActive" persistent max-width="800">
        <v-card dark>
            <div style="width: 100%">
                <v-btn absolute right icon @click="$emit('closeForm')"><v-icon color="white">mdi-close</v-icon></v-btn>
            </div>
            <v-card-title>Produkt Formular</v-card-title>
            <v-row no-gutters class="justify-content-center">
                <v-col cols="10">
                    <v-text-field
                        hint="Maximal 10 Zeichen"
                        counter
                        label="Produkt Titel"
                        v-model="formData.title"
                    ></v-text-field>
                </v-col>
                <v-col cols="10">
                    <v-text-field
                        hint="Maximal 50 Zeichen"
                        max-length="30"
                        label="kurz Beschreibung"
                        v-model="formData.shortDescription"
                    ></v-text-field>
                </v-col>
                <v-col cols="10">
                    <v-textarea
                        hint="Maximal 50 Zeichen"
                        max-length="30"
                        label="Beschreibung (Produktansicht)"
                        v-model="formData.description"
                    ></v-textarea>
                </v-col>
                <v-col cols="10">
                    <v-textarea label="Produkt Bedingungen" v-model="formData.condition"></v-textarea>
                </v-col>
                <v-col cols="10">
                    <v-select
                        label="Produktkategorie"
                        v-model="formData.category"
                        :items="[
                            {
                                text: 'Abo',
                                value: 'abo',
                            },
                            {
                                text: 'Pakete',
                                value: 'bundles',
                            },
                            {
                                text: 'Gutschein',
                                value: 'voucher',
                            },
                        ]"
                    ></v-select>
                </v-col>
                <v-col cols="10">
                    <v-select label="Anzeigen für Firma" v-model="formData.company" :items="companies"></v-select>
                </v-col>
                <v-col cols="10">
                    <v-card-title>Produkt Bild</v-card-title>
                    <slim-cropper
                        :key="formData.picture"
                        style="width: 300px; margin: auto"
                        class="d-table"
                        :options="slimOptionsProduct"
                    >
                        <img :key="formData.picture" v-if="formData.picture" :src="'/img/' + formData.picture" alt="" />
                        <input type="file" accept="image/*" />
                    </slim-cropper>
                </v-col>
                <v-col cols="10">
                    <v-card-title style="word-break: break-word">Hintergrund Farbe (dem Bild anpassen)</v-card-title>
                    <v-color-picker
                        hide-inputs
                        v-model="formData.backgroundColor"
                        dot-size="25"
                        swatches-max-height="200"
                    ></v-color-picker>
                </v-col>
                <v-col cols="10">
                    <v-checkbox label="Im Shop anzeigen" v-model="formData.public"></v-checkbox>
                </v-col>
                <v-col cols="10">
                    <v-text-field
                        label="Alternativ text für In den Warenkorb"
                        v-model="formData.alternateToCartText"
                    ></v-text-field>
                </v-col>
                <v-col cols="10">
                    <v-text-field
                        label="Link anstatt produkt direkt im Shop anbieten"
                        v-model="formData.shopExternLink"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" v-if="formData.shopExternLink">
                    <v-text-field
                        label="Text im Popup vor weiterleitung zu Link"
                        v-model="formData.externLinkPopupText"
                    ></v-text-field>
                </v-col>
                <v-row no-gutters class="justify-space-between">
                    <v-card-title>Produkt Variationen</v-card-title>
                    <v-col cols="12">
                        <v-btn
                            @click="formData.variations.push({ title: '', color: '', description: '', price: '' })"
                            color="primary"
                        >
                            Variation hinzufügen
                        </v-btn>
                    </v-col>
                    <v-col cols="5" :key="index" v-for="(variation, index) of formData.variations">
                        <div style="width: 100%">
                            <v-btn absolute right icon @click="formData.variations.splice(index, 1)">
                                <v-icon color="red">mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <v-text-field label="Titel" v-model="variation.title"></v-text-field>
                        <v-color-picker
                            label="Farbe"
                            hide-inputs
                            v-model="variation.color"
                            dot-size="25"
                            swatches-max-height="200"
                        ></v-color-picker>
                        <v-textarea label="Beschreibung" v-model="variation.description"></v-textarea>
                        <v-checkbox
                            label="eigene Preis Eingabe ermöglichen"
                            v-model="variation.setOwnPrice"
                        ></v-checkbox>
                        <v-text-field
                            label="Preis"
                            v-if="!variation.setOwnPrice"
                            number
                            v-model="variation.price"
                        ></v-text-field>
                        <v-text-field label="Preis prefix" v-model="variation.pricePrefix"></v-text-field>
                        <v-text-field label="Preis suffix" v-model="variation.priceSuffix"></v-text-field>
                    </v-col>
                </v-row>
            </v-row>
            <v-card-actions class="justify-space-around">
                <v-btn color="primary" @click="createOrUpdateProduct()">Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
  <script>
import axios from 'axios'
import api from '../../../api'
import userSearch from '@/components/userSearch.vue'
import Slim from '../../../plugins/slimImage/slim/slim.vue'

export default {
    components: { userSearch, 'slim-cropper': Slim },
    props: {
        dialogActive: {
            required: true,
        },
        propFormData: {
            required: false,
            type: Object,
        },
    },
    data() {
        return {
            companies: [],
            slimOptionsProduct: {
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                ratio: '16:10',
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            amount: 0,
            formData: { variations: [] },
        }
    },
    async created() {
        this.companies = await api.getStudioCompanies()
        this.initLoading = true
    },
    methods: {
        async createOrUpdateProduct() {
            if (this.formData.backgroundColor.hex) this.formData.backgroundColor = this.formData.backgroundColor.hex
            let createdProduct = await api.createOrUpdateProduct(this.formData)
            this.$emit('closeForm')
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'product'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.formData.picture = data.name

                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
        setNewFormData() {
            this.formData = {
                title: '',
                variations: [],
            }
        },
    },
    watch: {
        dialogActive() {
            if (this.dialogActive) {
                if (this.propFormData.title) {
                    this.formData = this.propFormData
                } else {
                    this.setNewFormData()
                }
            }
        },
    },
}
</script>
  