<template>
	<div class="instruktoren">
		<div class="back-navigation">
			<router-link to="/einstellungen">
				<span class="material-icons">navigate_before</span>
			</router-link>
			<span>Verwaltung</span>
		</div>
		<div class="user" v-for="(user, i) of users" :key="i">
			{{ user.vorname }} {{ user.nachname }} <input type="checkbox" v-model="user.isAdmin" />
		</div>
		<div class="save" @click="saveUserRoles">Speichern</div>
	</div>
</template>
<script>
import api from '../../api'

export default {
	data() {
		return {
			users: {},
		}
	},
	methods: {
		saveUserRoles() {
			api.saveUserRoles(this.users)
				.then((data) => {
					if (data.status) {
						alert('erfolgreich abgeändert')
					}
				})
				.catch((error) => {})
		},
	},
	created() {
		api.getAllUserList()
			.then((data) => {
				this.users = data.user
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
</script>
<style lang="scss" scoped>
.save {
	color: white;
	width: 130px;
	background-color: var(--v-primary);
	margin: 20px auto;
	text-align: center;
	padding: 10px;
	border-radius: 40px;
}

.user {
	color: #4a4a4a;
	position: relative;
	margin-bottom: 10px;
	margin-left: 15px;
	input {
		position: absolute;
		right: 20px;
		height: 20px;
		width: 20px;
	}
}

.back-navigation {
	background-color: var(--v-primary);
	color: white;
	padding: 0px 10px;
	font-weight: 500;
	display: flex;
	margin-bottom: 20px;

	span {
		margin-top: auto;
		margin-bottom: auto;
	}
	a {
		display: flex;
		padding: 5px;
		color: white;

		span {
			font-size: 34px;
		}
	}
}
</style>
