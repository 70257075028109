<template>
	<div></div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { habitService } from '@/services/habitService.js'
import habitDrinkForm from './habitDrinkForm.vue'
import habitWorkoutForm from './habitWorkoutForm.vue'
import habitGroupFitnessForm from './habitGroupFitnessForm.vue'
import habitWakeUpForm from './habitWakeUpForm.vue'
export default {
	name: 'habitStatistic',
	components: {
		habitDrinkForm,
		habitWorkoutForm,
		habitGroupFitnessForm,
		habitWakeUpForm,
	},
	props: {
		selectedHabit: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			dialog: true,
			date: this.$moment().format('YYYY-MM-DD'),
			datePicker: false,
		}
	},
	methods: {
		closeDialog() {
			this.dialog = false
			setTimeout(() => this.$emit('closeEdit'), 250)
		},
	},
	computed: {
		formattedDate() {
			if (this.$moment().isSame(this.$moment(this.date, 'YYYY-MM-DD'), 'day')) {
				return 'Heute'
			} else {
				return this.$moment(this.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
	transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	width: 100%;
	margin: 0;
	position: absolute;
	min-height: 40vh;
	bottom: 0;
	background: white;
	max-height: 90vh !important;
}

.option-button {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		background: lightgray;
		&-bulb {
			::v-deep .v-image__image {
				width: 80px;
			}
			flex-direction: row;
		}
	}
	&__wrapper:hover {
		background: gray;
	}
}
</style>
